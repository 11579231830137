import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobXTandemCheckOthersProfile, JobXTandemCheckProfile } from '../../../jobs.types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
    ConversationType,
    DirectMessagesCreateConversationActions,
    LocationService,
} from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'tp-job-x-tandem-dialog',
    templateUrl: './job-x-tandem-dialog.component.html',
    styleUrls: ['./job-x-tandem-dialog.component.scss'],
})
export class JobXTandemDialogComponent implements OnInit {
    ownLocations: Array<string>;
    targetLocations: Array<string>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            ownProfile: JobXTandemCheckProfile;
            targetProfile: JobXTandemCheckOthersProfile;
        },
        private readonly locationService: LocationService,
        private readonly store: Store<{ account }>,
        private readonly dialogRef: MatDialogRef<JobXTandemDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.ownLocations = this.locationService.getLocationsOrCountry(this.data.ownProfile.locations);
        this.targetLocations = this.locationService.getLocationsOrCountry(this.data.targetProfile.locations);
    }

    messageTandem(): void {
        this.store
            .select('account')
            .pipe(take(1))
            .subscribe((account) => {
                this.store.dispatch(
                    DirectMessagesCreateConversationActions.createConversationWithForceLoad({
                        payload: {
                            participants: [
                                {
                                    userId: this.data.targetProfile.user.id,
                                    generalData: {
                                        name: this.data.targetProfile.user.name,
                                        imageUrl: this.data?.targetProfile?.user?.imageUrl || '',
                                    },
                                },
                                {
                                    userId: account.userId,
                                    generalData: {
                                        name: account.generalData.current.data.name,
                                        imageUrl: account.generalData?.current?.data?.imageUrl || '',
                                    },
                                },
                            ],
                            type: ConversationType.PRIVATE,
                            origin: 'ORIGIN_JOB_SHARING',
                        },
                    }),
                );
                this.dialogRef.close();
            });
    }
}
