import { Injectable } from '@angular/core';
import {
    ParticipantsWithSkillsResponse,
    ParticipantsWithSkillsResponseDTO,
    RegistrationWithUserSkills,
    RegistrationWithUserSkillsDTO,
    Workshop,
    WorkshopDTO,
    WorkshopEntityHighlight,
    WorkshopStatus,
} from '../models/workshop.types';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';
import * as moment from 'moment';
import {
    WorkshopSearchResult,
    WorkshopSearchResultDTO,
    WorkshopSearchResultHighlight,
    WorkshopSearchResultHighlightDTO,
} from '../models/workshop-search.types';
import {
    WorkshopMatchingEntry,
    WorkshopMatchingEntryDTO,
    WorkshopMatchingEntryHighlight,
    WorkshopMatchingEntryHighlightDTO,
} from '../models/workshop-matching.types';
import { ConfigService, GeneralData, GeneralDataDTO } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class WorkshopSerializer {
    constructor(private skillsSerializer: SkillsSerializer, private readonly configService: ConfigService) {}

    deserialize(dto: WorkshopDTO): Workshop {
        const status = this.resolveStatus(dto);

        return {
            ...dto,
            requestedSkills: this.skillsSerializer.deserialize(dto.requestedSkills),
            topics: dto.topics?.map((skill) => skill.name) ?? [],
            startDate: dto.startDate ? new Date(dto.startDate) : undefined,
            endDate: dto.endDate ? new Date(dto.endDate) : undefined,
            registrationCloseAt: dto.registrationCloseAt ? new Date(dto.registrationCloseAt) : undefined,
            lastUpdatedAt: dto.lastUpdatedAt ? new Date(dto.lastUpdatedAt) : undefined,
            status: status,
            notifications: dto.notifications,
            highlighted: this.deserializeWorkshopEntityHighlight(dto.highlighted),
            organizers: dto.organizersDecorated,
        };
    }

    private resolveStatus(dto: WorkshopDTO): WorkshopStatus {
        if (!dto.published || moment().isBefore(new Date(dto.startDate))) {
            return WorkshopStatus.PLANNED;
        }
        if (moment().isAfter(new Date(dto.endDate))) {
            return WorkshopStatus.COMPLETE;
        }
        if (moment().isAfter(new Date(dto.startDate))) {
            return WorkshopStatus.RUNNING;
        }
    }

    serialize(workshop: Workshop): WorkshopDTO {
        return {
            ...workshop,
            requestedSkills: this.skillsSerializer.serialize(workshop.requestedSkills),
            topics: this.skillsSerializer.serialize(workshop.topics),
            startDate: workshop.startDate ? workshop.startDate.toISOString() : undefined,
            endDate: workshop.endDate ? workshop.endDate.toISOString() : undefined,
            registrationCloseAt: workshop.registrationCloseAt ? workshop.registrationCloseAt.toISOString() : undefined,
            lastUpdatedAt: null,
            customConditions: 'dummy',
            registrations: workshop.registrations,
            notifications: workshop.notifications,
            imageUrl: workshop.imageUrl.split(this.configService.remoteSettings.domain).pop(),
            organizers: workshop.organizers.map((organizer) => organizer.id),
        };
    }

    deserializeWorkshopSearchResult(dto: WorkshopSearchResultDTO): WorkshopSearchResult {
        dto.entity.organizersDecorated = dto.entity.organizersDecorated ?? dto.organizersDecorated;
        return {
            id: dto.id,
            highlight: this.deserializeWorkshopSearchResultHighlight(dto.highlight),
            entity: this.deserialize(dto.entity),
            organizers: dto.organizersDecorated,
        };
    }

    private deserializeWorkshopSearchResultHighlight(
        dto: WorkshopSearchResultHighlightDTO,
    ): WorkshopSearchResultHighlight {
        if (!dto) {
            return {
                categories: [],
                description: [],
                location: [],
                skillsOptional: [],
                skillsRequired: [],
                topics: [],
                title: [],
            };
        }

        return {
            skillsOptional: dto.skillsOptional,
            skillsRequired: dto.skillsRequired,
            topics: dto.topics ?? [],
            location: dto.location,
            categories: dto.categories,
            title: dto.title,
            description: dto.description,
        };
    }

    deserializeWorkshopMatchingEntry(dto: WorkshopMatchingEntryDTO): WorkshopMatchingEntry {
        return {
            id: dto.id,
            highlight: this.deserializeWorkshopMatchingEntryHighlight(dto.highlight),
            workshop: {
                ...this.deserialize(dto.entity),
                organizers: dto.organizersDecorated,
            },
            organizers: dto.organizersDecorated,
        };
    }

    private deserializeWorkshopMatchingEntryHighlight(
        dto: WorkshopMatchingEntryHighlightDTO,
    ): WorkshopMatchingEntryHighlight {
        if (!dto) {
            return {
                categories: [],
                topics: [],
                mustHaveSkills: [],
                niceToHaveSkills: [],
            };
        }

        return {
            niceToHaveSkills: dto.niceToHaveSkills,
            mustHaveSkills: dto.mustHaveSkills,
            categories: dto.categories,
            topics: dto.topics,
        };
    }

    deserializeParticipantsWithSkillsResponse(dto: ParticipantsWithSkillsResponseDTO): ParticipantsWithSkillsResponse {
        return {
            registrations: dto.registrations.map((registrationDTO) =>
                this.deserializeParticipantWithSkills(registrationDTO),
            ),
            notifications: dto.notifications.map((notificationDTO) =>
                this.deserializeParticipantWithSkills(notificationDTO),
            ),
        };
    }

    deserializeWorkshopUserSearchResult(dto: Partial<GeneralDataDTO>): Partial<GeneralData> {
        return {
            id: dto.id,
            name: dto.name,
            imageUrl: dto.imageUrl,
        };
    }

    private deserializeParticipantWithSkills(dto: RegistrationWithUserSkillsDTO): RegistrationWithUserSkills {
        return {
            ...dto,
            skillsInterestedIn: dto.skillsInterestedIn.map((skillDTO) => skillDTO.name),
            highlightedSkillsInterestedIn: dto.highlightedSkillsInterestedIn.map((skillDTO) => skillDTO.name),
            skills: dto.skills.map((skillDTO) => skillDTO.name),
            highlightedSkills: dto.highlightedSkills.map((skillDTO) => skillDTO.name),
        };
    }

    private deserializeWorkshopEntityHighlight(dto: WorkshopEntityHighlight): WorkshopEntityHighlight {
        if (!dto) {
            return {
                categories: [],
                topics: [],
                mustHaveSkills: [],
                niceToHaveSkills: [],
                mustHaveSkillsWithRelated: [],
                topicsWithRelated: [],
            };
        }

        return {
            categories: dto.categories,
            topics: dto.topics,
            mustHaveSkills: dto.mustHaveSkills,
            niceToHaveSkills: dto.niceToHaveSkills,
            mustHaveSkillsWithRelated: dto.mustHaveSkillsWithRelated,
            topicsWithRelated: dto.topicsWithRelated,
        };
    }
}
