<tp-job-x-account-tile
    [context]="context"
    [user]="user"
    [tags]="tags"
    [selectedTags]="selectedTags"
    [profileMessage]="user.message"
    [locations]="locations"
    [ownProfile]="ownProfile"
    [targetProfile]="jobXBookmark.profile"
    class="job-sharing__account-tile"
>
</tp-job-x-account-tile>
