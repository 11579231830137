import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { withLatestFrom, tap, switchMap, catchError, map } from 'rxjs/operators';
import { AccountState } from '../account.reducer';
import { ExperienceActions } from './experience.actions';
import { ExperienceState } from './experience.reducer';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ExperienceService } from './experience.service';
import { StorageService } from '../../storage/service/storage.service';
import { EXPERIENCE_STORAGE_EDIT_KEY } from './experience.types';

@Injectable({
    providedIn: 'root',
})
export class ExperienceEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ account: AccountState }>,
        private experienceService: ExperienceService,
        private storage: StorageService,
    ) {}

    storeChanges = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ExperienceActions.edit),
                withLatestFrom(this.store.select((state) => state.account.experience)),
                tap(([action, state]) => this.storeEditionState(state)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExperienceActions.save),
            withLatestFrom(this.store.select((state) => state.account.experience.edit.data)),
            switchMap(([action, experienceData]) =>
                this.experienceService.save(experienceData).pipe(
                    map((experienceData) => ExperienceActions.saveSuccess({ experienceData })),
                    catchError((error) => of(ExperienceActions.saveError({ error, experienceData }))),
                ),
            ),
        ),
    );

    deleteSession = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ExperienceActions.abortEdit, ExperienceActions.saveSuccess),
                tap(() => this.deleteEditionState()),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    private storeEditionState(state: ExperienceState): void {
        this.storage.session.set(EXPERIENCE_STORAGE_EDIT_KEY, state.edit);
    }

    private deleteEditionState(): void {
        this.storage.session.remove(EXPERIENCE_STORAGE_EDIT_KEY);
    }
}
