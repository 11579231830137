import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageFormat, ImageType, Media } from './media.types';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {
    MediaResponsePayloadException,
    MediaConversionException,
    MediaTooBigException,
    MediaNotAnImageException,
    MediaForbiddenException,
    MediaNotFoundException,
} from './media.exceptions';

const ENDPOINT = '/api/v2/media';

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    constructor(private http: HttpClient) {}

    uploadImage(image: string, format: ImageFormat, imageType?: ImageType): Observable<string> {
        let data: FormData;
        try {
            const binary = this.convertDataURIToBinary(image);
            data = new FormData();
            data.append('image', new Blob([binary]), 'upload');
        } catch {
            throw new MediaConversionException();
        }

        const params = new HttpParams({ fromObject: { format, imageType } });
        return this.http.post<{ url: string }>(`${ENDPOINT}`, data, { params, observe: 'body' }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 413) {
                    throw new MediaTooBigException();
                } else if (errorResponse.status === 415) {
                    throw new MediaNotAnImageException();
                } else {
                    throw errorResponse;
                }
            }),
            map((body) => {
                if (!body || !body.url) {
                    throw new MediaResponsePayloadException();
                }
                return body.url;
            }),
        );
    }

    get(id: string): Observable<Media> {
        return this.http.get<Media>(`${ENDPOINT}/${id}`).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 404) {
                    throw new MediaNotFoundException();
                } else {
                    throw errorResponse;
                }
            }),
        );
    }

    getAll(imageType: ImageType): Observable<Array<Media>> {
        const params = new HttpParams({
            fromObject: {
                imageType,
            },
        });
        return this.http.get<Array<Media>>(`${ENDPOINT}`, { params }).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                throw errorResponse;
            }),
        );
    }

    delete(id: string): Observable<string> {
        return this.http.delete<string>(`${ENDPOINT}/${id}`).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 403) {
                    throw new MediaForbiddenException();
                } else if (errorResponse.status === 404) {
                    throw new MediaNotFoundException();
                } else {
                    throw errorResponse;
                }
            }),
        );
    }

    private convertDataURIToBinary(dataURI: string): Uint8Array {
        const BASE64_MARKER = ';base64,';
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        const base64 = dataURI.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
}
