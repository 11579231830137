import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsoDataDirective } from './sso-data.directive';

@NgModule({
    declarations: [SsoDataDirective],
    imports: [CommonModule],
    exports: [SsoDataDirective],
})
export class SsoDataModule {}
