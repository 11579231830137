import { Component } from '@angular/core';

@Component({
    selector: 'tp-profile-view-fieldset',
    templateUrl: './profile-view-fieldset.component.html',
    host: {
        class: 'profile-view__fieldset',
    },
})
export class ProfileViewFieldsetComponent {}
