<p class="profile-title-locations">
    <tp-generation-exchange-profile-tile-location-independent
        *ngIf="isLocationIndependent"
        [matched]="highlightLocationIndependent"
    ></tp-generation-exchange-profile-tile-location-independent>

    <mat-icon svgIcon="location_filled"></mat-icon>

    <tp-list-item-tags [tags]="visibleLocations"></tp-list-item-tags>

    <tp-generation-exchange-profile-tile-locations
        *ngIf="showBadge"
        [locations]="extraLocations"
        [badgeText]="badgeText"
    ></tp-generation-exchange-profile-tile-locations>
</p>
