import { combineReducers, createReducer, on } from '@ngrx/store';
import { GenerationExchangeActions } from './generation-exchange.actions';
import {
    GenerationExchangeBookmark,
    GenerationExchangeMatch,
    GenerationExchangeProfile,
    GenerationExchangeSearchResult,
    GenerationExchangeSuggestedSkills,
} from './generation-exchange-service/generation-exchange.types';
import { AccountActions, BookmarkActions, PageParams } from '@tploy-enterprise/tenant-core';

export type GenerationExchangeState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    profile: GenerationExchangeProfileState;
    matches: GenerationExchangeMatchesState;
    bookmarks: GenerationExchangeBookmarksState;
    searchResults: GenerationExchangeSearchState;
    suggestedSkills: GenerationExchangeSkillsState;
};

export type GenerationExchangeSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: GenerationExchangeSuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type GenerationExchangeProfileState = {
    status: StateStatus;
    saved: GenerationExchangeProfile;
    current: GenerationExchangeProfile;
};

export type GenerationExchangeMatchesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    allResults: GenerationExchangeMatch[];
    results: GenerationExchangeMatch[];
    pageParams: PageParams;
    selectedCampaigns: string[];
    length: number;
};

export type GenerationExchangeBookmarksState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    data: GenerationExchangeBookmark[];
    length: number;
};

export type GenerationExchangeSearchState = {
    loaded: boolean;
    loading: boolean;
    error: Error;
    allResults: GenerationExchangeSearchResult[];
    results: GenerationExchangeSearchResult[];
    pageParams: PageParams;
    searchQuery: string;
};

export const GenerationExchangeProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(GenerationExchangeActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(GenerationExchangeActions.loadProfileSuccess, (state) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: null,
    })),
    on(GenerationExchangeActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(GenerationExchangeActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(GenerationExchangeActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(GenerationExchangeActions.saveProfileError, (state, { error }) => ({
        ...state,
        saving: false,
        savingError: error,
    })),
    on(GenerationExchangeActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
);

export const GenerationExchangeProfileSavedReducer = createReducer<GenerationExchangeProfile>(
    {
        campaigns: [],
        companyLocations: [],
        locationIndependent: false,
        skills: [],
        generation: null,
        motivations: [],
    },
    on(GenerationExchangeActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(GenerationExchangeActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const GenerationExchangeProfileCurrentReducer = createReducer<GenerationExchangeProfile>(
    {
        campaigns: [],
        companyLocations: [],
        locationIndependent: false,
        skills: [],
        generation: null,
        motivations: [],
    },
    on(GenerationExchangeActions.editCurrentProfile, (state, { currentProfile }) => ({ ...state, ...currentProfile })),
    on(GenerationExchangeActions.loadProfileSuccess, (state, { profile, session }) => session || profile),
    on(GenerationExchangeActions.loadProfileError, (state, { session }) => session || state),
    on(GenerationExchangeActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        skills: state.skills.length > 0 ? state.skills : account.generalData.externalSkills ?? [],
    })),
);

export const GenerationExchangeProfileReducer = combineReducers<GenerationExchangeProfileState>({
    status: GenerationExchangeProfileStatusReducer,
    saved: GenerationExchangeProfileSavedReducer,
    current: GenerationExchangeProfileCurrentReducer,
});
const generationExchangeMatchInitialState: GenerationExchangeMatchesState = {
    loaded: false,
    loading: false,
    loadingError: null,
    allResults: [],
    results: [],
    pageParams: { pageIndex: 0, pageSize: 0 },
    selectedCampaigns: [],
    length: 0,
};
const GenerationExchangeMatchesReducer = createReducer(
    generationExchangeMatchInitialState,
    on(GenerationExchangeActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(
        GenerationExchangeActions.loadMatchesSuccess,
        (state, { results, allResults, length, pageParams, selectedCampaigns }) => {
            return {
                ...state,
                loading: false,
                loaded: true,
                results: [...results],
                allResults: [...allResults],
                pageParams,
                selectedCampaigns,
                length,
            };
        },
    ),
    on(GenerationExchangeActions.loadMatchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(GenerationExchangeActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const GenerationExchangeBookmarksReducer = createReducer(
    { loaded: false, loading: false, data: [], length: 0, error: null },
    on(GenerationExchangeActions.loadBookmarks, (state) => {
        return { ...state, loading: true };
    }),
    on(GenerationExchangeActions.loadBookmarksSuccess, (state, { bookmarks, length }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: [...bookmarks],
            length,
        };
    }),
    on(GenerationExchangeActions.loadBookmarksError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
    on(BookmarkActions.addSuccess, (state) => ({ ...state, loaded: false })),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => ({
        ...state,
        data: state.data.filter((existingBookmark) => {
            return existingBookmark.id !== bookmark.id;
        }),
        loaded: false,
    })),
);

export const generationExchangeSearchInitialState: GenerationExchangeSearchState = {
    loaded: false,
    loading: false,
    error: null,
    allResults: [],
    results: [],
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    searchQuery: '',
};
export const GenerationExchangeSearchReducer = createReducer(
    generationExchangeSearchInitialState,
    on(GenerationExchangeActions.search, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(GenerationExchangeActions.searchSuccess, (state, { results, allResults, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...results],
            allResults: [...allResults],
            pageParams,
        };
    }),
    on(GenerationExchangeActions.loadSearchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(GenerationExchangeActions.searchError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const GenerationExchangeSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(GenerationExchangeActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(GenerationExchangeActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(GenerationExchangeActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

export const GenerationExchangeReducer = combineReducers({
    profile: GenerationExchangeProfileReducer,
    searchResults: GenerationExchangeSearchReducer,
    bookmarks: GenerationExchangeBookmarksReducer,
    matches: GenerationExchangeMatchesReducer,
    suggestedSkills: GenerationExchangeSuggestedSkillsReducer,
});
