import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { UnknownRouteService } from './unknown-route.service';

@Injectable({
    providedIn: 'root',
})
export class UnknownRouteGuard implements CanActivate {
    constructor(private readonly unknownRouteService: UnknownRouteService, private readonly router: Router) {}

    canActivate(): Observable<boolean | UrlTree> {
        if (this.isRouteHandle404(this.router.url)) {
            this.unknownRouteService.handle404();
        }
        return EMPTY;
    }

    private isRouteHandle404(requestUrl: string) {
        const exclude = ['sign-up', 'login'];
        return !exclude.some((urlSegment) => requestUrl.includes(urlSegment));
    }
}
