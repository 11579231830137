import { Component, Injector, Input } from '@angular/core';
import { AccountListItemComponent } from '@tploy-enterprise/tenant-core';
import { JobXTandemCheckOthersProfile, JobXTandemCheckProfile } from '../../jobs.types';
import { MatDialog } from '@angular/material/dialog';
import { JobXTandemDialogComponent } from './job-x-tandem-dialog/job-x-tandem-dialog.component';

@Component({
    selector: 'tp-job-x-account-tile',
    templateUrl: './job-x-account-tile.component.html',
    styleUrls: ['./job-x-account-tile.component.scss'],
})
export class JobXAccountTileComponent extends AccountListItemComponent {
    @Input() locations: Array<string> = [];
    @Input() selectedCampaigns: string[];
    @Input() ownProfile: JobXTandemCheckProfile;
    @Input() targetProfile: JobXTandemCheckOthersProfile;

    get campaigns(): Array<string> {
        return this.targetProfile.campaigns;
    }

    get targetCheckProfile(): JobXTandemCheckProfile {
        return {
            ...this.targetProfile,
            user: this.user as any,
        };
    }

    constructor(protected readonly injector: Injector, private dialog: MatDialog) {
        super(injector);
    }

    openTandemDialog(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.dialog.open(JobXTandemDialogComponent, {
            data: {
                ownProfile: this.ownProfile,
                targetProfile: this.targetProfile,
            },
            minWidth: '60vw',
            maxWidth: '80vw',
            panelClass: 'job-x-tandem-check-dialog',
        });
    }
}
