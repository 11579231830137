<tp-important-snack>
    <div data>
        {{ data | translatable }}
    </div>
    <div action>
        <tp-primary-button class="snack-close" (click)="close.emit()">
            {{ 'OK' | translatable }}
        </tp-primary-button>
    </div>
</tp-important-snack>
