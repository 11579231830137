export default {
    SIGN_UP_REGISTER_TEASER: 'The matching software for new work and a networked organization.',
    SIGN_UP_REGISTER_WELCOME_TITLE: 'Register now!',
    SIGN_UP_REGISTER_WELCOME_TEXT:
        '{{ appName }} is your space for flexible forms of work, such as job sharing or job rotation, new collaborative projects, and knowledge sharing with colleagues, experts, and mentors.',
    SIGN_UP_REGISTER_EMAIL_LABEL: 'E-mail',
    SIGN_UP_REGISTER_EMAIL_FAILED: 'Please enter a valid e-mail address',
    SIGN_UP_REGISTER_EMAIL_INFO_TEXT: 'SIGN_UP_REGISTER_EMAIL_INFO_TEXT',
    SIGN_UP_REGISTER_EMAIL_INFO_TITLE: 'Valid e-mail domains:',
    SIGN_UP_REGISTER_PASSWORD_LABEL: 'Your password',
    SIGN_UP_REGISTER_PASSWORD_FAILED: 'The password must be at least {{ length }} characters long.',
    SIGN_UP_REGISTER_PASSWORD_INFO_TITLE: 'Password rules',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_MIN_LENGTH: 'a total of at least {{ length }} characters',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_DIGITS: 'at least two numbers',
    PASSWORD_TWO_NUMBERS: 'at least two numbers',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_SPECIAL_CHARS: 'at least two special characters',
    PASSWORD_TWO_SPECIAL_CHAR: 'at least two special characters',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_DIGITS_AND_SPECIAL_CHARS: 'At least two digits and two special characters',
    SIGN_UP_REGISTER_LOGIN_TEXT: 'Already have an account?',
    SIGN_UP_REGISTER_LOGIN_LINK: 'Log in here!',
    SIGN_UP_REGISTER_BUTTON: 'Register',
    SIGN_UP_ACCEPT_TOU_CHECKBOX_LABEL: 'I accept the terms of use',
    SIGN_UP_ACCEPT_DP_CHECKBOX_LABEL: 'I accept the data privacy agreement',

    SIGN_UP_LOGIN_TEASER: 'The matching software for new work and a networked organization.',
    SIGN_UP_LOGIN_WELCOME_TITLE: 'Welcome back!',
    SIGN_UP_LOGIN_WELCOME_TEXT:
        '{{ appName }} is your space for flexible forms of work, such as job sharing or job rotation, new collaborative projects, and knowledge sharing with colleagues, experts, and mentors.',
    SIGN_UP_LOGIN_EMAIL_LABEL: 'E-mail',
    SIGN_UP_LOGIN_EMAIL_FAILED: 'Please enter a valid e-mail address.',
    SIGN_UP_LOGIN_PASSWORD_LABEL: 'Password',
    SIGN_UP_LOGIN_PASSWORD_FAILED: 'The password must be at least {{ length }} characters long.',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_MIN_LENGTH: 'a total of at least {{ length }} characters',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_DIGITS: 'at least two numbers',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_SPECIAL_CHARS: 'at least two special characters',
    SIGN_UP_LOGIN_RESET_PASSWORD: 'Reset password',
    SIGN_UP_LOGIN_REGISTER_LEGACY_TEXT: "Don't already have an account?",
    SIGN_UP_LOGIN_REGISTER_LEGACY_LINK: 'Register here.',
    SIGN_UP_LOGIN_REGISTER_SSO_TEXT: 'SIGN_UP_LOGIN_REGISTER_SSO_TEXT',
    SIGN_UP_LOGIN_REGISTER_SSO_LINK: 'SIGN_UP_LOGIN_REGISTER_SSO_LINK',

    GUEST_TEASER: 'The matching software for New Work and a networked organization.',
    GUEST_TEASER_PARAGRAPH: '',
    SIGN_UP_REGISTER_SSO_WELCOME_TITLE: 'Welcome to Tandemploy!',
    SIGN_UP_REGISTER_SSO_WELCOME_TEXT:
        'This is your space to work in a more networked and flexible way. Find colleagues and experts from other areas for knowledge exchange, sparring partners for flexible forms of work and great projects for cooperation. Let’s get started! Your colleagues are looking forward to working with you!',
    SIGN_UP_REGISTER_SSO_BUTTON: 'Log in or sign up',

    SIGN_UP_PRIVACY_TITLE: 'Privacy Policy',
    SIGN_UP_PRIVACY_LABEL: 'I accept the privacy policy',
    SIGN_UP_PRIVACY_BUTTON: 'Confirm',

    SIGN_UP_TERMS_TITLE: 'Terms',
    SIGN_UP_TERMS_LABEL: 'I accept the terms and conditions.',
    SIGN_UP_TERMS_BUTTON: 'Confirm',

    SIGN_UP_EMAIL_CONFIRMATION_TITLE: 'Please check your mailbox.',
    SIGN_UP_EMAIL_CONFIRMATION_TEXT_1: 'Thank you for registering with {{ appName }}.',
    SIGN_UP_EMAIL_CONFIRMATION_TEXT_2:
        'In order to finish the registration please click on the link we just sent you via e-mail.',
    SIGN_UP_EMAIL_CONFIRMATION_BUTTON: 'OK',

    PASSWORD_SWITCH_SAML_TEXT: "You are  aren't n external user?",
    PASSWORD_SWITCH_SAML_LINK: 'Log in here',
    PASSWORD_SWITCH_LOGIN_TEXT: 'Back to login page',
    PASSWORD_SWITCH_LOGIN_LINK: 'Click here',
    LOGIN_SWITCH_SAML_TEXT: 'Did you receive an invitation to an external e-mail address?',
    LOGIN_SWITCH_SAML_LINK: 'Log in here',
    REGISTER_SWITCH_SAML_TEXT: 'Did you receive an invitation to an external e-mail address?',
    REGISTER_SWITCH_SAML_LINK: 'Log in here',

    DESCRIPTION: 'Description',
    INTRODUCTION: 'Watch videos',
    INTRODUCTION_FOOTER: 'Preview',
    VIDEO_PREV_BTN_ARIA_LABEL: 'Back to the previous video {{videoTitle}}',
    VIDEO_NEXT_BTN_ARIA_LABEL: 'Go to the next video {{videoTitle}}',
    JOBSHARING: 'Job sharing',
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days',
    YEAR: 'year',
    YEARS: 'years',
    MONTH: 'month',
    MONTHS: 'months',
    WEEK: 'week(s)',
    WELCOME: 'Hello {{name}}!',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    LOGOUT_LINK: '',
    LOGOUT_SUCCESS: 'You have been successfully logged out.',
    LOGIN_AGAIN: 'Login again',
    LOGO: 'Logo',
    EMAIL: 'E-mail',
    REPEAT_PASSWORD: 'Repeat password',
    NEW_PASSWORD_1: 'New password',
    NEW_REPEAT_PASSWORD: 'Repeat new password',
    USERNAME: 'Username',
    HOME: 'About {{ appName }}',
    HOME_ARIA_LABEL: 'Information about the platform {{ appName }}',
    HOME_MOBILE: 'About',
    COMPANY_JOBSHARER: 'Job sharer pool',
    DASHBOARD: 'My {{ appName }}',
    DASHBOARD_HOME_TITLE: 'My {{ appName }}',
    JOBS: 'Offers',
    FORUM: 'Forum',
    ADMIN_PANEL: 'Administration',
    ADMIN_STATS: 'Statistics',
    ADMIN_ROLES: 'Platform Administration',
    COMPANY_JOBS: 'Job board',
    COMPANY_INFO: 'Products & info',
    JOBSHARERS: 'My colleagues',
    EDIT_PROFILE: 'Edit profile',
    JOBSHARERS_MATCHING_SEARCH_LABEL: 'Search for other colleagues by keyword: ',
    JOB_BOARD: 'Job Board',
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
    BOOKMARKS: 'Bookmarks',

    COMPANIES: 'Find company',
    COMPANY_PROFILES: 'Company profiles',
    ABOUT_FAQ: 'About, FAQ',
    INFORMATION: 'Information',
    COMPANY_COMPANIES: 'Company profiles',
    NEED_HELP: 'Need help?',
    PROFILE: 'My profile',
    NEWS: 'Dashboard',
    WORKING_MODELS: 'About the modules',
    WORKING_MODELS_ARIA_LABEL: 'Information about the offered working models',
    WORKING_MODELS_MOBILE: 'Working Models',
    PROFILE_SHORT: 'Profile',
    TANDEMS: 'My tandems',
    BLOG: 'Blog',
    PRESSE: 'Press',
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_DE: 'German',
    PREVIEW: 'Preview',
    UPDATE_PICTURE: 'Upload picture',
    UPDATE_PROFILE: 'Profile',
    CREATE_PROFILE: 'Create profile',
    CREATE_JOB: 'Create job',
    SETTINGS: 'Settings',
    REMEMBER_ME: 'Keep me signed in',
    UPDATE: 'Update',
    DONE: 'Done',
    CREATE: 'Create',
    NEXT: 'Next',
    BACK: 'Back',
    FIRST: 'First',
    LAST: 'Last',
    BACK_NAV: 'Back',
    SAVE: 'Save',
    OK: 'OK',
    FINISH_AND_GO_ON: 'Save and continue',
    ACCEPT: 'Accept',
    CLOSE: 'Close',
    CANCEL_QUESTIONAIRE: 'Cancel and complete matching questionnaire later',
    SEND: 'Send',
    CONTINUE: 'Next',
    JUMP: 'Skip',
    ADD: 'Add',
    IN: 'in',
    MORE: 'more ...',
    CANCEL: 'Cancel',
    CANCEL_CAP: 'Cancel',
    OK_DELETE_MY_INPUT: 'Ok, delete my input',
    CONTINUE_EDITING: 'Continue editing',
    HEADLINE_DIALOG_CONFIRM_EDIT_DATA: 'Your input will be lost',
    IF_LOSE_DIALOG_CONFIRM_EDIT_DATA: 'If you close the window without saving, your new input will be lost.',
    CLICK_FOR_CONTINUE_DIALOG_CONFIRM_EDIT_DATA:
        'Click on "continue editing" if you want to keep your new information.',
    DELETE: 'Delete',
    DELETE_IRREVERSIBLY: 'Delete irreversibly',
    CONFIRM: 'Confirm',
    UNSTAR: 'Remove',
    CREATE_PROFILE_NAV: 'Create profile',
    CREATE_PROFILE_NAV_TODO: 'Not yet registered?',
    UPDATE_PROFILE_SOFT: 'Questionnaire',
    SAVE_PROFILE_SOFT: 'Save questionnaire',
    UPLOAD_OTHER_IMAGE: 'Click on the image to change your profile picture.',
    UPDATE_PROFILE_HARD_NEW: 'Create profile',
    UPDATE_PROFILE_SOFT_NEW: 'Answer questionnaire',
    UPDATE_HARD_FACTS: 'Update profile',
    UNI_OLD_TEXT: 'Founder award of',
    ECO_TEXT: '1. place idea contest',
    PRICES_HEADER: 'Prizes',
    ATTR_PUBLIC: '{{ attr }} is shown',
    ATTR_PRIVATE: '{{ attr }} is hidden',
    LAST_NAME_PUBLIC: 'Hide last name',
    LAST_NAME_PRIVATE: 'Show last name',
    SIGNED_IN_HEADER: 'You need to be signed in!',
    JOB_LABEL: 'Offers',
    POOL_LABEL: 'User pool',
    USER_POOL_HEADER: 'All Users',
    INVITE: 'Invite',
    PAGE: 'Page',
    SAVE_AND_SYNC_SF: 'Save & Sync to SuccessFactors',
    APPLY_NOW: 'Apply now!',

    HIDE_SUGGESTED_SKILL: 'Do not suggest again',

    REQUEST_NEW_PASSWORD: 'Reset password',
    FOR_RESET_EMAIL_REQUIRED:
        'To reset your password, please enter your e-mail address above and click on <<reset password>> again!',
    FOR_RESET_EMAIL_NOT_KNOWN: "Unfortunately, we don't recognize your e-mail address. Please try again.",
    FOR_RESET_EMAIL_RECEIVED: 'You will shortly receive a link to reset your password.',
    NEW_PASSWORD: 'Your new password',

    JOBSHARER_HELP_INFO: 'Please contact us if you have any questions or feedback!',

    HELP_AND_LEGAL_INFORMATION: 'Help, legal, privacy',
    HELP_AND_LEGAL_INFORMATION_BTN_ARIA_LABEL: 'Open navigation for help and legal information',
    HELP_CONTACT: 'Help and contact',
    HELP_CONTACT_DIALOG_HEADER: 'Help and contact',
    HELP_CONTACT_EMAIL_DIALOG_TEXT: 'If you have technical problems or need assistance, please contact us by e-mail',
    HELP_CONTACT_HELP_DESK_DIALOG_TEXT: 'For questions and technical problems please contact:',

    HELP_CONTACT_EMAIL_LINK: 'Support request',
    HELP_CONTACT_FEEDBACK_CAPTION: 'Feedback',
    LEGAL: 'Legal notice',
    ACCOUNT_DELETE: 'Delete Account',
    T_C: 'Terms and conditions',
    PRIVACY: 'Privacy',
    LEGAL_1: 'legal',
    FAQ: 'FAQ',
    FAQ_ARIA_LABEL: 'FAQ',
    FAQ_MOBILE: 'FAQ',
    FAQ_FOOTER: 'FAQ',
    PARTNER: 'our partners',
    ZEITSCHENKER: 'time donors',
    KNOWN_FROM: 'known from',
    NEWSLETTER: 'newsletter',
    NEWSLETTER_FOOTER: 'newsletter',
    HOME_FOOTER: 'home',

    JOBSHARER_DASHBOARD_TITLE: 'Welcome to {{ appName }}!',
    JOBSHARER_DASHBOARD_NEWS_TITLE: 'News',
    JOBSHARER_DASHBOARD_HAPPENINGS_TITLE: "What's new on {{ appName }}?",
    JOBSHARER_DASHBOARD_MESSAGES_TITLE: 'New messages',
    JOBSHARER_DASHBOARD_BLOG_POST: " - On Tandemploy's blog",
    JOBSHARER_DASHBOARD_RETWEETED: '{{ appName }} retweeted',
    JOBSHARER_DASHBOARD_NEWS: 'News on {{ appName }}',
    JOBSHARER_DASHBOARD_TANDEMPLOY_TWITTER: '@tandemploy',

    INFO_PROFILE_BUTTON: 'Your profile',
    INFO_PROFILE_BUTTON_ARIA_LABEL: 'To your profile',

    CONTACT_PROFILE_BUTTON: 'Make contact',
    PROFILE_BUTTON_GET_TO_KNOW: 'Invite for coffee',

    INFO_TEXT_GENDER: '',

    SEARCH_JOB_ADMIN_TITLE: 'Create and manage offers',
    SEARCH_JOB_TITLE: 'Offers and fields of work',
    JOB_SEARCH_SUB_TITLE_TEXT: '',
    SEARCH_JOBS_SECOND_TITLE:
        'Here you can find job offers explicitly open to potential job sharers! Not the job you are looking for? Many tandem partners already have great jobs they would like to share! Just create a profile and get in touch!',
    SEARCH_JOB_COMPANY_TITLE: 'JOB BOARD',
    SEARCH_JOBS_COMPANY_SECOND_TITLE:
        'Many job sharing pioneers already advertise their vacant positions on our job board!',
    SEARCH_JOBS_PLACEHOLDER: 'Type in search keys, e.g. "Berlin", "marketing"...',

    JOB_SEARCH_SUGGESTIONS_TITLE: 'You might be interested in these jobs:',
    JOB_SEARCH_SUB_TITLE: 'All {{category}} offers on our job board:',

    JOB_SEARCH_SUB_TITLE_ALL: 'Find interesting fields of work and offers from colleagues and HR!',
    JOB_SEARCH_SUB_TITLE_ADMIN: 'Choose between HR and user created offers:',
    JOB_SEARCH_SUB_TITLE_PROJECT: 'All project offers on our job board:',
    JOB_SEARCH_SUB_TITLE_JOBROTATION: 'All job rotation offers on our job board:',
    JOB_SEARCH_SUB_TITLE_JOBSHARING: 'All job sharing job offers on our job board:',
    JOB_SEARCH_FILTER_HR: 'HR offers',
    JOB_SEARCH_FILTER_USER: 'User generated offers',

    JOB_HR_APPROVED_TOOLTIP: 'This offer was posted by the HR department.',

    ADMIN_JOBS_HEADER: 'Edit offers',

    JOB_CREATE_TITLE_JOBSHARING: 'Describe my current area of work for potential tandem partners',

    JOB_CREATE_TITLE_JOBROTATION: 'Describe your current job and a potential job rotation (swap) here',
    JOB_CREATE_TITLE_PROJECT: 'Describe the project you wish to find team members for.',

    JOB_ADMIN_CREATE_TITLE_PROJECT: 'Post project: Describe the project to find team members.',
    JOB_ADMIN_CREATE_TITLE_JOBSHARING: 'Post jobshare: Describe the job you are trying to find a tandem for.',
    JOB_ADMIN_CREATE_TITLE_JOBROTATION:
        'Post job rotation: Describe the job you would like to post as a job rotation (one-directional, no swap)',
    JOB_ADMIN_CREATE_TITLE: 'Publish job',
    JOB_UPDATE_TITLE: 'Edit your job',
    JOB_ADMIN_UPDATE_TITLE: 'Edit job',
    CREATE_NEW_JOB: 'New offer',
    CREATE_NEW_JOB_INFO: 'Here you can post your job to share or swap it and publish projects to find team members.',
    CREATE_NEW_JOB_INFO_ADMIN:
        'Here you can post new offers, publish or pause them and, if applicable, assign them to other users.',
    ADMIN_CREATE_NEW_JOB: 'New offer',
    ADMIN_CREATE_NEW_JOB_INFO:
        'Here you can post new offers, publish or pause them and, if applicable, assign them to other users.',

    JOB_CITY: 'City:',
    JOBROTATION_INTERESTED_CREATE_TAGS: 'Which job are you looking for? (keywords)',
    JOBROTATION_INTERESTED_CREATE_TAGS_PLACEHOLDER_TEXT: 'e.g. Controlling',
    JOBROTATION_INTERESTED_CATEGORIES_SUBTITLE:
        'Enter the most relevant keywords here and separate the words by pressing "Enter". The more tags you enter, the better the matching will be.',
    JOBROTATION_OFFERING_CREATE_TAGS_PLACEHOLDER_TEXT: 'e.g. Controlling',
    JOBROTATION_OFFERING_CREATE_TAGS: 'Which skills or job title are you offering for this swap? (keywords)',
    JOBROTATION_OFFERING_CREATE_TAGS_PLACEHOLDER_TEXT_ADMIN: 'Keywords about the job, e.g. "marketing", "IT"',
    JOBROTATION_OFFERING_CATEGORIES_SUBTITLE:
        'Enter the most relevant keywords here and separate the words by pressing "Enter". The more tags you enter, the better the matching will be.',

    JOB_TITLE_SUBTITLE:
        "Choose a generic job title such as 'leading position in marketing' if you wish the exact job title to remain anonymous.",
    JOB_TITLE_SUBTITLE_ADMIN: 'Choose a job title that describes this position as precisely as possible.',
    JOB_START_DATE_SUBTITLE: 'Specify the earliest start date here. This info does not have to be set in stone.',

    JOB_WORKING_HOURS_SUBTITLE: 'Preferred weekly working hours of tandem partner',
    JOB_WORKING_HOURS_SUBTITLE_ADMIN:
        'Specifiy the estimated overall scope of the job. It can have more (or less) hours than the typical full-time job. Have the courage to think in real tasks and capacities. Is the job manageable in 60 or 70 hour per week, or rather in 30?',
    JOB_DESCRIPTION_SUBTITLE:
        'You would like to paste an existing job description here? Then please copy and paste from the word format DOCX. In case you have any problems with this, please send us an e-mail to support@tandemploy.com.',
    JOB_DESCRIPTION_PLACEHOLDER:
        "Describe your job in your own words. What's special about it? What could be tasks for your tandem partner?...",
    JOB_DESCRIPTION_PLACEHOLDER_ADMIN:
        'Describe the position for which you are looking for a tandem. Which tasks should be taken over? What are the requirements of the position?',
    JOB_DESCRIPTION_SUBTITLE_ADMIN:
        'If available you can paste the "normal" job description of a full-time job here or describe the job in your own words.',
    JOB_CONTACT_INFO_ADMIN:
        'To assign this job offer to a specific person, please insert the user name of a registered employee.',
    JOBSHARING_CREATE_TAGS_PLACEHOLDER_TEXT: 'Keywords *',
    JOB_DURATION: 'Duration:',
    JOBROTATION_DURATION_PLACEHOLDER: 'e.g. 8',
    JOB_DURATION_IN_HOURS: 'Weekly hours tandem partner:',
    JOB_DURATION_IN_HOURS_ADMIN: 'Hours per week in total:',
    JOB_START_DATE: 'Earliest start date:',
    JOB_START_DATE_PLACEHOLDER: 'Select a date',
    JOB_TANDEM_TYPE: 'Sought-after:',
    FINISH_AND_PUBLISH: 'Save and publish',
    JOB_DEFAULT_DESCRIPTION:
        "Dear colleague,\n how good you have found this offer which can also mean that we have similar interests. Considering a detailed description is still missing, how about getting in touch already? This way, I could just tell you more about it and answer your questions.\n Using the button on this page you can contact me easily and I'll be notified directly. I look forward to hearing from you.",

    JOBROTATION_DURATION: 'Preferred duration',
    JOBROTATION_DURATION_SUBTITLE:
        "This info is supposed to give your colleagues an initial idea. It's not set in stone.",
    JOBROTATION_DESCRIPTION_INFO:
        'You would like to paste an existing job description here? Then please copy and paste from the word format DOCX. In case you have any problems with this, please send us an e-mail to support@tandemploy.com.',
    JOBROTATION_DESCRIPTION_INFO_ADMIN:
        'You would like to paste an existing job description here? Then please copy and paste from the word format DOCX. In case you have any problems with this, please send us an e-mail to support@tandemploy.com.',
    JOBROTATION_TITLE: 'Current job title',
    JOBROTATION_CITY: 'Current location',

    JOB_LOCATION: 'Location:',
    JOB_DEPARTMENT_PLACEHOLDER: 'Your department',
    JOB_DEPARTMENT: 'Department',

    DELETE_PROJECT_HEADER: 'Delete project',
    DELETE_PROJECT_TEXT: 'Do you really want to delete the project: "{{ title }}"?',
    DELETE_JOB_HEADER: 'Delete job',
    DELETE_JOB_TEXT: 'Do you really want to delete the job "{{ title }}"?',

    ALL_JOBS: 'All offers',
    FLEXIBLE_JOBS: 'Flexible',
    PROJECT: 'Projects',
    TANDEM: 'Tandem',
    SINGLE_JOBSHARER: 'Job sharer',
    JOBROTATION: 'Job rotation',
    MENTORING: 'Mentoring',
    REVERSE_MENTORING: 'Reverse mentoring',
    FLEXIBLE: 'Networking and knowledge transfer',
    CROSS_FUNCTIONAL: 'Cross-functional',
    SHORT_TIME: 'Short Assignment',
    NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    NETWORKING: 'Networking',

    JOB_HEADER_ALL: 'New Work offer',
    JOB_HEADER_PROJECT: 'project',
    JOB_HEADER_TANDEM: 'tandem',
    JOB_HEADER_JOBROTATION: 'job rotation',
    JOB_HEADER_JOBSHARING: 'job sharing job',
    JOB_HEADER_SINGLE_JOBSHARER: 'job sharer job',
    JOB_HEADER_CROSS_FUNCTIONAL: 'cross-functional job',
    JOB_HEADER_SHORT_TIME: 'short assignment',

    JOB_SUB_HEADER_FLEXIBLE_JOBS: 'New Work offers',
    JOB_SUB_HEADER_PROJECT: 'project',
    JOB_SUB_HEADER_TANDEM: 'Tandem jobs',
    JOB_SUB_HEADER_JOBROTATION: 'job rotation',
    JOB_SUB_HEADER_JOBSHARING: 'job sharing job',
    JOB_SUB_HEADER_SINGLE_JOBSHARER: 'job sharer job',
    JOB_SUB_HEADER_CROSS_FUNCTIONAL: 'cross-functional job',
    JOB_SUB_HEADER_SHORT_TIME: 'short assignment',

    JOB_START_PROJECT: 'Project',
    JOB_START_JOBROTATION: 'Job rotation',
    JOB_START_JOBSHARING: 'Job sharing',
    JOB_START_MENTORING: 'Mentoring',
    JOB_START_NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    JOB_START_NETWORKING: 'Networking',

    IMAGE_ALT_JOBSHARING: '',

    JOB_TAG_PROJECT: 'Projects',
    JOB_TAG_SINGLE_JOBSHARER: 'Tandem partner',
    JOB_TAG_JOBROTATION: 'Job rotation',
    JOB_TAG_JOBSHARING: 'Job sharing',
    JOB_TAG_NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    JOB_TAG_TANDEM: 'Tandem',
    JOB_TAG_JOB: 'Job',
    JOB_TAG_EXPERT: 'Expert',
    JOB_TAG_MENTORING: 'Mentoring',
    JOB_TAG_INTERESTED: 'Exchange with colleagues',
    JOB_TAG_MALE: 'male',
    JOB_TAG_FEMALE: 'female',
    JOB_TAG_OTHER: ' specified',
    JOBROTATION_OFFERING_INFO:
        'If you want to give more details about your offer, you can publish it as a job. In that case, please use the button "Offer New Work Opportunity" in your profile view.',

    PROFILE_TAG_PROJECT: 'Projects',
    PROFILE_TAG_FLEXIBLE: 'Networking and knowledge transfer',
    PROFILE_TAG_INTERESTED: 'Exchange with colleagues',
    PROFILE_TAG_JOBSHARER: 'Job sharer',
    PROFILE_TAG_JOBROTATION: 'Job rotation',
    PROFILE_TAG_MENTORING: 'Mentoring',
    PROFILE_TAG_MENTORING_INTERESTED: 'Mentoring',
    PROFILE_TAG_REVERSE_MENTORING_INTERESTED_TAG: 'Looking for Mentor',
    PROFILE_TAG_REVERSE_MENTORING_OFFERING_TAG: 'Offer mentoring',
    PROFILE_TAG_JOBSHARING: 'Job sharing',
    PROFILE_TAG_JOBSHARING_INTERESTED: 'Job sharing',
    PROFILE_TAG_CROSS_FUNCTIONAL: 'Cross-functional',
    PROFILE_TAG_SHORT_TIME: 'Short Assignment',
    PROFILE_TAG_TANDEM: 'Tandem partner',
    PROFILE_TAG_TANDEMPARTNER: 'Tandem partner',
    PROFILE_TAG_JOB: 'Job',
    PROFILE_TAG_MENTOR: 'Mentor',
    PROFILE_TAG_MENTEE: 'Mentee',
    PROFILE_TAG_JOBROTATION_INTERESTED: 'Job rotation',
    PROFILE_TAG_JOBROTATION_OFFERING: 'Job rotation',
    PROFILE_TAG_EXPERT: 'Experts',

    PROFILE_TAG_NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    PROFILE_TAG_NETWORKING_SKILLS: 'Specialist Exchange',
    PROFILE_TAG_NETWORKING_LOCATION: 'Location-related exchange',
    PROFILE_TAG_NETWORKING_INTERESTS: 'Networking by personal interests',
    PROFILE_TAG_EXPERTISE: 'Find experts',
    PROFILE_TAG_EXPATS: 'Expats and working abroad',
    PROFILE_TAG_INTERNSHIP: 'Trainee, intern, apprentice',
    PROFILE_TAG_REVERSE_MENTORING: 'Reverse Mentoring',
    PROFILE_TAG_ONBOARDING: 'Onboarding and settling in',
    PROFILE_TAG_PARENTAL_LEAVE: 'Parental leave',
    PROFILE_TAG_PEER_LEARNING: 'Peer Learning',
    PROFILE_TAG_RETRAINING: 'Retraining',
    PROFILE_TAG_SABBATICAL: 'Sabbatical',
    PROFILE_TAG_TRAINING: 'Advanced training',

    PROFILE_TAG_KNOWLEDGE_TRANSFER_KNOWLEDGE_TRANSFER: 'Knowledge transfer',
    PROFILE_TAG_ONBOARDING_OFFERED: 'Onboarding',
    PROFILE_TAG_ONBOARDING_NEEDED: 'Onboarding',
    PROFILE_TAG_CONNECT_WITH_YOUNG: 'Cross-generational exchange',
    PROFILE_TAG_CONNECT_WITH_EXPERIENCED: 'Cross-generational exchange',
    PROFILE_TAG_INTERDISCIPLINARY_INTERDISCIPLINARY: 'Interdisciplinary exchange',
    PROFILE_TAG_PERSONAL_DEV_ADVICE_RECEIVE: 'Personal development',
    PROFILE_TAG_PERSONAL_DEV_ADVICE_PROVIDE: 'Personal development',
    PROFILE_TAG_INTERCULTURAL_RELOCATION_NEW: 'Intercultural exchange',
    PROFILE_TAG_INTERCULTURAL_RELOCATION_EXPERIENCE: 'Intercultural exchange',

    KNOWLEDGE_TRANSFER: 'Knowledge transfer',
    KNOWLEDGE_TRANSFER_KNOWLEDGE_TRANSFER:
        "I'd like to exchange experiences and share knowledge with people in the same field of expertise.",
    ONBOARDING: 'Onboarding',
    ONBOARDING_NEEDED: 'A personal contact to help me feel at home in the company would be great.',
    ONBOARDING_OFFERED: 'I know this company like the back of my hand and am happy to support newcomers.',
    CONNECT_WITH: 'Cross-generational exchange',
    CONNECT_WITH_EXPERIENCED:
        'Young and old can learn a lot from each other. I would like to connect with young employees.',
    CONNECT_WITH_YOUNG:
        'Young and old can learn a lot from each other. I would like to connect with more experienced colleagues.',
    INTERDISCIPLINARY: 'Interdisciplinary exchange',
    INTERDISCIPLINARY_INTERDISCIPLINARY:
        "I like to think outside the box and would love to gain insights into other departments' daily work.",
    PERSONAL_DEV_ADVICE: 'Personal development',
    PERSONAL_DEV_ADVICE_RECEIVE:
        'Feedback and self-reflection are important for my personal and professional development. It would be nice to have a personal contact for career advice.',
    PERSONAL_DEV_ADVICE_PROVIDE:
        'I have been working for a relatively long time and it would be my pleasure to give professional and personal career advice to my colleagues.',
    INTERCULTURAL_RELOCATION: 'Intercultural exchange',
    INTERCULTURAL_RELOCATION_NEW:
        'I am thinking about relocating within the organization and would like to learn more about this.',
    INTERCULTURAL_RELOCATION_EXPERIENCE:
        'I will be happy to share knowledge about my location or share my previous relocation experiences.',
    MENTORING_FREE_TEXT: "You are looking for a mentor/mentee in a field that hasn't been named yet? Describe it here:",

    JOB_CATEGORY: 'Keywords',
    JOB_CATEGORY_PLACEHOLDER: 'e.g. Controlling',
    JOB_JOBSHARER_TYPE: 'search for:',
    JOB_APPLY: 'Apply now',
    JOB_APPLY_CONTACT: 'Apply directly now to',
    JOB_SHARE: 'Recommend this job to friends who could benefit from more time.',
    JOB_DESCRIPTION_1: 'Job description',
    JOB_CONTACT_USER: 'Send message and/or application.',
    JOB_WANTS_TO_SHARE: 'Wants to share his job',
    JOB_CREATE_TAGS_PLACEHOLDER_TEXT: 'e.g. HR, Marketing',
    JOB_CREATED_NOTIFICATION:
        'Great! You have created your offer. You can edit this directly here. It is also saved in your personal profile.',
    JOB_CREATED_BY_ADMIN_NOTIFICATION:
        'Great! You have created your offer. You can edit this directly here. It is also saved in the administration.',
    JOB_UPDATE_SUCCESS: 'Your job has been successfully updated.',
    JOB_CREATE_ERROR: 'Your job could not be saved. Please check your data.',
    JOB_ESSENTIALS_HEADLINE: 'Essentials',
    JOBROTATION_INTERESTED_CATEGORY: 'Looking for (Keywords)',
    JOBROTATION_OFFERING_CATEGORY: 'My job (Keywords)',

    JOB_OWNER: 'Job-Owner',
    JOB_OWNER_ADMIN: 'Admin Job',

    CARD_FACTS_END_TIME_UNTIL: '{{endTime}} days left',

    EMAIL_WITH_REQUIRED: 'E-mail *',
    CONTACT_PERSON: 'User name',
    CONTACT_PERSON_WITH_REQUIRED: 'Contact *',
    JOBTITLE: 'Job title',
    WRITE_JOBTITLE: 'Your job title',
    WRITE_JOBTITLE_ADMIN: 'Job titel of the position',
    CATEGORIES: 'Keywords z.B. HR, Marketing *',
    CATEGORIES_SUBTITLE:
        'Enter the most relevant keywords for your job here and separate the words by pressing "Enter". The more tags you enter, the better the matching will be.',
    CATEGORIES_SUBTITLE_ADMIN:
        'Enter the most relevant keywords for this job here and separate the words by pressing "Enter". The more tags you enter, the better the matching will be.',
    CATEGORIES_PLACEHOLDER: "'You can choose one or more categories.",
    CITY_REQUIRED: 'Location',
    CITY: 'Location',
    CITY_PLACEHOLDER: 'e.g Berlin',
    SHARING_DETAILS: 'What do you want to publish?',
    JOBSHARER_TEXT: 'Looking for job sharer (single person)',
    TANDEM_TEXT: 'Looking for tandem (two people)',
    JOB_DESCRIPTION: 'Job description *',
    POST: 'Publish',

    JOB_TITLE_TOO_SHORT: 'The title has to have at least 5 characters.',

    JOB_APPL_HEADER: 'Apply now!',
    JOB_APPL_EMAIL: 'Your e-mail address:',
    JOB_APPL_BODY: 'Your cover letter:',
    JOB_APPL_ATTACHMENTS: 'Attachments:',

    JOBSHARER_SEARCH_TITLE: 'Search for colleagues using tags',

    JOBSHARER_SEARCH_SUGGESTIONS_TITLE: 'My Matches:',
    JOBSHARER_SEARCH_SUGGESTIONS_INFO:
        "Our matching algorithm only suggests colleagues to you who are in principle in line with your interests and profile. In addition to the 'hard facts‘ and depending on the working model you have chosen, there will be a specific focus on the ‘soft facts‘. For a successful matching, please be as detailed as possible in your profile (and the matching questionnaire). And don't forget: even a perfect matching cannot replace the personal exchange.",
    SUGGESTIONS_NO_PROFILE_SUBTITLE: 'We could not find any matching colleagues.',
    SUGGESTIONS_NO_PROFILE_TEXT:
        'As soon as you have completed your profile, automatic suggestions for matching colleagues will appear here. Currently, your profile is not sufficiently detailed for that. Please complete it first.',
    SUGGESTIONS_NO_PROFILE_BUTTON: 'Create your profile',
    SUGGESTIONS_UPDATE_PROFILE_BUTTON: 'Complete your profile',

    SUGGESTIONS_NO_MATCH_TEXT:
        'Unfortunately, there are no matches yet.<br/>Change filter settings to show more results.',
    SUGGESTIONS_OPTIMIZE_PROFILE: 'Optimize profile',
    SUGGESTIONS_OPTIMIZE_PROFILE_BTN_ARIA_LABEL: 'Optimize topic details of {{topic}}',
    SUGGESTIONS_NO_MATCH_FOUND_TITLE: 'No matching profiles found yet',
    SUGGESTIONS_NO_MATCH_FOUND: 'Supplement the profile details to increase the chances of matches.',

    MATCHING_INVITE_FRIENDS_TITLE: 'Invite your colleagues',
    MATCHING_INVITE_FRIENDS: 'More users - more matches for everyone! Bring your colleagues to the platform.',
    MATCHING_INVITE_FRIENDS_BUTTON: 'Invite',
    MATCHING_INVITE_FRIENDS_BTN_ARIA_LABEL: 'To the form Invite colleagues',
    MATCHING: 'Matching',

    JOBSHARER_STATUS_JOB: 'Wants to share a job',
    JOBSHARER_STATUS_TANDEM: 'Is your tandem partner',
    JOBSHARER_STATUS_INTERESTED: 'Bookmarked colleague',
    JOBSHARER_STATUS_PROJECT_LEAD: 'Is project lead',

    OPEN_CONVERSATION_BTN_TOOLTIP_MESSAGE: 'New message',
    OPEN_CONVERSATION_BTN_ARIA_LABEL: 'New message to {{userName}}',
    JOB_WRITE_MESSAGE: 'Message',

    MESSAGES_TITLE: 'Stay in contact with <b>{{ name }}</b>',
    MESSAGE_WITH: 'Write a new message to <b>{{ name }}</b>',
    JOBSHARER_DEACTIVATED: 'This user has deactivated his account.',

    MESSAGING: 'My messages',
    MESSAGING_SUBTITLE: 'Here you can find an overview of your conversations with other members!',
    MESSAGING_NO_CONTENT_1: 'You do not know any other members yet? Start looking for a',
    MESSAGING_NO_CONTENT_2: 'right now!',
    MESSAGING_NO_CONTENT_LINK: 'flexible colleague',
    MESSAGING_ME: 'Me: ',

    CONVERSATION_DELETE_HEADER: 'Delete conversation',
    CONVERSATION_DELETE_TEXT: 'Would you really like to delete this conversation?',
    SPAM_HEADER: 'Report spam',
    SPAM_TEXT: 'I would like to report this conversation as spam because...',
    SPAM_SUCCESS: 'Thanks for supporting us by reporting spam messages. We will take it from here!',
    REPORT: 'Report',

    CONVERSATION_DELETE: 'Delete conversation',
    SPAM_REPORT: 'Report spam',

    MESSAGE_MODAL_TITLE: 'Write {{ shortName }} a message!',
    MESSAGE_ANONYM:
        "Recipients of your messages can only see your user name, not your e-mail address. If you've chosen a fantasy user name, your are anonymous until you decide to disclose who's behind your account.",
    MESSAGE_TITLE_INVITE_FOR_COFFEE: 'Invitation for coffee',
    MESSAGE_TITLE_INVITE_FOR_TEA: 'Invitation for tea',
    MESSAGE_TITLE_INVITE_FOR_LUNCH: 'Invitation for lunch',
    MESSAGE_TITLE_INVITE_FOR_BEER: 'Invitation for a beer (after work)',

    TANDEMS_TITLE: 'My tandems',
    TANDEM_PENDING: 'Pending',
    TANDEM_ACCEPT: 'Accept',
    TANDEM_DELETE_HEADER: 'Remove tandem partner',
    TANDEM_DELETE_TEXT: 'Would you really like to remove {{name}} from your potential tandem partner list?',
    NEW_TANDEMPARTNER_MESSAGE_HEADER: '{{ name }} would like to add you as a tandem partner',
    NEW_TANDEMPARTNER_MESSAGE_BUTTON: 'Accept',
    TANDEM_NO_SUBTITLE: 'You have not added any potential tandem partners yet.',
    TANDEM_NO_TEXT:
        'You have found interesting colleagues and possibly want to form a tandem with them? Use the button “Add tandem partner" in the respective profile to create a tandem profile by merging your profile with that of another colleague.',

    TANDEM_TITLE: 'Your tandem profile with {{ name }}',
    TANDEM_EXP_YEARS: 'Year/s',

    ADD_TANDEM: 'Add tandem partner',
    ADD_TANDEM_BTN_ARIA_LABEL: 'Send tandem request to {{userName}}',
    SHOW_TANDEM: 'Show tandem profile',
    SHOW_TANDEM_BTN_ARIA_LABEL: 'See tandem profile with {{userName}}',
    TANDEM_CREATE_HEADER: 'Add a tandem partner',
    TANDEM_CREATE_TEXT:
        "Add {{ name }} as a tandem partner. After you've added {{ name }}, he/she has to confirm you as a potential tandem partner.",
    TANDEM_MESSAGE: 'Add message',
    TANDEM_CREATE_MESSAGE_LABEL: 'Personal message',
    TANDEM_CREATE_MESSAGE_PLACEHOLDER: 'My personal tandem invitation to my match...',
    TANDEM_CREATE_MESSAGE_ARIA_LABEL: 'Send a personal message to {{userName}}.',
    TANDEM_CREATE_CANCEL_BTN_ARIA_LABEL: 'Cancel tandem request',
    TANDEM_CREATE_ADD_BTN_ARIA_LABEL: 'Send tandem request',

    TANDEM_LINK_HEADER: 'Do you want to forward the link of your tandem profile to your colleagues?',
    TANDEM_LINK_TEXT: 'Forward this link to your colleagues to enable them to see your tandem profile.',

    PROFILE_SHOW_SECURE_LINK: 'My profile-link',
    PROFILE_SHOW_SECURE_LINK_BTN_ARIA_LABEL: 'Show my profile link to send it to colleagues',
    PROFILE_LINK_HEADER: 'Do you want to forward the link of your profile to your colleagues?',
    PROFILE_LINK_TEXT: 'Forward this link to your colleagues to enable them to see your personal profile.',
    PROFILE_STAR_TEXT: 'Add this profile to your list of "Bookmarked colleagues".',
    PROFILE_UNSTAR_TEXT: 'Delete this profile of your list of "Bookmarked colleagues".',
    PROFILE_UNSTAR_BTN_ARIA_LABEL: 'Delete the profile of {{userName}} from your list of "Bookmarked colleagues"',
    PROFILE_GET_TO_KNOW: 'Get to know',

    TANDEM_SHOW_LINK: 'Link to tandem profile',
    TANDEM_PROFILE_TITLE: 'Tandem profile of {{ name1 }} & {{ name2 }}',

    TANDEM_INTERESTED_TITLE: 'Bookmarked colleagues',
    STARED_DELETE_HEADER: 'Remove',
    STARED_DELETE_TEXT: 'Do you really want to remove {{name}} from bookmarked collegues?',
    TANDEM_INTERESTED_NO_SUBTITLE: 'You have not added any colleagues to your watch list yet.',
    TANDEM_INTERESTED_NO_TEXT:
        'Once you have found potentially interesting colleagues, you can easily add them to your watch list by using the star icon in the profile view. The other users do  receive a notification about this. To contact a colleague on your watch list, just send a message.',
    TANDEM_INTERESTED_RESULT_ARIA_LABEL: 'To profile view of {{userName}}',

    TANDEM_PROFILE_SEARCH_JOB: 'search for:',
    TANDEM_PROFILE_SEARCH_IN: 'in:',
    TANDEM_PROFILE_EDUCATION: 'education:',
    TANDEM_PROFILE_WORK: 'professional experience:',
    TANDEM_PROFILE_PERFECT_MATCH: 'Our perfect tandem partner...',
    TANDEM_PROFILE_COMPANY_PERFECT_MATCH: 'Our perfect employer has...',
    TANDEM_PROFILE_ME: 'We are...',
    TANDEM_PROFILE_COMPANY_JOB_QUAL: 'We like...',
    TANDEM_PROFILE_INTERESSE: 'We are interested in...',
    TANDEM_PROFILE_ZITAT: 'We liked these quotes the most...',

    PROFILE_JOBSHARING_LANGUAGES: 'Languages',
    PROFILE_JOBSHARING_SKILLS: 'Skills',
    PROFILE_JOBSHARING_COMPLEMENTARY_SKILLS: 'Tandem partners knowledge',
    PROFILE_JOBSHARING_DESIRED_WORK_LOCATIONS: 'Work location',
    PROFILE_JOBSHARING_LEADERSHIP: 'Leadership position',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_INDIFFERENT: 'Flexible in future',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_NO: 'Not in future',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_YES: 'In future yes',
    PROFILE_JOBSHARING_LEADERSHIP_NO: 'Currently not',
    PROFILE_JOBSHARING_LEADERSHIP_YES: 'Currently yes',
    PROFILE_JOBSHARING_WORKING_HOURS: 'Desired working hours',
    PROFILE_JOBSHARING_DESIRED_WORKING_HOURS: 'Own: {{ hours }}',
    PROFILE_JOBSHARING_PARTNER_WORKING_HOURS: 'Tandem partner: {{ hours }}',
    PROFILE_JOBSHARING_JOB_LIKED_ABOUT: 'I like about my job',
    PROFILE_JOBSHARING_JOB_DESCRIPTION: 'Description of current job',

    PROFILE_MENTORING_LOOKING_FOR: 'Looking for',
    PROFILE_TAG_MENTORING_INTERESTED_INTERESTED_TAG: 'Mentor',
    PROFILE_TAG_MENTORING_INTERESTED_OFFERING_TAG: 'Mentee',
    PROFILE_MENTORING_OFFERING: 'Areas I support',
    PROFILE_TAG_MENTORING_OFFERING_CAREER_TAG: 'Career',
    PROFILE_TAG_MENTORING_OFFERING_EXPERTISE_TAG: 'Professional qualifications',
    PROFILE_MENTORING_INTERESTED: 'Desired support',
    PROFILE_TAG_MENTORING_INTERESTED_CAREER_TAG: 'Career',
    PROFILE_TAG_MENTORING_INTERESTED_EXPERTISE_TAG: 'Professional qualifications',
    PROFILE_MENTORING_OFFERING_SKILLS: 'My expertise',
    PROFILE_MENTORING_INTERESTED_SKILLS: 'Sought expertise',

    PROFILE_EXPERTISE_DURATION: 'Duration of support',
    PROFILE_TAG_EXPERTISE_DURATION_LONG_TERM: 'long-term',
    PROFILE_TAG_EXPERTISE_DURATION_SHORT_TERM: 'short-term',
    PROFILE_EXPERTISE_INTERESTED_SKILLS: 'Sought expertise',
    PROFILE_EXPERTISE_OFFERING_SKILLS: 'My expertise',

    PROFILE_LOCATION_DESIRED_LOCATON: 'Interesting locations',

    PROFILE_INTERESTS_INTERESTED_SKILLS: 'My interests',

    PROFILE_REVERSE_MENTORING_OFFERING_SKILLS: 'My expertise',
    PROFILE_REVERSE_MENTORING_INTERESTED_SKILLS: 'Sought support',

    PROFILE_SKILLS_INTERESTED_SKILLS: 'Topic interests',
    PROFILE_SKILLS_OFFERING_SKILLS: 'My expertise',

    PROFILE_TRAINING_INTERESTED_SKILLS: 'Desired training opportunities',

    PROFILE_EXPATS_OPTIONS: 'Looking for',
    PROFILE_TAG_EXPATS_MOTIVATION_INTERESTED: 'Expats',
    PROFILE_TAG_EXPATS_MOTIVATION_OFFERING: 'Colleagues who are interested in stays abroad',
    PROFILE_EXPATS_OFFERING_COUNTRIES: 'My experiences abroad',
    PROFILE_EXPATS_INTERESTED_COUNTRIES: 'Interesting countries',
    PROFILE_EXPATS_HOME_COUNTRIES: 'My home country',

    PROFILE_INTERNSHIP_INTERESTED_GROUP: 'I am',
    PROFILE_TAG_INTERNSHIP_SUPPORTER: 'Supporter',
    PROFILE_TAG_INTERNSHIP_GROUP_GENERAL: 'part of one of the three groups',
    PROFILE_TAG_INTERNSHIP_GROUP_TRAINEE: 'Trainee',
    PROFILE_TAG_INTERNSHIP_GROUP_APPRENTICE: 'Apprentice',
    PROFILE_TAG_INTERNSHIP_GROUP_INTERN: 'Intern',
    PROFILE_INTERNSHIP_OFFERING_MOTIVATION: 'My motivation',

    PROFILE_SABBATICAL_OPTION: 'Experiences with sabbaticals',
    PROFILE_TAG_SABBATICAL_INTERESTED_TAG: 'I want to know more about sabbaticals',
    PROFILE_TAG_SABBATICAL_OFFERING_TAG: 'Yes',
    PROFILE_SABBATICAL_OFFERING_DESCRIPTION: 'Description of my sabbatical',
    PROFILE_SABBATICAL_INTERESTED_DESCRIPTION: 'Reasons for my future sabbatical',
    PROFILE_SABBATICAL_INTERESTED_CAUSE_EMPLOYER: 'Reasons for the company',

    PROFILE_PARENTAL_LEAVE_OPTION: 'Experience with parental leave',
    PROFILE_TAG_PARENTAL_LEAVE_OFFERING_TAG: 'Yes',
    PROFILE_TAG_PARENTAL_LEAVE_INTERESTED_TAG: 'I want to know more about parental leave',

    PROFILE_RETRAINING_INTERESTED_SKILLS: "Retrainings I'm interested in",

    PROFILE_PEER_LEARNING_INTERESTED_SKILLS: 'Skills I want to learn',
    PROFILE_PEER_LEARNING_OFFERING_SKILLS: 'My expertise',
    PROFILE_PEER_LEARNING_LOOKING_FOR: 'Optimal learning format',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_0: 'Day-to-day work',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_1: 'Coaching',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_2: 'Events',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_3: 'Mentoring',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_4: 'Projects',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_5: 'Working circles',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_6: 'Workshops',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_7: 'Other trainings',

    PROFILE_ONBOARDING_OPTION: 'Looking for',
    PROFILE_TAG_ONBOARDING_OFFERING_TAG: 'New colleagues that I can help get started in the company',
    PROFILE_TAG_ONBOARDING_INTERESTED_TAG: 'Colleagues who support me during my start in the company',

    PROFILE_BIRTH_YEAR: 'Year of birth:',
    PROFILE_AGE_RANGE: 'Age range:',
    PROFILE_SEARCH_TYPE: 'Searches:',
    PROFILE_SEARCH_JOB: 'Skills:',
    PROFILE_SEARCH_EXPERT: ' Expert in:',
    PROFILE_SEARCH_JOB_SUCHT: 'Searches',
    PROFILE_WORK_HOURS: 'Working hours:',
    PROFILE_SEARCH_IN: 'Locations:',
    PROFILE_BEREICH: 'Division/Department:',
    PROFILE_SUBSIDIARY: 'Subsidiary:',
    HOURS_PER_WEEK: 'hours per week',
    PROFILE_EXP_YEARS_LABEL: 'Total work experience (years)',
    PROFILE_EXP_YEARS: 'year(s)',
    PROFILE_WORK_LABEL: 'Professional experience',
    PROFILE_ADD_WORK_LABEL: 'Add work experience',
    PROFILE_EXPERIENCE_LABEL: 'Professional experiences',
    PROFILE_EXPERIENCE_ARIA_LABEL: 'Edit professional experience',
    PROFILE_HIGHEST_DEGREE_LABEL: 'Highest qualification',
    PROFILE_GENERAL_SKILLS_LABEL: 'Skills',
    PROFILE_WORK: 'Total duration of professional experience',
    PROFILE_CAREER_STEPS: 'Professional background',
    PROFILE_PERFECT_MATCH: 'Good teamwork means to me:',
    PROFILE_COMPANY_PERFECT_MATCH: 'My perfect employer has:',
    PROFILE_ME: 'I am:',
    PROFILE_COMPANY_JOB_QUAL: 'I like:',
    PROFILE_INTERESSE: 'I am interested in:',
    PROFILE_MENTORING: 'Mentoring means the following to me:',
    PROFILE_ZITAT: 'I liked this quote the most:',
    PROFILE_PFLICHTFELD: '* Mandatory',
    PROFILE_LANGUAGES: 'Languages: ',
    PROFILE_JOB_HEADER: 'Offers',
    PROFILE_CREATE_OFFER: 'Create offer',
    PROFILE_AVATAR_TOOLTIP: 'Upload picture or choose:',
    PROFILE_AVATAR_CHOOSE_OWN: 'Upload your own picture',
    PROFILE_AVATAR_RECHOOSE_OWN: 'Replace',
    PROFILE_AVATAR_TAKE: 'Ok',
    PROFILE_AVATAR_CHOOSE: 'Save',
    PROFILE_IMAGE_UPLOADER_TEXT: 'Choose avatar or upload picture',
    PROFILE_QUESTIONNAIRE: 'More about me',
    PROFILE_QUESTIONNAIRE_ADD_LABEL: 'Go to questionnaire to tell more about yourself',
    PROFILE_EDIT_QUESTIONNAIRE_LABEL: 'Edit questionnaire',
    PROFILE_STARED: '{{ name }} has been added to "Bookmarked colleagues".',
    PROFILE_UNSTARED: '{{ name }} has been removed from "Bookmarked colleagues".',
    PROFILE_STARED_MESSAGE: ' Stay in touch.',
    LATER: 'Later',
    ATTACH: 'Upload picture',
    PROFILE_ABOUT_ME: 'About me',
    PROFILE_KNOWLEDGE: 'Knowledge',
    PROFILE_LOOKING_FOR: 'Topics I am interested in',
    PROFILE_UPDATE_TOPIC: 'Edit',
    PROFILE_DELETE_TOPIC: 'Deactivate',
    PROFILE_EXPAND_TOPIC: 'Expand',
    PROFILE_PLACEHOLDER_DESCRIPTION: 'Not specified:',
    PROFILE_PLACEHOLDER_VALUE: 'Please click here to edit this section.',
    PROFILE_VIEW_INTERESTS_NETWORKING: 'Networking',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_FIELDS_AND_EXPERTISE: 'Specialist Exchange',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PERSONAL_INTEREST: 'Interests',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SPONTANEOUS: 'Spontaneous',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SPECIFIC_LOCATION: 'Specific Location',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_TOPICS_AND_GROUPS: 'On-topic or specific audience',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_REVERSE_MENTORING: 'Reverse Mentoring',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PEER_LEARNING: 'Peer Learning',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_ONBOARDING: 'Onboarding',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_EXPERTS: 'Experts',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_ADVANCED_TRAINING: 'Advanced Training',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_RETRAINING: 'Re-Training',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_EXPATS: 'Expats',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_TRAINEE: 'Trainees',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_INTERN: 'Interns',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_APPRENTICE: 'Apprentices',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SABBATICAL: 'Sabbatical',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_CAREER_AND_FAMILY_PARENTAL_LEAVE: 'Career and Family Life',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_INDIVIDUAL: 'Individual',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_MENTORING: 'Mentoring',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_COACHING: 'Future Coaching',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PROJECT: 'Project',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_JOBSHARING: 'Job rotation',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_JOBROTATION: 'Jobsharing',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_NEVER_LUNCH_ALONE: 'Never Lunch Alone',

    PROFILE_NETWORKING_SKILLS_INTEREST_TITLE: 'Specialist Exchange (currently "Subject Areas and specific Knowledge")',
    PROFILE_NETWORKING_INTERESTS_TITLE: 'Personal Interests',
    PROFILE_NETWORKING_LOACTION_TITLE: 'Other Company Location',
    PROFILE_NETWORKING_REVERSE_MENTORING_TITLE: 'Reverse Mentoring',
    PROFILE_NETWORKING_PEER_LEARNING_TITLE: 'Peer Learning',
    PROFILE_NETWORKING_ONBOARDING_TITLE: 'Onboarding and Settling in',
    PROFILE_NETWORKING_EXPERTISE_TITLE: 'Find Experts',
    PROFILE_NETWORKING_EXPERTISE_TEXT: 'Seeking expertise in',
    PROFILE_NETWORKING_TRAINING_TITLE: 'Advanced Training',
    PROFILE_NETWORKING_RETRAINING_TITLE: 'Retraining',
    PROFILE_NETWORKING_EXPATS_TITLE: 'Expats and Working abroad',
    PROFILE_NETWORKING_INTERNSHIP_TITLE: 'Trainee, Intern, Apprentice',
    PROFILE_NETWORKING_SABBATICAL_TITLE: 'Sabbatical',
    PROFILE_NETWORKING_PARENTAL_LEAVE_TITLE: 'Parental Leave',
    PROFILE_NETWORKING_MENTORING_TITLE: 'Mentoring',

    PROFILE_DELETE_TOPIC_TITLE: 'Deactivate {{ topic }}',
    PROFILE_DELETE_TOPIC_TEXT: 'Are you sure you want to deactivate this interest and the related matching?',
    PROFILE_DELETE_TOPIC_DEACTIVATE: 'Deactivate topic',
    PROFILE_DELETE_TOPIC_TOOLTIP:
        'At least one interest must be active. You can first add other interests to deactivate this one.',

    PROFILE_OFFERING: 'Offering',
    NO_SUB_TAGS: 'General:',

    IMAGE_PICKER_DIALOG_TITLE: 'Upload picture',
    IMAGE_PICKER_DIALOG_BUTTON_TEXT: 'Save',
    IMAGE_PICKER_DIALOG_CHOOSE: 'Choose picture',
    IMAGE_PICKER_IMAGE_TOO_LARGE: 'The image size should not exceed 5MB.',
    IMAGE_PICKER_IMAGE_WRONG_FILETYPE: 'Only image files (.jpg or .png) are allowed.',

    NEW_AVATAR_HEADER: 'New Look!',
    NEW_AVATAR_TEXT:
        'We have launched a new set of free profile pictures! Since your account was using an old picture, we have randomly replaced it with a new one.',
    NEW_AVATAR_TEXT_2: 'You can change it any time you like or, even better, upload your own picture.',
    NEW_AVATAR_BUTTON: 'Visit profile',

    INFO_ABOUT_OFFERED_JOB: 'Information about your job',
    PROFILE_JOB_TITLE: 'Which field / department do you work in?',
    PROFILE_JOB_HOURS: 'How many hours do you currently work?',
    PROFILE_JOB_DESCRIPTION: 'Please describe your job / position:',
    PROFILE_JOB_PARTNER: 'Please write a few lines about yourself / your idea of a suitable tandem partner:',

    UPDATE_ACCOUNT_HEADER: 'User settings',
    UPDATE_ACCOUNT_CHANGE_PASSWORD: 'Change password',
    UPDATE_ACCOUNT_CHANGE_EMAIL: 'Change e-mail',
    UPDATE_ACCOUNT_DELETE_ACCOUNT: 'Delete user account',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_COLLAPSED_ARIA_LABEL: 'Open account deletion settings',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_EXPANDED_ARIA_LABEL: 'Close account deletion settings',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_BTN_ARIA_LABEL: 'Delete my account',
    UPDATE_ACCOUNT_CHANGE_NICKNAME: 'Change user name',
    PASSWORD_RESET_SUCCESS: 'Your password has been changed successfully.',
    MAIL_RESET_SUCCESS: 'Your e-mail has been changed successfully.',
    NICKNAME_RESET_SUCCESS: 'Your user name has been changed successfully.',
    NICKNAME_RESET_ERROR: 'Your new user name is already being used.',
    ACCOUNT_DELETE_HEADER: 'Delete user account?',
    ACCOUNT_DELETE_TEXT:
        'Do you really want to delete your account including all profile data? \n If you are inactive for a while and don\'t need matches, you can easily unsubscribe from unnecessary mails under "Email notifications" in the user settings.',
    ACCOUNT_DELETE_TEXT_NOTE:
        'Note: When an account is deleted, all personal data is automatically and completely deleted from the Tandemploy system. A separate request or confirmation regarding data deletion is not required.',
    ACCOUNT_DELETE_CANCEL_BTN_ARIA_LABEL: 'Cancel process',
    ACCOUNT_DELETE_DELETE_BTN_ARIA_LABEL: 'Delete my account',
    DELETE_SUCCESS_MESSAGE:
        'Thank you for your time and trust. We wish you all the best and hope to see you again soon!',

    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS: 'Change e-mail subscriptions',
    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS_HEADER: "I'd like to receive e-mail notifications on",

    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS_TEXT:
        "I'd like to receive the following e-mail notifications from {{ appName }}:",
    PROFILE_IFICATIONS_PROFILES: 'Notice about new potential tandem partners',
    PROFILE_IFICATIONS_JOBS: 'Notice about new fitting jobs',
    PROFILE_PLATFORM_UPDATES: 'Updates on new functions & features',

    UPDATE_ACCOUNT_EMAIL_SUBSCRIPTIONS: 'E-mail notifications',
    UPDATE_ACCOUNT_EMAIL_SUBSCRIPTIONS_COLLAPSED_ARIA_LABEL: 'Open e-mail notification settings',
    UPDATE_ACCOUNT_EMAIL_SUBSCRIPTIONS_EXPANDED_ARIA_LABEL: 'Close e-mail notification settings',
    NOTIFICATION_MATCHED_OFFERS_LABEL: 'fitting offers',
    NOTIFICATION_STAFFING_EMAILS_LABEL: 'staffing emails',
    NOTIFICATION_SUBSCRIPTION_PROFILES_LABEL: 'fitting colleagues',
    NOTIFICATION_SUBSCRIPTION_PROFILES_CHECKED_ARIA_LABEL:
        'Checkbox active: Receive e-mail notifications about fitting colleagues',
    NOTIFICATION_SUBSCRIPTION_PROFILES_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Receive e-mail notifications about fitting colleagues',
    NOTIFICATION_SUBSCRIPTION_JOBS_LABEL: 'fitting jobs & projects',
    NOTIFICATION_SUBSCRIPTION_BLOG_CHECKED_ARIA_LABEL:
        'Checkbox active: Receive e-mail notifications about new articles',
    NOTIFICATION_SUBSCRIPTION_BLOG_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Receive e-mail notifications about new articles',
    NOTIFICATION_SUBSCRIPTION_SURVEY_LABEL: 'Colleague echo',
    NOTIFICATION_SUBSCRIPTION_MESSAGES_CHECKED_ARIA_LABEL:
        'Checkbox active: Receive e-mail notifications about new personal messages',
    NOTIFICATION_SUBSCRIPTION_MESSAGES_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Receive e-mail notifications about new personal messages',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_LABEL: 'tandem request and acceptance',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_CHECKED_ARIA_LABEL:
        'Checkbox active: Receive email notification for tandem requests and confirmations',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Receive email notification for tandem requests and confirmations',
    NOTIFICATION_SUBSCRIPTION_DIRECT_MESSAGES_LABEL: 'personal messages',

    UPDATE_ACCOUNT_ANONYMITY: 'Anonymity',
    UPDATE_ACCOUNT_ANONYMITY_COLLAPSED_ARIA_LABEL: 'Open anonymity settings',
    UPDATE_ACCOUNT_ANONYMITY_EXPANDED_ARIA_LABEL: 'Close anonymity settings',
    UPDATE_ACCOUNT_CHANGE_ANONYMITIES_HEADER:
        'If you want to hide a section of your profile to other users, untick the checkbox:',
    ANONYMITY_BIRTHDATE_LABEL: 'Year of birth',
    ANONYMITY_BIRTHDATE_CHECKED_ARIA_LABEL: 'Checkbox active: Your year of birth is visible for other colleagues',
    ANONYMITY_BIRTHDATE_UNCHECKED_ARIA_LABEL: 'Checkbox inactive: Your year of birth is visible for other colleagues',
    ANONYMITY_EXPERIENCE_LABEL: 'Stages of your career',
    ANONYMITY_EXPERIENCE_CHECKED_ARIA_LABEL: 'Checkbox active: Your stages of career are visible for other colleagues',
    ANONYMITY_EXPERIENCE_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Your stages of career are visible for other colleagues',
    ANONYMITY_ALL_LABEL: 'All answers regarding the co-working questionnaire',
    ANONYMITY_QUESTIONNAIRE_CHECKED_ARIA_LABEL:
        'Checkbox active: Answers to the personal questionnaire are visible to other colleagues.',
    ANONYMITY_QUESTIONNAIRE_UNCHECKED_ARIA_LABEL:
        'Checkbox inactive: Answers to the personal questionnaire are visible to other colleagues.',

    CREATE_PROFILE_INFO_TEXT:
        'Happy to have you here! After providing some facts about yourself and your professional experience you will get to the matching questionnaire right away!',
    CREATE_PROFILE_INFO_TEXT_UNFINSHED:
        'Please fill in the *required fields first to use the platform to its full extent',
    PROFILE_PICTURE: 'Profile picture',
    PROFILE_PICTURE_WHY:
        'Experience has shown that members with a profile picture are much more likely to be contacted. You can upload an image here.',
    GENDER: 'Gender',
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
    NOT_SPECIFIED: 'not specified',
    BIRTH_YEAR: 'Year of birth',
    BIRTH_YEAR_LABEL: 'Your year of birth',
    BIRTH_YEAR_PLACEHOLDER: 'Please select',
    AGE_RANGE: 'Age range',
    PROFILE_CITY: 'Location',
    PROFILE_CITY_PLACEHOLDER: 'Location',

    PROFILE_CREATE_JOBSHARING_QUESTIONAIRE: 'Save and go to matching questionnaire',
    PROFILE_CREATE_PROJECT_CREATE: 'Save and publish your project',
    PROFILE_CREATE_JOBSHARING_QUESTIONAIRE_NOT_NOW: 'Save and answer later',
    PROFILE_CREATE_JOBSHARING_WITHOUT_QUESTIONNAIRE: 'Save',

    PROFILE_EDITING_FINAL_CONFIRM_STEP_TITLE: 'Confirm changes',
    PROFILE_EDITING_FINAL_CONFIRM_STEP_CONTENT_TITLE: 'Hit »Update Profile« in order to save the latest changes.',
    PROFILE_EDITING_FINAL_CONFIRM_STEP_SAVE_BUTTON_LABEL: 'Update Profile',

    PROFILE_UPDATE_NOTIFICATION_MSG: 'New information were saved to your personal profile',

    CREATE_JOB_JOB_TYPE_BUTTON: 'Next',
    CREATE_JOB_JOB_TYPE_TITLE_SINGLE_JOBSHARER: "I'd like to post my own job to find a tandem partner for it.",
    CREATE_JOB_JOB_TYPE_SUB_SINGLE_JOBSHARER:
        'Here you can post your own job if you want to share it with a tandem partner in the future. In the classic job sharing model, two people share one job and self-responsibly organize their close teamwork. Through this, even complex jobs become flexible for the individual - clever cooperation makes it possible!',
    CREATE_JOB_JOB_TYPE_TITLE_TANDEM: "I'd like to post a new job and find a tandem for it.",
    CREATE_JOB_JOB_TYPE_SUB_TANDEM:
        'Here you can post a job, typically a full-time (or more) position, for a job sharing tandem. A job sharing tandem typically contributes more qualifications and skills to a job than a single person could ever do and organizes the working model self-responsibly. You will get twice the power and competencies for your vacant position! If only half a tandem, i.e. one counterpart is needed, the employee can post this via his or her own profile.',
    CREATE_JOB_JOB_TYPE_TITLE_JOBROTATION_INTERESTED:
        'I want to swap my job with somebody for a certain period of time. (mutual job swap)',
    CREATE_JOB_JOB_TYPE_SUB_JOBROTATION_INTERESTED:
        "You can post your own job for a job rotation here and indicate the field you'd like to switch to temporarily. Job rotation in this case means a two-directional swap of tasks and possibly even of locations and/or departments.",
    CREATE_JOB_JOB_TYPE_TITLE_JOBROTATION_OFFERING: "I'd like to post a position for job rotation.",
    CREATE_JOB_JOB_TYPE_SUB_JOBROTATION_OFFERING:
        "Here you can post the possibility of a job rotation into a department where you'd like to win the expertise and experience of a colleague from a different field. Job rotation in this case does not mean a 'swap' but a one-directional offer to switch to a certain field for a limited period of time. If you'd like to make a mutual swap with an employee, this colleague can post the job rotation via his or her own profile.",
    CREATE_JOB_JOB_TYPE_TITLE_PROJECT_LEAD_SELF: "I'd like to post a project as a project lead.",
    CREATE_JOB_JOB_TYPE_SUB_PROJECT_LEAD_SELF:
        "Here you can post a project and search for team members, for example for colleagues from different fields or departments. A project means a broader undertaking on which you'd like to co-work with colleagues for a certain period of time.",
    CREATE_JOB_JOB_TYPE_TITLE_PROJECT_LEAD_OTHER: "I'd like to create a project and assign a project manager.",
    CREATE_JOB_JOB_TYPE_SUB_PROJECT_LEAD_OTHER:
        'Here you can create a project and assign a project manager from the user pool. If the project manager is not registered on the platform yet, please ask him / her to create an account first.',

    CHOOSE_MAX_3: '(max. 3 answers)',
    CHOOSE_MAX_3_TEXT:
        'Please only choose a maximum of {{ maxAnswers }}  answers. To deselect answers, please click on them again.',

    STATS_HEADER: '{{ appName }} Statistics',
    STATS_CURRENT_LABEL: 'Current: ',
    STATS_CURRENT_USERS: 'Users',
    STATS_CURRENT_TANDEMS: 'Tandems',

    STATS_GENDER_LABEL: 'Gender',
    STATS_AGE_LABEL: 'Age distribution',
    STATS_AREAS_LABEL: 'Expertise',
    STATS_MOTIVES_LABEL: 'Reasons for reducing working hours',
    STATS_MODEL_INTERESTED_IN_LABEL: 'Working models',
    STATS_WORKING_WEEK_HOURS_LABEL: 'Desired working hours/week',
    STATS_LOCATION_LABEL: 'Most common user locations',
    STATS_JOBSHARING_LABEL: 'Job sharers searching and offering',

    WITH_JOBSHARING_JOBS: 'Are willing to share their current field of work',
    WITHOUT_JOBSHARING_JOBS: 'Are looking for a new field of work to share',

    ADMIN_ADMINS_HEADER: 'Users with admin rights',
    ADMIN_ADMINS_ABBREVIATION: 'Permissions:',
    ADMIN_ADMINS_ADD: 'Add administrator',
    ADMIN_ADMINS_NAME: 'Name',
    ADMIN_ADMINS_MAIL: 'E-mail address',
    ADMIN_ADMINS_RIGHTS: 'Permissions',
    ADMIN_ADMINS_ADD_MODAL_HEADER: 'Add administrators',
    ADMIN_ADMINS_ADD_MODAL_ADD_MORE: 'Add more administrators',
    ADMIN_ADMINS_EDIT_MODAL_HEADER: 'Edit administrator',
    ADMIN_ADMINS_EDIT_MODAL_INPUT_HEADER: 'E-mail of administrator',
    ADMIN_ADMINS_DEMO_TEXT:
        'Dear visitor, our admin rights management area is  accessible in the demo version. In this screenshot you can see what it looks like.',
    ADMIN_ADMINS_EDIT: 'Edit permissions',
    ADMIN_ADMINS_DELETE: 'Delete administrator',
    USERS_DO_NOT_EXIST: 'Users with the e-mail addresses {{ emails }} do not exist.',
    USER_DOES_NOT_EXIST: 'A user with the e-mail address {{ email }} does not exist.',
    USERS_ALREADY_ADMINS:
        'Users with the e-mail addresses {{ emails }} already have admin rights. Please edit those admins in the list below.',
    USER_ALREADY_ADMIN:
        'A user with the e-mail address {{ email }} already has administration rights. Please edit this admin in the list below.',
    ROLE_REQUIRED: 'At least one permission is required',

    JOBS_ADMIN: 'Offers',
    JOBS_ADMIN_ABBREV: 'O',
    JOBS_ADMIN_ABBREVIATION: 'O = Offers',
    NEWS_ADMIN: 'Dashboard news',
    NEWS_ADMIN_ABBREV: 'N',
    NEWS_ADMIN_ABBREVIATION: 'N = News',
    MAILING_ADMIN: 'Mailings',
    MAILING_ADMIN_ABBREV: 'M',
    MAILING_ADMIN_ABBREVIATION: 'M = Mailings',
    RIGHTS_ADMIN: 'Rights management',
    RIGHTS_ADMIN_ABBREV: 'R',
    RIGHTS_ADMIN_ABBREVIATION: 'R = Rights management',
    STATS_ADMIN: 'Statistics',
    STATS_ADMIN_ABBREV: 'S',
    STATS_ADMIN_ABBREVIATION: 'S = Statistics',
    EXTERNAL_USERS_ADMIN: 'External users',
    EXTERNAL_USERS_ADMIN_ABBREV: 'E',
    EXTERNAL_USERS_ADMIN_ABBREVIATION: 'E = External users',

    REMOVE_ADMIN_RIGHTS_ERROR: 'The admin rights of {{ user }} could not be removed.',
    REMOVE_ADMIN_RIGHTS_SUCCESS: 'The admin rights of {{ user }} were removed successfully.',
    UPDATE_ADMIN_RIGHTS_SUCCESS: 'The admin rights of {{ user }} were updated successfully.',
    CREATE_ADMIN_RIGHTS_SUCCESS: 'Admin rights successfully added to {{ user }}.',

    EXTERNAL_USERS: 'External users',
    ADMIN_EXTERNAL_USERS_HEADER: 'External user',
    ADMIN_EXTERNAL_USERS_INFO:
        'Users who should be granted access to the user interface with an external e-mail address.',
    ADMIN_SEARCH_PLACEHOLDER: 'Search entries',
    ADMIN_EXTERNAL_USERS_EMAIL: 'E-mail address',
    ADMIN_EXTERNAL_USERS_CREATED_AT: 'Created',
    ADMIN_EXTERNAL_EXPIRED: 'Invitation active',
    ADMIN_NEW_EXTERNAL_USER: 'Add user',
    ADMIN_EXTERNAL_USERS_RESEND: 'Send invitation again',
    ADMIN_EXTERNAL_USERS_DELETE: 'Delete external users',

    ADMIN_EXTERNAL_USER_MODAL_HEADER: 'Invite external user',
    ADMIN_EXTERNAL_ADD_MODAL_ADD_MORE_INFO:
        'Please separate multiple e-mail addresses using commas.<br/>Example: "laura@laughs.com, mark@rocks.com, ..."',

    ADMIN_EXTERNAL_USER_DELETE_HEADER: 'Delete external user',
    ADMIN_EXTERNAL_USER_DELETE_TEXT:
        'Do you really want to delete the user with the e-mail address {{ email }}? All user data will be erased in the process!',
    ADMIN_EXTERNAL_USER_REFRESH_HEADER: 'Invite external user again',
    ADMIN_EXTERNAL_USER_REFRESH_TEXT: 'Do you want to invite {{ email }} again?',

    ADMIN_EXTERNAL_USER_ALREADY_USERS: 'Users with the e-mail addresses {{ emails }} already exist.<br/>',
    ADMIN_EXTERNAL_USER_ALREADY_USER: 'A user with the e-mail address {{ email }} already exists.<br/>',

    NO_ROLES: 'Oops... you are not authorized to access this site. Contact your admin to receive the necessary rights.',

    DELETE_ADMIN_HEADER: 'Delete administrator rights',
    DELETE_ADMIN_TEXT: 'Do you really want to delete all administrator rights for the e-mail {{ name }}?',
    DELETE_MESSAGE_HEADER: 'Delete e-mail',
    DELETE_MESSAGE_TEXT_ADMIN_NEWS: 'Do you really want to delete the news from {{ creator }}?',
    DELETE_MESSAGE_TEXT_ADMIN_EMAIL: 'Do you really want to delete the e-mail from admin_workz?',

    JOB_ACTION_EDIT: 'Edit',
    JOB_ACTION_PAUSE: 'Pause',
    JOB_ACTION_UNPAUSE: 'Publish',
    JOB_ACTION_DELETE: 'Delete',

    FAQ_TITLE: 'Questions and answers',

    TRY_AGAIN: 'A technical error occurred. Please try again or contact us via <a href="{{href}}">{{linkText}}</a>.',
    LOGIN_ERROR: 'Wrong e-mail or password',
    SIGN_UP_LOGIN_BAD_CREDENTIALS: 'E-mail address and/or password incorrect. Please check the credentials.',
    LOGIN_ERROR_LONG:
        'Not registered yet? Register now and create your own profile on {{ appName }} to find flexible working models.',
    LOGIN_ERROR_LONG_ACTION: 'Register now',
    LOGIN_ERROR_SHORT_ACTION: 'Register',
    PASSWORD_RESET_ERROR:
        "Unfortunately, the link to reset your password is not valid anymore. Please reset your password again. In case it's still not working, please contact support@tandemploy.com",
    TOKEN_EXPIRED_ERROR: 'The link in your email has already expired. Please reset your password again.',
    TOKEN_EXPIRED_ERROR_BTN: 'Reset password',

    EMAIL_ALREADY_EXISTS: 'An account already exists for this e-mail address.',
    NICKNAME_ALREADY_EXISTS: 'Already used.',
    NICKNAME_INVALID: 'Invalid name',
    PASSWORD_NOT_STRONG_ENOUGH: 'Your password is not strong enough.',
    PASSWORD_REUSED: 'You have already used this password in the past. Please choose a new one.',
    USERNAME_TOO_SHORT: 'Your user name must contain at least 5 characters.',
    USERNAME_REQUIRED: 'User name required.',
    USERNAME_ALLREADY_USED: 'The selected user name already exists.',
    EMAIL_REQUIRED: 'E-mail address required',
    EMAIL_INVALID: 'Please enter a valid e-mail address',
    EMAILS_INVALID: 'The following e-mail addresses are not valid: {{ emails }}',
    EMAIL_NOT_SUPPORTED: 'This e-mail address is not supported.',
    EMAIL_ALLREADY_USED: 'The e-mail address you have entered already exists.',
    NICKNAME_REQUIRED: 'Nickname required',
    NICKNAME_MINLENGTH: 'Your name must contain at least 4 characters',
    NICKNAME_ALLREADY_USED: 'The name you have entered already exists.',
    PASSWORD_REQUIRED: 'Password required',

    VALIDATION_REQUIRED: 'This field is required. Please fill in.',
    VALIDATION_INVALID: 'Please re-check this field',
    VALIDATION_AT_LEAST_ONE_REQUIRED: 'At least one tag required',

    PASSWORD_MINLENGTH: 'at least {{length}} characters in total',
    PASSWORD_MINLENGTH_LONG: 'Your password must contain at least {{length}} characters',
    PASSWORD_TWO_LETTERS: 'min. 2 non alphabetic characters',
    PASSWORD_NO_LICENCE_PLATE: 'no license plate',

    PASSWORD_REPEAT_REQUIRED: 'Your passwords do not match yet',
    PASSWORD_INVALID: "'The password needs to contain 2 non-letter characters",
    SERVERERRORS: 'Errors',
    NAMEINFO: 'Information',
    WARNINGINFO: 'Information',
    PASSWORD_NO_SINGLE_WORD: 'cannot match a single dictionary word',
    NICKNAME_ANONYM_INFO: 'If you prefer to stay anonymous for the time being, better choose a fantasy name.',
    MAGE_WRONG_FORMAT: 'Only images in JPEG or PNG format can be uploaded.',
    PROFILE_CREATE_WARNING:
        'The clever solution for true diversity, work-life-balance, compatibility of family & career and empowerment.',
    SHOW_AGB_PRIVACY_FIRST: 'I agree to the ',
    SHOW_AGB_PRIVACY_SECOND: ' and ',
    SHOW_AGB_PRIVACY_THIRD: '.',
    SHOW_AGB_LINK: 'terms of use',
    SHOW_PRIVACY_LINK: 'privacy policy',
    SUBSCRIBE_NEWSLETTER: 'subscribe to newsletter',

    ACCEPT_PRIVACY_HEADLINE: 'Terms of Use',
    ACCEPT_PRIVACY_TEXT:
        'Please read through and agree to the terms of use that are linked below. By activating the checkbox and clicking "I agree" you accept the terms and will be redirected to your accout.',

    AGB_TITLE: 'Terms and conditions',
    AGB_EFFECTIVE: 'Status: 12/2015',
    AGB_LINK: 'documents/generic/Tandemploy_AGB.pdf',
    AGB_LINK_TEXT: 'Download here',
    CONDITIONS_TITLE: 'General terms and conditions of use for job sharers',
    CONDITIONS_EFFECTIVE: 'Status: 12/2015',
    PRIVACY_LINK_TEXT: 'Download here',
    PRIVACY_DOWNLOAD_LINK: 'documents/generic/privacy_statement.pdf',
    PRIVACY_EFFECTIVE: 'Status: 01/2016',
    PRIVACY_TITLE: 'Privacy policy',

    PLEASE_SIGNOUT_FIRST:
        'You are already registered. If you are logged in by mistake, please log out first using the menu on the left side.',

    WORKING_WEEK_ERROR_LONG: 'You definitely work too much! More than 48 hours are prohibited by law :-)',
    WORKING_EXP_ERROR_LONG: 'At your age, you should retire and enjoy your time :-)',
    ERROR_POSITVE_NUMBER: 'Please enter a positive number.',

    UPLOADER_DUPLICATE: 'You have already attached this file.',
    UPLOADER_WRONG_FORMAT: 'This file format is not supported.',
    UPLOADER_MAX_REACHED: 'Unfortunately, we could not add the file. Please only attach files up to a maximum of 10MB.',
    JOB_APPLICATION_DUBLICATE: 'You have already applied to this job.',
    JOB_APPLICATION_SUCCESSFUL: 'Thank you for your application. You will shortly receive a confirmation e-mail.',
    ALLREADY_APPLIED:
        'You have already applied three times for this job recently. In case you wish to apply again, please contact support.',

    INTERNAL_ERROR:
        'Ooops... unfortunately something went wrong. Please try to reload the site or contact us at support@tandemploy.com!',
    SERVER_NOT_RESPONDING:
        'Ooops... unfortunately our server has no tandem partner helping him out when he is down. We are working hard on getting him back to business as usual. Give us a second chance and come back later or contact us at suppport@tandemploy.com! ',
    NO_AUTH: 'Ooops... you are not authenticated. Please create an account or sign in to use the platform',
    ERROR_COOKIES_DISABLED: 'To use the platform to its full extent, cookies must be enabled in your browser.',

    USER_DELETED: "This user has been deleted. Therefore you can't leave a message.",

    JOBSHARER_NOTHING_FOUND: 'Unfortunately, there are no suitable search results yet.',

    ADD_TAG_TOO_LONG: 'The tag you tried to add is too long. A maximum of 50 characters are allowed.',
    ADD_TAG_NOT_UNIQUE: 'The tag you tried to add is not unique. You may only add each tag once.',

    FINISH_YOUR_PROFILE:
        "Your profile is not complete yet. Please complete your profile to use all our platform's features and our matching.",
    FINISH_YOUR_PROFILE_ON_DESKTOP:
        'Your profile hasn’t been filled out yet. To do so, please use a desktop computer or a tablet, as editing is not currently possible on smaller devices.',
    ANSWER_MATCHING_QUESTIONNAIRE:
        "How about telling your colleagues a bit more about you, how you work and what's important for you when collaborating with others? Through this, you increase the probability of finding suitable colleagues for your projects. Of course, you provide this information on a voluntary basis.",
    ANSWER_MATCHING_QUESTIONNAIRE_MORE:
        'Complete the matching questionnaire and find suitable colleagues even more easily.',
    PROFILE_FINISH_BUTTON: 'Your profile',

    TOO_MANY_MESSAGES:
        'You have written so many messages in such a short time that we are wondering whether you are a real person or a little bot. Please take a short break!',

    PROFILE_CREATE_SUCCESS:
        'Thank you for taking the time! Now you can start looking for a suitable tandem partner – and the perfect job to share!',
    PROFILE_UPDATE_SUCCESS: 'Your profile has been saved successfully.',
    PROFILE_CREATE_CANCEL_HEADER: 'Do you really want to cancel?',
    PROFILE_CREATE_CANCEL_TEXT: 'If you click on "Cancel", all changes will be lost.',

    ON_MOBILE:
        'Unfortunately, this page has not been optimized for this display size yet. If you are working on a tablet, please rotate from portrait to landscape mode or change to a bigger screen. We are working at full speed to create a mobile version of our platform. Thank you for your understanding!',

    TOO_MANY_LETTERS: 'The size limit for messages is 250 characters.',
    TOO_MANY_LETTERS_1000: 'The size limit for messages is 1000 characters.',

    RESET_PASSWORD_SUCCESS: 'Your password has been reset successfully. You can now log in with the new password.',

    UNSUPPORTED_BROWSER:
        'Your browser is possibly outdated or not supported. <a href="http://browsehappy.com/" target="_blank">Please update your browser</a>. The latest versions of Firefox, Chrome and Safari are supported.',

    E_MAIL_VALIDATION:
        'Your registration is almost complete. Please confirm your registration by following the link you have received via e-mail.',
    PASSWORD_BLOCKED:
        "You must renew your password! You have not logged in to {{ appName }} for some time so for security reasons we've deactivated your account but just for now. A password renewal link has just been sent to you by e-mail which you can use to re-activate right away.",
    BROWSER_BACK_CONFIRM: "Do you really want to leave this page? Unsaved data will be lost if you don't save it.",
    ACCOUNT_NOT_ACTIVE: 'This account has been deactivated. Create a new password to log in again.',

    DASHBOARD_NEWS_TILE_TITLE: 'Messages',
    DASHBOARD_NEWS_EDITOR_PLACEHOLDER: 'Enter your message...',
    DASHBOARD_NEWS_TILE_LINK: 'All messages',
    DASHBOARD_NEWS_TILE_ARIA_LABEL: 'To my messages',
    DASHBOARD_NEWS_TILE_NEWS_LIST_ITEM_ARIA_LABEL: 'To the message from {{nicknames}}',
    DASHBOARD_NEWS_EDITOR_SEND_MESSAGE_ARIA_LABEL: 'Send message to {{nicknames}}',

    DASHBOARD_BLOG_TILE_TITLE: 'Articles',
    DASHBOARD_BLOG_TILE_LINK: 'All articles',
    DASHBOARD_BLOG_TILE_ARIA_LABEL: 'All articles',
    DASHBOARD_BLOG_TILE_ARTICLE_LIST_PREV_BTN_ARIA_LABEL: 'Preview of the previous article',
    DASHBOARD_BLOG_TILE_ARTICLE_LIST_NEXT_BTN_ARIA_LABEL: 'Preview of the next article',
    DASHBOARD_BLOG_TILE_ARTICLE_ARIA_LABEL: 'To the article {{articleTitle}}',

    DASHBOARD_PROFILE_TILE_TITLE: 'My profile',
    PROFILE_TILE_LINK: 'To profile',
    DASHBOARD_PROFILE_TILE_ARIA_LABEL: 'To my profile',

    DASHBOARD_JOB_TILE_TITLE: 'Offers',
    JOB_OFFER_FEATURE_JOBSHARING: 'Job sharing',
    JOB_OFFER_FEATURE_PROJECT: 'Projects',
    JOB_OFFER_FEATURE_JOBROTATION: 'Job rotation',
    JOB_OFFER_FEATURE_WORKING_CIRCLES: 'Working Circles',

    DASHBOARD_JOB_CREATION_TILE_TITLE: 'Create offers',
    DASHBOARD_JOB_CREATION_TILE_TEASER: 'Who, if not you?',
    DASHBOARD_JOB_CREATION_TILE_TEASER_STRONG: 'Start new things with colleagues.',

    DASHBOARD_JOB_CREATION_LINK_JOBSHARING: 'Job sharing',
    DASHBOARD_JOB_CREATION_LINK_JOBROTATION: 'Job rotation',
    DASHBOARD_JOB_CREATION_LINK_PROJECTS: 'Projects',
    DASHBOARD_JOB_CREATION_LINK_WORKING_CIRCLES: 'Working Circle',

    DASHBOARD_INVITATION_TILE_CONTENT: 'Bring more colleagues onboard!',
    DASHBOARD_INVITATION_TILE_HOVER_CONTENT: 'Because this platform only grows with your help.',
    DASHBOARD_INVITATION_TILE_TITLE: 'Invite colleagues',
    DASHBOARD_INVITATION_TILE_ARIA_LABEL: 'To the form Invite colleagues',

    DASHBOARD_SURVEY_TILE_TITLE: 'Colleague echo',
    DASHBOARD_SURVEY_TILE_LINK: 'Show all',

    DASHBOARD_COLLEAGUE_TILE_TITLE: 'My Colleagues',
    DASHBOARD_COLLEAGUE_TILE_ARIA_LABEL: 'most important Matching page',

    DASHBOARD_FAQ_TILE_TITLE: 'Questions and Answers',
    DASHBOARD_FAQ_TILE_CONTENT: 'Questions?',
    DASHBOARD_FAQ_TILE_HOVER_CONTENT: "Here you'll find answers!",

    DASHBOARD_INFORMATION_TILE_TITLE: 'Information',
    DASHBOARD_INFORMATION_TILE_CONTENT: 'Browse information',

    SURVEY_TIME_REMAINING: 'Time remaining',
    SURVEY_END_TIME: 'End of the question',
    SURVEY_MULTIPLE_ANSWER: 'Multiple answers are possible.',
    SURVEY_SINGLE_ANSWER: 'Choose one answer.',
    SURVEY_SUBMIT_ANSWERS: 'Answer',
    SURVEY_RESULTS: 'Results',
    SURVEY_RESULTS_THANKS: 'Thank you for your insight. Have a look at the results:',
    SURVEY_RESULTS_LABEL: ' Results of the answered question:',
    SURVEY_QUESTION_THANKS: 'Thank you for your participation',
    SURVEY_NEXT: 'Next question',
    SURVEY_TILE_TEASER: 'Take me to the questions.',
    SURVEY_QUESTION_COMPLETE: 'Thank you, you have answered all the questions.',

    BLOG_MENU: 'Categories',
    BLOG_MODAL_TITLE: 'Articles',
    BLOG_CLOSE_LABEL: 'Dashboard',
    BLOG_CLOSE_LABEL_LONG: 'back to the Dashboard',
    BLOG_CATEGORY_MENU_CLOSE: 'Close blog menu',
    BLOG_CATEGORY_MENU_TITLE: 'Categories',
    BLOG_CATEGORY_ALL: 'Show all articles',

    BLOG_CATEGORY_LABEL: 'Category',
    BLOG_FILTER_LABEL: 'Filter by',
    BLOG_CATEGORY_SHOW_ALL_LABEL: 'All articles',
    BLOG_FILTER_ALL: 'All',
    BLOG_FILTER_UNREAD: 'Unread',
    BLOG_FILTER_READ: 'Read',
    BLOG_NAVIGATION_HEADING: 'Further articles in',
    BLOG_ARTICLE_PREV_BTN_ARIA_LABEL: 'To the previous article {{articleTitle}}',
    BLOG_ARTICLE_NEXT_BTN_ARIA_LABEL: 'To the next article {{articleTitle}}',
    BLOG_ARTICLE_TITLE_ARIA_LIVE_MSG: '{{articleTitle}} is opened',

    NEWS_MESSAGE_FROM: 'From',
    NEWS_MODAL_TITLE: 'Messages',
    NEWS_CLOSE_LABEL: 'Dashboard',
    NEWS_BACK_TO_INDEX_LABEL: 'Back',
    NEWS_NO_MESSAGE: 'No messages yet',
    NEWS_DELETED_USER_NICKNAME: 'Deleted user',

    INVITATION_FORM_TITLE: 'Invitation',
    INVITATION_FORM_INVITEE_NAME: 'Name of the recipient',
    INVITATION_FORM_INVITEE_EMAIL: "Recipient's email address",
    INVITATION_FORM_INVITEE_EMAIL_DOMAIN_ERROR: 'Please enter a valid e-mail address.',
    INVITATION_FORM_INVITEE_EMAIL_ALREADY_SENT_ERROR: "You've already sent an invitation to that colleague.",
    INVITATION_FORM_BODY_PLACEHOLDER:
        'Hi [{{recipient_token}}] \n \n I was just checking out our new company internal tool, {{platform_name}}, and thought of you. You might want to take a look. The Sign up is fast and you can use {{platform_name}} anonymously if you like. \n \n nKind regards, ',
    INVITATION_FORM_BODY_RECIPIENT_TOKEN: 'Name of the recipient',
    INVITATION_FORM_BUTTON_LEGEND: 'Submit',
    INVITATION_FORM_BUTTON_ARIA_LABEL: 'Send invitation',
    INVITATION_FORM_BODY_LABEL: 'You can leave a personal message',
    INVITATION_FORM_BODY_INFO: 'Your email address will not be shown to the recipient.',
    INVITATION_FORM_MESSAGE_ARIA_LABEL: 'Write a personal invitation.',
    INVITATION_SENT_FEEDBACK: 'Invitation has been sent',
    INVITATION_SENT_AGAIN_BUTTON: 'Invite others',
    INVITATION_SENT_THANK_YOU_1: 'Thank you',
    INVITATION_SENT_THANK_YOU_TEXT_1:
        'Thanks for spreading the word. You and your network are what make {{platform_name}} work.',
    INVITATION_SENT_THANK_YOU_2: 'Wow,',
    INVITATION_SENT_THANK_YOU_TEXT_2:
        'you are great! You are one of the "first movers" and we need you! Do you have feedback or suggestions? Please let us know via e-mail at support@tandemploy.com.',
    INVITATION_SENT_THANK_YOU_3: 'Seriously,',
    INVITATION_SENT_THANK_YOU_TEXT_3:
        "we value your commitment! At this rate you'll have all your colleagues on here! Nice!",
    INVITATION_SENT_THANK_YOU_4: 'nothing can stop you!',
    INVITATION_SENT_THANK_YOU_TEXT_4:
        "It's your {{counter}}th invitation! We have no more messages for you - we are simply speechless. Thank you for changing the working world by using this platform. Good luck to you and all invited colleagues by moving things forward!",

    INVITATION_MODAL_TITLE: 'Invite colleagues',
    INVITATION_MODAL_CONTENT_TITLE: 'Send an invitation to one or more co-workers so they know about this platform.',
    INVITATION_MODAL_CONTENT_TEXT: 'Only company e-mail addresses are allowed.',
    INVITATION_MODAL_RECIPIENTS: 'E-mail addresses of colleagues',
    INVITATION_MODAL_INVALID_EMAIL_VALIDATION_MSG: 'Only company e-mail addresses are allowed.',
    INVITATION_MODAL_SEND_BTN: 'Send invite',
    INVITATION_MODAL_SENT_FEEDBACK: 'Invitation has been sent',

    PROFILE_EDIT_LABEL: 'To profile',
    PROFILE_MESSAGE_LT_40_TITLE: 'Not a bad start!',
    PROFILE_MESSAGE_LT_40:
        'However, for our algorithm to find matches, a few more details would be a really good idea.',
    PROFILE_MESSAGE_B_40_80_TITLE: 'Yeah! Great start.',
    PROFILE_MESSAGE_B_40_80:
        "You have taken the first steps. Still, adding more details will improve your matches and your profile's look even more.",
    PROFILE_MESSAGE_GT_80_TITLE: 'Wow. Good job!',
    PROFILE_MESSAGE_GT_80:
        'Your profile is commendable. By adding more skills or other details you may achieve even better results.',
    PROFILE_COMPLETED_SCORE: 'Your profile is {{score}}% completed.',
    PROFILE_EDIT_DETAILS_LABEL: 'Edit profile details',

    DASHBOARD_PREVIEW_TILE_TITLE: 'Videos',
    DASHBOARD_PREVIEW_TILE_TEXT: 'Watch videos',
    DASHBOARD_PREVIEW_TILE_ARIA_LABEL: 'Watch videos',

    DASHBOARD_QUESTIONNAIRE_TILE_TITLE: 'Questionnaire',
    DASHBOARD_QUESTIONNAIRE_TILE_TEXT: 'Optimized matching',
    DASHBOARD_QUESTIONNAIRE_TILE_ARIA_LABEL: 'To the personal questionnaire',

    DASHBOARD_SUGGESTION_HEADER: 'Find new colleagues...',

    TAG_SUGGESTION_LABEL: 'Select from used ones',
    TAG_SUGGESTION_SHOW_ALL: 'Show all',
    TAG_SUGGESTION_SHOW_LESS: 'Show less',
    TAG_SUGGESTION_SELECT_ALL: 'Select all',
    TAG_SUGGESTION_ADD_ALL: 'Add all',
    TAG_SUGGESTION_HIDE: 'Hide',
    TAG_SUGGESTION_DESELECT_ALL: 'Remove all',
    TAG_SUGGESTION_SKILLS_LABEL: 'Used before',
    TAG_TECH_WOLF_SKILLS_LABEL: 'Suggested terms by Tandemploy AI',

    INDIFFERENT: "I don't mind",
    NO_LEADERSHIP: 'no',
    LEADERSHIP: 'yes',

    SIGN_UP_TITLE: 'Sign-up',

    SIGN_UP_SUCCESS_PAGE_TITLE: 'Welcome to Tandemploy!',
    SIGN_UP_SUCCESS_PAGE_TEXT:
        'This is your space to work in a more networked and flexible way. Find colleagues and experts from other areas for knowledge exchange, sparring partners for flexible forms of work and great projects for cooperation. Let’s get started! Your colleagues are looking forward to working with you!',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_LABEL: 'Your desired username',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_INFO:
        'Choose a pseudonym if you prefer to remain anonymous. You can change your name later at any time. Read more about this topic under "Anonymity" on this page.',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_PLACEHOLDER: 'Name',
    SIGN_UP_SUCCESS_PAGE_ICON_1_TITLE: 'Data privacy',
    SIGN_UP_SUCCESS_PAGE_ICON_1_INFO:
        "{{ appName }} is a proprietary, in-house space that thrives on user engagement and trust. That's why protecting your data is a key concern. Even administrators and supervisors can only evaluate anonymous statistics. The data generated on {{ appName }} will be securely encrypted and stored in a high-security data center in Europe.",
    SIGN_UP_SUCCESS_PAGE_ICON_1_TEXT: 'Your personal and professional data will not be disclosed to third parties.',
    SIGN_UP_SUCCESS_PAGE_ICON_2_TITLE: 'Anonymity',
    SIGN_UP_SUCCESS_PAGE_ICON_2_INFO:
        'Want to remain anonymous for now and move around on the platform incognito? No problem. Your e-mail address is not visible to administrators or other users and cannot be associated with your username. If you want, you can choose a pseudonym as a username and hide additional personal profile information under "Settings" once your profile creation is complete.',
    SIGN_UP_SUCCESS_PAGE_ICON_2_TEXT: 'Personal details, such as name and e-mail address, can be hidden.',
    SIGN_UP_SUCCESS_PAGE_ICON_3_TITLE: 'Full control',
    SIGN_UP_SUCCESS_PAGE_ICON_3_INFO:
        'Your account, your data – your decision! Whether you open an account, use it on a(n) (ir-)regular basis or choose to delete it: it is your decision and freedom. This tool relies on your interest, your motivation and initiative – and wants to move away from top-down control.',
    SIGN_UP_SUCCESS_PAGE_ICON_3_TEXT: 'You can delete your account and your data at any time.',
    SIGN_UP_SUCCESS_PAGE_BUTTON: "Let's go!",
    SIGN_UP_SUCCESS_PAGE_TITLE_RECONFIRM_LEGACY_USERS:
        'It’s great that you’re here again – the re-confirmation of your account was successful!',
    SIGN_UP_SUCCESS_PAGE_TEXT_RECONFIRM_LEGACY_USERS:
        'So that {{ appName }}-Matching can propose suitable offers, we’ll ask a few questions. Please take a moment to answer them.',
    SIGN_UP_SUCCESS_PAGE_WELCOME_TITLE: 'The matching software for New Work and a networked organization.',

    SIGN_UP_MODULE_SELECTION_TITLE: 'Make your choice!',
    SIGN_UP_MODULE_SELECTION_SUB_TITLE: 'Select your topics with one click and drag your favourite to the top.',
    SIGN_UP_MODULE_SELECTION_INFO:
        'In this step we would like to know which of the available features personally interest you the most. This feedback is used to adapt and further develop {{ appName }} to your needs and interests. Your company can also use anonymized statistics to find out what significance individual topics have for employees.',
    SIGN_UP_MODULE_SELECTION_VALIDATION_ALERT: 'Please select at least one topic.',
    SIGN_UP_MODULE_SELECTION_MOBILE_TITLE: 'Topic Selection',
    SIGN_UP_MODULE_SELECTION_MOBILE_SUB_TITLE: 'Select topics that interest you.',
    SIGN_UP_MODULE_SELECTION_MOBILE_INFO:
        'To optimize the app for your needs, you can select all topics that interest you here. In the next step you can decide which of them are most important to you.',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_TITLE: 'Your Priorities',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_SUB_TITLE: 'Sort the topics by their importance.',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_INFO:
        'Here you determine in which order you would personally prioritize the selected topics. Your main interest comes first. The following questions of profiling depend on this prioritization and possible changes to it.',
    SIGN_UP_NETWORKING_TILE_TITLE: 'Networking',
    SIGN_UP_NETWORKING_TILE_TEXT: 'Network with interesting colleagues, exchange ideas, and learn from each other.',
    SIGN_UP_NETWORKING_TILE_INFO:
        "The Networking topic comprises many different ways to connect, e.g. special fields of expertise, personal interests or locations. In addition, there is the chance to specify certain groups of colleagues that you'd like to meet. On the following steps, we'll ask you to pick some of your favourites. You'll be able to add details any time later.",
    SIGN_UP_REVERSE_MENTORING_TILE_TITLE: 'Reverse Mentoring',
    SIGN_UP_EXPATS_TILE_TITLE: 'Expats',
    SIGN_UP_EXPERT_TILE_TITLE: 'Find experts',
    SIGN_UP_INTERNSHIP_TILE_TITLE: 'Trainee, intern, apprentice',
    SIGN_UP_ONBOARDING_TILE_TITLE: 'Onboarding and settling in',
    SIGN_UP_PARENTAL_LEAVE_TILE_TITLE: 'Parental leave',
    SIGN_UP_PEER_LEARNING_TILE_TITLE: 'Peer Learning',
    SIGN_UP_RETRAINING_TILE_TITLE: 'Retraining',
    SIGN_UP_SABBATICAL_TILE_TITLE: 'Sabbatical',
    SIGN_UP_TRAINING_TILE_TITLE: 'Advanced training',
    SIGN_UP_JOBSHARING_TILE_TITLE: 'Job sharing',
    SIGN_UP_JOBSHARING_TILE_TEXT: 'Discover tandem partners and shared positions (for possible job sharing).',
    SIGN_UP_JOBSHARING_TILE_INFO:
        'Job sharing is a flexible working time model in which two or more employees share at least one full-time position, working very closely together as a team and setting their individual working hours and tasks among themselves. Job sharing can also be very interesting in management positions (so-called top-sharing /co-leadership). The matching algorithm of {{ appName }} finds suitable tandem partners among your colleagues and informs you by e-mail of new matches.',
    SIGN_UP_MENTORING_TILE_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_TILE_TEXT: 'For professional and/or personal development: be or find a mentor.',
    SIGN_UP_MENTORING_TILE_INFO:
        'Are you looking for a contact person for professional development or your career? Then all you’re missing are suitable mentors. {{ appName }} not only finds suitable mentoring matches for you, but also gives employees themselves the opportunity to be mentors in specific disciplines. Self-organization can really be this easy.',

    SIGN_UP_RATING_TITLE: 'Which topics are particularly important to you?',
    SIGN_UP_RATING_SUB_TITLE: 'Please weight all selected topics.',
    SIGN_UP_RATING_SUB_INFO:
        'This step is about specifying what meaning the chosen topics have for you personally. The feedback is used to adapt {{ appName }} to your needs and, in the future, to further develop according to your interests. Your company can also use anonymized statistics to find out what significance individual topics have for employees.',
    SIGN_UP_RATING_TILE_TEXT: 'Please weight the importance of the topic.',
    SIGN_UP_RATING_DESCRIPTION: 'Importance of the topic.',
    SIGN_UP_RATING_SCALE_LABEL_1: 'not too bad',
    SIGN_UP_RATING_SCALE_LABEL_2: 'normal',
    SIGN_UP_RATING_SCALE_LABEL_3: 'important',
    SIGN_UP_RATING_SCALE_LABEL_4: 'more important',
    SIGN_UP_RATING_SCALE_LABEL_5: 'very important',
    SIGN_UP_RATING_DATA_SNACK: 'Each time you click "Next", your previous information will be saved.',

    SIGN_UP_JOBSHARING_TITLE: 'Job Sharing',
    SIGN_UP_JOBSHARING_SUB_TITLE: 'Double the power in one job.',
    SIGN_UP_JOBSHARING_INFO:
        'Are you interested in job sharing, but don’t exactly know how it would really work? The good news is that with this software, the company actively supports you to really tackle and implement job sharing. However, a bit of your own initiative is also needed: to find potential tandem partners or suitable positions in the company, you can a) describe your current work area, so that it can be found by others on the platform (the "Offers" section in your profile and the "Offers" subpage). Or you can b) simply describe a desired job that you would like to have in the job share. This won’t be visible to other users. However, based on this information, you can automatically receive suitable offers by e-mail and the software can suggest suitable people.',
    SIGN_UP_JOBSHARING_CONTENT_TITLE: 'How do you want to start?',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_LABEL: 'Describe my current area of work for potential tandem partners.',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_INFO:
        'In the next step, you can describe your current area of work to other users. Your information will make an offer visible to other users (the "Offers" section in your profile, subpage "Offers"). You can customize, pause or delete this at any time. Providing this information makes it easier for other users to find you or be identified by e-mail about a match.',
    SIGN_UP_JOBSHARING_MOTIVE_FIND_JOB_LABEL: 'Find a new job-sharing job and describe the desired area of work.',
    SIGN_UP_JOBSHARING_MOTIVE_FIND_JOB_INFO:
        'Why only orient yourself with the status quo? Here you can describe your individual desired job, which you might share in the future with a suitable tandem partner. Based on this description, you can automatically receive relevant offers by e-mail and the software can propose suitable people to you.',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_LABEL_DEACTIVE:
        'Describe my area of work. (This option is only available during registration. For more details please check info).',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_INFO_DEACTIVE:
        'After completing the sign-up, you are able to describe your area of work inside a new "offer" which you can create from your profile page.\n\nIn case you chose this option during the sign-up, you can find, modify and delete your created offer any time from your profile page.',

    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_TITLE: 'Describe the current area of work',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_INFO:
        'Describe your current your of work, the necessary skills, and complementary skills that would ideally be brought in by a potential tandem match. Based on your input, an offer will be created and made visible to other users ("Offers" section in your profile, "Offers" subpage), which you can customize, pause, or delete at any time, making it easier for other users to find you or be informed of a match by e-mail.',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_CONTENT_TITLE: 'Describe your area of activities for possible tandem partners',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_POSITION_LABEL: 'Your current title (position)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_POSITION_PLACEHOLDER: 'e.g., senior account manager',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_LABEL: 'This is a leadership position',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_SLIDER_LABEL_NOT_CHECKED: 'No (default)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_SLIDER_LABEL_CHECKED: 'Yes',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DEPARTMENT_LABEL: 'Your department',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DEPARTMENT_PLACEHOLDER: 'e.g., distribution',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_LABEL: 'Your skills that are relevant for this job',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_PLACEHOLDER: 'e.g., supply chain management, SCM, logistics',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LANGUAGES_LABEL: 'Business language(s)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LANGUAGES_PLACEHOLDER: 'e.g. German, English, French, Spanish',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_COMPLEMENTARY_LABEL:
        'Your tandem partner ideally has better/complementary knowledge of',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_COMPLEMENTARY_PLACEHOLDER: 'e.g., ERP, SCM tools, just-in-time',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LOCATION_LABEL: 'Your current work location',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LOCATION_PLACEHOLDER: 'e.g., Berlin',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DESIRED_LOCATIONS_LABEL: 'I could exercise this job in',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_LABEL: 'Your desired weekly hours',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PLACEHOLDER: 'e.g., 20',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PARTNER_LABEL: 'Weekly hours of the tandem partner',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PARTNER_PLACEHOLDER: 'e.g., 20',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_JOB_DESCRIPTION_LABEL: 'Job description',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_JOB_DESCRIPTION_PLACEHOLDER: '',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DESCRIPTION_LABEL:
        "If you'd like to describe your current job in more details, go ahead:",
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LIKED_ABOUT_LABEL:
        'What are the things that you like most about your current job?',

    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_TITLE: 'Details for your job sharing profile',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_INFO:
        'Here you can describe your individual desired job, which you might share in the future with a suitable tandem partner. Based on this description, you can automatically receive relevant offers by e-mail and the software can propose suitable people to you.',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LEADERSHIP_LABEL:
        'I want to (continue to) work in a leadership position in the future',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LEADERSHIP_PLACEHOLDER: '',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_FIELD_LABEL: 'Areas where you want to work',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_FIELD_PLACEHOLDER: 'e.g., supply chain management, SCM, logistics',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_DEPARTMENT_LABEL: 'Desired department/area',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_DEPARTMENT_PLACEHOLDER: 'e.g., purchasing',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_LOCATIONS_LABEL: 'Work locations that are suitable for you',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_LOCATION_PLACEHOLDER: 'e.g., Hamburg',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LOCATION_LABEL: 'Current work location',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_SKILLS_LABEL: 'Knowledge that you bring along',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_SKILLS_PLACEHOLDER: 'e.g., SCM, SCM strategy, efficient consumer response',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LANGUAGES_LABEL: 'Business language(s)',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LANGUAGES_PLACEHOLDER: 'e.g., German, English, French, Spanish',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_LABEL: 'Your desired weekly hours',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PLACEHOLDER: 'e.g., 20',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PARTNER_LABEL: 'Weekly hours of the tandem partner',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PARTNER_PLACEHOLDER: 'e.g., 20',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_COMPLEMENTARY_SKILLS_LABEL:
        'Your tandem partner has better or complementary knowledge of',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_COMPLEMENTARY_SKILLS_PLACEHOLDER: 'e.g., ERP, SCM tools, just-in-time',

    SIGN_UP_MENTORING_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_SUB_TITLE: 'For professional and/or personal development: Be or find a mentor.',
    SIGN_UP_MENTORING_INFO:
        'Are you tired of Excel spreadsheets and expensive mentoring searches? With the help of {{ appName }}, employees can come together on their own for mentoring tandems. The matching system brings together colleagues who can help each other solve problems and reach goals, as well as share their valuable knowledge.',
    SIGN_UP_MENTORING_CONTENT_TITLE: 'What would you like to do?',
    SIGN_UP_MENTORING_MOTIVE_INTEREST_LABEL: "I'd like to find a mentor.",
    SIGN_UP_MENTORING_MOTIVE_OFFERING_LABEL: "I'd like to become a mentor.",

    SIGN_UP_MENTORING_DETAILS_INTEREST_TITLE: 'Find a mentor',
    SIGN_UP_MENTORING_DETAILS_INTEREST_INFO:
        'Are you tired of Excel spreadsheets and expensive mentoring searches? With the help of {{ appName }}, employees can come together on their own for mentoring tandems. The matching system brings together colleagues who can help each other solve problems and reach goals, as well as share their valuable knowledge.',
    SIGN_UP_MENTORING_DETAILS_INTEREST_CONTENT_TITLE: 'What should the mentor support you with?',
    SIGN_UP_MENTORING_DETAILS_INTEREST_MOTIVATION_CAREER_LABEL: 'I want to build my career.',
    SIGN_UP_MENTORING_DETAILS_INTEREST_MOTIVATION_SKILL_LABEL: 'I want help improving my professional qualifications.',
    SIGN_UP_MENTORING_DETAILS_INTEREST_POSITION_LABEL: 'Your current job title',
    SIGN_UP_MENTORING_DETAILS_INTEREST_POSITION_PLACEHOLDER: 'e.g., senior account manager',
    SIGN_UP_MENTORING_DETAILS_INTEREST_FIELD_LABEL: 'In which fields would you like a mentor?',
    SIGN_UP_MENTORING_DETAILS_INTEREST_FIELD_PLACEHOLDER: 'e.g., team leadership, process management',

    SIGN_UP_MENTORING_DETAILS_OFFERING_TITLE: 'Be a mentor',
    SIGN_UP_MENTORING_DETAILS_OFFERING_INFO:
        'With the help of {{ appName }}, employees can come together on their own for mentoring tandems. The matching system brings together colleagues who can help each other solve problems and reach goals, as well as share their valuable knowledge.',
    SIGN_UP_MENTORING_DETAILS_OFFERING_CONTENT_TITLE: 'What kind of mentor would you like to be?',
    SIGN_UP_MENTORING_DETAILS_OFFERING_MOTIVATION_CAREER_LABEL: 'I want to help colleagues build their careers.',
    SIGN_UP_MENTORING_DETAILS_OFFERING_MOTIVATION_SKILL_LABEL:
        'I want to help colleagues improve their professional qualifications.',
    SIGN_UP_MENTORING_DETAILS_OFFERING_POSITION_LABEL: 'Your current job title',
    SIGN_UP_MENTORING_DETAILS_OFFERING_POSITION_PLACEHOLDER: 'e.g., senior account manager',
    SIGN_UP_MENTORING_DETAILS_OFFERING_FIELD_LABEL: 'In which fields can you provide professional advice?',
    SIGN_UP_MENTORING_DETAILS_OFFERING_FIELD_IMPROVE_PLACEHOLDER: 'e.g., online marketing, leadership, negotiation',

    SIGN_UP_NETWORKING_TITLE: 'Which form of networking appeals to you?',
    SIGN_UP_NETWORKING_SUB_TITLE: 'Network with interesting colleagues, exchange know-how, and learn from each other.',
    SIGN_UP_NETWORKING_INFO:
        "Which colleagues do you want to be shown and recommended in the future? What is especially important to you? What do you want to discuss, and which topics should this platform focus on in the future? That's what you decide! Choose from the various networking topics that excite you and simply answer the questions that follow. Not only does this allow you to configure the app for your own needs, it also makes it easier for other colleagues to find you and approach you.",
    SIGN_UP_NETWORKING_MOTIVE_SKILLS_LABEL: 'Subject areas and specific knowledge.',
    SIGN_UP_NETWORKING_MOTIVE_SKILLS_INFO:
        'Find and network with colleagues in specific disciplines, build your own individual network, and support colleagues with your expertise.',
    SIGN_UP_NETWORKING_MOTIVE_INTERESTS_LABEL: 'Networking based on personal interests.',
    SIGN_UP_NETWORKING_MOTIVE_INTERESTS_INFO:
        'Similar interests, hobbies, other shared concerns and life situations are the best basis for networking and getting to know new colleagues – even beyond your own department. Your details will not appear on the public profile but can be used for future matching with appropriate colleagues.',
    SIGN_UP_NETWORKING_MOTIVE_LOCATION_LABEL: 'Compare notes with colleagues from another company location.',
    SIGN_UP_NETWORKING_MOTIVE_LOCATION_INFO:
        'Are you interested in another location of the company and want to compare notes with colleagues there? Then this is the option for you.',
    SIGN_UP_NETWORKING_MOTIVE_PEOPLE_LABEL: 'Matching by topics and groups of people.',
    SIGN_UP_NETWORKING_MOTIVE_PEOPLE_INFO:
        'This option is preselected and compulsory, as there are several other specific networking opportunities that should not be withheld from you. A list of all topic- and group-related matching options will follow in the next steps.',

    SIGN_UP_NETWORKING_DETAILS_SKILLS_TITLE: 'Specialist Exchange',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INFO:
        'Find and network with colleagues in specific disciplines, build your own individual network, and support colleagues with your expertise.',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INTEREST_LABEL: 'Subject areas and knowledge that interest you',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INTEREST_PLACEHOLDER: 'e.g., controlling, CRM, online marketing',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_OFFERING_LABEL: 'Topics/subject areas in which you can help',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_OFFERING_PLACEHOLDER: 'e.g, controlling, risk analysis',

    SIGN_UP_NETWORKING_DETAILS_INTERESTS_TITLE: 'By personal interests',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_INFO:
        'Similar interests, hobbies, other shared concerns and life situations are the best basis for networking and getting to know new colleagues – even beyond your own department. Your details will not appear on the public profile but can be used for future matching with appropriate colleagues.',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_PERSONAL_INTEREST_LABEL: 'Your interests',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_PERSONAL_INTEREST_PLACEHOLDER: 'e.g., jogging, vegan food, theater, sports',

    SIGN_UP_NETWORKING_DETAILS_LOCATION_TITLE: 'At a specific work location',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INFO:
        'Are you interested in another location of the company and want to compare notes with colleagues there? Then this is the option for you.',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INTEREST_LABEL: 'Work locations you are interested in',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INTEREST_PLACEHOLDER: 'e.g., Munich or San Jose, USA',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_CURRENT_LABEL: 'Your current work location',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_CURRENT_PLACEHOLDER: 'e.g., Berlin',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_OFFERING_LOCATION_SUPPORT_LABEL:
        'Be found by colleagues who are interested in my location.',

    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TITLE: 'Matching by topics and groups of colleagues',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INFO:
        'Networking and intercommunication are a win for everyone. {{ appName }}  helps you sift through colleagues to find the right ones for you and your needs. Here are several suggestions for topics and peer groups. Just choose what you’re interested in or might find interesting in the future. If you scroll all the way down, you are welcome to propose other ideas that will be shared – anonymously, of course –  with the {{ appName }}  team.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SUB_TITLE: 'Which topics and peer groups interest you?',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_QUESTION_LABEL: 'Reverse Mentoring',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_QUESTION_INFO:
        'Exchange between colleagues of different age groups makes perfect sense. But older employees rarely have the opportunity to be mentees again. Reverse mentoring offers the opportunity for experienced older employees to benefit from the special knowledge and perspectives of younger colleagues and thus promotes intergenerational dialogue and knowledge exchange within the company.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_ANSWER_OFFERING:
        "I'm a younger employee and would be happy to help.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_ANSWER_INTEREST:
        "I'd love to gain insights and receive support from young mentors.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_QUESTION_LABEL: 'Peer Learning',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_QUESTION_INFO:
        "It's not just what we learn or why we learn it that's crucial; with whom we learn also plays a role. Who else would be familiar with the same challenges and have similar motivations? The peer-learning approach takes this into account, enabling more productive and true-to-life learning.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_ANSWER_MIXED:
        'I would like to learn new things with colleagues in similar situations/positions.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_QUESTION_LABEL: 'Onboarding and settling in',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_QUESTION_INFO:
        "Those who've been around for a while have a good idea of what they would have wanted to know when they started out. For others, this exact information could make life easier. Why not get together and help create a real team culture during onboarding?",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_ANSWER_OFFERING: 'I’m established and can help.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_ANSWER_INTEREST: 'I’m new and would like to get support.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_QUESTION_LABEL: 'Find experts',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_QUESTION_INFO:
        'If this company knew what its employees knew, would it need external consultants? Discover the knowledge and possible hidden talents of your colleagues and utilize existing expertise.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_ANSWER_MIXED: "I'm looking for experts.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TRAINING_QUESTION_LABEL: 'Advanced training',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TRAINING_ANSWER_MIXED: "I'm interested in advanced training.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_RETRAINING_QUESTION_LABEL: 'Retraining',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_RETRAINING_ANSWER_MIXED: "I'm interested in retraining.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_QUESTION_LABEL: 'Expats and working abroad',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_ANSWER_OFFERING: 'I am an expat or have worked abroad.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_ANSWER_INTEREST:
        "I'm looking for expats or colleagues who have worked abroad.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_QUESTION_LABEL: 'Trainee, intern, apprentice',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_ANSWER_INTEREST: 'I belong to one of these groups.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_ANSWER_OFFERING: 'I want to support one of these groups.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_QUESTION_LABEL: 'Sabbatical',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_ANSWER_OFFERING: 'I have already taken a sabbatical.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_ANSWER_INTEREST: 'I would like to know more about sabbaticals.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_QUESTION_LABEL: 'Parental leave',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_QUESTION_INFO:
        'SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_QUESTION_INFO',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_ANSWER_OFFERING: 'I have already taken parental leave.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_ANSWER_INTEREST: "I'm planning to take parental leave.",
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_FREE_FIELD_LABEL: 'Any other suggestions?',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_FREE_FIELD_PLACEHOLDER: 'Thanks a lot for sharing your ideas!',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_QUESTION_LABEL: 'Family and work; parental leave',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_QUESTION_INFO:
        'SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_QUESTION_INFO',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_ANSWER_OFFERING:
        'I am willing to share my experience with reconciling family and work/parental leave.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_ANSWER_INTEREST:
        'I am interested in learning about reconciling family and work.',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_TITLE: 'Reverse mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_INFO:
        'Exchange between colleagues of different age groups makes perfect sense. But older employees rarely have the opportunity to be mentees again. Reverse mentoring offers the opportunity for experienced older employees to benefit from the special knowledge and perspectives of younger colleagues and thus promotes intergenerational dialogue and knowledge exchange within the company.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_CONTENT_TITLE:
        'Support established colleagues (reverse mentoring)',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_SKILLS_LABEL:
        'With which skills/knowledge could you help established colleagues?',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_SKILLS_PLACEHOLDER:
        'e.g., software tools, digitalization, social media',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_YEAR_OF_BIRTH_LABEL: 'Your year of birth',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_YEAR_OF_BIRTH_PLACEHOLDER: 'Please select',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_TITLE: 'Reverse mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_SUB_TITLE: 'Mentoring the other way around.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_CONTENT_TITLE:
        'Are you looking for a "Reverse Mentor" or would you like to support more experienced colleagues yourself?',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_TITLE: 'Reverse mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_INFO:
        'Exchange between colleagues of different age groups makes perfect sense. But older employees rarely have the opportunity to be mentees again. Reverse mentoring offers the opportunity for experienced older employees to benefit from the special knowledge and perspectives of younger colleagues and thus promotes intergenerational dialogue and knowledge exchange within the company.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_SKILLS_LABEL:
        'In which fields would you like to be supported by younger colleagues?',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_SKILLS_PLACEHOLDER:
        'e.g., software tools, digitalization, social media',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_YEAR_OF_BIRTH_LABEL: 'Your year of birth',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_YEAR_OF_BIRTH_PLACEHOLDER: 'Please select',

    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_TITLE: 'Peer learning',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_INFO:
        "It's not just what we learn or why we learn it that's crucial; with whom we learn also plays a role. Who else would be familiar with the same challenges and have similar motivations? The peer-learning approach takes this into account, enabling more productive and true-to-life learning.",
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_CONTENT_TITLE:
        'Learn together and from other colleagues (peer learning)',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_LABEL: 'Best ways to learn in the company',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_PLACEHOLDER: 'Multiple selections possible',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_1: 'Day-to-day work',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_2: 'Coaching',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_3: 'Events',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_4: 'Mentoring',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_5: 'Projects',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_6: 'Working circles',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_7: 'Workshops',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_8: 'Other advanced-training opportunities',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_COMPANY_SKILLS_LABEL:
        'In your opinion, what are the most-needed skills for this company?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_COMPANY_SKILLS_PLACEHOLDER:
        'e.g., project manager, start-up mentality, IT skills, sustainability',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_INTEREST_LABEL:
        'Which skills would you personally like to gain from other colleagues?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_INTEREST_PLACEHOLDER:
        'e.g., scrum, strategic marketing, PowerPoint',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_OFFERING_LABEL:
        'Which skills would you be best prepared to share with colleagues?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_OFFERING_PLACEHOLDER:
        'e.g., scrum, strategic marketing, PowerPoint',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_TITLE: 'Onboarding and settling in',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_INFO:
        "Those who've been around for a while have a good idea of what they would have wanted to know when they started out. For others, this exact information could make life easier. Why not get together and help create a real team culture during onboarding?",
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_DEPARTMENT_LABEL: 'Which department do you work in?',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_DEPARTMENT_PLACEHOLDER: 'e.g., IT department',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_COMPANY_SITE_LABEL: 'Your current work location',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_COMPANY_SITE_PLACEHOLDER: 'e.g., Dortmund',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_TITLE: 'Onboarding and settling in',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_SUB_TITLE: 'Onboarding of new colleagues in the company',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_CONTENT_TITLE:
        'Support colleagues who start working in the company or find "Buddies" yourself who have been there for a while.',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_TITLE: 'Onboarding and settling in',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_CONTENT_TITLE:
        'I just joined the company and would like to meet my new colleagues',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_DEPARTMENT_LABEL: 'Your current department',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_DEPARTMENT_PLACEHOLDER: 'e.g., IT department',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_COMPANY_SITE_LABEL: 'Your current work location',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_COMPANY_SITE_PLACEHOLDER: 'e.g., Berlin',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_HELP_LATER_LABEL:
        'I would like to help colleagues once I know the company better.',

    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_TITLE: 'Find experts',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_INFO:
        'If this company knew what its employees knew, would it need external consultants? Discover the knowledge and possible hidden talents of your colleagues and utilize existing expertise.',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_CONTENT_TITLE: 'Find experts among your colleagues',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_SHORT_SUPPORT_LABEL:
        "I'm interested in short-term support from potential experts.",
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_LONG_SUPPORT_LABEL: "I'm interested in mid- to long-term support.",
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_WANTED_EXPERTISE_LABEL:
        'Describe the skills of the experts you are looking for.',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_WANTED_EXPERTISE_PLACEHOLDER: 'e.g., Linux system administrator',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_EXPERT_SKILLS_LABEL: 'What are you an expert at?',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_EXPERT_SKILLS_PLACEHOLDER: 'e.g., Linux system administrator',

    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TITLE: 'Advanced training',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_CONTENT_TITLE:
        'In which areas are you interested in advanced training options?',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TRAINING_FIELDS_LABEL: 'Describe the areas in keywords',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TRAINING_FIELDS_PLACEHOLDER: 'e.g., agile project management',

    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_TITLE: 'Retraining',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_CONTENT_TITLE:
        'In which areas are you interested in retraining options?',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_RETRAINING_FIELDS_LABEL: 'Describe the areas in keywords',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_RETRAINING_FIELDS_PLACEHOLDER: 'e.g., IT skills, system admin',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_TITLE: 'Expats',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_SUB_TITLE: 'Exchange of knowledge and experience with and between expats.',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_CONTENT_TITLE:
        'Indicate whether you are looking for expats and/or have already worked as an expat yourself.',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_TITLE: 'Expats and working abroad',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_CONTENT_TITLE: 'In which countries have you already worked before?',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_VISITED_COUNTRIES_LABEL:
        'Countries in which you work as an expat or have already worked',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_VISITED_COUNTRIES_PLACEHOLDER: 'e.g., France',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_HOME_COUNTRY_LABEL: 'Which country do you come from?',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_HOME_COUNTRY_PLACEHOLDER: 'e.g., Spain',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_TITLE: 'Expats and working abroad',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_CONTENT_TITLE:
        'Connect to expats or people who worked in a specific country',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_DESIRED_COUNTRIES_LABEL: 'Countries that interest you',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_DESIRED_COUNTRIES_PLACEHOLDER: 'e.g., Canada',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_HOME_COUNTRY_LABEL: 'Which country do you come from?',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_HOME_COUNTRY_PLACEHOLDER: 'e.g., Canada',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_TITLE: 'Interns, apprentices and trainees',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_SUB_TITLE: 'Exchange with and support specific groups such as interns.',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_CONTENT_TITLE:
        'Do you belong to one of the groups or would you like to support them?',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_TITLE: 'Trainee, intern, or apprentice',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_CONTENT_TITLE:
        'Do you want to support trainees, interns, or apprentices?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_LABEL: "Groups you'd like to support",
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_1: 'Trainee',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_2: 'Intern',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_3: 'Apprentice',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_PLACEHOLDER: 'Multiple selections possible',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_MOTIVATION_LABEL: 'What is your main motivation?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_MOTIVATION_PLACEHOLDER:
        'e.g., pass on my experience to a new generation',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_TITLE: 'Trainee, intern, or apprentice',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_CONTENT_TITLE:
        'Are you a trainee, intern or apprentice? Meet your peers and support them!',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_LABEL: 'Which group do you belong to?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_PLACEHOLDER: 'Please choose',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_1: 'Trainee',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_2: 'Intern',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_3: 'Apprentice',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_OTHER_INTEREST_LABEL: 'What else could this software help you with?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_OTHER_INTEREST_PLACEHOLDER: 'e.g., organize shared events',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_TITLE: 'Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_SUB_TITLE: 'Exchange of experiences regarding sabbaticals',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_CONTENT_TITLE:
        'Share your own experiences or ask colleagues who have already taken a sabbatical.',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_TITLE: 'Sabbaticals',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CONTENT_TITLE: 'Share your sabbatical experience',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CAUSE_LABEL: 'The main reasons for your sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CAUSE_PLACEHOLDER: 'e.g., family, travelling',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_BENEFIT_EMPLOYER_LABEL:
        'In what ways did your employer (indirectly) benefit from your sabbatical?',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_BENEFIT_EMPLOYER_PLACEHOLDER: 'e.g., new energy, new perspective',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_TITLE: 'Sabbaticals',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CONTENT_TITLE: 'Your interest in a sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CAUSE_LABEL: 'Your plans/reasons for a sabbatical:',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CAUSE_PLACEHOLDER: 'e.g., travelling, time off, learning new things',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_LABEL:
        "Does your company's work culture make it easy take a sabbatical?",
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_1: 'Yes.',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_2: 'Sort of.',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_3: 'Not really.',

    SIGN_UP_NETWORKING_DETAILS_ADVANCED_TRAINING_MIXED_TITLE: 'Advanced training',
    SIGN_UP_NETWORKING_DETAILS_ADVANCED_TRAINING_MIXED_CONTENT_TITLE: 'Your advanced training preferences',
    SIGN_UP_NETWORKING_DETAILS_ADVANCED_TRAINING_MIXED_SKILLS_LABEL:
        'In which fields would you like to have more options for advanced training?',
    SIGN_UP_NETWORKING_DETAILS_ADVANCED_TRAINING_MIXED_SKILLS_PLACEHOLDER: 'e.g. IT skills',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_TITLE: 'Parental leave',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_SUB_TITLE: 'Exchange of experiences regarding parental leave',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_CONTENT_TITLE:
        'Share your own experiences or ask colleagues who have already been on parental leave.',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_TITLE: 'Parental leave',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_CONTENT_TITLE:
        'Share your parental leave experience with colleagues',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_SHARE_EXPERIENCE_LABEL:
        "I'm willing to share my parental-leave experiences with colleagues",
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_LEADING_POSITION_LABEL: 'I am in a leadership position.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_LABEL:
        "Did your company's work culture make it easy for you to take parental leave?",
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_1: 'Yes.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_2: 'Sort of.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_3: 'Not really.',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_TITLE: 'Parental leave',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_CONTENT_TITLE: 'Your interest in parental leave',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_FIND_EXPERIENCE_LABEL:
        "I'd like to find colleagues who can share their experiences.",
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_LEADING_POSITION_LABEL: 'I am in a leadership position.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_LABEL:
        'In my position, I sometimes wonder if I should allow myself to take parental leave.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_RADIO_1: 'Yes.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_RADIO_2: 'Not at all.',

    SIGN_UP_SKILLS_ALL_LABEL: 'All your knowledge: Please select and/or fill in',
    SIGN_UP_SKILLS_ALL_PLACEHOLDER: 'Add an another skill or qualification',
    SIGN_UP_SKILLS_EXPERT_LABEL: "In your colleagues' view you are an expert in (max. 5 skills):",
    SIGN_UP_SKILLS_EXPERT_PLACEHOLDER: 'Add an another skill or qualification',
    SIGN_UP_SKILLS_EXPERT_MAXIMUM_TAGS_WARNING: 'Maximum number of tags is 5.',
    SIGN_UP_SKILLS_LAST_STEPS_MOTIVATION_MESSAGE:
        'Great! You finished that quickly. Just a couple more general details and then we can move on.',

    SIGN_UP_EXPERIENCE_TITLE: 'Work experience',
    SIGN_UP_EXPERIENCE_INFO:
        'The icon in the "Stages of your career" field means that you can hide the information about your previous career stages. This is possible at any time after registration under "Settings > Anonymity." However, we recommend that you keep all profile details visible and "show your face." It has been proven that this facilitates contact with others.',
    SIGN_UP_EXPERIENCE_CONTENT_TITLE: 'Your work experience',
    SIGN_UP_EXPERIENCE_STATION_LABEL: 'Stages of your career',
    SIGN_UP_EXPERIENCE_STATION_PERSONAL_INFO_BTN_ARIA_LABEL: 'Hide this information on my public profile',
    SIGN_UP_EXPERIENCE_STATION_PERSONAL_INFO:
        'You can hide the information about your previous career stages after registering under "Settings > Anonymity". However, we recommend that you keep all profile details visible and "show your face". It has been proven that this facilitates contact with others.',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_1: 'e.g., junior account manager',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_2: 'Years',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_3: 'Months',
    SIGN_UP_EXPERIENCE_STATION_ADD_BUTTON: 'Add',
    SIGN_UP_EXPERIENCE_STATION_REMOVE_BUTTON: 'Remove this entry',
    SIGN_UP_EXPERIENCE_DEGREES_LABEL: 'What are your most advanced degrees?',
    SIGN_UP_EXPERIENCE_DEGREES_PLACEHOLDER:
        'e.g., Bachelor of Business Administration, Master of Science in IT, MS in Engineering',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_LABEL: 'Total work experience (in years)',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_PLACEHOLDER: 'years',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_UNIT: 'Year(s)',
    SIGN_UP_EXPERIENCE_VALIDATION_REQUIRED_SHORT_VERSION: 'This field is required',
    SIGN_UP_EXPERIENCE_SKILL: 'Occupation',
    SIGN_UP_EXPERIENCE_YEARS: 'Years',
    SIGN_UP_EXPERIENCE_MONTHS: 'Months',

    SIGN_UP_HARD_FACTS_TITLE: 'About me',
    SIGN_UP_HARD_FACTS_INFO:
        'Here you can fill in the most important information about yourself to complete your profile. The symbol in the field "Year of birth" means that you can hide this information. This is possible at any time after registration under "Settings > Anonymity." However, we recommend that you keep all profile details visible and "show your face." It has been proven that this facilitates contact with others.',
    SIGN_UP_HARD_FACTS_SUB_TITLE: 'Your profile details',
    SIGN_UP_HARD_FACTS_PROFILE_PICTURE_INFO: 'SIGN_UP_HARD_FACTS_PROFILE_PICTURE_INFO',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_LABEL: 'Year of birth',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_PLACEHOLDER: 'Please select',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_INFO:
        'You can hide your year of birth after registering under "Settings > Anonymity". However, we recommend that you keep all profile details visible and "show your face". It has been proven that this facilitates contact with others.',
    SIGN_UP_HARD_FACTS_GENDER_LABEL: 'Gender',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_1: 'male',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_2: 'female',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_3: 'diverse',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_4: 'not specified',
    SIGN_UP_HARD_FACTS_USER_NAME_LABEL: 'Username/nickname',
    SIGN_UP_HARD_FACTS_USER_NAME_PLACEHOLDER: 'Preferred username',
    SIGN_UP_HARD_FACTS_POSITION_LABEL: 'Current title (position)',
    SIGN_UP_HARD_FACTS_POSITION_PLACEHOLDER: 'e.g., senior account manager',
    SIGN_UP_HARD_FACTS_LOCATIONS_LABEL: 'Work location',
    SIGN_UP_HARD_FACTS_DEPARTMENT_LABEL: 'Division or department',
    SIGN_UP_HARD_FACTS_DEPARTMENT_PLACEHOLDER: 'e.g., strategy management',
    SIGN_UP_HARD_FACTS_COMPANY_LABEL: 'Subsidiary company, if applicable',
    SIGN_UP_HARD_FACTS_COMPANY_PLACEHOLDER: '-',
    SIGN_UP_HARD_FACTS_DETAILS_FIND_JOB_LANGUAGES_LABEL: 'Business language(s)',
    SIGN_UP_HARD_FACTS_DETAILS_FIND_JOB_LANGUAGES_PLACEHOLDER: 'e.g., German, English, French, Spanish',
    SIGN_UP_HARD_FACTS_MOTTO_LABEL: 'A short message for your profile: Why are you here?',
    SIGN_UP_HARD_FACTS_MOTTO_PLACEHOLDER: '-',
    SIGN_UP_HARD_FACTS_GROUPS_LABEL: 'Target Groups',

    SIGN_UP_UNAVAILABLE_MODULE_RATING_TITLE: 'Finally, how interesting are the following topics to you in the future?',
    SIGN_UP_UNAVAILABLE_MODULE_RATING_SUB_TITLE: 'Please weight the topics according to their importance to you.',
    SIGN_UP_UNAVAILABLE_MODULE_RATING_SUB_INFO:
        'The direction this software takes in the future is also up to you and your interests. Give the management and project team running this platform some feedback (fully anonymous) regarding which features you want most in the future.',

    SIGN_UP_NEXT_STEP_TITLE: 'Great – you have successfully created your profile!',
    SIGN_UP_NEXT_STEP_CONTENT_TITLE: 'What would you like to start now?',
    SIGN_UP_NEXT_STEP_JOBS_TILE_TITLE: 'Jobs & projects',
    SIGN_UP_NEXT_STEP_JOBS_TILE_TEXT: 'View current offers or add your own.',
    SIGN_UP_NEXT_STEP_JOBS_TILE_BUTTON: 'To jobs',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_TITLE: 'View my finished profile',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_TEXT: 'View your profile and adjust if necessary.',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_BUTTON: 'To your profile',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_TITLE: 'My colleagues',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_TEXT: 'Show matches or find suitable colleagues.',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_BUTTON: 'To colleagues',
    SIGN_UP_NEXT_STEP_HELP_LABEL: 'No idea.',
    SIGN_UP_NEXT_STEP_HELP_LABEL_LINK: 'First, go to the dashboard.',

    SIGN_UP_THANK_YOU_HEADLINE: 'Great – you have successfully created your profile',
    SIGN_UP_THANK_YOU_SUBLINE: 'What would you like to start now?',
    SIGN_UP_THANK_YOU_CARD_1_TITLE: 'Questions about working methods',
    SIGN_UP_THANK_YOU_CARD_1_CONTENT: 'What is important to you in a collaboration?',
    SIGN_UP_THANK_YOU_CARD_1_ACTION: 'To the questionnaire',
    SIGN_UP_THANK_YOU_CARD_2_TITLE: 'View my finished profile',
    SIGN_UP_THANK_YOU_CARD_2_CONTENT: 'View your profile and adjust if necessary.',
    SIGN_UP_THANK_YOU_CARD_2_ACTION: 'To your profile',
    SIGN_UP_THANK_YOU_CARD_3_TITLE: 'Direct to my matches',
    SIGN_UP_THANK_YOU_CARD_3_CONTENT: 'Find suitable colleagues and view matches.',
    SIGN_UP_THANK_YOU_CARD_3_ACTION: 'To the matches',
    SIGN_UP_THANK_YOU_NO_IDEA: 'No idea?',
    SIGN_UP_THANK_YOU_TO_DASHBOARD: 'Go to the dashboard first',

    SIGN_UP_JOB_DEFAULT_DESCRIPTION:
        "Dear colleagues,\n\nthanks for stopping by and for your interest. This offer has been created automatically during my registration with {{ appName }}. That's why there isn't a proper description yet.\n\nIf the keywords above appeal to you at first sight, just get in touch with me via the message button. I'll be notified via e-mail and I am looking forward to getting into conversation.",

    COLLEAGUES_MATCHING_ADD_TOPIC: 'Add new interest',

    JOB_BOARD_TITLE: 'Job Board',
    JOB_BOARD_SUB_TITLE: 'Find positions that are meant to be shared.',
    JOB_BOARD_ADD_JOB_BUTTON_TEXT: 'Add Job',
    JOB_BOARD_ADD_JOB_BTN_ARIA_LABEL: 'To the job sharing advertisement creation form',
    JOB_BOARD_ADD_JOB_INFO_BTN_ARIA_LABEL: 'Information: When can I post a job sharing advertisement?',
    JOB_BOARD_ADD_JOB_INFO_BUTTON_TEXT: 'When can I post jobs?',
    JOB_BOARD_STATUS_PUBLISHED: 'Published',
    JOB_BOARD_STATUS_UNPUBLISHED: 'Unpublished',
    JOB_BOARD_STATUS_DEACTIVATED: 'Deactivated',
    JOB_BOARD_STATUS_DEACTIVATED_TOOLTIP: 'Deactivated after expiry. Edit to renew.',
    JOB_BOARD_DAYS_LEFT: 'days left',
    JOB_BOARD_VIEW_DETAILS_BUTTON_TEXT: 'View details',
    JOB_BOARD_VIEW_DETAILS_BTN_ARIA_LABEL: 'View details of job sharing advertisement',
    JOB_BOARD_PUBLISH_BUTTON_TEXT: 'Publish',
    JOB_BOARD_PUBLISH_BTN_ARIA_LABEL: 'Publish job sharing advertisement',
    JOB_BOARD_JOB_KEYWORDS: 'Keywords:',
    JOB_BOARD_JOB_DESCRIPTION: 'Job description:',
    JOB_BOARD_JOB_MESSAGE_BUTTON_TEXT: 'Message',

    JOB_INFO_PANEL_LOCATION_ARIA_LABEL: 'Location',
    JOB_INFO_PANEL_JOB_BOARD_DAYS_LEFT_ARIA_LABEL: 'Remaining time of the advertisement',
    JOB_INFO_PANEL_STATUS_ARIA_LABEL: 'Status',

    JOB_BOARD_LIST_JOB_TILE_JOB_DELETE_DIALOG_HEADER: 'Delete job',
    JOB_BOARD_LIST_JOB_TILE_JOB_DELETE_DIALOG_CONTENT: 'Are you sure, that you want to delete this job?',

    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_TITLE: "Great you're here!",
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_TITLE:
        'Before you get started, we just want to remind you of three points:',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_1:
        'You should be sure that the position meets all requirements to be filled in a duo and is approved for jobsharing by the hiring manager.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_2:
        'The short description should contain a link to the complete job posting on the intranet.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_3:
        'To assure that all job postings are up to date the posting will be deleted after 8 weeks. Please extend the validity period if necessary.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_THANK_YOU_TITLE: 'Thank you for your commitment on this platform!',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_SUBTITLE:
        'Choose a job title that describes this position as precisely as possible.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_TITLE: 'Job title',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_WRITE_JOB_TITLE: 'Your job title',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_TITLE_TOO_SHORT: 'The title has to have at least 3 characters.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY: 'Location',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY_ARIA_LABEL: 'Location of the job',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY_PLACEHOLDER: 'e.g Berlin',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL: 'Valid until (8 weeks max.)',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_PLACEHOLDER: 'Select a date',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_SUBTITLE: 'Specify valid until date',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_NOT_IN_RANGE: 'The date as to be within the next 8 weeks',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS: 'Relevant knowledge and skills (keywords)',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS_SUBTITLE:
        'Enter the most relevant keywords for your job here and separate the words by pressing "Enter". The more tags you enter, the better the matching will be.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS_PLACEHOLDER: 'e.g. Controlling',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_INFO:
        'You would like to paste an existing job description here? Then please copy and paste from the word format DOCX. In case you have any problems with this, please send us an e-mail to support@tandemploy.com.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION: 'Job description',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_PLACEHOLDER:
        "Describe your job in your own words. What's special about it? What could be tasks for your tandem partner?...",
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_TITLE_TOO_SHORT:
        'The description has to have at least 3 characters.',
    JOB_BOARD_ADD_JOB_BUTTON_INFO_POPUP_CONTENT:
        'All jobs that are posted here should be explicitly approved by the hiring manager.',

    JOB_BOARD_FUNNEL_NEXT_BTN_ARIA_LABEL: 'Continue to input mask',
    JOB_BOARD_FUNNEL_COMPLETE_BTN_ARIA_LABEL: 'Save job sharing advertisement',

    CONFIRM_CLOSE_DIALOG_HEADER: 'Discard all changes?',
    CONFIRM_CLOSE_DIALOG_CONTENT: 'All new and changed data will be lost!',
    CONFIRM_CLOSE_DIALOG_CONFIRM_BUTTON: 'Confirm',

    HEADER_BACK_TO_INDEX_LABEL: 'back',
    HEADER_BACK_TO_MATCHING_PAGE_ARIA_LABEL: 'Back to matching page',
    HEADER_BACK_TO_SEARCH_PAGE_ARIA_LABEL: 'Back to search',

    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_REMOVE_FROM_CANDIDATES: 'Remove from candidates',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_GO_TO_CANDIDATES_LIST: 'Go to candidates list',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_INFO: 'What does it mean?',
    TANDEM_CANDIDATE_ACTIONS_BTN_ADD_AS_CANDIDATE: 'Add as candidate',
    TANDEM_CANDIDATE_ACTIONS_BTN_IS_A_CANDIDATE: 'Is a candidate',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_INFO_DIALOG_MESSAGE:
        'You can add people to "tandem candidates" who are of particular interest to you and who you want to find again later. They will be added to a short list, where certain interactions like e.g. "show tandem profile" are available.',

    FOOTER_NAVIGATION_BACK_TO_RESULTS: 'Back to Results',
    FOOTER_NAVIGATION_NEXT_BUTTON_CAPTION: 'Next Profile',
    FOOTER_NAVIGATION_NEXT_BTN_ARIA_LABEL: 'To the next profile of {{userName}}',
    FOOTER_NAVIGATION_PREV_BUTTON_CAPTION: 'Previous Profile',
    FOOTER_NAVIGATION_PREV_BTN_ARIA_LABEL: 'To the previous profile of {{userName}}',
    PAGING_NAVIGATION: 'Pagination Navigation',
    NAVIGATION_MENU: 'Navigation menu',

    PROMPT_JOBSHARING_INFO:
        'As part of the latest platform update, job sharing matching has also been improved. Please confirm the location details in order to enable a functioning matching.',

    SIDENAV_MY_MATCHES: 'MY MATCHES',
    SIDENAV_ADD_MORE_ALT_TEXT: 'Add more topics',
    SIDENAV_SHOW_MORE_TOPICS: 'More topics...',
    SIDENAV_SHOW_LESS_TOPICS: 'Less topics...',
    SIDENAV_TOP_LINKS: 'PERSONAL',
    SIDENAV_BOTTOM_LINKS: 'MORE',
    SIDENAV_LOGO_ALT_TEXT: 'Logo of {{ appName }}',
    SIDENAV_AVATAR_ALT_TEXT: 'To your own profile',

    DIALOG_HELP: 'Further information and help',
    REMOVE_TANDEM: 'Remove tandem partner',
    TANDEM_ARIA_LABEL: 'To tandem profile with {{userName}}',
    GO_TO_HOMEPAGE_BTN_ARIA_LABEL: 'Start page Home',
    MESSAGES: 'To my messages',
    EDIT_TOPIC: 'Edit topic details of {{topic}}',
    OPEN_TOPIC_ADD_MODAL_BTN_ARIA_LABEL: 'Add other topics that interest me',
    SEND_MESSAGE: 'Send message',

    POWERED_BY_LINK_ARIA_LABEL: 'Get forwarded to Tandemploy homepage',
    CHANGE_LANGUAGE_TO_EN: 'Change language to english',
    CHANGE_LANGUAGE_TO_DE: 'Change language to german',

    DATEPICKER_DIALOG_ARIA_LABEL: 'Select date from calendar',

    INFO_TOGGLE_DIALOG_DEFAULT_HEADER: 'Information',
    INFO_TOGGLE_DIALOG_ANONYMITY_HEADER: 'Information about anonymity',

    PROFILE_EDIT_BTN_ARIA_LABEL: 'Edit profile details in the "About me" section',
    PROFILE_QUESTIONNAIRE_EDIT_BTN_ARIA_LABEL: 'Edit profile details in the "More about me" section',
    PROFILE_QUESTIONNAIRE_ADD_BTN_ARIA_LABEL: 'Add profile details in the "More about me" section',
    PROFILE_WORK_EDIT_BTN_ARIA_LABEL: 'Edit profile details in the "Professional experience" section',
    PROFILE_WORK_ADD_BTN_ARIA_LABEL: 'Add profile details in the "Professional experience" section',
    PROFILE_EDIT_TOPIC_BTN_ARIA_LABEL: 'Edit topic details of {{topic}}',
    PROFILE_DELETE_TOPIC_BTN_ARIA_LABEL: 'Deactivate {{topic}} and do not receive matches',
    PROFILE_EXPANSION_PANEL_HEADER_ARIA_LABEL: 'Check topic details of {{topic}}',

    SEARCH_BTN_TOOLTIP: 'Start search',
    SEARCH_RESULT_ARIA_LABEL: 'To profile view of {{userName}}',
    TOPIC_SELECTOR_LABEL: 'Choose the matching topic you want to search.',
    SEARCH_RESULT_TAGS_ARIA_LABEL: 'A label, keyword or other information related to this search result.',

    MATCHING_RESULT_ARIA_LABEL: 'To profile view of {{userName}}',
    MATCHING_RESULT_TAGS_ARIA_LABEL: 'A label, keyword or other information related to this match result.',

    JOB_PUBLISH_BTN_ARIA_LABEL: 'Publish job sharing advertisement',
    JOB_UNPUBLISH_BTN_ARIA_LABEL: 'Unpublish job sharing advertisement',
    JOB_EDIT_BTN_ARIA_LABEL: 'Edit job sharing advertisement',
    JOB_DELETE_BTN_ARIA_LABEL: 'Delete job sharing advertisement',
    JOB_DETAILS_MODAL_CLOSE_BTN_ARIA_LABEL: 'Close job sharing advertisement details',

    JOB_PUBLISH_BTN_LABEL: 'Publish job',
    JOB_UNPUBLISH_BTN_LABEL: 'Unpublish job',
    JOB_EDIT_BTN_LABEL: 'Edit job',
    JOB_DELETE_BTN_LABEL: 'Delete job',

    DIRECT_MESSAGES_SEND_WITH_INDICATOR_TEXT: 'Send now',
    DIRECT_MESSAGES_NO_CONTACTS_OF_THIS_TYPE: 'No contacts yet',
    DIRECT_MESSAGES_CONVERSATIONS_LOADING_MESSAGE: 'Messages are loading',
    DIRECT_MESSAGES_CONVERSATION_LOADING_MESSAGE: 'Message is loading',
    DIRECT_MESSAGES_PARTICIPANTS: 'Participants',
    DIRECT_MESSAGES_NO_CONVERSATION_SELECTED_MESSAGE: 'Please select a conversation',
    DIRECT_MESSAGES_NO_MESSAGE_IN_CONVERSATION: 'No message yet',
    DIRECT_MESSAGES_HEADER: 'Personal messages',
    DIRECT_MESSAGES_FROM: 'from',
    DIRECT_MESSAGES_GROUP_CONVERSATION_HEADER_TEXT: 'Group messages',
    DIRECT_MESSAGES_PRIVATE_CONVERSATION_HEADER_TEXT: 'Chats',
    DIRECT_MESSAGES_ENTER_MESSAGE_PLACEHOLDER: 'Message to',
    DIRECT_MESSAGES_SNACK_NEW_MESSAGE: '{{ userName }} sent a message',
    DIRECT_MESSAGES_CONVERSATION_PRIVATE: 'Private Chat: ',
    ORIGIN_GLOBAL_SEARCH: 'search',
    ORIGIN_TOPIC_STA: 'Short Assignments',
    ORIGIN_STA: 'Short Assignments',
    ORIGIN_TOPIC_WORKSHOP: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOP: 'Workshops',
    ORIGIN_WORKSHOPS: 'Workshops',
    ORIGIN_BOOKMARKS: 'bookmarks',
    ORIGIN_TOPIC_EXPERTS: 'Expert Finder',
    ORIGIN_EXPERTS: 'Expert Finder',
    ORIGIN_TOPIC_JOB_SHADOWING: 'Job Shadowing',
    ORIGIN_JOB_SHADOWING: 'Job Shadowing',
    ORIGIN_TOPIC_NLA: 'Never Lunch Alone',
    ORIGIN_NLA: 'Never Lunch Alone',
    ORIGIN_TOPIC_MENTORING: 'Mentoring',
    ORIGIN_MENTORING: 'Mentoring',
    ORIGIN_TOPIC_PROJECTS: 'Projects',
    ORIGIN_PROJECTS: 'Projects',
    ORIGIN_JOB_SHARING: 'Job Sharing',
    ORIGIN_GENERATION_EXCHANGE: 'Generation Exchange',
    ORIGIN_TOPIC_JOB_SHARING: 'Job Sharing',
    GLOBAL_SEARCH_SEARCH_LABEL: 'Search users',
    DIRECT_MESSAGES_CONVERSATION_MUTED_NOTIFICATION: 'Notifications muted',
    DIRECT_MESSAGES_CONVERSATION_UNMUTED_NOTIFICATION: 'Notifications un-muted',
    DIRECT_MESSAGES_MUTE_CONVERSATION_CONFIRM_TEXT: 'Mute notifications of new messages in this chat in the future?',
    DIRECT_MESSAGES_MUTE_CONVERSATION_BUTTON_TEXT: 'Mute',
    DIRECT_MESSAGES_MUTE_CONVERSATION_CONFIRMATION_HEADER: 'Mute notifications',
    DIRECT_MESSAGES_NO_UNREAD_MESSAGES: 'Currently no unread messages.',
    DIRECT_MESSAGES_MESSAGE_CANT_BE_EMPTY: 'Message field is empty!',
    DIRECT_MESSAGES_MUTE_CONVERSATION: 'Notifications off',
    DIRECT_MESSAGES_UNMUTE_CONVERSATION: 'Notifications on',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS: 'Manage participants',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS_HELPER_TEXT: 'Here you can appoint another person to lead the chat.',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS_NO_SEARCH_RESULTS: 'No users could be found.',
    DIRECT_MESSAGES_DISABLE_WRITE_PERMISSIONS: 'In the future, write access only for me.',
    DIRECT_MESSAGES_ENABLE_WRITE_PERMISSIONS: 'In the future, write access for everyone.',
    DIRECT_MESSAGES_WRITE_PERMISSIONS_DISABLED: 'From now on only you can write in this chat.',
    DIRECT_MESSAGES_CONVERSATION_NO_WRITE_ACCESS: 'Currently you cannot post messages in this conversation.',
    DIRECT_MESSAGES_NO_CONVERSATIONS_YET_1: 'No conversation yet.',
    DIRECT_MESSAGES_NO_CONVERSATIONS_YET_2: 'Start your first chat here!',
    DIRECT_MESSAGES_VISIT_PROFILE: 'Visit profile of this user',
    DIRECT_MESSAGES_ADD_OWNER: 'Appoint this person',
    DIRECT_MESSAGES_REMOVE_OWNER: 'Should no longer manage chat',
    DIRECT_MESSAGES_OPEN_MENU_LABEL: 'Open message menu',
    DELETED_USER: 'Profile deleted',
    GO_TO_HOMEPAGE: 'Go to homepage',

    EN: 'English',
    FR: 'French',
    DE: 'German',
    IT: 'Italian',
    PT: 'Portuguese',
    RU: 'Russian',
    ES: 'Spanish',
    AA: 'Afar',
    AB: 'Abkhaz',
    AE: 'Avestan',
    AF: 'Afrikaans',
    AK: 'Akan',
    AM: 'Amharic',
    AN: 'Aragonese',
    AR: 'Arabic',
    AS: 'Assamese',
    AV: 'Avaric',
    AY: 'Aymara',
    AZ: 'Azerbaijani',
    BA: 'Bashkir',
    BE: 'Belarusian',
    BG: 'Bulgarian',
    BH: 'Bihari',
    BI: 'Bislama',
    BM: 'Bambara',
    BN: 'Bengali',
    BO: 'Tibetan',
    BR: 'Breton',
    BS: 'Bosnian',
    CA: 'Catalan',
    CE: 'Chechen',
    CH: 'Chamorro',
    CO: 'Corsican',
    CR: 'Cree',
    CS: 'Czech',
    CU: 'Church Slavonic',
    CV: 'Chuvash',
    CY: 'Welsh',
    DA: 'Danish',
    DV: 'Dhivehi',
    DZ: 'Dzongkha',
    EE: 'Ewe',
    EL: 'Greek',
    EO: 'Esperanto',
    ET: 'Estonian',
    EU: 'Basque',
    FA: 'Persian (Farsi)',
    FF: 'Fulfulde',
    FI: 'Finnish',
    FJ: 'Fijian',
    FO: 'Faroese',
    FY: 'Western Frisian',
    GA: 'Irish',
    GD: 'Scottish Gaelic',
    GL: 'Galician',
    GN: 'Guaraní',
    GU: 'Gujarati',
    GV: 'Manx',
    HA: 'Hausa',
    HE: 'Hebrew (modern)',
    HI: 'Hindi',
    HO: 'Hiri Motu',
    HR: 'Croatian',
    HT: 'Haitian',
    HU: 'Hungarian',
    HY: 'Armenian',
    HZ: 'Herero',
    IA: 'Interlingua',
    ID: 'Indonesian',
    IE: 'Interlingue',
    IG: 'Igbo',
    II: 'Nuosu',
    IK: 'Inupiaq',
    IO: 'Ido',
    IS: 'Icelandic',
    IU: 'Inukitut',
    JA: 'Japanese',
    JV: 'Javanese',
    KA: 'Georgian',
    KG: 'Kongo',
    KI: 'Kikuyu',
    KJ: 'Kwanyama',
    KK: 'Kazakh',
    KL: 'Greenlandic',
    KM: 'Khmer',
    KN: 'Kannada',
    KO: 'Korean',
    KR: 'Kanuri',
    KS: 'Kashmiri',
    KU: 'Kurdisch',
    KV: 'Komi',
    KW: 'Cornish',
    KY: 'Kyrgyz',
    LA: 'Latin',
    LB: 'Luxembourgish',
    LG: 'Ganda',
    LI: 'Limburgish',
    LN: 'Lingala',
    LO: 'Lao',
    LT: 'Lithuanian',
    LU: 'Luba-Katanga',
    LV: 'Latvian',
    MG: 'Malagasy',
    MH: 'Marshallese',
    MI: 'Māori',
    MK: 'Macedonian',
    ML: 'Malayalam',
    MN: 'Mongolisch',
    MR: 'Marathi (Marāṭhī)',
    MS: 'Malay',
    MT: 'Maltese',
    MY: 'Burmese',
    NA: 'Nauruan',
    NB: 'Norwegian Bokmål',
    ND: 'Northern Ndebele',
    NE: 'Nepali',
    NG: 'Ndonga',
    NL: 'Dutch',
    NN: 'Norwegian Nynorsk',
    NO: 'Norwegian',
    NR: 'Southern Ndebele',
    NV: 'Navajo',
    NY: 'Chichewa',
    OC: 'Occitan',
    OJ: 'Ojibwe',
    OM: 'Oromo',
    OR: 'Oriya',
    OS: 'Ossetian',
    PA: 'Punjabi',
    PI: 'Pāli',
    PL: 'Polish',
    PS: 'Pashto',
    QU: 'Quechua',
    RM: 'Romansh',
    RN: 'Kirundi',
    RO: 'Romanian',
    RW: 'Kinyarwanda',
    SA: 'Sanskrit (Saṁskṛta)',
    SC: 'Sardinian',
    SD: 'Sindhi',
    SE: 'Northern Sami',
    SG: 'Sango',
    SI: 'Sinhalese',
    SK: 'Slovak',
    SL: 'Slovene',
    SM: 'Samoan',
    SN: 'Shona',
    SO: 'Somali',
    SQ: 'Albanian',
    SR: 'Serbian',
    SS: 'Swati',
    ST: 'Southern Sotho',
    SU: 'Sundanese',
    SV: 'Swedish',
    SW: 'Swahili',
    TA: 'Tamil',
    TE: 'Telugu',
    TG: 'Tajik',
    TH: 'Thai',
    TI: 'Tigrinya',
    TK: 'Turkmen',
    TL: 'Tagalog',
    TN: 'Setswana',
    TO: 'Tonga (Tonga Islands)',
    TR: 'Turkish',
    TS: 'Tsonga',
    TT: 'Tatar',
    TW: 'Twi',
    TY: 'Tahitian',
    UG: 'Uyghur',
    UK: 'Ukrainian',
    UR: 'Urdu',
    UZ: 'Uzbek',
    VE: 'Venda',
    VI: 'Vietnamese',
    VO: 'Volapük',
    WA: 'Walloon',
    WO: 'Wolof',
    XH: 'Xhosa',
    YI: 'Yiddish',
    YO: 'Yoruba',
    ZA: 'Zhuang',
    ZH: 'Chinese',
    ZU: 'Zulu',

    LOCATION_GLOBAL: 'Global',
    LOCATION_DACH: 'DACH',
    LOCATION_EU: 'Europe',
    LOCATION_AS: 'Asia',
    LOCATION_AF: 'Africa',
    LOCATION_DE: 'Germany',
    LOCATION_CH: 'Switzerland',
    LOCATION_AT: 'Austria',
    LOCATION_GRZ: 'Graz',
    LOCATION_LNZ: 'Linz',
    LOCATION_VIE: 'Wien',
    LOCATION_FR: 'Frankreich',
    LOCATION_FI: 'Finland',
    LOCATION_EG: 'Egypt',
    LOCATION_EC: 'Ecuador',
    LOCATION_DK: 'Denmark',
    LOCATION_CZ: 'Czech Republic',
    LOCATION_HR: 'Croatia',
    LOCATION_CN: 'China',
    LOCATION_CL: 'Chile',
    LOCATION_CA: 'Canada',
    LOCATION_CO: 'Colombia',
    LOCATION_BR: 'Brazil',
    LOCATION_BE: 'Belgium',
    LOCATION_BA: 'Bosnia and Herzegovina',
    LOCATION_BD: 'Bangladesh',
    LOCATION_AU: 'Australia',
    LOCATION_AR: 'Argentina',
    LOCATION_GT: 'Guatemala',
    LOCATION_HU: 'Hungary',
    LOCATION_IN: 'India',
    LOCATION_ID: 'Indonesia',
    LOCATION_IT: 'Italy',
    LOCATION_JP: 'Japan',
    LOCATION_JO: 'Jordan',
    LOCATION_LU: 'Luxembourg',
    LOCATION_MA: 'Morocco',
    LOCATION_MY: 'Malaysia',
    LOCATION_MX: 'Mexico',
    LOCATION_CK: 'New Zealand',
    LOCATION_NO: 'Norway',
    LOCATION_PK: 'Pakistan',
    LOCATION_PH: 'Philippines',
    LOCATION_PL: 'Poland',
    LOCATION_PT: 'Portugal',
    LOCATION_RO: 'Romania',
    LOCATION_RU: 'Russia',
    LOCATION_SA: 'Saudi Arabia',
    LOCATION_SG: 'Singapore',
    LOCATION_SK: 'Slovakia',
    LOCATION_ZA: 'South Africa',
    LOCATION_KR: 'South Korea',
    LOCATION_ES: 'Spain',
    LOCATION_SE: 'Sweden',
    LOCATION_TW: 'Taiwan',
    LOCATION_TH: 'Thailand',
    LOCATION_NL: 'The Netherlands',
    LOCATION_TR: 'Turkey',
    LOCATION_UA: 'Ukraine',
    LOCATION_US: 'USA',
    LOCATION_AE: 'United Arab Emirates',
    LOCATION_GB: 'United Kingdom',
    LOCATION_ET: 'Estonia',
    LOCATION_OTHER: 'Others',
    LOCATION_SALES: 'Sales/External offices',

    ADMIN_MESSAGES_TAB: 'Mailings',
    ADMIN_MESSAGES_HEADER: 'Mailings',
    ADMIN_MESSAGES_COLUMN_DATE: 'Date',
    ADMIN_MESSAGES_COLUMN_SUBJECT: 'Subject',
    ADMIN_MESSAGES_COLUMN_TO: 'To',
    ADMIN_MESSAGES_COLUMN_AUTHOR: 'Author',
    ADMIN_MESSAGES_COLUMN_ACTIONS: 'Actions',
    ADMIN_MESSAGES_DRAFT: 'Draft',
    ADMIN_MESSAGES_GROUP_ALL: 'All users',
    ADMIN_MESSAGES_BTN_SEND_NEW: 'Send new email',
    ADMIN_MESSAGES_FORM_TILE_CREATE: 'New message',
    ADMIN_MESSAGES_FORM_LABEL_GROUP: 'User group',
    ADMIN_MESSAGES_FORM_LABEL_LOCATIONS: 'Locations',
    ADMIN_MESSAGES_FORM_LABEL_COMPANY: 'Subsidiary',
    ADMIN_MESSAGES_FORM_LABEL_SUBJECT: 'Subject',
    ADMIN_MESSAGES_FORM_LABEL_CONTENT: 'Message',
    ADMIN_MESSAGES_BTN_SAVE: 'Save and send later',
    ADMIN_MESSAGES_BTN_SEND: 'Send now',
    ADMIN_MESSAGES_CONFIRM_SEND_TITLE: 'Send message now?',
    ADMIN_MESSAGES_CONFIRM_SEND_CONTENT:
        'If you click "Send now", a message is irrevocably sent to all members of the selected user group.',
    ADMIN_MESSAGES_CONFIRM_SEND_BTN_YES: 'Yes, send now!',
    ADMIN_MESSAGES_ENTITY_VIEW_TITLE: 'Content of the sent message',
    ADMIN_MESSAGES_ENTITY_EDIT_TITLE: 'Edit message',

    ADMIN_STATISTICS_CUSTOM_REPORTS_PROJECTS_LABEL: 'Report Projects',
    ADMIN_STATISTICS_CUSTOM_REPORTS_STA_LABEL: 'Report Short Assignments',
    ADMIN_STATISTICS_CUSTOM_REPORTS_WORKSHOPS_LABEL: 'Report Workshops',
    ADMIN_STATISTICS_CUSTOM_REPORTS_FILE_TYPE_LABEL: '(CSV)',

    ADMIN_ROLES_TABLE_COL_DATE: 'Date',
    ADMIN_ROLES_TABLE_COL_EMAIL: 'User e-mail',
    ADMIN_ROLES_TABLE_COL_STATISTICS: 'Statistics',
    ADMIN_ROLES_TABLE_COL_ADMIN_MAIL: 'Mailings',
    ADMIN_ROLES_TABLE_COL_STAFFING: 'Staffing',
    ADMIN_ROLES_TABLE_COL_ADMIN: 'Admin',
    ADMIN_ROLES_HEADER: 'Permission Management',
    ADMIN_ROLES_SUB_HEADER:
        'Here you can define which users should have special rights. The following rights can be assigned:',
    ADMIN_ROLES_DETAILS_STATISTICS:
        'Users with this right can call up the statistics dashboard and view aggregated evaluations on the use of the platform.',
    ADMIN_ROLES_DETAILS_ADMIN_MAIL:
        'Users with this right can access the "Mailings" page and send broadcast messages to all users or specific user subgroups.',
    ADMIN_ROLES_DETAILS_STAFFING:
        'Users with this authorization can support the providers of {{ projects }} and {{ stas }} in staffing. They can view lists of applicants and their current application status for all existing offers and make further suggestions for suitable candidates. A separate page on the platform is available for this purpose.',
    ADMIN_ROLES_DETAILS_ADMIN:
        'Users with this right have all the above mentioned rights. They can also access this page - "Platform Administration" - and manage permissions for other users.',
    ADMIN_ROLES_EMAIL_NOT_VALID: 'This email is not valid',
    ADMIN_ROLES_SELECT_AT_LAST_ONE_ROLE: 'Choose at least one permission',
    ADMIN_ROLES_EMAIL_USER_NOT_FOUND: 'A user with this email does not exist',
    ADMIN_ROLES_ADD_ROLE_UNEXPECTED_ERROR: 'An unexpected error has occurred. Please reload the page.',
    ADMIN_ROLES_BTN_ADD_ROLE: 'Add user to list',
    ADMIN_ROLES_BTN_SAVE: 'Save changes',
    ADMIN_ROLES_NOTIFICATION_ADD_SUCCESS: 'The user was added to the list.',
    ADMIN_ROLES_NOTIFICATION_SAVE_SUCCESS: 'Your changes are updated.',

    ADD_PROFILE_TEXT: "You're almost there! Enable this matching module by providing some more information.",
    ADD_PROFILE: 'Start now',

    PAGINATOR_OF: 'of',
    PAGINATOR_ITEMS_PER_PAGE: 'Items per page',
    PAGINATOR_NEXT_PAGE: 'Next page',
    PAGINATOR_PREVIOUS_PAGE: 'Previous page',
    PAGINATOR_FIRST_PAGE: 'First page',
    PAGINATOR_LAST_PAGE: 'Last page',
    PAGINATOR_NEXT: 'Next',

    SAVE_AND_FINISH_LATER: 'Save and continue later',
    QUESTIONNAIRE_BTN_START: 'Start questionnaire',
    QUESTIONNAIRE_EMPTY_RADIO_OPTION: 'None of these apply.',

    QUESTIONNAIRE_START_HEADER: 'Working in a tandem: the right chemistry.',
    QUESTIONNAIRE_START_BODY_TEXT:
        'In order to also take into account "soft factors" related to the way of working and personal preferences when matching colleagues, you will find here a questionnaire of about 5 minutes. There is no right or wrong! All questions are voluntary and can be skipped.\n\nYour answers are mainly used for a finer matching in the background, some of them will also be visible on your profile for colleagues. If you prefer not to show them, you can hide your details afterwards under "User settings > Anonymity".',

    QUESTIONNAIRE_1_HEADER: 'You hope that flexible work gives you more time for ...',
    QUESTIONNAIRE_1_ANSWER_1: '… family',
    QUESTIONNAIRE_1_ANSWER_2: '… own projects or additional jobs',
    QUESTIONNAIRE_1_ANSWER_3: '… further training',
    QUESTIONNAIRE_1_ANSWER_4: '… voluntary work',
    QUESTIONNAIRE_1_ANSWER_5: '… the care of relatives',
    QUESTIONNAIRE_1_ANSWER_6: '… time-consuming hobbies',
    QUESTIONNAIRE_1_ANSWER_7: '… my own health',
    QUESTIONNAIRE_1_ANSWER_8: 'other',

    QUESTIONNAIRE_2_HEADER: 'In my job, I particularly want to ...',
    QUESTIONNAIRE_2_ANSWER_1: 'have a career',
    QUESTIONNAIRE_2_ANSWER_2: 'achieve self-fulfilment and be happy',
    QUESTIONNAIRE_2_ANSWER_3: 'stay flexible with sufficient spare time',
    QUESTIONNAIRE_2_ANSWER_4: 'reach a respected position',
    QUESTIONNAIRE_2_ANSWER_5: 'make a living',

    QUESTIONNAIRE_3_HEADER: 'What is on your mind after a successful day at work?',
    QUESTIONNAIRE_3_ANSWER_1: 'I can really make a difference in my position!',
    QUESTIONNAIRE_3_ANSWER_2: 'It’s great to make others happy!',
    QUESTIONNAIRE_3_ANSWER_3: 'Good to know that my boss acknowledges my performance!',
    QUESTIONNAIRE_3_ANSWER_4: 'It feels good to do something meaningful!',
    QUESTIONNAIRE_3_ANSWER_5: 'Today I had a lot of fun!',
    QUESTIONNAIRE_3_ANSWER_6: 'I love my team!',
    QUESTIONNAIRE_3_ANSWER_7: 'It’s great to always continue learning!',
    QUESTIONNAIRE_3_ANSWER_8: 'I have managed to do everything I intended to do today!',
    QUESTIONNAIRE_3_ANSWER_9: 'I’m allowed to take over this important project - how exciting!',

    QUESTIONNAIRE_4_HEADER: 'Which standards do you set yourself for your work?',
    QUESTIONNAIRE_4_ANSWER_1: 'I want to do a good job but work is not the centre of my life',
    QUESTIONNAIRE_4_ANSWER_2: 'I want to do good work to secure my job and my existence',
    QUESTIONNAIRE_4_ANSWER_3: 'I want to delight and always give 100%',

    QUESTIONNAIRE_5_HEADER: 'Your friends would describe you as ...',
    QUESTIONNAIRE_5_ANSWER_1: '… rather extroverted',
    QUESTIONNAIRE_5_ANSWER_2: '… rather introverted',

    QUESTIONNAIRE_6_HEADER: 'What is most important for you when working closely together with a colleague?',
    QUESTIONNAIRE_6_ANSWER_1:
        'Especially when working closely together, I want to be informed about any of my colleague’s steps',
    QUESTIONNAIRE_6_ANSWER_2: 'For me it seems most efficient to primarily discuss mile stones and results',
    QUESTIONNAIRE_6_ANSWER_3: 'Transparent information is important to me, but I do not need to access it constantly',
    QUESTIONNAIRE_SHORT_6_HEADER: 'What I appreciate in colleagues',
    QUESTIONNAIRE_SHORT_6_ANSWER_1: 'transparency in co-operation',
    QUESTIONNAIRE_SHORT_6_ANSWER_2: 'efficiency in co-operation',
    QUESTIONNAIRE_SHORT_6_ANSWER_3: 'independence within the team',

    QUESTIONNAIRE_7_HEADER: 'You notice that your colleague is wrong with a statement. What do you do?',
    QUESTIONNAIRE_7_ANSWER_1: 'I directly point it out to him / her',
    QUESTIONNAIRE_7_ANSWER_2: 'I ask a clever question so that he/she notices himself/herself',
    QUESTIONNAIRE_7_ANSWER_3: 'I hold back and don’t say anything for the time being',
    QUESTIONNAIRE_SHORT_7_HEADER: 'In difficult situations with colleagues I am',
    QUESTIONNAIRE_SHORT_7_ANSWER_1: 'direct',
    QUESTIONNAIRE_SHORT_7_ANSWER_2: 'diplomatic',
    QUESTIONNAIRE_SHORT_7_ANSWER_3: 'considerate',

    QUESTIONNAIRE_8_HEADER:
        'You made a serious mistake and have to tell your colleague. How do you deal with the situation?',
    QUESTIONNAIRE_8_ANSWER_1: 'The situation worries me and I need some time to think about how to address that topic',
    QUESTIONNAIRE_8_ANSWER_2:
        'This is not an issue for me, I take responsibility for my mistake and directly address it',
    QUESTIONNAIRE_8_ANSWER_3:
        'It is very challenging for me to tell my colleague about it because I really take mistakes to heart',

    QUESTIONNAIRE_9_HEADER: 'An important deadline is approaching (too fast, as always). You do everything ...',
    QUESTIONNAIRE_9_ANSWER_1: '… 100% and ask for a little extra time',
    QUESTIONNAIRE_9_ANSWER_2: '… 80% and finish in time',
    QUESTIONNAIRE_SHORT_9_HEADER: 'Deadlines I handle',
    QUESTIONNAIRE_SHORT_9_ANSWER_1: 'in love with details',
    QUESTIONNAIRE_SHORT_9_ANSWER_2: 'pragmatically',

    QUESTIONNAIRE_10_HEADER: 'Important routine work for which high accuracy is required ...',
    QUESTIONNAIRE_10_ANSWER_1: '… I like to pass on to someone else',
    QUESTIONNAIRE_10_ANSWER_2: '… I like to carry out myself',

    QUESTIONNAIRE_11_HEADER: 'Stress at work ...',
    QUESTIONNAIRE_11_ANSWER_1: '… energizes me',
    QUESTIONNAIRE_11_ANSWER_2: '… is essential for me – at least to a certain extent',
    QUESTIONNAIRE_11_ANSWER_3: '… is counterproductive',

    QUESTIONNAIRE_12_HEADER: 'When are you usually most productive?',
    QUESTIONNAIRE_12_ANSWER_1: 'in the morning',
    QUESTIONNAIRE_12_ANSWER_2: 'at midday',
    QUESTIONNAIRE_12_ANSWER_3: 'in the afternoon',
    QUESTIONNAIRE_12_ANSWER_4: 'in the evening',
    QUESTIONNAIRE_SHORT_12_HEADER: 'I am usually the most productive',
    QUESTIONNAIRE_SHORT_12_ANSWER_1: 'in the morning',
    QUESTIONNAIRE_SHORT_12_ANSWER_2: 'at midday',
    QUESTIONNAIRE_SHORT_12_ANSWER_3: 'in the afternoon',
    QUESTIONNAIRE_SHORT_12_ANSWER_4: 'in the evening',

    QUESTIONNAIRE_13_HEADER: 'What does your workplace normally look like?',
    QUESTIONNAIRE_13_ANSWER_1: 'tiptop',
    QUESTIONNAIRE_13_ANSWER_2: 'relatively okay – I find my way around',
    QUESTIONNAIRE_13_ANSWER_3: 'pure chaos',

    QUESTIONNAIRE_14_HEADER: 'At work it makes me more nervous if I ...',
    QUESTIONNAIRE_14_ANSWER_1: '… get too many instructions',
    QUESTIONNAIRE_14_ANSWER_2: '… do not get any instructions',
    QUESTIONNAIRE_SHORT_14_HEADER: 'What I like about my working environment',
    QUESTIONNAIRE_SHORT_14_ANSWER_1: 'self-responsibility',
    QUESTIONNAIRE_SHORT_14_ANSWER_2: 'clear structures',

    QUESTIONNAIRE_15_HEADER: 'A new, unexpected area of responsibility is waiting for you. How do you react?',
    QUESTIONNAIRE_15_ANSWER_1: "I’m excited and can't wait to get started",
    QUESTIONNAIRE_15_ANSWER_2: 'I need more details to look forward to it',
    QUESTIONNAIRE_15_ANSWER_3:
        'I’m taken by surprise and would rather concentrate on the already agreed responsibilities for now',
    QUESTIONNAIRE_SHORT_15_HEADER: 'I react to new tasks in this way',
    QUESTIONNAIRE_SHORT_15_ANSWER_1: 'with joy and enthusiasm',
    QUESTIONNAIRE_SHORT_15_ANSWER_2: 'I inform myself in detail',
    QUESTIONNAIRE_SHORT_15_ANSWER_3: 'I prefer to concentrate on familiar tasks',

    QUESTIONNAIRE_16_HEADER: 'Right now, everything in your job is fine the way it is.',
    QUESTIONNAIRE_16_ANSWER_1: 'I’m happy and do not feel the need to change anything',
    QUESTIONNAIRE_16_ANSWER_2: 'I’m still looking for possibilities to optimize things',
    QUESTIONNAIRE_SHORT_16_HEADER: 'My job and how I work',
    QUESTIONNAIRE_SHORT_16_ANSWER_1: 'I appreciate what I have (established workflows)',
    QUESTIONNAIRE_SHORT_16_ANSWER_2: 'I am constantly looking for optimizations',

    QUESTIONNAIRE_17_HEADER: 'You are rather ...',
    QUESTIONNAIRE_17_ANSWER_1: '… analytic',
    QUESTIONNAIRE_17_ANSWER_2: '… resilient',
    QUESTIONNAIRE_17_ANSWER_3: '… empathic',
    QUESTIONNAIRE_17_ANSWER_4: '… determined',
    QUESTIONNAIRE_17_ANSWER_5: '… patient',
    QUESTIONNAIRE_17_ANSWER_6: '… flexible',
    QUESTIONNAIRE_17_ANSWER_7: '… creative',
    QUESTIONNAIRE_17_ANSWER_8: '… honest',
    QUESTIONNAIRE_17_ANSWER_9: '… ambitious',
    QUESTIONNAIRE_17_ANSWER_10: '… disciplined',
    QUESTIONNAIRE_17_ANSWER_11: '… humorous',
    QUESTIONNAIRE_17_ANSWER_12: '… communicative',
    QUESTIONNAIRE_17_ANSWER_13: '… strategic',
    QUESTIONNAIRE_SHORT_17_HEADER: 'I am especially',
    QUESTIONNAIRE_SHORT_17_ANSWER_1: 'analytic',
    QUESTIONNAIRE_SHORT_17_ANSWER_2: 'resilient',
    QUESTIONNAIRE_SHORT_17_ANSWER_3: 'empathic',
    QUESTIONNAIRE_SHORT_17_ANSWER_4: 'determined',
    QUESTIONNAIRE_SHORT_17_ANSWER_5: 'patient',
    QUESTIONNAIRE_SHORT_17_ANSWER_6: 'flexible',
    QUESTIONNAIRE_SHORT_17_ANSWER_7: 'creative',
    QUESTIONNAIRE_SHORT_17_ANSWER_8: 'honest',
    QUESTIONNAIRE_SHORT_17_ANSWER_9: 'ambitious',
    QUESTIONNAIRE_SHORT_17_ANSWER_10: 'disciplined',
    QUESTIONNAIRE_SHORT_17_ANSWER_11: 'humorous',
    QUESTIONNAIRE_SHORT_17_ANSWER_12: 'communicative',
    QUESTIONNAIRE_SHORT_17_ANSWER_13: 'strategic',

    QUESTIONNAIRE_18_HEADER: 'You are particularly ...',
    QUESTIONNAIRE_18_ANSWER_1: '… visionary',
    QUESTIONNAIRE_18_ANSWER_2: '… strong in implementation',
    QUESTIONNAIRE_SHORT_18_HEADER: 'I am',
    QUESTIONNAIRE_SHORT_18_ANSWER_1: 'visionary',
    QUESTIONNAIRE_SHORT_18_ANSWER_2: 'strong in implementation',

    QUESTIONNAIRE_19_HEADER: 'Which quote particularly appeals to you?',
    QUESTIONNAIRE_19_ANSWER_1:
        '"Normality is a paved road: It’s comfortable to walk but no flowers grow on it." (Vincent van Gogh)',
    QUESTIONNAIRE_19_ANSWER_2: '"Be the change you wish to see in the world." (Gandhi)',
    QUESTIONNAIRE_19_ANSWER_3:
        '"He who does not have two thirds of his day to himself is a slave." (Friedrich Nietzsche)',
    QUESTIONNAIRE_19_ANSWER_4: '"Our life is what our thoughts make it." (Marc Aurelius)',
    QUESTIONNAIRE_19_ANSWER_5: '"Nothing in life is to be feared, it is only to be understood." (Marie Curie)',
    QUESTIONNAIRE_19_ANSWER_6:
        '"When we remember that we are all mad, the mysteries disappear and life stands explained." (Mark Twain)',
    QUESTIONNAIRE_19_ANSWER_7: '"Formula for success: rise early, work hard, strike oil." (Jean Paul Getty)',
    QUESTIONNAIRE_SHORT_19_HEADER: 'I liked this quote the most',
    QUESTIONNAIRE_SHORT_19_ANSWER_1:
        '"Normality is a paved road: It’s comfortable to walk but no flowers grow on it." (Vincent van Gogh)',
    QUESTIONNAIRE_SHORT_19_ANSWER_2: '"Be be the change you wish to see in the world." (Gandhi)',
    QUESTIONNAIRE_SHORT_19_ANSWER_3:
        '"He who does not have two thirds of his day to himself is a slave." (Friedrich Nietzsche)',
    QUESTIONNAIRE_SHORT_19_ANSWER_4: '"Our life is what our thoughts make it." (Marc Aurelius)',
    QUESTIONNAIRE_SHORT_19_ANSWER_5: '"Nothing in life is to be feared, it is only to be understood." (Marie Curie)',
    QUESTIONNAIRE_SHORT_19_ANSWER_6:
        '"When we remember that we are all mad, the mysteries disappear and life stands explained." (Mark Twain)',
    QUESTIONNAIRE_SHORT_19_ANSWER_7: '"Formula for success: rise early, work hard, strike oil." (Jean Paul Getty)',

    QUESTIONNAIRE_20_HEADER: 'What do you value the most when working with colleagues?',
    QUESTIONNAIRE_20_SUB_HEADER:
        'Here you can highlight what you value and what you wish for with regard to your counterpart.',
    QUESTIONNAIRE_20_PLACEHOLDER:
        'Here you can highlight what you value and what you wish for with regard to your counterpart.',
    QUESTIONNAIRE_SHORT_20_HEADER: 'Good teamwork means to me',

    QUESTIONNAIRE_21_HEADER: 'What are you interested in and what else would you like to tell other colleagues?',
    QUESTIONNAIRE_21_SUB_HEADER:
        'What fills you with enthusiasm, what captivates you, how do you like to spend your time?',
    QUESTIONNAIRE_21_PLACEHOLDER:
        'What fills you with enthusiasm, what captivates you, what do you like to spend your time with?',
    QUESTIONNAIRE_SHORT_21_HEADER: 'What I would like to tell',

    BOOKMARKS_PAGE_TITLE: 'Bookmarked colleagues',
    BOOKMARKS_PAGE_OTHERS_TITLE: 'Other',

    OFFER_DELETED_DIALOG_TITLE: 'Offer no longer available',
    OFFER_DELETED_DIALOG_CONTENT:
        'Sorry! The offer you are looking for was subsequently removed by the provider. On the search page you might find exciting alternatives. Good luck with your search!',
    OFFER_DELETED_DIALOG_SEARCH: 'Continue to search',

    RELEASE_NOTES: "What's new",
    RELEASE_NOTES_TITLE: 'Release Notes',
    RELEASE_NOTES_DIALOG_CONTENT:
        '<h3>New</h3>\n' +
        '<p><ul>' +
        '<li>Improved Short Assignment matching based on required and helpful skills</li>\n' +
        '<li>Download button for further reports (Administrators only)</li>\n' +
        '</ul></p>\n' +
        '<h3>Improvements</h3>\n' +
        '<p><ul>' +
        '<li>Revised UX and designs for the application process and staffing management of Short Assignments</li>\n' +
        '<li>Improved design for the application process of Workshops and Projects</li>\n' +
        '</ul></p>\n',

    GET_TO_KNOW_DIALOG_TITLE: 'Get to know {{ userName }}!',
    GET_TO_KNOW_DIALOG_MESSAGE_TEXT:
        'Hello,<br/>I would like to exchange ideas with you, over a coffee (or lunch/tea/beer) or optionally of course virtually. I would be very happy about an answer and date suggestions.<br/>Best regards',
    GET_TO_KNOW_COFFEE_LABEL: 'For coffee',
    GET_TO_KNOW_TEA_LABEL: 'For tea',
    GET_TO_KNOW_LUNCH_LABEL: 'For lunch',
    GET_TO_KNOW_BEER_LABEL: 'For a beer (after work) ',

    SKILL_LEVEL_DEFINITION_HEADER: 'Skill Level Definition',
    SKILL_LEVEL_DEFINITION:
        '   <div class="no-preline">\n' +
        '       <span>Junior</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>I am a beginner regarding the requirements of this skill.</li>\n' +
        '           <li>I have a solid basic knowledge.</li>\n' +
        '           <li>I can work independently on simple subtasks concerning this skill.</li>\n' +
        '       </ul>\n' +
        '   </div>\n' +
        '   <div class="no-preline">\n' +
        '       <span>Senior</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>I am competent regarding the requirements of the skills.</li>\n' +
        '           <li>I have a strong knowledge and certain wealth of experience.</li>\n' +
        '           <li>I can work on tasks independently and am flexible/fast regarding this skill.</li>\n' +
        '       </ul>\n' +
        '   </div>\n' +
        '   <div class="no-preline">\n' +
        '       <span>Expert</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>I have a great deal of knowledge and master the requirements completely.</li>\n' +
        '           <li>I can solve even unexpected and extremely complex tasks/problems.</li>\n' +
        '           <li>I can guide others in this skill.</li>\n' +
        '       </ul>\n' +
        '   </div>',

    LOGIN_ERROR_EXTERNAL:
        'There has been an error during authentication. Please try again. If the problem occurs again, please contact the <a href="mailto:{{ supportEmail }}">support</a>.',
    VALIDATION_SKILLS_ENTRY_MAX_LENGTH: 'Maximum number of characters reached. Please use only keyword-type skills.',
    ERROR_URL_404:
        'The page you tried to access is no longer available and you were forwarded to your Dashboard. Just close this message to continue.',

    LABEL_ENTITY_EDIT: 'Continue editing',
    LABEL_ENTITY_NAMED_EDIT: 'Edit {{name}}',
    LABEL_ENTITY_NAMED_DELETE: 'Delete {{name}}',
    LABEL_ENTITY_NAMED_COPY: 'Copy {{name}}',
    LABEL_ENTITY_VIEW_DETAILS: 'View details',
    LABEL_ENTITY_VIEW_STAFFING: 'Staffing',
    LABEL_ENTITY_VIEW_STAFFING_COMPLETED: 'Staffing list',
    LABEL_ENTITY_MESSAGE_PARTICIPANTS: 'Message participants ',
    LABEL_ENTITY_MESSAGE_OWNER: 'Write message',
    LABEL_ENTITY_VIEW_PARTICIPANTS: 'Participants',
    ENTITY_COPY_TITLE: 'Copy {{name}}',
    ENTITY_COPIED_TITLE: '{{title}} (copy)',
    LABEL_ENTITY_LOCATION: 'Location',
    LABEL_ENTITY_CALENDAR: 'Date',
    LABEL_ENTITY_LANGUAGE: 'Required language',
    LABEL_ENTITY_APPLICATION_DEADLINE: 'Application deadline',
    LABEL_ENTITY_COMPANY: 'Tendering company',
    LABEL_ENTITY_CAMPAIGNS: 'HR Program',
    LABEL_ENTITY_ORGANIZERS_CHECKBOX: 'Multiple Organizers',
    LABEL_ENTITY_ORGANIZERS_ADD_LABEL: 'Add as Co-Organizer',

    CONFIRM_IMAGE_DELETE_TITLE: 'Delete this picture?',
    CONFIRM_IMAGE_DELETE_BODY: 'Do you want to delete this image irrevocably?',
    MAX_IMAGES_REACHED_TITLE: 'Maximum {{max}} images allowed',
    MAX_IMAGES_REACHED_BODY:
        'Sorry, we can only allow {{max}} uploaded images in your library. To upload a new one, please delete one of your existing images first.',

    SKILL_FORM_NAME_VALIDATION_REQUIRED: 'At least one skill has to be added.',

    SKILL_FORM_NAME_WHEN_REQUIRED_LABEL: 'Skills needed',
    SKILL_FORM_PERSONAL_NAME_LABEL: 'Your skills',
    SKILL_FORM_PERSONAL_LEVEL_LABEL: 'Your level of expertise',

    SKILL_FORM_IS_REQUIRED_LABEL: 'Required for application',
    SKILL_FORM_LEVEL_WHEN_REQUIRED_LABEL: 'Level',

    SKILL_FORM_REDUCED_NAME_WHEN_REQUIRED_LABEL: 'Skills needed',
    SKILL_FORM_REDUCED_LEVEL_WHEN_REQUIRED_LABEL: 'Preferred level',

    SKILL_VIEW_NAME_LABEL: 'Required skills',
    SKILL_VIEW_LEVEL_LABEL: 'Preferred level',

    SKILL_LEVEL_OPTION_REQUIRED_LABEL: 'required',
    SKILL_LEVEL_OPTION_HELPFUL_LABEL: 'helpful',

    SKILL_LEVEL_0: 'Any',
    SKILL_LEVEL_1: 'Junior',
    SKILL_LEVEL_2: 'Senior',
    SKILL_LEVEL_3: 'Expert',
    SKILL_LEVEL_MINIMAL: '...',

    EDIT_SKILL_LIST_TABLE_SKILL_ADDED_NOTIFICATION_MSG: 'This skill was added to your profile.',
    EDIT_TOPIC_LIST_TABLE_TOPIC_ADDED_NOTIFICATION_MSG: 'This topic was added to your profile.',
    EDIT_FUNCTION_ADDED_NOTIFICATION_MSG: 'This function was added to your profile.',
    EDIT_LANGUAGE_ADDED_NOTIFICATION_MSG: 'This language was added to your profile.',
    SKILL_LEVEL_BUTTON_ACTION_MENU_HEADER: 'Add to my profile',
    SHOW_MORE_SKILLS_DROPDOWN: 'Show more skills',
    REQUIRED_SKILLS_LABEL: 'Required skills',
    NTH_SKILLS_LABEL: 'Helpful skills',

    SUCCESS_FACTOR_LABEL_1: 'Sync to my CV Data in Profile',
    SUCCESS_FACTOR_LABEL_2:
        'Skills & interests can be quickly transferred to “my CV Data” in Profile, if a Profile access is available in SF',

    DIGITALIZATION: 'Digitalization',
    CUSTOMER_CENTRICITY: 'Customer Centricity',
    SUSTAINABILITY: 'Sustainability',
    MOBILITY: 'Mobility / International',
    COMMERCIAL: 'Commercial topics',
    AIRLINE_PRODUCT: 'Product development topics',
    IT_TOPICS: 'IT topics & tools',
    COMMUNICATION: 'Communication topics',
    SOCIAL: 'Social topics',
    AGILE_METHODS: 'Agile method',
    LEAN_METHODS: 'Lean methods',

    ANY: 'Any',
    PLANNED: 'Planned',
    RUNNING: 'Running',
    COMPLETE: 'Finished',

    SUCCESS_FACTOR_SKILLS_OVERLAY_TITLE: 'New skills and level updates',
    SUCCESS_FACTOR_SKILLS_OVERLAY_DESCRIPTION:
        'In this overview you can see which new skills and skill level updates will be synced with SuccessFactors.',
    SUCCESS_FACTOR_ORIGINAL_SKILLS_LABEL: 'CHANGED SKILL LEVELS',
    SUCCESS_FACTOR_NEW_SKILLS_LABEL: 'NEW SKILLS',

    HEADLINE_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION: 'Inform participants?',
    TEXT_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION:
        'Do you want to inform participants via email about the changes you just made?',
    INFORM_PARTICIPANTS: 'Yes, send email',
    NOT_INFORM_PARTICIPANTS: 'No, do not inform',

    SEARCH_CAMPAIGNS_LABEL: 'HR Program',
    SEARCH_CAMPAIGNS_PLACEHOLDER: 'any',

    MODAL_HEADER_WARNING_MESSAGE: 'Please fill in all required fields.',
    MODAL_CLOSE_LABEL: 'Close modal',

    PAGE_TITLE_DEFAULT: 'Flexible Work and Knowledge Transfer',
    PAGE_TITLE_DASHBOARD: 'Dashboard',
    PAGE_TITLE_PROFILE: 'Profile',
    PAGE_TITLE_PROFILE_PREFIX: 'Profile of',
    PAGE_TITLE_DIRECT_MESSAGES: 'Messages',
    PAGE_TITLE_BOOKMARKS: 'Bookmarks',
    PAGE_TITLE_ABOUT: 'About',
    PAGE_TITLE_ABOUT_MODULES: 'About the modules',
    PAGE_TITLE_ABOUT_FAQ: 'FAQ',
    PAGE_TITLE_SETTINGS: 'Settings',
    PAGE_TITLE_LOGIN: 'Log in',
    PAGE_TITLE_LOGOUT: 'Log out',
    PAGE_TITLE_SIGNUP: 'Sign up',

    DRAG_YOUR_ITEM_HERE: 'Drag your item here',
    SIDEBAR_ARIA_LABEL: 'Open sidebar navigation',
};
