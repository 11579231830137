import { Injectable } from '@angular/core';
import { GeneralData } from './general-data.types';
import { HttpClient } from '@angular/common/http';
import { GeneralDataSerializer } from './general-data.serializer';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Params } from '@angular/router';

const PATH = '/api/v2/accounts/general-data';

@Injectable({
    providedIn: 'root',
})
export class GeneralDataService {
    constructor(private http: HttpClient, private generalDataSerializer: GeneralDataSerializer) {}

    save(generalData: GeneralData): Observable<GeneralData> {
        const dto = this.generalDataSerializer.serialize(generalData);
        return this.http.put<void>(`${PATH}`, dto).pipe(map(() => generalData));
    }

    checkUsername(username: string): Observable<any> {
        const params: Params = {
            name: username,
        };

        return this.http.get<{ success: boolean; error: string }>(`/api/v2/accounts/validateName`, { params }).pipe(
            map((response) => ({
                nameNotUnique: response.error === 'name_in_use',
                nameNotValid: response.error === 'invalid_characters_in_name',
                success: response.success,
            })),
        );
    }
}
