import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    GenerationExchangeProfileDTO,
    GenerationExchangeProfileMatchResponseDTO,
    GenerationExchangeSearchResponseDTO,
    GenerationExchangeSuggestedSkillsDTO,
} from './generation-exchange.dtos';
import { GENERATION_EXCHANGE_TOPIC_NAME, OffsetLimit } from '@tploy-enterprise/tenant-core';
import { Params } from '@angular/router';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

const BASE_PATH = `/api/v2/${GENERATION_EXCHANGE_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class GenerationExchangeApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<GenerationExchangeProfileDTO> {
        return this.httpClient.get<GenerationExchangeProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string): Observable<GenerationExchangeProfileDTO> {
        return this.httpClient.get<GenerationExchangeProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: GenerationExchangeProfileDTO): Observable<GenerationExchangeProfileDTO> {
        return this.httpClient.put<GenerationExchangeProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, {
            observe: 'body',
        });
    }

    getProfileMatches(
        offsetLimit: OffsetLimit,
        campaigns?: string[],
    ): Observable<GenerationExchangeProfileMatchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<GenerationExchangeProfileMatchResponseDTO>(`${BASE_PATH}/matches`, { params });
    }

    search(
        searchQuery: string,
        offsetLimit: OffsetLimit,
        campaigns?: string[],
    ): Observable<GenerationExchangeSearchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<GenerationExchangeSearchResponseDTO>(
            `${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`,
            { params },
        );
    }

    getSuggestedSkills(id: string): Observable<SuggestedSkills> {
        return this.httpClient.get<GenerationExchangeSuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }
}
