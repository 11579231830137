import { createReducer, on } from '@ngrx/store';
import { ExpertSearchActions } from './expert-search.actions';
import { ExpertSearchResult } from '../../expert-api/expert.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export interface ExpertSearchState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: Array<ExpertSearchResult>;
    allResults: Array<ExpertSearchResult>;
    pageParams: PageParams;
}

export const expertSearchInitialState: ExpertSearchState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: null,
    allResults: null,
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
};

export const expertSearchReducer = createReducer(
    expertSearchInitialState,
    on(ExpertSearchActions.searchEntities, (state) => {
        return { ...state, loading: true };
    }),
    on(ExpertSearchActions.searchEntitiesSuccess, (state, { results, allResults, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...results],
            allResults: allResults,
            pageParams,
        };
    }),
    on(ExpertSearchActions.loadSearchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(ExpertSearchActions.searchEntitiesError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);
