<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-string-list-field
        *ngIf="hasOfferedSkills()"
        key="EXPERT_PROFILE_VIEW_OFFERED_SKILLS"
        [value]="offeringSkills"
    ></tp-profile-view-string-list-field>
    <tp-profile-view-string-list-field
        *ngIf="hasInterestSkills()"
        key="EXPERT_PROFILE_VIEW_INTERESTED_IN_SKILLS"
        [value]="interestedInSkills"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        *ngIf="hasCommunicationTypes()"
        key="EXPERT_PROFILE_VIEW_COMMUNICATION_TYPE"
        [value]="communicationTypeKey"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-dates-field
        *ngIf="hasAvailability()"
        key="EXPERT_PROFILE_VIEW_UNAVAILABILITY_DATES"
        [from]="topicData.unavailabilityFrom"
        [to]="topicData.unavailabilityTo"
    ></tp-profile-view-dates-field>
</tp-profile-view-fieldset>
