import { NgModule } from '@angular/core';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { LanguageSwitcherViewComponent } from './view/language-switcher-view.component';
import { CommonModule } from '@angular/common';
import { TranslatableModule } from '../translatable/translatable.module';

@NgModule({
    imports: [CommonModule, TranslatableModule],
    declarations: [LanguageSwitcherComponent, LanguageSwitcherViewComponent],
    exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
