export default {
    SIGN_UP_REGISTER_TEASER:
        'Die Matching-Software für flexible Arbeitsformen, neue Projekte und Wissensaustausch mit deinen Kolleg*innen.',
    SIGN_UP_REGISTER_WELCOME_TITLE: 'Jetzt registrieren!',
    SIGN_UP_REGISTER_WELCOME_TEXT:
        '{{ appName }} ist dein Raum für flexible Arbeitsformen wie Jobsharing oder Jobrotations, neue Projekte zur Mitarbeit und Wissensaustausch mit Kolleg*innen, Expert*innen und Mentor*innen.',
    SIGN_UP_REGISTER_EMAIL_LABEL: 'E-Mail',
    SIGN_UP_REGISTER_EMAIL_FAILED: 'Bitte gib eine valide E-Mail-Adresse ein',
    SIGN_UP_REGISTER_EMAIL_INFO_TEXT: 'SIGN_UP_REGISTER_EMAIL_INFO_TEXT',
    SIGN_UP_REGISTER_EMAIL_INFO_TITLE: 'Valide E-Mail-Endungen:',
    SIGN_UP_REGISTER_PASSWORD_LABEL: 'Dein Passwort',
    SIGN_UP_REGISTER_PASSWORD_FAILED: 'Das Passwort muss mindestens {{ length }} Zeichen lang sein',
    SIGN_UP_REGISTER_PASSWORD_INFO_TITLE: 'Passwortregeln',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_MIN_LENGTH: 'insgesamt mind. {{ length }} Zeichen',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_DIGITS: 'mindestens zwei Zahlen',
    PASSWORD_TWO_NUMBERS: 'mindestens zwei Zahlen',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_SPECIAL_CHARS: 'mindestens zwei Sonderzeichen',
    PASSWORD_TWO_SPECIAL_CHAR: 'mindestens zwei Sonderzeichen',
    SIGN_UP_REGISTER_PASSWORD_INFO_TEXT_DIGITS_AND_SPECIAL_CHARS: 'Mindestens zwei Zahlen und zwei Sonderzeichen',
    SIGN_UP_REGISTER_LOGIN_TEXT: 'Du hast schon einen Account?',
    SIGN_UP_REGISTER_LOGIN_LINK: 'Hier einloggen!',
    SIGN_UP_REGISTER_BUTTON: 'Registrieren',
    SIGN_UP_ACCEPT_TOU_CHECKBOX_LABEL: 'Ich stimme den Nutzungsbedingungen zu.',
    SIGN_UP_ACCEPT_DP_CHECKBOX_LABEL: 'Ich akzeptiere die Datenschutzbestimmungen',

    SIGN_UP_LOGIN_TEASER:
        'Die Matching-Software für flexible Arbeitsformen, neue Projekte und Wissensaustausch mit Kolleg*innen.',
    SIGN_UP_LOGIN_WELCOME_TITLE: 'Willkommen zurück!',
    SIGN_UP_LOGIN_WELCOME_TEXT:
        '{{ appName }} ist dein Raum für flexible Arbeitsformen wie Jobsharing oder Jobrotations, neue Projekte zur Mitarbeit und Wissensaustausch mit Kolleg*innen, Expert*innen und Mentor*innen.',
    SIGN_UP_LOGIN_EMAIL_LABEL: 'E-Mail',
    SIGN_UP_LOGIN_EMAIL_FAILED: 'Bitte gib eine valide E-Mail-Adresse ein.',
    SIGN_UP_LOGIN_PASSWORD_LABEL: 'Passwort',
    SIGN_UP_LOGIN_PASSWORD_FAILED: 'Das Passwort muss mindestens {{ length }} Zeichen lang sein',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_MIN_LENGTH: 'insgesamt mind. {{ length }} Zeichen',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_DIGITS: 'mindestens zwei Zahlen',
    SIGN_UP_LOGIN_PASSWORD_INFO_TEXT_SPECIAL_CHARS: 'mindestens zwei Sonderzeichen',
    SIGN_UP_LOGIN_RESET_PASSWORD: 'Passwort zurücksetzen',
    SIGN_UP_LOGIN_REGISTER_LEGACY_TEXT: 'Du hast noch keinen Account?',
    SIGN_UP_LOGIN_REGISTER_LEGACY_LINK: 'Hier registrieren.',
    SIGN_UP_LOGIN_REGISTER_SSO_TEXT: 'Du bist kein externer User?',
    SIGN_UP_LOGIN_REGISTER_SSO_LINK: 'SIGN_UP_LOGIN_REGISTER_SSO_LINK',

    GUEST_TEASER: 'Die Matching Software für neues Arbeiten und eine vernetzte Organisation.',
    GUEST_TEASER_PARAGRAPH: '',
    SIGN_UP_REGISTER_SSO_WELCOME_TITLE: 'Willkommen auf Tandemploy!',
    SIGN_UP_REGISTER_SSO_WELCOME_TEXT:
        'Dies ist dein Raum, um vernetzter und flexibler zu arbeiten. Finde Kolleg*innen und Expert*innen aus anderen Bereichen zum Wissensaustausch, Sparringspartner für flexible Arbeitsformen und tolle Projekte zur Mitarbeit. Kann’s losgehen? Deine Kolleg*innen freuen sich auf dich, ganz bestimmt.',
    SIGN_UP_REGISTER_SSO_BUTTON: 'Einloggen oder registrieren',

    SIGN_UP_PRIVACY_TITLE: 'Datenschutzhinweise',
    SIGN_UP_PRIVACY_LABEL: 'Ich akzeptiere die Datenschutzhinweise',
    SIGN_UP_PRIVACY_BUTTON: 'Bestätigen',

    SIGN_UP_TERMS_TITLE: 'Nutzungsbedingungen',
    SIGN_UP_TERMS_LABEL: 'Ich akzeptiere die Nutzungsbedingungen',
    SIGN_UP_TERMS_BUTTON: 'Bestätigen',

    SIGN_UP_EMAIL_CONFIRMATION_TITLE: 'Bitte überprüfe deine Mailbox.',
    SIGN_UP_EMAIL_CONFIRMATION_TEXT_1: 'Vielen Dank für deine Registrierung bei {{ appName }}.',
    SIGN_UP_EMAIL_CONFIRMATION_TEXT_2:
        'Um deine Registrierung abzuschließen, folge bitte dem Link, den wir dir gerade per E-Mail zugesendet haben.',
    SIGN_UP_EMAIL_CONFIRMATION_BUTTON: 'Ok',

    PASSWORD_SWITCH_SAML_TEXT: 'Du bist kein externer User?',
    PASSWORD_SWITCH_SAML_LINK: 'Hier einloggen',
    PASSWORD_SWITCH_LOGIN_TEXT: 'Zurück zur Login-Seite',
    PASSWORD_SWITCH_LOGIN_LINK: 'Klicke hier',
    LOGIN_SWITCH_SAML_TEXT: 'Du hast eine Einladung an eine externe E-Mail-Adresse erhalten?',
    LOGIN_SWITCH_SAML_LINK: 'Hier einloggen',
    REGISTER_SWITCH_SAML_TEXT: 'Du hast eine Einladung an eine externe E-Mail-Adresse erhalten?',
    REGISTER_SWITCH_SAML_LINK: 'Hier einloggen',

    DESCRIPTION: 'Beschreibung',
    INTRODUCTION: 'Videos ansehen',
    INTRODUCTION_FOOTER: 'Vorschau',
    VIDEO_PREV_BTN_ARIA_LABEL: 'Zurück zum letzten Video {{videoTitle}}',
    VIDEO_NEXT_BTN_ARIA_LABEL: 'Weiter zum nächsten Video {{videoTitle}}',
    JOBSHARING: 'Jobsharing',
    MINUTES: 'Minuten',
    HOURS: 'Stunden',
    DAYS: 'Tage',
    YEAR: 'Jahr',
    YEARS: 'Jahre',
    MONTH: 'Monat',
    MONTHS: 'Monate',
    WEEK: 'Woche/n',
    WELCOME: 'Hallo {{name}}!',
    LOGIN: 'Login',
    LOGOUT: 'Abmelden',
    LOGOUT_LINK: '',
    LOGOUT_SUCCESS: 'Du hast dich erfolgreich ausgeloggt.',
    LOGIN_AGAIN: 'Erneut einloggen',
    LOGO: 'Logo',
    EMAIL: 'E-Mail',
    REPEAT_PASSWORD: 'Passwort wiederholen',
    NEW_PASSWORD_1: 'Neues Passwort',
    NEW_REPEAT_PASSWORD: 'Neues Passwort wiederholen',
    USERNAME: 'Benutzername',
    HOME: 'Über {{ appName }}',
    HOME_ARIA_LABEL: 'Informationen über die Plattform {{ appName }}',
    HOME_MOBILE: 'About',
    COMPANY_JOBSHARER: 'Jobsharer-Pool',
    DASHBOARD: 'Mein {{ appName }}',
    DASHBOARD_HOME_TITLE: 'Mein {{ appName }}',
    JOBS: 'Angebote',
    FORUM: 'Forum',
    ADMIN_PANEL: 'Administration',
    ADMIN_STATS: 'Statistiken',
    ADMIN_ROLES: 'Plattform Administration',
    COMPANY_JOBS: 'Jobbörse',
    COMPANY_INFO: 'Produkte & Infos',
    JOBSHARERS: 'Meine Kollegen',
    EDIT_PROFILE: 'Profil bearbeiten',
    JOBSHARERS_MATCHING_SEARCH_LABEL: 'Per Schlagwort nach anderen Kollegen suchen: ',
    JOB_BOARD: 'Job Board',
    SHOW_MORE: 'Mehr anzeigen',
    SHOW_LESS: 'Weniger anzeigen',
    BOOKMARKS: 'Gemerkte Kollegen',

    COMPANIES: 'Unternehmen finden',
    COMPANY_PROFILES: 'Unternehmensprofile',
    ABOUT_FAQ: 'About, FAQ',
    INFORMATION: 'Informationen',
    COMPANY_COMPANIES: 'Unternehmensprofile',
    NEED_HELP: 'Brauchst du Hilfe?',
    PROFILE: 'Mein Profil',
    NEWS: 'Dashboard',
    WORKING_MODELS: 'Über die Module',
    WORKING_MODELS_ARIA_LABEL: 'Informationen über die angebotenen Arbeitsmodelle',
    WORKING_MODELS_MOBILE: 'Modelle',
    PROFILE_SHORT: 'Profil',
    TANDEMS: 'Meine Tandems',
    BLOG: 'Blog',
    PRESSE: 'Presse',
    BUTTON_LANG_EN: 'englisch',
    BUTTON_LANG_DE: 'deutsch',
    PREVIEW: 'Vorschau',
    UPDATE_PICTURE: 'Bild hochladen',
    UPDATE_PROFILE: 'Profil',
    CREATE_PROFILE: 'Profil erstellen',
    CREATE_JOB: 'Job erstellen',
    SETTINGS: 'Einstellungen',
    REMEMBER_ME: 'Angemeldet bleiben',
    UPDATE: 'Aktualisieren',
    CREATE: 'Fertig',
    DONE: 'Fertig',
    NEXT: 'Weiter',
    BACK: 'Zurück',
    FIRST: 'Erste',
    LAST: 'Letzte',
    BACK_NAV: 'Zurück',
    SAVE: 'Speichern',
    OK: 'OK',
    FINISH_AND_GO_ON: 'Speichern und weiter',
    ACCEPT: 'Annehmen',
    CLOSE: 'Schließen',
    CANCEL_QUESTIONAIRE: 'vorzeitig abbrechen und Fragebogen später beantworten',
    SEND: 'Senden',
    CONTINUE: 'Weiter',
    JUMP: 'Überspringen',
    ADD: 'Hinzufügen',
    IN: 'in',
    MORE: 'mehr...',
    CANCEL: 'Abbrechen',
    CANCEL_CAP: 'Abbrechen',
    OK_DELETE_MY_INPUT: 'Ok, Eingaben löschen',
    CONTINUE_EDITING: 'Weiter bearbeiten',
    HEADLINE_DIALOG_CONFIRM_EDIT_DATA: 'Ihre Eingabe geht verloren',
    IF_LOSE_DIALOG_CONFIRM_EDIT_DATA:
        'Wenn du das Fenster schließt, ohne zu speichern, gehen deine neuen Eingaben verloren.',
    CLICK_FOR_CONTINUE_DIALOG_CONFIRM_EDIT_DATA:
        'Klick auf "weiter bearbeiten", wenn du deine neuen Informationen behalten willst.',
    DELETE: 'Löschen',
    DELETE_IRREVERSIBLY: 'Unwiderruflich löschen',
    CONFIRM: 'Bestätigen',
    UNSTAR: 'Entfernen',
    CREATE_PROFILE_NAV: 'Profil erstellen',
    CREATE_PROFILE_NAV_TODO: 'noch nicht registriert?',
    UPDATE_PROFILE_SOFT: 'Fragebogen',
    SAVE_PROFILE_SOFT: 'Fragebogen speichern',
    UPLOAD_OTHER_IMAGE: 'Um dein Profilbild zu ändern, klicke auf dein Bild.',
    UPDATE_PROFILE_HARD_NEW: 'Profil anlegen',
    UPDATE_PROFILE_SOFT_NEW: 'Fragebogen beantworten',
    UPDATE_HARD_FACTS: 'Profil updaten',
    UNI_OLD_TEXT: 'Gründerpreis der',
    ECO_TEXT: '1. Platz Ideenwettbewerb',
    PRICES_HEADER: 'Preise',
    ATTR_PUBLIC: '{{ attr }} wird angezeigt',
    ATTR_PRIVATE: '{{ attr }} ist ausgeblendet',
    LAST_NAME_PUBLIC: 'Nachnamen verbergen',
    LAST_NAME_PRIVATE: 'Nachnamen zeigen',
    SIGNED_IN_HEADER: 'Du musst angemeldet sein!',
    JOB_LABEL: 'Angebote',
    POOL_LABEL: 'User-Pool',
    USER_POOL_HEADER: 'Alle User',
    INVITE: 'Einladen',
    PAGE: 'Page',
    SAVE_AND_SYNC_SF: 'Übernehmen & mit SF synchronisieren',
    APPLY_NOW: 'Jetzt bewerben!',

    HIDE_SUGGESTED_SKILL: 'Nicht mehr vorschlagen',

    REQUEST_NEW_PASSWORD: 'Passwort zurücksetzen',
    FOR_RESET_EMAIL_REQUIRED:
        'Um das Passwort zurückzusetzen, bitte oben E-Mail eingeben und erneut <<Passwort zurücksetzen>> klicken!',
    FOR_RESET_EMAIL_NOT_KNOWN: 'Deine E-Mail ist uns leider nicht bekannt. Bitte versuch es erneut.',
    FOR_RESET_EMAIL_RECEIVED: 'In Kürze erhältst du einen Link zum Zurücksetzen deines Passworts.',
    NEW_PASSWORD: 'Dein neues Passwort',

    JOBSHARER_HELP_INFO: 'Wende dich bei Fragen & Feedback jederzeit an unseren Support!',

    HELP_AND_LEGAL_INFORMATION: 'Hilfe, Impressum, Privacy',
    HELP_AND_LEGAL_INFORMATION_BTN_ARIA_LABEL: 'Navigation öffnen zu Hilfe, Datenschutz und Impressum',
    HELP_CONTACT: 'Hilfe und Kontakt',
    HELP_CONTACT_DIALOG_HEADER: 'Hilfe und Kontakt',
    HELP_CONTACT_EMAIL_DIALOG_TEXT:
        'Solltest Du technische Probleme haben oder Unterstützung benötigen, schreib uns gern eine E-Mail an',
    HELP_CONTACT_HELP_DESK_DIALOG_TEXT: 'Bei Fragen und technischen Problemen wende dich bitte an:',
    HELP_CONTACT_EMAIL_LINK: 'Support-Anfrage',
    HELP_CONTACT_FEEDBACK_CAPTION: 'Feedback',
    LEGAL: 'Impressum',
    ACCOUNT_DELETE: 'Account löschen',
    T_C: 'Nutzungsbedingungen',
    PRIVACY: 'Datenschutzhinweise',
    LEGAL_1: 'Rechtliches',
    FAQ: 'Fragen und Antworten',
    FAQ_ARIA_LABEL: 'Fragen und Antworten',
    FAQ_MOBILE: 'FAQ',
    FAQ_FOOTER: 'Fragen und Antworten',
    PARTNER: 'Unsere Partner',
    ZEITSCHENKER: 'Zeitschenker',
    KNOWN_FROM: 'Bekannt aus',
    NEWSLETTER: 'Newsletter',
    NEWSLETTER_FOOTER: 'Newsletter',
    HOME_FOOTER: 'Home',

    JOBSHARER_DASHBOARD_TITLE: 'Willkommen auf {{ appName }}!',
    JOBSHARER_DASHBOARD_NEWS_TITLE: 'News',
    JOBSHARER_DASHBOARD_HAPPENINGS_TITLE: 'Neuigkeiten auf {{ appName }}',
    JOBSHARER_DASHBOARD_MESSAGES_TITLE: 'Neue Nachrichten',
    JOBSHARER_DASHBOARD_BLOG_POST: ' - Auf dem Tandemploy-Blog',
    JOBSHARER_DASHBOARD_RETWEETED: '{{ appName }} retweetet',
    JOBSHARER_DASHBOARD_NEWS: 'News auf {{ appName }}',
    JOBSHARER_DASHBOARD_TANDEMPLOY_TWITTER: '@tandemploy',

    INFO_PROFILE_BUTTON: 'Zum Profil',
    INFO_PROFILE_BUTTON_ARIA_LABEL: 'Zu deinem Profil',

    CONTACT_PROFILE_BUTTON: 'Kontakt aufnehmen',
    PROFILE_BUTTON_GET_TO_KNOW: 'Zum Kaffee einladen',

    SEARCH_JOB_ADMIN_TITLE: 'Angebote erstellen und verwalten',
    SEARCH_JOB_TITLE: 'New Work Angebote und Arbeitsbereiche',
    JOB_SEARCH_SUB_TITLE_TEXT: '',
    SEARCH_JOBS_SECOND_TITLE:
        'Hier findest du Jobs, die ausdrücklich teilbar sind! Kein Job dabei? Viele Tandempartner sind bereits in tollen Jobs, die sie teilen möchten! Einfach Profil erstellen und sich austauschen!',
    SEARCH_JOB_COMPANY_TITLE: 'Jobbörse',
    SEARCH_JOBS_COMPANY_SECOND_TITLE: 'Viele Jobsharing-Pioniere schreiben bereits Stellen bei uns aus!',
    SEARCH_JOBS_PLACEHOLDER: "Gib hier Suchbegriffe ein, z.B. 'Berlin', 'Marketing'...",

    JOB_SEARCH_SUGGESTIONS_TITLE: 'Diese Stellen könnten spannend für dich sein:',
    JOB_SEARCH_SUB_TITLE: 'Alle {{category}} in unserer Jobbörse:',
    JOB_SEARCH_SUB_TITLE_ALL: 'Finde interessante Arbeitsbereiche und Angebote von Kolleg*innen und HR!',
    JOB_SEARCH_SUB_TITLE_ADMIN: 'Wähle zwischen HR- und von Usern erstellten Angeboten:',
    JOB_HR_APPROVED_TOOLTIP: 'Dieses Angebot wurde von der HR-Abteilung erstellt.',
    JOB_SEARCH_FILTER_HR: 'HR-Angebote',
    JOB_SEARCH_FILTER_USER: 'Von Usern erstellte Angbote',

    ADMIN_JOBS_HEADER: 'Angebote bearbeiten',

    JOB_CREATE_TITLE_JOBSHARING: 'Meinen derzeitigen Arbeitsbereich für potenzielle Tandempartner*innen beschreiben.',
    JOB_CREATE_TITLE_JOBROTATION: 'Beschreibe hier deinen aktuellen Job und einen möglichen Jobtausch',
    JOB_CREATE_TITLE_PROJECT: 'Beschreibe hier das Projekt, für das du Mitstreiter suchst',
    JOB_ADMIN_CREATE_TITLE_PROJECT: 'Beschreibe hier das Projekt, um passende Mitstreiter zu finden.',
    JOB_ADMIN_CREATE_TITLE_JOBSHARING: 'Beschreibe hier den Job, für den du ein Tandem suchst',
    JOB_ADMIN_CREATE_TITLE_JOBROTATION:
        'Beschreibe hier den Job, den du als Jobrotation ausschreiben möchtest (einseitige, kein Tausch)',
    JOB_ADMIN_CREATE_TITLE: 'Job Veröffentlichen',
    JOB_UPDATE_TITLE: 'Bearbeite deinen Job',
    JOB_ADMIN_UPDATE_TITLE: 'Job bearbeiten',
    CREATE_NEW_JOB: 'Angebot erstellen',
    CREATE_NEW_JOB_INFO:
        'Hier kannst du selber deinen Job zum Teilen anbieten, eine Jobrotation vorschlagen oder Projekte veröffentlichen, für die du Mitstreiter suchen.',
    CREATE_NEW_JOB_INFO_ADMIN:
        'Hier kannst du neue Angebote erstellen, veröffentlichen und ggf. bestimmten Nutzer*innen zuweisen.',
    ADMIN_CREATE_NEW_JOB: 'Angebot erstellen',
    ADMIN_CREATE_NEW_JOB_INFO:
        'Hier kannst du neue Angebote erstellen, veröffentlichen und ggf. bestimmten Nutzer*innen zuweisen.',

    JOB_CITY: 'Standort:',
    CITY_PLACEHOLDER: 'z.B. Berlin, München',
    JOBROTATION_INTERESTED_CREATE_TAGS_PLACEHOLDER_TEXT: 'Welchen Job suchst du? (Schlagwörter)',
    JOBROTATION_INTERESTED_CATEGORIES_SUBTITLE:
        'Gib hier die wichtigsten Schlagwörter an und trenne die einzelnen Begriffe durch "Enter". Je mehr Tags du einträgst, desto besser das anschließende Matching.',
    JOBROTATION_OFFERING_CREATE_TAGS_PLACEHOLDER_TEXT: 'Welchen Job bietest du? (Schlagwörter)',
    JOBROTATION_OFFERING_CREATE_TAGS_PLACEHOLDER_TEXT_ADMIN: 'Schlagwörter zum Job, z.B. "Marketing", "IT"',
    JOBROTATION_OFFERING_CATEGORIES_SUBTITLE:
        'Gib hier die wichtigsten Schlagwörter an und trenne die einzelnen Begriffe durch "Enter". Je mehr Tags du einträgst, desto besser das anschließende Matching.',
    JOB_TITLE_SUBTITLE:
        'Wähle einen generischen Jobtitel wie "Führungsposition im Bereich Marketing", wenn du deine genaue Berufsbezeichnung aus Gründen der Anoynmität noch nicht preisgeben möchten.',
    JOB_TITLE_SUBTITLE_ADMIN: 'Wähle einen Jobtitel, welcher diese Position möglichst präzise beschreibt.',
    JOB_START_DATE_SUBTITLE: 'Gib das anvisierte Startdatum an. Dieses muss nicht in Stein gemeißelt sein.',
    JOB_WORKING_HOURS_SUBTITLE: 'Wochenstunden, die auf den/die Tandempartner*in entfallen sollen',
    JOB_WORKING_HOURS_SUBTITLE_ADMIN:
        'Gib hier den geschätzten Gesamtumfang des Jobs an. Ggf. kann dieser auch über eine klassische Vollzeitstelle hinausgehen. Traue dich, in Bereichen und tatsächlichen Aufgaben zu denken. Braucht der Job vielleicht eher 60 oder 70 Wochenstunden - oder vielleicht auch nur 30?',
    JOB_DESCRIPTION_SUBTITLE:
        'Du möchtest hier eine bestehende Jobbeschreibung einfügen? Dann kopiere diese bitte aus dem Word Dateiformat DOCX. Sollten Probleme auftreten, sende uns gerne eine E-Mail an support@tandemploy.com.',
    JOB_DESCRIPTION_PLACEHOLDER:
        'Beschreibe hier in eigenen Worten deine aktuelle Tätigkeit. Was macht deinen Job aus? Was wären mögliche Aufgabenbereiche für deinen Tandempartner?...',
    JOB_DESCRIPTION_PLACEHOLDER_ADMIN:
        'Beschreibe hier die Position, für die du ein Tandem suchst. Welche Aufgabenbereiche sollen übernommen werden? Welche Anforderungen bringt die Position mit sich?',
    JOB_DESCRIPTION_SUBTITLE_ADMIN:
        "Falls vorhanden, kannst du hier die 'normale' Stellenbeschreibung einer Vollzeitstelle einfügen oder aber den Job in eigenen Worten beschreiben.",
    JOB_CONTACT_INFO_ADMIN:
        'Um diese Ausschreibung einem bereits registrierten Benutzer zuzuordnen, gib bitte dessen Benutzernamen an.',
    JOB_DURATION: 'Dauer:',
    JOB_DURATION_IN_HOURS: 'Wochenstunden Tandempartner*in:',
    JOB_DURATION_IN_HOURS_ADMIN: 'Wochenstunden insgesamt:',
    JOBSHARING_CREATE_TAGS_PLACEHOLDER_TEXT: 'Schlagwörter *',
    JOB_START_DATE: 'Frühestes Startdatum:',
    JOB_TANDEM_TYPE: 'Gesucht:',
    FINISH_AND_PUBLISH: 'Speichern und veröffentlichen',
    JOB_DEFAULT_DESCRIPTION:
        'Liebe Kollegin, lieber Kollege,\nwie schön, dass du zu meinem Angebot gefunden hast und wir vielleicht ganz ähnliche Interessen haben! Auch wenn eine detaillierte Beschreibung noch fehlt – warum nicht schon einmal Kontakt aufnehmen? Vielleicht können wir uns im persönlichen Gespräch leichter austauschen.\n Über den Button hier auf der Seite kannst du mir direkt eine kurze Nachricht schicken. Ich würde mich sehr freuen!',

    JOBROTATION_DURATION: 'Dauer des Jobtausches',
    JOBROTATION_DURATION_SUBTITLE:
        'Die Angabe soll Kollegen einen ersten Anhaltspunkt geben und ist nicht in Stein gemeißelt.',
    JOBROTATION_DESCRIPTION_INFO:
        'Du möchtest hier eine bestehende Jobbeschreibung einfügen? Dann kopiere diese bitte aus dem Word Dateiformat DOCX. Sollten Probleme auftreten, sende uns gerne eine E-Mail an support@tandemploy.com.',
    JOBROTATION_DESCRIPTION_INFO_ADMIN:
        'Du möchtest hier eine bestehende Jobbeschreibung einfügen? Dann kopiere diese bitte aus dem Word Dateiformat DOCX. Sollten Probleme auftreten, sende uns gerne eine E-Mail an support@tandemploy.com.',
    JOBROTATION_TITLE: 'Aktueller Jobtitel',
    JOBROTATION_CITY: 'Aktueller Standort',

    JOB_LOCATION: 'Standort:',
    JOB_DEPARTMENT_PLACEHOLDER: 'Deine Abteilung',
    JOB_DEPARTMENT: 'Abteilung',

    DELETE_PROJECT_HEADER: 'Projekt löschen',
    DELETE_PROJECT_TEXT: 'Willst du wirklich dieses Projekt "{{ title }}" löschen?',
    DELETE_JOB_HEADER: 'Job löschen',
    DELETE_JOB_TEXT: 'Willst du wirklich diesen Job "{{ title }}" löschen?',

    JOB_HEADER_ALL: 'New Work Angebot',
    JOB_HEADER_PROJECT: 'Projekt',
    JOB_HEADER_TANDEM: 'Tandem',
    JOB_HEADER_JOBROTATION: 'Jobrotation',
    JOB_HEADER_JOBSHARING: 'Jobsharing-Job',
    JOB_HEADER_SINGLE_JOBSHARER: 'Jobsharer-Job',
    JOB_HEADER_CROSS_FUNCTIONAL: 'Funktionsübergreifenden Job',
    JOB_HEADER_SHORT_TIME: 'Kurzeinsatz',

    JOB_SUB_HEADER_FLEXIBLE_JOBS: 'New Work Angebote',
    JOB_SUB_HEADER_PROJECT: 'Projekte',
    JOB_SUB_HEADER_TANDEM: 'Tandem-Jobs',
    JOB_SUB_HEADER_JOBROTATION: 'Jobrotations',
    JOB_SUB_HEADER_JOBSHARING: 'Jobsharing-Jobs',
    JOB_SUB_HEADER_SINGLE_JOBSHARER: 'Jobsharer-Job',
    JOB_SUB_HEADER_CROSS_FUNCTIONAL: 'funktionsübergreifenden Jobs',
    JOB_SUB_HEADER_SHORT_TIME: 'Kurzeinsätze',

    JOB_START_PROJECT: 'Projekt',
    JOB_START_JOBROTATION: 'Jobrotation',
    JOB_START_JOBSHARING: 'Jobsharing',
    JOB_START_MENTORING: 'Mentoring',
    JOB_START_NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    JOB_START_NETWORKING: 'Vernetzung',

    IMAGE_ALT_JOBSHARING: '',

    KNOWLEDGE_TRANSFER: 'Wissenstransfer',
    KNOWLEDGE_TRANSFER_KNOWLEDGE_TRANSFER:
        'Erfahrungen mit Menschen aus demselben Fachbereich austauschen und Wissen teilen.',
    ONBOARDING: 'Ankommen & Einleben',
    ONBOARDING_NEEDED: 'Ein persönlicher Ansprechpartner fürs Einleben im Unternehmen wäre toll.',
    ONBOARDING_OFFERED: 'Ich kenne das Unternehmen und erleichtere neuen Kollegen gerne das Ankommen.',
    CONNECT_WITH: 'Generationsübergreifender Austausch',
    CONNECT_WITH_EXPERIENCED:
        'Vernetzen mit Mitarbeitern der jüngeren Generation? Warum nicht! Wir können viel voneinander lernen.',
    CONNECT_WITH_YOUNG: 'Erfahrung zählt! Vernetzung mit älteren Mitarbeitern fände ich spannend.',
    INTERDISCIPLINARY: ' Interdisziplinärer Austausch',
    INTERDISCIPLINARY_INTERDISCIPLINARY:
        'Ich würde gerne über meinen eigenen Tellerrand hinaus und in die Arbeit anderer Abteilungen hineinblicken.',
    PERSONAL_DEV_ADVICE: 'Persönliche Entwicklung',
    PERSONAL_DEV_ADVICE_RECEIVE:
        'Zur fachlichen und persönlichen Entwicklung wünsche ich mir Feedback von einem Mentor für Karrierefragen.',
    PERSONAL_DEV_ADVICE_PROVIDE:
        'Ich bin bereits länger im Berufsleben und freue mich, Karrieretipps an jüngere Kollegen weiterzugeben.',
    INTERCULTURAL_RELOCATION: 'Interkultureller Austausch',
    INTERCULTURAL_RELOCATION_NEW:
        'Ich spiele mit dem Gedanken, an einem anderen Standort zu arbeiten und möchte mich gerne darüber austauschen.',
    INTERCULTURAL_RELOCATION_EXPERIENCE:
        'Ich gebe Kollegen gerne Einblick in meinen Standort oder teile Erfahrungen über vergangene Standortwechsel.',
    MENTORING_FREE_TEXT:
        'Suchst du einen Mentor/Mentee in einem noch nicht genannten Bereich? Dann beschreibe diesen hier:',

    JOB_CATEGORY: 'Schlagwörter',
    JOB_CATEGORY_PLACEHOLDER: 'z.B. Controlling, HR',
    JOB_JOBSHARER_TYPE: 'suchen:',
    JOB_APPLY: 'Jetzt bewerben',
    JOB_APPLY_CONTACT: 'Jetzt direkt bewerben bei',
    JOB_SHARE: 'Diese Stelle Freunden empfehlen, die mehr Zeit gebrauchen könnten.',
    JOB_DESCRIPTION_1: 'Jobbeschreibung',
    JOB_WANTS_TO_SHARE: 'Möchte seine Stelle teilen',
    JOB_CONTACT_USER: 'Nachricht und/oder Bewerbung an Stelleninhaber senden.',
    JOB_CREATE_TAGS_PLACEHOLDER_TEXT: 'z.B. HR, Marketing',
    JOB_CREATED_NOTIFICATION:
        'Großartig! Du hast dein Angebot erstellt. Dieses kannst du hier direkt bearbeiten. Außerdem ist es in deinem persönlichen Profil gespeichert.',
    JOB_CREATED_BY_ADMIN_NOTIFICATION:
        'Großartig! Du hast dein Angebot erstellt. Dieses kannst du hier direkt bearbeiten. Außerdem ist es im Admin-Bereich gespeichert.',
    JOB_UPDATE_SUCCESS: 'Dein Job wurde erfolgreich bearbeitet.',
    JOB_CREATE_ERROR: 'Dein Job konnte nicht gespeichert werden. Bitte überprüfe deine Angaben.',
    JOB_ESSENTIALS_HEADLINE: 'Eckdaten',
    JOBROTATION_INTERESTED_CATEGORY: 'Gesuchter Job (Schlagwörter)',
    JOBROTATION_OFFERING_CATEGORY: 'Eigener Job (Schlagwörter)',

    JOB_OWNER: 'Job-Owner',
    JOB_OWNER_ADMIN: 'Admin Job',

    EMAIL_WITH_REQUIRED: 'E-Mail *',
    CONTACT_PERSON: 'Benutzername',
    CONTACT_PERSON_WITH_REQUIRED: 'Ansprechpartner *',
    JOBTITLE: 'Jobtitel',
    WRITE_JOBTITLE: 'Dein Jobtitel',
    WRITE_JOBTITLE_ADMIN: 'Jobtitel der Position',
    CATEGORIES: 'Schlagwörter z.B. HR, Marketing *',
    CATEGORIES_SUBTITLE:
        'Gib hier die wichtigsten Schlagwörter zu deinem Job an und trenne die einzelnen Begriffe durch "Enter". Je mehr Tags du einträgst, desto besser das anschließende Matching.',
    CATEGORIES_SUBTITLE_ADMIN:
        'Gib hier die wichtigsten Schlagwörter zu dem Job an und trenne die einzelnen Begriffe durch "Enter". Je mehr Tags du einträgst, desto besser das anschließende Matching.',
    CATEGORIES_PLACEHOLDER: 'Es können mehrere Kategorien ausgewählt werden',
    CITY_REQUIRED: 'Standort',
    CITY: 'Standort',
    SHARING_DETAILS: 'Was möchtest du ausschreiben?',
    JOBSHARER_TEXT: 'Suchen Jobsharer (Einzelperson)',
    TANDEM_TEXT: 'Suchen Tandem (Zwei Personen)',
    JOB_DESCRIPTION: 'Jobbeschreibung *',
    POST: 'Veröffentlichen',

    JOB_TITLE_TOO_SHORT: 'Der Titel muss mindestens 5 Zeichen lang sein.',

    JOB_APPL_HEADER: 'Bewirb dich jetzt!',
    JOB_APPL_EMAIL: 'Deine E-Mail-Adresse:',
    JOB_APPL_BODY: 'Dein Anschreiben:',
    JOB_APPL_ATTACHMENTS: 'Anhänge:',

    JOBSHARER_SEARCH_TITLE: 'Jetzt interessante Kollegen finden!',
    JOBSHARER_SEARCH_SUB_TITLE: 'Alle Mitarbeiter auf {{ appName }}:',

    JOBSHARER_SEARCH_SUGGESTIONS_TITLE: 'Meine Matches:',
    JOBSHARER_SEARCH_SUGGESTIONS_INFO:
        'Unser Matching-Algorithmus schlägt dir nur Kollegen vor, die grundsätzlich zu deinen Interessen und den Angaben in deinem Profil passen. Neben den „harten Faktoren“ liegt je nach gewünschtem Modell auch ein besonderer Fokus auf den „weichen Faktoren“. Fülle für ein gelungenes Matching dein Profil (und den Matching-Fragebogen) möglichst vollständig aus. Und nicht vergessen: Auch ein perfektes Matching kann am Ende nicht den persönlichen Austausch ersetzen.',

    SUGGESTIONS_NO_PROFILE_SUBTITLE: 'Es können noch keine passenden Kollegen gefunden werden.',
    SUGGESTIONS_NO_PROFILE_TEXT:
        'Sobald du dein Profil vervollständigt hast, schlagen wir dir an dieser Stelle automatisch passende Kollegen vor.<br/>Die bisherigen Angaben reichen leider noch nicht für ein aussagekräftiges Matching aus. Bitte vervollständige hier dein Profil.',
    SUGGESTIONS_NO_PROFILE_BUTTON: 'Zur Profilerstellung',
    SUGGESTIONS_UPDATE_PROFILE_BUTTON: 'Profil vervollständigen',

    SUGGESTIONS_NO_MATCH_TEXT:
        'Leider gibt es noch keine Matches.<br/>Ändere die Filtereinstellungen, um mehr Ergebnisse zu sehen.',
    SUGGESTIONS_OPTIMIZE_PROFILE: 'Profil optimieren',
    SUGGESTIONS_OPTIMIZE_PROFILE_BTN_ARIA_LABEL: 'Angaben zum Thema {{topic}} optimieren',
    SUGGESTIONS_NO_MATCH_FOUND_TITLE: 'Noch keine passenden Profile gefunden',
    SUGGESTIONS_NO_MATCH_FOUND: 'Ergänzen Sie Angaben im Profil, damit steigen die Chancen auf Matches.',

    MATCHING_INVITE_FRIENDS_TITLE: 'Lade deine Kolleg/innen ein',
    MATCHING_INVITE_FRIENDS: 'Mehr User – mehr Matches für alle! Bringen Sie Ihre Kolleg/innen auf die Plattform. ',
    MATCHING_INVITE_FRIENDS_BUTTON: 'Einladen',
    MATCHING_INVITE_FRIENDS_BTN_ARIA_LABEL: 'Zum Formular Kollegen einladen',
    MATCHING: 'Matching',

    CARD_FACTS_END_TIME_UNTIL: 'Noch {{endTime}} Tage',

    JOBSHARER_STATUS_JOB: 'Möchte aktuell einen Job teilen',
    JOBSHARER_STATUS_TANDEM: 'Ist bereits dein Tandempartner',
    JOBSHARER_STATUS_INTERESTED: 'Bereits als interessant markiert',
    JOBSHARER_STATUS_PROJECT_LEAD: 'Ist Projektverantwortlicher',

    OPEN_CONVERSATION_BTN_TOOLTIP_MESSAGE: 'Neue Nachricht',
    OPEN_CONVERSATION_BTN_ARIA_LABEL: 'Neue Nachricht an {{userName}}',
    JOB_WRITE_MESSAGE: 'Nachricht',

    MESSAGES_TITLE: 'Bleibe in Kontakt mit <b>{{ name }}</b>',
    MESSAGE_WITH: 'Schreibe eine neue Nachricht an <b>{{ name }}</b>',
    JOBSHARER_DEACTIVATED: 'Dieser Nutzer hat seinen Account deaktiviert.',

    MESSAGING: 'Meine Nachrichten',
    MESSAGING_SUBTITLE: 'Hier findest du deine Gespräche mit anderen Mitgliedern im Überblick!',
    MESSAGING_NO_CONTENT_1: 'Noch keine anderen Mitglieder kennengelernt? Mache dich jetzt auf die Suche nach',
    MESSAGING_NO_CONTENT_2: '',
    MESSAGING_NO_CONTENT_LINK: 'flexiblen Kollegen!',
    MESSAGING_ME: 'Ich: ',

    CONVERSATION_DELETE_HEADER: 'Unterhaltung löschen',
    CONVERSATION_DELETE_TEXT: 'Möchtest du diese Unterhaltung wirklich löschen?',
    SPAM_HEADER: 'Spam melden',
    SPAM_TEXT: 'Ich möchte diese Unterhaltung als Spam melden, weil...',
    SPAM_SUCCESS:
        'Vielen Dank, dass du uns bei unserem Vorgehen gegen SPAM unterstützt und diese Unterhaltung gemeldet hast. Wir kümmern uns nun darum!',
    REPORT: 'Melden',

    CONVERSATION_DELETE: 'Unterhaltung löschen',
    SPAM_REPORT: 'Als Spam melden',

    MESSAGE_MODAL_TITLE: 'Schreibe {{ shortName }} eine Nachricht!',
    MESSAGE_ANONYM:
        'Empfänger deiner Nachrichten sehen nur deinen Benutzernamen, nicht deine E-Mail-Adresse! Sofern du einen Fantasie-Benutzernamen gewählt hast, bist du also so lange anonym, bis du dich bewusst zu erkennen gibst.',
    MESSAGE_TITLE_INVITE_FOR_COFFEE: 'Einladung auf einen Kaffee',
    MESSAGE_TITLE_INVITE_FOR_TEA: 'Einladung auf einen Tee',
    MESSAGE_TITLE_INVITE_FOR_LUNCH: 'Einladung zum Mittag',
    MESSAGE_TITLE_INVITE_FOR_BEER: 'Einladung auf ein Bier (nach Feierabend)',

    TANDEMS_TITLE: 'Meine Tandempartner',
    TANDEM_PENDING: 'Ausstehend',
    TANDEM_ACCEPT: 'Annehmen',
    TANDEM_DELETE_HEADER: 'Tandempartner entfernen?',
    TANDEM_DELETE_TEXT: 'Möchtest du {{ name }} wirklich als potentiellen Tandempartner löschen?',
    NEW_TANDEMPARTNER_MESSAGE_HEADER: '{{ name }} möchte dich als Tandempartner hinzufügen',
    NEW_TANDEMPARTNER_MESSAGE_BUTTON: 'Annehmen',
    TANDEM_NO_SUBTITLE: 'Du hast noch keine potentiellen Tandempartner hinzugefügt.',
    TANDEM_NO_TEXT:
        'Du hast interessante Kollegen gefunden und möchtest gerne mit ihnen ein Tandem bilden? Über den Button „Tandems bilden“ in den jeweiligen Profilen deiner Kollegen, kannst du das Profil eines anderen Mitglieds mit deinem eigenen zu einem Tandemprofil verschmelzen lassen.',

    TANDEM_TITLE: 'Dein Tandemprofil mit {{ name }} {{ lastName }}',
    TANDEM_EXP_YEARS: 'Jahr/e',

    ADD_TANDEM: 'Tandem bilden',
    ADD_TANDEM_BTN_ARIA_LABEL: 'Tandemanfrage an {{userName}} verschicken',
    SHOW_TANDEM: 'Tandemprofil zeigen',
    SHOW_TANDEM_BTN_ARIA_LABEL: 'Zum Tandemprofil mit {{userName}}',
    TANDEM_CREATE_HEADER: 'Tandempartner hinzufügen?',
    TANDEM_CREATE_TEXT:
        'Füge {{ name }} als einen möglichen Tandempartner hinzu. Nachdem du {{ name }} hinzugefügt hast, muss dieser dich als potentiellen Tandempartner bestätigen.',
    TANDEM_MESSAGE: 'Nachricht hinzufügen',
    TANDEM_CREATE_MESSAGE_LABEL: 'Persönliche Nachricht',
    TANDEM_CREATE_MESSAGE_PLACEHOLDER: 'Meine persönliche Tandem-Einladung an mein Match…',
    TANDEM_CREATE_MESSAGE_ARIA_LABEL: 'Schicke {{userName}} eine persönliche Nachricht.',
    TANDEM_CREATE_CANCEL_BTN_ARIA_LABEL: 'Tandemanfrage abbrechen',
    TANDEM_CREATE_ADD_BTN_ARIA_LABEL: 'Tandemanfrage verschicken',

    TANDEM_LINK_HEADER: 'Du möchtest deinen Kollegen den Link zu deinem Tandemprofil senden?',
    TANDEM_LINK_TEXT:
        'Versende diesen Link an deine Kollegen, wenn du ihnen die Ansicht deines Tandemprofils ermöglichen möchten.',

    PROFILE_SHOW_SECURE_LINK: 'Mein Profil-Link',
    PROFILE_SHOW_SECURE_LINK_BTN_ARIA_LABEL: 'Mein Profil-Link anzeigen, um es Kolleginnen und Kollegen zu schicken',
    PROFILE_LINK_HEADER: 'Du möchtest deinen Kollegen den Link zu deinem Profil senden?',
    PROFILE_LINK_TEXT:
        'Versende diesen Link an deine Kollegen, wenn du ihnen die Ansicht deines persönlichen Profils ermöglichen möchtest.',
    PROFILE_STAR_TEXT: 'Merke dir dieses Profil.',
    PROFILE_UNSTAR_TEXT: 'Lösche das Profil von der Merkliste.',
    PROFILE_UNSTAR_BTN_ARIA_LABEL: 'Lösche das Profil von {{userName}} von der Merkliste.',
    PROFILE_GET_TO_KNOW: 'Kennenlernen',

    TANDEM_SHOW_LINK: 'Link zum Tandemprofil',
    TANDEM_PROFILE_TITLE: 'Tandemprofil von {{ name1 }} {{ lastName1 }} & {{ name2 }} {{ lastName2 }}',

    TANDEM_INTERESTED_TITLE: 'Gemerkte Kollegen',
    STARED_DELETE_HEADER: 'Entfernen',
    STARED_DELETE_TEXT: 'Willst du {{ name }} aus der Liste der gemerkten Kollegen entfernen?',
    TANDEM_INTERESTED_NO_SUBTITLE: 'Du hast noch keine Kollegen in deiner Merkliste.',
    TANDEM_INTERESTED_NO_TEXT:
        'Wenn du potentiell spannende Kollegen entdeckt hast, kannst du dir diese ganz einfach über das Sternsymbol in der Profilansicht merken. Die anderen Mitglieder bekommen darüber keine Nachricht. Um Kontakt mit dem gemerkten Mitglied aufzunehmen, schreibe einfach eine Nachricht.',
    TANDEM_INTERESTED_RESULT_ARIA_LABEL: 'Zur Profilansicht von {{userName}}',

    TANDEM_PROFILE_SEARCH_JOB: 'suchen:',
    TANDEM_PROFILE_SEARCH_IN: 'in:',
    TANDEM_PROFILE_EDUCATION: 'Ausbildungen:',
    TANDEM_PROFILE_WORK: 'Berufserfahrungen:',
    TANDEM_PROFILE_PERFECT_MATCH: 'Unser perfekter Tandempartner...',
    TANDEM_PROFILE_COMPANY_PERFECT_MATCH: 'Unser perfekter Arbeitgeber hat...',
    TANDEM_PROFILE_ME: 'Wir sind...',
    TANDEM_PROFILE_COMPANY_JOB_QUAL: 'Wir schätzen...',
    TANDEM_PROFILE_INTERESSE: 'Wir interessieren uns für...',
    TANDEM_PROFILE_ZITAT: 'Diese Zitate gefielen uns besonders gut...',

    PROFILE_JOBSHARING_LANGUAGES: 'Sprachen',
    PROFILE_JOBSHARING_SKILLS: 'Kenntnisse',
    PROFILE_JOBSHARING_COMPLEMENTARY_SKILLS: 'Skills des Tandempartners',
    PROFILE_JOBSHARING_DESIRED_WORK_LOCATIONS: 'Arbeitsstandorte',
    PROFILE_JOBSHARING_LEADERSHIP: 'Führungsposition',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_INDIFFERENT: 'In Zukunft flexibel',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_NO: 'Künftig nein',
    PROFILE_JOBSHARING_LEADERSHIP_DESIRED_YES: 'Künftig ja',
    PROFILE_JOBSHARING_LEADERSHIP_NO: 'Aktuell nein',
    PROFILE_JOBSHARING_LEADERSHIP_YES: 'Aktuell ja',
    PROFILE_JOBSHARING_WORKING_HOURS: 'Gewünschte Wochenstunden',
    PROFILE_JOBSHARING_DESIRED_WORKING_HOURS: 'Eigene: {{ hours }}',
    PROFILE_JOBSHARING_PARTNER_WORKING_HOURS: 'Tandempartner: {{ hours }}',
    PROFILE_JOBSHARING_JOB_LIKED_ABOUT: 'An meinem Job mag ich',
    PROFILE_JOBSHARING_JOB_DESCRIPTION: 'Beschreibung meines Jobs',

    PROFILE_MENTORING_LOOKING_FOR: 'Ich suche',
    PROFILE_TAG_MENTORING_INTERESTED_INTERESTED_TAG: 'Mentor',
    PROFILE_TAG_MENTORING_INTERESTED_OFFERING_TAG: 'Mentee',
    PROFILE_MENTORING_OFFERING: 'Bereiche, die ich unterstütze',
    PROFILE_TAG_MENTORING_OFFERING_CAREER_TAG: 'Karriere',
    PROFILE_TAG_MENTORING_OFFERING_EXPERTISE_TAG: 'Fachliche Qualifikation',
    PROFILE_MENTORING_INTERESTED: 'Gewünschte Unterstützung',
    PROFILE_TAG_MENTORING_INTERESTED_CAREER_TAG: 'Karriere',
    PROFILE_TAG_MENTORING_INTERESTED_EXPERTISE_TAG: 'Fachliche Qualifikation',
    PROFILE_MENTORING_OFFERING_SKILLS: 'Meine Expertise',
    PROFILE_MENTORING_INTERESTED_SKILLS: 'Gesuchte Expertise',

    PROFILE_EXPERTISE_DURATION: 'Dauer der Unterstützung',
    PROFILE_TAG_EXPERTISE_DURATION_LONG_TERM: 'Langfristig',
    PROFILE_TAG_EXPERTISE_DURATION_SHORT_TERM: 'Kurzfristig',
    PROFILE_EXPERTISE_INTERESTED_SKILLS: 'Gesuchte Expertise',
    PROFILE_EXPERTISE_OFFERING_SKILLS: 'Meine Expertise',

    PROFILE_LOCATION_DESIRED_LOCATON: 'Interessante Arbeitsstandorte',

    PROFILE_INTERESTS_INTERESTED_SKILLS: 'Meine Interessen',

    PROFILE_REVERSE_MENTORING_OFFERING_SKILLS: 'Meine Expertise',
    PROFILE_REVERSE_MENTORING_INTERESTED_SKILLS: 'Gesuchte Unterstützung',

    PROFILE_SKILLS_INTERESTED_SKILLS: 'Themeninteresse',
    PROFILE_SKILLS_OFFERING_SKILLS: 'Meine Expertise',

    PROFILE_TRAINING_INTERESTED_SKILLS: 'Gewünschte Weiterbildungsmöglichkeiten',

    PROFILE_RETRAINING_INTERESTED_SKILLS: 'Umschulungen, die mich interessieren',

    PROFILE_EXPATS_OPTIONS: 'Ich suche',
    PROFILE_TAG_EXPATS_MOTIVATION_INTERESTED: 'Expats',
    PROFILE_TAG_EXPATS_MOTIVATION_OFFERING: 'Kolleg*innen, die sich für Auslandsaufenthalte interessieren',
    PROFILE_EXPATS_OFFERING_COUNTRIES: 'Meine Auslandserfahrung',
    PROFILE_EXPATS_INTERESTED_COUNTRIES: 'Interessante Länder',
    PROFILE_EXPATS_HOME_COUNTRIES: 'Heimatland',

    PROFILE_INTERNSHIP_INTERESTED_GROUP: 'Ich bin',
    PROFILE_TAG_INTERNSHIP_SUPPORTER: 'Unterstützer*in',
    PROFILE_TAG_INTERNSHIP_GROUP_GENERAL: 'Teil einer der drei Gruppen',
    PROFILE_TAG_INTERNSHIP_GROUP_TRAINEE: 'Trainee',
    PROFILE_TAG_INTERNSHIP_GROUP_APPRENTICE: 'Auzubildende*r',
    PROFILE_TAG_INTERNSHIP_GROUP_INTERN: 'Praktikant*in',
    PROFILE_INTERNSHIP_OFFERING_MOTIVATION: 'Meine Motivation',

    PROFILE_SABBATICAL_OPTION: 'Erfahrungen mit Sabbaticals',
    PROFILE_TAG_SABBATICAL_INTERESTED_TAG: 'Ich möchte mehr über Sabbaticals erfahren',
    PROFILE_TAG_SABBATICAL_OFFERING_TAG: 'Ja',
    PROFILE_SABBATICAL_OFFERING_DESCRIPTION: 'Beschreibung meines Sabbaticals',
    PROFILE_SABBATICAL_INTERESTED_DESCRIPTION: 'Gründe für mein zukünftiges Sabbatical',
    PROFILE_SABBATICAL_INTERESTED_CAUSE_EMPLOYER: 'Gründe für den Arbeitgeber',

    PROFILE_PARENTAL_LEAVE_OPTION: 'Erfahrung mit Elternzeit',
    PROFILE_TAG_PARENTAL_LEAVE_OFFERING_TAG: 'Ja',
    PROFILE_TAG_PARENTAL_LEAVE_INTERESTED_TAG: 'Ich möchte mehr über Elternzeit erfahren',

    PROFILE_PEER_LEARNING_INTERESTED_SKILLS: 'Kenntnisse, die ich erlernen möchte',
    PROFILE_PEER_LEARNING_OFFERING_SKILLS: 'Meine Expertise',
    PROFILE_PEER_LEARNING_LOOKING_FOR: 'Optimales Lernformat',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_0: 'Arbeitsalltag',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_1: 'Coaching',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_2: 'Events',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_3: 'Mentoring',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_4: 'Projekte',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_5: 'Working circles',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_6: 'Workshops',
    PROFILE_PEER_LEARNING_MIXED_LEARNING_METHOD_7: 'Andere Weiterbildungsangebote',

    PROFILE_ONBOARDING_OPTION: 'Ich suche',
    PROFILE_TAG_ONBOARDING_INTERESTED_TAG: 'Kolleg*innen, die mich beim Start im Unternehmen unterstützen',
    PROFILE_TAG_ONBOARDING_OFFERING_TAG: 'Neue Kolleg*innen, die ich beim Start im Unternehmen unterstützen kann',

    PROFILE_BIRTH_YEAR: 'Geburtsjahr: ',
    PROFILE_AGE_RANGE: 'Altersgruppe: ',
    PROFILE_SEARCH_TYPE: 'sucht:',
    PROFILE_SEARCH_JOB: 'Kenntnisse:',
    PROFILE_SEARCH_EXPERT: ' Experte in:',
    PROFILE_SEARCH_JOB_SUCHT: 'sucht',
    PROFILE_WORK_HOURS: 'Arbeitsstunden:',
    PROFILE_SEARCH_IN: 'Standorte:',
    PROFILE_BEREICH: 'Bereich/Abteilung:',
    PROFILE_SUBSIDIARY: 'Tochterunternehmen:',
    HOURS_PER_WEEK: 'Stunden pro Woche',
    PROFILE_EXP_YEARS_LABEL: 'Berufserfahrung insgesamt (Jahre)',
    PROFILE_EXP_YEARS: 'Jahr/en',
    PROFILE_WORK_LABEL: 'Berufserfahrung',
    PROFILE_EXPERIENCE_LABEL: 'Berufserfahrung',
    PROFILE_EXPERIENCE_ARIA_LABEL: 'Berufserfahrung bearbeiten',
    PROFILE_HIGHEST_DEGREE_LABEL: 'Höchste Abschlüsse',
    PROFILE_GENERAL_SKILLS_LABEL: 'Kenntnisse',
    PROFILE_WORK: 'Gesamtdauer deiner Berufserfahrung',
    PROFILE_CAREER_STEPS: 'Beruflicher Hintergrund',
    PROFILE_PERFECT_MATCH: 'Gutes Teamwork ist für mich:',
    PROFILE_COMPANY_PERFECT_MATCH: 'Mein perfekter Arbeitgeber hat:',
    PROFILE_ME: 'Ich bin:',
    PROFILE_COMPANY_JOB_QUAL: 'Ich schätze:',
    PROFILE_INTERESSE: 'Ich interessiere mich für:',
    PROFILE_MENTORING: 'Mentoring ist für mich:',
    PROFILE_ZITAT: 'Dieses Zitat gefiel mir besonders gut:',
    PROFILE_PFLICHTFELD: '* Pflichtfeld',
    PROFILE_LANGUAGES: 'Sprachen: ',
    PROFILE_JOB_HEADER: 'Angebote',
    PROFILE_CREATE_OFFER: 'Angebot erstellen',
    PROFILE_AVATAR_TOOLTIP: 'Bild hochladen oder wählen:',
    PROFILE_AVATAR_CHOOSE: 'Übernehmen',
    PROFILE_AVATAR_CHOOSE_OWN: 'Eigenes Foto wählen',
    PROFILE_AVATAR_RECHOOSE_OWN: 'Ersetzen',
    PROFILE_AVATAR_TAKE: 'Ok',
    PROFILE_IMAGE_UPLOADER_TEXT: 'Avatar auswählen oder Foto hochladen',
    PROFILE_QUESTIONNAIRE: 'Mehr über mich',
    PROFILE_QUESTIONNAIRE_ADD_LABEL: 'Gehe zum Fragebogen, um mehr von dir zu erzählen',
    PROFILE_EDIT_QUESTIONNAIRE_LABEL: 'Fragebogen bearbeiten',
    PROFILE_STARED: '{{ name }} wurde zu "Gemerkte Kollegen" hinzugefügt.',
    PROFILE_UNSTARED: '{{ name }} wurde aus "Gemerkte Kollegen" entfernt.',
    PROFILE_STARED_MESSAGE: ' Tritt in Kontakt.',
    LATER: 'Später',
    ATTACH: 'Bild hochladen',
    PROFILE_ABOUT_ME: 'Über mich',
    PROFILE_KNOWLEDGE: 'Kenntnisse',
    PROFILE_LOOKING_FOR: 'Meine Interessen',
    PROFILE_UPDATE_TOPIC: 'Bearbeiten',
    PROFILE_DELETE_TOPIC: 'Deaktivieren',
    PROFILE_EXPAND_TOPIC: 'Öffnen',
    PROFILE_PLACEHOLDER_DESCRIPTION: 'Bisher noch keine Angaben:',
    PROFILE_PLACEHOLDER_VALUE: 'Klicke hier, um diesen Abschnitt zu bearbeiten.',
    PROFILE_VIEW_INTERESTS_NETWORKING: 'Vernetzung',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_FIELDS_AND_EXPERTISE: 'Fachbezogen',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PERSONAL_INTEREST: 'Interessenbasiert',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SPONTANEOUS: 'Spontan',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SPECIFIC_LOCATION: 'Standortbezogen',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_TOPICS_AND_GROUPS: 'Nach Themen und Zielgruppen',

    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_REVERSE_MENTORING: 'Reverse Mentoring',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PEER_LEARNING: 'Peer Learning',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_ONBOARDING: 'Onboarding',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_EXPERTS: 'Experten',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_ADVANCED_TRAINING: 'Weiterbildung',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_RETRAINING: 'Umschulung',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_EXPATS: 'Expats',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_TRAINEE: 'Trainees',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_INTERN: 'Praktikant*innen',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_APPRENTICE: 'Lehrlinge',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_SABBATICAL: 'Sabbatical',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_CAREER_AND_FAMILY_PARENTAL_LEAVE: 'Vereinbarkeit Familie und Beruf',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_INDIVIDUAL: 'Individuell',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_MENTORING: 'Mentoring',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_COACHING: 'Zukunftscoaching',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_PROJECT: 'Projekt',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_JOBSHARING: 'Jobrotation',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_JOBROTATION: 'Jobsharing',
    PROFILE_VIEW_INTERESTS_NETWORKING_DETAIL_NEVER_LUNCH_ALONE: 'Never Lunch Alone',

    PROFILE_NETWORKING_SKILLS_INTEREST_TITLE: 'Fachspezifischer Austausch',
    PROFILE_NETWORKING_INTERESTS_TITLE: 'Persönliche Interessen',
    PROFILE_NETWORKING_LOACTION_TITLE: 'Andere Firmenstandorte',
    PROFILE_NETWORKING_REVERSE_MENTORING_TITLE: 'Reverse Mentoring',
    PROFILE_NETWORKING_PEER_LEARNING_TITLE: 'Peer Learning',
    PROFILE_NETWORKING_ONBOARDING_TITLE: 'Ankommen und Einleben',
    PROFILE_NETWORKING_EXPERTISE_TITLE: 'Expert*innen finden',
    PROFILE_NETWORKING_EXPERTISE_TEXT: 'Suche Experten für',
    PROFILE_NETWORKING_TRAINING_TITLE: 'Weiterbildung',
    PROFILE_NETWORKING_RETRAINING_TITLE: 'Umschulung',
    PROFILE_NETWORKING_EXPATS_TITLE: 'Expats und Auslandserfahrung',
    PROFILE_NETWORKING_INTERNSHIP_TITLE: 'Praktikant*innen, Auszubildende, Trainees',
    PROFILE_NETWORKING_SABBATICAL_TITLE: 'Sabbatical',
    PROFILE_NETWORKING_PARENTAL_LEAVE_TITLE: 'Elternzeit',
    PROFILE_NETWORKING_MENTORING_TITLE: 'Mentoring',

    PROFILE_DELETE_TOPIC_TITLE: '{{topic}} deaktivieren',
    PROFILE_DELETE_TOPIC_TEXT:
        'Bist du dir sicher, dass du dieses Interesse und das dazugehörige Matching deaktivieren möchtest?',
    PROFILE_DELETE_TOPIC_DEACTIVATE: 'Interesse deaktivieren',
    PROFILE_DELETE_TOPIC_TOOLTIP:
        'Es muss mindestens ein Interesse ausgewählt sein. Du kannst zuerst andere Interessen hinzufügen, um dieses zu deaktivieren. ',

    PROFILE_OFFERING: 'Biete',
    NO_SUB_TAGS: 'Allgemein:',

    IMAGE_PICKER_DIALOG_TITLE: 'Bild hochladen',
    IMAGE_PICKER_DIALOG_BUTTON_TEXT: 'Übernehmen',
    IMAGE_PICKER_DIALOG_CHOOSE: 'Bild auswählen',
    IMAGE_PICKER_IMAGE_TOO_LARGE: 'Die Größe des Bildes darf 5MB nicht überschreiten',
    IMAGE_PICKER_IMAGE_WRONG_FILETYPE: 'Es sind nur Bilddateien (.jpg oder .png) erlaubt.',

    NEW_AVATAR_HEADER: 'Neuer Look!',
    NEW_AVATAR_TEXT:
        'Die Auswahl der Profilbilder wurde erneuert! Da in deinem Profil ein altes in Verwendung war, wurde dir ein zufälliges neues zugewiesen.',
    NEW_AVATAR_TEXT_2:
        'Wähle jetzt ein anderes aus oder – besser noch! – lade ein eigenes Bild hoch und komme noch schneller in Kontakt mit Kollegen!',
    NEW_AVATAR_BUTTON: 'Profil ansehen',

    INFO_ABOUT_OFFERED_JOB: 'Angaben zu deiner Stelle',
    PROFILE_JOB_TITLE: 'In welchem Fachbereich / in welcher Abteilung arbeitest du?',
    PROFILE_JOB_HOURS: 'Wie viele Wochenstunden umfasst deine Stelle aktuell?',
    PROFILE_JOB_DESCRIPTION: 'Bitte beschreibe deine Stelle / Position:',
    PROFILE_JOB_PARTNER:
        'Bitte schreibe ein paar Zeilen zu deiner Person / deinen Vorstellungen eines passenden Tandempartners:',

    UPDATE_ACCOUNT_HEADER: 'Benutzereinstellungen',
    UPDATE_ACCOUNT_CHANGE_PASSWORD: 'Passwort ändern',
    UPDATE_ACCOUNT_CHANGE_EMAIL: 'E-Mail ändern',
    UPDATE_ACCOUNT_DELETE_ACCOUNT: 'Benutzerkonto löschen',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_COLLAPSED_ARIA_LABEL: 'Einstellungen zur Löschung des Accounts öffnen',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_EXPANDED_ARIA_LABEL: 'Einstellungen zur Löschung des Accounts schließen',
    UPDATE_ACCOUNT_DELETE_ACCOUNT_BTN_ARIA_LABEL: 'Meinen Account löschen',
    UPDATE_ACCOUNT_CHANGE_NICKNAME: 'Benutzername ändern',
    PASSWORD_RESET_SUCCESS: 'Du hast dein Passwort erfolgreich geändert.',
    MAIL_RESET_SUCCESS: 'Du hast deine E-Mail-Adresse erfolgreich geändert.',
    NICKNAME_RESET_SUCCESS: 'Du hast deinen Benutzernamen erfolgreich geändert.',
    NICKNAME_RESET_ERROR: 'Dein neuer Benutzername wird bereits verwendet.',
    ACCOUNT_DELETE_HEADER: 'Benutzerkonto löschen?',
    ACCOUNT_DELETE_TEXT:
        'Möchtest du wirklich deinen Account einschließlich aller Profildaten löschen? \n Wenn du eine zeitlang inaktiv bist und keine Matches benötigst, kannst du unnötige Mails ganz einfach unter „E-Mail-Benachrichtigungen" hier in den Benutzereinstellungen abbestellen.',
    ACCOUNT_DELETE_TEXT_NOTE:
        'Hinweis: Mit der Löschung eines Accounts werden alle personenbezogenen Daten automatisch und vollständig im Tandemploy-System gelöscht. Eine gesonderte Aufforderung oder Bestätigung hinsichtlich der Datenlöschung ist nicht erforderlich.',
    ACCOUNT_DELETE_CANCEL_BTN_ARIA_LABEL: 'Vorgang abbrechen',
    ACCOUNT_DELETE_DELETE_BTN_ARIA_LABEL: 'Meinen Account löschen',
    DELETE_SUCCESS_MESSAGE:
        'Danke, dass du uns deine Zeit und dein Vertrauen geschenkt hast. Wir wünschen dir alles Gute und freuen uns jederzeit über eine erneute Anmeldung!',

    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS: 'E-Mail-Benachrichtigungen',
    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS_HEADER: 'Ich möchte E-Mail-Benachrichtigungen erhalten mit Hinweisen auf',

    UPDATE_ACCOUNT_CHANGE_EMAIL_SUBSCRIPTIONS_TEXT:
        'Folgende E-Mail-Benachrichtigungen möchte ich von {{ appName }} erhalten',
    PROFILE_NOTIFICATIONS_PROFILES: 'Hinweise auf neu angemeldete potentielle Tandempartner',
    PROFILE_NOTIFICATIONS_JOBS: 'Hinweise auf neue passende Jobs',
    PROFILE_PLATFORM_UPDATES: 'Updates zu neuen Funktionen & Features',

    UPDATE_ACCOUNT_EMAIL_SUBSCRIPTIONS: 'E-Mail-Benachrichtigungen',
    NOTIFICATION_MATCHED_OFFERS_LABEL: 'passende Angebote',
    NOTIFICATION_STAFFING_EMAILS_LABEL: 'Staffing Emails',
    NOTIFICATION_SUBSCRIPTION_PROFILES_LABEL: 'passende Kollegen',
    NOTIFICATION_SUBSCRIPTION_PROFILES_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: E-Mail-Benachrichtigung über passende Kolleginnen und Kollegen erhalten',
    NOTIFICATION_SUBSCRIPTION_PROFILES_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: E-Mail-Benachrichtigung über passende Kolleginnen und Kollegen erhalten',
    NOTIFICATION_SUBSCRIPTION_JOBS_LABEL: 'passende Jobs & Projekte',
    NOTIFICATION_SUBSCRIPTION_BLOG_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: E-Mail-Benachrichtigung über neue Artikel erhalten',
    NOTIFICATION_SUBSCRIPTION_BLOG_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: E-Mail-Benachrichtigung über neue Artikel erhalten',
    NOTIFICATION_SUBSCRIPTION_SURVEY_LABEL: 'Kollegen-Echo',
    NOTIFICATION_SUBSCRIPTION_MESSAGES_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: E-Mail-Benachrichtigung über neue persönliche Nachrichten erhalten',
    NOTIFICATION_SUBSCRIPTION_MESSAGES_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: E-Mail-Benachrichtigung über neue persönliche Nachrichten erhalten',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_LABEL: 'Tandem-Anfragen und -Bestätigungen',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: E-Mail-Benachrichtigung bei Tandem-Anfragen und -Bestätigungen erhalten',
    NOTIFICATION_SUBSCRIPTION_TANDEMS_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: E-Mail-Benachrichtigung bei Tandem-Anfragen und -Bestätigungen erhalten',
    NOTIFICATION_SUBSCRIPTION_DIRECT_MESSAGES_LABEL: 'persönliche Nachrichten',

    UPDATE_ACCOUNT_ANONYMITY: 'Anonymität',
    UPDATE_ACCOUNT_ANONYMITY_COLLAPSED_ARIA_LABEL: 'Einstellungen zur Anonymität öffnen',
    UPDATE_ACCOUNT_ANONYMITY_EXPANDED_ARIA_LABEL: 'Einstellungen zur Anonymität schließen',
    UPDATE_ACCOUNT_CHANGE_ANONYMITIES_HEADER: 'Diese Profildetails sind für andere User sichtbar:',
    ANONYMITY_BIRTHDATE_LABEL: 'Geburtsjahr',
    ANONYMITY_BIRTHDATE_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: Dein Geburtsjahr ist für andere Kolleginnen und Kollegen sichtbar',
    ANONYMITY_BIRTHDATE_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: Dein Geburtsjahr ist für andere Kolleginnen und Kollegen sichtbar',
    ANONYMITY_EXPERIENCE_LABEL: 'Berufserfahrung (deine Karrierestationen)',
    ANONYMITY_EXPERIENCE_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: Deine Berufserfahrung (Karrierestationen) ist für andere Kolleginnen und Kollegen sichtbar',
    ANONYMITY_EXPERIENCE_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: Deine Berufserfahrung (Karrierestationen) ist für andere Kolleginnen und Kollegen sichtbar',
    ANONYMITY_ALL_LABEL: 'Alle Informationen aus dem Fragebogen zur Arbeitsweise',
    ANONYMITY_QUESTIONNAIRE_CHECKED_ARIA_LABEL:
        'Checkbox aktiv: Antworten des persönlichen Fragebogens sind für andere Kolleginnen und Kollegen sichtbar',
    ANONYMITY_QUESTIONNAIRE_UNCHECKED_ARIA_LABEL:
        'Checkbox inaktiv: Antworten des persönlichen Fragebogens sind für andere Kolleginnen und Kollegen sichtbar',

    CREATE_PROFILE_INFO_TEXT:
        "Schön, dass du dabei bist! Nach einigen kurzen Angaben zu deiner Person und deinem Werdegang geht's auch schon weiter zum Matching-Fragebogen!",
    CREATE_PROFILE_INFO_TEXT_UNFINSHED:
        'Bitte vervollständige dein Profil, indem du alle *Pflichtfelder auf dieser Seite ausfüllst. Im Anschluss kannst du die Plattform im vollem Umfang nutzen.',
    PROFILE_PICTURE: 'Profilbild',
    PROFILE_PICTURE_WHY:
        'Die Erfahrung zeigt, dass Mitglieder mit Bildern wesentlich mehr Anfragen erhalten und so schneller fündig werden. Hier kannst du Bilder hochladen.',
    GENDER: 'Geschlecht',
    MALE: 'männlich',
    FEMALE: 'weiblich',
    OTHER: 'andere',
    NOT_SPECIFIED: 'keine Angabe',
    BIRTH_YEAR: 'Year of birth',
    BIRTH_YEAR_LABEL: 'Your year of birth',
    BIRTH_YEAR_PLACEHOLDER: 'Please select',
    AGE_RANGE: 'Altersgruppe',
    PROFILE_CITY: 'Standort',
    PROFILE_CITY_PLACEHOLDER: 'Standort',

    PROFILE_CREATE_JOBSHARING_QUESTIONAIRE: 'Speichern und zum Matching-Fragebogen',
    PROFILE_CREATE_PROJECT_CREATE: 'Speichern und Projekt inserieren',
    PROFILE_CREATE_JOBSHARING_QUESTIONAIRE_NOT_NOW: 'Speichern und später beantworten',
    PROFILE_CREATE_JOBSHARING_WITHOUT_QUESTIONNAIRE: 'Speichern',

    PROFILE_EDITING_FINAL_CONFIRM_STEP_TITLE: 'Änderungen bestätigen',
    PROFILE_EDITING_FINAL_CONFIRM_STEP_CONTENT_TITLE: 'Klicke auf »Übernehmen«, um die Änderungen zu speichern.',
    PROFILE_EDITING_FINAL_CONFIRM_STEP_SAVE_BUTTON_LABEL: 'Übernehmen',

    PROFILE_UPDATE_NOTIFICATION_MSG: 'Neue Informationen wurden deinem Profil hinzugefügt',

    CREATE_JOB_JOB_TYPE_BUTTON: 'Weiter',
    CREATE_JOB_JOB_TYPE_TITLE_SINGLE_JOBSHARER:
        'Ich möchte meinen eigenen Job ausschreiben und einen Tandempartner hierfür finden',
    CREATE_JOB_JOB_TYPE_SUB_SINGLE_JOBSHARER:
        "Hier kannst du deinen eigenen Job ausschreiben, wenn du diesen gerne zukünftig mit einem Tandempartner teilen würdest. Im Jobsharing teilen sich klassischerweise zwei Personen einen Job und organisieren die Aufgabenverteilung eigenverantwortlich miteinander. So werden selbst komplexe Jobs für den Einzelnen flexibel und teilzeittauglich - cleveres Teamwork macht's möglich!",
    CREATE_JOB_JOB_TYPE_TITLE_TANDEM: 'Ich möchte einen Job ausschreiben, der von einem Tandem besetzt werden kann.',
    CREATE_JOB_JOB_TYPE_SUB_TANDEM:
        'Hier kannst du einen Job, klassischerweise einen Vollzeitjob oder einen noch umfangreicheren Aufgabenbereich, für ein Tandem ausschreiben. Ein Jobsharing-Tandem bringt in den allermeisten Fällen mehr Qualifikationen und Fähigkeiten mit als eine Einzelperson und organisiert seine Arbeitsbeziehung selbstständig und eigenverantwortlich. Somit bekommst du gleich doppelte Power und Kompetenz für die zu besetzende Position! Wird nur ein halbes Tandem, also ein "Pendant" für einen Mitarbeiter gesucht, kann Letzterer dies selber über sein Profil ausschreiben.',
    CREATE_JOB_JOB_TYPE_TITLE_JOBROTATION_INTERESTED:
        'Ich möchte meinen Job für eine gewisse Zeit tauschen. (gegenseitiger Jobtausch)',
    CREATE_JOB_JOB_TYPE_SUB_JOBROTATION_INTERESTED:
        'Hier kannst du deinen eigenen Job zum Tausch ausschreiben und gleichzeitig Angaben zum gewünschten Aufgabengebiet machen, in welches du temporär wechseln möchtest. Eine Jobrotation meint in diesem Fall einen beidseitigen Tausch von Aufgaben und unter Umständen auch Standorten und/oder Abteilungen.',
    CREATE_JOB_JOB_TYPE_TITLE_JOBROTATION_OFFERING: 'Ich möchte eine freie Position für eine Jobrotation ausschreiben.',
    CREATE_JOB_JOB_TYPE_SUB_JOBROTATION_OFFERING:
        'Hier hast du die Möglichkeit, eine Jobrotation in einer Abteilung auszuschreiben, in der zeitweise die Expertise und Erfahrung eines Kollegen aus einer anderen Abteilung erwünscht ist. Jobrotation meint in diesem Fall keinen Tausch, sondern das einseitige Angebot, phasenweise in einen bestimmten Bereich zu wechseln. Ist ein beidseitiger Tausch mit einem Mitarbeiter gewünscht, kann dieser die Jobrotation über sein eigenes Profil ausschreiben.',
    CREATE_JOB_JOB_TYPE_TITLE_PROJECT_LEAD_SELF: 'Ich möchte als Projektverantwortlicher ein Projekt ausschreiben.',
    CREATE_JOB_JOB_TYPE_SUB_PROJECT_LEAD_SELF:
        'Hier kannst du ein Projekt ausschreiben, für das du noch Mitstreiter - zum Beispiel aus unterschiedlichen Bereichen oder Fachgebieten - suchst. Ein Projekt meint ein umfangreicheres Vorhaben, an dem du gemeinsam mit Kollegen über einen bestimmten Zeitraum arbeiten möchtest.',
    CREATE_JOB_JOB_TYPE_TITLE_PROJECT_LEAD_OTHER:
        'Ich möchte ein Projekt ausschreiben und einen Projektverantwortlichen festlegen.',
    CREATE_JOB_JOB_TYPE_SUB_PROJECT_LEAD_OTHER:
        'Hier kannst du ein Projekt ausschreiben, für welches du einen Projektverantwortlichen aus dem Userpool festlegst. Ist der Projektverantwortliche noch nicht auf der Plattform registriert, bitte ihn/sie, vorab einen Account anzulegen.',

    CHOOSE_MAX_3: '(Bitte max. 3 auswählen)',
    CHOOSE_MAX_3_TEXT:
        'Hier sind nur maximal {{ maxAnswers }} Antworten möglich. Um bereits ausgewählte Antworten zu deaktivieren, klicke diese bitte erneut an.',

    STATS_HEADER: '{{ appName }} Statistiken',
    STATS_CURRENT_LABEL: 'Aktuell: ',
    STATS_CURRENT_USERS: 'Nutzer',
    STATS_CURRENT_TANDEMS: 'Tandems',

    STATS_GENDER_LABEL: 'Geschlecht',
    STATS_AGE_LABEL: 'Altersverteilung',
    STATS_AREAS_LABEL: 'Fachlicher Hintergrund',
    STATS_MOTIVES_LABEL: 'Motive zur Arbeitszeitreduktion',
    STATS_MODEL_INTERESTED_IN_LABEL: 'Modelle',
    STATS_WORKING_WEEK_HOURS_LABEL: 'Gewünschte Wochenstunden',
    STATS_LOCATION_LABEL: 'Standorte der Nutzer*innen',
    STATS_JOBSHARING_LABEL: 'Suchende vs. bietende Jobsharer*innen',

    WITH_JOBSHARING_JOBS: 'Wollen ihren aktuellen Arbeitsbereich teilen',
    WITHOUT_JOBSHARING_JOBS: 'Suchen neuen Arbeitsbereich zum Teilen',

    ADMIN_ADMINS_HEADER: 'Nutzer mit Administrationsrechten',
    ADMIN_ADMINS_ABBREVIATION: 'Berechtigungen:',
    ADMIN_ADMINS_ADD: 'Administrator hinzufügen',
    ADMIN_ADMINS_NAME: 'Name',
    ADMIN_ADMINS_MAIL: 'E-Mail-Adresse',
    ADMIN_ADMINS_RIGHTS: 'Berechtigungen',
    ADMIN_ADMINS_ADD_MODAL_HEADER: 'Administratoren hinzufügen',
    ADMIN_ADMINS_ADD_MODAL_ADD_MORE: 'Weiteren Administrator hinzufügen',
    ADMIN_ADMINS_EDIT_MODAL_HEADER: 'Administrator bearbeiten',
    ADMIN_ADMINS_EDIT_MODAL_INPUT_HEADER: 'E-Mail des Administrators',
    ADMIN_ADMINS_DEMO_TEXT:
        'Lieber Besucher, der Admin-Bereich Rechteverwaltung ist in der Demo Version nicht zugänglich. Auf diesem Screenshot kannst du einen Eindruck gewinnen, wie die Oberfläche aussieht.',
    ADMIN_ADMINS_EDIT: 'Berechtigungen bearbeiten',
    ADMIN_ADMINS_DELETE: 'Administrator löschen',
    USERS_DO_NOT_EXIST: 'User mit den E-Mail-Addressen {{ emails }} existieren nicht.',
    USER_DOES_NOT_EXIST: 'User mit der E-Mail-Addresse {{ email }} existiert nicht.',
    USERS_ALREADY_ADMINS:
        'User mit den E-Mail-Adressen {{ emails }} haben bereits Administrationsrechte. Bitte bearbeite die Rechte ggf. direkt in der Liste.',
    USER_ALREADY_ADMIN:
        'User mit der E-Mail-Adresse {{ email }} hat bereits Administrationsrechte. Bitte bearbeite die Rechte ggf. direkt in der Liste.',
    ROLE_REQUIRED: 'Es ist eine Berechtigung erforderlich',

    JOBS_ADMIN: 'Angebote',
    JOBS_ADMIN_ABBREV: 'A',
    JOBS_ADMIN_ABBREVIATION: 'A = Angebote',
    NEWS_ADMIN: 'Dashboard-News',
    NEWS_ADMIN_ABBREV: 'N',
    NEWS_ADMIN_ABBREVIATION: 'N = News',
    MAILING_ADMIN: 'Mailing',
    MAILING_ADMIN_ABBREV: 'M',
    MAILING_ADMIN_ABBREVIATION: 'M = Mailing',
    RIGHTS_ADMIN: 'Rechteverwaltung',
    RIGHTS_ADMIN_ABBREV: 'R',
    RIGHTS_ADMIN_ABBREVIATION: 'R = Rechteverwaltung',
    STATS_ADMIN: 'Statistiken',
    STATS_ADMIN_ABBREV: 'S',
    STATS_ADMIN_ABBREVIATION: 'S = Statistiken',
    EXTERNAL_USERS_ADMIN: 'Externe User',
    EXTERNAL_USERS_ADMIN_ABBREV: 'E',
    EXTERNAL_USERS_ADMIN_ABBREVIATION: 'E = Externe User',

    REMOVE_ADMIN_RIGHTS_ERROR: 'Die Adminrechte von {{ user }} konnten nicht entfernt werden.',
    REMOVE_ADMIN_RIGHTS_SUCCESS: 'Die Adminrechte von {{ user }} wurden erfolgreich entfernt.',
    UPDATE_ADMIN_RIGHTS_SUCCESS: 'Die Adminrechte von {{ user }} wurden erfolgreich upgedated.',
    CREATE_ADMIN_RIGHTS_SUCCESS: 'Dem {{ user }} wurden erfolgreich Adminrechte zugewiesen.',

    EXTERNAL_USERS: 'Externe User',
    ADMIN_EXTERNAL_USERS_HEADER: 'Externe User',
    ADMIN_EXTERNAL_USERS_INFO:
        'User, die mit einer externen E-Mail-Adresse Zugriff auf die Benutzeroberfläche erhalten sollen.',
    ADMIN_SEARCH_PLACEHOLDER: 'Einträge durchsuchen',
    ADMIN_EXTERNAL_USERS_EMAIL: 'Mailadresse',
    ADMIN_EXTERNAL_USERS_CREATED_AT: 'Erstellt',
    ADMIN_EXTERNAL_EXPIRED: 'Einladung aktiv',
    ADMIN_NEW_EXTERNAL_USER: 'User hinzufügen',
    ADMIN_EXTERNAL_USERS_RESEND: 'Einladung erneut versenden',
    ADMIN_EXTERNAL_USERS_DELETE: 'Externen User löschen',

    ADMIN_EXTERNAL_USER_MODAL_HEADER: 'Externen User einladen',
    ADMIN_EXTERNAL_ADD_MODAL_ADD_MORE_INFO:
        'Mehrere E-Mail-Adressen bitte jeweils durch ein Komma trennen.<br/>Beispiel: "lea@lacht.de, mark@munter.de, ..."',

    ADMIN_EXTERNAL_USER_DELETE_HEADER: 'Externen User löschen',
    ADMIN_EXTERNAL_USER_DELETE_TEXT:
        'Willst du den User mit der E-Mail {{ email }} wirklich löschen? Mit dieser Aktion entfernst du alle Daten des Users!',
    ADMIN_EXTERNAL_USER_REFRESH_HEADER: 'Externen User erneut einladen',
    ADMIN_EXTERNAL_USER_REFRESH_TEXT: 'Willst du {{ email }} erneut einladen?',

    ADMIN_EXTERNAL_USER_ALREADY_USERS: 'User mit den E-Mail-Addressen {{ emails }} existieren bereits.<br/>',
    ADMIN_EXTERNAL_USER_ALREADY_USER: 'User mit der E-Mail-Addresse {{ email }} existiert bereits.<br/>',

    NO_ROLES:
        'Ups... Du bist nicht authorisiert diese Seite aufzurufen. Kontaktiere deinen Admin, um die benötigten Rechte zu erhalten.',

    DELETE_ADMIN_HEADER: 'Administratorenrechte löschen',
    DELETE_ADMIN_TEXT: 'Willst du wirklich alle Administratorenrechte von {{ name }} löschen?',
    DELETE_MESSAGE_HEADER: 'E-Mail löschen',
    DELETE_MESSAGE_TEXT_ADMIN_NEWS: 'Willst du wirklich die News von {{ creator }} löschen?',
    DELETE_MESSAGE_TEXT_ADMIN_EMAIL: 'Willst du wirklich die E-Mail von {{ creator }} löschen?',

    JOB_ACTION_EDIT: 'Bearbeiten',
    JOB_ACTION_PAUSE: 'Pausieren',
    JOB_ACTION_UNPAUSE: 'Veröffentlichen',
    JOB_ACTION_DELETE: 'Löschen',

    FAQ_TITLE: 'Fragen und Antworten',

    TRY_AGAIN:
        'Es ist ein technischer Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere uns unter <a href="{{href}}">{{linkText}}</a>.',
    LOGIN_ERROR: 'E-Mail oder Passwort falsch',
    SIGN_UP_LOGIN_BAD_CREDENTIALS: 'E-Mail-Adresse und/oder Passwort fehlerhaft. Bitte überprüfe deine Anmeldedaten.',
    LOGIN_ERROR_LONG:
        'Du bist noch nicht auf {{ appName }} registriert? Registriere dich jetzt, um ein Profil anzulegen und flexible Arbeitsmodelle zu finden.',
    LOGIN_ERROR_LONG_ACTION: 'Registriere dich jetzt',
    LOGIN_ERROR_SHORT_ACTION: 'Registrieren',
    PASSWORD_RESET_ERROR:
        'Leider ist der Link in deiner E-Mail nicht mehr gültig. Bitte setze dein Passwort erneut zurück. Falls das nicht funktionieren sollte, kontaktiere uns unter suppport@tandemploy.com.',
    TOKEN_EXPIRED_ERROR: 'Der Link in deiner E-Mail ist bereits abgelaufen. Bitte setze dein Passwort erneut zurück.',
    TOKEN_EXPIRED_ERROR_BTN: 'Passwort zurücksetzen',

    EMAIL_ALREADY_EXISTS: 'Ein Profil mit dieser E-Mail-Adresse existiert bereits.',
    NICKNAME_ALREADY_EXISTS: 'Bereits verwendet.',
    NICKNAME_INVALID: 'Ungültiger Name.',
    PASSWORD_NOT_STRONG_ENOUGH: 'Dein Passwort ist nicht sicher genug.',
    PASSWORD_REUSED:
        'Dieses Passwort hast du bereits zu einem früheren Zeitpunkt verwendet. Bitte wähle ein neues Passwort.',
    USERNAME_TOO_SHORT: 'Dein Benutzername muss mindestens 5 Zeichen lang sein.',
    USERNAME_REQUIRED: 'Es ist ein Benutzername erforderlich.',
    USERNAME_ALLREADY_USED: 'Der von dir eingegebene Benutzername ist bereits vergeben.',
    EMAIL_REQUIRED: 'Es ist eine E-Mail-Adresse erforderlich',
    EMAIL_INVALID: 'Bitte gib eine valide E-Mail-Adresse ein',
    EMAILS_INVALID: 'Folgende E-Mail-Adressen sind nicht gültig: {{ emails }}',
    EMAIL_NOT_SUPPORTED: 'Diese E-Mail-Adresse wird nicht unterstützt.',
    EMAIL_ALLREADY_USED: 'Die von dir eingegebene E-Mail-Adresse ist bereits vergeben.',
    NICKNAME_REQUIRED: 'Es ist ein Benutzername erforderlich',
    NICKNAME_MINLENGTH: 'Der Benutzername muss mindestens 4 Zeichen lang sein',
    NICKNAME_ALLREADY_USED: 'Der von dir eingegebene Benutzername ist bereits vergeben.',
    PASSWORD_REQUIRED: 'Es ist ein Passwort erforderlich',

    VALIDATION_REQUIRED: 'Dies ist ein Pflichtfeld. Bitte ausfüllen.',
    VALIDATION_INVALID: ' Bitte dieses Feld überprüfen',
    VALIDATION_AT_LEAST_ONE_REQUIRED: 'Mindestens eine Angabe nötig',

    PASSWORD_MINLENGTH: 'insgesamt mind. {{length}} Zeichen',
    PASSWORD_MINLENGTH_LONG: 'Das Passwort muss mindestens {{length}} Zeichen lang sein',
    PASSWORD_TWO_LETTERS: 'mind. zwei Zeichen, die keine Buchstaben sind',
    PASSWORD_NO_LICENCE_PLATE: 'keine KFZ-Kennzeichen',

    PASSWORD_REPEAT_REQUIRED: 'Die Passwörter stimmen leider noch nicht überein',
    PASSWORD_INVALID: 'Das Passwort muss mind. zwei Zeichen beinhalten, die keine Buchstaben sind.',
    SERVERERRORS: 'Fehler',
    NAMEINFO: 'Information',
    WARNINGINFO: 'Information',
    PASSWORD_NO_SINGLE_WORD: 'keinen einzelnen Wörter aus dem Wörterbuch',
    NICKNAME_ANONYM_INFO: 'Wenn du erstmal anonym bleiben möchten, wähle am besten einen Fantasienamen.',
    IMAGE_WRONG_FORMAT: 'Es können nur Bilddateien in den Formaten jpeg und png hochgeladen werden.',
    PROFILE_CREATE_WARNING:
        'Die clevere strategische Lösung für mehr Diversity, Work-Life-Balance, Vereinbarkeit und Empowerment.',
    SHOW_AGB_PRIVACY_FIRST: 'Ich stimme den ',
    SHOW_AGB_PRIVACY_SECOND: ' und ',
    SHOW_AGB_PRIVACY_THIRD: ' zu.',
    SHOW_AGB_LINK: 'Nutzungsbedingungen',
    SHOW_PRIVACY_LINK: 'Datenschutzhinweisen',
    SUBSCRIBE_NEWSLETTER: 'Newsletter abonnieren',

    ACCEPT_PRIVACY_HEADLINE: 'Teilnahmebedingungen',
    ACCEPT_PRIVACY_TEXT:
        'Bitte lies und akzeptiere die Teilnahmebedingungen, die du über den Link aufrufen können. Durch Aktivierung des Kontrollkästchens und Klick auf "Annehmen" stimmst du den Bedingungen zu und gelangst in dein Benutzerkonto.',

    AGB_TITLE: 'AGB',
    AGB_EFFECTIVE: 'Stand: 12/2015',
    AGB_LINK: 'documents/generic/Tandemploy_AGB.pdf',
    AGB_LINK_TEXT: 'Hier herunterladen',
    CONDITIONS_TITLE: 'Allgemeine Nutzungsbedingungen für Jobsharer',
    CONDITIONS_EFFECTIVE: 'Stand: 12/2015',
    PRIVACY_LINK_TEXT: 'Hier herunterladen',
    PRIVACY_DOWNLOAD_LINK: 'documents/generic/privacy_statement.pdf',
    PRIVACY_EFFECTIVE: 'Stand: 01/2016',
    PRIVACY_TITLE: 'Datenschutzerklärung',

    PLEASE_SIGNOUT_FIRST:
        'Du bist bereits registriert. Wenn du fälschlicherweise angemeldet bist, melde dich erst im Menü auf der rechten Seite ab.',

    WORKING_WEEK_ERROR_LONG: 'Du arbeitest eindeutig zu lange! Mehr als 48 Stunden sind gesetzlich verboten***-)',
    WORKING_EXP_ERROR_LONG: 'Du solltest dich in deinem Alter zur Ruhe setzen und deine Zeit genießen***-)',
    ERROR_POSITVE_NUMBER: 'Bitte gib eine positive Zahl ein.',

    UPLOADER_DUPLICATE: 'Du hast diese Datei bereits angehängt.',
    UPLOADER_WRONG_FORMAT: 'Das Dateiformat wird nicht unterstützt.',
    UPLOADER_MAX_REACHED: 'Wir konnten die Datei leider nicht hinzufügen. Bitte hänge maximal 10MB an.',
    JOB_APPLICATION_DUBLICATE: 'Du hast dich bereits auf den Job beworben.',
    JOB_APPLICATION_SUCCESSFUL: 'Vielen Dank für deine Bewerbung. Du erhältst in Kürze eine Bestätigungs-E-Mail.',
    ALLREADY_APPLIED:
        'Du hast dich für diesen Job bereits dreimal innerhalb kurzer Zeit beworben. Wenn du dich wirklich erneut bewerben möchtest, kontaktiere bitte den Support.',

    INTERNAL_ERROR:
        'Ups... hier ist wohl etwas schief gegangen. Versuche die Seite neu zu laden oder kontaktiere uns unter suppport@tandemploy.com',
    SERVER_NOT_RESPONDING:
        'Ups.... unser Server hat leider noch keinen Tandempartner, der einspringt, wenn er ausfällt. Wir arbeiten auf Hochtouren daran, dass alles wieder reibungslos läuft. Gib uns eine zweite Chance und schau später noch einmal vorbei oder kontaktiere uns unter suppport@tandemploy.com!',
    NO_AUTH: 'Ups... Du bist nicht authentifiziert. Bitte registriere dich oder melde dich an.',
    ERROR_COOKIES_DISABLED:
        'Um die Plattform im vollem Umfang nutzen zu können, müssen Cookies in deinem Browser aktiviert sein.',

    USER_DELETED: 'Dieser Benutzer wurde gelöscht. Du kannst daher keine Nachricht mehr hinterlassen.',

    JOBSHARER_NOTHING_FOUND: 'Leider gibt es noch (!) keine passenden Suchergebnisse.',

    ADD_TAG_TOO_LONG: 'Das eingegebene Schlagwort ist zu lang. Es sind max. 50 Zeichen erlaubt.',
    ADD_TAG_NOT_UNIQUE: 'Dieses Schlagwort hast du bereits hinzugefügt. Bitte gib jedes Schlagwort nur ein Mal ein.',

    FINISH_YOUR_PROFILE:
        'Dein Profil ist noch unvollständig. Bitte vervollständige dein Profil, um unsere Plattform optimal nutzen zu können.',
    FINISH_YOUR_PROFILE_ON_DESKTOP:
        'Dein Profil ist noch nicht ausgefüllt. Bitte nutze dafür einen Desktop-Rechner oder ein Tablet, da diese Funktion aktuell noch nicht auf kleineren Geräten verfügbar ist.',
    ANSWER_MATCHING_QUESTIONNAIRE:
        'Erzähle deinen Kollegen doch noch etwas mehr über dich, deine Arbeitsweise und was dir in der Zusammenarbeit mit Anderen wichtig ist. So erhöhst du die Wahrscheinlichkeit, passende Kollegen für deine Vorhaben zu finden. Alle Angaben sind natürlich freiwillig.',
    ANSWER_MATCHING_QUESTIONNAIRE_MORE:
        'Matching-Fragebogen abschließen, um noch leichter geeignete Kollegen zu finden.',
    PROFILE_FINISH_BUTTON: 'Zum Profil',

    TOO_MANY_MESSAGES:
        'Du hast in kurzer Zeit so viele Nachrichten geschrieben, dass wir uns fragen, ob du eine echte Person oder ein kleiner Bot bist. Bitte mache eine kurze Pause!',

    PROFILE_CREATE_SUCCESS:
        'Vielen Dank fürs Ausfüllen und die Zeit, die du dir genommen hast! Suche nun einen Tandempartner, der zu dir passt – und den perfekten Job zum Teilen!',
    PROFILE_UPDATE_SUCCESS: 'Dein Profil wurde erfolgreich gespeichert.',
    PROFILE_CREATE_CANCEL_HEADER: 'Willst du wirklich abbrechen?',
    PROFILE_CREATE_CANCEL_TEXT: 'Wenn du auf "Abbrechen" klickst, gehen alle Neuerungen verloren.',

    ON_MOBILE:
        'Diese Seite ist leider noch nicht für diese Displaygröße optimiert. Wenn du auf einem Tablet bist, drehe dieses in den Landscape Mode oder wechsele bitte an einen größeren Bildschirm. Wir arbeiten auf Hochtouren an einer mobilen Version unserer Plattform. Vielen Dank für dein Verständnis!',

    TOO_MANY_LETTERS: 'Es sind nur maximal 250 Zeichen möglich.',
    TOO_MANY_LETTERS_1000: 'Es sind nur maximal 1000 Zeichen möglich.',

    RESET_PASSWORD_SUCCESS:
        'Dein Passwort wurde erfolgreich zurückgesetzt. Du kannst dich jetzt mit dem neuen Passwort einloggen.',

    UNSUPPORTED_BROWSER:
        'Dein Browser ist möglicherweise nicht mehr auf dem neusten Stand oder wird nicht unterstützt. <a href="http://browsehappy.com/" target="_blank">Bitte update deinen Browser</a>. Wir unterstützen die neusten Versionen von Firefox, Chrome und Safari.',

    E_MAIL_VALIDATION:
        'Deine Registrierung ist fast abgeschlossen. Bitte bestätige deine Registrierung, indem du auf den Link in deiner E-Mail klickst.',
    PASSWORD_BLOCKED:
        'Erneuerung des Passworts nötig! Du warst einige Zeit nicht mehr auf {{ appName }} eingeloggt. Aus Sicherheitsgründen wurde dein Account daher vorerst deaktiviert. Einen Link zum Erneuern des Passworts wurde dir gerade per E-Mail zugeschickt. Damit kannst du dich sofort wieder einloggen.',
    BROWSER_BACK_CONFIRM:
        'Willst du diese Seite wirklich verlassen? Die eingegeben Daten sind noch nicht gespeichert und gehen verloren.',
    ACCOUNT_NOT_ACTIVE: 'Dieser Account wurde deaktiviert. Erstelle ein neues Passwort, um dich wieder anzumelden.',

    DASHBOARD_NEWS_TILE_TITLE: 'Nachrichten',
    DASHBOARD_NEWS_EDITOR_PLACEHOLDER: 'Nachricht eingeben...',
    DASHBOARD_NEWS_TILE_LINK: 'Alle ansehen',
    DASHBOARD_NEWS_TILE_ARIA_LABEL: 'Zu meinen Nachrichten',
    DASHBOARD_NEWS_TILE_NEWS_LIST_ITEM_ARIA_LABEL: 'Zur Nachricht von {{nicknames}}',
    DASHBOARD_NEWS_EDITOR_SEND_MESSAGE_ARIA_LABEL: 'Nachricht an {{nicknames}} senden',

    DASHBOARD_BLOG_TILE_TITLE: 'Artikel',
    DASHBOARD_BLOG_TILE_LINK: 'Alle Artikel',
    DASHBOARD_BLOG_TILE_ARIA_LABEL: 'Alle Artikel',
    DASHBOARD_BLOG_TILE_ARTICLE_LIST_PREV_BTN_ARIA_LABEL: 'Zur Vorschau des vorherigen Artikels',
    DASHBOARD_BLOG_TILE_ARTICLE_LIST_NEXT_BTN_ARIA_LABEL: 'Zur Vorschau des nächsten Artikels',
    DASHBOARD_BLOG_TILE_ARTICLE_ARIA_LABEL: 'Zum Artikel {{articleTitle}}',

    DASHBOARD_PROFILE_TILE_TITLE: 'Mein Profil',
    DASHBOARD_PROFILE_TILE_LINK: 'Zum Profil',
    DASHBOARD_PROFILE_TILE_ARIA_LABEL: 'Zu meinem Profil',

    DASHBOARD_JOB_TILE_TITLE: 'Angebote',
    JOB_OFFER_FEATURE_JOBSHARING: 'Jobsharings',
    JOB_OFFER_FEATURE_PROJECT: 'Projekte',
    JOB_OFFER_FEATURE_JOBROTATION: 'Jobrotations',
    JOB_OFFER_FEATURE_WORKING_CIRCLES: 'Working Circles',

    DASHBOARD_JOB_CREATION_TILE_TITLE: 'Angebote erstellen',
    DASHBOARD_JOB_CREATION_TILE_TEASER: 'Wer, wenn nicht du?',
    DASHBOARD_JOB_CREATION_TILE_TEASER_STRONG: 'Mit Kollegen Neues starten.',

    DASHBOARD_JOB_CREATION_LINK_JOBSHARING: 'Jobsharing',
    DASHBOARD_JOB_CREATION_LINK_JOBROTATION: 'Jobrotation',
    DASHBOARD_JOB_CREATION_LINK_PROJECTS: 'Projekte',
    DASHBOARD_JOB_CREATION_LINK_WORKING_CIRCLES: 'Working Circle',

    DASHBOARD_INVITATION_TILE_CONTENT: 'Hole weitere Kollegen an Bord!',
    DASHBOARD_INVITATION_TILE_HOVER_CONTENT: 'Denn diese Plattform wächst nur mit deiner Hilfe.',
    DASHBOARD_INVITATION_TILE_TITLE: 'Kollegen einladen',
    DASHBOARD_INVITATION_TILE_ARIA_LABEL: 'Zum Formular Kollegen einladen',

    DASHBOARD_SURVEY_TILE_TITLE: 'Kollegen-Echo',
    DASHBOARD_SURVEY_TILE_LINK: 'Alle anzeigen',

    DASHBOARD_COLLEAGUE_TILE_TITLE: 'Meine Kollegen',
    DASHBOARD_COLLEAGUE_TILE_ARIA_LABEL: 'favorisierte Matching Seite',

    DASHBOARD_FAQ_TILE_TITLE: 'Fragen und Antworten',
    DASHBOARD_FAQ_TILE_CONTENT: 'Fragen?',
    DASHBOARD_FAQ_TILE_HOVER_CONTENT: 'Hier finden Sie Antworten!',

    DASHBOARD_INFORMATION_TILE_TITLE: 'Informationen',
    DASHBOARD_INFORMATION_TILE_CONTENT: 'Informationen durchsuchen',

    SURVEY_TIME_REMAINING: 'Restlaufzeit',
    SURVEY_END_TIME: 'Ende der Frage',
    SURVEY_MULTIPLE_ANSWER: 'Es sind Mehrfachnennungen möglich.',
    SURVEY_SINGLE_ANSWER: 'Wähle eine Antwort aus.',
    SURVEY_SUBMIT_ANSWERS: 'Teilnehmen',
    SURVEY_RESULTS: 'Auswertung',
    SURVEY_RESULTS_THANKS: 'Vielen Dank für deine Stimme. Hier kommt das Ergebnis:',
    SURVEY_RESULTS_LABEL: 'Echo zur beantworteten Frage:',
    SURVEY_QUESTION_THANKS: 'Vielen Dank für die Teilnahme.',
    SURVEY_NEXT: 'Nächste Frage',
    SURVEY_TILE_TEASER: "Hier geht's zu den Fragen.",
    SURVEY_QUESTION_COMPLETE: 'Danke, du hast alle Fragen beantwortet',

    BLOG_MENU: 'Kategorien',
    BLOG_MODAL_TITLE: 'Artikel',
    BLOG_CLOSE_LABEL: 'Dashboard',
    BLOG_CLOSE_LABEL_LONG: 'zurück zum Dashboard',
    BLOG_CATEGORY_MENU_CLOSE: 'Blogmenü schließen',
    BLOG_CATEGORY_MENU_TITLE: 'Kategorien',
    BLOG_CATEGORY_ALL: 'Alle Artikel anzeigen',

    BLOG_CATEGORY_LABEL: 'Kategorie',
    BLOG_CATEGORY_SHOW_ALL_LABEL: 'Alle Artikel',
    BLOG_FILTER_LABEL: 'Filtern nach',
    BLOG_FILTER_ALL: 'Alle',
    BLOG_FILTER_UNREAD: 'Ungelesen',
    BLOG_FILTER_READ: 'Gelesen',
    BLOG_NAVIGATION_HEADING: 'Weitere Beiträge in',
    BLOG_ARTICLE_PREV_BTN_ARIA_LABEL: 'Zum letzten Artikel {{articleTitle}}',
    BLOG_ARTICLE_NEXT_BTN_ARIA_LABEL: 'Zum nächsten Artikel {{articleTitle}}',
    BLOG_ARTICLE_TITLE_ARIA_LIVE_MSG: '{{articleTitle}} ist geöffnet',

    NEWS_MESSAGE_FROM: 'Von',
    NEWS_MODAL_TITLE: 'Nachrichten',
    NEWS_CLOSE_LABEL: 'Dashboard',
    NEWS_BACK_TO_INDEX_LABEL: 'zurück',
    NEWS_NO_MESSAGE: 'Momentan keine neuen Nachrichten',
    NEWS_DELETED_USER_NICKNAME: 'Gelöschter Benutzer',

    INVITATION_FORM_TITLE: 'Einladen',
    INVITATION_FORM_INVITEE_NAME: 'Name des Empfängers',
    INVITATION_FORM_INVITEE_EMAIL: 'Mailadresse des Empfängers',
    INVITATION_FORM_INVITEE_EMAIL_DOMAIN_ERROR: 'Bitte gib eine valide E-Mail-Adresse ein.',
    INVITATION_FORM_INVITEE_EMAIL_ALREADY_SENT_ERROR: 'Du hast diesen Kollegen bereits eingeladen.',
    INVITATION_FORM_BODY_PLACEHOLDER:
        'Hallo [{{recipient_token}}]\n\nals ich mir gerade unser neues firmeninternes Tool {{platform_name}} angesehen habe, musste ich an dich denken. Vielleicht hast du Interesse, es einmal anzusehen. Die Anmeldung geht schnell und man kann {{platform_name}} sogar anonym nutzen.\n\nViele Grüße',
    INVITATION_FORM_BODY_RECIPIENT_TOKEN: 'Name des Empfängers',
    INVITATION_FORM_BUTTON_LEGEND: 'Senden',
    INVITATION_FORM_BUTTON_ARIA_LABEL: 'Einladung senden',
    INVITATION_FORM_BODY_LABEL: 'Hinterlasse eine persönliche Nachricht',
    INVITATION_FORM_BODY_INFO: 'Deine Mailadresse wird dem Empfänger nicht angezeigt.',
    INVITATION_FORM_MESSAGE_ARIA_LABEL: 'Schreibe eine persönliche Einladung.',
    INVITATION_SENT_FEEDBACK: 'Einladung wurde versendet',
    INVITATION_SENT_AGAIN_BUTTON: 'Weitere einladen',
    INVITATION_SENT_THANK_YOU_1: 'Danke,',
    INVITATION_SENT_THANK_YOU_TEXT_1:
        'dass du Kollegen von den neuen Möglichkeiten erzählst! Ohne dich und dein Netzwerk könnte dieses Tool nicht funktionieren.',
    INVITATION_SENT_THANK_YOU_2: 'Wow,',
    INVITATION_SENT_THANK_YOU_TEXT_2:
        'du bist wunderbar! Auf die sog. "First Mover" wie dich kommt es an! Wenn du uns ein Feedback hinterlassen willst oder Anregungen hast, erreichst du uns unter support@tandemploy.com.',
    INVITATION_SENT_THANK_YOU_3: 'Wirklich,',
    INVITATION_SENT_THANK_YOU_TEXT_3:
        'dein Engagement ist außergewöhnlich! Wie schnell wohl alle Kolleginnen und Kollegen angemeldet sein werden, wenn du so weiter machst? Klasse!',
    INVITATION_SENT_THANK_YOU_4: 'Nicht zu (s)toppen!',
    INVITATION_SENT_THANK_YOU_TEXT_4:
        'Schon deine {{counter}}. Einladung! Uns gehen die Nachrichten aus – und wir sind sprachlos. Toll, dass du mit diesem Tool die Arbeitswelt bewegst! Wir wünschen dir und den eingeladenen Kolleginnen/Kollegen viel Erfolg!',

    INVITATION_MODAL_TITLE: 'Kolleg*innen einladen',
    INVITATION_MODAL_CONTENT_TITLE:
        'Verschicke Einladungen an einzelne oder mehrere Kolleg*innen, damit sie von dieser Plattform erfahren.',
    INVITATION_MODAL_CONTENT_TEXT: 'Zugelassen sind nur E-Mail-Adressen des Unternehmens.',
    INVITATION_MODAL_RECIPIENTS: 'E-Mail-Adressen der Kolleg*innen',
    INVITATION_MODAL_INVALID_EMAIL_VALIDATION_MSG: 'Zugelassen sind nur E-Mail-Adressen des Unternehmens.',
    INVITATION_MODAL_SEND_BTN: 'Einladung senden',
    INVITATION_MODAL_SENT_FEEDBACK: 'Einladung wurde versendet',

    PROFILE_EDIT_LABEL: 'Zum Profil',
    PROFILE_MESSAGE_LT_40_TITLE: 'Nicht schlecht!',
    PROFILE_MESSAGE_LT_40:
        'Dein Profil ist angelegt und sichtbar. Für gute Matches wären aber mehr Angaben dringend nötig.',
    PROFILE_MESSAGE_B_40_80_TITLE: 'Super Start!',
    PROFILE_MESSAGE_B_40_80:
        'Der Anfang ist gemacht. Allerdings könnten mehr Details dein Profil und das Matching stark verbessern.',
    PROFILE_MESSAGE_GT_80_TITLE: 'Wow! Ausgezeichnet.',
    PROFILE_MESSAGE_GT_80:
        'Dein Profil ist vorbildlich ausgefüllt. Für bessere Resultate könnten aber weitere Angaben von Vorteil sein.',
    PROFILE_COMPLETED_SCORE: 'Die Vollständigkeit deines Profils liegt bei {{score}}%.',
    PROFILE_EDIT_DETAILS_LABEL: 'Profil bearbeiten',

    DASHBOARD_PREVIEW_TILE_TITLE: 'Videos',
    DASHBOARD_PREVIEW_TILE_TEXT: 'Videos ansehen',
    DASHBOARD_PREVIEW_TILE_ARIA_LABEL: 'Videos ansehen',

    DASHBOARD_QUESTIONNAIRE_TILE_TITLE: 'Fragebogen',
    DASHBOARD_QUESTIONNAIRE_TILE_TEXT: 'Optimiertes Matching',
    DASHBOARD_QUESTIONNAIRE_TILE_ARIA_LABEL: 'Zum persönlichen Fragebogen',

    DASHBOARD_SUGGESTION_HEADER: 'Neue Kolleg*innen finden...',

    TAG_SUGGESTION_LABEL: 'Bereits verwendete per Klick auswählen',
    TAG_SUGGESTION_SHOW_ALL: 'Alle anzeigen',
    TAG_SUGGESTION_SHOW_LESS: 'Weniger anzeigen',
    TAG_SUGGESTION_SELECT_ALL: 'Alle übernehmen',
    TAG_SUGGESTION_ADD_ALL: 'alle hinzufügen',
    TAG_SUGGESTION_HIDE: 'Ausblenden',
    TAG_SUGGESTION_DESELECT_ALL: 'Alle entfernen',
    TAG_SUGGESTION_SKILLS_LABEL: 'Vorher verwendet',
    TAG_TECH_WOLF_SKILLS_LABEL: 'Vorgeschlagene Begriffe von Tandemploy AI',

    INDIFFERENT: 'da bin ich flexibel (Standard)',
    NO_LEADERSHIP: 'nein',
    LEADERSHIP: 'ja',

    SIGN_UP_TITLE: 'Profil-Erstellung',

    SIGN_UP_SUCCESS_PAGE_TITLE: 'Willkommen auf Tandemploy!',
    SIGN_UP_SUCCESS_PAGE_TEXT:
        'Dies ist dein Raum, um vernetzter und flexibler zu arbeiten. Finde Kolleg*innen und Expert*innen aus anderen Bereichen zum Wissensaustausch, Sparringspartner für flexible Arbeitsformen und tolle Projekte zur Mitarbeit. Kann’s losgehen? Deine Kolleg*innen freuen sich auf dich, ganz bestimmt.',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_LABEL: 'Dein gewünschter Nutzername',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_INFO:
        'Wähle ein beliebiges Pseudonym, wenn du lieber anonym bleiben willst. Du kannst deinen Namen später jederzeit wieder ändern. Mehr zu diesem Thema findest du auf dieser Seite unter Infos zu "Anonymität".',
    SIGN_UP_SUCCESS_PAGE_USER_NAME_PLACEHOLDER: 'Name',
    SIGN_UP_SUCCESS_PAGE_ICON_1_TITLE: 'Datenschutz',
    SIGN_UP_SUCCESS_PAGE_ICON_1_INFO:
        '{{ appName }} ist ein geschützter, unternehmensinterner Raum, der vom Engagement und Vertrauen der Nutzer*innen lebt. Schon deshalb ist der Schutz deiner Daten ein zentrales Anliegen. Auch Administrator*innen und Vorgesetzte können lediglich anonymisierte Statistiken auswerten. Die auf {{ appName }} generierten Daten werden auf sicherem Weg verschlüsselt übertragen und in einem nach höchsten Sicherheitsstandards zertifizierten Rechenzentrum in Europa gespeichert. ',
    SIGN_UP_SUCCESS_PAGE_ICON_1_TEXT: 'Deine persönlichen und beruflichen Daten werden nicht an Dritte weitergegeben.',
    SIGN_UP_SUCCESS_PAGE_ICON_2_TITLE: 'Anonymität',
    SIGN_UP_SUCCESS_PAGE_ICON_2_INFO:
        'Du möchtest vorerst anonym bleiben und dich erst einmal unerkannt auf der Plattform bewegen? Kein Problem. Deine E-Mail-Adresse ist weder für Administrator*innen, noch für andere Nutzer*innen sichtbar und kann nicht mit deinem Nutzernamen in Verbindung gebracht werden. Du kannst, wenn du möchtest, ein beliebiges Pseudonym als Nutzernamen wählen und weitere persönliche Profilangaben selbst unter "Einstellungen" verstecken, sobald die Profilerstellung abgeschlossen ist.',
    SIGN_UP_SUCCESS_PAGE_ICON_2_TEXT: 'Persönliche Angaben wie Name und Mailadresse lassen sich verbergen.',
    SIGN_UP_SUCCESS_PAGE_ICON_3_TITLE: 'Volle Kontrolle',
    SIGN_UP_SUCCESS_PAGE_ICON_3_INFO:
        'Dein Account, deine Daten – deine Entscheidung! Ob du einen Account eröffnest, diesen (un-)regelmäßig verwendest oder nach Belieben wieder löscht: Es ist deine Entscheidung und Freiheit. Dieses Tool setzt auf dein Interesse, deine Motivation und Eigeninitiative – und möchte weg von Top-Down-Steuerung.',
    SIGN_UP_SUCCESS_PAGE_ICON_3_TEXT: 'Du kannst deinen Account und deine Daten jederzeit löschen.',
    SIGN_UP_SUCCESS_PAGE_BUTTON: "Los geht's!",
    SIGN_UP_SUCCESS_PAGE_TITLE_RECONFIRM_LEGACY_USERS:
        'Schön, dass Du wieder hier bist! Die Account-Bestätigung war erfolgreich.',
    SIGN_UP_SUCCESS_PAGE_TEXT_RECONFIRM_LEGACY_USERS:
        'Um dein Profil mit der neuen Version von {{ appName }} kompatibel zu halten, folgen nun einige Schritte zur Optimierung des Matchings und zum Updaten deines Profils.',
    SIGN_UP_SUCCESS_PAGE_WELCOME_TITLE: 'Die Matching Software für neues Arbeiten und eine vernetzte Organisation.',

    SIGN_UP_MODULE_SELECTION_TITLE: 'Triff deine Auswahl!',
    SIGN_UP_MODULE_SELECTION_SUB_TITLE: 'Wähle Deine Themen mit einem Klick und ziehe dein wichtigstes ganz nach oben.',
    SIGN_UP_MODULE_SELECTION_INFO:
        'Hier hast du die Möglichkeit, aus den aktuell freigeschalteten Themen (Features) dieser App zu wählen. Deine Auswahl dient dazu, {{ appName }} an deine Bedürfnisse anzupassen und künftig auch nach deinen Interessen weiterzuentwickeln. Außerdem spiegeln anonymisierte Statistiken dem Unternehmen, welchen Stellenwert die verschiedenen Themen für Mitarbeiter*innen haben.',
    SIGN_UP_MODULE_SELECTION_VALIDATION_ALERT: 'Bitte wähle mindestens ein Thema.',
    SIGN_UP_MODULE_SELECTION_MOBILE_TITLE: 'Themenwahl',
    SIGN_UP_MODULE_SELECTION_MOBILE_SUB_TITLE: 'Wähle Themen, die dich interessieren.',
    SIGN_UP_MODULE_SELECTION_MOBILE_INFO:
        'Um die App für deine Bedürfnisse zu optimieren, kannst du hier alle Themen auswählen, die dich interessieren. Im nächsten Schritt kannst du dann bestimmen, welche davon dir besonders wichtig sind.',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_TITLE: 'Deine Prioritäten',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_SUB_TITLE: 'Sortiere die Themen nach ihrer Wichtigkeit.',
    SIGN_UP_MODULE_PRIORITIZATION_MOBILE_INFO:
        'Hier bestimmst du, in welcher Reihenfolge du die gewählten Themen persönlich priorisieren würdest. An erster Stelle kommt dabei dein Hauptinteresse. Von dieser Priorisierung und eventuellen Änderungen daran hängen auch die folgenden Fragen der Profilerstellung ab.',
    SIGN_UP_NETWORKING_TILE_TITLE: 'Vernetzung',
    SIGN_UP_NETWORKING_TILE_TEXT: 'Mit interessanten Kolleg*innen vernetzen, sich austauschen und voneinander lernen.',
    SIGN_UP_NETWORKING_TILE_INFO:
        'Das Thema Vernetzung beinhaltet eine Reihe unterschiedlicher Möglichkeiten zur Vernetzung mit Kolleg*innen. Bitte wähle im nächsten Schritt einfach aus, welche Themen und Personengruppen dich ansprechen. Weitere Angaben können jederzeit später gemacht werden.',
    SIGN_UP_JOBSHARING_TILE_TITLE: 'Jobsharing',
    SIGN_UP_JOBSHARING_TILE_TEXT: 'Tandempartner*innen und geteilte Stellen für mögliche Jobsharings entdecken.',
    SIGN_UP_JOBSHARING_TILE_INFO:
        'Jobsharing, oder auch Arbeitsplatzteilung, ist ein flexibles Arbeitszeitmodell, bei dem sich zwei oder mehr Arbeitnehmer mindestens eine Vollzeitstelle teilen. Ihr arbeitet dabei als Team sehr eng zusammen und legt eure Arbeitszeiten und Aufgaben individuell untereinander fest. Jobsharing kann auch in Führungspositionen (sog. Top-Sharing/ Co-Leadership) sehr interessant sein. Der Matching-Algorithmus von {{ appName }} findet passende Tandem-Partner*innen unter deinen Kollegen und informiert dich per Mail bei neuen Matches.',
    SIGN_UP_REVERSE_MENTORING_TILE_TITLE: 'Reverse Mentoring',
    SIGN_UP_EXPATS_TILE_TITLE: 'Expats und Auslandserfahrung',
    SIGN_UP_EXPERT_TILE_TITLE: 'Expert*innen finden',
    SIGN_UP_INTERNSHIP_TILE_TITLE: 'Praktikant*innen, Auszubildende, Trainees',
    SIGN_UP_ONBOARDING_TILE_TITLE: 'Ankommen und Einleben',
    SIGN_UP_PARENTAL_LEAVE_TILE_TITLE: 'Elternzeit',
    SIGN_UP_PEER_LEARNING_TILE_TITLE: 'Peer Learning',
    SIGN_UP_RETRAINING_TILE_TITLE: 'Umschulung',
    SIGN_UP_SABBATICAL_TILE_TITLE: 'Sabbatical',
    SIGN_UP_TRAINING_TILE_TITLE: 'Weiterbildung',
    SIGN_UP_MENTORING_TILE_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_TILE_TEXT:
        'Für die fachliche und/oder persönliche Weiterentwicklung: Mentor*in sein oder finden.',
    SIGN_UP_MENTORING_TILE_INFO:
        'Du suchst eine*n Ansprechpartner*in für die fachliche Weiterentwicklung oder deine Karriere? Dann fehlen nur noch passende Mentor*innen. {{ appName }} findet nicht nur passende Mentoring-Matches für dich, sondern gibt Mitarbeiter*innen auch die Möglichkeit, sich in selbst bestimmten Disziplinen als Mentor*innen anzubieten. So einfach kann Selbstorganisation funktionieren.',

    SIGN_UP_RATING_TITLE: 'Welche Themen sind Dir besonders wichtig?',
    SIGN_UP_RATING_SUB_TITLE: 'Bitte gewichte alle gewählten Themen.*',
    SIGN_UP_RATING_SUB_INFO:
        'In diesem Schritt geht es darum anzugeben, welche Bedeutung die gewählten Themen für dich persönlich haben. Das Feedback dient dazu,  {{ appName }} an deine Bedürfnisse anzupassen und künftig auch nach deinen Interessen weiterzuentwickeln. Auch kann dein Unternehmen durch anonymisierte Statistiken erfahren, welchen Stellenwert einzelne Themen für Mitarbeiter*innen haben.',
    SIGN_UP_RATING_TILE_TEXT: 'Bitte bewerte die Wichtigkeit des Themas.',
    SIGN_UP_RATING_DESCRIPTION: 'Wichtigkeit des Themas für dich.',
    SIGN_UP_RATING_SCALE_LABEL_1: 'geht so',
    SIGN_UP_RATING_SCALE_LABEL_2: 'normal',
    SIGN_UP_RATING_SCALE_LABEL_3: 'wichtig',
    SIGN_UP_RATING_SCALE_LABEL_4: 'wichtiger',
    SIGN_UP_RATING_SCALE_LABEL_5: 'sehr wichtig',
    SIGN_UP_RATING_DATA_SNACK: 'Mit jedem Klick auf "Weiter" werden deine bisherigen Angaben sicher gespeichert.',

    SIGN_UP_JOBSHARING_TITLE: 'Jobsharing',
    SIGN_UP_JOBSHARING_SUB_TITLE: 'Doppelte Power auf einer Stelle.',
    SIGN_UP_JOBSHARING_INFO:
        'Du interessierst dich für Jobsharing, weißt aber noch nicht so genau wie das alles funktionieren könnte? Die gute Nachricht ist, dass das Unternehmen dich mit dieser Software aktiv dabei unterstützt, Arbeitsplatzteilung wirklich anzugehen und umzusetzen. Allerdings ist auch etwas Eigeninitative gefragt: Damit potenzielle Tandempartner*innen oder passende Positionen im Unternehmen auffindbar werden, kannst du a) deinen derzeitigen Arbeitsbereich beschreiben, wodurch dieser von anderen auf der Plattform gefunden werden kann (Abschnitt "Angebote" in deinem Profil und Unterseite "Angebote"). Oder du kannst b) einfach einen Wunsch-Job beschreiben, den du gerne im Jobsharing besetzen würdest. Dieser wird nicht für andere Nutzer*innen sichtbar. Anhand der Angaben können dir jedoch automatisiert passende Angebote per Mail zugeschickt werden und die Software kann dir geeignete Personen vorschlagen.',
    SIGN_UP_JOBSHARING_CONTENT_TITLE: 'Wie möchtest du starten?',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_LABEL:
        'Meinen derzeitigen Arbeitsbereich für potenzielle Tandempartner*innen beschreiben.',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_INFO:
        'Im nächsten Schritt kannst du ein paar Angaben zu deinem derzeitigen Arbeitsbereich machen, um diesen für interessierte Jobsharer*innen zu beschreiben. Anhand deiner Angaben wird ein für andere Nutzer*innen sichtbares Angebot erstellt. Dieses kannst du jederzeit im Abschnitt "Angebote" in deinem Profil anpassen, pausieren oder wieder löschen. \nAuf diese Weise können andere User*innen leichter zu dir finden oder bei einem Match per Mail informiert werden.',
    SIGN_UP_JOBSHARING_MOTIVE_FIND_JOB_LABEL:
        'Einen neuen Jobsharing-Job finden und den gewünschten Arbeitsbereich beschreiben.',
    SIGN_UP_JOBSHARING_MOTIVE_FIND_JOB_INFO:
        'Warum nur am Status quo orientieren? Hier kannst du deinen individuellen Wunsch-Job beschreiben, den du dir vielleicht künftig mit einer/einem passenden Tandempartner*in teilen würdest. Anhand dieser Beschreibung können dir automatisiert relevante Angebote per Mail zugeschickt werden und die Software kann dir geeignete Personen vorschlagen.',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_LABEL_DEACTIVE:
        'Meinen Arbeitsbereich beschreiben. (Diese Option ist nur während der Registrierung verfügbar. Näheres siehe Info.)',
    SIGN_UP_JOBSHARING_MOTIVE_SHARE_JOB_INFO_DEACTIVE:
        'Nach Abschluss der Registrierung kannst du deinen eigenen Arbeitsbereich beschreiben, indem du "Angebot erstellen" in deinem Profil aufrufst.\n\nSolltest du diese Option bereits bei der Registrierung gewählt haben, kannst du das erstellte Angebot jederzeit im Profil (ebenfalls im Abschnitt Angebote) bearbeiten oder löschen.',

    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_TITLE: 'Derzeitigen Arbeitsbereich beschreiben',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_INFO:
        'Beschreibe deinen derzeitigen Arbeitsbereich, dafür nötige Kenntnisse und komplementäre Skills, die idealerweise von einem möglichen Tandem-Match eingebracht werden könnten. Anhand deiner Angaben wird ein für andere Nutzer*innen sichtbares Angebot (Abschnitt "Angebote" in Ihrem Profil, Unterseite "Angebote") erstellt, das du jederzeit anpassen, pausieren oder wieder löschen kannst. Auf diese Weise können andere User*innen leichter zu dir finden oder bei einem Match per Mail informiert werden.',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_CONTENT_TITLE:
        'Dein Tätigkeitsbereich für mögliche/n Tandempartner*in beschreiben',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_POSITION_LABEL: 'Dein aktueller Titel (Position)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_POSITION_PLACEHOLDER: 'z. B. Senior Account Manager',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_LABEL: 'Dies ist eine Führungsposition',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_SLIDER_LABEL_NOT_CHECKED: 'Nein (Standard)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LEADERSHIP_POSITION_SLIDER_LABEL_CHECKED: 'Ja',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DEPARTMENT_LABEL: 'Deine Abteilung',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DEPARTMENT_PLACEHOLDER: 'z. B. Vertrieb',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_LABEL: 'Deine Kenntnisse, die für diesen Job wichtig sind',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_PLACEHOLDER: 'z. B. Supply-Chain-Management, SCM, Logistik',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LANGUAGES_LABEL: 'Deine Business-Sprache(n) ',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LANGUAGES_PLACEHOLDER: 'z.B. Deutsch, Englisch, Französisch, Spanisch',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_COMPLEMENTARY_LABEL:
        'Tandempartner*in hat idealerweise bessere/komplementäre Kenntnisse in',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_SKILLS_COMPLEMENTARY_PLACEHOLDER: 'z.B. Personalführung, Online Marketing',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LOCATION_LABEL: 'Dein aktueller Arbeitsstandort',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LOCATION_PLACEHOLDER: 'z. B. Berlin',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DESIRED_LOCATIONS_LABEL: 'Ich kann diesen Job ausüben in',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_LABEL: 'Gewünschte Wochenstunden',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PLACEHOLDER: 'z. B. 20',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PARTNER_LABEL: 'Wochenstunden Tandempartner*in',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_WORKING_HOURS_PARTNER_PLACEHOLDER: 'z. B. 20',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_JOB_DESCRIPTION_LABEL: 'Jobbeschreibung',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_JOB_DESCRIPTION_PLACEHOLDER: '',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_DESCRIPTION_LABEL: 'Kurzbeschreibung deines aktuellen Jobs (optional)',
    SIGN_UP_JOBSHARING_DETAILS_SHARE_JOB_LIKED_ABOUT_LABEL: 'Was gefällt dir an deinem Job besonders?',

    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_TITLE: 'Details für dein Jobsharing-Profil',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_INFO:
        'Hier kannst du deinen individuellen Wunsch-Job beschreiben, den du dir vielleicht künftig mit einer/einem passenden Tandempartner*in teilen würdest. Anhand dieser Beschreibung können dir automatisiert relevante Angebote per Mail zugeschickt werden und die Software kann dir geeignete Personen vorschlagen.',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LEADERSHIP_LABEL:
        'Ich möchte weiterhin bzw. künftig in einer Führungsposition arbeiten',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LEADERSHIP_PLACEHOLDER: '',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_FIELD_LABEL: 'Bereiche, in denen du arbeiten möchtest',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_FIELD_PLACEHOLDER: 'z. B. Supply-Chain-Management, SCM, Logistik',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_DEPARTMENT_LABEL: 'Gewünschte Abteilung/Bereich',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_DEPARTMENT_PLACEHOLDER: 'z. B. Einkauf',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_LOCATIONS_LABEL: 'Arbeitsstandorte, die für dich in Frage kommen',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_DESIRED_LOCATION_PLACEHOLDER: 'z. B. Hamburg',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LOCATION_LABEL: 'Aktueller Arbeitsstandort',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_SKILLS_LABEL: 'Kenntnisse, die du mitbringst',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_SKILLS_PLACEHOLDER: 'z. B. SCM, SCM Strategie, Efficient Consumer Response',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_COMPLEMENTARY_SKILLS_LABEL:
        'Tandempartner*in hat bessere oder komplementäre Kenntnisse in',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_COMPLEMENTARY_SKILLS_PLACEHOLDER: 'z.B. Personalführung, Online Marketing',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LANGUAGES_LABEL: 'Deine Business-Sprache(n)',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_LANGUAGES_PLACEHOLDER: 'z.B. Deutsch, Englisch, Französisch, Spanisch',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_LABEL: 'Deine gewünschten Wochenstunden',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PLACEHOLDER: 'z. B. 20',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PARTNER_LABEL: 'Wochenstunden Tandempartner*in',
    SIGN_UP_JOBSHARING_DETAILS_FIND_JOB_WORKING_HOURS_PARTNER_PLACEHOLDER: 'z. B. 20',

    SIGN_UP_MENTORING_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_SUB_TITLE:
        'Für die fachliche und/oder persönliche Weiterentwicklung: Mentor*in sein oder finden.',
    SIGN_UP_MENTORING_INFO:
        'Keine Lust mehr auf Excel-Listen und aufwendige Mentor*innen-Suche? Mit Hilfe von {{ appName }} können sich Mitarbeiter*innen selbstständig und eigeninitiativ zu Mentoring-Tandems zusammenfinden. Das Matching bringt Kolleg*innen zusammen, die sich bei ihren Fragestellungen und Zielen unterstützen und ihr wertvolles Wissen teilen können.',
    SIGN_UP_MENTORING_CONTENT_TITLE: 'Woran hast du Interesse?',
    SIGN_UP_MENTORING_MOTIVE_INTEREST_LABEL: 'Eine*n Mentor*in finden',
    SIGN_UP_MENTORING_MOTIVE_OFFERING_LABEL: 'Mich als Mentor*in einbringen',

    SIGN_UP_MENTORING_DETAILS_INTEREST_TITLE: 'Eine*n Mentor*in finden',
    SIGN_UP_MENTORING_DETAILS_INTEREST_INFO:
        'Keine Lust mehr auf Excel-Listen und aufwendige Mentor*innen-Suche? Mit Hilfe von {{ appName }} können sich Mitarbeiter*innen selbstständig und eigeninitiativ zu Mentoring-Tandems zusammenfinden. Das Matching bringt Kolleg*innen zusammen, die sich bei ihren Fragestellungen und Zielen unterstützen und ihr wertvolles Wissen teilen können.',
    SIGN_UP_MENTORING_DETAILS_INTEREST_CONTENT_TITLE: 'Wobei soll der/die Mentor*in dich unterstützen?',
    SIGN_UP_MENTORING_DETAILS_INTEREST_MOTIVATION_CAREER_LABEL: 'Bei Karrierefragen',
    SIGN_UP_MENTORING_DETAILS_INTEREST_MOTIVATION_SKILL_LABEL:
        'Bei der Weiterentwicklung meiner fachlicher Qualifikationen.',
    SIGN_UP_MENTORING_DETAILS_INTEREST_POSITION_LABEL: 'Dein aktueller Titel (Position)',
    SIGN_UP_MENTORING_DETAILS_INTEREST_POSITION_PLACEHOLDER: 'z.B. Senior Account Manager',
    SIGN_UP_MENTORING_DETAILS_INTEREST_FIELD_LABEL: 'In welchen Bereichen wünschst du dir Mentor*innen?',
    SIGN_UP_MENTORING_DETAILS_INTEREST_FIELD_PLACEHOLDER: 'z. B. Teamführung, Prozessmanagement',

    SIGN_UP_MENTORING_DETAILS_OFFERING_TITLE: 'Als Mentor*in unterstützen',
    SIGN_UP_MENTORING_DETAILS_OFFERING_INFO:
        'Mit Hilfe von {{ appName }} können sich Mitarbeiter*innen selbstständig und eigeninitiativ zu Mentoring-Tandems zusammenfinden. Das Matching bringt Kolleg*innen zusammen, die sich bei ihren Fragestellungen und Zielen unterstützen und ihr wertvolles Wissen teilen können.',
    SIGN_UP_MENTORING_DETAILS_OFFERING_CONTENT_TITLE: 'In welchen Bereichen möchtest du als Mentor*in unterstützen?',
    SIGN_UP_MENTORING_DETAILS_OFFERING_MOTIVATION_CAREER_LABEL: 'Bei Karrierefragen',
    SIGN_UP_MENTORING_DETAILS_OFFERING_MOTIVATION_SKILL_LABEL:
        'Bei der Weiterentwicklung in fachlichen Qualifikationen.',
    SIGN_UP_MENTORING_DETAILS_OFFERING_POSITION_LABEL: 'Dein aktueller Titel (Position)',
    SIGN_UP_MENTORING_DETAILS_OFFERING_POSITION_PLACEHOLDER: 'z.B. Senior Account Manager',
    SIGN_UP_MENTORING_DETAILS_OFFERING_FIELD_LABEL: 'Nenne Bereiche, in denen du als Mentor*in unterstützen kannst',
    SIGN_UP_MENTORING_DETAILS_OFFERING_FIELD_IMPROVE_PLACEHOLDER:
        'z. B. Online Marketing, Mitarbeiterführung, Verhandlungsführung',

    SIGN_UP_NETWORKING_TITLE: 'Welche Form der Vernetzung spricht dich an?',
    SIGN_UP_NETWORKING_SUB_TITLE: 'Mit interessanten Kolleg*innen vernetzen, sich austauschen und voneinander lernen.',
    SIGN_UP_NETWORKING_INFO:
        'Welche Kolleg*innen sollen dir künftig angezeigt und vorgeschlagen werden? Was ist dir besonders wichtig? Worüber möchtest du dich austauschen und auf welche Themen soll sich diese Plattform in Zukunft fokussieren? Das bestimmst auch du! Wähle aus den verschiedenen Vernetzungsthemen die für dich spannenden aus und beantworte einfach die sich anschließenden Fragen. Damit konfigurierst du nicht nur die App für deine eigenen Bedürfnisse, du ermöglichst es auch anderen Kolleg*innen dich leichter zu finden und auf dich zuzugehen.',
    SIGN_UP_NETWORKING_MOTIVE_SKILLS_LABEL: 'Nach Fachgebieten und bestimmten Kenntnissen.',
    SIGN_UP_NETWORKING_MOTIVE_SKILLS_INFO:
        'Finde und vernetze dich mit Kolleg*innen bestimmter Fachrichtungen, baue dir dein individuelles Netzwerk und unterstütze Kolleg*innen mit deiner Expertise.',
    SIGN_UP_NETWORKING_MOTIVE_INTERESTS_LABEL: 'Vernetzung anhand persönlicher Interessen.',
    SIGN_UP_NETWORKING_MOTIVE_INTERESTS_INFO:
        'Ähnliche Interessen, Hobbies, andere geteilte Anliegen und Lebenssituationen sind die beste Basis für Vernetzung und das Kennenlernen neuer Kolleg*innen – auch über die eigene Abteilung hinaus. Deine Angaben werden nicht auf dem öffentlichen Profil erscheinen, können aber für künftiges Matching mit passenden Kolleg*innen genutzt werden.',
    SIGN_UP_NETWORKING_MOTIVE_LOCATION_LABEL: 'Mit Kolleg*innen eines anderen Firmenstandorts austauschen.',
    SIGN_UP_NETWORKING_MOTIVE_LOCATION_INFO:
        'Du interessierst dich für einen anderen Standort des Unternehmens und möchtest mit Kolleg*innen dieses Standorts in den Austausch kommen? Dann ist diese Option die richtige Wahl.',
    SIGN_UP_NETWORKING_MOTIVE_PEOPLE_LABEL: 'Matching nach Themen und Personengruppen.',
    SIGN_UP_NETWORKING_MOTIVE_PEOPLE_INFO:
        'Diese Option ist vorausgewählt und zwingend, da es eine Reihe weiterer spezifischer Vernetzungsmöglichkeiten gibt, die dir nicht vorenthalten bleiben sollen. Eine Liste aller themen- und gruppenbezogenen Matching-Möglichkeiten folgt in den nächsten Schritten.',

    SIGN_UP_NETWORKING_DETAILS_SKILLS_TITLE: 'Fachspezifischer Austausch',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INFO:
        'Finde und vernetze dich mit Kolleg*innen bestimmter Fachrichtungen, bau dir dein individuelles Netzwerk und unterstütze Kolleg*innen mit deiner Expertise.',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INTEREST_LABEL: 'Fachbereiche und Kenntnis, für die du dich interessierst',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_INTEREST_PLACEHOLDER: 'z. B. Controlling, CRM, Online-Marketing',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_OFFERING_LABEL: 'Themen/Fachbereiche, in denen du weiterhelfen kannst',
    SIGN_UP_NETWORKING_DETAILS_SKILLS_OFFERING_PLACEHOLDER: 'z. B. Controlling, Risikoanlysen',

    SIGN_UP_NETWORKING_DETAILS_INTERESTS_TITLE: 'Nach persönlichen Interessen',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_INFO:
        'Ähnliche Interessen, Hobbies, andere geteilte Anliegen und Lebenssituationen sind die beste Basis für Vernetzung und das Kennenlernen neuer Kolleg*innen – auch über die eigene Abteilung hinaus. Deine Angaben werden nicht auf dem öffentlichen Profil erscheinen, können aber für künftiges Matching mit passenden Kolleg*innen genutzt werden.',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_PERSONAL_INTEREST_LABEL: 'Deine Interessen',
    SIGN_UP_NETWORKING_DETAILS_INTERESTS_PERSONAL_INTEREST_PLACEHOLDER:
        'z. B. Joggen, veganes Essen, Theater, Fussball',

    SIGN_UP_NETWORKING_DETAILS_LOCATION_TITLE: 'Zu einem bestimmten Arbeitsstandort',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INFO:
        'Du interessierst dich für einen anderen Standort des Unternehmens und möchtest mit Kolleg*innen dieses Standorts in den Austausch kommen? Dann ist diese Option die richtige Wahl.',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INTEREST_LABEL: 'Arbeitsstandorte, für die du dich interessierst',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_INTEREST_PLACEHOLDER: 'z. B. München, San Jose USA',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_CURRENT_LABEL: 'Dein aktueller Arbeitsstandort',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_CURRENT_PLACEHOLDER: 'z. B. Dortmund',
    SIGN_UP_NETWORKING_DETAILS_LOCATION_OFFERING_LOCATION_SUPPORT_LABEL:
        'Von Kollegen*innen gefunden werden, die meinen Standort interessant finden.',

    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TITLE: 'Matching nach Themen und Personengruppen',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INFO:
        'Vernetzung und Austausch ist für alle ein Gewinn. {{ appName }}  unterstützt dich dabei, die passenden Kollegen für dich und deine Bedürfnisse herauszufiltern. Hier findest du eine Reihe von Vorschlägen zu Themen und Kollegengruppen. Wähle einfach aus, was dich davon interessiert oder künftig interessieren könnte. Gerne kannst du, wenn du ganz nach unten scrollst, noch weitere Ideen einbringen, die – natürlich anonymisiert – mit dem {{ appName }}  Team geteilt werden.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SUB_TITLE: 'Welche Themen und Kollegenkreise interessieren dich?',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_QUESTION_LABEL: 'Reverse Mentoring',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_QUESTION_INFO:
        'Der Austausch mit Kolleg*innen anderer Altersgruppen liegt sehr nahe. Nur haben ältere Mitarbeiter*innen selten Gelegenheit, auch wieder einmal Mentee zu sein. Reverse Mentoring bietet die Möglichkeit, erfahrene ältere Mitarbeiter*innen vom besonderen Wissen und den Perspektiven jüngerer Kolleg*innen profitieren zu lassen und fördert damit den generationsübergreifenden Dialog und Wissensaustausch im Unternehmen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_ANSWER_OFFERING:
        'Ich gehöre zu den jüngeren Kolleg*innen und bin gern dabei.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_REVERSE_MENTORING_ANSWER_INTEREST:
        'Ich würde mich über die Zusammenarbeit mit jungen Mentor*innen freuen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_QUESTION_LABEL: 'Peer Learning',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_QUESTION_INFO:
        'Nicht nur was und wozu wir lernen ist entscheidend. Auch mit wem wir lernen spielt eine Rolle. Wer kennt dieselben Herausforderungen und besitzt eine ähnliche Motivation? Der Peer-Learning-Ansatz berücksichtigt genau das und ermöglicht so produktiveres und lebensnaheres Lernen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PEER_LEARNING_ANSWER_MIXED:
        'Ich würde gerne zusammen mit Kolleg*innen in ähnlicher Situation/Position Neues lernen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_QUESTION_LABEL: 'Ankommen und Einleben',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_QUESTION_INFO:
        'Die einen sind schon länger dabei und wissen inzwischen vieles, was sie beim eigenen Start gern gewusst hätten. Den anderen könnte genau dieses Wissen vieles erleichtern. Warum nicht in den Austausch gehen und echte Teamkultur schon beim Onboarding prägen?',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_ANSWER_OFFERING:
        'Ich erleichtere neuen Kolleg*innen gerne das Ankommen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_ONBOARDING_ANSWER_INTEREST:
        'Etwas Unterstützung beim Einleben im Unternehmen wäre toll.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_QUESTION_LABEL: 'Expert*innen finden',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_QUESTION_INFO:
        'Wenn dieses Unternehmen wüsste, was seine Mitarbeiter*innen alles wissen – bräuchte es dann noch externe Berater? Entdecke Kenntnisse und vielleicht versteckte Talente deiner Kolleg*innen und nutze die vorhandene Expertise.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPERTISE_ANSWER_MIXED: 'Ich suche nach Expert*innen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TRAINING_QUESTION_LABEL: 'Weiterbildung',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_TRAINING_ANSWER_MIXED: 'Ich habe Interesse an Weiterbildungen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_RETRAINING_QUESTION_LABEL: 'Umschulung',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_RETRAINING_ANSWER_MIXED: 'Ich habe Interesse an einer Umschulung.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_QUESTION_LABEL: 'Expats und Auslandserfahrung',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_ANSWER_OFFERING: 'Ich bin Expat oder habe im Ausland gearbeitet.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_EXPATS_ANSWER_INTEREST:
        'Ich suche Expats oder Kolleg*innen, die im Ausland gearbeitet haben.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_QUESTION_LABEL: 'Praktikant*in, Auszubildende/r oder Trainee',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_ANSWER_INTEREST: 'Ich gehöre zu einer dieser Gruppen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_INTERNSHIP_ANSWER_OFFERING:
        'Ich möchte Personen aus diesen Gruppen unterstützen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_QUESTION_LABEL: 'Sabbatical',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_ANSWER_OFFERING: 'Ich habe bereits ein Sabatical gemacht.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_SABBATICAL_ANSWER_INTEREST: 'Ich möchte mehr über Sabbaticals erfahren.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_QUESTION_LABEL: 'Elternzeit',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_ANSWER_OFFERING: 'Ich habe bereits Elternzeit genommen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_PARENTAL_LEAVE_ANSWER_INTEREST: 'Ich habe vor, Elternzeit zu nehmen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_FREE_FIELD_LABEL: 'Weitere Vorschläge?',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_FREE_FIELD_PLACEHOLDER: 'Vielen Dank für Ihre Ideen!',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_QUESTION_LABEL: 'Familie und Beruf; Elternzeit',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_ANSWER_OFFERING:
        'Ich bin bereit, Erfahrungen im Bereich Vereinbarkeit von Familie und Beruf/Elternzeit zu teilen.',
    SIGN_UP_NETWORKING_EXTRA_MOTIVATION_CAREER_FAMILY_LIFE_ANSWER_INTEREST:
        'Ich interessiere mich für den Austausch zum Thema Vereinbarkeit von Familie und Beruf.',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_TITLE: 'Reverse Mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_INFO:
        'Der Austausch mit Kolleg*innen anderen Altersgruppen liegt sehr nahe. Nur haben ältere Mitarbeiter*innen selten Gelegenheit, auch wieder einmal Mentee zu sein. Reverse Mentoring bietet die Möglichkeit, erfahrene ältere Mitarbeiter*innen vom besonderen Wissen und den Perspektiven jüngerer Kolleg*innen profitieren zu lassen und fördert damit den generationsübergreifenden Dialog und Wissensaustausch im Unternehmen.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_CONTENT_TITLE:
        'Unterstütze ältere Kolleg*innen (Reverse Mentoring)',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_SKILLS_LABEL:
        'Mit welchen Kenntnissen kannst du ältere Kolleg*innen unterstützen?',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_SKILLS_PLACEHOLDER: 'z. B. Software Tools',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_YEAR_OF_BIRTH_LABEL: 'Dein Geburtsjahr',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_OFFERING_YEAR_OF_BIRTH_PLACEHOLDER: 'Bitte wählen',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_TITLE: 'Reverse mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_SUB_TITLE: 'Mentoring einmal anders herum.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_CONTENT_TITLE:
        'Suchst du einen "Reverse Mentor" oder willst du selbst einmal erfahrenere Kolleg*innen unterstützen?',

    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_TITLE: 'Reverse Mentoring',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_INFO:
        'Der Austausch mit Kolleg*innen anderen Altersgruppen liegt sehr nahe. Nur haben ältere Mitarbeiter*innen selten Gelegenheit, auch wieder einmal Mentee zu sein. Reverse Mentoring bietet die Möglichkeit, erfahrene ältere Mitarbeiter*innen vom besonderen Wissen und den Perspektiven jüngerer Kolleg*innen profitieren zu lassen und fördert damit den generationsübergreifenden Dialog und Wissensaustausch im Unternehmen.',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_SKILLS_LABEL:
        'In welchen Qualifikationen möchtest du von jüngeren Kolleg*innen unterstützt werden?',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_SKILLS_PLACEHOLDER:
        'z. B. Software Tools, Digitalisierung, Social Media',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_YEAR_OF_BIRTH_LABEL: 'Dein Geburtsjahr',
    SIGN_UP_NETWORKING_DETAILS_REVERSE_MENTORING_INTEREST_YEAR_OF_BIRTH_PLACEHOLDER: 'Bitte wählen',

    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_TITLE: 'Peer Learning',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_INFO:
        'Nicht nur was und wozu wir lernen ist entscheidend. Auch mit wem wir lernen spielt eine Rolle. Wer kennt dieselben Herausforderungen und besitzt eine ähnliche Motivation? Der Peer-Learning-Ansatz berücksichtigt genau das und ermöglicht so produktiveres und lebensnaheres Lernen.',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_CONTENT_TITLE:
        'Gemeinsam und von anderen Kolleg*innen lernen (Peer Learning)',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_LABEL: 'Wo und wie lernst du am besten?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_PLACEHOLDER: 'Mehrfachauswahl möglich',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_1: 'Arbeitsalltag',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_2: 'Coaching',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_3: 'Events',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_4: 'Mentoring',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_5: 'Projekte',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_6: 'Working Circles',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_7: 'Workshops',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_LEARNING_METHOD_8: 'Andere Weiterbildungsangebote',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_COMPANY_SKILLS_LABEL:
        'Welche Fähigkeiten/Talente fehlen dem Unternehmen deiner Meinung nach am meisten?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_COMPANY_SKILLS_PLACEHOLDER:
        'z. B. Project Manager, Start-up Mentalität, IT, Nachhaltigkeit',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_INTEREST_LABEL:
        'Welche Kenntnisse möchtest du persönlich von anderen Kolleg*innen erwerben?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_INTEREST_PLACEHOLDER:
        'z. B. Scrum, Strategisches Marketing, Powerpoint ',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_OFFERING_LABEL:
        'Welche Qualifikationen würdest du gerne an Kolleg*innen weitergeben?',
    SIGN_UP_NETWORKING_DETAILS_PEER_LEARNING_MIXED_SKILLS_OFFERING_PLACEHOLDER:
        'z. B. Scrum, Strategisches Marketing, Powerpoint',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_TITLE: 'Ankommen und Einleben',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_SUB_TITLE: 'Onboarding neuer Kolleg*innen im Unternehmen',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_CONTENT_TITLE:
        'Unterstütze Kolleg*innen bei deren Start im Unternehmen oder finde selbst "Buddies", die schon eine Weile da sind.',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_TITLE: 'Ankommen und Einleben',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_INFO:
        'Die einen sind schon länger dabei und wissen inzwischen vieles, was sie beim eigenen Start gern gewusst hätten. Den anderen könnte genau dieses Wissen vieles erleichtern. Warum nicht in den Austausch gehen und echte Teamkultur schon beim Onboarding prägen?  ',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_DEPARTMENT_LABEL: 'Deine aktuelle Abteilung',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_DEPARTMENT_PLACEHOLDER: 'z. B. IT Abteilung',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_COMPANY_SITE_LABEL: 'Dein aktueller Arbeitsstandort',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_OFFERING_COMPANY_SITE_PLACEHOLDER: 'z. B. Dortmund',

    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_TITLE: 'Ankommen und Einleben',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_CONTENT_TITLE:
        'Ich bin neu im Unternehmen und würde mich freuen Kolleg*innen kennenzulernen',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_DEPARTMENT_LABEL: 'Deine aktuelle Abteilung',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_DEPARTMENT_PLACEHOLDER: 'z. B. IT Abteilung',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_COMPANY_SITE_LABEL: 'Dein aktueller Arbeitsstandort',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_COMPANY_SITE_PLACEHOLDER: 'z. B. Berlin',
    SIGN_UP_NETWORKING_DETAILS_ONBOARDING_INTEREST_HELP_LATER_LABEL:
        'Ich möchten Kolleg*innen beim Ankommen unterstützen, sobald ich das Unternehmen besser kenne.',

    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_TITLE: 'Expert*innen finden',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_INFO:
        'Wenn dieses Unternehmen wüsste, was seine Mitarbeiter*innen alles wissen – bräuchte es dann noch externe Berater? Entdecke Kenntnisse und vielleicht versteckte Talente deiner Kolleg*innen und nutze die vorhandene Expertise.',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_CONTENT_TITLE: 'Für welche Dauer und Bereiche suchst du Expertise?',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_SHORT_SUPPORT_LABEL:
        'Ich suche nach einer kurzfristigen Unterstützung durch Expert*innen.',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_LONG_SUPPORT_LABEL:
        'Ich suche nach einer langfristigen Unterstützung durch Expert*innen.',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_WANTED_EXPERTISE_LABEL:
        'Beschreibe die gesuchten Kenntnisse in Schlagworten',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_WANTED_EXPERTISE_PLACEHOLDER: 'z. B. Linux System Administrator',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_EXPERT_SKILLS_LABEL: 'Worin bist du Experte?',
    SIGN_UP_NETWORKING_DETAILS_EXPERTISE_MIXED_EXPERT_SKILLS_PLACEHOLDER: 'z. B. Linux System Administrator',

    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TITLE: 'Weiterbildung',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_CONTENT_TITLE:
        'In welchen Bereichen wünscht du dir Weiterbildungsmöglichkeiten?',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TRAINING_FIELDS_LABEL: 'Beschreibe die Bereiche in Schlagworten',
    SIGN_UP_NETWORKING_DETAILS_TRAINING_MIXED_TRAINING_FIELDS_PLACEHOLDER: 'z. B. Agiles Projektmanagement',

    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_TITLE: 'Umschulung',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_CONTENT_TITLE:
        'In welchen Bereiche hast du Interesse an einer Umschulung?',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_RETRAINING_FIELDS_LABEL: 'Beschreibe die Bereiche in Schlagworten',
    SIGN_UP_NETWORKING_DETAILS_RETRAINING_MIXED_RETRAINING_FIELDS_PLACEHOLDER: 'z. B. IT, System Admin',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_TITLE: 'Expats und Auslandserfahrung',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_SUB_TITLE: 'Wissens- und Erfahrungsaustausch mit und zwischen Expats',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_CONTENT_TITLE:
        'Gib an, ob du Expats suchst und/oder selbst schon einmal als Expat gearbeitet hast.',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_TITLE: 'Expats und Auslandserfahrung',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_CONTENT_TITLE: 'In welchen Ländern hast du bereits gearbeitet?',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_VISITED_COUNTRIES_LABEL:
        'Länder, in denen du als Expat arbeitest oder gearbeitet hast',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_VISITED_COUNTRIES_PLACEHOLDER: 'z. B. Frankreich',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_HOME_COUNTRY_LABEL: 'Dein Heimatland',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_OFFERING_HOME_COUNTRY_PLACEHOLDER: 'z. B. Deutschland',

    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_TITLE: 'Expats & Auslandserfahrung',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_CONTENT_TITLE:
        'Zu welchen Ländern möchtest du dich mit Expats austauschen?',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_DESIRED_COUNTRIES_LABEL: 'Länder, die dich interessieren',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_DESIRED_COUNTRIES_PLACEHOLDER: 'z. B. Kanada',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_HOME_COUNTRY_LABEL: 'Dein Heimatland',
    SIGN_UP_NETWORKING_DETAILS_EXPATS_INTEREST_HOME_COUNTRY_PLACEHOLDER: 'z. B. Deutschland',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_TITLE: 'Praktikant*innen, Auszubildende und Trainees',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_SUB_TITLE:
        'Austausch mit und Unterstützung von bestimmten Gruppen wie z.B. Praktikant*innen.',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_CONTENT_TITLE:
        'Gehörst du einer der Gruppen an oder möchtest du diese unterstützen?',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_TITLE: 'Praktikant*innen, Auszubildende, Trainees',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_CONTENT_TITLE:
        'Praktikant*innen, Auszubildende oder Trainees – wen möchtest du unterstützen?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_LABEL: 'Gruppen, die du unterstützen möchtest',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_1: 'Trainee',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_2: 'Praktikant*in',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_OPTION_3: 'Auszubildende/r',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_SUPPORT_GROUP_PLACEHOLDER: 'Mehrfachauswahl',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_MOTIVATION_LABEL: 'Was ist deine Hauptmotivation?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_OFFERING_MOTIVATION_PLACEHOLDER:
        'z. B. Meine Erfahrung an den Nachwuchs weitergeben',

    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_TITLE: 'Praktikant*innen, Auszubildende, Trainees',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_CONTENT_TITLE:
        'Praktikant*in, Auszubildende/r oder Trainee? Untereinander vernetzen und supporten!',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_LABEL: 'Gruppe, der du angehörst',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_PLACEHOLDER: 'Bitte wählen',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_1: 'Trainee',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_2: 'Praktikant*in',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_GROUP_OPTION_3: 'Auszubildende/r',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_OTHER_INTEREST_LABEL:
        'Bei welchen Dingen könnte dich diese Software noch unterstützen?',
    SIGN_UP_NETWORKING_DETAILS_INTERNSHIP_INTEREST_OTHER_INTEREST_PLACEHOLDER:
        'Z. B. gemeinsame Aktivitäten organisieren',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_TITLE: 'Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_SUB_TITLE: 'Erfahrungsaustausch zum Thema Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_CONTENT_TITLE:
        'Teile deine eigenen Erfahrungen oder frage Kolleg*innen, die schon ein Sabbatical genommen haben.',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_TITLE: 'Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CONTENT_TITLE: 'Teile deine Sabbatical-Erfahrung',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CAUSE_LABEL: 'Gründe für dein Sabbatical:',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_CAUSE_PLACEHOLDER: 'z. B. Reisen, Familie',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_BENEFIT_EMPLOYER_LABEL:
        'Wie hat dein/e Arbeitgeber*in (indirekt) von deinem Sabbatical profitiert?',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_OFFERING_BENEFIT_EMPLOYER_PLACEHOLDER:
        'z. B. Neue Energie, neue Perspektiven',

    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_TITLE: 'Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CONTENT_TITLE: 'Dein Interesse an einem Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CAUSE_LABEL: 'Deine Pläne/Gründe für ein Sabbatical',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_CAUSE_PLACEHOLDER: 'z. B. Reisen, Auszeit, Neues lernen',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_LABEL:
        'Fördert die Arbeitskultur deines Unternehmens dich dabei, ein Sabbatical zu nehmen? ',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_1: 'sehr',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_2: 'mittelmäßig',
    SIGN_UP_NETWORKING_DETAILS_SABBATICAL_INTEREST_ACCEPTANCE_EMPLOYER_RADIO_3: 'wenig',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_TITLE: 'Elternzeit',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_SUB_TITLE: 'Erfahrungsaustausch zum Thema Elternzeit',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_CONTENT_TITLE:
        'Teile deine eigenen Erfahrungen oder frage Kolleg*innen, die schon in Elternzeit waren.',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_TITLE: 'Elternzeit',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_CONTENT_TITLE:
        'Teile deine Elternzeit-Erfahrung mit Kolleg*innen',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_SHARE_EXPERIENCE_LABEL:
        'Ich bin bereit, meine Elternzeit-Erfahrungen mit Kolleg*innen zu teilen. ',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_LEADING_POSITION_LABEL: 'Ich arbeite in einer Führungsposition.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_LABEL:
        'Machte es dir die Arbeitskultur deines Unternehmens leicht, Elternzeit zu nehmen?',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_1: 'sehr',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_2: 'mittelmäßig',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_OFFERING_ACCEPTANCE_RADIO_3: 'wenig',

    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_TITLE: 'Elternzeit',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_CONTENT_TITLE: 'Dein Interesse an Elternzeit',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_FIND_EXPERIENCE_LABEL:
        'Ich möchte mich mit Kolleg*innen austauschen, die bereits Elternzeit genommen haben.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_FIND_EXPERIENCE_INFO:
        'SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_FIND_EXPERIENCE_INFO',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_LEADING_POSITION_LABEL:
        'Ich arbeite in einer Fürhrungsposition.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_LEADING_POSITION_INFO:
        'SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_LEADING_POSITION_INFO',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_LABEL:
        'Ich habe manchmal Zweifel, ob ich mir eine Elternzeit in meiner Position wirklich nehmen kann.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_RADIO_1: 'Ja.',
    SIGN_UP_NETWORKING_DETAILS_PARENTAL_LEAVE_INTEREST_ACCEPTANCE_RADIO_2: 'Nein, auf keinen Fall.',

    SIGN_UP_SKILLS_ALL_LABEL: 'Alle deine Kenntnisse: Bitte übernimm und/oder ergänze',
    SIGN_UP_SKILLS_ALL_PLACEHOLDER: 'Weitere Qualifikation hinzufügen',
    SIGN_UP_SKILLS_EXPERT_LABEL: 'In den Augen deiner Kolleg*innen bist du Experte in (max. 5 Kenntnisse):',
    SIGN_UP_SKILLS_EXPERT_PLACEHOLDER: 'Weitere Qualifikation hinzufügen',
    SIGN_UP_SKILLS_EXPERT_MAXIMUM_TAGS_WARNING: 'Du kannst höchstens fünf Schlagworte eingeben.',
    SIGN_UP_SKILLS_LAST_STEPS_MOTIVATION_MESSAGE:
        'Großartig! Du hast es bald geschafft. Noch ein paar allgemeine Angaben und es kann losgehen.',

    SIGN_UP_EXPERIENCE_TITLE: 'Berufserfahrung',
    SIGN_UP_EXPERIENCE_INFO:
        'Das Symbol im Feld "Stationen deiner beruflichen Laufbahn" bedeutet, dass du die Angaben zu deinen bisherigen Karrierestationen auch verbergen kannst. Dies ist im Anschluss an die Registrierung unter "Einstellungen > Anonymität" jederzeit möglich. Allerdings empfehlen wir dir, möglichst alle Profildetails sichtbar zu halten und "Gesicht zu zeigen". Es ist erwiesen, dass dies die Kontaktaufnahme für andere erleichtert.',
    SIGN_UP_EXPERIENCE_CONTENT_TITLE: 'Deine berufliche Laufbahn',
    SIGN_UP_EXPERIENCE_STATION_LABEL: 'Stationen deiner beruflichen Laufbahn',
    SIGN_UP_EXPERIENCE_STATION_PERSONAL_INFO_BTN_ARIA_LABEL: 'Diese Angaben auf meinem öffentlichen Profil verbergen',
    SIGN_UP_EXPERIENCE_STATION_PERSONAL_INFO:
        'Die Angaben zu deinen bisherigen Karrierestationen kannst du im Anschluss an die Registrierung unter "Einstellungen > Anonymität" verbergen. Allerdings empfehlen wir dir, möglichst alle Profildetails sichtbar zu halten und "Gesicht zu zeigen". Es ist erwiesen, dass dies die Kontaktaufnahme für andere erleichtert.',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_1: 'z.B. Junior Account Manager',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_2: 'Jahre',
    SIGN_UP_EXPERIENCE_STATION_PLACEHOLDER_3: 'Monate',
    SIGN_UP_EXPERIENCE_STATION_ADD_BUTTON: 'Hinzufügen',
    SIGN_UP_EXPERIENCE_STATION_REMOVE_BUTTON: 'Diesen Eintrag entfernen',
    SIGN_UP_EXPERIENCE_DEGREES_LABEL: 'Was sind deine höchsten Abschlüsse?',
    SIGN_UP_EXPERIENCE_DEGREES_PLACEHOLDER: 'z. B. Betriebswirtin Diplom, Master of Science IT, Dipl. Ing.',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_LABEL: 'Berufserfahrung insgesamt (in Jahren)',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_PLACEHOLDER: 'Anzahl Jahre',
    SIGN_UP_EXPERIENCE_YEARS_TOTAL_UNIT: 'Jahr/e',
    SIGN_UP_EXPERIENCE_VALIDATION_REQUIRED_SHORT_VERSION: 'Dies ist ein Pflichtfeld',
    SIGN_UP_EXPERIENCE_SKILL: 'Beschäftigung',
    SIGN_UP_EXPERIENCE_YEARS: 'Jahre',
    SIGN_UP_EXPERIENCE_MONTHS: 'Monate',

    SIGN_UP_HARD_FACTS_TITLE: 'Über mich',
    SIGN_UP_HARD_FACTS_INFO:
        'Ergänze hier noch die wichtigsten Daten zu deiner Person – damit ist dein Profil vollständig. Das Symbol im Feld "Geburtsjahr" bedeutet, dass du diese Angabe künftig auch verbergen kannst. Dies ist im Anschluss an die Registrierung unter "Einstellungen > Anonymität" jederzeit möglich. Allerdings empfehlen wir dir, möglichst alle Profildetails sichtbar zu halten und "Gesicht zu zeigen". Es ist erwiesen, dass dies die Kontaktaufnahme für andere erleichtert.',
    SIGN_UP_HARD_FACTS_SUB_TITLE: 'Meine Profil-Details',
    SIGN_UP_HARD_FACTS_PROFILE_PICTURE_INFO: 'SIGN_UP_HARD_FACTS_PROFILE_PICTURE_INFO',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_LABEL: 'Geburtsjahr',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_PLACEHOLDER: 'Bitte wählen',
    SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_INFO:
        'Dein Geburtsjahr kannst du im Anschluss an die Registrierung unter "Einstellungen > Anonymität" verbergen. Allerdings empfehlen wir dir, möglichst alle Profildetails sichtbar zu halten und "Gesicht zu zeigen". Es ist erwiesen, dass dies die Kontaktaufnahme für andere erleichtert.',
    SIGN_UP_HARD_FACTS_GENDER_LABEL: 'Geschlecht',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_1: 'männlich',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_2: 'weiblich',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_3: 'divers',
    SIGN_UP_HARD_FACTS_GENDER_RADIO_4: 'keine Angabe',
    SIGN_UP_HARD_FACTS_USER_NAME_LABEL: 'Benutzername/Nickname',
    SIGN_UP_HARD_FACTS_USER_NAME_PLACEHOLDER: 'Gewünschter Benutzername',
    SIGN_UP_HARD_FACTS_POSITION_LABEL: 'Aktueller Titel (Position)',
    SIGN_UP_HARD_FACTS_POSITION_PLACEHOLDER: 'z.B. Senior Account Manager',
    SIGN_UP_HARD_FACTS_LOCATIONS_LABEL: 'Mein Arbeitsstandort',
    SIGN_UP_HARD_FACTS_DEPARTMENT_LABEL: 'Abteilung oder Bereich',
    SIGN_UP_HARD_FACTS_DEPARTMENT_PLACEHOLDER: 'z.B. Strategy Management',
    SIGN_UP_HARD_FACTS_COMPANY_LABEL: 'Tochterunternehmen',
    SIGN_UP_HARD_FACTS_COMPANY_PLACEHOLDER: '-',
    SIGN_UP_HARD_FACTS_DETAILS_FIND_JOB_LANGUAGES_LABEL: 'Deine Business-Sprache(n)',
    SIGN_UP_HARD_FACTS_DETAILS_FIND_JOB_LANGUAGES_PLACEHOLDER: 'z.B. Deutsch, Englisch, Französisch, Spanisch',
    SIGN_UP_HARD_FACTS_MOTTO_LABEL: 'Eine kurze Message für dein Profil: Warum bist du hier?',
    SIGN_UP_HARD_FACTS_MOTTO_PLACEHOLDER: '-',
    SIGN_UP_HARD_FACTS_GROUPS_LABEL: 'Zielgruppen eingrenzen',

    SIGN_UP_UNAVAILABLE_MODULE_RATING_TITLE: 'Zum Abschluss: Wie interessant sind für dich in Zukunft folgende Themen?',
    SIGN_UP_UNAVAILABLE_MODULE_RATING_SUB_TITLE:
        'Bitte gewichte die Themen nach ihrer Wichtigkeit für dich persönlich.',
    SIGN_UP_UNAVAILABLE_MODULE_RATING_SUB_INFO:
        'In welche Richtung sich diese Software künftig weiterentwickeln soll, liegt auch an dir und deinen Interessen. Gib dem Management und dem Projektteam, das diese Plattform betreut, ein (vollständig anonymisiertes) Feedback, welche künftigen Funktionen du dir am meisten wünscht.',

    SIGN_UP_NEXT_STEP_TITLE: 'Großartig - Du hast dein Profil erfolgreich erstellt!',
    SIGN_UP_NEXT_STEP_CONTENT_TITLE: 'Womit möchtest du jetzt starten?',
    SIGN_UP_NEXT_STEP_JOBS_TILE_TITLE: 'Jobs & Projekte',
    SIGN_UP_NEXT_STEP_JOBS_TILE_TEXT: 'Aktuelle Angebote ansehen oder eigene ergänzen.',
    SIGN_UP_NEXT_STEP_JOBS_TILE_BUTTON: 'Zu den Jobs',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_TITLE: 'Mein fertiges Profil ansehen',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_TEXT: 'Das eigene Profil ansehen und ggf. anpassen.',
    SIGN_UP_NEXT_STEP_PROFILE_TILE_BUTTON: 'Zum Profil',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_TITLE: 'Meine Kolleg*innen',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_TEXT: 'Matches anzeigen oder passende Kolleg*innen suchen.',
    SIGN_UP_NEXT_STEP_MATCHES_TILE_BUTTON: 'Zu den Kolleg*innen',
    SIGN_UP_NEXT_STEP_HELP_LABEL: 'Keine Ahnung.',
    SIGN_UP_NEXT_STEP_HELP_LABEL_LINK: 'Erstmal zum Dashboard.',

    SIGN_UP_THANK_YOU_HEADLINE: 'Großartig - du hast dein Profil erfolgreich erstellt',
    SIGN_UP_THANK_YOU_SUBLINE: 'Womit möchtest du jetzt starten?',
    SIGN_UP_THANK_YOU_CARD_1_TITLE: 'Fragen zur Arbeitsweise',
    SIGN_UP_THANK_YOU_CARD_1_CONTENT: 'Was ist dir in der Zusammenarbeit wichtig?',
    SIGN_UP_THANK_YOU_CARD_1_ACTION: 'Zum Fragebogen',
    SIGN_UP_THANK_YOU_CARD_2_TITLE: 'Mein fertiges Profil ansehen',
    SIGN_UP_THANK_YOU_CARD_2_CONTENT: 'Mein Profil ansehen und ggf. anpassen.',
    SIGN_UP_THANK_YOU_CARD_2_ACTION: 'Zum Profil',
    SIGN_UP_THANK_YOU_CARD_3_TITLE: 'Direkt zu meinen Matches',
    SIGN_UP_THANK_YOU_CARD_3_CONTENT: 'Passende Kollegen suchen und Matches anzeigen.',
    SIGN_UP_THANK_YOU_CARD_3_ACTION: 'Zu den Matches',
    SIGN_UP_THANK_YOU_NO_IDEA: 'Keine Ahnung?',
    SIGN_UP_THANK_YOU_TO_DASHBOARD: 'Erstmal zum Dashboard',

    SIGN_UP_JOB_DEFAULT_DESCRIPTION:
        'Liebe Kollegin, lieber Kollege!\n\nSchön, dass du hier bist und vielen Dank für dein Interesse! Da dieses Angebot während meiner Registrierung bei {{ appName }} automatisch erstellt wurde, gibt es noch keine ausführlichere Beschreibung.\n\nWenn dir die Schlagworte zusagen, würde ich mich aber schon jetzt sehr über eine Kontaktaufnahme und einen Austausch freuen. Via Nachrichten-Button kannst du mich direkt erreichen. Ich werde dann auch per Mail benachrichtigt.',

    COLLEAGUES_MATCHING_ADD_TOPIC: 'Interesse hinzufügen',

    JOB_BOARD_TITLE: 'Job Board',
    JOB_BOARD_SUB_TITLE: 'Hier findest du Positionen, die zum Teilen geeignet sind.',
    JOB_BOARD_ADD_JOB_BUTTON_TEXT: 'Job hinzufügen',
    JOB_BOARD_ADD_JOB_BTN_ARIA_LABEL: 'Zum Formular der Erstellung des Jobsharing Inserats',
    JOB_BOARD_ADD_JOB_INFO_BUTTON_TEXT: 'Wann kann ich Jobs ausschreiben?',
    JOB_BOARD_ADD_JOB_INFO_BTN_ARIA_LABEL: 'Information: Wann kann ich Jobsharing Inserate ausschreiben?',
    JOB_BOARD_STATUS_PUBLISHED: 'Veröffentlicht',
    JOB_BOARD_STATUS_UNPUBLISHED: 'Unveröffentlicht',
    JOB_BOARD_STATUS_DEACTIVATED: 'Deaktiviert',
    JOB_BOARD_STATUS_DEACTIVATED_TOOLTIP: 'Daektiviert nach Ablauf der Zeit. Zum Aktivieren bearbeiten.',
    JOB_BOARD_DAYS_LEFT: 'Tage verbleibend',
    JOB_BOARD_VIEW_DETAILS_BUTTON_TEXT: 'Details ansehen',
    JOB_BOARD_VIEW_DETAILS_BTN_ARIA_LABEL: 'Details ansehen des Jobsharing Inserats',
    JOB_BOARD_PUBLISH_BUTTON_TEXT: 'Veröffentlichen',
    JOB_BOARD_PUBLISH_BTN_ARIA_LABEL: 'Jobsharing Inserat veröffentlichen',
    JOB_BOARD_JOB_KEYWORDS: 'Schlagworte:',
    JOB_BOARD_JOB_DESCRIPTION: 'Jobbeschreibung:',
    JOB_BOARD_JOB_MESSAGE_BUTTON_TEXT: 'Nachricht',

    JOB_INFO_PANEL_LOCATION_ARIA_LABEL: 'Standort',
    JOB_INFO_PANEL_JOB_BOARD_DAYS_LEFT_ARIA_LABEL: 'Verbleibende Zeit des Inserats',
    JOB_INFO_PANEL_STATUS_ARIA_LABEL: 'Status',

    JOB_BOARD_LIST_JOB_TILE_JOB_DELETE_DIALOG_HEADER: 'Job löschen',
    JOB_BOARD_LIST_JOB_TILE_JOB_DELETE_DIALOG_CONTENT: 'Bist du dir sicher, dass du diesen Job löschen möchtest?',

    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_TITLE: 'Schön, dass du da bist!',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_TITLE: 'Bevor du loslegst, wollen wir dich auf drei Dinge hinweisen',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_1:
        'Du solltest sicherstellen, dass die Position alle Voraussetzungen erfüllt, um im Tandem ausgeführt zu werden und vom zuständigen Hiring Manager für Jobsharing freigegeben ist.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_2:
        'Die Kurzbeschreibung sollte den Link zur vollständigen Stellenausschreibung im Intranet beinhalten.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_OPTIONS_3:
        'Um sicherzustellen, dass alle Stellen aktuell sind, wird die Ausschreibung nach 8 Wochen gelöscht. Bitte verlängere den Gültigkeitszeitraum, wenn nötig.',
    JOB_BOARD_ADD_JOB_FUNNEL_WELCOME_STEP_THANK_YOU_TITLE: 'Danke!',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_SUBTITLE: 'Wähle einen Jobtitel, der so aussagekräftig wie möglich ist.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_TITLE: 'Jobtitel',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_WRITE_JOB_TITLE: 'Dein Jobtitel',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_JOB_TITLE_TOO_SHORT: 'Der Titel muss mindestens 3 Zeichen lang sein.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY: 'Standort',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY_ARIA_LABEL: 'Standort des Jobs',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_CITY_PLACEHOLDER: 'z.B. Berlin',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL: 'Aktiv bis (maximal 8 Wochen)',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_PLACEHOLDER: 'Wähle ein Datum',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_SUBTITLE: 'Gib ein Datum an',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_VALID_UNTIL_NOT_IN_RANGE:
        'Das Datum muss innerhalb der nächsten 8 Wochen sein.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS: 'Relevantes Wissen und Kompetenzen (Schlagworte)',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS_SUBTITLE:
        'Gib hier die wichtigsten Schlagwörter zu deinem Job an und trenne die einzelnen Begriffe durch "Enter".',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_SKILLS_PLACEHOLDER: 'z.B. Controlling',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_INFO:
        'Du möchtest hier eine bestehende Stellenbeschreibung einfügen? Dann kopiere bitte das Wordformat DOCX und füge es ein. Bei Problemen sende uns bitte eine E-Mail an support@tandemploy.com.',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION: 'Jobbeschreibung',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_PLACEHOLDER:
        'Beschreibe deinen Job in deinen eigenen Worten. Was ist das Besondere daran? Was könnten Aufgaben für deinen Tandempartner sein? ...',
    JOB_BOARD_ADD_JOB_FUNNEL_ADD_JOB_STEP_DESCRIPTION_TITLE_TOO_SHORT:
        'Die Beschreibung muss mindestens 3 Zeichen lang sein.',
    JOB_BOARD_ADD_JOB_BUTTON_INFO_POPUP_CONTENT:
        'Alle Jobs, die hier ausgeschrieben sind, müssen vom Hiring Manager freigegeben sein.',

    JOB_BOARD_FUNNEL_NEXT_BTN_ARIA_LABEL: 'Weiter zur Eingabemaske',
    JOB_BOARD_FUNNEL_COMPLETE_BTN_ARIA_LABEL: 'Jobsharing Inserat speichern',

    CONFIRM_CLOSE_DIALOG_HEADER: 'Alle Änderungen verwerfen?',
    CONFIRM_CLOSE_DIALOG_CONTENT: 'Alle neuen und geänderten Daten gehen verloren!',
    CONFIRM_CLOSE_DIALOG_CONFIRM_BUTTON: 'Bestätigen',

    HEADER_BACK_TO_INDEX_LABEL: 'zurück',
    HEADER_BACK_TO_MATCHING_PAGE_ARIA_LABEL: 'Zurück zur Matching-Seite',
    HEADER_BACK_TO_SEARCH_PAGE_ARIA_LABEL: 'Zurück zur Suche',

    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_REMOVE_FROM_CANDIDATES: 'Von Kandidaten entfernen',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_GO_TO_CANDIDATES_LIST: 'Kandidatenliste ansehen',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_INFO: 'Was heißt das?',
    TANDEM_CANDIDATE_ACTIONS_BTN_ADD_AS_CANDIDATE: 'Zu Kandidaten hinzufügen',
    TANDEM_CANDIDATE_ACTIONS_BTN_IS_A_CANDIDATE: 'Ist ein Kandidat',
    TANDEM_CANDIDATE_ACTIONS_BTN_MENU_ITEM_INFO_DIALOG_MESSAGE:
        'Zu "Tandem-Kandidaten" kannst du Jobsharer*innen hinzufügen, die dich besonders interessieren und die du später wieder finden willst. Sie kommen auf eine "Short List", wo dir bestimmte Interaktionen, wie z.B. "Tandem-Profil anzeigen", zur Verfügung stehen.',

    FOOTER_NAVIGATION_BACK_TO_RESULTS: 'Zurück zu den Ergebnissen',
    FOOTER_NAVIGATION_NEXT_BUTTON_CAPTION: 'Nächstes Profil',
    FOOTER_NAVIGATION_NEXT_BTN_ARIA_LABEL: 'Zum nächsten Profil von {{userName}}',
    FOOTER_NAVIGATION_PREV_BUTTON_CAPTION: 'Letztes Profil',
    FOOTER_NAVIGATION_PREV_BTN_ARIA_LABEL: 'Zum letzten Profil von {{userName}}',
    PAGING_NAVIGATION: 'Seitennavigation',
    NAVIGATION_MENU: 'Navigationsmenü',

    PROMPT_JOBSHARING_INFO:
        'Im Rahmen des letzten Plattform-Updates wurde auch das Jobsharing-Matching verbessert. Bitte bestätige die Angaben zum Standort, um ein funktionierendes Matching zu ermöglichen.',

    SIDENAV_MY_MATCHES: 'MEINE MATCHES',
    SIDENAV_ADD_MORE_ALT_TEXT: 'Mehr Interessen hinzufügen',
    SIDENAV_SHOW_MORE_TOPICS: 'Alle anzeigen...',
    SIDENAV_SHOW_LESS_TOPICS: 'Weniger anzeigen...',
    SIDENAV_TOP_LINKS: 'PERSÖNLICHES',
    SIDENAV_BOTTOM_LINKS: 'WEITERES',
    SIDENAV_LOGO_ALT_TEXT: 'Logo von {{ appName }}',
    SIDENAV_AVATAR_ALT_TEXT: 'Zum eigenen Profil',

    DIALOG_HELP: 'Weitere Informationen und Hilfe',
    REMOVE_TANDEM: 'Tandem-Partner entfernen',
    TANDEM_ARIA_LABEL: 'Zum Tandemprofil mit {{userName}}',
    GO_TO_HOMEPAGE_BTN_ARIA_LABEL: 'Startseite Home',
    MESSAGES: 'Zu meinen Nachrichten',
    EDIT_TOPIC: 'Angaben zum Thema {{topic}} bearbeiten',
    OPEN_TOPIC_ADD_MODAL_BTN_ARIA_LABEL: 'Weitere Themen hinzufügen, die mich interessieren',
    SEND_MESSAGE: 'Nachricht senden',

    POWERED_BY_LINK_ARIA_LABEL: 'Weiterleitung zu der Homepage von Tandemploy',
    CHANGE_LANGUAGE_TO_EN: 'Sprache wechseln zu Englisch',
    CHANGE_LANGUAGE_TO_DE: 'Sprache wechseln zu Deutsch',

    DATEPICKER_DIALOG_ARIA_LABEL: 'Datum aus Kalender auswählen',

    INFO_TOGGLE_DIALOG_DEFAULT_HEADER: 'Information',
    INFO_TOGGLE_DIALOG_ANONYMITY_HEADER: 'Information zur Anonymität',

    PROFILE_EDIT_BTN_ARIA_LABEL: 'Profildetails im Abschnitt "Über mich” bearbeiten"',
    PROFILE_QUESTIONNAIRE_EDIT_BTN_ARIA_LABEL: 'Profildetails im Abschnitt "Mehr über mich" bearbeiten',
    PROFILE_QUESTIONNAIRE_ADD_BTN_ARIA_LABEL: 'Profildetails zum Abschnitt "Mehr über mich" hinzufügen',
    PROFILE_WORK_EDIT_BTN_ARIA_LABEL: 'Profildetails im Abschnitt "Berufserfahrung" bearbeiten',
    PROFILE_WORK_ADD_BTN_ARIA_LABEL: 'Profildetails zum Abschnitt "Berufserfahrung" hinzufügen',
    PROFILE_EDIT_TOPIC_BTN_ARIA_LABEL: 'Profildetails zum Thema {{topic}} bearbeiten',
    PROFILE_DELETE_TOPIC_BTN_ARIA_LABEL: '{{topic}} deaktivieren und keine Matches erhalten',
    PROFILE_EXPANSION_PANEL_HEADER_ARIA_LABEL: 'Detailangaben zum Thema {{topic}} ansehen',

    SEARCH_BTN_TOOLTIP: 'Suche starten',
    SEARCH_RESULT_ARIA_LABEL: 'Zur Profilansicht von {{userName}}',
    TOPIC_SELECTOR_LABEL: 'Wähle das Matching-Thema, das du durchsuchen willst.',
    SEARCH_RESULT_TAGS_ARIA_LABEL: 'Ein Label, Schlagwort oder andere Information zu diesem Suchergebnis.',

    MATCHING_RESULT_ARIA_LABEL: 'Zur Profilansicht von {{userName}}',
    MATCHING_RESULT_TAGS_ARIA_LABEL: 'Ein Label, Schlagwort oder andere Information zu diesem Matches.',

    JOB_PUBLISH_BTN_ARIA_LABEL: 'Jobsharing Inserat veröffentlichen',
    JOB_UNPUBLISH_BTN_ARIA_LABEL: 'Jobsharing Inserat deaktivieren',
    JOB_EDIT_BTN_ARIA_LABEL: 'Jobsharing Inserat bearbeiten',
    JOB_DELETE_BTN_ARIA_LABEL: 'Jobsharing Inserat löschen',
    JOB_DETAILS_MODAL_CLOSE_BTN_ARIA_LABEL: 'Jobsharing Inserat schließen',

    JOB_PUBLISH_BTN_LABEL: 'Job veröffentlichen',
    JOB_UNPUBLISH_BTN_LABEL: 'Job deaktivieren',
    JOB_EDIT_BTN_LABEL: 'Job bearbeiten',
    JOB_DELETE_BTN_LABEL: 'Job löschen',

    DIRECT_MESSAGES_SEND_WITH_INDICATOR_TEXT: 'Jetzt senden',
    DIRECT_MESSAGES_NO_CONTACTS_OF_THIS_TYPE: 'Noch keine Kontakte',
    DIRECT_MESSAGES_CONVERSATIONS_LOADING_MESSAGE: 'Nachrichten werden geladen',
    DIRECT_MESSAGES_CONVERSATION_LOADING_MESSAGE: 'Nachricht wird geladen',
    DIRECT_MESSAGES_PARTICIPANTS: 'Teilnehmer',
    DIRECT_MESSAGES_NO_CONVERSATION_SELECTED_MESSAGE: 'Bitte Konversation wählen',
    DIRECT_MESSAGES_NO_MESSAGE_IN_CONVERSATION: 'Aktuell noch keine Nachricht',
    DIRECT_MESSAGES_HEADER: 'Persönliche Nachrichten',
    DIRECT_MESSAGES_FROM: 'aus',
    DIRECT_MESSAGES_GROUP_CONVERSATION_HEADER_TEXT: 'Gruppennachrichten',
    DIRECT_MESSAGES_PRIVATE_CONVERSATION_HEADER_TEXT: 'Chats',
    DIRECT_MESSAGES_ENTER_MESSAGE_PLACEHOLDER: 'Nachricht an',
    DIRECT_MESSAGES_SNACK_NEW_MESSAGE: '{{ userName }} hat eine neue Nachricht geschickt',
    DIRECT_MESSAGES_CONVERSATION_PRIVATE: 'Privatchat: ',
    ORIGIN_GLOBAL_SEARCH: 'Suche',
    ORIGIN_TOPIC_STA: 'Kurzeinsätze',
    ORIGIN_STA: 'Kurzeinsätze',
    ORIGIN_TOPIC_WORKSHOP: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOP: 'Workshops',
    ORIGIN_WORKSHOPS: 'Workshops',
    ORIGIN_BOOKMARKS: 'gemerkte Kollegen',
    ORIGIN_TOPIC_EXPERTS: 'Expertenfinder',
    ORIGIN_EXPERTS: 'Expertenfinder',
    ORIGIN_TOPIC_JOB_SHADOWING: 'Job Shadowing',
    ORIGIN_JOB_SHADOWING: 'Job Shadowing',
    ORIGIN_TOPIC_NLA: 'Never Lunch Alone',
    ORIGIN_NLA: 'Never Lunch Alone',
    ORIGIN_TOPIC_MENTORING: 'Mentoring',
    ORIGIN_MENTORING: 'Mentoring',
    ORIGIN_TOPIC_PROJECTS: 'Projekte',
    ORIGIN_PROJECTS: 'Projekte',
    ORIGIN_JOB_SHARING: 'Jobsharing',
    ORIGIN_GENERATION_EXCHANGE: 'Generation Exchange',
    ORIGIN_TOPIC_JOB_SHARING: 'Jobsharing',
    GLOBAL_SEARCH_SEARCH_LABEL: 'User suchen',
    DIRECT_MESSAGES_CONVERSATION_MUTED_NOTIFICATION: 'Stummschaltung aktiv',
    DIRECT_MESSAGES_CONVERSATION_UNMUTED_NOTIFICATION: 'Stummschaltung inaktiv',
    DIRECT_MESSAGES_MUTE_CONVERSATION_CONFIRM_TEXT:
        'Hinweise auf neue Nachrichten in diesem Chat künftig stumm schalten?',
    DIRECT_MESSAGES_MUTE_CONVERSATION_BUTTON_TEXT: 'Stumm schalten',
    DIRECT_MESSAGES_MUTE_CONVERSATION_CONFIRMATION_HEADER: 'Benachrichtigungen stumm schalten',
    DIRECT_MESSAGES_NO_UNREAD_MESSAGES: 'Aktuell keine ungelesenen Nachrichten.',
    DIRECT_MESSAGES_MESSAGE_CANT_BE_EMPTY: 'Nachrichtenfeld ist leer!',
    DIRECT_MESSAGES_MUTE_CONVERSATION: 'Benachrichtigungen ausgeschaltet',
    DIRECT_MESSAGES_UNMUTE_CONVERSATION: 'Benachrichtigungen eingeschaltet',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS: 'Teilnehmer bearbeiten',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS_HELPER_TEXT:
        'Hier kannst du eine andere Person bestimmen, die den Chat leiten soll.',
    DIRECT_MESSAGES_MANAGE_PARTICIPANTS_NO_SEARCH_RESULTS: 'Es konnten keine User gefunden werden.',
    DIRECT_MESSAGES_DISABLE_WRITE_PERMISSIONS: 'Künftig nur mich schreiben lassen',
    DIRECT_MESSAGES_ENABLE_WRITE_PERMISSIONS: 'Künftig alle schreiben lassen',
    DIRECT_MESSAGES_WRITE_PERMISSIONS_DISABLED: 'Ab jetzt kannst nur noch du in diesen Chat schreiben.',
    DIRECT_MESSAGES_CONVERSATION_NO_WRITE_ACCESS:
        'Du kannst derzeit keine Nachrichten in dieser Unterhaltung schreiben.',
    DIRECT_MESSAGES_NO_CONVERSATIONS_YET_1: 'Noch keine Konversation',
    DIRECT_MESSAGES_NO_CONVERSATIONS_YET_2: 'Starte hier deinen ersten Chat!',
    DIRECT_MESSAGES_VISIT_PROFILE: 'Profil des Users besuchen',
    DIRECT_MESSAGES_ADD_OWNER: 'Diese Person auswählen',
    DIRECT_MESSAGES_REMOVE_OWNER: 'Soll Chat nicht länger verwalten',
    DIRECT_MESSAGES_OPEN_MENU_LABEL: 'Chatmenu öffnen',
    DELETED_USER: 'Profil gelöscht',
    GO_TO_HOMEPAGE: 'Zur Startseite',

    EN: 'Englisch',
    FR: 'Französisch',
    DE: 'Deutsch',
    IT: 'Italienisch',
    PT: 'Portugiesisch',
    RU: 'Russisch',
    ES: 'Spanisch',
    AA: 'Afar',
    AB: 'Abchasisch',
    AE: 'Avestisch',
    AF: 'Afrikaans',
    AK: 'Akan',
    AM: 'Amharisch',
    AN: 'Aragonesisch',
    AR: 'Arabisch',
    AS: 'Assamesisch',
    AV: 'Avarisch',
    AY: 'Aymara',
    AZ: 'Aserbaidschanisch',
    BA: 'Baschkirisch',
    BE: 'Weißrussisch',
    BG: 'Bulgarisch',
    BH: 'Bihari',
    BI: 'Bislama',
    BM: 'Bambara',
    BN: 'Bengalisch',
    BO: 'Tibetisch',
    BR: 'Bretonisch',
    BS: 'Bosnisch',
    CA: 'Katalanisch',
    CE: 'Tschetschenisch',
    CH: 'Chamorro',
    CO: 'Korsisch',
    CR: 'Cree',
    CS: 'Tschechisch',
    CU: 'Kirchenslawisch',
    CV: 'Tschuwaschisch',
    CY: 'Walisisch',
    DA: 'Dänisch',
    DV: 'Dhivehi',
    DZ: 'Dzongkha',
    EE: 'Ewe',
    EL: 'Griechisch',
    EO: 'Esperanto',
    ET: 'Estnisch',
    EU: 'Baskisch',
    FA: 'Persisch',
    FF: 'Fulfulde',
    FI: 'Finnisch',
    FJ: 'Fidschi',
    FO: 'Färöisch',
    FY: 'Westfriesisch',
    GA: 'Irisch',
    GD: 'Schottisch-gälisch',
    GL: 'Galicisch',
    GN: 'Guaraní',
    GU: 'Gujarati',
    GV: 'Manx',
    HA: 'Hause',
    HE: 'Hebräisch',
    HI: 'Hindi',
    HO: 'Hiri Motu',
    HR: 'Kroatisch',
    HT: 'Haitianisch',
    HU: 'Ungarisch',
    HY: 'Armenisch',
    HZ: 'Otjiherero',
    IA: 'Interlingua',
    ID: 'Indonesisch',
    IE: 'Interlingue',
    IG: 'Igbo',
    II: 'Yi',
    IK: 'Inupiaq',
    IO: 'Ido',
    IS: 'Isländisch',
    IU: 'Inukitut',
    JA: 'Japanisch',
    JV: 'Javanisch',
    KA: 'Georgisch',
    KG: 'Kikongo',
    KI: 'Kikuyu',
    KJ: 'oshiKwanyama',
    KK: 'Kasachisch',
    KL: 'Grönländisch',
    KM: 'Khmer',
    KN: 'Kannada',
    KO: 'Koreanisch',
    KR: 'Kanuri',
    KS: 'Kashmiri',
    KU: 'Kurdisch',
    KV: 'Komi',
    KW: 'Kornisch',
    KY: 'Kirgisisch',
    LA: 'Latein',
    LB: 'Luxemburgisch',
    LG: 'Luganda',
    LI: 'Limburgisch',
    LN: 'Lingála',
    LO: 'Laotisch',
    LT: 'Litauisch',
    LU: 'Kiluba',
    LV: 'Lettisch',
    MG: 'Malagasy',
    MH: 'Marshallesisch',
    MI: 'Maori',
    MK: 'Mazedonisch',
    ML: 'Malayalam',
    MN: 'Mongolisch',
    MR: 'Marathi',
    MS: 'Malaiisch',
    MT: 'Maltesisch',
    MY: 'Birmanisch',
    NA: 'Nauruisch',
    NB: 'Bokmål',
    ND: 'Nord-Ndebele',
    NE: 'Nepali',
    NG: 'Ndonga',
    NL: 'Niederländisch',
    NN: 'Nynorsk',
    NO: 'Norwegisch',
    NR: 'Süd Ndebele',
    NV: 'Navajo',
    NY: 'Chichewa',
    OC: 'Okzitanisch',
    OJ: 'Ojibwe',
    OM: 'Oromo',
    OR: 'Oriya',
    OS: 'Ossetisch',
    PA: 'Panjabi',
    PI: 'Pali',
    PL: 'Polnisch',
    PS: 'Paschtunisch',
    QU: 'Quechua',
    RM: 'Bündnerromanisch',
    RN: 'Kirundi',
    RO: 'Rumänisch',
    RW: 'Kinyarwanda',
    SA: 'Sanskrit',
    SC: 'Sardisch',
    SD: 'Sindhi',
    SE: 'Nordsamisch',
    SG: 'Sango',
    SI: 'Singhalesisch',
    SK: 'Slowakisch',
    SL: 'Slowenisch',
    SM: 'Samoanisch',
    SN: 'Shona',
    SO: 'Somali',
    SQ: 'Albanisch',
    SR: 'Serbisch',
    SS: 'Siswati',
    ST: 'Sesotho',
    SU: 'Sundanesisch',
    SV: 'Schwedisch',
    SW: 'Swahili',
    TA: 'Tamil',
    TE: 'Telugu',
    TG: 'Tadschikisch',
    TH: 'Thai',
    TI: 'Tigrinya',
    TK: 'Turkmenisch',
    TL: 'Tagalog',
    TN: 'Setswana',
    TO: 'Tongaisch',
    TR: 'Türkisch',
    TS: 'Xitsonga',
    TT: 'Tatarisch',
    TW: 'Twi',
    TY: 'Tahitianisch',
    UG: 'Uigurisch',
    UK: 'Ukrainisch',
    UR: 'Urdu',
    UZ: 'Usbekisch',
    VE: 'Tshivenda',
    VI: 'Vietnamesisch',
    VO: 'Volapük',
    WA: 'Wallonisch',
    WO: 'Wolof',
    XH: 'isiXhosa',
    YI: 'Jiddisch',
    YO: 'Yoruba',
    ZA: 'Zhuang',
    ZH: 'Chinesisch',
    ZU: 'isiZulu',

    LOCATION_GLOBAL: 'Weltweit',
    LOCATION_DACH: 'DACH',
    LOCATION_EU: 'Europa',
    LOCATION_AS: 'Asien',
    LOCATION_AF: 'Afrika',
    LOCATION_DE: 'Deutschland',
    LOCATION_CH: 'Schweiz',
    LOCATION_AT: 'Österreich',
    LOCATION_GRZ: 'Graz',
    LOCATION_LNZ: 'Linz',
    LOCATION_VIE: 'Wien',
    LOCATION_FR: 'Frankreich',
    LOCATION_FI: 'Finnland',
    LOCATION_EG: 'Ägypten',
    LOCATION_EC: 'Ecuador',
    LOCATION_DK: 'Dänemark',
    LOCATION_CZ: 'Tschechische Republik',
    LOCATION_HR: 'Kroatien',
    LOCATION_CN: 'China',
    LOCATION_CL: 'Chile',
    LOCATION_CA: 'Kanada',
    LOCATION_CO: 'Kolumbien',
    LOCATION_BR: 'Brasilien',
    LOCATION_BE: 'Belgien',
    LOCATION_BA: 'Bosnien und Herzegowina',
    LOCATION_BD: 'Bangladesch',
    LOCATION_AU: 'Australien',
    LOCATION_AR: 'Argentinien',
    LOCATION_GT: 'Guatemala',
    LOCATION_HU: 'Ungarn',
    LOCATION_IN: 'Indien',
    LOCATION_ID: 'Indonesien',
    LOCATION_IT: 'Italien',
    LOCATION_JP: 'Japan',
    LOCATION_JO: 'Jordanien',
    LOCATION_LU: 'Luxemburg',
    LOCATION_MA: 'Marokko',
    LOCATION_MY: 'Malaysia',
    LOCATION_MX: 'Mexiko',
    LOCATION_CK: 'Neuseeland',
    LOCATION_NO: 'Norwegen',
    LOCATION_PK: 'Pakistan',
    LOCATION_PH: 'Philippinen',
    LOCATION_PL: 'Polen',
    LOCATION_PT: 'Portugal',
    LOCATION_RO: 'Rumänien',
    LOCATION_RU: 'Russland',
    LOCATION_SA: 'Saudi-Arabien',
    LOCATION_SG: 'Singapur',
    LOCATION_SK: 'Slowakei',
    LOCATION_ZA: 'Südafrika',
    LOCATION_KR: 'Südkorea',
    LOCATION_ES: 'Spanien',
    LOCATION_SE: 'Schweden',
    LOCATION_TW: 'Taiwan',
    LOCATION_TH: 'Thailand',
    LOCATION_NL: 'Niederlande',
    LOCATION_TR: 'Türkei',
    LOCATION_UA: 'Ukraine',
    LOCATION_US: 'USA',
    LOCATION_AE: 'Vereinigte Arabische Emirate',
    LOCATION_GB: 'Vereinigte Königreich',
    LOCATION_ET: 'Estland',
    LOCATION_OTHER: 'Weitere',
    LOCATION_SALES: 'Sales/Außenbüros',

    ADMIN_MESSAGES_TAB: 'Mailings',
    ADMIN_MESSAGES_HEADER: 'Mailings',
    ADMIN_MESSAGES_COLUMN_DATE: 'Datum',
    ADMIN_MESSAGES_COLUMN_SUBJECT: 'Betreff',
    ADMIN_MESSAGES_COLUMN_TO: 'An',
    ADMIN_MESSAGES_COLUMN_AUTHOR: 'Autor',
    ADMIN_MESSAGES_COLUMN_ACTIONS: 'Aktionen',
    ADMIN_MESSAGES_DRAFT: 'Entwurf',
    ADMIN_MESSAGES_GROUP_ALL: 'Alle User',
    ADMIN_MESSAGES_BTN_SEND_NEW: 'Neue E-Mail senden',
    ADMIN_MESSAGES_FORM_TILE_CREATE: 'Neue Nachricht',
    ADMIN_MESSAGES_FORM_LABEL_GROUP: 'Usergruppe',
    ADMIN_MESSAGES_FORM_LABEL_LOCATIONS: 'Standorte',
    ADMIN_MESSAGES_FORM_LABEL_COMPANY: 'Tochterunternehmen',
    ADMIN_MESSAGES_FORM_LABEL_SUBJECT: 'Betreff',
    ADMIN_MESSAGES_FORM_LABEL_CONTENT: 'Nachricht',
    ADMIN_MESSAGES_BTN_SAVE: 'Speichern und später senden',
    ADMIN_MESSAGES_BTN_SEND: 'Jetzt senden',
    ADMIN_MESSAGES_CONFIRM_SEND_TITLE: 'Nachricht jetzt senden?',
    ADMIN_MESSAGES_CONFIRM_SEND_CONTENT:
        'Wenn du “Jetzt senden” klickst, wird unwiderrufbar eine Nachricht an alle Mitglieder der gewählten Usergruppe geschickt.',
    ADMIN_MESSAGES_CONFIRM_SEND_BTN_YES: 'Ja, jetzt senden!',
    ADMIN_MESSAGES_ENTITY_VIEW_TITLE: 'Inhalt der versendeten Nachricht',
    ADMIN_MESSAGES_ENTITY_EDIT_TITLE: 'Nachricht bearbeiten',

    ADMIN_STATISTICS_CUSTOM_REPORTS_PROJECTS_LABEL: 'Report Projekte',
    ADMIN_STATISTICS_CUSTOM_REPORTS_STA_LABEL: 'Report Kurzeinsätze',
    ADMIN_STATISTICS_CUSTOM_REPORTS_WORKSHOPS_LABEL: 'Report Workshops',
    ADMIN_STATISTICS_CUSTOM_REPORTS_FILE_TYPE_LABEL: '(CSV)',

    ADMIN_ROLES_TABLE_COL_DATE: 'Datum',
    ADMIN_ROLES_TABLE_COL_EMAIL: 'E-Mail Nutzer*in',
    ADMIN_ROLES_TABLE_COL_STATISTICS: 'Statistiken',
    ADMIN_ROLES_TABLE_COL_ADMIN_MAIL: 'Mailings',
    ADMIN_ROLES_TABLE_COL_STAFFING: 'Staffing',
    ADMIN_ROLES_TABLE_COL_ADMIN: 'Admin',
    ADMIN_ROLES_HEADER: 'Rechteverwaltung',
    ADMIN_ROLES_SUB_HEADER:
        'Hier kannst du festlegen, welche Nutzer*innen spezielle Rechte bekommen sollen. Folgende Rechte können vergeben werden:',
    ADMIN_ROLES_DETAILS_STATISTICS:
        'Nutzer*innen mit diesem Recht können das Statistik-Dashboard aufrufen und aggregierte Auswertungen zur Nutzung der Plattform einsehen.',
    ADMIN_ROLES_DETAILS_ADMIN_MAIL:
        'Nutzer*innen mit diesem Recht können die „Mailings” Seite aufrufen und von dort aus Broadcast-Nachrichten an alle Nutzer*innen oder bestimmte User-Untergruppen versenden.',
    ADMIN_ROLES_DETAILS_STAFFING:
        'Nutzer*innen mit dieser Berechtigung können die Anbieter*innen von {{ projects }} und {{ stas }} beim Staffing unterstützen. Sie können zu allen bestehenden Angeboten die bisherigen Bewerber*innen und deren aktuellen Bewerbungsstatus einsehen und weitere Vorschläge für passende Kandidat*innen machen. Dafür steht ihnen eine eigene Seite auf der Plattform zur Verfügung.',
    ADMIN_ROLES_DETAILS_ADMIN:
        'Nutzer*innen mit diesem Recht erhalten alle oben genannten Rechte. Außerdem können sie diese Seite - “Plattform Administration” - aufrufen und die Rechteverwaltung für andere Nutzer*innen durchführen.',
    ADMIN_ROLES_EMAIL_NOT_VALID: 'Diese E-Mail ist ungültig',
    ADMIN_ROLES_SELECT_AT_LAST_ONE_ROLE: 'Wähle mindestens eine Berechtigung',
    ADMIN_ROLES_EMAIL_USER_NOT_FOUND: 'Ein Benutzer mit dieser E-Mail existiert nicht',
    ADMIN_ROLES_ADD_ROLE_UNEXPECTED_ERROR: 'Ein unerwarteter Fehler ist aufgetreten. Bitte lade die Seite erneut.',
    ADMIN_ROLES_BTN_ADD_ROLE: 'Nutzer*in hinzufügen',
    ADMIN_ROLES_BTN_SAVE: 'Änderungen speichern',
    ADMIN_ROLES_NOTIFICATION_ADD_SUCCESS: 'Der User wurde hinzugefügt.',
    ADMIN_ROLES_NOTIFICATION_SAVE_SUCCESS: 'Deine Änderungen wurden gespeichert.',

    ADD_PROFILE_TEXT: 'Fast geschafft! Aktiviere dieses Matching-Modul mit ein paar weiteren Informationen.',
    ADD_PROFILE: 'Jetzt loslegen',

    PAGINATOR_OF: 'von',
    PAGINATOR_ITEMS_PER_PAGE: 'Ergebnisse pro Seite',
    PAGINATOR_NEXT_PAGE: 'Nächste Seite',
    PAGINATOR_PREVIOUS_PAGE: 'Vorherige Seite',
    PAGINATOR_FIRST_PAGE: 'Erste Seite',
    PAGINATOR_LAST_PAGE: 'Letzte Seite',
    PAGINATOR_NEXT: 'Weitere',

    SAVE_AND_FINISH_LATER: 'Speichern und später fortfahren',
    QUESTIONNAIRE_BTN_START: 'Fragebogen starten',
    QUESTIONNAIRE_EMPTY_RADIO_OPTION: 'Nichts davon zutreffend.',

    QUESTIONNAIRE_START_HEADER: 'Arbeit im Tandem: damit die Chemie stimmt.',
    QUESTIONNAIRE_START_BODY_TEXT:
        'Um auch "weiche Faktoren" rund um die Arbeitsweise und persönliche Präferenzen beim Matching von Kolleg*innen zu berücksichtigen, findest du hier einen ca. 5-minütigen Fragebogen. Es gibt kein Richtig und kein Falsch! Alle Fragen sind freiwillig und können übersprungen werden.\n\nDeine Antworten dienen vor allem einem feineren Matching im Hintergrund, einige Antworten werden auch auf deinem Profil für Kolleg*innen sichtbar. Wenn du das lieber nicht möchtest, kannst du deine Angaben im Anschluss unter "Benutzereinstellungen > Anonymität" verbergen.',

    QUESTIONNAIRE_1_HEADER: 'Du erhoffst dir durch flexible Arbeit mehr Zeit für…',
    QUESTIONNAIRE_1_ANSWER_1: '… die Familie',
    QUESTIONNAIRE_1_ANSWER_2: '… eigene Projekte und weitere Jobs',
    QUESTIONNAIRE_1_ANSWER_3: '… eine Weiterbildung',
    QUESTIONNAIRE_1_ANSWER_4: '… ehrenamtliches Engagement',
    QUESTIONNAIRE_1_ANSWER_5: '… die Pflege Angehöriger',
    QUESTIONNAIRE_1_ANSWER_6: '… zeitintensive Hobbies',
    QUESTIONNAIRE_1_ANSWER_7: '… für die eigene Gesundheit',
    QUESTIONNAIRE_1_ANSWER_8: 'sonstiges',

    QUESTIONNAIRE_2_HEADER: 'Ich möchte in meinem Job vor allem...',
    QUESTIONNAIRE_2_ANSWER_1: 'Karriere machen',
    QUESTIONNAIRE_2_ANSWER_2: 'Selbstverwirklichung erreichen und glücklich sein',
    QUESTIONNAIRE_2_ANSWER_3: 'flexibel bleiben und genügend Freizeit haben',
    QUESTIONNAIRE_2_ANSWER_4: 'eine angesehene Position erreichen',
    QUESTIONNAIRE_2_ANSWER_5: 'meinen Lebensunterhalt bestreiten',

    QUESTIONNAIRE_3_HEADER: 'Welche Gedanken gehen dir nach einem glücklichen Arbeitstag durch den Kopf?',
    QUESTIONNAIRE_3_ANSWER_1: 'In meiner Position kann ich wirklich viel bewirken!',
    QUESTIONNAIRE_3_ANSWER_2: 'Es ist schön andere glücklich zu machen!',
    QUESTIONNAIRE_3_ANSWER_3: 'Gut zu wissen, dass mein Chef meine Leistung sieht!',
    QUESTIONNAIRE_3_ANSWER_4: 'Es fühlt sich gut an, etwas Sinnvolles zu tun!',
    QUESTIONNAIRE_3_ANSWER_5: 'Heute hatte ich viel Spaß!',
    QUESTIONNAIRE_3_ANSWER_6: 'Ich liebe mein Team!',
    QUESTIONNAIRE_3_ANSWER_7: 'Toll, dass ich immer noch weiter lernen kann!',
    QUESTIONNAIRE_3_ANSWER_8: 'Ich habe alles geschafft, was ich mir heute vorgenommen habe!',
    QUESTIONNAIRE_3_ANSWER_9: 'Ich darf dieses wichtige Projekt übernehmen - wie aufregend!',

    QUESTIONNAIRE_4_HEADER: 'Welchen Anspruch hast du an deine Arbeit?',
    QUESTIONNAIRE_4_ANSWER_1: 'Ich möchte gute Arbeit leisten, aber Arbeit ist nicht mein Lebensmittelpunkt',
    QUESTIONNAIRE_4_ANSWER_2: 'Ich möchte gute Arbeit leisten, um meinen Job und meine Existenz zu sichern',
    QUESTIONNAIRE_4_ANSWER_3: 'Ich möchte begeistern und gebe immer 100%',

    QUESTIONNAIRE_5_HEADER: 'Deine Freunde würden dich beschreiben als...',
    QUESTIONNAIRE_5_ANSWER_1: '… eher extrovertiert',
    QUESTIONNAIRE_5_ANSWER_2: '… eher introvertiert',

    QUESTIONNAIRE_6_HEADER: 'Was ist dir in der engen Zusammenarbeit mit einem/einer Kolleg*in am wichtigsten?',
    QUESTIONNAIRE_6_ANSWER_1:
        'Gerade in der engen Zusammenarbeit im Team möchte ich über jeden Schritt meines/meiner Kolleg*in informiert sein',
    QUESTIONNAIRE_6_ANSWER_2:
        'Für mich ist es am effizientesten, vorrangig die Meilensteine und Ergebnisse zu besprechen',
    QUESTIONNAIRE_6_ANSWER_3:
        'Mir sind transparente Informationen wichtig, ich muss aber nicht ständig darauf zugreifen',
    QUESTIONNAIRE_SHORT_6_HEADER: 'An engen Kolleg*innen schätze ich',
    QUESTIONNAIRE_SHORT_6_ANSWER_1: 'Transparenz in der Zusammenarbeit',
    QUESTIONNAIRE_SHORT_6_ANSWER_2: 'Effizienz in der Zusammenarbeit',
    QUESTIONNAIRE_SHORT_6_ANSWER_3: 'Eigenverantwortung im Team',

    QUESTIONNAIRE_7_HEADER: 'Du merkst, dass dein/e Kolleg*in mit einer Behauptung falsch liegt. Was tust du?',
    QUESTIONNAIRE_7_ANSWER_1: 'Ich weise sie/ihn direkt darauf hin',
    QUESTIONNAIRE_7_ANSWER_2: 'Ich stelle eine kluge Frage, sodass er/sie selbst drauf kommt',
    QUESTIONNAIRE_7_ANSWER_3: 'Ich halte mich zurück und sage erstmal nichts',
    QUESTIONNAIRE_SHORT_7_HEADER: 'In schwierigen Situationen mit Kolleg*innen bin ich',
    QUESTIONNAIRE_SHORT_7_ANSWER_1: 'direkt',
    QUESTIONNAIRE_SHORT_7_ANSWER_2: 'diplomatisch',
    QUESTIONNAIRE_SHORT_7_ANSWER_3: 'taktvoll',

    QUESTIONNAIRE_8_HEADER:
        'Dir ist ein schwerer Fehler unterlaufen, von dem du deinem Kollegen erzählen musst. Wie gehst du damit um?',
    QUESTIONNAIRE_8_ANSWER_1:
        'Die Situation bedrückt mich und ich brauche ein wenig Zeit, um zu überlegen, wie ich das Thema anspreche',
    QUESTIONNAIRE_8_ANSWER_2:
        'Damit habe ich überhaupt kein Problem, ich stehe zu meinem Fehler und spreche diesen direkt an',
    QUESTIONNAIRE_8_ANSWER_3:
        'Es kostet mich viel Kraft und Überwindung, meinem Kollegen davon zu erzählen, da ich mir meine Fehler oftmals sehr zu Herzen nehme',

    QUESTIONNAIRE_9_HEADER: 'Es naht (wie immer viel zu schnell) eine wichtige Deadline. Sie erledigen alles...',
    QUESTIONNAIRE_9_ANSWER_1: '… 100%ig und bittest um eine kleine Verlängerung',
    QUESTIONNAIRE_9_ANSWER_2: '… 80%ig und pünktlich',
    QUESTIONNAIRE_SHORT_9_HEADER: 'Deadlines erledige ich',
    QUESTIONNAIRE_SHORT_9_ANSWER_1: 'detailverliebt',
    QUESTIONNAIRE_SHORT_9_ANSWER_2: 'pragmatisch',

    QUESTIONNAIRE_10_HEADER: 'Wichtige Routinetätigkeiten, bei denen es auf Genauigkeit ankommt...',
    QUESTIONNAIRE_10_ANSWER_1: '… gebe ich gerne ab',
    QUESTIONNAIRE_10_ANSWER_2: '… führe ich gerne selbst durch',

    QUESTIONNAIRE_11_HEADER: 'Stress am Arbeitsplatz...',
    QUESTIONNAIRE_11_ANSWER_1: '… beflügelt mich',
    QUESTIONNAIRE_11_ANSWER_2: '… brauche ich – zumindest in Maßen',
    QUESTIONNAIRE_11_ANSWER_3: '… finde ich kontraproduktiv',

    QUESTIONNAIRE_12_HEADER: 'Wann bist du meist am produktivsten?',
    QUESTIONNAIRE_12_ANSWER_1: 'morgens',
    QUESTIONNAIRE_12_ANSWER_2: 'mittags',
    QUESTIONNAIRE_12_ANSWER_3: 'nachmittags',
    QUESTIONNAIRE_12_ANSWER_4: 'abends',
    QUESTIONNAIRE_SHORT_12_HEADER: 'Am produktivsten bin ich meistens',
    QUESTIONNAIRE_SHORT_12_ANSWER_1: 'morgens',
    QUESTIONNAIRE_SHORT_12_ANSWER_2: 'mittags',
    QUESTIONNAIRE_SHORT_12_ANSWER_3: 'nachmittags',
    QUESTIONNAIRE_SHORT_12_ANSWER_4: 'abends',

    QUESTIONNAIRE_13_HEADER: 'Wie sieht dein Arbeitsplatz normalerweise aus?',
    QUESTIONNAIRE_13_ANSWER_1: 'tiptop',
    QUESTIONNAIRE_13_ANSWER_2: 'ziemlich okay – ich blicke durch',
    QUESTIONNAIRE_13_ANSWER_3: 'Chaos pur',

    QUESTIONNAIRE_14_HEADER: 'Ich werde am Arbeitsplatz nervöser, wenn ich...',
    QUESTIONNAIRE_14_ANSWER_1: '… zu viele Vorgaben bekomme',
    QUESTIONNAIRE_14_ANSWER_2: '… keine Vorgaben bekomme',
    QUESTIONNAIRE_SHORT_14_HEADER: 'An meinem Arbeitsumfeld schätze ich',
    QUESTIONNAIRE_SHORT_14_ANSWER_1: 'Eigenverantwortung',
    QUESTIONNAIRE_SHORT_14_ANSWER_2: 'klare Strukturen',

    QUESTIONNAIRE_15_HEADER: 'Ein neues, unerwartetes Aufgabengebiet kommt auf dich zu. Wie reagierst du?',
    QUESTIONNAIRE_15_ANSWER_1: 'Ich freue mich und lege los',
    QUESTIONNAIRE_15_ANSWER_2: 'Ich brauche mehr Details, um mich freuen zu können',
    QUESTIONNAIRE_15_ANSWER_3:
        'Ich fühle mich überrumpelt und hätte mich lieber auf das vereinbarte Aufgabengebiet fokussiert',
    QUESTIONNAIRE_SHORT_15_HEADER: 'Auf neue Aufgaben reagiere ich so',
    QUESTIONNAIRE_SHORT_15_ANSWER_1: 'mit Freude und Enthusiasmus',
    QUESTIONNAIRE_SHORT_15_ANSWER_2: 'ich informiere mich ausführlich',
    QUESTIONNAIRE_SHORT_15_ANSWER_3: 'ich konzentriere mich lieber auf vertraute Aufgaben',

    QUESTIONNAIRE_16_HEADER: 'Alles ist im Job gerade gut, so wie es ist.',
    QUESTIONNAIRE_16_ANSWER_1: 'Ich freue mich und sehe keinen Grund, etwas zu verändern',
    QUESTIONNAIRE_16_ANSWER_2: 'Ich suche trotzdem nach weiteren Optimierungsmöglichkeiten',
    QUESTIONNAIRE_SHORT_16_HEADER: 'Mein Job und meine Arbeitsweise',
    QUESTIONNAIRE_SHORT_16_ANSWER_1: 'Ich schätze, was ich habe (etablierte Arbeitsabläufe)',
    QUESTIONNAIRE_SHORT_16_ANSWER_2: 'Ich suche stetig nach Optimierungen',

    QUESTIONNAIRE_17_HEADER: 'Du bist besonders ...',
    QUESTIONNAIRE_17_ANSWER_1: '… analytisch',
    QUESTIONNAIRE_17_ANSWER_2: '… belastbar',
    QUESTIONNAIRE_17_ANSWER_3: '… empathisch',
    QUESTIONNAIRE_17_ANSWER_4: '… zielstrebig',
    QUESTIONNAIRE_17_ANSWER_5: '… geduldig',
    QUESTIONNAIRE_17_ANSWER_6: '… flexibel',
    QUESTIONNAIRE_17_ANSWER_7: '… kreativ',
    QUESTIONNAIRE_17_ANSWER_8: '… ehrlich',
    QUESTIONNAIRE_17_ANSWER_9: '… ehrgeizig',
    QUESTIONNAIRE_17_ANSWER_10: '… diszipliniert',
    QUESTIONNAIRE_17_ANSWER_11: '… humorvoll',
    QUESTIONNAIRE_17_ANSWER_12: '… kommunikativ',
    QUESTIONNAIRE_17_ANSWER_13: '… strategisch',
    QUESTIONNAIRE_SHORT_17_HEADER: 'Ich bin besonders',
    QUESTIONNAIRE_SHORT_17_ANSWER_1: 'analytisch',
    QUESTIONNAIRE_SHORT_17_ANSWER_2: 'belastbar',
    QUESTIONNAIRE_SHORT_17_ANSWER_3: 'empathisch',
    QUESTIONNAIRE_SHORT_17_ANSWER_4: 'zielstrebig',
    QUESTIONNAIRE_SHORT_17_ANSWER_5: 'geduldig',
    QUESTIONNAIRE_SHORT_17_ANSWER_6: 'flexibel',
    QUESTIONNAIRE_SHORT_17_ANSWER_7: 'kreativ',
    QUESTIONNAIRE_SHORT_17_ANSWER_8: 'ehrlich',
    QUESTIONNAIRE_SHORT_17_ANSWER_9: 'ehrgeizig',
    QUESTIONNAIRE_SHORT_17_ANSWER_10: 'diszipliniert',
    QUESTIONNAIRE_SHORT_17_ANSWER_11: 'humorvoll',
    QUESTIONNAIRE_SHORT_17_ANSWER_12: 'kommunikativ',
    QUESTIONNAIRE_SHORT_17_ANSWER_13: 'strategisch',

    QUESTIONNAIRE_18_HEADER: 'Du bist eher…',
    QUESTIONNAIRE_18_ANSWER_1: '… visionär',
    QUESTIONNAIRE_18_ANSWER_2: '… umsetzungsstark',
    QUESTIONNAIRE_SHORT_18_HEADER: 'Ich bin',
    QUESTIONNAIRE_SHORT_18_ANSWER_1: 'visionär',
    QUESTIONNAIRE_SHORT_18_ANSWER_2: 'umsetzungsstark',

    QUESTIONNAIRE_19_HEADER: 'Welches Zitat spricht dich besonders an?',
    QUESTIONNAIRE_19_ANSWER_1:
        '„Die Normalität ist eine gepflasterte Straße; man kann gut darauf gehen - doch es wachsen keine Blumen auf ihr.“ (Vincent van Gogh)',
    QUESTIONNAIRE_19_ANSWER_2: '„Sei du selbst die Veränderung, die du dir wünschst für diese Welt.“ (Gandhi)',
    QUESTIONNAIRE_19_ANSWER_3:
        '„Wer von seinem Tag nicht zwei Drittel für sich selbst hat, ist ein Sklave.“ (Friedrich Nietzsche)',
    QUESTIONNAIRE_19_ANSWER_4: '„Unser Leben ist das Produkt unserer Gedanken.“ (Marc Aurelius)',
    QUESTIONNAIRE_19_ANSWER_5: '„Man braucht nichts im Leben zu fürchten, man muss nur alles verstehen.“ (Marie Curie)',
    QUESTIONNAIRE_19_ANSWER_6: '„Wenn wir bedenken, dass wir alle verrückt sind, ist das Leben erklärt.“ (Mark Twain)',
    QUESTIONNAIRE_19_ANSWER_7:
        '„Um es im Leben zu etwas zu bringen, muss man früh aufstehen, bis in die Nacht arbeiten - und Öl finden.“ (Jean Paul Getty)',
    QUESTIONNAIRE_SHORT_19_HEADER: 'Dieses Zitat gefiel mir besonders gut',
    QUESTIONNAIRE_SHORT_19_ANSWER_1:
        '„Die Normalität ist eine gepflasterte Straße; man kann gut darauf gehen - doch es wachsen keine Blumen auf ihr.“ (Vincent van Gogh)',
    QUESTIONNAIRE_SHORT_19_ANSWER_2: '„Sei du selbst die Veränderung, die du dir wünschst für diese Welt.“ (Gandhi)',
    QUESTIONNAIRE_SHORT_19_ANSWER_3:
        '„Wer von seinem Tag nicht zwei Drittel für sich selbst hat, ist ein Sklave.“ (Friedrich Nietzsche)',
    QUESTIONNAIRE_SHORT_19_ANSWER_4: '„Unser Leben ist das Produkt unserer Gedanken.“ (Marc Aurelius)',
    QUESTIONNAIRE_SHORT_19_ANSWER_5:
        '„Man braucht nichts im Leben zu fürchten, man muss nur alles verstehen.“ (Marie Curie)',
    QUESTIONNAIRE_SHORT_19_ANSWER_6:
        '„Wenn wir bedenken, dass wir alle verrückt sind, ist das Leben erklärt.“ (Mark Twain)',
    QUESTIONNAIRE_SHORT_19_ANSWER_7:
        '„Um es im Leben zu etwas zu bringen, muss man früh aufstehen, bis in die Nacht arbeiten - und Öl finden.“ (Jean Paul Getty)',

    QUESTIONNAIRE_20_HEADER: 'Was schätzt du besonders in der Zusammenarbeit mit Kolleg*innen?',
    QUESTIONNAIRE_20_SUB_HEADER:
        'Hier kannst du hervorheben, was dir besonders wichtig und wünschenswert an deinem Gegenüber ist.',
    QUESTIONNAIRE_20_PLACEHOLDER:
        'Hier kannst du hervorheben, was dir besonders wichtig und wünschenswert an deinem Gegenüber ist.',
    QUESTIONNAIRE_SHORT_20_HEADER: 'Gutes Teamwork ist für mich',

    QUESTIONNAIRE_21_HEADER: 'Was interessiert dich und was möchtest du anderen Kolleg*innen noch mitteilen?',
    QUESTIONNAIRE_21_SUB_HEADER: 'Was begeistert dich, was fesselt dich, womit verbringst du gerne deine Zeit?',
    QUESTIONNAIRE_21_PLACEHOLDER: 'Was begeistert dich, was fesselt dich, womit verbringst du gerne deine Zeit?',
    QUESTIONNAIRE_SHORT_21_HEADER: 'Das möchte ich noch mitteilen',

    BOOKMARKS_PAGE_TITLE: 'Gemerkte Kollegen',
    BOOKMARKS_PAGE_OTHERS_TITLE: 'Weitere',

    OFFER_DELETED_DIALOG_TITLE: 'Angebot nicht mehr verfügbar',
    OFFER_DELETED_DIALOG_CONTENT:
        'Tut uns leid! Das gesuchte Angebot wurde nachträglich vom Anbieter entfernt. Auf der Suchseite findest du vielleicht spannende Alternativen. Viel Erfolg bei der Suche!',
    OFFER_DELETED_DIALOG_SEARCH: 'Weiter zur Suche',

    RELEASE_NOTES: "What's new",
    RELEASE_NOTES_TITLE: 'Release Notes',
    RELEASE_NOTES_DIALOG_CONTENT:
        '<h3>New</h3>\n' +
        '<p><ul>' +
        '<li>Improved Short Assignment matching based on required and helpful skills</li>\n' +
        '<li>Download button for further reports (Administrators only)</li>\n' +
        '</ul></p>\n' +
        '<h3>Improvements</h3>\n' +
        '<p><ul>' +
        '<li>Revised UX and designs for the application process and staffing management of Short Assignments</li>\n' +
        '<li>Improved design for the application process of Workshops and Projects</li>\n' +
        '</ul></p>\n',

    GET_TO_KNOW_DIALOG_TITLE: '{{ userName }} kennenlernen!',
    GET_TO_KNOW_DIALOG_MESSAGE_TEXT:
        'Hallo,<br/>ich würde mich gerne mit dir austauschen, bei einem Kaffee (oder Mittagessen/Tee/Feierabendbier) oder optional natürlich auch virtuell. Freue mich sehr über eine Antwort und Terminvorschläge.<br/>Herzliche Grüße',
    GET_TO_KNOW_COFFEE_LABEL: 'Auf einen Kaffee',
    GET_TO_KNOW_TEA_LABEL: 'Auf einen Tee',
    GET_TO_KNOW_LUNCH_LABEL: 'Zum Mittag essen',
    GET_TO_KNOW_BEER_LABEL: 'Auf ein Bier (nach Feierabend)',

    SKILL_LEVEL_DEFINITION_HEADER: 'Definition der Kompetenzstufen',
    SKILL_LEVEL_DEFINITION:
        '   <div class="no-preline">\n' +
        '       <span>Junior</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>Ich bin Anfänger bzgl. der Anforderungen an diesen Skill.</li>\n' +
        '           <li>Ich verfüge über solides Grundwissen/-kenntnisse.</li>\n' +
        '           <li>Ich kann einfache Teilaufgaben diesen Skill betreffend eigenständig bearbeiten.</li>\n' +
        '       </ul>\n' +
        '   </div>\n' +
        '   <div class="no-preline">\n' +
        '       <span>Senior</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>Ich bin kompetent bzgl. der Anforderungen an diesen Skill.</li>\n' +
        '           <li>Ich verfüge über ausgeprägtes Wissen/ausgeprägte Kenntnisse und einen gewissen Erfahrungsschatz.</li>\n' +
        '           <li>Ich kann Aufgaben eigenständig bearbeiten und bin diesen Skill betreffend flexibel/schnell.</li>\n' +
        '       </ul>\n' +
        '   </div>\n' +
        '   <div class="no-preline">\n' +
        '       <span>Expert</span>\n' +
        '       <ul class="no-preline">\n' +
        '           <li>Ich verfüge über sehr großes Wissen/Kenntnisse und beherrsche die Anforderungen vollständig.</li>\n' +
        '           <li>Ich kann selbst unerwartete und äußerst komplexe Aufgaben/Probleme lösen.</li>\n' +
        '           <li>Ich kann andere in diesem Skill anleiten.</li>\n' +
        '       </ul>\n' +
        '   </div>',

    LOGIN_ERROR_EXTERNAL:
        'Leider gab es einen Fehler bei der Anmeldung. Bitte probiere es erneut. Sollte der Fehler wiederholt auftreten kontaktiere bitte den <a href="mailto:{{ supportEmail }}">Support</a>.',
    VALIDATION_SKILLS_ENTRY_MAX_LENGTH: 'Maximale Zeichenzahl erreicht. Bitte verwende nur stichwortartige Skills.',
    ERROR_URL_404:
        'Die Seite, die du aufrufen wolltest, ist nicht mehr verfügbar und du wurdest zu deinem Dashboard weitergeleitet. Um fortzufahren, schließe einfach diese Meldung.',

    LABEL_ENTITY_EDIT: 'Bearbeiten',
    LABEL_ENTITY_NAMED_EDIT: '{{name}} bearbeiten',
    LABEL_ENTITY_NAMED_DELETE: '{{name}} löschen',
    LABEL_ENTITY_NAMED_COPY: '{{name}} kopieren',
    LABEL_ENTITY_VIEW_DETAILS: 'Details ansehen',
    LABEL_ENTITY_VIEW_STAFFING: 'Besetzung',
    LABEL_ENTITY_VIEW_STAFFING_COMPLETED: 'Besetzung',
    LABEL_ENTITY_MESSAGE_PARTICIPANTS: 'Nachricht an Teilnehmer*innen',
    LABEL_ENTITY_MESSAGE_OWNER: 'Nachricht schreiben',
    LABEL_ENTITY_VIEW_PARTICIPANTS: 'Teilnehmer*innen',
    ENTITY_COPY_TITLE: '{{name}} kopieren',
    ENTITY_COPIED_TITLE: '{{title}} (Kopie)',
    LABEL_ENTITY_LOCATION: 'Standort',
    LABEL_ENTITY_CALENDAR: 'Datum',
    LABEL_ENTITY_LANGUAGE: 'Benötigte Sprache',
    LABEL_ENTITY_APPLICATION_DEADLINE: 'Bewerbungsschluss',
    LABEL_ENTITY_COMPANY: 'Ausschreibende Gesellschaft',
    LABEL_ENTITY_CAMPAIGNS: 'HR Programm',
    LABEL_ENTITY_ORGANIZERS_CHECKBOX: 'Multiple Organizers',
    LABEL_ENTITY_ORGANIZERS_ADD_LABEL: 'Als Organisator*in hinzufügen',

    CONFIRM_IMAGE_DELETE_TITLE: 'Dieses Bild löschen?',
    CONFIRM_IMAGE_DELETE_BODY: 'Möchtest du dieses Bild unwiderruflich löschen?',
    MAX_IMAGES_REACHED_TITLE: 'Maximal {{max}} Bilder erlaubt',
    MAX_IMAGES_REACHED_BODY:
        'Wir können leider nur {{max}} hochgeladene Bilder in deiner Auswahl zulassen. Um ein neues Bild hochzuladen, lösche bitte zuerst eines deiner vorhandenen Bilder.',

    SKILL_VIEW_NAME_LABEL: 'Benötigte Fähigkeiten',
    SKILL_VIEW_LEVEL_LABEL: 'Bevorzugtes Level',
    SKILL_FORM_IS_REQUIRED_LABEL: 'Erforderlich für Bewerbung',
    SKILL_FORM_NAME_VALIDATION_REQUIRED: 'Mindestens eine Fähigkeit muss angegeben werden.',
    SKILL_FORM_NAME_WHEN_REQUIRED_LABEL: 'Benötigte Fähigkeiten',
    SKILL_FORM_LEVEL_WHEN_REQUIRED_LABEL: 'Level',

    SKILL_FORM_REDUCED_NAME_WHEN_REQUIRED_LABEL: 'Benötigte Fähigkeiten',
    SKILL_FORM_REDUCED_LEVEL_WHEN_REQUIRED_LABEL: 'Bevorzugtes Level',

    SKILL_LEVEL_OPTION_REQUIRED_LABEL: 'erforderlich',
    SKILL_LEVEL_OPTION_HELPFUL_LABEL: 'hilfreich',

    SKILL_LEVEL_0: 'Beliebig',
    SKILL_LEVEL_1: 'Junior',
    SKILL_LEVEL_2: 'Senior',
    SKILL_LEVEL_3: 'Expert',
    SKILL_LEVEL_MINIMAL: '...',

    SKILL_FORM_PERSONAL_NAME_LABEL: 'Deine Fähigkeiten',
    SKILL_FORM_PERSONAL_LEVEL_LABEL: 'Dein Level',

    EDIT_SKILL_LIST_TABLE_SKILL_ADDED_NOTIFICATION_MSG: 'Die Fähigkeit wurde zu deinem Profil hinzugefügt.',
    EDIT_TOPIC_LIST_TABLE_TOPIC_ADDED_NOTIFICATION_MSG: 'Das Thema wurde zu deinem Profil hinzugefügt.',
    EDIT_FUNCTION_ADDED_NOTIFICATION_MSG: 'Die Funktion zu deinem Profil hinzugefügt.',
    EDIT_LANGUAGE_ADDED_NOTIFICATION_MSG: 'Die Sprache wurde zu deinem Profil hinzugefügt.',
    SKILL_LEVEL_BUTTON_ACTION_MENU_HEADER: 'Ich habe diesen Skill',
    SHOW_MORE_SKILLS_DROPDOWN: 'Weitere Fähigkeiten anzeigen',
    REQUIRED_SKILLS_LABEL: 'Erforderliche Fähigkeiten',
    NTH_SKILLS_LABEL: 'Hilfreiche Fähigkeiten',

    SUCCESS_FACTOR_LABEL_1: 'Sync to my CV Data in Profile',
    SUCCESS_FACTOR_LABEL_2:
        'Kenntnisse & Interessen können schnell zu „my CV Data“ in Profile übertragen werden, wenn ein Profile Zugriff in SF vorhanden ist',

    DIGITALIZATION: 'Digitalisierung',
    CUSTOMER_CENTRICITY: 'Kundenorientierung',
    SUSTAINABILITY: 'Nachhaltigkeit',
    MOBILITY: 'Mobilität / Internationale Themen',
    COMMERCIAL: 'Wirtschaftliche/ Kommerzielle',
    AIRLINE_PRODUCT: 'Produktthemen',
    IT_TOPICS: 'IT Themen & Tools',
    COMMUNICATION: 'Kommunikationsthemen',
    SOCIAL: 'Soziale Themen',
    AGILE_METHODS: 'Agile Methoden',
    LEAN_METHODS: 'Lean Methoden',

    ANY: 'Beliebig',
    PLANNED: 'Geplant',
    RUNNING: 'Laufend',
    COMPLETE: 'Beendet',

    SUCCESS_FACTOR_SKILLS_OVERLAY_TITLE: 'Neue Skills und Level Updates',
    SUCCESS_FACTOR_SKILLS_OVERLAY_DESCRIPTION:
        'In dieser Übersicht siehst du, welche neuen Fähigkeiten und Aktualisierungen der Level mit SuccessFactors synchronisiert werden.',
    SUCCESS_FACTOR_ORIGINAL_SKILLS_LABEL: 'GEÄNDERTE LEVEL',
    SUCCESS_FACTOR_NEW_SKILLS_LABEL: 'NEUE FÄHIGKEITEN',

    HEADLINE_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION: 'Teilnehmer*innen informieren?',
    TEXT_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION:
        'Möchtest du die Teilnehmer*innen per E-Mail über die gerade vorgenommenen Änderungen informieren?',
    INFORM_PARTICIPANTS: 'Ja, E-Mail senden',
    NOT_INFORM_PARTICIPANTS: 'Nein, nicht informieren',

    SEARCH_CAMPAIGNS_LABEL: 'HR Programm',
    SEARCH_CAMPAIGNS_PLACEHOLDER: 'keine',

    MODAL_HEADER_WARNING_MESSAGE: 'Bitte fülle alle notwendigen Felder aus.',
    MODAL_CLOSE_LABEL: 'Fenster schließen',

    PAGE_TITLE_DEFAULT: 'Flexible Arbeit und Wissenstransfer',
    PAGE_TITLE_DASHBOARD: 'Dashboard',
    PAGE_TITLE_PROFILE: 'Profil',
    PAGE_TITLE_PROFILE_PREFIX: 'Profil von',
    PAGE_TITLE_DIRECT_MESSAGES: 'Nachrichten',
    PAGE_TITLE_BOOKMARKS: 'Gemerkte Kollegen',
    PAGE_TITLE_ABOUT: 'About',
    PAGE_TITLE_ABOUT_MODULES: 'Über die Module',
    PAGE_TITLE_ABOUT_FAQ: 'FAQ',
    PAGE_TITLE_SETTINGS: 'Einstellungen',
    PAGE_TITLE_LOGIN: 'Log in',
    PAGE_TITLE_LOGOUT: 'Log out',
    PAGE_TITLE_SIGNUP: 'Registrierung',

    DRAG_YOUR_ITEM_HERE: 'Ziehe dein Thema hier hin',
    SIDEBAR_ARIA_LABEL: 'Seitennavigation öffnen',
};
