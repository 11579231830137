import { createAction, props } from '@ngrx/store';
import { MyWorkshop } from './my-workshops.reducer';

export const MyWorkshopsActions = {
    loadRegistered: createAction('[My Registered Workshops] load'),
    loadRegisteredSuccess: createAction('[My Registered Workshops][Success] load', props<{ data: MyWorkshop[] }>()),
    loadRegisteredError: createAction('[My Registered Workshops][Error] load', props<{ error: Error }>()),

    loadRequested: createAction('[My Requested Workshops] load'),
    loadRequestedSuccess: createAction('[My Requested Workshops][Success] load', props<{ data: MyWorkshop[] }>()),
    loadRequestedError: createAction('[My Requested Workshops][Error] load', props<{ error: Error }>()),
};
