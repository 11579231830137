import { SelectedTopics, SelectedTopicsDTO, SelectedTopicsWrapperDTO } from './selected-topics.types';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SelectedTopicsSerializer {
    // Define multiple overlad for the serialize function
    // We can map a signature to an output in that way.
    serialize(selectedTopics: SelectedTopics): SelectedTopicsDTO;
    serialize(selectedTopics: SelectedTopics, wrap: false): SelectedTopicsDTO;
    serialize(selectedTopics: SelectedTopics, wrap: true): SelectedTopicsWrapperDTO;

    serialize(selectedTopics: SelectedTopics, wrap = false): SelectedTopicsDTO | SelectedTopicsWrapperDTO {
        if (!wrap) {
            return [...selectedTopics];
        } else {
            return {
                selectedTopics: [...selectedTopics],
            };
        }
    }

    deserialize(dto: SelectedTopicsDTO): SelectedTopics {
        return [...dto];
    }
}
