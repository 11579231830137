import { ComponentType } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, HostListener, Input, OnDestroy, ElementRef, TemplateRef } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { of, Subject } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { PopoverDirective } from './popover.directive';

const DEFAULT_SHOW_DELAY = 0;
const DEFAULT_HIDE_DELAY = 0;

@Directive({
    selector: '[tpPopoverHover]',
})
export class PopoverHoverDirective implements AfterViewInit, OnDestroy {
    @Input() stopPropagationOnClick = false;
    @Input() popoverVisibleOnHoverStrategy = false;
    @Input() tpPopoverContent: TemplateRef<ElementRef> | ComponentType<ElementRef>;

    @Input()
    get tpPopoverHover() {
        return this._tpPopoverHover;
    }
    set tpPopoverHover(val: number) {
        this._tpPopoverHover = coerceNumberProperty(val);
    }
    private _tpPopoverHover = 0;

    @Input()
    get tpPopoverLeave() {
        return this._tpPopoverLeave;
    }
    set tpPopoverLeave(val: number) {
        this._tpPopoverLeave = coerceNumberProperty(val);
    }
    private _tpPopoverLeave = 0;

    private destroy$ = new Subject<void>();

    private mouseEnter$ = new Subject<void>();

    private mouseLeave$ = new Subject<void>();

    private shouldHide = true;
    private originElement = null;

    constructor(private readonly popoverDirective: PopoverDirective) {}

    ngAfterViewInit() {
        this.mouseEnter$
            .pipe(
                switchMap((event) => {
                    return of(null).pipe(
                        delay(this._tpPopoverHover || DEFAULT_SHOW_DELAY),
                        takeUntil(this.mouseLeave$),
                    );
                }),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.popoverDirective.show(0));

        this.mouseLeave$
            .pipe(
                switchMap((event) => {
                    if (this.popoverVisibleOnHoverStrategy) {
                        this.implementPopoverVisibleOnHover(event);
                    }
                    return of(null).pipe(delay(this._tpPopoverLeave || DEFAULT_HIDE_DELAY));
                }),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                if (!this.popoverVisibleOnHoverStrategy || this.shouldHide) {
                    return this.popoverDirective.hide(0);
                } else {
                    return this.popoverDirective.show(0);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    implementPopoverVisibleOnHover = (event) => {
        const currentTarget = event['currentTarget'] || event['target'];
        const nextTargets = this.elementParentList(event['toElement']);
        const popoverClass = this.tpPopoverContent['ɵcmp'];
        if (nextTargets && popoverClass) {
            const parentTarget = nextTargets.find(
                (target) => target.nodeName.toLocaleLowerCase() === popoverClass.selectors[0][0],
            );
            if (parentTarget) {
                parentTarget.addEventListener('mouseleave', (event) => this.mouseLeaveListener(event, currentTarget));
                this.shouldHide = false;
            } else {
                this.shouldHide = true;
            }
        }
    };

    mouseLeaveListener = (event, originElement) => {
        (event['currentTarget'] || event['target']).removeEventListener('mouseleave', (listenerEvent) =>
            this.mouseLeaveListener(listenerEvent, originElement),
        );
        if (event['toElement'] && event['toElement'].parentElement !== originElement) {
            this.shouldHide = true;
            this.popoverDirective.hide(0);
        }
    };

    elementParentList = (element) => {
        const els = [];
        if (element) {
            let el = element;
            while (el) {
                els.unshift(el);
                el = el.parentNode;
            }
        }
        return els;
    };

    @HostListener('click', ['$event'])
    clickButton(event) {
        if (this.stopPropagationOnClick) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    @HostListener('mouseenter', ['$event'])
    showPopover(event) {
        this.mouseEnter$.next(event);
    }

    @HostListener('mouseleave', ['$event'])
    closePopover(event) {
        this.mouseLeave$.next(event);
    }
}
