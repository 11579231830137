import { NgModule } from '@angular/core';
import { HelpComponent } from './help.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TenantTemplatesModule } from '../../core-modules/tenant-templates';
import { ModalLayoutModule } from '../../core-modules/modal-layout';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { LinkModule } from '@tandemploy/ngx-components';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        LinkModule,
        MatIconModule,
        MatButtonModule,
        TenantTemplatesModule,
        ModalLayoutModule,
        LanguageModule,
    ],
    declarations: [HelpComponent],
    exports: [HelpComponent],
})
export class HelpModule {}
