abstract class GenerationExchangeBadRequestException extends Error {
    constructor(message: string, apiError?: Error) {
        let extra: string;
        const additionalInformation = apiError ? apiError.message || null : null;

        if (additionalInformation) {
            extra = ` Here is what the service has returned: ${additionalInformation}.`;
        } else {
            extra = ``;
        }

        super(`${message}${extra}`);
    }
}

export class GenerationExchangeUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError ? originalError.message : 'Something unexpected has happened.');
    }
}

// Profile related exceptions
export class GenerationExchangeProfileBadRequestException extends GenerationExchangeBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the profile. Please check the data.', apiError);
    }
}

export class GenerationExchangeMyProfileNotFoundException extends Error {
    constructor() {
        super(`The Generation Exchange profile for the currently logged-in user was not found.`);
    }
}

export class GenerationExchangeOthersProfileNotFoundException extends Error {
    constructor() {
        super(`The requested Generation Exchange profile was not found.`);
    }
}

// Domain related exceptions
export class GenerationExchangeDomainMatchingException extends Error {
    constructor() {
        super('An unexpected error has occured while querying matches.');
    }
}

export class GenerationExchangeDomainBookmarksException extends Error {
    constructor() {
        super('An unexpected error has occured while querying bookmarks.');
    }
}

export class GenerationExchangeDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for Generation Exchange profiles.');
    }
}
