import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FooterLink } from './footer-links.types';

export type ViewMode = 'button' | 'link';

@Component({
    selector: 'tp-footer-links',
    templateUrl: './footer-links.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinksComponent {
    @Input()
    links: FooterLink[];

    @Input()
    viewMode: ViewMode;
}
