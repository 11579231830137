<tp-main-layout>
    <header>
        <h1>
            <tp-icon-button
                [icon]="'edit_filled'"
                [routerLink]="['/', { outlets: { overlay: ['edit', 'never-lunch-alone'] } }]"
                [ariaLabel]="'LABEL_ENTITY_EDIT' | translatable"
            ></tp-icon-button>
            {{ 'NEVER_LUNCH_ALONE' | translatable }}
        </h1>
    </header>

    <ng-container [ngTemplateOutlet]="(hasProfile$ | async) ? hasProfile : noProfile"></ng-container>

    <ng-template #hasProfile>
        <tp-tab [tabs]="tabs" [defaultTab]="initialTab"></tp-tab>
        <router-outlet></router-outlet>
    </ng-template>

    <ng-template #noProfile>
        <tp-add-profile [editTopicRoute]="['edit', 'never-lunch-alone']" viewMode="people"></tp-add-profile>
    </ng-template>
</tp-main-layout>
