import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GenerationExchangeProfile } from '../../../generation-exchange-service/generation-exchange.types';
import { LocationService } from '@tploy-enterprise/tenant-core';
import { AccountListItemBase } from '../../../../../../tenant-core/src/lib/core-modules/account/account-list-item/account-list-item-base';

@Component({
    selector: 'tp-generation-exchange-list-item',
    templateUrl: './generation-exchange-list-item.component.html',
})
export class GenerationExchangeListItemComponent extends AccountListItemBase implements OnChanges {
    @Input() highlightLocations: string[] = [];

    @Input() highlightLocationIndependent: boolean;

    @Input() profile: GenerationExchangeProfile;

    @Input() selectedCampaigns: string[];

    @Input() hasCampaigns = false;

    locations: string[];
    highlightedLocations: string[];

    constructor(protected injector: Injector, private readonly locationService: LocationService) {
        super(injector);
    }

    get locationIndependent(): boolean {
        return this.profile.locationIndependent;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.profile) {
            this.locations = this.locationService.getLocationsOrCountry(this.profile.companyLocations);
        }
        if (changes.highlightLocations) {
            this.highlightedLocations = this.locationService.getLocationsOrCountry(this.highlightLocations);
        }
    }
}
