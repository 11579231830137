import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageHelpersService } from '../language';

@Injectable({
    providedIn: 'root',
})
export class SuggestSkillService {
    loading$ = new Subject<boolean>();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly languageHelpersService: LanguageHelpersService,
    ) {}

    suggest(input: string): Observable<{ name: string }[]> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                term: encodeURIComponent(input),
                language: this.languageHelpersService.getCurrentLocale(),
            },
        });

        return this.httpClient
            .get<string[]>('api/v2/ontology-proxy/autocomplete', { params })
            .pipe(map((terms) => terms.map((t) => ({ name: t }))));
    }
}
