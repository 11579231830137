export default {
    GENERATION_EXCHANGE: 'Generation Exchange',
    GENERATION_EXCHANGE_INFO:
        'Would you like to network with colleagues of different generations, exchange experiences and expand your personal network? Then Generation Exchange is the right thing for you!',

    GENERATION_EXCHANGE_PROFILE_SUBTITLE:
        'Would you like to network with colleagues of different generations, exchange experiences and expand your personal network? Then Generation Exchange is the right thing for you!',
    GENERATION_EXCHANGE_PROFILE_LOCATION_LABEL: 'At what locations are you available for meetings?',
    GENERATION_EXCHANGE_PROFILE_LOCATION_INDEPENDENT_LABEL: 'Open for virtual meeting',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_LABEL: 'What motivates you to participate in Generation Exchange?',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_1:
        'I would like to talk about specific/work related topics and exchange knowledge with other generations.',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_2:
        'I am interested in the topic of generations in the company and I would like to better understand the views of generations on various topics.',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_3:
        'I would like to network with colleagues from other generations, because I find the exchange, whether professional or private, important.',
    GENERATION_MOTIVATION_VALIDATION_REQUIRED: 'You must select at least one option.',
    GENERATION_EXCHANGE_PROFILE_SKILLS_LABEL: 'You can also suggest individual topics for the exchange.',
    GENERATION_EXCHANGE_PROFILE_SKILLS_PLACEHOLDER: 'e.g., sales, sport theater',
    GENERATION_EXCHANGE_PROFILE_GENERATION_LABEL: 'Which generation do you belong to?',
    GENERATION_EXCHANGE_PROFILE_GENERATION_0: 'Traditionalist (born before 1946)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_1: 'Babyboomer (born 1946-1963)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_2: 'Generation X (born 1964-1978)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_3: 'Generation Y (born 1979-1994)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_4: 'Generation Z (born from 1995)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_PLACEHOLDER: 'Please select',

    PROFILE_VIEW_GENERATION_EXCHANGE_LOCATIONS_LABEL: 'Possible meeting points',
    PROFILE_VIEW_GENERATION_EXCHANGE_LOCATION_ONLINE: 'online',
    PROFILE_VIEW_GENERATION_EXCHANGE_TOPICS_LABEL: 'Individual topics',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_LABEL: 'My motivation',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_1: 'Specific/work-related topics',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_2: 'Interest in different points of view',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_3: 'Intergenerational exchange, no matter what',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_LABEL: 'My generation',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_0: 'Traditionalist',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_1: 'Babyboomer',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_2: 'Generation X',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_3: 'Generation Y',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_4: 'Generation Z',
    PROFILE_VIEW_GENERATION_EXCHANGE_CAMPAIGNS_LABEL: 'HR programs',

    GENERATION_EXCHANGE_PROFILE_CAMPAIGNS_LABEL: 'HR programs you participate in',
    GENERATION_EXCHANGE_PROFILE_CAMPAIGNS_PLACEHOLDER: 'choose..',

    GENERATION_EXCHANGE_MATCHES_TAB: 'Matches',
    GENERATION_EXCHANGE_BOOKMARKS_TAB: 'Bookmarks',
    GENERATION_EXCHANGE_SEARCH_TAB: 'Search',

    GENERATION_EXCHANGE_NO_MATCH_IMG_ALT: 'No matches found',
    GENERATION_EXCHANGE_NO_MATCH_CONTENT:
        'Please specify more skills and (hidden?) talents in your profile to find matches',
    GENERATION_EXCHANGE_NO_MATCH_CTA: 'Edit skills',
    GENERATION_EXCHANGE_NO_MATCH_FIND_CONTENT: 'Find more colleagues by using the search',

    GENERATION_EXCHANGE_NO_BOOKMARKS_TITLE: 'No profiles bookmarked yet',
    GENERATION_EXCHANGE_NO_BOOKMARKS_SUBTITLE:
        'Here you can see your bookmarked colleagues in the field of Generation Exchange.',

    GENERATION_EXCHANGE_SEARCH_HEADER: 'Search for Generation Exchange profiles by keywords',
    GENERATION_EXCHANGE_SEARCH_EMPTY_MESSAGE:
        "Unfortunately nothing was found. Tip: Searching without a search term shows all 'generation exchange' profiles.",
    GENERATION_EXCHANGE_SEARCH_BTN_TEXT: 'Search!',
    GENERATION_EXCHANGE_SEARCH_NO_RESULT_IMG_ALT:
        'Image shows a placeholder which appears instead of results, because the search did not return any.',
    GENERATION_EXCHANGE_SEARCH_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    GENERATION_EXCHANGE_ITEM_LOCATION_INDEPENDENT_LABEL: 'Available for online lunch',
    GENERATION_EXCHANGE_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL: 'Matching for online lunch',

    PAGE_TITLE_GENERATION_EXCHANGE_MATCHING: 'Generation exchange matching',
    PAGE_TITLE_GENERATION_EXCHANGE_BOOKMARKS: 'Generation exchange bookmarks',
    PAGE_TITLE_GENERATION_EXCHANGE_SEARCH: 'Generation exchange search',
};
