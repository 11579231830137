import { Injectable } from '@angular/core';
import { ProjectsApi } from './projects-api/projects.api';
import { ProjectsSerializer } from './projects-serializer.service';
import {
    ApplicationRequest,
    ProjectApplication,
    ProjectsEntity,
    ProjectsOthersProfile,
    ProjectsProfile,
    ProjectsApplicationIndicators,
    ProjectsProfileInvitationMessage,
    ProjectsProfileMatches,
    ProjectsProfileSearchResult,
    ProjectsSearchResults,
    ProjectSuggestedSkills,
    ProjectsStaffingFilter,
} from './projects.types';
import {
    AccountService,
    EntityNotFoundService,
    GeneralData,
    OthersAccount,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ProjectsDomainIndexException,
    ProjectsDomainMatchingException,
    ProjectsDomainSearchException,
    ProjectsEntityApplicationBadRequestException,
    ProjectsEntityApplicationForbiddenException,
    ProjectsEntityApplicationNotFoundException,
    ProjectsEntityBadRequestException,
    ProjectsEntityForbiddenException,
    ProjectsEntityNotFoundException,
    ProjectsMyProfileNotFoundException,
    ProjectsOthersProfileNotFoundException,
    ProjectsProfileBadRequestException,
    ProjectsUnexpectedException,
} from './projects.exceptions';
import { Skill } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    constructor(
        private readonly projectsApi: ProjectsApi,
        private readonly projectsSerializer: ProjectsSerializer,
        private readonly accountService: AccountService,
        private readonly entityNotFoundService: EntityNotFoundService,
        private readonly paginatorService: PaginatorService,
    ) {}

    saveProfile(profile: ProjectsProfile, general: GeneralData): Observable<ProjectsProfile> {
        const dto = this.projectsSerializer.serializeProjectsProfile(profile, general);
        return this.projectsApi.putProfile(dto).pipe(
            map((projectsProfileDTO) => this.projectsSerializer.deserializeProjectsProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfile(): Observable<ProjectsProfile> {
        return this.projectsApi.getProfile().pipe(
            map((projectsProfileDTO) => this.projectsSerializer.deserializeProjectsProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<ProjectsOthersProfile> {
        return this.projectsApi.getProfileById(id).pipe(
            map((projectsProfileDTO) => this.projectsSerializer.deserializeProjectsOthersProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    getSuggestedSkills(id: string): Observable<ProjectSuggestedSkills> {
        return this.projectsApi.getSuggestedSkills(id).pipe(
            catchError(() => of({ aType: [], lType: [], sType: [] })),
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
        );
    }

    saveEntity(projectsEntity: ProjectsEntity, sendNotifications?: boolean): Observable<ProjectsEntity> {
        const dto = this.projectsSerializer.serializeProjectsEntity(projectsEntity);
        return this.projectsApi.putEntity(dto, sendNotifications).pipe(
            map((projectsEntityDTO) => this.projectsSerializer.deserializeProjectsEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    createEntity(projectsEntity: ProjectsEntity): Observable<ProjectsEntity> {
        const dto = this.projectsSerializer.serializeProjectsEntity(projectsEntity);
        return this.projectsApi.postEntity(dto).pipe(
            map((projectsEntityDTO) => this.projectsSerializer.deserializeProjectsEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.projectsApi.deleteEntity(entityId).pipe(catchError((error) => this.handleEntityError(error)));
    }

    publishEntity(entityId: string): Observable<ProjectsEntity> {
        return this.projectsApi.publishEntity(entityId).pipe(
            map((projectsEntityDTO) => this.projectsSerializer.deserializeProjectsEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    searchEntities(
        searchQuery: string,
        pageParams: PageParams,
        campaigns?: string[],
    ): Observable<ProjectsSearchResults> {
        let matchesTotal = 0;
        return this.projectsApi.search(searchQuery, this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.projectsSerializer.deserializeProjectsSearchResult(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        results: results.map((result) => {
                            if (campaigns?.length > 0) {
                                result.highlight.campaigns = campaigns;
                            }
                            return result;
                        }),
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new ProjectsDomainSearchException();
            }),
        );
    }

    loadStaffingEntities(
        searchQuery: string,
        pageParams: PageParams,
        filter: ProjectsStaffingFilter,
    ): Observable<ProjectsSearchResults> {
        let matchesTotal = 0;
        return this.projectsApi
            .loadStaffingEntities(searchQuery, this.paginatorService.offsetLimit(pageParams), filter)
            .pipe(
                tap((response) => (matchesTotal = response.totalCount)),
                map((response) => response.results),
                map((results) => results.map((dto) => this.projectsSerializer.deserializeProjectsSearchResult(dto))),
                map((results) => {
                    if (results.length > 0) {
                        return {
                            results,
                            pageParams: { ...pageParams, length: matchesTotal },
                        };
                    } else {
                        return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                    }
                }),
                catchError(() => {
                    throw new ProjectsDomainSearchException();
                }),
            );
    }

    loadEntity(entityId: string, showHighlighted = false, isStaffingManagerView = false): Observable<ProjectsEntity> {
        return this.projectsApi.getEntity(entityId, showHighlighted, isStaffingManagerView).pipe(
            map((dto) => this.projectsSerializer.deserializeProjectsEntity(dto)),
            catchError((error: Error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    finishStaffing(entity: ProjectsEntity): Observable<ProjectsEntity> {
        return this.projectsApi.completeStuffing(entity.id).pipe(
            map((dto) => this.projectsSerializer.deserializeProjectsEntity(dto)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    finishOngoing(entity: ProjectsEntity): Observable<ProjectsEntity> {
        return this.projectsApi.finishOngoing(entity.id).pipe(
            map((dto) => this.projectsSerializer.deserializeProjectsEntity(dto)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    apply(entityId: string, name: string, selectedSkills: Array<Skill>): Observable<ProjectApplication> {
        const newApplication: ApplicationRequest = {
            user: { name },
            selectedSkills,
        };

        return this.projectsApi.postEntityApplication(entityId, newApplication).pipe(
            map((dto) => this.projectsSerializer.deserializeApplication(dto)),
            catchError((error: Error) => this.handleEntityApplicationsError(error)),
        );
    }

    acceptApplication(projectsEntity: ProjectsEntity, application: ProjectApplication): Observable<ProjectApplication> {
        return this.projectsApi
            .patchEntityApplicationStatus(projectsEntity.id, application.id, { status: 'approved' })
            .pipe(
                map((projectsApplicationDTO) => this.projectsSerializer.deserializeApplication(projectsApplicationDTO)),
                catchError((error: Error) => this.handleEntityApplicationsError(error)),
                switchMap((application) => this.hydrateApplication(application)),
            );
    }

    rejectApplication(projectsEntity: ProjectsEntity, application: ProjectApplication): Observable<ProjectApplication> {
        return this.projectsApi
            .patchEntityApplicationStatus(projectsEntity.id, application.id, { status: 'rejected' })
            .pipe(
                map((projectsApplicationDTO) => this.projectsSerializer.deserializeApplication(projectsApplicationDTO)),
                catchError((error: Error) => this.handleEntityApplicationsError(error)),
                switchMap((application) => this.hydrateApplication(application)),
            );
    }

    cancelApplication(projectsEntity: ProjectsEntity, application: ProjectApplication): Observable<ProjectApplication> {
        return this.projectsApi
            .patchEntityApplicationStatus(projectsEntity.id, application.id, { status: 'cancelled' })
            .pipe(
                map((projectsApplicationDTO) => this.projectsSerializer.deserializeApplication(projectsApplicationDTO)),
                catchError((error: Error) => this.handleEntityApplicationsError(error)),
                switchMap((application) => this.hydrateApplication(application)),
            );
    }

    loadEntitiesOfferedByMe(userId: string): Observable<ProjectsEntity[]> {
        return this.projectsApi.getEntitiesOfferedByMe(userId).pipe(
            map((projectsEntityDTOs) =>
                projectsEntityDTOs.map((dto) => this.projectsSerializer.deserializeProjectsEntity(dto)),
            ),
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
            catchError((error) => {
                console.error(error);
                return of([]);
            }),
        );
    }

    getProfileMatches(pageParams: PageParams, campaigns?: string[]): Observable<ProjectsProfileMatches> {
        let matchesTotal = 0;
        return this.projectsApi.getProfileMatches(this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.projectsSerializer.deserializeProjectsProfileMatch(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        matches: results.map((result) => {
                            if (campaigns?.length > 0) {
                                result.highlight.campaigns = campaigns;
                            }
                            return result;
                        }),
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { matches: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new ProjectsDomainMatchingException();
            }),
        );
    }

    getAppliedToEntities(): Observable<ProjectsEntity[]> {
        return this.projectsApi.getAppliedToEntities().pipe(
            map((projectsEntityDTOs) =>
                projectsEntityDTOs.map((dto) => this.projectsSerializer.deserializeProjectsEntity(dto)),
            ),
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    searchProfiles(projectId: string, searchTerms: string[]): Observable<ProjectsProfileSearchResult[]> {
        return this.projectsApi
            .searchProfiles(projectId, searchTerms)
            .pipe(
                map((searchResultsDTOs) =>
                    searchResultsDTOs.map((dto) => this.projectsSerializer.deserializeProjectsProfileSearchResult(dto)),
                ),
            );
    }

    searchTopicUserByName(searchQuery: string, entityId: string): Observable<Partial<GeneralData>[]> {
        return this.projectsApi
            .searchTopicUserByName(searchQuery, entityId)
            .pipe(
                map((searchResultsDTOs) =>
                    searchResultsDTOs.map((dto) => this.projectsSerializer.deserializeProjectsUserSearchResult(dto)),
                ),
            );
    }

    sendInvitation(invitationMessage: ProjectsProfileInvitationMessage): Observable<void> {
        const dto = this.projectsSerializer.serializeProjectsProfileInvitationMessage(invitationMessage);
        return this.projectsApi.sendInvitation(dto);
    }

    getApplications(entityId: string): Observable<Array<ProjectApplication>> {
        return this.projectsApi.getApplications(entityId).pipe(
            map((dto) => dto.map((applicationDTO) => this.projectsSerializer.deserializeApplication(applicationDTO))),
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
        );
    }

    getUnseenApplications(): Observable<ProjectsApplicationIndicators> {
        return this.projectsApi.getUnseenApplications().pipe(
            map((dto) => this.projectsSerializer.deserializeIndicators(dto)),
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
        );
    }

    markTopicLinkApplicationsAsSeen(): Observable<unknown> {
        return this.projectsApi.markTopicLinkApplicationsAsSeen().pipe(
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
        );
    }

    markOfferingTabApplicationsAsSeen(): Observable<unknown> {
        return this.projectsApi.markOfferingTabApplicationsAsSeen().pipe(
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
        );
    }

    markApplicationsAsSeen(entityId: string): Observable<unknown> {
        return this.projectsApi.markApplicationsAsSeen(entityId).pipe(
            catchError(() => {
                throw new ProjectsDomainIndexException();
            }),
        );
    }

    private hydrateEntity(entity: ProjectsEntity): Observable<ProjectsEntity> {
        const organizer$ = this.getOrganizerData(entity).pipe(catchError(() => of(null)));
        const applications$ = this.getApplicationProfiles(entity);

        return forkJoin({ organizerData: organizer$, applications: applications$ }).pipe(
            map((hydrationData) => ({ ...entity, ...hydrationData })),
        );
    }

    private getOrganizerData(entity: ProjectsEntity): Observable<OthersAccount> {
        if (entity.organizerData) {
            return of(entity.organizerData);
        }

        return this.accountService.getAccountById(entity.organizers[0].id);
    }

    private getApplicationProfiles(entity: ProjectsEntity): Observable<ProjectApplication[]> {
        if (entity.applications?.length > 0) {
            return forkJoin(entity.applications.map((application) => this.hydrateApplication(application)));
        } else {
            return of([]);
        }
    }

    private hydrateApplication(application: ProjectApplication): Observable<ProjectApplication> {
        return this.loadProfileById(application.userId).pipe(
            map((profile) => {
                return { ...application, profile };
            }),
            catchError(() => of({ ...application, profile: null })),
        );
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new ProjectsProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new ProjectsMyProfileNotFoundException();
                    } else {
                        throw new ProjectsOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new ProjectsUnexpectedException(error);
        }
    }

    private handleEntityError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new ProjectsEntityBadRequestException(error.error);
                case 403:
                    throw new ProjectsEntityForbiddenException();
                case 404:
                    this.entityNotFoundService.displayDialog('/projects/search');
                    throw new ProjectsEntityNotFoundException();
                default:
                    throw error;
            }
        } else {
            throw new ProjectsUnexpectedException(error);
        }
    }

    private handleEntityApplicationsError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new ProjectsEntityApplicationBadRequestException();
                case 403:
                    throw new ProjectsEntityApplicationForbiddenException();
                case 404:
                    throw new ProjectsEntityApplicationNotFoundException();
                default:
                    throw new ProjectsUnexpectedException(error.error);
            }
        } else {
            throw new ProjectsUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
