import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NLABookmark } from '../../nla-service/nla.types';

@Component({
    selector: 'tp-nla-bookmarks-view',
    templateUrl: './nla-bookmarks-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLABookmarksViewComponent implements OnChanges {
    @Input() bookmarks: NLABookmark[];

    hasBookmarks = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bookmarks) {
            this.hasBookmarks = !!this.bookmarks && this.bookmarks.length > 0;
        }
    }
}
