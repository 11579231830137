<mat-chip-list [selectable]="selectable" multiple="true" [attr.aria-label]="ariaLabel">
    <mat-icon class="tagger-icon-campaigns" *ngIf="tags.length > 0" svgIcon="campaign"></mat-icon>
    <tp-chip
        [isBasic]="true"
        class="tagger__chip"
        *ngFor="let tag of getTagsNames()"
        [value]="hasHiddenText(tag) ? (tag | slice : 0 : this.textLimit) + '...' : tag"
        #tooltip="matTooltip"
        matTooltip="{{ tag }}"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="!hasHiddenText(tag)"
        [removable]="false"
        [selectable]="selectable"
        [selected]="isSelected(tag)"
        (simpleClick)="onClick($event, tag)"
        (enterPressed)="onEnterPressed($event, tag)"
    ></tp-chip>

    <button
        *ngIf="showBadge"
        class="btn-more"
        mat-mini-fab
        color="accent"
        aria-label="Has more campaigns"
        tpPopover
        tpPopoverHover
        [tpPopoverHasBackdrop]="false"
        [tpVerticalTarget]="'above'"
        [tpPopoverContent]="popoverContent"
        [tpPopoverData]="popoverData"
        [tpHorizontalConnectionPosition]="'start'"
        [fallbackHorizontalOffset]="25"
    >
        {{ badgeText }}
    </button>
</mat-chip-list>
