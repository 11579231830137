import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { STAState } from '../../sta.reducer';
import { Observable } from 'rxjs';
import { showTopicLinkIndicator } from '../../sta.selectors';
import { STAActions } from '../../sta.actions';

@Component({
    selector: 'tp-sta-applicants-indicator',
    templateUrl: './sta-applicants-indicator-view.component.html',
})
export class StaApplicantsIndicatorViewComponent implements OnInit {
    showTopicLinkIndicator$: Observable<boolean>;

    constructor(private readonly store: Store<{ shortTimeAssignments: STAState }>) {}

    ngOnInit(): void {
        this.showTopicLinkIndicator$ = this.store.select(showTopicLinkIndicator);
        this.store.dispatch(STAActions.getOwnerUnseenApplications());
    }
}
