import { combineReducers, createReducer, on } from '@ngrx/store';
import { ProjectsActions } from './projects.actions';
import { AccountActions, GeneralData, PageParams } from '@tploy-enterprise/tenant-core';

import {
    ProjectsEntity,
    ProjectsEntityStatus,
    ProjectsProfile,
    ProjectsProfileInvitationMessage,
    ProjectsProfileMatch,
    ProjectsProfileSearchResult,
    ProjectsSearchResult,
    ProjectsStaffingFilter,
    ProjectSuggestedSkills,
} from './projects.types';
import { Skill } from '@tploy-enterprise/tenant-common';
import { ProjectsUtilsService } from './projects-domain/projects-utils.service';
const projectsUtilsService = new ProjectsUtilsService();

export type ProjectsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    profile: ProjectsProfileState;
    searchResults: ProjectsSearchState;
    staffing: ProjectsStaffingState;
    offeredByMe: ProjectsOfferedByMeState;
    matches: ProjectsMatchesState;
    edition: ProjectsEditionState;
    creation: ProjectsCreationState;
    deletion: ProjectsDeletionState;
    publish: ProjectsPublishState;
    searchProfiles: ProjectsSearchProfilesState;
    profileInvitation: ProjectsProfileInvitationState;
    suggestedSkills: SuggestedSkillsState;
    unseenApplications: ProjectsUnseenApplicationsState;
    applicationSkills: ProjectsApplicationState;
    searchTopicUsers: ProjectsSearchTopicUsersState;
};

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: ProjectSuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type ProjectsProfileState = {
    status: StateStatus;
    saved: ProjectsProfile;
    current: ProjectsProfile;
};

export type ProjectsSearchState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: ProjectsSearchResult[];
    searchQuery: string;
    pageParams: PageParams;
};

export type ProjectsStaffingState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: ProjectsSearchResult[];
    searchQuery: string;
    pageParams: PageParams;
    filter: ProjectsStaffingFilter;
    applicationsLoaded: boolean;
};

export type ProjectsOfferedByMeState = {
    loaded: boolean;
    loading: boolean;
    applicationsSelectionsLoaded: boolean;
    loadingError: Error;
    entities: ProjectsEntity[];
};

export type ProjectsMatchesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    entities: ProjectsProfileMatch[];
    pageParams: PageParams;
    selectedCampaigns: string[];
};

export type ProjectsTransactionalState = {
    projectsEntity: ProjectsEntity;
    savingError: Error;
    saving: boolean;
};

export type ProjectsEditionState = ProjectsTransactionalState;

export type ProjectsCreationState = ProjectsTransactionalState;

export type ProjectsPublishState = ProjectsTransactionalState & { publishing: boolean };

export type ProjectsDeletionState = {
    entityId: string;
    deletion: boolean;
    deletionError: Error;
};

export type ProjectsApplicationState = {
    selectedSkills: Array<Skill>;
};

export type ProjectsSearchProfilesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: ProjectsProfileSearchResult[];
    projectId: string;
    searchTerms: string[];
};

export type ProjectsProfileInvitationState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    message: ProjectsProfileInvitationMessage;
};

export type ProjectsUnseenApplicationsState = {
    loaded: boolean;
    error: Error;
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    projectsWithUnseenApplications: Array<string>;
};

export interface ProjectsSearchTopicUsersState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: Partial<GeneralData>[];
}

export const searchTopicUsersInitialState: ProjectsSearchTopicUsersState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
};

export const ProjectsProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(ProjectsActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(ProjectsActions.loadProfileSuccess, (state) => ({ ...state, loading: false, loaded: true, loadingError: null })),
    on(ProjectsActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(ProjectsActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(ProjectsActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(ProjectsActions.saveProfileError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(ProjectsActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
    on(ProjectsActions.markProfileStatusAsDirty, (state) => ({ ...state, dirty: true })),
);

export const ProjectsProfileSavedReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        categoriesInterestedIn: [],
    },
    on(ProjectsActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(ProjectsActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const ProjectsProfileCurrentReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        categoriesInterestedIn: [],
    },
    on(ProjectsActions.editCurrentProfile, (state, { currentProfile }) => ({ ...state, ...currentProfile })),
    on(ProjectsActions.loadProfileSuccess, (state, { profile, session }) => session || profile),
    on(ProjectsActions.loadProfileError, (state, { session }) => session || state),
    on(ProjectsActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        skills:
            state.skills.length > 0
                ? state.skills
                : account.generalData.externalSkills
                ? account.generalData.externalSkills.map((skill) => ({ name: skill, level: 1 }))
                : [],
    })),
);

export const ProjectsProfileReducer = combineReducers({
    status: ProjectsProfileStatusReducer,
    saved: ProjectsProfileSavedReducer,
    current: ProjectsProfileCurrentReducer,
});

export const ProjectsSearchReducer = createReducer<ProjectsSearchState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        searchQuery: '',
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    },
    on(ProjectsActions.searchEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(ProjectsActions.searchEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            pageParams,
        };
    }),
    on(ProjectsActions.searchEntitiesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
    on(ProjectsActions.applyToSuccess, (state, { searchResults }) => {
        return { ...state, results: [...searchResults] };
    }),
);

export const ProjectsStaffingReducer = createReducer<ProjectsStaffingState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        searchQuery: '',
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        filter: {
            categories: [],
            languages: [],
            isApplicationOpen: true,
            statuses: [ProjectsEntityStatus.PLANNED, ProjectsEntityStatus.RUNNING],
        },
        applicationsLoaded: false,
    },
    on(ProjectsActions.loadStaffingEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(ProjectsActions.loadStaffingEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            pageParams,
            applicationsLoaded: false,
        };
    }),
    on(ProjectsActions.loadStaffingEntitiesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(ProjectsActions.updateStaffingFilter, (state, { filter }) => {
        return {
            ...state,
            filter: filter,
        };
    }),
    on(ProjectsActions.resetStaffingApplications, (state) => {
        return {
            ...state,
            applicationsLoaded: false,
        };
    }),
    on(ProjectsActions.getStaffingApplicationsSuccess, (state, { data, entity }) => {
        return {
            ...state,
            applicationsLoaded: true,
            results: [
                ...state.results.map((result) => {
                    return {
                        ...result,
                        projectsEntity: {
                            ...result.projectsEntity,
                            applications: (result.projectsEntity.id === entity.id ? entity.applications : []).map(
                                (application) => {
                                    const profile = data.find((applicant) => applicant.id === application.id) || {};
                                    return {
                                        ...application,
                                        highlightedExactSkills: profile['highlightedExactSkills'] || [],
                                        highlightedByRelatedSkills: profile['highlightedByRelatedSkills'] || [],
                                        exactSkills: projectsUtilsService.getApplicantSkills(application),
                                        exactSkillsRelations:
                                            projectsUtilsService.getApplicantSkillRelations(application),
                                        notExactSkills: projectsUtilsService.getApplicantCollapsedSkills(application),
                                        notExactSkillsRelations:
                                            projectsUtilsService.getApplicantCollapsedSkillRelations(application),
                                    };
                                },
                            ),
                        },
                    };
                }),
            ],
        };
    }),
);

const ProjectsOfferedByMeReducer = createReducer<ProjectsOfferedByMeState>(
    { loaded: false, loading: false, applicationsSelectionsLoaded: false, entities: [], loadingError: null },
    on(ProjectsActions.loadEntitiesOfferedByMe, (state) => {
        return { ...state, loading: true };
    }),
    on(ProjectsActions.loadEntitiesOfferedByMeSuccess, (state, { entities }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            applicationsSelectionsLoaded: false,
            entities,
        };
    }),
    on(ProjectsActions.resetApplicationsSelectionsSuccess, (state) => {
        return {
            ...state,
            applicationsSelectionsLoaded: false,
        };
    }),
    on(ProjectsActions.getApplicationsSuccess, (state, { data }) => {
        return {
            ...state,
            applicationsSelectionsLoaded: true,
            entities: [
                ...state.entities.map((entity) => ({
                    ...entity,
                    applications: (entity.applications || []).map((application) => {
                        const applicationHighlight = data.find((applicant) => applicant.id === application.id) || {};
                        const updatedApplication = {
                            ...application,
                            ...applicationHighlight,
                        };
                        return {
                            ...updatedApplication,
                            exactSkills: projectsUtilsService.getApplicantSkills(updatedApplication),
                            exactSkillsRelations: projectsUtilsService.getApplicantSkillRelations(updatedApplication),
                            notExactSkills: projectsUtilsService.getApplicantCollapsedSkills(updatedApplication),
                            notExactSkillsRelations:
                                projectsUtilsService.getApplicantCollapsedSkillRelations(updatedApplication),
                        };
                    }),
                })),
            ],
        };
    }),
    on(ProjectsActions.loadEntitiesOfferedByMeError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(
        ProjectsActions.saveEntitySuccess,
        ProjectsActions.publishEntitySuccess,
        ProjectsActions.createEntitySuccess,
        ProjectsActions.updateEntity,
        ProjectsActions.finishOngoingProjectSuccess,
        ProjectsActions.finishProjectSuccess,
        (state, { entity }) => {
            const existingProjectIndex = state.entities.findIndex((project) => project.id === entity.id);
            // create new project
            if (existingProjectIndex === -1) {
                return {
                    ...state,
                    entities: [...state.entities, entity],
                };
            }

            // update existing project
            const existingProjects = state.entities[existingProjectIndex];
            const newProject = [...state.entities];
            newProject.splice(existingProjectIndex, 1, {
                ...existingProjects,
                ...entity,
                applications: (entity.applications || []).map((application) => {
                    const profile =
                        existingProjects.applications.find((applicant) => applicant.id === application.id) || {};
                    return {
                        ...application,
                        highlightedExactSkills: profile['highlightedExactSkills'] || [],
                        highlightedByRelatedSkills: profile['highlightedByRelatedSkills'] || [],
                        exactSkills: projectsUtilsService.getApplicantSkills(application),
                        exactSkillsRelations: projectsUtilsService.getApplicantSkillRelations(application),
                        notExactSkills: projectsUtilsService.getApplicantCollapsedSkills(application),
                        notExactSkillsRelations: projectsUtilsService.getApplicantCollapsedSkillRelations(application),
                    };
                }),
            });
            return { ...state, entities: newProject };
        },
    ),
    on(ProjectsActions.deleteEntitySuccess, (state, { entityId }) => {
        return {
            ...state,
            entities: state.entities.filter((entity) => entity.id !== entityId),
        };
    }),
    on(ProjectsActions.entityMarkApplicationsAsSeenSuccess, (state, { entityId }) => {
        const existingProjectIndex = state.entities.findIndex((project) => project.id === entityId);
        const existingProject = state.entities[existingProjectIndex];
        if (existingProject) {
            existingProject.hasUnseenApplications = false;
        }
        const newProjectEntities = [...state.entities];
        newProjectEntities.splice(existingProjectIndex, 1, { ...existingProject });
        return { ...state, entities: newProjectEntities };
    }),
);

const ProjectsMatchesReducer = createReducer<ProjectsMatchesState>(
    {
        loaded: false,
        loading: false,
        entities: [],
        loadingError: null,
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        selectedCampaigns: [],
    },
    on(ProjectsActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(ProjectsActions.loadMatchesSuccess, (state, { entities, pageParams, selectedCampaigns }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            entities: [...entities],
            pageParams,
            selectedCampaigns,
        };
    }),
    on(ProjectsActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(ProjectsActions.applyToSuccess, (state, { matches }) => {
        return { ...state, entities: [...matches] };
    }),
);

const projectsCreationReducer = createReducer(
    { projectsEntity: null, savingError: null, saving: false },
    on(ProjectsActions.createEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(ProjectsActions.createEntitySuccess, (state, { entity }) => ({
        ...state,
        projectsEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(ProjectsActions.createEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(ProjectsActions.publishEntitySuccess, () => ({
        projectsEntity: null,
        savingError: null,
        saving: false,
    })),
);

const projectsEditionReducer = createReducer(
    { projectsEntity: null, savingError: null, saving: false },
    on(ProjectsActions.saveEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(ProjectsActions.saveEntitySuccess, (state, { entity }) => ({
        ...state,
        projectsEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(ProjectsActions.saveEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(ProjectsActions.publishEntitySuccess, () => ({
        projectsEntity: null,
        savingError: null,
        saving: false,
    })),
);

const projectsDeletionReducer = createReducer<ProjectsDeletionState>(
    { entityId: null, deletion: false, deletionError: null },
    on(ProjectsActions.deleteEntity, (state, { entityId }) => ({ ...state, entityId, deletion: true })),
    on(ProjectsActions.deleteEntitySuccess, (state, { entityId }) => ({ ...state, entityId, deletion: false })),
    on(ProjectsActions.deleteEntityError, (state, { error }) => ({ ...state, deletion: false, deletionError: error })),
);

const projectsPublishReducer = createReducer(
    { projectsEntity: null, publishError: null, publishing: false },
    on(ProjectsActions.publishEntity, (state) => ({ ...state, publishing: true })),
    on(ProjectsActions.publishEntitySuccess, (state, { entity }) => ({
        ...state,
        projectsEntity: entity,
        publishing: false,
    })),
    on(ProjectsActions.publishEntityError, (state, { error }) => ({
        ...state,
        publishError: error,
        publishing: false,
    })),
);

const projectsSearchProfilesInitialState: ProjectsSearchProfilesState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
    projectId: null,
    searchTerms: [],
};

const projectsSearchProfilesReducer = createReducer<ProjectsSearchProfilesState>(
    projectsSearchProfilesInitialState,
    on(ProjectsActions.searchProfiles, (state, { projectId, searchTerms }) => ({
        ...state,
        loading: true,
        loaded: false,
        loadingError: null,
        results: [],
        projectId,
        searchTerms,
    })),
    on(ProjectsActions.searchProfilesSuccess, (state, { profiles }) => ({
        ...state,
        loading: false,
        loaded: true,
        results: profiles,
    })),
    on(ProjectsActions.searchProfilesError, (state, { error }) => ({
        ...state,
        loading: false,
        loadingError: error,
    })),
    on(ProjectsActions.resetSearchProfilesResults, () => ({
        ...projectsSearchProfilesInitialState,
    })),
    on(ProjectsActions.sendInvitationMessageSuccess, (state, { profileId }) => {
        const indexToUpdate = state.results.findIndex((result) => result.profile.user.id === profileId);
        return {
            ...state,
            results: state.results.map((result, index) => {
                if (index === indexToUpdate) {
                    return {
                        ...result,
                        notified: true,
                    };
                } else {
                    return result;
                }
            }),
        };
    }),
);

const projectsProfileInvitationReducer = createReducer<ProjectsProfileInvitationState>(
    { message: null, loaded: false, loading: false, loadingError: null },
    on(ProjectsActions.sendInvitationMessage, (state, { message }) => ({
        ...state,
        loading: true,
        loaded: false,
        loadingError: null,
        message: message,
    })),
    on(ProjectsActions.sendInvitationMessageSuccess, (state) => ({
        ...state,
        loading: false,
        loaded: true,
    })),
    on(ProjectsActions.sendInvitationMessageError, (state, { error }) => ({
        ...state,
        loading: false,
        loadingError: error,
    })),
);

const projectSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(ProjectsActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(ProjectsActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(ProjectsActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

const projectsUnseenApplicationsReducer = createReducer(
    {
        hasUnseenApplicationsOfferingTab: false,
        hasUnseenApplicationsTopicLink: false,
        projectsWithUnseenApplications: [],
        error: null,
        loaded: false,
    },
    on(ProjectsActions.getOwnerUnseenApplications, (state) => ({ ...state, loaded: false })),
    on(ProjectsActions.getOwnerUnseenApplicationsSuccess, (state, { applicationIndicators }) => ({
        ...state,
        ...applicationIndicators,
        loaded: true,
    })),
    on(ProjectsActions.getOwnerUnseenApplicationsError, (state, { error }) => ({
        ...state,
        error,
        loaded: false,
    })),
    on(ProjectsActions.topicLinkIndicatorMarkAsSeenSuccess, (state) => ({
        ...state,
        hasUnseenApplicationsTopicLink: false,
    })),
    on(ProjectsActions.offeringTabIndicatorMarkAsSeenSuccess, (state) => ({
        ...state,
        hasUnseenApplicationsOfferingTab: false,
    })),
    on(ProjectsActions.entityMarkApplicationsAsSeenSuccess, (state, { entityId }) => ({
        ...state,
        projectsWithUnseenApplications: state.projectsWithUnseenApplications.filter((entity) => entity.id !== entityId),
    })),
);

const projectsApplicationSkillsReducer = createReducer<ProjectsApplicationState>(
    { selectedSkills: [] },
    on(ProjectsActions.storeSelectedSkills, (state, { selectedSkills }) => ({
        ...state,
        selectedSkills,
    })),
    on(ProjectsActions.applyToSuccess, (state) => ({ ...state, selectedSkills: [] })),
);

export const ProjectsSearchTopicUsersReducer = createReducer(
    searchTopicUsersInitialState,
    on(ProjectsActions.clearSearchTopicUsers, (state) => {
        return { ...state, results: [], loaded: false };
    }),
    on(ProjectsActions.searchTopicUsers, (state) => {
        return { ...state, loading: true };
    }),
    on(ProjectsActions.searchTopicUsersSuccess, (state, { results }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results,
        };
    }),
    on(ProjectsActions.searchTopicUsersError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);

export const ProjectsReducer = combineReducers({
    profile: ProjectsProfileReducer,
    searchResults: ProjectsSearchReducer,
    staffing: ProjectsStaffingReducer,
    offeredByMe: ProjectsOfferedByMeReducer,
    matches: ProjectsMatchesReducer,
    edition: projectsEditionReducer,
    creation: projectsCreationReducer,
    deletion: projectsDeletionReducer,
    publish: projectsPublishReducer,
    searchProfiles: projectsSearchProfilesReducer,
    profileInvitation: projectsProfileInvitationReducer,
    suggestedSkills: projectSuggestedSkillsReducer,
    unseenApplications: projectsUnseenApplicationsReducer,
    applicationSkills: projectsApplicationSkillsReducer,
    searchTopicUsers: ProjectsSearchTopicUsersReducer,
});
