import { Injectable } from '@angular/core';
import { NavigationError, Params, Router, UrlTree } from '@angular/router';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import { isObservable, Observable, of } from 'rxjs';
import { StorageService } from './core-modules/storage/service/storage.service';
import { NoRequiredPermissionsException, RedirectByPermissionsException } from './core-modules/authorization';
import { RoutingSequenceException } from './core-modules/routing-sequence/routing-sequence.exception';

const ATTEMPTED_ROUTE = 'tp.attempted_route';
const DEFAULT_AUTHENTICATED_ROUTE = '/dashboard';
const AUTHENTICATION_REDIRECT_ENDPOINT = '/login';
const REDIRECT_URL = 'tp.redirect_url';

@Injectable({
    providedIn: 'root',
})
export class RedirectService {
    private attemptedRoute: string;

    constructor(
        private router: Router,
        private store: Store<{ router: RouterReducerState }>,
        private storage: StorageService,
    ) {
        // Consumes the attempted route from storage if found
        // from then on, it is either used or lost on next page load
        this.attemptedRoute = this.getStoredRoute();
        this.deleteAttemptedRoute();

        this.removeRedirectUrl();
    }

    private getAttemptedUrl(): Observable<string> {
        const routerSelector = (state: { router: RouterReducerState }) => state.router;
        const { selectUrl } = getSelectors(routerSelector);

        return this.store.select(selectUrl);
    }

    redirect(url: string | UrlTree | Observable<string | UrlTree>): void {
        if (!isObservable(url)) {
            url = of(url);
        }

        url.pipe(take(1)).subscribe((u) => this.router.navigateByUrl(u));
    }

    getRedirect(
        area: 'authenticated' | 'unauthenticated',
        onNavigationDenial = true,
        queryParams: Params,
    ): Observable<UrlTree> {
        if (area === 'authenticated') {
            const route = this.attemptedRoute || this.getRedirectUrl() || DEFAULT_AUTHENTICATED_ROUTE;
            return of(route).pipe(
                map((url) => this.coalesceUrlTree(url, queryParams)),
                tap(() => {
                    this.removeRedirectUrl();
                    this.attemptedRoute = null;
                }),
            );
        }

        if (area === 'unauthenticated') {
            return this.getAttemptedUrl().pipe(
                tap((attemptedUrl) => {
                    if (onNavigationDenial) {
                        this.storeAttemptedRoute(attemptedUrl);
                    }
                }),
                map(() => this.coalesceUrlTree(AUTHENTICATION_REDIRECT_ENDPOINT, queryParams)),
            );
        }
    }

    storeRedirectUrl(url: string): void {
        this.storage.session.set(REDIRECT_URL, url);
    }

    getRedirectUrl(): string | null {
        return this.storage.session.get<string, null>(REDIRECT_URL, null);
    }

    removeRedirectUrl(): void {
        this.storage.session.remove(REDIRECT_URL);
    }

    private storeAttemptedRoute(route: string | UrlTree): void {
        const url = this.coalesceString(route);

        if (!!url && url !== '/' && url !== AUTHENTICATION_REDIRECT_ENDPOINT) {
            this.storage.session.set(ATTEMPTED_ROUTE, url);
        }
    }

    private deleteAttemptedRoute(): void {
        this.storage.session.remove(ATTEMPTED_ROUTE);
    }

    private getStoredRoute(): string {
        return this.storage.session.get<string, null>(ATTEMPTED_ROUTE, null);
    }

    private coalesceUrlTree(url: string | UrlTree, queryParams: Params): UrlTree {
        if (!url) {
            return null;
        }

        if (typeof url === 'string') {
            const urlTree = this.router.parseUrl(url);
            urlTree.queryParams = queryParams;
            return urlTree;
        }

        return url;
    }

    private coalesceString(url: string | UrlTree): string {
        if (!url) {
            return null;
        }

        if (typeof url === 'string') {
            return url;
        }

        return this.router.serializeUrl(url);
    }

    isNavigationStepError(error: NavigationError): boolean {
        return (
            error instanceof RedirectByPermissionsException ||
            error instanceof NoRequiredPermissionsException ||
            error instanceof RoutingSequenceException
        );
    }
}
