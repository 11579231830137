import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'tp-account-list-item-get-to-know',
    templateUrl: './account-list-item-get-to-know.component.html',
    host: {
        class: 'account-tile__get-to-know',
    },
})
export class AccountListItemGetToKnowComponent {
    @Input() userId: string;
    @Input() context: string;

    constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

    openDialog($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();

        const navigationExtras = { queryParams: { ref: this.context } };
        const commands = ['/', { outlets: { overlay: ['get-to-know', this.userId] } }];
        this.router.navigate(commands, navigationExtras);
    }
}
