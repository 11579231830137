import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AccountService } from '../../core-modules/account/account.service';
import { OthersAccount } from '../../core-modules/account/account.types';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { UnknownRouteService } from '../../core-modules/unknown-route/unknown-route.service';
import { AccountNotFoundException } from '../../core-modules/account/account-not-found.exception';

@Injectable({
    providedIn: 'root',
})
export class OthersProfileResolver implements Resolve<OthersAccount> {
    constructor(
        private readonly accountService: AccountService,
        private readonly unknownRouteService: UnknownRouteService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<OthersAccount> {
        const id = route.paramMap.get('id');

        if (!id) {
            throw new Error('There was no `id` parameter in the route.');
        }
        return this.readAccount(id).pipe(take(1));
    }

    private readAccount(id: string): Observable<OthersAccount> {
        return this.accountService.getAccountById(id).pipe(
            catchError((error) => {
                if (error instanceof AccountNotFoundException) {
                    this.unknownRouteService.handle404();
                }
                return EMPTY;
            }),
        );
    }
}
