import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models/direct-messages-conversation.types';

export const DirectMessagesLoadConversationActions = {
    // Load Conversation Action
    loadConversation: createAction('[DM-Conversations] Load Conversation', props<{ conversationId: string }>()),
    loadConversationSuccess: createAction(
        '[DM-Conversations][Success] Load Conversation',
        props<{ currentConversation: Conversation; conversations: Conversation[] }>(),
    ),
    loadConversationError: createAction('[DM-Conversations][Error] Load Conversation', props<{ error: Error }>()),
    unloadConversation: createAction('[DM-Conversations] Unload Conversation', props<{ clearStorage: boolean }>()),
    unloadConversationSuccess: createAction('[DM-Conversations][Success] Unload Conversation'),
};
