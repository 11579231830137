import { Component, OnInit } from '@angular/core';
import { merge, Observable, partition, timer } from 'rxjs';
import { debounce, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { RouteTransitionLoaderVisibilityService } from '../services/route-transition-loader-visibility.service';
import { PendingRequestsInterceptor } from '../services/pending-requests-interceptor.service';

@Component({
    selector: 'tp-route-transition-loader',
    templateUrl: './route-transition-loader.component.html',
})
export class RouteTransitionLoaderComponent implements OnInit {
    public isVisible$: Observable<boolean>;
    private visibleUntil = Date.now();

    private debounceDelay = 0;
    private minDuration = 200;

    constructor(
        private pendingRequestsInterceptor: PendingRequestsInterceptor,
        private routeTransitionLoaderVisibilityService: RouteTransitionLoaderVisibilityService,
    ) {}

    private initIsVisibleObservable(): void {
        const [showSpinner$, hideSpinner$] = partition(
            this.pendingRequestsInterceptor.pendingRequestsStatus$,
            (h) => h,
        );

        this.isVisible$ = merge(
            this.pendingRequestsInterceptor.pendingRequestsStatus$.pipe(
                switchMap(() => showSpinner$.pipe(debounce(() => timer(this.debounceDelay)))),
            ),
            showSpinner$.pipe(switchMap(() => hideSpinner$.pipe(debounce(() => this.getVisibilityTimer$())))),
            this.routeTransitionLoaderVisibilityService.visibility$,
        ).pipe(
            distinctUntilChanged(),
            tap((h) => this.updateExpirationDelay(h)),
        );
    }

    public ngOnInit(): void {
        this.initIsVisibleObservable();
    }

    private updateExpirationDelay(showSpinner: boolean): void {
        if (showSpinner) {
            this.visibleUntil = Date.now() + this.minDuration;
        }
    }

    private getVisibilityTimer$(): Observable<number> {
        return timer(this.visibleUntil - Date.now());
    }
}
