import { NgModule } from '@angular/core';
import { SignUpMenuModule } from './sign-up-menu/sign-up-menu.module';
import { LanguageModule, InfoToggleModule } from '@tploy-enterprise/tenant-common';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [SignUpMenuModule, ButtonModule, LanguageModule, InfoToggleModule, MatButtonModule],
    exports: [SignUpMenuModule, LanguageModule, InfoToggleModule, MatButtonModule],
})
export class SignUpLayoutModule {}
