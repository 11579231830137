import { combineReducers, createReducer, on } from '@ngrx/store';
import { STAActions } from './sta.actions';
import {
    AccountActions,
    GeneralData,
    PageParams,
    SuccessFactorsActions,
    SuccessFactorsUtilsService,
} from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';
import {
    PROFILE_STORAGE_KEY,
    STAEntity,
    STAEntityStatus,
    STAProfile,
    STAProfileInvitationMessage,
    STAProfileMatch,
    STAProfileSearchResult,
    STASearchResult,
    StaStaffingFilter,
    STASuggestedSkills,
} from './sta.types';
import { STAUtilsService } from './sta-domain/sta-utils.service';

const staUtilsService = new STAUtilsService();
const successFactorsUtilsService = new SuccessFactorsUtilsService();

export type STAState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    profile: STAProfileState;
    searchResults: STASearchState;
    staffing: STAStaffingState;
    offeredByMe: STAOfferedByMeState;
    matches: STAMatchesState;
    edition: STAEditionState;
    creation: STACreationState;
    deletion: STADeletionState;
    publish: STAPublishState;
    searchProfiles: STASearchProfilesState;
    profileInvitation: STAProfileInvitationState;
    suggestedSkills: SuggestedSkillsState;
    unseenApplications: STAUnseenApplicationsState;
    applicationSkills: STAApplicationState;
    searchTopicUsers: STASearchTopicUsersState;
};

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: STASuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type STAProfileState = {
    status: StateStatus;
    saved: STAProfile;
    current: STAProfile;
};

export type STASearchState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: STASearchResult[];
    searchQuery: string;
    pageParams: PageParams;
};

export type STAStaffingState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: STASearchResult[];
    searchQuery: string;
    pageParams: PageParams;
    filter: StaStaffingFilter;
    applicationsLoaded: boolean;
};

export type STAOfferedByMeState = {
    loaded: boolean;
    loading: boolean;
    applicationsSelectionsLoaded: boolean;
    loadingError: Error;
    entities: STAEntity[];
};

export type STAMatchesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    entities: STAProfileMatch[];
    pageParams: PageParams;
    selectedCampaigns: string[];
};

export type STATransactionalState = {
    staEntity: STAEntity;
    savingError: Error;
    saving: boolean;
};

export type STAEditionState = STATransactionalState;

export type STACreationState = STATransactionalState;

export type STAPublishState = STATransactionalState & { publishing: boolean };

export type STASearchProfilesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: STAProfileSearchResult[];
    staId: string;
    searchTerms: string[];
};

export type STAProfileInvitationState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    message: STAProfileInvitationMessage;
};

export type STADeletionState = {
    entityId: string;
    deletion: boolean;
    deletionError: Error;
};

export type STAUnseenApplicationsState = {
    loaded: boolean;
    error: Error;
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    shortTimeAssignmentsWithUnseenApplications: Array<string>;
};

export type STAApplicationState = {
    selectedSkills: Array<Skill>;
};

export interface STASearchTopicUsersState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: Partial<GeneralData>[];
}

export const searchTopicUsersInitialState: STASearchTopicUsersState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
};

export const STAProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(STAActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(STAActions.loadProfileSuccess, (state) => ({ ...state, loading: false, loaded: true, loadingError: null })),
    on(STAActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(STAActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(STAActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(STAActions.saveProfileError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(STAActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
);

export const STAProfileSavedReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        categoriesInterestedIn: [],
    },
    on(STAActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(STAActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const STAProfileCurrentReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        categoriesInterestedIn: [],
    },
    on(STAActions.editCurrentProfile, (state, { currentProfile }) => ({
        ...state,
        ...currentProfile,
    })),
    on(STAActions.loadProfileSuccess, (state, { profile, session }) => {
        return {
            ...(session || profile),
            loadingError: null,
        };
    }),
    on(STAActions.loadProfileError, (state, { session }) => {
        return {
            ...(session || state),
            loadingError: (session || state)['loadingError'] || {},
        };
    }),
    on(STAActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => {
        return {
            ...state,
            skills:
                state.skills.length > 0
                    ? state.skills
                    : account.generalData.externalSkills
                    ? account.generalData.externalSkills.map((skill) => ({ name: skill, level: 1 }))
                    : [],
        };
    }),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => {
        if (!state['loadingError'] && state.motivations?.length > 0) {
            return state;
        } else {
            const sessionProfile = JSON.parse(localStorage.getItem(PROFILE_STORAGE_KEY));
            if (sessionProfile) {
                localStorage.setItem(
                    PROFILE_STORAGE_KEY,
                    JSON.stringify({
                        ...sessionProfile,
                        skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.skills, ...skills]),
                    }),
                );
            }
            return {
                ...state,
                skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.skills, ...skills]),
            };
        }
    }),
    on(STAActions.syncSuccessFactorsSkills, (state, { skills }) => {
        return {
            ...state,
            skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.skills, ...skills]),
        };
    }),
);

export const STAProfileReducer = combineReducers({
    status: STAProfileStatusReducer,
    saved: STAProfileSavedReducer,
    current: STAProfileCurrentReducer,
});

export const STASearchReducer = createReducer<STASearchState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        searchQuery: '',
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    },
    on(STAActions.searchEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(STAActions.searchEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            pageParams,
        };
    }),
    on(STAActions.searchEntitiesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(STAActions.applyToSuccess, (state, { searchResults }) => {
        return { ...state, results: [...searchResults] };
    }),
);

export const STAStaffingReducer = createReducer<STAStaffingState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        searchQuery: '',
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        filter: {
            categories: [],
            languages: [],
            isApplicationOpen: true,
            statuses: [STAEntityStatus.PLANNED, STAEntityStatus.RUNNING],
        },
        applicationsLoaded: false,
    },
    on(STAActions.loadStaffingEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(STAActions.loadStaffingEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            applicationsLoaded: false,
            pageParams,
        };
    }),
    on(STAActions.loadStaffingEntitiesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(STAActions.updateStaffingFilter, (state, { filter }) => {
        return {
            ...state,
            filter: filter,
        };
    }),
    on(STAActions.resetStaffingApplications, (state) => {
        return {
            ...state,
            applicationsLoaded: false,
        };
    }),
    on(STAActions.getStaffingApplicationsSuccess, (state, { data, entity }) => {
        return {
            ...state,
            applicationsLoaded: true,
            results: [
                ...state.results.map((result) => {
                    return {
                        ...result,
                        staEntity: {
                            ...result.staEntity,
                            applications: (result.staEntity.id === entity.id ? entity.applications : []).map(
                                (application) => {
                                    const profile =
                                        data.applications.find((applicant) => applicant.id === application.id) || {};
                                    const updatedApplication = {
                                        ...application,
                                        profile: {
                                            ...(application || {}).profile,
                                            highlightedExactSkills: profile['highlightedExactSkills'] || [],
                                            highlightedByRelatedSkills: profile['highlightedByRelatedSkills'] || [],
                                        },
                                    };
                                    return {
                                        ...updatedApplication,
                                        profile: {
                                            ...updatedApplication.profile,
                                            requiredExactSkills: staUtilsService.getFilteredMatchedSkills(
                                                updatedApplication,
                                                true,
                                                true,
                                                entity,
                                            ),
                                            requiredExactSkillsRelations: staUtilsService.getRelatedSkills(
                                                updatedApplication,
                                                true,
                                                true,
                                                entity,
                                            ),
                                            requiredNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                                updatedApplication,
                                                false,
                                                true,
                                                entity,
                                            ),
                                            requiredNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                                updatedApplication,
                                                false,
                                                true,
                                                entity,
                                            ),
                                            nthExactSkills: staUtilsService.getFilteredMatchedSkills(
                                                updatedApplication,
                                                true,
                                                false,
                                                entity,
                                            ),
                                            nthExactSkillsRelations: staUtilsService.getRelatedSkills(
                                                updatedApplication,
                                                true,
                                                false,
                                                entity,
                                            ),
                                            nthNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                                updatedApplication,
                                                false,
                                                false,
                                                entity,
                                            ),
                                            nthNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                                updatedApplication,
                                                false,
                                                false,
                                                entity,
                                            ),
                                        },
                                    };
                                },
                            ),
                        },
                    };
                }),
            ],
        };
    }),
);

const STAOfferedByMeReducer = createReducer<STAOfferedByMeState>(
    { loaded: false, loading: false, applicationsSelectionsLoaded: false, entities: [], loadingError: null },
    on(STAActions.loadEntitiesOfferedByMe, (state) => {
        return { ...state, loading: true };
    }),
    on(STAActions.loadEntitiesOfferedByMeSuccess, (state, { entities }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            applicationsSelectionsLoaded: false,
            entities: [
                ...entities.map((entity) => ({
                    ...entity,
                    applications: (entity.applications || []).map((application) => {
                        return {
                            ...application,
                            profile: {
                                ...application.profile,
                                requiredExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    application,
                                    true,
                                    true,
                                    entity,
                                ),
                                requiredExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    application,
                                    true,
                                    true,
                                    entity,
                                ),
                                requiredNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    application,
                                    false,
                                    true,
                                    entity,
                                ),
                                requiredNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    application,
                                    false,
                                    true,
                                    entity,
                                ),
                                nthExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    application,
                                    true,
                                    false,
                                    entity,
                                ),
                                nthExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    application,
                                    true,
                                    false,
                                    entity,
                                ),
                                nthNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    application,
                                    false,
                                    false,
                                    entity,
                                ),
                                nthNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    application,
                                    false,
                                    false,
                                    entity,
                                ),
                            },
                        };
                    }),
                })),
            ],
        };
    }),
    on(STAActions.resetApplicationsSelectionsSuccess, (state) => {
        return {
            ...state,
            applicationsSelectionsLoaded: false,
        };
    }),
    on(STAActions.getApplicationsSelectionsSuccess, (state, { data }) => {
        return {
            ...state,
            applicationsSelectionsLoaded: true,
            entities: [
                ...state.entities.map((entity) => ({
                    ...entity,
                    applications: (entity.applications || []).map((application) => {
                        const profile = data.applications.find((applicant) => applicant.id === application.id) || {};
                        const updatedApplication = {
                            ...application,
                            profile: {
                                ...application.profile,
                                highlightedExactSkills: profile['highlightedExactSkills'] || [],
                                highlightedByRelatedSkills: profile['highlightedByRelatedSkills'] || [],
                            },
                        };
                        return {
                            ...updatedApplication,
                            profile: {
                                ...updatedApplication.profile,
                                requiredExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    updatedApplication,
                                    true,
                                    true,
                                    entity,
                                ),
                                requiredExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    updatedApplication,
                                    true,
                                    true,
                                    entity,
                                ),
                                requiredNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    updatedApplication,
                                    false,
                                    true,
                                    entity,
                                ),
                                requiredNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    updatedApplication,
                                    false,
                                    true,
                                    entity,
                                ),
                                nthExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    updatedApplication,
                                    true,
                                    false,
                                    entity,
                                ),
                                nthExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    updatedApplication,
                                    true,
                                    false,
                                    entity,
                                ),
                                nthNotExactSkills: staUtilsService.getFilteredMatchedSkills(
                                    updatedApplication,
                                    false,
                                    false,
                                    entity,
                                ),
                                nthNotExactSkillsRelations: staUtilsService.getRelatedSkills(
                                    updatedApplication,
                                    false,
                                    false,
                                    entity,
                                ),
                            },
                        };
                    }),
                })),
            ],
        };
    }),
    on(STAActions.loadEntitiesOfferedByMeError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(
        STAActions.saveEntitySuccess,
        STAActions.publishEntitySuccess,
        STAActions.createEntitySuccess,
        STAActions.updateEntity,
        (state, { entity }) => {
            const existingStaIndex = state.entities.findIndex((sta) => sta.id === entity.id);
            // create new sta
            if (existingStaIndex === -1) {
                return {
                    ...state,
                    entities: [...state.entities, entity],
                };
            }

            // update existing sta
            const existingStas = state.entities[existingStaIndex];
            const newSta = [...state.entities];
            newSta.splice(existingStaIndex, 1, {
                ...existingStas,
                ...entity,
                applications: (entity.applications || []).map((application) => {
                    const profile =
                        existingStas.applications.find((applicant) => applicant.id === application.id) || {};
                    return {
                        ...application,
                        profile: {
                            ...application.profile,
                            highlightedExactSkills: profile['highlightedExactSkills'] || [],
                            highlightedByRelatedSkills: profile['highlightedByRelatedSkills'] || [],
                        },
                    };
                }),
            });
            return { ...state, entities: newSta };
        },
    ),
    on(STAActions.deleteEntitySuccess, (state, { entityId }) => {
        return {
            ...state,
            entities: state.entities.filter((entity) => entity.id !== entityId),
        };
    }),
    on(STAActions.entityMarkApplicationsAsSeenSuccess, (state, { entityId }) => {
        // update existing sta
        const existingStaIndex = state.entities.findIndex((sta) => sta.id === entityId);
        const existingSta = state.entities[existingStaIndex];
        if (existingSta) {
            existingSta.hasUnseenApplications = false;
        }
        const newStaEntities = [...state.entities];
        newStaEntities.splice(existingStaIndex, 1, { ...existingSta });
        return { ...state, entities: newStaEntities };
    }),
);

const STAMatchesReducer = createReducer<STAMatchesState>(
    {
        loaded: false,
        loading: false,
        entities: [],
        loadingError: null,
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        selectedCampaigns: [],
    },
    on(STAActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(STAActions.loadMatchesSuccess, (state, { entities, pageParams, selectedCampaigns }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            entities: [...entities],
            pageParams,
            selectedCampaigns,
        };
    }),
    on(STAActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(STAActions.applyToSuccess, (state, { matches }) => {
        return { ...state, entities: [...matches] };
    }),
);

const staCreationReducer = createReducer(
    { staEntity: null, savingError: null, saving: false },
    on(STAActions.createEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(STAActions.createEntitySuccess, (state, { entity }) => ({
        ...state,
        staEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(STAActions.createEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(STAActions.publishEntitySuccess, () => ({
        staEntity: null,
        savingError: null,
        saving: false,
    })),
);

const staEditionReducer = createReducer(
    { staEntity: null, savingError: null, saving: false },
    on(STAActions.saveEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(STAActions.saveEntitySuccess, (state, { entity }) => ({
        ...state,
        staEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(STAActions.saveEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(STAActions.publishEntitySuccess, () => ({
        staEntity: null,
        savingError: null,
        saving: false,
    })),
);

const staDeletionReducer = createReducer<STADeletionState>(
    { entityId: null, deletion: false, deletionError: null },
    on(STAActions.deleteEntity, (state, { entityId }) => ({ ...state, entityId, deletion: true })),
    on(STAActions.deleteEntitySuccess, (state, { entityId }) => ({ ...state, entityId, deletion: false })),
    on(STAActions.deleteEntityError, (state, { error }) => ({ ...state, deletion: false, deletionError: error })),
);

const staPublishReducer = createReducer(
    { staEntity: null, publishError: null, publishing: false },
    on(STAActions.publishEntity, (state) => ({ ...state, publishing: true })),
    on(STAActions.publishEntitySuccess, (state, { entity }) => ({
        ...state,
        staEntity: entity,
        publishing: false,
    })),
    on(STAActions.publishEntityError, (state, { error }) => ({
        ...state,
        publishError: error,
        publishing: false,
    })),
);

const staSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(STAActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(STAActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(STAActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

const staUnseenApplicationsReducer = createReducer(
    {
        hasUnseenApplicationsOfferingTab: false,
        hasUnseenApplicationsTopicLink: false,
        shortTimeAssignmentsWithUnseenApplications: [],
        error: null,
        loaded: false,
    },
    on(STAActions.getOwnerUnseenApplications, (state) => ({ ...state, loaded: false })),
    on(STAActions.getOwnerUnseenApplicationsSuccess, (state, { applicationIndicators }) => ({
        ...state,
        ...applicationIndicators,
        loaded: true,
    })),
    on(STAActions.getOwnerUnseenApplicationsError, (state, { error }) => ({
        ...state,
        error,
        loaded: false,
    })),
    on(STAActions.topicLinkIndicatorMarkAsSeenSuccess, (state) => ({
        ...state,
        hasUnseenApplicationsTopicLink: false,
    })),
    on(STAActions.offeringTabIndicatorMarkAsSeenSuccess, (state) => ({
        ...state,
        hasUnseenApplicationsOfferingTab: false,
    })),
    on(STAActions.entityMarkApplicationsAsSeenSuccess, (state, { entityId }) => ({
        ...state,
        shortTimeAssignmentsWithUnseenApplications: state.shortTimeAssignmentsWithUnseenApplications.filter(
            (entity) => entity.id !== entityId,
        ),
    })),
);

const staApplicationSkillsReducer = createReducer<STAApplicationState>(
    { selectedSkills: [] },
    on(STAActions.storeSelectedSkills, (state, { selectedSkills }) => ({
        ...state,
        selectedSkills,
    })),
    on(STAActions.applyToSuccess, (state) => ({ ...state, selectedSkills: [] })),
);

const staSearchProfilesInitialState: STASearchProfilesState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
    staId: null,
    searchTerms: [],
};

const STASearchProfilesReducer = createReducer<STASearchProfilesState>(
    staSearchProfilesInitialState,
    on(STAActions.searchProfiles, (state, { staId, searchTerms }) => ({
        ...state,
        loading: true,
        loaded: false,
        loadingError: null,
        results: [],
        staId,
        searchTerms,
    })),
    on(STAActions.searchProfilesSuccess, (state, { profiles }) => ({
        ...state,
        loading: false,
        loaded: true,
        results: profiles,
    })),
    on(STAActions.searchProfilesError, (state, { error }) => ({
        ...state,
        loading: false,
        loadingError: error,
    })),
    on(STAActions.resetSearchProfilesResults, () => ({
        ...staSearchProfilesInitialState,
    })),
    on(STAActions.sendInvitationMessageSuccess, (state, { profileId }) => {
        const indexToUpdate = state.results.findIndex((result) => result.profile.user.id === profileId);
        return {
            ...state,
            results: state.results.map((result, index) => {
                if (index === indexToUpdate) {
                    return {
                        ...result,
                        notified: true,
                    };
                } else {
                    return result;
                }
            }),
        };
    }),
);

const STAProfileInvitationReducer = createReducer<STAProfileInvitationState>(
    { message: null, loaded: false, loading: false, loadingError: null },
    on(STAActions.sendInvitationMessage, (state, { message }) => ({
        ...state,
        loading: true,
        loaded: false,
        loadingError: null,
        message: message,
    })),
    on(STAActions.sendInvitationMessageSuccess, (state) => ({
        ...state,
        loading: false,
        loaded: true,
    })),
    on(STAActions.sendInvitationMessageError, (state, { error }) => ({
        ...state,
        loading: false,
        loadingError: error,
    })),
);

export const STASearchTopicUsersReducer = createReducer(
    searchTopicUsersInitialState,
    on(STAActions.clearSearchTopicUsers, (state) => {
        return { ...state, results: [], loaded: false };
    }),
    on(STAActions.searchTopicUsers, (state) => {
        return { ...state, loading: true };
    }),
    on(STAActions.searchTopicUsersSuccess, (state, { results }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results,
        };
    }),
    on(STAActions.searchTopicUsersError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);

export const STAReducer = combineReducers({
    profile: STAProfileReducer,
    searchResults: STASearchReducer,
    staffing: STAStaffingReducer,
    offeredByMe: STAOfferedByMeReducer,
    matches: STAMatchesReducer,
    edition: staEditionReducer,
    creation: staCreationReducer,
    deletion: staDeletionReducer,
    publish: staPublishReducer,
    suggestedSkills: staSuggestedSkillsReducer,
    unseenApplications: staUnseenApplicationsReducer,
    applicationSkills: staApplicationSkillsReducer,
    searchProfiles: STASearchProfilesReducer,
    profileInvitation: STAProfileInvitationReducer,
    searchTopicUsers: STASearchTopicUsersReducer,
});
