import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsProfileViewComponent } from './projects-profile-view/projects-profile-view.component';
import { ProfileContentModule, LanguageModule, SkillModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [ProjectsProfileViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule, SkillModule],
})
export class ProjectsProfileViewModule {}
