import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsViewerComponent } from './tags-viewer.component';
import { TranslatableModule } from '../language/translatable/translatable.module';

@NgModule({
    declarations: [TagsViewerComponent],
    exports: [TagsViewerComponent],
    imports: [CommonModule, TranslatableModule],
})
export class TagsViewerModule {}
