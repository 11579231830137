import { SkillDTO, SuggestedSkills } from '@tploy-enterprise/tenant-common';

export type GenerationExchangeMotivationDTO = 'exchangeKnowledge' | 'generationsCommunication' | 'networking';

export interface GenerationExchangeProfileDTO {
    campaigns: string[];
    user: GenerationExchangeUserDTO;
    locationsForMeet: { name: string }[];
    interestedInLocationType: GenerationExchangeLocationType;
    skills: SkillDTO[];
    generation: number;
    motivations: GenerationExchangeMotivationDTO[];
}

export interface GenerationExchangeUserDTO {
    id: string;
    imageUrl: string;
    name: string;
    languages?: string[];
    locations?: { name: string }[];
    message: string;
    groups: string[];
}

export enum GenerationExchangeLocationType {
    OWN = 'OWN',
    ONLINE = 'ONLINE',
}

export type GenerationExchangeSuggestedSkillsDTO = SuggestedSkills;

export type GenerationExchangeProfileMatchResponseDTO = {
    results: GenerationExchangeProfileMatchDTO[];
    totalCount: number;
};

export type GenerationExchangeSearchResponseDTO = {
    results: GenerationExchangeSearchResultDTO[];
    totalCount: number;
};

export type GenerationExchangeProfileMatchDTO = {
    score: number;
    highlight: GenerationExchangeHighlightDTO;
    profile: GenerationExchangeProfileDTO;
};

export type GenerationExchangeSearchResultDTO = {
    relevance: number;
    highlight: GenerationExchangeHighlightDTO;
    profile: GenerationExchangeProfileDTO;
};

export type GenerationExchangeHighlightDTO = {
    skills: string[];
    isOnlineMatch: boolean;
    locations: { name: string }[];
};
