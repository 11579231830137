import { Injectable } from '@angular/core';
import { ExpertService } from '../../expert-api/expert.service';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ExpertSearchActions } from './expert-search.actions';
import { CommonActions, CommonState } from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class ExpertSearchEffects {
    constructor(
        private actions$: Actions,
        private service: ExpertService,
        private readonly store: Store<{ common: CommonState }>,
    ) {}

    searchEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertSearchActions.searchEntities),
            switchMap((action) => this.service.searchEntities(action.searchQuery, action.pageParams)),
            map((searchResult) =>
                ExpertSearchActions.searchEntitiesSuccess({
                    results: searchResult.results,
                    allResults: searchResult.allResults,
                    pageParams: searchResult.pageParams,
                }),
            ),
        ),
    );

    loadSearchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertSearchActions.loadSearchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.service.loadSearchResult(action.searchQuery, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return ExpertSearchActions.loadSearchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(ExpertSearchActions.loadError({ error }));
                    }),
                );
            }),
        ),
    );
}
