export default {
    EXPERT: 'Expertenfinder',
    EXPERT_INFO:
        'Mit dem Expertenfinder findest du schnell und zielgerichtet bestimmte Skills in Ihrer Organisation. Du suchst einen Profi für ein bestimmtes Thema oder Tool und möchten herausfinden, welche deiner Kolleg*innen über die passenden Skills verfügen? Dann wähle den „Expertenfinder“ als dein Interesse und spezifiziere, welche Art von Skills du aktuell im Unternehmen suchst. Deine Auswahl kannst du jederzeit anpassen und erweitern – und natürlich auch angeben, wofür du selber öfter um Rat gefragt wirst. Vielleicht möchtest ja auch du Ihre Spezialthemen mit anderen teilen – und diese sogar um Skills oder Talente ergänzen, von denen niemand etwas ahnt.',
    SIGN_UP_EXPERT_TITLE: 'Expertenfinder',
    SIGN_UP_EXPERT_SUBTITLE: 'Ich suche Fachwissen und / oder möchte mein Wissen teilen.',

    PROFILE_EXPERT_TITLE: 'Expertenfinder',
    PROFILE_EXPERT_SUBTITLE: 'Ich suche Fachwissen und / oder möchte mein Wissen teilen.',

    PROFILE_EXPERT_LOCATION_LABEL: 'Mein Standort',
    PROFILE_EXPERT_COMMUNICATE_OPTIONS_LABEL: 'Wie möchtest du kommunizieren?',
    PROFILE_EXPERT_COMMUNICATE_OPTION_1: 'Persönlich an meinem Standort',
    PROFILE_EXPERT_COMMUNICATE_OPTION_2: 'Virtuell',
    PROFILE_EXPERT_COMMUNICATE_OPTION_3: 'Beides ist möglich',
    PROFILE_EXPERT_SKILLS_INTEREST_LABEL: 'Über welche Experten möchtest du informiert werden?',
    PROFILE_EXPERT_SKILLS_INTEREST_PLACEHOLDER: 'z.B. WordPress, Slack, Executive Development, Powerpoint',
    PROFILE_EXPERT_OFFER_SKILLS_LABEL: 'Zu welchen Fähigkeiten und Werkzeugen bitten Kolleg*innen dich um Rat?',
    PROFILE_EXPERT_OFFER_SKILLS_PLACEHOLDER: 'z.B. WordPress, Slack, Executive Development, Powerpoint',

    EXPERT_AVAILABILITY_EDIT_TITLE: 'Eine Zeit lang nicht verfügbar?',
    EXPERT_AVAILABILITY_EDIT_SUBTITLE:
        'Wähle hier den Zeitraum – dein Status wird anschließend automatisch aktualisiert.',
    EXPERT_AVAILABILITY_SAVED: 'Verfügbarkeit aktualisiert!',
    EXPERT_UNAVAILABLE_FROM_DATE_LABEL: 'Nicht erreichbar von',
    EXPERT_UNAVAILABLE_TO_DATE_LABEL: 'bis',

    EXPERT_TAB_MATCHES: 'Matches',
    EXPERT_TAB_MY_EXPERTS: 'Gemerkte Kolleg*innen',
    EXPERT_TAB_AVAILABILITY: 'Verfügbarkeit',
    EXPERT_TAB_SEARCH: 'Suche',

    EXPERT_MATCHING_NOT_FOUND_CONTENT:
        'Bitte gib mehr Fähigkeiten und (versteckte?) Talente in deinem Profil an, um Matches zu finden',
    EXPERT_MATCHING_NOT_FOUND_IMG_ALT: 'Keine Matches gefunden',
    EXPERT_MATCHING_NOT_FOUND_BTN: 'Bearbeiten',
    EXPERT_NO_MATCH_FIND_CONTENT: 'Weitere Kolleg*innen über die Suche finden',

    EXPERT_BOOKMARKS_TITLE: 'Du hast keine Experten mit einem Lesezeichen versehen.',
    EXPERT_BOOKMARKS_SUBTITLE: 'Hier kannst du deine Lieblingsexperten speichern.',
    EXPERT_BOOKMARKS_PROFILES: 'gemerkte Experten',

    EXPERT_PROFILE_VIEW_OFFERED_SKILLS: 'Expertise, die ich teile',
    EXPERT_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Expertise, die ich suche ',
    EXPERT_PROFILE_VIEW_COMMUNICATION_TYPE: 'Bevorzugter Kommunikationsweg',
    EXPERT_PROFILE_VIEW_COMMUNICATION_IN_PERSON: 'persönlich',
    EXPERT_PROFILE_VIEW_COMMUNICATION_VIRTUAL: 'virtuell',
    EXPERT_PROFILE_VIEW_COMMUNICATION_BOTH: 'persönlich und virtuell',
    EXPERT_PROFILE_VIEW_UNAVAILABILITY_DATES: 'Vorübergehend nicht verfügbar',

    EXPERT_SEARCH_HEADER: 'Expertensuche mit Schlagwörtern',
    EXPERT_SEARCH_BTN_TEXT: 'Suchen!',
    EXPERT_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Suchen ohne Suchbegriff zeigt alle aktuell verfügbaren Experten.',

    PAGE_TITLE_EXPERT_MATCHES: 'Expertenfinder - Matches',
    PAGE_TITLE_EXPERT_BOOKMARKS: 'Expertenfinder - Gemerkte Kollegen',
    PAGE_TITLE_EXPERT_SEARCH: 'Expertenfinder - Suche',
    PAGE_TITLE_EXPERT_AVAILABILITY: 'Expertenfinder Verfügbarkeit',
};
