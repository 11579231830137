import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NLAProfileDTO, NLAProfileMatchResponseDTO, NLASearchResponseDTO, NLASuggestedSkillsDTO } from './nla.dtos';
import { NEVER_LUNCH_ALONE_TOPIC_NAME, OffsetLimit } from '@tploy-enterprise/tenant-core';
import { Params } from '@angular/router';

const BASE_PATH = `/api/v2/${NEVER_LUNCH_ALONE_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class NLAApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<NLAProfileDTO> {
        return this.httpClient.get<NLAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string) {
        return this.httpClient.get<NLAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: NLAProfileDTO): Observable<NLAProfileDTO> {
        return this.httpClient.put<NLAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, { observe: 'body' });
    }

    getProfileMatches(offsetLimit: OffsetLimit): Observable<NLAProfileMatchResponseDTO> {
        const params: Params = { ...offsetLimit };
        return this.httpClient.get<NLAProfileMatchResponseDTO>(`${BASE_PATH}/matches`, { params });
    }

    search(searchQuery: string, offsetLimit: OffsetLimit): Observable<NLASearchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
        };
        return this.httpClient.get<NLASearchResponseDTO>(`${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`, {
            params,
        });
    }

    getSuggestedSkills(id: string) {
        return this.httpClient.get<NLASuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }
}
