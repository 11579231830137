import { Injectable } from '@angular/core';
import { Bookmark, CreateBookmark, CreateBookmarkResponse } from './bookmark.types';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const SERVICE_PATH = '/api/v2/bookmarks';

@Injectable({
    providedIn: 'root',
})
export class BookmarksService {
    constructor(private http: HttpClient) {}

    load(context: string): Observable<Array<Bookmark>> {
        if (context) {
            return this.loadBookmarksByContext(context);
        }
        return this.loadBookmarks();
    }

    loadBookmarks(): Observable<Array<Bookmark>> {
        return this.http.get<Array<Bookmark>>(`${SERVICE_PATH}`, { observe: 'body' });
    }

    loadBookmarksByContext(context: string): Observable<Array<Bookmark>> {
        return this.http.get<Array<Bookmark>>(`${SERVICE_PATH}?contexts=${context}`, { observe: 'body' });
    }

    createBookmark(bookmark: CreateBookmark): Observable<string> {
        return this.http.post<CreateBookmarkResponse>(`${SERVICE_PATH}`, bookmark).pipe(map((response) => response.id));
    }

    removeBookmark(bookmarkId: string): Observable<string> {
        return this.http.delete(`${SERVICE_PATH}/${bookmarkId}`, { responseType: 'text' });
    }
}
