import { Injectable } from '@angular/core';
import { RoutingSequenceState } from './routing-sequence.reducer';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom, map, catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable, of, from } from 'rxjs';
import { nextSequenceStepCommand, previousSequenceStepCommand } from './routing-sequence.selectors';
import { RoutingSequenceActions } from './routing-sequence.actions';
import { NavigateCommand, ROUTING_SEQUENCE_STORAGE_KEY } from './routing-sequence.types';
import { StorageService } from '../storage/service/storage.service';
import { RedirectService } from '../../redirect.service';

type State = { routingSequence: RoutingSequenceState };

@Injectable({
    providedIn: 'root',
})
export class RoutingSequenceEffects {
    constructor(
        private readonly store: Store<State>,
        private readonly router: Router,
        private readonly actions$: Actions,
        private readonly storage: StorageService,
        private readonly redirectService: RedirectService,
    ) {}

    start$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.start),
            withLatestFrom(this.store.select(nextSequenceStepCommand)),
            switchMap(([action, command]) => this.resolveNavigateAction(command)),
        ),
    );

    navigateToNext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.navigateToNext),
            withLatestFrom(this.store.select(nextSequenceStepCommand)),
            switchMap(([action, command]) => this.resolveNavigateAction(command)),
        ),
    );

    navigateToPrevious$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.navigateToPrevious),
            withLatestFrom(this.store.select(previousSequenceStepCommand)),
            switchMap(([action, command]) => this.resolveNavigateAction(command)),
        ),
    );

    navigate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.navigate),
            switchMap(({ command }) => this.navigate(command)),
            switchMap((command) => {
                if (command?.event === 'skip') {
                    return of(
                        RoutingSequenceActions.navigated({ step: command?.index }),
                        command?.direction && command?.direction === 'forward'
                            ? RoutingSequenceActions.navigateToNext()
                            : RoutingSequenceActions.navigateToPrevious(),
                    );
                } else {
                    return of(RoutingSequenceActions.navigated({ step: command?.index }));
                }
            }),
        ),
    );

    complete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.complete),
            switchMap(({ command }) => this.navigate(command)),
            map((command) => RoutingSequenceActions.navigated({ step: command.index })),
        ),
    );

    abort$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RoutingSequenceActions.abort),
            switchMap(({ command }) => this.navigate(command)),
            map(() => RoutingSequenceActions.aborted()),
        ),
    );

    // On every reducer change, store the new state in session (persistence across reload)
    updateSession$ = createEffect(
        () =>
            this.store
                .select((state) => state.routingSequence)
                .pipe(
                    tap((state) => this.storeRoutingSequenceState(state)),
                    switchMap(() => EMPTY),
                ),
        { dispatch: false },
    );

    private navigate(command: NavigateCommand): Observable<NavigateCommand> {
        return from(this.router.navigate(command.step as any)).pipe(
            map((success) => {
                if (success) {
                    return command;
                } else {
                    throw new Error('The navigation was a failure');
                }
            }),
            catchError((err) => {
                const skipStep = this.redirectService.isNavigationStepError(err);

                if (skipStep) {
                    return of({ ...command, event: 'skip' } as NavigateCommand);
                }

                return EMPTY;
            }),
        );
    }

    private resolveNavigateAction(command: NavigateCommand): Observable<Action> {
        if (command?.event === 'navigate') {
            return of(RoutingSequenceActions.navigate({ command }));
        } else if (command?.event === 'complete') {
            return of(RoutingSequenceActions.complete({ command }));
        } else if (command?.event === 'abort') {
            return of(RoutingSequenceActions.abort({ command }));
        }
    }

    private storeRoutingSequenceState(state: RoutingSequenceState) {
        this.storage.local.set(ROUTING_SEQUENCE_STORAGE_KEY, state);
    }
}
