import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    WorkshopProfile,
    WorkshopProfileDTO,
    WorkshopOthersProfile,
    WorkshopSuggestedSkills,
} from '../models/workshop-profile.types';
import { HttpClient } from '@angular/common/http';
import { WorkshopProfileSerializer } from './workshop-profile-serializer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GeneralData, WORKSHOPS_TOPIC_NAME } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class WorkshopProfileService {
    private readonly API_PATH = `/api/v2/${WORKSHOPS_TOPIC_NAME}`;

    constructor(private http: HttpClient, private serializer: WorkshopProfileSerializer) {}

    load(): Observable<WorkshopProfile> {
        return this.http
            .get<WorkshopProfileDTO>(`${this.API_PATH}/profiles`)
            .pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    loadById(id: string): Observable<WorkshopOthersProfile> {
        return this.http
            .get<WorkshopProfileDTO>(`${this.API_PATH}/profiles/${id}`)
            .pipe(map((dto) => this.serializer.deserializeOthers(dto)));
    }

    save(workshopProfile: WorkshopProfile, general: GeneralData): Observable<WorkshopProfile> {
        const dto = this.serializer.serialize(workshopProfile, general);
        return this.http
            .put<WorkshopProfileDTO>(`${this.API_PATH}/profiles`, dto, { observe: 'body' })
            .pipe(map((responseBody) => this.serializer.deserialize(responseBody)));
    }

    getSuggestedSkills(id: string): Observable<WorkshopSuggestedSkills> {
        return this.http.get<WorkshopSuggestedSkills>(`${this.API_PATH}/profiles/${id}/skills`).pipe(
            catchError(() => of({ aType: [], lType: [], sType: [] })),
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
        );
    }
}
