import { Injectable } from '@angular/core';
import { IGenericStorageAdapter, LocalStorageAdapter, SessionStorageAdapter } from '../adapter';
import { WindowRef } from '../../window-ref';

@Injectable()
export class StorageService {
    constructor(
        private localStorageAdapter: LocalStorageAdapter,
        private sessionStorageAdapter: SessionStorageAdapter,
    ) {}

    public get local(): IGenericStorageAdapter {
        return this.localStorageAdapter;
    }

    public get session(): IGenericStorageAdapter {
        return this.sessionStorageAdapter;
    }

    public hasAny<T>(key: string): T | string | undefined {
        if (this.localStorageAdapter.has(key)) {
            return this.local.get<T>(key);
        }
        if (this.sessionStorageAdapter.has(key)) {
            return this.session.get<T>(key);
        }
        return undefined;
    }

    public static get local(): IGenericStorageAdapter {
        return new LocalStorageAdapter(new WindowRef());
    }

    public static get session(): IGenericStorageAdapter {
        return new SessionStorageAdapter(new WindowRef());
    }
}
