import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    ViewChild,
} from '@angular/core';

export interface OffsetPosition {
    offsetTop: number;
    offsetLeft: number;
}

const HEADER_WARNING_HIDDEN_CLASS = 'form-header__note--hidden';
const FORM_FIELD_CLASS = 'grid-layout_field';

@Component({
    selector: 'tp-modal-header-warning',
    templateUrl: './modal-header-warning.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'form-header__wrapper' },
})
export class ModalHeaderWarningComponent {
    public modalBodyElement: HTMLElement;
    private errorElements: HTMLElement[] = [];
    public hasStaticMessage = false;

    @ViewChild('warningElement', { read: ElementRef }) warningElementRef: ElementRef;

    @HostListener('click', ['$event']) handleClickEvent($event: MouseEvent): void {
        $event.stopPropagation();

        const errorElement = this.errorElements[0];
        const wrapperElement = this.findErrorElementWrapper(errorElement);

        this.modalBodyElement.scrollTop = this.getPositionRelativeToParent(
            wrapperElement,
            'modal-form-layout_content',
        ).offsetTop;
    }

    get contentText(): string {
        const firstErrorElement = this.errorElements[0];
        if (!firstErrorElement) return '';
        const errorElementLabel = this.findErrorElementLabel(firstErrorElement);
        return errorElementLabel ? `${errorElementLabel}: ${firstErrorElement.innerText}` : firstErrorElement.innerText;
    }

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    public hide(): void {
        this.warningElementRef?.nativeElement.firstChild.classList.add(HEADER_WARNING_HIDDEN_CLASS);
        this.changeDetectorRef.detectChanges();
    }

    public show(errorElements: HTMLElement[]): void {
        this.errorElements = errorElements;
        this.warningElementRef.nativeElement.firstChild.classList.remove(HEADER_WARNING_HIDDEN_CLASS);
        this.changeDetectorRef.detectChanges();
    }

    private findErrorElementLabel(errorElement: HTMLElement): string {
        const errorElementFormField = this.findParentByClass(errorElement, FORM_FIELD_CLASS);
        return (errorElementFormField?.querySelector('mat-label') as HTMLElement)?.innerText;
    }

    private findErrorElementWrapper(errorElement: HTMLElement): HTMLElement {
        const wrapperElement = this.findParentByClass(errorElement, FORM_FIELD_CLASS);
        return wrapperElement ?? errorElement;
    }

    private getPositionRelativeToParent(srcElement: HTMLElement, parentElementClass: string): OffsetPosition {
        let offsetLeft = 0;
        let offsetTop = 0;

        let el = srcElement;

        while (el) {
            if (!el.className.includes(parentElementClass)) {
                offsetLeft += el.offsetLeft;
                offsetTop += el.offsetTop;
                el = el.offsetParent as HTMLElement;
            } else {
                break;
            }
        }
        return { offsetTop: offsetTop, offsetLeft: offsetLeft };
    }

    private findParentByClass(child: HTMLElement, parentElementClass: string) {
        let el = child;
        while (el) {
            if (!el.className.includes(parentElementClass)) {
                el = el.parentElement;
            } else {
                return el;
            }
        }
    }
}
