import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Account, OthersAccount, TopicTandemCheckView } from '@tploy-enterprise/tenant-core';
import { JobXProfile } from '../../jobs.types';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { JobXCurrentProfileResolver } from '../../jobx-current-profile-resolver.service';
import { JobXTopicEventHandlerService } from '../../job-x-topic-event-handler.service';
import { Store } from '@ngrx/store';
import { JobXState } from '../../jobXReducer';
import { isJobXOwnOrOthersProfileLoading } from '../../jobx.selectors';

@Component({
    selector: 'tp-job-x-tandem-check-view',
    templateUrl: './job-x-tandem-check-view.component.html',
})
export class JobXTandemCheckViewComponent implements TopicTandemCheckView, OnInit {
    @Input() ownAccount: Account;
    @Input() othersAccount: OthersAccount;

    ownJobXProfile$: Observable<JobXProfile>;
    othersJobXProfile$: Observable<JobXProfile>;
    jobXProfileLoading$: Observable<boolean>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly jobXCurrentProfileResolver: JobXCurrentProfileResolver,
        private readonly jobXEventHandlerService: JobXTopicEventHandlerService,
        private readonly store: Store<{ jobx: JobXState }>,
    ) {}

    ngOnInit() {
        this.jobXProfileLoading$ = this.store.select(isJobXOwnOrOthersProfileLoading);
        this.ownJobXProfile$ = this.jobXCurrentProfileResolver.resolve();
        this.othersJobXProfile$ = this.jobXEventHandlerService.onOthersProfileLoad(this.othersAccount.userId);
    }
}
