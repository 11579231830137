import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TechWolfService } from './tech-wolf.service';
import { TechWolfActions } from './tech-wolf.actions';

@Injectable({
    providedIn: 'root',
})
export class TechWolfEffects {
    constructor(private readonly actions$: Actions, private readonly techWolfDataService: TechWolfService) {}

    loadTechWolfSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TechWolfActions.loadTechWolfSuggestedSkills),
            mergeMap((action) =>
                this.techWolfDataService
                    .loadTechWolfSuggestedSkills(action.skills, action.skillType)
                    .pipe(map((skills) => TechWolfActions.loadTechWolfSuggestedSkillsSuccess({ skills }))),
            ),
            catchError((error) => of(TechWolfActions.loadTechWolfSuggestedSkillsError({ error }))),
        ),
    );

    resetTechWolfSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TechWolfActions.resetTechWolfSuggestedSkills),
            map((action) => TechWolfActions.resetTechWolfSuggestedSkillsSuccess()),
        ),
    );
}
