import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Translatable } from '../../language';

@Component({
    selector: 'tp-profile-view-text-field',
    templateUrl: './profile-view-text-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__text-field',
    },
})
export class ProfileViewTextFieldComponent {
    @Input()
    key: Translatable | string;

    @Input()
    value: Translatable | string;

    @Input()
    suffix = '';
}
