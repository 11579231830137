import { ErrorHandler, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TenantCoreComponent } from './tenant-core.component';
import {
    CURRENT_LANGUAGE,
    FocusModule,
    ImageAdapterModule,
    LanguageModule,
    LanguageOptions,
} from '@tploy-enterprise/tenant-common';
import { ConfigModule, ConfigService } from './core-modules/config';
import { CookieService } from 'ngx-cookie-service';
import { authenticationReducer } from './core-modules/authentication/authentication.reducer';
import { AuthenticationEffects } from './core-modules/authentication/authentication.effects';
import { TenantCoreRoutingModule } from './tenant-core-routing.module';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NotificationsSnackModule } from './core-modules/notifications/notifications.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { accountDataReducer, AccountState } from './core-modules/account/account.reducer';
import { commonDataReducer } from './core-modules/common/common.reducer';
import { techWolfDataReducer } from './core-modules/tech-wolf/tech-wolf.reducer';
import { successFactorsReducer } from './core-modules/success-factors/success-factors.reducer';
import { AccountEffects } from './core-modules/account/account.effects';
import { TechWolfEffects } from './core-modules/tech-wolf/tech-wolf.effects';

import { StylesProvidersModule } from './styles-providers.module';
import { LocaleEffects } from './core-modules/account/locale/locale.effects';
import { Observable } from 'rxjs';
import { ExperienceEffects } from './core-modules/account/experience';
import { RoutingSequenceModule } from './core-modules/routing-sequence';
import { GeneralDataEffects } from './core-modules/account/general-data/general-data.effects';
import { SelectedTopicsEffects } from './core-modules/account/selected-topics/selected-topics.effects';
import { AccountSettingsEffects } from './core-modules/account/account-settings/account-settings.effects';
import { bookmarksDataReducer } from './core-modules/bookmarks/bookmarks.reducer';
import { BookmarkEffects } from './core-modules/bookmarks/bookmark.effects';
import { AuthorizationModule } from './core-modules/authorization/authorization.module';
import { CorePermissions } from './core-modules/authorization/core-permissions';
import { ErrorHandlerModule } from './core-modules/error-handler/error-handler.module';
import { RequestHandlerModule } from './core-modules/request-handler/request-handler.module';
import { GlobalErrorHandler, HttpErrorLoggerInterceptor, LoggerModule } from './core-modules/logger';
import { SocketIOModule } from './core-modules/socket.io/socket.io.module';
import { directMessagesReducer } from './core-pages/direct-messages/reducers/direct-messages.reducer';
import { DirectMessagesConversationEffects } from './core-pages/direct-messages/effects/direct-messages-conversation.effects';
import { IconsService } from './core-modules/icons';
import { blogReducer } from './core-pages/blog/blog.reducer';
import { BlogEffects } from './core-pages/blog/blog.effects';
import { StorageModule } from './core-modules/storage/storage.module';
import { WindowRefModule } from './core-modules/window-ref/window-ref.module';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { QuestionnaireEffects } from './core-modules/account/questionnaire/questionnaire.effects';
import { BookmarksPageEffects } from './core-pages/bookmarks-page/bookmarks-page.effects';
import { bookmarksPageReducer } from './core-pages/bookmarks-page/bookmarks-page.reducer';
import { MatomoModule } from './core-modules/matomo';
import { EntityNotFoundModule } from './core-modules/entity-not-found/entity-not-found.module';
import { RefreshTokenInterceptor } from './core-modules/authentication/refresh-token.interceptor';
import { LocationModule } from './core-modules/location';
import { SearchLoaderModule } from './core-modules/search-loader';
import { MatchesSliderModule } from './core-modules/matches-slider';
import { TabsModule } from './core-modules/tabs/tabs.module';
import { SortablejsModule } from 'nxt-sortablejs';
import { MediaEffects } from './core-modules/media/media.effects';
import { mediaReducer } from './core-modules/media/media.reducer';
import { InputModule, LinkModule } from '@tandemploy/ngx-components';
import { PaginatorTpModule } from './core-modules/paginator';
import { ImagePickerModule } from './core-modules/image-picker';
import { LocalTenantConfiguration } from './tenant-core.types';
import { LOCAL_TENANT_CONFIGURATION } from './tenant-core.di';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SuccessFactorsModule } from './core-modules/success-factors';
import { TrustedSecurityService } from './trusted-security-policy';

function currentLanguageProviderFactory(store: Store<{ account: AccountState }>): Observable<LanguageOptions> {
    return store.select((state) => state.account.locale.used ?? state.account.locale.saved);
}

export class CustomLoader implements TranslateLoader {
    constructor(private readonly configService: ConfigService) {}

    public getTranslation(lang: string): Observable<any> {
        return this.configService.getTranslation(lang);
    }
}

@NgModule({
    imports: [
        ConfigModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [ConfigService],
            },
        }),
        ErrorHandlerModule,
        RequestHandlerModule,
        EntityNotFoundModule,
        BrowserAnimationsModule,
        LinkModule,
        FocusModule,
        LanguageModule,
        SearchLoaderModule,
        MatchesSliderModule,
        TabsModule,
        LoggerModule,
        WindowRefModule,
        StorageModule,
        LocationModule,
        InputModule,
        SortablejsModule.forRoot({ animation: 150, draggable: 'tp-chip' }),
        StoreModule.forRoot(
            {
                authentication: authenticationReducer,
                router: routerReducer,
                account: accountDataReducer,
                common: commonDataReducer,
                techWolf: techWolfDataReducer,
                successFactors: successFactorsReducer,
                bookmarks: bookmarksDataReducer,
                bookmarksPage: bookmarksPageReducer,
                directMessages: directMessagesReducer,
                blog: blogReducer,
                media: mediaReducer,
            },
            {
                runtimeChecks: {
                    // Errors are still a problem for serializability
                    // The Error class based ones are not considered serializable
                    strictActionImmutability: false,
                    strictActionSerializability: false,
                    // strictStateImmutability as much as I like the sound of it, it is creating nasty issues.
                    strictStateImmutability: false,
                    strictStateSerializability: false,
                },
            },
        ),
        EffectsModule.forRoot([
            AuthenticationEffects,
            AccountEffects,
            TechWolfEffects,
            LocaleEffects,
            GeneralDataEffects,
            SelectedTopicsEffects,
            ExperienceEffects,
            AccountSettingsEffects,
            BookmarkEffects,
            BookmarksPageEffects,
            DirectMessagesConversationEffects,
            BlogEffects,
            QuestionnaireEffects,
            MediaEffects,
        ]),
        // StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true }),
        StoreRouterConnectingModule.forRoot(),
        RoutingSequenceModule,
        TenantCoreRoutingModule,
        NotificationsSnackModule.forRoot(),
        HttpClientModule,
        StylesProvidersModule,
        ImageAdapterModule,
        AuthorizationModule.forRoot({ permissions: CorePermissions }),
        LoggerModule,
        SocketIOModule,
        MatomoModule,
        PaginatorTpModule,
        SuccessFactorsModule,
        ImagePickerModule.forRoot(),
    ],
    providers: [
        CookieService,
        BreakpointObserver,
        { provide: CURRENT_LANGUAGE, useFactory: currentLanguageProviderFactory, deps: [Store] },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorLoggerInterceptor,
            multi: true,
        },
    ],
    declarations: [TenantCoreComponent],
    exports: [TenantCoreComponent, ConfigModule],
})
export class TenantCoreModule {
    static configure(localTenantConfiguration: LocalTenantConfiguration): ModuleWithProviders<TenantCoreModule> {
        return {
            ngModule: TenantCoreModule,
            providers: [{ provide: LOCAL_TENANT_CONFIGURATION, useValue: localTenantConfiguration }],
        };
    }

    constructor(private injector: Injector) {
        TenantCoreModule.registerTrustedTypesPolicy(injector);
        TenantCoreModule.registerIcons(injector);
        TenantCoreModule.registerLocaleDateFormat();
    }

    private static registerIcons(injector: Injector) {
        const iconsService = injector.get(IconsService);
        iconsService.registerIcons();
    }

    private static registerLocaleDateFormat() {
        registerLocaleData(localeEn, 'en');
        registerLocaleData(localeDe, 'de');
    }

    private static registerTrustedTypesPolicy(injector) {
        const trustedSecurityService = injector.get(TrustedSecurityService);
        trustedSecurityService.registerTrustedTypesPolicy();
    }
}
