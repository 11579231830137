import { createAction, props } from '@ngrx/store';
import { Bookmark, CreateBookmark } from './bookmark.types';

export const BookmarkActions = {
    load: createAction('[Bookmark Data] load', props<{ context: string }>()),
    loadSuccess: createAction('[Bookmark Data][Success] load', props<{ bookmarks: Array<Bookmark> }>()),
    loadError: createAction('[Bookmark Data][Error] load', props<{ error: Error }>()),

    add: createAction('[Bookmark] Add', props<{ bookmark: CreateBookmark; accountName: string }>()),
    addSuccess: createAction('[Bookmark][Success] Add', props<{ bookmark: Bookmark; accountName: string }>()),
    addError: createAction('[Bookmark][Error] Add', props<{ error: Error }>()),
    delete: createAction('[Bookmark] Delete', props<{ bookmark: Bookmark; accountName: string }>()),
    deleteSuccess: createAction('[Bookmark][Success] Delete', props<{ bookmark: Bookmark; accountName: string }>()),
    deleteError: createAction('[Bookmark][Error] Delete', props<{ bookmark: Bookmark; error: Error }>()),
};
