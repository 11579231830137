import { Injectable } from '@angular/core';
import { ConfigService } from '../config';

@Injectable()
export class FroalaEditorConfigService {
    constructor(private readonly configService: ConfigService) {}

    getConfig(messengerMode: boolean, uploadImages: boolean): Record<string, string | unknown> {
        const baseConfig = {
            key: this.configService.remoteSettings.settings.froalaLicenseKey,
            attribution: false,
            wordPasteModal: false,
            imageUpload: false,
            imageResize: true,
            imageDefaultWidth: 300,
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            linkInsertButtons: ['linkBack'],
        };
        const buttonsLG = [
            'paragraphFormat',
            'bold',
            'italic',
            'underline',
            '|',
            'formatUL',
            'formatOL',
            '|',
            'insertLink',
            '|',
            'undo',
            'redo',
        ];
        const buttonsXS = ['bold', 'italic', 'underline', 'undo', 'redo'];
        const buttonsMessenger = ['bold', 'italic', 'underline'];
        const imageUploadConfiguration = {
            imageUpload: true,
            imageUploadURL: '/api/v2/media/admin',
            imageUploadMethod: 'POST',
            imageUploadParam: 'image',
            imageMaxSize: 1024 * 1024 * 5,
        };

        let config = messengerMode
            ? {
                  ...baseConfig,
                  pastePlain: true,
                  toolbarButtons: buttonsMessenger,
                  toolbarBottom: true,
                  enter: 0, //FroalaEditor.ENTER_P
              }
            : {
                  ...baseConfig,
                  paragraphFormat: { N: 'Normal', H1: 'Heading 1', H2: 'Heading 2', H3: 'Heading 3', H4: 'Heading 4' },
                  paragraphFormatSelection: true,
                  toolbarButtons: buttonsLG,
                  toolbarButtonsXS: buttonsXS,
              };
        if (uploadImages) {
            config = { ...config, ...imageUploadConfiguration };
        }
        return config;
    }
}
