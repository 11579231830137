import { Component, Input } from '@angular/core';
import { GenerationExchangeProfile } from '../../generation-exchange-service/generation-exchange.types';
import { Bookmark, GeneralData, TopicUserCardView } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-generation-exchange-user-card-view',
    templateUrl: './generation-exchange-user-card-view.component.html',
})
export class GenerationExchangeUserCardViewComponent implements TopicUserCardView {
    @Input() topicProfileData: GenerationExchangeProfile;
    @Input() bookmark: Bookmark;
    @Input() generalData: GeneralData;

    get context(): string {
        return this.bookmark?.context;
    }

    get user(): GeneralData {
        return this.generalData;
    }

    get tags(): string[] {
        return this.topicProfileData.skills;
    }

    get selectedTags(): string[] {
        return [];
    }
}
