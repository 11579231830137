import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MediaService } from './media.service';
import { MediaState } from './media.reducer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MediaActions } from './media.actions';

@Injectable({
    providedIn: 'root',
})
export class MediaEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly mediaService: MediaService,
        private readonly store: Store<{ media: MediaState }>,
    ) {}

    loadAllUserMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MediaActions.loadAllUserMedia),
            switchMap(({ imageType }) => this.mediaService.getAll(imageType)),
            map((data) => MediaActions.loadAllUserMediaSuccess({ data })),
            catchError((error) => of(MediaActions.loadAllUserMediaError({ error }))),
        ),
    );
    deleteUserMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MediaActions.deleteUserMedia),
            switchMap(({ id }) =>
                this.mediaService.delete(id).pipe(
                    map(() => MediaActions.deleteUserMediaSuccess({ id })),
                    catchError((error) => of(MediaActions.deleteUserMediaError({ error }))),
                ),
            ),
        ),
    );
}
