import { NgModule } from '@angular/core';
import { CommonLoaderComponent } from './loader.component';
import { LanguageModule } from '../language';

@NgModule({
    declarations: [CommonLoaderComponent],
    exports: [CommonLoaderComponent],
    imports: [LanguageModule],
})
export class CommonLoaderModule {}
