import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '../language';
import { DetailsContentComponent } from './details-content/details-content.component';
import { DetailsFactsComponent } from './details-facts/details-facts.component';
import { DetailsOrganizerComponent } from './details-organizer/details-organizer.component';
import { DetailsSubContentComponent } from './details-sub-content/details-sub-content.component';
import { DetailsComponent } from './details.component';
import { DetailsTitleComponent } from './details-title/details-title.component';
import { DetailsTargetGroupsComponent } from './details-target-groups/details-target-groups.component';
import { DetailsTopicsComponent } from './details-topics/details-topics.component';
import { DetailsActionsComponent } from './details-actions/details-actions.component';
import { DetailsImageHeaderComponent } from './details-image-header/details-image-header.component';
import { DetailsLanguageComponent } from './details-language/details-language.component';
import { MatButtonModule } from '@angular/material/button';
import { LocalizedDateModule } from '../localized-date';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CampaignsModule } from '../campaigns';
import { PopoverModule } from '../popover';

const detailsComponents = [
    DetailsComponent,
    DetailsContentComponent,
    DetailsFactsComponent,
    DetailsOrganizerComponent,
    DetailsSubContentComponent,
    DetailsTitleComponent,
    DetailsImageHeaderComponent,
    DetailsTargetGroupsComponent,
    DetailsLanguageComponent,
    DetailsTopicsComponent,
];

@NgModule({
    declarations: [...detailsComponents, DetailsActionsComponent],
    exports: [...detailsComponents, DetailsActionsComponent],
    imports: [
        CommonModule,
        MatIconModule,
        LanguageModule,
        MatButtonModule,
        LocalizedDateModule,
        MatTooltipModule,
        CampaignsModule,
        PopoverModule,
    ],
})
export class DetailsModule {}
