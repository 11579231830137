import { AccountState, isAccountSyncCompleted, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, filter, map, catchError } from 'rxjs/operators';
import { TopicExpertState } from './expert-store/topic-expert.reducer';
import { ExpertProfile } from './expert-api/expert.types';
import { ExpertActions } from './expert-store/topic-expert.actions';
import { ExpertService } from './expert-api/expert.service';

@Injectable({
    providedIn: 'root',
})
export class ExpertTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ expert: TopicExpertState; account: AccountState }>,
        private readonly expertService: ExpertService,
    ) {}

    onProfileSave(): Observable<void> {
        this.store.dispatch(ExpertActions.save());

        // once saved the dirty state will be reset to false
        // if originally pristine, there is nothing to save
        return this.store
            .select((state) => state.expert)
            .pipe(
                filter((expertState) => {
                    if (!expertState.edit.dirty) {
                        return true;
                    }

                    if (expertState.current.savingError) {
                        throw expertState.current.savingError;
                    }

                    return false;
                }),
                map(() => undefined),
                take(1),
            );
    }

    onOwnProfileLoad(): Observable<ExpertProfile> {
        const loaded = this.readLoadedState();
        if (!loaded) {
            this.store.dispatch(ExpertActions.load());
        }

        return this.store
            .select((state) => state.expert.current)
            .pipe(
                filter((state) => state.loaded),
                map((state) => state.data),
                catchError(() => {
                    return of(null);
                }),
                take(1),
            );
    }

    onOthersProfileLoad(id: string): Observable<ExpertProfile> {
        return this.expertService.getExpertProfileById(id).pipe(
            catchError(() => of(null)),
            take(1),
        );
    }

    private readLoadedState(): boolean {
        let loaded: boolean = undefined;
        this.store
            .select((state) => state.expert.current)
            .pipe(
                map((state) => state.loaded || state.loading),
                take(1),
            )
            .subscribe((value) => (loaded = value));

        return loaded;
    }

    onProfileSync() {
        this.store.dispatch(ExpertActions.syncProfileSkills());
        return this.waitOnSync();
    }

    private waitOnSync(): Observable<void> {
        return this.store.select(isAccountSyncCompleted).pipe(
            filter((loaded) => !loaded),
            map(() => undefined),
            take(1),
        );
    }
}
