<section role="dialog" class="modal-layout_main" tpModalHelpers #modal="tpModalHelpers">
    <tp-icon-button [icon]="'close'" (click)="modal.close()" class="no-border modal-layout_close"> </tp-icon-button>
    <section class="modal-layout_content">
        <div class="modal-header">
            <h1 class="modal-title">{{ 'LEGAL' | translatable }}</h1>
        </div>
        <div [innerHTML]="htmlTemplate$ | async"></div>
    </section>
    <footer class="modal-layout_footer"></footer>
</section>
