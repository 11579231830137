import { OthersAccount, INTEREST_TYPE, OFFERING_TYPE, PageParams, LocationDTO } from '@tploy-enterprise/tenant-core';
import { TopicEntityStatus } from '@tandemploy/common';
import { Skill, SuggestedSkills } from '@tploy-enterprise/tenant-common';
import { ProjectsSearchResultDTO } from './projects-api/projects.dtos';

export const PROFILE_STORAGE_KEY = 'tploy.projects.profile';
export const IS_PROFILE_DIRTY = 'tploy.projects.isDirty';

export enum ProjectsSkillLevel {
    'JUNIOR' = 1,
    'SENIOR' = 2,
    'EXPERT' = 3,
}

export interface StaffingSkill {
    name: string;
    hasExactMatch: boolean;
}

export interface ProjectsSkill {
    name: string;
    level: ProjectsSkillLevel;
    // the position is a serialization concern
    // localy, the array ordering is the source of truth
}

export interface ProjectsRequiredSkill extends ProjectsSkill {
    required: boolean;
}

export type ProjectsMotivation = OFFERING_TYPE | INTEREST_TYPE;

export interface ProjectsProfile {
    motivations: ProjectsMotivation[];
    skillsInterestedIn: string[];
    skills: Skill[];
    categoriesInterestedIn: string[];
    locations?: LocationDTO[];
    campaigns: string[];
}

export interface ProjectsOthersProfile extends ProjectsProfile {
    user: {
        id: string;
        name: string;
        imageUrl: string;
        locations: { name: string }[];
        languages: string[];
    };
}

export interface ProjectsEntity {
    id?: string;
    title: string;
    categories: string[];
    campaigns: string[];
    languages: string[];
    description: string;
    startDate: Date;
    endDate: Date;
    applicationCloseAt: Date;
    requestedSkills: Skill[];
    selectedGroups: string[];
    location: string;
    imageUrl: string;
    staffingCompleted: boolean;
    organizers: Array<ProjectOrganizer>;
    customConditions?: string;
    lastUpdatedAt?: string;
    published?: boolean;
    company: string; // this is not editable by the creator
    department: string; // this is not editable by the creator
    applications?: ProjectApplication[];
    applicationStatus?: ProjectsApplicationStatus;
    canUserApply?: boolean;
    isApplicationForUserPendingOrApproved?: boolean;
    draft: boolean;
    ongoing: boolean;
    status: TopicEntityStatus;
    hasUnseenApplications: boolean;
    finished?: boolean;
    finishedAt?: Date;
    numberOfApplications?: number;
    highlighted?: ProjectEntityHighlight;
    applicationSelectedSkills?: Skill[];

    // This is resolved dynamically
    // Another option would be to replace the organizer property with its data
    organizerData?: OthersAccount; // this is set in the backend and cannot be edited
}

export enum ProjectsApplicationStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
}

export enum ProjectsEntityStatus {
    PLANNED = 'planned',
    RUNNING = 'running',
    COMPLETED = 'completed',
}

export type ProjectEntityHighlight = {
    categories: Array<string>;
    mustHaveSkills: Array<string>;
    mustHaveSkillsWithRelated?: Array<ProjectEntityHighlightRelation>;
};

export type ProjectEntityHighlightRelation = {
    name: string;
    exact: boolean;
    level: ProjectsSkillLevel;
    related: Array<string>;
};

export interface ProjectApplication {
    id: string;
    userId: string;
    name: string;
    status: ProjectsApplicationStatus;
    selectedSkills: Skill[];
    highlightedExactNotSelectedSkills: Skill[];
    highlightedExactSkills: Skill[];
    highlightedByRelatedSkills: Skill[];
    appliedAt: string;
    approvedAt?: string;
    approvedBy?: string;
    rejectedAt?: string;
    rejectedBy?: string;

    // hydrated property
    profile?: ProjectsOthersProfile;

    exactSkills?: Skill[];
    exactSkillsRelations?: Skill[][];
    notExactSkills?: Skill[];
    notExactSkillsRelations?: Skill[][];
}

export interface ApplicationRequest {
    user: { name: string };
    selectedSkills: Skill[];
}

export interface ProjectsProfileSearchResult {
    profile: ProjectsOthersProfile;
    notified: boolean;
}

export interface ProjectsProfileInvitationMessage {
    projectEntity: ProjectsEntity;
    profile: ProjectsOthersProfile;
    messageContent: string;
}

export type ProjectsSearchResultHighlight = {
    skillsOptional: string[];
    skillsRequired: string[];
    location: string[];
    categories: string[];
    campaigns?: string[];
    title: string[];
    description: string[];
};

export interface ProjectsSearchResultsDTO {
    results: Array<ProjectsSearchResultDTO>;
    totalCount: number;
}

export interface ProjectsSearchResults {
    results: Array<ProjectsSearchResult>;
    pageParams: PageParams;
}

export interface ProjectsSearchResult {
    projectId: string;
    projectsEntity: ProjectsEntity;
    organizers: Array<ProjectOrganizer>;
    highlight: ProjectsSearchResultHighlight;
}

export type ProjectOrganizer = { id: string; name: string; imageUrl: string };

export type ProjectSuggestedSkills = SuggestedSkills;

export type ProjectsProfileMatchHighlight = {
    categories: string[];
    campaigns?: string[];
    mustHaveSkills: string[];
    niceToHaveSkills: string[];
};

export type ProjectsProfileMatches = {
    matches: Array<ProjectsProfileMatch>;
    pageParams: PageParams;
};

export type ProjectsProfileMatch = {
    projectId: string;
    projectsEntity: ProjectsEntity;
    highlight: ProjectsProfileMatchHighlight;
    organizers: Array<ProjectOrganizer>;
};

export type ProjectsApplicationIndicatorsDTO = {
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    projectsWithUnseenApplications: Array<string>;
};
export type ProjectsApplicationIndicators = {
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    projectsWithUnseenApplications: Array<string>;
};

export interface ProjectsStaffingFilter {
    statuses?: ProjectsEntityStatus[];
    isApplicationOpen?: boolean;
    languages?: string[];
    categories?: string[];
}
