import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { WorkshopFeatureModule } from '@tploy-enterprise/topic-workshop';
import { ShortTimeAssignmentFeatureModule } from '@tploy-enterprise/topic-short-time-assignment';
import { ExpertFeatureModule } from '@tploy-enterprise/topic-expert';
import { ProjectsFeatureModule } from '@tploy-enterprise/topic-projects';
import { MentoringFeatureModule } from '@tploy-enterprise/topic-mentoring';
import { NeverLunchAloneFeatureModule } from '../../../../libs/topic-never-lunch-alone/src';
import { JobXFeatureModule } from '@tploy-enterprise/topic-job-sharing';
import { GenerationExchangeFeatureModule } from '@tploy-enterprise/topic-generation-exchange';

@NgModule({
    imports: [
        ShortTimeAssignmentFeatureModule,
        WorkshopFeatureModule,
        ExpertFeatureModule,
        ProjectsFeatureModule,
        MentoringFeatureModule,
        NeverLunchAloneFeatureModule,
        JobXFeatureModule,
        GenerationExchangeFeatureModule,
    ],
    providers: [], // used to detect readiness
    exports: [RouterModule],
})
export class FeaturesModule {}
