import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsHighlighterComponent } from './tags-highlighter.component';

@NgModule({
    declarations: [TagsHighlighterComponent],
    imports: [CommonModule],
    exports: [TagsHighlighterComponent],
})
export class TagsHighlighterModule {}
