import { PipeTransform, Pipe } from '@angular/core';
import { NotificationMailTo } from './notifications.types';

@Pipe({ name: 'notificationMailto' })
export class NotificationMailtoPipe implements PipeTransform {
    transform(value: string, mailto: NotificationMailTo) {
        const regex = new RegExp(mailto.token);
        return value.replace(regex, `<a class="tp-mailto" href="mailto:${mailto.email}">${mailto.email}</a>`);
    }
}
