// This file should stay empty, since workz is our default tenant, that uses our default translations
export default {
    DASHBOARD: 'My dashboard',
    DASHBOARD_HOME_TITLE: 'My dashboard',

    ORIGIN_TOPIC_WORKSHOP: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOPSHOPS: 'Workshops',

    WORKSHOP: 'Workshops',
    WORKSHOP_SINGULAR: 'Workshop',
    WORKSHOP_INFO:
        'If you want to advertise an internal workshop and win exactly the right participants, workshop matching is the right choice. Here you can create internal information events, network events, events and further trainings –internal workshops of any kind – and specify exactly how many participants are wanted and whether they should bring certain skills (in addition to interest in a particular topic). Potentially relevant members will automatically receive a notification and can register easily! Or vice versa: If you want to stay informed about internal workshops, you should definitely select "Workshops" and indicate what you are particularly interested in.',
    WORKSHOP_ENTITY_CREATE_BTN: 'New workshop',
    WORKSHOP_TAB_MY: 'My workshops',
    CREATE_WORKSHOP_DIALOG_TITLE: 'Create workshop',
    EDIT_WORKSHOP_DIALOG_TITLE: 'Edit workshop',
    PUBLISH_WORKSHOP_DIALOG_TITLE: 'What happens after publishing a workshop?',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_1: 'Your workshop will be online and colleagues get notified.',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_2:
        'Colleagues who are possibly interested in workshops will be matched with your event and receive a notification. From now on they can register for the workshop until the application deadline is reached.',
    PUBLISH_WORKSHOP_DIALOG_PUBLISH_BTN_TITLE: 'Publish workshop',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_1: 'Create your first workshop',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_2:
        'This list is still empty. To create your first workshop, just click the button below.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CREATE_WORKSHOP_BTN: 'Create a new workshop',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_TITLE: 'Workshops',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_SUB_TITLE:
        'Explore exciting Workshops and/or post your own ones to address fitting participants.',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_INFO:
        'Here you can choose the options that suit you best. Either you can search for interesting workshops and have them automatically suggested to you. Or you can create your own workshops and use the matching function to automatically find participants.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_INTEREST_LABEL: 'Stay informed about suitable workshops',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_OFFERING_LABEL: 'Offer workshops and find suitable participants',
    SIGN_UP_WORKSHOP_INTEREST_STEP_TITLE: 'Workshops',
    SIGN_UP_WORKSHOP_INTEREST_STEP_INFO:
        'What do I have to enter here - and why do you ask for skills? The reason: Suitable workshops are matched mainly based on your skills and professional orientation. There are also workshops that require certain skills and skill levels. If you want to have a chance to participate in such workshops, it is advisable to indicate as many of your skills and the corresponding level as possible.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_1:
        'Thank you for your interest in this workshop! Unfortunately, all currently available places are occupied. If capacities change or other participants drop out, something might become available again.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_HEADING: 'You‘ll be participating in the workshop!',
    WORKSHOP_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        "Here you can see which prior knowledge would be helpful for participating in this workshop. If you discover skills that you actually own, but don't have them in your profile yet, you can select them here and add them to your profile.",
    WORKSHOP_ENTITY_VIEW_REGISTERED_STATUS_NOTE: 'You got a seat for the workshop',
    WORKSHOP_DETAILS_VIEW_DESCRIPTION_TITLE: 'Workshop description',
    WORKSHOP_FORM_TITLE_LABEL: 'Title of Workshop',
    WORKSHOP_FORM_SUBSIDIARY_LABEL: 'Subsidiary (offering the workshop)',
    WORKSHOP_FORM_DEPARTMENT_LABEL: 'Department/Unit/Division offering the workshop',
    WORKSHOP_FORM_BUTTON_PUBLISH: 'Publish workshop',
    MY_WORKSHOPS_PLACEHOLDER_TEXT: 'You are not yet taking part in any workshop.',
    MY_WORKSHOPS_PLACEHOLDER_BTN: 'Browse matching workshops',
    WORKSHOP_SEARCH_HEADER: 'Search for Workshops by keywords',
};
