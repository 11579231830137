import { Resolve } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, tap, catchError, take, map } from 'rxjs/operators';
import { AccountNotFoundException } from './account-not-found.exception';
import { Injectable } from '@angular/core';
import { SignUpService } from './sign-up.service';
import { AccountState } from './account.reducer';
import { AccountActions } from './account.actions';
import { Account } from './account.types';

@Injectable({
    providedIn: 'root',
})
export class AccountResolver implements Resolve<Account> {
    constructor(
        private readonly store: Store<{ account: AccountState }>,
        private readonly signUpService: SignUpService,
    ) {}

    resolve(): Observable<Account> {
        return this.store
            .select((state) => state.account)
            .pipe(
                tap((accountData) => this.checkState(accountData)),
                filter((accountData) => !!accountData.generalData.current.data),
                map((accountState) => {
                    return {
                        userId: accountState.userId,
                        roles: accountState.roles,
                        generalData: accountState.generalData.current.data,
                        locale: accountState.locale.saved,
                        selectedTopics: accountState.selectedTopics.current.data,
                        settings: accountState.settings.current.data,
                        experience: accountState.experience.current.data,
                        completionScore: accountState.completionScore,
                    };
                }),
                catchError((error) => {
                    if (error instanceof AccountNotFoundException) {
                        this.signUpService.signUp();
                    }

                    return EMPTY;
                }),
                take(1),
            );
    }

    private checkState(state: AccountState): void {
        if (state.status.loadingError) {
            throw state.status.loadingError;
        }

        if (!state.status.loaded && !state.status.loading) {
            this.store.dispatch(AccountActions.load());
        }
    }
}
