import { LanguageOptions } from '@tploy-enterprise/tenant-common';
import { createReducer, on } from '@ngrx/store';
import { LocaleActions } from './locale.actions';
import { AccountActions } from '../account.actions';

export interface LocaleState {
    used: LanguageOptions;
    saved: LanguageOptions;
}

const localeInitialState: LocaleState = {
    used: undefined,
    saved: undefined,
};

export const localeReducer = createReducer(
    localeInitialState,
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        used: account.locale,
        saved: account.locale,
    })),
    on(LocaleActions.change, (state, { locale }) => ({ ...state, used: locale, saved: locale })),
    on(LocaleActions.saveSuccess, (state, { locale }) => ({ ...state, saved: locale })),
    on(AccountActions.deleteSuccess, () => ({ ...localeInitialState })),
    on(AccountActions.loadSsoDataSuccess, (state) => ({
        ...state,
    })),
);
