import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@tploy-enterprise/tenant-common';
import { NLAProfileTileLocationsPopoverData } from './nla-profile-tile-locations.component';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-profile-tile-extra-locations',
    templateUrl: './nla-profile-tile-locations-popover.component.html',
})
export class NlaProfileTileLocationsPopoverComponent {
    locations: AccountListItemTag[];

    constructor(@Inject(POPOVER_DATA) public popoverData: NLAProfileTileLocationsPopoverData) {
        this.locations = this.popoverData.locations;
    }
}
