import { createAction, props } from '@ngrx/store';
import { TechWolfSkills } from '@tploy-enterprise/tenant-common';

export const TechWolfActions = {
    loadTechWolfSuggestedSkills: createAction(
        '[TechWolf SuggestedSkills] load',
        props<{ skills: Array<string>; skillType: string }>(),
    ),
    loadTechWolfSuggestedSkillsSuccess: createAction(
        '[TechWolf SuggestedSkills][Success] load',
        props<{ skills: TechWolfSkills }>(),
    ),
    loadTechWolfSuggestedSkillsError: createAction('[TechWolf SuggestedSkills][Error] load', props<{ error: Error }>()),
    resetTechWolfSuggestedSkills: createAction('[TechWolf SuggestedSkills][Reset]'),
    resetTechWolfSuggestedSkillsSuccess: createAction('[TechWolf SuggestedSkills][Reset Success]'),
};
