import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    SuccessFactorsUserData,
    SuccessFactorsUserDataDTO,
    SUCCESS_FACTORS_USER_DATA_STORAGE_KEY,
} from './success-factors.types';

const PATH = '/api/v2/success-factor-proxy';

@Injectable({
    providedIn: 'root',
})
export class SuccessFactorsUserDataService {
    constructor(private http: HttpClient) {}

    getSuccessFactorsUserData(): Observable<SuccessFactorsUserData> {
        const storageData = window.sessionStorage.getItem(SUCCESS_FACTORS_USER_DATA_STORAGE_KEY);
        if (storageData) {
            const userData = JSON.parse(storageData);
            return new Observable((subscriber) => subscriber.next()).pipe(map(() => userData));
        } else {
            return this.http
                .get<SuccessFactorsUserDataDTO>(`${PATH}/user-data`, { observe: 'body' })
                .pipe(map((dto) => dto));
        }
    }
}
