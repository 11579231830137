import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule, CollapsableModule } from '@tploy-enterprise/tenant-common';
import { PreviewModule } from '@tploy-enterprise/tenant-core';
import { templateComponents } from './templates';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        LanguageModule,
        PreviewModule,
        MatButtonModule,
        CollapsableModule,
        RouterModule,
    ],
    declarations: [...templateComponents],
    exports: [...templateComponents],
})
export class CustomTemplateModule {}
