<section role="dialog" class="modal-form-layout_main" tpModalHelpers #modal="tpModalHelpers">
    <header class="form-header">
        <h1>{{ 'HELP_CONTACT_DIALOG_HEADER' | translatable }}</h1>
        <tp-icon-button
            [icon]="'close'"
            ariaLabel="{{ 'MODAL_CLOSE_LABEL' | translatable }}"
            (click)="modal.close()"
            class="no-border form-header_close"
        ></tp-icon-button>
    </header>
    <section class="modal-form-layout_content">
        <p>
            {{ supportBody | translatable }}
            <ng-container *ngIf="hasFeedBackOption">
                <br />
                {{ 'HELP_CONTACT_FEEDBACK_BODY_TEXT' | translatable }}
            </ng-container>
        </p>
        <p *ngIf="showClearTextLink">
            <b>
                <tp-basic-link [href]="supportLink" [target]="target">
                    {{ cleanMailsupportLink | translatable }}
                </tp-basic-link>
            </b>
        </p>

        <footer *ngIf="!showClearTextLink" class="form-footer invitation__content-footer form-footer--buttons-center">
            <nav>
                <tp-link [href]="supportLink" [target]="target">
                    {{ 'HELP_CONTACT_EMAIL_LINK' | translatable }}
                </tp-link>
                <tp-link *ngIf="hasFeedBackOption" [href]="feedBackUrl" [target]="target">
                    {{ 'HELP_CONTACT_FEEDBACK_CAPTION' | translatable }}
                </tp-link>
            </nav>
        </footer>
    </section>
</section>
