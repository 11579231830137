import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CardTag } from '../card.types';

@Component({
    selector: 'tp-card-tags-split-by-required',
    templateUrl: './card-tags-split-by-required.component.html',
    host: {
        class: 'card__tags',
    },
})
export class CardTagsSplitByRequiredComponent implements OnChanges {
    @Input() tags: CardTag[];
    requiredTags: CardTag[];
    helpfulTags: CardTag[];
    isRequiredTagsShown: boolean;
    isHelpfulTagsShown: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tags && changes.tags.currentValue) {
            this.requiredTags = this.tags.filter((tag) => tag.required);
            this.helpfulTags = this.tags.filter((tag) => !tag.required);
            this.isRequiredTagsShown = this.requiredTags.length > 0;
            this.isHelpfulTagsShown = this.helpfulTags.length > 0;
        }
    }
}
