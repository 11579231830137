import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    JobXEntityDTO,
    JobXProfileDTO,
    JobXProfileMatchResponseDTO,
    JobXProfileSearchResponseDTO,
    JobXSearchResponseDTO,
    JobXSearchResultDTO,
    JobXSuggestedSkillsDTO,
} from './jobx.dtos';
import { map } from 'rxjs/operators';
import { JOB_SHARING_TOPIC_NAME, OffsetLimit } from '@tploy-enterprise/tenant-core';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';
import { Params } from '@angular/router';

const BASE_PATH = `/api/v2/${JOB_SHARING_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class JobXApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<JobXProfileDTO> {
        return this.httpClient.get<JobXProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string) {
        return this.httpClient.get<JobXProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: JobXProfileDTO): Observable<JobXProfileDTO> {
        return this.httpClient.put<JobXProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, { observe: 'body' });
    }

    getEntity(entityId: string) {
        return this.httpClient.get<JobXEntityDTO>(`${BASE_PATH}/${entityId}`);
    }

    postEntity(jobXEntity: JobXEntityDTO) {
        return this.httpClient.post<JobXEntityDTO>(`${BASE_PATH}`, jobXEntity);
    }

    putEntity(jobXEntity: JobXEntityDTO): Observable<JobXEntityDTO> {
        return this.httpClient.put<JobXEntityDTO>(`${BASE_PATH}/${jobXEntity.id}`, jobXEntity, {
            observe: 'body',
        });
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.httpClient
            .delete(`${BASE_PATH}/${entityId}`, {
                responseType: 'text',
            })
            .pipe(map(() => entityId));
    }

    publishEntity(entityId: string) {
        return this.httpClient.get<JobXEntityDTO>(`${BASE_PATH}/${entityId}/publish`);
    }

    search(searchQuery: string) {
        return this.httpClient.get<JobXSearchResultDTO[]>(`${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`);
    }

    searchEntities(searchQuery: string, offsetLimit: OffsetLimit): Observable<JobXSearchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
        };
        return this.httpClient.get<JobXSearchResponseDTO>(
            `${BASE_PATH}/jobs/search?q=${encodeURIComponent(searchQuery)}`,
            { params },
        );
    }

    getEntitiesOfferedByMe(userId: string) {
        const params = new HttpParams({
            fromObject: {
                organizer: userId,
            },
        });

        return this.httpClient.get<JobXEntityDTO[]>(`${BASE_PATH}`, { params });
    }

    getProfileMatches(offsetLimit: OffsetLimit, campaigns?: string[]): Observable<JobXProfileMatchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<JobXProfileMatchResponseDTO>(`${BASE_PATH}/matches`, { params });
    }

    searchProfiles(
        searchQuery: string,
        offsetLimit: OffsetLimit,
        campaigns?: string[],
    ): Observable<JobXProfileSearchResponseDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };

        return this.httpClient.get<JobXProfileSearchResponseDTO>(
            `${BASE_PATH}/profiles/search?q=${encodeURIComponent(searchQuery)}`,
            { params },
        );
    }

    getSuggestedSkills(id: string): Observable<SuggestedSkills> {
        return this.httpClient.get<JobXSuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }
}
