import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestHttpInterceptor } from './request-http.interceptor';
import { RequestHandlerService } from './request-handler.service';

@NgModule({
    providers: [
        RequestHandlerService,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: RequestHttpInterceptor,
        },
    ],
})
export class RequestHandlerModule {}
