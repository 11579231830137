import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BookmarksService, EXPERTS_TOPIC_NAME } from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';
import { TopicExpertState } from '../../expert-store/topic-expert.reducer';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExpertBookmarkActions } from './expert-bookmark.actions';
import { ExpertService } from '../../expert-api/expert.service';

@Injectable({
    providedIn: 'root',
})
export class ExpertBookmarkEffects {
    constructor(
        private actions$: Actions,
        private bookmarksService: BookmarksService,
        private expertService: ExpertService,
        private store: Store<{ expert: TopicExpertState }>,
    ) {}

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertBookmarkActions.load),
            switchMap(() => this.expertService.getBookmarks(EXPERTS_TOPIC_NAME)),
            map((data) => ExpertBookmarkActions.loadSuccess({ data })),
            catchError((error) => {
                return of(ExpertBookmarkActions.loadError({ error }));
            }),
        ),
    );
}
