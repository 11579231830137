import { Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { GenerationExchangeProfile } from '../../generation-exchange-service/generation-exchange.types';
import { LocationService } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-generation-exchange-profile-view',
    templateUrl: './generation-exchange-profile-view.component.html',
})
export class GenerationExchangeProfileViewComponent implements TopicProfileView {
    _topicData: GenerationExchangeProfile;

    @Input('topicData')
    set topicData(profile: GenerationExchangeProfile) {
        this._topicData = profile;
    }

    get topicData(): GenerationExchangeProfile {
        return this._topicData;
    }

    get locations(): Array<string> {
        return this.locationService.getLocationsOrCountry(this.topicData.companyLocations);
    }

    get isLocationIndependent(): boolean {
        return this.topicData?.locationIndependent;
    }
    get locationIndependent(): string {
        return this.isLocationIndependent ? 'PROFILE_VIEW_GENERATION_EXCHANGE_LOCATION_ONLINE' : '';
    }

    get topics(): string[] {
        return this.topicData.skills;
    }

    get motivations(): string[] {
        const motivations: string[] = [];
        if (this.topicData.motivations.includes('exchangeKnowledge')) {
            motivations.push('PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_1');
        }
        if (this.topicData.motivations.includes('generationsCommunication')) {
            motivations.push('PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_2');
        }
        if (this.topicData.motivations.includes('networking')) {
            motivations.push('PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_3');
        }
        return motivations;
    }

    get generation(): string {
        return `PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_${this.topicData.generation}`;
    }

    get campaigns(): string[] {
        return this.topicData.campaigns;
    }

    constructor(private readonly locationService: LocationService) {}

    hasLocations(): boolean {
        return this.topicData?.companyLocations?.length > 0;
    }

    hasTopics(): boolean {
        return this.topicData?.skills?.length > 0;
    }

    hasMotivation(): boolean {
        return this.topicData?.motivations.length > 0;
    }

    hasGeneration(): boolean {
        return this.topicData?.generation != null;
    }

    hasCampaigns(): boolean {
        return this.topicData?.campaigns?.length > 0;
    }

    hasContent(): boolean {
        return (
            this.hasLocations() ||
            this.hasTopics() ||
            this.isLocationIndependent ||
            this.hasMotivation() ||
            this.hasGeneration() ||
            this.hasCampaigns()
        );
    }
}
