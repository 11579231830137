import { combineReducers, createReducer, on } from '@ngrx/store';
import { GeneralDataActions } from './general-data.actions';
import { GeneralData, GENERAL_DATA_STORAGE_EDIT_KEY } from './general-data.types';
import { AccountActions } from '../account.actions';
import { SuccessFactorsActions } from '../../success-factors';
import { CurrentState, ValidationErrors } from '../account.types';

interface GeneralDataEditState {
    dirty: boolean;
    validationErrors: ValidationErrors;
    data: GeneralData;
    newImage?: {
        uploading: boolean;
        uploadingError: Error;
    };
}

interface GeneralDataCurrentState {
    saving: boolean;
    savingError: Error;
    loading: boolean;
    loadingError: Error;
    data: GeneralData;
}

export type GeneralDataState = {
    edit: GeneralDataEditState;
    current: GeneralDataCurrentState;
};

const defaultInitialEditState: GeneralDataEditState = {
    dirty: false,
    validationErrors: null,
    data: {} as any,
};

function getInitialEditState(): GeneralDataEditState {
    const savedState = window.sessionStorage.getItem(GENERAL_DATA_STORAGE_EDIT_KEY);
    if (savedState) {
        return JSON.parse(savedState);
    } else return { ...defaultInitialEditState };
}

const editReducer = createReducer(
    getInitialEditState(),
    on(AccountActions.loadSuccess, (state, { account }) => {
        // unless there is an edition going on, keep the data in sync with the latest known state
        if (state.dirty === false) {
            return {
                ...state,
                data: account.generalData,
                dirty: false,
            };
        } else return state;
    }),
    on(AccountActions.deleteSuccess, () => ({ ...getInitialEditState() })),
    on(GeneralDataActions.edit, (state, { generalData, errors }) => ({
        ...state,
        data: { ...state.data, ...generalData },
        validationErrors: { ...state.validationErrors, ...errors },
        dirty: true,
    })),
    on(GeneralDataActions.validate, (state, { namespace, errors }) => ({
        ...state,
        validationErrors: { ...state.validationErrors, [namespace]: errors },
    })),
    on(GeneralDataActions.saveSucces, (state, { generalData }) => ({ ...state, data: generalData, dirty: false })),
    on(GeneralDataActions.abortEdit, (state, { generalData }) => ({ ...state, data: generalData, dirty: false })),
    on(GeneralDataActions.changeImage, (state, { localUrl }) => ({
        ...state,
        data: { ...state.data, imageUrl: localUrl },
        newImage: { uploading: true, uploadingError: null },
    })),
    on(GeneralDataActions.changeImageSuccess, (state, { imageUrl }) => ({
        ...state,
        data: { ...state.data, imageUrl },
        newImage: undefined,
    })),
    on(GeneralDataActions.changeImageError, (state, { error }) => ({
        ...state,
        newImage: { ...state.newImage, uploading: false, uploadingError: error },
    })),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => {
        return {
            ...state,
            data: {
                ...account.generalData,
                ...state.data,
            },
        };
    }),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { imageUrl, displayName }) => {
        return {
            ...state,
            data: {
                ...state.data,
                imageUrl: state.data.imageUrl ? state.data.imageUrl : imageUrl || '',
                name: state.data.name ? state.data.name : displayName || '',
            },
        };
    }),
);

const currentInitialState: CurrentState<GeneralData> = {
    data: null,
    loading: false,
    loadingError: null,
    saving: false,
    savingError: null,
};

const currentReducer = createReducer(
    currentInitialState,
    on(AccountActions.createSuccess, (state) => ({ ...state, loadingError: null })),
    on(AccountActions.load, (state) => ({ ...state, loading: true })),
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        loading: false,
        loadingError: null,
        data: account.generalData,
    })),
    on(AccountActions.loadError, (state, { error }) => ({ ...state, loading: false, loadingError: error })),
    on(GeneralDataActions.save, (state) => ({ ...state, saving: true })),
    on(GeneralDataActions.saveSucces, (state, { generalData }) => ({
        ...state,
        saving: false,
        savingError: null,
        data: generalData,
    })),
    on(GeneralDataActions.saveError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
);

export const generalDataReducer = combineReducers({
    current: currentReducer,
    edit: editReducer,
});
