import { createAction, props } from '@ngrx/store';
import { ExpertBookmark } from '../../expert-api/expert.types';

export const ExpertBookmarkActions = {
    load: createAction('[Expert bookmarks] Load'),
    fetchAccounts: createAction('[Expert bookmarks] Fetch accounts', props<{ data: Array<ExpertBookmark> }>()),
    fetchExpertProfiles: createAction(
        '[Expert bookmarks] Fetch expert profiles',
        props<{ data: Array<ExpertBookmark> }>(),
    ),
    loadSuccess: createAction('[Expert bookmarks][Success] Load', props<{ data: Array<ExpertBookmark> }>()),
    loadError: createAction('[Expert bookmarks][Error] Load', props<{ error: Error }>()),
};
