import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const formFieldDefaults = {
    appearance: 'standard',
    floatLabel: 'always',
};

@NgModule({
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: formFieldDefaults,
        },
    ],
})
export class StylesProvidersModule {}
