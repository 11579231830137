import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '../language';
import { CardContentComponent } from './card-content/card-content.component';
import { CardOrganizerComponent } from './card-organizer/card-organizer.component';
import { CardSubContentComponent } from './card-sub-content/card-sub-content.component';
import { CardActionsComponent } from './card-actions/card-actions.component';
import { CardFactsComponent } from './card-facts/card-facts.component';
import { CardTagsComponent } from './card-tags/card-tags.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardComponent } from './card.component';
import { CardCtaComponent } from './card-cta/card-cta.component';
import { CardTextComponent } from './card-text/card-text.component';
import { CardIndicatorComponent } from './card-indicator/card-indicator.component';
import { TagsHighlighterModule } from '../tags-highlighter';
import { LocalizedDateModule } from '../localized-date';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardTagsSplitByRequiredComponent } from './card-tags/card-tags-split-by-required.component';
import { CampaignsModule } from '../campaigns/campaigns.module';

const cardComponents = [
    CardComponent,
    CardFactsComponent,
    CardTagsComponent,
    CardTagsSplitByRequiredComponent,
    CardOrganizerComponent,
    CardContentComponent,
    CardSubContentComponent,
    CardActionsComponent,
    CardTitleComponent,
    CardCtaComponent,
    CardTextComponent,
    CardIndicatorComponent,
];

@NgModule({
    declarations: [...cardComponents],
    exports: [...cardComponents],
    imports: [
        CommonModule,
        MatIconModule,
        LanguageModule,
        TagsHighlighterModule,
        LocalizedDateModule,
        MatTooltipModule,
        CampaignsModule,
    ],
})
export class CardModule {}
