import { NgModule } from '@angular/core';
import { CollapsableContentDirective } from './collapsable-content.directive';
import { CollapsableDirective } from './collapsable.directive';
import { CollapsableTriggerDirective } from './collapsable-trigger.directive';

@NgModule({
    declarations: [CollapsableContentDirective, CollapsableDirective, CollapsableTriggerDirective],
    exports: [CollapsableContentDirective, CollapsableDirective, CollapsableTriggerDirective],
})
export class CollapsableModule {}
