import { createAction, props } from '@ngrx/store';
import { SelectedTopics } from './selected-topics.types';
import { ValidationErrors } from '../account.types';

export const SelectedTopicsActions = {
    edit: createAction(
        '[Account Selected Topics] edit',
        props<{ selectedTopics: SelectedTopics; errors: ValidationErrors }>(),
    ),
    resetErrors: createAction('[Account Selected Topics] resetErrors', props<{ errors: ValidationErrors }>()),
    cancelEdit: createAction('[Account Selected Topics] cancel edit'),
    unselectTopic: createAction('[Account Selected Topics] unselect', props<{ topic: string }>()),
    save: createAction('[Account Selected Topics] save'),
    saveSuccess: createAction('[Account Selected Topics][Success] save', props<{ selectedTopics: SelectedTopics }>()),
    saveError: createAction(
        '[Account Selected Topics][Error] save',
        props<{ error: Error; selectedTopics: SelectedTopics }>(),
    ),
    selectTopic: createAction('[Account Selected Topics] select', props<{ topic: string }>()),
};
