import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { JobXProfile } from '../../jobs.types';
import { TranslateService } from '@ngx-translate/core';
import { Skill } from '@tploy-enterprise/tenant-common';
import { LocationService } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-job-x-profile-view',
    templateUrl: './job-x-profile-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobXProfileViewComponent implements TopicProfileView {
    @Input()
    topicData: JobXProfile;

    get skills(): Skill[] {
        return this.topicData.skills;
    }

    get interestedInSkills(): Skill[] {
        return this.topicData.skillsInterestedIn;
    }

    get leadershipText(): string {
        const currentLabel = this.translationsService.instant('JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_CURRENT');
        const futureLabel = this.translationsService.instant('JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_FUTURE');
        const isInLeadershipPosition = this.translationsService.instant(
            this.topicData.currentlyInLeadershipPosition
                ? 'JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_YES'
                : 'JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_NO',
        );
        const wantsLeadershipPosition = this.translationsService.instant(this.topicData.wantsToBeInLeadershipPosition);
        return `${currentLabel}: ${isInLeadershipPosition} · ${futureLabel}: ${wantsLeadershipPosition}`;
    }

    get locations(): Array<string> {
        return this.locationService.getLocationsOrCountry(this.topicData.locations);
    }

    get campaigns(): string[] {
        return this.topicData.campaigns;
    }

    constructor(
        private readonly translationsService: TranslateService,
        private readonly locationService: LocationService,
    ) {}

    hasSkills(): boolean {
        return this.topicData && this.topicData.skills && this.topicData.skills.length > 0;
    }

    hasTandemSkills(): boolean {
        return this.topicData && this.topicData.skillsInterestedIn && this.topicData.skillsInterestedIn.length > 0;
    }

    hasCurrentJobLikes(): boolean {
        return this.topicData && this.topicData.currentJobLikes && this.topicData.currentJobLikes.length > 0;
    }

    hasCurrentJobDescription(): boolean {
        return (
            this.topicData && this.topicData.currentJobDescription && this.topicData.currentJobDescription.length > 0
        );
    }

    hasLocations(): boolean {
        return this.topicData?.locations?.length > 0;
    }

    hasCampaigns(): boolean {
        return this.topicData?.campaigns?.length > 0;
    }

    hasContent() {
        return (
            this.hasSkills() ||
            this.hasTandemSkills() ||
            this.hasLocations() ||
            this.hasCurrentJobLikes() ||
            this.hasCampaigns()
        );
    }
}
