import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'tp-matches-slider-side-buttons',
    templateUrl: './matches-slider-side-buttons.component.html',
    styleUrls: ['./matches-slider-side-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchesSliderSideButtonsComponent {
    @Input() paginator: MatPaginator;
    @Input() isLoading: boolean;

    nextButtonClicked: boolean;
    prevButtonClicked: boolean;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    next(): void {
        this.paginator.nextPage();
        this.nextButtonClicked = true;
        this.prevButtonClicked = false;
        this.changeDetectorRef.markForCheck();
    }

    prev(): void {
        this.paginator.previousPage();
        this.prevButtonClicked = true;
        this.nextButtonClicked = false;
        this.changeDetectorRef.markForCheck();
    }
}
