import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(value: string | Date, pattern = 'mediumDate'): string {
        const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
        return datePipe.transform(value, pattern);
    }
}
