import { createReducer, on } from '@ngrx/store';
import { SearchActions } from '../actions/search.actions';
import { directMessagesSearchInitialState } from '../store/search.state';

export const searchReducer = createReducer(
    directMessagesSearchInitialState,
    on(SearchActions.clearSearchResults, (state) => {
        return { ...state, results: [], loaded: false };
    }),
    // on(SearchActions.performSearch, (state) => {
    //     return { ...state, loading: true };
    // }),
    // on(SearchActions.performSearchSuccess, (state, { entities }) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         loaded: true,
    //         results: [...entities],
    //     };
    // }),
    // on(SearchActions.performSearchError, (state, { error }) => {
    //     return { ...state, loading: false, error: error };
    // }),
    on(SearchActions.performContactsSearch, (state) => {
        return { ...state, loading: true };
    }),
    on(SearchActions.performContactsSearchSuccess, (state, { results }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results,
        };
    }),
    on(SearchActions.performContactsSearchError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);
