import { createAction, props } from '@ngrx/store';
import { GeneralData } from './general-data.types';
import { ValidationErrors } from '../account.types';

export const GeneralDataActions = {
    edit: createAction(
        '[Account General Data] edit',
        props<{ generalData: Partial<GeneralData>; errors: ValidationErrors }>(),
    ),
    changeImage: createAction('[Account General Data] change profile image', props<{ localUrl: string }>()),
    changeImageSuccess: createAction(
        '[Account General Data][Success] change profile image',
        props<{ imageUrl: string }>(),
    ),
    changeImageError: createAction('[Account General Data][Error] change profile image', props<{ error: Error }>()),
    cancelEdit: createAction('[Account General Data] cancel edition'),
    abortEdit: createAction('[Account General Data] abort edit', props<{ generalData: GeneralData }>()),
    validate: createAction(
        '[Account General data] add validation error',
        props<{ namespace: 'string'; errors: string[] }>(),
    ),
    removeValidationErrors: createAction('[Account General data] remove validation errors', props<{ field: string }>()),

    // This is achieved at account level
    // load: createAction('[Account General Data] Load'),
    // loadSuccess: createAction('[Account General Data][Success] Load', props<{ generalData: GeneralData }>()),
    // loadError: createAction('[Account General Data][Error] Load', props<{ generalData: GeneralData; error: Error }>()),

    save: createAction('[Account General Data] Save'),
    saveSucces: createAction('[Account General Data][Success] save', props<{ generalData: GeneralData }>()),
    saveError: createAction('[Account General Data][Error] save', props<{ error: Error; generalData: GeneralData }>()),

    delete: createAction('[Account General Data] Delete'),
    deleteSuccess: createAction('[Account General Data][Success] Delete'),
    deleteError: createAction('[Account General Data][Error] Delete', props<{ error: Error }>()),
};
