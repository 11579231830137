import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SignUpMenuComponent } from './sign-up-menu.component';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { SignUpMenuViewComponent } from './sign-up-menu-view.component';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        ButtonModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        RouterModule,
        LanguageModule,
    ],
    declarations: [SignUpMenuComponent, SignUpMenuViewComponent],
    exports: [SignUpMenuComponent],
})
export class SignUpMenuModule {}
