import {
    Directive,
    ViewContainerRef,
    ComponentFactoryResolver,
    Input,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ComponentRef,
} from '@angular/core';
import { ProfileNavigatorView } from './navigator.types';
import { ComponentType } from '@angular/cdk/portal';

@Directive({
    selector: '[tpProfileNavigator]',
    exportAs: 'profileNavigatorContainer',
})
export class ProfileNavigatorDirective implements OnChanges {
    @Input() profileNavigatorView: ComponentType<ProfileNavigatorView>;
    @Input() isSearchScope: boolean;
    @Input() isMatchScope: boolean;
    @Input() isGlobalBookmarkScope: boolean;
    @Input() isTopicBookmarkScope: boolean;
    @Input() context: string;
    @Input() itemId: string;

    @Output()
    loadContent = new EventEmitter();

    private componentRef: ComponentRef<any>;

    constructor(
        private viewContainer: ViewContainerRef,
        private factoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const checkProperties = [
            'profileNavigatorView',
            'isSearchScope',
            'isMatchScope',
            'isGlobalBookmarkScope',
            'isTopicBookmarkScope',
            'itemId',
            'context',
        ];
        const hasChanges = Object.keys(changes).some((change) => checkProperties.includes(change));

        if (hasChanges) {
            this.viewContainer.clear();
            const factory = this.factoryResolver.resolveComponentFactory(this.profileNavigatorView);

            this.componentRef = this.viewContainer.createComponent(factory);
            this.componentRef.instance.isSearchScope = this.isSearchScope;
            this.componentRef.instance.isMatchScope = this.isMatchScope;
            this.componentRef.instance.isGlobalBookmarkScope = this.isGlobalBookmarkScope;
            this.componentRef.instance.isTopicBookmarkScope = this.isTopicBookmarkScope;
            this.componentRef.instance.context = this.context;
            this.componentRef.instance.itemId = this.itemId;

            this.cd.detectChanges();
        }
    }
}
