import { Injectable } from '@angular/core';
import { JobXApi } from './job-x-api/job-x-api.service';
import { JobXSerializer } from './jobs-serializer.service';
import {
    JobXBookmark,
    JobXEntity,
    JobXOthersProfile,
    JobXProfile,
    JobXProfileMatch,
    JobXProfileMatches,
    JobXProfileSearchResult,
    JobXProfileSearchResults,
    JobXSearchResult,
    JobXSearchResults,
    JobXSuggestedSkills,
} from './jobs.types';
import {
    AccountService,
    BookmarksApi,
    BookmarksService,
    GeneralData,
    OthersAccount,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    JobXDomainIndexException,
    JobXDomainMatchingException,
    JobXDomainSearchException,
    JobXEntityBadRequestException,
    JobXEntityForbiddenException,
    JobXEntityNotFoundException,
    JobXMyProfileNotFoundException,
    JobXOthersProfileNotFoundException,
    JobXProfileBadRequestException,
    JobXUnexpectedException,
} from './jobx.exceptions';

@Injectable({
    providedIn: 'root',
})
export class JobXService {
    constructor(
        private readonly jobXApi: JobXApi,
        private readonly jobXSerializer: JobXSerializer,
        private readonly accountService: AccountService,
        private readonly bookmarksService: BookmarksService,
        private readonly bookmarksApi: BookmarksApi,
        private readonly paginatorService: PaginatorService,
    ) {}

    saveProfile(profile: JobXProfile, general: GeneralData): Observable<JobXProfile> {
        const dto = this.jobXSerializer.serializeJobXProfile(profile, general);
        return this.jobXApi.putProfile(dto).pipe(
            map((projectsProfileDTO) => this.jobXSerializer.deserializeJobXProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfile(): Observable<JobXProfile> {
        return this.jobXApi.getProfile().pipe(
            map((projectsProfileDTO) => this.jobXSerializer.deserializeJobXProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<JobXOthersProfile> {
        return this.jobXApi.getProfileById(id).pipe(
            map((projectsProfileDTO) => this.jobXSerializer.deserializeJobXOthersProfile(projectsProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    saveEntity(jobXEntity: JobXEntity): Observable<JobXEntity> {
        const dto = this.jobXSerializer.serializeJobXEntity(jobXEntity);
        return this.jobXApi.putEntity(dto).pipe(
            map((projectsEntityDTO) => this.jobXSerializer.deserializeJobXEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    createEntity(jobXEntity: JobXEntity): Observable<JobXEntity> {
        const dto = this.jobXSerializer.serializeJobXEntity(jobXEntity);
        return this.jobXApi.postEntity(dto).pipe(
            map((projectsEntityDTO) => this.jobXSerializer.deserializeJobXEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.jobXApi.deleteEntity(entityId).pipe(catchError((error) => this.handleEntityError(error)));
    }

    publishEntity(entityId: string): Observable<JobXEntity> {
        return this.jobXApi.publishEntity(entityId).pipe(
            map((projectsEntityDTO) => this.jobXSerializer.deserializeJobXEntity(projectsEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    searchEntities(searchQuery: string, pageParams: PageParams): Observable<JobXSearchResults> {
        let resultsTotal = 0;
        return this.jobXApi.searchEntities(searchQuery, this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (resultsTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.jobXSerializer.deserializeJobXSearchResult(dto))),
            map((results) => {
                if (resultsTotal > 0) {
                    return { results, pageParams: { ...pageParams, length: resultsTotal } };
                } else {
                    return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new JobXDomainSearchException();
            }),
        );
    }

    getSuggestedSkills(id: string): Observable<JobXSuggestedSkills> {
        return this.jobXApi.getSuggestedSkills(id).pipe(
            catchError(() => of({ aType: [], lType: [], sType: [] })),
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
        );
    }

    private hydrateJobXSearchResult(partialSearchResult: Partial<JobXSearchResult>): Observable<JobXSearchResult> {
        return this.loadEntity(partialSearchResult.jobId).pipe(
            map((entity) => {
                return {
                    jobId: partialSearchResult.jobId,
                    highlight: partialSearchResult.highlight,
                    jobEntity: entity,
                };
            }),
        );
    }

    loadEntity(entityId: string): Observable<JobXEntity> {
        return this.jobXApi.getEntity(entityId).pipe(
            map((dto) => this.jobXSerializer.deserializeJobXEntity(dto)),
            catchError((error: Error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    loadEntitiesOfferedByMe(userId: string): Observable<JobXEntity[]> {
        return this.jobXApi.getEntitiesOfferedByMe(userId).pipe(
            map((jobEntityDTO) => jobEntityDTO.map((dto) => this.jobXSerializer.deserializeJobXEntity(dto))),
            catchError(() => {
                throw new JobXDomainIndexException();
            }),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
            catchError((error) => {
                console.error(error);
                return of([]);
            }),
        );
    }

    getProfileMatches(pageParams: PageParams, campaigns: string[]): Observable<JobXProfileMatches> {
        return this.jobXApi.getProfileMatches(this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            map((response) => response.results),
            catchError(() => {
                throw new JobXDomainMatchingException();
            }),
            map((results) => results.map((dto) => this.jobXSerializer.deserializeJobXProfileMatch(dto))),
            switchMap((matches) => {
                if (matches.length > 0) {
                    const hydratedMatches = matches.map((result) => this.hydrateJobXProfileMatch(result));
                    return forkJoin(hydratedMatches).pipe(
                        map((hydratedMatches) => ({
                            hydratedMatches,
                            mergedMatches: matches.map((i) => hydratedMatches.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedMatches, mergedMatches }) => {
                            return {
                                matches: hydratedMatches.filter((hydratedResult) => {
                                    if (campaigns?.length > 0) {
                                        hydratedResult.highlight.campaigns = campaigns;
                                    }
                                    return !!hydratedResult.profile;
                                }),
                                allMatches: this.paginatorService.fillAllPagesArray(mergedMatches, pageParams),
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({ matches: [], allMatches: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } });
                }
            }),
        );
    }

    hydrateJobXProfileMatch(partialProjectsProfileMatch: JobXProfileMatch): Observable<JobXProfileMatch> {
        const bookmark$ = this.bookmarksApi.getBookmark(partialProjectsProfileMatch.userId);
        return forkJoin({ bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...partialProjectsProfileMatch, ...hydrationData })),
        );
    }

    hydrateJobXOnlyProfileMatch(partialProjectsProfileMatch: JobXProfileMatch): Observable<JobXProfileMatch> {
        return this.loadProfileById(partialProjectsProfileMatch.userId).pipe(
            map((result) => {
                return {
                    ...result,
                    ...partialProjectsProfileMatch,
                };
            }),
            catchError(() => of(null)),
        );
    }

    searchProfiles(
        searchQuery: string,
        pageParams: PageParams,
        campaigns: string[],
    ): Observable<JobXProfileSearchResults> {
        return this.jobXApi.searchProfiles(searchQuery, this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            catchError(() => {
                throw new JobXDomainSearchException();
            }),
            map((response) => {
                return response.results.map((searchResultsDTO) => {
                    return this.jobXSerializer.deserializeJobXProfileSearchResult(searchResultsDTO);
                });
            }),
            switchMap((results) => {
                if (results.length > 0) {
                    const hydratedResults = results.map((result) => this.hydrateJobXProfileSearchResult(result));
                    return forkJoin(hydratedResults).pipe(
                        map((hydratedResults) => ({
                            hydratedResults,
                            mergedResults: results.map((i) => hydratedResults.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedResults, mergedResults }) => {
                            return {
                                results: hydratedResults.filter((hydratedResult) => {
                                    if (campaigns?.length > 0) {
                                        hydratedResult.highlight.campaigns = campaigns;
                                    }
                                    return !!hydratedResult.profile;
                                }),
                                allResults: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({ results: [], allResults: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } });
                }
            }),
        );
    }

    loadSearchResult(searchQuery: string, index: number): Observable<JobXProfileSearchResult> {
        return this.jobXApi.searchProfiles(searchQuery, { offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) =>
                results.length > 0 ? this.jobXSerializer.deserializeJobXProfileSearchResult(results[0]) : null,
            ),
            catchError(() => {
                throw new JobXDomainSearchException();
            }),
        );
    }

    loadMatchResult(index: number): Observable<JobXProfileMatch> {
        return this.jobXApi.getProfileMatches({ offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) => (results.length > 0 ? this.jobXSerializer.deserializeJobXProfileMatch(results[0]) : null)),
            catchError(() => {
                throw new JobXDomainSearchException();
            }),
        );
    }

    hydrateJobXProfileSearchResult(
        partialJobXProfileSearchResult: JobXProfileSearchResult,
    ): Observable<JobXProfileSearchResult> {
        const bookmark$ = this.bookmarksApi.getBookmark(partialJobXProfileSearchResult.userId);

        return forkJoin({ bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...partialJobXProfileSearchResult, ...hydrationData })),
        );
    }

    private hydrateEntity(entity: JobXEntity): Observable<JobXEntity> {
        const organizer$ = this.getOrganizerData(entity);

        return forkJoin({ organizerData: organizer$ }).pipe(map((hydrationData) => ({ ...entity, ...hydrationData })));
    }

    private getOrganizerData(entity: JobXEntity): Observable<Partial<OthersAccount>> {
        if (entity.organizerData) {
            return of(entity.organizerData);
        }

        return this.accountService.getAccountById(entity.organizer);
    }

    getBookmarks(context: string): Observable<Array<JobXBookmark>> {
        return this.bookmarksService.loadBookmarksByContext(context).pipe(
            map((results) => results.slice(0, 20)),
            map((results) => results.map((dto) => this.jobXSerializer.deserializeJobXBookmark(dto))),
            switchMap((results) => {
                if (results.length > 0) {
                    return forkJoin(results.map((result) => this.hydrateJobXBookmark(result)));
                } else {
                    return of([]);
                }
            }),
            map((bookmarks) => bookmarks.filter((bookmark) => !!bookmark.profile)),
        );
    }

    private hydrateJobXBookmark(result: JobXBookmark): Observable<JobXBookmark> {
        const profile$ = this.loadProfileById(result.entityId).pipe(catchError(() => of(null)));
        const bookmark$ = this.bookmarksApi.getBookmark(result.entityId).pipe(take(1));

        return combineLatest([profile$, bookmark$]).pipe(
            map(([profile, bookmark]) => ({ ...result, bookmark, profile })),
        );
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new JobXProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new JobXMyProfileNotFoundException();
                    } else {
                        throw new JobXOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new JobXUnexpectedException(error);
        }
    }

    private handleEntityError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new JobXEntityBadRequestException(error.error);
                case 403:
                    throw new JobXEntityForbiddenException();
                case 404:
                    throw new JobXEntityNotFoundException();
                default:
                    throw error;
            }
        } else {
            throw new JobXUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
