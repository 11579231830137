import { createAction, props } from '@ngrx/store';
import { ImageType, Media } from './media.types';

export const MediaActions = {
    loadAllUserMedia: createAction('[Media] Load all user media', props<{ imageType: ImageType }>()),
    loadAllUserMediaSuccess: createAction('[Media][Success] Load all user media', props<{ data: Array<Media> }>()),
    loadAllUserMediaError: createAction('[Media][Error] Load all user media', props<{ error: Error }>()),

    loadUserMedia: createAction('[Media] Load user media', props<{ id: string }>()),
    loadUserMediaSuccess: createAction('[Media][Success] Load user media', props<{ data: Media }>()),
    loadUserMediaError: createAction('[Media][Error] Load user media', props<{ error: Error }>()),

    deleteUserMedia: createAction('[Media] Delete user media', props<{ id: string }>()),
    deleteUserMediaSuccess: createAction('[Media][Success] Delete user media', props<{ id: string }>()),
    deleteUserMediaError: createAction('[Media][Error] Delete user media', props<{ error: Error }>()),

    uploadImageSuccess: createAction('[Media][Success] Upload image', props<{ imageUrl: string }>()),
};
