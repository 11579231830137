import { Injectable } from '@angular/core';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { TopicViewData, TopicDefinition, BookmarksPageTopicViewData } from './topic.types';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CommonState } from '../common';
import { SelectedTopicsDefinitionsService } from '../account/selected-topics/selected-topics-definitions.service';
import {
    WORKSHOPS_TOPIC_NAME,
    PROJECTS_TOPIC_NAME,
    SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    INTEREST_KEY,
} from '../../tenant-core.constants';

@Injectable({
    providedIn: 'root',
})
export class TopicsDataService {
    constructor(
        private topicDefinitions: SelectedTopicsDefinitionsService,
        private store: Store<{ common: CommonState }>,
    ) {}

    getOwnTopicsData(topics: string[]): Observable<TopicViewData[]> {
        const definitions$ = this.getSelectedTopicDefinitions(topics).pipe();
        const data$ = this.resolveOwnTopicsData(topics);

        return combineLatest(definitions$, data$).pipe(
            map(([definitions, topicsData]) => {
                return this.mergeDefinitionsAndData(definitions, topicsData);
            }),
        );
    }

    getOthersTopicsData(id: string, topics: string[]): Observable<TopicViewData[]> {
        const definitions$ = this.getSelectedTopicDefinitions(topics);
        const data$ = this.resolveOthersData(topics, id);

        return combineLatest(definitions$, data$).pipe(
            map(([definitions, topicsData]) => {
                return this.mergeDefinitionsAndData(definitions, topicsData);
            }),
        );
    }

    getInterestedInTopics(topicsData: TopicViewData[]): Array<string> {
        const goalTopics = [WORKSHOPS_TOPIC_NAME, PROJECTS_TOPIC_NAME, SHORT_TIME_ASSIGNMENTS_TOPIC_NAME];
        const motivationKey = INTEREST_KEY;
        return topicsData
            .filter((topic) => {
                const isGoalTopic = goalTopics.includes(topic.name);
                if (isGoalTopic) {
                    if (topic.topicData) {
                        const motivations = topic.topicData.selectedMotivations || topic.topicData.motivations || [];
                        return motivations.includes(motivationKey);
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            })
            .map((topic) => topic.name);
    }

    getBookmarksPageTopicViews(topics: string[]): Observable<BookmarksPageTopicViewData[]> {
        return of(
            this.topicDefinitions
                .fromTopicList(topics)
                .filter((definition) => !!definition.embeddableContent.userCardView)
                .map((definition) => {
                    return {
                        name: definition.name,
                        label: definition.embeddableContent.sidenavItem.label,
                        icon: {
                            svgIcon: definition.embeddableContent.sidenavItem.svgIcon,
                        },
                        topicUserCardView: definition.embeddableContent.userCardView.component,
                    } as BookmarksPageTopicViewData;
                }),
        );
    }

    getProfileNavigatorView(topic: string): any {
        return this.topicDefinitions
            .fromTopicList([topic])
            .filter((definition) => !!definition.embeddableContent.profileNavigatorView)
            .filter((i) => i)
            .map((definition) => definition.embeddableContent.profileNavigatorView.component)
            .pop();
    }

    getTandemCheckView(topic: string): any {
        return this.topicDefinitions
            .fromTopicList([topic])
            .filter((definition) => !!definition.embeddableContent.tandemCheckView)
            .filter((i) => i)
            .map((definition) => definition.embeddableContent.tandemCheckView.component)
            .pop();
    }

    getApplicationsIndicatorView(topic: string): any {
        return this.topicDefinitions
            .fromTopicList([topic])
            .filter((definition) => !!definition.embeddableContent.applicantsIndicatorView)
            .filter((i) => i)
            .map((definition) => definition.embeddableContent.applicantsIndicatorView.component)
            .pop();
    }

    getTopicDefinitionsWithNotification(topics: string[]): Array<TopicDefinition> {
        return this.topicDefinitions
            .fromTopicList(topics)
            .filter((definition) => definition.notification)
            .filter((i) => i);
    }

    saveTopicsData(topics: string[]): Observable<boolean> {
        const definitions$ = this.getSelectedTopicDefinitions(topics);
        const save$ = definitions$.pipe(
            map((definition) => definition.map((d) => d.eventHandlers.onProfileSave())),
            switchMap((saveHandlers) => forkJoin(saveHandlers)),
            map(() => true),
        );

        return save$;
    }

    private resolveOthersData(topics: string[], id: string): Observable<{ [key: string]: TopicViewData }> {
        return this.getSelectedTopicDefinitions(topics).pipe(
            map((defs) => {
                const resolversMap: { [key: string]: Observable<any> } = {};
                defs.forEach((def) => {
                    if (def.actions) {
                        this.store.dispatch(def.actions.loadOtherProfile({ topicName: def.name }));
                    }
                    return (resolversMap[def.name] = def.eventHandlers.onOthersProfileLoad(id).pipe(
                        map((value) => {
                            if (def.actions) {
                                this.store.dispatch(def.actions.loadOtherProfileSuccess({ topicName: def.name }));
                            }
                            return value;
                        }),
                        catchError((error) => {
                            if (def.actions) {
                                this.store.dispatch(def.actions.loadOtherProfileError({ topicName: def.name }));
                            }
                            return of(null);
                        }),
                    ));
                });

                return resolversMap;
            }),
            switchMap((resolversMap) => forkJoin(resolversMap)),
        );
    }

    private resolveOwnTopicsData(topics: string[]) {
        return this.getSelectedTopicDefinitions(topics).pipe(
            map((defs) => {
                const resolversMap: { [key: string]: Observable<any> } = {};
                defs.forEach(
                    (def) =>
                        (resolversMap[def.name] = def.eventHandlers
                            .onOwnProfileLoad()
                            .pipe(catchError((error) => of(null)))),
                );

                return resolversMap;
            }),
            switchMap((resolversMap) => forkJoin(resolversMap)),
        );
    }

    private mergeDefinitionsAndData(definitions: TopicDefinition[], topicsData: any): TopicViewData[] {
        return definitions.map((def) => this.mergeDefinitionAndData(def, topicsData[def.name]));
    }

    private mergeDefinitionAndData(definition: TopicDefinition, data: any): TopicViewData {
        return {
            icon: {
                svgIcon: definition.embeddableContent.sidenavItem.svgIcon,
            },
            label: definition.embeddableContent.sidenavItem.label,
            name: definition.name,
            topicView: definition.embeddableContent.profileView.component,
            links: {
                edition: definition.links.edition,
                signUp: definition.links.signUp,
            },
            permissions: [definition.embeddableContent.sidenavItem.editPermission],
            topicData: data,
        };
    }

    private getSelectedTopicDefinitions(selectedTopics: string[]): Observable<TopicDefinition[]> {
        return of(this.topicDefinitions.fromTopicList(selectedTopics));
    }
}
