import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnaireData, QuestionStepDefinition } from './questionnaire.types';
import { QuestionnaireSerializer } from './questionnaire.serrializer';

const PATH = '/api/v2/accounts';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireService {
    constructor(private http: HttpClient, private serializer: QuestionnaireSerializer) {}

    save(questionnaire: QuestionnaireData): Observable<QuestionnaireData> {
        const dto = this.serializer.serialize(questionnaire);
        return this.http.put(`${PATH}/questionnaire`, dto).pipe(map(() => questionnaire));
    }

    getQuestions(): Array<QuestionStepDefinition> {
        return [
            {
                id: '',
                header: 'QUESTIONNAIRE_START_HEADER',
                bodyText: 'QUESTIONNAIRE_START_BODY_TEXT',
            },
            {
                id: '1',
                header: 'QUESTIONNAIRE_1_HEADER',
                maxSelection: 3,
                options: [
                    'QUESTIONNAIRE_1_ANSWER_1',
                    'QUESTIONNAIRE_1_ANSWER_2',
                    'QUESTIONNAIRE_1_ANSWER_3',
                    'QUESTIONNAIRE_1_ANSWER_4',
                    'QUESTIONNAIRE_1_ANSWER_5',
                    'QUESTIONNAIRE_1_ANSWER_6',
                    'QUESTIONNAIRE_1_ANSWER_7',
                ],
                input: 'QUESTIONNAIRE_1_ANSWER_8',
                placeholder: 'QUESTIONNAIRE_1_ANSWER_8',
            },
            {
                id: '2',
                header: 'QUESTIONNAIRE_2_HEADER',
                maxSelection: 1,
                options: [
                    'QUESTIONNAIRE_2_ANSWER_1',
                    'QUESTIONNAIRE_2_ANSWER_2',
                    'QUESTIONNAIRE_2_ANSWER_3',
                    'QUESTIONNAIRE_2_ANSWER_4',
                    'QUESTIONNAIRE_2_ANSWER_5',
                ],
            },
            {
                id: '3',
                header: 'QUESTIONNAIRE_3_HEADER',
                maxSelection: 3,
                options: [
                    'QUESTIONNAIRE_3_ANSWER_1',
                    'QUESTIONNAIRE_3_ANSWER_2',
                    'QUESTIONNAIRE_3_ANSWER_3',
                    'QUESTIONNAIRE_3_ANSWER_4',
                    'QUESTIONNAIRE_3_ANSWER_5',
                    'QUESTIONNAIRE_3_ANSWER_6',
                    'QUESTIONNAIRE_3_ANSWER_7',
                    'QUESTIONNAIRE_3_ANSWER_8',
                    'QUESTIONNAIRE_3_ANSWER_9',
                ],
            },
            {
                id: '4',
                header: 'QUESTIONNAIRE_4_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_4_ANSWER_1', 'QUESTIONNAIRE_4_ANSWER_2', 'QUESTIONNAIRE_4_ANSWER_3'],
            },
            {
                id: '5',
                header: 'QUESTIONNAIRE_5_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_5_ANSWER_1', 'QUESTIONNAIRE_5_ANSWER_2'],
            },
            {
                id: '6',
                header: 'QUESTIONNAIRE_6_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_6_ANSWER_1', 'QUESTIONNAIRE_6_ANSWER_2', 'QUESTIONNAIRE_6_ANSWER_3'],
                shortHeader: 'QUESTIONNAIRE_SHORT_6_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_6_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_6_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_6_ANSWER_3',
                ],
            },
            {
                id: '7',
                header: 'QUESTIONNAIRE_7_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_7_ANSWER_1', 'QUESTIONNAIRE_7_ANSWER_2', 'QUESTIONNAIRE_7_ANSWER_3'],
                shortHeader: 'QUESTIONNAIRE_SHORT_7_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_7_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_7_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_7_ANSWER_3',
                ],
            },
            {
                id: '8',
                header: 'QUESTIONNAIRE_8_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_8_ANSWER_1', 'QUESTIONNAIRE_8_ANSWER_2', 'QUESTIONNAIRE_8_ANSWER_3'],
            },
            {
                id: '9',
                header: 'QUESTIONNAIRE_9_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_9_ANSWER_1', 'QUESTIONNAIRE_9_ANSWER_2'],
                shortHeader: 'QUESTIONNAIRE_SHORT_9_HEADER',
                shortOptions: ['QUESTIONNAIRE_SHORT_9_ANSWER_1', 'QUESTIONNAIRE_SHORT_9_ANSWER_2'],
            },
            {
                id: '10',
                header: 'QUESTIONNAIRE_10_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_10_ANSWER_1', 'QUESTIONNAIRE_10_ANSWER_2'],
            },
            {
                id: '11',
                header: 'QUESTIONNAIRE_11_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_11_ANSWER_1', 'QUESTIONNAIRE_11_ANSWER_2', 'QUESTIONNAIRE_11_ANSWER_3'],
            },
            {
                id: '12',
                header: 'QUESTIONNAIRE_12_HEADER',
                maxSelection: 1,
                options: [
                    'QUESTIONNAIRE_12_ANSWER_1',
                    'QUESTIONNAIRE_12_ANSWER_2',
                    'QUESTIONNAIRE_12_ANSWER_3',
                    'QUESTIONNAIRE_12_ANSWER_4',
                ],
                shortHeader: 'QUESTIONNAIRE_SHORT_12_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_12_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_12_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_12_ANSWER_3',
                    'QUESTIONNAIRE_SHORT_12_ANSWER_4',
                ],
            },
            {
                id: '13',
                header: 'QUESTIONNAIRE_13_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_13_ANSWER_1', 'QUESTIONNAIRE_13_ANSWER_2', 'QUESTIONNAIRE_13_ANSWER_3'],
            },
            {
                id: '14',
                header: 'QUESTIONNAIRE_14_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_14_ANSWER_1', 'QUESTIONNAIRE_14_ANSWER_2'],
                shortHeader: 'QUESTIONNAIRE_SHORT_14_HEADER',
                shortOptions: ['QUESTIONNAIRE_SHORT_14_ANSWER_1', 'QUESTIONNAIRE_SHORT_14_ANSWER_2'],
            },
            {
                id: '15',
                header: 'QUESTIONNAIRE_15_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_15_ANSWER_1', 'QUESTIONNAIRE_15_ANSWER_2', 'QUESTIONNAIRE_15_ANSWER_3'],
                shortHeader: 'QUESTIONNAIRE_SHORT_15_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_15_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_15_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_15_ANSWER_3',
                ],
            },
            {
                id: '16',
                header: 'QUESTIONNAIRE_16_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_16_ANSWER_1', 'QUESTIONNAIRE_16_ANSWER_2'],
                shortHeader: 'QUESTIONNAIRE_SHORT_16_HEADER',
                shortOptions: ['QUESTIONNAIRE_SHORT_16_ANSWER_1', 'QUESTIONNAIRE_SHORT_16_ANSWER_2'],
            },
            {
                id: '17',
                header: 'QUESTIONNAIRE_17_HEADER',
                maxSelection: 3,
                options: [
                    'QUESTIONNAIRE_17_ANSWER_1',
                    'QUESTIONNAIRE_17_ANSWER_2',
                    'QUESTIONNAIRE_17_ANSWER_3',
                    'QUESTIONNAIRE_17_ANSWER_4',
                    'QUESTIONNAIRE_17_ANSWER_5',
                    'QUESTIONNAIRE_17_ANSWER_6',
                    'QUESTIONNAIRE_17_ANSWER_7',
                    'QUESTIONNAIRE_17_ANSWER_8',
                    'QUESTIONNAIRE_17_ANSWER_9',
                    'QUESTIONNAIRE_17_ANSWER_10',
                    'QUESTIONNAIRE_17_ANSWER_11',
                    'QUESTIONNAIRE_17_ANSWER_12',
                    'QUESTIONNAIRE_17_ANSWER_13',
                ],
                shortHeader: 'QUESTIONNAIRE_SHORT_17_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_17_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_3',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_4',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_5',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_6',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_7',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_8',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_9',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_10',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_11',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_12',
                    'QUESTIONNAIRE_SHORT_17_ANSWER_13',
                ],
            },
            {
                id: '18',
                header: 'QUESTIONNAIRE_18_HEADER',
                maxSelection: 1,
                options: ['QUESTIONNAIRE_18_ANSWER_1', 'QUESTIONNAIRE_18_ANSWER_2'],
                shortHeader: 'QUESTIONNAIRE_SHORT_18_HEADER',
                shortOptions: ['QUESTIONNAIRE_SHORT_18_ANSWER_1', 'QUESTIONNAIRE_SHORT_18_ANSWER_2'],
            },
            {
                id: '19',
                header: 'QUESTIONNAIRE_19_HEADER',
                maxSelection: 1,
                options: [
                    'QUESTIONNAIRE_19_ANSWER_1',
                    'QUESTIONNAIRE_19_ANSWER_2',
                    'QUESTIONNAIRE_19_ANSWER_3',
                    'QUESTIONNAIRE_19_ANSWER_4',
                    'QUESTIONNAIRE_19_ANSWER_5',
                    'QUESTIONNAIRE_19_ANSWER_6',
                    'QUESTIONNAIRE_19_ANSWER_7',
                ],
                shortHeader: 'QUESTIONNAIRE_SHORT_19_HEADER',
                shortOptions: [
                    'QUESTIONNAIRE_SHORT_19_ANSWER_1',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_2',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_3',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_4',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_5',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_6',
                    'QUESTIONNAIRE_SHORT_19_ANSWER_7',
                ],
            },
            {
                id: '20',
                header: 'QUESTIONNAIRE_20_HEADER',
                textarea: 'QUESTIONNAIRE_20_PLACEHOLDER',
                placeholder: 'QUESTIONNAIRE_20_PLACEHOLDER',
                shortHeader: 'QUESTIONNAIRE_SHORT_20_HEADER',
            },
            {
                id: '21',
                header: 'QUESTIONNAIRE_21_HEADER',
                textarea: 'QUESTIONNAIRE_21_PLACEHOLDER',
                placeholder: 'QUESTIONNAIRE_21_PLACEHOLDER',
                shortHeader: 'QUESTIONNAIRE_SHORT_21_HEADER',
            },
        ];
    }

    getShortQuestionIds(): string[] {
        return ['6', '7', '9', '12', '14', '15', '16', '17', '18', '19', '20', '21'];
    }

    getNextQuestion(currentQuestion: QuestionStepDefinition): QuestionStepDefinition {
        const currentIndex = this.getQuestions().findIndex((question) => question.id === currentQuestion.id);
        return this.getQuestions()[currentIndex + 1];
    }
    getPreviousQuestion(currentQuestion: QuestionStepDefinition): QuestionStepDefinition {
        const currentIndex = this.getQuestions().findIndex((question) => question.id === currentQuestion.id);
        return this.getQuestions()[currentIndex - 1];
    }
    getQuestion(id: string): QuestionStepDefinition {
        return this.getQuestions().find((question) => question.id === id);
    }
}
