export default {
    NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    NEVER_LUNCH_ALONE_INFO:
        'You are new to the company and would like to exchange ideas with colleagues over a coffee or a meal in a relaxed atmosphere? Or you have been a member for some time and would like to meet new colleagues from other departments or locations? About Tandemploy SaaS you will find interesting colleagues with whom you can meet without obligation.',

    SIGN_UP_NEVER_LUNCH_ALONE_TITLE: 'Never Lunch Alone',
    SIGN_UP_NEVER_LUNCH_ALONE_SUBTITLE: 'Meet colleagues for lunch or a cup of coffee.',

    NEVER_LUNCH_ALONE_PROFILE_LOCATION_LABEL: 'At what locations are you available for lunch dates?',
    NEVER_LUNCH_ALONE_PROFILE_LOCATION_INDEPENDENT_LABEL: 'open for virtual meeting',
    NEVER_LUNCH_ALONE_PROFILE_TOPICS_LABEL: 'Which topics are you interested in?',
    NEVER_LUNCH_ALONE_PROFILE_TOPICS_PLACEHOLDER: 'e.g., sabbatical, marketing, running group',
    NEVER_LUNCH_ALONE_PROFILE_DISHES_LABEL: "Last but not least: What's your favourite food?",
    NEVER_LUNCH_ALONE_PROFILE_DISHES_PLACEHOLDER: 'e.g., chocolate pudding',

    PROFILE_VIEW_NEVER_LUNCH_ALONE_LOCATIONS_LABEL: 'Location',
    PROFILE_VIEW_NEVER_LUNCH_ALONE_TOPICS_LABEL: 'Topic interests',
    PROFILE_VIEW_NEVER_LUNCH_ALONE_DISHES_LABEL: 'Favourite dish',

    PROFILE_NEVER_LUNCH_ALONE_LOCATION_INDEPENDENT_LABEL: "Colleagues I'd like to meet",
    PROFILE_NEVER_LUNCH_ALONE_OPTION_SAME_LOCATION: 'Colleagues from my location',
    PROFILE_NEVER_LUNCH_ALONE_OPTION_ALL: 'Colleagues from all locations',

    NLA_MATCHES_TAB: 'Matches',
    NLA_BOOKMARKS_TAB: 'Bookmarks',
    NLA_SEARCH_TAB: 'Search',

    NLA_NO_MATCH_IMG_ALT: 'No matches found',
    NLA_NO_MATCH_CONTENT: 'Please specify more skills and (hidden?) talents in your profile to find matches',
    NLA_NO_MATCH_CTA: 'Edit skills',
    NLA_NO_MATCH_FIND_CONTENT: 'Find more colleagues by using the search',

    NLA_NO_BOOKMARKS_TITLE: 'No profiles bookmarked yet',
    NLA_NO_BOOKMARKS_SUBTITLE: 'Here you can see your bookmarked colleagues in the field of Never Lunch Alone.',

    NLA_SEARCH_HEADER: 'Search for Never Lunch Alone profiles by keywords',
    NLA_SEARCH_EMPTY_MESSAGE:
        "Unfortunately nothing was found. Tip: Searching without a search term shows all 'never lunch alone' profiles.",
    NLA_SEARCH_BTN_TEXT: 'Search!',
    NLA_SEARCH_NO_RESULT_IMG_ALT:
        'Image shows a placeholder which appears instead of results, because the search did not return any.',

    NLA_ITEM_LOCATION_INDEPENDENT_LABEL: 'Available for online lunch',
    NLA_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL: 'Matching for online lunch',

    PAGE_TITLE_NEVER_LUNCH_ALONE_MATCHES: 'Never lunch alone matches',
    PAGE_TITLE_NEVER_LUNCH_ALONE_BOOKMARKS: 'Never lunch alone bookmarks',
    PAGE_TITLE_NEVER_LUNCH_ALONE_SEARCH: 'Search in never lunch alone',
};
