import { Component, Input, ViewChild } from '@angular/core';
import { PopoverDirective } from '@tploy-enterprise/tenant-common';
import { NlaProfileTileLocationsPopoverComponent } from './nla-profile-tile-locations-popover.component';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';

export interface NLAProfileTileLocationsPopoverData {
    locations: AccountListItemTag[];
}

@Component({
    selector: 'tp-nla-profile-tile-locations',
    templateUrl: './nla-profile-tile-locations.component.html',
})
export class NlaProfileTileLocationsComponent {
    @Input()
    locations: AccountListItemTag[];

    @Input()
    badgeText: string;

    popoverContent = NlaProfileTileLocationsPopoverComponent;
    @ViewChild(PopoverDirective) popover: PopoverDirective;

    get popoverData(): NLAProfileTileLocationsPopoverData {
        return {
            locations: this.locations,
        };
    }
}
