import { Component, Input, OnInit } from '@angular/core';
import { TopicsDataService } from '../../../../topic/topic-data.service';

@Component({
    selector: 'tp-topic-applications-indicator',
    templateUrl: './applicants-indicator.component.html',
})
export class ApplicantsIndicatorComponent implements OnInit {
    @Input() topicName: string;
    applicantsIndicatorView: unknown;
    constructor(private readonly topicsDataService: TopicsDataService) {}

    ngOnInit() {
        this.applicantsIndicatorView = this.topicsDataService.getApplicationsIndicatorView(this.topicName);
    }
}
