import { createAction, props } from '@ngrx/store';
import {
    ProjectApplication,
    ProjectsApplicationIndicators,
    ProjectsEntity,
    ProjectsProfile,
    ProjectsProfileInvitationMessage,
    ProjectsProfileMatch,
    ProjectsProfileSearchResult,
    ProjectsSearchResult,
    ProjectsStaffingFilter,
    ProjectSuggestedSkills,
} from './projects.types';
import { GeneralData, PageParams } from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';

export const ProjectsActions = {
    featureInit: createAction('[Projects] Init'),

    loadProfile: createAction('[Projects] Load'),
    loadProfileSuccess: createAction(
        '[Projects][Success] Load',
        props<{ profile: ProjectsProfile; session: ProjectsProfile }>(),
    ),
    loadProfileError: createAction('[Projects][Error] Load', props<{ error: Error; session: ProjectsProfile }>()),

    editCurrentProfile: createAction(
        '[Projects] Change edit profile',
        props<{ currentProfile: Partial<ProjectsProfile> }>(),
    ),
    cancelCurrentProfileEdit: createAction('[Projects] Cancel edition'),
    markProfileStatusAsDirty: createAction('[Projects] Mark profile status as dirty'),

    saveProfile: createAction('[Projects] Save profile'),
    saveProfileSuccess: createAction('[Projects][Success] Save profile', props<{ currentProfile: ProjectsProfile }>()),
    saveProfileError: createAction(
        '[Projects][Error] Save profile',
        props<{ currentProfile: ProjectsProfile; error: Error }>(),
    ),

    createEntity: createAction('[Projects] Create entity', props<{ entity: ProjectsEntity }>()),
    createEntitySuccess: createAction('[Projects][Success] Create entity', props<{ entity: ProjectsEntity }>()),
    createEntityError: createAction('[Projects][Error] Create entity', props<{ error: Error }>()),

    saveEntity: createAction(
        '[Projects] Save entity',
        props<{ entity: ProjectsEntity; sendNotifications?: boolean }>(),
    ),
    saveEntitySuccess: createAction('[Projects][Success] Save entity', props<{ entity: ProjectsEntity }>()),
    saveEntityError: createAction('[Projects][Error] Save entity', props<{ error: Error }>()),

    deleteEntity: createAction('[Projects] Delete entity', props<{ entityId: string }>()),
    deleteEntitySuccess: createAction('[Projects][Success] Delete entity', props<{ entityId: string }>()),
    deleteEntityError: createAction('[Projects][Error] Delete entity', props<{ error: Error }>()),

    publishEntity: createAction('[Projects] Publish entity', props<{ entityId: string }>()),
    publishEntitySuccess: createAction('[Projects][Success] Publish entity', props<{ entity: ProjectsEntity }>()),
    publishEntityError: createAction('[Projects][Error] Publish entity', props<{ error: Error }>()),

    updateEntity: createAction('[Projects] Update entity', props<{ entity: ProjectsEntity }>()),

    loadSuggestedSkills: createAction('[Projects] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[Projects][Success] Load Suggested Skills',
        props<{ suggestedSkills: ProjectSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[Projects][Error] Load Suggested Skills', props<{ error: Error }>()),

    searchEntities: createAction(
        '[Projects] Search entities',
        props<{ searchQuery: string; pageParams: PageParams; campaigns?: string[] }>(),
    ),
    searchEntitiesSuccess: createAction(
        '[Projects][Success] Search entities',
        props<{ entities: ProjectsSearchResult[]; pageParams: PageParams }>(),
    ),
    searchEntitiesError: createAction('[Projects][Error] Search entities', props<{ error: Error }>()),

    loadEntitiesOfferedByMe: createAction('[Projects] Load entities offered by me'),
    loadEntitiesOfferedByMeSuccess: createAction(
        '[Projects][Success] Load entities offered by me',
        props<{ entities: ProjectsEntity[] }>(),
    ),
    loadEntitiesOfferedByMeError: createAction(
        '[Projects][Error] Load entities offered by me',
        props<{ error: Error }>(),
    ),

    loadMatches: createAction('[Projects] Load Matches', props<{ pageParams: PageParams; campaigns?: string[] }>()),
    loadMatchesSuccess: createAction(
        '[Projects][Success] Load Matches',
        props<{ entities: ProjectsProfileMatch[]; pageParams: PageParams; selectedCampaigns: string[] }>(),
    ),
    loadMatchesError: createAction('[Projects][Error] Load Matches', props<{ error: Error }>()),

    storeSelectedSkills: createAction('[Projects] Store selected skills', props<{ selectedSkills: Array<Skill> }>()),

    applyTo: createAction('[Projects] Apply to entity', props<{ entityId: string; name: string }>()),
    applyToSuccess: createAction(
        '[Projects][Success] Apply to entity',
        props<{
            application: ProjectApplication;
            matches: ProjectsProfileMatch[];
            searchResults: ProjectsSearchResult[];
        }>(),
    ),
    applyToError: createAction('[Projects][Error] Apply to entity', props<{ error: Error }>()),

    syncProfileSkills: createAction('[Projects] Sync profile skills'),

    searchProfiles: createAction('[Projects] Search profiles', props<{ projectId: string; searchTerms: string[] }>()),
    searchProfilesSuccess: createAction(
        '[Projects][Success] Search profiles',
        props<{ profiles: ProjectsProfileSearchResult[] }>(),
    ),
    searchProfilesError: createAction('[Projects][Error] Search profiles', props<{ error: Error }>()),
    resetSearchProfilesResults: createAction('[Projects] Reset search profiles results'),

    sendInvitationMessage: createAction(
        '[Projects] Send invitation message',
        props<{ message: ProjectsProfileInvitationMessage }>(),
    ),
    sendInvitationMessageSuccess: createAction(
        '[Projects][Success] Send invitation message',
        props<{ profileId: string }>(),
    ),
    sendInvitationMessageError: createAction('[Projects][Error] Send invitation message', props<{ error: Error }>()),

    finishProject: createAction('[Projects] Finish Project', props<{ entity: ProjectsEntity }>()),
    finishProjectSuccess: createAction('[Projects][Success] Finish Project', props<{ entity: ProjectsEntity }>()),
    finishProjectError: createAction('[Projects][Error] Finish Project', props<{ error: Error }>()),

    finishOngoingProject: createAction('[Projects] FinishOngoing Project', props<{ entity: ProjectsEntity }>()),
    finishOngoingProjectSuccess: createAction(
        '[Projects][Success] FinishOngoing Project',
        props<{ entity: ProjectsEntity }>(),
    ),
    finishOngoingProjectError: createAction('[Projects][Error] FinishOngoing Project', props<{ error: Error }>()),

    getApplications: createAction('[Projects] Get applications selections', props<{ entityId: string }>()),
    getApplicationsSuccess: createAction(
        '[Projects][Success] Get applications selections',
        props<{ data: Array<ProjectApplication> }>(),
    ),
    getApplicationsSelectionsError: createAction(
        '[Projects][Error] Get applications selections',
        props<{ error: Error }>(),
    ),
    resetApplicationsSelections: createAction('[Projects] Reset applications selections'),
    resetApplicationsSelectionsSuccess: createAction('[Projects][Success] Reset applications selections'),

    getOwnerUnseenApplications: createAction('[Projects] Get owner unseen applications'),
    getOwnerUnseenApplicationsSuccess: createAction(
        '[Projects][Success] Get owner unseen applications',
        props<{ applicationIndicators: ProjectsApplicationIndicators }>(),
    ),
    getOwnerUnseenApplicationsError: createAction(
        '[Projects][Error] Get owner unseen applications',
        props<{ error: Error }>(),
    ),

    topicLinkIndicatorMarkAsSeen: createAction('[Projects] Topic link mark applications indicator as seen'),
    topicLinkIndicatorMarkAsSeenSuccess: createAction(
        '[Projects][Success] Topic link mark applications indicator as seen',
    ),
    topicLinkIndicatorMarkAsSeenError: createAction(
        '[Projects][Error] Topic link mark applications indicator as seen',
        props<{ error: Error }>(),
    ),

    offeringTabIndicatorMarkAsSeen: createAction('[Projects] Offering tab mark applications indicator as seen'),
    offeringTabIndicatorMarkAsSeenSuccess: createAction(
        '[Projects][Success] Offering tab mark applications indicator as seen',
    ),
    offeringTabIndicatorMarkAsSeenError: createAction(
        '[Projects][Error] Offering tab mark applications indicator as seen',
        props<{ error: Error }>(),
    ),

    entityMarkApplicationsAsSeen: createAction(
        '[Projects] Entity mark applications as seen',
        props<{ entityId: string }>(),
    ),
    entityMarkApplicationsAsSeenSuccess: createAction(
        '[Projects][Success] Entity mark applications as seen',
        props<{ entityId: string }>(),
    ),
    entityMarkApplicationsAsSeenError: createAction(
        '[Projects][Error] Entity mark applications as seen',
        props<{ error: Error }>(),
    ),

    loadStaffingEntities: createAction(
        '[Projects] Load staffing entities',
        props<{ searchQuery: string; pageParams: PageParams; filter: ProjectsStaffingFilter }>(),
    ),
    loadStaffingEntitiesSuccess: createAction(
        '[Projects][Success] Load staffing entities',
        props<{ entities: ProjectsSearchResult[]; pageParams: PageParams }>(),
    ),
    loadStaffingEntitiesError: createAction('[Projects][Error] Load staffing entities', props<{ error: Error }>()),
    updateStaffingFilter: createAction(
        '[Projects] Update staffing filter',
        props<{ filter: ProjectsStaffingFilter }>(),
    ),
    getStaffingApplications: createAction('[Projects][Staffing] Get applications', props<{ entity: ProjectsEntity }>()),
    getStaffingApplicationsSuccess: createAction(
        '[Projects][Staffing][Success] Get applications',
        props<{ data: Array<ProjectApplication>; entity: ProjectsEntity }>(),
    ),
    getStaffingApplicationsError: createAction(
        '[Projects][Staffing][Error] Get applications',
        props<{ error: Error }>(),
    ),
    resetStaffingApplications: createAction('[Projects][Staffing] Reset applications'),

    clearSearchTopicUsers: createAction('[Projects][Search] Clear Search Topic Users Results'),
    searchTopicUsers: createAction(
        '[Projects][Search] Search Topic Users',
        props<{ searchQuery: string; entityId: string }>(),
    ),
    searchTopicUsersSuccess: createAction(
        '[Projects][Search][Success] Search Topic Users',
        props<{ results: Partial<GeneralData>[] }>(),
    ),
    searchTopicUsersError: createAction('[Projects][Search][Error] Search Topic Users', props<{ error: Error }>()),
};
