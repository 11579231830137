<ng-template #skillWithLevel let-item>
    <tp-skill-text-view [skill]="item" [withLevel]="true" [skillBold]="false"></tp-skill-text-view>
</ng-template>

<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-list-field
        *ngIf="hasSkills()"
        key="PROJECTS_PROFILE_VIEW_SKILLS"
        [value]="skills"
        [template]="skillWithLevel"
    >
    </tp-profile-view-list-field>
    <tp-profile-view-list-field
        *ngIf="hasCategories()"
        key="PROJECTS_PROFILE_VIEW_INTERESTED_IN_CATEGORIES"
        [value]="interestInCategories"
    >
    </tp-profile-view-list-field>

    <tp-profile-view-string-list-field
        *ngIf="hasCampaigns()"
        key="PROJECTS_PROFILE_VIEW_CAMPAIGNS_LABEL"
        [value]="campaigns"
        [separateByComma]="true"
    >
    </tp-profile-view-string-list-field>

    <tp-profile-view-string-list-field
        *ngIf="hasInterestSkills()"
        key="PROJECTS_PROFILE_VIEW_INTERESTED_IN_SKILLS"
        [value]="interestedInSkills"
    >
    </tp-profile-view-string-list-field>
</tp-profile-view-fieldset>
