import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { TaggerComponent } from '../../tagger';
import { CampaignsPopoverComponent, CampaignsPopoverData } from '../campaigns-popover/campaigns-popover.component';
import { PopoverDirective } from '../../popover';

@Component({
    selector: 'tp-campaigns-tagger',
    templateUrl: './campaigns-tagger.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignsTaggerComponent extends TaggerComponent implements OnInit {
    @ViewChild(PopoverDirective) popover: PopoverDirective;
    popoverContent = CampaignsPopoverComponent;

    extraTags: Array<{ name: string; matched: boolean }> = [];
    viewLimit = 2;

    get badgeText(): string {
        return `+${this.extraTags.length}`;
    }

    get showBadge(): boolean {
        return this.extraTags.length > 0;
    }

    get popoverData(): CampaignsPopoverData {
        return {
            campaigns: this.extraTags,
        };
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.tags
            .slice(this.viewLimit, this.tags.length)
            .map((tag) => this.extraTags.push({ name: tag, matched: this.isSelected(tag) }));
        this.tagsNames = this.tags.slice(0, this.viewLimit);
    }
}
