import { BlogActions } from './blog.actions';
import { createReducer, on } from '@ngrx/store';
import { BlogArticle, BlogPreview } from './blog.types';

export interface BlogState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    articles: BlogArticle[];
    tags: { [key: string]: BlogTagState };
    map: { [blogId: number]: BlogArticle };
    navigation: BlogNavigationState;
}

export interface BlogTagState {
    blogs: Array<BlogArticle>;
    count: number;
    tag: string;
}

export interface BlogNavigationState {
    [blogId: number]: { next: BlogPreview; previous: BlogPreview };
}

export const initialBlogState: BlogState = {
    loaded: false,
    loading: false,
    loadingError: null,
    articles: [],
    map: {},
    tags: {},
    navigation: {},
};

export const blogReducer = createReducer(
    initialBlogState,
    on(BlogActions.loadArticles, (state) => {
        return { ...state, loading: true };
    }),
    on(BlogActions.loadArticlesSuccess, (state, { articles }) => {
        return blogArticlesMutator(state, articles);
    }),
    on(BlogActions.loadArticleError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);

export function blogArticlesMutator(state: any, blogs: Array<BlogArticle>): BlogState {
    const blogArticles: Array<BlogArticle> = [];
    const tags: { [key: string]: BlogTagState } = {};
    const navigation: BlogNavigationState = {};

    const refMap: {
        [key: string]: { firstBlog: BlogPreview; firstNavObject: any; lastBlog: BlogPreview; lastNavObject: any };
    } = {};

    blogs.forEach((blog) => {
        const navObject = {
            next: null,
            previous: null,
        };

        blogArticles.push(blog);
        navigation[blog.id] = navObject;

        if (blog.tag) {
            const tag = blog.tag;

            if (!tags[tag]) {
                tags[tag] = {
                    blogs: [],
                    count: 0,
                    tag: tag,
                };
            }

            tags[tag].blogs.push(blog);
            tags[tag].count++;

            if (!refMap[tag]) {
                refMap[tag] = {
                    firstBlog: getBlogPreview(blog),
                    firstNavObject: navObject,
                    lastBlog: getBlogPreview(blog),
                    lastNavObject: navObject,
                };
            } else {
                // We use previously stored information before we override it
                navObject.previous = refMap[tag].lastBlog; // current blog get a previous blog
                refMap[tag].lastNavObject.next = getBlogPreview(blog); // previous blog get a next blog

                refMap[tag] = { ...refMap[tag], lastBlog: blog, lastNavObject: navObject };
            }
        }
    });

    for (const tagName in tags) {
        if (Object.prototype.hasOwnProperty.call(tags, tagName)) {
            const tag = tags[tagName];

            if (tag.count >= 2) {
                const tagNavData = refMap[tagName];

                tagNavData.firstNavObject.previous = tagNavData.lastBlog;
                tagNavData.lastNavObject.next = tagNavData.firstBlog;
            }
        }
    }

    return {
        loading: false,
        loaded: true,
        loadingError: null,
        articles: blogArticles,
        map: blogArticles.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {}),
        tags,
        navigation,
    };
}

function getBlogPreview(blog: BlogArticle): BlogPreview {
    const { id, title, summary, imageUrl } = blog; // deconstruct blog into 3 variables
    return { id, title, summary, imageUrl }; // use those variables as a subset of the blog
}
