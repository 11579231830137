import { NgModule, Injector } from '@angular/core';
import {
    TopicRegistryService,
    TopicDefinition,
    StaticTopicDefinition,
    AuthenticatedGuard,
    AuthorizationModule,
    MENTORING_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MentoringEffects } from './mentoring.effects';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { MentoringProfileViewModule } from './mentoring-embeddable/mentoring-profile-view/mentoring-profile-view.module';
import { MentoringReducer } from './mentoring.reducer';
import { MentoringTopicEventHandlerService } from './mentoring-topic-event-handler.service';
import { MentoringProfileViewComponent } from './mentoring-embeddable/mentoring-profile-view/mentoring-profile-view.component';
import { RouterModule, Routes } from '@angular/router';
import { MentoringUserCardViewComponent } from './mentoring-embeddable/mentoring-user-card-view/mentoring-user-card-view.component';
import { MentoringUserCardViewModule } from './mentoring-embeddable/mentoring-user-card-view/mentoring-user-card-view.module';
import { MentoringProfileNavigatorViewComponent } from './mentoring-embeddable/mentoring-profile-navigator-view/mentoring-profile-navigator-view.component';
import { MentoringProfileNavigatorViewModule } from './mentoring-embeddable/mentoring-profile-navigator-view/mentoring-profile-navigator-view.module';
import { UserRole } from '@tandemploy/common';

const lazyRoutes: Routes = [
    {
        path: 'mentoring',
        loadChildren: () => import('./mentoring-domain/mentoring-domain.module').then((m) => m.MentoringDomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/mentoring',
        loadChildren: () =>
            import('./mentoring-profile/mentoring-profile-sign-up.module').then((m) => m.MentoringProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/mentoring',
        loadChildren: () =>
            import('./mentoring-profile/mentoring-profile-add.module').then((m) => m.MentoringProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/mentoring',
        loadChildren: () =>
            import('./mentoring-profile/mentoring-profile-edit.module').then((m) => m.MentoringProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
];

const MentoringTopicDefinition: StaticTopicDefinition = {
    name: MENTORING_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: MentoringProfileViewComponent,
            permission: 'mentoring.default',
        },
        userCardView: {
            component: MentoringUserCardViewComponent,
        },
        profileNavigatorView: {
            component: MentoringProfileNavigatorViewComponent,
        },
        sidenavItem: {
            link: ['/mentoring'],
            editPermission: 'mentoring.default',
            editLink: ['/', { outlets: { overlay: ['edit', 'mentoring'] } }],
            label: 'MENTORING',
            svgIcon: 'mentoring',
            referenceContext: 'mentoring',
        },
        modelInformation: {
            svgIcon: 'mentoring',
            image: '/assets/images/topics/mentoring.jpg',
            label: 'MENTORING',
            content: 'MENTORING_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['sign-up', 'mentoring'],
        signUpSequence: [
            ['/', 'sign-up', 'mentoring', 'motivation'],
            ['/', 'sign-up', 'mentoring', 'interest'],
            ['/', 'sign-up', 'mentoring', 'offering'],
        ],
        edition: ['/', { outlets: { overlay: ['edit', 'mentoring'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'mentoring'] } }],
        addSequence: [
            [{ outlets: { overlay: ['add-topic', 'mentoring', 'motivation'] } }],
            [{ outlets: { overlay: ['add-topic', 'mentoring', 'interest'] } }],
            [{ outlets: { overlay: ['add-topic', 'mentoring', 'offering'] } }],
        ],
        domain: ['mentoring'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['mentoring.default'],
        [UserRole.USER]: ['mentoring.default'],
        [UserRole.CONTRIBUTOR]: ['mentoring.default'],
        [UserRole.ADMIN]: ['mentoring.default'],
        [UserRole.ADMIN_MAIL]: ['mentoring.default'],
        [UserRole.STATISTICS]: ['mentoring.default'],
        [UserRole.STAFFING_MANAGER]: ['mentoring.default'],
    },
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        MentoringProfileViewModule,
        MentoringUserCardViewModule,
        MentoringProfileNavigatorViewModule,
        StoreModule.forFeature('mentoring', MentoringReducer),
        EffectsModule.forFeature([MentoringEffects]),
        AuthorizationModule.forFeature({ permissions: MentoringTopicDefinition.rolePermissions }),
    ],
    declarations: [],
})
export class MentoringFeatureModule {
    constructor(private readonly injector: Injector) {
        this.registerMentoringTopic();
    }

    private registerMentoringTopic(): void {
        const eventHandlers = this.injector.get(MentoringTopicEventHandlerService);
        const definition: TopicDefinition = {
            ...MentoringTopicDefinition,
            eventHandlers,
        };
        const topicRegistry = this.injector.get(TopicRegistryService);
        topicRegistry.registerTopic(definition);
    }
}
