// Bases

export class BlogProxyUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError ? originalError.message : 'Something unexpected has happened.');
    }
}

// Article related exceptions
export class BlogArticleNotFoundException extends Error {
    constructor() {
        super('The requested blog article was not found.');
    }
}

export class BlogArticlesException extends Error {
    constructor() {
        super('An unexpected error has occured while loading all blog articles.');
    }
}

export class BlogArticlesLoadNotFoundException extends Error {
    constructor() {
        super(`While loading articles not found error returned.`);
        this.name = 'BlogArticlesLoadNotFoundException';
    }
}

export class BlogArticlesLoadForbiddenException extends Error {
    constructor() {
        super('While loading articles not allowed error returned.');
        this.name = 'BlogArticlesLoadForbiddenException';
    }
}

export class BlogArticlesLoadBadRequestException extends Error {
    constructor() {
        super('Bad request. Check request values');
        this.name = 'BlogArticlesLoadBadRequestException';
    }
}

export class BlogArticlesLoadUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError?.message ?? 'An unexpected error has occured while loading all blog articles.');
        this.name = 'BlogArticlesLoadUnexpectedException';
    }
}
