import { createAction, props } from '@ngrx/store';

export const AuthenticationActions = {
    authenticate: createAction('[Authentication] Authenticate'),
    signOut: createAction('[Authentication] Sign Out'),
    forceLogin: createAction('[Authentication] Force Login', props<{ returnUrl: string }>()),
    signOutSuccess: createAction('[Authentication][Success] Sign Out'),
    signOutError: createAction('[Authentication][Error] Sign Out'),
    verify: createAction('[Authentication] Verify'),
    verified: createAction('[Authentication] Verified', props<{ authenticated: boolean }>()),
};
