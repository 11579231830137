import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobXProfileViewComponent } from './job-x-profile-view.component';
import { ProfileContentModule, LanguageModule, SkillModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [JobXProfileViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule, SkillModule],
})
export class JobXProfileViewModule {}
