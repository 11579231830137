import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatableModule } from '../language/translatable/translatable.module';
import { VerticalAlignmentHelperDirective } from './vertical-alignment-helper.directive';

@NgModule({
    declarations: [VerticalAlignmentHelperDirective],
    exports: [VerticalAlignmentHelperDirective],
    imports: [CommonModule, TranslatableModule],
})
export class VerticalAlignmentHelperModule {}
