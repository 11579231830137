import { NLAState } from './nla.reducer';
import { NLABookmark, NLAMatch, NLAProfile, NLASearchResult, NLASuggestedSkills } from './nla-service/nla.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export function selectCurrentNLAProfile(state: { neverLunchAlone: NLAState }): NLAProfile {
    return state.neverLunchAlone.profile.current;
}

export function selectSavedNLAProfile(state: { neverLunchAlone: NLAState }): NLAProfile {
    return state.neverLunchAlone.profile.saved;
}

export function isNLAProfileLoadedOrLoading(state: { neverLunchAlone: NLAState }): boolean {
    const status = state.neverLunchAlone.profile.status;
    return status.loaded || status.loading;
}

export function isNLAProfileLoaded(state: { neverLunchAlone: NLAState }): boolean {
    return state.neverLunchAlone.profile.status.loaded;
}

export function isNLAProfileInDirtyState(state: { neverLunchAlone: NLAState }): boolean {
    return state.neverLunchAlone.profile.status.dirty;
}

export function hasNLAProfile(state: { neverLunchAlone: NLAState }): boolean {
    const profileState = state.neverLunchAlone.profile;
    return (
        profileState.status.loaded &&
        !profileState.status.loadingError &&
        (profileState.current?.companyLocations?.length > 0 || profileState.current.locationIndependent)
    );
}

export function selectSearchResults(state: { neverLunchAlone: NLAState }): NLASearchResult[] {
    return state.neverLunchAlone.searchResults.results;
}

export function selectAllSearchResults(state: { neverLunchAlone: NLAState }): NLASearchResult[] {
    return state.neverLunchAlone.searchResults.allResults;
}

export function selectSearchPageParams(state: { neverLunchAlone: NLAState }): PageParams {
    return state.neverLunchAlone.searchResults.pageParams;
}

export function selectMatches(state: { neverLunchAlone: NLAState }): NLAMatch[] {
    return state.neverLunchAlone.matches.results;
}

export function selectAllMatches(state: { neverLunchAlone: NLAState }): NLAMatch[] {
    return state.neverLunchAlone.matches.allResults;
}

export function selectMatchesPageParams(state: { neverLunchAlone: NLAState }): PageParams {
    return state.neverLunchAlone.matches.pageParams;
}

export function selectMatchesTotal(state: { neverLunchAlone: NLAState }): number {
    return state.neverLunchAlone.matches.length;
}

export function selectBookmarks(state: { neverLunchAlone: NLAState }): NLABookmark[] {
    return state.neverLunchAlone.bookmarks.data;
}

export function isSearchResultsEmpty(state: { neverLunchAlone: NLAState }): boolean {
    const searchState = state.neverLunchAlone.searchResults;
    return searchState.loaded && searchState.results.length === 0;
}

export function isSearchLoading(state: { neverLunchAlone: NLAState }): boolean {
    return state.neverLunchAlone.searchResults.loading;
}

export function suggestedSkills(state: { neverLunchAlone: NLAState }): NLASuggestedSkills {
    return state.neverLunchAlone.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { neverLunchAlone: NLAState }): string[] {
    return state.neverLunchAlone.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { neverLunchAlone: NLAState }): string[] {
    return state.neverLunchAlone.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { neverLunchAlone: NLAState }): string[] {
    return state.neverLunchAlone.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}
