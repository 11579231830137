import { Injectable } from '@angular/core';
import { GeneralDataSerializer } from '../general-data/general-data.serializer';
import { AccountSsoDataDTO } from './sso-data.types';
import { Account } from '../account.types';

@Injectable({
    providedIn: 'root',
})
export class SsoDataSerializer {
    constructor(private generalDataSerializer: GeneralDataSerializer) {}

    deserialize(dto: AccountSsoDataDTO): Partial<Account> {
        return {
            userId: dto.userId,
            roles: dto.roles,
            locale: dto.locale,
            generalData: this.generalDataSerializer.deserialize(dto.generalData),
        };
    }
}
