import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-profile-tile-locations-header',
    templateUrl: './nla-profile-tile-locations-header.component.html',
    host: {
        class: 'nla-profile-tile__locations-header',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NlaProfileTileLocationsHeaderComponent implements OnInit {
    @Input() locations: Array<string>;
    @Input() isLocationIndependent: boolean;
    @Input() highlightLocationIndependent: boolean;

    public visibleLocations: Array<AccountListItemTag> = [];

    constructor() {}

    ngOnInit(): void {
        this.visibleLocations =
            this.locations?.length > 0
                ? [
                      {
                          name: this.locations[0],
                          matched: false,
                      },
                  ]
                : [];
    }
}
