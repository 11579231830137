import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Skill } from '../skill.types';
import { SkillService } from '../skill.service';

const SIGN_WIDTH = 5.5;
const COLUMN_WIDTH = 145;

@Component({
    selector: 'tp-skill-text-view',
    templateUrl: './skill-text-view.component.html',
    styleUrls: ['./skill-text-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillTextViewComponent {
    @Input()
    skill: Skill;

    @Input() withLevel = false;
    @Input() showComma = false;
    @Input() dotMode = false;
    @Input() showTooltip = false;
    @Input() skillBold = true;

    constructor(private readonly skillService: SkillService, private readonly translateService: TranslateService) {}

    getLevelTranslation(level: number): string {
        return this.skillService.getLevelLabel(level);
    }

    getTooltip(skill: Skill): string {
        const result =
            skill.name +
            (this.withLevel ? ` (${this.translateService.instant(this.getLevelTranslation(skill.level))})` : '');
        return this.showTooltip && result.length * SIGN_WIDTH > COLUMN_WIDTH ? result : '';
    }
}
