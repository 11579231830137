import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-content',
    templateUrl: './card-content.component.html',
    host: {
        class: 'card__content',
    },
})
export class CardContentComponent {}
