abstract class ProjectsBadRequestException extends Error {
    constructor(message: string, apiError?: Error) {
        let extra: string;
        const additionalInformation = apiError ? apiError.message || null : null;

        if (additionalInformation) {
            extra = ` Here is what the service has returned: ${additionalInformation}.`;
        } else {
            extra = ``;
        }

        super(`${message}${extra}`);
    }
}

export class ProjectsUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError ? originalError.message : 'Something unexpected has happened.');
    }
}

// Profile related exceptions
export class ProjectsProfileBadRequestException extends ProjectsBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the profile. Please check the data.', apiError);
    }
}

export class ProjectsMyProfileNotFoundException extends Error {
    constructor() {
        super(`The Projects profile for the currently logged-in user was not found.`);
    }
}

export class ProjectsOthersProfileNotFoundException extends Error {
    constructor() {
        super(`The requested Projects profile was not found.`);
    }
}

// Entity related exceptions
export class ProjectsEntityBadRequestException extends ProjectsBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the Projects entity. Please check the data.', apiError);
    }
}

export class ProjectsEntityNotFoundException extends Error {
    constructor() {
        super('The requested Projects entity was not found.');
    }
}

export class ProjectsEntityForbiddenException extends Error {
    constructor() {
        super('A user with no organization or administration permission has tried modifying a Projects entity.');
    }
}

// Domain related exceptions

export class ProjectsDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for Projects entities.');
    }
}

export class ProjectsDomainIndexException extends Error {
    constructor() {
        super('An unexpected error has occured while loading Projects entities.');
    }
}

export class ProjectsDomainMatchingException extends Error {
    constructor() {
        super('An unexpected error has occured while querying matches.');
    }
}

// Entity applications related exceptions

export class ProjectsEntityApplicationNotFoundException extends Error {
    constructor() {
        super(`Either the Projects entity or the application were not found.`);
    }
}

export class ProjectsEntityApplicationForbiddenException extends Error {
    constructor() {
        super(
            "The user was not allowed to perform this action. Either he has tried to apply to a Projects entity while he doesn't have the permission to, or he has tried modifying the status of an application and is neither the organizer or an administrator",
        );
    }
}

export class ProjectsEntityApplicationBadRequestException extends Error {
    constructor() {
        super(
            'Either the user as applied to an entity that he is alredy applied to, or he has tried to apply to a Projects entity that has not yet been published.',
        );
    }
}
