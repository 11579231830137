import { Component, ChangeDetectionStrategy, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationsData, CustomSnack } from './notifications.types';

@Component({
    selector: 'tp-notification-important-snack',
    templateUrl: './important-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'snack',
    },
})
export class ImportantSnackComponent implements CustomSnack {
    @Output() close = new EventEmitter<void>();

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationsData) {}
}
