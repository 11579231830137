<button
    class="btn-more"
    mat-mini-fab
    color="accent"
    aria-label="Has more locations"
    tpPopover
    tpPopoverHover
    [tpPopoverHasBackdrop]="false"
    [tpVerticalTarget]="'above'"
    [tpPopoverContent]="popoverContent"
    [tpPopoverData]="popoverData"
    [tpHorizontalConnectionPosition]="'start'"
    [fallbackHorizontalOffset]="25"
>
    {{ badgeText }}
</button>
