import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteTransitionLoaderComponent } from './components/route-transition-loader.component';
import { PendingRequestsInterceptor } from './services/pending-requests-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    declarations: [RouteTransitionLoaderComponent],
    imports: [CommonModule],
    exports: [RouteTransitionLoaderComponent],
})
export class RouteTransitionLoaderModule {
    public static forRoot(): ModuleWithProviders<RouteTransitionLoaderModule> {
        return {
            ngModule: RouteTransitionLoaderModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useExisting: PendingRequestsInterceptor,
                    multi: true,
                },
            ],
        };
    }
}
