import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NLASearchResult } from '../../nla-service/nla.types';
import { Store } from '@ngrx/store';
import { NLAState } from '../../nla.reducer';
import { NLAActions } from '../../nla.actions';
import { PageEvent } from '@angular/material/paginator';
import { PageParams, QUERY_DELIMITER, QueryParamKey, UrlUtilService, WindowRef } from '@tploy-enterprise/tenant-core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { SuggestSkillService } from '@tploy-enterprise/tenant-common';
import { isSearchLoading } from '../../nla.selectors';

@Component({
    selector: 'tp-nla-search-results-view',
    templateUrl: './nla-search-results-view.component.html',
    styleUrls: ['./nla-search-results-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLASearchResultsViewComponent implements OnInit, OnChanges {
    @Input() results: NLASearchResult[];
    @Input() resultPageParams: PageParams;
    @Input() isResultsEmpty: boolean;

    isSearchLoading$: Observable<boolean>;
    isAutocompleteLoadingSubscribe$: Subscription;
    isAutocompleteLoading: boolean;
    buttonClicked: boolean;

    form: FormGroup;
    hasResults = false;

    private readonly onDestroy$ = new Subject<void>();

    get searchTagger(): FormControl {
        return this.form.get('searchTagger') as FormControl;
    }

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly store: Store<{ neverLunchAlone: NLAState }>,
        private readonly router: Router,
        private readonly urlUtilService: UrlUtilService,
        private suggestSkillService: SuggestSkillService,
    ) {}

    ngOnInit() {
        this.createForm();
        this.isSearchLoading$ = this.store.select(isSearchLoading);
        this.dispatchSearchAction();
        this.isAutocompleteLoadingSubscribe$ = this.suggestSkillService.loading$.subscribe((loading) => {
            this.isAutocompleteLoading = loading;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.results) {
            this.hasResults = !!this.results && this.results.length > 0;
            if (this.hasResults) {
                this.buttonClicked = false;
            }
        }
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            searchTagger: [this.urlUtilService.getSearchQueryParam()],
        });
        this.searchTagger.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value) =>
                this.urlUtilService.updateQueryParams({ [QueryParamKey.Q]: value.join(QUERY_DELIMITER) }),
            );
    }

    triggerSearch(event?: MouseEvent): void {
        if (event && (event.ctrlKey || event.metaKey)) {
            WindowRef.open(this.router.url, '_blank');
        } else {
            this.urlUtilService.resetPageParams().then(() => this.dispatchSearchAction());
        }
    }

    buttonTriggerSearch(event?: MouseEvent): void {
        this.buttonClicked = true;
        this.triggerSearch(event);
    }

    private dispatchSearchAction(): void {
        this.store.dispatch(
            NLAActions.search({
                searchQuery: this.searchTagger.value.join('+'),
                pageParams: this.urlUtilService.getPageParams(),
            }),
        );
    }

    onPageChange(pageEvent: PageEvent): void {
        this.urlUtilService
            .updateQueryParams({
                [QueryParamKey.PAGE_INDEX]: pageEvent.pageIndex,
                [QueryParamKey.PAGE_SIZE]: pageEvent.pageSize,
            })
            .then(() => this.dispatchSearchAction());
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
