import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessFactorsSkills } from '../success-factors.types';

@Component({
    selector: 'tp-success-factors-skills-overlay-dialog',
    templateUrl: './success-factors-skills-overlay.component.html',
    styleUrls: ['./success-factors-skills-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessFactorsSkillsOverlayComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessFactorsSkills) {}

    getLevel(level: number): string {
        return `SKILL_LEVEL_${level}`;
    }
}
