import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'tp-preview-item',
    template: `
        <img
            [class.visible]="isVisible"
            [class.selected]="isSelected"
            [alt]="title"
            [src]="securedSrc"
            aria-hidden="true"
        />
    `,
    styleUrls: ['./preview-item.component.scss'],
})
export class PreviewItemComponent implements OnChanges {
    @Input() src: string;
    @Input() preview: string;

    @Input()
    title: string;

    @Input() subtitles: string;

    @Output()
    select: EventEmitter<PreviewItemComponent> = new EventEmitter<PreviewItemComponent>();

    @HostBinding('class.visible')
    get isVisible(): boolean {
        return this._isVisible;
    }

    set isVisible(value: boolean) {
        this._isVisible = value;
        this.ariaHidden = !value;
        this.tabindex = value ? '0' : null;
    }

    private _isVisible = true;

    @HostBinding('attr.aria-hidden')
    ariaHidden = false;

    @HostBinding('attr.tabindex')
    tabindex: string = null;

    @HostBinding('attr.role')
    role = 'button';

    securedSrc: SafeResourceUrl;

    public isSelected = false;

    constructor(private domSanitizer: DomSanitizer) {}

    @HostListener('click')
    @HostListener('keydown.enter')
    onSelect(): void {
        this.select.emit(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.preview.currentValue !== changes.preview.previousValue) {
            this.securedSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(changes.preview.currentValue);
        }
    }
}
