import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { LanguageModule } from '../language';
import { SkillsListComponent } from './skills-list/skills-list.component';
import { PersonalSkillsInputComponent } from './personal-skills-input/personal-skills-input.component';
import { SkillTextViewComponent } from './skill-text-view/skill-text-view.component';
import { SkillsListTableComponent } from './skills-list-table/skills-list-table.component';
import { SkillsListTextComponent } from './skills-list-text/skills-list-text.component';
import { SkillsListLevelsComponent } from './skills-list-levels/skills-list-levels.component';
import { SkillsCheckedListComponent } from './skills-checked-list/skills-checked-list.component';
import { ShowMoreSkillsDropdownComponent } from './show-more-skills-dropdown/show-more-skills-dropdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { SingleSkillTaggerInputComponent } from './single-skill-tagger-input/single-skill-tagger-input.component';
import { EditSkillListTableComponent } from './edit-skill-list-table/edit-skill-list-table.component';
import { EditSkillButtonComponent } from './edit-skill-list-table/edit-skill-button/edit-skill-button.component';
import { EditSkillButtonActionsMenuComponent } from './edit-skill-list-table/edit-skill-button/edit-skill-button-actions-menu/edit-skill-button-actions-menu.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { PopoverModule } from '../popover';
import { SuggestModule } from '../suggest/suggest.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TagsSuggestionModule } from '../simple-tagger/tags-suggestion/tags-suggestion.module';
import { SkillsSuggestionComponent } from './skills-suggestion/skills-suggestion.component';
import { SkillsSuggestionDirective } from './skills-suggestion/skills-suggestion.directive';
import { RequiredSkillsInputReducedComponent } from './required-skills-input/required-skills-input-reduced.component';
import { RequiredSkillsInputComponent } from './required-skills-input/required-skills-input.component';
import { InterestedTopicsCheckListComponent } from './interested-topics-check-list/interested-topics-check-list.component';
import { SkillsCheckBoxListTableComponent } from './skills-checkbox-list-table/skills-checkbox-list-table.component';
import { SkillTextViewWithLevelTooltipComponent } from './skill-text-view-with-level-tooltip/skill-text-view-with-level-tooltip.component';
import { InfoToggleModule } from '../info-toggle';
import { ButtonModule, ChipsModule, SelectModule, SelectedOptionModule } from '@tandemploy/ngx-components';

@NgModule({
    declarations: [
        SkillsListComponent,
        SkillsSuggestionComponent,
        SkillsSuggestionDirective,
        PersonalSkillsInputComponent,
        RequiredSkillsInputComponent,
        SkillsCheckBoxListTableComponent,
        InterestedTopicsCheckListComponent,
        RequiredSkillsInputReducedComponent,
        SkillTextViewComponent,
        SkillsListTableComponent,
        SkillsListTextComponent,
        SkillsListLevelsComponent,
        SkillsCheckedListComponent,
        ShowMoreSkillsDropdownComponent,
        SkillTextViewWithLevelTooltipComponent,
        SingleSkillTaggerInputComponent,
        EditSkillListTableComponent,
        EditSkillButtonComponent,
        EditSkillButtonActionsMenuComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        SelectedOptionModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatOptionModule,
        MatButtonModule,
        MatIconModule,
        LanguageModule,
        MatTableModule,
        MatTooltipModule,
        MatChipsModule,
        RouterModule,
        MatMenuModule,
        MatListModule,
        PopoverModule,
        MatTableModule,
        SuggestModule,
        MatAutocompleteModule,
        TagsSuggestionModule,
        InfoToggleModule,
        ChipsModule,
        SelectModule,
    ],
    exports: [
        SkillsListComponent,
        ShowMoreSkillsDropdownComponent,
        RequiredSkillsInputComponent,
        SkillsCheckBoxListTableComponent,
        InterestedTopicsCheckListComponent,
        RequiredSkillsInputReducedComponent,
        PersonalSkillsInputComponent,
        SkillTextViewComponent,
        EditSkillListTableComponent,
        SkillsSuggestionDirective,
    ],
})
export class SkillModule {}
