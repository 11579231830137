export default {
    SHORT_TIME_ASSIGNMENT: 'Kurzeinsätze',
    SHORT_TIME_ASSIGNMENT_SINGULAR: 'Kurzeinsatz',
    SHORT_TIME_ASSIGNMENT_INFO:
        'Sie suchen – unter Umständen auch recht kurzfristig – die passenden Personen oder Teams für einen Kurzeinsatz? Beides ist mit dem Matching für Kurzeinsätze unkompliziert möglich. Ideal geeignet ist das Matching zum Beispiel für das Staffing kurzfristiger und zeitlich begrenzter Bedarfe, für Auslandseinsätze, aber auch für Hospitationsmöglichkeiten. Wer sich für die Teilnahme an Kurzeinsätzen interessiert oder gar gezielt nach einer Abwechslung sucht und bestimmte Fähigkeiten stärker einbringen möchte, sollte unbedingt „Kurzeinsätze“ als sein Interesse auswählen und hierzu auf dem Laufenden bleiben!',
    STA_ENTITY_CREATE: 'Neuer Kurzeinsatz',
    STA_PROFILE_TITLE: 'Kurzeinsätze',
    STA_PROFILE_SUBTITLE: 'Ihre Angaben werden dabei helfen, künftig passende Kurzeinsätze für Sie zu finden.',
    STA_PROFILE_MOTIVATION_SUBTITLE:
        'Spannende Kurzeinsätze entdecken und/oder eigene Short Assignments veröffentlichen und passende Kolleg*innen finden.',
    STA_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Selbst Kurzeinsatz erstellen und Matches dafür finden.',
    STA_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Passende Kurzeinsätze finden und daran teilnehmen.',
    STA_MOTIVATION_VALIDATION_REQUIRED: 'Es muss mindestens eine Option ausgewählt werden.',
    STA_PROFILE_SKILLS_LABEL: 'Kenntnisse und Fähigkeiten, die Sie bei Kurzeinsätzen einbringen können',
    STA_PROFILE_CATEGORIES_INTERESTED_IN_LABEL: 'Bereiche, die Sie besonders interessieren',
    STA_PROFILE_SKILLS_INTERESTED_IN_LABEL:
        'Kenntnisse, die Sie im Rahmen von Kurzeinsätzen gern erwerben/vertiefen möchten',

    STA_MATCHING_HEADER: 'Matches und Bewerbung',
    STA_MATCHING_DETAILS_1:
        'Unten sehen Sie alle Angebote, die basierend auf Ihren Profilangaben zu Ihnen passen könnten. Um sich für {{topicName}} zu bewerben, müssen Sie alle erforderlichen Fähigkeiten (',
    STA_MATCHING_DETAILS_2:
        ') in Ihrem Profil angegeben haben. Diese können Sie allerdings auch noch während des Bewerbungsprozesses hinzufügen. Wenn bestimmte Fähigkeiten als “hilfreich” (',
    STA_MATCHING_DETAILS_3:
        ') markiert wurden, sind diese zwar wünschenswert, aber nicht zwingend notwendig für die Bewerbung. \n' +
        'Ihre Chancen auf die Teilnahme steigen, je mehr der gefragten Fähigkeiten Sie mitbringen.',
    STA_ENTITY_LABEL_REQUIRED_SKILLS: 'Erforderliche Fähigkeiten',
    STA_ENTITY_LABEL_HELPFUL_SKILLS: 'Hilfreiche Fähigkeiten',

    // ALIAS OF STA_PROFILE_SKILLS_LABEL
    STA_PROFILE_VIEW_SKILLS: 'Kenntnisse, die ich einbringen kann',
    STA_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Was ich lernen möchte',
    STA_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Interessenfelder',
    STA_PROFILE_VIEW_CAMPAIGNS_LABEL: 'HR Programme',

    STA_ENTITY_EDIT_TITLE: 'Kurzeinsatz bearbeiten',
    STA_ENTITY_EDIT_BUTTON_DRAFT: 'Entwurf speichern',
    STA_ENTITY_EDIT_BUTTON_PUBLISH: 'Veröffentlichen',
    STA_ENTITY_EDIT_BUTTON_SAVE: 'Speichern',
    STA_ENTITY_CREATE_TITLE: 'Kurzeinsatz erstellen',
    STA_ENTITY_CREATE_BUTTON_DRAFT: 'Entwurf speichern',
    STA_ENTITY_CREATE_BUTTON_PUBLISH: 'Veröffentlichen',
    STA_ENTITY_FORM_TITLE_LABEL: 'Titel des Kurzeinsatzes',
    STA_ENTITY_FORM_START_DATE_LABEL: 'Startdatum',
    STA_ENTITY_FORM_END_DATE_LABEL: 'Enddatum',
    STA_ENTITY_FORM_APPLICATION_DEADLINE_LABEL: 'Bewerbungsschluss',
    STA_ENTITY_FORM_APPLICATION_DEADLINE_VALIDATION_MIN_MAX_ERROR:
        'Bitte wählen Sie einen Bewerbungsschluss vor dem Start aus.',
    STA_ENTITY_FORM_VENUE_LABEL: 'Ort',
    STA_ENTITY_FORM_LANGUAGES_LABEL: 'Sprache (von allen Bewerber/innen erwartet)',
    STA_ENTITY_FORM_SUBSIDIARY_LABEL: 'Ausschreibende (Tochter-)Gesellschaft',
    STA_ENTITY_FORM_DEPARTMENT_LABEL: 'Abteilung',
    STA_ENTITY_FORM_TARGET_GROUPS_LABEL: 'Zielgruppe eingrenzen',
    STA_ENTITY_FORM_CATEGORY_LABEL: 'Thematische Einordnung',
    STA_ENTITY_FORM_DESCRIPTION_LABEL: 'Beschreibung',
    STA_ENTITY_FORM_DESCRPTION_ERROR: 'Die Beschreibung muss mindestens 50 Zeichen umfassen.',
    STA_ENTITY_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unbegrenzt (alle werden erreicht)',

    STA_FORM_READY_STATUS: 'Ja! Sie können sich bewerben!',
    STA_FORM_ALMOST_READY_STATUS: 'Fast bereit zur Bewerbung!',
    STA_FORM_NOT_READY_STATUS: 'Noch nicht bereit zur Bewerbung!',
    STA_FORM_HEADLINE_LANGUAGE: 'Benötigte Sprache',
    STA_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Fügen Sie die gewünschte Sprache hinzu',
    STA_FORM_HEADLINE_TARGET_GROUPS: 'Offen für folgende Gruppen',
    STA_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Fügen Sie eine Gruppe hinzu, wenn Sie ihr angehören',
    STA_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Wählen Sie eine Funktion',
    STA_FORM_HEADLINE_STA_SKILLS: 'Wählen Sie alle der folgenden Fähigkeiten aus, um teilzunehmen',
    STA_FORM_REQUIRED_TO_ADD_STA_SKILLS: 'Wählen Sie mindestens eine Fähigkeit',
    STA_FORM_SKILL_TABLE_COL_CHECKED: 'Fähigkeit vorhanden?',
    STA_FORM_SKILL_TABLE_COL_SKILL_NAME: 'Fähigkeit',
    STA_FORM_SKILL_TABLE_COL_SKILL_INFORMATION: 'Information',
    STA_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Fähigkeit auswählen, wenn Sie diese mitbringen ',

    STA_ENTITY_FORM_SKILLS_HEADER: 'Gesuchte Fähigkeiten',
    STA_ENTITY_FORM_SKILLS_DESCRIPTION:
        'Bitte beachten Sie: Wenn sie viele Fähigkeiten als “erforderlich” markieren, verringert sich unter Umständen die Anzahl der Bewerber*innen für Ihr Angebot. Bewerber*innen müssen alle “erforderlichen” Fähigkeiten in ihrem Profil angegeben haben, um sich bewerben zu können. Wenn Sie andererseits keine einzige “erforderliche” Fähigkeit angeben, könnten Sie viele Bewerbungen erhalten, die Sie als nicht passend empfinden. \n' +
        '\n' +
        'Bitte klicken Sie auf das Informationssymbol für weitere Details.',

    STA_ENTITY_FORM_SKILLS_INFO_DESCRIPTION:
        '<h3>Erforderliche Fähigkeiten:</h3>' +
        '<p>' +
        'Fähigkeiten, die zwingend erforderlich sind, um bei deinem Kurzeinsatz teilzunehmen. Kolleg*innen können sich nur bewerben, wenn sie diese Fähigkeiten in ihrem Profil angegeben haben.' +
        '</p>' +
        '<h3>Hilfreiche Fähigkeiten:</h3>' +
        '<p>' +
        'Fähigkeiten, die nicht zwingend notwendig sind, um an deinem Kurzeinsatz teilzunehmen. Kolleg*innen können sich bewerben, ohne diese Fähigkeiten in ihrem Profil hinterlegt zu haben.' +
        '</p>',
    STA_ENTITY_FORM_SKILLS_REQUIRED_ADDED_NOTIFICATION:
        'Sie haben diese Fähigkeit als erforderlich markiert.' +
        '\n' +
        'Hinweis: Je mehr Fähigkeiten Sie als erforderlich festlegen, desto weniger Kolleg*innen können sich bewerben.',
    STA_ENTITY_FORM_CAMPAIGNS_LABEL: 'Gehört zu folgendem Programm:',
    STA_ENTITY_FORM_CAMPAIGNS_PLACEHOLDER: 'wählen..',

    STA_PUBLISH_DIALOG_TITLE: 'Kurzeinsatz jetzt veröffentlichen?',
    STA_PUBLISH_DIALOG_CONTENT_1: 'Passende Kolleg*innen werden nun informiert!',
    STA_PUBLISH_DIALOG_CONTENT_2:
        'Sobald ein Kurzeinsatz veröffentlicht wird, werden automatisch alle Matches ermittelt und via Mail darüber informiert. Bitte prüfe daher, ob deine Angaben korrekt sind. Die Benachrichtigungen werden innerhalb von 24h nach Veröffentlichung verschickt.',
    STA_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Jetzt veröffentlichen',

    STA_PUBLISH_APPROVAL_TITLE: 'Projekteinsatz erstellen',
    STA_PUBLISH_APPROVAL_SUB_TITLE:
        'Ihr zuständiges Personalmanagement muss über den Projekteinsatz informiert werden.',
    STA_PUBLISH_APPROVAL_TEXT:
        'Tandemploy bringt Ihre Skills und spannende Angebote zusammen. Dies erfolgt natürlich unter Einhaltung von arbeitsrechtlich vorgeschriebenen Prozessen, die im Falle einer Versetzung oder eines Auslandseinsatzes zu beachten sind. Bei Einsätzen im Ausland, die die Dauer einer Dienstreise übersteigen, sind entsprechende Vorlaufzeiten zur Administration zu berücksichtigen. Wenn Sie dazu Fragen haben, wenden Sie sich an Ihre Führungskraft oder Ihre Personalabteilung.',
    STA_PUBLISH_APPROVAL_OPTIONS_LABEL:
        'Ich habe mit meinem zuständigen Personalmanagement besprochen, welche rechtlichen Rahmenbedingungen gelten und inwiefern die Mitbestimmung bei Veröffentlichung und Besetzung dieses Projekteinsatzes einzubeziehen ist.',
    STA_PUBLISH_APPROVAL_OPTIONS_YES_LABEL: 'Ja',
    STA_PUBLISH_APPROVAL_OPTIONS_NO_LABEL: 'Noch nicht',
    STA_PUBLISH_APPROVAL_PUBLISH_BTN_TITLE: 'Jetzt veröffentlichen!',

    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Erstellen Sie Ihren ersten Einsatz',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'Sie haben bisher noch keinen Kurzeinsatz angeboten. Um einen neuen zu erstellen, legen Sie hier einfach los.',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_WORKSHOP_BTN: 'Neuen Einsatz erstellen',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Kolleg*innen einladen',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Laden Sie Kolleg*innen ein, diese Plattform zu nutzen.',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Einladen',
    STA_OFFERED_BY_ME_PLANNED_HEADER: 'Geplante',
    STA_OFFERED_BY_ME_RUNNING_HEADER: 'Laufende',
    STA_OFFERED_BY_ME_COMPLETE_HEADER: 'Abgeschlossene',
    STA_OFFERED_BY_ME_CONTINUE_EDIT: 'Bearbeiten fortsetzen',
    STA_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Nachricht an Teilnehmer*innen',
    STA_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'Details ansehen',
    STA_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Unveröffentlicht',
    STA_OFFERED_BY_ME_START_DATE_INDICATOR_LABEL: 'Startdatum',
    STA_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'Endet am',
    STA_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Zeitraum',
    STA_OFFERED_BY_ME_APPLICANTS_INDICATOR_LABEL: 'Bewerber',
    STA_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Zuletzt aktualisiert',
    STA_OFFERED_BY_ME_CONTACT_LABEL: 'Kontakt',
    STA_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Kurzeinsatz löschen',
    STA_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Sind Sie sicher, dass Sie den Kurzeinsatz löschen wollen?',
    STA_OFFERED_BY_ME_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Sind Sie sicher, dass Sie dieses Angebot einschließlich der potenziellen Bewerber/innen und/oder Teilnehmer/innen unwiderruflich löschen möchten? Wenn Sie es löschen, werden alle beteiligten Benutzer entsprechend benachrichtigt. Das Angebot kann anschließend nicht mehr aufgerufen werden.',

    STA_SEARCH_HEADER: 'Kurzeinsatzsuche mit Schlagwörtern',
    STA_SEARCH_BTN_TEXT: 'Suchen!',
    STA_SEARCH_RESULT_REQUESTED_SKILLS: 'Gewünschte Fähigkeiten',
    STA_SEARCH_RESULT_VIEW_ENTITY: 'Details ansehen',
    STA_SEARCH_RESULT_AVATAR_LABEL: 'Kontakt',
    STA_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Suchen ohne Suchbegriff zeigt alle aktuell verfügbaren Angebote.',

    STA_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalisierung',
    STA_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Kundenorientierung',
    STA_PROFILE_CATEGORY_SUSTAINABILITY: 'Nachhaltigkeit',
    STA_PROFILE_CATEGORY_MOBILITY: 'Mobilität / Internationale Themen',
    STA_PROFILE_CATEGORY_COMMERCIAL: 'Wirtschaftliche/ Kommerzielle',
    STA_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Produktthemen',
    STA_PROFILE_CATEGORY_IT_TOPICS: 'IT Themen & Tools',
    STA_PROFILE_CATEGORY_COMMUNICATION: 'Kommunikationsthemen',
    STA_PROFILE_CATEGORY_SOCIAL: 'Soziale Themen',
    STA_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methoden',
    STA_PROFILE_CATEGORY_LEAN_METHODS: 'Lean Methoden',
    STA_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Individuelle Skills teilen',
    STA_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    STA_DOMAIN_APPLIED_TO_TAB: 'Meine Kurzeinsätze',
    STA_DOMAIN_SEARCH_TAB: 'Suche',
    STA_DOMAIN_OFFERED_BY_ME_TAB: 'Von mir angeboten',
    STA_DOMAIN_STAFFING: 'Staffing',

    STA_PROFILE_NO_MATCH_IMG_ALT:
        'Bild zeigt eine Lupe mit traurigem Smiley und symbolisiert, dass noch keine Matches gefunden wurden.',
    STA_PROFILE_NO_MATCH_CONTENT:
        'Bitte geben Sie mehr Fähigkeiten und (versteckte?) Talente in Ihrem Profil an, um Matches zu finden',
    STA_PROFILE_NO_MATCH_CTA: 'Bearbeiten',
    STA_PROFILE_NO_MATCH_FIND_CONTENT: 'Weitere Kurzeinsätze über die Suche finden',
    STA_PROFILE_SEARCH_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von Ihnen gewählten Filter',

    STA_DOMAIN_PROFILE_MATCH_SKILLS: 'Gewünschte Fähigkeiten',
    STA_PROFILE_MATCH_VIEW_ENTITY: 'Details ansehen',
    STA_DOMAIN_PROFILE_MATCH_AVATAR_LABEL: 'Kontakt',

    STA_APPLIED_TO_PLACHOLDER_TEXT: 'Sie haben sich bisher noch nicht auf Kurzeinsätze beworben.',
    STA_APPLIED_TO_PLACHOLDER_BTN: 'Passende Kurzeinsätze finden',
    STA_APPLIED_TO_CONTACT_LABEL: 'Kontakt',
    STA_APPLIED_TO_VIEW_DETAILS_BTN_TITLE: 'Details ansehen',
    STA_APPLIED_TO_VIEW_APPLICATION_BTN_TITLE: 'Bewerbung ansehen',
    STA_APPLIED_TO_NOT_ACCEPTED_LABEL:
        'Deine Bewerbung ist vollständig. \nDu wirst über die Entscheidung per Mail informiert.',
    STA_APPLIED_TO_ACCEPTED_LABEL: 'Gratulation! \nDeine Bewerbung war erfolgreich.',
    STA_APPLIED_TO_CANCELLED_LABEL: 'Teilnahme abgelehnt. \nNachträgliche Absage durch Anbieter.',
    STA_APPLIED_TO_REJECTED_LABEL:
        'Sie haben leider keinen Platz bekommen. \nIhre Bewerbung war leider nicht erfolgreich.',
    STA_APPLIED_TO_PLANNED_HEADER: 'Geplante',
    STA_APPLIED_TO_RUNNING_HEADER: 'Laufende Kurzeinsätze',
    STA_APPLIED_TO_COMPLETE_HEADER: 'Abgeschlossene',
    STA_APPLIED_TO_END_DATE_INDICATOR_LABEL: 'Endet am',
    STA_APPLIED_TO_APPLICANTS_INDICATOR_LABEL: 'Bewerber',
    STA_APPLIED_TO_DATE_RANGE_INDICATOR_LABEL: 'Zeitraum',

    STA_DETAILS_VIEW_DESCRIPTION_TITLE: 'Beschreibung',
    STA_DETAILS_VIEW_REQUIRED_SKILLS_TITLE: 'Benötigte Fähigkeiten',
    STA_DETAILS_VIEW_ORGANIZER_LABEL: 'Kontakt',

    STA_FORM_HEADLINE_STA_SKILLS_TITLE: 'Ihre Fähigkeiten',
    STA_FORM_HEADLINE_STA_SKILLS_DESCRIPTION:
        'Um an diesem {{topicName}} teilnehmen zu können, müssen Sie alle der unten genannten Fähigkeiten mitbringen. Sollten Sie unsicher sein, ob Ihr Wissen ausreichend ist, schreiben Sie dem {{topicName}}-Anbieter eine Nachricht.',
    STA_FORM_HEADLINE_NICE_TO_HAVE_SKILLS: 'Hilfreiche Fähigkeiten',

    STA_ENTITY_PARTICIPANTS_SKILL_SUMMARY_LABEL: 'Für diesen Kurzeinsatz benötigte Fähigkeiten sind:',
    STA_ENTITY_PARTICIPANTS_TITLE: 'Bewerber*innen-Liste',
    STA_ENTITY_VIEW_PARTICIPATE_BUTTON: 'Bewerben',
    STA_ENTITY_VIEW_APPLIED_STATUS_NOTE: 'Sie haben sich bereits auf diesen Kurzeinsatz beworben',
    STA_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'Sie können sich für {{topicEntity}} bewerben, indem Sie die <i>>>erste Option in Ihrem Profil aktivieren</i> und somit angeben, dass Sie auch an der Teilnahme interessiert sind',

    STA_ENTITY_APPLICATIONS_IDENTITY_HEADER: 'Bewerber*in',
    STA_ENTITY_APPLICATIONS_SKILLS_HEADER: 'Fähigkeiten von Bewerber*in',
    STA_ENTITY_APPLICATIONS_INTERESTED_IN_SKILLS_HEADER: 'Verwandter Begriff',
    STA_ENTITY_APPLICATIONS_STATUS_HEADER: 'Angenommen',
    STA_ENTITY_APPLICATIONS_ACCEPT: 'Annehmen',
    STA_ENTITY_APPLICATIONS_ACCEPTED: 'Angenommen',
    STA_ENTITY_APPLICATIONS_REFUSED: 'abgelehnt',
    STA_ENTITY_APPLICATIONS_CANCELLED: 'abgesagt',
    STA_ENTITY_APPLICATIONS_COMPLETE_STAFFING: 'Besetzung abschließen',
    STA_ENTITY_APPLICATIONS_STAFFING_COMPLETED: 'Besetzung abgeschlossen',
    STA_ENTITY_APPLICATIONS_NO_MATCHED_SKILLS: 'Bewerbung ohne matchende Skills',

    STA_ENTITY_APPLICATIONS_SEARCH_TITLE: 'Selbst nach Matches suchen',
    STA_ENTITY_APPLICATIONS_SEARCH_INPUT_VALIDATION_REQUIRED: 'Bitte mindestens einen gesuchten Skill eingeben.',
    STA_ENTITY_APPLICATIONS_SEARCH_INPUT_SEARCH_BUTTON: 'Suchen',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFY_BUTTON: 'hinweisen',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFIED: 'hingewiesen',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NO_MATCHES_FOUND: 'Keine Treffer gefunden.',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_TITLE: 'Auf dieses {{topicName}} hinweisen',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SUB_TITLE:
        'Informieren Sie die ausgewählte Person bequem mit einem Klick.',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE_LABEL: 'Die folgende Nachricht wird per Mail versendet:',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE:
        'Ein neues {{name_sta_singular}}-Angebot passt hervorragend zu Ihren Skills und Wünschen! Der {{name_sta_singular}}-Owner hat Sie auf {{platform_name}} gefunden und würde sich freuen, Sie an Bord zu haben. Loggen Sie sich in Ihren Account ein, um Details zu erfahren und gleich Kontakt aufzunehmen!',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SEND_NOTE_BUTTON: 'Hinweis senden',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK: 'Hinweis wurde gesendet.',

    STA_ENTITY_APPLICATIONS_SKILLS_AND_LEVELS_HEADER: 'Fähigkeiten der Bewerber*in',

    STA_ENTITY_APPLICATION_FEEDBACK_DIALOG_BTN: 'Fertig',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADLINE: 'Sie haben keinen Platz bekommen!',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADING: 'Ihre Bewerbung war leider nicht erfolgreich!',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_TEXT:
        'Dieses Mal hat es leider nicht geklappt. Sie werden natürlich weiterhin über passende neue Angebote informiert.',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADLINE: 'Danke für Ihr Interesse',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADING: 'Ihre Bewerbung ist eingegangen.',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_TEXT:
        'Sobald die Besetzung feststeht, werden Sie informiert. Danke für Ihre Geduld!',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADLINE: 'Danke für Ihre Bewerbung',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADING: 'Sie sind dabei!',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_TEXT: 'Ihre Bewerbung war erfolgreich.',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADLINE: 'Nachträglich abgesagt',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADING: 'Ihre Teilnahme wurde abgesagt',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_TEXT:
        'Ihre geplante Teilnahme musste leider nachträglich abgesagt werden. Wenden Sie sich  bei Rückfragen  bitte direkt an den/die Ansprechpartner/in. Vielen Dank für Ihr Verständnis!',

    STA_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_TITLE: 'Bitte bestätigen',
    STA_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_CONTENT:
        'Klar zum Durchstarten? Es scheint, Sie haben passende Bewerber*innen für Ihren Kurzeinsatz gefunden und möchten die Besetzung jetzt abschließen. Wichtig: Wenn Sie nun bestätigen, werden alle nicht akzeptierten Bewerber*innen eine Absage erhalten und es können anschließend keine weiteren Personen zu diesem Kurzeinsatz hinzugefügt werden. Mit den angenommenen Bewerber*innen können Sie im Gruppen-Chat weiteres klären.\nJetzt die Besetzung vollständig abschließen?',

    STA_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_TITLE: '{{userName}} jetzt annehmen',
    STA_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_CONTENT:
        'Wählen Sie  “Annehmen”, um den/die Bewerber/in zuzulassen. Die Person wird im Anschluss automatisch per Mail über die erfolgreiche Bewerbung informiert.',

    STA_ENTITY_REQUIRED_SKILLS_TITLE: 'Fähigkeiten',
    STA_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        'Hier sehen Sie alle Fähigkeiten, die für diesen Kurzeinsatz gesucht werden. Wenn Sie noch Fähigkeiten entdecken, die Sie eigentlich besitzen und einbringen möchten, aber bisher noch nicht in Ihrem Profil haben, können Sie diese hier auswählen und Ihrem Profil hinzufügen.',

    STA_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Vielen Dank für Ihr Interesse!',
    STA_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE: 'Ihr/e Vorgesetze/r muss Ihrer Teilnahme zustimmen.',
    STA_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Tandemploy bringt Ihre Skills und spannende Angebote zusammen. Dies erfolgt natürlich unter Einhaltung von arbeitsrechtlich vorgeschriebenen Prozessen, die im Falle einer Versetzung oder eines Auslandseinsatzes zu beachten sind. Bei Einsätzen im Ausland, die die Dauer einer Dienstreise übersteigen, sind entsprechende Vorlaufzeiten zur Administration zu berücksichtigen. Wenn Sie dazu Fragen haben, wenden Sie sich an Ihre Führungskraft oder Ihre Personalabteilung.',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL:
        'Ich bestätige hiermit, dass ich meine Führungskraft informiert habe und er/sie meiner Teilnahme zustimmt. ',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Ja',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'Noch nicht',

    STA_SKILLS_LABEL: 'Gewünschte Fähigkeiten',
    STA_SKILLS_LEVEL_LABEL: 'Bevorzugtes Level',

    STA_VALIDATION_ERROR_MAX_DURATION: 'Maximale Dauer 6 Monate',

    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_QUESTION: 'Diese Person bereits jetzt ablehnen?',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_YES: 'Ja',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_NO: 'Nein',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_MORE: 'Was bedeutet das?',
    STA_ENTITY_APPLICATION_REJECT_INFO_TITLE: 'Bewerbungen vorab ablehnen',
    STA_ENTITY_APPLICATION_REJECT_INFO_CONTENT:
        'Bewerbungen können frühzeitig abgelehnt werden, wenn bereits klar ist, dass der/die Kandidat/in eher schlechte Chancen hat. Das bringt den Vorteil, dass die Betroffenen früher Bescheid wissen und entsprechend planen können. Außerdem bleibt so die Liste der Bewerbungen übersichtlicher.',

    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_QUESTION: 'Diese Person im Nachhinein ablehnen?',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_YES: 'Ja',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_NO: 'Nein',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_MORE: 'Was bedeutet das?',
    STA_ENTITY_APPLICATION_CANCEL_INFO_TITLE: 'Bewerbung im Nachhinein ablehnen',
    STA_ENTITY_APPLICATION_CANCEL_INFO_CONTENT:
        'Bewerbungen können abgelehnt werden, nachdem Bewerber*innen zuvor schon angenommen wurden. Die Bewerber*innen werden via E-Mail benachrichtigt. Für eine Erklärung empfiehlt es sich, eine persönliche Nachricht zu schreiben.',

    STA_STAFFING_FILTER_STATUS_LABEL: 'Status',
    STA_STAFFING_FILTER_STAFFING_LABEL: 'Staffing',
    STA_STAFFING_FILTER_STAFFING_OPTION_ON: 'offen für Bewerbungen',
    STA_STAFFING_FILTER_STAFFING_OPTION_OFF: 'Berwerbungsfrist erreicht',
    STA_STAFFING_FILTER_CATEGORY_LABEL: 'Thematische Einordnung',
    STA_STAFFING_FILTER_LANGUAGE_LABEL: 'Sprache',
    STA_STAFFING_NO_RESULTS_PLACEHOLDER: 'Es gibt keine Ergebnisse für Ihre ausgewählten Filter.',

    STA_PROFILE_CAMPAIGNS_LABEL: 'HR Programme, an denen Sie teilnehmen',
    STA_PROFILE_CAMPAIGNS_PLACEHOLDER: 'wählen..',

    PAGE_TITLE_SHORT_ASSIGNMENT_MATCHES: 'Kurzeinsätze - Matches',
    PAGE_TITLE_SHORT_ASSIGNMENT_MY: 'Meine Kurzeinsätze',
    PAGE_TITLE_SHORT_ASSIGNMENT_SEARCH: 'Kurzeinsätze - Suche',
    PAGE_TITLE_SHORT_ASSIGNMENT_OFFERED: 'Kurzeinsätze - Von mir angeboten',

    ORIGIN_TOPIC_STA_PRIVATE: 'Kurzeinsätze',
    ORIGIN_TOPIC_STA_PARTICIPANT: 'Kurzeinsätze',

    SHORT_TIME_ASSIGNMENT_ORGANIZERS_INFO:
        'Ein Angebot kann mehrere Organisator*innen haben, sodass sie parallel oder in Abwesenheit anderer Organisator*innen dieses Angebot vorantreiben können. Hier können Sie festlegen, wer außer Ihnen dieses {{name_sta_singular}}-Angebot bearbeiten und löschen sowie Nachrichten an Teilnehmende senden können soll. \n' +
        'Die hier aufgeführten Nutzer*Innen können ihrerseits Organisator*Innen hinzufügen und löschen.',
};
