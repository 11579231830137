import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobXUserCardViewComponent } from './job-xuser-card-view.component';
import { JobXBookmarksModule } from '../../job-x-domain/job-x-bookmarks/job-x-bookmarks.module';

@NgModule({
    declarations: [JobXUserCardViewComponent],
    imports: [CommonModule, JobXBookmarksModule],
})
export class JobXUserCardViewModule {}
