export default {
    JOBX: 'Jobsharing',
    JOBX_INFO:
        'Jobsharing, oder auch Arbeitsplatzteilung, ist ein flexibles Arbeitszeitmodell, bei dem sich zwei oder mehr Arbeitnehmer mindestens eine Vollzeitstelle teilen. Sie arbeiten dabei als Team sehr eng zusammen und legen ihre Arbeitszeiten und Aufgaben individuell untereinander fest. Jobsharing kann auch in Führungspositionen (sog. Top-Sharing/ Co-Leadership) sehr interessant sein. Der Matching-Algorithmus von {{ appName }} findet passende Tandem-Partner*innen unter Ihren Kollegen und informiert Sie per Mail bei neuen Matches.',

    JOBX_ENTITY_CREATE: 'Job erstellen',
    JOBX_ENTITY_CREATE_TITLE: 'Job erstellen',

    JOBX_ENTITY_FORM_TITLE_LABEL: 'Jobtitel',
    JOBX_ENTITY_FORM_END_DATE_LABEL: 'Aktiv bis (maximal 8 Wochen)',
    JOBX_ENTITY_FORM_LOCATION_LABEL: 'Standort',
    JOBX_ENTITY_FORM_LANGUAGES_LABEL: 'Nur für Personen mit Sprachkenntnissen in…',
    JOBX_ENTITY_FORM_DESCRIPTION_LABEL: 'Jobbeschreibung',
    JOBX_ENTITY_FORM_REQUESTED_SKILLS_LABEL: 'Relevantes Wissen und Kompetenzen (Schlagworte)',
    JOBX_ENTITY_FORM_DESCRIPTION_ERROR: 'Die Beschreibung muss mindestens 50 Zeichen umfassen.',

    JOBX_ENTITY_CREATE_BUTTON_DRAFT: 'Als Entwurf speichern',
    JOBX_ENTITY_CREATE_BUTTON_PUBLISH: 'Veröffentlichen',

    JOBX_ENTITY_EDIT_BUTTON_DRAFT: 'Als Entwurf speichern',
    JOBX_ENTITY_EDIT_BUTTON_PUBLISH: 'Veröffentlichen',
    JOBX_ENTITY_EDIT_BUTTON_SAVE: 'Speichern',
    JOBX_ENTITY_EDIT_TITLE: 'Job bearbeiten',

    JOBX_OFFERED_BY_ME_COMPLETE_HEADER: 'Abgelaufen',
    JOBX_OFFERED_BY_ME_EXTEND_VALIDITY_BTN_TITLE: 'Ablaufdatum aktualisieren',
    JOBX_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Posting ist abgelaufen.',

    JOBX_DOMAIN_OFFERED_BY_ME_TAB: 'Von mir angeboten',
    JOBX_DOMAIN_SEARCH_TAB: 'Suche',
    JOBX_DOMAIN_JOB_BOARD_TAB: 'Job Board',
    JOBX_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    JOBX_DOMAIN_BOOKMARKS_TAB: 'Gemerkte Kolleg*innen',

    JOBX_PUBLISH_DIALOG_TITLE: 'Job veröffentlichen',
    JOBX_PUBLISH_DIALOG_CONTENT_1: 'Passende Kolleg*innen werden nun informiert!',
    JOBX_PUBLISH_DIALOG_CONTENT_2:
        'Sobald ein Job veröffentlicht wurde, ist er für alle Jobsharing-Interessenten sichtbar. Von da an können Sie für weitere Informationen kontaktiert werden.',
    JOBX_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Jetzt veröffentlichen!',

    JOBX_OFFERED_BY_ME_CONTACT_LABEL: 'Kontakt',
    JOBX_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'Noch bis zum',
    JOBX_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'Details anzeigen',
    JOBX_OFFERED_BY_ME_RUNNING_HEADER: 'Laufende',
    JOBX_OFFERED_BY_ME_PLANNED_HEADER: 'Geplant',
    JOBX_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Unveröffentlicht',
    JOBX_OFFERED_BY_ME_PUBLISH_BTN_TITLE: 'Veröffentlichen',
    JOBX_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Zuletzt aktualisiert ',
    JOBX_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Job löschen',
    JOBX_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Sind Sie sicher, dass Sie diesen Job löschen wollen?',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Erstellen Sie Ihren ersten Job',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'Du hast bisher noch keinen Job angeboten. Um einen neuen zu erstellen, lege hier einfach los.',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_JOB_BTN: 'Job erstellen',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Kolleg*innen einladen',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Laden Sie Kolleg*innen ein, diese Plattform zu nutzen.',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Einladen',

    JOBX_SEARCH_HEADER: 'Jobsuche mit Schlagwörtern',
    JOBX_SEARCH_EMPTY_MESSAGE: 'Leider wurde nichts gefunden.',
    JOBX_SEARCH_RESULT_VIEW_ENTITY: 'Details anzeigen',
    JOBX_SEARCH_RESULT_AVATAR_LABEL: 'Kontakt',
    JOBX_SEARCH_RESULT_REQUESTED_SKILLS: 'Benötigte Fähigkeiten',
    JOBX_SEARCH_BTN_TEXT: 'Suchen!',

    JOBX_SEARCH_CAMPAIGNS_LABEL: 'HR Programm',
    JOBX_SEARCH_CAMPAIGNS_PLACEHOLDER: 'keine',
    JOBX_SEARCH_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von Ihnen gewählten Filter',

    JOBX_MATCHING_CAMPAIGNS_LABEL: 'HR Programm',
    JOBX_MATCHING_CAMPAIGNS_PLACEHOLDER: 'keine',
    JOBX_MATCHING_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von Ihnen gewählten Filter',

    JOBX_ADS_LABEL:
        'Die hier ausgeschriebenen Stellen sind möglicherweise nicht die vollständige Liste der aktuellen Stellenangebote für verfügbare Co-Leadership-Positionen. Besuche  daher auch die SAP Success Maps Karrieremöglichkeiten, um passende Positionen für dich zu finden. <u><a href="https://performancemanager5.successfactors.eu/sf/careers/jobsearch?bplte_company=SAP&_s.crb=T3PnaFkNEtvdAT9W9XMBUv6mYSumbW3waU9NDOqt97U%253d" target="_blank">SuccessFactors: Career Opportunities</a></u>',
    JOBX_ADS_INFO:
        'Tipp: Wähle bei den Suchkriterien den Karrierestatus "Executive" & "Management". Wenn du ein interessantes Stellenangebot gefunden hast, schau dir die Details an, um zu sehen, ob diese Position als Co-Führungsposition ausgeschrieben wurde.',
    JOBX_ADS_INFO_LABEL: 'Tipp',

    JOBX_DETAILS_VIEW_ORGANIZER_LABEL: 'Kontakt',
    JOBX_DETAILS_VIEW_DESCRIPTION_TITLE: 'Jobbeschreibung',
    JOBX_DETAILS_VIEW_SKILLS_TITLE: 'Relevantes Wissen und Kompetenzen (Schlagworte)',

    JOBX_PROFILE_TITLE: 'Jobsharing',
    JOBX_PROFILE_SUBTITLE:
        'Ich suche eine*n Jobsharing-Partner*in und / oder möchte einen neuen Jobsharing-Job finden.',
    JOBX_PROFILE_LOCATION_LABEL: 'Künftig möchte ich am liebsten arbeiten in',
    JOBX_PROFILE_MAIN_MOTIVATION_LABEL: 'Was ist deine Hauptmotivation für das Jobsharing?',
    JOBX_PROFILE_IS_CURRENTLY_IN_LEADER_POSITION_LABEL: 'Ich arbeite aktuell in einer Führungsposition',
    JOBX_PROFILE_WANTS_TO_BE_IN_LEADER_POSITION_LABEL: 'Künftig in einer Führungsposition arbeiten',
    JOBX_PROFILE_MY_SKILLS_LABEL: 'Meine Kenntnisse',
    JOBX_PROFILE_TANDEM_SKILLS_LABEL: 'Tandempartner*in hat bessere oder komplementäre Kenntnisse in',
    JOBX_PROFILE_HOUR_SPLIT_HEADER_LABEL: 'Gewünschte Aufteilung in Wochenstunden',
    JOBX_PROFILE_HOUR_SPLIT_MY_HOURS_LABEL: 'Meine Stunden:',
    JOBX_PROFILE_HOUR_SPLIT_TANDEM_HOURS_LABEL: 'Tandempartner*in:',
    JOBX_PROFILE_FORM_CURRENT_JOB_LIKES_LABEL: 'An meinem aktuellen Job mag ich besonders:',
    JOBX_PROFILE_FORM_CURRENT_JOB_DESCRIPTION_LABEL: 'Kurzbeschreibung meines aktuellen Jobs:',

    JOBX_PROFILE_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',
    JOBX_PROFILE_NO_MATCH_CONTENT:
        'Bitte geben Sie mehr Fähigkeiten und (versteckte?) Talente in Ihrem Profil an, um Matches zu finden',
    JOBX_PROFILE_NO_MATCH_CTA: 'Bearbeiten',
    JOBX_PROFILE_NO_MATCH_FIND_CONTENT: 'Weitere Kolleg*innen über die Suche finden',
    JOBX_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',

    JOBX_PROFILE_SEARCH_HEADER: 'Jobsharing - Profile durchsuchen',

    LEADERSHIP_POSITION_YES: 'ja',
    LEADERSHIP_POSITION_FLEXIBLE: 'da bin ich flexibel',
    LEADERSHIP_POSITION_NO: 'nein',

    JOBX_MAIN_MOTIVATION_DESIRED_CAREER: 'Wunschkarriere ohne Vollzeit',
    JOBX_MAIN_MOTIVATION_TEAMWORK: 'Teamwork im Tandem',
    JOBX_MAIN_MOTIVATION_CO_LEADERSHIP: 'Co-Leadership ist die schönere Art zu führen',

    JOBX_PROFILE_VIEW_LOCATION_LABEL: 'Mögliche künftige Standorte',
    JOBX_PROFILE_VIEW_MY_SKILLS_LABEL: 'Kenntnisse',
    JOBX_PROFILE_VIEW_TANDEM_SKILLS_LABEL: 'Gewünschte Kenntnisse des Partners',
    JOBX_PROFILE_VIEW_CURRENT_JOB_DESCRIPTION_LABEL: 'Kurzbeschreibung meines Jobs',
    JOBX_PROFILE_VIEW_CURRENT_JOB_LIKES_LABEL: 'An meinem Job mag ich',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_LABEL: 'Führungsposition',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_CURRENT: 'aktuell',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_FUTURE: 'künftig',
    JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_YES: 'ja',
    JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_NO: 'nein',

    JOBX_BOOKMARKS_TITLE: 'Noch kein Profil gemerkt',
    JOBX_BOOKMARKS_PROFILES: 'Bookmarks',
    JOBX_BOOKMARKS_SUBTITLE: 'Hier sehen Sie Ihre gemerkten Kolleg*innen im Bereich Jobsharing.',

    JOBX_TANDEM_CHECK_BIRTH_YEAR: 'Geburtsjahr',
    JOBX_TANDEM_CHECK_LANGUAGES: 'Sprachen',
    JOBX_TANDEM_CHECK_SPLIT_HOURS: 'Gewünschte Wochenstunden',
    JOBX_TANDEM_CHECK_SKILLS: 'Kenntnisse',
    JOBX_TANDEM_CHECK_LOCATIONS: 'Standort(e)',
    JOBX_TANDEM_CHECK_EDUCATION: 'Abschluss',
    JOBX_TANDEM_CHECK_EXPERIENCE: 'Berufserfahrung',
    JOBX_TANDEM_CHECK_TITLE: 'Tandem Check',
    JOBX_TANDEM_CHECK_GET_TO_KNOW: 'Kennenlernen',

    PAGE_TITLE_JOB_SHARING_MATCHES: 'Job Sharing - Matches',
    PAGE_TITLE_JOB_SHARING_BOOKMARKS: 'Job Sharing - Gemerkte Kollegen',
    PAGE_TITLE_JOB_SHARING_SEARCH: 'Job Sharing - Suche',
    PAGE_TITLE_JOB_SHARING_JOBBOARD: 'Job Sharing - Job Board',
    PAGE_TITLE_JOB_SHARING_OFFERED: 'Job Sharings - Von mir angeboten',

    JOBX_PROFILE_CAMPAIGNS_LABEL: 'HR Programme, an denen Sie teilnehmen',
    JOBX_PROFILE_CAMPAIGNS_PLACEHOLDER: 'wählen..',
    PROFILE_VIEW_JOBX_CAMPAIGNS_LABEL: 'HR Programme',
};
