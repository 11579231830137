import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { MentoringService } from './mentoring-service/mentoring.service';
import { MentoringActions } from './mentoring.actions';
import {
    IS_PROFILE_DIRTY,
    MentoringBookmark,
    MentoringContext,
    MentoringMatches,
    MentoringProfile,
    PROFILE_STORAGE_KEY,
} from './mentoring-service/mentoring.types';
import { Store } from '@ngrx/store';
import { MentoringUnexpectedException } from './mentoring-service/mentoring.exceptions';
import {
    isMentoringProfileInDirtyState,
    selectCurrentMentoringProfile,
    selectSavedMentoringProfile,
} from './mentoring.selectors';
import {
    AccountActions,
    AccountState,
    CommonActions,
    DEFAULT_PAGE_SIZE,
    SelectedTopicsActions,
    StorageService,
} from '@tploy-enterprise/tenant-core';
import { MentoringState } from './mentoring.reducer';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MentoringEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{ mentoring: MentoringState; account: AccountState }>,
        private readonly mentoringService: MentoringService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
        private readonly router: Router,
    ) {}

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadProfile),
            switchMap(() =>
                this.mentoringService.loadProfile().pipe(
                    map((profile: MentoringProfile) =>
                        MentoringActions.loadProfileSuccess({ profile, session: this.readProfileFromSession() }),
                    ),
                    catchError((error) => {
                        if (error instanceof MentoringUnexpectedException) {
                            this.errorHandler.handleError(error);
                        }
                        return of(MentoringActions.loadProfileError({ error, session: this.readProfileFromSession() }));
                    }),
                ),
            ),
        ),
    );

    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MentoringActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentMentoringProfile)),
                tap(([{ currentProfile }, state]) => this.storeProfileInSession({ ...state, ...currentProfile })),
            ),
        { dispatch: false },
    );

    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => MentoringActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.saveProfile),
            // ignore the mentoring profile's non dirty state
            // it is a responsibility of the mentoring to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isMentoringProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentMentoringProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.mentoringService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) => MentoringActions.saveProfileSuccess({ currentProfile: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(MentoringActions.saveProfileError({ error, currentProfile }));
                    }),
                ),
            ),
        ),
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.mentoringService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return MentoringActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(MentoringActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MentoringActions.cancelCurrentProfileEdit,
                AccountActions.createSuccess,
                SelectedTopicsActions.cancelEdit,
            ),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedMentoringProfile)),
            map(([action, profile]) => MentoringActions.loadProfileSuccess({ profile, session: null })),
        ),
    );

    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MentoringActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadMatches),
            switchMap((action) =>
                this.mentoringService.getProfileMatches(action.context, action.pageParams, action.campaigns).pipe(
                    map((result: MentoringMatches) =>
                        MentoringActions.loadMatchesSuccess({
                            matches: result.matches,
                            allMatches: result.allMatches,
                            pageParams: result.pageParams,
                            selectedCampaigns: action.campaigns,
                        }),
                    ),
                    catchError((error: Error) => {
                        this.errorHandler.handleError(error);
                        return of(MentoringActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadBookmarks),
            switchMap(({ context }) =>
                this.mentoringService.getBookmarks(context).pipe(
                    map((bookmarks: MentoringBookmark[]) => MentoringActions.loadBookmarksSuccess({ bookmarks })),
                    catchError((error: Error) => {
                        this.errorHandler.handleError(error);
                        return of(MentoringActions.loadBookmarksError({ error }));
                    }),
                ),
            ),
        ),
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.search),
            switchMap((action) =>
                this.mentoringService
                    .search(action.searchQuery, action.context, action.pageParams, action.campaigns)
                    .pipe(
                        map((searchResults) =>
                            MentoringActions.searchSuccess({
                                results: searchResults.results,
                                allResults: searchResults.allResults,
                                pageParams: searchResults.pageParams,
                            }),
                        ),
                        catchError((error) => {
                            this.errorHandler.handleError(error);
                            return of(MentoringActions.searchError({ error }));
                        }),
                    ),
            ),
        ),
    );

    reloadMatchesOnProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.saveProfileSuccess),
            withLatestFrom(this.store.select(selectCurrentMentoringProfile)),
            switchMap(([action, profile]) => {
                if (this.router.url.includes('/mentoring/matching')) {
                    const context = profile.isMentee
                        ? MentoringContext.MENTORING_MENTOR
                        : MentoringContext.MENTORING_MENTEE;
                    this.store.dispatch(MentoringActions.changeContext({ context }));
                    return of(
                        MentoringActions.loadMatches({
                            context,
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    loadSearchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadSearchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.mentoringService.loadSearchResult(action.searchQuery, action.context, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return MentoringActions.loadSearchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(MentoringActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    loadMatchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MentoringActions.loadMatchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.mentoringService.loadMatchResult(action.context, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return MentoringActions.loadMatchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(MentoringActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    private storeProfileInSession(profile: MentoringProfile) {
        this.storage.local.set(PROFILE_STORAGE_KEY, profile);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): MentoringProfile {
        return this.storage.local.get<MentoringProfile, null>(PROFILE_STORAGE_KEY, null);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession(): void {
        this.storage.local.remove(PROFILE_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
