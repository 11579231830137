import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobsActions } from './jobs.actions';
import { JobXEntity, JobXProfile, IS_PROFILE_DIRTY, JOBX_STORAGE_KEY } from './jobs.types';
import { catchError, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isJobXProfileInDirtyState, selectCurrentJobXProfile, selectSavedJobXProfile } from './jobx.selectors';
import { JobXService } from './jobx.service';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
    AccountActions,
    AccountState,
    BookmarkActions,
    SelectedTopicsActions,
    StorageService,
    JOB_SHARING_TOPIC_NAME,
    DEFAULT_PAGE_SIZE,
    SuccessFactorsActions,
    SuccessFactorsSkillsState,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { JobXUnexpectedException } from './jobx.exceptions';
import { JobXState } from './jobXReducer';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class JobXEffects {
    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadProfile),
            switchMap(() =>
                this.jobXService.loadProfile().pipe(
                    map((profile: JobXProfile) =>
                        JobsActions.loadProfileSuccess({ profile, session: this.readProfileFromSession() }),
                    ),
                    catchError((error) => {
                        if (error instanceof JobXUnexpectedException) {
                            // Other possible error is "Not Found" but this is a valid scenario
                            // When something else happens, we want to log it.
                            this.errorHandler.handleError(error);
                        }
                        return of(JobsActions.loadProfileError({ error, session: this.readProfileFromSession() }));
                    }),
                ),
            ),
        ),
    );
    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(JobsActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentJobXProfile)),
                tap(([{ currentProfile }, state]) => this.storeProfileInSession({ ...state, ...currentProfile })),
            ),
        { dispatch: false },
    );
    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => JobsActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );
    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.saveProfile),
            // ignore the projects profile's non dirty state
            // it is a responsibility of the project to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isJobXProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentJobXProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.jobXService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) => JobsActions.saveProfileSuccess({ currentProfile: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(JobsActions.saveProfileError({ error, currentProfile }));
                    }),
                ),
            ),
        ),
    );
    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.jobXService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return JobsActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );
    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                JobsActions.cancelCurrentProfileEdit,
                AccountActions.createSuccess,
                SelectedTopicsActions.cancelEdit,
            ),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedJobXProfile)),
            map(([, profile]) => JobsActions.loadProfileSuccess({ profile, session: null })),
        ),
    );
    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(JobsActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );
    syncProfileSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.syncProfileSkills),
            withLatestFrom(this.store.select(selectCurrentJobXProfile)),
            switchMap(([, currentProfile]) => {
                return of(AccountActions.sync({ skills: currentProfile.skills.map((skill) => skill.name) }));
            }),
        ),
    );
    saveEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.saveEntity),
            exhaustMap((action) =>
                this.jobXService.saveEntity(action.entity).pipe(
                    map((savedEntity) => JobsActions.saveEntitySuccess({ entity: savedEntity })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.saveEntityError({ error }));
                    }),
                ),
            ),
        ),
    );
    createEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.createEntity),
            switchMap((action) => {
                if (action.entity.id) {
                    return this.jobXService.saveEntity(action.entity);
                }

                return this.jobXService.createEntity(action.entity);
            }),
            map((savedEntity) => JobsActions.createEntitySuccess({ entity: savedEntity })),
            catchError((error) => {
                this.errorHandler.handleError(error);
                return of(JobsActions.createEntityError({ error }));
            }),
        ),
    );
    deleteEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.deleteEntity),
            switchMap((action) =>
                this.jobXService.deleteEntity(action.entityId).pipe(
                    map((entityId) => JobsActions.deleteEntitySuccess({ entityId })),
                    catchError((error: Error) => of(JobsActions.deleteEntityError({ error }))),
                ),
            ),
        ),
    );
    publishEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.publishEntity),
            switchMap((projectsEntity) =>
                this.jobXService.publishEntity(projectsEntity.entityId).pipe(
                    map((entity) => JobsActions.publishEntitySuccess({ entity })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.publishEntityError({ error }));
                    }),
                ),
            ),
        ),
    );
    searchEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.searchEntities),
            switchMap((action) =>
                this.jobXService.searchEntities(action.searchQuery, action.pageParams).pipe(
                    map((searchResults) =>
                        JobsActions.searchEntitiesSuccess({
                            entities: searchResults.results,
                            pageParams: searchResults.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.searchEntitiesError({ error }));
                    }),
                ),
            ),
        ),
    );
    loadEntitiesOfferedByMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadEntitiesOfferedByMe),
            withLatestFrom(this.store.select((state) => state.account)),
            switchMap(([, account]) =>
                this.jobXService.loadEntitiesOfferedByMe(account.userId).pipe(
                    map((entities: JobXEntity[]) => JobsActions.loadEntitiesOfferedByMeSuccess({ entities })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.loadEntitiesOfferedByMeError({ error }));
                    }),
                ),
            ),
        ),
    );
    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadMatches),
            switchMap((action) =>
                this.jobXService.getProfileMatches(action.pageParams, action.campaigns).pipe(
                    map((result) =>
                        JobsActions.loadMatchesSuccess({
                            entities: result.matches,
                            allEntities: result.allMatches,
                            pageParams: result.pageParams,
                            selectedCampaigns: action.campaigns ?? [],
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(JobsActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );
    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.saveProfileSuccess),
            switchMap(() => {
                if (this.router.url.includes('job-sharing/matching')) {
                    return of(
                        JobsActions.loadMatches({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                            campaigns: [],
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );
    searchProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.searchProfiles),
            switchMap((action) =>
                this.jobXService.searchProfiles(action.searchQuery, action.pageParams, action.campaigns).pipe(
                    map((searchResults) =>
                        JobsActions.searchProfilesSuccess({
                            profiles: searchResults.results,
                            allProfiles: searchResults.allResults,
                            pageParams: searchResults.pageParams,
                        }),
                    ),
                    catchError((error: Error) => of(JobsActions.searchProfilesError({ error }))),
                ),
            ),
        ),
    );

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadBookmarks),
            switchMap(() => this.jobXService.getBookmarks(JOB_SHARING_TOPIC_NAME)),
            map((data) => JobsActions.loadBookmarksSuccess({ data })),
            catchError((error) => {
                return of(JobsActions.loadBookmarksError({ error }));
            }),
        ),
    );

    loadBookmarkData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookmarkActions.addSuccess),
            switchMap(() => of(JobsActions.loadBookmarks())),
        ),
    );

    loadSearchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadSearchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.jobXService.loadSearchResult(action.searchQuery, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return JobsActions.loadSearchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(JobsActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    loadMatchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(JobsActions.loadMatchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.jobXService.loadMatchResult(action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return JobsActions.loadMatchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(JobsActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    syncSuccessFactorsSkills$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SuccessFactorsActions.syncSuccessFactorsSkills),
                withLatestFrom(this.store.select((state) => state.successFactors.topicsForSync)),
                switchMap(([{ skills }, topics]) => {
                    if (topics.includes('job-sharing')) {
                        this.store.dispatch(JobsActions.syncSuccessFactorsSkills({ skills }));
                    }
                    return [];
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{
            jobx: JobXState;
            account: AccountState;
            successFactors: SuccessFactorsSkillsState;
        }>,
        private readonly jobXService: JobXService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
        private readonly router: Router,
    ) {}

    private storeProfileInSession(profile: JobXProfile) {
        this.storage.local.set(JOBX_STORAGE_KEY, {
            ...profile,
            skills: profile.skills.map((skill) => (skill?.name ? skill : { name: skill, level: 1 })),
        });
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): JobXProfile {
        return this.storage.local.get<JobXProfile, null>(JOBX_STORAGE_KEY, null);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(JOBX_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
