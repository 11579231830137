import { Observable } from 'rxjs';
import { InjectionToken, Provider } from '@angular/core';
import { Cloud } from '@tploy-enterprise/tenant-common';
import { ConfigService } from '../config/config.service';

export const TENANT_COUNTRY_LOCATIONS_CLOUD = new InjectionToken<Cloud>('TENANT_COUNTRY_LOCATIONS_CLOUD');

export const TenantCountryLocationsProvider: Provider = {
    provide: TENANT_COUNTRY_LOCATIONS_CLOUD,
    useFactory: TenantCountryLocationsCloudFactory,
    deps: [ConfigService],
};

export function TenantCountryLocationsCloudFactory(configService: ConfigService): Cloud {
    const companyLocations: Cloud = configService.availableLocations;

    const rootLocationName: string = configService.remoteSettings.settings.tenantCountryLocationName;
    if (!rootLocationName) {
        return companyLocations;
    }

    return companyLocations.getChildCloud(rootLocationName);
}
