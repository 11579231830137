import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnknownRouteGuard } from './unknown-route.guard';

const routes: Routes = [
    {
        path: '**',
        canActivate: [UnknownRouteGuard],
        children: [],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UnknownRouteRoutingModule {}
