import { Injectable } from '@angular/core';
import { ExperienceData, ExperienceDataDTO } from './experience.types';

@Injectable({
    providedIn: 'root',
})
export class ExperienceSerializer {
    serialize(experience: ExperienceData): ExperienceDataDTO {
        return {
            ...experience,
            positions: this.serializePositions(experience),
        };
    }

    private serializePositions(experience: ExperienceData) {
        return experience.positions.map((p) => ({
            name: p.name,
            numberOfYears: p.numberOfYears > 0 ? p.numberOfYears : 0,
            numberOfMonths: p.numberOfMonths > 0 ? p.numberOfMonths : 0,
        }));
    }

    deserialize(dto: ExperienceDataDTO): ExperienceData {
        return { ...dto };
    }
}
