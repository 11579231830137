<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-string-list-field
        *ngIf="hasLocations() || isLocationIndependent"
        key="PROFILE_VIEW_GENERATION_EXCHANGE_LOCATIONS_LABEL"
        [value]="locations"
        [extraValue]="locationIndependent"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        *ngIf="hasGeneration()"
        key="PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_LABEL"
        [value]="generation"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-string-list-field
        *ngIf="hasCampaigns()"
        key="PROFILE_VIEW_GENERATION_EXCHANGE_CAMPAIGNS_LABEL"
        [value]="campaigns"
        [separateByComma]="true"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-list-field
        *ngIf="hasMotivation()"
        key="PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_LABEL"
        [value]="motivations"
    >
    </tp-profile-view-list-field>
    <tp-profile-view-string-list-field
        *ngIf="hasTopics()"
        key="PROFILE_VIEW_GENERATION_EXCHANGE_TOPICS_LABEL"
        [value]="topics"
    >
    </tp-profile-view-string-list-field>
</tp-profile-view-fieldset>
