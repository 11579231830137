import { EventEmitter, HostBinding, HostListener, Input, Output, Directive } from '@angular/core';
import { ListItem } from './list-item.interface';
import { CheckboxListOption } from '../../checkbox-tools.types';

@Directive()
export abstract class ListItemBase implements ListItem {
    @HostBinding('class.list-item') listItemClass = true;

    @Input()
    @HostBinding('class.selected')
    set selected(value: boolean) {
        this._selected = value;
    }
    get selected(): boolean {
        return this._selected;
    }
    private _selected: boolean;

    set hover(value: boolean) {
        this._hover = value;
    }
    get hover(): boolean {
        return this._hover;
    }
    @HostBinding('class.hover') _hover = false;

    @Input() option: CheckboxListOption;
    @Output() toggleOption: EventEmitter<CheckboxListOption> = new EventEmitter();

    toggle() {
        this.toggleOption.emit(this.option);
    }

    @HostListener('click', ['$event']) onMouseClick(event: MouseEvent) {
        event.preventDefault();
        this.toggle();
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.hover = true;
    }

    @HostListener('mouseleave') onMouseleave() {
        this.hover = false;
    }
}
