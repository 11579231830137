<h1 mat-dialog-title>{{ 'OFFER_DELETED_DIALOG_TITLE' | translatable }}</h1>
<mat-dialog-content class="dialog-content">
    <div class="grid-layout">
        <div class="grid-layout_span-7">
            <p>{{ 'OFFER_DELETED_DIALOG_CONTENT' | translatable }}</p>
            <tp-secondary-button (click)="routerNavigate()" mat-dialog-close>{{
                'OFFER_DELETED_DIALOG_SEARCH' | translatable
            }}</tp-secondary-button>
        </div>
        <div class="grid-layout_span-5 grid-layout_span-12--md">
            <div class="entity-not-found__img-cnt">
                <img [src]="'/global_assets/images/offer_deleted.png'" aria-hidden="true" />
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="form-footer form-footer--buttons-center">
    <nav>
        <tp-primary-button mat-dialog-close>
            {{ 'OK' | translatable }}
        </tp-primary-button>
    </nav>
</mat-dialog-actions>
