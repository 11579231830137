<div class="text-container" layout="column" layout-align="start center">
    <h1>Welcome to Tandemploy</h1>

    <section layout="column" layout-align="start stretch">
        <h2 layout-margin layout-padding style="align-self: center">
            This is your space to work in a more networked and flexible way.
        </h2>
        <p>
            Find expertise and advice in your organization quickly and easily, learn from colleagues and through
            exciting projects, don't miss any offers and training opportunities that are relevant to you - and share
            your experience with people you didn't know before. Work more flexibly, connected, collaboratively - because
            a lot is possible here and most importantly, you can shape it!
        </p>
        <div class="info-text-img-container">
            <img src="/assets/images/info1.jpg" class="info-text-img" />
        </div>
    </section>

    <section layout="column" layout-align="start stretch">
        <h2 layout-margin layout-padding style="align-self: center" class="orange">Not „another profile“!?</h2>
        <p>
            Together with us, show that work can be done differently: with more cooperation, more space for reflection
            and fresh perspectives - and with new opportunities to learn from and with colleagues and to develop further
            within your organization.
        </p>
        <div class="info-text-img-container">
            <img src="/assets/images/info2.jpg" class="info-text-img" />
        </div>
    </section>
</div>
