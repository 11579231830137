import { combineReducers, createReducer, createSelector, on } from '@ngrx/store';
import { BookmarkActions } from './bookmark.actions';
import { Bookmark } from './bookmark.types';

export interface BookmarkStatusState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
}

const bookmarkStatusInitialState: BookmarkStatusState = {
    loaded: false,
    loading: false,
    loadingError: null,
};

const statusReducer = createReducer(
    bookmarkStatusInitialState,
    on(BookmarkActions.load, (state) => ({ ...state, loading: true })),
    on(BookmarkActions.loadError, (state, { error }) => ({ ...state, loadingError: error, loading: false })),
    on(BookmarkActions.loadSuccess, (state) => ({ ...state, loadingError: null, loading: false, loaded: true })),
);

export interface BookmarksState {
    status: BookmarkStatusState;
    bookmarks: Array<Bookmark>;
}

const bookmarksReducer = createReducer(
    [],
    on(BookmarkActions.loadSuccess, (state, { bookmarks }) => bookmarks),
    on(BookmarkActions.addSuccess, (state, { bookmark }) => [...state, bookmark]),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => removeBookmark(state, bookmark)),
);

function removeBookmark(state: Array<Bookmark>, removing: Bookmark): Array<Bookmark> {
    const bookmarkIndex = state.indexOf(removing);
    if (bookmarkIndex === -1) {
        return state;
    } else {
        const newBookmarks = [...state];
        newBookmarks.splice(bookmarkIndex, 1);
        return newBookmarks;
    }
}

export const bookmarksDataReducer = combineReducers({
    status: statusReducer,
    bookmarks: bookmarksReducer,
});

export const getBookmarkByEntityId = (state: BookmarksState, id: string): Bookmark => {
    if (!state.status.loaded) {
        return null;
    }

    const bookmark = state.bookmarks.find((b) => b.entityId === id);
    if (bookmark === null) {
        throw new Error(`Bookmark with entityId=${id} doesn't exist`);
    }

    return bookmark;
};

export const getBookmarkById = (state: BookmarksState, id: string): Bookmark => {
    if (!state.status.loaded) {
        return null;
    }

    const bookmark = state.bookmarks.find((b) => b.id === id);
    if (bookmark === null) {
        throw new Error(`Bookmark with entityId=${id} doesn't exist`);
    }

    return bookmark;
};

export const selectBookmarksState = (state: BookmarksState): BookmarksState => {
    return state;
};

export const selectBookmarkByEntityId = createSelector(selectBookmarksState, getBookmarkByEntityId);

export const selectBookmarkById = createSelector(selectBookmarksState, getBookmarkById);
