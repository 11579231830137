<mat-chip-list
    #chipsList
    [attr.aria-labelledby]="ariaLabelledBy"
    draggable="false"
    [sortablejs]="value"
    [sortablejsContainer]="'.mat-chip-list-wrapper'"
    [sortablejsOptions]="sortablejsOptions"
    (change)="suppressSortablejsChange($event)"
    (click)="onFocus($event)"
>
    <tp-chip
        *ngFor="let tag of value"
        [value]="tag"
        [icon]="'close'"
        [disableRipple]="true"
        (enterPressed)="removeTag(tag)"
        (iconClick)="removeTag(tag)"
        [attr.aria-label]="tag + '. To delete tag press enter or backspace.'"
        [class.red-border]="tag.length > skillMaxLength"
    >
    </tp-chip>
    <input
        type="text"
        matInput
        #autocompleteInput
        #chipListInput="matChipInput"
        [placeholder]="placeholder"
        [formControl]="inputControl"
        [matAutocomplete]="autocomplete"
        [matChipInputFor]="chipsList"
        [matChipInputAddOnBlur]="false"
        (keydown.enter)="onInputEnterPressed($event)"
        (matChipInputTokenEnd)="createTag($event.value)"
        (focus)="onFocus($event)"
        (click)="onFocus($event)"
        (focusout)="onBlur($event)"
        (keydown.backspace)="onBackspace()"
        [attr.aria-labelledby]="ariaLabelledBy"
        (dragstart)="handleInputDragStart($event)"
    />
</mat-chip-list>

<mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="selectAutocompleteOption($event.option.value)">
    <mat-option *ngFor="let tag of suggestions; trackBy: trackOptions" [value]="tag" class="matomo-mask">{{
        tag
    }}</mat-option>
</mat-autocomplete>

<span class="suggest-skills-error" *ngIf="skillsHaveLengthError">{{
    'VALIDATION_SKILLS_ENTRY_MAX_LENGTH' | translatable
}}</span>

<mat-progress-bar class="suggest-progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
