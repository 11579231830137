import { Component, Input, ViewChild } from '@angular/core';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';
import { PopoverDirective } from '@tploy-enterprise/tenant-common';
import { JobXAccountTileLocationsPopoverComponent } from '../job-x-account-tile-locations-popover/job-x-account-tile-locations-popover.component';

export type JobXProfileTileLocationsPopoverData = {
    locations: AccountListItemTag[];
};

@Component({
    selector: 'tp-job-x-account-tile-locations-header-badge',
    templateUrl: './job-x-account-tile-locations-header-badge.component.html',
    styleUrls: ['./job-x-account-tile-locations-header-badge.component.scss'],
})
export class JobXAccountTileLocationsHeaderBadgeComponent {
    @Input()
    locations: AccountListItemTag[];

    @Input()
    badgeText: string;

    popoverContent = JobXAccountTileLocationsPopoverComponent;
    @ViewChild(PopoverDirective) popover: PopoverDirective;

    get popoverData(): JobXProfileTileLocationsPopoverData {
        return {
            locations: this.locations,
        };
    }
}
