<div class="generation-exchange-paginator">
    <div class="bottom-buttons">
        <button
            tpLoader
            #container="loaderContainer"
            [showUntil]="prevButtonClicked && isLoading"
            [disabled]="!paginator.hasPreviousPage()"
            (click)="prev()"
            (keydown.enter)="prev()"
            mat-stroked-button
            color="primary"
            class="call-to-action small"
            [class.loading]="prevButtonClicked && isLoading"
            [class.on-edge-in-list]="prevButtonClicked && isLoading && !paginator.hasPreviousPage()"
        >
            <mat-icon svgIcon="arrow_left_long"></mat-icon>
            {{ 'BACK' | translatable }}
        </button>

        <button
            tpLoader
            #container="loaderContainer"
            [showUntil]="nextButtonClicked && isLoading"
            [disabled]="!paginator.hasNextPage()"
            mat-flat-button
            color="primary"
            (click)="next()"
            (keydown.enter)="next()"
            [class.loading]="nextButtonClicked && isLoading"
            [class.on-edge-in-list]="nextButtonClicked && isLoading && !paginator.hasNextPage()"
        >
            {{ 'PAGINATOR_NEXT' | translatable }}
            <mat-icon svgIcon="arrow_right_long"></mat-icon>
        </button>
    </div>
</div>
