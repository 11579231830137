import { createAction, props } from '@ngrx/store';
import {
    STAApplication,
    STAApplicationIndicators,
    STAApplicationsSelections,
    STAEntity,
    STAProfile,
    STAProfileInvitationMessage,
    STAProfileMatch,
    STAProfileSearchResult,
    STASearchResult,
    StaStaffingFilter,
    STASuggestedSkills,
} from './sta.types';
import { GeneralData, PageParams } from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';

export const STAActions = {
    featureInit: createAction('[STA] Init'),

    loadProfile: createAction('[STA] Load'),
    loadProfileSuccess: createAction('[STA][Success] Load', props<{ profile: STAProfile; session: STAProfile }>()),
    loadProfileError: createAction('[STA][Error] Load', props<{ error: Error; session: STAProfile }>()),

    editCurrentProfile: createAction('[STA] Change edit profile', props<{ currentProfile: Partial<STAProfile> }>()),
    cancelCurrentProfileEdit: createAction('[STA] Cancel edition'),

    saveProfile: createAction('[STA] Save profile'),
    saveProfileSuccess: createAction('[STA][Success] Save profile', props<{ currentProfile: STAProfile }>()),
    saveProfileError: createAction('[STA][Error] Save profile', props<{ currentProfile: STAProfile; error: Error }>()),

    createEntity: createAction('[STA] Create entity', props<{ entity: STAEntity }>()),
    createEntitySuccess: createAction('[STA][Success] Create entity', props<{ entity: STAEntity }>()),
    createEntityError: createAction('[STA][Error] Create entity', props<{ error: Error }>()),

    saveEntity: createAction('[STA] Save entity', props<{ entity: STAEntity; sendNotifications?: boolean }>()),
    saveEntitySuccess: createAction('[STA][Success] Save entity', props<{ entity: STAEntity }>()),
    saveEntityError: createAction('[STA][Error] Save entity', props<{ error: Error }>()),

    deleteEntity: createAction('[STA] Delete entity', props<{ entityId: string }>()),
    deleteEntitySuccess: createAction('[STA][Success] Delete entity', props<{ entityId: string }>()),
    deleteEntityError: createAction('[STA][Error] Delete entity', props<{ error: Error }>()),

    publishEntity: createAction('[STA] Publish entity', props<{ entityId: string }>()),
    publishEntitySuccess: createAction('[STA][Success] Publish entity', props<{ entity: STAEntity }>()),
    publishEntityError: createAction('[STA][Error] Publish entity', props<{ error: Error }>()),

    updateEntity: createAction('[STA] Update entity', props<{ entity: STAEntity }>()),

    searchEntities: createAction(
        '[STA] Search entities',
        props<{ searchQuery: string; pageParams: PageParams; campaigns?: string[] }>(),
    ),
    searchEntitiesSuccess: createAction(
        '[STA][Success] Search entities',
        props<{ entities: STASearchResult[]; pageParams: PageParams }>(),
    ),
    searchEntitiesError: createAction('[STA][Error] Search entities', props<{ error: Error }>()),

    loadEntitiesOfferedByMe: createAction('[STA] Load entities offered by me'),
    loadEntitiesOfferedByMeSuccess: createAction(
        '[STA][Success] Load entities offered by me',
        props<{ entities: STAEntity[] }>(),
    ),
    loadEntitiesOfferedByMeError: createAction('[STA][Error] Load entities offered by me', props<{ error: Error }>()),

    loadMatches: createAction('[STA] Load Matches', props<{ pageParams: PageParams; campaigns?: string[] }>()),
    loadMatchesSuccess: createAction(
        '[STA][Success] Load Matches',
        props<{ entities: STAProfileMatch[]; pageParams: PageParams; selectedCampaigns?: string[] }>(),
    ),
    loadMatchesError: createAction('[STA][Error] Load Matches', props<{ error: Error }>()),

    loadSuggestedSkills: createAction('[STA] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[STA][Success] Load Suggested Skills',
        props<{ suggestedSkills: STASuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[STA][Error] Load Suggested Skills', props<{ error: Error }>()),

    storeSelectedSkills: createAction('[STA] Store selected skills', props<{ selectedSkills: Array<Skill> }>()),

    applyTo: createAction('[STA] Apply to entity', props<{ entityId: string; name: string }>()),
    applyToSuccess: createAction(
        '[STA][Success] Apply to entity',
        props<{ application: STAApplication; matches: STAProfileMatch[]; searchResults: STASearchResult[] }>(),
    ),
    applyToError: createAction('[STA][Error] Apply to entity', props<{ error: Error }>()),

    syncSuccessFactorsSkills: createAction(
        '[STA SuccessFactors SyncSkills][Success] sync',
        props<{ skills: Skill[] }>(),
    ),
    syncProfileSkills: createAction('[STA] Sync profile skills'),

    getOwnerUnseenApplications: createAction('[STA] Get owner unseen applications'),
    getOwnerUnseenApplicationsSuccess: createAction(
        '[STA][Success] Get owner unseen applications',
        props<{ applicationIndicators: STAApplicationIndicators }>(),
    ),
    getOwnerUnseenApplicationsError: createAction(
        '[STA][Error] Get owner unseen applications',
        props<{ error: Error }>(),
    ),

    entityMarkApplicationsAsSeen: createAction('[STA] Entity mark applications as seen', props<{ entityId: string }>()),
    entityMarkApplicationsAsSeenSuccess: createAction(
        '[STA][Success] Entity mark applications as seen',
        props<{ entityId: string }>(),
    ),
    entityMarkApplicationsAsSeenError: createAction(
        '[STA][Error] Entity mark applications as seen',
        props<{ error: Error }>(),
    ),

    topicLinkIndicatorMarkAsSeen: createAction('[STA] Topic link mark applications indicator as seen'),
    topicLinkIndicatorMarkAsSeenSuccess: createAction('[STA][Success] Topic link mark applications indicator as seen'),
    topicLinkIndicatorMarkAsSeenError: createAction(
        '[STA][Error] Topic link mark applications indicator as seen',
        props<{ error: Error }>(),
    ),

    offeringTabIndicatorMarkAsSeen: createAction('[STA] Offering tab mark applications indicator as seen'),
    offeringTabIndicatorMarkAsSeenSuccess: createAction(
        '[STA][Success] Offering tab mark applications indicator as seen',
    ),
    offeringTabIndicatorMarkAsSeenError: createAction(
        '[STA][Error] Offering tab mark applications indicator as seen',
        props<{ error: Error }>(),
    ),

    getApplicationsSelections: createAction('[STA] Get applications selections', props<{ entityId: string }>()),
    getApplicationsSelectionsSuccess: createAction(
        '[STA][Success] Get applications selections',
        props<{ data: STAApplicationsSelections }>(),
    ),
    getApplicationsSelectionsError: createAction('[STA][Error] Get applications selections', props<{ error: Error }>()),
    resetApplicationsSelections: createAction('[STA] Reset applications selections'),
    resetApplicationsSelectionsSuccess: createAction('[STA][Success] Reset applications selections'),

    searchProfiles: createAction('[STA] Search profiles', props<{ staId: string; searchTerms: string[] }>()),
    searchProfilesSuccess: createAction(
        '[STA][Success] Search profiles',
        props<{ profiles: STAProfileSearchResult[] }>(),
    ),
    searchProfilesError: createAction('[STA][Error] Search profiles', props<{ error: Error }>()),
    resetSearchProfilesResults: createAction('[STA] Reset search profiles results'),

    sendInvitationMessage: createAction(
        '[STA] Send invitation message',
        props<{ message: STAProfileInvitationMessage }>(),
    ),
    sendInvitationMessageSuccess: createAction(
        '[STA][Success] Send invitation message',
        props<{ profileId: string }>(),
    ),
    sendInvitationMessageError: createAction('[STA][Error] Send invitation message', props<{ error: Error }>()),

    loadStaffingEntities: createAction(
        '[STA] Load staffing entities',
        props<{ searchQuery: string; pageParams: PageParams; filter: StaStaffingFilter }>(),
    ),
    loadStaffingEntitiesSuccess: createAction(
        '[STA][Success] Load staffing entities',
        props<{ entities: STASearchResult[]; pageParams: PageParams }>(),
    ),
    loadStaffingEntitiesError: createAction('[STA][Error] Load staffing entities', props<{ error: Error }>()),
    updateStaffingFilter: createAction('[STA] Update staffing filter', props<{ filter: StaStaffingFilter }>()),
    getStaffingApplications: createAction('[STA][Staffing] Get applications', props<{ entity: STAEntity }>()),
    getStaffingApplicationsSuccess: createAction(
        '[STA][Staffing][Success] Get applications',
        props<{ data: STAApplicationsSelections; entity: STAEntity }>(),
    ),
    getStaffingApplicationsError: createAction('[STA][Staffing][Error] Get applications', props<{ error: Error }>()),
    resetStaffingApplications: createAction('[STA][Staffing] Reset applications'),

    clearSearchTopicUsers: createAction('[STA][Search] Clear Search Topic Users Results'),
    searchTopicUsers: createAction(
        '[STA][Search] Search Topic Users',
        props<{ searchQuery: string; entityId: string }>(),
    ),
    searchTopicUsersSuccess: createAction(
        '[STA][Search][Success] Search Topic Users',
        props<{ results: Partial<GeneralData>[] }>(),
    ),
    searchTopicUsersError: createAction('[STA][Search][Error] Search Topic Users', props<{ error: Error }>()),
};
