import { createAction, props } from '@ngrx/store';
import { Conversation, ConversationType } from '../models/direct-messages-conversation.types';
import { Contact } from '../models/direct-messages-contact.types';
import { MessageMetadata } from '../models';

export const DirectMessagesCreateConversationActions = {
    // Create Conversation Actions
    createConversationWithForceLoad: createAction(
        '[DM-Conversations] Create Conversation and force load',
        props<{
            payload: {
                participants: Contact[];
                type: ConversationType;
                origin: string;
                originId?: string;
                imageUrl?: string;
                name?: string;
            };
        }>(),
    ),
    createConversationWithMessageAndForceLoad: createAction(
        '[DM-Conversations] Create Conversation with message and force load',
        props<{
            payload: {
                participants: Contact[];
                type: ConversationType;
                origin: string;
                message: { message: string; metadata?: MessageMetadata };
                originId?: string;
                imageUrl?: string;
                name?: string;
            };
        }>(),
    ),
    createConversation: createAction(
        '[DM-Conversations] Create Conversation',
        props<{
            payload: {
                participants: Contact[];
                type: ConversationType;
                origin: string;
                originId?: string;
                imageUrl?: string;
                name?: string;
            };
        }>(),
    ),
    createConversationSuccess: createAction(
        '[DM-Conversations][Success] Create Conversation',
        props<{ conversations: Conversation[] }>(),
    ),
    createConversationError: createAction('[DM-Conversations][Error] Create Conversation', props<{ error: Error }>()),
};
