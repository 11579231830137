import { BaseStorageAdapter } from './base-storage.adapter';
import { Injectable } from '@angular/core';
import { WindowRef } from '../../window-ref/service/window-ref.service';

@Injectable()
export class SessionStorageAdapter extends BaseStorageAdapter {
    constructor(private windowRef: WindowRef) {
        super(windowRef.nativeWindow.sessionStorage);
    }
}
