import { Directive, Input, HostListener, DoCheck } from '@angular/core';
import { SidenavHelpersDirective } from '../sidenav-helpers.directive';

type SidenavToggleMode = 'open' | 'close' | 'both';

@Directive({
    selector: '[tpSidenavToggle]',
    host: {
        class: 'main-layout_sidenav-toggle',
        '[class.main-layout_sidenav-toggle--shown]': 'shown',
        '[class.main-layout_sidenav-toggle--hidden]': '!shown',
    },
})
export class SidenavToggleDirective implements DoCheck {
    @Input()
    tpSidenavToggle: SidenavToggleMode = 'both';
    shown: boolean;

    constructor(private sidenavHelpers: SidenavHelpersDirective) {}

    ngDoCheck() {
        this.toggleVisibility();
    }

    @HostListener('click')
    clickHandler() {
        switch (this.tpSidenavToggle) {
            case 'both':
                this.toggle();
                break;
            case 'open':
                this.open();
                break;
            case 'close':
                this.close();
                break;
        }
    }

    private open() {
        this.sidenavHelpers.open();
    }

    private close() {
        this.sidenavHelpers.close();
    }

    private toggle() {
        this.sidenavHelpers.toggle();
    }

    private toggleVisibility() {
        this.shown =
            this.tpSidenavToggle === 'both' ||
            (this.sidenavHelpers.config.opened && this.tpSidenavToggle === 'close') ||
            (!this.sidenavHelpers.config.opened && this.tpSidenavToggle === 'open');
    }
}
