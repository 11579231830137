import { BirthYearSelectComponent } from './birth-year-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { InfoToggleModule } from '../info-toggle';

@NgModule({
    imports: [CommonModule, MatSelectModule, MatOptionModule, InfoToggleModule],
    declarations: [BirthYearSelectComponent],
    exports: [BirthYearSelectComponent],
})
export class BirthYearSelectModule {}
