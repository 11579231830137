import { PipeTransform, Pipe, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService, TranslationChangeEvent, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageHelpersService } from '../language-helpers.service';
import { Translatable } from './translatable.type';

@Pipe({
    name: 'translatable',
    pure: false,
})
export class TranslatablePipe implements PipeTransform, OnDestroy {
    value = '';
    lastKey: string;
    lastParams: any[];
    onTranslationChangeSub: Subscription;
    onLangChangeSub: Subscription;
    onDefaultLangChangeSub: Subscription;

    constructor(
        private readonly translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly languageHelpers: LanguageHelpersService,
    ) {}

    updateValue(key: string, interpolateParams?: Record<string, any>, translations?: any): void {
        const onTranslation = (res: string): void => {
            this.value = res !== undefined ? res : key;
            this.lastKey = key;
            this.changeDetectorRef.markForCheck();
        };
        if (translations) {
            const res = this.translateService.getParsedResult(translations, key, interpolateParams);
            if (typeof res.subscribe === 'function') {
                res.subscribe(onTranslation);
            } else {
                onTranslation(res);
            }
        }
        this.translateService.get(key, interpolateParams).subscribe(onTranslation);
    }

    transform(translatable: Translatable | string, ...args: unknown[]): any {
        // Unwrap translatable
        let query: string;
        let params: Record<string, any> = typeof args[0] === 'object' ? args[0] : {};

        const defaultParams = {
            appName: this.languageHelpers.appName,
        };

        if (typeof translatable === 'string') {
            query = translatable;
            params = { ...params, ...defaultParams };
        } else if (typeof translatable === 'number') {
            query = `${translatable}`;
            params = { ...params, ...defaultParams };
        } else {
            query = translatable.message;
            params = {
                ...(translatable.messageParams || {}),
                ...params,
                ...defaultParams,
            };
        }

        if (!query || query.length === 0) {
            return query;
        }

        args = [params];

        // if we ask another time for the same key, return the last value
        if (this.equals(query, this.lastKey) && this.equals(args, this.lastParams)) {
            return this.value;
        }

        let interpolateParams: Record<string, any>;
        if (typeof args[0] === 'object' && !Array.isArray(args[0])) {
            interpolateParams = args[0];
        }

        // store the query, in case it changes
        this.lastKey = query;

        // store the params, in case they change
        this.lastParams = args;

        // set the value
        this.updateValue(query, interpolateParams);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this.onTranslationChangeSub) {
            this.onTranslationChangeSub = this.translateService.onTranslationChange.subscribe(
                (event: TranslationChangeEvent) => {
                    if (this.lastKey && event.lang === this.translateService.currentLang) {
                        this.lastKey = null;
                        this.updateValue(query, interpolateParams, event.translations);
                    }
                },
            );
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChangeSub) {
            this.onLangChangeSub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                if (this.lastKey) {
                    // we want to make sure it doesn't return the same value until it's been updated
                    this.lastKey = null;
                    this.updateValue(query, interpolateParams, event.translations);
                }
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this.onDefaultLangChangeSub) {
            this.onDefaultLangChangeSub = this.translateService.onDefaultLangChange.subscribe(() => {
                if (this.lastKey) {
                    // we want to make sure it doesn't return the same value until it's been updated
                    this.lastKey = null;
                    this.updateValue(query, interpolateParams);
                }
            });
        }

        return this.value;
    }

    /**
     * Clean any existing subscription to change events
     */
    private _dispose(): void {
        if (typeof this.onTranslationChangeSub !== 'undefined') {
            this.onTranslationChangeSub.unsubscribe();
            this.onTranslationChangeSub = undefined;
        }
        if (typeof this.onLangChangeSub !== 'undefined') {
            this.onLangChangeSub.unsubscribe();
            this.onLangChangeSub = undefined;
        }
        if (typeof this.onDefaultLangChangeSub !== 'undefined') {
            this.onDefaultLangChangeSub.unsubscribe();
            this.onDefaultLangChangeSub = undefined;
        }
    }

    private equals(o1: any, o2: any): boolean {
        if (o1 === o2) {
            return true;
        }
        if (o1 === null || o2 === null) {
            return false;
        }
        if (o1 !== o1 && o2 !== o2) {
            // NaN === NaN
            return true;
        }
        const t1 = typeof o1;
        const t2 = typeof o2;
        let length: number;
        let key: any;
        let keySet: any;

        if (t1 === t2 && t1 === 'object') {
            if (Array.isArray(o1)) {
                if (!Array.isArray(o2)) {
                    return false;
                }

                length = o1.length;
                if (length === o2.length) {
                    for (key = 0; key < length; key++) {
                        if (!this.equals(o1[key], o2[key])) {
                            return false;
                        }
                    }
                    return true;
                }
            } else {
                if (Array.isArray(o2)) {
                    return false;
                }
                keySet = Object.create(null);
                for (key in o1) {
                    if (Object.prototype.hasOwnProperty.call(o1, key)) {
                        if (!this.equals(o1[key], o2[key])) {
                            return false;
                        }
                        keySet[key] = true;
                    }
                }
                for (key in o2) {
                    if (!(key in keySet) && typeof o2[key] !== 'undefined') {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this._dispose();
    }
}
