import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';
import { Permission } from './authorization.types';
import { AuthorizationService } from './authorization.service';
import { RedirectByPermissionsException } from './redirect-by-permissions.exception';

@Injectable({
    providedIn: 'root',
})
export class RedirectByPermissionsGuard implements CanActivate {
    constructor(private readonly router: Router, private readonly authorizationService: AuthorizationService) {}

    // Redirect to the first satisfiable route provided in the configuration
    canActivate(route: ActivatedRouteSnapshot) {
        const candidates: { route: any[]; permissions: Permission[] }[] = route.data.routeByPermissionConfig;

        if (!candidates) {
            throw new Error(
                "No configuration. You must provide a `routeByPermissionConfig` object in the route's data to use this guard",
            );
        }

        for (const candidate of candidates) {
            if (this.authorizationService.hasPermissions(candidate.permissions)) {
                return this.router.createUrlTree(candidate.route);
            }
        }

        throw new RedirectByPermissionsException();
    }
}
