import { Action, createReducer, on } from '@ngrx/store';
import { WorkshopProfileActions } from './workshop-profile.actions';
import {
    WorkshopProfile,
    WORKSHOP_PROFILE_DATA_STORAGE_KEY,
    WorkshopSuggestedSkills,
} from '../models/workshop-profile.types';
import { AccountActions, SuccessFactorsActions, SuccessFactorsUtilsService } from '@tploy-enterprise/tenant-core';

const successFactorsUtilsService = new SuccessFactorsUtilsService();

export interface WorkshopProfileState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    editing: WorkshopProfile;
    current: WorkshopProfile;
    suggestedSkills: SuggestedSkillsState;
}

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: WorkshopSuggestedSkills;
};

const defaultWorkshopSuggestedSkills = {
    loaded: false,
    loading: false,
    loadingError: null,
    suggestedSkills: {
        aType: [],
        lType: [],
        sType: [],
    },
};

const defaultWorkshopProfile: WorkshopProfile = {
    selectedMotivations: [],
    skills: [],
    skillsInterestedIn: [],
    categoriesInterestedIn: [],
    pushToSuccessFactor: false,
};

function getEditingInitialState(): WorkshopProfile {
    const serialized = window.localStorage.getItem(WORKSHOP_PROFILE_DATA_STORAGE_KEY);
    if (serialized) {
        return JSON.parse(serialized);
    } else return null;
}

const workshopProfileInitialState: WorkshopProfileState = {
    loading: false,
    loaded: false,
    loadingError: null,
    saving: false,
    savingError: null,
    dirty: false,
    editing: getEditingInitialState() ?? defaultWorkshopProfile,
    current: null,
    suggestedSkills: defaultWorkshopSuggestedSkills,
};

const reducer = createReducer(
    workshopProfileInitialState,
    on(WorkshopProfileActions.edit, (state, { workshopProfile }) => ({
        ...state,
        dirty: true,
        editing: { ...state.editing, ...workshopProfile },
    })),
    on(WorkshopProfileActions.editAbort, (state) => ({
        ...state,
        editing: { ...defaultWorkshopProfile, ...state.current },
    })),
    on(WorkshopProfileActions.load, (state) => ({ ...state, loading: true })),
    on(WorkshopProfileActions.loadError, (state, { error }) => ({
        ...state,
        editing: getEditingInitialState() || state.editing,
        loadingError: error,
        loading: false,
        loaded: true,
    })),
    on(WorkshopProfileActions.loadSuccess, (state, { workshopProfile }) => ({
        ...state,
        loaded: true,
        loading: false,
        loadingError: null,
        dirty: false,
        saving: false,
        savingError: null,
        editing: getEditingInitialState() || workshopProfile,
        current: workshopProfile,
    })),
    on(WorkshopProfileActions.save, (state) => ({ ...state, saving: true })),
    on(WorkshopProfileActions.saveSuccess, (state, { workshopProfile }) => ({
        ...state,
        loaded: true,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
        current: {
            ...state.current,
            ...workshopProfile,
        },
        editing: {
            ...state.current,
            ...workshopProfile,
        },
    })),
    on(WorkshopProfileActions.saveError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(WorkshopProfileActions.loadSuggestedSkills, (state) => ({
        ...state,
        suggestedSkills: { ...state.suggestedSkills, loading: true },
    })),
    on(WorkshopProfileActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills: {
            ...state.suggestedSkills,
            suggestedSkills,
            loading: false,
        },
    })),
    on(WorkshopProfileActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        suggestedSkills: {
            ...state.suggestedSkills,
            loadingError: error,
            loading: false,
        },
    })),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        editing: {
            ...state.editing,
            skills:
                state.editing.skills?.length > 0
                    ? state.editing.skills
                    : account.generalData.externalSkills
                    ? account.generalData.externalSkills.map((skill) => ({ name: skill, level: 1 }))
                    : [],
        },
    })),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => {
        const currentProfile = state.current || defaultWorkshopProfile;
        if (state.current?.selectedMotivations?.length > 0) {
            return state;
        } else {
            return {
                ...state,
                editing: {
                    ...state.editing,
                    skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.editing.skills, ...skills]),
                },
                current: {
                    ...currentProfile,
                    skills: successFactorsUtilsService.getSuccessFactorsSkills([...currentProfile.skills, ...skills]),
                },
            };
        }
    }),
    on(WorkshopProfileActions.syncSuccessFactorsSkills, (state, { skills }) => {
        const currentProfile = state.current || defaultWorkshopProfile;
        return {
            ...state,
            editing: {
                ...state.editing,
                skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.editing.skills, ...skills]),
            },
            current: {
                ...currentProfile,
                skills: successFactorsUtilsService.getSuccessFactorsSkills([...currentProfile.skills, ...skills]),
            },
        };
    }),
);

export function workshopProfileReducer(state: WorkshopProfileState, action: Action) {
    return reducer(state, action);
}
