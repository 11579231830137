import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { init, captureException, configureScope, Scope } from '@sentry/browser';
import { LOCAL_TENANT_CONFIGURATION } from '../../tenant-core.di';
import { LocalTenantConfiguration } from '../../tenant-core.types';

const DSN = 'https://fafd271064b94107b98214b981a2eca4@bugtracking.int.tploy.de/43';

@Injectable({
    providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
    constructor(@Inject(LOCAL_TENANT_CONFIGURATION) readonly localTenantConfiguration: LocalTenantConfiguration) {
        this.configure(this.localTenantConfiguration);
    }

    handleError(error) {
        captureException(error.originalError || error);
        console.error(error);
    }

    private configure(config: LocalTenantConfiguration) {
        init({
            dsn: DSN,
            environment: config.environment.environment,
            release: config.environment.release,
            ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
        });

        configureScope((scope: Scope) => {
            scope.setTag('tenant', config.environment.tenant);
        });
    }
}
