<div
    class="details__organizer-image"
    (click)="onAvatarClick($event)"
    [style.background-image]="'url(' + imageSrc + ')'"
    [title]="imageAlt | translatable"
></div>
<ng-content></ng-content>
<div class="details__organizer-wrapper">
    <div class="details__organizer-label">{{ label | translatable }}</div>
    <div class="details__organizer-name">{{ name | translatable }}</div>
</div>
