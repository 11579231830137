<mat-checkbox
    #matCheckbox
    class="list-item-input very-big-checkbox"
    (keydown.space)="toggle()"
    [checked]="selected"
    [disabled]="option.disabled"
    aria-labelledby="{{ matCheckbox.id }}-label"
>
</mat-checkbox>
<label id="{{ matCheckbox.id }}-label">{{ option.label | translatable }}</label>
