import { Component, Inject } from '@angular/core';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';
import { POPOVER_DATA } from '@tploy-enterprise/tenant-common';
import { JobXProfileTileLocationsPopoverData } from '../job-x-account-tile-locations-header-badge/job-x-account-tile-locations-header-badge.component';

@Component({
    selector: 'tp-job-x-account-tile-locations-popover',
    templateUrl: './job-x-account-tile-locations-popover.component.html',
    styleUrls: ['./job-x-account-tile-locations-popover.component.scss'],
})
export class JobXAccountTileLocationsPopoverComponent {
    locations: AccountListItemTag[];

    constructor(@Inject(POPOVER_DATA) public popoverData: JobXProfileTileLocationsPopoverData) {
        this.locations = this.popoverData.locations;
    }
}
