import { createReducer, on } from '@ngrx/store';
import { BookmarksPageActions } from './bookmarks-page.actions';
import { BookmarksPageEntry } from './bookmarks-page.types';
import { BookmarkActions, Bookmark } from '../../core-modules/bookmarks';

export interface BookmarksPageState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
    data: BookmarksPageEntry[];
    length: number;
}
export const bookmarksPageInitialState: BookmarksPageState = {
    loading: false,
    loaded: false,
    loadingError: null,
    data: [],
    length: 0,
};

export const bookmarksPageReducer = createReducer(
    bookmarksPageInitialState,
    on(BookmarksPageActions.load, (state) => ({ ...state, loading: true, loaded: false })),
    on(BookmarksPageActions.loadSuccess, (state, { entriesData }) => ({
        ...state,
        loadingError: null,
        loading: false,
        loaded: true,
        data: entriesData.bookmarks,
        length: entriesData.total,
    })),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => {
        const data = removeBookmark(state.data, bookmark);
        return {
            ...state,
            data,
            length: data.length,
        };
    }),
    on(BookmarksPageActions.loadError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
        loaded: false,
        data: [],
        length: 0,
    })),
);

function removeBookmark(bookmarks: Array<BookmarksPageEntry>, removing: Bookmark): Array<BookmarksPageEntry> {
    const bookmarkIndex = bookmarks.findIndex((bookmark) => bookmark.bookmark?.entityId === removing.entityId);
    if (bookmarkIndex === -1) {
        return bookmarks;
    } else {
        const newBookmarks = [...bookmarks];
        newBookmarks.splice(bookmarkIndex, 1);
        return newBookmarks;
    }
}
