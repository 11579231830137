import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from '../notifications';
import { CriticalErrorSnackComponent } from './critical-error-snack.component';
import { Injectable } from '@angular/core';

@Injectable()
export class CriticalHttpExceptionsInterceptor implements HttpInterceptor {
    constructor(private readonly notificationService: NotificationsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 500) {
                    this.notificationService.open(CriticalErrorSnackComponent);
                }

                return throwError(error);
            }),
        );
    }
}
