import { Bookmark, INTEREST_TYPE, OFFERING_TYPE, PageParams } from '@tploy-enterprise/tenant-core';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export const PROFILE_STORAGE_KEY = 'tploy.mentoring.profile';
export const IS_PROFILE_DIRTY = 'tploy.mentoring.profile.isDirty';

export type MentoringSubMotivation = 'CAREER' | 'EXPERTISE';
export type MentoringMotivation = OFFERING_TYPE | INTEREST_TYPE;

export type MentoringSuggestedSkills = SuggestedSkills;

export enum CommunicationType {
    'IN_PERSON' = 'IN_PERSON',
    'VIRTUAL' = 'VIRTUAL',
    'BOTH' = 'BOTH',
}

export interface MentoringProfile {
    motivations: MentoringMotivation[];
    communicationTypeInterest: CommunicationType;
    skillsInterestedIn: string[];
    motivationInterest: MentoringSubMotivation[];
    communicationTypeOffering: CommunicationType;
    skillsOffering: string[];
    motivationOffering: MentoringSubMotivation[];
    campaignsOffering?: string[];
    campaignsInterest?: string[];
    isMentor: boolean;
    isMentee: boolean;
}

export interface OthersMentoringProfile extends MentoringProfile {
    user: {
        id: string;
        name: string;
        imageUrl: string;
        languages: string[];
        locations: string[];
    };
}

export interface MentoringMatches {
    matches: MentoringMatch[];
    allMatches: MentoringMatch[];
    pageParams: PageParams;
}

export interface MentoringMatch extends MentoringAccountListItem {
    userId: string;
    highlight: MentoringResultHighlight;
    mentor: boolean;
    mentee: boolean;
}

export interface MentoringBookmark extends Bookmark, MentoringAccountListItem {}

export interface MentoringSearchResults {
    results: MentoringSearchResult[];
    allResults: MentoringSearchResult[];
    pageParams: PageParams;
}

export interface MentoringSearchResult extends MentoringAccountListItem {
    userId: string;
    mentor: boolean;
    mentee: boolean;
    highlight: MentoringResultHighlight;
}

export interface MentoringAccountListItem {
    bookmark?: Bookmark;
    profile?: OthersMentoringProfile;
}

export interface MentoringResultHighlight {
    languages: string[];
    skills: string[];
    campaigns: string[];
}

export interface Skill {
    name: string;
}

export enum MentoringContext {
    'MENTORING' = 'mentoring',
    'MENTORING_MENTOR' = 'mentoring_mentor',
    'MENTORING_MENTEE' = 'mentoring_mentee',
}
