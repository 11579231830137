export default {
    NEVER_LUNCH_ALONE: 'Never Lunch Alone',
    NEVER_LUNCH_ALONE_INFO:
        'Du bist neu im Unternehmen und möchtest dich in entspannter Atmosphäre bei einem Kaffee oder bei einem Essen mit Kolleginnen austauschen? Oder du bist schon länger dabei und möchtest neue Kolleginnen aus anderen Abteilungen oder Standorten kennenlernen? Über Tandemploy SaaS findest du interessante Kolleg*innen mit denen du dich unverbindlich treffen kannst.',

    SIGN_UP_NEVER_LUNCH_ALONE_TITLE: 'Never Lunch Alone',
    SIGN_UP_NEVER_LUNCH_ALONE_SUBTITLE: 'Spontan Kolleg*innen zum Mittag oder auf einen Kaffee treffen.',

    NEVER_LUNCH_ALONE_PROFILE_LOCATION_LABEL: 'An welchen Standorten bist du für Lunch Dates verfügbar?',
    NEVER_LUNCH_ALONE_PROFILE_LOCATION_INDEPENDENT_LABEL: 'gerne virtuelles Treffen',
    NEVER_LUNCH_ALONE_PROFILE_TOPICS_LABEL: 'Welche Themen interessieren dich',
    NEVER_LUNCH_ALONE_PROFILE_TOPICS_PLACEHOLDER: 'z.B. Sabbatical, Marketing, Laufgruppe',
    NEVER_LUNCH_ALONE_PROFILE_DISHES_LABEL: 'Last but not least: Was ist dein Lieblingsessen?',
    NEVER_LUNCH_ALONE_PROFILE_DISHES_PLACEHOLDER: 'z.B. Fischbrötchen, Kartoffelpuffer',

    PROFILE_VIEW_NEVER_LUNCH_ALONE_LOCATIONS_LABEL: 'Standort',
    PROFILE_VIEW_NEVER_LUNCH_ALONE_TOPICS_LABEL: 'Themeninteressen',
    PROFILE_VIEW_NEVER_LUNCH_ALONE_DISHES_LABEL: 'Lieblingsessen',

    PROFILE_NEVER_LUNCH_ALONE_LOCATION_INDEPENDENT_LABEL: 'Kolleg*innen, die ich treffen möchte',
    PROFILE_NEVER_LUNCH_ALONE_OPTION_SAME_LOCATION: 'Kolleg*innen von meinem Standort',
    PROFILE_NEVER_LUNCH_ALONE_OPTION_ALL: 'Kolleg*innen von allen Standorten',

    NLA_MATCHES_TAB: 'Matches',
    NLA_BOOKMARKS_TAB: 'Gemerkte Kolleg*innen',
    NLA_SEARCH_TAB: 'Suche',

    NLA_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',
    NLA_NO_MATCH_CONTENT:
        'Bitte gib mehr Fähigkeiten und (versteckte?) Talente in deinem Profil an, um Matches zu finden',
    NLA_NO_MATCH_CTA: 'Bearbeiten',
    NLA_NO_MATCH_FIND_CONTENT: 'Weitere Kolleg*innen über die Suche finden',

    NLA_NO_BOOKMARKS_TITLE: 'Noch kein Profil gemerkt',
    NLA_NO_BOOKMARKS_SUBTITLE: 'Hier siehst du deine gemerkten Kolleg*innen im Bereich Never Lunch Alone.',

    NLA_SEARCH_HEADER: 'Never Lunch Alone - Profile durchsuchen',
    NLA_SEARCH_BTN_TEXT: 'Suchen!',
    NLA_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Eine Suche ohne Suchbegriff zeigt alle Never Lunch Alone profils.',
    NLA_SEARCH_NO_RESULT_IMG_ALT:
        'Das Bild zeigt einen Platzhalter, der anstelle von Ergebnissen erscheint, da die Suche keine Ergebnisse ergab.',

    NLA_ITEM_LOCATION_INDEPENDENT_LABEL: 'auch zum Online-Lunch bereit',
    NLA_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL: 'Match nur für Online-Lunch',

    PAGE_TITLE_NEVER_LUNCH_ALONE_MATCHES: 'Never Lunch Alone - Matches',
    PAGE_TITLE_NEVER_LUNCH_ALONE_BOOKMARKS: 'Never Lunch Alone - Gemerkte Kollegen',
    PAGE_TITLE_NEVER_LUNCH_ALONE_SEARCH: 'Never Lunch Alone - Suche',
};
