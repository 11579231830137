import { RolePermissions } from './authorization.types';
import { UserRole } from '@tandemploy/common';

export const CorePermissions: RolePermissions = {
    [UserRole.USER]: [],
    [UserRole.RESTRICTED_USER]: [],
    [UserRole.CONTRIBUTOR]: [],
    [UserRole.ADMIN]: ['core.admin.view', 'core.admin.emails', 'core.admin.stats', 'core.admin.platform'],
    [UserRole.ADMIN_MAIL]: ['core.admin.view', 'core.admin.emails'],
    [UserRole.STATISTICS]: ['core.admin.view', 'core.admin.stats'],
    [UserRole.STAFFING_MANAGER]: [],
};
