import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobXBookmarksRoutingModule } from './job-x-bookmarks-routing.module';
import { JobXBookmarksComponent } from './job-x-bookmarks/job-x-bookmarks.component';
import { JobXBookmarkComponent } from './job-x-bookmark/job-x-bookmark.component';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { AccountListItemModule, SearchLoaderModule } from '@tploy-enterprise/tenant-core';
import { JobXAccountTileModule } from '../job-x-account-tile/job-x-account-tile.module';

@NgModule({
    declarations: [JobXBookmarksComponent, JobXBookmarkComponent],
    imports: [
        CommonModule,
        SearchLoaderModule,
        JobXBookmarksRoutingModule,
        LanguageModule,
        AccountListItemModule,
        JobXAccountTileModule,
    ],
    exports: [JobXBookmarkComponent],
})
export class JobXBookmarksModule {}
