import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ExpertAvailabilityDTO,
    ExpertMatchResponseDTO,
    ExpertProfileDTO,
    ExpertSearchDTO,
    ExpertSuggestedSkillsDTO,
} from './expert.dtos';
import { Observable } from 'rxjs';
import { EXPERTS_TOPIC_NAME, OffsetLimit } from '@tploy-enterprise/tenant-core';
import { Params } from '@angular/router';

const BASE_PATH = `/api/v2/${EXPERTS_TOPIC_NAME}`;

@Injectable({
    providedIn: 'root',
})
export class ExpertApi {
    constructor(private http: HttpClient) {}

    getProfile(): Observable<ExpertProfileDTO> {
        return this.http.get<ExpertProfileDTO>(`${BASE_PATH}/profiles`, { observe: 'body' });
    }

    getProfileById(id: string): Observable<ExpertProfileDTO> {
        return this.http.get<ExpertProfileDTO>(`${BASE_PATH}/profiles/${id}`, { observe: 'body' });
    }

    putProfile(profile: ExpertProfileDTO): Observable<ExpertProfileDTO> {
        return this.http.put<ExpertProfileDTO>(`${BASE_PATH}/profiles`, profile);
    }

    putUnavailability(availability: ExpertAvailabilityDTO): Observable<ExpertProfileDTO> {
        return this.http.put<ExpertProfileDTO>(`${BASE_PATH}/profiles/unavailibility`, availability, {
            observe: 'body',
        });
    }

    getMatches(offsetLimit: OffsetLimit): Observable<ExpertMatchResponseDTO> {
        const params: Params = { ...offsetLimit };
        return this.http.get<ExpertMatchResponseDTO>(`${BASE_PATH}/matches`, { observe: 'body', params });
    }

    getSearch(searchQuery: string, offsetLimit: OffsetLimit): Observable<ExpertSearchDTO> {
        const params: Params = {
            ...offsetLimit,
        };
        return this.http.get<ExpertSearchDTO>(`${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`, { params });
    }

    getSuggestedSkills(id: string) {
        return this.http.get<ExpertSuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }
}
