import { Injectable, Inject } from '@angular/core';
import { Conversation, ConversationType } from '../models/direct-messages-conversation.types';
import { Observable, of } from 'rxjs';
import { DirectMessage, DirectMessageServerResponse, MessageMetadata } from '../models/direct-messages.types';
import { SocketIOService } from '../../../core-modules/socket.io/socket-io.service';
import { SocketIOClientEvent, SocketIOServerEvent } from '../../../core-modules/socket.io/enum/socket.io-events.enum';
import { Contact } from '../models/direct-messages-contact.types';
import { Store } from '@ngrx/store';
import {
    DirectMessagesJoinConversationActions,
    DirectMessagesLeaveConversationActions,
    DirectMessagesOwnershipPermissionsActions,
    DirectMessagesReceiveMessageActions,
    DirectMessagesUnreadMessagesActions,
    DirectMessagesWritePermissionsActions,
} from '../actions';
import { BrowserNotificationsService } from '../../../core-modules/browser-notifications/browser-notifications.service';
import {
    directMessagesConversationSelector,
    directMessagesCurrentConversationSelector,
} from '../state/direct-messages.selector';
import { first, map, take } from 'rxjs/operators';
import { DirectMessagesState } from '../state/direct-messages.state';
import { ConversationNotFoundException } from '../exceptions/conversation-not-found.exception';
import { DELETED_USER_IMAGE_URL, DELETED_USER_KEY } from '../constants/direct-messages.constants';

@Injectable({
    providedIn: 'root',
})
export class DirectMessagesService {
    constructor(
        private readonly socketIOService: SocketIOService,
        private readonly store: Store<{ directMessages: DirectMessagesState }>,
        private readonly browserNotificationsService: BrowserNotificationsService,
    ) {
        socketIOService.listenTo<{ message: DirectMessage }>(SocketIOClientEvent.RECEIVE_MESSAGE).subscribe((data) => {
            /**
             * TODO: Need to define why backend sent this event twice
             **/
            this.receiveMessage(data);
        });

        socketIOService
            .listenTo<{ conversation: Conversation }>(SocketIOClientEvent.CONVERSATION_JOINED)
            .subscribe((data) => {
                this.joinConversation(data.conversation);
            });

        socketIOService
            .listenTo<{ conversation: Conversation }>(SocketIOClientEvent.CONVERSATION_JOINED_OTHER)
            .subscribe((data) => {
                this.updateGroupConversationAfterParticipantJoined(data.conversation);
            });

        socketIOService
            .listenTo<{ conversationId: string }>(SocketIOClientEvent.CONVERSATION_LEFT)
            .subscribe((data) => {
                this.leaveConversation(data.conversationId);
            });

        socketIOService
            .listenTo<{ conversationId: string; userId: string }>(SocketIOClientEvent.CONVERSATION_LEFT_OTHER)
            .subscribe((data) => {
                this.updateGroupConversationAfterParticipantLeft(data.conversationId, data.userId);
            });

        socketIOService
            .listenTo<{ conversationId: string }>(SocketIOClientEvent.WRITE_PERMISSIONS_ENABLED)
            .subscribe((data) => {
                this.enableWritePermissionsForConversationOthers(data.conversationId);
            });

        socketIOService
            .listenTo<{ conversationId: string }>(SocketIOClientEvent.WRITE_PERMISSIONS_DISABLED)
            .subscribe((data) => {
                this.disableWritePermissionsForConversationOthers(data.conversationId);
            });

        socketIOService
            .listenTo<{ conversationId: string; participantId: string }>(SocketIOClientEvent.PROMOTED_TO_OWNER)
            .subscribe((data) => {
                this.promotedToOwner(data.conversationId, data.participantId);
            });

        socketIOService
            .listenTo<{ conversationId: string; participantId: string }>(SocketIOClientEvent.DEMOTED_FROM_OWNER)
            .subscribe((data) => {
                this.demotedFromOwner(data.conversationId, data.participantId);
            });
    }

    connect(): void {
        this.socketIOService.connect('/api/v2/direct-messages', 80);
    }

    disconnect(): void {
        this.socketIOService.disconnect();
    }

    getUserAvatar(userData: any): string {
        if (!userData || userData?.name === DELETED_USER_KEY) {
            return DELETED_USER_IMAGE_URL;
        } else {
            return userData.imageUrl || DELETED_USER_IMAGE_URL;
        }
    }

    getUserName(userData: any): string {
        if (!userData || userData?.name === DELETED_USER_KEY) {
            return DELETED_USER_KEY;
        } else {
            return userData.name || '';
        }
    }

    getIsUserDeleted(userData: any): boolean {
        return !userData || userData?.name === DELETED_USER_KEY;
    }

    createConversation(payload: {
        participants: Contact[];
        type: ConversationType;
        origin: string;
    }): Observable<DirectMessageServerResponse<Conversation>> {
        return this.socketIOService.emit<Conversation>(SocketIOServerEvent.CREATE_CONVERSATION, payload);
    }

    loadConversations(): Observable<DirectMessageServerResponse<Conversation[]>> {
        return this.socketIOService.emit<Conversation[]>(SocketIOServerEvent.GET_AVAILABLE_CONVERSATIONS);
    }

    loadConversation(conversationId: string): Observable<DirectMessageServerResponse<Conversation>> {
        return this.socketIOService.emit<Conversation>(SocketIOServerEvent.GET_CONVERSATION, { conversationId });
    }

    sendMessage(payload: {
        message: string;
        conversationId: string;
        metadata?: MessageMetadata;
    }): Observable<DirectMessageServerResponse<DirectMessage>> {
        return this.socketIOService.emit<DirectMessage>(SocketIOServerEvent.SEND_MESSAGE, payload);
    }

    loadUnreadMessagesCount(): Observable<DirectMessageServerResponse<{ count: number }>> {
        return this.socketIOService.emit<{ count: number }>(SocketIOServerEvent.GET_UNREAD_MESSAGES_COUNT);
    }

    markMessageAsRead(
        messageId: string,
    ): Observable<DirectMessageServerResponse<{ messageId: string; userId: string }>> {
        return this.socketIOService.emit<{ messageId: string; userId: string }>(
            SocketIOServerEvent.MARK_MESSAGE_AS_READ,
            {
                messageId,
            },
        );
    }

    markMessageAsReadWhenInCurrentConversation(message: DirectMessage): void {
        this.store
            .select(directMessagesCurrentConversationSelector)
            .pipe(take(1))
            .subscribe((currentConversation) => {
                if (currentConversation && message.conversationId === currentConversation.id) {
                    this.store.dispatch(
                        DirectMessagesUnreadMessagesActions.markMessageAsRead({ messageId: message.id }),
                    );
                }
            });
    }

    showMessageNotification(message: DirectMessage): Observable<DirectMessage> {
        if (document.hidden) {
            return new Observable<DirectMessage>((observer) => {
                this.store
                    .select(directMessagesConversationSelector)
                    .pipe(
                        map((conversations) =>
                            conversations.find((conversation) => conversation.id === message.conversationId),
                        ),
                        first(),
                    )
                    .subscribe((conversation) => {
                        if (conversation) {
                            const participant = conversation.participants.find((participant) => {
                                return participant.userId === message.userId;
                            });
                            this.browserNotificationsService
                                .create(participant?.generalData.name, {
                                    body: message.message,
                                    icon: participant?.generalData.imageUrl,
                                })
                                .pipe(first())
                                .subscribe(() => {
                                    observer.next(message);
                                });
                        } else {
                            observer.error(new ConversationNotFoundException());
                        }
                    });
            });
        } else {
            return of(message);
        }
    }

    muteConversation(conversationId: string): Observable<string> {
        return new Observable<string>((observer) => {
            this.socketIOService
                .emit<null>(SocketIOServerEvent.MUTE_CONVERSATION, { conversationId })
                .pipe(take(1))
                .subscribe(() => {
                    observer.next(conversationId);
                });
        });
    }

    unmuteConversation(conversationId: string): Observable<string> {
        return new Observable<string>((observer) => {
            this.socketIOService
                .emit<null>(SocketIOServerEvent.UNMUTE_CONVERSATION, { conversationId })
                .pipe(take(1))
                .subscribe(() => {
                    observer.next(conversationId);
                });
        });
    }

    joinGroupConversation(payload): Observable<DirectMessageServerResponse<Conversation>> {
        return this.socketIOService.emit<Conversation>(SocketIOServerEvent.JOIN_GROUP_CONVERSATION, payload);
    }

    removeFromConversation(
        conversationId: string,
        userId: string,
    ): Observable<DirectMessageServerResponse<{ conversationId: string; userId: string }>> {
        return this.socketIOService.emit<{ conversationId: string; userId: string }>(
            SocketIOServerEvent.LEAVE_CONVERSATION,
            {
                conversationId,
                userId,
                informTargetUser: true,
            },
        );
    }

    enableWritePermissionsForConversation(
        conversationId: string,
    ): Observable<DirectMessageServerResponse<{ conversationId: string }>> {
        return this.socketIOService.emit<{ conversationId: string }>(SocketIOServerEvent.ENABLE_WRITE_PERMISSIONS, {
            conversationId,
        });
    }

    disableWritePermissionsForConversation(
        conversationId: string,
    ): Observable<DirectMessageServerResponse<{ conversationId: string }>> {
        return this.socketIOService.emit<{ conversationId: string }>(SocketIOServerEvent.DISABLE_WRITE_PERMISSIONS, {
            conversationId,
        });
    }

    promoteUserToOwner(
        conversationId: string,
        participantId: string,
    ): Observable<DirectMessageServerResponse<{ conversationId: string; participantId: string }>> {
        return this.socketIOService.emit<{ conversationId: string; participantId: string }>(
            SocketIOServerEvent.PROMOTE_USER_TO_OWNER,
            {
                conversationId,
                participantId,
            },
        );
    }

    demoteUserFromOwner(
        conversationId: string,
        participantId: string,
    ): Observable<DirectMessageServerResponse<{ conversationId: string; participantId: string }>> {
        return this.socketIOService.emit<{ conversationId: string; participantId: string }>(
            SocketIOServerEvent.DEMOTE_USER_FROM_OWNER,
            {
                conversationId,
                participantId,
            },
        );
    }

    private promotedToOwner(conversationId: string, participantId: string): void {
        this.store.dispatch(
            DirectMessagesOwnershipPermissionsActions.promotedToOwner({ conversationId, participantId }),
        );
    }

    private demotedFromOwner(conversationId: string, participantId: string): void {
        this.store.dispatch(
            DirectMessagesOwnershipPermissionsActions.demotedFromOwner({ conversationId, participantId }),
        );
    }

    private enableWritePermissionsForConversationOthers(conversationId: string): void {
        this.store.dispatch(DirectMessagesWritePermissionsActions.enableWritePermissionsOthers({ conversationId }));
    }

    private disableWritePermissionsForConversationOthers(conversationId: string): void {
        this.store.dispatch(DirectMessagesWritePermissionsActions.disableWritePermissionsOthers({ conversationId }));
    }

    private receiveMessage(data: { message: DirectMessage }): void {
        this.store.dispatch(DirectMessagesReceiveMessageActions.receiveMessage({ message: data.message }));
    }

    private joinConversation(conversation: Conversation): void {
        this.store.dispatch(DirectMessagesJoinConversationActions.joinConversationSuccess({ conversation }));
    }

    private updateGroupConversationAfterParticipantJoined(conversation: Conversation): void {
        this.store.dispatch(DirectMessagesJoinConversationActions.joinGroupConversationOther({ conversation }));
    }

    private leaveConversation(conversationId: string): void {
        this.store.dispatch(DirectMessagesLeaveConversationActions.leaveConversation({ conversationId }));
    }

    private updateGroupConversationAfterParticipantLeft(conversationId: string, userId: string): void {
        this.store.dispatch(DirectMessagesLeaveConversationActions.leaveConversationOther({ conversationId, userId }));
    }
}
