import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Bookmark, GeneralData, LocationService } from '@tploy-enterprise/tenant-core';
import { JobXBookmark, JobXTandemCheckProfile, Location } from '../../../jobs.types';
import { Observable } from 'rxjs';

@Component({
    selector: 'tp-job-x-bookmark',
    templateUrl: './job-x-bookmark.component.html',
})
export class JobXBookmarkComponent implements OnChanges {
    @Input() jobXBookmark: JobXBookmark;
    @Input() context: string;
    @Input() ownProfile: JobXTandemCheckProfile;
    locations: string[];

    constructor(private readonly locationService: LocationService) {}

    get user(): Omit<Partial<GeneralData>, 'locations'> & { locations: Array<Location> } {
        return this.jobXBookmark.profile.user;
    }
    get bookmark(): Bookmark {
        return this.jobXBookmark.bookmark;
    }
    get tags(): string[] {
        return this.jobXBookmark.profile.skills.map((skill) => skill.name);
    }
    get selectedTags(): string[] {
        return [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.jobXBookmark) {
            this.locations = this.locationService.getLocationsOrCountry(this.jobXBookmark.profile.locations);
        }
    }
}
