import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FooterLink } from '@tploy-enterprise/tenant-common';
import { Store } from '@ngrx/store';
import { AccountState } from '../../account/account.reducer';
import { TopicSidenavItem } from '../../topic';
import { AuthenticationActions } from '../../authentication/authentication.actions';
import { SelectedTopicsDefinitionsService } from '../../account/selected-topics/selected-topics-definitions.service';
import { DirectMessagesState } from '../../../core-pages/direct-messages/state/direct-messages.state';
import { RouterReducerState } from '@ngrx/router-store';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { TopicsDataService } from '../../topic/topic-data.service';

@Component({
    selector: 'tp-sidenav',
    templateUrl: './sidenav.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        class: 'sidenav',
    },
})
export class SidenavComponent implements OnInit, OnDestroy {
    topicLinks$: Observable<TopicSidenavItem[]>;
    private readonly destroy$ = new Subject<void>();
    canAddMoreTopics$: Observable<boolean>;

    footerLinks: FooterLink[];
    avatar$: Observable<string>;
    unreadMessages$: Observable<string>;
    referenceContext$: Observable<string>;

    constructor(
        private readonly store: Store<{
            account: AccountState;
            directMessages: DirectMessagesState;
            router: RouterReducerState;
        }>,
        private readonly selectedTopicsDefinitions: SelectedTopicsDefinitionsService,
        private readonly topicsDataService: TopicsDataService,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.initializeFooterLinks();
        this.topicLinks$ = combineLatest([
            this.store.select(this.selectedTopicsDefinitions.selected),
            this.store.select((state) => state.account.selectedTopics.edit.data || []),
            this.store.select(this.selectedTopicsDefinitions.staffingManagerTopics),
        ]).pipe(
            map(([topics, selected, staffingManagerTopics]) => {
                const selectedTopics = (topics || []).filter((topic) => selected.includes(topic?.name));
                staffingManagerTopics.forEach((staffingTopic) => {
                    if (!selectedTopics.find((selectedTopic) => selectedTopic.name === staffingTopic.name)) {
                        selectedTopics.unshift(staffingTopic);
                    }
                });
                return selectedTopics;
            }),
            map((topics) =>
                topics.map((topic) => ({
                    referenceContext: topic.name,
                    link: topic.embeddableContent.sidenavItem.link,
                    label: topic.embeddableContent.sidenavItem.label,
                    svgIcon: topic.embeddableContent.sidenavItem.svgIcon,
                    editLink: topic.embeddableContent.sidenavItem.editLink,
                    editPermission: topic.embeddableContent.sidenavItem.editPermission,
                })),
            ),
        );

        this.canAddMoreTopics$ = this.store.select(this.selectedTopicsDefinitions.canAddMoreTopics);
        this.avatar$ = this.store.select((state) => state.account.generalData?.current?.data?.imageUrl || '');
        this.unreadMessages$ = this.store.select('directMessages').pipe(
            map((directMessages) => {
                if (!directMessages || !directMessages.unreadMessages || directMessages.unreadMessages <= 0) {
                    return '';
                } else if (directMessages.unreadMessages <= 9) {
                    return directMessages.unreadMessages.toString();
                } else {
                    return '9+';
                }
            }),
        );

        this.referenceContext$ = this.store.select('router').pipe(
            map((router) => router.state),
            map((routerState) => routerState.url),
            map((url) => this.router.parseUrl(url)),
            map((urlTree) => urlTree.root.children[PRIMARY_OUTLET].segments),
            map((segments) => segments.find((segment) => segment.parameters?.ref)),
            map((segment) => segment?.parameters?.ref || ''),
        );
        this.checkTopicNotifications();
    }

    initializeFooterLinks(): void {
        this.footerLinks = [
            { label: 'HELP_CONTACT', routerLink: ['/', { outlets: { overlay: ['footer', 'help'] } }] },
            { label: 'INTRODUCTION_FOOTER', routerLink: ['/', { outlets: { overlay: ['footer', 'preview'] } }] },
            { label: 'PRIVACY', routerLink: ['/', { outlets: { overlay: ['footer', 'privacy'] } }] },
            { label: 'T_C', routerLink: ['/', { outlets: { overlay: ['footer', 'terms'] } }] },
            { label: 'LEGAL', routerLink: ['/', { outlets: { overlay: ['footer', 'imprint'] } }] },
            { label: 'RELEASE_NOTES', routerLink: ['/', { outlets: { overlay: ['release-notes'] } }] },
        ];
    }

    signOut() {
        this.store.dispatch(AuthenticationActions.signOut());
    }

    private checkTopicNotifications(): void {
        this.store
            .select((state) => state.account.selectedTopics.current.data)
            .pipe(
                takeUntil(this.destroy$),
                map((topics) => this.topicsDataService.getTopicDefinitionsWithNotification(topics)),
                map((topicsWithNotification) =>
                    topicsWithNotification.map((topicDefinition) =>
                        topicDefinition.eventHandlers.onCheckNotification(),
                    ),
                ),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
