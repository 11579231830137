import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-actions',
    templateUrl: './card-actions.component.html',
    host: {
        class: 'card__actions',
    },
})
export class CardActionsComponent {}
