import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LanguageOptions } from '../../language.types';

interface LocaleItem {
    locale: LanguageOptions;
    selected: boolean;
    label: string;
    ariaLabel: string;
}

@Component({
    selector: 'tp-language-switcher-view',
    templateUrl: './language-switcher-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherViewComponent implements OnChanges {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    @Output()
    switch = new EventEmitter<LanguageOptions>();

    @Input()
    current: LanguageOptions = 'de';

    @Input()
    locales: LanguageOptions[] = ['en', 'de'];

    localeItems: LocaleItem[];

    ngOnChanges() {
        this.localeItems = this.locales.map((locale) => {
            const capitalized = locale.toUpperCase();
            return {
                locale,
                selected: this.current === locale,
                label: capitalized,
                ariaLabel: `CHANGE_LANGUAGE_TO_${capitalized}`,
            };
        });
    }

    doSwitch($event: LanguageOptions) {
        if ($event === this.current) {
            return;
        }

        this.switch.emit($event);
        this.document.documentElement.lang = $event;
    }
}
