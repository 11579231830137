<h2 class="success-factor-overlay--title" mat-dialog-title>
    {{ 'SUCCESS_FACTOR_SKILLS_OVERLAY_TITLE' | translatable }}
</h2>

<mat-dialog-content>
    <p class="success-factor-overlay--description">{{ 'SUCCESS_FACTOR_SKILLS_OVERLAY_DESCRIPTION' | translatable }}</p>
</mat-dialog-content>

<mat-dialog-content *ngIf="data.newSkills?.length > 0">
    <p class="success-factor-overlay--label">{{ 'SUCCESS_FACTOR_NEW_SKILLS_LABEL' | translatable }}</p>
    <div class="success-factor-overlay--skills-area">
        <p class="success-factor-overlay--skill" *ngFor="let skill of data.newSkills">
            <span *ngIf="!skill.level">{{ skill.name }}</span>
            <span *ngIf="skill.level">
                {{ skill.name }}
                <span *ngIf="skill.level"
                    >(<span [innerHtml]="{ message: getLevel(skill.level) } | translatable"></span>)</span
                >
            </span>
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="data.changedLevelSkills?.length > 0">
    <p class="success-factor-overlay--label">{{ 'SUCCESS_FACTOR_ORIGINAL_SKILLS_LABEL' | translatable }}</p>
    <div class="success-factor-overlay--skills-area">
        <p class="success-factor-overlay--skill" *ngFor="let skill of data.changedLevelSkills">
            <span *ngIf="!skill.level">{{ skill.name }}</span>
            <span *ngIf="skill.level">
                {{ skill.name }}
                <span *ngIf="skill.level"
                    >(<span [innerHtml]="{ message: getLevel(skill.level) } | translatable"></span>)</span
                >
            </span>
        </p>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="form-footer form-footer--buttons-center form-footer--ignore-sibling-padding">
    <nav>
        <tp-secondary-button mat-dialog-close>
            {{ 'CLOSE' | translatable }}
        </tp-secondary-button>
    </nav>
</mat-dialog-actions>
