import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormFieldDirective } from './form-field.directive';

@NgModule({
    imports: [MatFormFieldModule],
    declarations: [FormFieldDirective],
    exports: [MatFormFieldModule, FormFieldDirective],
})
export class FormFieldModule {}
