import { combineReducers, createReducer, on } from '@ngrx/store';
import { MyWorkshopsActions } from './my-workshops.actions';
import { Workshop } from '../models/workshop.types';
import { Account, OthersAccount } from '@tploy-enterprise/tenant-core';

interface StatusState {
    loading: boolean;
    loaded: boolean;
    error: Error;
}

const initialRegisteredWorkshopsStatusState: StatusState = {
    loading: false,
    loaded: false,
    error: null,
};

const registeredStatusReducer = createReducer(
    initialRegisteredWorkshopsStatusState,
    on(MyWorkshopsActions.loadRegistered, (state) => ({ ...state, loading: true })),
    on(MyWorkshopsActions.loadRegisteredError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
    on(MyWorkshopsActions.loadRegisteredSuccess, (state) => ({ ...state, error: null, loading: false, loaded: true })),
);

const registeredWorkshopsReducer = createReducer(
    [],
    on(MyWorkshopsActions.loadRegisteredSuccess, (state, { data }) => data),
);

const registeredWorkshopsStateReducer = combineReducers({
    status: registeredStatusReducer,
    workshops: registeredWorkshopsReducer,
});

const initialRequestedWorkshopsStatusState: StatusState = {
    loading: false,
    loaded: false,
    error: null,
};

const requestedWorkshopsStatusReducer = createReducer(
    initialRequestedWorkshopsStatusState,
    on(MyWorkshopsActions.loadRequested, (state) => ({ ...state, loading: true })),
    on(MyWorkshopsActions.loadRequestedError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
    on(MyWorkshopsActions.loadRequestedSuccess, (state) => ({ ...state, error: null, loading: false, loaded: true })),
);

const requestedWorkshopsReducer = createReducer(
    [],
    on(MyWorkshopsActions.loadRequestedSuccess, (state, { data }) => data),
);

const requestedWorkshopsStateReducer = combineReducers({
    status: requestedWorkshopsStatusReducer,
    workshops: requestedWorkshopsReducer,
});

export interface MyWorkshopsItemState {
    status: StatusState;
    workshops: [];
}

export interface MyCurrentWorkshopState {
    status: StatusState;
    workshop: null;
}

export interface MyWorkshopsState {
    registered: MyWorkshopsItemState;
    requested: MyWorkshopsItemState;
    details: MyCurrentWorkshopState;
}

export const myWorkshopsReducer = combineReducers({
    registered: registeredWorkshopsStateReducer,
    requested: requestedWorkshopsStateReducer,
});

export interface MyWorkshop extends Workshop {
    account?: Account | OthersAccount;
}
