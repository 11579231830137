import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../config';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(private readonly configService: ConfigService, private readonly translateService: TranslateService) {}

    public getLocationsOrCountry(locations: string[]): string[] {
        const locationsCloud = this.configService.availableLocations;
        return this.getLocationsTranslations(
            locations.filter((location: string) => {
                const locationArea = locationsCloud.findParent(location);
                return locations.indexOf(locationArea?.name) === -1;
            }),
        );
    }

    public getCountry(location: string): string | undefined {
        const locationsCloud = this.configService.availableLocations;
        if (locationsCloud.locateNode(location)?.showAsParent) {
            return location;
        }
        return locationsCloud?.findParent(location)?.originalKey;
    }

    getLocationsTranslations(locations: string[]): string[] {
        return locations.map((location) => this.getLocationTranslation(location));
    }

    getLocationTranslation(location: string): string {
        const locationsCloud = this.configService.availableLocations;
        if (locationsCloud.locateNode(location)?.translationKey) {
            return this.translateService.instant(locationsCloud.locateNode(location).translationKey);
        }
        return location;
    }
}
