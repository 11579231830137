<h1 mat-dialog-title>
    {{ headerLabel | translatable }}
</h1>
<mat-dialog-content class="info-toggle-dialog__content">
    <mat-icon class="info-toggle-dialog__icon" svgIcon="info_filled"></mat-icon>
    <p [innerHTML]="data.translatable | translatable"></p>
</mat-dialog-content>
<mat-dialog-actions class="form-footer form-footer--buttons-center">
    <nav>
        <tp-secondary-button [mat-dialog-close]="true">
            {{ 'CLOSE' | translatable }}
        </tp-secondary-button>
    </nav>
</mat-dialog-actions>
