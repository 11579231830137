import { NgModule } from '@angular/core';
import { InfoToggleComponent } from './info-toggle.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '../language';
import { ButtonModule } from '@tandemploy/ngx-components';
import { InfoToggleDialogComponent } from './info-toggle-dialog/info-toggle-dialog.component';

@NgModule({
    declarations: [InfoToggleComponent, InfoToggleDialogComponent],
    exports: [InfoToggleComponent, InfoToggleDialogComponent],
    imports: [MatIconModule, ButtonModule, CommonModule, MatDialogModule, LanguageModule, MatButtonModule],
})
export class InfoToggleModule {}
