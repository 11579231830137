<ng-template #noMatches>
    <tp-nla-matches-placeholder></tp-nla-matches-placeholder>
</ng-template>

<ng-template #loadingMatches>
    <tp-matching-loader></tp-matching-loader>
</ng-template>

<ng-container *ngIf="!isLoadingFirstly; else loadingMatches">
    <ng-container *ngIf="hasMatches; else noMatches">
        <tp-matches-slider-view
            [hidePaginator]="hidePaginator"
            [matchesTotal]="matchesTotal"
            [pageSize]="pageSize"
            [isLoading]="showLoader"
            (pageChange)="onPageEvent($event)"
        >
            <tp-nla-match *ngFor="let match of matches" [match]="match" class="slider-tiles__tile"></tp-nla-match>
        </tp-matches-slider-view>
    </ng-container>
</ng-container>
