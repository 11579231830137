import { NgModule, Injector } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
    AuthenticatedGuard,
    StaticTopicDefinition,
    TopicEventHandlers,
    TopicDefinition,
    TopicRegistryService,
    AuthorizationModule,
    SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { StaProfileViewModule } from './sta-profile/sta-profile-view.module';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { StaProfileViewComponent } from './sta-profile/sta-profile-view/sta-profile-view.component';
import { StaTopicEventHandlerService } from './sta-topic-event-handler.service';
import { StoreModule } from '@ngrx/store';
import { STAReducer } from './sta.reducer';
import { EffectsModule } from '@ngrx/effects';
import { STAEffects } from './sta.effects';
import { UserRole } from '@tandemploy/common';
import { StaApplicantsIndicatorViewModule } from './sta-embedable/sta-applicants-indicator-view/sta-applicants-indicator-view.module';
import { StaApplicantsIndicatorViewComponent } from './sta-embedable/sta-applicants-indicator-view/sta-applicants-indicator-view.component';

const lazyRoutes: Routes = [
    {
        path: 'short-time-assignments',
        loadChildren: () => import('./sta-domain/sta-domain.module').then((m) => m.StaDomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'short-time-assignments',
        loadChildren: () => import('./sta-entity/sta-entity.module').then((m) => m.StaEntityModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/short-time-assignments',
        loadChildren: () => import('./sta-profile/sta-profile-edit.module').then((m) => m.StaProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/short-time-assignments',
        loadChildren: () => import('./sta-profile/sta-profile-add.module').then((m) => m.StaProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/short-time-assignments',
        loadChildren: () => import('./sta-profile/sta-profile-sign-up.module').then((m) => m.StaProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
];

const staDefinition: StaticTopicDefinition = {
    name: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: StaProfileViewComponent,
            permission: 'sta.entity.apply_to',
        },
        sidenavItem: {
            link: ['/short-time-assignments'],
            editPermission: 'sta.entity.apply_to',
            editLink: ['/', { outlets: { overlay: ['edit', 'short-time-assignments'] } }],
            label: 'SHORT_TIME_ASSIGNMENT',
            svgIcon: 'short_time_assignment',
            referenceContext: 'sta',
        },
        applicantsIndicatorView: {
            component: StaApplicantsIndicatorViewComponent,
        },
        modelInformation: {
            svgIcon: 'short_time_assignment',
            image: '/assets/images/topics/short-time-assignments.jpg',
            label: 'SHORT_TIME_ASSIGNMENT',
            content: 'SHORT_TIME_ASSIGNMENT_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['/sign-up', 'short-time-assignments'],
        signUpSequence: [
            ['/', 'sign-up', 'short-time-assignments', 'motivation'],
            ['/', 'sign-up', 'short-time-assignments', 'interest'],
        ],
        edition: ['/', { outlets: { overlay: ['edit', 'short-time-assignments'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'short-time-assignments'] } }],
        addSequence: [
            ['/', { outlets: { overlay: ['add-topic', 'short-time-assignments', 'motivation'] } }],
            ['/', { outlets: { overlay: ['add-topic', 'short-time-assignments', 'interest'] } }],
        ],
        domain: ['short-time-assignments'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['sta.entity.apply_to'],
        [UserRole.USER]: ['sta.entity.organize', 'sta.entity.apply_to', 'sta.entity.administrate'],
        [UserRole.CONTRIBUTOR]: ['sta.entity.organize', 'sta.entity.administrate'],
        [UserRole.ADMIN]: ['sta.entity.organize', 'sta.entity.apply_to', 'sta.entity.administrate'],
        [UserRole.ADMIN_MAIL]: ['sta.entity.organize', 'sta.entity.apply_to', 'sta.entity.administrate'],
        [UserRole.STATISTICS]: ['sta.entity.organize', 'sta.entity.apply_to', 'sta.entity.administrate'],
        [UserRole.STAFFING_MANAGER]: [
            'sta.entity.organize',
            'sta.entity.apply_to',
            'sta.entity.administrate',
            'sta.entity.manage_staffing',
        ],
    },
    staffingManager: true,
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        StaProfileViewModule,
        StaApplicantsIndicatorViewModule,
        StoreModule.forFeature('shortTimeAssignments', STAReducer),
        EffectsModule.forFeature([STAEffects]),
        AuthorizationModule.forFeature({ permissions: staDefinition.rolePermissions }),
    ],
})
export class ShortTimeAssignmentFeatureModule {
    constructor(private readonly injector: Injector) {
        const eventHandlers = injector.get(StaTopicEventHandlerService);
        this.registerSTATopic(eventHandlers);
    }

    private registerSTATopic(eventHandler: TopicEventHandlers) {
        const topicRegistry = this.injector.get(TopicRegistryService);

        const definition: TopicDefinition = {
            ...staDefinition,
            eventHandlers: eventHandler,
        };

        topicRegistry.registerTopic(definition);
    }
}
