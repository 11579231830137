import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AccountListItemBase } from './account-list-item-base';

@Component({
    selector: 'tp-account-list-item',
    templateUrl: './account-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AccountListItemComponent extends AccountListItemBase {
    constructor(protected readonly injector: Injector) {
        super(injector);
    }
}
