import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CollapsableDirective } from './collapsable.directive';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Directive({
    selector: '[tpCollapsableContent]',
    exportAs: 'collapsableContentContainer',
})
export class CollapsableContentDirective implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
    @Input() dynamicContent = false;
    @Input() paddingTop = 16;
    @Input() paddingBottom = 16;
    @Input() expanded = false;

    @HostBinding('class.collapsable__content') class = true;
    @HostBinding('class.content-hidden') isHidden = true;
    @HostBinding('class.content-shown') isShown = false;
    @HostBinding('attr.aria-hidden') ariaHidden = true;
    @HostBinding('attr.role') role = 'region';

    private maxHeight = 0;
    private contentHeight: number;

    constructor(
        private readonly collapsableDirective: CollapsableDirective,
        private readonly elementRef: ElementRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly animationBuilder: AnimationBuilder,
    ) {}

    ngOnInit() {
        this.collapsableDirective.setContent(this);
    }

    ngAfterViewInit() {
        this.getContentHeight();
        this.initDefaultState();
    }

    ngOnDestroy() {
        this.collapsableDirective.removeContent();
    }

    initDefaultState() {
        if (this.expanded) {
            this.show();
        } else {
            this.hide(true);
        }
    }

    show() {
        this.isShown = true;
        this.isHidden = false;
        this.ariaHidden = false;

        this.setMaxHeight();
        this.playUnCollapse();
    }

    hide(initial = false) {
        this.isShown = false;
        this.isHidden = true;
        this.ariaHidden = true;

        this.playCollapse(initial);
    }

    checkContent() {
        this.getContentHeight();
    }

    @HostListener('window:resize')
    getContentHeight() {
        const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
        const well = element.querySelector('.well');
        this.contentHeight = well.clientHeight;

        if (this.isShown) {
            this.setMaxHeight();
        }
    }

    setMaxHeight() {
        this.maxHeight = this.contentHeight + this.paddingTop + this.paddingBottom;
    }

    private playCollapse(initial: boolean) {
        const timing = initial ? 0 : '300ms ease-in-out';

        const collapseMetaData = [
            animate(
                timing,
                style({
                    visibility: 'hidden',
                    'max-height': '0',
                }),
            ),
        ];

        const collapseFactory = this.animationBuilder.build(collapseMetaData);
        const collapsePlayer = collapseFactory.create(this.elementRef.nativeElement);
        collapsePlayer.play();
    }

    private playUnCollapse() {
        const unCollapseMetaData = [
            animate(
                '300ms ease-in-out',
                style({
                    visibility: 'visible',
                    'max-height': `${this.maxHeight}px`,
                }),
            ),
        ];

        const unCollapseFactory = this.animationBuilder.build(unCollapseMetaData);
        const unCollapsePlayer = unCollapseFactory.create(this.elementRef.nativeElement);
        unCollapsePlayer.play();
    }

    ngAfterContentChecked(): void {
        this.processDynamicContent();
    }

    private processDynamicContent() {
        if (!this.dynamicContent || !this.isShown) {
            return;
        }

        const beforeContentHeight = this.contentHeight;
        this.checkContent();
        const afterContentHeight = this.contentHeight;

        if (beforeContentHeight != afterContentHeight) {
            this.playContentLoad(beforeContentHeight, afterContentHeight);
        }
    }

    private playContentLoad(beforeHeight: number, afterHeight: number) {
        const contentLoadMetaData = [
            style({ 'max-height': `${beforeHeight}px` }),
            animate(
                '300ms ease-in-out',
                style({
                    'max-height': `${afterHeight}px`,
                }),
            ),
        ];

        const contentLoadFactory = this.animationBuilder.build(contentLoadMetaData);
        const contentLoadPlayer = contentLoadFactory.create(this.elementRef.nativeElement);
        contentLoadPlayer.play();
    }
}
