import { createAction, props } from '@ngrx/store';
import { RoutingSequence, NavigateCommand } from './routing-sequence.types';

export const RoutingSequenceActions = {
    start: createAction('[Routing Sequence] Start', props<{ sequence: RoutingSequence }>()),
    abort: createAction('[Routing Sequence] Abort', props<{ command: NavigateCommand }>()),
    aborted: createAction('[Routing Sequence] Aborted'),
    navigateToNext: createAction('[Routing Sequence] Navigate to next'),
    navigateToPrevious: createAction('[Routing Sequence] Navigate to previous'),
    navigate: createAction('[Routing Sequence] navigate', props<{ command: NavigateCommand }>()),
    navigateError: createAction('[Routing Sequence][Error] Navigate', props<{ step: number }>()),
    navigated: createAction('[Routing Sequence] Navigated', props<{ step: number }>()),
    complete: createAction('[Routing Sequence] Complete', props<{ command: NavigateCommand }>()),
    completed: createAction('[Routing Sequence] Completed'),
};
