import { ProjectApplication, ProjectsEntity } from '../projects.types';
import { Injectable } from '@angular/core';
import { Skill } from '@tploy-enterprise/tenant-common';
import { TopicEntityStatus } from '@tandemploy/common';

@Injectable({
    providedIn: 'root',
})
export class ProjectsUtilsService {
    getApplicantSkills(application: ProjectApplication): Skill[] {
        const highlightExactSkills = application.highlightedExactSkills ?? [];
        return (application.selectedSkills ?? []).map((selectedSkill) => {
            const exactHighlight = highlightExactSkills.find(
                (exactHighlight) => selectedSkill.name == exactHighlight.name,
            );
            if (exactHighlight) {
                return {
                    ...selectedSkill,
                    ...exactHighlight,
                };
            }
            return selectedSkill;
        });
    }

    getApplicantCollapsedSkills(application: ProjectApplication): Skill[] {
        const exactSkills = this.getApplicantSkills(application);
        return []
            .concat(application.highlightedExactNotSelectedSkills ?? [], application.highlightedByRelatedSkills ?? [])
            .filter(
                (skill) =>
                    !exactSkills.some(
                        (exactSkill) =>
                            exactSkill.name === skill.name ||
                            (skill?.related || []).some((name) => name === exactSkill.name),
                    ),
            );
    }

    getApplicantSkillRelations(application: ProjectApplication): Skill[][] {
        return this.getApplicantSkills(application).map((skill) => []);
    }

    getApplicantCollapsedSkillRelations(application: ProjectApplication): Skill[][] {
        return this.getApplicantCollapsedSkills(application).map((skill) => {
            return (skill?.related || []).map((name) => ({ name }));
        });
    }

    public getProjectStatus = (project: ProjectsEntity): TopicEntityStatus => {
        project.published = project.published === undefined || project.published;
        if (
            !project.finishedAt &&
            (!project.published || (project.startDate && new Date(project.startDate).getTime() > new Date().getTime()))
        ) {
            return TopicEntityStatus.PLANNED;
        }

        if (
            project.published &&
            !project.finishedAt &&
            (!project.startDate || new Date(project.startDate).getTime() < new Date().getTime()) &&
            (!project.endDate || new Date(project.endDate).getTime() >= new Date().getTime())
        ) {
            return TopicEntityStatus.RUNNING;
        }

        if (
            project.finishedAt ||
            (project.published && project.endDate && new Date(project.endDate).getTime() < new Date().getTime())
        ) {
            return TopicEntityStatus.COMPLETED;
        }
    };
}
