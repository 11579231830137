<tp-job-x-tandem-check-row>
    <div class="job-x__col--flex" own-profile-value>
        <div
            class="job-x__tandem-check__avatar"
            [style.background-image]="'url(' + ownProfile.user.generalData.imageUrl + ')'"
        ></div>
        <h2 class="job-x__tandem-check__name">{{ ownProfile.user.generalData.name }}</h2>
        <div class="job-x__tandem-check__profile-message" [innerHTML]="ownProfile.user.generalData.message"></div>
    </div>
    <div class="job-x__col--flex" target-profile-value>
        <div
            class="job-x__tandem-check__avatar"
            [style.background-image]="'url(' + targetProfile.user.imageUrl + ')'"
        ></div>
        <h2 class="job-x__tandem-check__name">{{ targetProfile.user.name }}</h2>
        <div class="job-x__tandem-check__profile-message" [innerHTML]="targetProfile.user.message"></div>
    </div>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_BIRTH_YEAR' | translatable"
    [ownProfileValue]="getYearOfBirth(ownProfile.user.generalData)"
    [targetProfileValue]="getYearOfBirth(targetProfile.user)"
    *ngIf="ownProfile.user?.generalData?.yearOfBirth || targetProfile.user?.yearOfBirth"
    class="job-x__tandem-check--large"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_LANGUAGES' | translatable"
    [ownProfileValue]="getLanguages(ownProfile.user.generalData.languages)"
    [targetProfileValue]="getLanguages(targetProfile.user.languages)"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_SPLIT_HOURS' | translatable"
    [ownProfileValue]="getSplitHoursString(ownProfile.splitHours)"
    [targetProfileValue]="getSplitHoursString(targetProfile.splitHours)"
    class="job-x__tandem-check--large"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_SKILLS' | translatable"
    [ownProfileValue]="getSkills(ownProfile.skills)"
    [targetProfileValue]="getSkills(targetProfile.skills)"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_LOCATIONS' | translatable"
    [ownProfileValue]="getLocations(ownLocations)"
    [targetProfileValue]="getLocations(targetLocations)"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_EDUCATION' | translatable"
    [ownProfileValue]="getEducation(ownProfile.user.experience?.education)"
    [targetProfileValue]="getEducation(targetProfile.user.experience?.education)"
>
</tp-job-x-tandem-check-row>
<tp-job-x-tandem-check-row
    [title]="'JOBX_TANDEM_CHECK_EXPERIENCE' | translatable"
    [ownProfileValue]="getExperience(ownProfile.user.experience?.positions)"
    [targetProfileValue]="getExperience(targetProfile.user.experience?.positions)"
>
</tp-job-x-tandem-check-row>
