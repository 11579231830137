import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, withLatestFrom, tap, take, filter } from 'rxjs/operators';
import { of, EMPTY, forkJoin } from 'rxjs';
import { ExpertService } from '../expert-api/expert.service';
import { ExpertActions } from './topic-expert.actions';
import { TopicExpertState, LS_KEY_EXPERT_EDIT, IS_PROFILE_DIRTY, ExpertProfileEditState } from './topic-expert.reducer';
import { Store } from '@ngrx/store';
import {
    AccountState,
    SelectedTopicsActions,
    AccountActions,
    StorageService,
    SuccessFactorsActions,
    SuccessFactorsSkillsState,
} from '@tploy-enterprise/tenant-core';
import { isExpertProfileInDirtyState, selectCurrentExpertProfile, selectEditExpertProfile } from './expert.selectors';
import { ExpertProfile } from '../expert-api/expert.types';

@Injectable({
    providedIn: 'root',
})
export class TopicExpertEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly expertService: ExpertService,
        private readonly store: Store<{
            expert: TopicExpertState;
            account: AccountState;
            successFactors: SuccessFactorsSkillsState;
        }>,
        private readonly storage: StorageService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.load),
            switchMap((action) =>
                this.expertService.getProfile().pipe(
                    map((expertData) => ExpertActions.loadSuccess({ expertData })),
                    catchError((error) => of(ExpertActions.loadError({ error }))),
                ),
            ),
        ),
    );

    syncSuccessFactorsSkills$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SuccessFactorsActions.syncSuccessFactorsSkills),
                withLatestFrom(this.store.select((state) => state.successFactors.topicsForSync)),
                switchMap(([{ skills }, topics]) => {
                    if (topics.includes('experts')) {
                        this.store.dispatch(ExpertActions.syncSuccessFactorsSkills({ skills }));
                    }
                    return [];
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    storeChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ExpertActions.edit),
                withLatestFrom(this.store.select((state) => state.expert.edit)),
                tap(([action, editState]) => this.storeEditionState(editState)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    // Only maps the generic cancelEdit action to another action that provides the current state
    // necessary to reset the edit state back to the latest saved state
    resetEditState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.cancelEdit, AccountActions.createSuccess, SelectedTopicsActions.cancelEdit),
            withLatestFrom(this.store.select((state) => state.expert.current.data)),
            map(([action, expertData]) => ExpertActions.resetEditState({ expertData })),
        ),
    );

    deleteSession$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ExpertActions.cancelEdit, ExpertActions.saveSuccess),
                tap(() => this.deleteEditionState()),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.save),
            // ignore the expert profile's non dirty state
            switchMap(() => this.store.select(isExpertProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentExpertProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.expertService.putProfile(currentProfile, general).pipe(
                    map((savedProfile) => ExpertActions.saveSuccess({ expertData: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(ExpertActions.saveError({ error, expertData: currentProfile }));
                    }),
                ),
            ),
        ),
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.expertService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return ExpertActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        return of(ExpertActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    syncProfileSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.syncProfileSkills),
            withLatestFrom(this.store.select(selectEditExpertProfile)),
            switchMap(([action, currentProfile]) => {
                return of(AccountActions.sync({ skills: currentProfile.skillsOffering }));
            }),
        ),
    );

    public storeEditionState(state: ExpertProfileEditState): void {
        this.storage.local.set(LS_KEY_EXPERT_EDIT, state);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): ExpertProfile {
        const expertProfileEditState = this.storage.local.get<ExpertProfileEditState, null>(LS_KEY_EXPERT_EDIT);
        return expertProfileEditState?.data;
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    public deleteEditionState() {
        this.storage.local.remove(LS_KEY_EXPERT_EDIT);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
