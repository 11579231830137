import { NgModule } from '@angular/core';
import { JobXAccountTileComponent } from './job-x-account-tile.component';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';
import { JobXAccountTileLocationsHeaderComponent } from './job-x-account-tile-locations-header/job-x-account-tile-locations-header.component';
import { MatIconModule } from '@angular/material/icon';
import { JobXAccountTileLocationsHeaderBadgeComponent } from './job-x-account-tile-locations-header-badge/job-x-account-tile-locations-header-badge.component';
import { JobXAccountTileLocationsPopoverComponent } from './job-x-account-tile-locations-popover/job-x-account-tile-locations-popover.component';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@tandemploy/ngx-components';
import { CampaignsModule, LanguageModule, PopoverModule } from '@tploy-enterprise/tenant-common';
import { MatButtonModule } from '@angular/material/button';
import { JobXTandemDialogComponent } from './job-x-tandem-dialog/job-x-tandem-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { JobXTandemCheckGridComponent } from './job-x-tandem-dialog/job-x-tandem-check-grid/job-x-tandem-check-grid.component';
import { JobXTandemCheckRowComponent } from './job-x-tandem-dialog/job-x-tandem-check-row/job-x-tandem-check-row.component';
import { JobXTandemCheckColComponent } from './job-x-tandem-dialog/job-x-tandem-check-col/job-x-tandem-check-col.component';

@NgModule({
    declarations: [
        JobXAccountTileComponent,
        JobXAccountTileLocationsHeaderComponent,
        JobXAccountTileLocationsHeaderBadgeComponent,
        JobXAccountTileLocationsPopoverComponent,
        JobXTandemDialogComponent,
        JobXTandemCheckGridComponent,
        JobXTandemCheckRowComponent,
        JobXTandemCheckColComponent,
    ],
    imports: [
        AccountListItemModule,
        ButtonModule,
        MatIconModule,
        MatListModule,
        CommonModule,
        PopoverModule,
        MatButtonModule,
        LanguageModule,
        MatDialogModule,
        CampaignsModule,
    ],
    exports: [JobXAccountTileComponent],
})
export class JobXAccountTileModule {}
