import { NgModule } from '@angular/core';
import { ReadonlyFieldDirective } from './readonly-field.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ReadonlyFieldDirective],
    imports: [CommonModule],
    exports: [ReadonlyFieldDirective],
})
export class ReadonlyFieldModule {}
