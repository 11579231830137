import {
    JobXBookmark,
    JobXProfile,
    JobXProfileMatch,
    JobXProfileSearchResult,
    JobXSearchResult,
    JobXSuggestedSkills,
} from './jobs.types';
import { JobXProfileState, JobXSearchState, JobXState } from './jobXReducer';
import { PageParams, INTEREST_KEY } from '@tploy-enterprise/tenant-core';

export function selectCurrentJobXProfile(state: { jobx: JobXState }): JobXProfile {
    return state.jobx.profile.current;
}

export function selectSavedJobXProfile(state: { jobx: JobXState }): JobXProfile {
    return state.jobx.profile.saved;
}

export function isJobXProfileLoadedOrLoading(state: { jobx: JobXState }): boolean {
    const status = state.jobx.profile.status;
    return status.loaded || status.loading;
}

export function isJobXOwnOrOthersProfileLoading(state: { jobx: JobXState }): boolean {
    return state.jobx.profile.status.loading || state.jobx.othersProfile.loading;
}

export function isJobXProfileLoaded(state: { jobx: JobXState }): boolean {
    return state.jobx.profile.status.loaded;
}

export function isJobXProfileInDirtyState(state: { jobx: JobXState }): boolean {
    return state.jobx.profile.status.dirty;
}

export function hasJobXProfile(state: { jobx: JobXState }): boolean {
    const profileState: JobXProfileState = state.jobx.profile;
    return profileState.status.loaded && !profileState.status.loadingError && profileState.current?.skills?.length > 0;
}

export function isUserInterestedInJobs(state: { jobx: JobXState }): boolean {
    return state.jobx.profile.current.motivations.indexOf(INTEREST_KEY) !== -1;
}

export function selectSearchResultsEntities(state: { jobx: JobXState }): JobXSearchResult[] {
    return state.jobx.searchResults.results;
}

export function selectSearchResults(state: { jobx: JobXState }): JobXSearchState {
    return state.jobx.searchResults;
}

export function selectSearchPageParams(state: { jobx: JobXState }): PageParams {
    return state.jobx.searchResults.pageParams;
}

export function isSearchResultsEmpty(state: { jobx: JobXState }): boolean {
    const searchState = state.jobx.searchResults;
    return searchState.loaded && searchState.results.length === 0;
}

export function isSearchResultsLoading(state: { jobx: JobXState }): boolean {
    return state.jobx.searchResults.loading;
}

export function selectMatching(state: { jobx: JobXState }): Array<JobXProfileMatch> {
    return state.jobx.matches.entities;
}

export function selectAllMatching(state: { jobx: JobXState }): Array<JobXProfileMatch> {
    return state.jobx.matches.allEntities;
}

export function selectMatchesPageParams(state: { jobx: JobXState }): PageParams {
    return state.jobx.matches.pageParams;
}

export function selectMatchesPageSelectedCampaigns(state: { jobx: JobXState }): string[] {
    return state.jobx.matches.selectedCampaigns || [];
}

export function selectProfileSearchResults(state: { jobx: JobXState }): Array<JobXProfileSearchResult> {
    return state.jobx.searchProfiles.results;
}

export function selectAllProfileSearchResults(state: { jobx: JobXState }): Array<JobXProfileSearchResult> {
    return state.jobx.searchProfiles.allResults;
}

export function selectProfileSearchPageParams(state: { jobx: JobXState }): PageParams {
    return state.jobx.searchProfiles.pageParams;
}

export function selectIsProfileSearchResultsEmpty(state: { jobx: JobXState }): boolean {
    const searchState = state.jobx.searchProfiles;
    return searchState.loaded && searchState.results.length === 0;
}

export function selectIsProfileSearchResultsLoading(state: { jobx: JobXState }): boolean {
    return state.jobx.searchProfiles.loading;
}

export function selectBookmarks(state: { jobx: JobXState }): Array<JobXBookmark> {
    return state.jobx.bookmarks.data;
}

export function isOfferedByMeLoading(state: { jobx: JobXState }): boolean {
    return state.jobx.offeredByMe.loading;
}

export function suggestedSkills(state: { jobx: JobXState }): JobXSuggestedSkills {
    return state.jobx.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { jobx: JobXState }): string[] {
    return state.jobx.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { jobx: JobXState }): string[] {
    return state.jobx.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { jobx: JobXState }): string[] {
    return state.jobx.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}
