import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestHandlerService } from './request-handler.service';

const declaredRequestsUrlsParts = [
    {
        url: '/bookmarks' /* bookmarks */,
        method: 'GET',
    },
    {
        url: '/admin/messages' /* admin messages */,
        method: 'GET',
    },
    {
        url: 'job-sharing/profiles/search' /* search requests */,
        method: 'GET',
    },
    {
        url: '?organizer' /* offered by me requests */,
        method: 'GET',
    },
    {
        url: 'job-sharing/jobs/search' /* search job requests */,
        method: 'GET',
    },
    {
        url: 'generation-exchange/search' /* search */,
        method: 'GET',
    },
    {
        url: 'generation-exchange/matches' /* search */,
        method: 'GET',
    },
    {
        url: 'experts/search' /* search */,
        method: 'GET',
    },
    {
        url: 'job-shadowing/search' /* search */,
        method: 'GET',
    },
    {
        url: 'mentoring/search' /* search */,
        method: 'GET',
    },
    {
        url: 'projects/search' /* search */,
        method: 'GET',
    },
    {
        url: 'workshops/search' /* search */,
        method: 'GET',
    },
    {
        url: 'never-lunch-alone/search' /* search */,
        method: 'GET',
    },
    {
        url: 'never-lunch-alone/matches' /* search */,
        method: 'GET',
    },
    {
        url: 'short-time-assignments/search' /* search */,
        method: 'GET',
    },
    {
        url: 'workshops?notificationRequested=true' /* my workshops */,
        method: 'GET',
    },
    {
        url: 'workshops?registered=true' /* my workshops */,
        method: 'GET',
    },
    {
        url: 'short-time-assignments?applied=true' /* my sta */,
        method: 'GET',
    },
    {
        url: 'projects?applied=true' /* my projects */,
        method: 'GET',
    },
    {
        url: 'job-sharing' /* save jobx project  */,
        method: 'POST',
    },
    {
        url: 'short-time-assignments' /* save sta project  */,
        method: 'POST',
    },
    {
        url: 'workshops' /* save ws project  */,
        method: 'POST',
    },
    {
        url: 'projects' /* save ws project  */,
        method: 'POST',
    },
    {
        url: 'job-sharing' /* save jobx project  */,
        method: 'PUT',
    },
    {
        url: 'generation-exchange' /* save jobx project  */,
        method: 'PUT',
    },
    {
        url: 'never-lunch-alone' /* save nla  */,
        method: 'PUT',
    },
    {
        url: 'mentoring' /* save mentoring  */,
        method: 'PUT',
    },
    {
        url: 'experts' /* save experts  */,
        method: 'PUT',
    },
    {
        url: 'job-shadowing' /* save job shadowing  */,
        method: 'PUT',
    },
    {
        url: 'short-time-assignments' /* save sta project  */,
        method: 'PUT',
    },
    {
        url: 'workshops' /* save ws project  */,
        method: 'PUT',
    },
    {
        url: 'projects' /* save ws project  */,
        method: 'PUT',
    },
    {
        url: '/general-data' /* save my profile */,
        method: 'PUT',
    },
    {
        url: '/accounts/questionnaire' /* save my profile */,
        method: 'PUT',
    },
    {
        url: '/accounts/experience' /* save my profile */,
        method: 'PUT',
    },
];

const REQUEST_NORMAL_DURATION = 1_000; // milliseconds
const REQUEST_ADDITIONAL_DELAY = 750; // milliseconds
const MAX_DURATION = REQUEST_NORMAL_DURATION + REQUEST_ADDITIONAL_DELAY;

const requestDurationMap = {};
const timeouts = {};

@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {
    private actualDelay = 0;

    constructor(private requestHandlerService: RequestHandlerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        requestDurationMap[req.url] = {
            timestamp: new Date().getTime(),
            method: req.method,
        };
        if (req.url && this.doesRequestHandleLoader(req.url)) {
            timeouts[req.url] = setTimeout(() => {
                this.requestHandlerService.showSpinner$.next(true);
            }, REQUEST_NORMAL_DURATION);
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                const url = event['url'];
                if (url && this.doesRequestHandleLoader(url)) {
                    this.updateActualDelay(url);
                    clearTimeout(timeouts[this.getRequestUrlFromMap(url)]);
                    delete timeouts[this.getRequestUrlFromMap(url)];
                } else {
                    this.actualDelay = 0;
                }
                return event;
            }),
            delay(this.actualDelay),
            map((event: HttpEvent<any>) => {
                const url = event['url'];
                if (url && this.doesRequestHandleLoader(url) && !this.doesRequestHaveNormalDuration(url)) {
                    this.requestHandlerService.showSpinner$.next(false);
                    this.clearDurationMap(url);
                }
                return event;
            }),
        );
    }

    getRequestUrlFromMap(url: string): string {
        const keys = Object.keys(requestDurationMap);
        const index = keys.findIndex((key) => ~url.indexOf(key));
        return keys[index];
    }

    getRequestStartTime(url: string): number {
        const requestUrl = this.getRequestUrlFromMap(url);
        return requestDurationMap[requestUrl].timestamp;
    }

    doesRequestHaveNormalDuration(url: string): boolean {
        const requestStartTime = this.getRequestStartTime(url);
        return new Date().getTime() - requestStartTime < REQUEST_NORMAL_DURATION;
    }

    doesRequestHaveLongDurationRequest(url: string): boolean {
        const requestStartTime = this.getRequestStartTime(url);
        return new Date().getTime() - requestStartTime > MAX_DURATION;
    }

    getRequestMethod(url: string): string {
        const requestUrl = this.getRequestUrlFromMap(url);
        return requestUrl ? requestDurationMap[requestUrl].method : '';
    }

    doesRequestHandleLoader(url: string): boolean {
        const requestMethod = this.getRequestMethod(url);
        return declaredRequestsUrlsParts.some(
            (urlPart) => ~url.indexOf(urlPart.url) && requestMethod === urlPart.method,
        );
    }

    clearDurationMap(url: string): void {
        const requestUrl = this.getRequestUrlFromMap(url);
        delete requestDurationMap[requestUrl];
    }

    calculateActualDelay(url: string): number {
        const requestStartTime = this.getRequestStartTime(url);
        const currentRequestDuration = new Date().getTime() - requestStartTime;
        return Math.abs(MAX_DURATION - currentRequestDuration);
    }

    updateActualDelay(url: string): void {
        const requestIsNormal = this.doesRequestHaveNormalDuration(url);
        const shouldSkipDelay = this.doesRequestHaveLongDurationRequest(url);
        this.actualDelay = requestIsNormal || shouldSkipDelay ? 0 : this.calculateActualDelay(url);
    }
}
