<mat-select
    #select
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    [value]="value"
    [aria-labelledby]="ariaLabelledBy"
>
    <mat-select-trigger>{{ value }}</mat-select-trigger>
    <mat-option *ngIf="!required"></mat-option>
    <mat-option *ngFor="let year of options" [value]="year">{{ year }}</mat-option>
</mat-select>
<tp-info-toggle icon="view_filled" [content]="{ message: 'SIGN_UP_HARD_FACTS_YEAR_OF_BIRTH_INFO' }"></tp-info-toggle>
