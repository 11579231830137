import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface Validatable {
    forceNavigation: boolean;
    validate(): boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class ValidateOnDeactivateGuard implements CanDeactivate<Validatable> {
    canDeactivate(component: Validatable): Observable<boolean> | boolean {
        if (component.forceNavigation) {
            return true;
        }

        return component.validate && component.validate();
    }
}
