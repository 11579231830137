import { Bookmark, Location, PageParams } from '@tploy-enterprise/tenant-core';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export interface ExpertProfileUser {
    id?: string;
    name: string;
    imageUrl: string;
    languages: string[];
    locations: string[];
}

export interface ExpertProfile {
    skillsInterestedIn: string[];
    skillsOffering: string[];
    communicationType: CommunicationType;
    unavailabilityFrom: Date;
    unavailabilityTo: Date;
    pushToSuccessFactor?: boolean;
}

export interface ExpertOthersProfile extends ExpertProfile {
    user: ExpertProfileUser;
}

export enum CommunicationType {
    'IN_PERSON' = 'IN_PERSON',
    'VIRTUAL' = 'VIRTUAL',
    'BOTH' = 'BOTH',
}
export interface Skill {
    name: string;
}

export interface ValidationErrors {
    [namespace: string]: string[];
}

export interface ExpertAvailabilityData {
    unavailabilityFrom: Date;
    unavailabilityTo: Date;
}

export interface ExpertMatch {
    userId: string;
    highlight: {
        languages: string[];
        locations: Location[];
        skillsOffered: string[];
    };
    bookmark?: Bookmark;
    expertProfile: ExpertOthersProfileReduced;
}
export interface ExpertMatchResults {
    matches: ExpertMatch[];
    allMatches: ExpertMatch[];
    pageParams: PageParams;
}
export interface ExpertBookmark extends Bookmark {
    bookmark?: Bookmark;
    expertProfile?: ExpertOthersProfile;
}
export interface ExpertSearchResults {
    results: ExpertSearchResult[];
    allResults: ExpertSearchResult[];
    pageParams: PageParams;
}
export interface ExpertSearchResult {
    userId: string;
    highlight: {
        locations: Location[];
        skillsOffered: string[];
        name: string[];
    };
    bookmark?: Bookmark;
    expertProfile: ExpertOthersProfileReduced;
}
export type ExpertSuggestedSkills = SuggestedSkills;

export interface ExpertOthersProfileReduced extends ExpertProfileReduced {
    user: Partial<ExpertProfileUser>;
}
export interface ExpertProfileReduced {
    skillsInterestedIn: string[];
    skillsOffering: string[];
}
