import { Component } from '@angular/core';

@Component({
    selector: 'tp-details-topics',
    templateUrl: './details-topics.component.html',
    host: {
        class: 'details__topics',
    },
})
export class DetailsTopicsComponent {}
