import { Topics, BookmarkContext } from '@tandemploy/common';

export const EXPERTS_TOPIC_NAME = Topics.EXPERTS.toString();
export const MENTORING_TOPIC_NAME = Topics.MENTORING.toString();
export const NEVER_LUNCH_ALONE_TOPIC_NAME = Topics.NEVER_LUNCH_ALONE.toString();
export const ONBOARDING_TOPIC_NAME = 'onboarding';
export const JOB_SHARING_TOPIC_NAME = Topics.JOB_SHARING.toString();
export const PROJECTS_TOPIC_NAME = Topics.PROJECTS.toString();
export const SHORT_TIME_ASSIGNMENTS_TOPIC_NAME = Topics.SHORT_TIME_ASSIGNMENTS.toString();
export const WORKSHOPS_TOPIC_NAME = Topics.WORKSHOPS.toString();
export const GENERATION_EXCHANGE_TOPIC_NAME = Topics.GENERATION_EXCHANGE.toString();
export const JOB_SHADOWING_TOPIC_NAME = Topics.JOB_SHADOWING.toString();

export const BOOKMARKS_CONTEXT = {
    PROJECTS: BookmarkContext.PROJECTS.toString(),
    NEVER_LUNCH_ALONE: BookmarkContext.NEVER_LUNCH_ALONE.toString(),
    SHORT_TIME_ASSIGNMENTS: BookmarkContext.SHORT_TIME_ASSIGNMENTS.toString(),
    JOB_SHARING: BookmarkContext.JOB_SHARING.toString(),
    MENTORING_MENTEE: BookmarkContext.MENTORING_MENTEE.toString(),
    MENTORING_MENTOR: BookmarkContext.MENTORING_MENTOR.toString(),
    EXPERTS: BookmarkContext.EXPERTS.toString(),
    WORKSHOPS: BookmarkContext.WORKSHOPS.toString(),
    GENERATION_EXCHANGE: BookmarkContext.GENERATION_EXCHANGE.toString(),
    JOB_SHADOWING: BookmarkContext.JOB_SHADOWING.toString(),
    PROFILE: BookmarkContext.PROFILE.toString(),
    DIRECT_MESSAGES: BookmarkContext.DIRECT_MESSAGES.toString(),
};

export const TOPIC_NAMES_LIST = [
    EXPERTS_TOPIC_NAME,
    PROJECTS_TOPIC_NAME,
    WORKSHOPS_TOPIC_NAME,
    MENTORING_TOPIC_NAME,
    ONBOARDING_TOPIC_NAME,
    JOB_SHARING_TOPIC_NAME,
    NEVER_LUNCH_ALONE_TOPIC_NAME,
    GENERATION_EXCHANGE_TOPIC_NAME,
    SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    JOB_SHADOWING_TOPIC_NAME,
];

export const TOPIC_ORIGIN_CONTEXT_MAPPER = {
    ORIGIN_TOPIC_STA: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    ORIGIN_STA: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    ORIGIN_TOPIC_WORKSHOP: WORKSHOPS_TOPIC_NAME,
    ORIGIN_TOPIC_WORKSHOPS: WORKSHOPS_TOPIC_NAME,
    ORIGIN_TOPIC_WORKSHOPSHOP: WORKSHOPS_TOPIC_NAME,
    ORIGIN_WORKSHOP: WORKSHOPS_TOPIC_NAME,
    ORIGIN_WORKSHOPS: WORKSHOPS_TOPIC_NAME,
    ORIGIN_TOPIC_EXPERTS: EXPERTS_TOPIC_NAME,
    ORIGIN_EXPERTS: EXPERTS_TOPIC_NAME,
    ORIGIN_TOPIC_NLA: NEVER_LUNCH_ALONE_TOPIC_NAME,
    ORIGIN_NLA: NEVER_LUNCH_ALONE_TOPIC_NAME,
    ORIGIN_TOPIC_MENTORING: MENTORING_TOPIC_NAME,
    ORIGIN_MENTORING: MENTORING_TOPIC_NAME,
    ORIGIN_TOPIC_PROJECTS: PROJECTS_TOPIC_NAME,
    ORIGIN_PROJECTS: PROJECTS_TOPIC_NAME,
    ORIGIN_JOB_SHARING: JOB_SHARING_TOPIC_NAME,
    ORIGIN_TOPIC_JOB_SHARING: JOB_SHARING_TOPIC_NAME,
    ORIGIN_TOPIC_ONBOARDING: ONBOARDING_TOPIC_NAME, //TODO: check
    ORIGIN_GENERATION_EXCHANGE: GENERATION_EXCHANGE_TOPIC_NAME, //TODO: check
    ORIGIN_TOPIC_WORKSHOPS_PRIVATE: WORKSHOPS_TOPIC_NAME,
    ORIGIN_TOPIC_WORKSHOPS_PARTICIPANT: WORKSHOPS_TOPIC_NAME,
    ORIGIN_TOPIC_PROJECTS_PRIVATE: PROJECTS_TOPIC_NAME,
    ORIGIN_TOPIC_PROJECTS_PARTICIPANT: PROJECTS_TOPIC_NAME,
    ORIGIN_TOPIC_STA_PRIVATE: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    ORIGIN_TOPIC_STA_PARTICIPANT: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
    ORIGIN_TOPIC_JOB_SHADOWING: JOB_SHADOWING_TOPIC_NAME,
};

export const INTEREST_KEY = 'interest';
export const OFFERING_KEY = 'offering';

export type INTEREST_TYPE = 'interest';
export type OFFERING_TYPE = 'offering';
