import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Bookmark, GeneralData, LocationService, NEVER_LUNCH_ALONE_TOPIC_NAME } from '@tploy-enterprise/tenant-core';
import { NLABookmark } from '../../nla-service/nla.types';
import { Observable } from 'rxjs';

@Component({
    selector: 'tp-nla-bookmark',
    templateUrl: './nla-bookmark.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLABookmarkComponent implements OnChanges {
    @Input()
    nlaBookmark: NLABookmark;
    locations: string[];
    context = NEVER_LUNCH_ALONE_TOPIC_NAME;

    get user(): Partial<GeneralData> {
        return this.nlaBookmark.profile.user;
    }
    get bookmark(): Bookmark {
        return this.nlaBookmark.bookmark;
    }
    get tags(): string[] {
        return this.nlaBookmark.profile.interestedInTopics;
    }
    get selectedTags(): string[] {
        return [];
    }
    get locationIndependent(): boolean {
        return this.nlaBookmark.profile.locationIndependent;
    }

    constructor(private readonly locationService: LocationService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.nlaBookmark) {
            this.locations = this.locationService.getLocationsOrCountry(this.nlaBookmark.profile.companyLocations);
        }
    }
}
