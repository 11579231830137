import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    isMultiDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            return !moment(startDate.toISOString()).isSame(endDate.toISOString(), 'date');
        } else {
            return false;
        }
    }
}
