<ng-template #skillWithoutLevel let-item>
    <tp-skill-text-view [skill]="item" [withLevel]="false"></tp-skill-text-view>
</ng-template>

<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-list-field
        *ngIf="hasSkills()"
        key="JOBX_PROFILE_VIEW_MY_SKILLS_LABEL"
        [value]="topicData.skills"
        [template]="skillWithoutLevel"
    >
    </tp-profile-view-list-field>
    <tp-profile-view-list-field
        *ngIf="hasTandemSkills()"
        key="JOBX_PROFILE_VIEW_TANDEM_SKILLS_LABEL"
        [value]="interestedInSkills"
        [template]="skillWithoutLevel"
    >
    </tp-profile-view-list-field>
    <tp-profile-view-list-field [value]="locations" key="JOBX_PROFILE_VIEW_LOCATION_LABEL">
    </tp-profile-view-list-field>
    <tp-profile-view-text-field [value]="leadershipText" key="JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_LABEL">
    </tp-profile-view-text-field>
    <tp-profile-view-string-list-field
        *ngIf="hasCampaigns()"
        key="PROFILE_VIEW_JOBX_CAMPAIGNS_LABEL"
        [value]="campaigns"
        [separateByComma]="true"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        [value]="topicData.currentJobLikes"
        key="JOBX_PROFILE_VIEW_CURRENT_JOB_LIKES_LABEL"
        *ngIf="hasCurrentJobLikes()"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-text-field
        [value]="topicData.currentJobDescription"
        key="JOBX_PROFILE_VIEW_CURRENT_JOB_DESCRIPTION_LABEL"
        *ngIf="hasCurrentJobDescription()"
    >
    </tp-profile-view-text-field>
</tp-profile-view-fieldset>
