import {
    ExpertAvailabilityData,
    ExpertBookmark,
    ExpertMatch,
    ExpertOthersProfile,
    ExpertOthersProfileReduced,
    ExpertProfile,
    ExpertSearchResult,
} from './expert.types';
import { Bookmark, GeneralData } from '@tploy-enterprise/tenant-core';
import { Injectable } from '@angular/core';
import {
    ExpertAvailabilityDTO,
    ExpertMatchDTO,
    ExpertOthersProfileReducedDTO,
    ExpertProfileDTO,
    ExpertSearchResultDTO,
} from './expert.dtos';
import { LocationSerializer } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class ExpertSerializer {
    constructor(private readonly locationSerializer: LocationSerializer) {}

    serialize(profile: ExpertProfile, user: GeneralData): ExpertProfileDTO {
        return {
            user: {
                name: user.name,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            communicationType: profile.communicationType,
            skillsInterestedIn: profile.skillsInterestedIn.map((skill) => ({ name: skill })),
            skillsOffering: profile.skillsOffering.map((skill) => ({ name: skill })),
            unavailabilityFrom: this.dateToString(profile.unavailabilityFrom),
            unavailabilityTo: this.dateToString(profile.unavailabilityTo),
            pushToSuccessFactor: profile.pushToSuccessFactor,
        };
    }

    deserialize(dto: ExpertProfileDTO): ExpertProfile {
        return {
            communicationType: dto.communicationType,
            skillsInterestedIn: dto.skillsInterestedIn.map((skill) => skill.name),
            skillsOffering: dto.skillsOffering.map((skill) => skill.name),
            unavailabilityFrom: dto.unavailabilityFrom ? new Date(dto.unavailabilityFrom) : undefined,
            unavailabilityTo: dto.unavailabilityTo ? new Date(dto.unavailabilityTo) : undefined,
        };
    }

    deserializeOthers(dto: ExpertProfileDTO): ExpertOthersProfile {
        return {
            ...this.deserialize(dto),
            user: {
                ...dto.user,
                locations: dto.user.locations.map((l) => l.name),
            },
        };
    }

    deserializeSearchResult(dto: ExpertSearchResultDTO): ExpertSearchResult {
        const highlight = dto.highlight ?? { languages: [], locations: [], name: [], skillsOffered: [] };

        return {
            userId: dto.profile.user.id,
            highlight: { ...highlight },
            expertProfile: this.deserializeExpertOthersProfileReduced(dto.profile),
        };
    }

    deserializeMath(dto: ExpertMatchDTO): ExpertMatch {
        const highlight = dto.highlight ?? { languages: [], locations: [], name: [], skillsOffered: [] };

        return {
            userId: dto.profile.user.id,
            highlight: { ...highlight },
            expertProfile: this.deserializeExpertOthersProfileReduced(dto.profile),
        };
    }

    deserializeExpertOthersProfileReduced(profileDto: ExpertOthersProfileReducedDTO): ExpertOthersProfileReduced {
        return {
            user: {
                ...profileDto.user,
                locations: profileDto.user.locations.map((l) => l.name),
            },
            skillsInterestedIn: profileDto.skillsInterestedIn.map((skill) => skill.name),
            skillsOffering: profileDto.skillsOffering.map((skill) => skill.name),
        };
    }

    serializeAvailability(availability: ExpertAvailabilityData): ExpertAvailabilityDTO {
        return {
            unavailabilityFrom: this.dateToString(availability.unavailabilityFrom),
            unavailabilityTo: this.dateToString(availability.unavailabilityTo),
        };
    }

    deserializeBookmark(bookmark: Bookmark): ExpertBookmark {
        return {
            ...bookmark,
        };
    }

    private dateToString(date: string | Date): string {
        if (!date) return undefined;
        if (typeof date === 'string') {
            return date;
        }
        return date.toISOString();
    }
}
