// This file should stay empty, since workz is our default tenant, that uses our default translations
export default {
    DASHBOARD: 'Mein Dashboard',
    DASHBOARD_HOME_TITLE: 'Mein Dashboard',

    ORIGIN_TOPIC_WORKSHOP: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOPSHOP: 'Workshops',
    ORIGIN_WORKSHOPSHOPS: 'Workshops',

    WORKSHOP: 'Workshops',
    WORKSHOP_SINGULAR: 'Workshop',
    WORKSHOP_INFO:
        'Wer eine interne Veranstaltung bewerben und genau die passenden Teilnehmer*innen gewinnen möchte, ist mit dem Workshop-Matching richtig beraten. Hier können Sie interne Infoveranstaltungen, Netzwerkevents, Workshops und Weiterbildungen – interne Workshops aller Art – erstellen und genau angeben, wie viele Teilnehmer*innen gesucht sind und ob diese bestimmte Voraussetzungen (neben dem Interesse für ein bestimmtes Thema) mitbringen sollten. Potentiell interessierte Mitglieder bekommen automatisch eine Benachrichtigung und können sich unkompliziert anmelden! Oder umgekehrt: Wer über interne Veranstaltungen auf dem Laufenden bleiben möchte, sollte unbedingt „Workshops“ als sein Interesse auswählen und angeben, was sie oder ihn besonders interessiert!',
    WORKSHOP_ENTITY_CREATE_BTN: 'Neuer Workshop',
    WORKSHOP_TAB_MY: 'Meine Workshops',
    CREATE_WORKSHOP_DIALOG_TITLE: 'Neuen Workshop erstellen',
    EDIT_WORKSHOP_DIALOG_TITLE: 'Workshop bearbeiten',
    PUBLISH_WORKSHOP_DIALOG_TITLE: 'Workshop jetzt veröffentlichen?',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_2:
        'Sobald ein Workshop veröffentlicht wird, werden automatisch alle Matches ermittelt und via Mail darüber informiert. Bitte prüfen Sie daher, ob Ihre Angaben korrekt sind. Die Benachrichtigungen werden innerhalb von 24h nach Veröffentlichung verschickt.',
    PUBLISH_WORKSHOP_DIALOG_PUBLISH_BTN_TITLE: 'Workshop veröffentlichen',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_1: 'Erstellen Sie Ihren ersten Workshop',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_2:
        'Sie haben bisher noch keinen Workshop angeboten. Hier können Sie erstmalig einen erstellen.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CREATE_WORKSHOP_BTN: 'Neuen Workshop erstellen',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_TITLE: 'Workshops',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_SUB_TITLE:
        'Spannende Workshops entdecken und/oder eigene Workshops veröffentlichen und passende Teilnehmer*innen adressieren.',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_INFO:
        'Hier können Sie die für sich passenden Optionen auswählen. Entweder können Sie auf die Suche nach interessanten Workshops gehen und sich automatisch passende vorschlagen lassen. Oder Sie erstellen selbst Workshops und finden mithilfe des Matchings automatisch Teilnehmer*innen.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_INTEREST_LABEL: 'Über passende Workshops informiert werden.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_OFFERING_LABEL: 'Workshops bequem ausschreiben und Teilnehmer*innen finden.',
    SIGN_UP_WORKSHOP_INTEREST_STEP_TITLE: 'Workshops',
    SIGN_UP_WORKSHOP_INTEREST_STEP_INFO:
        'Was muss ich hier eintragen – und warum wird nach Skills gefragt? Ganz einfach: Passende Workshops werden vor allem auf Grundlage Ihrer Skills und fachlichen Orientierung gematcht. Außerdem gibt es auch Workshops, in denen bestimmte Skills und Skill-Levels zwingend erforderlich sind. Wenn Sie Chancen auf eine Teilnahme an solchen Workshops haben wollen, empfielt es sich, möglichst viele Ihrer Skills und den entsprechenden Level anzugeben.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_1:
        'Vielen Dank für Ihr Interesse an diesem Workshop. Leider sind aktuell alle Plätze vergeben. Wenn Sie sofort über freiwerdende Plätze informiert werden wollen, können Sie sich mit einem Klick auf der Warteliste eintragen.',
    WORKSHOP_ENTITY_VIEW_REGISTERED_STATUS_NOTE: 'Sie haben einen Platz für den Workshop',
    WORKSHOP_DETAILS_VIEW_DESCRIPTION_TITLE: 'Workshopbeschreibung',
    WORKSHOP_FORM_TITLE_LABEL: 'Titel des Workshops',
    MY_WORKSHOPS_PLACEHOLDER_TEXT: 'Sie nehmen noch an keinem Workshop teil.',
    MY_WORKSHOPS_PLACEHOLDER_BTN: 'Finden Sie passende Workshops',
    WORKSHOP_SEARCH_HEADER: 'Workshopsuche mit Schlagwörtern',
};
