import {
    AfterViewInit,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ModalHeaderWarningComponent } from './modal-header-warning.component';

@Directive({
    selector: '[tpModalHelpers]',
    exportAs: 'tpModalHelpers',
})
export class ModalHelpersDirective implements OnInit, OnDestroy, AfterViewInit {
    stopBackdropClickListener: () => void;
    stopEscapeKeydownListener: () => void;
    closed$ = new Subject<void>();
    private readonly onDestroy$ = new Subject<void>();
    private modalHeaderWarningComponentRef: ComponentRef<ModalHeaderWarningComponent>;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly ngZone: NgZone,
    ) {}

    @Input()
    hasGeneralErrorMessage = false;

    @Input()
    backdropClick = false;
    private backDropClickListener = () => {
        if (!this.backdropClick) return;
        this.close();
    };

    @Input()
    closeOnEscape = false;
    private escapeListener = (event: KeyboardEvent): void => {
        if (!this.closeOnEscape) return;
        if (event.key === 'Esc' || event.key === 'Escape') {
            this.close();
        }
    };

    ngOnInit(): void {
        const parentNode = this.renderer.parentNode(this.elementRef.nativeElement);
        this.stopBackdropClickListener = this.renderer.listen(parentNode, 'click', this.backDropClickListener);
        this.stopEscapeKeydownListener = this.renderer.listen(this.document, 'keydown', this.escapeListener);
    }

    ngAfterViewInit() {
        if (this.elementRef?.nativeElement) {
            const modalBodyElement = this.elementRef.nativeElement.querySelector('.modal-form-layout_content');
            const modalHeaderElement = this.elementRef.nativeElement.querySelector('header');
            const modalHasFormFields = !!this.elementRef.nativeElement.querySelector('mat-form-field');
            if (modalBodyElement) {
                fromEvent(modalBodyElement, 'scroll')
                    .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
                    .subscribe((e: Event) => {
                        modalHeaderElement.classList.toggle('scrollbox', (e.target as HTMLElement).scrollTop > 0);
                        if (modalHasFormFields) this.onScrollShowHeaderWarning(modalBodyElement);
                    });
            }
            if (modalHasFormFields) {
                this.ngZone.onStable
                    .pipe(take(1))
                    .subscribe(() => this.loadModalHeaderWarningComponent(modalHeaderElement, modalBodyElement));
            }
        }
    }

    ngOnDestroy(): void {
        if (this.stopEscapeKeydownListener) {
            this.stopEscapeKeydownListener();
        }
        if (this.stopBackdropClickListener) {
            this.stopBackdropClickListener();
        }
        if (this.modalHeaderWarningComponentRef) {
            this.modalHeaderWarningComponentRef.destroy();
        }
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    close(): void {
        if (this.hasOverlayOutletAncestor(this.route)) {
            this.router.navigate([{ outlets: { overlay: null } }]);
        } else {
            this.router.navigate(['../'], { relativeTo: this.route });
        }

        this.closed$.next();
    }

    hasOverlayOutletAncestor(route: ActivatedRoute): boolean {
        let current = route;
        while (current) {
            if (current.outlet === 'overlay') {
                return true;
            }
            current = current.parent;
        }

        return false;
    }

    @HostListener('click', ['$event'])
    @HostListener('scroll', ['$event'])
    muteEvent($event: MouseEvent): void {
        $event.stopPropagation();
    }

    private getErrorElements(modalBodyElement) {
        return [...modalBodyElement.querySelectorAll('.ng-touched.ng-invalid .mat-error')];
    }

    onScrollShowHeaderWarning(modalBodyElement) {
        const errorElements = this.getErrorElements(modalBodyElement);
        if (this.hasGeneralErrorMessage) {
            this.showStaticWarning(errorElements);
            return;
        }
        if (errorElements.length === 0) return;
        const modalBodyOffsetTop = modalBodyElement.getBoundingClientRect().top;
        const notVisibleErrorElements = errorElements.filter((errorElement) => {
            if (window.getComputedStyle(errorElement).display !== 'none') {
                return errorElement.getBoundingClientRect().top - modalBodyOffsetTop < 0;
            } else {
                return false;
            }
        });
        if (notVisibleErrorElements.length === 0) {
            this.modalHeaderWarningComponentRef.instance.hide();
            return;
        }
        this.modalHeaderWarningComponentRef.instance.show(notVisibleErrorElements);
    }

    private showStaticWarning(errorElements) {
        if (errorElements.length === 0) {
            this.modalHeaderWarningComponentRef.instance.hide();
            return;
        }
        this.modalHeaderWarningComponentRef.instance.show(errorElements);
    }

    private loadModalHeaderWarningComponent(modalHeaderElement: HTMLElement, modalBodyElement: HTMLElement): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalHeaderWarningComponent);
        this.modalHeaderWarningComponentRef =
            this.viewContainerRef.createComponent<ModalHeaderWarningComponent>(componentFactory);
        this.modalHeaderWarningComponentRef.instance.modalBodyElement = modalBodyElement;
        this.renderer.insertBefore(
            modalHeaderElement,
            this.modalHeaderWarningComponentRef.location.nativeElement,
            modalHeaderElement.firstChild,
        );
        this.modalHeaderWarningComponentRef.instance.hasStaticMessage = this.hasGeneralErrorMessage;
    }
}
