<h2 mat-dialog-title>
    {{ 'MAX_IMAGES_REACHED_TITLE' | translatable : { max: maxImages } }}
</h2>

<mat-dialog-content>
    <p>{{ 'MAX_IMAGES_REACHED_BODY' | translatable : { max: maxImages } }}</p>
</mat-dialog-content>

<mat-dialog-actions class="form-footer form-footer--buttons-center form-footer--ignore-sibling-padding">
    <nav>
        <tp-secondary-button mat-dialog-close>
            {{ 'OK' | translatable }}
        </tp-secondary-button>
    </nav>
</mat-dialog-actions>
