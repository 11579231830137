<p class="profile-title-locations" *ngIf="locations?.length > 0">
    <mat-icon svgIcon="location_filled"></mat-icon>
    <tp-list-item-tags [tags]="visibleLocations"></tp-list-item-tags>
    <tp-job-x-account-tile-locations-header-badge
        *ngIf="showBadge"
        [locations]="extraLocations"
        [badgeText]="badgeText"
    >
    </tp-job-x-account-tile-locations-header-badge>
</p>
