import { CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TPLOY_LANGUAGE_STORAGE_KEY } from './core-modules/account/locale/locale.effects';
import { StorageService } from './core-modules/storage';
import { ConfigService } from './core-modules/config';
import { LanguageHelpersService } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class SkipLoginPageGuard implements CanActivate {
    private readonly defaultEndpoint = '/auth/sign-in';

    constructor(
        private readonly configService: ConfigService,
        private readonly languageHelpers: LanguageHelpersService,
    ) {}

    async canActivate(): Promise<boolean | UrlTree> {
        if (this.configService.remoteSettings.skipLoginPage) {
            const locale = this.languageHelpers.getCurrentLocale();

            const currentLocation = window.location;
            const queryParameters = new URLSearchParams(currentLocation.search);
            const returnUrl = queryParameters.has('returnUrl')
                ? `&returnUrl=${encodeURI(queryParameters.get('returnUrl'))}`
                : '';

            window.location.href = `${this.defaultEndpoint}?lang=${locale}${returnUrl}`;
            return false;
        }

        return true;
    }
}
