import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountListItemComponent } from './account-list-item.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from '@tandemploy/ngx-components';
import { LanguageModule, TaggerModule, FocusModule } from '@tploy-enterprise/tenant-common';
import { AccountListItemTagsComponent } from './account-list-item-tags.component';
import { RouterModule } from '@angular/router';
import { AccountListItemGetToKnowComponent } from './account-list-item-get-to-know.component';

@NgModule({
    imports: [
        CommonModule,
        LanguageModule,
        TaggerModule,
        FocusModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        ButtonModule,
    ],
    declarations: [AccountListItemComponent, AccountListItemTagsComponent, AccountListItemGetToKnowComponent],
    exports: [AccountListItemComponent, AccountListItemTagsComponent, AccountListItemGetToKnowComponent],
})
export class AccountListItemModule {}
