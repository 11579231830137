import { combineReducers, createReducer, createSelector, on } from '@ngrx/store';
import { Notification, Registration, Workshop } from '../models/workshop.types';
import { WorkshopMatchingActions } from './workshop-matching.actions';
import { TopicWorkshopState } from './topic-workshop.reducer';
import { GeneralData, PageParams } from '@tploy-enterprise/tenant-core';
import { WorkshopMatchingEntry, WorkshopMatchingEntryHighlight } from '../models/workshop-matching.types';

export interface WorkshopMatchesState {
    workshops: {
        [id: string]: Workshop;
    };
    generalDataEntries: {
        [userId: string]: GeneralData;
    };
    highlightEntries: {
        [workshopId: string]: WorkshopMatchingEntryHighlight;
    };
    loaded: boolean;
    loading: boolean;
    error: Error;
    pageParams: PageParams;
}

export interface MatchingWorkshopState {
    loaded: boolean;
    loading: boolean;
    error: Error;
    workshopId: string;
}

export interface MatchingWorkshopRegistrationState {
    data: Registration;
    startInProgress: boolean;
    finishInProgress: boolean;
    error: Error;
}

export interface MatchingWorkshopNotificationState {
    data: Notification;
    inProgress: boolean;
    error: Error;
}

export interface WorkshopMatchingState {
    matches: WorkshopMatchesState;
    current: MatchingWorkshopState;
    registration: MatchingWorkshopRegistrationState;
    notification: MatchingWorkshopNotificationState;
}

const workshopMatchingInitialState: WorkshopMatchingState = {
    matches: {
        loaded: false,
        loading: false,
        workshops: {},
        generalDataEntries: {},
        highlightEntries: {},
        error: null,
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    },
    current: {
        loaded: false,
        loading: false,
        error: null,
        workshopId: null,
    },
    registration: {
        data: null,
        startInProgress: false,
        finishInProgress: false,
        error: null,
    },
    notification: {
        data: null,
        inProgress: false,
        error: null,
    },
};

const workshopMatchesReducer = createReducer<WorkshopMatchesState>(
    workshopMatchingInitialState.matches,
    on(WorkshopMatchingActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(WorkshopMatchingActions.loadMatchesSuccess, (state, { workshopMatchingEntries, pageParams }) => {
        const workshops = {};
        const generalDataEntries = {};
        const highlightEntries = {};

        workshopMatchingEntries.forEach((entry) => {
            const workshop = entry.workshop;
            workshops[workshop.id] = workshop;
            generalDataEntries[workshop.organizers[0].id] = workshop.organizers[0];
            highlightEntries[workshop.id] = entry.highlight;
        });

        return {
            ...state,
            loading: false,
            loaded: true,
            workshops,
            generalDataEntries,
            highlightEntries,
            pageParams,
        };
    }),
    on(WorkshopMatchingActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
    on(WorkshopMatchingActions.finishRegistrationSuccess, (state, { workshopId, registration }) =>
        removeMatchingWorkshop(state, workshopId, registration.userId),
    ),
    on(WorkshopMatchingActions.createNotificationSuccess, (state, { workshopId, notification }) =>
        removeMatchingWorkshop(state, workshopId, notification.userId),
    ),
);

const removeMatchingWorkshop = (state: WorkshopMatchesState, workshopId: string, userId: string) => {
    // remove workshop
    const { [workshopId]: value, ...newWorkshops } = state.workshops;

    const keepGeneralData = Object.values(newWorkshops).some((w) =>
        w.organizers.some((organizer) => organizer.id === userId),
    );

    // remove general data if it is not used anymore
    let generalDataEntries = state.generalDataEntries;
    if (!keepGeneralData) {
        const { [userId]: value, ...newGeneralDataEntries } = generalDataEntries;
        generalDataEntries = newGeneralDataEntries;
    }

    return {
        ...state,
        workshops: newWorkshops,
        generalDataEntries,
    };
};

const matchingWorkshopRegistrationReducer = createReducer(
    workshopMatchingInitialState.registration,
    on(WorkshopMatchingActions.startRegistration, (state) => {
        return { ...state, startInProgress: true, data: null, error: null };
    }),
    on(WorkshopMatchingActions.startRegistrationSuccess, (state, { registration }) => {
        return { ...state, startInProgress: false, data: registration };
    }),
    on(WorkshopMatchingActions.startRegistrationError, (state, { error }) => {
        return { ...state, startInProgress: false, error };
    }),
    on(WorkshopMatchingActions.finishRegistration, (state) => {
        return { ...state, finishInProgress: true, error: null };
    }),
    on(WorkshopMatchingActions.finishRegistrationSuccess, (state, { registration }) => {
        return { ...state, finishInProgress: false, data: registration };
    }),
    on(WorkshopMatchingActions.finishRegistrationError, (state, { error }) => {
        return { ...state, finishInProgress: false, error };
    }),
);

const matchingWorkshopNotificationReducer = createReducer(
    workshopMatchingInitialState.notification,
    on(WorkshopMatchingActions.createNotification, (state) => {
        return { ...state, inProgress: true, data: null, error: null };
    }),
    on(WorkshopMatchingActions.createNotificationSuccess, (state, { notification }) => {
        return { ...state, inProgress: false, data: notification };
    }),
    on(WorkshopMatchingActions.createNotificationError, (state, { error }) => {
        return { ...state, inProgress: false, error };
    }),
);

export const workshopMatchingReducer = combineReducers({
    matches: workshopMatchesReducer,
    registration: matchingWorkshopRegistrationReducer,
    notification: matchingWorkshopNotificationReducer,
});

// selectors
export const selectWorkshopMatchingState = (state: TopicWorkshopState): WorkshopMatchingState => {
    return state.workshopMatching;
};

export const getWorkshopMatchingEntries = (state: WorkshopMatchingState): WorkshopMatchingEntry[] | null => {
    if (!state.matches.loaded) {
        return null;
    }

    return Object.keys(state.matches.workshops).map((workshopId) => {
        const workshop = state.matches.workshops[workshopId];
        const highlight = state.matches.highlightEntries[workshopId];

        return {
            id: workshopId,
            workshop,
            organizers: workshop.organizers,
            highlight,
        };
    });
};

export const selectWorkshopMatchingEntries = createSelector(selectWorkshopMatchingState, getWorkshopMatchingEntries);
