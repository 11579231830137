import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models/direct-messages-conversation.types';

export const DirectMessagesMuteConversationActions = {
    // Mute Conversation Actions
    muteConversation: createAction('[DM-Conversations] Mute Conversation'),
    muteConversationSuccess: createAction(
        '[DM-Conversations][Success] Mute Conversation',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    muteConversationError: createAction('[DM-Conversations][Error] Mute Conversation', props<{ error: Error }>()),

    // Unmute Conversation Actions
    unmuteConversation: createAction('[DM-Conversations] Unmute Conversation'),
    unmuteConversationSuccess: createAction(
        '[DM-Conversations][Success] Unmute Conversation',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    unmuteConversationError: createAction('[DM-Conversations][Error] Unmute Conversation', props<{ error: Error }>()),
};
