import { Injectable } from '@angular/core';
import { Bookmark, GeneralData } from '@tploy-enterprise/tenant-core';
import {
    GenerationExchangeHighlightDTO,
    GenerationExchangeLocationType,
    GenerationExchangeProfileDTO,
    GenerationExchangeProfileMatchDTO,
    GenerationExchangeSearchResultDTO,
} from '../generation-exchange-api/generation-exchange.dtos';
import {
    GenerationExchangeBookmark,
    GenerationExchangeMatch,
    GenerationExchangeProfile,
    GenerationExchangeHighlight,
    GenerationExchangeSearchResult,
    OthersGenerationExchangeProfile,
} from './generation-exchange.types';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';
import { LocationSerializer } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class GenerationExchangeSerializer {
    constructor(
        private readonly skillsSerializer: SkillsSerializer,
        private readonly locationSerializer: LocationSerializer,
    ) {}

    deserializeGenerationExchangeProfile(dto: GenerationExchangeProfileDTO): GenerationExchangeProfile {
        return {
            campaigns: dto.campaigns,
            companyLocations: dto.locationsForMeet.map((location) => location.name),
            locationIndependent:
                (dto.interestedInLocationType as GenerationExchangeLocationType) ===
                GenerationExchangeLocationType.ONLINE,
            generation: dto.generation,
            motivations: dto.motivations,
            skills: dto.skills.map((skill) => skill.name),
        };
    }
    deserializeOthersGenerationExchangeProfile(dto: GenerationExchangeProfileDTO): OthersGenerationExchangeProfile {
        return {
            user: {
                ...dto.user,
                locations: dto.user.locations?.map((location) => location.name),
            },
            ...this.deserializeGenerationExchangeProfile(dto),
        };
    }
    serializeGenerationExchangeProfile(
        profile: GenerationExchangeProfile,
        user: GeneralData,
    ): GenerationExchangeProfileDTO {
        return {
            user: {
                id: user.id,
                imageUrl: user.imageUrl,
                name: user.name,
                message: user.message ?? '',
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            locationsForMeet: this.locationSerializer.serialise(profile.companyLocations),
            interestedInLocationType: profile.locationIndependent
                ? GenerationExchangeLocationType.ONLINE
                : GenerationExchangeLocationType.OWN,
            generation: profile.generation,
            motivations: profile.motivations,
            skills: this.skillsSerializer.serialize(profile.skills),
            campaigns: profile.campaigns,
        };
    }

    deserializeMatch(dto: GenerationExchangeProfileMatchDTO): GenerationExchangeMatch {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeHighlight(dto.highlight),
            profile: this.deserializeOthersGenerationExchangeProfile(dto.profile),
        };
    }

    deserializeBookmark(bookmark: Bookmark): GenerationExchangeBookmark {
        return { ...bookmark };
    }

    deserializeSearchResult(dto: GenerationExchangeSearchResultDTO): GenerationExchangeSearchResult {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeHighlight(dto.highlight),
            profile: this.deserializeOthersGenerationExchangeProfile(dto.profile),
        };
    }

    deserializeHighlight(highlightDTO: GenerationExchangeHighlightDTO): GenerationExchangeHighlight {
        return {
            locations: highlightDTO?.locations.map((location) => location.name) ?? [],
            skills: highlightDTO?.skills ?? [],
            isOnlineMatch: highlightDTO?.isOnlineMatch ?? false,
        };
    }
}
