// This is the universal type for a Skill
// It should be used everywhere. Optional fields need to
// be checked / validated at runtime depending on context.
import { Translatable } from '../language';

export const SKILL_MAX_LENGTH = 60;

export interface Skill {
    name: string;
    level?: SkillLevel;
    required?: boolean;

    // should not be serialized
    // can be used to control skill views
    highlight?: boolean;
    exact?: boolean;
    related?: string[];
}

export interface SkillDTO {
    name: string;
    level?: SkillLevel;
    required?: boolean;
    position: number;
}

export enum SkillLevel {
    ANY,
    JUNIOR,
    SENIOR,
    EXPERT,
}

export interface SuggestedSkill {
    name: string;
}

export interface SuggestedSkills {
    aType: SuggestedSkill[];
    sType: SuggestedSkill[];
    lType: SuggestedSkill[];
}

export interface TechWolfSkills {
    aType: string[];
    sType: string[];
    lType: string[];
}

export interface RequiredSkillsInputFieldsDescription {
    name?: Translatable;
    required?: Translatable;
    level?: Translatable;
}

export interface RequiredSkillsInputReducedFieldsDescription {
    name?: Translatable;
    level?: Translatable;
}
