import { Directive, OnInit, Inject, Self, OnDestroy, HostBinding } from '@angular/core';
import { SUGGESTABLE } from './suggest.di';
import { Suggestable } from './suggest.types';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, switchMap, map, takeUntil } from 'rxjs/operators';
import { SuggestSkillService } from './suggest-skill.service';

@Directive({
    selector: '[suggestSkills]',
    exportAs: 'suggestSkills',
})
export class SuggestSkillsDirective implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    $: Observable<string[]>;

    @HostBinding('class.suggest-requesting') isRequesting = false;

    constructor(
        @Inject(SUGGESTABLE) @Self() private readonly suggestable: Suggestable,
        private readonly suggestSkillService: SuggestSkillService,
    ) {}

    ngOnInit() {
        this.$ = this.suggestable.input$.pipe(
            debounceTime(250),
            map((input) => input?.trim()),
            switchMap((input) => {
                if (!!input && input.length > 0) {
                    this.isRequesting = true;
                    this.suggestSkillService.loading$.next(this.isRequesting);
                    return this.suggestSkillService.suggest(input);
                } else {
                    this.isRequesting = false;
                    this.suggestSkillService.loading$.next(this.isRequesting);
                    return of([]);
                }
            }),
            map((skills) => {
                this.isRequesting = false;
                this.suggestSkillService.loading$.next(this.isRequesting);
                return skills.map((skill) => skill.name);
            }),
            takeUntil(this.destroy$),
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
