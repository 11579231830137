import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { TranslatablePipe } from './translatable/translatable.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSwitcherModule } from './language-switcher/language-switcher.module';
import { TPLOY_LANGUAGE_STORAGE_KEY } from './language.types';
import { TranslatableModule } from './translatable/translatable.module';
import { LanguageHelpersService } from './language-helpers.service';

@NgModule({
    imports: [CommonModule, LanguageSwitcherModule, TranslatableModule],
    exports: [LanguageSwitcherComponent, TranslatablePipe, LanguageSwitcherModule, TranslatableModule],
    providers: [LanguageHelpersService],
})
export class LanguageModule {
    static configured = false;

    constructor(translateService: TranslateService, private readonly languageHelpers: LanguageHelpersService) {
        if (!translateService) {
            throw new Error('TranslateModule.forRoot(...) must be called prior to using this module.');
        }

        if (!LanguageModule.configured) {
            try {
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY)) {
                    translateService.use(localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY));
                } else {
                    translateService.use('de');
                }
            } catch (e) {
                throw new Error(
                    'It was impossible to set the translations. Please verify that the application is properly configured.',
                );
            }
        }

        LanguageModule.configured = true;
    }
}
