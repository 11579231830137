import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountSettingsService } from './account-settings.service';
import { AccountSettingsActions } from './account-settings.actions';
import { withLatestFrom, switchMap, catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AccountState } from '../account.reducer';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly accountSettingsService: AccountSettingsService,
        private readonly store: Store<{ account: AccountState }>,
    ) {}

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountSettingsActions.save),
            withLatestFrom(this.store.select((state) => state.account.settings.edit.data)),
            switchMap(([action, accountSettings]) =>
                this.accountSettingsService.save(accountSettings).pipe(
                    map(() => AccountSettingsActions.saveSuccess({ accountSettings })),
                    catchError((error) => of(AccountSettingsActions.saveError({ error, accountSettings }))),
                ),
            ),
        ),
    );
}
