import { NgModule } from '@angular/core';
import { MentoringProfileNavigatorViewComponent } from './mentoring-profile-navigator-view.component';
import { CommonModule } from '@angular/common';
import { LanguageModule, ProfileContentModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [MentoringProfileNavigatorViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class MentoringProfileNavigatorViewModule {}
