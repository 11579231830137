import { createAction, props } from '@ngrx/store';
import { Skill } from '@tploy-enterprise/tenant-common';

export const SuccessFactorsActions = {
    setSuccessFactorsOriginalRequiredSkills: createAction(
        '[SuccessFactors OriginalRequiredSkills] set',
        props<{ skills: Skill[] }>(),
    ),
    setSuccessFactorsRequiredSkills: createAction('[SuccessFactors RequiredSkills] set', props<{ skills: Skill[] }>()),
    setSuccessFactorsOriginalNTHSkills: createAction(
        '[SuccessFactors OriginalNTHSkills] set',
        props<{ skills: Skill[] }>(),
    ),
    setSuccessFactorsNTHSkills: createAction('[SuccessFactors NTHSkills] set', props<{ skills: Skill[] }>()),
    resetSuccessFactorsSkills: createAction('[SuccessFactors Skills] reset'),
    loadSuccessFactorsUserData: createAction('[SuccessFactors User Data] load'),
    loadSuccessFactorsUserDataSuccess: createAction(
        '[SuccessFactors Data][Success] load',
        props<{ skills: Skill[]; imageUrl: string; displayName: string }>(),
    ),
    loadSuccessFactorsUserDataError: createAction('[SuccessFactors User Data] Error'),
    syncSuccessFactorsSkills: createAction('[SuccessFactors SyncSkills] sync', props<{ skills: Skill[] }>()),
    setTopicForSync: createAction('[SuccessFactors SetTopicForSync][Success] sync', props<{ name: string }>()),
    removeTopicFromSync: createAction('[SuccessFactors RemoveTopicFromSync][Success] sync', props<{ name: string }>()),
};
