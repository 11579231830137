export default {
    MENTORING: 'Mentoring',
    MENTORS: 'Mentors',
    MENTEES: 'Mentees',
    MENTORING_INFO:
        'Are you tired of Excel spreadsheets and expensive mentoring searches? With the help of this module, employees can come together on their own for mentoring tandems. The matching system brings together colleagues who can help each other solve problems and reach goals, as well as share their valuable knowledge.',

    SIGN_UP_MENTORING_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_SUBTITLE: 'For professional and / or personal development: Be or find a mentor.',
    SIGN_UP_MENTORING_CONTENT_TITLE: 'What is your interest?',
    MENTORING_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Find a mentor',
    MENTORING_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Offer myself as a mentor',
    MENTORING_MOTIVATION_VALIDATION_REQUIRED: 'You must select at least one option.',
    MENTORING_PROFILE_SKILLS_INTERESTED_IN_VALIDATION_REQUIRED: 'This field is required. Please fill in.',

    MENTORING_PROFILE_TITLE: 'Mentoring',
    MENTORING_PROFILE_SUBTITLE_FIND_MENTOR: 'Find a mentor.',
    MENTORING_PROFILE_SUBTITLE_FIND_MENTEE: 'Offer myself as a mentor.',
    MENTORING_PROFILE_MOTIVATION_SUBTITLE: "Choose what you're interested in. You can also activate both options.",
    MENTORING_PROFILE_SKILLS_OFFERING_LABEL: 'Which skills can you contribute as a mentor?',
    MENTORING_PROFILE_SKILLS_INTERESTED_IN_LABEL: 'Which skills should your mentor ideally bring along?',
    MENTORING_PROFILE_LOCATION_LABEL: 'My location',
    MENTORING_PROFILE_COMMUNICATE_OPTIONS_LABEL: 'How do you want to communicate?',
    MENTORING_PROFILE_COMMUNICATE_OPTION_PERSONAL: 'In person on my location',
    MENTORING_PROFILE_COMMUNICATE_OPTION_VIRTUAL: 'Virtual',
    MENTORING_PROFILE_COMMUNICATE_OPTION_BOTH: 'Both is possible',
    MENTORING_PROFILE_PERSONAL_MOTIVATION_CAREER_OPTION: 'Advance career',
    MENTORING_PROFILE_PERSONAL_MOTIVATION_EXPERTISE_OPTION: 'Professional development/specialization',
    MENTORING_PROFILE_OFFERING_SUPPORT_LABEL: 'In which fields could you offer support?',
    MENTORING_PROFILE_INTERESTED_IN_SUPPORT_LABEL: 'What should the mentor support you with?',

    MENTORING_PROFILE_FIND_MENTOR_CAMPAIGNS_LABEL: 'HR programs you participate in as a mentee',
    MENTORING_PROFILE_FIND_MENTEE_CAMPAIGNS_LABEL: 'HR programs you participate in as a mentor',
    MENTORING_PROFILE_CAMPAIGNS_PLACEHOLDER: 'choose..',

    MENTORING_MATCHES_TAB: 'Matches',
    MENTORING_BOOKMARKS_TAB: 'Bookmarks',
    MENTORING_SEARCH_TAB: 'Search',

    MENTORING_PROFILE_VIEW_OFFERED_SKILLS: 'I support mentees in the field of',
    MENTORING_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Interested in mentoring in the field of',
    MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_OFFERING: 'Preferred communication channel with mentees',
    MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_INTEREST: 'Preferred communication channel with mentors',
    MENTORING_PROFILE_VIEW_CAMPAIGNS_OFFERING: 'HR programs  I participate as a mentor',
    MENTORING_PROFILE_VIEW_CAMPAIGNS_INTEREST: 'HR programs  I participate as a mentee',
    MENTORING_PROFILE_VIEW_COMMUNICATION_IN_PERSON: 'personal',
    MENTORING_PROFILE_VIEW_COMMUNICATION_VIRTUAL: 'virtual',
    MENTORING_PROFILE_VIEW_COMMUNICATION_BOTH: 'both personal and remote',

    MENTORING_SEARCH_HEADER: 'Search for Mentoring profiles by keywords',
    MENTORING_SEARCH_EMPTY_MESSAGE:
        'Unfortunately nothing was found. Tip: Searching without a search term shows all mentoring profiles.',
    MENTORING_SEARCH_BTN_TEXT: 'Search!',
    MENTORING_SEARCH_NO_RESULT_IMG_ALT:
        'Image shows a placeholder which appears instead of results, because the search did not return any.',
    MENTORING_SEARCH_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    MENTORING_FILTER_OPTION_ALL: 'All',
    MENTORING_FILTER_OPTION_MENTORS: 'Mentors',
    MENTORING_FILTER_OPTION_MENTEES: 'Mentees',

    MENTORING_NO_MATCH_IMG_ALT: 'No matches found',
    MENTORING_NO_MATCH_CONTENT: 'Please specify more skills and (hidden?) talents in your profile to find matches ',
    MENTORING_NO_MATCH_CTA: 'Edit skills',
    MENTORING_NO_MATCH_FIND_CONTENT: 'Find more colleagues by using the search',

    MENTORING_NO_BOOKMARKS_TITLE: 'No profiles bookmarked yet',
    MENTORING_NO_BOOKMARKS_SUBTITLE: 'Here you can see your bookmarked colleagues in the field of mentoring.',

    MENTORING_BOOKMARKS_MENTORS_TITLE: 'Mentors',
    MENTORING_BOOKMARKS_MENTEES_TITLE: 'Mentees',

    PAGE_TITLE_MENTORING_MATCHES: 'Mentoring matches',
    PAGE_TITLE_MENTORING_BOOKMARKS: 'Mentoring bookmarks',
    PAGE_TITLE_MENTORING_SEARCH: 'Mentoring search',
};
