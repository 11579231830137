import { Injectable, ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { BookmarksState, selectBookmarkByEntityId } from './bookmarks.reducer';
import { Bookmark } from './bookmark.types';
import { BookmarkActions } from './bookmark.actions';

@Injectable({
    providedIn: 'root',
})
export class BookmarksApi {
    constructor(private store: Store<{ bookmarks: BookmarksState }>, private errorHandler: ErrorHandler) {}

    getBookmark(accountId: string): Observable<Bookmark> {
        return this.store
            .select((state) => state.bookmarks)
            .pipe(
                tap((state: BookmarksState) => this.checkState(state)),
                filter((state) => state.status.loaded),
                select(selectBookmarkByEntityId, accountId),
                catchError(() => {
                    return of(null);
                }),
                take(1),
            );
    }

    private checkState(state: BookmarksState): void {
        if (state.status.loadingError) {
            throw state.status.loadingError;
        }

        if (!state.status.loaded && !state.status.loading) {
            this.store.dispatch(BookmarkActions.load({ context: null }));
        }
    }
}
