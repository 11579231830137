abstract class JobXBadRequestException extends Error {
    constructor(message: string, apiError?: Error) {
        let extra: string;
        const additionalInformation = apiError ? apiError.message || null : null;

        if (additionalInformation) {
            extra = ` Here is what the service has returned: ${additionalInformation}.`;
        } else {
            extra = ``;
        }

        super(`${message}${extra}`);
    }
}

export class JobXUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError ? originalError.message : 'Something unexpected has happened.');
    }
}

// Profile related exceptions
export class JobXProfileBadRequestException extends JobXBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the profile. Please check the data.', apiError);
    }
}

export class JobXMyProfileNotFoundException extends Error {
    constructor() {
        super(`The JobX profile for the currently logged-in user was not found.`);
    }
}

export class JobXOthersProfileNotFoundException extends Error {
    constructor() {
        super(`The requested JobX profile was not found.`);
    }
}

// Entity related exceptions
export class JobXEntityBadRequestException extends JobXBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the JobX entity. Please check the data.', apiError);
    }
}

export class JobXEntityNotFoundException extends Error {
    constructor() {
        super('The requested JobX entity was not found.');
    }
}

export class JobXEntityForbiddenException extends Error {
    constructor() {
        super('A user with no organization or administration permission has tried modifying a JobX entity.');
    }
}

// Domain related exceptions

export class JobXDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for JobX entities.');
    }
}

export class JobXDomainIndexException extends Error {
    constructor() {
        super('An unexpected error has occured while loading JobX entities.');
    }
}

export class JobXDomainMatchingException extends Error {
    constructor() {
        super('An unexpected error has occured while querying matches.');
    }
}
