<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-string-list-field
        *ngIf="hasInterestSkills()"
        key="WORKSHOP_PROFILE_VIEW_INTERESTED_IN_SKILLS"
        [value]="interestedInSkills"
    ></tp-profile-view-string-list-field>
    <tp-profile-view-list-field
        *ngIf="hasInterestCategories()"
        key="WORKSHOP_PROFILE_VIEW_INTERESTED_IN_CATEGORIES"
        [value]="interestInCategories"
    ></tp-profile-view-list-field>
    <tp-profile-view-string-list-field
        *ngIf="hasSkills()"
        key="WORKSHOP_PROFILE_VIEW_SKILLS"
        [value]="skills"
    ></tp-profile-view-string-list-field>
</tp-profile-view-fieldset>
