import { AccountState, isAccountSyncCompleted, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { TopicWorkshopState } from './workshop/store/topic-workshop.reducer';
import { take, filter, map, catchError, tap } from 'rxjs/operators';
import { WorkshopProfileActions } from './workshop/store/workshop-profile.actions';
import { Injectable } from '@angular/core';
import { WorkshopProfileService } from './workshop/services/workshop-profile.service';
import { WorkshopProfile } from './workshop/models/workshop-profile.types';
import { WorkshopNotificationService } from './workshop/services/workshop-notification.service';

@Injectable({
    providedIn: 'root',
})
export class WorkshopTopicEventHandlers implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ topicWorkshop: TopicWorkshopState; account: AccountState }>,
        private readonly service: WorkshopProfileService,
        private readonly workshopNotificationService: WorkshopNotificationService,
    ) {}

    onProfileSave(): Observable<void> {
        this.store.dispatch(WorkshopProfileActions.save());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<WorkshopProfile> {
        const loaded = this.readLoadedState();

        if (!loaded) {
            this.store.dispatch(WorkshopProfileActions.load());
        }

        return this.store
            .select((state) => state.topicWorkshop.workshopProfile)
            .pipe(
                filter((state) => state.loaded),
                map((state) => state.current),
                catchError(() => of(null)),
                take(1),
            );
    }

    onOthersProfileLoad(id: string): Observable<WorkshopProfile> {
        return this.service.loadById(id).pipe(
            catchError(() => of(null)),
            take(1),
        );
    }

    onCheckNotification(): void {
        const loaded = this.readLoadedState();

        if (!loaded) {
            this.store.dispatch(WorkshopProfileActions.load());
        }

        const subscription = this.store
            .select((state) => state.topicWorkshop.workshopProfile)
            .pipe(
                filter((state) => state.loaded),
                tap((state) => {
                    this.workshopNotificationService.checkProfile(state.current);
                    subscription.unsubscribe();
                }),
                catchError(() => of(null)),
            )
            .subscribe();
    }

    private readDirtyState(): boolean {
        let isDirty;
        this.store
            .select((state) => state.topicWorkshop.workshopProfile.dirty)
            .pipe(take(1))
            .subscribe((value) => (isDirty = value));

        return isDirty;
    }

    private readLoadedState(): boolean {
        let loaded: boolean;
        this.store
            .select((state) => state.topicWorkshop.workshopProfile)
            .pipe(
                map((state) => state.loaded || state.loading),
                take(1),
            )
            .subscribe((value) => (loaded = value));

        return loaded;
    }

    private waitOnPristine(): Observable<void> {
        return this.store
            .select((state) => state.topicWorkshop.workshopProfile)
            .pipe(
                filter((workshopProfileState) => {
                    if (!workshopProfileState.dirty) {
                        return true;
                    }

                    if (workshopProfileState.savingError) {
                        throw workshopProfileState.savingError;
                    }

                    return false;
                }),
                map(() => undefined),
                take(1),
            );
    }

    onProfileSync() {
        this.store.dispatch(WorkshopProfileActions.syncProfileSkills());
        return this.waitOnSync();
    }

    private waitOnSync(): Observable<void> {
        return this.store.select(isAccountSyncCompleted).pipe(
            filter((loaded) => !loaded),
            map(() => undefined),
            take(1),
        );
    }
}
