import { Injectable } from '@angular/core';
import { WorkshopProfile } from '../models/workshop-profile.types';
import {
    INTEREST_KEY,
    NotificationsService,
    StorageService,
    TopicRegistryService,
    WORKSHOPS_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { Router } from '@angular/router';
import { WORKSHOP_NOTIFICATION_SHOWN_STORAGE_KEY } from '../models/workshop.types';

@Injectable({
    providedIn: 'root',
})
export class WorkshopNotificationService {
    constructor(
        private readonly notificationsService: NotificationsService,
        private readonly topicRegistryService: TopicRegistryService,
        private readonly router: Router,
        private readonly storage: StorageService,
    ) {}

    checkProfile(profile: WorkshopProfile): void {
        if (this.isShowProfileNotification(profile) && !this.isNotificationShown()) {
            this.openNotification();
        }
    }

    isShowProfileNotification(profile: WorkshopProfile): boolean {
        return (
            profile && profile.skillsInterestedIn?.length === 0 && profile.selectedMotivations?.includes(INTEREST_KEY)
        );
    }

    private openNotification() {
        this.notificationsService.openActionSnack('WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE', {
            label: 'WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE_BTN',
            callback: () => {
                this.setNotificationShown();
                return this.navigateToProfileEdit();
            },
        });
    }

    private navigateToProfileEdit(): Promise<boolean> {
        return this.router.navigate(
            this.topicRegistryService.selectTopicDefinition(WORKSHOPS_TOPIC_NAME).links.edition,
        );
    }

    private setNotificationShown(): void {
        this.storage.local.set(WORKSHOP_NOTIFICATION_SHOWN_STORAGE_KEY, true);
    }

    private isNotificationShown(): boolean {
        return this.storage.local.get(WORKSHOP_NOTIFICATION_SHOWN_STORAGE_KEY);
    }
}
