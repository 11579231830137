import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ErrorHandler, Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchActions } from '../actions/search.actions';
import { SearchService } from '../service/search.service';

@Injectable()
export class SearchEffects {
    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private service: SearchService,
        private errorHandler: ErrorHandler,
    ) {}

    performContactsSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchActions.performContactsSearch),
            switchMap((searchQuery) =>
                this.service.contactsSearch(searchQuery.searchQuery).pipe(
                    map((searchResult) => SearchActions.performContactsSearchSuccess({ results: searchResult })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(SearchActions.performContactsSearchError({ error }));
                    }),
                ),
            ),
        ),
    );
}
