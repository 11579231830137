<div>
    <h2>Wie nutze ich die Tandemploy SaaS?</h2>
    <div tpCollapsable class="section" data-ng-init="faq1.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq1.isCollapsed = !faq1.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Was ist die Tandemploy SaaS (= Software as a Service)? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Die SaaS ist ein geschlossener, unternehmensinterner Raum, in dem Sie sich mit Ihren Kolleg*innen zu
                    flexiblen Arbeitsmodellen und New Work Angeboten austauschen und auch direkt zusammenfinden können.
                    Die Tandemploy SaaS lebt von Ihrer Initiative und wird nicht zentral von Ihrer Personalabteilung
                    gesteuert oder verwaltet. Dies ist Ihr Raum, um die Digitalisierung und die Wege "neuer Arbeit" in
                    Ihrem Unternehmen aktiv mitzugestalten.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq2.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq2.isCollapsed = !faq2.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Welche Arbeitsformen bzw. Modelle lassen sich mit der Tandemploy SaaS organisieren? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    In der Tandemploy SaaS können Sie sich mit Kolleg*innen für einen einfachen und alltäglichen
                    Wissensaustausch und zur gegenseitigen Unterstützung vernetzen. Sie können außerdem Kolleg*innen
                    finden, mit denen Sie eine Aufgabe teilen (Jobsharing), einen Job tauschen (Jobrotation), ein
                    Mentoring/Peer-Learning organisieren, einen Working Circle initiieren oder aber gemeinsam Projekte
                    angehen.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq3.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq3.isCollapsed = !faq3.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kommt ein Erstkontakt mit Kolleg*innen zustande? Wie finde ich spannende Mitglieder? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sobald Sie ein spannendes Profil finden, können Sie es als „interessant“ markieren, indem Sie auf
                    den Stern klicken. Sie können auch über die Nachrichtenfunktion direkt mit der Person in Kontakt
                    treten. Wenn Sie nach dem Erstkontakt den Eindruck haben, dass es zwischen Ihnen „passen“ könnte,
                    verabreden Sie sich doch beispielsweise zum persönlichen Kennenlernen einfach auf einen Kaffee oder
                    zum gemeinsamen Lunch.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq4.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq4.isCollapsed = !faq4.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kann ich die Funktion „Projekte“ nutzen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie planen ein Projekt, für das Sie Mitstreiter mit bestimmten Qualifikationen und Erfahrungen
                    suchen? Dann haben Sie hier den Raum, Ihr Projekt vorzustellen und Kolleg*innen dafür zu begeistern.
                    Zudem können Sie als Mitarbeiter*in auch selber einfach und direkt Initiativen einbringen und
                    starten – Bottom-up und ohne, dass die äußeren Rahmenbedingungen schon abgesteckt sein müssen.
                    Finden Sie Mitstreiter*innen für Ihre Idee und legen Sie los!
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq5.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq5.isCollapsed = !faq5.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kann ich die Funktion „Mentoring/Peer-Learning“ nutzen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie hätten Freude daran, Ihr besonderes (Experten)Wissen bzw. Ihre Erfahrungen in einem bestimmten
                    Bereich an andere weiterzugeben und zu teilen? Oder suchen Sie innerhalb des Unternehmens jemanden,
                    den Sie bei bestimmten Themen um Rat fragen können? Dann haben Sie hier die Möglichkeit, einen
                    Mentee oder Mentor zu finden.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq6.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq6.isCollapsed = !faq6.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kann ich die Funktion „Jobrotation“ nutzen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie möchten gerne für eine gewisse Zeit in einen anderen Aufgabenbereich oder eine andere Abteilung
                    hineinschnuppern oder könnten sich sogar vorstellen, Ihren Job zeitweise zu tauschen? Oder gibt es
                    im Team eine Aufgabe, bei der Sie sehr vom frischen Blick und Wissen eines Kollegen aus einer
                    anderen Abteilung profitieren würden? Dann haben Sie hier die Option, eine Jobrotation zu
                    organisieren. Diese kann sowohl einseitig als auch beidseitig erfolgen.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq7.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq7.isCollapsed = !faq7.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kann ich die Funktion „Jobsharing“ nutzen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie interessieren sich für ein Jobsharing und können sich vorstellen, einen - oder Ihren - Job zu
                    teilen? Dann bietet Ihnen die Software die Möglichkeit, passende Tandempartner*innen innerhalb des
                    Unternehmens zu finden. Ein cleveres Matching schlägt Ihnen sogar automatisch passende Kolleg*innen
                    vor, die in ähnlichen Bereichen suchen und auch hinsichtlich der "richtigen Chemie" gut zu Ihnen
                    passen könnten. Sie können sich über die Nachrichtenfunktion austauschen und auf Wunsch auch Ihre
                    Profile zu einem Tandemprofil verschmelzen lassen.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq8.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq8.isCollapsed = !faq8.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Weitere Informationen: </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Weitere inhaltliche und organisatorische Tipps zu den Themen Jobsharing, Jobrotation,
                    Mentoring/Peer-Learning und Projekten finden Sie auch in Artikeln auf dem Dashboard der SaaS. Dort
                    erhalten Sie außerdem regelmäßig Input rund um New Work, Digitalisierung und Wissenstransfer.
                </div>
            </div>
        </div>
    </div>
    <h2>Technische Fragen zur Plattform</h2>
    <div tpCollapsable class="section" data-ng-init="faq9.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq9.isCollapsed = !faq9.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Was passiert, nachdem ich mich registriert habe? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Nachdem Sie sich mit Ihrer E-Mail-Adresse registriert haben, können und sollten Sie einige Angaben
                    zu Ihren Interessen und Ihrer Person machen. Ihre Antworten sind für ein optimales Matching mit
                    passenden Kolleg*innen wichtig. Basierend auf Ihrem Profil bekommen Sie dann automatisch passende
                    Vorschläge.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq10.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq10.isCollapsed = !faq10.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie funktioniert das Matching? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sobald Sie ein Profil angelegt und dieses möglichst vollständig ausgefüllt haben, beginnt der
                    Matching-Algorithmus zu arbeiten. Unter dem Menüpunkt „Meine Kollegen” erhalten Sie Vorschläge, die
                    grundsätzlich zu Ihnen und Ihren Interessen passen könnten. Neben den eher „harten Faktoren“ liegt
                    je nach gewünschtem Modell (Projekt, Mentoring, Jobrotation oder Jobsharing) ein besonderer Fokus
                    auf den „weichen Faktoren“. Diese werden in dem Matching-Fragebogen abgefragt und abhängig von dem
                    gewählten Modell unterschiedlich gewichtet.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq11.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq11.isCollapsed = !faq11.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Wie kann ich zu anderen Mitgliedern Kontakt aufnehmen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie können ganz einfach über die Nachrichten-Funktion mit anderen Mitgliedern Kontakt aufnehmen.
                    Dazu können Sie einfach im Profil eines Kollegen auf „Neue Nachricht“ klicken und eine Nachricht
                    schreiben. Ihre Nachrichten im Überblick finden Sie unter dem Menüpunkt „Meine Nachrichten“.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq12.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq12.isCollapsed = !faq12.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Was mache ich bei technischen Problemen? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie haben in der Software ein technisches Problem festgestellt und können Funktionen nicht in vollem
                    Umfang nutzen? Dann überprüfen Sie bitte zuerst, ob Ihre Browserversion auf dem neusten Stand ist.
                    Schließen Sie Ihren Browser und öffnen Sie ihn erneut. Sollte dies nicht helfen, starten Sie Ihren
                    PC einmal neu. Wenn das Problem dann noch immer besteht, kontaktieren Sie bitte den technischen
                    Support unter
                    <a href="mailto:support@tandemploy.com">support@tandemploy.com</a>.
                </div>
            </div>
        </div>
    </div>
    <h2>Fragen zum Datenschutz</h2>
    <div tpCollapsable class="section" data-ng-init="faq13.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq13.isCollapsed = !faq13.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Kann ich mit meinem Profil auch anonym bleiben? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Sie können den Grad Ihrer Anonymität selbst bestimmen. Wenn Sie anonym bleiben möchten, können Sie
                    ein Fantasie-Benutzername wählen, der keine eindeutigen Rückschlüsse auf Sie zulässt. Außerdem
                    besteht die Möglichkeit einen Avatar oder ein abstraktes Bild (ohne Porträt) hochzuladen. Die
                    Erfahrung zeigt jedoch, dass Profile von Kollegen, die ein Bild von sich hochladen, wesentlich
                    häufiger besucht werden. Damit Ihnen passende Matches vorgeschlagen werden, füllen Sie Ihr Profil
                    bitte möglichst vollständig aus. Abgesehen von einigen allgemeinen Informationen, können Sie in
                    Ihrer Profilansicht einzelne Angaben für andere Kollegen verbergen. Nutzen Sie dafür das
                    „Schloss-Symbol“ in Ihrer eigenen Profilansicht.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq14.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq14.isCollapsed = !faq14.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span>
                Wie kann ich, wenn ich mein Profil sehr anonym gestalte, dennoch mit anderen Kollegen in Kontakt treten?
            </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Auch wenn Sie in Ihrem Profil erstmal vage bleiben, können Sie über das interne Messaging-System
                    ganz einfach mit spannenden Kolleg*innen in Kontakt treten. Diese sehen nicht Ihre
                    Firmen-E-Mail-Adresse, sondern nur Ihren selbstgewählten Benutzernamen. Ob und wann Sie sich zu
                    erkennen geben, liegt ganz bei Ihnen!
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq15.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq15.isCollapsed = !faq15.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Account löschen </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    <p>Nutzer/innen können ihren Account jederzeit selbständig löschen.</p>
                    <p>
                        Mit der Löschung Accounts werden alle personenbezogenen und sonstigen Profildaten des Users im
                        Tandemploy-System vollständig gelöscht.
                    </p>
                    <p>
                        Wenn Sie eine zeitlang inaktiv sind und keine Matches benötigen, können Sie unnötige Mails ganz
                        einfach unter „E-Mail-Benachrichtigungen” in den Benutzereinstellungen abbestellen.
                    </p>
                    <p>Die Account-Löschung kann dort im Bereich “Benutzerkonto löschen” durchgeführt werden.</p>
                </div>
            </div>
        </div>
    </div>
</div>
