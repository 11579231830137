import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    OnInit,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import { PreviewItemComponent } from '../preview-item.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tp-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnChanges, OnInit {
    @ViewChild('videoEl', { static: true })
    ref: ElementRef<HTMLVideoElement>;

    videoElement: HTMLVideoElement;

    @Input()
    video: PreviewItemComponent;

    constructor(protected cd: ChangeDetectorRef, private translateService: TranslateService) {}

    ngOnInit(): void {
        this.videoElement = this.ref.nativeElement;
        this.videoElement.addEventListener('loadedmetadata', () => {
            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.video && !changes.video.firstChange) {
            this.stopCurrentVideo();
            this.videoElement.load();
        }
    }

    private stopCurrentVideo(): void {
        if (!this.videoElement.paused) {
            this.videoElement.pause();
        }
    }
}
