import { LocationDTO } from '../../location';

export const GENERAL_DATA_STORAGE_EDIT_KEY = 'tploy.generalData.edit';

export enum Gender {
    'MALE' = 'male',
    'FEMALE' = 'female',
    'OTHER' = 'other',
    'NOT_SPECIFIED' = 'not_specified',
}

export interface GeneralData {
    id?: string;
    name: string;
    imageUrl: string;
    locations: string[];
    position: string;
    languages: string[];
    department: string;
    company: string;
    gender?: Gender;
    yearOfBirth?: number;
    message?: string;
    groups?: string[];
    externalSkills?: string[];
    enableSync: boolean;
    profileUrl?: string;
}

export interface ContactAccountGeneralData {
    name: string;
    imageUrl: string;
}

export interface ContactAccountGeneralDataDto {
    name: string;
    imageUrl: string;
}

export interface GeneralDataDTO {
    id?: string;
    name: string;
    imageUrl: string;
    locations: Array<LocationDTO>;
    position: string;
    languages: string[];
    department: string;
    company: string;
    gender?: Gender;
    yearOfBirth?: string;
    message?: string;
    groups?: string[];
    externalSkills?: string[];
    enableSync: boolean;
}
