import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxListOption, CheckboxListOptions } from '../checkbox-tools.types';

@Component({
    selector: 'tp-checkbox-list',
    templateUrl: './checkbox-list.component.html',
    styleUrls: ['./checkbox-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxListComponent),
            multi: true,
        },
    ],
})
export class CheckboxListComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    options: CheckboxListOptions;

    @Input()
    formControl: FormControl;

    @Input()
    overwriteDisabledOptions = true;

    @Input()
    set multiple(value: boolean) {
        this._multiple = value;
        this.changeDetectorRef.markForCheck();
    }
    get multiple(): boolean {
        return this._multiple;
    }
    private _multiple = true;

    @Input()
    set maxSelection(value: number) {
        this._maxSelection = value;
        this.multiple = value > 1;
    }
    get maxSelection(): number {
        return this._maxSelection;
    }
    private _maxSelection: number;

    @Input()
    allowUnselectNonMultipleOptions = false;

    @Output()
    maxSelectionExceeded = new EventEmitter();

    private selection: CheckboxListOptions = [];
    private notifyOnChange: (value: CheckboxListOptions) => void;

    get emptyRadioOption(): CheckboxListOption {
        return { label: { message: 'QUESTIONNAIRE_EMPTY_RADIO_OPTION' }, name: 'emptyOption' };
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    registerOnChange(fn: (value?: unknown) => void): void {
        this.notifyOnChange = fn;
    }

    registerOnTouched(fn: (value?: unknown) => void): void {
        this.registerOnTouched = fn;
    }

    writeValue(value: CheckboxListOptions) {
        const disabledOptions = this.options.filter((option) => option.disabled);
        const optionalOptions = value.filter((option) => !option.disabled);
        this.selection = this.overwriteDisabledOptions ? [...disabledOptions, ...optionalOptions] : value;
        this.changeDetectorRef.markForCheck();
    }

    contains(option: CheckboxListOption): boolean {
        return this.selection.findIndex((selectedOption) => selectedOption.name === option.name) !== -1;
    }

    selectionEmpty(): boolean {
        return this.selection.length < 1;
    }

    clearSelection(): void {
        this.selection = [];
        this.notifyOnChange(this.selection);
    }

    toggleOption(option: CheckboxListOption): void {
        if (this.multiple) {
            if (option.disabled) {
                return;
            } else if (this.contains(option)) {
                this.removeOption(option);
            } else {
                this.addOption(option);
            }
        }
    }

    setOption(option: CheckboxListOption) {
        if (this.multiple) {
            return;
        }

        if (this.allowUnselectNonMultipleOptions) {
            this.selection = this.contains(option) ? [] : [option];
        } else {
            this.selection = [option];
        }

        this.notifyOnChange(this.selection);
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    private removeOption(option: CheckboxListOption) {
        this.selection = this.selection.filter((selectedOption) => selectedOption.name !== option.name);
        this.notifyOnChange(this.selection);
    }

    private addOption(option: CheckboxListOption) {
        if (!this.maxSelection || this.selection.length < this.maxSelection) {
            this.selection = [...this.selection, option];
            this.notifyOnChange(this.selection);
            this.changeDetectorRef.markForCheck();
        } else {
            this.maxSelectionExceeded.emit();
        }
    }
}
