import { createReducer, on } from '@ngrx/store';
import {
    DirectMessagesCreateConversationActions,
    DirectMessagesJoinConversationActions,
    DirectMessagesLeaveConversationActions,
    DirectMessagesLoadConversationActions,
    DirectMessagesLoadConversationsActions,
    DirectMessagesMuteConversationActions,
    DirectMessagesOwnershipPermissionsActions,
    DirectMessagesReceiveMessageActions,
    DirectMessagesSendMessageActions,
    DirectMessagesUnreadMessagesActions,
    DirectMessagesWritePermissionsActions,
} from '../actions';
import { directMessagesInitialState } from '../state/direct-messages-initial.state';

export const directMessagesReducer = createReducer(
    directMessagesInitialState,

    // Create Conversation Actions
    on(
        DirectMessagesCreateConversationActions.createConversationWithForceLoad,
        DirectMessagesCreateConversationActions.createConversation,
        (state) => {
            return { ...state, loading: true };
        },
    ),
    on(DirectMessagesCreateConversationActions.createConversationSuccess, (state, { conversations }) => {
        return {
            ...state,
            loading: false,
            conversations,
        };
    }),
    on(DirectMessagesCreateConversationActions.createConversationError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Load Conversations Actions
    on(DirectMessagesLoadConversationsActions.loadConversations, (state) => {
        return { ...state, conversationsLoading: true };
    }),
    on(DirectMessagesLoadConversationsActions.loadConversationsSuccess, (state, { conversations }) => {
        return {
            ...state,
            conversationsLoading: false,
            conversationsLoaded: true,
            loadingError: null,
            conversations,
        };
    }),
    on(DirectMessagesLoadConversationsActions.loadConversationsError, (state, { error }) => {
        return {
            ...state,
            loadingError: error,
            conversationsLoading: false,
            conversationsLoaded: false,
        };
    }),

    // Load Conversation Actions
    on(DirectMessagesLoadConversationActions.loadConversation, (state) => {
        return {
            ...state,
            currentConversationLoading: true,
            currentConversationLoaded: false,
        };
    }),
    on(
        DirectMessagesLoadConversationActions.loadConversationSuccess,
        (state, { currentConversation, conversations }) => {
            return {
                ...state,
                currentConversationLoading: false,
                currentConversationLoaded: true,
                currentConversation,
                conversations,
            };
        },
    ),
    on(DirectMessagesLoadConversationActions.loadConversationError, (state, { error }) => {
        return {
            ...state,
            currentConversationLoading: false,
            currentConversationLoaded: false,
            currentConversationLoadingError: error,
        };
    }),
    on(DirectMessagesLoadConversationActions.unloadConversationSuccess, (state) => {
        return {
            ...state,
            currentConversation: null,
        };
    }),

    // Join Conversation Actions
    on(DirectMessagesJoinConversationActions.joinConversationSuccess, (state, { conversation }) => {
        return {
            ...state,
            conversations: [...state.conversations, conversation],
        };
    }),
    on(
        DirectMessagesJoinConversationActions.joinGroupConversationOtherSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                conversations,
                currentConversation,
            };
        },
    ),

    // Leave Conversation Actions
    on(DirectMessagesLeaveConversationActions.leaveConversation, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesLeaveConversationActions.leaveConversationSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                loading: false,
                conversations,
                currentConversation,
            };
        },
    ),
    on(DirectMessagesLeaveConversationActions.leaveConversationError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    on(DirectMessagesLeaveConversationActions.leaveConversationOther, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesLeaveConversationActions.leaveConversationOtherSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                loading: false,
                conversations,
                currentConversation,
            };
        },
    ),
    on(DirectMessagesLeaveConversationActions.leaveConversationOtherError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    on(DirectMessagesLeaveConversationActions.removeFromConversation, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesLeaveConversationActions.removeFromConversationSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                loading: false,
                conversations,
                currentConversation,
            };
        },
    ),
    on(DirectMessagesLeaveConversationActions.removeFromConversationError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Unread Messages Count Actions
    on(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCount, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCountSuccess, (state, { count }) => {
        return {
            ...state,
            loading: false,
            unreadMessages: count,
        };
    }),
    on(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCountError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Mark Message As Read Actions
    on(DirectMessagesUnreadMessagesActions.markMessageAsRead, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(
        DirectMessagesUnreadMessagesActions.markMessageAsReadSuccess,
        (state, { unreadMessages, currentConversation, conversations }) => {
            return {
                ...state,
                loading: false,
                unreadMessages,
                conversations,
                currentConversation,
            };
        },
    ),
    on(DirectMessagesUnreadMessagesActions.markMessageAsReadError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Send Message Actions
    on(DirectMessagesSendMessageActions.sendMessage, (state) => ({ ...state, loading: true })),
    on(DirectMessagesSendMessageActions.sendMessageError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Receive Message Actions
    on(DirectMessagesReceiveMessageActions.receiveMessage, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(
        DirectMessagesReceiveMessageActions.receiveMessageSuccess,
        (state, { unreadMessages, currentConversation, conversations }) => {
            return {
                ...state,
                loading: false,
                unreadMessages,
                conversations,
                currentConversation,
            };
        },
    ),
    on(DirectMessagesReceiveMessageActions.receiveMessageError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Mute Conversation Actions
    on(DirectMessagesMuteConversationActions.muteConversation, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(
        DirectMessagesMuteConversationActions.muteConversationSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                conversations,
                currentConversation,
                loading: false,
            };
        },
    ),
    on(DirectMessagesMuteConversationActions.muteConversationError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Unmute Conversation Actions
    on(DirectMessagesMuteConversationActions.unmuteConversation, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(
        DirectMessagesMuteConversationActions.unmuteConversationSuccess,
        (state, { conversations, currentConversation }) => {
            return {
                ...state,
                conversations,
                currentConversation,
                loading: false,
            };
        },
    ),
    on(DirectMessagesMuteConversationActions.unmuteConversationError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Enable Write Permissions Actions
    on(DirectMessagesWritePermissionsActions.enableWritePermissions, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesWritePermissionsActions.enableWritePermissionsSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesWritePermissionsActions.enableWritePermissionsError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Enable Write Permissions Others Actions
    on(DirectMessagesWritePermissionsActions.enableWritePermissionsOthers, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesWritePermissionsActions.enableWritePermissionsOthersSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesWritePermissionsActions.enableWritePermissionsOthersError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Disable Write Permissions Actions
    on(DirectMessagesWritePermissionsActions.disableWritePermissions, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesWritePermissionsActions.disableWritePermissionsSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesWritePermissionsActions.disableWritePermissionsError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Disable Write Permissions Others Actions
    on(DirectMessagesWritePermissionsActions.disableWritePermissionsOthers, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesWritePermissionsActions.disableWritePermissionsOthersSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesWritePermissionsActions.disableWritePermissionsOthersError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Promote User to Owner Actions
    on(DirectMessagesOwnershipPermissionsActions.promoteUserToOwner, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesOwnershipPermissionsActions.promoteUserToOwnerSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesOwnershipPermissionsActions.promoteUserToOwnerError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Demote User from Owner Actions
    on(DirectMessagesOwnershipPermissionsActions.demoteUserFromOwner, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesOwnershipPermissionsActions.demoteUserFromOwnerSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesOwnershipPermissionsActions.demoteUserFromOwnerError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Promote Others to Owner Actions
    on(DirectMessagesOwnershipPermissionsActions.promotedToOwner, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesOwnershipPermissionsActions.promotedToOwnerSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesOwnershipPermissionsActions.promotedToOwnerError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),

    // Demote Others from Owner Actions
    on(DirectMessagesOwnershipPermissionsActions.demotedFromOwner, (state) => {
        return { ...state, loading: true };
    }),
    on(
        DirectMessagesOwnershipPermissionsActions.demotedFromOwnerSuccess,
        (state, { conversations, currentConversation }) => {
            return { ...state, conversations, currentConversation, loading: false };
        },
    ),
    on(DirectMessagesOwnershipPermissionsActions.demotedFromOwnerError, (state, { error }) => {
        return {
            ...state,
            loading: false,
            loadingError: error,
        };
    }),
);
