import { NgModule } from '@angular/core';
import { FroalaEditorComponent } from './froala-editor.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/languages/de.js';

@NgModule({
    imports: [FroalaEditorModule, FroalaViewModule],
    declarations: [FroalaEditorComponent],
    exports: [FroalaEditorComponent],
})
export class FroalaModule {}
