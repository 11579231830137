<h2 mat-dialog-title>
    {{ 'IMAGE_PICKER_DIALOG_TITLE' | translatable }}
    <tp-icon-button class="no-border" mat-dialog-close [icon]="'close'"></tp-icon-button>
</h2>
<mat-dialog-content class="image-picker-dialog_content">
    <div class="image-picker-dialog_cropper" *ngIf="showCropper">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageFile]="imageFile"
            (imageCropped)="imageCropped($event)"
            [aspectRatio]="1"
            (loadImageFailed)="loadImageFailed()"
            resizeToHeight="400"
            resizeToWidth="400"
        ></image-cropper>
    </div>

    <div class="image-loader" *ngIf="loading || cropping">
        <tp-search-loader [isVisible]="true" class="default-loader"></tp-search-loader>
    </div>

    <div class="image-picker-dialog_button-group" *ngIf="!showCropper">
        <tp-secondary-icon-button
            tpDragImage
            [icon]="'photo_filled'"
            [disabled]="loading"
            (dropFile)="onFileChangeDrag($event)"
            (click)="upload.click()"
            (keydown.enter)="upload.click()"
        >
            {{ 'PROFILE_AVATAR_CHOOSE_OWN' | translatable }}
        </tp-secondary-icon-button>
        <tp-primary-button
            class="image-picker-dialog_button-crop"
            [disabled]="loading"
            (click)="uploadFile(cropImage)"
            *ngIf="showCropper"
        >
            <span>{{ 'PROFILE_AVATAR_TAKE' | translatable }}</span>
        </tp-primary-button>
    </div>

    <span class="alert-error" role="alert" *ngIf="!loading && sizeTooLarge"
        >{{ 'IMAGE_PICKER_IMAGE_TOO_LARGE' | translatable }}
    </span>
    <span class="alert-error" role="alert" *ngIf="!loading && wrongFileType">{{
        'IMAGE_PICKER_IMAGE_WRONG_FILETYPE' | translatable
    }}</span>
    <section class="image-picker-dialog_placeholders" *ngIf="!showCropper">
        <a
            *ngFor="let placeholder of placeholders; let index = index"
            role="button"
            tabindex="0"
            [class.selected]="selectedAvatar === placeholder"
            (click)="select(placeholder)"
            (keydown.enter)="select(placeholder)"
        >
            <img [src]="placeholder" [alt]="index" />
        </a>
    </section>
    <input [formControl]="uploadControl" #upload type="file" accept="image/*" (change)="onFileChange($event)" />
</mat-dialog-content>
<mat-dialog-actions class="form-footer form-footer--buttons-center">
    <nav>
        <ng-container *ngIf="showCropper">
            <tp-secondary-icon-button
                tpDragImage
                [icon]="'photo_filled'"
                [disabled]="loading"
                (dropFile)="onFileChangeDrag($event)"
                (click)="upload.click()"
                (keydown.enter)="upload.click()"
            >
                {{ 'PROFILE_AVATAR_RECHOOSE_OWN' | translatable }}
            </tp-secondary-icon-button>
            <tp-primary-button
                class="image-picker-dialog_button-crop"
                [disabled]="loading"
                (click)="uploadFile(cropImage)"
            >
                {{ 'PROFILE_AVATAR_TAKE' | translatable }}
            </tp-primary-button>
        </ng-container>

        <tp-primary-button
            #selectBtn
            class="image-picker-dialog_save"
            [mat-dialog-close]="selectedAvatar"
            *ngIf="!showCropper"
        >
            {{ (loading ? 'CANCEL' : 'IMAGE_PICKER_DIALOG_BUTTON_TEXT') | translatable }}
        </tp-primary-button>
    </nav>
</mat-dialog-actions>
