<mat-radio-button
    #matRadioButton
    class="list-item-input very-big-radio-button"
    [checked]="selected"
    [disabled]="option.disabled"
    [value]="option"
    aria-labelledby="{{ matRadioButton.id }}-label"
>
</mat-radio-button>
<label id="{{ matRadioButton.id }}-label">{{ option.label | translatable }}</label>
