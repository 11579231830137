<mat-card
    focused
    #container="focusContainer"
    class="account-tile-layout account-tile-nla"
    (click)="openAccountPage($event)"
    (keydown.enter)="openAccountPage()"
    (keydown.meta.enter)="openAccountPage($event)"
    role="group"
    tabindex="0"
    [attr.aria-label]="'MATCHING_RESULT_ARIA_LABEL' | translatable : { userName: user.name }"
>
    <div class="account-tile__image-container">
        <div class="mat-card-image" [ngStyle]="{ 'background-image': 'url(' + user.imageUrl + ')' }"></div>
        <tp-account-list-item-get-to-know
            [userId]="this.user.id"
            [context]="context"
        ></tp-account-list-item-get-to-know>
    </div>

    <mat-card-content>
        <p class="profile-name">{{ user.name }}</p>
        <ng-content></ng-content>
        <p class="profile-tags">
            <tp-tagger *ngIf="tags" [simpleView]="false" [tags]="tags" [selectedTags]="selectedTags"></tp-tagger>
        </p>
        <ng-content select=".account-list-item__campaigns"></ng-content>
        <div class="profile-message" [innerHTML]="profileMessage"></div>
    </mat-card-content>
    <mat-card-actions>
        <tp-icon-button
            [icon]="(bookmark$ | async) ? 'star_filled' : 'star_outline'"
            disableRipple="true"
            class="no-border mat-primary big"
            [title]="getTooltipMessageKey() | translatable"
            (click)="onToggleStar($event)"
            (keydown.enter)="onToggleStar($event)"
            ariaLabel="{{ getTooltipMessageKey() | translatable }}"
        ></tp-icon-button>
        <ng-content select=".account-list-item__buttons"></ng-content>
    </mat-card-actions>
</mat-card>
