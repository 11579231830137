import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UrlUtilService } from '../../url-utils';

export interface PageParams {
    pageIndex: number;
    pageSize: number;
    length?: number;
}

@Component({
    selector: 'tp-matches-slider-view',
    templateUrl: './matches-slider-view.component.html',
    styleUrls: ['./matches-slider-view.component.scss'],
})
export class MatchesSliderViewComponent implements OnChanges {
    @Input() hidePaginator: boolean;
    @Input() isLoading: boolean;
    @Input() matchesTotal: number;
    @Input() pageSize: number;

    @Output() pageChange = new EventEmitter<PageEvent>();
    pageEvent: PageEvent;

    pageParams: PageParams;

    @ViewChild('paginator') paginator: MatPaginator;

    constructor(private readonly urlUtilService: UrlUtilService) {
        this.pageParams = this.urlUtilService.getPageParams(this.pageSize);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            this.pageParams = this.urlUtilService.getPageParams(this.pageSize);
        }
    }

    onPageEvent(pageEvent: PageEvent): void {
        this.pageChange.emit(pageEvent);
    }
}
