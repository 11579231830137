import { CustomSnack, NotificationsData } from './notifications.types';
import { Output, EventEmitter, Inject, Component, ChangeDetectionStrategy } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'tp-notification-error-snack',
    templateUrl: './error-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'snack',
    },
})
export class ErrorSnackComponent implements CustomSnack {
    @Output()
    close = new EventEmitter<void>();

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationsData) {}
}
