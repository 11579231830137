import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const PAGINATOR_OF = 'PAGINATOR_OF';
const PAGINATOR_ITEMS_PER_PAGE = 'PAGINATOR_ITEMS_PER_PAGE';
const PAGINATOR_NEXT_PAGE = 'PAGINATOR_NEXT_PAGE';
const PAGINATOR_PREVIOUS_PAGE = 'PAGINATOR_PREVIOUS_PAGE';
const PAGINATOR_FIRST_PAGE = 'PAGINATOR_PREVIOUS_PAGE';
const PAGINATOR_LAST_PAGE = 'PAGINATOR_PREVIOUS_PAGE';

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(private translateService: TranslateService) {
        super();
        this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const of = this.translateService.instant(PAGINATOR_OF);
        if (length === 0 || pageSize === 0) {
            return `0 ${of} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
    };

    public getAndInitTranslations(): void {
        const translations = this.translateService.instant([
            PAGINATOR_ITEMS_PER_PAGE,
            PAGINATOR_NEXT_PAGE,
            PAGINATOR_PREVIOUS_PAGE,
            PAGINATOR_FIRST_PAGE,
            PAGINATOR_LAST_PAGE,
        ]);
        this.itemsPerPageLabel = translations[PAGINATOR_ITEMS_PER_PAGE];
        this.nextPageLabel = translations[PAGINATOR_NEXT_PAGE];
        this.previousPageLabel = translations[PAGINATOR_PREVIOUS_PAGE];
        this.firstPageLabel = translations[PAGINATOR_FIRST_PAGE];
        this.lastPageLabel = translations[PAGINATOR_LAST_PAGE];
        this.changes.next();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
