import { Injectable } from '@angular/core';
import { AppWindow, LanguageOptions, TPLOY_LANGUAGE_STORAGE_KEY } from './language.types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export class WindowRef {
    private static window(): Window {
        return window;
    }

    static get nativeWindow(): Window {
        return WindowRef.window();
    }
}

@Injectable()
export class LanguageHelpersService {
    locale$: Observable<LanguageOptions>;

    private get appWindow(): AppWindow {
        return WindowRef.nativeWindow as AppWindow;
    }
    get availableLocales(): LanguageOptions[] {
        return this.appWindow.languageConfig.availableLocales as LanguageOptions[];
    }
    get defaultLocale(): LanguageOptions {
        return this.appWindow.languageConfig.defaultLocale;
    }
    get appName(): string {
        return this.appWindow.languageConfig.appName;
    }
    get culture(): string {
        return this.appWindow.languageConfig.culture;
    }

    constructor(
        private readonly translateService: TranslateService,
        private readonly store: Store<{ account: { locale: { used: LanguageOptions } } }>,
    ) {
        this.locale$ = store
            .select((state) => state.account.locale.used)
            .pipe(
                map((locale) => {
                    if (locale) {
                        return locale;
                    } else {
                        const storedLocale = localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY);
                        // eslint-disable-next-line no-extra-boolean-cast
                        if (!!storedLocale && storedLocale != 'undefined') {
                            return storedLocale as LanguageOptions;
                        } else {
                            return this.defaultLocale;
                        }
                    }
                }),
            );
    }

    changeLocale(locale: LanguageOptions) {
        // as we can not import from core, contruct the action from scratch
        this.store.dispatch({ type: '[Account Locale] change', locale });
    }

    getCurrentLocale(): string {
        const availableLanguages = this.availableLocales;
        const browserLanguage = availableLanguages.includes(this.translateService.getBrowserLang() as LanguageOptions)
            ? (this.translateService.getBrowserLang() as LanguageOptions)
            : null;
        let suitableLocale = availableLanguages.includes(
            localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY) as LanguageOptions,
        )
            ? (localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY) as LanguageOptions)
            : browserLanguage;
        suitableLocale = suitableLocale ?? this.defaultLocale;
        localStorage.setItem(TPLOY_LANGUAGE_STORAGE_KEY, suitableLocale);
        this.translateService.use(suitableLocale);

        return suitableLocale;
    }
}
