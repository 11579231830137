import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

export type ViewMode = 'people' | 'offer';

@Component({
    selector: 'tp-add-profile',
    templateUrl: './add-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'add-profile',
    },
})
export class AddProfileComponent {
    @Input()
    editTopicRoute: string[];

    @Input() viewMode: ViewMode = 'offer';

    @Input() useTabBarPlaceholder = true;

    @HostBinding('class.add-profile--people')
    public get isPeopleMode(): boolean {
        return this.viewMode === 'people';
    }
    @HostBinding('class.add-profile--offer')
    public get isOfferMode(): boolean {
        return this.viewMode === 'offer';
    }
    @HostBinding('class.add-profile--tab-bar-placeholder')
    public get isTabBarPlaceholder(): boolean {
        return this.useTabBarPlaceholder;
    }
}
