import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectsActions } from '../../projects.actions';
import { ProjectsState } from '../../projects.reducer';
import { showTopicLinkIndicator } from '../../projects.selectors';

@Component({
    selector: 'tp-projects-applicants-indicator',
    templateUrl: './projects-applicants-indicator-view.component.html',
})
export class ProjectsApplicantsIndicatorViewComponent implements OnInit {
    showTopicLinkIndicator$: Observable<boolean>;

    constructor(private readonly store: Store<{ projects: ProjectsState }>) {}

    ngOnInit(): void {
        this.showTopicLinkIndicator$ = this.store.select(showTopicLinkIndicator);
        this.store.dispatch(ProjectsActions.getOwnerUnseenApplications());
    }
}
