export type SocketIOEvent = SocketIOClientEvent | SocketIOServerEvent;

export enum SocketIOServerEvent {
    CREATE_CONVERSATION = 'dm:server:conversation:create',
    JOIN_CONVERSATION = 'dm:server:conversation:join',
    LEAVE_CONVERSATION = 'dm:server:conversation:leave',
    SEND_MESSAGE = 'dm:server:message:send',
    GET_AVAILABLE_CONVERSATIONS = 'dm:server:conversations:get',
    SET_USER_ID = 'dm:server:user-id:set',
    GET_CONVERSATION = 'dm:server:conversation:get',
    MARK_MESSAGE_AS_READ = 'dm:server:message:read',
    GET_UNREAD_MESSAGES_COUNT = 'dm:server:message:unread:count',
    MUTE_CONVERSATION = 'dm:server:conversation:mute',
    UNMUTE_CONVERSATION = 'dm:server:conversation:unmute',
    JOIN_GROUP_CONVERSATION = 'dm:server:conversation:group:join',
    ENABLE_WRITE_PERMISSIONS = 'dm:server:conversation:write-permissions:enable',
    DISABLE_WRITE_PERMISSIONS = 'dm:server:conversation:write-permissions:disable',
    PROMOTE_USER_TO_OWNER = 'dm:server:conversation:ownership:promote-user',
    DEMOTE_USER_FROM_OWNER = 'dm:server:conversation:ownership:demote-user',
}
export enum SocketIOClientEvent {
    CONVERSATION_CREATED = 'dm:client:conversation:created',
    CONVERSATION_JOINED = 'dm:client:conversation:joined',
    CONVERSATION_JOINED_OTHER = 'dm:client:conversation:joined:other',
    CONVERSATION_LEFT = 'dm:client:conversation:left',
    CONVERSATION_LEFT_OTHER = 'dm:client:conversation:left:other',
    RECEIVE_MESSAGE = 'dm:client:message:receive',
    GET_AVAILABLE_CONVERSATIONS = 'dm:client:conversations:available',
    WRITE_PERMISSIONS_ENABLED = 'dm:client:conversation:write-permissions:enabled',
    WRITE_PERMISSIONS_DISABLED = 'dm:client:conversation:write-permissions:disabled',
    PROMOTED_TO_OWNER = 'dm:client:conversation:ownership:promoted',
    DEMOTED_FROM_OWNER = 'dm:client:conversation:ownership:demoted',
}
