import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { RequestHandlerService } from '../../request-handler/request-handler.service';

@Component({
    selector: 'tp-search-loader',
    templateUrl: './search-loader.component.html',
    styleUrls: ['./search-loader.component.scss'],
})
export class SearchLoaderComponent {
    @Input()
    isVisible = false;

    @Input()
    showAlways = false;

    constructor(private requestHandlerService: RequestHandlerService, private changeDetectorRef: ChangeDetectorRef) {
        this.requestHandlerService.showSpinner$.subscribe((showSpinner) => {
            this.isVisible = showSpinner;
            this.changeDetectorRef.detectChanges();
        });
    }
}
