import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalParticipantsNotificationComponent } from './modal-participants-notification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    declarations: [ModalParticipantsNotificationComponent],
    exports: [ModalParticipantsNotificationComponent],
    imports: [CommonModule, ButtonModule, MatIconModule, MatDialogModule, LanguageModule, MatButtonModule],
})
export class ModalParticipantsNotificationModule {}
