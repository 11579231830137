import {
    ComponentType,
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategyOrigin,
    HorizontalConnectionPos,
    Overlay,
    OverlayConfig,
    OverlayRef,
    PositionStrategy,
    VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    InjectionToken,
    Injector,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PopoverComponent } from './popover.component';
import { take, takeUntil } from 'rxjs/operators';
import { PopoverVerticalAlign } from './popover.types';

export const POPOVER_DATA = new InjectionToken<string>('PopoverData');

@Directive({
    selector: '[tpPopover]',
    exportAs: 'tpPopover',
})
export class PopoverDirective implements OnDestroy {
    private overlayRef: OverlayRef;
    private popoverInstance: PopoverComponent;
    private readonly destroy$ = new Subject<void>();

    @Input() tpPopoverContent: TemplateRef<ElementRef> | ComponentType<ElementRef>;

    @Input() tpPopoverData: unknown;

    @Input() tpPopoverShowDelay = 0;

    @Input() tpPopoverHideDelay = 0;

    @Input() tpPopoverClass: string;

    @Input() tpPopoverHasBackdrop = true;

    @Input() tpVerticalTarget: PopoverVerticalAlign = 'below';

    @Input() tpHorizontalConnectionPosition: HorizontalConnectionPos = 'center';

    @Input() fallbackHorizontalOffset = 0;

    @Input() fallbackVerticalOffset = 0;

    constructor(
        private readonly overlay: Overlay,
        private readonly injector: Injector,
        private readonly elementRef: ElementRef,
        private readonly viewContainerRef: ViewContainerRef,
    ) {}

    ngOnDestroy() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.popoverInstance = null;
        }

        this.destroy$.next();
    }

    show(delay: number = this.tpPopoverShowDelay) {
        if (!this.tpPopoverContent || this.isVisible()) {
            return;
        }

        this.overlayRef = this.overlay.create(this.getOverlayConfig({ origin: this.elementRef }));
        this.createPopoverInstance();

        this.overlayRef
            .backdropClick()
            .pipe(take(1))
            .subscribe(() => this.hide(this.tpPopoverHideDelay));

        this.popoverInstance.show(delay);
    }

    private createPopoverInstance() {
        const componentPortal = new ComponentPortal(PopoverComponent, this.viewContainerRef);
        this.popoverInstance = this.overlayRef.attach(componentPortal).instance;

        this.popoverInstance
            .afterHidden()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cleanUp());

        this.popoverInstance.popoverClass = this.tpPopoverClass;

        this.attachContent(this.popoverInstance, this.tpPopoverContent);
    }

    hide(delay: number = this.tpPopoverHideDelay): void {
        if (this.popoverInstance) {
            this.popoverInstance.hide(delay);
        }
    }

    private cleanUp() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }

        if (this.popoverInstance) {
            this.popoverInstance = null;
        }
    }

    private attachContent(popoverInstance: PopoverComponent, content: TemplateRef<any> | ComponentType<any>) {
        if (content instanceof TemplateRef) {
            const templatePortal = new TemplatePortal(content, null, { data: this.tpPopoverData });
            popoverInstance.attachTemplatePortal(templatePortal);
        } else {
            const injector = new PortalInjector(this.injector, new WeakMap([[POPOVER_DATA, this.tpPopoverData]]));
            const componentPortal = new ComponentPortal(content, null, injector);
            popoverInstance.attachComponentPortal(componentPortal);
        }
    }

    private getOverlayConfig({ origin }): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: this.tpPopoverHasBackdrop,
            backdropClass: 'popover-backdrop',
            positionStrategy: this.getOverlayPosition(origin),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    }

    private getOverlayPosition(origin: FlexibleConnectedPositionStrategyOrigin): PositionStrategy {
        return this.overlay
            .position()
            .flexibleConnectedTo(origin)
            .withPositions(this.getPositions())
            .withViewportMargin(15)
            .withPush();
    }

    private getPositions(): ConnectionPositionPair[] {
        const targetPosition = this.getPosition(this.tpVerticalTarget);
        const positions = [targetPosition];
        positions.push(...this.getFallbacks(this.tpVerticalTarget));
        return positions;
    }

    private getFallbacks(vTarget: PopoverVerticalAlign): ConnectionPositionPair[] {
        const possibleVerticalAlignments: PopoverVerticalAlign[] = ['above', 'below'];

        const fallbacks: ConnectionPositionPair[] = [];

        this.prioritizeAroundTarget(vTarget, possibleVerticalAlignments).forEach((v) => {
            fallbacks.push(this.getPosition(v));
        });

        return fallbacks.slice(1, fallbacks.length);
    }

    private getPosition(v: PopoverVerticalAlign): ConnectionPositionPair {
        const { originX, overlayX } = this.getHorizontalConnectionPosPair();
        const { originY, overlayY } = this.getVerticalConnectionPosPair(v);
        return new ConnectionPositionPair(
            { originX, originY },
            { overlayX, overlayY },
            this.fallbackHorizontalOffset,
            this.fallbackVerticalOffset,
        );
    }

    protected getHorizontalConnectionPosPair(): {
        originX: HorizontalConnectionPos;
        overlayX: HorizontalConnectionPos;
    } {
        return {
            originX: this.tpHorizontalConnectionPosition,
            overlayX: this.tpHorizontalConnectionPosition,
        };
    }
    protected getVerticalConnectionPosPair(v: PopoverVerticalAlign): {
        originY: VerticalConnectionPos;
        overlayY: VerticalConnectionPos;
    } {
        switch (v) {
            case 'above':
                return { originY: 'top', overlayY: 'bottom' };
            case 'start':
                return { originY: 'top', overlayY: 'top' };
            case 'end':
                return { originY: 'bottom', overlayY: 'bottom' };
            case 'below':
                return { originY: 'bottom', overlayY: 'top' };
            default:
                return { originY: 'center', overlayY: 'center' };
        }
    }

    protected prioritizeAroundTarget<T>(target: T, options: T[]): T[] {
        const targetIndex = options.indexOf(target);
        const reordered = [target];
        const left = options.slice(0, targetIndex);
        const right = options.slice(targetIndex + 1, options.length).reverse();
        while (left.length && right.length) {
            reordered.push(right.pop());
            reordered.push(left.pop());
        }
        while (right.length) {
            reordered.push(right.pop());
        }
        while (left.length) {
            reordered.push(left.pop());
        }
        return reordered;
    }

    isVisible(): boolean {
        return !!this.popoverInstance && this.popoverInstance.isVisible();
    }
}
