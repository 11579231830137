import { combineReducers, createReducer, on } from '@ngrx/store';
import { QuestionnaireData, QUESTIONNAIRE_STORAGE_EDIT_KEY } from './questionnaire.types';
import { AccountActions } from '../account.actions';
import { QuestionnaireActions } from './questionnaire.actions';

interface QuestionnaireEditState {
    dirty: boolean;
    data: QuestionnaireData;
}

export interface QuestionnaireCurrentState {
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    data: QuestionnaireData;
}

export interface QuestionnaireState {
    edit: QuestionnaireEditState;
    current: QuestionnaireCurrentState;
}

const defaultQuestionnaire: QuestionnaireData = {
    answers: [],
    currentQuestion: null,
};

const initialEditQuestionnaireState: QuestionnaireEditState = {
    dirty: false,
    data: defaultQuestionnaire,
};

function getInitialEditState(): QuestionnaireEditState {
    const savedState = window.sessionStorage.getItem(QUESTIONNAIRE_STORAGE_EDIT_KEY);
    if (savedState) {
        return JSON.parse(savedState);
    } else return { ...initialEditQuestionnaireState };
}

const initialCurrentQuestionnaireState: QuestionnaireCurrentState = {
    loading: false,
    loadingError: null,
    saving: false,
    savingError: null,
    data: defaultQuestionnaire,
};

const editQuestionnaireReducer = createReducer(
    getInitialEditState(),
    on(AccountActions.loadSuccess, (state, { account }) => ({ ...state, data: account.questionnaire, dirty: false })),
    on(AccountActions.deleteSuccess, () => ({ ...initialEditQuestionnaireState })),
    on(QuestionnaireActions.edit, (state, { questionnaireData }) => {
        questionnaireData.answers.map((newAnswer) => {
            if (newAnswer.answer.length === 0) {
                state.data.answers = state.data.answers.filter(
                    (stateAnswer) => stateAnswer.question === newAnswer.question,
                );
            }
        });
        questionnaireData.answers = questionnaireData.answers.filter((newAnswer) => newAnswer.answer.length !== 0);

        return {
            ...state,
            data: questionnaireData,
            dirty: true,
        };
    }),
    on(QuestionnaireActions.editCurrentQuestion, (state, { currentQuestion }) => ({
        ...state,
        data: { ...state.data, currentQuestion },
    })),
    on(QuestionnaireActions.saveSuccess, (state, { questionnaireData }) => ({
        ...state,
        data: questionnaireData,
        dirty: false,
    })),
);

const currentQuestionnaireReducer = createReducer(
    initialCurrentQuestionnaireState,
    on(AccountActions.load, (state) => ({ ...state, loading: true })),
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        data: account.questionnaire || initialCurrentQuestionnaireState.data,
        loading: false,
        loadingError: null,
    })),
    on(AccountActions.deleteSuccess, () => ({ ...initialCurrentQuestionnaireState })),
    on(QuestionnaireActions.save, (state) => ({ ...state, saving: true })),
    on(QuestionnaireActions.saveSuccess, (state, { questionnaireData }) => ({
        ...state,
        data: questionnaireData,
        saving: false,
        savingError: null,
    })),
    on(QuestionnaireActions.saveError, (state, { error }) => ({ ...state, savingError: error, saving: false })),
);

export const questionnaireReducer = combineReducers({
    edit: editQuestionnaireReducer,
    current: currentQuestionnaireReducer,
});
