import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { CollapsableDirective } from './collapsable.directive';

@Directive({
    selector: '[tpCollapsableTrigger]',
    exportAs: 'collapsableTrigger',
})
export class CollapsableTriggerDirective implements OnInit {
    @HostBinding('class.collapsable__trigger') collapsableTriggerClass = true;
    @HostBinding('attr.tabindex') tabindex = '0';
    @HostBinding('attr.aria-expanded') expanded = false;
    @HostBinding('attr.role') role = 'button';
    @HostBinding('attr.aria-label') ariaLabel = '';

    @Input()
    collapsedAriaLabel = '';

    @Input()
    expandedAriaLabel = '';

    @Input() disabled = false;

    constructor(private readonly collapsableDirective: CollapsableDirective) {}

    ngOnInit() {
        this.ariaLabel = this.getAriaLabel();
    }

    @HostListener('click', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    triggerContent(event: Event) {
        event.stopPropagation();
        this.toggle();
    }

    toggle() {
        if (!this.disabled) {
            this.collapsableDirective.toggle();
            this.expanded = this.collapsableDirective.content.isShown;
            this.ariaLabel = this.getAriaLabel();
        }
    }

    private getAriaLabel() {
        return this.expanded ? this.expandedAriaLabel : this.collapsedAriaLabel;
    }
}
