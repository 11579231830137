<ng-container
    [ngTemplateOutlet]="highlightLocationIndependent ? matchedLocationIndependent : locationIndependent"
></ng-container>

<ng-template #matchedLocationIndependent>
    {{ 'GENERATION_EXCHANGE_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL' | translatable }}
</ng-template>
<ng-template #locationIndependent>
    {{ 'GENERATION_EXCHANGE_ITEM_LOCATION_INDEPENDENT_LABEL' | translatable }}
</ng-template>
