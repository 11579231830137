import { createAction, props } from '@ngrx/store';
import { ExpertSearchResult } from '../../expert-api/expert.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const ExpertSearchActions = {
    searchEntities: createAction('[Experts] Search entities', props<{ searchQuery: string; pageParams: PageParams }>()),
    searchEntitiesSuccess: createAction(
        '[Experts][Success] Search entities',
        props<{ results: ExpertSearchResult[]; allResults: ExpertSearchResult[]; pageParams: PageParams }>(),
    ),
    searchEntitiesError: createAction(
        '[Experts][Error] Search entities',
        props<{ entities: ExpertSearchResult[]; error: Error }>(),
    ),
    loadError: createAction('[Experts][Error] Load', props<{ error: Error }>()),
    loadSearchResult: createAction('[Experts] Load search result', props<{ searchQuery: string; index: number }>()),
    loadSearchResultSuccess: createAction(
        '[Experts] [Success] Load search result',
        props<{ data: ExpertSearchResult; index: number }>(),
    ),
};
