import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GeneralDataActions } from './general-data.actions';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { GeneralDataState } from './general-data.reducer';
import { AccountState } from '../account.reducer';
import { GeneralDataService } from './general-data.service';
import { AccountActions } from '../account.actions';
import { StorageService } from '../../storage/service/storage.service';
import { GENERAL_DATA_STORAGE_EDIT_KEY } from './general-data.types';

@Injectable({
    providedIn: 'root',
})
export class GeneralDataEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ account: AccountState }>,
        private generalDataService: GeneralDataService,
        private storage: StorageService,
    ) {}

    storeChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralDataActions.edit, GeneralDataActions.validate),
                withLatestFrom(this.store.select((state) => state.account.generalData)),
                tap(([action, state]) => this.storeEditionState(state)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralDataActions.save),
            withLatestFrom(this.store.select((state) => state.account.generalData.edit.data)),
            switchMap(([action, generalData]) =>
                this.generalDataService.save(generalData).pipe(
                    map(() => GeneralDataActions.saveSucces({ generalData })),
                    catchError((error) => of(GeneralDataActions.saveError({ error, generalData }))),
                ),
            ),
        ),
    );

    resetEditState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralDataActions.cancelEdit),
            withLatestFrom(this.store.select((state) => state.account.generalData.current.data)),
            map(([action, generalData]) => GeneralDataActions.abortEdit({ generalData })),
        ),
    );

    deleteSession$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralDataActions.cancelEdit, GeneralDataActions.saveSucces, AccountActions.createSuccess),
                tap(() => this.deleteEditionState()),
            ),
        { dispatch: false },
    );

    private storeEditionState(state: GeneralDataState): void {
        this.storage.session.set(GENERAL_DATA_STORAGE_EDIT_KEY, state.edit);
    }

    private deleteEditionState(): void {
        this.storage.session.remove(GENERAL_DATA_STORAGE_EDIT_KEY);
    }
}
