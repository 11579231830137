import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipInput, MatChipListChange } from '@angular/material/chips';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { TagsSuggestionComponent } from './tags-suggestion/tags-suggestion.component';
import { DOCUMENT } from '@angular/common';
import { Suggestable, SUGGESTABLE } from '../suggest';
import { SKILL_MAX_LENGTH, SkillService } from '../skill';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MoveEvent, SortableOptions } from 'sortablejs';

const CHIP_TOTAL_PADDING = 51; /* padding, margin, icon width */
const AVERAGE_SIGN_WIDTH = 6.5;
const SELECT_ALL_BUTTON_WIDTH = 140;

/**
 * This is meant to replace entirely the former TaggerComponent
 * It is simpler in the sense that:
 * - It loads its own suggestions based on the type provided
 * - It is more straightforward as it assumes only that the tagger is a form control
 * meant to collect tags.
 * - The template is much simpler
 */

@Component({
    selector: 'tp-simple-tagger',
    templateUrl: './simple-tagger.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] } },
        { provide: MatFormFieldControl, useExisting: SimpleTaggerComponent },
        { provide: SUGGESTABLE, useExisting: SimpleTaggerComponent },
    ],
    host: {
        class: 'simple-tagger',
    },
})
export class SimpleTaggerComponent
    implements
        ControlValueAccessor,
        MatFormFieldControl<Array<string>>,
        OnInit,
        AfterViewInit,
        DoCheck,
        OnChanges,
        OnDestroy,
        Suggestable
{
    private readonly _stateChanges = new Subject<void>();
    private readonly destroy$ = new Subject<void>();
    private _required = false;
    private _placeholder: string;
    private _ariaLabelledBy: string;
    sortablejsOptions: SortableOptions;

    @Input()
    skillsHaveLengthError = false;

    @Input()
    skillType = '';

    static nextId = 0;
    @Input()
    suggestions = [];

    input$: Observable<string>;

    @Input()
    set suggestedSkills(value: Array<string>) {
        this._suggestedSkills = value;
        this.changeDetectorRef.markForCheck();
    }

    get suggestedSkills(): Array<string> {
        return this._suggestedSkills;
    }

    private _suggestedSkills: Array<string> = [];

    @Input()
    set techWolfSkills(value: Array<string>) {
        this._techWolfSkills = value;
        this.changeDetectorRef.markForCheck();
    }

    get techWolfSkills(): Array<string> {
        return this._techWolfSkills;
    }

    private _techWolfSkills: Array<string> = [];

    private updatedSuggestedSkillsValue = 0;
    private updatedTechWolfSkillsValue = 0;

    @Output()
    updateTechWolfSkills = new EventEmitter();

    @Output()
    hideSkill = new EventEmitter();

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this._stateChanges.next();
    }

    get placeholder(): string {
        if (this.value && this.value.length === 0) {
            return this._placeholder;
        } else {
            return null;
        }
    }

    isMobileView$: Subscription;
    isMobileView: boolean;

    @HostBinding() id = `tp-simple-tagger-${SimpleTaggerComponent.nextId++}`;

    @HostBinding('attr.aria-describedby') describedBy = '';

    @Input()
    set ariaLabelledBy(value: string) {
        this._ariaLabelledBy = value;
    }

    get ariaLabelledBy(): string {
        if (this._ariaLabelledBy) {
            return this._ariaLabelledBy;
        }

        return this.formField && this.formField._labelId;
    }

    @Input()
    isExpertTagger = false;

    // Emits when enter is pressed and the input field is empty
    @Output()
    trigger = new EventEmitter();
    @ViewChild('autocompleteInput', { static: true })
    input: ElementRef;

    @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger, static: true })
    autocompleteTrigger: MatAutocompleteTrigger;

    @ViewChild('autocompleteInput', { read: MatChipInput, static: true })
    chipInput: MatChipInput;

    @ViewChild('autocomplete', { static: true })
    autocomplete: MatAutocomplete;

    @Input()
    set required(value) {
        this._required = coerceBooleanProperty(value);
        this._stateChanges.next();
    }

    get required(): boolean {
        return this._required;
    }

    @Input()
    set value(value: Array<string>) {
        this._value = value;
        if (this.onValueChange) {
            this.onValueChange(value);
        }

        this.initSkillsContainerWrapper();
        this.markSuggestedSkillsForCheck();
        this.markTechWolfSkillsForCheck();

        this._stateChanges.next();
    }

    get value(): Array<string> {
        return this._value;
    }

    private _value: Array<string> = [];

    @Input()
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
        this._stateChanges.next();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private _disabled = false;

    get stateChanges() {
        return this._stateChanges;
    }

    focused = false;

    get empty(): boolean {
        return !this.value || this.value.length === 0;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    errorState = false;

    controlType = 'tp-simple-tagger';

    inputControl: FormControl = new FormControl('');

    onTouched: () => void;
    onValueChange: (value: Array<string>) => void;

    get skillMaxLength(): number {
        return SKILL_MAX_LENGTH;
    }

    private suggestedSkillsShowAll = false;
    private techWolfSkillsShowAll = false;

    private containerInited = false;
    private inputFocused = false;

    private getFormFieldElement() {
        return this.formField._elementRef.nativeElement as HTMLElement;
    }

    private initSkillsContainerWrapper() {
        if (!this.containerInited) {
            const formFieldEl = this.getFormFieldElement();
            const formEl = formFieldEl.parentNode?.parentNode;
            const containerWrapperEl = this.document.createElement('div');
            containerWrapperEl.classList.add('skills-container-wrapper');
            containerWrapperEl.classList.add('hidden');
            containerWrapperEl.addEventListener('click', (event) => {
                this.showSkills();
                event.stopPropagation();
            });
            formEl &&
                formEl.addEventListener('click', (event) => {
                    this.hideSkills();
                });
            formFieldEl.appendChild(containerWrapperEl);
            this.containerInited = true;
        }
    }

    private getSkillsContainerWrapper() {
        const formFieldEl = this.getFormFieldElement();
        return formFieldEl.querySelector('.skills-container-wrapper');
    }

    private getDomPortalOutlet(className: string): DomPortalOutlet {
        const formFieldEl = this.getSkillsContainerWrapper();
        const portalOutletEl = this.document.createElement('div');
        portalOutletEl.classList.add(className);
        formFieldEl.appendChild(portalOutletEl);
        return new DomPortalOutlet(portalOutletEl, this.componentFactoryResolver, this.applicationRef, this.injector);
    }

    get suggestedSkillsPortalOutlet(): DomPortalOutlet {
        if (this.formField) {
            if (!this._suggestedSkillsPortalOutlet) {
                this._suggestedSkillsPortalOutlet = this.getDomPortalOutlet('tp-suggested-skills-container');
            }
            return this._suggestedSkillsPortalOutlet;
        } else {
            return null;
        }
    }

    get techWolfSkillsPortalOutlet(): DomPortalOutlet {
        if (this.formField) {
            if (!this._techWolfSkillsPortalOutlet) {
                this._techWolfSkillsPortalOutlet = this.getDomPortalOutlet('tp-techwolf-skills-container');
            }
            return this._techWolfSkillsPortalOutlet;
        } else {
            return null;
        }
    }

    private _suggestedSkillsPortalOutlet: DomPortalOutlet;
    private suggestedSkillsPortal: ComponentPortal<TagsSuggestionComponent> = null;
    private suggestedSkillsRef: ComponentRef<TagsSuggestionComponent>;

    private _techWolfSkillsPortalOutlet: DomPortalOutlet;
    private techWolfSkillsPortal: ComponentPortal<TagsSuggestionComponent> = null;
    private techWolfSkillsRef: ComponentRef<TagsSuggestionComponent>;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly applicationRef: ApplicationRef,
        private readonly injector: Injector,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly focusMonitor: FocusMonitor,
        private readonly elementRef: ElementRef<HTMLElement>,
        @Optional() private readonly formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        private readonly renderer: Renderer2,
        private bo: BreakpointObserver,
        private skillService: SkillService,
    ) {
        this.sortablejsOptions = {
            onMove: (event) => this.handleOnMove(event),
            onUpdate: () => this.handleOnUpdate(),
        };

        if (ngControl) {
            ngControl.valueAccessor = this;
        }

        focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
            this.focused = !!origin;
            this._stateChanges.next();
        });
    }

    ngOnInit() {
        this.isMobileView$ = this.bo
            .observe(['(max-width: 599px)'])
            .pipe(map((breakpoint) => breakpoint.matches))
            .subscribe((isMobileView) => (this.isMobileView = isMobileView));
        if (this.ngControl) {
            this.input$ = this.inputControl.valueChanges;

            this.ngControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.changeDetectorRef.markForCheck();
            });
        } else {
            this.input$ = of('');
        }

        this.initSkillsContainerWrapper();
        this.renderSuggestedSkills();
        this.renderTechWolfSkills();
        this.skillService.showSkillsContainer$.subscribe((skillTypeToShow) => {
            if (this.skillType === skillTypeToShow) {
                this.showSkills();
                this.inputFocused = true;
            } else {
                this.hideSkills();
                this.inputFocused = false;
            }
        });
    }

    ngAfterViewInit() {
        this.ngControl.valueChanges
            .pipe(startWith([]), takeUntil(this.destroy$))
            .subscribe(() => this.setFormControlPaddings());
    }

    ngDoCheck() {
        const errorState = this.ngControl.invalid && this.ngControl.touched;
        if (errorState !== this.errorState) {
            this.errorState = errorState;
            this._stateChanges.next();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // Hide already selected from suggestions
        if (changes.suggestions && changes.suggestions.currentValue) {
            if (changes.suggestions.currentValue !== changes.suggestions.previousValue) {
                this.suggestions = changes.suggestions.currentValue.filter(
                    (suggestion) => !this.value.includes(suggestion),
                );
            }
        }
        this.rerenderSuggestedSkills(changes);
        this.rerenderTechWolfSkills(changes);

        if (!this.suggestedSkills.length && !this.techWolfSkills.length) {
            this.hideSkills();
        }
        this.hideSkillsIfEmpty();
    }

    private rerenderSuggestedSkills(changes: SimpleChanges) {
        const currentValue = changes.suggestedSkills?.currentValue?.length;
        const previousValue = changes.suggestedSkills?.previousValue?.length;
        if (currentValue !== previousValue && this.containerInited) {
            this.suggestedSkillsPortalOutlet.detach();
            this.renderSuggestedSkills();
            if (this.inputFocused && currentValue > previousValue) {
                this.showSkills();
            }
        }
    }

    private rerenderTechWolfSkills(changes: SimpleChanges) {
        const currentValue = changes.techWolfSkills?.currentValue?.length;
        const previousValue = changes.techWolfSkills?.previousValue?.length;
        if (currentValue !== previousValue && this.containerInited) {
            this.techWolfSkillsPortalOutlet.detach();
            this.renderTechWolfSkills();
            if (this.inputFocused && currentValue > previousValue) {
                this.showSkills();
            }
        }
    }

    ngOnDestroy() {
        this.detachSuggestedSkills();
        this.detachTechWolfSkills();
        this.destroy$.next();
    }

    private isValueValid(value: string): boolean {
        const trimedValue = (value && value.trim()) || '';
        return trimedValue.length > 0;
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {}

    onInputEnterPressed(event: KeyboardEvent) {
        // There is a race on enter keypressed detection
        // The tagger trigger detection, the chipList and the autocomplete all
        // detect it to produce different outcomes.
        // It is important for this handler to be ran before the others as the others
        // will reinitialize the input value, which we use to make our decision
        if (event.keyCode === ENTER && !this.input.nativeElement.value) {
            this.trigger.emit(this.value);
        }
    }

    registerOnChange(fn) {
        this.onValueChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: Array<string>) {
        this.value = value;
    }

    addTags(tags: string[]) {
        const targetTags = tags.filter((tag) => !!tag && !this.contains(tag.trim()) && this.isValueValid(tag));
        this.updateTechWolfSkills.emit({
            skills: targetTags.filter((tag) => !this.value.includes(tag)),
            skillType: this.skillType,
        });
        this.value = [...this.value, ...targetTags];
        if (this.suggestedSkillsRef) {
            this.tagsLimitForView('suggestedSkills');
            this.suggestedSkillsRef.changeDetectorRef.markForCheck();
        }
        if (this.techWolfSkillsRef) {
            this.tagsLimitForView('techWolfSkills');
            this.techWolfSkillsRef.changeDetectorRef.markForCheck();
        }
    }

    addTag(tag: string) {
        if (!!tag && !this.contains(tag.trim())) {
            this.input.nativeElement.value = '';
            this.inputControl.setValue('');

            this.updateTechWolfSkills.emit({
                skills: [tag],
                skillType: this.skillType,
            });

            if (this.isValueValid(tag)) {
                this.value = [...this.value, tag.trim()];
            }

            if (this.suggestedSkillsRef) {
                this.tagsLimitForView('suggestedSkills');
                this.suggestedSkillsRef.changeDetectorRef.markForCheck();
            }

            if (this.techWolfSkillsRef) {
                this.tagsLimitForView('techWolfSkills');
                this.techWolfSkillsRef.changeDetectorRef.markForCheck();
            }
        }
    }

    removeTags(): void {
        while (this.value.length > 0) {
            this.removeTag(this.value[0]);
        }
    }

    removeLastTag(): void {
        if (this.value.length > 0) {
            this.removeTag(this.value[this.value.length - 1]);
        }
    }

    removeTag(tag: string) {
        if (this.contains(tag)) {
            const index = (this.value || []).map((tag) => tag.toLowerCase()).indexOf(tag.toLowerCase());
            const newValue = [...(this.value || [])];
            newValue.splice(index, 1);
            this.value = newValue;

            if (this.suggestedSkillsRef) {
                this.tagsLimitForView('suggestedSkills');
                this.suggestedSkillsRef.changeDetectorRef.markForCheck();
                this.suggestedSkillsRef.changeDetectorRef.detectChanges();
            }

            if (this.techWolfSkillsRef) {
                this.tagsLimitForView('techWolfSkills');
                this.techWolfSkillsRef.changeDetectorRef.markForCheck();
                this.techWolfSkillsRef.changeDetectorRef.detectChanges();
            }

            this.autocompleteTrigger.closePanel();
            this.changeDetectorRef.detectChanges();
        }
    }

    selectAutocompleteOption(tag: string) {
        this.inputControl.setValue(null);
        this.input.nativeElement.value = '';
        this.autocompleteTrigger.closePanel();
        this.addTag(tag);
    }

    createTag(name: string) {
        if (name) {
            this.inputControl.setValue(null);
            this.input.nativeElement.value = '';
            this.autocompleteTrigger.closePanel();
            this.addTag(name);
        }
    }

    trackOptions(index, tag: string) {
        return tag;
    }

    onFocus(event) {
        this.showSkills();
        this.skillService.showSkillsContainer$.next(this.skillType);
        event.stopPropagation();
        event.preventDefault();
    }

    onBlur(event: FocusEvent) {
        const blurRelatedTarget = event.relatedTarget as HTMLElement;

        // MatChipInput is designed to handle blur events
        // We can use the feature because when the cause for blurring
        // the input is selecting a suggestion, the blur handler is
        // triggered first, and the suggestion is not selected but
        // whatever was in the input value becomes a new tag

        // here we detect the reason for blur to occur and emit the MatChipInput
        // event when the cause is not selecting an option from MatAutocomplete
        if (
            !blurRelatedTarget ||
            (blurRelatedTarget.nodeName !== 'mat-option'.toUpperCase() &&
                blurRelatedTarget.className !== 'mat-option-text')
        ) {
            this.chipInput._emitChipEnd();
        }
    }

    onBackspace() {
        if (this.input.nativeElement.value?.length === 0) {
            this.removeLastTag();
        }
    }

    private contains(tag: string): boolean {
        return (this.value || []).map((tag) => tag.toLowerCase()).indexOf(tag.toLowerCase()) !== -1;
    }

    private hideSkillsIfEmpty() {
        if (this.techWolfSkillsRef && this.suggestedSkillsRef) {
            if (!this.techWolfSkillsRef.instance.tags.length && !this.suggestedSkillsRef.instance.tags.length) {
                this.hideSkills();
            }
        }
    }

    private renderSuggestedSkills() {
        if (this.suggestedSkillsPortalOutlet) {
            if (this.suggestedSkillsPortalOutlet.outletElement.querySelector('tp-tags-suggestion')) {
                this.suggestedSkillsPortalOutlet.detach();
            }
            const outletInjector = Injector.create({
                providers: [],
                parent: this.injector,
            });

            this.suggestedSkillsPortal = new ComponentPortal(TagsSuggestionComponent, null, outletInjector);

            this.suggestedSkillsRef = this.suggestedSkillsPortalOutlet.attachComponentPortal(
                this.suggestedSkillsPortal,
            );

            this.tagsLimitForView('suggestedSkills');
            this.suggestedSkillsRef.instance.allTags = this.suggestedSkills;
            this.suggestedSkillsRef.instance.showAllSkills = this.suggestedSkillsShowAll;
            this.suggestedSkillsRef.instance.showSelectAll = true;
            this.suggestedSkillsRef.instance.showConfigIcon = true;
            this.suggestedSkillsRef.instance.showCloseIcon = true;
            this.suggestedSkillsRef.instance.skillsMode = 'suggestedSkills';
            this.suggestedSkillsRef.instance.label = 'TAG_SUGGESTION_SKILLS_LABEL';
            this.suggestedSkillsRef.instance.control = this.ngControl;

            this.suggestedSkillsRef.instance.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                if (this.updatedSuggestedSkillsValue !== value.length) {
                    this.updatedSuggestedSkillsValue = value.length;
                    this.suggestedSkillsPortalOutlet.detach();
                    this.renderSuggestedSkills();
                    this.hideSkillsIfEmpty();
                }
            });

            this.suggestedSkillsRef.instance.select.pipe(takeUntil(this.destroy$)).subscribe((tag) => {
                this.addTag(tag);
                this.suggestedSkillsPortalOutlet.detach();
                this.renderSuggestedSkills();
                this.hideSkillsIfEmpty();
            });
            this.suggestedSkillsRef.instance.selectAllTags.pipe(takeUntil(this.destroy$)).subscribe((allTags) => {
                this.addTags(allTags);
                this.suggestedSkillsPortalOutlet.detach();
                this.renderSuggestedSkills();
                this.hideSkillsIfEmpty();
            });
            this.suggestedSkillsRef.instance.removeAllTags.subscribe(() => this.removeTags());
            this.suggestedSkillsRef.instance.toogleShowAllMode.subscribe(({ showAllSkills, skillsMode }) => {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                this[skillsMode + 'ShowAll'] = showAllSkills;
                this.tagsLimitForView(skillsMode);
            });
            this.suggestedSkillsRef.instance.hideSkills.subscribe(() => this.hideSkills());
            this.suggestedSkillsRef.instance.hideSkill.pipe(takeUntil(this.destroy$)).subscribe((tag) => {
                this.hideSkill.emit([tag]);
            });

            this.suggestedSkillsRef.changeDetectorRef.markForCheck();
        }
    }

    private renderTechWolfSkills() {
        if (this.techWolfSkillsPortalOutlet) {
            if (this.techWolfSkillsPortalOutlet.outletElement.querySelector('tp-tags-suggestion')) {
                this.techWolfSkillsPortalOutlet.detach();
            }
            const outletInjector = Injector.create({
                providers: [],
                parent: this.injector,
            });

            this.techWolfSkillsPortal = new ComponentPortal(TagsSuggestionComponent, null, outletInjector);

            this.techWolfSkillsRef = this.techWolfSkillsPortalOutlet.attachComponentPortal(this.techWolfSkillsPortal);

            this.tagsLimitForView('techWolfSkills');
            this.techWolfSkillsRef.instance.allTags = this.techWolfSkills;
            this.techWolfSkillsRef.instance.showAllSkills = this.techWolfSkillsShowAll;
            this.techWolfSkillsRef.instance.showSelectAll = false;
            this.techWolfSkillsRef.instance.showConfigIcon = false;
            this.techWolfSkillsRef.instance.showCloseIcon = false;
            this.techWolfSkillsRef.instance.skillsMode = 'techWolfSkills';
            this.techWolfSkillsRef.instance.label = 'TAG_TECH_WOLF_SKILLS_LABEL';
            this.techWolfSkillsRef.instance.control = this.ngControl;

            this.techWolfSkillsRef.instance.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                if (this.updatedTechWolfSkillsValue !== value.length) {
                    this.updatedTechWolfSkillsValue = value.length;
                    this.techWolfSkillsPortalOutlet.detach();
                    this.renderTechWolfSkills();
                    this.hideSkillsIfEmpty();
                }
            });

            this.techWolfSkillsRef.instance.select.pipe(takeUntil(this.destroy$)).subscribe((tag) => {
                this.addTag(tag);
                this.techWolfSkillsPortalOutlet.detach();
                this.renderTechWolfSkills();
                this.hideSkillsIfEmpty();
            });
            this.techWolfSkillsRef.instance.selectAllTags.pipe(takeUntil(this.destroy$)).subscribe((allTags) => {
                this.addTags(allTags);
                this.techWolfSkillsPortalOutlet.detach();
                this.renderTechWolfSkills();
                this.hideSkillsIfEmpty();
            });
            this.techWolfSkillsRef.instance.removeAllTags.subscribe(() => this.removeTags());
            this.techWolfSkillsRef.instance.toogleShowAllMode.subscribe(({ showAllSkills, skillsMode }) => {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                this[skillsMode + 'ShowAll'] = showAllSkills;
                this.tagsLimitForView(skillsMode);
            });
            this.techWolfSkillsRef.instance.hideSkills.subscribe(() => this.hideSkills());

            this.techWolfSkillsRef.changeDetectorRef.markForCheck();
        }
    }

    private getSkillsForFirstTwoLines(skillsName: string) {
        const wrapperWidth = this.elementRef.nativeElement?.parentElement?.offsetWidth || 0;
        const firstLineTags = [];
        const secondLineTags = [];
        let firstLineTotalWidth = 0;
        let secondLineTotalWidth = 0;
        this[skillsName]
            .filter((skill) => !this.value.some((value) => value.toLowerCase() === skill.toLowerCase()))
            .sort((a, b) => {
                if (a.length > b.length) return 1;
                if (a.length < b.length) return -1;
                if (a.length === b.length) return 0;
            })
            .forEach((skill) => {
                const skillWidth = skill.length * AVERAGE_SIGN_WIDTH + CHIP_TOTAL_PADDING;
                if (!secondLineTags.length && firstLineTotalWidth + skillWidth < wrapperWidth) {
                    firstLineTotalWidth += skillWidth;
                    firstLineTags.push(skill);
                } else if (secondLineTotalWidth + skillWidth < wrapperWidth - SELECT_ALL_BUTTON_WIDTH) {
                    secondLineTotalWidth += skillWidth;
                    secondLineTags.push(skill);
                }
            });

        return [...firstLineTags, ...secondLineTags];
    }

    private tagsLimitForView(skillsName: string) {
        if (this[skillsName + 'ShowAll']) {
            this[skillsName + 'Ref'].instance.tags = this[skillsName];
            return;
        }

        if (this[skillsName + 'Ref'] && this[skillsName + 'Ref'].instance) {
            this[skillsName + 'Ref'].instance.tags = this.getSkillsForFirstTwoLines(skillsName);
        } else {
            this[skillsName + 'Ref'].instance.tags = [];
        }
    }

    private detachSuggestedSkills() {
        if (this.suggestedSkillsPortalOutlet && this.suggestedSkillsPortalOutlet.hasAttached()) {
            this.suggestedSkillsPortalOutlet.detach();
        }
    }

    private detachTechWolfSkills() {
        if (this.techWolfSkillsPortalOutlet && this.techWolfSkillsPortalOutlet.hasAttached()) {
            this.techWolfSkillsPortalOutlet.detach();
        }
    }

    private markSuggestedSkillsForCheck() {
        if (this.suggestedSkillsPortalOutlet && this.suggestedSkillsPortalOutlet.hasAttached()) {
            this.suggestedSkillsRef.changeDetectorRef.markForCheck();
        }
    }

    private markTechWolfSkillsForCheck() {
        if (this.techWolfSkillsPortalOutlet && this.techWolfSkillsPortalOutlet.hasAttached()) {
            this.techWolfSkillsRef.changeDetectorRef.markForCheck();
        }
    }

    private hideSkills() {
        const container = this.getSkillsContainerWrapper();
        container.classList.add('hidden');
        const formFieldEl = this.getFormFieldElement();
        formFieldEl.querySelector('.mat-form-field-wrapper').classList.remove('mat-focused');
    }

    private showSkills() {
        if (this.suggestedSkills.length > 0 || this.techWolfSkills.length > 0) {
            const container = this.getSkillsContainerWrapper();
            container.classList.remove('hidden');
            const formFieldEl = this.getFormFieldElement();
            formFieldEl.querySelector('.mat-form-field-wrapper').classList.add('mat-focused');
        }
        this.hideSkillsIfEmpty();
    }

    /**
     * Allows to remove extra padding in form field to avoid vertical grow/shrink on the addition/removal of the value.
     */
    private setFormControlPaddings() {
        const nativeElement = this.elementRef.nativeElement as HTMLElement;
        const el = nativeElement.closest('.mat-form-field-infix');

        if (this.value.length === 0) {
            this.renderer.removeStyle(el, 'padding-top');
            this.renderer.removeStyle(el, 'padding-bottom');
        } else {
            this.renderer.setStyle(el, 'padding-top', 'calc(1em - 5.5px)');
            this.renderer.setStyle(el, 'padding-bottom', 'calc(1em - 5.5px)');
        }
    }

    drop(event: CdkDragDrop<{ index: number }>): void {
        moveItemInArray(this.value, event.previousContainer.data.index, event.container.data.index);
    }

    handleOnMove(event: MoveEvent) {
        return event.related.tagName.indexOf('INPUT') === -1;
    }

    handleInputDragStart(event: DragEvent) {
        if ((event.target as HTMLElement).tagName.indexOf('INPUT') > -1) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    handleOnUpdate() {
        this.value = this._value;
    }

    suppressSortablejsChange(event: CustomEvent | MatChipListChange) {
        if (event instanceof CustomEvent) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
