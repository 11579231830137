<a
    class="image-picker_image-wrapper"
    [attr.tabindex]="tabindex"
    role="button"
    (click)="openImagePickerDialog($event)"
    (keydown.enter)="openImagePickerDialog($event)"
    [ngClass]="{ 'image-picker_image-wrapper--square': square, 'image-picker_image-wrapper--has-own-image': !!value }"
>
    <mat-icon *ngIf="!disabled" svgIcon="photo_filled" class="image-picker__camera-button"></mat-icon>
    <div *ngIf="value" [style.background-image]="'url(' + value + ')'"></div>
</a>
