<div
    class="card__facts-segment"
    tpTextHighlighter
    [text]="location"
    [tagsToHighlight]="tagsToHighlight"
    [renderTag]="'div'"
    [highlightClass]="'card__facts-segment--highlight'"
>
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_LOCATION' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="location_filled"
    ></mat-icon>
</div>

<div class="card__facts-segment" *ngIf="start && end">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ start | localizedDate : startDateFormat }} &#8594; {{ end | localizedDate : endDateFormat }}
</div>

<div class="card__facts-segment" *ngIf="end && !start">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ 'CARD_FACTS_END_TIME_UNTIL' | translatable : { endTime: end | localizedHumanizedDate } }}
</div>

<div class="card__facts-segment" *ngIf="start && !end">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ start | localizedDate : startDateFormat }}
</div>

<div class="card__facts-segment card__facts-segment--uppercase" *ngIf="languages && languages.length > 0">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_LANGUAGE' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="languages_filled"
    ></mat-icon>
    {{ languages.join(', ') }}
</div>

<div class="card__facts-segment" *ngIf="deadline">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_APPLICATION_DEADLINE' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="deadline"
    ></mat-icon>
    {{ deadline | localizedDate }}
</div>

<div class="card__facts-segment" *ngIf="company">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_COMPANY' | translatable"
        class="card__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="home_location_filled"
    ></mat-icon>
    <div class="card__facts-segment-company">{{ company }}</div>
</div>

<tp-campaigns-fact
    *ngIf="showCampaigns && campaigns?.length > 0"
    [campaigns]="campaigns"
    [campaignsToHighlight]="campaignsToHighlight"
    [styleType]="'card'"
></tp-campaigns-fact>
