import { Injectable } from '@angular/core';
import { ContactAccountGeneralData, ContactAccountGeneralDataDto } from './general-data.types';

@Injectable({
    providedIn: 'root',
})
export class ContactAccountGeneralDataSerializer {
    deserialize(dto: ContactAccountGeneralDataDto): ContactAccountGeneralData {
        return {
            name: dto.name,
            imageUrl: dto.imageUrl,
        };
    }
}
