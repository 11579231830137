import { Component } from '@angular/core';

@Component({
    selector: 'tp-matching-loader',
    templateUrl: './matching-loader.component.html',
    host: {
        class: 'gooey-container',
    },
})
export class MatchingLoaderComponent {}
