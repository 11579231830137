import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Injectable, Injector } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { HTTPLogStatus } from './logger.types';

@Injectable()
export class HttpErrorLoggerInterceptor implements HttpInterceptor {
    private readonly loggerService: LoggerService;

    constructor(private injector: Injector) {
        this.loggerService = injector.get(LoggerService);
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        const startTime = Date.now();
        let status: string;
        let httpLogStatus: HTTPLogStatus;
        let response: HttpResponse<unknown> | HttpErrorResponse;

        return next.handle(req).pipe(
            tap(
                (event) => {
                    status = event instanceof HttpResponse ? 'succeeded' : '';
                    httpLogStatus = HTTPLogStatus.SUCCESS;
                    response = event as HttpResponse<unknown>;
                },
                (error: HttpErrorResponse) => {
                    status = 'failed';
                    httpLogStatus = HTTPLogStatus.FAILURE;
                    response = error;
                },
            ),
            finalize(() => {
                const endTime = Date.now() - startTime;
                const message = `[${req.method}] "${req.urlWithParams}" ${status} in ${endTime} ms.`;

                this.loggerService.logHttpRequest(message, httpLogStatus, endTime, req, response);
            }),
        );
    }
}
