import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NLAUserCardViewComponent } from './nla-user-card-view.component';
import { NLADomainModule } from '../../nla-domain/nla-domain.module';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';
import { NLAProfileTitleModule } from '../../nla-domain/common/nla-profile-title/nla-profile-title.module';

@NgModule({
    declarations: [NLAUserCardViewComponent],
    imports: [CommonModule, NLADomainModule, AccountListItemModule, NLAProfileTitleModule],
})
export class NLAUserCardViewModule {}
