import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { EntityNotFoundDialogComponent } from './entity-not-found-dialog.component';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class EntityNotFoundService {
    constructor(private readonly matDialog: MatDialog, private readonly router: Router) {}

    displayDialog(searchUrl: string): void {
        this.router.navigate(['/dashboard']).then((navigated: boolean) => {
            if (navigated) {
                this.matDialog.open(EntityNotFoundDialogComponent, {
                    data: searchUrl,
                });
            }
        });
    }
}
