import { ExpertBookmark } from '../../expert-api/expert.types';
import { combineReducers, createReducer, on } from '@ngrx/store';
import { ExpertBookmarkActions } from './expert-bookmark.actions';
import { BookmarkActions } from '@tploy-enterprise/tenant-core';

export interface ExpertBookmarksStatusState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
}

export interface ExpertBookmarksState {
    status: ExpertBookmarksStatusState;
    data: Array<ExpertBookmark>;
}

const expertBookmarksStatusInitialState: ExpertBookmarksStatusState = {
    loaded: false,
    loading: false,
    loadingError: null,
};

const statusReducer = createReducer(
    expertBookmarksStatusInitialState,
    on(ExpertBookmarkActions.load, (state) => ({ ...state, loading: true })),
    on(ExpertBookmarkActions.loadError, (state, { error }) => ({ ...state, loadingError: error, loading: false })),
    on(ExpertBookmarkActions.loadSuccess, (state) => ({ ...state, loadingError: null, loading: false, loaded: true })),
    on(BookmarkActions.addSuccess, (state) => ({ ...state, loaded: false })),
    on(BookmarkActions.deleteSuccess, (state) => ({ ...state, loaded: false })),
);

const expertBookmarksReducer = createReducer(
    [],
    on(ExpertBookmarkActions.loadSuccess, (state, { data }) => data),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) =>
        state.filter((existingBookmark) => {
            return existingBookmark.id !== bookmark.id;
        }),
    ),
);

export const expertBookmarksInitialState: ExpertBookmarksState = {
    status: expertBookmarksStatusInitialState,
    data: [],
};

export const expertBookmarksDataReducer = combineReducers({
    status: statusReducer,
    data: expertBookmarksReducer,
});
