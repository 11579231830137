import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
    ExpertAvailabilityData,
    ExpertBookmark,
    ExpertMatch,
    ExpertMatchResults,
    ExpertOthersProfile,
    ExpertProfile,
    ExpertSearchResult,
    ExpertSearchResults,
    ExpertSuggestedSkills,
} from './expert.types';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
    BookmarksApi,
    BookmarksService,
    GeneralData,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { ExpertSerializer } from './expert.serializer';
import { ExpertApi } from './expert.api';
import { ExpertDomainMatchingException, ExpertDomainSearchException } from './expert.exceptions';

@Injectable({
    providedIn: 'root',
})
export class ExpertService {
    constructor(
        private readonly expertApi: ExpertApi,
        private readonly bookmarksApi: BookmarksApi,
        private readonly bookmarksService: BookmarksService,
        private readonly serializer: ExpertSerializer,
        private readonly paginatorService: PaginatorService,
    ) {}

    getProfile(): Observable<ExpertProfile> {
        return this.expertApi.getProfile().pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    getExpertProfileById(id: string): Observable<ExpertOthersProfile> {
        return this.expertApi.getProfileById(id).pipe(
            map((dto) => {
                return { ...this.serializer.deserializeOthers(dto), userId: id };
            }),
        );
    }

    putProfile(profile: ExpertProfile, general: GeneralData): Observable<ExpertProfile> {
        const dto = this.serializer.serialize(profile, general);
        return this.expertApi.putProfile(dto).pipe(map((savedDto) => this.serializer.deserialize(savedDto)));
    }

    putUnavailability(availability: ExpertAvailabilityData): Observable<ExpertProfile> {
        const availabilityDto = this.serializer.serializeAvailability(availability);
        return this.expertApi.putUnavailability(availabilityDto).pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    getBookmarks(context: string): Observable<ExpertBookmark[]> {
        return this.bookmarksService.loadBookmarksByContext(context).pipe(
            // Safeguard: makes sure we don't fire more than 20 loading requests.
            map((results) => results.slice(0, 20)),
            map((results) => results.map((dto) => this.serializer.deserializeBookmark(dto))),
            switchMap((results) => {
                if (results.length > 0) {
                    return forkJoin(results.map((r) => this.hydrateExpertBookmark(r)));
                } else {
                    return of([]);
                }
            }),
            map((bookmarks) => bookmarks.filter((bookmark) => !!bookmark.expertProfile)),
        );
    }

    getSuggestedSkills(id: string): Observable<ExpertSuggestedSkills> {
        return this.expertApi.getSuggestedSkills(id).pipe(
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
            catchError((error) => of({ aType: [], lType: [], sType: [] })),
        );
    }

    getMatches(pageParams: PageParams): Observable<ExpertMatchResults> {
        return this.expertApi.getMatches(this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.serializer.deserializeMath(dto))),
            catchError(() => {
                throw new ExpertDomainMatchingException();
            }),
            switchMap((matches) => {
                if (matches.length > 0) {
                    const hydratedMatches = matches.map((result) => this.hydrateMatchResult(result));
                    return forkJoin(hydratedMatches).pipe(
                        map((hydratedMatches) => ({
                            hydratedMatches,
                            mergedMatches: matches.map((i) => hydratedMatches.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedMatches, mergedMatches }) => {
                            return {
                                matches: hydratedMatches.filter((hydratedMatch) => !!hydratedMatch.expertProfile),
                                allMatches: this.paginatorService.fillAllPagesArray(mergedMatches, pageParams),
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({ matches: [], allMatches: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } });
                }
            }),
        );
    }

    searchEntities(searchQuery: string, pageParams: PageParams): Observable<ExpertSearchResults> {
        return this.expertApi.getSearch(searchQuery, this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.serializer.deserializeSearchResult(dto))),
            catchError(() => {
                throw new ExpertDomainSearchException();
            }),
            switchMap((results) => {
                if (results.length > 0) {
                    const hydratedResults = results.map((result) => this.hydrateSearchResult(result));
                    return forkJoin(hydratedResults).pipe(
                        map((hydratedResults) => ({
                            hydratedResults,
                            mergedResults: results.map((i) => hydratedResults.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedResults, mergedResults }) => {
                            return {
                                results: hydratedResults.filter((hydratedResult) => !!hydratedResult.expertProfile),
                                allResults: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({ results: [], allResults: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } });
                }
            }),
        );
    }

    hydrateSearchResult(result: ExpertSearchResult): Observable<ExpertSearchResult> {
        const bookmark$ = this.bookmarksApi.getBookmark(result.userId).pipe(take(1));

        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateMatchResult(result: ExpertMatch): Observable<ExpertMatch> {
        const bookmark$ = this.bookmarksApi.getBookmark(result.userId).pipe(take(1));

        return forkJoin({ bookmark: bookmark$ }).pipe(
            map((hydrationData) => {
                return { ...result, ...hydrationData };
            }),
        );
    }

    hydrateExpertBookmark(result: ExpertBookmark): Observable<ExpertBookmark> {
        const profile$ = this.getExpertProfileById(result.entityId).pipe(catchError(() => of(null)));
        const bookmark$ = this.bookmarksApi.getBookmark(result.entityId).pipe(take(1));

        return forkJoin({ expertProfile: profile$, bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...result, ...hydrationData })),
        );
    }

    loadSearchResult(searchQuery: string, index: number): Observable<ExpertSearchResult> {
        return this.expertApi.getSearch(searchQuery, { offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) => (results.length > 0 ? this.serializer.deserializeSearchResult(results[0]) : null)),
            catchError(() => {
                throw new ExpertDomainSearchException();
            }),
        );
    }

    loadMatchResult(index: number): Observable<ExpertMatch> {
        return this.expertApi.getMatches({ offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) => (results.length > 0 ? this.serializer.deserializeMath(results[0]) : null)),
            catchError(() => {
                throw new ExpertDomainMatchingException();
            }),
        );
    }
}
