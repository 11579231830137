import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Skill, SKILL_MAX_LENGTH } from '../skill.types';

@Injectable({
    providedIn: 'root',
})
export class SkillValidators {
    static skillEntriesMaxLength(control: AbstractControl): any | null {
        return SkillValidators.skillEntriesControlMaxLength(SKILL_MAX_LENGTH)(control);
    }

    static skillMaxLength(control: AbstractControl): any | null {
        return SkillValidators.skillControlMaxLength(SKILL_MAX_LENGTH)(control);
    }

    static skillEntriesControlMaxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const wrongSkills =
                control.value
                    ?.filter((skill) => skill)
                    .filter((skill) => SkillValidators.skillLength(skill) > maxLength) ?? [];
            return wrongSkills.length === 0 ? null : { skillMaxLength: true };
        };
    }

    static skillControlMaxLength(maxLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const skillValid = control.value ? SkillValidators.skillLength(control.value) <= maxLength : true;
            return skillValid ? null : { skillMaxLength: true };
        };
    }

    static skillLength(skill: string | Skill): number {
        if (typeof skill === 'string') {
            return skill.length;
        } else {
            return skill.name.length;
        }
    }
}
