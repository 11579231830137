import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-sub-content',
    templateUrl: './card-sub-content.component.html',
    host: {
        class: 'card__sub-content',
    },
})
export class CardSubContentComponent {}
