import { Component, Input } from '@angular/core';
import { Bookmark, GeneralData, TopicUserCardView } from '@tploy-enterprise/tenant-core';
import { ExpertProfile } from '../../expert-api/expert.types';

@Component({
    selector: 'tp-expert-user-card-view',
    templateUrl: './expert-user-card-view.component.html',
})
export class ExpertUserCardViewComponent implements TopicUserCardView {
    @Input() topicProfileData: ExpertProfile;
    @Input() bookmark: Bookmark;
    @Input() generalData: GeneralData;

    get context(): string {
        return this.bookmark?.context;
    }

    get user(): GeneralData {
        return this.generalData;
    }

    get tags(): string[] {
        return this.topicProfileData.skillsOffering;
    }

    get selectedTags(): string[] {
        return [];
    }
}
