import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GenerationExchangeActions } from './generation-exchange.actions';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
    AccountActions,
    AccountState,
    GENERATION_EXCHANGE_TOPIC_NAME,
    SelectedTopicsActions,
    StorageService,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { GenerationExchangeState } from './generation-exchange.reducer';
import { GenerationExchangeService } from './generation-exchange-service/generation-exchange.service';
import {
    GenerationExchangeBookmarks,
    GenerationExchangeMatchResults,
    GenerationExchangeProfile,
    IS_PROFILE_DIRTY,
    PROFILE_STORAGE_KEY,
} from './generation-exchange-service/generation-exchange.types';
import {
    isGenerationExchangeProfileInDirtyState,
    selectCurrentGenerationExchangeProfile,
    selectSavedGenerationExchangeProfile,
} from './generation-exchange.selectors';
import { GenerationExchangeUnexpectedException } from './generation-exchange-service/generation-exchange.exceptions';

@Injectable({
    providedIn: 'root',
})
export class GenerationExchangeEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{
            generationExchange: GenerationExchangeState;
            account: AccountState;
            router: RouterReducerState;
        }>,
        private readonly generationExchangeService: GenerationExchangeService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
    ) {}

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadProfile),
            switchMap(() =>
                this.generationExchangeService.loadProfile().pipe(
                    map((profile: GenerationExchangeProfile) =>
                        GenerationExchangeActions.loadProfileSuccess({
                            profile,
                            session: this.readProfileFromSession(),
                        }),
                    ),
                    catchError((error) => {
                        if (error instanceof GenerationExchangeUnexpectedException) {
                            // Other possible error is "Not Found" but this is a valid scenario
                            // When something else happens, we want to log it.
                            this.errorHandler.handleError(error);
                        }
                        return of(
                            GenerationExchangeActions.loadProfileError({
                                error,
                                session: this.readProfileFromSession(),
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.generationExchangeService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return GenerationExchangeActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(GenerationExchangeActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GenerationExchangeActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentGenerationExchangeProfile)),
                tap(([{ currentProfile }, state]) => {
                    this.storeProfileInSession({ ...state, ...currentProfile });
                }),
            ),
        { dispatch: false },
    );

    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => GenerationExchangeActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.saveProfile),
            // ignore the sta profile's non dirty state
            // it is a responsibility of the sta to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isGenerationExchangeProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentGenerationExchangeProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.generationExchangeService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) =>
                        GenerationExchangeActions.saveProfileSuccess({ currentProfile: savedProfile }),
                    ),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(GenerationExchangeActions.saveProfileError({ error, currentProfile }));
                    }),
                ),
            ),
        ),
    );

    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                GenerationExchangeActions.cancelCurrentProfileEdit,
                AccountActions.createSuccess,
                SelectedTopicsActions.cancelEdit,
            ),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedGenerationExchangeProfile)),
            map(([action, profile]) => GenerationExchangeActions.loadProfileSuccess({ profile, session: null })),
        ),
    );

    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GenerationExchangeActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadMatches),
            switchMap((action) =>
                this.generationExchangeService.getProfileMatches(action.pageParams, action.campaigns).pipe(
                    map((result: GenerationExchangeMatchResults) =>
                        GenerationExchangeActions.loadMatchesSuccess({
                            allResults: result.allMatches,
                            results: result.matches,
                            length: result.total,
                            pageParams: result.pageParams,
                            selectedCampaigns: action.campaigns,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(GenerationExchangeActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadBookmarks),
            switchMap((action) =>
                this.generationExchangeService.getBookmarks(GENERATION_EXCHANGE_TOPIC_NAME).pipe(
                    map((result: GenerationExchangeBookmarks) =>
                        GenerationExchangeActions.loadBookmarksSuccess({
                            bookmarks: result.bookmarks,
                            length: result.total,
                        }),
                    ),
                    catchError((error: Error) => {
                        this.errorHandler.handleError(error);
                        return of(GenerationExchangeActions.loadBookmarksError({ error }));
                    }),
                ),
            ),
        ),
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.search),
            switchMap((action) =>
                this.generationExchangeService.search(action.searchQuery, action.pageParams, action.campaigns).pipe(
                    map((results) =>
                        GenerationExchangeActions.searchSuccess({
                            allResults: results.allResults,
                            results: results.results,
                            pageParams: results.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(GenerationExchangeActions.searchError({ error }));
                    }),
                ),
            ),
        ),
    );

    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.saveProfileSuccess),
            withLatestFrom(this.getCurrentUrl()),
            switchMap(([action, url]) => {
                if (url && url.includes('/generation-exchange/matching')) {
                    return of(GenerationExchangeActions.loadMatches({ pageParams: { pageIndex: 0, pageSize: 3 } }));
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    loadSearchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadSearchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.generationExchangeService.loadSearchResult(action.searchQuery, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return GenerationExchangeActions.loadSearchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(GenerationExchangeActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    loadMatchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerationExchangeActions.loadMatchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.generationExchangeService.loadMatchResult(action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return GenerationExchangeActions.loadMatchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(GenerationExchangeActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    private getCurrentUrl(): Observable<string> {
        const routerSelector = (state: { router: RouterReducerState }) => state.router;
        const { selectUrl } = getSelectors(routerSelector);
        return this.store.select(selectUrl);
    }

    private storeProfileInSession(profile: GenerationExchangeProfile) {
        this.storage.local.set(PROFILE_STORAGE_KEY, profile);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): GenerationExchangeProfile {
        return this.storage.local.get<GenerationExchangeProfile, null>(PROFILE_STORAGE_KEY);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(PROFILE_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
