<tp-account-list-item
    [context]="context"
    [user]="user"
    [tags]="tags"
    [selectedTags]="selectedTags"
    [profileMessage]="profileMessage"
>
    <tp-job-x-account-tile-locations-header [locations]="locations"></tp-job-x-account-tile-locations-header>
    <ng-container class="account-list-item__campaigns">
        <tp-campaigns-tagger
            *ngIf="campaigns"
            [simpleView]="false"
            [tags]="campaigns"
            [selectedTags]="selectedCampaigns ?? []"
        ></tp-campaigns-tagger>
    </ng-container>
    <ng-container class="account-list-item__buttons" *ngIf="targetProfile && ownProfile">
        <tp-icon-button
            [icon]="'job_sharing_filled'"
            class="no-border"
            disableRipple="true"
            [attr.aria-label]="'JOBX_TANDEM_CHECK_TITLE' | translatable"
            (click)="openTandemDialog($event)"
            (keydown.enter)="openTandemDialog($event)"
            [title]="'JOBX_TANDEM_CHECK_TITLE' | translatable"
        ></tp-icon-button>
    </ng-container>
</tp-account-list-item>
