import { BaseStorageAdapter } from './base-storage.adapter';
import { WindowRef } from '../../window-ref';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageAdapter extends BaseStorageAdapter {
    constructor(private windowRef: WindowRef) {
        super(windowRef.nativeWindow.localStorage);
    }
}
