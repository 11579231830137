import { createReducer, on } from '@ngrx/store';
import { ExpertMatch } from '../../expert-api/expert.types';
import { ExpertMatchingActions } from './expert-matching.actions';
import { PageParams } from '@tploy-enterprise/tenant-core';

export interface ExpertMatchesState {
    loading: boolean;
    loadingError: Error;
    data: Array<ExpertMatch>;
    allData: Array<ExpertMatch>;
    pageParams: PageParams;
}

export const expertMatchingInitialState: ExpertMatchesState = {
    loading: false,
    loadingError: null,
    data: null,
    allData: null,
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
};

export const expertMatchingReducer = createReducer(
    expertMatchingInitialState,
    on(ExpertMatchingActions.load, (state) => ({ ...state, loading: true })),
    on(ExpertMatchingActions.loadSuccess, (state, { results, allResults, pageParams }) => {
        return {
            ...state,
            loading: false,
            loadingError: null,
            data: results,
            allData: allResults,
            pageParams,
        };
    }),
    on(ExpertMatchingActions.loadMatchResultSuccess, (state, { data, index }) => {
        state.allData[index] = data;
        return {
            ...state,
            allData: [...state.allData],
        };
    }),
    on(ExpertMatchingActions.loadError, (state, { error }) => ({ ...state, loading: false, loadingError: error })),
);
