import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { GenerationExchangeSerializer } from './generation-exchange.serializer';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    GenerationExchangeDomainBookmarksException,
    GenerationExchangeDomainMatchingException,
    GenerationExchangeDomainSearchException,
    GenerationExchangeMyProfileNotFoundException,
    GenerationExchangeOthersProfileNotFoundException,
    GenerationExchangeProfileBadRequestException,
    GenerationExchangeUnexpectedException,
} from './generation-exchange.exceptions';
import {
    BookmarksApi,
    BookmarksService,
    GeneralData,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { GenerationExchangeApi } from '../generation-exchange-api/generation-exchange.api';
import {
    GenerationExchangeAccountListItem,
    GenerationExchangeSearchResult,
    GenerationExchangeMatch,
    GenerationExchangeBookmarks,
    GenerationExchangeMatchResults,
    GenerationExchangeProfile,
    GenerationExchangeSearchResults,
    OthersGenerationExchangeProfile,
    GenerationExchangeSuggestedSkills,
} from './generation-exchange.types';

@Injectable({
    providedIn: 'root',
})
export class GenerationExchangeService {
    constructor(
        private readonly generationExchangeApi: GenerationExchangeApi,
        private readonly bookmarksApi: BookmarksApi,
        private readonly bookmarksService: BookmarksService,
        private readonly generationExchangeSerializer: GenerationExchangeSerializer,
        private readonly paginatorService: PaginatorService,
    ) {}

    loadProfile(): Observable<GenerationExchangeProfile> {
        return this.generationExchangeApi.getProfile().pipe(
            map((dto) => this.generationExchangeSerializer.deserializeGenerationExchangeProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<OthersGenerationExchangeProfile> {
        return this.generationExchangeApi.getProfileById(id).pipe(
            map((dto) => this.generationExchangeSerializer.deserializeOthersGenerationExchangeProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    saveProfile(profile: GenerationExchangeProfile, general: GeneralData): Observable<GenerationExchangeProfile> {
        const dto = this.generationExchangeSerializer.serializeGenerationExchangeProfile(profile, general);
        return this.generationExchangeApi.putProfile(dto).pipe(
            map((dto) => this.generationExchangeSerializer.deserializeGenerationExchangeProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    getProfileMatches(pageParams: PageParams, campaigns?: string[]): Observable<GenerationExchangeMatchResults> {
        return this.generationExchangeApi
            .getProfileMatches(this.paginatorService.offsetLimit(pageParams), campaigns)
            .pipe(
                tap((response) => (pageParams.length = response.totalCount)),
                map((response) => response.results),
                map((matches) => matches.map((dto) => this.generationExchangeSerializer.deserializeMatch(dto))),
                catchError(() => {
                    throw new GenerationExchangeDomainMatchingException();
                }),
                switchMap((matches) => {
                    if (matches.length > 0) {
                        const hydratedResults = matches.map((result) =>
                            this.hydrateAccountMatchListItem(result, result.userId),
                        );
                        return forkJoin(hydratedResults).pipe(
                            map((hydratedResults) => ({
                                hydratedResults,
                                mergedResults: matches.map(
                                    (i) => hydratedResults.find((j) => j.userId === i.userId) || i,
                                ),
                            })),
                            map(({ hydratedResults, mergedResults }) => {
                                return {
                                    matches: hydratedResults.filter((hydratedResult) => {
                                        if (campaigns?.length > 0) {
                                            hydratedResult.highlight.campaigns = campaigns;
                                        }
                                        return !!hydratedResult.profile;
                                    }),
                                    allMatches: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                    total: pageParams.length,
                                    pageParams,
                                };
                            }),
                        );
                    } else {
                        return of({
                            matches: [],
                            allMatches: [],
                            total: 0,
                            pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
                        });
                    }
                }),
            );
    }

    getBookmarks(context: string): Observable<GenerationExchangeBookmarks> {
        let bookmarksTotal = 0;
        return this.bookmarksService.loadBookmarksByContext(context).pipe(
            tap((bookmarks) => (bookmarksTotal = bookmarks.length)),
            map((bookmarks) => bookmarks.map((dto) => this.generationExchangeSerializer.deserializeBookmark(dto))),
            catchError(() => {
                throw new GenerationExchangeDomainBookmarksException();
            }),
            switchMap((bookmarks) => {
                if (bookmarks.length > 0) {
                    return forkJoin(bookmarks.map((dto) => this.hydrateAccountListItem(dto, dto.entityId))).pipe(
                        map((bookmarks) => {
                            return { bookmarks: bookmarks, total: bookmarksTotal };
                        }),
                    );
                } else {
                    return of({ bookmarks: [], total: 0 });
                }
            }),
            map(({ bookmarks, total }) => ({
                bookmarks: bookmarks.filter((bookmark) => !!bookmark.profile),
                total,
            })),
        );
    }

    getSuggestedSkills(id: string): Observable<GenerationExchangeSuggestedSkills> {
        return this.generationExchangeApi.getSuggestedSkills(id).pipe(
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
            catchError((error) => of({ aType: [], lType: [], sType: [] })),
        );
    }

    search(
        searchQuery: string,
        pageParams: PageParams,
        campaigns?: string[],
    ): Observable<GenerationExchangeSearchResults> {
        return this.generationExchangeApi
            .search(searchQuery, this.paginatorService.offsetLimit(pageParams), campaigns)
            .pipe(
                tap((response) => (pageParams.length = response.totalCount)),
                map((response) => response.results),
                map((results) => results.map((dto) => this.generationExchangeSerializer.deserializeSearchResult(dto))),
                switchMap((results) => {
                    if (results.length > 0) {
                        const hydratedResults = results.map((result) =>
                            this.hydrateAccountSearchListItem(result, result.userId),
                        );
                        return forkJoin(hydratedResults).pipe(
                            map((hydratedResults) => ({
                                hydratedResults,
                                mergedResults: results.map(
                                    (i) => hydratedResults.find((j) => j.userId === i.userId) || i,
                                ),
                            })),
                            map(({ hydratedResults, mergedResults }) => {
                                return {
                                    results: hydratedResults.filter((hydratedResult) => {
                                        if (campaigns?.length > 0) {
                                            hydratedResult.highlight.campaigns = campaigns;
                                        }
                                        return !!hydratedResult.profile;
                                    }),
                                    allResults: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                    pageParams,
                                };
                            }),
                        );
                    } else {
                        return of({
                            results: [],
                            allResults: [],
                            pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
                        });
                    }
                }),
                catchError(() => {
                    throw new GenerationExchangeDomainSearchException();
                }),
            );
    }

    loadSearchResult(searchQuery: string, index: number): Observable<GenerationExchangeSearchResult> {
        return this.generationExchangeApi.search(searchQuery, { offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) =>
                results.length > 0 ? this.generationExchangeSerializer.deserializeSearchResult(results[0]) : null,
            ),
            catchError(() => {
                throw new GenerationExchangeDomainSearchException();
            }),
        );
    }

    loadMatchResult(index: number): Observable<GenerationExchangeMatch> {
        return this.generationExchangeApi.getProfileMatches({ offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) =>
                results.length > 0 ? this.generationExchangeSerializer.deserializeMatch(results[0]) : null,
            ),
            catchError(() => {
                throw new GenerationExchangeDomainSearchException();
            }),
        );
    }

    hydrateAccountMatchListItem(
        result: GenerationExchangeMatch,
        accountId: string,
    ): Observable<GenerationExchangeMatch> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));
        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateAccountSearchListItem(
        result: GenerationExchangeSearchResult,
        accountId: string,
    ): Observable<GenerationExchangeSearchResult> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));
        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateAccountListItem(
        result: GenerationExchangeAccountListItem,
        accountId: string,
    ): Observable<GenerationExchangeAccountListItem> {
        const profile$ = this.loadProfileById(accountId).pipe(catchError(() => of(null)));
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));
        return forkJoin({ profile: profile$, bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...result, ...hydrationData })),
        );
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new GenerationExchangeProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new GenerationExchangeMyProfileNotFoundException();
                    } else {
                        throw new GenerationExchangeOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new GenerationExchangeUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
