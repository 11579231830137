import { combineReducers, createReducer, on } from '@ngrx/store';
import { MediaActions } from './media.actions';
import { Media } from './media.types';

export interface MediaState {
    allUserMedia: AllUserMediaState;
}

export interface AllUserMediaState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    data: Array<Media>;
}

const allUserMediaInitialState: AllUserMediaState = {
    loaded: false,
    loading: false,
    loadingError: null,
    data: [],
};

const allUserMediaReducer = createReducer(
    allUserMediaInitialState,
    on(MediaActions.loadAllUserMedia, (state) => ({ ...state, loading: true, loadingError: null })),
    on(MediaActions.loadAllUserMediaSuccess, (state, { data }) => ({
        ...state,
        loading: false,
        loadingError: null,
        data,
    })),
    on(MediaActions.loadAllUserMediaError, (state, { error }) => ({ ...state, loading: false, loadingError: error })),
    on(MediaActions.deleteUserMediaSuccess, (state, { id }) => ({
        ...state,
        data: state.data.filter((media) => media.id !== id),
    })),
);

export const mediaReducer = combineReducers({
    allUserMedia: allUserMediaReducer,
});
