import { JobXProfile } from './jobs.types';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { selectCurrentJobXProfile, isJobXProfileLoadedOrLoading, isJobXProfileLoaded } from './jobx.selectors';
import { take, filter, switchMap } from 'rxjs/operators';
import { JobsActions } from './jobs.actions';
import { JobXState } from './jobXReducer';

@Injectable({
    providedIn: 'root',
})
export class JobXCurrentProfileResolver implements Resolve<JobXProfile> {
    constructor(private readonly store: Store<{ jobx: JobXState }>) {}

    resolve(): Observable<JobXProfile> {
        this.loadIfNotLoaded();

        return this.store.select(isJobXProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentJobXProfile)),
            take(1),
        );
    }

    private loadIfNotLoaded() {
        this.store
            .select(isJobXProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(JobsActions.loadProfile());
                }
            });
    }
}
