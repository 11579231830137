import {
    Component,
    ChangeDetectionStrategy,
    Inject,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormBuilder } from '@angular/forms';
import { ImageAdapterService } from '@tploy-enterprise/tenant-common';
import { MediaService } from '../../../media';
import { ENTITY_PLACEHOLDERS } from '../../image-picker.di';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IMAGE_UPLOAD_LIMIT, ALLOWED_FILE_TYPES } from '../../image-picker.constant';

@Component({
    selector: 'tp-image-picker-dialog',
    templateUrl: './image-picker-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'image-picker-dialog',
    },
})
export class ImagePickerDialogComponent implements OnInit {
    public hasOwnPicture: boolean;
    public uploadControl: FormControl;
    public loading = false;
    public cropping = false;
    public sizeTooLarge = false;
    public wrongFileType = false;
    public showCropper = false;
    public imageChangedEvent: any = '';
    public imageFile: any = '';
    public cropImage: string;
    public nameFileCropImage: string;
    public typeFileCropImage: string;

    @ViewChild('selectBtn')
    selectBtn: ElementRef<HTMLButtonElement>;
    @ViewChild('upload')
    uploadBtn: ElementRef<HTMLButtonElement>;

    constructor(
        public dialogRef: MatDialogRef<ImagePickerDialogComponent, string>,
        @Inject(MAT_DIALOG_DATA) public selectedAvatar: string,
        @Inject(ENTITY_PLACEHOLDERS) public placeholders: string[],
        private fb: FormBuilder,
        private imageAdapter: ImageAdapterService,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaService,
    ) {}

    ngOnInit() {
        this.hasOwnPicture = this.placeholders.indexOf(this.selectedAvatar) === -1;
        this.placeholders =
            this.hasOwnPicture && !!this.selectedAvatar
                ? [this.selectedAvatar, ...this.placeholders]
                : [...this.placeholders];
        this.uploadControl = this.fb.control(null);
    }

    public select(url: string): void {
        this.selectedAvatar = url;
        this.hasOwnPicture = false;
        this.changeDetectorRef.markForCheck();
    }

    public onFileChange(event: Event): void {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            this.setFile(target.files[0]);
        }
        this.changeDetectorRef.markForCheck();
    }

    public onFileChangeDrag(event: File[]): void {
        this.setFile(event[0]);
        this.changeDetectorRef.markForCheck();
    }

    public loadImageFailed(): void {
        this.showCropper = false;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.cropImage = event.base64;
    }

    public uploadFile(url: string): void {
        this.cropping = true;
        this.sizeTooLarge = false;
        this.wrongFileType = false;
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], this.nameFileCropImage, { type: this.typeFileCropImage });
                this.readFileBeforeUpload(file);
            })
            .catch((error) => {
                this.cropping = false;
                this.sizeTooLarge = true;
                this.wrongFileType = true;
                this.changeDetectorRef.markForCheck();
            });
    }

    private readFileBeforeUpload(files: File | File[]): void {
        const file = files instanceof File ? files : files[0];
        if (file.size > IMAGE_UPLOAD_LIMIT) {
            this.cropping = false;
            this.sizeTooLarge = true;
            this.changeDetectorRef.markForCheck();
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            this.cropping = false;
            this.wrongFileType = true;
            this.changeDetectorRef.markForCheck();
        } else {
            this.startLoading();
            this.readFile(file)
                .then((file) => this.media.uploadImage(file, 'square').toPromise())
                .then((image) => {
                    this.loading = false;
                    this.placeholders = [image, ...this.placeholders];
                    this.selectedAvatar = image;
                    this.hasOwnPicture = true;
                    this.showCropper = false;
                    this.resetFile();
                    this.changeDetectorRef.markForCheck();
                });
        }
    }

    private readFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                this.imageAdapter.adaptImage(reader).subscribe((reader) => {
                    if (reader.readyState === reader.DONE) {
                        resolve(reader.result as string);
                    } else {
                        reject(reader.error);
                    }
                });
            };
            reader.readAsDataURL(file);
        });
    }

    private startLoading(): void {
        this.loading = true;
        this.sizeTooLarge = false;
        this.cropping = false;
    }

    private resetFile(): void {
        this.uploadBtn.nativeElement.value = '';
    }

    private setFile(file: File): void {
        if (file.size > IMAGE_UPLOAD_LIMIT) {
            this.sizeTooLarge = true;
            return;
        }
        this.nameFileCropImage = file.name;
        this.typeFileCropImage = file.type;
        this.imageFile = file;
        this.sizeTooLarge = false;
        this.showCropper = true;
    }
}
