import { Component, Input } from '@angular/core';
import { Translatable } from '../../language';
import { CardTag } from '../card.types';

@Component({
    selector: 'tp-card-tags',
    templateUrl: './card-tags.component.html',
    host: {
        class: 'card__tags',
    },
})
export class CardTagsComponent {
    @Input() withLevel: boolean;
    @Input() title: string;
    @Input() icon: string;
    @Input() tags: CardTag[];

    getLevelLabel(level: number): Translatable {
        return {
            message: `SKILL_LEVEL_${level}`,
        };
    }
}
