<tp-account-list-item
    [context]="context"
    [user]="user"
    [bookmark]="bookmark"
    [tags]="tags"
    [selectedTags]="selectedTags"
>
    <tp-nla-profile-title
        [locations]="locations"
        [highlightLocations]="highlightLocations"
        [isLocationIndependent]="locationIndependent"
        [highlightLocationIndependent]="highlightLocationIndependent"
    ></tp-nla-profile-title>
</tp-account-list-item>
