import { Inject, Injectable } from '@angular/core';
import { Logger } from './Logger';
import { Router, RouterEvent } from '@angular/router';
import { RouterStack } from './router-stack';
import { HTTPLogStatus, LogData, LogType } from './logger.types';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { LOCAL_TENANT_CONFIGURATION } from '../../tenant-core.di';
import { LocalTenantConfiguration } from '../../tenant-core.types';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private logger: Logger;

    private routerStack: RouterStack = new RouterStack();

    constructor(
        private router: Router,
        private deviceDetectorService: DeviceDetectorService,
        @Inject(LOCAL_TENANT_CONFIGURATION) readonly localTenantConfiguration: LocalTenantConfiguration,
    ) {
        this.logger = new Logger(this.localTenantConfiguration);
        router.events.subscribe((event) => {
            if (event instanceof RouterEvent) {
                this.routerStack.add(event);
            }
        });
    }

    private prepareFields(
        elapsedTime = 0,
        requestPath = '',
        status?: number,
        statusText?: string,
    ): LogData | (LogData & DeviceInfo & Location) {
        return {
            url: location.href,
            elapsedTime,
            requestPath,
            routerStack: this.routerStack.getElements(),
            status,
            statusText,
            ...this.getBrowserTrackingData(),
        };
    }

    private getBrowserTrackingData(): (DeviceInfo & Location) | Record<string, unknown> {
        if (this.allowBrowserTracking()) {
            return {
                ...window.location,
                ...this.deviceDetectorService.getDeviceInfo(),
                isMobile: this.deviceDetectorService.isMobile(),
                isDesktop: this.deviceDetectorService.isDesktop(),
                isTablet: this.deviceDetectorService.isTablet(),
                browserInnerHeight: window.innerHeight,
                browserInnerWidth: window.innerWidth,
                browserOuterHeight: window.outerHeight,
                browserOuterWidth: window.outerWidth,
                windowSize: `${window.innerWidth}x${window.innerHeight}`,
                browserSize: `${window.outerWidth}x${window.outerHeight}`,
                scrollXOffset: window.pageXOffset,
                scrollYOffset: window.pageYOffset,
                cookiesEnabled: window.navigator.cookieEnabled,
                browserEngine: window.navigator.product,
            };
        }
        return {};
    }

    private allowBrowserTracking(): boolean {
        if ((window as any).doNotTrack || navigator.doNotTrack || (navigator as any).msDoNotTrack) {
            return !(
                (window as any).doNotTrack === '1' ||
                navigator.doNotTrack === 'yes' ||
                navigator.doNotTrack === '1' ||
                (navigator as any).msDoNotTrack === '1'
            );
        }
        return true;
    }

    public logHttpRequest(
        message: string,
        status: HTTPLogStatus,
        requestDuration: number,
        request: HttpRequest<unknown>,
        response: HttpResponse<unknown> | HttpErrorResponse,
    ): void {
        if (status === HTTPLogStatus.SUCCESS) {
            this.info(
                message,
                this.prepareFields(requestDuration, request.urlWithParams, response.status, response.statusText),
            );
        } else if (status === HTTPLogStatus.FAILURE) {
            this.error(
                message,
                this.prepareFields(requestDuration, request.urlWithParams, response.status, response.statusText),
            );
        }
    }

    public warning(message: string, logData?: LogData): void {
        this.logger.log(LogType.WARNING, message, logData || this.prepareFields());
    }

    public error(message: string, logData?: LogData): void {
        this.logger.log(LogType.ERROR, message, logData || this.prepareFields());
    }

    public info(message: string, logData?: LogData): void {
        this.logger.log(LogType.INFO, message, logData || this.prepareFields());
    }

    public log(message: string, logData?: LogData): void {
        this.logger.log(LogType.DEBUG, message, logData || this.prepareFields());
    }

    public fatal(message: string, logData?: LogData): void {
        this.logger.log(LogType.FATAL, message, logData || this.prepareFields());
    }
}
