export default {
    WORKSHOP: 'Events',
    WORKSHOP_SINGULAR: 'Event',
    WORKSHOP_INFO:
        'If you want to advertise an internal event and win exactly the right participants, event matching is the right choice. Here you can create internal information events, network events, events and further trainings –internal events of any kind – and specify exactly how many participants are wanted and whether they should bring certain skills (in addition to interest in a particular topic). Potentially relevant members will automatically receive a notification and can register easily! Or vice versa: If you want to stay informed about internal events, you should definitely select "Events" and indicate what you are particularly interested in.',
    WORKSHOP_ENTITY_CREATE_BTN: 'New event',
    WORKSHOP_TAB_MATCHING: 'Matches',
    WORKSHOP_TAB_MY: 'My events',
    WORKSHOP_TAB_SEARCH: 'Search',
    WORKSHOP_TAB_OFFERED_BY_ME: 'Offered by me',
    CREATE_WORKSHOP_DIALOG_TITLE: 'Create Event',
    EDIT_WORKSHOP_DIALOG_TITLE: 'Edit event',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_REQUIRED_ERROR_MSG: 'At least one skill must be added',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_ADD_SKILL_BTN_TITLE: 'Add skill',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_LABEL: 'Helpful prior knowledge',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_MUST_HAVE_SKILLS_LABEL: 'Activate level',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_LEVEL_LABEL: 'Level',
    PUBLISH_WORKSHOP_DIALOG_TITLE: 'What happens after publishing a event?',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_1: 'Your event will be online and colleagues get notified.',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_2:
        'Colleagues who are possibly interested in events will be matched with your event and receive a notification. From now on they can register for the event until the application deadline is reached.',
    PUBLISH_WORKSHOP_DIALOG_PUBLISH_BTN_TITLE: 'Publish event',
    WORKSHOP_DELETE_DIALOG_HEADER: 'Delete Offer',
    WORKSHOP_DELETE_DIALOG_CONTENT: 'Are you sure you want to delete this offer?',
    WORKSHOP_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Are you sure you want to irrevocably delete this offer including potential applicants and/or participants? If you delete it, all users involved will be notified accordingly. The offer can then no longer be accessed.',

    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_1: 'Create your first Event',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_2:
        'This list is still empty. To create your first Event, just click the button below.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CREATE_WORKSHOP_BTN: 'Create a new event',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_ASIDE_CONTENT_1: 'Invite colleagues',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_ASIDE_CONTENT_2: 'Invite colleagues to join this platform.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_INVITE_BTN: 'Invite',
    WORKSHOPS_OFFERED_BY_ME_TILE_CONTINUE_EDIT: 'Continue editing',
    WORKSHOPS_OFFERED_BY_ME_TILE_VIEW_DETAILS_BTN_TITLE: 'View Details',
    WORKSHOPS_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Message participants',
    WORKSHOPS_OFFERED_BY_ME_TILE_LAST_EDITED_INDICATOR_LABEL: 'last version from',
    WORKSHOPS_OFFERED_BY_ME_TILE_CONTACT_LABEL: 'Contact',
    WORKSHOPS_OFFERED_BY_ME_TILE_START_DATE_INDICATOR_LABEL: 'date',
    WORKSHOPS_OFFERED_BY_ME_TILE_APPLICANTS_INDICATOR_LABEL: 'applicants',
    WORKSHOPS_OFFERED_BY_ME_TILE_PLANNED: 'Planned',
    WORKSHOPS_OFFERED_BY_ME_TILE_RUNNING: 'Running',
    WORKSHOPS_OFFERED_BY_ME_TILE_COMPLETED: 'Completed',
    WORKSHOP_STATUS_UNPUBLISHED: 'Unpublished',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_TITLE: 'Events',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_SUB_TITLE:
        'Explore exciting Events and/or post your own ones to address fitting participants.',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_INFO:
        'Here you can choose the options that suit you best. Either you can search for interesting events and have them automatically suggested to you. Or you can create your own events and use the matching function to automatically find participants.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_LABEL: 'Please choose what you want to do',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_INTEREST_LABEL: 'Stay informed about suitable events',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_OFFERING_LABEL: 'Offer events and find suitable participants',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_ERROR_MSG: 'At least one option must be selected',
    SIGN_UP_WORKSHOP_INTEREST_STEP_TITLE: 'Events',
    SIGN_UP_WORKSHOP_INTEREST_STEP_SUB_TITLE:
        'Here you can specify skills, abilities and experiences that may be relevant or required for restricted events. This improves your chances',
    SIGN_UP_WORKSHOP_INTEREST_STEP_INFO:
        'What do I have to enter here - and why do you ask for skills? The reason: Suitable events are matched mainly based on your skills and professional orientation. There are also events that require certain skills and skill levels. If you want to have a chance to participate in such events, it is advisable to indicate as many of your skills and the corresponding level as possible.',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LABEL: 'Your skills and previous knowledge',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LIST_SKILLS_LABEL: 'Your skills',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LIST_LEVEL_LABEL: 'Your level of expertise',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_ADD_SKILL_BTN_TITLE: 'Add skill',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_REQUIRED_ERROR_MSG: 'At least one skill must be added',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_ITEM_NAME_REQUIRED_ERROR_MSG: 'Specify skill name',
    SIGN_UP_WORKSHOP_INTEREST_CATEGORIES_LABEL: 'Areas you are particularly interested in',
    SIGN_UP_WORKSHOP_INTEREST_CATEGORIES_ERROR_MSG: 'Specify categories',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_INTERESTED_IN_LABEL: 'Workshop topics you are interested in',
    WORKSHOP_INFO_PANEL_LIST_PARTICIPANTS_LABEL: 'Participants',
    WORKSHOP_MATCHING_TILE_REQUIRED_SKILLS_LABEL: 'Helpful prior knowledge',
    WORKSHOP_MATCHING_TILE_OFFERED_TOPICS_LABEL: 'Workshop topics',
    WORKSHOP_MATCHING_TILE_VIEW_BTN_TITLE: 'View',
    WORKSHOP_MATCHING_TILE_CONTACT_LABEL: 'Contact',
    WORKSHOP_MATCHING_DETAILS_PARTICIPATE_BTN_TITLE: 'Participate',
    WORKSHOP_MATCHING_DETAILS_TO_WAITING_LIST_BTN_TITLE: 'Get on waiting list',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TITLE: 'Only spots on the waiting list available!',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_1:
        'Thank you for your interest in this event! Unfortunately, all currently available places are occupied. If capacities change or other participants drop out, something might become available again.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_2:
        'You are welcome to continue the application process to get on a waiting list. As soon as places become available, all waiting people will be informed at the same time. The quickest person to react is then given a chance.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_CONTINUE_BTN_LABEL: 'Get on waiting list',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_HEADLINE: 'Application not successful',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_HEADING: 'No free seats available any more.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_TEXT:
        'Oh no! Sadly the participant list and the waiting list are already full. It seems someone else was faster. Sorry!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_HEADLINE: 'Thank you for your application',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_HEADING: 'You will be informed about vacant seats.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_TEXT:
        'Notifications will be sent to your e-mail. Then it’s “first come - first served”. Thank you for your patience!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_HEADLINE: 'Thank you for your application',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_HEADING: 'You‘ll be participating in the event!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_TEXT: 'Your application was successful.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_DONE_BTN_LABEL: 'Done',

    WORKSHOP_ENTITY_REQUIRED_SKILLS_TITLE: 'Skills',
    WORKSHOP_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        "Here you can see which prior knowledge would be helpful for participating in this Event. If you discover skills that you actually own, but don't have them in your profile yet, you can select them here and add them to your profile.",

    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Conditions for participation',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE:
        'Please confirm that you have taken note of the following information:',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Individual regulations of your company, which generally govern participation in workshops / events. There may also be specific terms and conditions that users must agree to. Alternatively, this step can also be used for helpful information or generic references.',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL: 'I have taken note of the instructions and agree.',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'No',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Yes',
    WORKSHOP_ENTITY_PARTICIPATE_BTN: 'Participate!',
    WORKSHOP_ENTITY_VIEW_REGISTERED_STATUS_NOTE: 'You got a seat for the event',
    WORKSHOP_ENTITY_VIEW_IN_WAITING_LIST_STATUS_NOTE: 'You are on the waiting list and will be informed by mail',
    WORKSHOP_ENTITY_VIEW_BOOKED_OUT_STATUS_NOTE:
        'The workshop is unfortunately fully booked. Scroll down to get on waiting list',
    WORKSHOP_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'You can apply for {{topicEntity}} by enabling the <i>>>first option in your profile</i> and therefore indicate that your are also interested in participating',

    WORKSHOP_DETAILS_VIEW_DESCRIPTION_TITLE: 'Event description',
    WORKSHOP_DETAILS_VIEW_SKILLS_TITLE: 'Helpful prior knowledge',
    WORKSHOP_DETAILS_VIEW_ORGANIZER_LABEL: 'Contact',
    WORKSHOP_DETAILS_VIEW_START_DATE_FORMAT: 'MMM d, y | h:mm a',

    WORKSHOP_FORM_HEADLINE_DESCRIPTION: 'Workshop description',
    WORKSHOP_FORM_TITLE_LABEL: 'Title of Event',
    WORKSHOP_FORM_START_TIME_LABEL: 'Start time',
    WORKSHOP_FORM_END_TIME_LABEL: 'Ends',
    WORKSHOP_FORM_DATE_LABEL: 'Starts',
    WORKSHOP_FORM_APPLICATION_DEADLINE_LABEL: 'Application deadline',
    WORKSHOP_FORM_VENUE_LABEL: 'Venue',
    WORKSHOP_FORM_LANGUAGES_LABEL: 'Language (necessary for participation)',
    WORKSHOP_FORM_SUBSIDIARY_LABEL: 'Subsidiary (offering the event)',
    WORKSHOP_FORM_DEPARTMENT_LABEL: 'Department/Unit/Division offering the job',
    WORKSHOP_FORM_TARGET_GROUPS_LABEL: 'Target Groups',
    WORKSHOP_FORM_CATEGORY_LABEL: 'Specific category',
    WORKSHOP_FORM_PARTICIPANTS_LABEL: 'Maximum number of participants',
    WORKSHOP_FORM_TOPICS_LABEL: 'Workshop topics in keywords (to notify the right people)',
    WORKSHOP_FORM_DESCRIPTION_LABEL: 'Description',
    WORKSHOP_FORM_DESCRIPTION_ERROR: 'The description has to have at least 50 characters.',
    WORKSHOP_FORM_BUTTON_DRAFT: 'Save draft',
    WORKSHOP_FORM_BUTTON_PUBLISH: 'Publish event',
    WORKSHOP_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unlimited (all are reached)',
    WORKSHOP_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Select a function',

    WORKSHOP_FORM_REQUIRED_SKILLS_LABEL: 'Helpful skills for participation',
    WORKSHOP_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Add the required language',
    WORKSHOP_FORM_HEADLINE_TAGS: 'Workshop Tags',
    WORKSHOP_FORM_HEADLINE_LANGUAGE: 'Required language',
    WORKSHOP_FORM_HEADLINE_TARGET_GROUPS: 'Open for following groups',
    WORKSHOP_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Add one group, if you belong to it',
    WORKSHOP_FORM_HEADLINE_WORKSHOP_TOPICS: 'Workshop topics',
    WORKSHOP_FORM_REQUIRED_TO_ADD_WORKSHOP_TOPIC: 'Select at least one topic',
    WORKSHOP_FORM_TOPIC_TABLE_INTERESTED: 'Interested?',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_NAME: 'Workshop topic',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_INFORMATION: 'Information',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_ADD_IF_YOU_INTERESTED: 'Add topic, if you are interested',
    WORKSHOP_FORM_READY_STATUS: 'Yes! You are ready to participate!',
    WORKSHOP_FORM_ALMOST_READY_STATUS: 'Almost ready to participate!',
    WORKSHOP_FORM_NOT_READY_STATUS: 'Not ready to participate!',
    WORKSHOP_FORM_HEADLINE_NICE_TO_HAVE_SKILLS: 'Add helpful previous knowledge to my profile',
    WORKSHOP_FORM_SKILL_TABLE_INTERESTED: 'Previous knowledge available?',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_NAME: 'Helpful previous knowledge',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_INFORMATION: 'Information',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Add skill, if you bring it',
    WORKSHOP_FORM_RELATED_TERM: 'You have a related term in your profile',

    MY_WORKSHOPS_BUTTON_VIEW_DETAILS: 'View details',
    MY_WORKSHOPS_BUTTON_VIEW_APPLICATION: 'View registration',
    MY_WORKSHOPS_BUTTON_CANCEL_APPLICATION: 'Cancel registration',
    MY_WORKSHOPS_STATUS_NOTIFICATION_REQUESTED:
        'Your registration to the waiting list is complete. \nIf there are still spots available, you will receive an email.',
    MY_WORKSHOPS_PLANNED_TITLE: 'Planned',
    MY_WORKSHOPS_RUNNING_TITLE: 'Running',
    MY_WORKSHOPS_COMPLETED_TITLE: 'Completed',
    MY_WORKSHOPS_VIEW_REQUEST_TITLE: 'Your Application',
    MY_WORKSHOPS_VIEW_REQUEST_STATUS: 'Registration status',
    MY_WORKSHOPS_REG_REQUEST_SENT: 'Application was sent',
    MY_WORKSHOPS_PLACEHOLDER_TEXT: 'You are not yet taking part in any event.',
    MY_WORKSHOPS_PLACEHOLDER_BTN: 'Browse matching events',
    MY_WORKSHOPS_CANCEL_APPLICATION_TITLE: 'Cancel registration',
    MY_WORKSHOPS_CANCEL_APPLICATION_BODY_TEXT: 'Do you want to cancel your planned participation?',
    MY_WORKSHOPS_CANCEL_APPLICATION_BODY_SUB_TEXT: 'Your spot will be available to another person',
    MY_WORKSHOPS_CANCEL_APPLICATION_CANCEL_BUTTON: 'Yes, cancel registration!',

    WORKSHOP_SEARCH_HEADER: 'Search for Workshops by keywords',
    WORKSHOP_SEARCH_BTN_TEXT: 'Search!',
    WORKSHOP_SEARCH_RESULT_REQUESTED_SKILLS: 'Helpful prior knowledge',
    WORKSHOP_SEARCH_RESULT_OFFERED_TOPICS_LABEL: 'Workshop topics',
    WORKSHOP_SEARCH_RESULT_VIEW_ENTITY: 'View Details',
    WORKSHOP_SEARCH_RESULT_AVATAR_LABEL: 'Contact',
    WORKSHOP_SEARCH_NO_RESULT_IMG_ALT:
        'Image shows a placeholder which appears instead of results, because the search did not return any.',
    WORKSHOP_SEARCH_EMPTY_MESSAGE:
        'Unfortunately nothing was found. Tip: Searching without a search term shows all currently available offers.',

    WORKSHOP_PROFILE_VIEW_SKILLS: 'Skills I can contribute',
    WORKSHOP_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Fields of interest',
    WORKSHOP_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Workshop topics I’m interested in',

    WORKSHOP_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalization',
    WORKSHOP_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Customer Centricity',
    WORKSHOP_PROFILE_CATEGORY_SUSTAINABILITY: 'Sustainability',
    WORKSHOP_PROFILE_CATEGORY_MOBILITY: 'Mobility',
    WORKSHOP_PROFILE_CATEGORY_COMMERCIAL: 'Commercial',
    WORKSHOP_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Airline Product',
    WORKSHOP_PROFILE_CATEGORY_IT_TOPICS: 'IT Topics',
    WORKSHOP_PROFILE_CATEGORY_COMMUNICATION: 'Communication',
    WORKSHOP_PROFILE_CATEGORY_SOCIAL: 'Social',
    WORKSHOP_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methods',
    WORKSHOP_PROFILE_CATEGORY_LEAN_METHODS: 'LEAN Methods',
    WORKSHOP_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Sharing personal skills',
    WORKSHOP_PARTICIPANTS_DIALOG_TITLE: 'List of participants',
    WORKSHOP_PARTICIPANTS_DIALOG_SUB_TITLE: 'Interested persons on the waiting list will be informed about free seats',
    WORKSHOP_PARTICIPANTS_REQUIRED_TOPICS_LABEL: 'Workshop Topics',
    WORKSHOP_PARTICIPANTS_NUMBER_LABEL: 'Participants',
    WORKSHOP_PARTICIPANTS_REGISTERED_TITLE: 'List of participants',
    WORKSHOP_PARTICIPANTS_WAITING_LIST_TITLE: 'Waiting list',
    WORKSHOP_PARTICIPANTS_COLUMN_PERSON: 'Participants',
    WORKSHOP_PARTICIPANTS_COLUMN_PERSON_WAITING: 'on waiting list',
    WORKSHOP_PARTICIPANTS_COLUMN_TOPICS: 'Workshop topics',
    WORKSHOP_PARTICIPANTS_COLUMN_SKILLS: 'Previous knowledge',
    WORKSHOP_PARTICIPANTS_COLUMN_REGISTERED_AT: 'Registered at',
    WORKSHOP_PARTICIPANTS_COLUMN_REGISTERED_AT_DATE_FORMAT: 'yyyy/MM/dd',
    WORKSHOP_PARTICIPANTS_MESSAGE_TO_PARTICIPANTS_BTN: 'Message to all participants',

    WORKSHOP_NO_MATCH_IMG_ALT: 'No matches found',
    WORKSHOP_NO_MATCH_CONTENT: 'Please specify more skills and (hidden?) talents in your profile to find matches .',
    WORKSHOP_NO_MATCH_CTA: 'Edit skills',
    WORKSHOP_NO_MATCH_FIND_CONTENT: 'Find more workshops by using the search.',

    WORKSHOP_VALIDATION_ERROR_MIN_PARTICIPANTS:
        'This number is lower than the number of already registered participants',

    WORKSHOP_SKILLS_LABEL: 'Helpful prior knowledge',
    WORKSHOP_SKILLS_LEVEL_LABEL: 'Preferred level',

    WORKSHOP_LABEL_ENTITY_VIEW_STAFFING: 'Participants',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE:
        'We have improved the matching. Make the last specifications for your matching results.',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE_BTN: 'Let’s go',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE_HEADER:
        'The matching was improved and we need some more information to be able to match fitting offers to you.',

    PAGE_TITLE_WORKSHOPS_MATCHES: 'Workshop matches',
    PAGE_TITLE_WORKSHOPS_MY: 'My workshops',
    PAGE_TITLE_WORKSHOPS_OFFERED: 'Workshops offered by me',
    PAGE_TITLE_WORKSHOPS_SEARCH: 'Search workshops',

    ORIGIN_TOPIC_WORKSHOPS_PRIVATE: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS_PARTICIPANT: 'Workshops',

    WORKSHOP_ORGANIZERS_INFO:
        'An offer can have several organizers, so that they can promote this offer in parallel or in the absence of other organizers. Here you can define who else can edit and delete this {{name_workshop_singular}} and send messages to participants. The users listed here can add and delete organizers, too.',
};
