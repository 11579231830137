import { Component, Input } from '@angular/core';
import {
    Direction,
    PageParams,
    ProfileNavigatorUser,
    ProfileNavigatorView,
    CommonState,
    TOPIC_NAMES_LIST,
} from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';
import {
    selectAllSearchResults,
    selectAllMatches,
    selectBookmarks,
    selectSearchPageParams,
    selectMatchesPageParams,
} from '../../generation-exchange.selectors';
import { GenerationExchangeActions } from '../../generation-exchange.actions';
import { filter, map, withLatestFrom, takeUntil } from 'rxjs/operators';
import { BookmarksPageState, BookmarksPageEntry } from '@tploy-enterprise/tenant-core';
import { GenerationExchangeState } from '../../generation-exchange.reducer';
import {
    GenerationExchangeMatch,
    GenerationExchangeSearchResult,
} from '../../generation-exchange-service/generation-exchange.types';
import { ActivatedRoute, UrlSerializer } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'tp-generation-exchange-profile-navigator-view',
    templateUrl: './generation-exchange-profile-navigator-view.component.html',
})
export class GenerationExchangeProfileNavigatorViewComponent implements ProfileNavigatorView {
    @Input() isSearchScope: boolean;
    @Input() isMatchScope: boolean;
    @Input() isGlobalBookmarkScope: boolean;
    @Input() isTopicBookmarkScope: boolean;
    @Input() context: string;
    @Input() itemId: any;

    prev$: any;
    next$: any;

    common$: Observable<any>;
    private readonly destroy$ = new Subject<void>();

    isProfilesLoading = false;

    constructor(
        private readonly store: Store<{
            common: CommonState;
            generationExchange: GenerationExchangeState;
            bookmarksPage: BookmarksPageState;
        }>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly urlSerializer: UrlSerializer,
    ) {}

    ngOnInit() {
        this.common$ = this.store.select((state) => state.common);
        this.common$.pipe(takeUntil(this.destroy$)).subscribe(({ otherProfiles }) => {
            const { loaded, loading, listLoading } = otherProfiles;
            this.isProfilesLoading =
                TOPIC_NAMES_LIST.some((topicName) => !loaded[topicName] && !!loading[topicName]) || listLoading;
        });

        if (this.isSearchScope) {
            this.initSearchState();
        } else if (this.isMatchScope) {
            this.initMatchState();
        } else if (this.isTopicBookmarkScope) {
            this.initTopicBookmarkState();
        } else {
            this.initGlobalBookmarkState();
        }
    }

    initSearchState(): void {
        const store = this.store.select(selectAllSearchResults);
        const pageParams = this.store.select(selectSearchPageParams);
        this.next$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.NEXT, this.isSearchScope, pageParams),
            ),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.PREVIOUS, this.isSearchScope, pageParams),
            ),
        );
    }

    initMatchState(): void {
        const store = this.store.select(selectAllMatches);
        const pageParams = this.store.select(selectMatchesPageParams);
        this.next$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.NEXT, this.isSearchScope, pageParams),
            ),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.PREVIOUS, this.isSearchScope, pageParams),
            ),
        );
    }

    initGlobalBookmarkState(): void {
        const store = this.store.select('bookmarksPage');
        this.next$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getBookmarkedUserByDirection(results.data, Direction.NEXT)),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getBookmarkedUserByDirection(results.data, Direction.PREVIOUS)),
        );
    }

    private getBookmarkedUserByDirection(
        results: Array<BookmarksPageEntry>,
        direction: Direction,
    ): ProfileNavigatorUser {
        results = results.filter((result) => result.bookmark.context === this.context);
        const resultIndex = Number(results.findIndex((result) => result.account.userId === this.itemId));
        const item = results[resultIndex + direction];
        return item
            ? {
                  name: item?.account?.generalData.name,
                  avatar: item?.account?.generalData.imageUrl,
                  userId: item?.account?.userId,
              }
            : null;
    }

    private getUserByDirection(
        results: Array<GenerationExchangeMatch | GenerationExchangeSearchResult>,
        direction: Direction,
        isSearchScope: boolean,
        pageParams: PageParams,
    ): ProfileNavigatorUser {
        const currentIndex = Number(results.findIndex((result) => result?.userId === this.itemId));
        const targetIndex = currentIndex + direction;
        const item = results[targetIndex];
        if (!item && targetIndex > -1 && targetIndex < pageParams.length) {
            this.loadItemByIndex(targetIndex, isSearchScope);
        }
        return item
            ? {
                  name: item?.profile?.user?.name,
                  avatar: item?.profile?.user?.imageUrl,
                  userId: item?.userId,
              }
            : null;
    }

    private hydrateProfile(item: any, isSearchScope: boolean): void {
        const methodName = isSearchScope ? 'hydrateSearchProfile' : 'hydrateMatchProfile';
        this.store.dispatch(
            GenerationExchangeActions[methodName]({
                data: item,
            }),
        );
    }

    private loadItemByIndex(index: number, isSearchScope: boolean): void {
        isSearchScope
            ? this.store.dispatch(
                  GenerationExchangeActions.loadSearchResult({ searchQuery: this.getSearchQuery(), index }),
              )
            : this.store.dispatch(GenerationExchangeActions.loadMatchResult({ index }));
    }

    initTopicBookmarkState() {
        const store = this.store.select(selectBookmarks);
        this.next$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getTopicBookmarkUserByDirection(results, Direction.NEXT)),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getTopicBookmarkUserByDirection(results, Direction.PREVIOUS)),
        );
    }

    private getTopicBookmarkUserByDirection(results, direction: Direction): ProfileNavigatorUser {
        const resultIndex = Number(results.findIndex((result) => result.entityId === this.itemId));
        const item = results[resultIndex + direction];
        return item
            ? {
                  name: item?.profile?.user?.name,
                  avatar: item?.profile?.user?.imageUrl,
                  userId: item?.entityId,
              }
            : null;
    }

    private getSearchQuery(): string {
        const src = this.activatedRoute.snapshot.params.src;
        return this.urlSerializer.parse(src).queryParamMap.get('q') ?? '';
    }
}
