import { createAction, props } from '@ngrx/store';
import { Account, HiddenSkills } from './account.types';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export const AccountActions = {
    load: createAction('[Account Data] load'),
    loadSuccess: createAction('[Account Data][Success] load', props<{ account: Account }>()),
    loadError: createAction('[Account Data][Error] load', props<{ error: Error }>()),
    create: createAction('[Account Data] Create'),
    createSuccess: createAction('[Account Data][Success] Create', props<{ account: Account }>()),
    createError: createAction('[Account Data][Error] Create', props<{ error: Error }>()),
    delete: createAction('[Account Data] delete'),
    deleteSuccess: createAction('[Account Data][Success] delete'),
    deleteError: createAction('[Account Data][Error] delete', props<{ error: Error }>()),
    loadSsoData: createAction('[Account Sso Data] load'),
    loadSsoDataSuccess: createAction('[Account Sso Data][Success] load', props<{ account: Partial<Account> }>()),
    sync: createAction('[Account sync] send', props<{ skills: string[] }>()),
    syncSuccess: createAction('[Account sync][Success] send'),
    syncError: createAction('[Account sync][Error] send', props<{ error: Error }>()),
    loadAllSuggestedSkills: createAction('[Account AllSuggestedSkills] load', props<{ id: string }>()),
    loadAllSuggestedSkillsSuccess: createAction(
        '[Account AllSuggestedSkills][Success] load',
        props<{ suggestedSkills: SuggestedSkills }>(),
    ),
    loadAllSuggestedSkillsError: createAction('[Account AllSuggestedSkills][Error] load', props<{ error: Error }>()),
    hideSkills: createAction('[Account Skills Hidden] hide', props<{ skills: HiddenSkills }>()),
    hideSkillsSuccess: createAction('[Account Skills Hidden][Success] hide', props<{ skills: HiddenSkills }>()),
    hideSkillsError: createAction('[Account Skills Hidden][Error] hide', props<{ error: Error }>()),
    recoverSkills: createAction('[Account Skills Recover Hidden] hide', props<{ skills: HiddenSkills }>()),
    recoverSkillsSuccess: createAction(
        '[Account Skills Recover Hidden][Success] hide',
        props<{ skills: HiddenSkills }>(),
    ),
    recoverSkillsError: createAction('[Account Skills Recover Hidden][Error] hide', props<{ error: Error }>()),
};
