import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ImagePickerComponent } from './image-picker.component';
import { AvatarImagePickerDialogComponent } from './dialog/avatar-image-picker-dialog/avatar-image-picker-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AVATAR_PLACEHOLDERS, ENTITY_PLACEHOLDERS, ENTITY_PLACEHOLDERS_WIDE } from './image-picker.di';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { ImagePickerDialogComponent } from './dialog/image-picker-dialog/image-picker-dialog.component';
import { DragImageModule } from '../drag-image/drag-image.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchLoaderModule } from '../search-loader';
import { ButtonModule } from '@tandemploy/ngx-components';
import { ConfirmImageDeleteDialogComponent } from './dialog/confirm-image-delete-dialog/confirm-image-delete-dialog.component';
import { MaxImagesReachedDialogComponent } from './dialog/max-images-reached-dialog/max-images-reached-dialog.component';

const availableAvatars: string[] = [
    '/global_assets/images/placeholder/avatar/1.png',
    '/global_assets/images/placeholder/avatar/2.png',
    '/global_assets/images/placeholder/avatar/3.png',
    '/global_assets/images/placeholder/avatar/4.png',
    '/global_assets/images/placeholder/avatar/5.png',
    '/global_assets/images/placeholder/avatar/6.png',
    '/global_assets/images/placeholder/avatar/7.png',
    '/global_assets/images/placeholder/avatar/8.png',
    '/global_assets/images/placeholder/avatar/9.png',
    '/global_assets/images/placeholder/avatar/10.png',
    '/global_assets/images/placeholder/avatar/11.png',
    '/global_assets/images/placeholder/avatar/12.png',
    '/global_assets/images/placeholder/avatar/13.png',
];
const availableEntityPlaceholders: string[] = [
    '/global_assets/images/placeholder/entity/1.jpg',
    '/global_assets/images/placeholder/entity/2.jpg',
    '/global_assets/images/placeholder/entity/3.jpg',
    '/global_assets/images/placeholder/entity/4.jpg',
    '/global_assets/images/placeholder/entity/5.jpg',
    '/global_assets/images/placeholder/entity/6.jpg',
    '/global_assets/images/placeholder/entity/7.jpg',
    '/global_assets/images/placeholder/entity/8.jpg',
    '/global_assets/images/placeholder/entity/9.jpg',
    '/global_assets/images/placeholder/entity/10.jpg',
];
const availableEntityPlaceholdersWide: string[] = [
    '/global_assets/images/placeholder/entity/1_wide.jpg',
    '/global_assets/images/placeholder/entity/2_wide.jpg',
    '/global_assets/images/placeholder/entity/3_wide.jpg',
    '/global_assets/images/placeholder/entity/4_wide.jpg',
    '/global_assets/images/placeholder/entity/5_wide.jpg',
    '/global_assets/images/placeholder/entity/6_wide.jpg',
    '/global_assets/images/placeholder/entity/7_wide.jpg',
    '/global_assets/images/placeholder/entity/8_wide.jpg',
    '/global_assets/images/placeholder/entity/9_wide.jpg',
    '/global_assets/images/placeholder/entity/10_wide.jpg',
];

@NgModule({
    imports: [
        CommonModule,
        LanguageModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        DragImageModule,
        ImageCropperModule,
        MatFormFieldModule,
        SearchLoaderModule,
        ButtonModule,
    ],
    declarations: [
        ImagePickerComponent,
        AvatarImagePickerDialogComponent,
        ImagePickerDialogComponent,
        ConfirmImageDeleteDialogComponent,
        MaxImagesReachedDialogComponent,
    ],
    exports: [ImagePickerComponent],
})
export class ImagePickerModule {
    static forRoot(): ModuleWithProviders<ImagePickerModule> {
        return {
            ngModule: ImagePickerModule,
            providers: [
                { provide: AVATAR_PLACEHOLDERS, useValue: availableAvatars },
                { provide: ENTITY_PLACEHOLDERS, useValue: availableEntityPlaceholders },
                { provide: ENTITY_PLACEHOLDERS_WIDE, useValue: availableEntityPlaceholdersWide },
            ],
        };
    }
}
