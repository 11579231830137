import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { STAActions } from './sta.actions';
import { IS_PROFILE_DIRTY, PROFILE_STORAGE_KEY, STAProfile } from './sta.types';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    isSTAProfileInDirtyState,
    selectApplicationSkills,
    selectCurrentSTAProfile,
    selectMatching,
    selectSavedSTAProfile,
    selectSearchResults,
    selectStaffingFilter,
} from './sta.selectors';
import { STAService } from './sta.service';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
    AccountActions,
    AccountState,
    Contact,
    ConversationType,
    DEFAULT_PAGE_SIZE,
    DirectMessagesCreateConversationActions,
    NotificationsService,
    SelectedTopicsActions,
    StorageService,
    SuccessFactorsActions,
    SuccessFactorsSkillsState,
    SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { StaUnexpectedException } from './sta.exceptions';
import { STAState } from './sta.reducer';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class STAEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{
            shortTimeAssignments: STAState;
            account: AccountState;
            successFactors: SuccessFactorsSkillsState;
        }>,
        private readonly staService: STAService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
        private readonly router: Router,
        private readonly notificationService: NotificationsService,
    ) {}

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.loadProfile),
            switchMap(() =>
                this.staService.loadProfile().pipe(
                    map((profile: STAProfile) =>
                        STAActions.loadProfileSuccess({ profile, session: this.readProfileFromSession() }),
                    ),
                    catchError((error) => {
                        if (error instanceof StaUnexpectedException) {
                            // Other possible error is "Not Found" but this is a valid scenario
                            // When something else happens, we want to log it.
                            this.errorHandler.handleError(error);
                        }
                        return of(STAActions.loadProfileError({ error, session: this.readProfileFromSession() }));
                    }),
                ),
            ),
        ),
    );

    syncSuccessFactorsSkills$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SuccessFactorsActions.syncSuccessFactorsSkills),
                withLatestFrom(this.store.select((state) => state.successFactors.topicsForSync)),
                switchMap(([{ skills }, topics]) => {
                    if (topics.includes('short-time-assignments')) {
                        this.store.dispatch(STAActions.syncSuccessFactorsSkills({ skills }));
                    }
                    return [];
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.staService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return STAActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        if (error instanceof StaUnexpectedException) {
                            this.errorHandler.handleError(error);
                        }
                        return of(STAActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(STAActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentSTAProfile)),
                tap(([{ currentProfile }, state]) => {
                    this.storeProfileInSession({ ...state, ...currentProfile });
                }),
            ),
        { dispatch: false },
    );

    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => STAActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.saveProfile),
            // ignore the sta profile's non dirty state
            // it is a responsibility of the sta to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isSTAProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentSTAProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) => {
                return this.staService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) => STAActions.saveProfileSuccess({ currentProfile: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(STAActions.saveProfileError({ error, currentProfile }));
                    }),
                );
            }),
        ),
    );

    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.cancelCurrentProfileEdit, AccountActions.createSuccess, SelectedTopicsActions.cancelEdit),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedSTAProfile)),
            map(([action, profile]) => STAActions.loadProfileSuccess({ profile, session: null })),
        ),
    );

    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(STAActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    syncProfileSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.syncProfileSkills),
            withLatestFrom(this.store.select(selectCurrentSTAProfile)),
            switchMap(([action, currentProfile]) => {
                return of(AccountActions.sync({ skills: currentProfile.skills.map((s) => s.name) }));
            }),
        ),
    );

    saveEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.saveEntity),
            exhaustMap((action) =>
                this.staService.saveEntity(action.entity, action.sendNotifications).pipe(
                    map((savedEntity) => STAActions.saveEntitySuccess({ entity: savedEntity })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.saveEntityError({ error }));
                    }),
                ),
            ),
        ),
    );

    createEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.createEntity),
            switchMap((action) => {
                if (action.entity.id) {
                    return this.staService.saveEntity(action.entity);
                }

                return this.staService.createEntity(action.entity);
            }),
            map((savedEntity) => STAActions.createEntitySuccess({ entity: savedEntity })),
            catchError((error) => {
                this.errorHandler.handleError(error);
                return of(STAActions.createEntityError({ error }));
            }),
        ),
    );

    deleteEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.deleteEntity),
            switchMap((action) =>
                this.staService.deleteEntity(action.entityId).pipe(
                    map((entityId) => STAActions.deleteEntitySuccess({ entityId })),
                    catchError((error: Error) => of(STAActions.deleteEntityError({ error }))),
                ),
            ),
        ),
    );

    publishEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.publishEntity),
            switchMap((staEntity) =>
                this.staService.publishEntity(staEntity.entityId).pipe(
                    withLatestFrom(this.store.select('account')),
                    switchMap(([savedEntity, account]) => {
                        const participants: Contact[] = [
                            {
                                userId: account.userId,
                                generalData: {
                                    name: account.generalData.current.data.name,
                                    imageUrl: account.generalData.current.data?.imageUrl,
                                },
                            },
                        ];

                        return [
                            STAActions.publishEntitySuccess({ entity: savedEntity }),
                            DirectMessagesCreateConversationActions.createConversation({
                                payload: {
                                    origin: 'ORIGIN_TOPIC_STA',
                                    type: ConversationType.GROUP,
                                    participants,
                                    originId: savedEntity.id,
                                    imageUrl: savedEntity.imageUrl,
                                    name: savedEntity.title,
                                },
                            }),
                        ];
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.publishEntityError({ error }));
                    }),
                ),
            ),
        ),
    );

    searchEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.searchEntities),
            switchMap((action) =>
                this.staService.searchEntities(action.searchQuery, action.pageParams, action.campaigns).pipe(
                    map((search) =>
                        STAActions.searchEntitiesSuccess({ entities: search.results, pageParams: search.pageParams }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.searchEntitiesError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadStaffingEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.loadStaffingEntities),
            withLatestFrom(this.store.select(selectStaffingFilter)),
            switchMap(([action, filter]) =>
                this.staService
                    .loadStaffingEntities(action.searchQuery, action.pageParams, action.filter ?? filter)
                    .pipe(
                        map((search) =>
                            STAActions.loadStaffingEntitiesSuccess({
                                entities: search.results,
                                pageParams: search.pageParams,
                            }),
                        ),
                        catchError((error) => {
                            this.errorHandler.handleError(error);
                            return of(STAActions.loadStaffingEntitiesError({ error }));
                        }),
                    ),
            ),
        ),
    );

    loadEntitiesOfferedByMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.loadEntitiesOfferedByMe),
            withLatestFrom(this.store.select((state) => state.account)),
            switchMap(([action, account]) =>
                this.staService.loadEntitiesOfferedByMe(account.userId).pipe(
                    map((entities) => STAActions.loadEntitiesOfferedByMeSuccess({ entities })),
                    catchError((error: Error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.loadEntitiesOfferedByMeError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.loadMatches),
            switchMap((action) =>
                this.staService.getProfileMatches(action.pageParams, action.campaigns).pipe(
                    map((result) =>
                        STAActions.loadMatchesSuccess({
                            entities: result.matches,
                            pageParams: result.pageParams,
                            selectedCampaigns: action.campaigns,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );

    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.saveProfileSuccess),
            switchMap(() => {
                if (this.router.url.includes('/short-time-assignments/matches')) {
                    return of(
                        STAActions.loadMatches({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    onProfileSaveSelectTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.saveProfile),
            switchMap(() => {
                return of(
                    SelectedTopicsActions.selectTopic({
                        topic: SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
                    }),
                );
            }),
        ),
    );

    applyTo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.applyTo),
            withLatestFrom(this.store.select(selectApplicationSkills)),
            switchMap(([action, applicationSkills]) =>
                this.staService.apply(action.entityId, action.name, applicationSkills).pipe(
                    withLatestFrom(this.store.select(selectMatching), this.store.select(selectSearchResults)),
                    map(([application, matches, searchResults]) => {
                        matches = [
                            ...matches.map((match) => {
                                if (match.staEntity.id === action.entityId) {
                                    match.staEntity.isApplicationForUserPendingOrApproved = true;
                                }
                                return match;
                            }),
                        ];
                        searchResults = [
                            ...searchResults.map((searchResult) => {
                                if (searchResult.staEntity.id === action.entityId) {
                                    searchResult.staEntity.isApplicationForUserPendingOrApproved = true;
                                }
                                return searchResult;
                            }),
                        ];
                        return STAActions.applyToSuccess({ application, matches, searchResults });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.applyToError({ error }));
                    }),
                ),
            ),
        ),
    );

    getApplicationsSelections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.getApplicationsSelections),
            switchMap(({ entityId }) =>
                this.staService.getApplicationsSelections(entityId).pipe(
                    map((data) => STAActions.getApplicationsSelectionsSuccess({ data })),
                    catchError((error: Error) => of(STAActions.getApplicationsSelectionsError({ error }))),
                ),
            ),
        ),
    );

    getStaffingApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.getStaffingApplications),
            switchMap(({ entity }) =>
                this.staService.getApplicationsSelections(entity.id).pipe(
                    map((data) => STAActions.getStaffingApplicationsSuccess({ data, entity })),
                    catchError((error: Error) => of(STAActions.getStaffingApplicationsError({ error }))),
                ),
            ),
        ),
    );

    resetApplicationsSelections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.resetApplicationsSelections),
            map(() => STAActions.resetApplicationsSelectionsSuccess()),
        ),
    );

    getOwnerUnseenApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.getOwnerUnseenApplications),
            switchMap(() =>
                this.staService.getUnseenApplications().pipe(
                    map((applicationIndicators) =>
                        STAActions.getOwnerUnseenApplicationsSuccess({ applicationIndicators }),
                    ),
                    catchError((error: Error) => of(STAActions.getOwnerUnseenApplicationsError({ error }))),
                ),
            ),
        ),
    );

    entityMarkApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.entityMarkApplicationsAsSeen),
            switchMap((action) =>
                this.staService.markApplicationsAsSeen(action.entityId).pipe(
                    map(() =>
                        STAActions.entityMarkApplicationsAsSeenSuccess({
                            entityId: action.entityId,
                        }),
                    ),
                    catchError((error: Error) => of(STAActions.entityMarkApplicationsAsSeenError({ error }))),
                ),
            ),
        ),
    );

    topicLinkMarkApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.topicLinkIndicatorMarkAsSeen),
            switchMap(() =>
                this.staService.markTopicLinkApplicationsAsSeen().pipe(
                    map(() => STAActions.topicLinkIndicatorMarkAsSeenSuccess()),
                    catchError((error: Error) => of(STAActions.topicLinkIndicatorMarkAsSeenError({ error }))),
                ),
            ),
        ),
    );

    offeringTabMarkApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.offeringTabIndicatorMarkAsSeen),
            switchMap(() =>
                this.staService.markOfferingTabApplicationsAsSeen().pipe(
                    map(() => STAActions.offeringTabIndicatorMarkAsSeenSuccess()),
                    catchError((error: Error) => of(STAActions.offeringTabIndicatorMarkAsSeenError({ error }))),
                ),
            ),
        ),
    );

    searchProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.searchProfiles),
            switchMap((action) =>
                this.staService.searchProfiles(action.staId, action.searchTerms).pipe(
                    map((profiles) => STAActions.searchProfilesSuccess({ profiles })),
                    catchError((error: Error) => of(STAActions.searchProfilesError({ error }))),
                ),
            ),
        ),
    );

    searchTopicUsersByName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.searchTopicUsers),
            switchMap((action) =>
                this.staService.searchTopicUserByName(action.searchQuery, action.entityId).pipe(
                    map((searchResult) => STAActions.searchTopicUsersSuccess({ results: searchResult })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(STAActions.searchTopicUsersError({ error }));
                    }),
                ),
            ),
        ),
    );

    sendInvitationMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STAActions.sendInvitationMessage),
            concatMap((action) =>
                this.staService.sendInvitation(action.message).pipe(
                    tap(() =>
                        this.notificationService.openDefaultSnack(
                            'STA_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK',
                        ),
                    ),
                    map(() => STAActions.sendInvitationMessageSuccess({ profileId: action.message.profile.user.id })),
                    catchError((error: Error) => of(STAActions.sendInvitationMessageError({ error }))),
                ),
            ),
        ),
    );

    private storeProfileInSession(profile: STAProfile) {
        this.storage.local.set(PROFILE_STORAGE_KEY, profile);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): STAProfile {
        return this.storage.local.get<STAProfile, null>(PROFILE_STORAGE_KEY);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(PROFILE_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
