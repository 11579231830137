export class ConversationNotFoundException implements Error {
    originalError: Error;
    name: string;
    message: string;
    stack: string;

    constructor(originalError?: Error) {
        this.originalError = originalError;
        this.name = 'ConversationNotFoundException';
        this.message = originalError?.message ? originalError.message : 'Conversation could not be found';
        this.stack = originalError?.stack;
    }
}
