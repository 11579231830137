<tp-nla-profile-tile-locations-header
    [locations]="locations"
    [isLocationIndependent]="isLocationIndependent"
    [highlightLocationIndependent]="highlightLocationIndependent"
>
    <tp-nla-profile-tile-locations
        *ngIf="showBadge"
        [locations]="extraLocations"
        [badgeText]="badgeText"
    ></tp-nla-profile-tile-locations>
</tp-nla-profile-tile-locations-header>
