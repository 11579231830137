import { RouterReducerState } from '@ngrx/router-store';
import { RoutingSequenceState } from './routing-sequence.reducer';
import { NavigateCommand, RoutingStep } from './routing-sequence.types';

type State = { router: RouterReducerState; routingSequence: RoutingSequenceState };

export function activeRoutingSequence(state: any): boolean {
    return state.routingSequence.sequence && state.routingSequence.sequence.length > 0;
}

export function currentRoutingSequenceStep(state: State): number {
    return state.routingSequence.current;
}

export function hasNextSequenceStep(state: any): boolean {
    const current = currentRoutingSequenceStep(state);
    return state.routingSequence.sequence[current + 1] !== undefined;
}

export function hasPreviousSequenceStep(state: any): boolean {
    const current = currentRoutingSequenceStep(state);
    return state.routingSequence.sequence[current - 1] !== undefined;
}

export function nextSequenceStepCommand(state: State): NavigateCommand {
    const isActive = activeRoutingSequence(state);

    if (!isActive) {
        return null;
    }

    if (!hasNextSequenceStep(state)) {
        return {
            step: state.routingSequence.onComplete,
            index: -1,
            event: 'complete',
        };
    }

    const index = state.routingSequence.current + 1;
    const step = state.routingSequence.sequence[index];

    return {
        index,
        step,
        event: 'navigate',
        direction: 'forward',
    };
}

export function previousSequenceStepCommand(state: State): NavigateCommand {
    const isActive = activeRoutingSequence(state);

    if (!isActive) {
        return null;
    }

    if (!hasPreviousSequenceStep(state)) {
        return {
            step: state.routingSequence.onAbort,
            index: -1,
            event: 'abort',
        };
    }

    const index = state.routingSequence.current - 1;
    const step = state.routingSequence.sequence[index];

    return {
        index,
        step,
        event: 'navigate',
        direction: 'backward',
    };
}

export function previousSequenceStep(state: State): RoutingStep {
    const isActive = activeRoutingSequence(state);

    if (!isActive) {
        return null;
    }

    if (!hasPreviousSequenceStep(state)) {
        return null;
    }

    const current = state.routingSequence.current;
    return state.routingSequence.sequence[current + 1];
}

export type CompleteStepFunctionType = () => void;

export function completeStep(state: State): RoutingStep | CompleteStepFunctionType {
    if (!activeRoutingSequence(state)) {
        return null;
    }

    return state.routingSequence.onComplete;
}

export function abortStep(state: State): RoutingStep {
    if (!activeRoutingSequence(state)) {
        return null;
    }

    return state.routingSequence.onAbort;
}
