<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-string-list-field
        *ngIf="hasLocations()"
        key="PROFILE_VIEW_NEVER_LUNCH_ALONE_LOCATIONS_LABEL"
        [value]="locations"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        key="PROFILE_NEVER_LUNCH_ALONE_LOCATION_INDEPENDENT_LABEL"
        [value]="locationIndependentDetail"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-string-list-field
        *ngIf="hasTopics()"
        key="PROFILE_VIEW_NEVER_LUNCH_ALONE_TOPICS_LABEL"
        [value]="topics"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-string-list-field
        *ngIf="hasDishes()"
        key="PROFILE_VIEW_NEVER_LUNCH_ALONE_DISHES_LABEL"
        [value]="dishes"
    >
    </tp-profile-view-string-list-field>
</tp-profile-view-fieldset>
