import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Translatable } from '../../language/translatable/translatable.type';

@Component({
    selector: 'tp-profile-view-dates-field',
    templateUrl: './profile-view-dates-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__text-field',
    },
})
export class ProfileViewDatesFieldComponent {
    @Input()
    key: Translatable;

    @Input()
    from: Date;

    @Input()
    to: Date;
}
