import { RouterEvent } from '@angular/router';

export class RouterStack {
    private stack: RouterEvent[] = [];

    private readonly stackSize: number;

    constructor(stackSize = 50) {
        this.stackSize = stackSize;
    }

    add(event: RouterEvent) {
        this.checkStackSize();
        this.stack.push(event);
    }

    private checkStackSize(): number {
        if (this.stack.length === this.stackSize) {
            this.stack.shift();
        }
        return this.stack.length;
    }

    getElements(keep = true): string {
        const elements = this.stringify(
            this.stack.map((event) => {
                return typeof event.toString === 'function'
                    ? event.toString()
                    : {
                          id: event.id,
                          url: event.url,
                      };
            }),
        );

        if (!keep) {
            this.clearStack();
        }

        return elements;
    }

    clearStack() {
        this.stack = [];
    }

    stringify(obj: unknown, spaces = 2) {
        return JSON.stringify(obj, this.serializer(), spaces);
    }

    serializer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }
}
