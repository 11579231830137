import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
    selector: 'tp-profile-button-navigation',
    templateUrl: './navigator-button.component.html',
    styleUrls: ['./navigator-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileNavigationButtonComponent {
    @Input() caption: string;
    @Input() name: string;
    @Input() background: string;
    @Input() avatar: string;
    @Input() direction: string;
    @Input() hideAvatar: boolean;

    @HostBinding('class.left-direction')
    get isLeftDirection() {
        return this.direction === 'LEFT';
    }

    @HostBinding('class.right-direction')
    get isRightDirection() {
        return this.direction === 'RIGHT';
    }
}
