export default {
    PROJECTS: 'Projects',
    PROJECTS_SINGULAR: 'Project',
    PROJECTS_INFO:
        'Are you looking for the right person or team for a project – possibly at very short notice? Both is easily feasible with the matching for projects. It is ideal for staffing short-term and temporary requirements, foreign assignments or job shadowings. If you are interested in taking part in short missions or are looking for a change of pace and would like to bring certain skills to bear, you should definitely select "projects" as your interest and keep up to date with the latest developments!',
    PROJECTS_ENTITY_CREATE: 'New Project',
    PROJECTS_PROFILE_TITLE: 'Projects',
    PROJECTS_MATCHING_PAGE: 'Matches',
    PROJECTS_PROFILE_SUBTITLE: 'Please provide a few details to enable an exact matching.',
    PROJECTS_PROFILE_MOTIVATION_SUBTITLE:
        'Explore exciting projects and/or offer your own ones to find the best fitting team.',
    PROJECTS_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Offer own projects to find teams.',
    PROJECTS_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Discover suitable projects.',
    PROJECTS_MOTIVATION_VALIDATION_REQUIRED: 'You must select at least one option.',
    PROJECTS_PROFILE_SKILLS_LABEL: 'Skills you can contribute to projects',
    PROJECTS_PROFILE_CATEGORIES_INTERESTED_IN_LABEL: 'Areas, you are particularily interested in',
    PROJECTS_PROFILE_SKILLS_INTERESTED_IN_LABEL: 'Skills you would like to acquire during projects',

    // ALIAS OF PROJECTS_PROFILE_SKILLS_LABEL
    PROJECTS_PROFILE_VIEW_SKILLS: 'Skills I can contribute',
    PROJECTS_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'What I would like to learn',
    PROJECTS_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Fields of interest',
    PROJECTS_PROFILE_VIEW_CAMPAIGNS_LABEL: 'HR programs',

    PROJECTS_ENTITY_EDIT_TITLE: 'Edit project',
    PROJECTS_ENTITY_EDIT_BUTTON_DRAFT: 'Save draft',
    PROJECTS_ENTITY_EDIT_BUTTON_PUBLISH: 'Publish project',
    PROJECTS_ENTITY_EDIT_BUTTON_SAVE: 'Save',
    PROJECTS_ENTITY_CREATE_TITLE: 'Create Project',
    PROJECTS_ENTITY_CREATE_BUTTON_DRAFT: 'Save draft',
    PROJECTS_ENTITY_CREATE_BUTTON_PUBLISH: 'Publish project',
    PROJECTS_ENTITY_FORM_TITLE_LABEL: 'Title of Project',
    PROJECTS_ENTITY_FORM_ONGOING_LABEL: 'This is an ongoing project without an end date',
    PROJECTS_ENTITY_FORM_START_DATE_LABEL: 'Start date',
    PROJECTS_ENTITY_FORM_START_DATE_OPTIONAL_LABEL: 'Start date (optional)',
    PROJECTS_ENTITY_FORM_END_DATE_LABEL: 'End date',
    PROJECTS_ENTITY_FORM_APPLICATION_DEADLINE_LABEL: 'Application deadline',
    PROJECTS_ENTITY_FORM_APPLICATION_DEADLINE_VALIDATION_MIN_MAX_ERROR:
        'Please choose an application deadline before or on the starting date.',
    PROJECTS_ENTITY_FORM_VENUE_LABEL: 'Location',
    PROJECTS_ENTITY_FORM_LANGUAGES_LABEL: 'Project language (expected from all applicants)',
    PROJECTS_ENTITY_FORM_SUBSIDIARY_LABEL: 'Subsidiary (offering the project)',
    PROJECTS_ENTITY_FORM_DEPARTMENT_LABEL: 'Department',
    PROJECTS_ENTITY_FORM_TARGET_GROUPS_LABEL: 'Select special target groups',
    PROJECTS_ENTITY_FORM_CATEGORY_LABEL: 'Thematic categorization (for statistics):',
    PROJECTS_ENTITY_FORM_CAMPAIGNS_LABEL: 'Belongs to the following program:',
    PROJECTS_ENTITY_FORM_CAMPAIGNS_PLACEHOLDER: 'choose..',
    PROJECTS_ENTITY_FORM_DESCRIPTION_LABEL: 'Project description',
    PROJECTS_ENTITY_FORM_DESCRPTION_ERROR: 'The description needs to be at least 50 characters long.',
    PROJECTS_ENTITY_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unlimited (all are reached)',
    PROJECTS_ENTITY_FORM_SKILLS_FORM_NAME_FIELD_INFO:
        'Please enter all the skills/abilities/competencies you wish for a successful project team. Only one skill can be entered per line. If necessary, you can choose your preferred skill level in the next step.\nInterested employees can apply for your project with one or more suitable skills.',
    PROJECTS_FORM_READY_STATUS: 'Yes! You are ready to apply!',
    PROJECTS_FORM_ALMOST_READY_STATUS: 'Almost ready to apply!',
    PROJECTS_FORM_NOT_READY_STATUS: 'Not ready to apply!',
    PROJECTS_FORM_HEADLINE_LANGUAGE: 'Required language',
    PROJECTS_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Add the required language',
    PROJECTS_FORM_HEADLINE_TARGET_GROUPS: 'Open for following groups',
    PROJECTS_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Add one group, if you belong to it',
    PROJECTS_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Select a function',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS_TITLE: 'Skills wanted for project',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS_DESCRIPTION:
        'To participate in this project, you must have at least one of the skills listed below. If you are unsure whether your knowledge is sufficient, write a message to the project organizer.',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS: 'Check at least one of the following skills to participate',
    PROJECTS_FORM_REQUIRED_TO_ADD_PROJECT_SKILLS: 'Check at least one of the following skills',
    PROJECTS_FORM_SKILL_TABLE_COL_CHECKED: 'Skill available?',
    PROJECTS_FORM_SKILL_TABLE_COL_SKILL_NAME: 'Skill',
    PROJECTS_FORM_SKILL_TABLE_COL_SKILL_INFORMATION: 'Information',

    PROJECTS_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Check this skill if you have it',

    PROJECTS_PUBLISH_DIALOG_TITLE: 'What happens after publishing a project?',
    PROJECTS_PUBLISH_DIALOG_CONTENT_1: 'Matching colleagues get notified',
    PROJECTS_PUBLISH_DIALOG_CONTENT_2:
        'After the project has been published, all matches are automatically determined and informed via e-mail. So please check in advance if your information is correct.',
    PROJECTS_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Publish project',

    PROJECTS_PUBLISH_APPROVAL_TITLE: 'Create project assignment',
    PROJECTS_PUBLISH_APPROVAL_SUB_TITLE:
        'Your responsible human resources management must be informed about the project assignment.',
    PROJECTS_PUBLISH_APPROVAL_TEXT:
        'Tandemploy brings your skills and exciting offerings together. Of course, this is done in compliance with labor law procedures that must be followed in case of a transfer or a project abroad. In the case of projects abroad that exceed the duration of a business trip, appropriate lead times for administration must be taken into account. If you have any questions, please contact your manager or your human resources department.',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_LABEL:
        'I have discussed the legal framework and the involvement of co-determination in the publishing and staffing of this project assignment with my responsible HR management.',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_YES_LABEL: 'Yes',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_NO_LABEL: 'Not yet',
    PROJECTS_PUBLISH_APPROVAL_PUBLISH_BTN_TITLE: 'Publish now!',

    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Create your first project',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'This list is still empty. To create your first project, just click the button below.',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_ENTITY_BTN: 'Create Project',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Invite colleagues',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Invite colleagues to join this platform.',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Invite',
    PROJECTS_OFFERED_BY_ME_PLANNED_HEADER: 'Planned',
    PROJECTS_OFFERED_BY_ME_RUNNING_HEADER: 'Running',
    PROJECTS_OFFERED_BY_ME_COMPLETE_HEADER: 'Complete',
    PROJECTS_OFFERED_BY_ME_CONTINUE_EDIT: 'Continue editing',
    PROJECTS_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Message participants',
    PROJECTS_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'View details',
    PROJECTS_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Unpublished',
    PROJECTS_OFFERED_BY_ME_START_DATE_INDICATOR_LABEL: 'Starts at',
    PROJECTS_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'Ends at',
    PROJECTS_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Date',
    PROJECTS_OFFERED_BY_ME_APPLICANTS_INDICATOR_LABEL: 'Applicants',
    PROJECTS_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Last version from',
    PROJECTS_OFFERED_BY_ME_CONTACT_LABEL: 'Contact',
    PROJECTS_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Delete Project',
    PROJECTS_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Are you sure, that you want to delete this Project?',
    PROJECTS_OFFERED_BY_ME_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Are you sure you want to irrevocably delete this offer including potential applicants and/or participants? If you delete it, all users involved will be notified accordingly. The offer can then no longer be accessed.',

    PROJECTS_SEARCH_HEADER: 'Search for Projects by keywords',
    PROJECTS_SEARCH_BTN_TEXT: 'Search!',
    PROJECTS_SEARCH_RESULT_REQUESTED_SKILLS: 'Wanted skills',
    PROJECTS_SEARCH_RESULT_VIEW_ENTITY: 'View Details',
    PROJECTS_SEARCH_RESULT_AVATAR_LABEL: 'Contact',
    PROJECTS_SEARCH_EMPTY_MESSAGE:
        'Unfortunately nothing was found. Tip: Searching without a search term shows all currently available offers.',

    PROJECTS_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalization',
    PROJECTS_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Customer Centricity',
    PROJECTS_PROFILE_CATEGORY_SUSTAINABILITY: 'Sustainability',
    PROJECTS_PROFILE_CATEGORY_MOBILITY: 'Mobility',
    PROJECTS_PROFILE_CATEGORY_COMMERCIAL: 'Commercial',
    PROJECTS_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Airline Product',
    PROJECTS_PROFILE_CATEGORY_IT_TOPICS: 'IT Topics',
    PROJECTS_PROFILE_CATEGORY_COMMUNICATION: 'Communication',
    PROJECTS_PROFILE_CATEGORY_SOCIAL: 'Social',
    PROJECTS_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methods',
    PROJECTS_PROFILE_CATEGORY_LEAN_METHODS: 'LEAN Methods',
    PROJECTS_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Sharing personal skills',
    PROJECTS_PROFILE_CATEGORY_SERVICES: 'Services',
    PROJECTS_PROFILE_CATEGORY_SUPPORT: 'Support',
    PROJECTS_PROFILE_CATEGORY_SALES: 'Sales',
    PROJECTS_PROFILE_CATEGORY_FACILITY: 'Facility',
    PROJECTS_PROFILE_CATEGORY_FINANCE: 'Finance',
    PROJECTS_PROFILE_CATEGORY_GENERAL_ADMINISTRATION: 'General Administration',
    PROJECTS_PROFILE_CATEGORY_HUMAN_RESOURCES: 'Human Resources',
    PROJECTS_PROFILE_CATEGORY_HEALTH: 'Health',
    PROJECTS_PROFILE_CATEGORY_LEGAL: 'Legal',
    PROJECTS_PROFILE_CATEGORY_MARKETING: 'Marketing',
    PROJECTS_PROFILE_CATEGORY_EVENTS: 'Events',
    PROJECTS_PROFILE_CATEGORY_DEVELOPMENT: 'Development',
    PROJECTS_PROFILE_CATEGORY_PRODUCT_MANAGEMENT: 'Product Management',
    PROJECTS_PROFILE_CATEGORY_TRAINING_AND_EDUCATION: 'Training&Education',

    PROJECTS_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    PROJECTS_DOMAIN_APPLIED_TO_TAB: 'My Projects',
    PROJECTS_DOMAIN_SEARCH_TAB: 'Search Projects',
    PROJECTS_DOMAIN_OFFERED_BY_ME_TAB: 'Offered by me',
    PROJECTS_DOMAIN_STAFFING: 'Staffing',

    PROJECTS_PROFILE_NO_MATCH_IMG_ALT: 'No matches found',
    PROJECTS_PROFILE_NO_MATCH_CONTENT:
        'Please specify more skills and (hidden?) talents in your profile to find matches',
    PROJECTS_PROFILE_NO_MATCH_CTA: 'Edit skills',
    PROJECTS_PROFILE_NO_MATCH_FIND_CONTENT: 'Find more Projects by using the search',
    PROJECTS_PROFILE_SEARCH_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    PROJECTS_DOMAIN_PROFILE_MATCH_SKILLS: 'Wanted skills',
    PROJECTS_PROFILE_MATCH_VIEW_ENTITY: 'View details',
    PROJECTS_DOMAIN_PROFILE_MATCH_AVATAR_LABEL: 'Contact',

    CUSTOM_SKILL_FORM_PERSONAL_NAME_LABEL: 'Wanted skills',
    CUSTOM_SKILL_FORM_PERSONAL_LEVEL_LABEL: 'Preferred level',

    PROJECTS_APPLIED_TO_PLACHOLDER_TEXT: 'You have not applied for Projects yet.',
    PROJECTS_APPLIED_TO_PLACHOLDER_BTN: 'Find matching Projects',
    PROJECTS_APPLIED_TO_CONTACT_LABEL: 'Contact',
    PROJECTS_APPLIED_TO_VIEW_DETAILS_BTN_TITLE: 'View details',
    PROJECTS_APPLIED_TO_VIEW_APPLICATION_BTN_TITLE: 'View application',
    PROJECTS_APPLIED_TO_PENDING_LABEL: 'Your application is complete. \nYou will be notified of the decision by e-mail',
    PROJECTS_APPLIED_TO_CANCELLED_LABEL: 'Participation declined. \nSubsequent cancellation by provider.',
    PROJECTS_APPLIED_TO_REJECTED_LABEL: 'You didn’t get a seat. \nYour application was not successful.',
    PROJECTS_APPLIED_TO_ACCEPTED_LABEL: 'Congratulations! \nYour applications was successful.',
    PROJECTS_APPLIED_TO_PLANNED_HEADER: 'Applied for',
    PROJECTS_APPLIED_TO_RUNNING_HEADER: 'Ongoing projects',
    PROJECTS_APPLIED_TO_COMPLETE_HEADER: 'Finished',
    PROJECTS_APPLIED_TO_END_DATE_INDICATOR_LABEL: 'Ends at',
    PROJECTS_APPLIED_TO_APPLICANTS_INDICATOR_LABEL: 'Participants',
    PROJECTS_APPLIED_TO_DATE_RANGE_INDICATOR_LABEL: 'Date',

    PROJECTS_DETAILS_VIEW_DESCRIPTION_TITLE: 'Project description',
    PROJECTS_DETAILS_VIEW_SKILLS_TITLE: 'Skills wanted',
    PROJECTS_DETAILS_VIEW_ORGANIZER_LABEL: 'Contact',

    PROJECTS_ENTITY_PARTICIPANTS_SKILL_SUMMARY_LABEL: 'Skills to be staffed:',
    PROJECTS_ENTITY_PARTICIPANTS_TITLE: 'Applicants list',
    PROJECTS_ENTITY_VIEW_PARTICIPATE_BUTTON: 'Apply',
    PROJECTS_ENTITY_VIEW_APPLIED_STATUS_NOTE: 'You have already applied to this project',
    PROJECTS_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'You can apply for {{topicEntity}} by enabling the <i>>>first option in your profile</i> and therefore indicate that your are also interested in participating',

    PROJECTS_ENTITY_APPLICATIONS_IDENTITY_HEADER: 'Applicant',
    PROJECTS_ENTITY_APPLICATIONS_SKILLS_AND_LEVELS_HEADER: 'Skills of applicant',
    PROJECTS_ENTITY_APPLICATIONS_RELATED_SKILLS_HEADER: 'Related term',
    PROJECTS_ENTITY_APPLICATIONS_STATUS_HEADER: 'Status',
    PROJECTS_ENTITY_APPLICATIONS_ACCEPT: 'Accept',
    PROJECTS_ENTITY_APPLICATIONS_ACCEPTED: 'accepted',
    PROJECTS_ENTITY_APPLICATIONS_REFUSED: 'refused',
    PROJECTS_ENTITY_APPLICATIONS_CANCELLED: 'cancelled',
    PROJECTS_ENTITY_APPLICATIONS_COMPLETE_STAFFING: 'Complete staffing now',
    PROJECTS_ENTITY_APPLICATIONS_STAFFING_COMPLETED: 'Staffing complete',
    PROJECTS_ENTITY_APPLICATIONS_FINISH_ONGOING: 'Finish project',
    PROJECTS_ENTITY_APPLICATIONS_ONGOING_FINISHED: 'Project finished',

    PROJECTS_ENTITY_APPLICATIONS_SEARCH_TITLE: 'Search for matches yourself',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_INPUT_VALIDATION_REQUIRED:
        'Please enter at least one skill you are looking for.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_INPUT_SEARCH_BUTTON: 'Search',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFY_BUTTON: 'notify',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFIED: 'notified',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NO_MATCHES_FOUND: 'No matches found.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_TITLE: 'Send a note about this {{topicName}}',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SUB_TITLE: 'Inform the selected person easily with one click.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE_LABEL: 'The following message is sent by e-mail:',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE:
        'A new {{name_project_singular}} perfectly matches your skills and interests! The {{name_project_singular}} owner has found you on {{platform_name}} and would be thrilled to have you on board. Log into your account to learn about the details and get in touch right away.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SEND_NOTE_BUTTON: 'Send note',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK: 'Note has been sent.',

    PROJECTS_ENTITY_APPLICATION_FEEDBACK_DIALOG_BTN: 'Done',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADLINE: 'You didn’t get a seat!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADING: 'Your application was not successful!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_TEXT:
        'This time, it didn’t work out. You will be informed about suitable new offers.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADLINE: 'Thank you for your interest',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADING: 'Your application has been received.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_TEXT:
        'As soon as the staffing has been decided, you will be informed. Thank you for your patience!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADLINE: 'Thank you for your application',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADING: 'You’re in!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_TEXT: 'Your application was successful.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADLINE: 'Participation declined',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADING: 'Subsequent cancellation by provider',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_TEXT:
        'Your successful application for the project was subsequently cancelled by the provider. If you have any questions, please contact the responsible contact persons - thank you very much for your interest! You will be informed about new projects.',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_TITLE: 'Please Confirm',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_CONTENT:
        'Ready to get started? It seems you have found people for your project team and would like to complete the project staffing now. Important: If you confirm now, all unaccepted applicants will receive a rejection and no more people can be added to the team. You can clarify further details with your project team in the group chat.\nComplete the casting for this project now?',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_FINISH_ONGOING_DIALOG_TITLE: 'Please Confirm',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_FINISH_ONGOING_DIALOG_CONTENT:
        'Is this project finished? It seems you could complete your project and no further staffing is needed. Important: If you confirm now, your colleagues can no longer apply for your offer.\nComplete the casting for this project now?',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_TITLE: 'Accept {{userName}} now',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_CONTENT:
        'Select "Accept" to admit the applicant. The person will then be automatically informed by e-mail about the successful application.',

    PROJECTS_ENTITY_REQUIRED_SKILLS_TITLE: 'Skills',
    PROJECTS_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        "Here you can see all the skills that are sought for this project. If you still discover skills that you actually own and want to contribute, but don't have them in your profile yet, you can select them here and add them to your profile.",

    PROJECTS_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Thank you very much for your interest!',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE: 'Your manager has to agree to your participation',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Tandemploy brings your skills and exciting offerings together. Of course, this is done in compliance with labor law procedures that must be followed in case of a transfer or a project abroad. In the case of projects abroad that exceed the duration of a business trip, appropriate lead times for administration must be taken into account. If you have any questions, please contact your manager or your human resources department.',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL:
        'I hereby confirm that I have informed my manager and that he/she agrees to my participation.',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Yes',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'Not yet',

    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_QUESTION: 'Sure you want to reject this applicant already?',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_YES: 'Yes',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_NO: 'No',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_MORE: 'What does it mean?',
    PROJECTS_ENTITY_APPLICATION_REJECT_INFO_TITLE: 'Reject applications in advance',
    PROJECTS_ENTITY_APPLICATION_REJECT_INFO_CONTENT:
        'Applications can be rejected early if it is already clear that the candidate has rather poor chances. This has the advantage that those affected know earlier and can plan accordingly. In addition, the list of applications remains clearer.',

    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_QUESTION: 'Reject this person in hindsight?',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_YES: 'Yes',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_NO: 'No',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_MORE: 'What does that mean?',
    PROJECTS_ENTITY_APPLICATION_CANCEL_INFO_TITLE: 'Cancel application in hindsight',
    PROJECTS_ENTITY_APPLICATION_CANCEL_INFO_CONTENT:
        'Applications can be cancelled after you have already accepted applicants. They will be informed about it via mail. For further explanations it is recommended to write a personal message. ',

    PROJECTS_VALIDATION_ERROR_MAX_DURATION: 'Maximum duration 4 weeks',

    PROJECTS_STAFFING_FILTER_STATUS_LABEL: 'Status',
    PROJECTS_STAFFING_FILTER_STAFFING_LABEL: 'Staffing',
    PROJECTS_STAFFING_FILTER_STAFFING_OPTION_ON: 'open for applications',
    PROJECTS_STAFFING_FILTER_STAFFING_OPTION_OFF: 'application deadline exceeded',
    PROJECTS_STAFFING_FILTER_CATEGORY_LABEL: 'Thematic Category',
    PROJECTS_STAFFING_FILTER_LANGUAGE_LABEL: 'Language',
    PROJECTS_STAFFING_NO_RESULTS_PLACEHOLDER: 'There are no results for your selected filters.',

    PROJECTS_PROFILE_CAMPAIGNS_LABEL: 'HR programs you participate in',
    PROJECTS_PROFILE_CAMPAIGNS_PLACEHOLDER: 'choose..',

    PAGE_TITLE_PROJECTS_MATCHES: 'Project matches',
    PAGE_TITLE_PROJECTS_MY: 'My projects',
    PAGE_TITLE_PROJECTS_SEARCH: 'Search projects',
    PAGE_TITLE_PROJECTS_OFFERED: 'Projects offered by me',

    ORIGIN_TOPIC_PROJECTS_PRIVATE: 'Projects',
    ORIGIN_TOPIC_PROJECTS_PARTICIPANT: 'Projects',

    PROJECTS_ORGANIZERS_INFO:
        'An offer can have several organizers, so that they can promote this offer in parallel or in the absence of other organizers. Here you can define who else can edit and delete this {{name_project_singular}} and send messages to participants. The users listed here can add and delete organizers, too.',
};
