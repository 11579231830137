import { combineReducers, createReducer, on } from '@ngrx/store';
import { MentoringActions } from './mentoring.actions';
import { AccountActions, BookmarkActions, PageParams } from '@tploy-enterprise/tenant-core';
import {
    MentoringBookmark,
    MentoringContext,
    MentoringMatch,
    MentoringProfile,
    MentoringSuggestedSkills,
} from './mentoring-service/mentoring.types';

export type MentoringState = {
    profile: MentoringProfileState;
    matches: MentoringMatchesState;
    bookmarks: MentoringBookmarksState;
    search: MentoringSearchState;
    context: MentoringContextState;
    suggestedSkills: SuggestedSkillsState;
};

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: MentoringSuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type MentoringProfileState = {
    status: StateStatus;
    saved: MentoringProfile;
    current: MentoringProfile;
};

export type MentoringMatchesState = {
    loaded: boolean;
    loading: boolean;
    error: Error;
    allData: Array<MentoringMatch>;
    data: Array<MentoringMatch>;
    pageParams: PageParams;
    selectedCampaigns: string[];
};

export type MentoringBookmarksState = {
    loading: boolean;
    loaded: boolean;
    error: Error;
    data: MentoringBookmark[];
};

export type MentoringSearchState = {
    loaded: boolean;
    loading: boolean;
    error: Error;
    results: MentoringMatch[];
    allResults: MentoringMatch[];
    pageParams: PageParams;
    searchQuery: string;
};

export type MentoringContextState = {
    used: MentoringContext;
};

export const MentoringProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(MentoringActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(MentoringActions.loadProfileSuccess, (state) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: null,
    })),
    on(MentoringActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(MentoringActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(MentoringActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(MentoringActions.saveProfileError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(MentoringActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
);

export const MentoringProfileSavedReducer = createReducer(
    {
        motivations: [],
        skillsInterestedIn: [],
        motivationInterest: [],
        skillsOffering: [],
        motivationOffering: [],
    },
    on(MentoringActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(MentoringActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const MentoringProfileCurrentReducer = createReducer(
    {
        motivations: [],
        skillsInterestedIn: [],
        motivationInterest: [],
        skillsOffering: [],
        motivationOffering: [],
    },
    on(MentoringActions.editCurrentProfile, (state, { currentProfile }) => ({ ...state, ...currentProfile })),
    on(MentoringActions.loadProfileSuccess, (state, { profile, session }) => session || profile),
    on(MentoringActions.loadProfileError, (state, { session }) => session || state),
    on(MentoringActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        skillsOffering:
            state.skillsOffering.length > 0 ? state.skillsOffering : account.generalData.externalSkills ?? [],
    })),
);

export const MentoringProfileReducer = combineReducers({
    status: MentoringProfileStatusReducer,
    saved: MentoringProfileSavedReducer,
    current: MentoringProfileCurrentReducer,
});

export const mentoringMatchingInitialState: MentoringMatchesState = {
    loading: false,
    loaded: false,
    error: null,
    data: null,
    allData: null,
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    selectedCampaigns: [],
};

const MentoringMatchesReducer = createReducer(
    mentoringMatchingInitialState,
    on(MentoringActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(MentoringActions.loadMatchesSuccess, (state, { matches, allMatches, pageParams, selectedCampaigns }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: [...matches],
            allData: [...allMatches],
            pageParams,
            selectedCampaigns,
        };
    }),
    on(MentoringActions.loadMatchResultSuccess, (state, { data, index }) => {
        state.allData[index] = data;
        return {
            ...state,
            allData: [...state.allData],
        };
    }),
    on(MentoringActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const MentoringBookmarksReducer = createReducer(
    { loaded: false, loading: false, data: [], length: 0, error: null },
    on(MentoringActions.loadBookmarks, (state) => {
        return { ...state, loading: true };
    }),
    on(MentoringActions.loadBookmarksSuccess, (state, { bookmarks }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: [...bookmarks],
        };
    }),
    on(MentoringActions.loadBookmarksError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
    on(BookmarkActions.addSuccess, (state) => ({ ...state, loaded: false })),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => ({
        ...state,
        loaded: false,
        data: state.data.filter((existingBookmark) => {
            return existingBookmark.id !== bookmark.id;
        }),
    })),
);

export const mentoringSearchInitialState: MentoringSearchState = {
    loaded: false,
    loading: false,
    error: null,
    results: [],
    allResults: [],
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    searchQuery: '',
};

export const MentoringSearchReducer = createReducer(
    mentoringSearchInitialState,
    on(MentoringActions.search, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(MentoringActions.searchSuccess, (state, { results, allResults, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...results],
            allResults: [...allResults],
            pageParams,
        };
    }),
    on(MentoringActions.loadSearchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(MentoringActions.searchError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

export const MentoringContextReducer = createReducer(
    { used: MentoringContext.MENTORING },
    on(MentoringActions.changeContext, (state, { context }) => ({ ...state, used: context })),
);

const MentoringSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(MentoringActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(MentoringActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(MentoringActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

export const MentoringReducer = combineReducers({
    profile: MentoringProfileReducer,
    matches: MentoringMatchesReducer,
    bookmarks: MentoringBookmarksReducer,
    search: MentoringSearchReducer,
    context: MentoringContextReducer,
    suggestedSkills: MentoringSuggestedSkillsReducer,
});
