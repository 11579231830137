import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountState, GeneralData } from '../account';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GeneralInformationResolver implements Resolve<GeneralData> {
    constructor(private store: Store<{ account: AccountState }>) {}

    resolve(): Observable<GeneralData> {
        return this.store.select((state) => state.account.generalData.edit.data).pipe(take(1));
    }
}
