// Domain related exceptions

export class WorkshopDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for Workshops.');
    }
}
export class WorkshopDomainMatchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was getting matches for Workshops.');
    }
}
