import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'tp-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'modal-layout',
    },
})
export class PreviewComponent {}
