import {
    MentoringBookmark,
    MentoringContext,
    MentoringMatch,
    MentoringProfile,
    MentoringSearchResult,
    MentoringSuggestedSkills,
} from './mentoring-service/mentoring.types';
import { MentoringState } from './mentoring.reducer';
import { INTEREST_KEY, OFFERING_KEY, PageParams } from '@tploy-enterprise/tenant-core';

export function selectCurrentMentoringProfile(state: { mentoring: MentoringState }): MentoringProfile {
    return state.mentoring.profile.current;
}

export function selectSavedMentoringProfile(state: { mentoring: MentoringState }): MentoringProfile {
    return state.mentoring.profile.saved;
}

export function selectMatchesPageSelectedCampaigns(state: { mentoring: MentoringState }): string[] {
    return state.mentoring.matches.selectedCampaigns;
}

export function isMentoringProfileLoadedOrLoading(state: { mentoring: MentoringState }): boolean {
    const status = state.mentoring.profile.status;
    return status.loaded || status.loading;
}

export function isMentoringProfileLoaded(state: { mentoring: MentoringState }): boolean {
    const status = state.mentoring.profile.status;
    return status.loaded;
}

export function isMentoringProfileInDirtyState(state: { mentoring: MentoringState }): boolean {
    const status = state.mentoring.profile.status;
    return status.dirty;
}

export function hasMentoringProfile(state: { mentoring: MentoringState }): boolean {
    const profileState = state.mentoring.profile;
    return (
        profileState.status.loaded && !profileState.status.loadingError && profileState.current?.motivations?.length > 0
    );
}

export function isUserInterestedInMentoring(state: { mentoring: MentoringState }): boolean {
    return state.mentoring.profile.current?.motivations?.indexOf(INTEREST_KEY) !== -1;
}

export function isUserOfferMentoring(state: { mentoring: MentoringState }): boolean {
    const currentMotivations = state.mentoring.profile.current.motivations;
    return currentMotivations.length === 0 || currentMotivations.indexOf(OFFERING_KEY) >= 0;
}

export function selectMatches(state: { mentoring: MentoringState }): MentoringMatch[] {
    return state.mentoring.matches.data;
}

export function selectAllMatches(state: { mentoring: MentoringState }): MentoringMatch[] {
    return state.mentoring.matches.allData;
}

export function selectMatchesPageParams(state: { mentoring: MentoringState }): PageParams {
    return state.mentoring.matches.pageParams;
}

export function selectBookmarks(state: { mentoring: MentoringState }): MentoringBookmark[] {
    return state.mentoring.bookmarks.data;
}

export function selectSearchResults(state: { mentoring: MentoringState }): MentoringSearchResult[] {
    return state.mentoring.search.results || [];
}

export function selectAllSearchResults(state: { mentoring: MentoringState }): MentoringSearchResult[] {
    return state.mentoring.search.allResults;
}

export function selectSearchPageParams(state: { mentoring: MentoringState }): PageParams {
    return state.mentoring.search.pageParams;
}

export function isSearchResultsEmpty(state: { mentoring: MentoringState }): boolean {
    const searchState = state.mentoring.search;
    return searchState.loaded && searchState.results.length == 0;
}

export function isSearchResultsLoading(state: { mentoring: MentoringState }): boolean {
    return state.mentoring.search.loading;
}

export function selectUsedContext(state: { mentoring: MentoringState }): MentoringContext {
    return state.mentoring.context.used;
}

export function suggestedSkills(state: { mentoring: MentoringState }): MentoringSuggestedSkills {
    return state.mentoring.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { mentoring: MentoringState }): string[] {
    return state.mentoring.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { mentoring: MentoringState }): string[] {
    return state.mentoring.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { mentoring: MentoringState }): string[] {
    return state.mentoring.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}
