import {
    GeneralData,
    INTEREST_TYPE,
    LocationDTO,
    OFFERING_TYPE,
    OthersAccount,
    PageParams,
} from '@tploy-enterprise/tenant-core';
import { Skill, SuggestedSkills, Translatable } from '@tploy-enterprise/tenant-common';
import { STAProfileMatchDTO } from './sta-api/sta.dtos';

export const PROFILE_STORAGE_KEY = 'tploy.shortTimeAssignments.profile';
export const IS_PROFILE_DIRTY = 'tploy.shortTimeAssignments.profile.isDirty';

export enum STASkillLevel {
    'JUNIOR' = 1,
    'SENIOR' = 2,
    'EXPERT' = 3,
}

export interface StaffingSkill {
    name: string;
    hasExactMatch: boolean;
}

export type STAApplicationsSelections = {
    skillsStaffing: StaffingSkill[];
    applications: STAApplication[];
};

export interface STASkill {
    name: string;
    level: STASkillLevel;
    // the position is a serialization concern
    // locally, the array ordering is the source of truth
}

export interface STARequiredSkill extends STASkill {
    required: boolean;
}

export type STAMotivation = OFFERING_TYPE | INTEREST_TYPE;

export interface STAProfile {
    motivations: STAMotivation[];
    skillsInterestedIn: string[];
    skills: Skill[];
    campaigns: string[];
    categoriesInterestedIn: string[];
    pushToSuccessFactor?: boolean;
    locations?: LocationDTO[];
    requiredExactSkills?: Skill[];
    requiredExactSkillsRelations?: Skill[][];
    requiredNotExactSkills?: Skill[];
    requiredNotExactSkillsRelations?: Skill[][];
    nthExactSkills?: Skill[];
    nthExactSkillsRelations?: Skill[][];
    nthNotExactSkills?: Skill[];
    nthNotExactSkillsRelations?: Skill[][];
    highlightedExactSkills?: Skill[];
    highlightedByRelatedSkills?: Skill[];
    loadingError?: Error;
}

export interface STAOthersProfile extends STAProfile {
    user: {
        name: string;
        imageUrl: string;
        locations: { name: string }[];
        languages: string[];
        id?: string; // Check BE returns user id
    };
}

export interface STAEntity {
    id?: string;
    title: string;
    categories: string[];
    languages: string[];
    description: string;
    startDate: Date;
    endDate: Date;
    applicationCloseAt: Date;
    requestedSkills: Skill[];
    selectedGroups: string[];
    location: string;
    imageUrl: string;
    staffingCompleted: boolean;
    organizers: Array<STAOrganizer>;
    customConditions?: string;
    lastUpdatedAt?: string;
    published?: boolean;
    company: string;
    campaigns: string[];
    department: string;
    applications?: STAApplication[];
    applicationStatus?: STAApplicationStatus;
    canUserApply?: boolean;
    isApplicationForUserPendingOrApproved?: boolean;
    draft: boolean;
    numberOfApplications?: number;
    hasUnseenApplications: boolean;
    highlighted?: StaEntityHighlight;
    applicationSelectedSkills?: Skill[];

    // This is resolved dynamically
    // Another option would be to replace the organizer property with its data
    organizerData?: OthersAccount; // this is set in the backend and cannot be edited
}

export interface STAApplication {
    id: string;
    userId: string;
    name: string;
    status: STAApplicationStatus;
    appliedAt: string;
    approvedAt?: string;
    approvedBy?: string;
    rejectedAt?: string;
    rejectedBy?: string;

    // hydrated property
    profile?: STAOthersProfile;
}

export enum STAApplicationStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
}

export enum STAEntityStatus {
    PLANNED = 'planned',
    RUNNING = 'running',
    COMPLETED = 'completed',
}

export interface ApplicationRequest {
    user: { name: string };
    selectedSkills: Skill[];
}

export type StaEntityHighlight = {
    categories: Array<string>;
    mustHaveSkills: Array<string>;
    mustHaveSkillsWithRelated?: Array<StaEntityHighlightRelation>;
    niceToHaveSkills: Array<string>;
    niceToHaveSkillsWithRelated?: Array<StaEntityHighlightRelation>;
};

export type StaEntityHighlightRelation = {
    name: string;
    exact: boolean;
    level: STASkillLevel;
    related: Array<string>;
};

export type STASuggestedSkills = SuggestedSkills;

export type TargetGroup = {
    name: string;
    options: Array<string | Translatable>;
};

export type STASearchResultHighlight = {
    skillsOptional: string[];
    skillsRequired: string[];
    location: string[];
    categories: string[];
    campaigns?: string[];
    title: string[];
    description: string[];
};

export type STASearchResults = {
    results: Array<STASearchResult>;
    pageParams: PageParams;
};

export type STASearchResult = {
    staId: string;
    staEntity: STAEntity;
    organizers: Array<STAOrganizer>;
    highlight: STASearchResultHighlight;
};

export type STAProfileMatchHighlight = {
    categories: string[];
    campaigns?: string[];
    mustHaveSkills: string[];
    niceToHaveSkills: string[];
};

export type STAProfileMatchesDTO = {
    results: STAProfileMatchDTO[];
    totalCount: number;
};

export type STAProfileMatches = {
    matches: Array<STAProfileMatch>;
    pageParams: PageParams;
};

export type STAProfileMatch = {
    staId: string;
    staEntity: STAEntity;
    organizers: Array<STAOrganizer>;
    highlight: STAProfileMatchHighlight;
};

export type STAApplicationIndicatorsDTO = {
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    shortTimeAssignmentsWithUnseenApplications: Array<string>;
};
export type STAApplicationIndicators = {
    hasUnseenApplicationsOfferingTab: boolean;
    hasUnseenApplicationsTopicLink: boolean;
    shortTimeAssignmentsWithUnseenApplications: Array<string>;
};

export interface STAProfileSearchResult {
    profile: STAOthersProfile;
    notified: boolean;
}

export interface STAProfileInvitationMessage {
    staEntity: STAEntity;
    profile: STAOthersProfile;
    messageContent: string;
}

export interface StaStaffingFilter {
    statuses?: STAEntityStatus[];
    isApplicationOpen?: boolean;
    languages?: string[];
    categories?: string[];
}

export type STAOrganizer = { id: string; name: string; imageUrl: string };
