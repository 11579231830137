<a
    class="info-toggle__link"
    [class.disabled]="disabled"
    tabindex="0"
    role="link"
    (click)="!disabled && openDialog($event)"
    (keydown.enter)="!disabled && openDialog($event)"
    [attr.aria-label]="ariaLabel"
>
    <mat-icon class="info-toggle__icon" aria-hidden="true" [svgIcon]="svgIcon"> </mat-icon>
    <ng-content></ng-content>
</a>
