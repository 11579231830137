<div>
    <div *ngIf="!hideAvatar" class="mat-card-image" [ngStyle]="{ 'background-image': 'url(' + avatar + ')' }"></div>

    <div class="caption-and-name">
        <div class="caption">{{ caption }}</div>
        <div class="name">{{ name }}</div>
    </div>

    <mat-icon
        *ngIf="isLeftDirection && !hideAvatar"
        class="nav-arrow mat-icon-left"
        svgIcon="arrow_left"
        [inline]="true"
    ></mat-icon>
    <mat-icon
        *ngIf="isRightDirection && !hideAvatar"
        class="nav-arrow mat-icon-right"
        svgIcon="arrow_right"
        [inline]="true"
    ></mat-icon>
</div>
