import { Injectable } from '@angular/core';
import { TopicDefinition } from './topic.types';
import { ConfigService } from '../config';

@Injectable({
    providedIn: 'root',
})
export class TopicRegistryService {
    private topicDefinitions: TopicDefinition[] = [];

    constructor(private configService: ConfigService) {}

    registerTopic(definition: TopicDefinition) {
        this.topicDefinitions.push(definition);
        this.configService.isLoaded.subscribe((isLoaded) => {
            if (isLoaded) {
                this.registerTranslations(definition);
            }
        });
    }

    availableTopics(): TopicDefinition[] {
        return [...this.topicDefinitions];
    }

    selectTopicDefinitions(topics: string[]): TopicDefinition[] {
        const resolvedDefinitions = topics
            .map((topic) => this.topicDefinitions.map((t) => t.name).indexOf(topic))
            .filter((index) => index !== -1)
            .map((index) => this.topicDefinitions[index]);

        return resolvedDefinitions;
    }

    selectTopicDefinition(topic: string): TopicDefinition {
        const topicDefinitions = this.selectTopicDefinitions([topic]);

        if (topicDefinitions[0]) {
            return topicDefinitions[0];
        }

        throw new Error(`Topic definition of ${topic} does not exist!`);
    }

    private registerTranslations(topicDefinition: TopicDefinition) {
        const translations = topicDefinition.translations;
        if (!translations) {
            console.warn(`${topicDefinition.name} module does not provide translations.`);
            return;
        }

        this.configService.registerTranslations(translations, true);
    }
}
