import { createAction, props } from '@ngrx/store';
import { ExpertAvailabilityData } from '../../expert-api/expert.types';

export const ExpertAvailabilityActions = {
    change: createAction('[Expert Availability] Change', props<{ availability: ExpertAvailabilityData }>()),
    save: createAction('[Expert Availability] Save'),
    saveSuccess: createAction('[Expert Availability][Success] Save', props<{ availability: ExpertAvailabilityData }>()),
    saveError: createAction(
        '[Expert Availability][Error] Save',
        props<{ error: Error; availability: ExpertAvailabilityData }>(),
    ),
};
