<header class="sidenav_header">
    <a routerLink="/dashboard" class="sidenav__logo-link" [attr.aria-label]="'SIDENAV_LOGO_ALT_TEXT' | translatable">
        <img
            class="sidenav__logo"
            [alt]="'SIDENAV_LOGO_ALT_TEXT' | translatable"
            src="/api/v2/tenant-settings/assets/logo-navbar.png"
        />
    </a>
    <a
        routerLinkActive="active"
        class="sidenav__profile-link"
        routerLink="/profile"
        focused
        #container="focusContainer"
        [attr.aria-label]="'SIDENAV_AVATAR_ALT_TEXT' | translatable"
    >
        <div class="sidenav__avatar" [style.background-image]="'url(' + (avatar$ | async) + ')'"></div>
    </a>
</header>
<nav class="sidenav_links">
    <h6 class="sidenav_top-links-header">{{ 'SIDENAV_TOP_LINKS' | translatable }}</h6>

    <ul class="sidenav_top-links" role="menu">
        <li
            routerLinkActive="active"
            role="menuitem"
            class="sidenav_link"
            focused
            #container="focusContainer"
            [attr.aria-label]="'DASHBOARD' | translatable"
        >
            <a routerLink="/dashboard">
                <mat-icon class="sidenav_link-icon" svgIcon="home_filled" aria-hidden="true"></mat-icon>
                {{ 'DASHBOARD' | translatable }}
            </a>
        </li>
        <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            role="menuitem"
            class="sidenav_link"
            focused
            #container="focusContainer"
            [attr.aria-label]="'PROFILE' | translatable"
        >
            <a routerLink="/profile">
                <mat-icon class="sidenav_link-icon" svgIcon="profile" aria-hidden="true"></mat-icon>
                {{ 'PROFILE' | translatable }}
            </a>
        </li>
        <li
            routerLinkActive="active"
            role="menuitem"
            class="sidenav_link"
            focused
            #container="focusContainer"
            [attr.aria-label]="'MESSAGING' | translatable"
        >
            <a
                routerLink="/direct-messages"
                class="sidenav_link-icon-badge"
                [matBadge]="unreadMessages$ | async"
                matBadgePosition="before"
                matBadgeColor="primary"
                [matBadgeHidden]="(unreadMessages$ | async)?.length === 0"
            >
                <mat-icon class="sidenav_link-icon" svgIcon="email_filled" aria-hidden="true"></mat-icon>
                {{ 'MESSAGING' | translatable }}
            </a>
        </li>
        <li
            routerLinkActive="active"
            role="menuitem"
            class="sidenav_link"
            focused
            #container="focusContainer"
            [attr.aria-label]="'BOOKMARKS' | translatable"
        >
            <a routerLink="/bookmark">
                <mat-icon class="sidenav_link-icon no-border" svgIcon="star_filled" aria-hidden="true"></mat-icon>
                {{ 'BOOKMARKS' | translatable }}
            </a>
        </li>
    </ul>

    <mat-divider class="sidenav_divider"></mat-divider>
    <tp-sidenav-topics
        [topics]="topicLinks$ | async"
        [canAddMoreTopics]="canAddMoreTopics$ | async"
        [referenceContext]="referenceContext$ | async"
    ></tp-sidenav-topics>

    <mat-divider class="sidenav_divider"></mat-divider>
    <h6 class="sidenav_bottom-links-header">{{ 'SIDENAV_BOTTOM_LINKS' | translatable }}</h6>
    <ul class="sidenav_bottom-links" role="menu">
        <li
            routerLinkActive="active"
            class="sidenav_link"
            role="menuitem"
            focused
            #container="focusContainer"
            [attr.aria-label]="'ABOUT_FAQ' | translatable"
        >
            <a routerLink="/information">
                <mat-icon class="sidenav_link-icon" svgIcon="info_filled" aria-hidden="true"></mat-icon>
                {{ 'ABOUT_FAQ' | translatable }}
            </a>
        </li>
        <li
            routerLinkActive="active"
            class="sidenav_link"
            role="menuitem"
            focused
            #container="focusContainer"
            [attr.aria-label]="'SETTINGS' | translatable"
        >
            <a routerLink="/settings">
                <mat-icon class="sidenav_link-icon" svgIcon="settings_filled" aria-hidden="true"></mat-icon>
                {{ 'SETTINGS' | translatable }}
            </a>
        </li>
        <li
            *tpIfPermission="'core.admin.view'"
            routerLinkActive="active"
            class="sidenav_link"
            role="menuitem"
            focused
            #container="focusContainer"
            [attr.aria-label]="'ADMIN_PANEL' | translatable"
        >
            <a routerLink="/administration">
                <mat-icon class="sidenav_link-icon" svgIcon="view_filled" aria-hidden="true"></mat-icon>
                {{ 'ADMIN_PANEL' | translatable }}
            </a>
        </li>
        <li class="sidenav_link" role="menuitem" focused #container="focusContainer">
            <tp-footer-links [links]="footerLinks" [viewMode]="'link'"></tp-footer-links>
        </li>
    </ul>
</nav>
<footer class="sidenav_footer">
    <tp-language-switcher class="sidenav_language-switcher"></tp-language-switcher>
    <a
        class="sidenav_sign-out-toggle"
        (click)="signOut()"
        [attr.aria-label]="'LOGOUT' | translatable"
        tabindex="0"
        role="link"
    >
        {{ 'LOGOUT_LINK' | translatable }}
        <mat-icon class="sidenav_sign-out-toggle-icon" svgIcon="exit_filled" aria-hidden="true"></mat-icon>
    </a>
</footer>
