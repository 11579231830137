import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, tap, switchMap, map, catchError } from 'rxjs/operators';
import { SelectedTopicsState } from './selected-topics.reducer';
import { SelectedTopicsActions } from './selected-topics.actions';
import { EMPTY, of } from 'rxjs';
import { AccountState } from '../account.reducer';
import { SelectedTopicsService } from './selected-topics.service';
import { TopicsDataService } from '../../topic/topic-data.service';
import { AccountActions } from '../account.actions';
import { StorageService } from '../../storage/service/storage.service';
import { SELECTED_TOPICS_STORAGE_EDIT_KEY } from './selected-topics.types';

@Injectable({
    providedIn: 'root',
})
export class SelectedTopicsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ account: AccountState }>,
        private selectedTopicsService: SelectedTopicsService,
        private topicsDataService: TopicsDataService,
        private storage: StorageService,
    ) {}

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SelectedTopicsActions.save),
            withLatestFrom(this.store.select((state) => state.account.selectedTopics.edit.data)),
            switchMap(([action, selectedTopics]) =>
                this.topicsDataService.saveTopicsData(selectedTopics).pipe(map(() => selectedTopics)),
            ),
            switchMap((selectedTopics) =>
                this.selectedTopicsService.save(selectedTopics).pipe(
                    map(() => SelectedTopicsActions.saveSuccess({ selectedTopics })),
                    catchError((error) => of(SelectedTopicsActions.saveError({ error, selectedTopics }))),
                ),
            ),
        ),
    );

    storeChanges = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SelectedTopicsActions.edit),
                withLatestFrom(this.store.select((state) => state.account.selectedTopics)),
                tap(([action, state]) => this.storeEditionState(state)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    restoreEditStateOnAbort = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SelectedTopicsActions.cancelEdit),
                withLatestFrom(this.store.select((state) => state.account.selectedTopics.current.data)),
                switchMap(([action, current]) => {
                    return of(
                        SelectedTopicsActions.resetErrors({ errors: null }),
                        SelectedTopicsActions.edit({ selectedTopics: current, errors: null }),
                    );
                }),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    deleteSessionSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    SelectedTopicsActions.cancelEdit,
                    SelectedTopicsActions.saveSuccess,
                    AccountActions.createSuccess,
                ),
                tap(() => this.deleteEditionState()),
            ),
        { dispatch: false },
    );

    unselectTopic = createEffect(() =>
        this.actions$.pipe(
            ofType(SelectedTopicsActions.unselectTopic),
            withLatestFrom(this.store.select((state) => state.account.selectedTopics.edit.data)),
            switchMap(([action, selectedTopics]) => {
                const newSelection = [...selectedTopics];
                const index = newSelection.indexOf(action.topic);
                if (index !== -1) {
                    newSelection.splice(index, 1);
                }

                return of(
                    SelectedTopicsActions.edit({ selectedTopics: newSelection, errors: null }),
                    SelectedTopicsActions.save(),
                );
            }),
        ),
    );

    selectTopic = createEffect(() =>
        this.actions$.pipe(
            ofType(SelectedTopicsActions.selectTopic),
            withLatestFrom(this.store.select((state) => state.account.selectedTopics.edit.data)),
            switchMap(([action, selectedTopics]) => {
                const newSelection = [...selectedTopics];
                const index = newSelection.indexOf(action.topic);
                if (index === -1) {
                    newSelection.splice(0, 0, action.topic);
                    return of(
                        SelectedTopicsActions.edit({ selectedTopics: newSelection, errors: null }),
                        SelectedTopicsActions.save(),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    private storeEditionState(state: SelectedTopicsState): void {
        this.storage.local.set(SELECTED_TOPICS_STORAGE_EDIT_KEY, state.edit);
    }

    private deleteEditionState(): void {
        this.storage.local.remove(SELECTED_TOPICS_STORAGE_EDIT_KEY);
    }
}
