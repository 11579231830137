import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NLAState } from '../nla.reducer';
import { hasNLAProfile } from '../nla.selectors';
import { map, withLatestFrom } from 'rxjs/operators';
import { AccountState, isContributor } from '@tploy-enterprise/tenant-core';
import { Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent, LangChangeEvent } from '@ngx-translate/core';

const TABS = ['/never-lunch-alone/matching', '/never-lunch-alone/bookmarks', '/never-lunch-alone/search'];

@Component({
    selector: 'tp-nla-domain',
    templateUrl: './nla-domain.component.html',
    styleUrls: ['./nla-domain.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'main-layout nla',
    },
})
export class NlaDomainComponent implements OnInit, OnDestroy {
    onTranslationChangeSub: Subscription;
    onLangChangeSub: Subscription;
    onDefaultLangChangeSub: Subscription;

    hasProfile$: Observable<boolean>;

    tabs: Array<any>;
    initialTab = '';

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly store: Store<{ neverLunchAlone: NLAState; account: AccountState }>,
    ) {}

    ngOnInit() {
        this.hasProfile$ = this.store.select(hasNLAProfile).pipe(
            withLatestFrom(this.store.select(isContributor)),
            map(([hasProfile, isContributor]) => hasProfile || isContributor),
        );

        this.initTabs();
        if (!this.onTranslationChangeSub) {
            this.onTranslationChangeSub = this.translateService.onTranslationChange.subscribe(
                (event: TranslationChangeEvent) => {
                    this.initTabs();
                },
            );
        }
        if (!this.onLangChangeSub) {
            this.onLangChangeSub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                this.initTabs();
            });
        }
        if (!this.onDefaultLangChangeSub) {
            this.onDefaultLangChangeSub = this.translateService.onDefaultLangChange.subscribe(() => {
                this.initTabs();
            });
        }

        this.initialTab = this.router.url.split('?')[0];
        this.router.events.subscribe((route: any) => {
            if (route.url) {
                const tabValue = route.url.split('?')[0];
                if (this.initialTab !== route.url) {
                    if (TABS.includes(tabValue)) {
                        this.initialTab = tabValue;
                    } else {
                        this.initialTab = TABS[0];
                    }
                    this.changeDetectorRef.detectChanges();
                    this.changeDetectorRef.markForCheck();
                }
            }
        });
    }

    ngOnDestroy() {
        if (typeof this.onTranslationChangeSub !== 'undefined') {
            this.onTranslationChangeSub.unsubscribe();
            this.onTranslationChangeSub = undefined;
        }
        if (typeof this.onLangChangeSub !== 'undefined') {
            this.onLangChangeSub.unsubscribe();
            this.onLangChangeSub = undefined;
        }
        if (typeof this.onDefaultLangChangeSub !== 'undefined') {
            this.onDefaultLangChangeSub.unsubscribe();
            this.onDefaultLangChangeSub = undefined;
        }
    }

    private initTabs() {
        this.tabs = [
            {
                path: TABS[0],
                label: this.translateService.instant('NLA_MATCHES_TAB'),
            },
            {
                path: TABS[1],
                label: this.translateService.instant('NLA_BOOKMARKS_TAB'),
            },
            {
                path: TABS[2],
                label: this.translateService.instant('NLA_SEARCH_TAB'),
            },
        ];
    }
}
