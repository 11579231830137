import {
    Input,
    Directive,
    ComponentFactory,
    ComponentRef,
    ViewContainerRef,
    ComponentFactoryResolver,
} from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TenantTemplateEnum } from './tenant-templates.types';
import { LanguageOptions, TPLOY_LANGUAGE_STORAGE_KEY } from '@tploy-enterprise/tenant-common';
import { AccountState } from '../account';
import { Store } from '@ngrx/store';
import { ConfigService } from '../config';

@Directive({
    selector: '[tpCustomTemplateOutlet]',
})
export class TenantTemplateDirective {
    @Input()
    set tpCustomTemplateOutlet(value) {
        this._componentFactory$ = this.load(value);
    }

    private _componentFactory$: Observable<ComponentFactory<unknown>>;
    private destroy$ = new Subject<void>();

    private _componentRef: ComponentRef<unknown>;

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly configService: ConfigService,
        private readonly store: Store<{ account: AccountState }>,
    ) {}

    ngOnInit() {
        this._componentFactory$.subscribe((factory: ComponentFactory<any>) => {
            this.viewContainerRef.clear();
            this._componentRef = this.viewContainerRef.createComponent(factory);
            this._componentRef.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        if (this._componentRef) {
            this._componentRef.destroy();
            this._componentRef = null;
        }
    }

    private load(templateName: TenantTemplateEnum) {
        return this.store
            .select((state) => state.account.locale.used)
            .pipe(
                map((locale) => {
                    if (locale) {
                        return locale;
                    } else {
                        const storedLocale = localStorage.getItem(TPLOY_LANGUAGE_STORAGE_KEY);
                        // eslint-disable-next-line no-extra-boolean-cast
                        if (!!storedLocale) {
                            return storedLocale as LanguageOptions;
                        } else {
                            return this.configService.remoteSettings.defaultLocale;
                        }
                    }
                }),
                switchMap((lang) => this.createComponentFactory(templateName, lang as LanguageOptions)),
                takeUntil(this.destroy$),
            );
    }

    private createComponentFactory(
        template: TenantTemplateEnum,
        lang: LanguageOptions,
    ): Observable<ComponentFactory<unknown>> {
        const templates = this.configService.localSettings.templates;
        if (templates[lang]) {
            const factory = this.componentFactoryResolver.resolveComponentFactory(templates[lang][template]);
            return of(factory);
        } else {
            return of(null);
        }
    }
}
