import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tp-job-x-tandem-check-col',
    templateUrl: './job-x-tandem-check-col.component.html',
    styleUrls: ['./job-x-tandem-check-col.component.scss'],
    host: {
        class: 'job-x__tandem-check-grid--col',
    },
})
export class JobXTandemCheckColComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
