import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessFactorsSkillsOverlayComponent } from './modal/success-factors-skills-overlay.component';
import { TranslateService } from '@ngx-translate/core';
import { ButtonModule } from '@tandemploy/ngx-components';
import { LanguageModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    imports: [CommonModule, ButtonModule, LanguageModule, MatDialogModule],
    declarations: [SuccessFactorsSkillsOverlayComponent],
    exports: [SuccessFactorsSkillsOverlayComponent],
    providers: [TranslateService],
})
export class SuccessFactorsModule {}
