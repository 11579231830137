import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'tp-max-images-reached-delete-dialog',
    templateUrl: './max-images-reached-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxImagesReachedDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public maxImages: number) {}
}
