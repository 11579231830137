import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ListItemBase } from './list-item-base.component';

@Component({
    selector: 'tp-radio-list-item',
    templateUrl: './radio-list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioListItemComponent extends ListItemBase {}
