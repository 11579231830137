import { Settings } from '@tploy-enterprise/tenant-core';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { templates } from './custom-templates/templates';

export const localSettings: Settings = {
    language: {
        translations: {
            en: enTranslations,
            de: deTranslations,
        },
    },
    templates,
};
