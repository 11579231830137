<h2 *ngIf="tags.length > 0">{{ label | translatable }}</h2>
<mat-icon (click)="hideSkillsContainer($event)" svgIcon="close" class="close-skills-container-icon"></mat-icon>
<div class="tags-suggestion__tags_container">
    <mat-chip-list
        selectable="false"
        class="tags-suggestion__tags-list"
        [class.tags-suggestion__tags-list--empty]="tags.length === 0"
    >
        <tp-chip
            *ngFor="let tag of tags; trackBy: trackByName"
            (simpleClick)="selectTag(tag)"
            (enterPressed)="selectTag(tag)"
            (iconClick)="openModalForHideSkill($event)"
            [icon]="showConfigIcon && 'close'"
            [value]="tag"
            class="tags-suggestion__skill"
            [class.opened]="isShowModalForHide[tag]"
        >
            <div class="tags-suggestion__skill-hide-modal" [class.opened]="isShowModalForHide[tag]">
                <div class="tags-suggestion__skill-hide-modal__arrow"></div>
                <div class="tags-suggestion__skill-hide-modal__hide-action" (click)="hideTag($event, tag)">
                    <mat-icon class="card__facts-segment-icon" svgIcon="visibility_off"></mat-icon>
                    <span>{{ 'HIDE_SUGGESTED_SKILL' | translatable }}</span>
                </div>
                <div
                    class="tags-suggestion__skill-hide-modal__cancel-action"
                    (click)="closeModalForHideSkill($event, tag)"
                >
                    <mat-icon class="card__facts-segment-icon" svgIcon="close"></mat-icon>
                    <span>{{ 'CANCEL' | translatable }}</span>
                </div>
            </div>
        </tp-chip>

        <ng-container [ngTemplateOutlet]="showAllButton"></ng-container>
        <ng-container [ngTemplateOutlet]="selectAllButton"></ng-container>
    </mat-chip-list>

    <ng-template #showAllButton>
        <tp-chip
            *ngIf="tags.length > 0 && (tags.length < allTagsWithoutSelected.length || showAllSkills)"
            (enterPressed)="toggleShowAll()"
            (simpleClick)="toggleShowAll()"
            class="tags-suggestion__hide-button no-margin"
            [value]="!showAllSkills ? '...' : ''"
            [icon]="showAllSkills && 'close'"
        ></tp-chip>
    </ng-template>

    <ng-template #selectAllButton>
        <tp-chip
            *ngIf="tags.length > 0 && showSelectAll"
            (simpleClick)="selectAll()"
            (enterPressed)="selectAll()"
            class="tags-suggestion__select-all-button"
            [value]="'TAG_SUGGESTION_ADD_ALL' | translatable"
            [icon]="'add'"
        ></tp-chip>
    </ng-template>
    <div></div>
</div>
