import {
    ACTION_SNACK_COMPONENT,
    DEFAULT_SNACK_COMPONENT,
    ERROR_SNACK_COMPONENT,
    IMPORTANT_SNACK_COMPONENT,
} from './notifications.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { DefaultSnackComponent } from './default-snack.component';
import { ActionSnackComponent } from './action-snack.component';
import { ImportantSnackComponent } from './important-snack.component';
import { ErrorSnackComponent } from './error-snack.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NotificationMailtoPipe } from './notification-mailto.pipe';
import { ButtonModule, NotificationsModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        NotificationsModule,
        MatButtonModule,
        MatSnackBarModule,
        LanguageModule,
        MatIconModule,
    ],
    declarations: [
        DefaultSnackComponent,
        ActionSnackComponent,
        ImportantSnackComponent,
        ErrorSnackComponent,
        NotificationMailtoPipe,
    ],
    exports: [NotificationMailtoPipe],
})
export class NotificationsSnackModule {
    static forRoot(): ModuleWithProviders<NotificationsSnackModule> {
        return {
            ngModule: NotificationsSnackModule,
            providers: [
                { provide: DEFAULT_SNACK_COMPONENT, useValue: DefaultSnackComponent },
                { provide: ACTION_SNACK_COMPONENT, useValue: ActionSnackComponent },
                { provide: IMPORTANT_SNACK_COMPONENT, useValue: ImportantSnackComponent },
                { provide: ERROR_SNACK_COMPONENT, useValue: ErrorSnackComponent },
            ],
        };
    }

    static forChild(): ModuleWithProviders<NotificationsSnackModule> {
        return {
            ngModule: NotificationsSnackModule,
            providers: [],
        };
    }
}
