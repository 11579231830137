<span class="details__facts-segment" *ngIf="location">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_LOCATION' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="location_filled"
    ></mat-icon>
    {{ location }}
</span>

<span class="details__facts-segment" *ngIf="start && end">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ start | localizedDate : startDateFormat }} &#8594; {{ end | localizedDate : endDateFormat }}
</span>

<span class="details__facts-segment" *ngIf="end && !start">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ 'CARD_FACTS_END_TIME_UNTIL' | translatable : { endTime: end | localizedHumanizedDate } }}
</span>

<span class="details__facts-segment" *ngIf="start && !end">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CALENDAR' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="calendar_filled"
    ></mat-icon>
    {{ start | localizedDate : startDateFormat }}
</span>

<span class="details__facts-segment details__facts-segment--uppercase" *ngIf="languages && languages.length > 0">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_LANGUAGE' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="languages_filled"
    ></mat-icon>
    {{ languages.join(', ') }}
</span>

<span class="details__facts-segment" *ngIf="deadline">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_APPLICATION_DEADLINE' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="deadline"
    ></mat-icon>
    {{ deadline | localizedDate }}
</span>

<span class="details__facts-segment" *ngIf="company">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_COMPANY' | translatable"
        class="details__facts-segment-icon"
        matTooltipPosition="above"
        svgIcon="home_location_filled"
    ></mat-icon>
    <span class="details__facts-segment-company">{{ company }}</span>
</span>

<tp-campaigns-fact
    *ngIf="campaigns && campaigns.length > 0"
    [campaigns]="campaigns"
    [styleType]="'details'"
></tp-campaigns-fact>
