import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { Permission } from './authorization.types';
import { AuthorizationService } from './authorization.service';

@Directive({
    selector: '[tpIfPermission]',
})
export class IfPermissionDirective implements OnInit {
    @Input() tpIfPermission: Permission;

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly template: TemplateRef<unknown>,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (!this.tpIfPermission) {
            throw new Error('No permission was set.');
        }

        const rolePermissions = this.authorizationService.getPermissionsForRole();
        const hasPermission = rolePermissions.indexOf(this.tpIfPermission) !== -1;

        if (hasPermission) {
            this.attachTemplate();
            this.changeDetectorRef.markForCheck();
        }
    }

    private attachTemplate() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }
}
