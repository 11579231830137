import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'tp-confirm-image-delete-dialog',
    templateUrl: './confirm-image-delete-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmImageDeleteDialogComponent {
    constructor() {}
}
