import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { CommunicationType, ExpertProfile } from '../../expert-api/expert.types';

@Component({
    selector: 'tp-expert-profile-details',
    templateUrl: './expert-profile-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__subsection-details',
    },
})
export class ExpertProfileViewComponent implements TopicProfileView {
    @Input()
    topicData: ExpertProfile;

    get offeringSkills(): string[] {
        return this.topicData.skillsOffering;
    }

    get interestedInSkills(): string[] {
        return this.topicData.skillsInterestedIn;
    }

    get communicationTypeKey(): string {
        switch (this.topicData.communicationType) {
            case CommunicationType.BOTH:
                return 'EXPERT_PROFILE_VIEW_COMMUNICATION_BOTH';
            case CommunicationType.IN_PERSON:
                return 'EXPERT_PROFILE_VIEW_COMMUNICATION_IN_PERSON';
            case CommunicationType.VIRTUAL:
                return 'EXPERT_PROFILE_VIEW_COMMUNICATION_VIRTUAL';
        }
    }

    hasOfferedSkills(): boolean {
        return this.topicData && this.topicData.skillsOffering && this.topicData.skillsOffering.length > 0;
    }

    hasInterestSkills(): boolean {
        return this.topicData && this.topicData.skillsInterestedIn && this.topicData.skillsInterestedIn.length > 0;
    }

    hasCommunicationTypes(): boolean {
        return this.topicData && !!this.topicData.communicationType;
    }

    hasAvailability(): boolean {
        return this.topicData && !!this.topicData.unavailabilityFrom && !!this.topicData.unavailabilityTo;
    }

    hasContent(): boolean {
        return (
            this.hasOfferedSkills() ||
            this.hasInterestSkills() ||
            this.hasCommunicationTypes() ||
            this.hasAvailability()
        );
    }
}
