import { Resolve } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, tap, catchError, take, map } from 'rxjs/operators';
import { AccountNotFoundException } from './account-not-found.exception';
import { Injectable } from '@angular/core';
import { SignUpService } from './sign-up.service';
import { AccountState } from './account.reducer';
import { AccountActions } from './account.actions';
import { Account } from './account.types';
import { ConfigService } from '../config';

@Injectable({
    providedIn: 'root',
})
export class AccountSSOResolver implements Resolve<Account> {
    constructor(
        private readonly store: Store<{ account: AccountState }>,
        private readonly configService: ConfigService,
        private readonly signUpService: SignUpService,
    ) {}

    private loadingFired = false;

    get isSuccessFactorIntegrated(): boolean {
        return this.configService.remoteSettings.integrationSuccessFactor;
    }

    resolve(): Observable<Account> {
        return this.store
            .select((state) => state.account)
            .pipe(
                tap((accountData) => this.checkState(accountData)),
                filter((accountData) =>
                    this.isSuccessFactorIntegrated
                        ? !!accountData.generalData.current.data &&
                          accountData.ssoLoadStatus.loaded &&
                          accountData.successFactorsUserDataLoadStatus.loaded
                        : !!accountData.generalData.current.data,
                ),
                map((accountState) => {
                    return {
                        userId: accountState.userId,
                        roles: accountState.roles,
                        generalData: accountState.generalData.current.data,
                        locale: accountState.locale.saved,
                        selectedTopics: accountState.selectedTopics.current.data,
                        settings: accountState.settings.current.data,
                        experience: accountState.experience.current.data,
                        completionScore: accountState.completionScore,
                    };
                }),
                catchError((error) => {
                    if (error instanceof AccountNotFoundException) {
                        this.signUpService.signUp();
                    }

                    return EMPTY;
                }),
                take(1),
            );
    }

    private checkState(state: AccountState): void {
        if (!this.loadingFired) {
            this.store.dispatch(AccountActions.loadSsoData());

            if (state.status.loadingError) {
                throw state.status.loadingError;
            }

            if (!state.status.loaded && !state.status.loading) {
                this.store.dispatch(AccountActions.load());
            }
            this.loadingFired = true;
        }
    }
}
