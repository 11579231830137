import io, { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { SocketIOEvent, SocketIOServerEvent } from './enum/socket.io-events.enum';
import { DirectMessageServerResponse } from '../../core-pages/direct-messages/models/direct-messages.types';
import { Store } from '@ngrx/store';
import {
    DirectMessagesLoadConversationActions,
    DirectMessagesLoadConversationsActions,
    DirectMessagesUnreadMessagesActions,
} from '../../core-pages/direct-messages/actions';
import { DirectMessagesState } from '../../core-pages/direct-messages/state/direct-messages.state';
import {
    SocketConnectException,
    SocketTimeoutException,
    SocketErrorException,
    SocketDisconnectException,
    SocketReconnectException,
} from './exceptions';

@Injectable({
    providedIn: 'root',
})
export class SocketIOService {
    private hostAddress: string;
    private hostPort: number | string;

    private readonly messageBuffer: Array<{
        channel: string;
        message: Record<any, unknown>;
        observer: Subscriber<DirectMessageServerResponse<unknown>>;
        callback: (observer: Subscriber<DirectMessageServerResponse>, data: string) => void;
    }> = [];

    private readonly listenerBuffer: Array<{
        channel: string;
        observer: Subscriber<unknown>;
        callback: (observer: Subscriber<unknown>, data: unknown) => void;
    }> = [];

    private options = {
        forceNew: false,
        path: 'socket.io',
        reconnection: true,
        rememberUpgrade: false,
        transports: ['websocket'],
        upgrade: true,
        timeout: 120000,
        reconnectionAttempts: 5,
        reconnectionDelay: 5000,
    };

    private connecting = false;

    public socket: Socket;

    public messages$: Subject<unknown> = new Subject<unknown>();
    public connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly loggerService: LoggerService,
        private readonly store: Store<{ directMessages: DirectMessagesState }>,
    ) {}

    private normalizePort(): void {
        const port = parseInt(this.hostPort as string, 10);

        if (port >= 0) {
            this.hostPort = port;
        }
    }

    private setSocketIoPath(): void {
        const hostUrl: URL = new URL(`${location.origin}${this.hostAddress}`);

        if (hostUrl.pathname.length > 0) {
            this.options.path = `${hostUrl.pathname}/socket.io`;
        }
    }

    public connect(hostAddress?: string, hostPort?: number | string): Subject<unknown> {
        if ((!this.socket || !this.socket.connected) && !this.connecting) {
            this.connecting = true;
            if (hostAddress) {
                this.hostAddress = hostAddress;
            }

            if (hostPort) {
                this.hostPort = hostPort;
            }

            try {
                this.normalizePort();

                this.setSocketIoPath();

                this.socket = io(`${this.hostAddress}`, this.options);

                this.socket.on('connect', () => {
                    this.loggerService.log('[Direct-Messaging] Successfully connected to the websocket service');
                    this.connected$.next(true);
                    this.store.dispatch(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCount());
                    this.store.dispatch(DirectMessagesLoadConversationsActions.loadConversations());
                    this.processMessageBuffer();
                    this.processListenerBuffer();
                    this.connecting = false;
                });

                this.socket.on('disconnect', () => {
                    this.loggerService.warning('[Direct-Messaging] Disconnected from the websocket service');
                    this.messages$.error(new SocketDisconnectException());
                    this.connected$.next(false);
                    this.connecting = false;
                });

                this.socket.on('reconnect', () => {
                    this.loggerService.warning('[Direct-Messaging] Reconnected to the websocket service');
                    this.connected$.next(true);
                    this.store.dispatch(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCount());
                    this.store.dispatch(DirectMessagesLoadConversationsActions.loadConversations());
                    this.store.dispatch(
                        DirectMessagesLoadConversationActions.unloadConversation({ clearStorage: false }),
                    );
                    this.processMessageBuffer();
                    this.processListenerBuffer();
                    this.messages$.error(new SocketReconnectException());
                    this.connecting = false;
                });

                this.socket.on('exceptions', (data: unknown) => {
                    const exception: SocketErrorException = new SocketErrorException();
                    exception.stack = JSON.stringify(data);
                    this.messages$.error(exception);
                    this.loggerService.error(
                        `[Direct-Messaging] Encountered a websocket exception: ${exception.stack}`,
                    );
                });

                this.listenOnceTo(SocketIOServerEvent.SET_USER_ID);

                this.socket.on('error', (error: string | Record<string, unknown>) => {
                    const exception: SocketErrorException = new SocketErrorException();
                    exception.stack = typeof error === 'object' ? JSON.stringify(error) : error;
                    this.messages$.error(exception);
                    this.loggerService.error(`[Direct-Messaging] Encountered a websocket error: ${exception.stack}`);
                });

                this.socket.on('connect_error', (error: string | Error) => {
                    const exception: SocketConnectException = new SocketConnectException();
                    exception.stack = typeof error === 'object' ? JSON.stringify(error) : error;
                    this.messages$.error(exception);
                    this.loggerService.error(
                        `[Direct-Messaging] Encountered a websocket connection exception: ${exception.stack}`,
                    );
                    this.connecting = false;
                });

                this.socket.on('connect_timeout', (error: string | Error) => {
                    const exception: SocketTimeoutException = new SocketTimeoutException();
                    exception.stack = typeof error === 'object' ? JSON.stringify(error) : error;
                    this.messages$.error(exception);
                    this.loggerService.error(`[Direct-Messaging] Websocket timed out: ${exception.stack}`);
                    this.connecting = false;
                });
            } catch (e) {
                this.connecting = false;
                this.loggerService.error(
                    `[Direct-Messaging] Unable to connect to ${this.hostAddress}:${this.hostPort}`,
                    e,
                );
            }
        }

        return this.messages$;
    }

    private processMessageBuffer(): void {
        this.messageBuffer.forEach((bufferedMessage) => {
            this.socket.emit(bufferedMessage.channel, bufferedMessage.message, (data) => {
                bufferedMessage.callback(bufferedMessage.observer, data);
            });
        });
    }

    private processListenerBuffer(): void {
        this.listenerBuffer.forEach((bufferedListener) => {
            this.socket.on(bufferedListener.channel, (data) => {
                bufferedListener.callback(bufferedListener.observer, data);
            });
        });
    }

    public forceReconnect(): Subject<unknown> {
        this.loggerService.warning(`[Direct-Messaging] Forced to reconnect to websocket server`);
        this.disconnect();
        return this.connect();
    }

    public disconnect(): void {
        if (this.socket) {
            this.socket.disconnect();
        }
        this.messages$.complete();
    }

    public listenTo<T extends Record<string, unknown> = Record<string, unknown>>(
        channel: SocketIOEvent,
    ): Observable<T> {
        return new Observable<T>((observer) => {
            const callback = (observer: Subscriber<T>, data: T) => {
                observer.next(data);
            };
            if (this.connected$.getValue()) {
                this.socket.on(channel, (data: T) => {
                    callback(observer, data);
                });
            } else {
                this.listenerBuffer.push({ channel, observer, callback: callback.bind(this) });
            }
        });
    }

    public listenOnceTo<T extends Record<string, unknown> = Record<string, unknown>>(
        channel: SocketIOEvent,
    ): Observable<T> {
        return new Observable<T>((observer) => {
            this.socket.once(channel, (data: T) => {
                this.messages$.next({ channel, data });
                observer.next(data);
            });
        });
    }

    public stopListening(channel: SocketIOEvent): void {
        this.socket.off(channel, () => null);
    }

    public emit<T>(
        channel: SocketIOEvent,
        message: Record<string, unknown> = {},
    ): Observable<DirectMessageServerResponse<T>> {
        return new Observable<DirectMessageServerResponse<T>>((observer) => {
            const callback = (observer: Subscriber<DirectMessageServerResponse<T>>, data: string) => {
                let jsonData;
                try {
                    jsonData = JSON.parse(data);
                } catch {
                    jsonData = data;
                    this.loggerService.warning(`Received non-parsable JSON: ${data}`);
                }
                this.messages$.next({ channel, data: jsonData });
                observer.next(jsonData);
            };
            if (this.connected$.getValue()) {
                this.socket.emit(channel, message, (data: string) => {
                    callback(observer, data);
                });
            } else {
                this.messageBuffer.push({ channel, message, observer, callback: callback.bind(this) });
            }
        });
    }
}
