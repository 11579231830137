import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-job-x-account-tile-locations-header',
    templateUrl: './job-x-account-tile-locations-header.component.html',
    styleUrls: ['./job-x-account-tile-locations-header.component.scss'],
    host: {
        class: 'job-x-account-tile__locations-header',
    },
})
export class JobXAccountTileLocationsHeaderComponent implements OnChanges {
    @Input() locations: Array<string>;

    public visibleLocations: Array<AccountListItemTag> = [];
    public extraLocations: Array<AccountListItemTag> = [];
    public locationTags: Array<AccountListItemTag> = [];

    get showBadge(): boolean {
        return this.extraLocations.length > 0;
    }

    get badgeText(): string {
        return `+${this.extraLocations.length}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.visibleLocations =
            this.locations?.length > 0
                ? [
                      {
                          name: this.locations[0],
                          matched: false,
                      },
                  ]
                : [];
        this.extraLocations =
            this.locations?.slice(1, this.locations.length).map((location) => {
                return {
                    name: location,
                    matched: false,
                };
            }) || [];
    }
}
