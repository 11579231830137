import { createAction, props } from '@ngrx/store';
import { ExpertProfile, ExpertSuggestedSkills } from '../expert-api/expert.types';
import { Skill } from '@tploy-enterprise/tenant-common';

export const ExpertActions = {
    load: createAction('[Expert] load'),
    loadSuccess: createAction('[Expert][Success] Load', props<{ expertData: ExpertProfile }>()),
    loadError: createAction('[Expert][Error] Load', props<{ error: Error }>()),
    edit: createAction('[Topic Expert] Edit', props<{ expertData: Partial<ExpertProfile> }>()),
    cancelEdit: createAction('[Topic Expert edit] Cancel edit'),
    resetEditState: createAction('[Topic Expert] Reset edit', props<{ expertData }>()),
    save: createAction('[Topic Expert edit] Save'),
    saveSuccess: createAction('[Topic Expert edit][Success] Save', props<{ expertData: ExpertProfile }>()),
    saveError: createAction('[Topic Expert edit][Error] Save', props<{ error: Error; expertData: ExpertProfile }>()),

    loadSuggestedSkills: createAction('[Expert] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[Expert][Success] Load Suggested Skills',
        props<{ suggestedSkills: ExpertSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[Expert][Error] Load Suggested Skills', props<{ error: Error }>()),
    syncSuccessFactorsSkills: createAction(
        '[Expert SuccessFactors SyncSkills][Success] sync',
        props<{ skills: Skill[] }>(),
    ),
    syncProfileSkills: createAction('[Expert] Sync profile skills'),
};
