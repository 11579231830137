import { combineReducers, createReducer, createSelector, on } from '@ngrx/store';
import { WorkshopParticipantsActions } from './workshop-participants.action';
import { RegistrationWithUserSkills, Workshop } from '../models/workshop.types';
import { TopicWorkshopState } from './topic-workshop.reducer';

export interface WorkshopParticipants extends Workshop {
    registrations: RegistrationWithUserSkills[];
    notifications: RegistrationWithUserSkills[];
}

export interface WorkshopParticipantsState {
    status: StatusState;
    workshop: WorkshopParticipants;
}

interface StatusState {
    loading: boolean;
    loaded: boolean;
    error: Error;
}

const initialWorkshopParticipantsStatusState: StatusState = {
    loading: false,
    loaded: false,
    error: null,
};

const workshopParticipantsStatusReducer = createReducer(
    initialWorkshopParticipantsStatusState,
    on(WorkshopParticipantsActions.load, (state) => ({ ...state, loading: true })),
    on(WorkshopParticipantsActions.loadError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
    on(WorkshopParticipantsActions.loadSuccess, (state) => ({ ...state, error: null, loading: false, loaded: true })),
    on(WorkshopParticipantsActions.unload, (state) => ({ ...state, error: null, loading: false, loaded: false })),
);

const workshopParticipantsReducer = createReducer(
    null,
    on(WorkshopParticipantsActions.loadSuccess, (state, { data }) => data),
    on(WorkshopParticipantsActions.unload, () => null),
);

export const workshopParticipantsStateReducer = combineReducers({
    status: workshopParticipantsStatusReducer,
    workshop: workshopParticipantsReducer,
});

const getWorkshopParticipant = (state: WorkshopParticipantsState): WorkshopParticipants => {
    return state.workshop;
};
const selectWorkshopParticipantsState = (state: TopicWorkshopState): WorkshopParticipantsState => {
    return state.workshopParticipants;
};
export const selectWorkshopParticipants = createSelector(selectWorkshopParticipantsState, getWorkshopParticipant);
