<tp-profile-view-fieldset *ngIf="hasContent()">
    <tp-profile-view-string-list-field
        *ngIf="hasInterestSkills()"
        key="MENTORING_PROFILE_VIEW_INTERESTED_IN_SKILLS"
        [value]="skillsInterestedIn"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-string-list-field
        *ngIf="hasOfferingSkills()"
        key="MENTORING_PROFILE_VIEW_OFFERED_SKILLS"
        [value]="skillsOffering"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        *ngIf="hasCommunicationTypeOffering()"
        [value]="communicationTypeOfferingKey"
        key="MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_OFFERING"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-string-list-field
        *ngIf="hasCampaignsOffering()"
        key="MENTORING_PROFILE_VIEW_CAMPAIGNS_OFFERING"
        [value]="campaignsOffering"
        [separateByComma]="true"
    >
    </tp-profile-view-string-list-field>
    <tp-profile-view-text-field
        *ngIf="hasCommunicationTypeInterest()"
        [value]="communicationTypeInterestKey"
        key="MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_INTEREST"
    >
    </tp-profile-view-text-field>
    <tp-profile-view-string-list-field
        *ngIf="hasCampaignsInterest()"
        key="MENTORING_PROFILE_VIEW_CAMPAIGNS_INTEREST"
        [value]="campaignsInterest"
        [separateByComma]="true"
    >
    </tp-profile-view-string-list-field>
</tp-profile-view-fieldset>
