import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Bookmark, GeneralData, TopicUserCardView } from '@tploy-enterprise/tenant-core';
import { MentoringContext, MentoringProfile } from '../../mentoring-service/mentoring.types';
import { MentoringSkillsService } from '../../mentoring-service/mentoring-skills.service';

@Component({
    selector: 'tp-mentoring-user-card-view',
    templateUrl: './mentoring-user-card-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentoringUserCardViewComponent implements TopicUserCardView {
    @Input() topicProfileData: MentoringProfile;
    @Input() bookmark: Bookmark;
    @Input() generalData: GeneralData;

    constructor(private readonly mentoringSkillsService: MentoringSkillsService) {}

    get user(): Partial<GeneralData> {
        return this.generalData;
    }
    get tags(): string[] {
        return this.mentoringSkillsService.getProfileSkills(
            this.topicProfileData,
            this.bookmark.context as MentoringContext,
        );
    }
    get selectedTags(): string[] {
        return [];
    }
    get context(): string {
        return this.bookmark.context;
    }
}
