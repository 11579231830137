import { Injectable } from '@angular/core';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';
import {
    MentoringProfileDTO,
    MentoringProfileMatchDTO,
    MentoringSearchResultDTO,
} from '../mentoring-api/mentoring-dtos';
import {
    MentoringBookmark,
    MentoringMatch,
    MentoringProfile,
    MentoringSearchResult,
    OthersMentoringProfile,
} from './mentoring.types';
import { Bookmark, GeneralData, INTEREST_KEY, LocationSerializer, OFFERING_KEY } from '@tploy-enterprise/tenant-core';
import { MentoringType } from '@tandemploy/common';

@Injectable({
    providedIn: 'root',
})
export class MentoringSerializer {
    constructor(
        private readonly skillsSerializer: SkillsSerializer,
        private readonly locationSerializer: LocationSerializer,
    ) {}

    deserializeMentoringProfile(dto: MentoringProfileDTO): MentoringProfile {
        dto.skillsInterestedIn.sort((a, b) => b.position - a.position);
        dto.skillsOffering.sort((a, b) => b.position - a.position);
        return {
            motivations: dto.motivations,
            communicationTypeInterest: dto.communicationTypeInterest,
            motivationInterest: dto.motivationInterest,
            skillsInterestedIn: dto.skillsInterestedIn.map((s) => s.name),
            campaignsInterest: dto.campaignsInterest,
            communicationTypeOffering: dto.communicationTypeOffering,
            motivationOffering: dto.motivationOffering,
            skillsOffering: dto.skillsOffering.map((s) => s.name),
            campaignsOffering: dto.campaignsOffering,
            isMentor: dto.motivations?.includes(OFFERING_KEY),
            isMentee: dto.motivations?.includes(INTEREST_KEY),
        };
    }

    deserializeOthersMentoringProfile(dto: MentoringProfileDTO): OthersMentoringProfile {
        return {
            user: {
                ...dto.user,
                locations: dto.user.locations?.map((l) => l.name),
            },
            ...this.deserializeMentoringProfile(dto),
        };
    }

    serializeMentoringProfile(profile: MentoringProfile, user: GeneralData): MentoringProfileDTO {
        return {
            user: {
                id: user.id,
                name: user.name,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            motivations: profile.motivations,
            communicationTypeInterest: profile.communicationTypeInterest,
            motivationInterest: profile.motivationInterest,
            skillsInterestedIn: this.skillsSerializer.serialize(profile.skillsInterestedIn),
            campaignsInterest: profile.campaignsInterest,
            communicationTypeOffering: profile.communicationTypeOffering,
            motivationOffering: profile.motivationOffering,
            skillsOffering: this.skillsSerializer.serialize(profile.skillsOffering),
            campaignsOffering: profile.campaignsOffering,
        };
    }

    deserializeMatch(dto: MentoringProfileMatchDTO): MentoringMatch {
        return {
            userId: dto.profile.user.id,
            mentor: dto.types.includes(MentoringType.MENTOR),
            mentee: dto.types.includes(MentoringType.MENTEE),
            highlight: {
                languages: [...(dto.highlight.languages ?? []), ...(dto.highlightReverse.languages ?? [])],
                skills: [...(dto.highlight.skills ?? []), ...(dto.highlightReverse.skills ?? [])],
                campaigns: dto.highlight.campaigns,
            },
            profile: this.deserializeOthersMentoringProfile(dto.profile),
        };
    }

    deserializeBookmark(bookmark: Bookmark): MentoringBookmark {
        return { ...bookmark };
    }

    deserializeSearchResult(dto: MentoringSearchResultDTO): MentoringSearchResult {
        return {
            userId: dto.profile.user.id,
            mentor: dto.types.includes(MentoringType.MENTOR),
            mentee: dto.types.includes(MentoringType.MENTEE),
            highlight: {
                ...dto.highlight,
                skills: [
                    ...(dto.highlight?.skillsOffering?.map((skill) => skill.name) ?? []),
                    ...(dto.highlight?.skillsInterestedIn?.map((skill) => skill.name) ?? []),
                ],
            },
            profile: this.deserializeOthersMentoringProfile(dto.profile),
        };
    }
}
