<tp-job-x-tandem-check-col>
    {{ title | translatable }}
</tp-job-x-tandem-check-col>

<tp-job-x-tandem-check-col [innerHTML]="ownProfileValue" *ngIf="ownProfileValue; else ownWrapperOnly">
</tp-job-x-tandem-check-col>

<ng-template #ownWrapperOnly>
    <tp-job-x-tandem-check-col>
        <ng-content select="[own-profile-value]"></ng-content>
    </tp-job-x-tandem-check-col>
</ng-template>

<tp-job-x-tandem-check-col [innerHTML]="targetProfileValue" *ngIf="targetProfileValue; else targetWrapperOnly">
</tp-job-x-tandem-check-col>

<ng-template #targetWrapperOnly>
    <tp-job-x-tandem-check-col>
        <ng-content select="[target-profile-value]"></ng-content>
    </tp-job-x-tandem-check-col>
</ng-template>
