<header class="card__tags-header">{{ title }}</header>
<mat-icon *ngIf="icon" class="card__tags-icon" [svgIcon]="icon"></mat-icon>
<ng-template #tagView let-tag>
    <div class="card__tags-tag" [class.card__tags-tag--matched]="tag.matched">{{ tag.name }}</div>
    <div *ngIf="withLevel" class="card__tags-tag card__tags-tag--level">
        ({{ getLevelLabel(tag.level) | translatable }})
    </div>
    <div class="card__tags-middot"></div>
</ng-template>
<ng-container
    *ngFor="let tag of tags"
    [ngTemplateOutlet]="tagView"
    [ngTemplateOutletContext]="{ $implicit: tag }"
></ng-container>
