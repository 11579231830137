import { Injectable } from '@angular/core';

@Injectable()
export class WindowRef {
    private static window(): Window {
        return window;
    }

    get nativeWindow(): Window {
        return WindowRef.window();
    }

    static open(url: string, target?: string): void {
        WindowRef.window().open(url, target);
    }
}
