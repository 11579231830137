import { ErrorHandler, Injectable } from '@angular/core';
import { AccountState, isAccountSyncCompleted, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import {
    isProjectsProfileInDirtyState,
    isProjectsProfileLoaded,
    isProjectsProfileLoadedOrLoading,
    selectCurrentProjectsProfile,
} from './projects.selectors';
import { ProjectsActions } from './projects.actions';
import { ProjectsProfile } from './projects.types';
import { ProjectsService } from './projects.service';
import { ProjectsOthersProfileNotFoundException, ProjectsUnexpectedException } from './projects.exceptions';
import { ProjectsState } from './projects.reducer';

@Injectable({
    providedIn: 'root',
})
export class ProjectsTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ projects: ProjectsState; account: AccountState }>,
        private readonly projectsService: ProjectsService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(ProjectsActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<ProjectsProfile> {
        this.store
            .select(isProjectsProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(ProjectsActions.loadProfile());
                }
            });

        return this.store.select(isProjectsProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentProjectsProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        return this.projectsService.loadProfileById(id).pipe(
            catchError((error) => {
                if (
                    error instanceof ProjectsOthersProfileNotFoundException ||
                    error instanceof ProjectsUnexpectedException
                ) {
                    this.errorHandler.handleError(error);
                }
                return of(null);
            }),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isProjectsProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }

    onProfileSync() {
        this.store.dispatch(ProjectsActions.syncProfileSkills());
        return this.waitOnSync();
    }

    private waitOnSync(): Observable<void> {
        return this.store.select(isAccountSyncCompleted).pipe(
            filter((loaded) => !loaded),
            map(() => undefined),
            take(1),
        );
    }
}
