import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[tpProfileViewTextButton]',
    host: {
        class: 'profile-view__interaction profile-view__text-button',
    },
})
export class ProfileViewTextButtonDirective {
    @Input()
    disabled = true;

    @Input()
    @HostBinding('class.profile-view__text-button--uppercase')
    uppercase = false;
}
