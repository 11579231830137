import { Component, Input } from '@angular/core';
import { Bookmark, GeneralData, TopicUserCardView } from '@tploy-enterprise/tenant-core';
import { JobXBookmark, JobXProfile } from '../../jobs.types';

@Component({
    selector: 'tp-job-xuser-card-view',
    templateUrl: './job-xuser-card-view.component.html',
})
export class JobXUserCardViewComponent implements TopicUserCardView {
    @Input() topicProfileData: JobXProfile;
    @Input() bookmark: Bookmark;
    @Input() generalData: GeneralData;

    get context(): string {
        return this.bookmark?.context;
    }

    get mappedBookmark(): JobXBookmark {
        return {
            bookmark: this.bookmark,
            profile: this.topicProfileData,
        } as JobXBookmark;
    }
}
