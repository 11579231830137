import { NgModule } from '@angular/core';
import { NLAProfileNavigatorViewComponent } from './nla-profile-navigator-view.component';
import { CommonModule } from '@angular/common';
import { LanguageModule, ProfileContentModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [NLAProfileNavigatorViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class NLAProfileNavigatorViewModule {}
