import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerationExchangeUserCardViewComponent } from './generation-exchange-user-card-view.component';
import { GenerationExchangeListItemModule } from '../../generation-exchange-domain/common/generation-exchange-list-item/generation-exchange-list-item.module';

@NgModule({
    declarations: [GenerationExchangeUserCardViewComponent],
    imports: [CommonModule, GenerationExchangeListItemModule],
})
export class GenerationExchangeUserCardViewModule {}
