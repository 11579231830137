import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountActions } from '../account.actions';

@Injectable({
    providedIn: 'root',
})
export class SsoDataResolver implements Resolve<any> {
    constructor(protected store: Store<any>) {}
    resolve(): Observable<any> {
        this.store.dispatch(AccountActions.loadSsoData());
        return null;
    }
}
