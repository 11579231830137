import { ContactSearchResult } from '../types/search.types';

export interface SearchState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: ContactSearchResult[];
}

export const directMessagesSearchInitialState: SearchState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
};
