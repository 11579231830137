<div>
    <tp-common-loader
        *ngIf="prevButtonClicked && (showLoader || isProfilesLoading)"
        [size]="'small'"
        class="float-left"
    ></tp-common-loader>
    <tp-profile-button-navigation
        *ngIf="prev && prev.name"
        [direction]="'LEFT'"
        [avatar]="prev.avatar"
        [hideAvatar]="false"
        [name]="prev.name"
        [caption]="'FOOTER_NAVIGATION_PREV_BUTTON_CAPTION' | translatable"
        [attr.aria-label]="'FOOTER_NAVIGATION_PREV_BTN_ARIA_LABEL' | translatable : { userName: prev.name }"
        (click)="goPrev()"
        (keydown.enter)="goPrev()"
        tabindex="0"
        role="button"
    ></tp-profile-button-navigation>
</div>
<div>
    <tp-common-loader
        *ngIf="nextButtonClicked && (showLoader || isProfilesLoading)"
        [size]="'small'"
        class="float-right"
    ></tp-common-loader>
    <tp-profile-button-navigation
        *ngIf="next && next.name"
        [direction]="'RIGHT'"
        [avatar]="next.avatar"
        [hideAvatar]="false"
        [name]="next.name"
        [caption]="'FOOTER_NAVIGATION_NEXT_BUTTON_CAPTION' | translatable"
        [attr.aria-label]="'FOOTER_NAVIGATION_NEXT_BTN_ARIA_LABEL' | translatable : { userName: next.name }"
        (click)="goNext()"
        (keydown.enter)="goNext()"
        tabindex="0"
        role="button"
    ></tp-profile-button-navigation>
</div>
