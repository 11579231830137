import { NgModule, ErrorHandler } from '@angular/core';
import {} from '@sentry/browser';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { CriticalErrorSnackComponent } from './critical-error-snack.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CriticalHttpExceptionsInterceptor } from './critical-http-exceptions.interceptor';
import { NotificationsModule } from '@tandemploy/ngx-components';

@NgModule({
    providers: [
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: CriticalHttpExceptionsInterceptor,
        },
    ],
    declarations: [CriticalErrorSnackComponent],
    imports: [LanguageModule, NotificationsModule],
})
export class ErrorHandlerModule {}
