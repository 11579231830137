export enum QueryParamKey {
    Q = 'q',
    CONTEXT = 'context',
    PAGE_INDEX = 'pageIndex',
    PAGE_SIZE = 'pageSize',
}
export interface UrlQueryParams {
    q: string;
    context: string;
    pageIndex: number;
    pageSize: number;
}

export const QUERY_DELIMITER = ',';
