import { ErrorHandler, Injectable } from '@angular/core';
import { AccountState, isAccountSyncCompleted, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import {
    isSTAProfileInDirtyState,
    isSTAProfileLoaded,
    isSTAProfileLoadedOrLoading,
    selectCurrentSTAProfile,
} from './sta.selectors';
import { STAActions } from './sta.actions';
import { STAProfile } from './sta.types';
import { STAService } from './sta.service';
import { StaOthersProfileNotFoundException, StaUnexpectedException } from './sta.exceptions';
import { STAState } from './sta.reducer';

@Injectable({
    providedIn: 'root',
})
export class StaTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ shortTimeAssignments: STAState; account: AccountState }>,
        private readonly staService: STAService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(STAActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<STAProfile> {
        this.store
            .select(isSTAProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(STAActions.loadProfile());
                }
            });

        return this.store.select(isSTAProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentSTAProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        return this.staService.loadProfileById(id).pipe(
            catchError((error) => {
                if (error instanceof StaOthersProfileNotFoundException || error instanceof StaUnexpectedException) {
                    this.errorHandler.handleError(error);
                }
                return of(null);
            }),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isSTAProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }

    onProfileSync() {
        this.store.dispatch(STAActions.syncProfileSkills());
        return this.waitOnSync();
    }

    private waitOnSync(): Observable<void> {
        return this.store.select(isAccountSyncCompleted).pipe(
            filter((loaded) => !loaded),
            map(() => undefined),
            take(1),
        );
    }
}
