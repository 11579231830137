import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { STAProfile } from '../../sta.types';
import { Skill } from '@tploy-enterprise/tenant-common';

@Component({
    selector: 'tp-sta-profile-view',
    templateUrl: './sta-profile-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaProfileViewComponent implements TopicProfileView {
    @Input()
    topicData: STAProfile;

    get skills(): Skill[] {
        const sorted = [...this.topicData.skills];
        sorted.sort((a, b) => b.level - a.level);
        return sorted;
    }

    get campaigns(): string[] {
        return this.topicData.campaigns;
    }

    get interestInCategories(): string[] {
        return this.topicData.categoriesInterestedIn.map((category) => `STA_PROFILE_CATEGORY_${category}`);
    }

    get interestedInSkills(): string[] {
        return this.topicData.skillsInterestedIn;
    }

    hasSkills(): boolean {
        return this.topicData && this.topicData.skills && this.topicData.skills.length > 0;
    }

    hasCategories(): boolean {
        return (
            this.topicData && this.topicData.categoriesInterestedIn && this.topicData.categoriesInterestedIn.length > 0
        );
    }

    hasCampaigns(): boolean {
        return this.topicData?.campaigns?.length > 0;
    }

    hasInterestSkills(): boolean {
        return this.topicData && this.topicData.skillsInterestedIn && this.topicData.skillsInterestedIn.length > 0;
    }

    hasContent() {
        return this.hasSkills() || this.hasInterestSkills() || this.hasCategories();
    }
}
