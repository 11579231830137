import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TopicWorkshopState } from './topic-workshop.reducer';
import { AccountService, AccountState } from '@tploy-enterprise/tenant-core';
import { WorkshopService } from '../services/workshop.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Workshop } from '../models/workshop.types';
import { of } from 'rxjs';
import { MyWorkshopsActions } from './my-workshops.actions';
import { MyWorkshop } from './my-workshops.reducer';

@Injectable({
    providedIn: 'root',
})
export class MyWorkshopsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{ topicWorkshop: TopicWorkshopState; account: AccountState }>,
        private readonly service: WorkshopService,
        private readonly accountService: AccountService,
    ) {}

    loadMyRegisteredWorkshops$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyWorkshopsActions.loadRegistered),
            switchMap(() =>
                this.service.loadMyRegisteredWorkshops().pipe(
                    map((data: Workshop[]) => MyWorkshopsActions.loadRegisteredSuccess({ data })),
                    catchError((error: Error) => of(MyWorkshopsActions.loadRegisteredError({ error }))),
                ),
            ),
        ),
    );
    loadMyRequestedWorkshops$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyWorkshopsActions.loadRequested),
            switchMap(() =>
                this.service.loadMyRequestedWorkshops().pipe(
                    map((data: MyWorkshop[]) => MyWorkshopsActions.loadRequestedSuccess({ data })),
                    catchError((error: Error) => of(MyWorkshopsActions.loadRequestedError({ error }))),
                ),
            ),
        ),
    );
}
