import { combineReducers, createReducer, on } from '@ngrx/store';
import { JobsActions } from './jobs.actions';
import {
    AccountActions,
    SuccessFactorsActions,
    SuccessFactorsUtilsService,
    BookmarkActions,
    PageParams,
} from '@tploy-enterprise/tenant-core';
import {
    JobXBookmark,
    JobXEntity,
    JobXLeadershipPositionWish,
    JobXProfile,
    JobXProfileMatch,
    JobXProfileSearchResult,
    JobXSearchResult,
    JobXSuggestedSkills,
} from './jobs.types';

const successFactorsUtilsService = new SuccessFactorsUtilsService();

export type JobXState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    profile: JobXProfileState;
    othersProfile: JobXOthersProfileState;
    searchResults: JobXSearchState;
    offeredByMe: JobXOfferedByMeState;
    matches: JobXMatchesState;
    edition: JobXEditionState;
    creation: JobXCreationState;
    deletion: JobXDeletionState;
    publish: JobXPublishState;
    searchProfiles: JobXSearchProfilesState;
    bookmarks: JobXBookmarksState;
    suggestedSkills: SuggestedSkillsState;
};

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: JobXSuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type JobXProfileState = {
    status: StateStatus;
    saved: JobXProfile;
    current: JobXProfile;
};

export type JobXOthersProfileState = {
    loading: boolean;
};

export type JobXSearchState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: JobXSearchResult[];
    pageParams: PageParams;
    searchQuery: string;
};

export type JobXOfferedByMeState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    entities: JobXEntity[];
};

export type JobXMatchesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    allEntities: Array<JobXProfileMatch>;
    entities: Array<JobXProfileMatch>;
    pageParams: PageParams;
    selectedCampaigns: string[];
};

export type JobXTransactionalState = {
    jobXEntity: JobXEntity;
    savingError: Error;
    saving: boolean;
};

export type JobXBookmarksState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    data: Array<JobXBookmark>;
};

export type JobXEditionState = JobXTransactionalState;

export type JobXCreationState = JobXTransactionalState;

export type JobXPublishState = JobXTransactionalState & { publishing: boolean };

export type JobXDeletionState = {
    entityId: string;
    deletion: boolean;
    deletionError: Error;
};

export type JobXSearchProfilesState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    allResults: JobXProfileSearchResult[];
    results: JobXProfileSearchResult[];
    pageParams: PageParams;
    searchQuery: string;
};

export const JobXProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(JobsActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(JobsActions.loadProfileSuccess, (state) => ({ ...state, loading: false, loaded: true, loadingError: null })),
    on(JobsActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(JobsActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(JobsActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(JobsActions.saveProfileError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(JobsActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
);

export const JobXProfileSavedReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        locations: [],
        mainMotivation: '',
        currentlyInLeadershipPosition: false,
        wantsToBeInLeadershipPosition: JobXLeadershipPositionWish.LEADERSHIP_POSITION_FLEXIBLE,
        splitHours: {
            myHours: 20,
            tandemHours: 20,
        },
        currentJobLikes: '',
        currentJobDescription: '',
    },
    on(JobsActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(JobsActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const JobXProfileCurrentReducer = createReducer(
    {
        motivations: [],
        skills: [],
        skillsInterestedIn: [],
        locations: [],
        mainMotivation: '',
        currentlyInLeadershipPosition: false,
        wantsToBeInLeadershipPosition: JobXLeadershipPositionWish.LEADERSHIP_POSITION_FLEXIBLE,
        splitHours: {
            myHours: 20,
            tandemHours: 20,
        },
        currentJobLikes: '',
        currentJobDescription: '',
    },
    on(JobsActions.editCurrentProfile, (state, { currentProfile }) => {
        return {
            ...state,
            ...currentProfile,
            skills: currentProfile.skills.map((skill) => (skill?.name ? skill : { name: skill })),
        };
    }),
    on(JobsActions.loadProfileSuccess, (state, { profile, session }) => session || profile),
    on(JobsActions.loadProfileError, (state, { session }) => session || state),
    on(JobsActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        skills:
            state.skills.length > 0
                ? state.skills
                : account.generalData.externalSkills
                ? account.generalData.externalSkills.map((skill) => ({ name: skill, level: 1 }))
                : [],
    })),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => {
        if (state.locations?.length > 0) {
            return {
                ...state,
                skills: state.skills,
            };
        } else {
            return {
                ...state,
                skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.skills, ...skills]),
            };
        }
    }),
    on(JobsActions.syncSuccessFactorsSkills, (state, { skills }) => ({
        ...state,
        skills: successFactorsUtilsService.getSuccessFactorsSkills([...state.skills, ...skills]),
    })),
);

export const JobXProfileReducer = combineReducers({
    status: JobXProfileStatusReducer,
    saved: JobXProfileSavedReducer,
    current: JobXProfileCurrentReducer,
});

export const JobXSearchReducer = createReducer<JobXSearchState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        searchQuery: '',
    },
    on(JobsActions.searchEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(JobsActions.searchEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            pageParams,
        };
    }),
    on(JobsActions.searchEntitiesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const JobXOfferedByMeReducer = createReducer<JobXOfferedByMeState>(
    { loaded: false, loading: false, entities: [], loadingError: null },
    on(JobsActions.loadEntitiesOfferedByMe, (state) => {
        return { ...state, loading: true };
    }),
    on(JobsActions.loadEntitiesOfferedByMeSuccess, (state, { entities }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            entities: [...entities],
        };
    }),
    on(JobsActions.loadEntitiesOfferedByMeError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
    on(
        JobsActions.saveEntitySuccess,
        JobsActions.publishEntitySuccess,
        JobsActions.createEntitySuccess,
        (state, { entity }) => {
            const existingJobIndex = state.entities.findIndex((job) => job.id === entity.id);
            // create new project
            if (existingJobIndex === -1) {
                return {
                    ...state,
                    entities: [...state.entities, entity],
                };
            }

            // update existing project
            const existingJobs = state.entities[existingJobIndex];
            const newJob = [...state.entities];
            newJob.splice(existingJobIndex, 1, { ...existingJobs, ...entity });
            return { ...state, entities: newJob };
        },
    ),
    on(JobsActions.deleteEntitySuccess, (state, { entityId }) => {
        return {
            ...state,
            entities: state.entities.filter((entity) => entity.id !== entityId),
        };
    }),
);

const JobXMatchesReducer = createReducer<JobXMatchesState>(
    {
        loaded: false,
        loading: false,
        entities: [],
        allEntities: [],
        loadingError: null,
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
        selectedCampaigns: [],
    },
    on(JobsActions.loadMatches, (state) => {
        return { ...state, loading: true, loaded: false, loadingError: null };
    }),
    on(JobsActions.saveProfileSuccess, (state, { currentProfile }) => {
        return {
            ...state,
            selectedCampaigns: [],
        };
    }),
    on(JobsActions.loadMatchesSuccess, (state, { entities, allEntities, pageParams, selectedCampaigns }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            entities: [...entities],
            allEntities: [...allEntities],
            pageParams,
            selectedCampaigns: selectedCampaigns || [],
        };
    }),
    on(JobsActions.loadMatchResultSuccess, (state, { data, index }) => {
        state.allEntities[index] = data;
        return {
            ...state,
            allEntities: [...state.allEntities],
        };
    }),
    on(JobsActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, loadingError: error };
    }),
);

const JobXCreationReducer = createReducer(
    { jobXEntity: null, savingError: null, saving: false },
    on(JobsActions.createEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(JobsActions.createEntitySuccess, (state, { entity }) => ({
        ...state,
        jobXEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(JobsActions.createEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(JobsActions.publishEntitySuccess, () => ({
        jobXEntity: null,
        savingError: null,
        saving: false,
    })),
);

const JobXEditionReducer = createReducer(
    { jobXEntity: null, savingError: null, saving: false },
    on(JobsActions.saveEntity, (state) => ({
        ...state,
        saving: true,
    })),
    on(JobsActions.saveEntitySuccess, (state, { entity }) => ({
        ...state,
        jobXEntity: entity,
        savingError: null,
        saving: false,
    })),
    on(JobsActions.saveEntityError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(JobsActions.publishEntitySuccess, () => ({
        jobXEntity: null,
        savingError: null,
        saving: false,
    })),
);

const JobXDeletionReducer = createReducer<JobXDeletionState>(
    { entityId: null, deletion: false, deletionError: null },
    on(JobsActions.deleteEntity, (state, { entityId }) => ({ ...state, entityId, deletion: true })),
    on(JobsActions.deleteEntitySuccess, (state, { entityId }) => ({ ...state, entityId, deletion: false })),
    on(JobsActions.deleteEntityError, (state, { error }) => ({ ...state, deletion: false, deletionError: error })),
);

const JobXPublishReducer = createReducer(
    { jobXEntity: null, publishError: null, publishing: false },
    on(JobsActions.publishEntity, (state) => ({ ...state, publishing: true })),
    on(JobsActions.publishEntitySuccess, (state, { entity }) => ({
        ...state,
        jobXEntity: entity,
        publishing: false,
    })),
    on(JobsActions.publishEntityError, (state, { error }) => ({
        ...state,
        publishError: error,
        publishing: false,
    })),
);

const JobXSearchProfilesInitialState: JobXSearchProfilesState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
    allResults: [],
    searchQuery: '',
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
};

const JobXSearchProfilesReducer = createReducer<JobXSearchProfilesState>(
    JobXSearchProfilesInitialState,
    on(JobsActions.searchProfiles, (state, { searchQuery }) => ({
        ...state,
        loading: true,
        loaded: false,
        loadingError: null,
        results: [],
        allResults: [],
        searchQuery,
    })),
    on(JobsActions.searchProfilesSuccess, (state, { profiles, allProfiles, pageParams }) => ({
        ...state,
        loading: false,
        loaded: true,
        results: profiles,
        allResults: allProfiles,
        pageParams,
    })),
    on(JobsActions.loadSearchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(JobsActions.searchProfilesError, (state, { error }) => ({
        ...state,
        loading: false,
        loadingError: error,
    })),
    on(JobsActions.resetSearchProfilesResults, () => ({
        ...JobXSearchProfilesInitialState,
    })),
);

const JobXBookmarksReducer = createReducer<JobXBookmarksState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        data: [],
    },
    on(JobsActions.loadBookmarks, (state) => ({ ...state, loading: true })),
    on(JobsActions.loadBookmarksError, (state, { error }) => ({ ...state, loadingError: error, loading: false })),
    on(JobsActions.loadBookmarksSuccess, (state, { data }) => ({
        ...state,
        loadingError: null,
        loading: false,
        loaded: true,
        data,
    })),
    on(BookmarkActions.addSuccess, (state, { bookmark }) => {
        return {
            ...state,
            loaded: false,
            data: [...state.data, bookmark],
        };
    }),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => {
        return {
            ...state,
            loaded: false,
            data: state.data.filter((existingBookmark) => {
                return existingBookmark.id !== bookmark.id;
            }),
        };
    }),
);

const JobXSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(JobsActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(JobsActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(JobsActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

const JobXOthersProfileReducer = createReducer(
    { loading: false },
    on(JobsActions.loadOthersProfile, (state) => ({ ...state, loading: true })),
    on(JobsActions.loadOthersProfileSuccess, (state) => ({ ...state, loading: false })),
    on(JobsActions.loadOthersProfileError, (state) => ({ ...state, loading: false })),
);

export const JobXReducer = combineReducers({
    profile: JobXProfileReducer,
    othersProfile: JobXOthersProfileReducer,
    searchResults: JobXSearchReducer,
    offeredByMe: JobXOfferedByMeReducer,
    matches: JobXMatchesReducer,
    edition: JobXEditionReducer,
    creation: JobXCreationReducer,
    deletion: JobXDeletionReducer,
    publish: JobXPublishReducer,
    searchProfiles: JobXSearchProfilesReducer,
    bookmarks: JobXBookmarksReducer,
    suggestedSkills: JobXSuggestedSkillsReducer,
});
