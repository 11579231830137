import {
    ApplicationRef,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Input,
    SimpleChanges,
    OnChanges,
    Injector,
    ComponentRef,
    ComponentFactoryResolver,
} from '@angular/core';
import { AnimationBuilder } from '@angular/animations';
import { SearchLoaderComponent } from './search-loader/search-loader.component';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { RequestHandlerService } from '../request-handler/request-handler.service';

@Directive({
    selector: '[tpLoader]',
    exportAs: 'loaderContainer',
})
export class LoaderDirective implements OnChanges {
    @Input() showLoader = false;
    @Input() showUntil = false;

    private isLoaderCouldBeShown = false;
    private isLoaderInited = false;

    private loaderPortalOutlet: DomPortalOutlet;
    private loaderPortal: ComponentPortal<SearchLoaderComponent> = null;
    private loaderRef: ComponentRef<SearchLoaderComponent>;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly animationBuilder: AnimationBuilder,
        private readonly injector: Injector,
        private readonly applicationRef: ApplicationRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private requestHandlerService: RequestHandlerService,
    ) {
        this.requestHandlerService.showSpinner$.subscribe((showSpinner) => {
            this.isLoaderCouldBeShown = showSpinner;
            this.changeDetectorRef.markForCheck();
            if (this.showUntil || (this.showLoader && this.isLoaderCouldBeShown)) {
                this.initLoader();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((!this.showLoader || !this.isLoaderCouldBeShown) && !this.showUntil && this.loaderRef) {
            this.loaderRef.instance.isVisible = false;
            this.loaderRef.instance.showAlways = false;
            const index = this.elementRef.nativeElement.innerHTML.indexOf('<tp-search-loader');
            if (~index) {
                this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.substr(0, index);
                this.isLoaderInited = false;
            }
            this.loaderRef.changeDetectorRef.markForCheck();
        }
    }

    initLoader(): void {
        if (!this.isLoaderInited) {
            this.isLoaderInited = true;

            const outletInjector = Injector.create({
                providers: [],
                parent: this.injector,
            });
            this.loaderPortal = new ComponentPortal(SearchLoaderComponent, null, outletInjector);

            this.loaderPortalOutlet = new DomPortalOutlet(
                this.elementRef.nativeElement.firstChild,
                this.componentFactoryResolver,
                this.applicationRef,
                this.injector,
            );

            this.loaderRef = this.loaderPortalOutlet.attachComponentPortal(this.loaderPortal);
            this.loaderRef.instance.isVisible = true;
            this.loaderRef.instance.showAlways = this.showUntil;
            this.loaderRef.changeDetectorRef.markForCheck();
        }
    }
}
