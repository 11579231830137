import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Bookmark, GeneralData, LocationService, NEVER_LUNCH_ALONE_TOPIC_NAME } from '@tploy-enterprise/tenant-core';
import { NLAMatch } from '../../nla-service/nla.types';
import { Observable } from 'rxjs';

@Component({
    selector: 'tp-nla-match',
    templateUrl: './nla-match.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLAMatchComponent implements OnChanges {
    @Input()
    match: NLAMatch;
    context = NEVER_LUNCH_ALONE_TOPIC_NAME;
    locations: string[];

    get user(): Partial<GeneralData> {
        return this.match.profile.user;
    }
    get bookmark(): Bookmark {
        return this.match.bookmark;
    }
    get tags(): string[] {
        return this.match.profile.interestedInTopics;
    }
    get selectedTags(): string[] {
        return this.match.highlight.interestedInTopics;
    }
    get locationIndependent(): boolean {
        return this.match.profile.locationIndependent;
    }
    get highlightLocations(): string[] {
        return this.match.highlight.locations;
    }
    get highlightLocationIndependent(): boolean {
        return this.match.highlight.isOnlineMatch;
    }

    constructor(private readonly locationService: LocationService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.match) {
            this.locations = this.locationService.getLocationsOrCountry(this.match.profile.companyLocations);
        }
    }
}
