import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TechWolfSkills } from '@tploy-enterprise/tenant-common';
import { ConfigService } from '../config';

const SERVICE_PATH = '/api/v2/techwolf-proxy';
const LIMIT_FOR_TECH_WOLF_SKILLS = 20;

@Injectable({
    providedIn: 'root',
})
export class TechWolfService {
    constructor(private readonly configService: ConfigService, private readonly http: HttpClient) {}

    loadTechWolfSuggestedSkills(skills: string[], skillType: string): Observable<TechWolfSkills> {
        if (!this.configService.remoteSettings.integrationTechwolf || !skills || skills.length === 0) {
            return new Observable((subscriber) => subscriber.next()).pipe(
                map(() => ({ aType: [], sType: [], lType: [] })),
            );
        } else {
            const requestsSkills = skills.slice(0, LIMIT_FOR_TECH_WOLF_SKILLS);
            return this.http.get<string[]>(`${SERVICE_PATH}/related?terms=${requestsSkills.join(',')}`).pipe(
                map((techWolfSkills) => {
                    const result = { aType: [], sType: [], lType: [] };
                    result[skillType] = techWolfSkills;
                    return result;
                }),
            );
        }
    }
}
