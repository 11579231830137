<tp-icon-button [icon]="'close'" class="job-x__tandem-check-close no-border" mat-dialog-close> </tp-icon-button>
<div mat-dialog-title class="job-x__tandem-check">
    <h1>{{ 'JOBX_TANDEM_CHECK_TITLE' | translatable }}</h1>
    <tp-primary-icon-button [icon]="'message'" (click)="messageTandem()">
        {{ 'JOBX_TANDEM_CHECK_GET_TO_KNOW' | translatable }}
    </tp-primary-icon-button>
</div>
<mat-dialog-content class="dialog-content">
    <tp-job-x-tandem-check-grid
        [ownProfile]="data.ownProfile"
        [targetProfile]="data.targetProfile"
        [ownLocations]="ownLocations"
        [targetLocations]="targetLocations"
    >
    </tp-job-x-tandem-check-grid>
</mat-dialog-content>
