import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from './logger.service';
import { SentryErrorHandler } from '../error-handler/sentry-error-handler.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private sentencesForWarningLogging: string[] | RegExp[] = [];
    private sentencesForInfoLogging: string[] | RegExp[] = [];
    private sentencesForIgnoreLogging: string[] | RegExp[] = ["URL Segment: 'sockjs-node/iframe.html'"];

    constructor(private readonly injector: Injector) {
        super();
    }

    public handleError(error) {
        const logService: LoggerService = this.injector.get(LoggerService);
        const sentryErrorHandler: SentryErrorHandler = this.injector.get(SentryErrorHandler);

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }

        sentryErrorHandler.handleError(error);

        const message = error.message ? error.message : error.toString();

        if (error.status) {
            error = new Error(message);
        }

        const errorTraceStr = `Error message:\n${message}.\nStack trace: ${error.stack}`;

        if (!this.checkLogLevelOrIfErrorShouldBeIgnored(errorTraceStr, this.sentencesForIgnoreLogging)) {
            if (this.checkLogLevelOrIfErrorShouldBeIgnored(errorTraceStr, this.sentencesForWarningLogging)) {
                logService.warning(errorTraceStr);
            } else if (this.checkLogLevelOrIfErrorShouldBeIgnored(errorTraceStr, this.sentencesForInfoLogging)) {
                logService.info(errorTraceStr);
            } else {
                logService.error(errorTraceStr);
            }
        }

        throw error;
    }

    private checkLogLevelOrIfErrorShouldBeIgnored(
        errorTraceStr: string,
        comparisonArray: string[] | RegExp[],
    ): boolean {
        return comparisonArray.some((whiteListSentence) => {
            if (typeof whiteListSentence === 'string') {
                return errorTraceStr.includes(whiteListSentence);
            }
            return whiteListSentence.test(errorTraceStr);
        });
    }
}
