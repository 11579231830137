import { Inject, Injectable, Injector } from '@angular/core';
import {
    Environment,
    Settings,
    TenantConfiguration,
    RemoteSettings,
    LocalTenantConfiguration,
    AppWindow,
} from '../../tenant-core.types';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LOCAL_TENANT_CONFIGURATION } from '../../tenant-core.di';
import { AuthenticationEndpoints } from '../authentication/constants';
import { default as enDefaultTranslations } from '../../../i18n/translate.en';
import { default as deDefaultTranslations } from '../../../i18n/translate.de';
import { default as deInformalDefaultTranslations } from '../../../i18n/translate.de_informal';
import { Cloud, LanguageConfiguration, LanguageOptions } from '@tploy-enterprise/tenant-common';
import { WindowRef } from '../window-ref';
import { TranslateService } from '@ngx-translate/core';

const SERVICE_PATH = '/api/v2/tenant-settings/configurations';

@Injectable()
export class ConfigService {
    public storedSettings: RemoteSettings;
    private refreshTokenSubject = new BehaviorSubject<null | boolean>(null);

    private get _translateService() {
        return this._injector.get(TranslateService);
    }

    isLoaded = new BehaviorSubject<boolean>(false);

    get remoteSettings(): RemoteSettings {
        return this.storedSettings;
    }

    set remoteSettings(settings: RemoteSettings) {
        this.storedSettings = settings;
    }

    get localSettings(): Settings {
        return this.localTenantConfiguration.localSettings;
    }

    get environment(): Environment {
        return this.localTenantConfiguration.environment;
    }

    get availableLocations(): Cloud {
        return Cloud.from(this.remoteSettings.availableLocations);
    }

    constructor(
        private readonly httpClient: HttpClient,
        private windowRef: WindowRef,
        private _injector: Injector,
        @Inject(LOCAL_TENANT_CONFIGURATION) readonly localTenantConfiguration: LocalTenantConfiguration,
    ) {}

    loadConfig(): Observable<void> {
        return this.refreshToken().pipe(
            switchMap((): Observable<void> => {
                return this.load();
            }),
            catchError((err): Observable<void> => {
                return this.load();
            }),
        );
    }

    private refreshToken(): Observable<boolean> {
        return this.httpClient
            .post(`${AuthenticationEndpoints.RefreshToken}`, {}, { responseType: 'text' })
            .pipe(map(() => true));
    }

    private load(): Observable<void> {
        this.refreshTokenSubject.next(true);
        const settingsAndEnvironment$ = {
            remoteSettings: this.httpClient.get<RemoteSettings>(`${SERVICE_PATH}`),
        };
        return forkJoin(settingsAndEnvironment$).pipe(
            tap((settingsAndEnvironment: TenantConfiguration) => {
                this.storedSettings = settingsAndEnvironment.remoteSettings;
                this.configureLanguage(settingsAndEnvironment.remoteSettings);
                this.isLoaded.next(true);
            }),
            switchMap(() => EMPTY),
        );
    }

    registerTranslations(translationsByCulture, filterExisting?: boolean): void {
        const culture = this.remoteSettings.useInformalLanguage ? 'informal' : 'formal';
        const translations = translationsByCulture[culture];

        for (const lang in translations) {
            const translationsToAdd = filterExisting
                ? this.filterExistingTranslations(translations[lang], this._translateService.translations[lang])
                : translations[lang];
            this._translateService.setTranslation(lang, translationsToAdd, true);
        }
    }

    filterExistingTranslations(translations: any, existingTranslations: any): any {
        const existingTranslationKeys = existingTranslations ? Object.keys(existingTranslations) : [];
        return Object.keys(translations)
            .filter((key) => !existingTranslationKeys.includes(key))
            .reduce((obj, key) => {
                obj[key] = translations[key];
                return obj;
            }, {});
    }

    availableCategories(topicName: string): string[] {
        let categoriesMap = this.remoteSettings.availableCategories;
        if (this.remoteSettings.availableCategories[topicName]) {
            categoriesMap = this.remoteSettings.availableCategories[topicName];
        }
        return categoriesMap;
    }

    public getTranslation(lang: string): Observable<any> {
        return this.isLoaded.pipe(
            filter((isLoaded) => isLoaded),
            take(1),
            switchMap(() => {
                const culture = this.remoteSettings.useInformalLanguage ? 'informal' : 'formal';
                return of(this.getTranslations()[culture][lang]);
            }),
        );
    }

    private configureLanguage(remoteSettings: RemoteSettings) {
        const translations = this.getTranslations();
        const languageConfig: LanguageConfiguration = {
            appName: remoteSettings.appName,
            culture: remoteSettings.useInformalLanguage ? 'informal' : 'formal',
            translations: translations,
            defaultLocale: remoteSettings.defaultLocale as LanguageOptions,
            availableLocales: remoteSettings.availableLocales as LanguageOptions[],
        };

        this.registerTranslations(translations);

        (this.windowRef.nativeWindow as AppWindow).languageConfig = { ...languageConfig };
    }

    private getTranslations(): any {
        const enTranslations = {
            ...enDefaultTranslations,
            ...this.localSettings.language.translations['en'],
        };
        const translationsByCulture = {
            formal: {
                en: enTranslations,
                de: { ...deDefaultTranslations, ...this.localSettings.language.translations['de'] },
            },
            informal: {
                en: enTranslations,
                de: { ...deInformalDefaultTranslations, ...this.localSettings.language.translations['de'] },
            },
        };

        return translationsByCulture;
    }
}
