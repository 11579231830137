import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignsFactComponent } from './campaigns-fact/campaigns-fact.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CampaignsPopoverComponent } from './campaigns-popover/campaigns-popover.component';
import { MatListModule } from '@angular/material/list';
import { CampaignsSelectComponent } from './campaigns-select/campaigns-select.component';
import { ChipsModule, SelectModule } from '@tandemploy/ngx-components';
import { LanguageModule } from '../language';
import { TagsHighlighterModule } from '../tags-highlighter';
import { PopoverModule } from '../popover';
import { CampaignsTaggerComponent } from './campaigns-tagger/campaigns-tagger.component';
import { TaggerModule } from '../tagger';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        LanguageModule,
        MatButtonModule,
        TagsHighlighterModule,
        PopoverModule,
        MatListModule,
        SelectModule,
        TaggerModule,
        MatChipsModule,
        ChipsModule,
    ],
    declarations: [
        CampaignsFactComponent,
        CampaignsPopoverComponent,
        CampaignsSelectComponent,
        CampaignsTaggerComponent,
    ],
    exports: [CampaignsFactComponent, CampaignsPopoverComponent, CampaignsSelectComponent, CampaignsTaggerComponent],
})
export class CampaignsModule {}
