<mat-chip-list [selectable]="selectable" multiple="true" [attr.aria-label]="ariaLabel">
    <span *ngFor="let tag of getTagsNames(); let i = index">
        <tp-chip
            [isBasic]="true"
            class="tagger__chip"
            [value]="getTagValue(tag)"
            #tooltip="matTooltip"
            matTooltip="{{ tag }}"
            [matTooltipPosition]="'above'"
            [matTooltipDisabled]="!hasHiddenText(tag)"
            [removable]="false"
            [selectable]="selectable"
            [selected]="isSelected(tag)"
            (simpleClick)="onClick($event, tag)"
            (enterPressed)="onEnterPressed($event, tag)"
        ></tp-chip>
        <div *ngIf="i < getTagsNames().length - 1" class="tagger__chip-middot"></div>
    </span>
</mat-chip-list>
