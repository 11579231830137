<mat-icon
    svgIcon="wifi"
    title="{{ 'NLA_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL' | translatable }}"
    tpPopover
    tpPopoverHover
    [tpPopoverHasBackdrop]="false"
    [tpVerticalTarget]="'above'"
    [tpPopoverContent]="popoverContent"
    [tpPopoverData]="popoverData"
    [tpPopoverClass]="popoverClass"
></mat-icon>
