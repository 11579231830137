import { Injectable } from '@angular/core';
import {
    Account,
    AccountDTO,
    ContactAccount,
    ContactAccountDto,
    OthersAccount,
    OthersAccountDTO,
    PowerBiConfigurationDTO,
} from './account.types';
import { GeneralDataSerializer } from './general-data/general-data.serializer';
import { SelectedTopicsSerializer } from './selected-topics/selected-topics.serializer';
import { AccountSettingsSerializer } from './account-settings/account-settings.serializer';
import { ExperienceSerializer } from './experience/experience.serializer';
import { ContactAccountGeneralDataSerializer } from './general-data/contact-account-general-data.serializer';
import { QuestionnaireSerializer } from './questionnaire/questionnaire.serrializer';
import { LanguageHelpersService, LanguageOptions } from '@tploy-enterprise/tenant-common';
import { TranslateService } from '@ngx-translate/core';
import { IEmbedConfiguration } from 'powerbi-client';

@Injectable({
    providedIn: 'root',
})
export class AccountSerializer {
    constructor(
        private readonly generalDataSerializer: GeneralDataSerializer,
        private readonly selectedTopicsSerializer: SelectedTopicsSerializer,
        private readonly accountSettingsSerializer: AccountSettingsSerializer,
        private readonly experienceSerializer: ExperienceSerializer,
        private readonly contactAccountGeneralDataSerializer: ContactAccountGeneralDataSerializer,
        private readonly questionnaireSerializer: QuestionnaireSerializer,
        private readonly translateService: TranslateService,
        private readonly languageHelpersService: LanguageHelpersService,
    ) {}

    serialize(account: Account): AccountDTO {
        return {
            ...account,
            locale: this.serializeLocale(account.locale),
            generalData: this.generalDataSerializer.serialize(account.generalData),
            selectedTopics: this.selectedTopicsSerializer.serialize(account.selectedTopics),
            experience: this.experienceSerializer.serialize(account.experience),
            settings: this.accountSettingsSerializer.serialize(account.settings),
        };
    }

    serializeLocale(locale: string | LanguageOptions): LanguageOptions {
        if (!locale || locale === 'undefined') {
            return this.translateService.currentLang as LanguageOptions;
        }
        return locale as LanguageOptions;
    }

    deserializeOthersAccount(dto: OthersAccountDTO): OthersAccount {
        return {
            userId: dto.userId,
            generalData: this.generalDataSerializer.deserialize(dto.generalData),
            selectedTopics: this.selectedTopicsSerializer.deserialize(dto.selectedTopics),
            experience: this.experienceSerializer.deserialize(dto.experience),
            questionnaire: this.questionnaireSerializer.deserialize(
                dto.questionnaire ?? { answers: [], currentQuestion: null },
            ),
        };
    }

    deserializeContactAccount(dto: ContactAccountDto): ContactAccount {
        return {
            userId: dto.userId,
            generalData: this.contactAccountGeneralDataSerializer.deserialize(dto),
        };
    }

    deserialize(dto: AccountDTO): Account {
        return {
            ...dto,
            generalData: this.generalDataSerializer.deserialize(dto.generalData),
            selectedTopics: this.selectedTopicsSerializer.deserialize(dto.selectedTopics),
            experience: this.experienceSerializer.deserialize(dto.experience),
            settings: this.accountSettingsSerializer.deserialize(dto.settings),
        };
    }
    deserializePowerBiConfiguration(dto: PowerBiConfigurationDTO): IEmbedConfiguration {
        return {
            id: dto.id,
            embedUrl: dto.embedUrl,
            accessToken: dto.accessToken,
        };
    }
}
