import { Injectable } from '@angular/core';
import { GeneralData, GeneralDataDTO } from './general-data.types';
import { LocationSerializer } from '../../location/location.serializer';
import { ConfigService } from '../../config';

@Injectable({
    providedIn: 'root',
})
export class GeneralDataSerializer {
    constructor(
        private readonly locationSerializer: LocationSerializer,
        private readonly configService: ConfigService,
    ) {}
    serialize(generalData: GeneralData): GeneralDataDTO {
        return {
            ...generalData,
            imageUrl: generalData.imageUrl.split(this.configService.remoteSettings.domain).pop(),
            locations: this.locationSerializer.serialise(generalData.locations),
            yearOfBirth: generalData.yearOfBirth ? generalData.yearOfBirth.toString() : null,
        };
    }

    deserialize(dto: GeneralDataDTO): GeneralData {
        return {
            ...dto,
            locations: dto.locations.map((l) => l.name),
            yearOfBirth: +dto.yearOfBirth,
        };
    }
}
