import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { isSearchResultsEmpty, selectSearchResults, selectSearchPageParams } from '../../nla.selectors';
import { NLAState } from '../../nla.reducer';
import { NLASearchResult } from '../../nla-service/nla.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-search-results',
    templateUrl: './nla-search-results.component.html',
    host: { class: 'nla-search' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLASearchResultsComponent implements OnInit, OnDestroy {
    results$: Observable<NLASearchResult[]>;
    resultPageParams$: Observable<PageParams>;
    isResultsEmpty$: Observable<boolean>;
    private readonly destroy$ = new Subject<void>();

    constructor(private readonly store: Store<{ neverLunchAlone: NLAState }>) {}

    ngOnInit(): void {
        this.results$ = this.store.select(selectSearchResults);
        this.resultPageParams$ = this.store.select(selectSearchPageParams);
        this.isResultsEmpty$ = this.store.select(isSearchResultsEmpty);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
