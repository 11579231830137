<section class="modal-confirm-edit-data-wrap">
    <header class="form-header">
        <h1 mat-dialog-title>
            {{ 'HEADLINE_DIALOG_CONFIRM_EDIT_DATA' | translatable }}
        </h1>
        <tp-icon-button
            mat-dialog-close
            [icon]="'close'"
            ariaLabel="{{ 'MODAL_CLOSE_LABEL' | translatable }}"
            class="form-header_close modal-confirm-edit-data-close no-border"
        ></tp-icon-button>
    </header>
    <mat-dialog-content class="dialog-content">
        <div class="dialog-content__column--left">
            <mat-icon svgIcon="info_filled"></mat-icon>
        </div>
        <div class="dialog-content__column--right">
            <p>{{ 'IF_LOSE_DIALOG_CONFIRM_EDIT_DATA' | translatable }}</p>
            <p>{{ 'CLICK_FOR_CONTINUE_DIALOG_CONFIRM_EDIT_DATA' | translatable }}</p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="form-footer form-footer--buttons-center form-footer--ignore-sibling-padding">
        <nav class="modal-confirm-edit-data-nav">
            <tp-secondary-button [mat-dialog-close]="true">
                {{ 'OK_DELETE_MY_INPUT' | translatable }}
            </tp-secondary-button>
            <tp-primary-button mat-dialog-close>
                {{ 'CONTINUE_EDITING' | translatable }}
            </tp-primary-button>
        </nav>
    </mat-dialog-actions>
</section>
