import { combineReducers, createReducer, on } from '@ngrx/store';
import { generalDataReducer, GeneralDataState } from './general-data/general-data.reducer';
import { selectedTopicsReducer, SelectedTopicsState } from './selected-topics/selected-topics.reducer';
import { localeReducer, LocaleState } from './locale/locale.reducer';
import { accountSettingsReducer, AccountSettingsState } from './account-settings/account-settings.reducer';
import { experienceReducer, ExperienceState } from './experience';
import { AccountActions } from './account.actions';
import { SuccessFactorsActions } from '../success-factors';
import { questionnaireReducer, QuestionnaireState } from './questionnaire/questionnaire.reducer';
import { SuggestedSkills, SuggestedSkill } from '@tploy-enterprise/tenant-common';
import { UserRole } from '@tandemploy/common';

export interface AccountStatusState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
    creating: boolean;
    deleting: boolean;
}
export interface AccountSyncStatusState {
    completed: boolean;
    error: Error;
}
export interface SsoLoadStatusState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
}
export interface SuccessFactorsUserDataLoadStatusState {
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
}
export interface SuggestedSkillsState {
    suggestedSkills: SuggestedSkills;
}
export interface HiddenSkillsFromSuggestionState {
    skills: string[];
}

const accountStatusInitialState: AccountStatusState = {
    loaded: false,
    loading: false,
    loadingError: null,
    creating: false,
    deleting: false,
};

const initialAccountSyncStatusState: AccountSyncStatusState = {
    completed: false,
    error: null,
};

const initialSsoLoadStatusState: SsoLoadStatusState = {
    loaded: false,
    loading: false,
    loadingError: null,
};

const initialSuccessFactorsUserDataLoadStatusState: SuccessFactorsUserDataLoadStatusState = {
    loaded: false,
    loading: false,
    loadingError: null,
};

const initialSuggestedSkillsState: SuggestedSkillsState = {
    suggestedSkills: { aType: [], lType: [], sType: [] },
};

const initialHiddenSkillsFromSuggestionState: HiddenSkillsFromSuggestionState = {
    skills: [],
};

export interface AccountState {
    status: AccountStatusState;
    userId: string;
    roles: Array<UserRole>;
    generalData: GeneralDataState;
    settings: AccountSettingsState;
    selectedTopics: SelectedTopicsState;
    locale: LocaleState;
    experience: ExperienceState;
    questionnaire: QuestionnaireState;
    completionScore: number;
    syncStatus: AccountSyncStatusState;
    ssoLoadStatus: SsoLoadStatusState;
    successFactorsUserDataLoadStatus: SuccessFactorsUserDataLoadStatusState;
    suggestedSkills: SuggestedSkillsState;
    hiddenSkillsFromSuggestion: HiddenSkillsFromSuggestionState;
}

const statusReducer = createReducer(
    accountStatusInitialState,
    on(AccountActions.load, (state) => ({ ...state, loading: true })),
    on(AccountActions.loadError, (state, { error }) => ({ ...state, loadingError: error, loading: false })),
    on(AccountActions.loadSuccess, (state) => ({ ...state, loadingError: null, loading: false, loaded: true })),
    on(AccountActions.create, (state) => ({ ...state, creating: true })),
    on(AccountActions.createError, (state) => ({ ...state, creating: false })),
    on(AccountActions.createSuccess, (state) => ({
        ...state,
        loadingError: null,
        loaded: true,
        loading: false,
        creating: false,
    })),
    on(AccountActions.delete, (state) => ({ ...state, deleting: true })),
    on(AccountActions.deleteSuccess, (state) => ({ ...state, deleting: false })),
    on(AccountActions.deleteError, (state) => ({ ...state, deleting: false })),
);

const completionScoreReducer = createReducer(
    0,
    on(AccountActions.loadSuccess, (state, { account }) => Number(account.completionScore)),
    on(AccountActions.createSuccess, (state, { account }) => Number(account.completionScore)),
);

const rolesReducer = createReducer(
    [UserRole.RESTRICTED_USER],
    on(AccountActions.loadSuccess, (state, { account }) => account.roles),
    on(AccountActions.createSuccess, (state, { account }) => account.roles),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => account.roles ?? state),
);

const userIdReducer = createReducer(
    null,
    on(AccountActions.loadSuccess, (state, { account }) => account.userId),
    on(AccountActions.createSuccess, (state, { account }) => account.userId),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => account.userId ?? state),
);

const syncStatusReducer = createReducer(
    initialAccountSyncStatusState,
    on(AccountActions.sync, (state) => ({ ...state, completed: false })),
    on(AccountActions.syncSuccess, (state) => ({ ...state, completed: true })),
    on(AccountActions.syncError, (state, { error }) => ({ ...state, error, completed: true })),
);

const ssoLoadStatusReducer = createReducer(
    initialSsoLoadStatusState,
    on(AccountActions.loadSsoData, (state) => ({ ...state, loaded: false, loading: true })),
    on(AccountActions.loadSsoDataSuccess, (state) => ({ ...state, loaded: true, loading: false })),
);

const successFactorsUserDataLoadStatusReducer = createReducer(
    initialSuccessFactorsUserDataLoadStatusState,
    on(SuccessFactorsActions.loadSuccessFactorsUserData, (state) => ({ ...state, loaded: false, loading: true })),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state) => ({
        ...state,
        loaded: true,
        loading: false,
    })),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataError, (state) => ({
        ...state,
        loaded: true,
        loading: false,
    })),
);

const combineSkills = (oldSkills, newSkills, key): Array<SuggestedSkill> => {
    const result = oldSkills;
    newSkills.forEach((newSkill) => {
        if (!result.some((oldSkill) => oldSkill[key] === newSkill[key])) {
            result.push(newSkill);
        }
    });
    return result;
};

const suggestedSkillsReducer = createReducer(
    initialSuggestedSkillsState,
    on(AccountActions.loadAllSuggestedSkillsSuccess, (state, { suggestedSkills }) => {
        return {
            ...state,
            suggestedSkills: {
                aType: combineSkills(state.suggestedSkills.aType, suggestedSkills.aType, 'name'),
                sType: combineSkills(state.suggestedSkills.sType, suggestedSkills.sType, 'name'),
                lType: combineSkills(state.suggestedSkills.lType, suggestedSkills.lType, 'name'),
            },
        };
    }),
);

const hiddenSkillsFromSuggestionReducer = createReducer(
    initialHiddenSkillsFromSuggestionState,
    on(AccountActions.loadSuccess, (state, { account }) => {
        return {
            ...state,
            skills: account['hiddenSkillsFromSuggestion'],
        };
    }),
    on(AccountActions.hideSkillsSuccess, (state, { skills }) => {
        return {
            ...state,
            skills,
        };
    }),
    on(AccountActions.recoverSkillsSuccess, (state, { skills }) => {
        return {
            ...state,
            skills,
        };
    }),
);

export const accountDataReducer = combineReducers({
    status: statusReducer,
    roles: rolesReducer,
    userId: userIdReducer,
    generalData: generalDataReducer,
    settings: accountSettingsReducer,
    selectedTopics: selectedTopicsReducer,
    experience: experienceReducer,
    questionnaire: questionnaireReducer,
    locale: localeReducer,
    completionScore: completionScoreReducer,
    syncStatus: syncStatusReducer,
    ssoLoadStatus: ssoLoadStatusReducer,
    successFactorsUserDataLoadStatus: successFactorsUserDataLoadStatusReducer,
    suggestedSkills: suggestedSkillsReducer,
    hiddenSkillsFromSuggestion: hiddenSkillsFromSuggestionReducer,
});
