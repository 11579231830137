import { NgModule } from '@angular/core';
import { TenantTemplateDirective } from './tenant-templates.directive';
import { CollapsableModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    imports: [CollapsableModule],
    declarations: [TenantTemplateDirective],
    exports: [TenantTemplateDirective],
})
export class TenantTemplatesModule {}
