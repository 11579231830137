import { QuestionnaireData } from './questionnaire.types';
import { AccountState } from '../../../core-modules/account';
import { QuestionnaireCurrentState, QuestionnaireState } from './questionnaire.reducer';

export function selectQuestionnaireEditData(state: { account: AccountState }): QuestionnaireData {
    return state.account.questionnaire.edit.data;
}
export function selectQuestionnaireCurrentData(state: { account: AccountState }): QuestionnaireData {
    return state.account.questionnaire.current.data;
}
export function selectQuestionnaireCurrentState(state: { account: AccountState }): QuestionnaireCurrentState {
    return state.account.questionnaire.current;
}
export function selectQuestionnaireState(state: { account: AccountState }): QuestionnaireState {
    return state.account.questionnaire;
}
