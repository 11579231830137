import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const SCRIPT_ORIGINS = [
    'https://matomo-experiments.tandemploy.com',
    'https://pi.tandemploy.com',
    'https://bugtracking.int.tploy.de',
];

@Injectable({ providedIn: 'root' })
export class TrustedSecurityService {
    constructor(@Inject(DOCUMENT) private readonly document, private readonly sanitizer: DomSanitizer) {}

    registerTrustedTypesPolicy() {
        if (window['trustedTypes'] && window['trustedTypes'].createPolicy) {
            window['trustedTypes'].createPolicy('default', {
                createHTML: (string) => string.replace(/</g, '<').replace(/>/g, '>'),
                createScriptURL: (string) => {
                    const u = new URL(string, this.document.baseUrl);
                    if (SCRIPT_ORIGINS.includes(u.origin)) {
                        return u.href;
                    }
                    throw new Error('Cannot load scripts from this origin');
                },
            });
        }
    }
}
