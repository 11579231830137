export class WorkshopUnexpectedException implements Error {
    originalError: Error;
    name: string;
    message: string;
    stack: string;

    constructor(originalError?: Error) {
        this.originalError = originalError;
        this.name = 'WorkshopUnexpectedException';
        this.message = originalError?.message ? originalError.message : 'Something unexpected has happened.';
        this.stack = originalError?.stack;
    }
}

export class WorkshopEntityRegistrationFullException implements Error {
    readonly name = 'WorkshopEntityRegistrationFullException';
    readonly message = 'The registration list is full.';
}

abstract class WorkshopBadRequestException extends Error {
    constructor(message: string, apiError?: Error) {
        let extra: string;
        const additionalInformation = apiError ? apiError.message || null : null;

        if (additionalInformation) {
            extra = ` Here is what the service has returned: ${additionalInformation}.`;
        } else {
            extra = ``;
        }

        super(`${message}${extra}`);
    }
}

// Entity related exceptions
export class WorkshopEntityBadRequestException extends WorkshopBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the Workshop entity. Please check the data.', apiError);
    }
}

export class WorkshopEntityNotFoundException extends Error {
    constructor() {
        super('The requested Workshop entity was not found.');
    }
}

export class WorkshopEntityForbiddenException extends Error {
    constructor() {
        super('A user with no organization or administration permission has tried modifying a Workshop entity.');
    }
}
