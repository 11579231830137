import { GenerationExchangeState } from './generation-exchange.reducer';
import {
    GenerationExchangeBookmark,
    GenerationExchangeMatch,
    GenerationExchangeProfile,
    GenerationExchangeSearchResult,
    GenerationExchangeSuggestedSkills,
} from './generation-exchange-service/generation-exchange.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export function selectCurrentGenerationExchangeProfile(state: {
    generationExchange: GenerationExchangeState;
}): GenerationExchangeProfile {
    return state.generationExchange.profile.current;
}

export function selectSavedGenerationExchangeProfile(state: {
    generationExchange: GenerationExchangeState;
}): GenerationExchangeProfile {
    return state.generationExchange.profile.saved;
}

export function isGenerationExchangeProfileLoadedOrLoading(state: {
    generationExchange: GenerationExchangeState;
}): boolean {
    const status = state.generationExchange.profile.status;
    return status.loaded || status.loading;
}

export function isGenerationExchangeProfileLoaded(state: { generationExchange: GenerationExchangeState }): boolean {
    return state.generationExchange.profile.status.loaded;
}

export function isGenerationExchangeProfileInDirtyState(state: {
    generationExchange: GenerationExchangeState;
}): boolean {
    return state.generationExchange.profile.status.dirty;
}

export function hasSavedGenerationExchangeProfile(state: { generationExchange: GenerationExchangeState }): boolean {
    const profileState = state.generationExchange.profile;
    return (
        profileState.status.loaded &&
        !profileState.status.loadingError &&
        (profileState.saved?.companyLocations?.length > 0 || profileState.saved.locationIndependent)
    );
}

export function hasGenerationExchangeProfile(state: { generationExchange: GenerationExchangeState }): boolean {
    const profileState = state.generationExchange.profile;
    return (
        profileState.status.loaded &&
        !profileState.status.loadingError &&
        (profileState.current?.companyLocations?.length > 0 || profileState.current.locationIndependent)
    );
}

export function selectSearchResults(state: {
    generationExchange: GenerationExchangeState;
}): GenerationExchangeSearchResult[] {
    return state.generationExchange.searchResults.results;
}

export function selectAllSearchResults(state: {
    generationExchange: GenerationExchangeState;
}): GenerationExchangeSearchResult[] {
    return state.generationExchange.searchResults.allResults;
}

export function selectSearchPageParams(state: { generationExchange: GenerationExchangeState }): PageParams {
    return state.generationExchange.searchResults.pageParams;
}

export function selectMatches(state: { generationExchange: GenerationExchangeState }): GenerationExchangeMatch[] {
    return state.generationExchange.matches.results;
}

export function selectAllMatches(state: { generationExchange: GenerationExchangeState }): GenerationExchangeMatch[] {
    return state.generationExchange.matches.allResults;
}

export function selectMatchesTotal(state: { generationExchange: GenerationExchangeState }): number {
    return state.generationExchange.matches.length;
}

export function selectMatchesPageParams(state: { generationExchange: GenerationExchangeState }): PageParams {
    return state.generationExchange.matches.pageParams;
}

export function selectMatchesPageSelectedCampaigns(state: { generationExchange: GenerationExchangeState }): string[] {
    return state.generationExchange.matches.selectedCampaigns;
}

export function selectBookmarks(state: { generationExchange: GenerationExchangeState }): GenerationExchangeBookmark[] {
    return state.generationExchange.bookmarks.data;
}

export function selectBookmarksTotal(state: { generationExchange: GenerationExchangeState }): number {
    return state.generationExchange.bookmarks.length;
}

export function isSearchResultsEmpty(state: { generationExchange: GenerationExchangeState }): boolean {
    const searchState = state.generationExchange.searchResults;
    return searchState.loaded && searchState.results.length === 0;
}

export function isSearchResultsLoading(state: { generationExchange: GenerationExchangeState }): boolean {
    return state.generationExchange.searchResults.loading;
}

export function suggestedSkills(state: {
    generationExchange: GenerationExchangeState;
}): GenerationExchangeSuggestedSkills {
    return state.generationExchange.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { generationExchange: GenerationExchangeState }): string[] {
    return state.generationExchange.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { generationExchange: GenerationExchangeState }): string[] {
    return state.generationExchange.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { generationExchange: GenerationExchangeState }): string[] {
    return state.generationExchange.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}
