import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkshopService } from '../services/workshop.service';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Workshop } from '../models/workshop.types';
import { of } from 'rxjs';
import { TopicWorkshopActions } from './topic-workshop.actions';
import { Store } from '@ngrx/store';
import { TopicWorkshopState } from './topic-workshop.reducer';
import {
    AccountState,
    Contact,
    ConversationType,
    DirectMessagesCreateConversationActions,
} from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class TopicWorkshopEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{ topicWorkshop: TopicWorkshopState; account: AccountState }>,
        private readonly workshopService: WorkshopService,
    ) {}

    loadWorkshopsOfferedByMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.loadWorkshopsOfferedByMe),
            withLatestFrom(this.store.select((state) => state.account)),
            switchMap(([action, account]) =>
                this.workshopService.loadOfferedByMe(account.userId).pipe(
                    map((workshops: Workshop[]) => {
                        return TopicWorkshopActions.loadWorkshopsOfferedByMeSuccess({ workshops });
                    }),
                    catchError((error: Error) => {
                        return of(TopicWorkshopActions.loadWorkshopsOfferedByMeError({ error }));
                    }),
                ),
            ),
        ),
    );

    createWorkshop$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.createWorkshop),
            switchMap((action) => {
                if (action.workshop.id) {
                    return this.workshopService.save(action.workshop);
                }

                return this.workshopService.create(action.workshop);
            }),
            map((createdWorkshop: Workshop) =>
                TopicWorkshopActions.createWorkshopSuccess({ workshop: createdWorkshop }),
            ),
            catchError((error: Error) => of(TopicWorkshopActions.createWorkshopError({ error }))),
        ),
    );

    saveWorkshop$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.saveWorkshop),
            exhaustMap((action) =>
                this.workshopService.save(action.workshop, action.sendNotifications).pipe(
                    map((savedWorkshop: Workshop) =>
                        TopicWorkshopActions.saveWorkshopSuccess({ workshop: savedWorkshop }),
                    ),
                    catchError((error: Error) => of(TopicWorkshopActions.saveWorkshopError({ error }))),
                ),
            ),
        ),
    );

    publishWorkshop$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.publishWorkshop),
            switchMap((action) =>
                this.workshopService.publish(action.workshopId).pipe(
                    withLatestFrom(this.store.select('account')),
                    switchMap(([publishedWorkshop, account]) => {
                        const participants: Contact[] = [
                            {
                                userId: account.userId,
                                generalData: {
                                    name: account.generalData?.current?.data?.name || '',
                                    imageUrl: account.generalData?.current?.data?.imageUrl || '',
                                },
                            },
                        ];
                        return [
                            TopicWorkshopActions.publishWorkshopSuccess({ workshop: publishedWorkshop }),
                            DirectMessagesCreateConversationActions.createConversation({
                                payload: {
                                    origin: 'ORIGIN_TOPIC_WORKSHOP',
                                    type: ConversationType.GROUP,
                                    participants,
                                    originId: publishedWorkshop.id,
                                    imageUrl: publishedWorkshop?.imageUrl || '',
                                    name: publishedWorkshop.title,
                                },
                            }),
                        ];
                    }),
                    catchError((error: Error) => of(TopicWorkshopActions.publishWorkshopError({ error }))),
                ),
            ),
        ),
    );

    deleteWorkshop$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.deleteWorkshop),
            switchMap((action) =>
                this.workshopService.delete(action.workshopId).pipe(
                    map((workshopId) => TopicWorkshopActions.deleteWorkshopSuccess({ workshopId })),
                    catchError((error: Error) => of(TopicWorkshopActions.deleteWorkshopError({ error }))),
                ),
            ),
        ),
    );

    searchTopicUsersByName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TopicWorkshopActions.searchTopicUsers),
            switchMap((action) =>
                this.workshopService.searchTopicUserByName(action.searchQuery, action.entityId).pipe(
                    map((searchResult) => TopicWorkshopActions.searchTopicUsersSuccess({ results: searchResult })),
                    catchError((error) => of(TopicWorkshopActions.searchTopicUsersError({ error }))),
                ),
            ),
        ),
    );
}
