import { combineReducers, createReducer, on } from '@ngrx/store';
import { CommonActions } from './common.actions';

export interface CommonState {
    loading: any;
    loaded: any;
    listLoading: boolean;
    listLoaded: boolean;
}

const initialCommonState: CommonState = {
    loading: {},
    loaded: {},
    listLoading: false,
    listLoaded: false,
};

const commonReducer = createReducer(
    initialCommonState,
    on(CommonActions.loadOtherProfile, (state, { topicName }) => {
        return {
            ...state,
            loaded: {
                ...state.loaded,
                [topicName]: false,
            },
            loading: {
                ...state.loading,
                [topicName]: true,
            },
        };
    }),
    on(CommonActions.loadOtherProfileSuccess, (state, { topicName }) => {
        return {
            ...state,
            loaded: {
                ...state.loaded,
                [topicName]: true,
            },
            loading: {
                ...state.loading,
                [topicName]: false,
            },
        };
    }),
    on(CommonActions.loadOtherProfileError, (state, { topicName }) => {
        return {
            ...state,
            loaded: {
                ...state.loaded,
                [topicName]: false,
            },
            loading: {
                ...state.loading,
                [topicName]: false,
            },
        };
    }),
    on(CommonActions.loadList, (state) => ({ ...state, listLoading: true, listLoaded: false })),
    on(CommonActions.loadListSuccess, (state) => ({ ...state, listLoading: false, listLoaded: true })),
);

export const commonDataReducer = combineReducers({
    otherProfiles: commonReducer,
});
