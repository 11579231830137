import { createAction, props } from '@ngrx/store';
import { ContactSearchResult } from '../types/search.types';

export const SearchActions = {
    clearSearchResults: createAction('[Search] Clear Search Results'),

    performContactsSearch: createAction('[Search] Perform Contacts Search', props<{ searchQuery: string }>()),
    performContactsSearchSuccess: createAction(
        '[Search][Success] Perform Contacts Search',
        props<{ results: ContactSearchResult[] }>(),
    ),
    performContactsSearchError: createAction('[Search][Error] Perform Contacts Search', props<{ error: Error }>()),
};
