import { NgModule } from '@angular/core';
import { MotivationSelectComponent } from './motivation-select.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageModule } from '../language';

@NgModule({
    declarations: [MotivationSelectComponent],
    imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule, LanguageModule],
    exports: [MotivationSelectComponent],
})
export class MotivationSelectModule {}
