import { Component, Input } from '@angular/core';
import { Account, OthersAccount } from '@tploy-enterprise/tenant-core';
import { JobXProfile, JobXTandemCheckOthersProfile, JobXTandemCheckProfile } from '../../jobs.types';
import { JobXTandemDialogComponent } from '../../job-x-domain/job-x-account-tile/job-x-tandem-dialog/job-x-tandem-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'tp-job-x-tandem-check-button',
    templateUrl: './job-x-tandem-check-button.component.html',
})
export class JobXTandemCheckButtonComponent {
    @Input() ownAccount: Account;
    @Input() othersAccount: OthersAccount;
    @Input() ownJobXProfile: JobXProfile;
    @Input() othersJobXProfile: JobXProfile;
    @Input() disabled: boolean;

    constructor(private readonly dialog: MatDialog) {}

    openTandemDialog() {
        if (!this.othersJobXProfile) {
            setTimeout(() => this.openTandemDialog(), 0);
            return;
        }
        this.dialog.open(JobXTandemDialogComponent, {
            data: {
                ownProfile: {
                    ...this.ownJobXProfile,
                    user: { generalData: this.ownAccount.generalData, experience: this.ownAccount.experience },
                } as JobXTandemCheckProfile,
                targetProfile: {
                    ...this.othersJobXProfile,
                    user: {
                        ...this.othersAccount.generalData,
                        locations: this.othersAccount.generalData.locations.map((location) => ({
                            name: location,
                        })),
                        generalData: this.othersAccount.generalData,
                        experience: this.othersAccount.experience,
                    },
                } as JobXTandemCheckOthersProfile,
            },
            minWidth: '60vw',
            maxWidth: '80vw',
            panelClass: 'job-x-tandem-check-dialog',
        });
    }
}
