<tp-error-snack>
    <div data>
        <span
            [innerHtml]="{ message: 'TRY_AGAIN', messageParams: { href: url, linkText: label } } | translatable"
        ></span>
    </div>
    <div action>
        <span class="snack-close" (click)="close.emit()" role="button">✕</span>
    </div>
    <tp-error-snack> </tp-error-snack
></tp-error-snack>
