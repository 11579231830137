import { Tag } from './workshop.types';
import { Skill, SuggestedSkills } from '@tploy-enterprise/tenant-common';
import { INTEREST_TYPE, OFFERING_TYPE } from '@tploy-enterprise/tenant-core';

export type Motivation = OFFERING_TYPE | INTEREST_TYPE;

export const WORKSHOP_PROFILE_DATA_STORAGE_KEY = 'tploy.workshop.profile';
export const IS_PROFILE_DIRTY = 'tploy.workshop.profile.isDirty';

export interface WorkshopProfileDTO {
    user: WorkshopProfileUserDTO;
    skills: Skill[];
    skillsInterestedIn: Skill[];
    categoriesInterestedIn: string[];
    motivations: Motivation[];
    pushToSuccessFactor?: boolean;
}

export type WorkshopSuggestedSkills = SuggestedSkills;

export interface WorkshopProfileUserDTO {
    id: string;
    name: string;
    imageUrl: string;
    locations: Tag[];
    languages: string[];
    groups: string[];
}

export interface WorkshopProfile {
    skills: Skill[];
    skillsInterestedIn: string[];
    categoriesInterestedIn: string[];
    selectedMotivations: Motivation[];
    pushToSuccessFactor?: boolean;
}

export interface WorkshopOthersProfile extends WorkshopProfile {
    user: {
        id: string;
        name: string;
        locations: Tag[];
        languages: string[];
        imageUrl: string;
    };
}
