import {
    Component,
    ChangeDetectionStrategy,
    Inject,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl, FormBuilder } from '@angular/forms';
import { ImageAdapterService } from '@tploy-enterprise/tenant-common';
import { AVATAR_PLACEHOLDERS } from '../../image-picker.di';
import { MediaService } from '../../../media';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IMAGE_UPLOAD_LIMIT, USER_IMAGES_MAX, ALLOWED_FILE_TYPES } from '../../image-picker.constant';
import { Media } from '../../../media/media.types';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MediaState } from '../../../media/media.reducer';
import { selectAllUserMedia } from '../../../media/media.selectors';
import { MediaActions } from '../../../media/media.actions';
import { map, take, tap } from 'rxjs/operators';
import { ConfirmImageDeleteDialogComponent } from '../confirm-image-delete-dialog/confirm-image-delete-dialog.component';
import { MaxImagesReachedDialogComponent } from '../max-images-reached-dialog/max-images-reached-dialog.component';

@Component({
    selector: 'tp-avatar-image-picker-dialog',
    templateUrl: './avatar-image-picker-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'image-picker-dialog',
    },
})
export class AvatarImagePickerDialogComponent implements OnInit {
    public uploadControl: FormControl;
    public loading = false;
    public cropping = false;
    public sizeTooLarge = false;
    public wrongFileType = false;
    public showCropper = false;
    public imageChangedEvent: any = '';
    public imageFile: any = '';
    public cropImage: string;
    public nameFileCropImage: string;
    public typeFileCropImage: string;
    public currentAvatar: string;

    userMedia$: Observable<Array<Media>>;
    private userImagesLength: number;

    @ViewChild('selectBtn')
    selectBtn: ElementRef<HTMLButtonElement>;
    @ViewChild('upload')
    uploadBtn: ElementRef<HTMLButtonElement>;

    constructor(
        public readonly dialogRef: MatDialogRef<AvatarImagePickerDialogComponent, string>,
        @Inject(MAT_DIALOG_DATA) public selectedAvatar: string,
        @Inject(AVATAR_PLACEHOLDERS) public placeholders: string[],
        private readonly formBuilder: FormBuilder,
        private readonly imageAdapterService: ImageAdapterService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly mediaService: MediaService,
        private readonly store: Store<{ media: MediaState }>,
        private dialog: MatDialog,
    ) {
        this.currentAvatar = selectedAvatar;
    }

    ngOnInit() {
        this.uploadControl = this.formBuilder.control(null);
        this.userMedia$ = this.store.select(selectAllUserMedia).pipe(
            tap((userMedia) => {
                this.userImagesLength = userMedia.length;
            }),
            map((userMedia) => [
                ...userMedia,
                ...this.placeholders.map((placeholder) => ({ url: placeholder } as Media)),
            ]),
            map((allUserMedia) =>
                allUserMedia.some((media) => ~this.currentAvatar.indexOf(media.url))
                    ? allUserMedia
                    : [{ url: this.currentAvatar } as Media, ...allUserMedia],
            ),
        );
        this.dispatchLoadImages();
    }

    isCurrentAvatar(url: string): boolean {
        return !!~this.currentAvatar.indexOf(url);
    }

    isSelectedAvatar(url: string): boolean {
        return !!~this.selectedAvatar.indexOf(url);
    }

    public openFilePicker(): void {
        this.canUpload()
            ? this.uploadBtn.nativeElement.click()
            : this.dialog.open(MaxImagesReachedDialogComponent, { data: USER_IMAGES_MAX });
    }

    public select(url: string): void {
        this.selectedAvatar = url;
        this.changeDetectorRef.markForCheck();
    }

    public delete(id: string, event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.openConfirmImageDeleteDialog(id);
    }

    public onFileChange(event: Event): void {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            this.setFile(target.files[0]);
        }
        this.changeDetectorRef.markForCheck();
    }

    public onFileChangeDrag(event: File[]): void {
        if (!this.canUpload()) {
            this.dialog.open(MaxImagesReachedDialogComponent, { data: USER_IMAGES_MAX });
            return;
        }
        this.setFile(event[0]);
        this.changeDetectorRef.markForCheck();
    }

    public loadImageFailed(): void {
        this.showCropper = false;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.cropImage = event.base64;
    }

    public uploadFile(url: string): void {
        this.cropping = true;
        this.sizeTooLarge = false;
        this.wrongFileType = false;
        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], this.nameFileCropImage, { type: this.typeFileCropImage });
                this.readFileBeforeUpload(file);
            })
            .catch((error) => {
                this.cropping = false;
                this.sizeTooLarge = true;
                this.wrongFileType = true;
                this.changeDetectorRef.markForCheck();
            });
    }

    private readFileBeforeUpload(files: File | File[]): void {
        const file = files instanceof File ? files : files[0];
        if (file.size > IMAGE_UPLOAD_LIMIT) {
            this.cropping = false;
            this.sizeTooLarge = true;
            this.changeDetectorRef.markForCheck();
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            this.cropping = false;
            this.wrongFileType = true;
            this.changeDetectorRef.markForCheck();
        } else {
            this.startLoading();
            this.readFile(file)
                .then((file) => this.mediaService.uploadImage(file, 'square', 'profile').toPromise())
                .then((image) => {
                    this.loading = false;
                    this.selectedAvatar = image;
                    this.showCropper = false;
                    this.resetFile();
                    this.dispatchLoadImages();
                    this.changeDetectorRef.markForCheck();
                });
        }
    }

    private readFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                this.imageAdapterService.adaptImage(fileReader).subscribe((reader) => {
                    if (reader.readyState === reader.DONE) {
                        resolve(reader.result as string);
                    } else {
                        reject(reader.error);
                    }
                });
            };
            fileReader.readAsDataURL(file);
        });
    }

    private startLoading(): void {
        this.loading = true;
        this.sizeTooLarge = false;
        this.cropping = false;
    }

    private resetFile(): void {
        this.uploadBtn.nativeElement.value = '';
    }

    private setFile(file: File): void {
        if (file.size > IMAGE_UPLOAD_LIMIT) {
            this.sizeTooLarge = true;
            return;
        }
        this.nameFileCropImage = file.name;
        this.typeFileCropImage = file.type;
        this.imageFile = file;
        this.sizeTooLarge = false;
        this.showCropper = true;
    }

    openConfirmImageDeleteDialog(id: string) {
        const dialogRef = this.dialog.open(ConfirmImageDeleteDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((confirmed: string) => {
                if (confirmed) {
                    this.store.dispatch(MediaActions.deleteUserMedia({ id }));
                }
            });
    }

    private canUpload(): boolean {
        return this.userImagesLength < USER_IMAGES_MAX;
    }

    private dispatchLoadImages(): void {
        this.store.dispatch(MediaActions.loadAllUserMedia({ imageType: 'profile' }));
    }
}
