import { createAction, props } from '@ngrx/store';
import { ExpertMatch } from '../../expert-api/expert.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const ExpertMatchingActions = {
    load: createAction('[Expert matches] Load', props<{ pageParams: PageParams }>()),
    fetchExpertProfiles: createAction('[Expert matches] Fetch expert profiles', props<{ data: Array<ExpertMatch> }>()),
    loadSuccess: createAction(
        '[Expert matches][Success] Load',
        props<{ results: ExpertMatch[]; allResults: ExpertMatch[]; pageParams: PageParams }>(),
    ),
    loadError: createAction('[Expert matches][Error] Load', props<{ error: Error }>()),
    loadMatchResult: createAction('[Expert matches] Load match result', props<{ index: number }>()),
    loadMatchResultSuccess: createAction(
        '[Expert matches] [Success] Load match result',
        props<{ data: ExpertMatch; index: number }>(),
    ),
};
