import { Component } from '@angular/core';
import sitemapItems from './sitemap.json';

@Component({
    selector: 'tp-sitemap-en',
    templateUrl: './sitemap.tpl.html',
})
export class SitemapEnComponent {
    title: string;
    items: {
        label: string;
        route?: any[] | string;
        items?: {
            label: string;
            route?: any[] | string;
        }[];
    }[];

    constructor() {
        this.title = 'Sitemap';
        this.items = sitemapItems;
    }
}
