import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { LocationDTO } from './location.types';

@Injectable({
    providedIn: 'root',
})
export class LocationSerializer {
    constructor(private readonly locationService: LocationService) {}

    public serialise(locations: string[]): Array<LocationDTO> {
        return locations.map((name) => ({
            name,
            country: this.locationService.getCountry(name),
        }));
    }
}
