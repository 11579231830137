import { createAction, props } from '@ngrx/store';
import {
    GenerationExchangeBookmark,
    GenerationExchangeMatch,
    GenerationExchangeProfile,
    GenerationExchangeSearchResult,
    GenerationExchangeSuggestedSkills,
} from './generation-exchange-service/generation-exchange.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const GenerationExchangeActions = {
    featureInit: createAction('[Generation exchange] Init'),

    loadProfile: createAction('[Generation exchange] Load'),
    loadProfileSuccess: createAction(
        '[Generation exchange][Success] Load',
        props<{ profile: GenerationExchangeProfile; session: GenerationExchangeProfile }>(),
    ),
    loadProfileError: createAction(
        '[Generation exchange][Error] Load',
        props<{ error: Error; session: GenerationExchangeProfile }>(),
    ),

    loadSuggestedSkills: createAction('[Generation exchange] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[Generation exchange][Success] Load Suggested Skills',
        props<{ suggestedSkills: GenerationExchangeSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction(
        '[Generation exchange][Error] Load Suggested Skills',
        props<{ error: Error }>(),
    ),
    editCurrentProfile: createAction(
        '[Generation exchange] Change edit profile',
        props<{ currentProfile: Partial<GenerationExchangeProfile> }>(),
    ),
    cancelCurrentProfileEdit: createAction('[Generation exchange] Cancel edition'),

    saveProfile: createAction('[Generation exchange] Save profile'),
    saveProfileSuccess: createAction(
        '[Generation exchange][Success] Save profile',
        props<{ currentProfile: GenerationExchangeProfile }>(),
    ),
    saveProfileError: createAction(
        '[Generation exchange][Error] Save profile',
        props<{ currentProfile: GenerationExchangeProfile; error: Error }>(),
    ),

    loadMatches: createAction(
        '[Generation exchange] Load Matches',
        props<{ pageParams: PageParams; campaigns?: string[] }>(),
    ),
    loadMatchesSuccess: createAction(
        '[Generation exchange][Success] Load Matches',
        props<{
            results: GenerationExchangeMatch[];
            allResults: GenerationExchangeMatch[];
            length: number;
            pageParams: PageParams;
            selectedCampaigns?: string[];
        }>(),
    ),
    loadMatchesError: createAction('[Generation exchange][Error] Load Matches', props<{ error: Error }>()),

    loadBookmarks: createAction('[Generation exchange] Load Bookmarks'),
    loadBookmarksSuccess: createAction(
        '[Generation exchange][Success] Load Bookmarks',
        props<{ bookmarks: GenerationExchangeBookmark[]; length: number }>(),
    ),
    loadBookmarksError: createAction('[Generation exchange][Error] Load Bookmarks', props<{ error: Error }>()),

    search: createAction(
        '[Generation exchange] Search entities',
        props<{ searchQuery: string; pageParams: PageParams; campaigns?: string[] }>(),
    ),
    searchSuccess: createAction(
        '[Generation exchange][Success] Search entities',
        props<{
            results: GenerationExchangeSearchResult[];
            allResults: GenerationExchangeSearchResult[];
            pageParams: PageParams;
        }>(),
    ),
    searchError: createAction('[Generation exchange][Error] Search entities', props<{ error: Error }>()),

    loadSearchResult: createAction(
        '[Generation exchange] Load search result',
        props<{ searchQuery: string; index: number }>(),
    ),
    loadSearchResultSuccess: createAction(
        '[Generation exchange] [Success] Load search result',
        props<{ data: GenerationExchangeSearchResult; index: number }>(),
    ),
    loadMatchResult: createAction('[Generation exchange] Load match result', props<{ index: number }>()),
    loadMatchResultSuccess: createAction(
        '[Generation exchange] [Success] Load match result',
        props<{ data: GenerationExchangeMatch; index: number }>(),
    ),
    loadResultError: createAction('[Generation exchange] [Error] Load result', props<{ error: Error }>()),
};
