import {
    Component,
    forwardRef,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FroalaEditorConfigService } from './froala-editor-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tp-froala-editor',
    templateUrl: './froala-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FroalaEditorComponent),
            multi: true,
        },
        FroalaEditorConfigService,
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class FroalaEditorComponent implements ControlValueAccessor {
    @Input() placeholder: string;
    @Input() nonEditable: boolean;
    @Input() messenger: boolean;
    @Input() uploadImages: boolean;
    config: Record<string, string | unknown>;

    constructor(
        protected readonly changeDetectorRef: ChangeDetectorRef,
        private readonly froalaEditorConfigService: FroalaEditorConfigService,
        private readonly translateService: TranslateService,
    ) {}

    ngOnInit() {
        //note: froala editor require editable instance of config
        this.config = this.froalaEditorConfigService.getConfig(this.messenger, this.uploadImages);
        this.config.placeholderText = this.placeholder || this.translateService.instant('DESCRIPTION');
        this.config.events = { initialized: (e) => this.onInitialize(e) };
    }

    // Begin ControlValueAccesor methods.
    onChange = (_) => {};
    onTouched = () => {};
    onInitialize = (event) => {
        const editor = event?.getEditor();
        if (editor && this.nonEditable) {
            try {
                editor.edit.off();
            } catch (error) {
                console.error('FroalaEditorComponent -> caught error', error);
            }
        }
        if (editor && !this.uploadImages) {
            editor.events.on(
                'image.beforeUpload',
                (files: Blob[]) => {
                    if (files.length) {
                        const reader = new FileReader();
                        reader.onload = function (e) {
                            const result = e.target.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    return false;
                },
                true,
            );
        }
    };
    // Form model content changed.
    writeValue(content: string): void {
        this.model = content;
    }

    registerOnChange(fn: (_: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    // End ControlValueAccesor methods.

    model: string;
}
