import { Injectable } from '@angular/core';
import { Bookmark, GeneralData } from '@tploy-enterprise/tenant-core';
import {
    NLAHighlightDTO,
    NLALocationType,
    NLAProfileDTO,
    NLAProfileMatchDTO,
    NLASearchResultDTO,
} from '../nla-api/nla.dtos';
import { NLABookmark, NLAMatch, NLAProfile, NLAHighlight, NLASearchResult, OthersNLAProfile } from './nla.types';
import { LocationSerializer } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class NlaSerializer {
    constructor(private readonly locationSerializer: LocationSerializer) {}

    deserializeNlaProfile(dto: NLAProfileDTO): NLAProfile {
        return {
            companyLocations: dto.locationsForMeet.map((location) => location.name),
            locationIndependent: (dto.interestedInLocationType as NLALocationType) === NLALocationType.ONLINE,
            interestedInTopics: dto.interestedInTopics,
            favoriteFood: dto.favoriteFood,
        };
    }
    deserializeOthersNlaProfile(dto: NLAProfileDTO): OthersNLAProfile {
        return {
            user: {
                ...dto.user,
                locations: dto.user.locations?.map((location) => location.name),
            },
            ...this.deserializeNlaProfile(dto),
        };
    }
    serializeNlaProfile(profile: NLAProfile, user: GeneralData): NLAProfileDTO {
        return {
            user: {
                id: user.id,
                imageUrl: user.imageUrl,
                name: user.name,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            locationsForMeet: this.locationSerializer.serialise(profile.companyLocations),
            interestedInLocationType: profile.locationIndependent ? NLALocationType.ONLINE : NLALocationType.OWN,
            interestedInTopics: profile.interestedInTopics,
            favoriteFood: profile.favoriteFood,
        };
    }

    deserializeMatch(dto: NLAProfileMatchDTO): NLAMatch {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeHighlight(dto.highlight),
            profile: this.deserializeOthersNlaProfile(dto.profile),
        };
    }

    deserializeBookmark(bookmark: Bookmark): NLABookmark {
        return { ...bookmark };
    }

    deserializeSearchResult(dto: NLASearchResultDTO): NLASearchResult {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeHighlight(dto.highlight),
            profile: this.deserializeOthersNlaProfile(dto.profile),
        };
    }

    deserializeHighlight(highlightDTO: NLAHighlightDTO): NLAHighlight {
        return {
            locations: highlightDTO?.locations.map((location) => location.name) ?? [],
            interestedInTopics: highlightDTO?.interestedInTopics ?? [],
            isOnlineMatch: highlightDTO?.isOnlineMatch ?? false,
        };
    }
}
