<tp-video *ngIf="isVideo; else image" class="intro" [video]="current"></tp-video>
<ng-template #image><img class="intro" [src]="current.src" [alt]="current.title" /></ng-template>
<div class="previews" *ngIf="introArray.length > 1">
    <tp-icon-button
        [icon]="'arrow_left'"
        (click)="previous()"
        class="preview-button"
        tabindex="0"
        [attr.aria-label]="'VIDEO_PREV_BTN_ARIA_LABEL' | translatable : { videoTitle: previousTitle }"
    ></tp-icon-button>
    <ng-content></ng-content>
    <tp-icon-button
        [icon]="'arrow_right'"
        (click)="next()"
        class="preview-button"
        tabindex="0"
        [attr.aria-label]="'VIDEO_NEXT_BTN_ARIA_LABEL' | translatable : { videoTitle: nextTitle }"
    ></tp-icon-button>
</div>
