<div>
    <h2>What is Tandemploy all about?</h2>
    <div tpCollapsable class="section" data-ng-init="faq1.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq1.isCollapsed = !faq1.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> What is Tandemploy all about? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Tandemploy offers you a closed, in-house forum where you can discuss and organize flexible working
                    models with your colleagues. You can use Tandemploy anonymously (if you wish to do so) and
                    proactively find solutions for stages of your life in which you would like to work less, more
                    flexibly or just differently. Tandemploy thrives on your initiative and is not operated by your HR
                    department. This is your space, a space that provides the basis for "new work" at the heart of your
                    company.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq2.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq2.isCollapsed = !faq2.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Which working models can be organized using Tandemploy? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    With Tandemploy, you can find job sharing partners, colleagues to swap a job through job rotation,
                    mentors or mentees, and supporters for your projects.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq3.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq3.isCollapsed = !faq3.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How do I use Tandemploy to realize a job sharing model? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    You are interested in job sharing and could envisage sharing a - or your - job with a colleague? In
                    this case, Tandemploy offers you the possibility to find suitable tandem partners within your
                    company. A smart and learning-based matching algorithm automatically suggests colleagues to you who
                    are also interested in job sharing, who search within similar fields and who may have the 'right
                    chemistry'. You can communicate with them via the messaging system and even merge your two
                    individual profiles to a single tandem profile when you've found a potential job sharing partner.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq4.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq4.isCollapsed = !faq4.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How do I use Tandemploy to organize job rotation? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    You would like to get to know a different field or department within your organization for a limited
                    period of time (for example 3, 6 or 12 months) to broaden your horizon? You could even imagine to
                    swap your job with someone during this period of time? Or are there any tasks within your team that
                    could benefit from a different perspective and skill set privded by a colleague from another
                    department? Then Tandemploy gives you the opportunity to organize job rotations which can be either
                    one-sided or two-sided. The latter concept is also known as 'job swapping'.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq5.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq5.isCollapsed = !faq5.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How do I use Tandemploy to organize mentoring? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Would you love to share the (expert-)knowledge and experience you have in a certain field? Or are
                    you looking for a colleague whom you can ask for advice in certain situations or on specific topics?
                    Then Tandemploy offers you the possibility to find a mentee or mentor within your organization. The
                    biggest difference from a cross-generational job sharing is the fact that a mentoring constellation
                    is much more casual and can be totally detached from your daily working routine. It is totally up to
                    you and your mentor/mentee to decide on the time and frequency of your meetings or calls. A
                    mentoring can already consist of giving each other impulses atlunch once a month or quarter.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq6.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq6.isCollapsed = !faq6.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How do I use Tandemploy to build a project team? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Are you planning a project (that may be limited in time) and look for supporters with specific
                    qualifications or backgrounds among your colleagues? Then you can use Tandemploy to present your
                    project and win over suitable colleagues for it. You can add precious and versatile knowledge to
                    your project team and even find supporters who were not on your radar before.
                </div>
            </div>
        </div>
    </div>
    <h2>Technical questions about Tandemploy</h2>
    <div tpCollapsable class="section" data-ng-init="faq7.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq7.isCollapsed = !faq7.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> What happens after I have registered? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    After you’ve registered using your e-mail address, please complete a profile and answer some
                    questions about yourself, your interests and your skills. Your answers ensure that the matching with
                    suitable colleagues is as efficient as possible. Your answers will be used as the basis for
                    automatically suggesting suitable (job sharing, job rotation, mentoring or project) partners within
                    the company.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq8.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq8.isCollapsed = !faq8.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How exactly does the matching work? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    As soon as you have created a profile and completed it to the extent possible, the matching
                    algorithm will start working. When clicking on 'My colleagues' within the menu, you will be able to
                    view colleagues who could match your interests and the profile information you have provided. In
                    addition to the ‘hard factors‘ and depending on which working model you have chosen (project,
                    mentoring, job rotation or job sharing), there will be a specific focus on the ‘soft factors‘. You
                    provide the relevant information within the matching questionnaire and it will be weighted
                    differently depending on the model you have chosen.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq9.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq9.isCollapsed = !faq9.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> How can I contact other members? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    You can easily contact other members using our messaging function. If another member’s profile
                    attracts your attention, you can click on 'New message' within your colleague's profile and write a
                    message. You can find an overview of your messages when you click on 'My messages' within the menu.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq11.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq11.isCollapsed = !faq11.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> What do I do when technical problems occur? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    When a technical problem occurs in your Tandemploy environment and features cannot be used in the
                    way they should, please check your browser version first. Are you using the latest version? Please
                    close and reopen your browser. If the problem persists, try to shut down and restart your computer.
                    Still not working? Then please contact our technical support via support@tandemploy.com.
                </div>
            </div>
        </div>
    </div>
    <h2>Questions about data privacy</h2>
    <div tpCollapsable class="section" data-ng-init="faq12.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq12.isCollapsed = !faq12.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Can I choose to stay anonymous with my profile? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    You can determine the degree of anonymity yourself. Should you wish to remain anonymous, you can
                    choose a fantasy username without a link to you. In addition, you can choose to upload an avatar or
                    abstract image (without a portrait). However, we know from experience that profiles of colleagues
                    who upload a picture of themselves are viewed a lot more often. In order to receive suitable
                    suggestions for matches, please provide as much detail as possible within your profile. Except for
                    general information, you can hide certain parts of your profile from your colleagues' view. To do
                    so, just use the 'lock' symbol within your own profile view.
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq13.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq13.isCollapsed = !faq13.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> If I render my profile anonymous, how can I contact my colleagues? </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    Even if you decide to remain anonymous within your profile in the beginning, it’s very easy to
                    contact colleagues who attract your interest using the internal Tandemploy messaging system. Any
                    colleagues you are messaging will only see your username, not your e-mail address. It’s up to you
                    when and whether you decide to reveal your identity!
                </div>
            </div>
        </div>
    </div>
    <div tpCollapsable class="section" data-ng-init="faq14.isCollapsed = true">
        <a tpCollapsableTrigger data-ng-click="faq14.isCollapsed = !faq14.isCollapsed">
            <i color="accent" class="fa fa-plus orange"></i>
            <span> Delete account </span>
        </a>
        <div tpCollapsableContent>
            <div class="well">
                <div>
                    <p>Users can delete their account independently at any time.</p>
                    <p>
                        With the deletion of an account, all personal and other profile data of the user are completely
                        deleted in the Tandemploy system.
                    </p>
                    <p>
                        If you are inactive for a while and don't need matches, you can easily unsubscribe from
                        unnecessary mails under "e-mail notifications" in the user settings.
                    </p>
                    <p>The deletion can be carried out in the user settings, sub-item "Delete user account".</p>
                </div>
            </div>
        </div>
    </div>
</div>
