import { NgModule } from '@angular/core';
import { PreviewComponent } from './preview.component';
import { IntroViewComponent } from './intro-view.component';
import { PreviewItemComponent } from './preview-item.component';
import { VideoComponent } from './video/video.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { ModalLayoutModule } from '../../core-modules/modal-layout';
import { TenantTemplatesModule } from '../../core-modules/tenant-templates';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [
        MatIconModule,
        CommonModule,
        RouterModule,
        LanguageModule,
        MatButtonModule,
        TenantTemplatesModule,
        ModalLayoutModule,
        ButtonModule,
    ],
    declarations: [PreviewComponent, IntroViewComponent, PreviewItemComponent, VideoComponent],
    exports: [PreviewComponent, PreviewItemComponent, IntroViewComponent],
})
export class PreviewModule {}
