import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AuthenticatedGuard,
    AuthorizationModule,
    StaticTopicDefinition,
    TopicDefinition,
    TopicEventHandlers,
    TopicRegistryService,
    JOB_SHARING_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { JobXProfileViewModule } from './job-x-embeddable/jobx-profile-view/job-x-profile-view.module';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { JobXProfileViewComponent } from './job-x-embeddable/jobx-profile-view/job-x-profile-view.component';
import { JobXTopicEventHandlerService } from './job-x-topic-event-handler.service';
import { StoreModule } from '@ngrx/store';
import { JobXReducer } from './jobXReducer';
import { EffectsModule } from '@ngrx/effects';
import { JobXEffects } from './jobx.effects';
import { JobXProfileNavigatorViewComponent } from './job-x-embeddable/job-x-profile-navigator-view/job-x-profile-navigator-view.component';
import { JobXProfileNavigatorViewModule } from './job-x-embeddable/job-x-profile-navigator-view/job-x-profile-navigator-view.module';
import { JobXUserCardViewComponent } from './job-x-embeddable/job-x-user-card-view/job-xuser-card-view.component';
import { JobXUserCardViewModule } from './job-x-embeddable/job-x-user-card-view/job-xuser-card-view.module';
import { InfoToggleModule } from '@tploy-enterprise/tenant-common';
import { JobXTandemCheckViewModule } from './job-x-embeddable/job-x-tandem-check-view/job-x-tandem-check-view.module';
import { JobXTandemCheckViewComponent } from './job-x-embeddable/job-x-tandem-check-view/job-x-tandem-check-view.component';
import { UserRole } from '@tandemploy/common';

const lazyRoutes: Routes = [
    {
        path: 'job-sharing',
        loadChildren: () => import('./job-x-domain/job-x-domain.module').then((m) => m.JobXDomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'job-sharing',
        loadChildren: () => import('./job-x-entity/job-x-entity.module').then((m) => m.JobXEntityModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/job-sharing',
        loadChildren: () => import('./job-x-profile/job-x-profile-edit.module').then((m) => m.JobXProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/job-sharing',
        loadChildren: () => import('./job-x-profile/job-x-profile-add.module').then((m) => m.JobXProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/job-sharing',
        loadChildren: () =>
            import('./job-x-profile/job-x-profile-sign-up.module').then((m) => m.JobXProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
];

const jobXDefinition: StaticTopicDefinition = {
    name: JOB_SHARING_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: JobXProfileViewComponent,
            permission: 'job-sharing.entity.apply_to',
        },
        userCardView: {
            component: JobXUserCardViewComponent,
        },
        profileNavigatorView: {
            component: JobXProfileNavigatorViewComponent,
        },
        tandemCheckView: {
            component: JobXTandemCheckViewComponent,
        },
        sidenavItem: {
            link: ['/job-sharing'],
            editPermission: 'job-sharing.entity.apply_to',
            editLink: ['/', { outlets: { overlay: ['edit', 'job-sharing'] } }],
            label: 'JOBX',
            svgIcon: 'job_sharing_filled',
            referenceContext: 'job-sharing',
        },
        modelInformation: {
            svgIcon: 'job_sharing_filled',
            image: '/assets/images/topics/job-sharing.jpg',
            label: 'JOBX',
            content: 'JOBX_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['/sign-up', 'job-sharing'],
        signUpSequence: [['/', 'sign-up', 'job-sharing', 'interest']],
        edition: ['/', { outlets: { overlay: ['edit', 'job-sharing'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'job-sharing'] } }],
        addSequence: [[{ outlets: { overlay: ['add-topic', 'job-sharing', 'interest'] } }]],
        domain: ['job-sharing'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['job-sharing.entity.organize', 'job-sharing.entity.apply_to'],
        [UserRole.USER]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
        [UserRole.CONTRIBUTOR]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
        [UserRole.ADMIN]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
        [UserRole.ADMIN_MAIL]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
        [UserRole.STATISTICS]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
        [UserRole.STAFFING_MANAGER]: [
            'job-sharing.entity.organize',
            'job-sharing.entity.apply_to',
            'job-sharing.entity.administrate',
        ],
    },
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        InfoToggleModule,
        JobXProfileViewModule,
        JobXUserCardViewModule,
        JobXProfileNavigatorViewModule,
        JobXTandemCheckViewModule,
        StoreModule.forFeature('jobx', JobXReducer),
        EffectsModule.forFeature([JobXEffects]),
        AuthorizationModule.forFeature({ permissions: jobXDefinition.rolePermissions }),
    ],
})
export class JobXFeatureModule {
    constructor(private readonly injector: Injector) {
        const eventHandlers = injector.get(JobXTopicEventHandlerService);
        this.registerJobXTopic(eventHandlers);
    }

    private registerJobXTopic(eventHandler: TopicEventHandlers) {
        const topicRegistry = this.injector.get(TopicRegistryService);

        const definition: TopicDefinition = {
            ...jobXDefinition,
            eventHandlers: eventHandler,
        };
        topicRegistry.registerTopic(definition);
    }
}
