import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobXTandemCheckButtonComponent } from './job-x-tandem-check-button.component';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { ButtonModule } from '@tandemploy/ngx-components';
import { JobXTandemCheckViewComponent } from './job-x-tandem-check-view.component';

@NgModule({
    declarations: [JobXTandemCheckViewComponent, JobXTandemCheckButtonComponent],
    imports: [CommonModule, LanguageModule, ButtonModule],
})
export class JobXTandemCheckViewModule {}
