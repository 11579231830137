import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models';

export const DirectMessagesLeaveConversationActions = {
    // Leave Conversation Actions
    removeFromConversation: createAction(
        '[DM-Conversations] Leave Conversation Universal',
        props<{ conversationId: string; userId: string }>(),
    ),
    removeFromConversationSuccess: createAction(
        '[DM-Conversation][Success] Remove from Conversation',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    removeFromConversationError: createAction(
        '[DM-Conversation][Error] Remove from Conversation',
        props<{ error: Error }>(),
    ),

    leaveConversation: createAction('[DM-Conversations] Leave Conversation', props<{ conversationId: string }>()),
    leaveConversationSuccess: createAction(
        '[DM-Conversations][Success] Leave Conversation',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    leaveConversationError: createAction('[DM-Conversations][Error] Leave Conversation', props<{ error: Error }>()),

    leaveConversationOther: createAction(
        '[DM-Conversations] Leave Conversation Other',
        props<{ conversationId: string; userId: string }>(),
    ),
    leaveConversationOtherSuccess: createAction(
        '[DM-Conversations][Success] Leave Conversation Other',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    leaveConversationOtherError: createAction(
        '[DM-Conversations][Error] Leave Conversation Other',
        props<{ error: Error }>(),
    ),
};
