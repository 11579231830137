import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavHelpersDirective } from './sidenav-helpers.directive';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LanguageModule, FooterLinksModule, PoweredByModule, FocusModule } from '@tploy-enterprise/tenant-common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SidenavProfileEditComponent } from './sidenav/sidenav-profile-edit/sidenav-profile-edit.component';
import { SidenavTopicsComponent } from './sidenav/sidenav-topics/sidenav-topics.component';
import { SidenavToggleDirective } from './sidenav-toggle/sidenav-toggle.directive';
import { MainLayoutComponent } from './main-layout.component';
import { ImagePickerModule } from '../image-picker';
import { AuthorizationHelpersModule } from '../authorization/authorization-helpers.module';
import { MatBadgeModule } from '@angular/material/badge';
import { UserSearchModule } from '../search/user-search.module';
import { RouteTransitionLoaderModule } from '../route-transition-loader/route-transition-loader.module';
import { ButtonModule } from '@tandemploy/ngx-components';
import { ApplicantsIndicatorDirective } from './sidenav/sidenav-topics/applicants-indicator/applicants-indicator.directive';
import { ApplicantsIndicatorComponent } from './sidenav/sidenav-topics/applicants-indicator/applicants-indicator.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LanguageModule,
        MatButtonModule,
        FooterLinksModule,
        MatDividerModule,
        MatIconModule,
        MatSidenavModule,
        PoweredByModule,
        FocusModule,
        ImagePickerModule,
        AuthorizationHelpersModule,
        MatBadgeModule,
        UserSearchModule,
        ButtonModule,
        RouteTransitionLoaderModule,
    ],
    declarations: [
        MainLayoutComponent,
        SidenavComponent,
        SidenavHelpersDirective,
        SidenavToggleDirective,
        SidenavProfileEditComponent,
        SidenavTopicsComponent,
        ApplicantsIndicatorDirective,
        ApplicantsIndicatorComponent,
    ],
    exports: [
        MainLayoutComponent,
        SidenavComponent,
        SidenavHelpersDirective,
        MatSidenavModule,
        SidenavToggleDirective,
        LanguageModule,
        MatSidenavModule,
        UserSearchModule,
        ApplicantsIndicatorDirective,
        ApplicantsIndicatorComponent,
    ],
})
export class MainLayoutModule {}
