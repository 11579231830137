import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { AuthenticatedGuard } from './core-modules/authentication/authenticated.guard';
import { PreviewComponent } from './core-pages/preview/preview.component';
import { HelpComponent } from './core-pages/help';
import { PrivacyComponent } from './core-pages/privacy';
import { TermsComponent } from './core-pages/terms';
import { LegalComponent } from './core-pages/legal';
import { AccountResolver } from './core-modules/account/account.resolver';
import { EmptyAccountResolver } from './core-modules/account';
import { SkipLoginPageGuard } from './skip-login-page.guard';
import { OthersProfileResolver } from './core-pages/others-profile/others-profile.resolver';

const routes: Routes = [
    {
        path: 'login',
        data: { title: 'PAGE_TITLE_LOGIN' },
        loadChildren: () => import('./core-pages/login/login.module').then((m) => m.LoginPageModule),
        canActivate: [SkipLoginPageGuard],
    },
    {
        path: 'logout',
        data: { title: 'PAGE_TITLE_LOGOUT' },
        loadChildren: () => import('./core-pages/logout/logout.module').then((m) => m.LogoutPageModule),
        canActivate: [],
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./core-pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthenticatedGuard],
        data: { title: 'PAGE_TITLE_DASHBOARD' },
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'sign-up',
        data: { title: 'PAGE_TITLE_SIGNUP' },
        pathMatch: 'full',
        redirectTo: '/sign-up/welcome',
    },
    {
        path: 'sign-up/welcome',
        loadChildren: () =>
            import('./core-pages/welcome/sign-up/welcome-sign-up.module').then((m) => m.WelcomeSignUpModule),
        canActivate: [AuthenticatedGuard],
        resolve: {
            emptyAccount: EmptyAccountResolver,
        },
    },
    {
        path: 'sign-up/general-information',
        loadChildren: () =>
            import('./core-pages/general-information/sign-up/general-information-sign-up.module').then(
                (m) => m.GeneralInformationSignUpModule,
            ),
        resolve: {
            emptyAccount: EmptyAccountResolver,
        },
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/general-information',
        loadChildren: () =>
            import('./core-pages/general-information/edit/general-information-edit.module').then(
                (m) => m.GeneralInformationEditModule,
            ),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/topics',
        loadChildren: () =>
            import('./core-pages/topics/sign-up/topics-sign-up.module').then((m) => m.TopicsSignUpModule),
        canActivate: [AuthenticatedGuard],
        resolve: {
            emptyAccount: EmptyAccountResolver,
        },
    },
    {
        path: 'edit/topics',
        outlet: 'overlay',
        loadChildren: () => import('./core-pages/topics/edit/topics-edit.module').then((m) => m.TopicsEditModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/experience',
        outlet: 'overlay',
        loadChildren: () =>
            import('./core-pages/experience/edit/experience-edit.module').then((m) => m.ExperienceEditModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'bookmark',
        data: { title: 'PAGE_TITLE_BOOKMARKS' },
        loadChildren: () =>
            import('./core-pages/bookmarks-page/bookmarks-page.module').then((m) => m.BookmarksPageModule),
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'profile',
        pathMatch: 'full',
        loadChildren: () => import('./core-pages/my-profile/my-profile.module').then((m) => m.MyProfileModule),
        data: { title: 'PAGE_TITLE_PROFILE' },
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./core-pages/others-profile/others-profile.module').then((m) => m.OthersProfileModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./core-pages/settings/settings.module').then((m) => m.SettingsModule),
        data: { title: 'PAGE_TITLE_SETTINGS' },
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'administration',
        loadChildren: () =>
            import('./core-pages/administration/administration.module').then((m) => m.AdministrationModule),
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'information',
        loadChildren: () => import('./core-pages/information/information.module').then((m) => m.InformationModule),
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'direct-messages',
        loadChildren: () =>
            import('./core-pages/direct-messages/direct-messages.module').then((m) => m.DirectMessagesModule),
        data: { title: 'PAGE_TITLE_DIRECT_MESSAGES' },
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'questionnaire',
        loadChildren: () =>
            import('./core-pages/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'blog',
        loadChildren: () => import('./core-pages/blog/blog.module').then((m) => m.BlogModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'invitation',
        loadChildren: () => import('./core-pages/invitation/invitation.module').then((m) => m.InvitationModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
        resolve: {
            accountData: AccountResolver,
        },
    },
    {
        path: 'release-notes',
        loadChildren: () => import('./core-pages/release-notes/release-notes.module').then((m) => m.ReleaseNotesModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'footer/preview',
        outlet: 'overlay',
        component: PreviewComponent,
    },
    {
        path: 'footer/help',
        outlet: 'overlay',
        component: HelpComponent,
    },
    {
        path: 'footer/privacy',
        outlet: 'overlay',
        component: PrivacyComponent,
    },
    {
        path: 'footer/terms',
        outlet: 'overlay',
        component: TermsComponent,
    },
    {
        path: 'footer/imprint',
        outlet: 'overlay',
        component: LegalComponent,
    },
    {
        path: 'get-to-know/:id',
        loadChildren: () => import('./core-pages/get-to-know/get-to-know.module').then((m) => m.GetToKnowModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
        resolve: {
            ownAccount: AccountResolver,
            othersAccount: OthersProfileResolver,
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading,
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class TenantCoreRoutingModule {}
