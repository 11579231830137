import { createAction, props } from '@ngrx/store';
import { AccountSettings } from './account-settings.types';

export const AccountSettingsActions = {
    edit: createAction('[Account Notification Settings] edit', props<{ accountSettings: Partial<AccountSettings> }>()),
    save: createAction('[Account Notification Settings] save'),
    saveSuccess: createAction(
        '[Account Notification Settings][Success] save',
        props<{ accountSettings: AccountSettings }>(),
    ),
    saveError: createAction(
        '[Account Notification Settings][Error] save',
        props<{ error: Error; accountSettings: AccountSettings }>(),
    ),
};
