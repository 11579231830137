import { Injectable } from '@angular/core';
import { SelectedTopics } from './selected-topics.types';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SelectedTopicsSerializer } from './selected-topics.serializer';

const PATH = `/api/v2/accounts`;

@Injectable({
    providedIn: 'root',
})
export class SelectedTopicsService {
    constructor(private http: HttpClient, private serializer: SelectedTopicsSerializer) {}

    save(selectedTopics: SelectedTopics): Observable<SelectedTopics> {
        const dto = this.serializer.serialize(selectedTopics, true);
        return this.http.put(`${PATH}/selected-topics`, dto).pipe(map(() => selectedTopics));
    }
}
