import { Component } from '@angular/core';

@Component({
    selector: 'tp-details-language',
    templateUrl: './details-language.component.html',
    host: {
        class: 'details__language',
    },
})
export class DetailsLanguageComponent {}
