import { Component } from '@angular/core';

@Component({
    selector: 'tp-details-image-header',
    templateUrl: './details-image-header.component.html',
    host: {
        class: 'details__image-header',
    },
})
export class DetailsImageHeaderComponent {}
