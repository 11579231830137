export default {
    SHORT_TIME_ASSIGNMENT: 'Short Assignments',
    SHORT_TIME_ASSIGNMENT_SINGULAR: 'Short Assignment',
    SHORT_TIME_ASSIGNMENT_INFO:
        'Are you looking for the right person or team for a short assignment – possibly at very short notice? Both is easily feasible with the matching for short assignments. It is ideal for staffing short-term and temporary requirements, foreign assignments or job shadowings. If you are interested in taking part in short missions or are looking for a change of pace and would like to bring certain skills to bear, you should definitely select "Short Assignments" as your interest and keep up to date with the latest developments!',
    STA_ENTITY_CREATE: 'New Short Assignment',
    STA_PROFILE_TITLE: 'Short Assignments',
    STA_PROFILE_SUBTITLE: 'Your detail will help find suitable short assignments for you in the future.',
    STA_PROFILE_MOTIVATION_SUBTITLE:
        'Explore exciting Short Assignments and/or post your own ones to find the best fitting colleagues.',
    STA_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Offer short assignments and find suitable matches.',
    STA_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Stay informed about suitable assignments.',
    STA_MOTIVATION_VALIDATION_REQUIRED: 'You must select at least one option.',
    STA_PROFILE_SKILLS_LABEL: 'Skills you can contribute to short assignments',
    STA_PROFILE_CATEGORIES_INTERESTED_IN_LABEL: 'Areas, you are particularily interested in',
    STA_PROFILE_SKILLS_INTERESTED_IN_LABEL: 'Skills you would like to acquire during short assignments',

    STA_MATCHING_HEADER: 'Matches and Application',
    STA_MATCHING_DETAILS_1:
        'Below you can see all offers that might fit you based on your profile information. To apply for {{topicName}}, you must have all the required skills (',
    STA_MATCHING_DETAILS_2:
        ') in your profile. However, you can still add them during the application process. If certain skills are marked as "helpful" (',
    STA_MATCHING_DETAILS_3:
        '), they are desirable but not mandatory for the application. Your chances of participating increase the more of the skills you bring with you.',
    STA_ENTITY_LABEL_REQUIRED_SKILLS: 'Required skills',
    STA_ENTITY_LABEL_HELPFUL_SKILLS: 'Helpful skills',

    // ALIAS OF STA_PROFILE_SKILLS_LABEL
    STA_PROFILE_VIEW_SKILLS: 'Skills I can contribute',
    STA_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'What I would like to learn',
    STA_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Fields of interest',
    STA_PROFILE_VIEW_CAMPAIGNS_LABEL: 'HR programs',

    STA_ENTITY_EDIT_TITLE: 'Edit Short Assignment',
    STA_ENTITY_EDIT_BUTTON_DRAFT: 'Save draft',
    STA_ENTITY_EDIT_BUTTON_PUBLISH: 'Publish Short Assignment',
    STA_ENTITY_EDIT_BUTTON_SAVE: 'Save',
    STA_ENTITY_CREATE_TITLE: 'Create Short Assignment',
    STA_ENTITY_CREATE_BUTTON_DRAFT: 'Save draft',
    STA_ENTITY_CREATE_BUTTON_PUBLISH: 'Publish Short Assignment',
    STA_ENTITY_FORM_TITLE_LABEL: 'Title of Short Assignment',
    STA_ENTITY_FORM_START_DATE_LABEL: 'Start date',
    STA_ENTITY_FORM_END_DATE_LABEL: 'End date',
    STA_ENTITY_FORM_APPLICATION_DEADLINE_LABEL: 'Application deadline',
    STA_ENTITY_FORM_APPLICATION_DEADLINE_VALIDATION_MIN_MAX_ERROR:
        'Please choose an application deadline before or on the starting date.',
    STA_ENTITY_FORM_VENUE_LABEL: 'Location',
    STA_ENTITY_FORM_LANGUAGES_LABEL: 'Required language (expected from all applicants)',
    STA_ENTITY_FORM_SUBSIDIARY_LABEL: 'Subsidiary (offering the Short Assignment)',
    STA_ENTITY_FORM_DEPARTMENT_LABEL: 'Department',
    STA_ENTITY_FORM_TARGET_GROUPS_LABEL: 'Select special target groups',
    STA_ENTITY_FORM_CATEGORY_LABEL: 'For users generally interested in',
    STA_ENTITY_FORM_DESCRIPTION_LABEL: 'Description',
    STA_ENTITY_FORM_DESCRPTION_ERROR: 'The description needs to be at least 50 characters long.',
    STA_ENTITY_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unlimited (all are reached)',
    STA_ENTITY_FORM_SKILLS_HEADER: 'Skills wanted',
    STA_ENTITY_FORM_SKILLS_DESCRIPTION:
        'Please note: Marking many skills as "required" may reduce the number of applicants for your offer. Applicants must have all "required" skills listed in their profile in order to apply. On the other hand, if you don\'t include a single "required" skill, you may receive many applications that you don\'t feel are a good fit.\n' +
        '\n' +
        'Please click the information icon for more details.',

    STA_ENTITY_FORM_SKILLS_INFO_DESCRIPTION:
        '<h3>Required skills:</h3>' +
        '<p>' +
        'Skills that are mandatory to participate in your Short Assignment. Colleagues can only apply if they have specified these skills in their profile.' +
        '</p>' +
        '<h3>Helpful skills:</h3>' +
        '<p>' +
        'Skills that are not mandatory to participate in your Short Assignment. Colleagues can apply without having these skills in their profile.' +
        '</p>',
    STA_ENTITY_FORM_SKILLS_REQUIRED_ADDED_NOTIFICATION:
        'You set this skill as required for application.' +
        '\n' +
        'Note: The more skills you set as required the less colleagues can apply.',
    STA_ENTITY_FORM_CAMPAIGNS_LABEL: 'Belongs to the following program:',
    STA_ENTITY_FORM_CAMPAIGNS_PLACEHOLDER: 'choose..',

    STA_FORM_READY_STATUS: 'Yes! You are ready to apply!',
    STA_FORM_ALMOST_READY_STATUS: 'Almost ready to apply!',
    STA_FORM_NOT_READY_STATUS: 'Not ready to apply!',
    STA_FORM_HEADLINE_LANGUAGE: 'Required language',
    STA_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Add the required language',
    STA_FORM_HEADLINE_TARGET_GROUPS: 'Open for following groups',
    STA_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Add one group, if you belong to it',
    STA_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Select a function',
    STA_FORM_HEADLINE_STA_SKILLS: 'Check all of the following skills to participate',
    STA_FORM_REQUIRED_TO_ADD_STA_SKILLS: 'Check at least one of the following skills',
    STA_FORM_SKILL_TABLE_COL_CHECKED: 'Skill available?',
    STA_FORM_SKILL_TABLE_COL_SKILL_NAME: 'Skill',
    STA_FORM_SKILL_TABLE_COL_SKILL_INFORMATION: 'Information',
    STA_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Check this skill if you have it',

    STA_PUBLISH_DIALOG_TITLE: 'What happens after publishing an assignment?',
    STA_PUBLISH_DIALOG_CONTENT_1: 'Your short assignment will be online and colleagues get notified.',
    STA_PUBLISH_DIALOG_CONTENT_2:
        'Colleagues who are possibly interested in assignments will be matched and receive a notification. From now on they can register for the assignment until the application deadline is reached.',
    STA_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Publish Short Assignment',

    STA_PUBLISH_APPROVAL_TITLE: 'Create project assignment',
    STA_PUBLISH_APPROVAL_SUB_TITLE:
        'Your responsible human resources management must be informed about the project assignment.',
    STA_PUBLISH_APPROVAL_TEXT:
        'Tandemploy brings your skills and exciting offerings together. Of course, this is done in compliance with labor law procedures that must be followed in case of a transfer or a project abroad. In the case of projects abroad that exceed the duration of a business trip, appropriate lead times for administration must be taken into account. If you have any questions, please contact your manager or your human resources department.',
    STA_PUBLISH_APPROVAL_OPTIONS_LABEL:
        'I have discussed the legal framework and the involvement of co-determination in the publishing and staffing of this project assignment with my responsible HR management.',
    STA_PUBLISH_APPROVAL_OPTIONS_YES_LABEL: 'Yes',
    STA_PUBLISH_APPROVAL_OPTIONS_NO_LABEL: 'Not yet',
    STA_PUBLISH_APPROVAL_PUBLISH_BTN_TITLE: 'Publish now!',

    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Create your first Short Assignment',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'This list is empty, to create a new short assignment, click the button and fill in the form on the next page.',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_WORKSHOP_BTN: 'Create Short Assignment',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Invite colleagues',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Invite colleagues to join this platform.',
    STA_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Invite',
    STA_OFFERED_BY_ME_PLANNED_HEADER: 'Planned',
    STA_OFFERED_BY_ME_RUNNING_HEADER: 'Running',
    STA_OFFERED_BY_ME_COMPLETE_HEADER: 'Complete',
    STA_OFFERED_BY_ME_CONTINUE_EDIT: 'Continue editing',
    STA_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Message participants',
    STA_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'View details',
    STA_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Unpublished',
    STA_OFFERED_BY_ME_START_DATE_INDICATOR_LABEL: 'Starts at',
    STA_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'Ends at',
    STA_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Date',
    STA_OFFERED_BY_ME_APPLICANTS_INDICATOR_LABEL: 'Applicants',
    STA_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Last version from',
    STA_OFFERED_BY_ME_CONTACT_LABEL: 'Contact',
    STA_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Delete Short Assignment',
    STA_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Are you sure, that you want to delete this Short Assignment?',
    STA_OFFERED_BY_ME_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Are you sure you want to irrevocably delete this offer including potential applicants and/or participants? If you delete it, all users involved will be notified accordingly. The offer can then no longer be accessed.',

    STA_SEARCH_HEADER: 'Search for Short Assignments by keywords',
    STA_SEARCH_BTN_TEXT: 'Search!',
    STA_SEARCH_RESULT_REQUESTED_SKILLS: 'Wanted skills',
    STA_SEARCH_RESULT_VIEW_ENTITY: 'View Details',
    STA_SEARCH_RESULT_AVATAR_LABEL: 'Contact',
    STA_SEARCH_EMPTY_MESSAGE:
        'Unfortunately nothing was found. Tip: Searching without a search term shows all currently available offers.',

    STA_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalization',
    STA_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Customer Centricity',
    STA_PROFILE_CATEGORY_SUSTAINABILITY: 'Sustainability',
    STA_PROFILE_CATEGORY_MOBILITY: 'Mobility',
    STA_PROFILE_CATEGORY_COMMERCIAL: 'Commercial',
    STA_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Airline Product',
    STA_PROFILE_CATEGORY_IT_TOPICS: 'IT Topics',
    STA_PROFILE_CATEGORY_COMMUNICATION: 'Communication',
    STA_PROFILE_CATEGORY_SOCIAL: 'Social',
    STA_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methods',
    STA_PROFILE_CATEGORY_LEAN_METHODS: 'LEAN Methods',
    STA_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Sharing personal skills',
    STA_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    STA_DOMAIN_APPLIED_TO_TAB: 'My Short Assignments',
    STA_DOMAIN_SEARCH_TAB: 'Search Short Assignments',
    STA_DOMAIN_OFFERED_BY_ME_TAB: 'Offered by me',
    STA_DOMAIN_STAFFING: 'Staffing',

    STA_PROFILE_NO_MATCH_IMG_ALT: 'No matches found',
    STA_PROFILE_NO_MATCH_CONTENT: 'Please specify more skills and (hidden?) talents in your profile to find matches',
    STA_PROFILE_NO_MATCH_CTA: 'Edit skills',
    STA_PROFILE_NO_MATCH_FIND_CONTENT: 'Find more Short Assignments by using the search',
    STA_PROFILE_SEARCH_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    STA_DOMAIN_PROFILE_MATCH_SKILLS: 'Wanted Skills',
    STA_PROFILE_MATCH_VIEW_ENTITY: 'View details',
    STA_DOMAIN_PROFILE_MATCH_AVATAR_LABEL: 'Assignment lead',

    STA_APPLIED_TO_PLACHOLDER_TEXT: 'You have not applied for Short Assignments yet.',
    STA_APPLIED_TO_PLACHOLDER_BTN: 'Find matching Short Assignments',
    STA_APPLIED_TO_CONTACT_LABEL: 'Contact',
    STA_APPLIED_TO_VIEW_DETAILS_BTN_TITLE: 'View details',
    STA_APPLIED_TO_VIEW_APPLICATION_BTN_TITLE: 'View application',
    STA_APPLIED_TO_NOT_ACCEPTED_LABEL: 'Your application is complete. \nYou will be notified of the decision by e-mail',
    STA_APPLIED_TO_ACCEPTED_LABEL: 'Congratulations! \nYour applications was successful.',
    STA_APPLIED_TO_CANCELLED_LABEL: 'You didn’t get a seat. \nYour application was not successful.',
    STA_APPLIED_TO_REJECTED_LABEL: 'Participation declined. \nSubsequent cancellation by provider.',
    STA_APPLIED_TO_PLANNED_HEADER: 'Applied for',
    STA_APPLIED_TO_RUNNING_HEADER: 'Ongoing Short assignments',
    STA_APPLIED_TO_COMPLETE_HEADER: 'Finished',
    STA_APPLIED_TO_END_DATE_INDICATOR_LABEL: 'Ends at',
    STA_APPLIED_TO_APPLICANTS_INDICATOR_LABEL: 'Participants',
    STA_APPLIED_TO_DATE_RANGE_INDICATOR_LABEL: 'Date',

    STA_DETAILS_VIEW_DESCRIPTION_TITLE: 'Description',
    STA_DETAILS_VIEW_REQUIRED_SKILLS_TITLE: 'Required skills',
    STA_DETAILS_VIEW_ORGANIZER_LABEL: 'Contact',

    STA_FORM_HEADLINE_STA_SKILLS_TITLE: 'Your Skills',
    STA_FORM_HEADLINE_STA_SKILLS_DESCRIPTION:
        'To participate in this {{topicName}}, you must have all of the skills listed below. If you are unsure whether your knowledge is sufficient, please write a message to the {{topicName}} organizer.',
    STA_FORM_HEADLINE_NICE_TO_HAVE_SKILLS: 'Helpful skills',

    STA_ENTITY_PARTICIPANTS_SKILL_SUMMARY_LABEL: 'Needed skills for this Short Assignment are:',
    STA_ENTITY_PARTICIPANTS_TITLE: 'Applicants list',
    STA_ENTITY_VIEW_PARTICIPATE_BUTTON: 'Apply',
    STA_ENTITY_VIEW_APPLIED_STATUS_NOTE: 'You have already applied for this short assignment',
    STA_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'You can apply for {{topicEntity}} by enabling the <i>>>first option in your profile</i> and therefore indicate that your are also interested in participating',

    STA_ENTITY_APPLICATIONS_IDENTITY_HEADER: 'Applicant',
    STA_ENTITY_APPLICATIONS_SKILLS_HEADER: 'Skills of applicant',
    STA_ENTITY_APPLICATIONS_INTERESTED_IN_SKILLS_HEADER: 'Related term',
    STA_ENTITY_APPLICATIONS_STATUS_HEADER: 'Accept',
    STA_ENTITY_APPLICATIONS_ACCEPT: 'Accept',
    STA_ENTITY_APPLICATIONS_ACCEPTED: 'accepted',
    STA_ENTITY_APPLICATIONS_REFUSED: 'refused',
    STA_ENTITY_APPLICATIONS_CANCELLED: 'cancelled',
    STA_ENTITY_APPLICATIONS_COMPLETE_STAFFING: 'Complete staffing now',
    STA_ENTITY_APPLICATIONS_STAFFING_COMPLETED: 'Staffing complete',
    STA_ENTITY_APPLICATIONS_NO_MATCHED_SKILLS: 'Application without matching skills',

    STA_ENTITY_APPLICATIONS_SEARCH_TITLE: 'Search for matches yourself',
    STA_ENTITY_APPLICATIONS_SEARCH_INPUT_VALIDATION_REQUIRED: 'Please enter at least one skill you are looking for.',
    STA_ENTITY_APPLICATIONS_SEARCH_INPUT_SEARCH_BUTTON: 'Search',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFY_BUTTON: 'notify',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFIED: 'notified',
    STA_ENTITY_APPLICATIONS_SEARCH_RESULTS_NO_MATCHES_FOUND: 'No matches found.',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_TITLE: 'Send a note about this {{topicName}}',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SUB_TITLE: 'Inform the selected person easily with one click.',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE_LABEL: 'The following message is sent by e-mail:',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE:
        'A new {{name_sta_singular}} perfectly matches your skills and interests! The {{name_sta_singular}} owner has found you on {{platform_name}} and would be thrilled to have you on board. Log into your account to learn about the details and get in touch right away.',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SEND_NOTE_BUTTON: 'Send note',
    STA_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK: 'Note has been sent.',

    STA_ENTITY_APPLICATIONS_SKILLS_AND_LEVELS_HEADER: 'Skills of applicant',

    STA_ENTITY_APPLICATION_FEEDBACK_DIALOG_BTN: 'Done',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADLINE: 'You didn’t get a seat!',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADING: 'Your application was not successful!',
    STA_ENTITY_APPLICATION_FEEDBACK_REJECTED_TEXT:
        'This time, it didn’t work out. You will be informed about suitable new offers.',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADLINE: 'Thank you for your interest',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADING: 'Your application has been received.',
    STA_ENTITY_APPLICATION_FEEDBACK_PENDING_TEXT:
        'As soon as the staffing has been decided, you will be informed. Thank you for your patience!',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADLINE: 'Thank you for your application',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADING: 'You’re in!',
    STA_ENTITY_APPLICATION_FEEDBACK_APPROVED_TEXT: 'Your application was successful.',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADLINE: 'Canceled retroactively',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADING: 'Your participation was cancelled',
    STA_ENTITY_APPLICATION_FEEDBACK_CANCELLED_TEXT:
        'Unfortunately your planned participation had to be cancelled afterwards. Please refer directly to the contact person if you have any questions. Thank you for your understanding!',

    STA_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_TITLE: 'Please Confirm',
    STA_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_CONTENT:
        'Ready to get started? It seems you have found the right talent for your short assignment and like to complete the  staffing now. Important: If you confirm now, all unaccepted applicants will receive a rejection and no more people can be added to this short assignment. You can clarify further details with the accepted applicants in the group chat.\nComplete the casting now?',

    STA_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_TITLE: 'Accept {{userName}} now',
    STA_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_CONTENT:
        'Select "Accept" to admit the applicant. The person will then be automatically informed by e-mail about the successful application.',

    STA_ENTITY_REQUIRED_SKILLS_TITLE: 'Skills',
    STA_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        "Here you can see all the skills that are sought for this Short Assignment. If you still discover skills that you actually own and want to contribute, but don't have them in your profile yet, you can select them here and add them to your profile.",

    STA_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Thank you very much for your interest!',
    STA_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE: 'Your manager has to agree to your participation',
    STA_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Tandemploy brings your skills and exciting offerings together. Of course, this is done in compliance with labor law procedures that must be followed in case of a transfer or an assignment abroad. In the case of assignments abroad that exceed the duration of a business trip, appropriate lead times for administration must be taken into account. If you have any questions, please contact your manager or your human resources department.',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL:
        'I hereby confirm that I have informed my manager and that he/she agrees to my participation.',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Yes',
    STA_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'Not yet',

    STA_SKILLS_LABEL: 'Wanted skills',
    STA_SKILLS_LEVEL_LABEL: 'Preferred level',

    STA_VALIDATION_ERROR_MAX_DURATION: 'Maximum duration 6 months',

    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_QUESTION: 'Sure you want to reject this applicant already?',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_YES: 'Yes',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_NO: 'No',
    STA_ENTITY_APPLICATION_REJECT_TOOLTIP_MORE: 'What does it mean?',
    STA_ENTITY_APPLICATION_REJECT_INFO_TITLE: 'Reject applications in advance',
    STA_ENTITY_APPLICATION_REJECT_INFO_CONTENT:
        'Applications can be rejected early if it is already clear that the candidate has rather poor chances. This has the advantage that those affected know earlier and can plan accordingly. In addition, the list of applications remains clearer.',

    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_QUESTION: 'Reject this person in hindsight?',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_YES: 'Yes',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_NO: 'No',
    STA_ENTITY_APPLICATION_CANCEL_TOOLTIP_MORE: 'What does that mean?',
    STA_ENTITY_APPLICATION_CANCEL_INFO_TITLE: 'Cancel application in hindsight',
    STA_ENTITY_APPLICATION_CANCEL_INFO_CONTENT:
        'Applications can be cancelled after you have already accepted applicants. They will be informed about it via mail. For further explanations it is recommended to write a personal message. ',

    STA_STAFFING_FILTER_STATUS_LABEL: 'Status',
    STA_STAFFING_FILTER_STAFFING_LABEL: 'Staffing',
    STA_STAFFING_FILTER_STAFFING_OPTION_ON: 'open for applications',
    STA_STAFFING_FILTER_STAFFING_OPTION_OFF: 'application deadline exceeded',
    STA_STAFFING_FILTER_CATEGORY_LABEL: 'Thematic Category',
    STA_STAFFING_FILTER_LANGUAGE_LABEL: 'Language',
    STA_STAFFING_NO_RESULTS_PLACEHOLDER: 'There are no results for your selected filters.',

    STA_PROFILE_CAMPAIGNS_LABEL: 'HR programs you participate in',
    STA_PROFILE_CAMPAIGNS_PLACEHOLDER: 'choose..',

    PAGE_TITLE_SHORT_ASSIGNMENT_MATCHES: 'Short assignment matches',
    PAGE_TITLE_SHORT_ASSIGNMENT_MY: 'My short assignments',
    PAGE_TITLE_SHORT_ASSIGNMENT_SEARCH: 'Search short assignments',
    PAGE_TITLE_SHORT_ASSIGNMENT_OFFERED: 'Short assignments offered by me',

    ORIGIN_TOPIC_STA_PRIVATE: 'Short Assignments',
    ORIGIN_TOPIC_STA_PARTICIPANT: 'Short Assignments',

    SHORT_TIME_ASSIGNMENT_ORGANIZERS_INFO:
        'An offer can have several organizers, so that they can promote this offer in parallel or in the absence of other organizers. Here you can define who else can edit and delete this {{name_sta_singular}} and send messages to participants. The users listed here can add and delete organizers, too. ',
};
