import { Injectable, InjectionToken, Inject, Type } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnack, NotificationAction, NotificationMailTo } from './notifications.types';
import { takeUntil } from 'rxjs/operators';

const snackConfig: MatSnackBarConfig = {
    verticalPosition: 'top',
    horizontalPosition: 'right',
    duration: 5000,
};

export const DEFAULT_SNACK_COMPONENT = new InjectionToken('DEFAULT_SNACK_COMPONENT');
export const ACTION_SNACK_COMPONENT = new InjectionToken('ACTION_SNACK_COMPONENT');
export const IMPORTANT_SNACK_COMPONENT = new InjectionToken('IMPORTANT_SNACK_COMPONENT');
export const ERROR_SNACK_COMPONENT = new InjectionToken('ERROR_SNACK_COMPONENT');

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private snackBarRef: MatSnackBarRef<CustomSnack>;

    constructor(
        private snack: MatSnackBar,
        @Inject(DEFAULT_SNACK_COMPONENT)
        private defaultSnackType: Type<CustomSnack>,
        @Inject(ACTION_SNACK_COMPONENT) private actionSnackType: Type<CustomSnack>,
        @Inject(IMPORTANT_SNACK_COMPONENT)
        private importantSnackType: Type<CustomSnack>,
        @Inject(ERROR_SNACK_COMPONENT) private errorSnackType: Type<CustomSnack>,
    ) {}

    openDefaultSnack(message: string, messageParams?: any, themeDark = true, icon?: string) {
        this.snackBarRef = this.snack.openFromComponent(this.defaultSnackType, {
            ...snackConfig,
            panelClass: themeDark ? 'tp-theme-dark-snack' : 'tp-theme-light-snack',
            data: { message, messageParams, icon },
        });

        this.listenToCloseEvents();
    }

    openDefaultSnackAtBottom(message: string, messageParams?: any, themeDark = true, icon?: string) {
        this.snackBarRef = this.snack.openFromComponent(this.defaultSnackType, {
            ...snackConfig,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            duration: 4000,
            panelClass: themeDark ? 'tp-theme-dark-snack' : 'tp-theme-light-snack',
            data: { message, messageParams, icon },
        });

        this.listenToCloseEvents();
    }

    openActionSnack(message: string, action: NotificationAction, messageParams?: any, themeDark = true) {
        this.snackBarRef = this.snack.openFromComponent(this.actionSnackType, {
            ...snackConfig,
            duration: 0,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ['container--wide', themeDark ? 'tp-theme-dark-snack' : 'tp-theme-light-snack'],
            data: { message, action, messageParams },
        });

        this.listenToCloseEvents();
    }

    openImportantSnack(message: string, messageParams?: any, themeDark = true) {
        this.snackBarRef = this.snack.openFromComponent(this.importantSnackType, {
            ...snackConfig,
            duration: 0,
            panelClass: themeDark ? 'tp-theme-dark-snack' : 'tp-theme-light-snack',
            data: { message, messageParams },
        });

        this.listenToCloseEvents();
    }

    openErrorSnack(message: string, mailto?: NotificationMailTo, messageParams?: any, themeDark = true) {
        this.snackBarRef = this.snack.openFromComponent(this.errorSnackType, {
            ...snackConfig,
            panelClass: themeDark ? 'tp-theme-dark-snack' : 'tp-theme-light-snack',
            data: { message, mailto, messageParams },
            duration: 0,
        });

        this.listenToCloseEvents();
    }

    open(snack: Type<any>): void {
        this.snackBarRef = this.snack.openFromComponent(snack, {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: 5000,
            panelClass: 'tp-theme-dark-snack',
        });

        this.listenToCloseEvents();
    }

    dismiss() {
        if (this.snackBarRef) {
            this.snackBarRef.dismiss();
            this.snackBarRef = null;
        }
    }

    private listenToCloseEvents() {
        this.snackBarRef.instance.close
            .pipe(takeUntil(this.snackBarRef.afterDismissed()))
            .subscribe(() => this.dismiss());
    }
}
