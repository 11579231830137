import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-text',
    templateUrl: './card-text.component.html',
    host: {
        class: 'card__text',
    },
})
export class CardTextComponent {}
