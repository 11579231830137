import { ConfigService, GeneralData, GeneralDataDTO, LocationSerializer } from '@tploy-enterprise/tenant-core';
import {
    STAApplication,
    STAApplicationIndicators,
    STAApplicationIndicatorsDTO,
    STAEntity,
    StaEntityHighlight,
    STAOthersProfile,
    STAProfile,
    STAProfileInvitationMessage,
    STAProfileMatch,
    STAProfileMatchHighlight,
    STAProfileSearchResult,
    STASearchResult,
    STASearchResultHighlight,
} from './sta.types';
import {
    STAApplicationDTO,
    STAEntityDTO,
    STAProfileDTO,
    STAProfileInvitationMessageDTO,
    STAProfileMatchDTO,
    STAProfileMatchHighlightDTO,
    STASearchResultDTO,
    STASearchResultHighlightDTO,
} from './sta-api/sta.dtos';
import { Injectable } from '@angular/core';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class STASerializer {
    constructor(
        private readonly skillsSerializer: SkillsSerializer,
        private readonly locationSerializer: LocationSerializer,
        private readonly configService: ConfigService,
    ) {}

    serializeSTAProfile(profile: STAProfile, user: GeneralData): STAProfileDTO {
        return {
            user: {
                name: user.name,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            campaigns: profile.campaigns,
            skills: this.skillsSerializer.serialize(profile.skills),
            skillsInterestedIn: this.skillsSerializer.serialize(profile.skillsInterestedIn),
            categoriesInterestedIn: profile.categoriesInterestedIn,
            motivations: profile.motivations,
            pushToSuccessFactor: profile.pushToSuccessFactor || false,
        };
    }

    deserializeSTAProfile(dto: STAProfileDTO): STAProfile {
        return {
            skills: this.skillsSerializer.deserialize(dto.skills),
            campaigns: dto.campaigns,
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn).map((skill) => skill.name),
            categoriesInterestedIn: dto.categoriesInterestedIn,
            motivations: dto.motivations,
            pushToSuccessFactor: dto.pushToSuccessFactor || false,
        };
    }

    deserializeSTAOthersProfile(dto: STAProfileDTO): STAOthersProfile {
        return {
            user: dto.user,
            skills: this.skillsSerializer.deserialize(dto.skills),
            campaigns: dto.campaigns,
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn).map((skill) => skill.name),
            categoriesInterestedIn: dto.categoriesInterestedIn,
            motivations: dto.motivations,
            pushToSuccessFactor: dto.pushToSuccessFactor || false,
        };
    }

    serializeSTAEntity(staEntity: STAEntity): STAEntityDTO {
        return {
            id: staEntity.id,
            title: staEntity.title,
            categories: staEntity.categories,
            languages: staEntity.languages,
            description: staEntity.description,
            company: staEntity.company,
            campaigns: staEntity.campaigns,
            department: staEntity.department,
            startDate: staEntity.startDate ? staEntity.startDate.toISOString() : undefined,
            endDate: staEntity.endDate ? staEntity.endDate.toISOString() : undefined,
            applicationCloseAt: staEntity.applicationCloseAt ? staEntity.applicationCloseAt.toISOString() : undefined,
            requestedSkills: this.skillsSerializer.serialize(staEntity.requestedSkills), // save current array position
            selectedGroups: staEntity.selectedGroups,
            location: { name: staEntity.location },
            imageUrl: staEntity.imageUrl.split(this.configService.remoteSettings.domain).pop(),
            customConditions: staEntity.customConditions,
            published: staEntity.published,
            applications: staEntity.applications ? staEntity.applications.map((a) => this.serializeApplication(a)) : [],
            canUserApply: staEntity.canUserApply,
            isApplicationForUserPendingOrApproved: staEntity.isApplicationForUserPendingOrApproved,
            staffingCompleted: staEntity.staffingCompleted,
            draft: staEntity.draft,
            hasUnseenApplications: staEntity.hasUnseenApplications,
            organizers: staEntity.organizers.map((organizer) => organizer.id),
        };
    }

    deserializeSTAEntity(dto: STAEntityDTO): STAEntity {
        return {
            id: dto.id,
            title: dto.title,
            categories: dto.categories,
            languages: dto.languages,
            description: dto.description,
            company: dto.company,
            campaigns: dto.campaigns,
            department: dto.department,
            startDate: dto.startDate ? new Date(dto.startDate) : undefined,
            endDate: dto.endDate ? new Date(dto.endDate) : undefined,
            applicationCloseAt: dto.applicationCloseAt ? new Date(dto.applicationCloseAt) : undefined,
            requestedSkills: this.skillsSerializer.deserialize(dto.requestedSkills), // strip the position property
            selectedGroups: dto.selectedGroups,
            location: dto.location.name,
            imageUrl: dto.imageUrl,
            customConditions: dto.customConditions,
            published: dto.published,
            applications: dto.applications ? dto.applications.map((a) => this.deserializeApplication(a)) : [],
            organizers: dto.organizersDecorated,
            lastUpdatedAt: dto.lastUpdatedAt,
            canUserApply: dto.canUserApply,
            applicationStatus: dto.applicationStatus,
            isApplicationForUserPendingOrApproved: dto.isApplicationForUserPendingOrApproved,
            staffingCompleted: dto.staffingCompleted,
            draft: dto.draft,
            numberOfApplications: dto.numberOfApplications,
            hasUnseenApplications: dto.hasUnseenApplications,
            highlighted: this.deserializeSTAEntityHighlight(dto.highlighted),
            applicationSelectedSkills: dto.applicationSelectedSkills,
        };
    }

    serializeApplication(application: STAApplication): STAApplicationDTO {
        return {
            id: application.id,
            name: application.name,
            userId: application.userId,
            status: application.status,
            appliedAt: application.appliedAt,
            approvedBy: application.approvedBy,
            approvedAt: application.approvedAt,
            rejectedAt: application.rejectedAt,
            rejectedBy: application.rejectedBy,
        };
    }

    deserializeApplication(dto: STAApplicationDTO): STAApplication {
        return {
            ...dto,
        };
    }

    deserializeSTASearchResult(dto: STASearchResultDTO): STASearchResult {
        dto.entity.organizersDecorated = dto.entity.organizersDecorated ?? dto.organizersDecorated;
        return {
            staId: dto.id,
            staEntity: this.deserializeSTAEntity(dto.entity),
            organizers: dto.organizersDecorated,
            highlight: this.deserializeSTASearchResultHighlight(dto.highlight),
        };
    }

    private deserializeSTASearchResultHighlight(dto: STASearchResultHighlightDTO): STASearchResultHighlight {
        if (!dto) {
            return { categories: [], description: [], location: [], skillsOptional: [], skillsRequired: [], title: [] };
        }

        return {
            skillsOptional: dto.skillsOptional,
            skillsRequired: dto.skillsRequired,
            location: dto.location,
            categories: dto.categories,
            title: dto.title,
            description: dto.description,
        };
    }

    deserializeSTAProfileMatch(dto: STAProfileMatchDTO): STAProfileMatch {
        return {
            staId: dto.id,
            highlight: this.deserializeSTAProfileMatchHighlight(dto.highlight),
            staEntity: this.deserializeSTAEntity(dto.entity),
            organizers: dto.organizersDecorated,
        };
    }

    private deserializeSTAProfileMatchHighlight(dto: STAProfileMatchHighlightDTO): STAProfileMatchHighlight {
        if (!dto) {
            return { categories: [], mustHaveSkills: [], niceToHaveSkills: [] };
        }

        return {
            niceToHaveSkills: dto.niceToHaveSkills,
            mustHaveSkills: dto.mustHaveSkills,
            categories: dto.categories,
        };
    }

    deserializeIndicators(dto: STAApplicationIndicatorsDTO): STAApplicationIndicators {
        return {
            hasUnseenApplicationsOfferingTab: dto.hasUnseenApplicationsOfferingTab,
            hasUnseenApplicationsTopicLink: dto.hasUnseenApplicationsTopicLink,
            shortTimeAssignmentsWithUnseenApplications: dto.shortTimeAssignmentsWithUnseenApplications,
        };
    }

    private deserializeSTAEntityHighlight(dto: StaEntityHighlight): StaEntityHighlight {
        if (!dto) {
            return {
                categories: [],
                mustHaveSkills: [],
                mustHaveSkillsWithRelated: [],
                niceToHaveSkills: [],
                niceToHaveSkillsWithRelated: [],
            };
        }

        return {
            categories: dto.categories,
            mustHaveSkills: dto.mustHaveSkills,
            mustHaveSkillsWithRelated: dto.mustHaveSkillsWithRelated,
            niceToHaveSkills: dto.niceToHaveSkills,
            niceToHaveSkillsWithRelated: dto.niceToHaveSkillsWithRelated,
        };
    }

    serializeSTAProfileInvitationMessage(
        invitationMessage: STAProfileInvitationMessage,
    ): STAProfileInvitationMessageDTO {
        return {
            shortTimeAssignmentId: invitationMessage.staEntity.id,
            shortTimeAssignmentTitle: invitationMessage.staEntity.title,
            applicantId: invitationMessage.profile.user.id,
            applicantName: invitationMessage.profile.user.name,
            messageContent: invitationMessage.messageContent,
        };
    }

    deserializeSTAProfileSearchResult(dto: STAProfileDTO): STAProfileSearchResult {
        return {
            profile: this.deserializeSTAOthersProfile(dto),
            notified: dto.notified,
        };
    }

    deserializeSTAUserSearchResult(dto: Partial<GeneralDataDTO>): Partial<GeneralData> {
        return {
            id: dto.id,
            name: dto.name,
            imageUrl: dto.imageUrl,
        };
    }
}
