import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-cta',
    templateUrl: './card-cta.component.html',
    host: {
        class: 'card__cta',
    },
})
export class CardCtaComponent {}
