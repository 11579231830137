import { NgModule, Injector } from '@angular/core';
import {
    TopicRegistryService,
    TopicDefinition,
    StaticTopicDefinition,
    AuthenticatedGuard,
    AuthorizationModule,
    GENERATION_EXCHANGE_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { RouterModule, Routes } from '@angular/router';
import { GenerationExchangeTopicEventHandlerService } from './generation-exchange-topic-event-handler.service';
import { GenerationExchangeProfileNavigatorViewComponent } from './generation-exchange-embeddable/generation-exchange-profile-navigator-view/generation-exchange-profile-navigator-view.component';
import { GenerationExchangeProfileNavigatorViewModule } from './generation-exchange-embeddable/generation-exchange-profile-navigator-view/generation-exchange-profile-navigator-view.module';
import { GenerationExchangeProfileViewComponent } from './generation-exchange-embeddable/generation-exchange-profile-view/generation-exchange-profile-view.component';
import { GenerationExchangeProfileViewModule } from './generation-exchange-embeddable/generation-exchange-profile-view/generation-exchange-profile-view.module';
import { GenerationExchangeReducer } from './generation-exchange.reducer';
import { GenerationExchangeEffects } from './generation-exchange.effects';
import { GenerationExchangeUserCardViewComponent } from './generation-exchange-embeddable/generation-exchange-bookmarks-page-list-item/generation-exchange-user-card-view.component';
import { GenerationExchangeUserCardViewModule } from './generation-exchange-embeddable/generation-exchange-bookmarks-page-list-item/generation-exchange-user-card-view.module';
import { UserRole } from '@tandemploy/common';

const lazyRoutes: Routes = [
    {
        path: 'generation-exchange',
        loadChildren: () =>
            import('./generation-exchange-domain/generation-exchange-domain.module').then(
                (m) => m.GenerationExchangeDomainModule,
            ),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/generation-exchange',
        loadChildren: () =>
            import('./generation-exchange-profile/generation-exchange-profile-sign-up.module').then(
                (m) => m.GenerationExchangeProfileSignUpModule,
            ),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/generation-exchange',
        loadChildren: () =>
            import('./generation-exchange-profile/generation-exchange-profile-add.module').then(
                (m) => m.GenerationExchangeProfileAddModule,
            ),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/generation-exchange',
        loadChildren: () =>
            import('./generation-exchange-profile/generation-exchange-profile-edit.module').then(
                (m) => m.GenerationExchangeProfileEditModule,
            ),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
];

const GenerationExchangeTopicDefinition: StaticTopicDefinition = {
    name: GENERATION_EXCHANGE_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: GenerationExchangeProfileViewComponent,
            permission: 'generation-exchange.default',
        },
        profileNavigatorView: {
            component: GenerationExchangeProfileNavigatorViewComponent,
        },
        userCardView: {
            component: GenerationExchangeUserCardViewComponent,
        },
        sidenavItem: {
            link: ['/generation-exchange'],
            editPermission: 'generation-exchange.default',
            editLink: ['/', { outlets: { overlay: ['edit', 'generation-exchange'] } }],
            label: 'GENERATION_EXCHANGE',
            svgIcon: 'generation_exchange_filled',
            referenceContext: 'generationExchange',
        },
        modelInformation: {
            svgIcon: 'generation_exchange_filled',
            image: '/assets/images/topics/generation-exchange.jpg',
            label: 'GENERATION_EXCHANGE',
            content: 'GENERATION_EXCHANGE_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['sign-up', 'generation-exchange'],
        signUpSequence: [['/', 'sign-up', 'generation-exchange']],
        edition: ['/', { outlets: { overlay: ['edit', 'generation-exchange'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'generation-exchange'] } }],
        addSequence: [[{ outlets: { overlay: ['add-topic', 'generation-exchange'] } }]],
        domain: ['generation-exchange'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['generation-exchange.default'],
        [UserRole.USER]: ['generation-exchange.default'],
        [UserRole.CONTRIBUTOR]: ['generation-exchange.default'],
        [UserRole.ADMIN]: ['generation-exchange.default'],
        [UserRole.ADMIN_MAIL]: ['generation-exchange.default'],
        [UserRole.STATISTICS]: ['generation-exchange.default'],
        [UserRole.STAFFING_MANAGER]: ['generation-exchange.default'],
    },
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        GenerationExchangeProfileViewModule,
        GenerationExchangeProfileNavigatorViewModule,
        GenerationExchangeUserCardViewModule,
        StoreModule.forFeature('generationExchange', GenerationExchangeReducer),
        EffectsModule.forFeature([GenerationExchangeEffects]),
        AuthorizationModule.forFeature({ permissions: GenerationExchangeTopicDefinition.rolePermissions }),
    ],
})
export class GenerationExchangeFeatureModule {
    constructor(private readonly injector: Injector) {
        this.registerGenerationExchangeTopic();
    }

    private registerGenerationExchangeTopic(): void {
        const eventHandlers = this.injector.get(GenerationExchangeTopicEventHandlerService);
        const definition: TopicDefinition = {
            ...GenerationExchangeTopicDefinition,
            eventHandlers,
        };
        const topicRegistry = this.injector.get(TopicRegistryService);
        topicRegistry.registerTopic(definition);
    }
}
