import { NgModule } from '@angular/core';
import { PaginatorComponent } from './paginator.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { PaginatorIntlService } from './paginator-intl.service';
import { CommonModule } from '@angular/common';
import { PaginatorService } from './paginator.service';
import { PaginatorModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [MatPaginatorModule, CommonModule, PaginatorModule],
    declarations: [PaginatorComponent],
    exports: [PaginatorComponent],
    providers: [
        PaginatorComponent,
        PaginatorService,
        {
            provide: MatPaginatorIntl,
            useClass: PaginatorIntlService,
        },
    ],
})
export class PaginatorTpModule {}
