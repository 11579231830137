import { ConfigService, GeneralData, GeneralDataDTO } from '@tploy-enterprise/tenant-core';
import {
    ProjectApplication,
    ProjectsEntity,
    ProjectsOthersProfile,
    ProjectsProfile,
    ProjectsApplicationIndicators,
    ProjectsApplicationIndicatorsDTO,
    ProjectsProfileInvitationMessage,
    ProjectsProfileMatch,
    ProjectsProfileMatchHighlight,
    ProjectsProfileSearchResult,
    ProjectsSearchResult,
    ProjectsSearchResultHighlight,
    ProjectEntityHighlight,
} from './projects.types';
import {
    ProjectApplicationDTO,
    ProjectsEntityDTO,
    ProjectsProfileDTO,
    ProjectsProfileInvitationMessageDTO,
    ProjectsProfileMatchDTO,
    ProjectsProfileMatchHighlightDTO,
    ProjectsSearchResultDTO,
    ProjectsSearchResultHighlightDTO,
} from './projects-api/projects.dtos';
import { Injectable } from '@angular/core';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';
import { LocationSerializer } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class ProjectsSerializer {
    constructor(
        private readonly skillsSerializer: SkillsSerializer,
        private readonly locationSerializer: LocationSerializer,
        private readonly configService: ConfigService,
    ) {}

    serializeProjectsProfile(profile: ProjectsProfile, user: GeneralData): ProjectsProfileDTO {
        return {
            user: {
                id: user.id,
                name: user.name,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                groups: user.groups,
            },
            skills: this.skillsSerializer.serialize(profile.skills),
            skillsInterestedIn: this.skillsSerializer.serialize(profile.skillsInterestedIn),
            categoriesInterestedIn: profile.categoriesInterestedIn,
            motivations: profile.motivations,
            campaigns: profile.campaigns,
        };
    }

    deserializeProjectsProfile(dto: ProjectsProfileDTO): ProjectsProfile {
        return {
            skills: this.skillsSerializer.deserialize(dto.skills),
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn).map((skill) => skill.name),
            categoriesInterestedIn: dto.categoriesInterestedIn,
            motivations: dto.motivations,
            campaigns: dto.campaigns,
        };
    }

    deserializeProjectsOthersProfile(dto: ProjectsProfileDTO): ProjectsOthersProfile {
        return {
            user: dto.user,
            skills: this.skillsSerializer.deserialize(dto.skills),
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn).map((skill) => skill.name),
            categoriesInterestedIn: dto.categoriesInterestedIn,
            motivations: dto.motivations,
            campaigns: dto.campaigns,
        };
    }

    serializeProjectsEntity(projectsEntity: ProjectsEntity): ProjectsEntityDTO {
        return {
            id: projectsEntity.id,
            title: projectsEntity.title,
            categories: projectsEntity.categories,
            campaigns: projectsEntity.campaigns,
            languages: projectsEntity.languages,
            description: projectsEntity.description,
            company: projectsEntity.company,
            department: projectsEntity.department,
            startDate: projectsEntity.startDate ? projectsEntity.startDate.toISOString() : undefined,
            endDate: projectsEntity.endDate ? projectsEntity.endDate.toISOString() : undefined,
            applicationCloseAt: projectsEntity.applicationCloseAt
                ? projectsEntity.applicationCloseAt.toISOString()
                : undefined,
            requestedSkills: this.skillsSerializer.serialize(projectsEntity.requestedSkills), // save current array position
            selectedGroups: projectsEntity.selectedGroups,
            location: { name: projectsEntity.location },
            imageUrl: projectsEntity.imageUrl.split(this.configService.remoteSettings.domain).pop(),
            customConditions: projectsEntity.customConditions,
            published: projectsEntity.published,
            applications: projectsEntity.applications
                ? projectsEntity.applications.map((a) => this.serializeApplication(a))
                : [],
            canUserApply: projectsEntity.canUserApply,
            isApplicationForUserPendingOrApproved: projectsEntity.isApplicationForUserPendingOrApproved,
            staffingCompleted: projectsEntity.staffingCompleted,
            draft: projectsEntity.draft,
            status: projectsEntity.status,
            finishedAt: projectsEntity.finishedAt ? projectsEntity.finishedAt.toISOString() : undefined,
            numberOfApplications: projectsEntity.numberOfApplications,
            hasUnseenApplications: projectsEntity.hasUnseenApplications,
            organizers: projectsEntity.organizers.map((organizer) => organizer.id),
        };
    }

    deserializeProjectsEntity(dto: ProjectsEntityDTO): ProjectsEntity {
        return {
            id: dto.id,
            title: dto.title,
            categories: dto.categories,
            campaigns: dto.campaigns,
            languages: dto.languages,
            description: dto.description,
            company: dto.company,
            department: dto.department,
            startDate: dto.startDate ? new Date(dto.startDate) : undefined,
            endDate: dto.endDate ? new Date(dto.endDate) : undefined,
            applicationCloseAt: dto.applicationCloseAt ? new Date(dto.applicationCloseAt) : undefined,
            requestedSkills: this.skillsSerializer.deserialize(dto.requestedSkills), // strip the position property
            selectedGroups: dto.selectedGroups,
            location: dto.location.name,
            imageUrl: dto.imageUrl,
            customConditions: dto.customConditions,
            published: dto.published,
            applications: dto.applications ? dto.applications.map((a) => this.deserializeApplication(a)) : [],
            organizers: dto.organizersDecorated,
            lastUpdatedAt: dto.lastUpdatedAt,
            canUserApply: dto.canUserApply,
            applicationStatus: dto.applicationStatus,
            isApplicationForUserPendingOrApproved: dto.isApplicationForUserPendingOrApproved,
            staffingCompleted: dto.staffingCompleted,
            draft: dto.draft,
            ongoing: !dto.endDate,
            status: dto.status,
            finished: !!dto.finishedAt,
            finishedAt: dto.finishedAt ? new Date(dto.finishedAt) : undefined,
            numberOfApplications: dto.numberOfApplications,
            hasUnseenApplications: dto.hasUnseenApplications,
            highlighted: this.deserializeProjectEntityHighlight(dto.highlighted),
            applicationSelectedSkills: dto.applicationSelectedSkills,
        };
    }

    serializeApplication(application: ProjectApplication): ProjectApplicationDTO {
        return {
            id: application.id,
            name: application.name,
            userId: application.userId,
            status: application.status,
            selectedSkills: application.selectedSkills,
            appliedAt: application.appliedAt,
            approvedBy: application.approvedBy,
            approvedAt: application.approvedAt,
            rejectedAt: application.rejectedAt,
            rejectedBy: application.rejectedBy,
            highlightedExactNotSelectedSkills: application.highlightedExactNotSelectedSkills,
            highlightedExactSkills: application.highlightedExactSkills,
            highlightedByRelatedSkills: application.highlightedByRelatedSkills,
            exactSkills: application.exactSkills,
            exactSkillsRelations: application.exactSkillsRelations,
            notExactSkills: application.notExactSkills,
            notExactSkillsRelations: application.notExactSkillsRelations,
        };
    }

    deserializeApplication(dto: ProjectApplicationDTO): ProjectApplication {
        return {
            ...dto,
        };
    }

    serializeProjectsProfileInvitationMessage(
        invitationMessage: ProjectsProfileInvitationMessage,
    ): ProjectsProfileInvitationMessageDTO {
        return {
            projectId: invitationMessage.projectEntity.id,
            projectTitle: invitationMessage.projectEntity.title,
            applicantId: invitationMessage.profile.user.id,
            applicantName: invitationMessage.profile.user.name,
            messageContent: invitationMessage.messageContent,
        };
    }

    deserializeProjectsProfileSearchResult(dto: ProjectsProfileDTO): ProjectsProfileSearchResult {
        return {
            profile: this.deserializeProjectsOthersProfile(dto),
            notified: dto.notified,
        };
    }

    deserializeProjectsSearchResult(dto: ProjectsSearchResultDTO): ProjectsSearchResult {
        dto.entity.organizersDecorated = dto.entity.organizersDecorated ?? dto.organizersDecorated;
        return {
            projectId: dto.id,
            highlight: this.deserializeProjectSearchResultHighlight(dto.highlight),
            projectsEntity: this.deserializeProjectsEntity(dto.entity),
            organizers: dto.organizersDecorated,
        };
    }

    private deserializeProjectSearchResultHighlight(
        dto: ProjectsSearchResultHighlightDTO,
    ): ProjectsSearchResultHighlight {
        if (!dto) {
            return { categories: [], description: [], location: [], skillsOptional: [], skillsRequired: [], title: [] };
        }

        return {
            skillsOptional: dto.skillsOptional,
            skillsRequired: dto.skillsRequired,
            location: dto.location,
            categories: dto.categories,
            title: dto.title,
            description: dto.description,
        };
    }

    deserializeProjectsProfileMatch(dto: ProjectsProfileMatchDTO): ProjectsProfileMatch {
        return {
            projectId: dto.entity.id,
            highlight: this.deserializeProjectsProfileMatchHighlight(dto.highlight),
            projectsEntity: this.deserializeProjectsEntity(dto.entity),
            organizers: dto.organizersDecorated,
        };
    }

    deserializeProjectsUserSearchResult(dto: Partial<GeneralDataDTO>): Partial<GeneralData> {
        return {
            id: dto.id,
            name: dto.name,
            imageUrl: dto.imageUrl,
        };
    }

    private deserializeProjectsProfileMatchHighlight(
        dto: ProjectsProfileMatchHighlightDTO,
    ): ProjectsProfileMatchHighlight {
        if (!dto) {
            return { categories: [], mustHaveSkills: [], niceToHaveSkills: [] };
        }

        return {
            niceToHaveSkills: dto.niceToHaveSkills,
            mustHaveSkills: dto.mustHaveSkills,
            categories: dto.categories,
        };
    }

    deserializeIndicators(dto: ProjectsApplicationIndicatorsDTO): ProjectsApplicationIndicators {
        return {
            hasUnseenApplicationsOfferingTab: dto.hasUnseenApplicationsOfferingTab,
            hasUnseenApplicationsTopicLink: dto.hasUnseenApplicationsTopicLink,
            projectsWithUnseenApplications: dto.projectsWithUnseenApplications,
        };
    }

    private deserializeProjectEntityHighlight(dto: ProjectEntityHighlight): ProjectEntityHighlight {
        if (!dto) {
            return {
                categories: [],
                mustHaveSkills: [],
                mustHaveSkillsWithRelated: [],
            };
        }

        return {
            categories: dto.categories,
            mustHaveSkills: dto.mustHaveSkills,
            mustHaveSkillsWithRelated: dto.mustHaveSkillsWithRelated,
        };
    }
}
