import {
    ExpertBookmark,
    ExpertMatch,
    ExpertProfile,
    ExpertSearchResult,
    ExpertSuggestedSkills,
} from '../expert-api/expert.types';
import { TopicExpertState } from './topic-expert.reducer';
import { PageParams } from '@tploy-enterprise/tenant-core';

export function isExpertProfileLoadedOrLoading(state: { expert: TopicExpertState }): boolean {
    const current = state.expert.current;
    return current.loaded || current.loading;
}
export function isExpertProfileLoaded(state: { expert: TopicExpertState }): boolean {
    const current = state.expert.current;
    return current.loaded;
}
export function selectCurrentExpertProfile(state: { expert: TopicExpertState }): ExpertProfile {
    return state.expert.current.data;
}
export function selectEditExpertProfile(state: { expert: TopicExpertState }): ExpertProfile {
    return state.expert.edit.data;
}
export function selectMatchResults(state: { expert: TopicExpertState }): Array<ExpertMatch> {
    return state.expert.matching.data;
}
export function selectAllMatchResults(state: { expert: TopicExpertState }): Array<ExpertMatch> {
    return state.expert.matching.allData;
}
export function selectAllBookmarkResults(state: { expert: TopicExpertState }): Array<ExpertBookmark> {
    return state.expert.bookmarks.data;
}
export function selectSearchResults(state: { expert: TopicExpertState }): ExpertSearchResult[] {
    return state.expert.searchResults.results;
}
export function selectAllSearchResults(state: { expert: TopicExpertState }): ExpertSearchResult[] {
    return state.expert.searchResults.allResults;
}
export function isSearchResultsEmpty(state: { expert: TopicExpertState }): boolean {
    return state.expert.searchResults.loaded && state.expert.searchResults.results.length == 0;
}
export function hasExpertProfile(state: { expert: TopicExpertState }): boolean {
    return state.expert.current.loaded && !state.expert.current.loadingError;
}
export function isExpertProfileInDirtyState(state: { expert: TopicExpertState }): boolean {
    return state.expert.edit.dirty;
}

export function selectMatchesPageParams(state: { expert: TopicExpertState }): PageParams {
    return state.expert.matching.pageParams;
}

export function selectSearchingPageParams(state: { expert: TopicExpertState }): PageParams {
    return state.expert.searchResults.pageParams;
}

export function isSearchLoading(state: { expert: TopicExpertState }): boolean {
    return state.expert.searchResults.loading;
}

export function suggestedSkills(state: { expert: TopicExpertState }): ExpertSuggestedSkills {
    return state.expert.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { expert: TopicExpertState }): string[] {
    return state.expert.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { expert: TopicExpertState }): string[] {
    return state.expert.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { expert: TopicExpertState }): string[] {
    return state.expert.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}
