import { createAction, props } from '@ngrx/store';
import { Notification, Registration } from '../models/workshop.types';
import { WorkshopMatchingEntry } from '../models/workshop-matching.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const WorkshopMatchingActions = {
    loadMatches: createAction('[Workshop Matching] load matches', props<{ pageParams: PageParams }>()),
    loadMatchesSuccess: createAction(
        '[Workshop Matching][Success] load matches',
        props<{ workshopMatchingEntries: WorkshopMatchingEntry[]; pageParams: PageParams }>(),
    ),
    loadMatchesError: createAction('[Workshop Matching][Error] load matches', props<{ error: Error }>()),

    startRegistration: createAction('[Workshop Matching] start registration', props<{ workshopId: string }>()),
    startRegistrationSuccess: createAction(
        '[Workshop Matching][Success] start registration',
        props<{ workshopId: string; registration: Registration }>(),
    ),
    startRegistrationError: createAction('[Workshop Matching][Error] start registration', props<{ error: Error }>()),

    finishRegistration: createAction(
        '[Workshop Matching] finish registration',
        props<{ workshopId: string; registrationId: string }>(),
    ),
    finishRegistrationSuccess: createAction(
        '[Workshop Matching][Success] finish registration',
        props<{ workshopId: string; registration: Registration }>(),
    ),
    finishRegistrationError: createAction('[Workshop Matching][Error] finish registration', props<{ error: Error }>()),

    deleteRegistration: createAction(
        '[Workshop Matching] delete registration',
        props<{ workshopId: string; registrationId: string }>(),
    ),

    createNotification: createAction('[Workshop Matching] create notification', props<{ workshopId: string }>()),
    createNotificationSuccess: createAction(
        '[Workshop Matching][Success] create notification',
        props<{ workshopId: string; notification: Notification }>(),
    ),
    createNotificationError: createAction('[Workshop Matching][Error] create notification', props<{ error: Error }>()),
};
