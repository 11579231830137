import { Bookmark, PageParams } from '@tploy-enterprise/tenant-core';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export const PROFILE_STORAGE_KEY = 'tploy.neverLunchAlone.profile';
export const IS_PROFILE_DIRTY = 'tploy.neverLunchAlone.profile.isDirty';

export type NLASuggestedSkills = SuggestedSkills;

export interface NLAProfile {
    companyLocations: string[];
    locationIndependent: boolean;
    interestedInTopics: string[];
    favoriteFood: string[];
}

export interface OthersNLAProfile extends NLAProfile {
    user: {
        id: string;
        name?: string;
        languages?: string[];
        locations?: string[];
        imageUrl?: string;
    };
}

export interface NLAMatches {
    matches: NLAMatch[];
    allMatches: NLAMatch[];
    total: number;
    pageParams: PageParams;
}

export interface NLAMatch extends NLAAccountListItem {
    userId: string;
    highlight: NLAHighlight;
}

export interface NLABookmark extends Bookmark, NLAAccountListItem {}

export interface NLASearchResults {
    results: NLASearchResult[];
    allResults: NLASearchResult[];
    pageParams: PageParams;
}

export interface NLASearchResult extends NLAAccountListItem {
    userId: string;
    highlight: NLAHighlight;
}

export interface NLAAccountListItem {
    bookmark?: Bookmark;
    profile?: OthersNLAProfile;
}

export interface NLAHighlight {
    interestedInTopics: string[];
    isOnlineMatch: boolean;
    locations: string[];
}
