import {
    Directive,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    HostListener,
    Renderer2,
    Inject,
    OnDestroy,
    AfterViewInit,
    ElementRef,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../storage/service/storage.service';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WindowRef } from '../window-ref';

const SMALL_QUERY = '(max-width: 959px)';
const MEDIUM_QUERY = '(min-width: 960px) and (max-width: 1399px)';
const LARGE_QUERY = '(min-width: 1400px)';

const SIDENAV_STORAGE_KEY = 'tploy.sidenav';

export interface SidenavConfig {
    opened: boolean;
    disableClose: boolean;
    mode: 'side' | 'over';
    query: string;
}

@Directive({
    selector: '[tpSidenavHelpers]',
    exportAs: 'tpSidenavHelpers',
})
export class SidenavHelpersDirective implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatSidenav)
    sidenavComponent: MatSidenav;

    @HostListener('backdropClick')
    onBackdropClick() {
        this.close();
    }

    config: SidenavConfig;

    private destroy$ = new Subject<void>();

    constructor(
        private bo: BreakpointObserver,
        private cd: ChangeDetectorRef,
        private storage: StorageService,
        private deviceDetectorService: DeviceDetectorService,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        // restore previously stored state
        this.config = this.readStoredConfig() || null;
        this.bo
            .observe([SMALL_QUERY, MEDIUM_QUERY, LARGE_QUERY])
            .pipe(takeUntil(this.destroy$))
            .subscribe((state) => {
                const currentQuery = this.config ? this.config.query : null;
                if (currentQuery && state[this.config.query] === true) {
                    return; // don't override the restored state if the applicable media query has not changed.
                }

                if (state.breakpoints[SMALL_QUERY]) {
                    this.applyMobileMode();
                }
                if (state.breakpoints[MEDIUM_QUERY]) {
                    this.applyTabletMode();
                }
                if (state.breakpoints[LARGE_QUERY]) {
                    this.applyFullscreenMode();
                }
            });
    }

    ngAfterViewInit(): void {
        this.setMaxHeight();
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.setMaxHeight();
    }

    ngOnDestroy() {
        this.storeConfig();
        this.destroy$.next();
        this.setScrollingBehaviour(false);
    }

    toggle() {
        const opened = !this.config?.opened;
        this.setOpenState(opened);
        this.setScrollingBehaviour(opened);
    }

    open() {
        this.setOpenState(true);
        this.setScrollingBehaviour(true);
    }

    close() {
        this.setOpenState(false);
        this.setScrollingBehaviour(false);
    }

    private setOpenState(value: boolean) {
        this.config = {
            ...this.config,
            opened: value,
        };
        this.cd.markForCheck();
    }

    private applyFullscreenMode(): void {
        this.config = {
            opened: true,
            disableClose: true,
            mode: 'side',
            query: LARGE_QUERY,
        };
    }

    private applyTabletMode(): void {
        this.config = {
            opened: true,
            disableClose: true,
            mode: 'side',
            query: MEDIUM_QUERY,
        };
    }

    private applyMobileMode(): void {
        this.config = {
            opened: false,
            disableClose: true,
            mode: 'over',
            query: SMALL_QUERY,
        };
    }

    private storeConfig(): void {
        this.storage.session.set(SIDENAV_STORAGE_KEY, this.config);
    }

    private readStoredConfig(): SidenavConfig {
        return this.storage.session.get<SidenavConfig, null>(SIDENAV_STORAGE_KEY, null);
    }

    private setScrollingBehaviour(sidenavOpened: boolean) {
        if (!this.deviceDetectorService.isMobile()) {
            return;
        }

        if (sidenavOpened) {
            this.renderer.addClass(this.document.body, 'disable-scrolling');
        } else {
            this.renderer.removeClass(this.document.body, 'disable-scrolling');
        }
    }

    private setMaxHeight() {
        if (!this.deviceDetectorService.isMobile() && (!this.config || !this.config.opened)) {
            return;
        }

        const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
        if (element) {
            const sidenavContainerElement = element.querySelector('.main-layout_sidenav-container');
            this.renderer.setStyle(sidenavContainerElement, 'height', `${this.windowRef.nativeWindow.innerHeight}px`);
        } else {
            console.warn('Sidebar element not found');
        }
    }
}
