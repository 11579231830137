import { Component, Input, OnInit } from '@angular/core';

type RenderTag = 'span' | 'div';

@Component({
    selector: '[tpTextHighlighter]',
    template: `
        <ng-content></ng-content>
        <div [outerHTML]="html"></div>
    `,
})
export class TagsHighlighterComponent implements OnInit {
    @Input()
    text: string;

    @Input()
    textToShow: string;

    @Input()
    tagsToHighlight: string[];

    @Input()
    renderTag: RenderTag;

    @Input()
    highlightClass: string;

    html: string;

    ngOnInit(): void {
        this.html = this.transformText(this.text, this.tagsToHighlight, this.renderTag, this.highlightClass);
    }

    private transformText(
        text = '',
        tagsToHighlight: string[] = [],
        renderTag: RenderTag = 'span',
        highlightClass = '',
    ): string {
        if (text.trim() === '' || !tagsToHighlight.length) {
            return this.textToShow ?? text;
        }

        const highlight = tagsToHighlight.some((tag) => text.toLowerCase().trim() === tag.toLowerCase().trim());

        if (highlight) {
            return `<${renderTag} class='${highlightClass}'>${this.textToShow ?? text}</${renderTag}>`;
        } else {
            return `${text}`;
        }
    }
}
