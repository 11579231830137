import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ConfigService } from '../../config';
import { SsoDataField } from './sso-data.types';

@Directive({
    selector: '[tpSsoData]',
})
export class SsoDataDirective implements OnInit, AfterViewInit {
    @Input()
    tpSsoData: SsoDataField;

    get nativeElement(): HTMLElement {
        return this.el.nativeElement;
    }

    constructor(private el: ElementRef, private ngControl: NgControl, private configService: ConfigService) {}

    ngOnInit(): void {
        if (!this.tpSsoData) {
            throw new Error('Missed SSO data field!');
        }
    }

    ngAfterViewInit() {
        if (!this.isSsoDataField()) {
            return;
        }

        const tagName = this.nativeElement.tagName.toLowerCase();

        if (tagName === 'input') {
            this.nativeElement.setAttribute('readonly', 'readonly');
        } else if (tagName === 'tp-cloud-selector') {
            this.nativeElement.classList.add(...['readonly']);
            this.ngControl.control.disable({ onlySelf: true, emitEvent: false });
        } else {
            const input = this.nativeElement.querySelector('input');
            if (input) {
                this.nativeElement.querySelector('input').setAttribute('readonly', 'readonly');
            }
            const container = this.nativeElement.querySelector('.mat-form-field-container');
            if (container) {
                this.nativeElement.querySelector('.mat-form-field-container').classList.add(...['readonly']);
            }
        }

        if (this.nativeElement.closest('.mat-form-field')) {
            this.nativeElement.closest('.mat-form-field').classList.add(...['mat-select-read-only']);
        }
    }

    private isSsoDataField() {
        return this.configService.remoteSettings.authenticationAttributesFromSso.indexOf(this.tpSsoData) !== -1;
    }
}
