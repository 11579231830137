import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BlogService } from './blog.service';
import { of } from 'rxjs';
import { BlogActions } from './blog.actions';

@Injectable({
    providedIn: 'root',
})
export class BlogEffects {
    constructor(private actions$: Actions, private service: BlogService, private errorHandler: ErrorHandler) {}

    loadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BlogActions.loadArticles),
            switchMap((locale) =>
                this.service.loadArticles(locale.locale).pipe(
                    map((result) => BlogActions.loadArticlesSuccess({ articles: result })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(BlogActions.loadArticlesError({ error }));
                    }),
                ),
            ),
        ),
    );
}
