import { Injectable } from '@angular/core';
import { WorkshopProfile, WorkshopProfileDTO, WorkshopOthersProfile } from '../models/workshop-profile.types';
import { WorkshopTypesMappingUtil } from '../models/workshop-types-mapping.util';
import { GeneralData } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class WorkshopProfileSerializer {
    constructor(private mappingUtil: WorkshopTypesMappingUtil) {}

    serialize(profile: WorkshopProfile, user: GeneralData): WorkshopProfileDTO {
        return {
            pushToSuccessFactor: profile.pushToSuccessFactor || false,
            skills: this.mappingUtil.mapSkillsWithFallback(profile.skills),
            categoriesInterestedIn: profile.categoriesInterestedIn || [],
            motivations: profile.selectedMotivations || [],
            skillsInterestedIn: this.mappingUtil.stringArrayToSkillArray(profile.skillsInterestedIn),
            user: {
                id: user.id,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.mappingUtil.stringArrayToTagArray(user.locations),
                name: user.name,
                groups: user.groups,
            },
        };
    }

    deserialize(dto: WorkshopProfileDTO): WorkshopProfile {
        return {
            skills: dto.skills,
            skillsInterestedIn: this.mappingUtil.skillArrayToStringArray(dto.skillsInterestedIn),
            categoriesInterestedIn: dto.categoriesInterestedIn || [],
            selectedMotivations: dto.motivations || [],
            pushToSuccessFactor: dto.pushToSuccessFactor || false,
        };
    }

    deserializeOthers(dto: WorkshopProfileDTO): WorkshopOthersProfile {
        return {
            ...this.deserialize(dto),
            user: dto.user,
        };
    }
}
