import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CampaignsPopoverComponent, CampaignsPopoverData } from '../campaigns-popover/campaigns-popover.component';
import { PopoverDirective } from '../../popover';

@Component({
    selector: 'tp-campaigns-fact',
    templateUrl: './campaigns-fact.component.html',
})
export class CampaignsFactComponent implements OnInit {
    @Input() campaigns: string[];
    @Input() campaignsToHighlight: string[];
    @Input() styleType: 'details' | 'card';

    @ViewChild(PopoverDirective) popover: PopoverDirective;
    popoverContent = CampaignsPopoverComponent;

    textLimit = 25;
    campaignToShow: string;
    extraCampaigns: Array<{ name: string; matched: boolean }> = [];

    get popoverData(): CampaignsPopoverData {
        return {
            campaigns: this.extraCampaigns,
        };
    }

    get badgeText(): string {
        return `+${this.extraCampaigns.length}`;
    }

    ngOnInit(): void {
        this.campaignToShow = this.campaigns[0];
        this.extraCampaigns = this.campaigns.slice(1, this.campaigns.length).map((campaign) => ({
            name: campaign,
            matched: this.campaignsToHighlight?.includes(campaign),
        }));
    }

    hasHiddenText(campaign: string): boolean {
        return campaign?.length > this.textLimit;
    }
}
