import { Component, ChangeDetectionStrategy, OnInit, HostBinding } from '@angular/core';
import { LanguageOptions } from '../language.types';
import { LanguageHelpersService } from '../language-helpers.service';
import { Store } from '@ngrx/store';

@Component({
    selector: 'tp-language-switcher',
    templateUrl: './language-switcher.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class LanguageSwitcherComponent implements OnInit {
    locales: LanguageOptions[];

    current$: any;

    @HostBinding('class')
    languageSwitcherClass = 'language-switcher';

    constructor(private languageHelpers: LanguageHelpersService, private readonly store: Store<unknown>) {}

    ngOnInit() {
        this.current$ = this.languageHelpers.locale$;
        this.locales = this.languageHelpers.availableLocales;
    }

    changeLocale(language: LanguageOptions) {
        this.store.dispatch({ type: '[Account Locale] change', locale: language });
    }
}
