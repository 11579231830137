<p class="profile-title-locations" *ngIf="locations?.length > 0 || isLocationIndependent">
    <tp-nla-profile-tile-location-independent
        *ngIf="isLocationIndependent"
        [matched]="highlightLocationIndependent"
    ></tp-nla-profile-tile-location-independent>
    <ng-container *ngIf="locations?.length > 0">
        <mat-icon svgIcon="location_filled"></mat-icon>
        <tp-list-item-tags [tags]="visibleLocations"></tp-list-item-tags>
        <ng-content></ng-content>
    </ng-container>
</p>
