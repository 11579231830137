<h2 mat-dialog-title>
    {{ 'CONFIRM_IMAGE_DELETE_TITLE' | translatable }}
</h2>

<mat-dialog-content>
    <p>{{ 'CONFIRM_IMAGE_DELETE_BODY' | translatable }}</p>
</mat-dialog-content>

<mat-dialog-actions class="form-footer form-footer--buttons-center form-footer--ignore-sibling-padding">
    <nav>
        <tp-secondary-button mat-dialog-close>
            {{ 'CANCEL' | translatable }}
        </tp-secondary-button>
        <tp-primary-button [mat-dialog-close]="true">
            {{ 'DELETE_IRREVERSIBLY' | translatable }}
        </tp-primary-button>
    </nav>
</mat-dialog-actions>
