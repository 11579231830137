import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translatable } from '../../language';

@Component({
    selector: 'tp-info-toggle-dialog',
    templateUrl: './info-toggle-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'info-toggle-dialog',
    },
})
export class InfoToggleDialogComponent {
    private readonly headerLabels = {
        info_filled: 'INFO_TOGGLE_DIALOG_DEFAULT_HEADER',
        view_filled: 'INFO_TOGGLE_DIALOG_ANONYMITY_HEADER',
    };

    get headerLabel() {
        const defaultLabel = 'INFO_TOGGLE_DIALOG_DEFAULT_HEADER';
        const label = this.data.label || this.headerLabels[this.data.svgIcon];
        return label ? label : defaultLabel;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<InfoToggleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoToggle,
    ) {}
}

export interface InfoToggle {
    translatable: Translatable;
    svgIcon: string;
    label: string;
}
