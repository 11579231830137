import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '../notifications/notifications.service';

@Injectable({
    providedIn: 'root',
})
export class UnknownRouteService {
    constructor(private readonly router: Router, private readonly notificationsService: NotificationsService) {}

    public handle404(): void {
        this.router.navigate(['/dashboard']).then((navigated: boolean) => {
            if (navigated) {
                this.notificationsService.openErrorSnack('ERROR_URL_404');
            }
        });
    }
}
