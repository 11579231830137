import { Injectable } from '@angular/core';
import { QuestionnaireData, QuestionnaireDataDto } from './questionnaire.types';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireSerializer {
    serialize(questionnaire: QuestionnaireData): QuestionnaireDataDto {
        return {
            ...questionnaire,
            answers: this.serializeAnswers(questionnaire),
        };
    }

    deserialize(dto: QuestionnaireDataDto): QuestionnaireData {
        return {
            ...dto,
        };
    }

    private serializeAnswers(questionnaire: QuestionnaireData) {
        return questionnaire.answers.map((answer) => ({
            question: answer.question,
            answer: answer.answer,
        }));
    }
}
