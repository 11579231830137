import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
    STAApplicationDTO,
    STAApplicationsSelectionsDTO,
    STAEntityDTO,
    STAProfileDTO,
    STAProfileInvitationMessageDTO,
    STASearchDTO,
    STASuggestedSkillsDTO,
} from './sta.dtos';
import { ApplicationRequest, STAApplicationIndicatorsDTO, STAProfileMatchesDTO, StaStaffingFilter } from '../sta.types';
import { map } from 'rxjs/operators';
import { GeneralDataDTO, OffsetLimit, SHORT_TIME_ASSIGNMENTS_TOPIC_NAME } from '@tploy-enterprise/tenant-core';
import { Params } from '@angular/router';

const BASE_PATH = `/api/v2/${SHORT_TIME_ASSIGNMENTS_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class STAApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<STAProfileDTO> {
        return this.httpClient.get<STAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string) {
        return this.httpClient.get<STAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: STAProfileDTO): Observable<STAProfileDTO> {
        return this.httpClient.put<STAProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, { observe: 'body' });
    }

    getEntity(entityId: string, showHighlighted = false, isStaffingManagerView = false) {
        let params: Params = {};
        if (showHighlighted) {
            params = {
                ...params,
                showHighlighted: 1,
            };
        }
        if (isStaffingManagerView) {
            params = {
                ...params,
                isStaffingManagerView: 1,
            };
        }
        return this.httpClient.get<STAEntityDTO>(`${BASE_PATH}/${entityId}`, { params });
    }

    postEntity(staEntity: STAEntityDTO) {
        return this.httpClient.post<STAEntityDTO>(`${BASE_PATH}`, staEntity);
    }

    putEntity(staEntity: STAEntityDTO, sendNotifications?: boolean): Observable<STAEntityDTO> {
        const params: Params = sendNotifications !== undefined ? { sendNotifications: sendNotifications } : {};
        return this.httpClient.put<STAEntityDTO>(`${BASE_PATH}/${staEntity.id}`, staEntity, {
            observe: 'body',
            params,
        });
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.httpClient
            .delete(`${BASE_PATH}/${entityId}`, {
                responseType: 'text',
            })
            .pipe(map(() => entityId));
    }

    publishEntity(entityId: string) {
        return this.httpClient.get<STAEntityDTO>(`${BASE_PATH}/${entityId}/publish`);
    }

    getAppliedToEntities() {
        const httpParams = new HttpParams({
            fromObject: { applied: 'true' },
        });

        return this.httpClient.get<STAEntityDTO[]>(`${BASE_PATH}`, { params: httpParams });
    }

    search(searchQuery: string, offsetLimit: OffsetLimit, campaigns?: string[]): Observable<STASearchDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<STASearchDTO>(`${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`, {
            params,
        });
    }

    loadStaffingEntities(
        searchQuery: string,
        offsetLimit: OffsetLimit,
        filter: StaStaffingFilter,
    ): Observable<STASearchDTO> {
        let params: Params = {
            ...offsetLimit,
            isStaffingManagerView: 1,
        };
        if (filter !== undefined) {
            params = {
                ...params,
                isApplicationOpen: filter.isApplicationOpen ? 1 : 0,
            };
            if (filter.statuses.length > 0) {
                params = {
                    ...params,
                    statuses: filter.statuses.join(','),
                };
            }
            if (filter.categories.length > 0) {
                params = {
                    ...params,
                    categories: filter.categories.join(','),
                };
            }
            if (filter.languages.length > 0) {
                params = {
                    ...params,
                    languages: filter.languages.join(','),
                };
            }
        }
        return this.httpClient.get<STASearchDTO>(`${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`, {
            params,
        });
    }

    getSuggestedSkills(id: string) {
        return this.httpClient.get<STASuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }

    getEntitiesOfferedByMe(userId: string) {
        const params = new HttpParams({
            fromObject: {
                organizer: userId,
            },
        });

        return this.httpClient.get<STAEntityDTO[]>(`${BASE_PATH}`, { params });
    }

    postEntityApplication(entityId, application: ApplicationRequest): Observable<STAApplicationDTO> {
        return this.httpClient.post<STAApplicationDTO>(`${BASE_PATH}/${entityId}/application`, application);
    }

    patchEntityApplicationStatus(
        entityId: string,
        applicationId: string,
        patch: { status: 'approved' | 'rejected' | 'cancelled' },
    ): Observable<STAApplicationDTO> {
        return this.httpClient.patch<STAApplicationDTO>(`${BASE_PATH}/${entityId}/application/${applicationId}`, patch);
    }

    getProfileMatches(offsetLimit: OffsetLimit, campaigns?: string[]): Observable<STAProfileMatchesDTO> {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<STAProfileMatchesDTO>(`${BASE_PATH}/matches`, { params });
    }

    completeStuffing(entityId: string): Observable<STAEntityDTO> {
        return this.httpClient.put<STAEntityDTO>(`${BASE_PATH}/${entityId}/complete-staffing`, { observe: 'body' });
    }

    getApplicationsSelections(entityId: string) {
        return this.httpClient.get<STAApplicationsSelectionsDTO>(`${BASE_PATH}/${entityId}/applications-selection`);
    }

    getUnseenApplications(): Observable<STAApplicationIndicatorsDTO> {
        return this.httpClient.get<STAApplicationIndicatorsDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/unseen-applications`, {
            observe: 'body',
        });
    }

    markTopicLinkApplicationsAsSeen(): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}${PROFILE_ENDPOINT}/mark-applications-topic-link-as-seen`, {
            observe: 'body',
        });
    }

    markOfferingTabApplicationsAsSeen(): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}${PROFILE_ENDPOINT}/mark-applications-offering-tab-as-seen`, {
            observe: 'body',
        });
    }

    markApplicationsAsSeen(entityId: string): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}/${entityId}/mark-applications-as-seen`, { observe: 'body' });
    }

    searchProfiles(statId: string, searchTerms: string[]): Observable<STAProfileDTO[]> {
        const params: Params = {
            q: encodeURIComponent(searchTerms.join(',')),
        };

        return this.httpClient.get<STAProfileDTO[]>(`${BASE_PATH}/profiles/search/${statId}`, { params });
    }

    searchTopicUserByName(queryString: string, entityId: string): Observable<Partial<GeneralDataDTO>[]> {
        const entityIdSegment = entityId ? `&shortTimeAssignmentId=${entityId}` : '';
        return this.httpClient.get<Partial<GeneralDataDTO>[]>(
            `${BASE_PATH}/profiles/search/potential-organizers?q=${queryString}${entityIdSegment}`,
            {
                observe: 'body',
            },
        );
    }

    sendInvitation(invitation: STAProfileInvitationMessageDTO): Observable<void> {
        return this.httpClient.post<void>(`${BASE_PATH}/profiles/message`, invitation);
    }
}
