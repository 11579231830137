import { FaqDeComponent } from './components/de/faq.de.component';
import { InfoDeComponent } from './components/de/info.de.component';
import { PreviewDeComponent } from './components/de/preview.de.component';
import { SitemapDeComponent } from './components/de/sitemap.de.component';
import { FaqEnComponent } from './components/en/faq.en.component';
import { InfoEnComponent } from './components/en/info.en.component';
import { PreviewEnComponent } from './components/en/preview.en.component';
import { SitemapEnComponent } from './components/en/sitemap.en.component';
import { TenantTemplates } from '@tploy-enterprise/tenant-core';

export const templates: TenantTemplates = {
    de: {
        faq: FaqDeComponent,
        info: InfoDeComponent,
        preview: PreviewDeComponent,
        sitemap: SitemapDeComponent,
    },
    en: {
        faq: FaqEnComponent,
        info: InfoEnComponent,
        preview: PreviewEnComponent,
        sitemap: SitemapEnComponent,
    },
};

export const templateComponents = [
    FaqDeComponent,
    InfoDeComponent,
    PreviewDeComponent,
    SitemapDeComponent,
    FaqEnComponent,
    InfoEnComponent,
    PreviewEnComponent,
    SitemapEnComponent,
];
