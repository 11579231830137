<h6 class="sidenav_topic-links-header">
    {{ 'SIDENAV_MY_MATCHES' | translatable }}
</h6>
<a
    [routerLink]="['/', { outlets: { overlay: ['edit', 'topics'] } }]"
    *ngIf="canAddMoreTopics"
    class="sidenav_select-topics"
    [attr.aria-label]="'SIDENAV_ADD_MORE_ALT_TEXT' | translatable"
>
    <mat-icon svgIcon="add" aria-hidden="true"></mat-icon>
</a>
<ul class="sidenav_topic-links" role="menu">
    <li
        *ngFor="let topic of topics"
        routerLinkActive="active"
        [ngClass]="{ active: referenceContext === topic.referenceContext }"
        class="sidenav_link"
        role="menuitem"
        focused
        #container="focusContainer"
        [attr.aria-label]="topic.label | translatable"
    >
        <a [routerLink]="topic.link">
            <tp-topic-applications-indicator [topicName]="topic.referenceContext"></tp-topic-applications-indicator>
            <mat-icon class="sidenav_link-icon" [svgIcon]="topic.svgIcon"></mat-icon>
            {{ topic.label | translatable }}
        </a>
        <mat-icon
            *tpIfPermission="topic.editPermission"
            class="sidenav_link-edit"
            role="button"
            tabindex="0"
            (click)="navigate(topic.editLink)"
            (keydown.enter)="navigate(topic.editLink)"
            svgIcon="edit_filled"
            aria-hidden="true"
        ></mat-icon>
    </li>
</ul>
