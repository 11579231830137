import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AuthenticatedGuard,
    AuthorizationModule,
    StaticTopicDefinition,
    TopicDefinition,
    TopicEventHandlers,
    TopicRegistryService,
    PROJECTS_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { ProjectsProfileViewModule } from './projects-profile/projects-profile-view.module';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { ProjectsProfileViewComponent } from './projects-profile/projects-profile-view/projects-profile-view.component';
import { ProjectsTopicEventHandlerService } from './projects-topic-event-handler.service';
import { StoreModule } from '@ngrx/store';
import { ProjectsReducer } from './projects.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProjectsEffects } from './projects.effects';
import { UserRole } from '@tandemploy/common';
import { ProjectsApplicantsIndicatorViewComponent } from './projects-embedable/projects-applicants-indicator-view/projects-applicants-indicator-view.component';
import { ProjectsApplicantsIndicatorViewModule } from './projects-embedable/projects-applicants-indicator-view/projects-applicants-indicator-view.module';

const lazyRoutes: Routes = [
    {
        path: 'projects',
        loadChildren: () => import('./projects-domain/projects-domain.module').then((m) => m.ProjectsDomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'projects',
        loadChildren: () => import('./projects-entity/projects-entity.module').then((m) => m.ProjectsEntityModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/projects',
        loadChildren: () =>
            import('./projects-profile/projects-profile-edit.module').then((m) => m.ProjectsProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/projects',
        loadChildren: () =>
            import('./projects-profile/projects-profile-add.module').then((m) => m.ProjectsProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/projects',
        loadChildren: () =>
            import('./projects-profile/projects-profile-sign-up.module').then((m) => m.ProjectsProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
];

const projectsDefinition: StaticTopicDefinition = {
    name: PROJECTS_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: ProjectsProfileViewComponent,
            permission: 'projects.entity.apply_to',
        },
        sidenavItem: {
            link: ['/projects'],
            editPermission: 'projects.entity.apply_to',
            editLink: ['/', { outlets: { overlay: ['edit', 'projects'] } }],
            label: 'PROJECTS',
            svgIcon: 'projects',
            referenceContext: 'project',
        },
        applicantsIndicatorView: {
            component: ProjectsApplicantsIndicatorViewComponent,
        },
        modelInformation: {
            svgIcon: 'projects',
            image: '/assets/images/topics/projects.jpg',
            label: 'PROJECTS',
            content: 'PROJECTS_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['/sign-up', 'projects'],
        signUpSequence: [
            ['/', 'sign-up', 'projects', 'motivation'],
            ['/', 'sign-up', 'projects', 'interest'],
        ],
        edition: ['/', { outlets: { overlay: ['edit', 'projects'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'projects'] } }],
        addSequence: [
            [{ outlets: { overlay: ['add-topic', 'projects', 'motivation'] } }],
            [{ outlets: { overlay: ['add-topic', 'projects', 'interest'] } }],
        ],
        domain: ['projects'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['projects.entity.apply_to'],
        [UserRole.USER]: ['projects.entity.organize', 'projects.entity.apply_to', 'projects.entity.administrate'],
        [UserRole.CONTRIBUTOR]: ['projects.entity.organize', 'projects.entity.administrate'],
        [UserRole.ADMIN]: ['projects.entity.organize', 'projects.entity.apply_to', 'projects.entity.administrate'],
        [UserRole.ADMIN_MAIL]: ['projects.entity.organize', 'projects.entity.apply_to', 'projects.entity.administrate'],
        [UserRole.STATISTICS]: ['projects.entity.organize', 'projects.entity.apply_to', 'projects.entity.administrate'],
        [UserRole.STAFFING_MANAGER]: [
            'projects.entity.organize',
            'projects.entity.apply_to',
            'projects.entity.administrate',
            'projects.entity.manage_staffing',
        ],
    },
    staffingManager: true,
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        ProjectsProfileViewModule,
        ProjectsApplicantsIndicatorViewModule,
        StoreModule.forFeature('projects', ProjectsReducer),
        EffectsModule.forFeature([ProjectsEffects]),
        AuthorizationModule.forFeature({ permissions: projectsDefinition.rolePermissions }),
    ],
})
export class ProjectsFeatureModule {
    constructor(private readonly injector: Injector) {
        const eventHandlers = injector.get(ProjectsTopicEventHandlerService);
        this.registerProjectsTopic(eventHandlers);
    }

    private registerProjectsTopic(eventHandler: TopicEventHandlers) {
        const topicRegistry = this.injector.get(TopicRegistryService);

        const definition: TopicDefinition = {
            ...projectsDefinition,
            eventHandlers: eventHandler,
        };
        topicRegistry.registerTopic(definition);
    }
}
