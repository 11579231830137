import { NgModule } from '@angular/core';
import { ModalHelpersDirective } from './modal-helpers.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ModalHeaderWarningComponent } from './modal-header-warning.component';
import { NotificationsModule } from '@tandemploy/ngx-components';
import { LanguageModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    imports: [MatIconModule, NotificationsModule, LanguageModule],
    declarations: [ModalHelpersDirective, ModalHeaderWarningComponent],
    exports: [ModalHelpersDirective, MatIconModule, MatButtonModule, ModalHeaderWarningComponent],
})
export class ModalLayoutModule {}
