import { WorkshopProfile, WorkshopSuggestedSkills } from '../models/workshop-profile.types';
import { TopicWorkshopState } from './topic-workshop.reducer';
import { WorkshopSearchResult } from '../models/workshop-search.types';
import { INTEREST_KEY, OFFERING_KEY, PageParams, GeneralData } from '@tploy-enterprise/tenant-core';

export function isUserInterestedInWorkshops(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.workshopProfile.editing.selectedMotivations?.indexOf(INTEREST_KEY) !== -1;
}
export function isUserOfferingWorkshops(state: { topicWorkshop: TopicWorkshopState }): boolean {
    const currentMotivations = state.topicWorkshop.workshopProfile.editing.selectedMotivations;
    return currentMotivations.indexOf(OFFERING_KEY) >= 0;
}
export function selectSearchResults(state: { topicWorkshop: TopicWorkshopState }): WorkshopSearchResult[] {
    return state.topicWorkshop.workshopSearch.results;
}
export function selectSearchPageParams(state: { topicWorkshop: TopicWorkshopState }): PageParams {
    return state.topicWorkshop.workshopSearch.pageParams;
}
export function isSearchResultsEmpty(state: { topicWorkshop: TopicWorkshopState }): boolean {
    const searchState = state.topicWorkshop.workshopSearch;
    return searchState.loaded && searchState.results.length === 0;
}
export function isSearchResultsLoading(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.workshopSearch.loading;
}
export function selectCurrentWsProfile(state: { topicWorkshop: TopicWorkshopState }): WorkshopProfile {
    return state.topicWorkshop.workshopProfile.current;
}
export function selectEditingWsProfile(state: { topicWorkshop: TopicWorkshopState }): WorkshopProfile {
    return state.topicWorkshop.workshopProfile.editing;
}
export function isWorkshopProfileLoadedOrLoading(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.workshopProfile.loaded || state.topicWorkshop.workshopProfile.loading;
}

export function isOfferedByMeLoading(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.workshopsOfferedByMe.loading;
}

export function hasWorkshopProfile(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.workshopProfile.loaded && !state.topicWorkshop.workshopProfile.loadingError;
}

export function suggestedSkills(state: { topicWorkshop: TopicWorkshopState }): WorkshopSuggestedSkills {
    return state.topicWorkshop.workshopProfile.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { topicWorkshop: TopicWorkshopState }): string[] {
    return state.topicWorkshop.workshopProfile.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { topicWorkshop: TopicWorkshopState }): string[] {
    return state.topicWorkshop.workshopProfile.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { topicWorkshop: TopicWorkshopState }): string[] {
    return state.topicWorkshop.workshopProfile.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}

export function selectMatchingPageParams(state: { topicWorkshop: TopicWorkshopState }): PageParams {
    return state.topicWorkshop.workshopMatching.matches.pageParams;
}

export function selectSearchTopicUsers(state: { topicWorkshop: TopicWorkshopState }): Partial<GeneralData>[] {
    return state.topicWorkshop.searchTopicUsers.results;
}

export function selectSearchTopicUsersLoading(state: { topicWorkshop: TopicWorkshopState }): boolean {
    return state.topicWorkshop.searchTopicUsers.loading;
}
