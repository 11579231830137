import { NgModule } from '@angular/core';
import { MatchesSliderViewComponent } from './matches-slider-view/matches-slider-view.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatchesSliderSideButtonsComponent } from './matches-slider-side-buttons/matches-slider-side-buttons.component';
import { MatIconModule } from '@angular/material/icon';
import { MatchesSliderSideBottomButtonsComponent } from './matches-slider-side-bottom-buttons/matches-slider-side-bottom-buttons.component';
import { MatButtonModule } from '@angular/material/button';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { SearchLoaderModule } from '../search-loader';

@NgModule({
    declarations: [
        MatchesSliderViewComponent,
        MatchesSliderSideButtonsComponent,
        MatchesSliderSideBottomButtonsComponent,
    ],
    exports: [MatchesSliderViewComponent, MatchesSliderSideButtonsComponent, MatchesSliderSideBottomButtonsComponent],
    imports: [LanguageModule, MatPaginatorModule, MatIconModule, SearchLoaderModule, MatButtonModule],
})
export class MatchesSliderModule {}
