import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { LocaleActions } from './locale.actions';
import { withLatestFrom, switchMap, tap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { AccountActions } from '../account.actions';
import { AccountState } from '../account.reducer';
import { StorageService } from '../../storage';
import { TPLOY_LANGUAGE_STORAGE_KEY as storageKey } from '@tploy-enterprise/tenant-common';

export const TPLOY_LANGUAGE_STORAGE_KEY = storageKey; // cannot export because of cyclic dependencies

@Injectable({
    providedIn: 'root',
})
export class LocaleEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly translate: TranslateService,
        private readonly store: Store<{ account: AccountState }>,
        private readonly storage: StorageService,
    ) {}

    updateUsedLocale = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocaleActions.change, AccountActions.loadSuccess),
                withLatestFrom(this.store.select((state) => state.account.locale.used)),
                map(([, locale]) => locale),
                tap((locale) => this.translate.use(locale)),
                tap((locale) => this.storage.local.set(TPLOY_LANGUAGE_STORAGE_KEY, locale)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    saveLocale = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AccountActions.createSuccess),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
}
