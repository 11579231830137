import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export type NLASuggestedSkillsDTO = SuggestedSkills;

export interface NLAProfileDTO {
    user: NLAUserDTO;
    locationsForMeet: { name: string }[];
    interestedInLocationType: NLALocationType;
    interestedInTopics: string[];
    favoriteFood: string[];
}

export interface NLAUserDTO {
    id: string;
    imageUrl: string;
    name: string;
    languages?: string[];
    locations?: { name: string }[];
    groups?: string[];
}

export enum NLALocationType {
    OWN = 'OWN',
    ONLINE = 'ONLINE',
}

export type NLAProfileMatchResponseDTO = {
    results: NLAProfileMatchDTO[];
    totalCount: number;
};

export type NLAProfileMatchDTO = {
    score: number;
    highlight: NLAHighlightDTO;
    profile: NLAProfileDTO;
};

export type NLASearchResponseDTO = {
    results: NLASearchResultDTO[];
    totalCount: number;
};

export type NLASearchResultDTO = {
    relevance: number;
    highlight: NLAHighlightDTO;
    profile: NLAProfileDTO;
};

export type NLAHighlightDTO = {
    interestedInTopics: string[];
    isOnlineMatch: boolean;
    locations: { name: string }[];
};
