import { NgModule, Injector } from '@angular/core';
import {
    CommonActions,
    TopicRegistryService,
    TopicDefinition,
    StaticTopicDefinition,
    AuthenticatedGuard,
    AuthorizationModule,
    NEVER_LUNCH_ALONE_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { RouterModule, Routes } from '@angular/router';
import { NlaTopicEventHandlerService } from './nla-topic-event-handler.service';
import { NLAProfileNavigatorViewComponent } from './nla-embeddable/nla-profile-navigator-view/nla-profile-navigator-view.component';
import { NLAProfileNavigatorViewModule } from './nla-embeddable/nla-profile-navigator-view/nla-profile-navigator-view.module';
import { NLAProfileViewComponent } from './nla-embeddable/nla-profile-view/nla-profile-view.component';
import { NlaProfileViewModule } from './nla-embeddable/nla-profile-view/nla-profile-view.module';
import { NLAReducer } from './nla.reducer';
import { NLAEffects } from './nla.effects';
import { NLAUserCardViewComponent } from './nla-embeddable/nla-user-card-view/nla-user-card-view.component';
import { NLAUserCardViewModule } from './nla-embeddable/nla-user-card-view/nla-user-card-view.module';
import { UserRole } from '@tandemploy/common';

const lazyRoutes: Routes = [
    {
        path: 'never-lunch-alone',
        loadChildren: () => import('./nla-domain/nla-domain.module').then((m) => m.NLADomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/never-lunch-alone',
        loadChildren: () => import('./nla-profile/nla-profile-sign-up.module').then((m) => m.NlaProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/never-lunch-alone',
        loadChildren: () => import('./nla-profile/nla-profile-add.module').then((m) => m.NlaProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/never-lunch-alone',
        loadChildren: () => import('./nla-profile/nla-profile-edit.module').then((m) => m.NlaProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
];

const NlaTopicDefinition: StaticTopicDefinition = {
    name: NEVER_LUNCH_ALONE_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    embeddableContent: {
        profileView: {
            component: NLAProfileViewComponent,
            permission: 'nla.default',
        },
        profileNavigatorView: {
            component: NLAProfileNavigatorViewComponent,
        },
        userCardView: {
            component: NLAUserCardViewComponent,
        },
        sidenavItem: {
            link: ['/never-lunch-alone'],
            editPermission: 'nla.default',
            editLink: ['/', { outlets: { overlay: ['edit', 'never-lunch-alone'] } }],
            label: 'NEVER_LUNCH_ALONE',
            svgIcon: 'never_lunch_alone',
            referenceContext: 'nla',
        },
        modelInformation: {
            svgIcon: 'never_lunch_alone',
            image: '/assets/images/topics/never-lunch-alone.jpg',
            label: 'NEVER_LUNCH_ALONE',
            content: 'NEVER_LUNCH_ALONE_INFO',
            imageAlt: '',
        },
    },
    links: {
        signUp: ['sign-up', 'never-lunch-alone'],
        signUpSequence: [['/', 'sign-up', 'never-lunch-alone']],
        edition: ['/', { outlets: { overlay: ['edit', 'never-lunch-alone'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'never-lunch-alone'] } }],
        addSequence: [[{ outlets: { overlay: ['add-topic', 'never-lunch-alone'] } }]],
        domain: ['never-lunch-alone'],
    },
    info: null,
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['nla.default'],
        [UserRole.USER]: ['nla.default'],
        [UserRole.CONTRIBUTOR]: ['nla.default'],
        [UserRole.ADMIN]: ['nla.default'],
        [UserRole.ADMIN_MAIL]: ['nla.default'],
        [UserRole.STATISTICS]: ['nla.default'],
        [UserRole.STAFFING_MANAGER]: ['nla.default'],
    },
};

@NgModule({
    imports: [
        RouterModule.forChild(lazyRoutes),
        NlaProfileViewModule,
        NLAUserCardViewModule,
        NLAProfileNavigatorViewModule,
        StoreModule.forFeature('neverLunchAlone', NLAReducer),
        EffectsModule.forFeature([NLAEffects]),
        AuthorizationModule.forFeature({ permissions: NlaTopicDefinition.rolePermissions }),
    ],
})
export class NeverLunchAloneFeatureModule {
    constructor(private readonly injector: Injector) {
        this.registerNlaTopic();
    }

    private registerNlaTopic(): void {
        const eventHandlers = this.injector.get(NlaTopicEventHandlerService);
        const definition: TopicDefinition = {
            ...NlaTopicDefinition,
            eventHandlers,
        };
        const topicRegistry = this.injector.get(TopicRegistryService);
        topicRegistry.registerTopic(definition);
    }
}
