import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of as observableOf, OperatorFunction, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

type ImageTypeSupported = 'IMAGE/JPEG' | 'IMAGE/JPG' | 'IMAGE/PNG';
type ImageOrientation = 'landscape' | 'portrait';

interface ImageAdapterConfiguration {
    maxWidth: number;
    maxHeight: number;
    outputFormat: ImageTypeSupported;
    quality: number;
}

const defaultImageAdapterConfig: ImageAdapterConfiguration = {
    maxWidth: 1000,
    maxHeight: 1000,
    outputFormat: 'IMAGE/JPEG',
    quality: 0.85,
};

@Injectable()
export class ImageAdapterService {
    constructor(@Inject(DOCUMENT) private readonly document: Document) {}

    adaptImage(
        reader: FileReader,
        config: ImageAdapterConfiguration = defaultImageAdapterConfig,
    ): Observable<FileReader> {
        return observableOf(reader).pipe(
            this.fileReaderToImage(),
            this.redrawImage(config),
            this.imageToFileReader(),
            take(1),
        );
    }

    private getCanvas(): HTMLCanvasElement {
        const canvas: HTMLCanvasElement = this.document.createElement('canvas');
        canvas.id = 'canvas';
        canvas.style.display = 'none';
        document.body.appendChild(canvas);

        return canvas;
    }

    private destroyCanvas(canvas: HTMLCanvasElement): void {
        canvas.remove();
    }

    // Observable pipeable operator
    private fileReaderToImage(): OperatorFunction<FileReader, HTMLImageElement> {
        return (source: Observable<FileReader>) => {
            return new Observable((subscriber) => {
                return source.subscribe((fileReader) => {
                    const onLoaded = () => {
                        const image: HTMLImageElement = new Image();
                        image.onload = () => {
                            subscriber.next(image);
                        };
                        image.src = fileReader.result as string; // the file was read using readAsDataURL
                    };

                    if (fileReader.readyState === fileReader.DONE) {
                        onLoaded();
                    } else {
                        fileReader.onloadend = () => onLoaded();
                    }
                });
            });
        };
    }

    // Observable pipeable operator
    private redrawImage(config: ImageAdapterConfiguration): OperatorFunction<HTMLImageElement, HTMLImageElement> {
        return (source: Observable<HTMLImageElement>) =>
            new Observable((subscriber: Subscriber<HTMLImageElement>) => {
                return source.pipe(take(1)).subscribe((image: HTMLImageElement) => {
                    const shouldRedraw = image.height > config.maxHeight || image.width > config.maxWidth;

                    if (!shouldRedraw) {
                        subscriber.next(image);
                    } else {
                        const newImage = this.redrawImageInternal(image, config);
                        if (newImage.complete) {
                            subscriber.next(newImage);
                        } else {
                            newImage.onload = () => {
                                subscriber.next(newImage);
                            };
                        }
                    }
                });
            });
    }

    private redrawImageInternal(image: HTMLImageElement, config: ImageAdapterConfiguration): HTMLImageElement {
        const canvas: HTMLCanvasElement = this.getCanvas();
        const orientation = this.getImageOrientation(image);
        if (image.width / 2 < config.maxWidth) {
            if (orientation === 'landscape') {
                canvas.width = config.maxWidth;
                canvas.height = (image.height / image.width) * config.maxWidth;
            } else {
                canvas.height = config.maxHeight;
                canvas.width = (image.width / image.height) * config.maxHeight;
            }
        } else {
            canvas.width = image.width / 2;
            canvas.height = image.height / 2;
        }

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        const resultImage = new Image();
        resultImage.src = canvas.toDataURL(config.outputFormat, config.quality);
        this.destroyCanvas(canvas);

        return resultImage;
    }

    private getImageOrientation(image: HTMLImageElement): ImageOrientation {
        if (image.width > image.height) {
            return 'landscape';
        } else {
            return 'portrait';
        }
    }

    // Observable pipeable operator
    private imageToFileReader(): OperatorFunction<HTMLImageElement, FileReader> {
        return (source: Observable<HTMLImageElement>) =>
            new Observable((subscriber) => {
                return source.subscribe((image) => {
                    const fileReader = new FileReader();
                    const imageData = new Blob([image.src]);
                    fileReader.onloadend = () => {
                        subscriber.next(fileReader);
                    };

                    // The file reader must contain the image URL as result
                    // which is why we don't read an actual file but a blob
                    // created from the image url that has already been created
                    fileReader.readAsText(imageData);
                });
            });
    }
}
