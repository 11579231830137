import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { map, take } from 'rxjs/operators';

const REQUEST_NORMAL_DURATION = 1_000; // FIXME: when common and core will be combined it shoul be one const

@Component({
    selector: 'tp-profile-navigation',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileNavigationComponent {
    @Input() prev: any;
    @Input() next: any;
    @Input() context: string;
    @Input() isProfilesLoading = false;

    public showLoader = false;
    public nextButtonClicked = false;
    public prevButtonClicked = false;
    public timeout = 0;

    constructor(
        private readonly store: Store<{
            router: RouterReducerState;
        }>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private cd: ChangeDetectorRef,
    ) {
        this.activatedRoute.params
            .pipe(
                map((params) => params['clicked']),
                take(1),
            )
            .subscribe((clicked) => {
                if ('next' === clicked) {
                    this.nextButtonClicked = true;
                } else if ('prev' === clicked) {
                    this.prevButtonClicked = true;
                }
            });
    }

    goNext() {
        if (this.next) {
            this.prevButtonClicked = false;
            this.nextButtonClicked = true;
            this.goToProfilePage(this.next.userId);
        }
    }

    goPrev() {
        if (this.prev) {
            this.nextButtonClicked = false;
            this.prevButtonClicked = true;
            this.goToProfilePage(this.prev.userId);
        }
    }

    goToProfilePage(userId: string): void {
        this.timeout = window.setTimeout(() => {
            this.showLoader = true;
            this.cd.detectChanges();
        }, REQUEST_NORMAL_DURATION);
        this.store // FIXME this shouldn't be necessary anymore if modal windows don't trigger a location change, until then: ugly hack.
            .select('router')
            .pipe(take(1))
            .subscribe((routerState) => {
                const src = routerState.state.root.firstChild.firstChild.params.src;
                this.router
                    .navigate([
                        `/profile/${userId}`,
                        {
                            ref: this.context,
                            src,
                            context: this.context,
                            clicked: this.nextButtonClicked ? 'next' : 'prev',
                        },
                    ])
                    .then(() => {
                        window.clearTimeout(this.timeout);
                        this.timeout = 0;
                        this.showLoader = false;
                        this.nextButtonClicked = false;
                        this.prevButtonClicked = false;
                    });
            });
    }
}
