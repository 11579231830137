import { NgModule } from '@angular/core';
import { JobXProfileNavigatorViewComponent } from './job-x-profile-navigator-view.component';
import { CommonModule } from '@angular/common';
import { LanguageModule, ProfileContentModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [JobXProfileNavigatorViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class JobXProfileNavigatorViewModule {}
