import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@tploy-enterprise/tenant-common';
import { NLAProfileTileLocationIndependentPopoverData } from './nla-profile-tile-location-independent.component';

@Component({
    selector: 'tp-nla-profile-tile-location-independent-popover',
    templateUrl: './nla-profile-tile-location-independent-popover.component.html',
})
export class NlaProfileTileLocationIndependentPopoverComponent {
    highlightLocationIndependent: boolean;

    constructor(@Inject(POPOVER_DATA) public popoverData: NLAProfileTileLocationIndependentPopoverData) {
        this.highlightLocationIndependent = this.popoverData.matched;
    }
}
