export const QUESTIONNAIRE_STORAGE_EDIT_KEY = 'tploy.questionnaire.edit';

export interface QuestionnaireAnswer {
    question: string;
    answer: string[];
}
export interface QuestionnaireData {
    answers: QuestionnaireAnswer[];
    currentQuestion: string;
}

export interface QuestionnaireAnswerDto {
    question: string;
    answer: string[];
}
export interface QuestionnaireDataDto {
    answers: QuestionnaireAnswerDto[];
    currentQuestion: string;
}

export interface QuestionStepDefinition {
    id: string;
    header?: string;
    subheader?: string;
    bodyText?: string;
    maxSelection?: number;
    options?: Array<string>;
    input?: string;
    textarea?: string;
    placeholder?: string;
    shortHeader?: string;
    shortOptions?: string[];
}
