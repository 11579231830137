import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule, ProfileContentModule, SkillModule } from '@tploy-enterprise/tenant-common';
import { MentoringProfileViewComponent } from './mentoring-profile-view.component';

@NgModule({
    declarations: [MentoringProfileViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule, SkillModule],
})
export class MentoringProfileViewModule {}
