import { Directive, ElementRef } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

@Directive({
    selector: '[focused]',
    exportAs: 'focusContainer',
})
export class FocusDirective {
    constructor(private readonly elementRef: ElementRef, private readonly focusMonitor: FocusMonitor) {
        this.focusMonitor.monitor(this.elementRef, true);
    }
}
