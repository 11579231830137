import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BlogArticleDTO } from './blog.types';
import { LanguageOptions } from '@tploy-enterprise/tenant-common';

const BASE_PATH = '/api/v2/blog-proxy/posts';

@Injectable({
    providedIn: 'root',
})
export class BlogApi {
    constructor(private http: HttpClient) {}

    getArticles(locale: LanguageOptions): Observable<BlogArticleDTO[]> {
        return this.http.get<BlogArticleDTO[]>(`${BASE_PATH}?locale=${locale}`);
    }

    getArticleById(id: string): Observable<BlogArticleDTO> {
        return this.http.get<BlogArticleDTO>(`${BASE_PATH}/${id}`);
    }
}
