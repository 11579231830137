import { Component, ChangeDetectionStrategy, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationsData, CustomSnack } from './notifications.types';

@Component({
    selector: 'tp-notification-action-snack',
    templateUrl: './action-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionSnackComponent implements CustomSnack {
    @Output() close = new EventEmitter<void>();

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationsData) {}

    doClose() {
        this.close.emit();
    }

    callCallback() {
        this.data.action.callback();
        this.doClose();
    }
}
