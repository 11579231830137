import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { NLAProfile } from './nla-service/nla.types';
import { NLAActions } from './nla.actions';
import { isNLAProfileLoaded, isNLAProfileLoadedOrLoading, selectCurrentNLAProfile } from './nla.selectors';
import { NLAState } from './nla.reducer';

@Injectable({
    providedIn: 'root',
})
export class NLACurrentProfileResolver implements Resolve<NLAProfile> {
    constructor(private readonly store: Store<{ neverLunchAlone: NLAState }>) {}

    resolve(): Observable<NLAProfile> {
        this.loadIfNotLoaded();

        return this.store.select(isNLAProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentNLAProfile)),
            take(1),
        );
    }

    private loadIfNotLoaded() {
        this.store
            .select(isNLAProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(NLAActions.loadProfile());
                }
            });
    }
}
