<ng-template #withLevelTemplate let-skill>
    <span
        *ngIf="skill.name.length > 0"
        [matTooltip]="getTooltip(skill)"
        [matTooltipPosition]="'above'"
        [attr.aria-label]="getTooltip(skill)"
    >
        <ng-container [ngSwitch]="skillBold">
            <span *ngSwitchCase="false">{{ skill.name }}</span>
            <strong *ngSwitchCase="true">{{ skill.name }}</strong>
        </ng-container>
        ({{ getLevelTranslation(skill.level) | translatable }})<ng-container *ngIf="showComma && !dotMode"
            >, </ng-container
        ><ng-container *ngIf="showComma && dotMode"><span class="dot-mode">&#8226;</span></ng-container>
    </span>
    <span *ngIf="!skill.name.length"></span>
</ng-template>

<ng-template #defaultTemplate let-skill>
    <span [matTooltip]="getTooltip(skill)" [matTooltipPosition]="'above'" [attr.aria-label]="getTooltip(skill)">
        {{ skill.name }}<ng-container *ngIf="showComma && !dotMode">, </ng-container
        ><ng-container *ngIf="showComma && dotMode"><span class="dot-mode">&#8226;</span></ng-container>
    </span>
</ng-template>

<ng-container
    [ngTemplateOutlet]="withLevel ? withLevelTemplate : defaultTemplate"
    [ngTemplateOutletContext]="{ $implicit: skill }"
>
</ng-container>
