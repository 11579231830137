import { DirectMessagesState } from './direct-messages.state';
import { TOPIC_ORIGIN_CONTEXT_MAPPER } from '../../../tenant-core.constants';

export const directMessagesSelector = (state: { directMessages?: DirectMessagesState }) => {
    return state.directMessages;
};

export const directMessagesConversationSelector = (state: { directMessages?: DirectMessagesState }) => {
    if (state.directMessages) {
        if (state.directMessages.conversations) {
            return state.directMessages.conversations;
        }
    }
};

export const directMessagesCurrentConversationSelector = (state: { directMessages?: DirectMessagesState }) => {
    return state.directMessages.currentConversation;
};

export const directMessagesCurrentConversationContext = (state: { directMessages?: DirectMessagesState }) => {
    return TOPIC_ORIGIN_CONTEXT_MAPPER[state.directMessages.currentConversation?.origin] || '';
};
