import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigService } from './config.service';

@NgModule({})
export class ConfigModule {
    static forRoot(): ModuleWithProviders<ConfigModule> {
        return {
            ngModule: ConfigModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    deps: [ConfigService],
                    useFactory: (configService: ConfigService) => {
                        return () => {
                            return configService.loadConfig().toPromise();
                        };
                    },
                },
                ConfigService,
            ],
        };
    }
}
