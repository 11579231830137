import { Directive, HostBinding, DoCheck, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { CollapsableContentDirective } from './collapsable-content.directive';

@Directive({
    selector: '[tpCollapsable],[tpcollapsable]',
    exportAs: 'tpCollapsable',
})
export class CollapsableDirective implements DoCheck {
    @HostBinding('class.collapsable')
    collapsibleClass = true;

    @HostBinding('class.collapsable--opened')
    opened = false;

    @HostBinding('class.collapsable--closed')
    closedClass = false;

    @Input()
    targetName: string;

    @Input()
    @HostBinding('class.disabled')
    disabled = false;

    @Output()
    onOpen = new EventEmitter();
    @Output()
    onClose = new EventEmitter();

    content: CollapsableContentDirective;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngDoCheck() {
        this.closedClass = !this.content || this.content.isHidden;
        this.opened = !this.closedClass;
    }

    toggle() {
        if (this.disabled) {
            return;
        }

        if (this.content.isShown) {
            this.close();
        } else {
            this.open();
        }
        this.changeDetectorRef.detectChanges();
    }

    open() {
        if (!this.disabled) {
            this.content.show();
            this.onOpen.emit();
        }
    }

    close() {
        if (!this.disabled) {
            this.content.hide();
            this.onClose.emit();
        }
    }

    setContent(content: CollapsableContentDirective) {
        this.content = content;
    }

    removeContent() {
        this.content = null;
    }
}
