import { createAction, props } from '@ngrx/store';
import { BlogArticle } from './blog.types';
import { LanguageOptions } from '@tploy-enterprise/tenant-common';

export const BlogActions = {
    loadArticle: createAction('[Blog] Load article'),
    loadArticleSuccess: createAction('[Blog][Success] Load article', props<{ article: BlogArticle }>()),
    loadArticleError: createAction('[Blog][Error] Load article', props<{ error: Error }>()),

    loadArticles: createAction('[Blog] Load articles', props<{ locale: LanguageOptions }>()),
    loadArticlesSuccess: createAction('[Blog][Success] Load articles', props<{ articles: BlogArticle[] }>()),
    loadArticlesError: createAction('[Blog][Error] Load articles', props<{ error: Error }>()),
};
