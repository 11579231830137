import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PushNotification } from './browser-notifications.types';
import { DefaultBrowserNotificationConfig } from './browser-notification.config';

@Injectable({
    providedIn: 'root',
})
export class BrowserNotificationsService {
    public permission: NotificationPermission;

    private defaultConfig: PushNotification = DefaultBrowserNotificationConfig;

    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    async requestPermission(): Promise<void> {
        if (this.isSupported()) {
            this.permission = await Notification.requestPermission((status) => {
                this.permission = status; // Safari still uses the callback, all other browsers don't
            });
        }
    }

    create(title: string, options?: PushNotification): Observable<{ notification: Notification; event: Event }> {
        options = Object.assign({}, this.defaultConfig, options);

        return new Observable((observer) => {
            if (!this.isSupported()) {
                observer.complete();
            }

            if (this.permission !== 'granted') {
                observer.complete();
            }

            const notification = new Notification(title, options);
            notification.onshow = (event) => observer.next({ notification, event });
            notification.onclick = (event) => observer.next({ notification, event });
            notification.onerror = (event) => observer.error({ notification, event });
            notification.onclose = () => observer.complete();
        });
    }
}
