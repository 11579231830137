import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountSettingsSerializer } from './account-settings.serializer';
import { AccountSettings } from './account-settings.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const PATH = '/api/v2/accounts';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsService {
    constructor(private readonly http: HttpClient, private readonly serializer: AccountSettingsSerializer) {}

    save(accountSettings: AccountSettings): Observable<AccountSettings> {
        const dto = this.serializer.serialize(accountSettings);
        return this.http.put(`${PATH}/settings`, dto).pipe(map(() => accountSettings));
    }
}
