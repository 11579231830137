import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    ProjectApplicationDTO,
    ProjectsEntityDTO,
    ProjectsProfileDTO,
    ProjectsProfileInvitationMessageDTO,
    ProjectsProfileMatchResultDTO,
    ProjectsSuggestedSkillsDTO,
} from './projects.dtos';
import {
    ApplicationRequest,
    ProjectsApplicationIndicatorsDTO,
    ProjectsSearchResultsDTO,
    ProjectsStaffingFilter,
} from '../projects.types';
import { map } from 'rxjs/operators';
import { Params } from '@angular/router';
import { GeneralDataDTO, OffsetLimit, PROJECTS_TOPIC_NAME } from '@tploy-enterprise/tenant-core';

const BASE_PATH = `/api/v2/${PROJECTS_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class ProjectsApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<ProjectsProfileDTO> {
        return this.httpClient.get<ProjectsProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string) {
        return this.httpClient.get<ProjectsProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: ProjectsProfileDTO): Observable<ProjectsProfileDTO> {
        return this.httpClient.put<ProjectsProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, { observe: 'body' });
    }

    getEntity(entityId: string, showHighlighted = false, isStaffingManagerView = false) {
        let params: Params = {};
        if (showHighlighted) {
            params = {
                ...params,
                showHighlighted: 1,
            };
        }
        if (isStaffingManagerView) {
            params = {
                ...params,
                isStaffingManagerView: 1,
            };
        }
        return this.httpClient.get<ProjectsEntityDTO>(`${BASE_PATH}/${entityId}`, { params });
    }

    postEntity(projectsEntity: ProjectsEntityDTO) {
        return this.httpClient.post<ProjectsEntityDTO>(`${BASE_PATH}`, projectsEntity);
    }

    putEntity(projectsEntity: ProjectsEntityDTO, sendNotifications: boolean): Observable<ProjectsEntityDTO> {
        const params: Params = sendNotifications !== undefined ? { sendNotifications } : {};
        return this.httpClient.put<ProjectsEntityDTO>(`${BASE_PATH}/${projectsEntity.id}`, projectsEntity, {
            observe: 'body',
            params,
        });
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.httpClient
            .delete(`${BASE_PATH}/${entityId}`, {
                responseType: 'text',
            })
            .pipe(map(() => entityId));
    }

    publishEntity(entityId: string) {
        return this.httpClient.get<ProjectsEntityDTO>(`${BASE_PATH}/${entityId}/publish`);
    }

    getAppliedToEntities() {
        const httpParams = new HttpParams({
            fromObject: { applied: 'true' },
        });

        return this.httpClient.get<ProjectsEntityDTO[]>(`${BASE_PATH}`, { params: httpParams });
    }

    search(searchQuery: string, offsetLimit: OffsetLimit, campaigns?: string[]) {
        const params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        return this.httpClient.get<ProjectsSearchResultsDTO>(
            `${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`,
            { params },
        );
    }

    loadStaffingEntities(
        searchQuery: string,
        offsetLimit: OffsetLimit,
        filter: ProjectsStaffingFilter,
    ): Observable<ProjectsSearchResultsDTO> {
        let params: Params = {
            ...offsetLimit,
            isStaffingManagerView: 1,
        };
        if (filter !== undefined) {
            params = {
                ...params,
                isApplicationOpen: filter.isApplicationOpen ? 1 : 0,
            };
            if (filter.statuses.length > 0) {
                params = {
                    ...params,
                    statuses: filter.statuses.join(','),
                };
            }
            if (filter.categories.length > 0) {
                params = {
                    ...params,
                    categories: filter.categories.join(','),
                };
            }
            if (filter.languages.length > 0) {
                params = {
                    ...params,
                    languages: filter.languages.join(','),
                };
            }
        }
        return this.httpClient.get<ProjectsSearchResultsDTO>(
            `${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`,
            {
                params,
            },
        );
    }

    getEntitiesOfferedByMe(userId: string) {
        const params = new HttpParams({
            fromObject: {
                organizer: userId,
            },
        });

        return this.httpClient.get<ProjectsEntityDTO[]>(`${BASE_PATH}`, { params });
    }

    postEntityApplication(entityId, application: ApplicationRequest): Observable<ProjectApplicationDTO> {
        return this.httpClient.post<ProjectApplicationDTO>(`${BASE_PATH}/${entityId}/application`, application);
    }

    patchEntityApplicationStatus(
        entityId: string,
        applicationId: string,
        patch: { status: 'approved' | 'rejected' | 'cancelled' },
    ): Observable<ProjectApplicationDTO> {
        return this.httpClient.patch<ProjectApplicationDTO>(
            `${BASE_PATH}/${entityId}/application/${applicationId}`,
            patch,
        );
    }

    getProfileMatches(offsetLimit: OffsetLimit, campaigns?: string[]): Observable<ProjectsProfileMatchResultDTO> {
        const params: Params = { ...offsetLimit, campaigns: campaigns?.length > 0 ? campaigns.join(',') : [] };
        return this.httpClient.get<ProjectsProfileMatchResultDTO>(`${BASE_PATH}/matches`, { params });
    }

    searchProfiles(projectId: string, searchTerms: string[]): Observable<ProjectsProfileDTO[]> {
        const params: Params = {
            q: encodeURIComponent(searchTerms.join(',')),
        };

        return this.httpClient.get<ProjectsProfileDTO[]>(`${BASE_PATH}/profiles/search/${projectId}`, { params });
    }

    searchTopicUserByName(queryString: string, entityId: string): Observable<Partial<GeneralDataDTO>[]> {
        const entityIdSegment = entityId ? `&projectId=${entityId}` : '';
        return this.httpClient.get<Partial<GeneralDataDTO>[]>(
            `${BASE_PATH}/profiles/search/potential-organizers/?q=${queryString}${entityIdSegment}`,
            {
                observe: 'body',
            },
        );
    }

    sendInvitation(invitation: ProjectsProfileInvitationMessageDTO): Observable<void> {
        return this.httpClient.post<void>(`${BASE_PATH}/profiles/message`, invitation);
    }

    getSuggestedSkills(id: string) {
        return this.httpClient.get<ProjectsSuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }

    completeStuffing(entityId: string): Observable<ProjectsEntityDTO> {
        return this.httpClient.put<ProjectsEntityDTO>(`${BASE_PATH}/${entityId}/complete-staffing`, {
            observe: 'body',
        });
    }

    finishOngoing(entityId: string): Observable<ProjectsEntityDTO> {
        return this.httpClient.put<ProjectsEntityDTO>(`${BASE_PATH}/${entityId}/finish`, {
            observe: 'body',
        });
    }

    getApplications(entityId: string): Observable<Array<ProjectApplicationDTO>> {
        return this.httpClient.get<Array<ProjectApplicationDTO>>(`${BASE_PATH}/${entityId}/application`);
    }

    getUnseenApplications(): Observable<ProjectsApplicationIndicatorsDTO> {
        return this.httpClient.get<ProjectsApplicationIndicatorsDTO>(
            `${BASE_PATH}${PROFILE_ENDPOINT}/unseen-applications`,
            { observe: 'body' },
        );
    }

    markTopicLinkApplicationsAsSeen(): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}${PROFILE_ENDPOINT}/mark-applications-topic-link-as-seen`, {
            observe: 'body',
        });
    }

    markOfferingTabApplicationsAsSeen(): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}${PROFILE_ENDPOINT}/mark-applications-offering-tab-as-seen`, {
            observe: 'body',
        });
    }

    markApplicationsAsSeen(entityId: string): Observable<unknown> {
        return this.httpClient.post(`${BASE_PATH}/${entityId}/mark-applications-as-seen`, { observe: 'body' });
    }
}
