import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthenticationEndpoints } from './constants';
import { AuthenticationActions } from './authentication.actions';
import { Store } from '@ngrx/store';
import { SocketIOService } from '../socket.io/socket-io.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject = new BehaviorSubject<null | boolean>(null);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store<unknown>,
        private readonly socketIOService: SocketIOService,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((requestError: HttpErrorResponse) => {
                if (requestError.status === 401) {
                    if (RefreshTokenInterceptor.isValidEndpoint(request)) {
                        if (this.refreshTokenInProgress) {
                            return this.refreshTokenSubject.pipe(
                                filter((tokenResult) => tokenResult !== null),
                                take(1),
                                switchMap(() => next.handle(request)),
                            );
                        } else {
                            this.refreshTokenInProgress = true;
                            this.refreshTokenSubject.next(null);

                            this.socketIOService.disconnect();

                            return this.refreshToken().pipe(
                                switchMap(() => {
                                    this.refreshTokenInProgress = false;
                                    this.refreshTokenSubject.next(true);
                                    this.socketIOService.connect();
                                    return next.handle(request);
                                }),
                                catchError((refreshTokenError) => {
                                    this.refreshTokenInProgress = false;
                                    this.refreshTokenSubject.next(null);

                                    this.store.dispatch(AuthenticationActions.signOutSuccess());

                                    this.store.dispatch(
                                        AuthenticationActions.forceLogin({
                                            returnUrl: encodeURI(window.location.href),
                                        }),
                                    );
                                    return throwError(refreshTokenError);
                                }),
                            );
                        }
                    }
                }
                return throwError(requestError);
            }),
        );
    }

    private refreshToken(): Observable<boolean> {
        return this.httpClient
            .post(`${AuthenticationEndpoints.RefreshToken}`, {}, { responseType: 'text' })
            .pipe(map(() => true));
    }

    private static isValidEndpoint(request: HttpRequest<unknown>): boolean {
        return !request.url.includes(AuthenticationEndpoints.RefreshToken) && !request.url.includes('/auth/sign-in');
    }
}
