import { createAction, props } from '@ngrx/store';
import { ExperienceData } from './experience.types';

export const ExperienceActions = {
    edit: createAction('[Account Experience] Edit', props<{ experienceData: ExperienceData }>()),
    abortEdit: createAction('[Account Experience] Abort edition'),
    save: createAction('[Account Exerience] Save'),
    saveSuccess: createAction('[Account Experience][Success] Save', props<{ experienceData: ExperienceData }>()),
    saveError: createAction(
        '[Account Experience][Error] Save',
        props<{ error: Error; experienceData: ExperienceData }>(),
    ),
};
