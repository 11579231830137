import { createReducer, on } from '@ngrx/store';
import { SuccessFactorsActions } from './success-factors.actions';
import { AccountActions } from '../account/account.actions';
import { Skill } from '@tploy-enterprise/tenant-common';
import { SELECTED_TOPICS_STORAGE_EDIT_KEY } from '../account/selected-topics/selected-topics.types';

export interface SuccessFactorsSkillsState {
    originalRequiredSkills: Skill[];
    originalNTHSkills: Skill[];
    requiredSkills: Skill[];
    nthSkills: Skill[];
    newSkills: Skill[];
    changedLevelSkills: Skill[];
    successFactorsCanBeUpdated: boolean;
    topicsForSync: string[];
    skillsForSync: Skill[];
}

function getInitialEditState() {
    const stored = window.localStorage.getItem(SELECTED_TOPICS_STORAGE_EDIT_KEY);
    if (stored) {
        const { data } = JSON.parse(stored);
        return data;
    } else {
        return [];
    }
}

const initialSuccessFactorsSkillsState: SuccessFactorsSkillsState = {
    originalRequiredSkills: [],
    originalNTHSkills: [],
    requiredSkills: [],
    nthSkills: [],
    newSkills: [],
    changedLevelSkills: [],
    successFactorsCanBeUpdated: false,
    topicsForSync: getInitialEditState(),
    skillsForSync: [],
};

function successFactorsCanBeUpdated(originalSkills, skills): boolean {
    return (
        skills.length > originalSkills.length ||
        skills.some((skill) => {
            const sameSkill = originalSkills.find((originalSkill) => skill.name === originalSkill.name);
            return !sameSkill || sameSkill.level !== skill.level;
        })
    );
}

function getNewSkills(updatedSkills, originalSkills): Skill[] {
    return updatedSkills.filter((skill) => !originalSkills.some((originalSkill) => skill.name === originalSkill.name));
}

function getSkillsWithUpdatedLevel(updatedSkills, originalSkills): Skill[] {
    return updatedSkills.filter((skill) =>
        originalSkills.some(
            (originalSkill) => skill.name === originalSkill.name && skill.level !== originalSkill.level,
        ),
    );
}

const successFactorsSkillsReducer = createReducer(
    initialSuccessFactorsSkillsState,
    on(SuccessFactorsActions.setSuccessFactorsOriginalRequiredSkills, (state, { skills }) => {
        return {
            ...state,
            requiredSkills: initialSuccessFactorsSkillsState.requiredSkills,
            originalRequiredSkills: skills,
            newSkills: initialSuccessFactorsSkillsState.newSkills,
            changedLevelSkills: initialSuccessFactorsSkillsState.changedLevelSkills,
        };
    }),
    on(SuccessFactorsActions.setSuccessFactorsOriginalNTHSkills, (state, { skills }) => {
        return {
            ...state,
            nthSkills: initialSuccessFactorsSkillsState.nthSkills,
            originalNTHSkills: skills,
            newSkills: initialSuccessFactorsSkillsState.newSkills,
            changedLevelSkills: initialSuccessFactorsSkillsState.changedLevelSkills,
        };
    }),
    on(SuccessFactorsActions.setSuccessFactorsRequiredSkills, (state, { skills }) => {
        return {
            ...state,
            requiredSkills: skills,
            newSkills: getNewSkills(skills, state.originalRequiredSkills),
            changedLevelSkills: getSkillsWithUpdatedLevel(skills, state.originalRequiredSkills),
            successFactorsCanBeUpdated:
                successFactorsCanBeUpdated(state.originalRequiredSkills, skills) ||
                successFactorsCanBeUpdated(state.originalNTHSkills, state.nthSkills),
        };
    }),
    on(SuccessFactorsActions.setSuccessFactorsNTHSkills, (state, { skills }) => {
        return {
            ...state,
            nthSkills: skills,
            newSkills: getNewSkills(skills, state.originalNTHSkills),
            changedLevelSkills: getSkillsWithUpdatedLevel(skills, state.originalNTHSkills),
            successFactorsCanBeUpdated:
                successFactorsCanBeUpdated(state.originalRequiredSkills, state.requiredSkills) ||
                successFactorsCanBeUpdated(state.originalNTHSkills, skills),
        };
    }),
    on(SuccessFactorsActions.syncSuccessFactorsSkills, (state, { skills }) => {
        return {
            ...state,
            skillsForSync: skills,
        };
    }),
    on(SuccessFactorsActions.resetSuccessFactorsSkills, (state) => {
        return {
            ...state,
            nthSkills: initialSuccessFactorsSkillsState.nthSkills,
            requiredSkills: initialSuccessFactorsSkillsState.requiredSkills,
            originalRequiredSkills: initialSuccessFactorsSkillsState.originalRequiredSkills,
            originalNTHSkills: initialSuccessFactorsSkillsState.originalNTHSkills,
            newSkills: initialSuccessFactorsSkillsState.newSkills,
            changedLevelSkills: initialSuccessFactorsSkillsState.changedLevelSkills,
            successFactorsCanBeUpdated: initialSuccessFactorsSkillsState.successFactorsCanBeUpdated,
        };
    }),
    on(SuccessFactorsActions.setTopicForSync, (state, { name }) => {
        return {
            ...state,
            topicsForSync: [...state.topicsForSync, name],
        };
    }),
    on(SuccessFactorsActions.removeTopicFromSync, (state, { name }) => {
        return {
            ...state,
            topicsForSync: state.topicsForSync.filter((topicName) => topicName !== name),
        };
    }),
    on(AccountActions.loadSuccess, (state, { account }) => {
        return {
            ...state,
            topicsForSync: state.topicsForSync.filter((topic) => !(account.selectedTopics || []).includes(topic)),
        };
    }),
);

export const successFactorsReducer = successFactorsSkillsReducer;
