import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models';

export const DirectMessagesWritePermissionsActions = {
    enableWritePermissions: createAction(
        '[DM-Permissions] Enable Write Permissions',
        props<{ conversationId: string }>(),
    ),
    enableWritePermissionsSuccess: createAction(
        '[DM-Permissions][Success] Enable Write Permissions',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    enableWritePermissionsError: createAction(
        '[DM-Permissions][Error] Enable Write Permissions',
        props<{ error: Error }>(),
    ),

    enableWritePermissionsOthers: createAction(
        '[DM-Permissions] Enable Write Permissions Others',
        props<{ conversationId: string }>(),
    ),
    enableWritePermissionsOthersSuccess: createAction(
        '[DM-Permissions][Success] Enable Write Permissions Others',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    enableWritePermissionsOthersError: createAction(
        '[DM-Permissions][Error] Enable Write Permissions Others',
        props<{ error: Error }>(),
    ),

    disableWritePermissions: createAction(
        '[DM-Permissions] Disable Write Permissions',
        props<{ conversationId: string }>(),
    ),
    disableWritePermissionsSuccess: createAction(
        '[DM-Permissions][Success] Disable Write Permissions',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    disableWritePermissionsError: createAction(
        '[DM-Permissions][Error] Disable Write Permissions',
        props<{ error: Error }>(),
    ),

    disableWritePermissionsOthers: createAction(
        '[DM-Permissions] Disable Write Permissions Others',
        props<{ conversationId: string }>(),
    ),
    disableWritePermissionsOthersSuccess: createAction(
        '[DM-Permissions][Success] Disable Write Permissions Others',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    disableWritePermissionsOthersError: createAction(
        '[DM-Permissions][Error] Disable Write Permissions Others',
        props<{ error: Error }>(),
    ),
};
