import { NgModule } from '@angular/core';
import { MatchingLoaderComponent } from './matching-loader/matching-loader.component';
import { LanguageModule } from '../language';

@NgModule({
    declarations: [MatchingLoaderComponent],
    exports: [MatchingLoaderComponent],
    imports: [LanguageModule],
})
export class MatchingLoaderModule {}
