import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExperienceSerializer } from './experience.serializer';
import { ExperienceData } from './experience.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const PATH = '/api/v2/accounts';

@Injectable({
    providedIn: 'root',
})
export class ExperienceService {
    constructor(private http: HttpClient, private serializer: ExperienceSerializer) {}

    save(experience: ExperienceData): Observable<ExperienceData> {
        const dto = this.serializer.serialize(experience);
        return this.http.put(`${PATH}/experience`, dto).pipe(map(() => experience));
    }
}
