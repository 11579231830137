import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoweredByComponent } from './powered-by.component';
import { LanguageModule } from '../language';
import { LinkModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [CommonModule, LanguageModule, LinkModule],
    declarations: [PoweredByComponent],
    exports: [PoweredByComponent],
})
export class PoweredByModule {}
