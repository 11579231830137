import { Component, Input } from '@angular/core';
import { AccountListItemTag } from './account-list-item.types';

@Component({
    selector: 'tp-list-item-tags',
    templateUrl: './account-list-item-tags.component.html',
    host: {
        class: 'list-item__tags',
    },
})
export class AccountListItemTagsComponent {
    @Input() tags: AccountListItemTag[];
}
