import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'tp-tagger',
    templateUrl: './tagger.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggerComponent implements OnInit {
    protected tagsNames: Array<string> = [];
    public textLimit = 25;

    private readonly checkNamesEquality = (name1: string, name2: string) => {
        return this.trimTagName(name1) === this.trimTagName(name2);
    };

    private trimTagName(name: string): string {
        name = name?.toLowerCase().trim();
        return name?.startsWith('#') ? name.substring(1) : name;
    }

    @Input()
    tags: Array<string> = [];

    @Input()
    selectedTags: Array<string> = [];

    @HostBinding('class.selectable')
    @Input()
    selectable = false;

    @HostBinding('class.simple-view')
    @Input()
    simpleView = true;

    @HostBinding('class.tagger--thin')
    @Input()
    thin = false;

    @Input()
    ariaLabel: string;

    @Output()
    selectTag = new EventEmitter<string>();

    ngOnInit(): void {
        this.tagsNames = this.sortTags(this.tags);
    }

    getTagsNames(): Array<string> {
        return [...new Set(this.tagsNames)];
    }

    getTagValue(tag: string): string {
        return this.hasHiddenText(tag) ? tag.slice(0, this.textLimit) + '...' : tag;
    }

    isSelected(tag: string): boolean {
        return this.selectedTags.some((selected) => this.checkNamesEquality(selected, tag));
    }

    protected sortTags(tags: Array<string>): Array<string> {
        const selected = tags.filter((tag) => this.isSelected(tag));
        const unselected = tags.filter((tag) => !this.isSelected(tag));

        // selected tags should be at the beginning
        return [...selected, ...unselected];
    }

    onClick({ event: MouseEvent }, clickedTagName: string): void {
        event.stopPropagation();
        event.preventDefault();
        this.emitSelectedTag(clickedTagName);
    }

    onEnterPressed({ event: KeyboardEvent }, tagKeyPressedOn: string): void {
        event.stopPropagation();
        event.preventDefault();
        this.emitSelectedTag(tagKeyPressedOn);
    }

    private emitSelectedTag(selectedTagName: string) {
        const tagToEmit = this.tags.find((tag) => this.checkNamesEquality(tag, selectedTagName));
        this.selectTag.emit(tagToEmit);
    }

    hasHiddenText(tag: string): boolean {
        return tag.length > this.textLimit;
    }
}
