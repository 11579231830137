import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from './account.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AccountNotFoundException } from './account-not-found.exception';
import { RedirectService } from '../../redirect.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigService } from '../config/config.service';
import { AccountState } from './account.reducer';
import { AccountActions } from './account.actions';
@Injectable({
    providedIn: 'root',
})
export class EmptyAccountResolver implements Resolve<any> {
    constructor(
        protected store: Store<{ account: AccountState }>,
        private readonly accountService: AccountService,
        private readonly redirectService: RedirectService,
        private readonly configService: ConfigService,
    ) {}

    get isSuccessFactorIntegrated(): boolean {
        return this.configService.remoteSettings.integrationSuccessFactor;
    }

    resolve(): Observable<any> {
        return this.store
            .select((state) => state.account)
            .pipe(
                tap((accountState) => this.checkSsoStatus(accountState)),
                filter((accountState) =>
                    this.isSuccessFactorIntegrated
                        ? accountState.ssoLoadStatus.loaded && accountState.successFactorsUserDataLoadStatus.loaded
                        : accountState.ssoLoadStatus.loaded,
                ),
                switchMap(() => {
                    return this.accountService.loadCurrentUserAccount().pipe(
                        tap(() =>
                            this.redirectService.redirect(this.redirectService.getRedirect('authenticated', true, {})),
                        ),
                        catchError((error) => {
                            if (error instanceof AccountNotFoundException) {
                                return of(true);
                            } else {
                                throw error;
                            }
                        }),
                    );
                }),
                take(1),
            );
    }

    private checkSsoStatus(accountState: AccountState): void {
        if (!accountState.ssoLoadStatus.loaded && !accountState.ssoLoadStatus.loading) {
            this.store.dispatch(AccountActions.loadSsoData());
        }
    }
}
