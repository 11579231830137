import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-indicator',
    templateUrl: './card-indicator.component.html',
    host: {
        class: 'card__indicators',
    },
})
export class CardIndicatorComponent {}
