import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { TagsSuggestionComponent } from './tags-suggestion.component';
import { FormFieldModule } from '../../form-field/form-field.module';
import { LanguageModule } from '../../language';
import { ChipsModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [CommonModule, FormFieldModule, LanguageModule, MatChipsModule, MatIconModule, ChipsModule],
    declarations: [TagsSuggestionComponent],
    exports: [TagsSuggestionComponent],
})
export class TagsSuggestionModule {}
