import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TenantCoreModule, TenantCoreComponent, UnknownRouteModule } from '@tploy-enterprise/tenant-core';
import { CustomTemplateModule, FeaturesModule, localSettings } from '../configuration';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';

// This weird trick of importing the module and provide its providers independently seem to be required
// after migrating to angular 9. This feels like a glitch.

// the order of imported module is important. TenantCoreModule has to come before FeaturesModule
// otherwise we have issues with the topic translations, because they will use different instances
// of the language service

const TenantCoreModuleWithProviders = TenantCoreModule.configure({
    localSettings,
    environment,
});

@NgModule({
    imports: [BrowserModule, RouterModule, TenantCoreModule, FeaturesModule, CustomTemplateModule, UnknownRouteModule],
    providers: [...TenantCoreModuleWithProviders.providers],
    bootstrap: [TenantCoreComponent],
})
export class WorkzModule {}
