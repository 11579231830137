<ul class="language-switcher_menu" role="menu" data-cy="language-switcher_menu">
    <li
        *ngFor="let item of localeItems"
        role="menuitem"
        class="language-switcher_menuitem"
        [class.selected]="item.selected"
    >
        <button
            class="language-switcher_button-switch"
            tabindex="0"
            [attr.aria-label]="item.ariaLabel | translatable"
            (click)="doSwitch(item.locale)"
            (keydown.enter)="doSwitch(item.locale)"
        >
            {{ item.label }}
        </button>
    </li>
</ul>
