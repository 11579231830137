import {
    ProjectsProfile,
    ProjectsProfileMatch,
    ProjectsSearchResult,
    ProjectSuggestedSkills,
    PROFILE_STORAGE_KEY,
    ProjectsEntity,
    ProjectsStaffingFilter,
} from './projects.types';
import { ProjectsProfileState, ProjectsSearchState, ProjectsState } from './projects.reducer';
import {
    AccountState,
    GeneralData,
    INTEREST_KEY,
    OFFERING_KEY,
    PageParams,
    PROJECTS_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';

export function selectCurrentProjectsProfile(
    state: { projects: ProjectsState },
    selectCurrent = false,
): ProjectsProfile {
    return !selectCurrent
        ? JSON.parse(localStorage.getItem(PROFILE_STORAGE_KEY)) || state.projects.profile.current
        : state.projects.profile.current;
}

export function selectSavedProjectsProfile(state: { projects: ProjectsState }): ProjectsProfile {
    return state.projects.profile.saved;
}

export function isProjectsProfileLoadedOrLoading(state: { projects: ProjectsState }): boolean {
    const status = state.projects.profile.status;
    return status.loaded || status.loading;
}

export function isProjectsProfileLoaded(state: { projects: ProjectsState }): boolean {
    return state.projects.profile.status.loaded;
}

export function isProjectsProfileInDirtyState(state: { projects: ProjectsState }): boolean {
    return state.projects.profile.status.dirty;
}

export function selectMatchesPageSelectedCampaigns(state: { projects: ProjectsState }): string[] {
    return state.projects.matches.selectedCampaigns;
}

export function hasProjectsProfile(state: { projects: ProjectsState; account: AccountState }): boolean {
    const profileState: ProjectsProfileState = state.projects.profile;
    const accountState: AccountState = state.account;
    return (
        profileState.status.loaded &&
        !profileState.status.loadingError &&
        profileState.current?.motivations?.length > 0 &&
        accountState.selectedTopics.current.data.includes(PROJECTS_TOPIC_NAME)
    );
}

export function isUserInterestedInProjects(state: { projects: ProjectsState }): boolean {
    return state.projects.profile.current.motivations.indexOf(INTEREST_KEY) !== -1;
}

export function isUserOfferingProjects(state: { projects: ProjectsState }): boolean {
    const currentMotivations = state.projects.profile.current.motivations;
    return currentMotivations.indexOf(OFFERING_KEY) >= 0;
}

export function selectSearchResultsEntities(state: { projects: ProjectsState }): ProjectsSearchResult[] {
    return state.projects.searchResults.results;
}

export function selectSearchPageParams(state: { projects: ProjectsState }): PageParams {
    return state.projects.searchResults.pageParams;
}

export function selectSearchResults(state: { projects: ProjectsState }): ProjectsSearchState {
    return state.projects.searchResults;
}

export function isSearchResultsEmpty(state: { projects: ProjectsState }): boolean {
    const searchState = state.projects.searchResults;
    return searchState.loaded && searchState.results.length == 0;
}

export function isSearchResultsLoading(state: { projects: ProjectsState }): boolean {
    return state.projects.searchResults.loading;
}

export function isOfferedByMeLoading(state: { projects: ProjectsState }): boolean {
    return state.projects.offeredByMe.loading;
}

export function selectMatching(state: { projects: ProjectsState }): ProjectsProfileMatch[] {
    return state.projects.matches.entities;
}

export function selectMatchingPageParams(state: { projects: ProjectsState }): PageParams {
    return state.projects.matches.pageParams;
}

export function suggestedSkills(state: { projects: ProjectsState }): ProjectSuggestedSkills {
    return state.projects.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { projects: ProjectsState }): string[] {
    return state.projects.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { projects: ProjectsState }): string[] {
    return state.projects.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { projects: ProjectsState }): string[] {
    return state.projects.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}

export function showTopicLinkIndicator(state: { projects: ProjectsState }): boolean {
    return state.projects.unseenApplications.hasUnseenApplicationsTopicLink;
}

export function showOfferingTabIndicator(state: { projects: ProjectsState }): boolean {
    return state.projects.unseenApplications.hasUnseenApplicationsOfferingTab;
}

export function selectOfferedByMe(state: { projects: ProjectsState }): Array<ProjectsEntity> {
    return state.projects.offeredByMe.entities;
}

export function selectApplicationSkills(state: { projects: ProjectsState }): Array<Skill> {
    return state.projects.applicationSkills.selectedSkills;
}

export function selectStaffingFilter(state: { projects: ProjectsState }): ProjectsStaffingFilter {
    return state.projects.staffing.filter;
}

export function isStaffingResultsEmpty(state: { projects: ProjectsState }): boolean {
    const staffingState = state.projects.staffing;
    return staffingState.loaded && staffingState.results.length === 0;
}
export function isStaffingResultsLoading(state: { projects: ProjectsState }): boolean {
    return state.projects.staffing.loading;
}

export function selectStaffingResults(state: { projects: ProjectsState }): ProjectsSearchResult[] {
    return state.projects.staffing.results;
}

export function selectStaffingPageParams(state: { projects: ProjectsState }): PageParams {
    return state.projects.staffing.pageParams;
}

export function selectSearchTopicUsers(state: { projects: ProjectsState }): Partial<GeneralData>[] {
    return state.projects.searchTopicUsers.results;
}

export function selectSearchTopicUsersLoading(state: { projects: ProjectsState }): boolean {
    return state.projects.searchTopicUsers.loading;
}
