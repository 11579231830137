export default {
    MENTORING: 'Mentoring',
    MENTORS: 'Mentoren',
    MENTEES: 'Mentees',
    MENTORING_INFO:
        'Keine Lust mehr auf Excel-Listen und aufwendige Mentor*innen-Suche? Mit Hilfe dieses Moduls können sich Mitarbeiter*innen selbstständig und eigeninitiativ zu Mentoring-Tandems zusammenfinden. Das Matching bringt Kolleg*innen zusammen, die sich bei ihren Fragestellungen und Zielen unterstützen und ihr wertvolles Wissen teilen können.',

    SIGN_UP_MENTORING_TITLE: 'Mentoring',
    SIGN_UP_MENTORING_SUBTITLE: 'Für die fachliche und/oder persönliche Weiterentwicklung: Mentor*in sein oder finden.',
    SIGN_UP_MENTORING_CONTENT_TITLE: 'Woran hast du Interesse?',
    MENTORING_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Eine*n Mentor*in finden',
    MENTORING_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Mich als Mentor*in einbringen',
    MENTORING_MOTIVATION_VALIDATION_REQUIRED: 'Es muss mindestens eine Option ausgewählt werden.',
    MENTORING_PROFILE_SKILLS_INTERESTED_IN_VALIDATION_REQUIRED: 'Dies ist ein Pflichtfeld',

    MENTORING_PROFILE_TITLE: 'Mentoring',
    MENTORING_PROFILE_SUBTITLE_FIND_MENTOR: 'Mentor finden.',
    MENTORING_PROFILE_SUBTITLE_FIND_MENTEE: 'Mich als Mentor*in einbringen.',
    MENTORING_PROFILE_MOTIVATION_SUBTITLE:
        'Wählen Sie aus, was Sie interessiert. Sie können auch beide Optionen aktivieren.',
    MENTORING_PROFILE_SKILLS_OFFERING_LABEL: 'Welche Fähigkeiten können Sie als Mentor*in einbringen?',
    MENTORING_PROFILE_SKILLS_INTERESTED_IN_LABEL: 'Welche Fähigkeiten sollte ein/e Mentor*in idealerweise mitbringen?',
    MENTORING_PROFILE_LOCATION_LABEL: 'Mein Standort',
    MENTORING_PROFILE_COMMUNICATE_OPTIONS_LABEL: 'Wie möchten Sie kommunizieren?',
    MENTORING_PROFILE_COMMUNICATE_OPTION_PERSONAL: 'Persönlich an meinem Standort',
    MENTORING_PROFILE_COMMUNICATE_OPTION_VIRTUAL: 'Virtuell',
    MENTORING_PROFILE_COMMUNICATE_OPTION_BOTH: 'Beides ist möglich',
    MENTORING_PROFILE_PERSONAL_MOTIVATION_CAREER_OPTION: 'Bei Karrierefragen',
    MENTORING_PROFILE_PERSONAL_MOTIVATION_EXPERTISE_OPTION: 'Fachliche Weiterentwicklung/Spezialisierung',
    MENTORING_PROFILE_OFFERING_SUPPORT_LABEL: 'Wobei möchten Sie Mentees hauptsächlich unterstützen?',
    MENTORING_PROFILE_INTERESTED_IN_SUPPORT_LABEL: 'Wobei soll der/die Mentor*in Sie unterstützen?',

    MENTORING_PROFILE_FIND_MENTOR_CAMPAIGNS_LABEL: 'HR Programme, an denen Sie als Mentee teilnehmen',
    MENTORING_PROFILE_FIND_MENTEE_CAMPAIGNS_LABEL: 'HR Programme, an denen Sie als Mentor teilnehmen',
    MENTORING_PROFILE_CAMPAIGNS_PLACEHOLDER: 'wählen..',

    MENTORING_MATCHES_TAB: 'Matches',
    MENTORING_BOOKMARKS_TAB: 'Gemerkte Kolleg*innen',
    MENTORING_SEARCH_TAB: 'Suche',

    MENTORING_PROFILE_VIEW_OFFERED_SKILLS: 'Unterstütze Mentees im Bereich',
    MENTORING_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Interesse an Mentor*in im Bereich',
    MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_OFFERING: 'Bevorzugter Kommunikationsweg mit Mentees',
    MENTORING_PROFILE_VIEW_COMMUNICATION_TYPE_INTEREST: 'Bevorzugter Kommunikationsweg mit Mentoren',
    MENTORING_PROFILE_VIEW_CAMPAIGNS_OFFERING: 'HR Programme, an denen ich als Mentor teilnehme',
    MENTORING_PROFILE_VIEW_CAMPAIGNS_INTEREST: 'HR Programme, an denen ich als Mentee teilnehme',
    MENTORING_PROFILE_VIEW_COMMUNICATION_IN_PERSON: 'persönlich',
    MENTORING_PROFILE_VIEW_COMMUNICATION_VIRTUAL: 'virtuell',
    MENTORING_PROFILE_VIEW_COMMUNICATION_BOTH: 'persönlich und virtuell',

    MENTORING_SEARCH_HEADER: 'Mentoring-Profile durchsuchen',
    MENTORING_SEARCH_BTN_TEXT: 'Suchen!',
    MENTORING_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Eine Suche ohne Suchbegriff zeigt alle Mentoren/Mentees.',
    MENTORING_SEARCH_NO_RESULT_IMG_ALT:
        'Das Bild zeigt einen Platzhalter, der anstelle von Ergebnissen erscheint, da die Suche keine Ergebnisse ergab.',
    MENTORING_SEARCH_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von Ihnen gewählten Filter',

    MENTORING_FILTER_OPTION_ALL: 'Alle',
    MENTORING_FILTER_OPTION_MENTORS: 'Mentoren',
    MENTORING_FILTER_OPTION_MENTEES: 'Mentees',

    MENTORING_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',
    MENTORING_NO_MATCH_CONTENT:
        'Bitte geben Sie mehr Fähigkeiten und (versteckte?) Talente in Ihrem Profil an, um Matches zu finden',
    MENTORING_NO_MATCH_CTA: 'Bearbeiten',
    MENTORING_NO_MATCH_FIND_CONTENT: 'Weitere Kolleg*innen über die Suche finden',

    MENTORING_NO_BOOKMARKS_TITLE: 'Noch kein Profil gemerkt',
    MENTORING_NO_BOOKMARKS_SUBTITLE: 'Hier sehen Sie Ihre gemerkten Kolleg*innen im Bereich Mentoring.',

    MENTORING_BOOKMARKS_MENTORS_TITLE: 'Mentoren',
    MENTORING_BOOKMARKS_MENTEES_TITLE: 'Mentees',

    PAGE_TITLE_MENTORING_MATCHES: 'Mentoring - Matches',
    PAGE_TITLE_MENTORING_BOOKMARKS: 'Mentoring - Gemerkte Kollegen',
    PAGE_TITLE_MENTORING_SEARCH: 'Mentoring - Suche',
};
