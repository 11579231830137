import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NLABookmark } from '../../nla-service/nla.types';
import { NLAActions } from '../../nla.actions';
import { NLAState } from '../../nla.reducer';
import { selectBookmarks } from '../../nla.selectors';
import { map } from 'rxjs/operators';

@Component({
    selector: 'tp-nla-bookmarks',
    templateUrl: './nla-bookmarks.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLABookmarksComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    areBookmarksLoading$: Observable<boolean>;
    bookmarks$: Observable<NLABookmark[]>;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly store: Store<{ neverLunchAlone: NLAState }>,
    ) {}

    ngOnInit(): void {
        this.bookmarks$ = this.store.select(selectBookmarks);
        this.store.dispatch(NLAActions.loadBookmarks());
        this.areBookmarksLoading$ = this.store
            .select('neverLunchAlone')
            .pipe(map((neverLunchAlone) => neverLunchAlone.bookmarks.loading));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
