import { createReducer, on } from '@ngrx/store';
import { AuthenticationActions } from './authentication.actions';

export interface AuthenticationState {
    authenticated: boolean;
}

export const initialState: AuthenticationState = {
    authenticated: undefined,
};

const _reducer = createReducer(
    initialState,
    on(AuthenticationActions.verified, (state, { authenticated }) => ({ ...state, authenticated })),
);

export function authenticationReducer(state, action) {
    return _reducer(state, action);
}
