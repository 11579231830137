import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchLoaderComponent } from './search-loader/search-loader.component';
import { LoaderDirective } from './search-loader.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [SearchLoaderComponent, LoaderDirective],
    exports: [SearchLoaderComponent, LoaderDirective],
})
export class SearchLoaderModule {}
