import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AccountResolver } from '@tploy-enterprise/tenant-core';
import { NLACurrentProfileResolver } from '../nla-current-profile.resolver';
import { NlaDomainComponent } from './nla-domain.component';
import { NLAMatchesComponent } from './nla-matches/nla-matches.component';
import { NLABookmarksComponent } from './nla-bookmarks/nla-bookmarks.component';
import { NLASearchResultsComponent } from './nla-search-results/nla-search-results.component';

const routes: Routes = [
    {
        path: '',
        resolve: {
            profile: NLACurrentProfileResolver,
            account: AccountResolver,
        },
        component: NlaDomainComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'matching' },
            {
                path: 'matching',
                data: {
                    title: 'PAGE_TITLE_NEVER_LUNCH_ALONE_MATCHES',
                },
                component: NLAMatchesComponent,
            },
            {
                path: 'bookmarks',
                data: {
                    title: 'PAGE_TITLE_NEVER_LUNCH_ALONE_BOOKMARKS',
                },
                component: NLABookmarksComponent,
            },
            {
                path: 'search',
                data: {
                    title: 'PAGE_TITLE_NEVER_LUNCH_ALONE_SEARCH',
                },
                component: NLASearchResultsComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NLADomainRoutingModule {}
