import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Pipe({
    name: 'localizedHumanizedDate',
    pure: false,
})
export class LocalizedHumanizedDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(value: string | Date): string {
        const date = moment(value);
        if (moment.isMoment(date)) {
            moment.locale(this.translateService.currentLang);
            return Math.floor(moment.duration(date.diff(moment())).asDays()).toString();
        }
        return '';
    }
}
