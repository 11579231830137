import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SsoDataSerializer } from './sso-data.serializer';
import { AccountSsoDataDTO } from './sso-data.types';
import { Account } from '../account.types';

const PATH = '/api/v2/accounts/new';

@Injectable({
    providedIn: 'root',
})
export class SsoDataService {
    constructor(private http: HttpClient, private ssoDataSerializer: SsoDataSerializer) {}

    getAccountSSOData(): Observable<Partial<Account>> {
        return this.http
            .get<AccountSsoDataDTO>(`${PATH}`, { observe: 'body' })
            .pipe(map((dto) => this.ssoDataSerializer.deserialize(dto)));
    }
}
