import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigateByUrlDirective } from './navigate-by-url.directive';

@NgModule({
    declarations: [NavigateByUrlDirective],
    imports: [CommonModule],
    exports: [NavigateByUrlDirective],
})
export class NavigateByUrlModule {}
