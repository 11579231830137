import { Injectable } from '@angular/core';
import { OffsetLimit, PageParams } from '../paginator';

@Injectable()
export class PaginatorService {
    public offsetLimit(pageParams: PageParams): OffsetLimit {
        const offsetLimitNumeric = this.offsetLimitNumeric(pageParams);
        return {
            offset: String(offsetLimitNumeric.offset),
            limit: String(offsetLimitNumeric.limit),
        };
    }
    public fillAllPagesArray(pageResults: Array<any>, pageParams: PageParams): Array<any> {
        const resultsArray = Array<any>(pageParams.length).fill(null);
        const spliceParams = this.offsetLimitNumeric(pageParams);
        resultsArray.splice(spliceParams.offset, spliceParams.limit, ...pageResults);
        return resultsArray;
    }

    private offsetLimitNumeric(pageParams: PageParams): { offset: number; limit: number } {
        return {
            offset: pageParams.pageIndex * pageParams.pageSize,
            limit: pageParams.pageSize,
        };
    }
}
