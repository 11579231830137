import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Translatable } from '../../language';

@Component({
    selector: 'tp-details-organizer',
    templateUrl: './details-organizer.component.html',
    host: {
        class: 'details__organizer',
    },
})
export class DetailsOrganizerComponent {
    @Input() imageSrc: string;
    @Input() imageAlt: string | Translatable;
    @Input() label: string | Translatable;
    @Input() name: string | Translatable;
    @Input() originizers: any[];

    @Output() click = new EventEmitter<void>();

    onAvatarClick(event) {
        if (this.click) {
            this.click.emit();
        }
    }
}
