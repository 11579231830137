import { OthersAccount } from '@tploy-enterprise/tenant-core';
import { Skill, SkillDTO, Translatable } from '@tploy-enterprise/tenant-common';

export const WORKSHOP_NOTIFICATION_SHOWN_STORAGE_KEY = 'tploy.workshop.profile.notificationShown';

export interface WorkshopDTO {
    id?: string;
    title: string;
    categories: string[];
    languages: string[];
    description: string;
    company: string;
    department: string;
    startDate: string;
    endDate: string;
    registrationCloseAt: string;
    maxRegistrations: number;
    requestedSkills: SkillDTO[];
    selectedGroups: string[];
    topics: Skill[];
    location: Tag;
    imageUrl: string;
    organizersDecorated?: Array<WorkshopOrganizer>;
    organizers?: Array<string>;
    customConditions?: string;
    lastUpdatedAt?: string;
    published?: boolean;
    registrations: Registration[];
    notifications: Notification[];
    isNotificationRequested: boolean;
    isRegistrationFull: boolean;
    numberOfRegistrations: number;
    canUserRegister?: boolean;
    draft: boolean;
    highlighted?: WorkshopEntityHighlight;

    // This is resolved dynamically
    // Another option would be to replace the organizer property with its data
    organizerData?: OthersAccount; // this is set in the backend and cannot be edited
}

export interface Workshop {
    id?: string;
    title: string;
    categories: string[];
    languages: string[];
    description: string;
    company: string;
    department: string;
    startDate: Date;
    endDate: Date;
    registrationCloseAt: Date;
    maxRegistrations: number;
    requestedSkills: Skill[];
    selectedGroups: string[];
    topics: string[];
    location: Tag;
    imageUrl: string;
    organizers?: Array<WorkshopOrganizer>;
    published?: boolean;
    customConditions?: string;
    lastUpdatedAt?: Date;
    registrations?: Registration[];
    notifications?: Notification[];
    status: WorkshopStatus;
    isNotificationRequested: boolean;
    isRegistrationFull: boolean;
    numberOfRegistrations: number;
    canUserRegister?: boolean;
    isUserRegistered?: boolean;
    draft: boolean;
    highlighted: WorkshopEntityHighlight;

    // This is resolved dynamically
    // Another option would be to replace the organizer property with its data
    organizerData?: OthersAccount; // this is set in the backend and cannot be edited
}

export interface Tag {
    name: string;
}

export enum WorkshopStatus {
    PLANNED,
    RUNNING,
    COMPLETE,
}

export enum RegistrationStatus {
    PENDING = 'pending',
    CREATED = 'created',
}

export interface Registration {
    id: string;
    userId: string;
    name: string;
    status: RegistrationStatus;
    registeredAt: string;
    expireAt?: string;
}

export interface Notification {
    userId: string;
    name: string;
    createdAt: string;
}

export type TargetGroup = {
    name: string;
    options: Array<string | Translatable>;
};

export interface RegistrationWithUserSkills extends Registration, Notification {
    imageUrl: string;
    skillsInterestedIn: Array<string>;
    highlightedSkillsInterestedIn: Array<string>;
    skills: Array<string>;
    highlightedSkills: Array<string>;
}

export interface RegistrationWithUserSkillsDTO extends Registration, Notification {
    imageUrl: string;
    skillsInterestedIn: Array<Skill>;
    highlightedSkillsInterestedIn: Array<Skill>;
    skills: Array<Skill>;
    highlightedSkills: Array<Skill>;
}

export type ParticipantsWithSkillsResponseDTO = {
    registrations: Array<RegistrationWithUserSkillsDTO>;
    notifications: Array<RegistrationWithUserSkillsDTO>;
};

export type ParticipantsWithSkillsResponse = {
    registrations: Array<RegistrationWithUserSkills>;
    notifications: Array<RegistrationWithUserSkills>;
};

export type WorkshopEntityHighlightRelation = {
    name: string;
    exact: boolean;
    related: Array<string>;
};

export type WorkshopEntityHighlight = {
    mustHaveSkills: string[];
    niceToHaveSkills: string[];
    categories: string[];
    topics: string[];
    mustHaveSkillsWithRelated?: WorkshopEntityHighlightRelation[];
    topicsWithRelated?: WorkshopEntityHighlightRelation[];
};

export type WorkshopOrganizer = { id: string; name: string; imageUrl: string };
