<mat-card
    focused
    #container="focusContainer"
    class="account-tile-layout account-tile-gen-x"
    (click)="openAccountPage($event)"
    (keydown.enter)="openAccountPage()"
    (keydown.meta.enter)="openAccountPage($event)"
    role="group"
    tabindex="0"
    [attr.aria-label]="'MATCHING_RESULT_ARIA_LABEL' | translatable : { userName: user.name }"
>
    <div class="mat-card-top">
        <tp-generation-exchange-profile-title
            [locations]="locations"
            [highlightLocations]="highlightedLocations"
            [isLocationIndependent]="locationIndependent"
            [highlightLocationIndependent]="highlightLocationIndependent"
        ></tp-generation-exchange-profile-title>
    </div>
    <div class="account-tile__image-container">
        <div class="mat-card-image" [ngStyle]="{ 'background-image': 'url(' + user.imageUrl + ')' }"></div>
        <tp-account-list-item-get-to-know
            [userId]="this.user.id"
            [context]="context"
        ></tp-account-list-item-get-to-know>
    </div>
    <mat-card-content>
        <p class="profile-name">{{ user.name }}</p>
        <p class="profile-tags">
            <tp-tagger *ngIf="tags" [simpleView]="false" [tags]="tags" [selectedTags]="selectedTags"></tp-tagger>
        </p>
        <p class="profile-campaigns-tags" *ngIf="hasCampaigns">
            <tp-campaigns-tagger
                *ngIf="profile.campaigns"
                [simpleView]="false"
                [tags]="profile.campaigns"
                [selectedTags]="selectedCampaigns ?? []"
            ></tp-campaigns-tagger>
        </p>
        <p class="profile-message">
            {{ user.message }}
        </p>
    </mat-card-content>
    <mat-card-actions>
        <tp-icon-button
            [icon]="(bookmark$ | async) ? 'star_filled' : 'star_outline'"
            disableRipple="true"
            class="no-border mat-primary big"
            [title]="getTooltipMessageKey() | translatable"
            (click)="onToggleStar($event)"
            (keydown.enter)="onToggleStar($event)"
            [attr.aria-label]="getTooltipMessageKey() | translatable"
        ></tp-icon-button>
    </mat-card-actions>
</mat-card>
