import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfigService } from '../config';

@Component({
    selector: 'tp-critical-error-snack',
    templateUrl: './critical-error-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriticalErrorSnackComponent implements OnInit {
    url: string;
    label: string;

    @Output()
    close = new EventEmitter<void>();

    constructor(private readonly configService: ConfigService) {}

    ngOnInit() {
        this.url = this.getSupportUrl();
        this.label = this.getSupportLabel();
    }

    private getSupportUrl(): string {
        if (this.configService.remoteSettings) {
            return this.configService.remoteSettings.mailingSupportLink;
        } else {
            return 'mailto:support@tandemploy.com';
        }
    }

    private getSupportLabel(): string {
        return this.url.replace('mailto:', '');
    }
}
