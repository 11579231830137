import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SuccessFactorsUtilsService {
    getSuccessFactorsSkills(skills) {
        const result = [];
        (skills || [])
            .filter((skill) => !!skill)
            .forEach((skill) => {
                if (!result.some((sk) => sk.name === skill.name)) {
                    const levels = skills.filter((sk) => sk.name === skill.name).map((sk) => sk.level || 1);
                    result.push({
                        ...skill,
                        level: Math.max(...levels, 1),
                    });
                }
            });
        return result;
    }
}
