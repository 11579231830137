import { Component } from '@angular/core';

@Component({
    selector: 'tp-details-title',
    templateUrl: './details-title.component.html',
    host: {
        class: 'details__title',
    },
})
export class DetailsTitleComponent {}
