<ng-template #imageHeader>
    <ng-content select="tp-details-image-header"></ng-content>
</ng-template>
<ng-template #title>
    <ng-content select="tp-details-title"></ng-content>
</ng-template>
<ng-template #subContent1>
    <ng-content select=".details__sub-content-1"></ng-content>
</ng-template>
<ng-template #subContent2>
    <ng-content select=".details__sub-content-2"></ng-content>
</ng-template>
<ng-template #content1>
    <ng-content select=".details__content-1"></ng-content>
</ng-template>
<ng-template #content2>
    <ng-content select=".details__content-2"></ng-content>
</ng-template>
<ng-template #content3>
    <ng-content select=".details__content-3"></ng-content>
</ng-template>
<ng-template #content4>
    <ng-content select=".details__content-4"></ng-content>
</ng-template>
<ng-template #content5>
    <ng-content select=".details__content-5"></ng-content>
</ng-template>
<ng-template #content6>
    <ng-content select=".details__content-6"></ng-content>
</ng-template>
<ng-template #content7>
    <ng-content select=".details__content-7"></ng-content>
</ng-template>
<ng-template #content8>
    <ng-content select=".details__content-8"></ng-content>
</ng-template>
<ng-template #content9>
    <ng-content select=".details__content-9"></ng-content>
</ng-template>

<ng-container *ngIf="isCombinedLayout">
    <ng-container *ngIf="desktop">
        <ng-container *ngTemplateOutlet="imageHeader"></ng-container>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <div class="details__column">
            <ng-container *ngTemplateOutlet="subContent1"></ng-container>
            <ng-container *ngTemplateOutlet="subContent2"></ng-container>
        </div>
        <div class="details__column">
            <ng-container *ngTemplateOutlet="content1"></ng-container>
            <ng-container *ngTemplateOutlet="content2"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!desktop">
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="subContent1"></ng-container>
        <ng-container *ngTemplateOutlet="subContent2"></ng-container>
        <ng-container *ngTemplateOutlet="content1"></ng-container>
        <ng-container *ngTemplateOutlet="content2"></ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="isCanvasLayout">
    <ng-container *ngIf="desktop">
        <ng-container *ngTemplateOutlet="imageHeader"></ng-container>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="content1"></ng-container>
        <ng-container *ngTemplateOutlet="content2"></ng-container>
        <ng-container *ngTemplateOutlet="content3"></ng-container>
        <ng-container *ngTemplateOutlet="content4"></ng-container>
        <ng-container *ngTemplateOutlet="content5"></ng-container>
        <ng-container *ngTemplateOutlet="content6"></ng-container>
        <ng-container *ngTemplateOutlet="content7"></ng-container>
        <ng-container *ngTemplateOutlet="content8"></ng-container>
        <ng-container *ngTemplateOutlet="content9"></ng-container>
    </ng-container>

    <ng-container *ngIf="!desktop">
        <ng-container *ngTemplateOutlet="imageHeader"></ng-container>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="content1"></ng-container>
        <ng-container *ngTemplateOutlet="content2"></ng-container>
        <ng-container *ngTemplateOutlet="content3"></ng-container>
        <ng-container *ngTemplateOutlet="content4"></ng-container>
        <ng-container *ngTemplateOutlet="content5"></ng-container>
        <ng-container *ngTemplateOutlet="content6"></ng-container>
        <ng-container *ngTemplateOutlet="content7"></ng-container>
        <ng-container *ngTemplateOutlet="content8"></ng-container>
        <ng-container *ngTemplateOutlet="content9"></ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="isSingleLayout">
    <ng-container *ngTemplateOutlet="content1"></ng-container>
</ng-container>
