import { Action, combineReducers, createReducer, on } from '@ngrx/store';
import { CommunicationType, ExpertProfile, ExpertSuggestedSkills } from '../expert-api/expert.types';
import { ExpertActions } from './topic-expert.actions';
import {
    availabilityInitialState,
    availabilityReducer,
    AvailabilityState,
} from '../expert-domain/availability/availability.reducer';
import {
    ExpertMatchesState,
    expertMatchingInitialState,
    expertMatchingReducer,
} from '../expert-domain/matching/expert-matching.reducer';
import {
    expertBookmarksDataReducer,
    expertBookmarksInitialState,
    ExpertBookmarksState,
} from '../expert-domain/bookmarks/expert-bookmarks.reducer';
import { ExpertAvailabilityActions } from '../expert-domain/availability/availability.actions';
import {
    expertSearchInitialState,
    expertSearchReducer,
    ExpertSearchState,
} from '../expert-domain/search/expert-search.reducer';
import { AccountActions, SuccessFactorsActions, SuccessFactorsUtilsService } from '@tploy-enterprise/tenant-core';

const successFactorsUtilsService = new SuccessFactorsUtilsService();

export const LS_KEY_EXPERT_EDIT = 'tploy.expert.edit';
export const IS_PROFILE_DIRTY = 'tploy.expert.profile.isDirty';

export interface ExpertProfileCurrentState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    data: ExpertProfile;
}

export interface ExpertProfileEditState {
    dirty: boolean;
    data: ExpertProfile;
    loadingError: Error;
    saving: boolean;
}

export interface TopicExpertState {
    edit: ExpertProfileEditState;
    current: ExpertProfileCurrentState;
    availability: AvailabilityState;
    matching: ExpertMatchesState;
    bookmarks: ExpertBookmarksState;
    searchResults: ExpertSearchState;
    suggestedSkills: SuggestedSkillsState;
}

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: ExpertSuggestedSkills;
};

const defaultExpertProfile: ExpertProfile = {
    communicationType: CommunicationType.BOTH,
    skillsInterestedIn: [],
    skillsOffering: [],
    unavailabilityFrom: null,
    unavailabilityTo: null,
};

const topicExpertInitialState: TopicExpertState = {
    edit: {
        dirty: false,
        data: defaultExpertProfile,
        loadingError: null,
        saving: false,
    },
    current: {
        loaded: false,
        loading: false,
        loadingError: null,
        saving: false,
        savingError: null,
        data: defaultExpertProfile,
    },
    availability: availabilityInitialState,
    matching: expertMatchingInitialState,
    bookmarks: expertBookmarksInitialState,
    searchResults: expertSearchInitialState,
    suggestedSkills: {
        loaded: false,
        loading: false,
        loadingError: null,
        suggestedSkills: {
            aType: [],
            lType: [],
            sType: [],
        },
    },
};

function getInitialExpertEditState(): ExpertProfileEditState {
    const savedState = window.localStorage.getItem(LS_KEY_EXPERT_EDIT);
    if (savedState) {
        return JSON.parse(savedState);
    } else return null;
}

const editReducer = createReducer(
    { ...topicExpertInitialState.edit },
    on(ExpertActions.edit, (state, { expertData }) => {
        return {
            ...state,
            data: {
                ...state.data,
                ...expertData,
            },
            dirty: true,
        };
    }),
    on(ExpertActions.loadSuccess, (state, { expertData }) => {
        return {
            ...(getInitialExpertEditState() || {
                ...state,
                data: {
                    ...expertData,
                    skillsOffering:
                        expertData.skillsOffering?.length > 0
                            ? expertData.skillsOffering
                            : state.data.skillsOffering || [],
                },
                dirty: false,
            }),
            loadingError: null,
        };
    }),
    on(ExpertActions.loadError, (state, { error }) => {
        return {
            ...(getInitialExpertEditState() || state),
            loadingError: error,
        };
    }),
    on(ExpertActions.saveSuccess, (state, { expertData }) => ({
        ...state,
        data: expertData,
        loadingError: null,
        dirty: false,
    })),
    on(ExpertActions.resetEditState, (state, { expertData }) => ({
        ...state,
        data: expertData || { ...topicExpertInitialState.edit.data },
        dirty: false,
        loadingError: null,
    })),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => {
        return {
            ...state,
            data: {
                ...state.data,
                skillsOffering:
                    state.data.skillsOffering?.length > 0
                        ? state.data.skillsOffering
                        : account.generalData.externalSkills,
            },
            dirty: account.generalData.externalSkills?.length > 0,
        };
    }),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => {
        if (!state.loadingError) {
            return state;
        } else {
            return {
                ...state,
                data: {
                    ...state.data,
                    skillsOffering: successFactorsUtilsService
                        .getSuccessFactorsSkills([
                            ...state.data.skillsOffering.map((name) => ({ name, level: 1 })),
                            ...skills,
                        ])
                        .map((skill) => skill.name),
                },
                dirty: skills?.length > 0,
            };
        }
    }),
    on(ExpertActions.syncSuccessFactorsSkills, (state, { skills }) => ({
        ...state,
        data: {
            ...state.data,
            skillsOffering: successFactorsUtilsService
                .getSuccessFactorsSkills([...state.data.skillsOffering.map((name) => ({ name, level: 1 })), ...skills])
                .map((skill) => skill.name),
        },
    })),
    on(ExpertAvailabilityActions.saveSuccess, (state, { availability }) => ({
        ...state,
        data: {
            ...state.data,
            unavailabilityFrom: availability.unavailabilityFrom,
            unavailabilityTo: availability.unavailabilityTo,
        },
    })),
);

const currentReducer = createReducer(
    topicExpertInitialState.current,
    on(ExpertActions.load, (state) => ({ ...state, loading: true })),
    on(ExpertActions.loadSuccess, (state, { expertData }) => ({
        ...state,
        loaded: true,
        loading: false,
        loadingError: null,
        data: expertData,
    })),
    on(ExpertActions.loadError, (state, { error }) => ({
        ...state,
        loaded: true,
        loading: false,
        loadingError: error,
    })),
    on(ExpertActions.save, (state) => ({ ...state, saving: true })),
    on(ExpertActions.saveSuccess, (state, { expertData }) => ({
        ...state,
        saving: false,
        savingError: null,
        data: expertData,
        loaded: true,
        loadingError: null,
    })),
    on(ExpertActions.saveError, (state, { error, expertData }) => ({
        ...state,
        saving: false,
        savingError: error,
        data: expertData,
    })),
    on(ExpertAvailabilityActions.saveSuccess, (state, { availability }) => ({
        ...state,
        data: {
            ...state.data,
            unavailabilityFrom: availability.unavailabilityFrom,
            unavailabilityTo: availability.unavailabilityTo,
        },
    })),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => {
        return {
            ...state,
            data: {
                ...state.data,
                skillsOffering:
                    state.data.skillsOffering?.length > 0
                        ? state.data.skillsOffering
                        : account.generalData.externalSkills,
            },
        };
    }),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => {
        if (!state.loadingError) {
            return state;
        } else {
            return {
                ...state,
                data: {
                    ...state.data,
                    skillsOffering:
                        state.data.skillsOffering?.length > 0
                            ? state.data.skillsOffering
                            : skills.map((skill) => skill.name),
                },
            };
        }
    }),
);

const expertSuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(ExpertActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(ExpertActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(ExpertActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

const reducer = combineReducers({
    edit: editReducer,
    current: currentReducer,
    availability: availabilityReducer,
    matching: expertMatchingReducer,
    bookmarks: expertBookmarksDataReducer,
    searchResults: expertSearchReducer,
    suggestedSkills: expertSuggestedSkillsReducer,
});

export function expertsReducer(state: TopicExpertState, action: Action) {
    return reducer(state, action);
}
