import { Injectable } from '@angular/core';
import { Skill, SkillDTO } from './skill.types';

@Injectable({
    providedIn: 'root',
})
export class SkillsSerializer {
    serialize(skills: (Skill | string)[]): SkillDTO[] {
        return skills.map((skill, position) => {
            if (this.isString(skill)) {
                // TODO: Work with backend so that this adapter can go away. A skill is a skill.
                return { name: skill, position, required: true };
            } else {
                const { name, level, required } = skill;
                return {
                    name,
                    level,
                    required: required ? true : false,
                    position,
                };
            }
        });
    }

    deserialize(skills: SkillDTO[]): Skill[] {
        const adaptedSkills = skills.map((skill) => {
            if (this.isString(skill)) {
                return { name: skill, position: 0 };
            } else {
                return skill;
            }
        });

        adaptedSkills.sort((a, b) => a.position - b.position);

        return adaptedSkills.map((skill) => {
            const { name, level, required } = skill;
            return { name, level, required };
        });
    }

    private isString(skill: Skill | string): skill is string {
        return typeof skill === 'string';
    }
}
