import { createAction, props } from '@ngrx/store';
import { WorkshopProfile, WorkshopSuggestedSkills } from '../models/workshop-profile.types';
import { Skill } from '@tploy-enterprise/tenant-common';

export const WorkshopProfileActions = {
    edit: createAction('[Workshop profile] Edit', props<{ workshopProfile: Partial<WorkshopProfile> }>()),
    editAbort: createAction('[Workshop profile] Edit abort'),
    load: createAction('[Workshop Profile] load'),
    loadSuccess: createAction('[Workshop Profile][Success] load', props<{ workshopProfile: WorkshopProfile }>()),
    loadError: createAction('[Workshop Profile][Error] load', props<{ error: Error }>()),

    save: createAction('[Workshop Profile] save'),
    saveSuccess: createAction('[Workshop Profile][Success] save', props<{ workshopProfile: WorkshopProfile }>()),
    saveError: createAction('[Workshop Profile][Error] save', props<{ error: Error }>()),

    loadSuggestedSkills: createAction('[Workshop Profile] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[Workshop Profile][Success] Load Suggested Skills',
        props<{ suggestedSkills: WorkshopSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction(
        '[Workshop Profile][Error] Load Suggested Skills',
        props<{ error: Error }>(),
    ),
    syncSuccessFactorsSkills: createAction(
        '[Workshop SuccessFactors SyncSkills][Success] sync',
        props<{ skills: Skill[] }>(),
    ),
    syncProfileSkills: createAction('[Workshop Profile] Sync profile skills'),
};
