import { AbstractControl, ValidationErrors } from '@angular/forms';

type ValidatorFunction = (control: AbstractControl) => ValidationErrors;
type ValidatorFunctionFactory = (...args: Array<any>) => ValidatorFunction;

export class SimpleTaggerValidators {
    static TaggerMinLength: ValidatorFunctionFactory = function (numberOfTags) {
        return (control: AbstractControl) => {
            const tags: Array<string> = control.value;
            if (tags.length >= numberOfTags) {
                return null;
            } else {
                return {
                    taggerMinLength: true,
                };
            }
        };
    };

    static TaggerMaxLength: ValidatorFunctionFactory = function (numberOfTags) {
        return (control: AbstractControl) => {
            const tags: Array<string> = control.value;
            if (tags.length <= numberOfTags) {
                return null;
            } else {
                return {
                    taggerMaxLength: true,
                };
            }
        };
    };
}
