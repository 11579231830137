import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { searchReducer } from './reducers/search.reducer';
import { SearchEffects } from './effects/search.effects';
import { SearchComponent } from './components/search/search.component';
import { SearchService } from './service/search.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    imports: [
        StoreModule.forFeature('search', searchReducer),
        EffectsModule.forFeature([SearchEffects]),
        ReactiveFormsModule,
        MatFormFieldModule,
        LanguageModule,
        MatInputModule,
        MatIconModule,
        MatAutocompleteModule,
        CommonModule,
        MatProgressBarModule,
    ],
    declarations: [SearchComponent, SearchResultComponent],
    providers: [SearchService],
    exports: [SearchComponent],
})
export class UserSearchModule {}
