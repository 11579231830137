import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { NlaSerializer } from './nla.serializer';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    NlaDomainBookmarksException,
    NlaDomainMatchingException,
    NlaDomainSearchException,
    NlaMyProfileNotFoundException,
    NlaOthersProfileNotFoundException,
    NlaProfileBadRequestException,
    NlaUnexpectedException,
} from './nla.exceptions';
import {
    BookmarksApi,
    BookmarksService,
    GeneralData,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { NLAApi } from '../nla-api/nla.api';
import {
    NLAAccountListItem,
    NLABookmark,
    NLAMatches,
    NLAMatch,
    NLAProfile,
    NLASearchResults,
    NLASearchResult,
    OthersNLAProfile,
    NLASuggestedSkills,
} from './nla.types';

@Injectable({
    providedIn: 'root',
})
export class NLAService {
    constructor(
        private readonly nlaApi: NLAApi,
        private readonly bookmarksApi: BookmarksApi,
        private readonly bookmarksService: BookmarksService,
        private readonly nlaSerializer: NlaSerializer,
        private readonly paginatorService: PaginatorService,
    ) {}

    loadProfile(): Observable<NLAProfile> {
        return this.nlaApi.getProfile().pipe(
            map((dto) => this.nlaSerializer.deserializeNlaProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<OthersNLAProfile> {
        return this.nlaApi.getProfileById(id).pipe(
            map((dto) => this.nlaSerializer.deserializeOthersNlaProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    saveProfile(profile: NLAProfile, general: GeneralData): Observable<NLAProfile> {
        const dto = this.nlaSerializer.serializeNlaProfile(profile, general);
        return this.nlaApi.putProfile(dto).pipe(
            map((dto) => this.nlaSerializer.deserializeNlaProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    getSuggestedSkills(id: string): Observable<NLASuggestedSkills> {
        return this.nlaApi.getSuggestedSkills(id).pipe(
            catchError(() => of({ aType: [], lType: [], sType: [] })),
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
        );
    }

    getProfileMatches(pageParams: PageParams): Observable<NLAMatches> {
        return this.nlaApi.getProfileMatches(this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            map((response) => response.results),
            map((matches) => matches.map((dto) => this.nlaSerializer.deserializeMatch(dto))),
            switchMap((matches) => {
                if (matches.length > 0) {
                    const hydratedResults = matches.map((result) =>
                        this.hydrateMatchAccountListItem(result, result.userId),
                    );
                    return forkJoin(hydratedResults).pipe(
                        map((hydratedResults) => ({
                            hydratedResults,
                            mergedResults: matches.map((i) => hydratedResults.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedResults, mergedResults }) => {
                            return {
                                matches: hydratedResults.filter((hydratedResult) => !!hydratedResult.profile),
                                allMatches: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                total: pageParams.length,
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({
                        matches: [],
                        allMatches: [],
                        total: 0,
                        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
                    });
                }
            }),
            catchError(() => {
                throw new NlaDomainMatchingException();
            }),
        );
    }

    getBookmarks(context: string): Observable<NLABookmark[]> {
        return this.bookmarksService.loadBookmarksByContext(context).pipe(
            // Safeguard: makes sure we don't fire more than 20 loading requests.
            map((bookmarks) => bookmarks.slice(0, 20)),
            map((bookmarks) => bookmarks.map((dto) => this.nlaSerializer.deserializeBookmark(dto))),
            catchError(() => {
                throw new NlaDomainBookmarksException();
            }),
            switchMap((bookmarks) => {
                if (bookmarks.length > 0) {
                    return forkJoin(bookmarks.map((dto) => this.hydrateAccountListItem(dto, dto.entityId)));
                } else {
                    return of([]);
                }
            }),
            map((bookmarks) => bookmarks.filter((bookmark) => !!bookmark.profile)),
        );
    }

    search(searchQuery: string, pageParams: PageParams): Observable<NLASearchResults> {
        return this.nlaApi.search(searchQuery, this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (pageParams.length = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.nlaSerializer.deserializeSearchResult(dto))),
            switchMap((results) => {
                if (results.length > 0) {
                    const hydratedResults = results.map((result) =>
                        this.hydrateSearchAccountListItem(result, result.userId),
                    );
                    return forkJoin(hydratedResults).pipe(
                        map((hydratedResults) => ({
                            hydratedResults,
                            mergedResults: results.map((i) => hydratedResults.find((j) => j.userId === i.userId) || i),
                        })),
                        map(({ hydratedResults, mergedResults }) => {
                            return {
                                results: hydratedResults.filter((hydratedResult) => !!hydratedResult.profile),
                                allResults: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                pageParams,
                            };
                        }),
                    );
                } else {
                    return of({ results: [], allResults: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } });
                }
            }),
            catchError(() => {
                throw new NlaDomainSearchException();
            }),
        );
    }

    loadSearchResult(searchQuery: string, index: number): Observable<NLASearchResult> {
        return this.nlaApi.search(searchQuery, { offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) => (results.length > 0 ? this.nlaSerializer.deserializeSearchResult(results[0]) : null)),
            catchError(() => {
                throw new NlaDomainSearchException();
            }),
        );
    }

    loadMatchResult(index: number): Observable<NLAMatch> {
        return this.nlaApi.getProfileMatches({ offset: String(index), limit: String(1) }).pipe(
            map((response) => response.results),
            map((results) => (results.length > 0 ? this.nlaSerializer.deserializeMatch(results[0]) : null)),
            catchError(() => {
                throw new NlaDomainSearchException();
            }),
        );
    }

    hydrateMatchAccountListItem(result: NLAMatch, accountId: string): Observable<NLAMatch> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));
        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateSearchAccountListItem(result: NLASearchResult, accountId: string): Observable<NLASearchResult> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));
        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateAccountListItem(result: NLAAccountListItem, accountId: string): Observable<NLAAccountListItem> {
        const profile$ = this.loadProfileById(accountId).pipe(catchError(() => of(null)));
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));

        return forkJoin({ profile: profile$, bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...result, ...hydrationData })),
        );
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new NlaProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new NlaMyProfileNotFoundException();
                    } else {
                        throw new NlaOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new NlaUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
