import { createAction, props } from '@ngrx/store';
import { DirectMessage, MessageMetadata } from '../models/direct-messages.types';

export const DirectMessagesSendMessageActions = {
    // Send Message Actions
    sendMessage: createAction(
        '[DM-Conversations] Send Message',
        props<{ payload: { message: string; conversationId: string; metadata?: MessageMetadata } }>(),
    ),
    sendMessageSuccess: createAction('[DM-Conversations][Success] Send Message', props<{ message: DirectMessage }>()),
    sendMessageError: createAction('[DM-Conversations][Error] Send Message', props<{ error: Error }>()),
};
