<tp-intro-view>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/de/saas_animation.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/de/saas_animation_preview.jpg"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/de/Moritz_Mihm_BPM_Gelbe_Couch_Cover.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/de/Moritz_Mihm_BPM_Gelbe_Couch_Cover.png"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/de/gelbe_couch_lufthansa.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/de/gelbe_couch_lufthansa_preview.png"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/de/easter_bunny.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/de/easter_bunny_preview.png"
    ></tp-preview-item>
</tp-intro-view>
