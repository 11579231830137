import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ExpertAvailabilityActions } from './availability.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ExpertAvailabilityData } from '../../expert-api/expert.types';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ExpertService } from '../../expert-api/expert.service';
import { Store } from '@ngrx/store';
import { TopicExpertState } from '../../expert-store/topic-expert.reducer';
import { NotificationsService } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class ExpertAvailabilityEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly expertService: ExpertService,
        private readonly store: Store<{ expert: TopicExpertState }>,
        private readonly notificationsService: NotificationsService,
    ) {}

    saveAvailability$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertAvailabilityActions.save),
            withLatestFrom(this.store.select((state) => state.expert.availability.used)),
            map(([action, availability]) => availability),
            switchMap((availability: ExpertAvailabilityData) => {
                return this.expertService.putUnavailability(availability).pipe(
                    map(() => {
                        this.notificationsService.openDefaultSnack('EXPERT_AVAILABILITY_SAVED');
                        return ExpertAvailabilityActions.saveSuccess({ availability });
                    }),
                    catchError((error) => of(ExpertAvailabilityActions.saveError({ error, availability }))),
                );
            }),
        ),
    );
}
