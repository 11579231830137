import { CanActivate, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RedirectService } from '../../redirect.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly redirectService: RedirectService,
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        if (this.authenticationService.checkAuthentication()) {
            return of(true);
        }

        return this.redirectService.getRedirect('unauthenticated', true, {});
    }
}
