import { Component } from '@angular/core';

@Component({
    selector: 'tp-card-title',
    templateUrl: './card-title.component.html',
    host: {
        class: 'card__title mat-h2',
    },
})
export class CardTitleComponent {}
