import { createReducer, on } from '@ngrx/store';
import { WorkshopSearchActions } from './workshop-search.actions';
import { WorkshopSearchResult } from '../../workshop/models/workshop-search.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export interface WorkshopSearchState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: WorkshopSearchResult[];
    searchQuery: string;
    pageParams: PageParams;
}

export const workshopSearchReducer = createReducer<WorkshopSearchState>(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        results: [],
        searchQuery: '',
        pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    },
    on(WorkshopSearchActions.searchEntities, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(WorkshopSearchActions.searchEntitiesSuccess, (state, { entities, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...entities],
            pageParams,
        };
    }),
    on(WorkshopSearchActions.searchEntitiesError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);
