import { Component, ChangeDetectionStrategy, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationsData, CustomSnack } from './notifications.types';

@Component({
    selector: 'tp-notification-default-snack',
    templateUrl: './default-snack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultSnackComponent implements CustomSnack {
    @Output()
    close = new EventEmitter<void>();

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationsData) {}
}
