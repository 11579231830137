// Bases

abstract class StaBadRequestException extends Error {
    constructor(message: string, apiError?: Error) {
        let extra: string;
        const additionalInformation = apiError ? apiError.message || null : null;

        if (additionalInformation) {
            extra = ` Here is what the service has returned: ${additionalInformation}.`;
        } else {
            extra = ``;
        }

        super(`${message}${extra}`);
    }
}

export class StaUnexpectedException extends Error {
    constructor(originalError?: Error) {
        super(originalError ? originalError.message : 'Something unexpected has happened.');
    }
}

// Profile related exceptions
export class StaProfileBadRequestException extends StaBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the profile. Please check the data.', apiError);
    }
}

export class StaMyProfileNotFoundException extends Error {
    constructor() {
        super(`The Short-time assignment profile for the currently logged-in user was not found.`);
    }
}

export class StaOthersProfileNotFoundException extends Error {
    constructor() {
        super(`The requested Short-time assignment profile was not found.`);
    }
}

// Entity related exceptions
export class StaEntityBadRequestException extends StaBadRequestException {
    constructor(apiError?: Error) {
        super('There was an error while saving the Short-time assignment. Please check the data.', apiError);
    }
}

export class StaEntityNotFoundException extends Error {
    constructor() {
        super('The requested Short-time assigment was not found.');
    }
}

export class StaEntityForbiddenException extends Error {
    constructor() {
        super('A user with no organization or administration permission has tried modifying a Short-time assignment.');
    }
}

// Domain related exceptions

export class StaDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for Short-time assignments.');
    }
}

export class StaDomainIndexException extends Error {
    constructor() {
        super('An unexpected error has occured while loading Short-time assignments.');
    }
}

export class StaDomainMatchingException extends Error {
    constructor() {
        super('An unexpected error has occured while querying matches.');
    }
}

// Entity applications related exceptions

export class StaEntityApplicationNotFoundException extends Error {
    constructor() {
        super(`Either the Short-time assignment or the application were not found.`);
    }
}

export class StaEntityApplicationForbiddenException extends Error {
    constructor() {
        super(
            "The user was not allowed to perform this action. Either he has tried to apply to a Short-time assignment while he doesn't have the permission to, or he has tried modifying the status of an application and is neither the organizer or an administrator",
        );
    }
}

export class StaEntityApplicationBadRequestException extends Error {
    constructor() {
        super(
            'Either the user as applied to an entity that he is alredy applied to, or he has tried to apply to a Short-time assignment that has not yet been published.',
        );
    }
}
