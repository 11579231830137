import { ErrorHandler, Injectable } from '@angular/core';
import { AccountState, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { GenerationExchangeActions } from './generation-exchange.actions';
import { GenerationExchangeState } from './generation-exchange.reducer';
import { GenerationExchangeService } from './generation-exchange-service/generation-exchange.service';
import { GenerationExchangeProfile } from './generation-exchange-service/generation-exchange.types';
import {
    isGenerationExchangeProfileInDirtyState,
    isGenerationExchangeProfileLoaded,
    isGenerationExchangeProfileLoadedOrLoading,
    selectCurrentGenerationExchangeProfile,
} from './generation-exchange.selectors';
import {
    GenerationExchangeOthersProfileNotFoundException,
    GenerationExchangeUnexpectedException,
} from './generation-exchange-service/generation-exchange.exceptions';

@Injectable({
    providedIn: 'root',
})
export class GenerationExchangeTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ generationExchange: GenerationExchangeState; account: AccountState }>,
        private readonly nlaService: GenerationExchangeService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(GenerationExchangeActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<GenerationExchangeProfile> {
        this.store
            .select(isGenerationExchangeProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(GenerationExchangeActions.loadProfile());
                }
            });

        return this.store.select(isGenerationExchangeProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentGenerationExchangeProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        return this.nlaService.loadProfileById(id).pipe(
            catchError((error) => {
                if (
                    error instanceof GenerationExchangeOthersProfileNotFoundException ||
                    error instanceof GenerationExchangeUnexpectedException
                ) {
                    this.errorHandler.handleError(error);
                }
                return of(null);
            }),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isGenerationExchangeProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }
}
