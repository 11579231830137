import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

type SignUpUrl = string;

@Injectable({
    providedIn: 'root',
})
export class SignUpService {
    constructor(private router: Router) {}

    signUp() {
        this.router.navigate(['sign-up']);
    }
}
