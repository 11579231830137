import { Injectable } from '@angular/core';
import { Tag } from './workshop.types';
import { SkillLevel, Skill } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class WorkshopTypesMappingUtil {
    stringArrayToTagArray(items: string[]): Tag[] {
        if (!items || !Array.isArray(items)) {
            return [];
        }
        return items.map((item) => {
            return {
                name: item,
            };
        });
    }

    tagToString(tag: Tag): string {
        return tag.name;
    }

    tagArrayToStringArray(tags: Tag[]): string[] {
        return tags.map((tag) => this.tagToString(tag));
    }

    skillArrayToStringArray(skills: Skill[]): string[] {
        if (!skills || !Array.isArray(skills)) {
            return [];
        }
        return skills.map((s) => s.name);
    }

    stringArrayToSkillArray(skills: string[]): Skill[] {
        if (!skills || !Array.isArray(skills)) {
            return [];
        }
        return skills.map((skill, idx) => ({
            name: skill,
            position: idx,
            required: false,
            level: SkillLevel.JUNIOR,
        }));
    }

    mapSkillsWithFallback(skills: Skill[]): Skill[] {
        if (!skills || !Array.isArray(skills)) {
            return [];
        }
        return skills.map((s, idx) => ({
            name: s.name,
            position: idx,
            required: !!s.required,
            level: s.level ? s.level : SkillLevel.JUNIOR,
        }));
    }
}
