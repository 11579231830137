<tp-route-transition-loader></tp-route-transition-loader>
<mat-sidenav-container class="main-layout_container" tpSidenavHelpers #sidenav="tpSidenavHelpers">
    <mat-sidenav
        class="main-layout_sidenav-container"
        [opened]="sidenav.config.opened"
        [disableClose]="sidenav.config.disableClose"
        [mode]="sidenav.config.mode"
        focused
        #container="focusContainer"
    >
        <tp-sidenav></tp-sidenav>
        <tp-icon-button
            [icon]="'close'"
            tpSidenavToggle="close"
            class="no-border"
            *ngIf="!sidenav.config.disableClose"
        ></tp-icon-button>
    </mat-sidenav>
    <mat-sidenav-content class="main-layout_main-container" [class.sidenav-opened]="sidenav.config.opened">
        <main class="main-layout_main">
            <ng-content></ng-content>
        </main>
        <button mat-icon-button tpSidenavToggle="open" [attr.aria-label]="'SIDEBAR_ARIA_LABEL' | translatable">
            <mat-icon svgIcon="hamburger_menu"></mat-icon>
        </button>
    </mat-sidenav-content>
</mat-sidenav-container>
