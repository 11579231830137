import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QUERY_DELIMITER, QueryParamKey, UrlQueryParams } from './url-util.types';
import { PageParams } from '../paginator';
import { DEFAULT_PAGE_SIZE } from '../paginator/paginator.types';

@Injectable({
    providedIn: 'root',
})
export class UrlUtilService {
    constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

    public updateQueryParams(queryParams: Partial<UrlQueryParams>): Promise<boolean> {
        return this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    getSearchQueryParam(): string[] {
        return this.activatedRoute.snapshot.queryParams[QueryParamKey.Q]?.split(QUERY_DELIMITER) ?? [];
    }

    getPageParams(defaultPageSize?: number): PageParams {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        return {
            pageIndex: +(queryParams[QueryParamKey.PAGE_INDEX] ?? 0),
            pageSize: +(queryParams[QueryParamKey.PAGE_SIZE] ?? defaultPageSize ?? DEFAULT_PAGE_SIZE),
        };
    }

    getContextParam(): string {
        return this.activatedRoute.snapshot.queryParams[QueryParamKey.CONTEXT];
    }

    resetPageParams(): Promise<boolean> {
        return this.updateQueryParams({
            [QueryParamKey.PAGE_INDEX]: null,
            [QueryParamKey.PAGE_SIZE]: null,
        });
    }
}
