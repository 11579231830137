import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { withLatestFrom, tap, switchMap, catchError, map } from 'rxjs/operators';
import { AccountState } from '../account.reducer';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { StorageService } from '../../storage/service/storage.service';
import { QUESTIONNAIRE_STORAGE_EDIT_KEY } from './questionnaire.types';
import { QuestionnaireService } from './questionnaire.service';
import { QuestionnaireActions } from './questionnaire.actions';
import { selectQuestionnaireEditData, selectQuestionnaireState } from './questionnaire.selectors';
import { QuestionnaireState } from './questionnaire.reducer';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ account: AccountState }>,
        private questionnaireService: QuestionnaireService,
        private storage: StorageService,
    ) {}

    storeChanges = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuestionnaireActions.edit),
                withLatestFrom(this.store.select(selectQuestionnaireState)),
                tap(([action, state]) => this.storeEditionState(state)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuestionnaireActions.save),
            withLatestFrom(this.store.select(selectQuestionnaireEditData)),
            switchMap(([action, questionnaireData]) =>
                this.questionnaireService.save(questionnaireData).pipe(
                    map((questionnaireData) => QuestionnaireActions.saveSuccess({ questionnaireData })),
                    catchError((error) => of(QuestionnaireActions.saveError({ error, questionnaireData }))),
                ),
            ),
        ),
    );

    deleteSession = createEffect(
        () =>
            this.actions$.pipe(
                ofType(QuestionnaireActions.abortEdit, QuestionnaireActions.saveSuccess),
                tap(() => this.deleteEditionState()),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    private storeEditionState(state: QuestionnaireState): void {
        this.storage.session.set(QUESTIONNAIRE_STORAGE_EDIT_KEY, state.edit);
    }

    private deleteEditionState(): void {
        this.storage.session.remove(QUESTIONNAIRE_STORAGE_EDIT_KEY);
    }
}
