import { combineReducers, createReducer, on } from '@ngrx/store';
import { TechWolfActions } from './tech-wolf.actions';
import { TechWolfSkills } from '@tploy-enterprise/tenant-common';

export interface TechWolfSkillsState {
    skills: TechWolfSkills;
    loading: boolean;
    loaded: boolean;
    loadingError: Error;
}

const initialTechWolfSkillsState: TechWolfSkillsState = {
    skills: { aType: [], lType: [], sType: [] },
    loading: false,
    loaded: false,
    loadingError: null,
};

export interface TechWolfState {
    techWolfSkills: TechWolfSkillsState;
}

const combineTechWolkSkills = (oldSkills, newSkills): Array<string> => {
    const result = oldSkills;
    newSkills.forEach((newSkill) => {
        if (!result.some((oldSkill) => oldSkill === newSkill)) {
            result.push(newSkill);
        }
    });
    return result;
};

const techWolfSkillsReducer = createReducer(
    initialTechWolfSkillsState,
    on(TechWolfActions.loadTechWolfSuggestedSkills, (state) => {
        return {
            ...state,
            loaded: false,
            loading: true,
        };
    }),
    on(TechWolfActions.loadTechWolfSuggestedSkillsSuccess, (state, { skills }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            skills: {
                aType: combineTechWolkSkills(state.skills.aType, skills.aType),
                sType: combineTechWolkSkills(state.skills.sType, skills.sType),
                lType: combineTechWolkSkills(state.skills.lType, skills.lType),
            },
        };
    }),
    on(TechWolfActions.resetTechWolfSuggestedSkillsSuccess, (state) => {
        return {
            ...state,
            loaded: false,
            loading: false,
            skills: {
                aType: [],
                sType: [],
                lType: [],
            },
        };
    }),
);

export const techWolfDataReducer = combineReducers({
    techWolfSkills: techWolfSkillsReducer,
});
