<div class="gooey">
    <span>{{ 'MATCHING' | translatable }}</span>
</div>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%">
    <defs>
        <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 15 -7" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
        </filter>
    </defs>
</svg>
