export interface PageParams {
    pageIndex: number;
    pageSize: number;
    length?: number;
}
export enum Direction {
    NEXT = 1,
    PREVIOUS = -1,
}

export interface OffsetLimit {
    offset: string;
    limit: string;
}

export interface LabelKeys {
    ofPageLabel?: string;
    itemsPerPageLabel?: string;
}

export const DEFAULT_PAGE_SIZE = 12;
