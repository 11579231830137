import { createReducer, on } from '@ngrx/store';
import { ExpertActions } from '../../expert-store/topic-expert.actions';
import { ExpertAvailabilityActions } from './availability.actions';
import { AccountActions } from '@tploy-enterprise/tenant-core';
import { ExpertAvailabilityData, ExpertProfile } from '../../expert-api/expert.types';

export interface AvailabilityState {
    used: ExpertAvailabilityData;
    saved: ExpertAvailabilityData;
}

export const availabilityInitialState: AvailabilityState = {
    used: {
        unavailabilityFrom: null,
        unavailabilityTo: null,
    },
    saved: undefined,
};

export function getExpertAvailability(expertData: ExpertProfile): ExpertAvailabilityData {
    return { unavailabilityFrom: expertData.unavailabilityFrom, unavailabilityTo: expertData.unavailabilityTo };
}

export const availabilityReducer = createReducer(
    availabilityInitialState,
    on(ExpertActions.loadSuccess, (state, { expertData }) => ({
        ...state,
        used: getExpertAvailability(expertData),
    })),

    on(ExpertAvailabilityActions.change, (state, { availability }) => ({ ...state, used: availability })),
    on(ExpertAvailabilityActions.saveSuccess, (state, { availability }) => ({ ...state, saved: availability })),

    on(AccountActions.deleteSuccess, () => ({ ...availabilityInitialState })),
);
