import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    MentoringProfileDTO,
    MentoringProfileMatchResponseDTO,
    MentoringSearchResponseDTO,
    MentoringSuggestedSkillsDTO,
} from './mentoring-dtos';
import { MENTORING_TOPIC_NAME, OffsetLimit } from '@tploy-enterprise/tenant-core';
import { Params } from '@angular/router';
import { MentoringType } from '@tandemploy/common';

const BASE_PATH = `/api/v2/${MENTORING_TOPIC_NAME}`;
const PROFILE_ENDPOINT = '/profiles';

@Injectable({
    providedIn: 'root',
})
export class MentoringApi {
    constructor(private readonly httpClient: HttpClient) {}

    getProfile(): Observable<MentoringProfileDTO> {
        return this.httpClient.get<MentoringProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`);
    }

    getProfileById(id: string) {
        return this.httpClient.get<MentoringProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}/${id}`);
    }

    putProfile(profile: MentoringProfileDTO): Observable<MentoringProfileDTO> {
        return this.httpClient.put<MentoringProfileDTO>(`${BASE_PATH}${PROFILE_ENDPOINT}`, profile, {
            observe: 'body',
        });
    }

    getMatches(
        mentoringType: MentoringType,
        offsetLimit: OffsetLimit,
        campaigns?: string[],
    ): Observable<MentoringProfileMatchResponseDTO> {
        let params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        if (mentoringType) params = { ...params, mentoringType };
        return this.httpClient.get<MentoringProfileMatchResponseDTO>(`${BASE_PATH}/matches`, { params });
    }

    search(
        searchQuery: string,
        mentoringType: MentoringType,
        offsetLimit: OffsetLimit,
        campaigns?: string[],
    ): Observable<MentoringSearchResponseDTO> {
        let params: Params = {
            ...offsetLimit,
            campaigns: campaigns?.length > 0 ? campaigns.join(',') : [],
        };
        if (mentoringType) params = { ...params, mentoringType };
        return this.httpClient.get<MentoringSearchResponseDTO>(
            `${BASE_PATH}/search?q=${encodeURIComponent(searchQuery)}`,
            { params },
        );
    }

    getSuggestedSkills(id: string) {
        return this.httpClient.get<MentoringSuggestedSkillsDTO>(`${BASE_PATH}/profiles/${id}/skills`);
    }
}
