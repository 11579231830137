<div class="text-container" layout="column" layout-align="start center">
    <h1>{{ title }}</h1>

    <section layout="column" layout-align="start stretch">
        <ul class="sitemap-list">
            <li *ngFor="let item of items">
                <a *ngIf="item.route" [routerLink]="item.route">{{ item.label }}</a>

                <span *ngIf="item.items">{{ item.label }}</span>

                <ul *ngIf="item.items">
                    <li *ngFor="let subItem of item.items">
                        <a *ngIf="subItem.route" [routerLink]="subItem.route">{{ subItem.label }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </section>
</div>
