import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
    Account,
    AccountDTO,
    ContactAccountDto,
    OthersAccount,
    PowerBiConfigurationDTO,
    HiddenSkills,
} from './account.types';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AccountNotFoundException } from './account-not-found.exception';
import { AccountSerializer } from './account.serializer';
import { ContactSearchResult } from '../search/types/search.types';
import { IEmbedConfiguration } from 'powerbi-client';
import { ConfigService } from '../config';

const SERVICE_PATH = '/api/v2/accounts';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        private readonly accountSerializer: AccountSerializer,
    ) {}

    loadCurrentUserAccount(): Observable<Account> {
        return this.http.get<AccountDTO>(`${SERVICE_PATH}/me`, { observe: 'body' }).pipe(
            map((dto) => this.accountSerializer.deserialize(dto)),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 404) {
                    throw new AccountNotFoundException();
                } else {
                    throw errorResponse;
                }
            }),
        );
    }

    save(account: Account): Observable<void> {
        try {
            const dto = this.accountSerializer.serialize(account);
            return this.http.put<void>(`${SERVICE_PATH}/me`, dto);
        } catch {
            if (account.locale) {
                return this.http.put<void>(`${SERVICE_PATH}/me/locale`, { locale: account.locale });
            }
        }
    }

    delete(): Observable<void> {
        return this.http.delete<void>(`${SERVICE_PATH}/me`);
    }

    getAccountById(id: string): Observable<OthersAccount> {
        return this.http.get<AccountDTO>(`${SERVICE_PATH}/${id}`, { observe: 'body' }).pipe(
            map((dto) => this.accountSerializer.deserializeOthersAccount(dto)),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 404) {
                    throw new AccountNotFoundException();
                } else {
                    throw errorResponse;
                }
            }),
        );
    }

    getAccountsByIds(userIds: string[]): Observable<OthersAccount[]> {
        if (userIds.length === 0) {
            return of([]);
        }

        const requests = userIds.map((userId) => this.getAccountById(userId));

        return forkJoin(requests).pipe(map((account) => account.filter((a) => a !== null)));
    }

    contactSearch(queryString: string): Observable<ContactSearchResult[]> {
        return this.http
            .get<ContactAccountDto[]>(`${SERVICE_PATH}/contact-search?search=${queryString}`, { observe: 'body' })
            .pipe(map((dtos) => dtos.map((dto) => this.accountSerializer.deserializeContactAccount(dto))));
    }

    sync(skills: string[]): Observable<void> {
        return this.http.post<void>(`${SERVICE_PATH}/sync`, skills);
    }

    acceptConditions(): Observable<void> {
        return this.http.post<void>(`${SERVICE_PATH}/conditionsAccepted`, null);
    }

    getPowerBiToken(): Observable<IEmbedConfiguration> {
        return this.http
            .get<PowerBiConfigurationDTO>(`${SERVICE_PATH}/power-bi/token`, { observe: 'body' })
            .pipe(map((dto) => this.accountSerializer.deserializePowerBiConfiguration(dto)));
    }

    hideSkills(skills: HiddenSkills): Observable<HiddenSkills> {
        return this.http
            .put<HiddenSkills>(`${SERVICE_PATH}/hidden-skills-from-suggestion`, { hiddenSkillsFromSuggestion: skills })
            .pipe(map((skills) => skills));
    }

    recoverSkills(skills: HiddenSkills): Observable<HiddenSkills> {
        return this.http
            .put<HiddenSkills>(`${SERVICE_PATH}/hidden-skills-from-suggestion`, { hiddenSkillsFromSuggestion: skills })
            .pipe(map((skills) => skills));
    }
}
