import { Provider, NgModule, Injector } from '@angular/core';
import {
    AuthenticatedGuard,
    TopicDefinition,
    TopicRegistryService,
    StaticTopicDefinition,
    AuthorizationModule,
    EmptyAccountResolver,
    EXPERTS_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import { ExpertProfileViewComponent } from './expert-embeddable/expert-profile-view/expert-profile-view.component';
import { ExpertTopicEventHandlerService } from './expert-topic-event-handler.service';
import { expertsReducer } from './expert-store/topic-expert.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TopicExpertEffects } from './expert-store/topic-expert.effects';
import { ExpertAvailabilityEffects } from './expert-domain/availability/availability.effects';
import { ExpertMatchesEffects } from './expert-domain/matching/expert-matching.effects';
import { provideRoutes } from '@angular/router';
import { ExpertBookmarkEffects } from './expert-domain/bookmarks/expert-bookmark.effects';
import { ExpertSearchEffects } from './expert-domain/search/expert-search.effects';
import { ExpertUserCardViewComponent } from './expert-embeddable/expert-user-card-view/expert-user-card-view.component';
import { ExpertProfileNavigatorViewComponent } from './expert-embeddable/expert-profile-paginator-view/expert-profile-paginator-view.component';
import { UserRole } from '@tandemploy/common';

const ExpertTopicDefinition: StaticTopicDefinition = {
    name: EXPERTS_TOPIC_NAME,
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    info: '',
    links: {
        signUp: ['sign-up', 'expert'],
        signUpSequence: [['sign-up', 'expert']],
        edition: ['/', { outlets: { overlay: ['edit', 'expert'] } }],
        add: ['/', { outlets: { overlay: ['add-topic', 'expert'] } }],
        addSequence: [['/', { outlets: { overlay: ['add-topic', 'expert'] } }]],
        domain: ['expert'],
    },
    embeddableContent: {
        profileView: {
            component: ExpertProfileViewComponent,
            permission: 'expert.default',
        },
        userCardView: {
            component: ExpertUserCardViewComponent,
        },
        profileNavigatorView: {
            component: ExpertProfileNavigatorViewComponent,
        },
        sidenavItem: {
            label: 'EXPERT',
            link: '/expert',
            svgIcon: 'expert',
            editLink: ['/', { outlets: { overlay: ['edit', 'expert'] } }],
            editPermission: 'expert.default',
            referenceContext: 'expert',
        },
        modelInformation: {
            svgIcon: 'expert',
            image: '/assets/images/topics/experts.jpg',
            label: 'EXPERT',
            content: 'EXPERT_INFO',
            imageAlt: '',
        },
    },
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['expert.default'],
        [UserRole.USER]: ['expert.default'],
        [UserRole.CONTRIBUTOR]: ['expert.default'],
        [UserRole.ADMIN]: ['expert.default'],
        [UserRole.ADMIN_MAIL]: ['expert.default'],
        [UserRole.STATISTICS]: ['expert.default'],
        [UserRole.STAFFING_MANAGER]: ['expert.default'],
    },
};

const LazyRoutesProvider: Provider = provideRoutes([
    {
        path: 'expert',
        loadChildren: () => import('./expert-domain/topic-expert.module').then((m) => m.TopicExpertModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'edit/expert',
        loadChildren: () =>
            import('./expert-profile/expert-profile-edit.module').then((m) => m.ExpertProfileEditModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'add-topic/expert',
        loadChildren: () => import('./expert-profile/expert-profile-add.module').then((m) => m.ExpertProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/expert',
        loadChildren: () => import('./expert-profile/expert-sign-up.module').then((m) => m.ExpertSignUpModule),
        resolve: {
            emptyAccount: EmptyAccountResolver,
        },
        canActivate: [AuthenticatedGuard],
    },
]);

@NgModule({
    providers: [LazyRoutesProvider],
    imports: [
        StoreModule.forFeature('expert', expertsReducer),
        EffectsModule.forFeature([
            TopicExpertEffects,
            ExpertAvailabilityEffects,
            ExpertMatchesEffects,
            ExpertBookmarkEffects,
            ExpertSearchEffects,
        ]),
        AuthorizationModule.forFeature({ permissions: ExpertTopicDefinition.rolePermissions }),
    ],
})
export class ExpertFeatureModule {
    constructor(private injector: Injector) {
        this.registerExpertTopic();
    }

    private registerExpertTopic(): void {
        const eventHandlers = this.injector.get(ExpertTopicEventHandlerService);
        const definition: TopicDefinition = {
            ...ExpertTopicDefinition,
            eventHandlers,
        };
        const topicRegistry = this.injector.get(TopicRegistryService);
        topicRegistry.registerTopic(definition);
    }
}
