import { AccountSettings, AccountSettingsDTO } from './account-settings.types';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsSerializer {
    serialize(settings: AccountSettings): AccountSettingsDTO {
        // No transformation required
        return { privacy: { ...settings.privacy }, notifications: { ...settings.notifications } };
    }

    deserialize(dto: AccountSettingsDTO) {
        return { privacy: { ...dto.privacy }, notifications: { ...dto.notifications } };
    }
}
