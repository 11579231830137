import { Injectable } from '@angular/core';
import { BlogArticle, BlogArticleDTO } from './blog.types';

@Injectable({
    providedIn: 'root',
})
export class BlogSerializer {
    deserialize(dto: BlogArticleDTO): BlogArticle {
        return {
            id: dto.id,
            author: dto.author,
            content: dto.content,
            imageUrl: dto.imageUrl,
            link: dto.link,
            publishedAt: dto.publishedAt,
            summary: dto.summary,
            title: dto.title,
            updatedAt: dto.updatedAt,
            tag: dto.tags && dto.tags.length > 0 ? dto.tags[0] : null,
        };
    }
}
