<tp-error-snack>
    <div data>
        <span *ngIf="data.mailto" [innerHTML]="data | translatable | notificationMailto : data.mailto"></span>
        <span *ngIf="!data.mailto" [innerHTML]="data | translatable"></span>
    </div>
    <div action>
        <tp-icon-button
            [icon]="'close'"
            (click)="close.emit()"
            [attr.aria-label]="'CLOSE' | translatable"
        ></tp-icon-button>
    </div>
</tp-error-snack>
