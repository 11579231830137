export class ExpertDomainMatchingException extends Error {
    constructor() {
        super('An unexpected error has occured while querying matches.');
    }
}

export class ExpertDomainSearchException extends Error {
    constructor() {
        super('An unexpected error has occured while the user was searching for Expert profiles.');
    }
}
