import { Component, Input } from '@angular/core';
import { Bookmark, GeneralData, LocationService, TopicUserCardView } from '@tploy-enterprise/tenant-core';
import { NLAProfile } from '../../nla-service/nla.types';
import { Observable } from 'rxjs';

@Component({
    selector: 'tp-nla-user-card-view',
    templateUrl: './nla-user-card-view.component.html',
})
export class NLAUserCardViewComponent implements TopicUserCardView {
    @Input('topicProfileData') set topicProfileData(val: NLAProfile) {
        this._topicProfileData = val;
        this.locations = this.locationService.getLocationsOrCountry(this.topicProfileData.companyLocations);
    }
    get topicProfileData(): NLAProfile {
        return this._topicProfileData;
    }

    @Input() bookmark: Bookmark;
    @Input() generalData: GeneralData;

    _topicProfileData: NLAProfile;

    locations: string[];

    get context(): string {
        return this.bookmark?.context;
    }

    get user(): GeneralData {
        return this.generalData;
    }

    get tags(): string[] {
        return this.topicProfileData.interestedInTopics;
    }

    get selectedTags(): string[] {
        return [];
    }

    get locationIndependent(): boolean {
        return this.topicProfileData.locationIndependent;
    }

    constructor(private readonly locationService: LocationService) {}
}
