import { createAction, props } from '@ngrx/store';
import { WorkshopSearchResult } from '../../workshop/models/workshop-search.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const WorkshopSearchActions = {
    searchEntities: createAction(
        '[Workshops] Search entities',
        props<{ searchQuery: string; pageParams: PageParams }>(),
    ),
    searchEntitiesSuccess: createAction(
        '[Workshops][Success] Search entities',
        props<{ entities: WorkshopSearchResult[]; pageParams: PageParams }>(),
    ),
    searchEntitiesError: createAction('[Workshops][Error] Search entities', props<{ error: Error }>()),
};
