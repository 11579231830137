<div class="text-container" layout="column" layout-align="start center">
    <h1>Willkommen auf Tandemploy</h1>

    <section layout="column" layout-align="start stretch">
        <h2 layout-margin layout-padding style="align-self: center">
            Dies ist Ihr Raum, um vernetzter und flexibler zu arbeiten.
        </h2>
        <p>
            Finden Sie schnell und unkompliziert Expertise und Rat in Ihrer Organisation, lernen Sie von Kolleg*innen
            und in spannenden Projekten, verpassen Sie keine für Sie relevanten Angebote und Weiterbildungsmöglichkeiten
            mehr – und teilen Sie Ihre Erfahrung mit Menschen, die Sie vorher nicht kannten. Arbeiten Sie flexibler,
            vernetzter, kollaborativer – denn hier ist vieles möglich und vor allem nach Ihren Wünschen gestaltbar!
        </p>

        <div class="info-text-img-container">
            <img src="/assets/images/info1.jpg" class="info-text-img" />
        </div>
    </section>

    <section layout="column" layout-align="start stretch">
        <h2 layout-margin layout-padding style="align-self: center">Nicht „noch ein Profil“!?</h2>

        <p>
            Keine Angst: das hier ist nicht noch ein Profil – versprochen! Die Software stellt Ihnen nur wenige,
            zielgerichtete Fragen und lässt Ihr „Smart Profile“ im Anschluss fast automatisch entstehen. So kommen Sie
            in wenigen Schritten zu Ihren „Matches“ – also den für Sie relevanten Kolleg*innen und Angeboten innerhalb
            der FIRMA – und können die Vorteile der Digitalisierung für sich nutzen.
        </p>

        <div class="info-text-img-container">
            <img src="/assets/images/info2.jpg" class="info-text-img" />
        </div>
    </section>

    <section layout="column" layout-align="start stretch">
        <h2 layout-margin layout-padding style="align-self: center" class="orange">Einfach loslegen!</h2>
        <p>
            Zeigen Sie gemeinsam mit uns, dass Arbeit auch anders gehen kann: durch mehr Miteinander, mit mehr Raum für
            Reflexion und frische Perspektiven – und mit neuen Möglichkeiten, um von und mit Kolleg*innen zu lernen und
            sich innerhalb Ihrer Organisation weiterzuentwickeln.
        </p>
    </section>
</div>
