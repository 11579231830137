<ng-template #tagView let-tag>
    <div class="card__tags-tag" [class.card__tags-tag--matched]="tag.matched">{{ tag.name }}</div>
    <div class="card__tags-middot"></div>
</ng-template>
<div *ngIf="isRequiredTagsShown" class="card__tags-row card__tags-row--bold">
    <mat-icon
        class="card__tags-icon"
        svgIcon="required_skills"
        matTooltipPosition="above"
        [matTooltip]="'STA_ENTITY_LABEL_REQUIRED_SKILLS' | translatable"
    ></mat-icon>
    <ng-container
        *ngFor="let tag of requiredTags"
        [ngTemplateOutlet]="tagView"
        [ngTemplateOutletContext]="{ $implicit: tag }"
    ></ng-container>
</div>
<div *ngIf="isHelpfulTagsShown" class="card__tags-row card__tags-row--normal">
    <mat-icon
        class="card__tags-icon"
        svgIcon="light_filled"
        matTooltipPosition="above"
        [matTooltip]="'STA_ENTITY_LABEL_HELPFUL_SKILLS' | translatable"
    ></mat-icon>
    <ng-container
        *ngFor="let tag of helpfulTags"
        [ngTemplateOutlet]="tagView"
        [ngTemplateOutletContext]="{ $implicit: tag }"
    ></ng-container>
</div>
