import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[tpDragImage]',
    exportAs: 'tpDragImage',
})
export class DragImageDirective {
    @Output() private dropFile: EventEmitter<File[]> = new EventEmitter();

    @HostBinding('style.background') private background;
    @HostBinding('style.border') private borderStyle;
    @HostBinding('style.border-color') private borderColor;
    @HostBinding('style.border-radius') private borderRadius;

    constructor() {}

    @HostListener('dragover', ['$event']) public onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event']) public onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        const validFiles: File[] = files;
        this.dropFile.emit(validFiles);
    }
}
