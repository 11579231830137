import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BookmarkActions, BookmarksState } from '../../core-modules/bookmarks';
import { BookmarksPageActions } from './bookmarks-page.actions';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { BookmarksPageService } from './bookmarks-page.service';

@Injectable({
    providedIn: 'root',
})
export class BookmarksPageEffects {
    constructor(
        private store: Store<{ bookmarks: BookmarksState }>,
        private actions$: Actions,
        private bookmarksPageService: BookmarksPageService,
    ) {}

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookmarksPageActions.load),
            switchMap((action) => this.bookmarksPageService.loadBookmarksPageEntries(action.selectedTopicDefinitions)),
            map((entriesData) => {
                this.store.dispatch(
                    BookmarkActions.loadSuccess({ bookmarks: entriesData.bookmarks.map((data) => data.bookmark) }),
                );
                return BookmarksPageActions.loadSuccess({ entriesData });
            }),
            catchError((error) => {
                return of(BookmarksPageActions.loadError({ error }));
            }),
        ),
    );
}
