import { NgModule, ModuleWithProviders, InjectionToken, Inject, Self, Optional } from '@angular/core';
import { AuthorizationConfig, RolePermissions } from './authorization.types';
import { AuthorizationService } from './authorization.service';

const PERMISSION_PROVIDER = new InjectionToken<RolePermissions[]>('PERMISSION_PROVIDER');

@NgModule({})
export class AuthorizationModule {
    static forRoot(config: AuthorizationConfig): ModuleWithProviders<AuthorizationModule> {
        return {
            ngModule: AuthorizationModule,
            providers: [
                AuthorizationService,
                { provide: PERMISSION_PROVIDER, useValue: config.permissions, multi: true },
            ],
        };
    }

    static forFeature(config: AuthorizationConfig): ModuleWithProviders<AuthorizationModule> {
        return {
            ngModule: AuthorizationModule,
            providers: [{ provide: PERMISSION_PROVIDER, useValue: config.permissions, multi: true }],
        };
    }

    constructor(
        authorizationService: AuthorizationService,
        @Self() @Optional() @Inject(PERMISSION_PROVIDER) rolePermissions: RolePermissions[],
    ) {
        if (rolePermissions) {
            rolePermissions.forEach((rolePermission) => authorizationService.registerPermissions(rolePermission));
        }
    }
}
