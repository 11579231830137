import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models/direct-messages-conversation.types';
import { Contact } from '../models';

export const DirectMessagesJoinConversationActions = {
    // Join Conversation Actions
    joinGroupConversation: createAction(
        '[DM-Conversations] Join Group Conversation',
        props<{ originId: string; informTargetUser?: boolean } & Contact>(),
    ),

    joinConversationSuccess: createAction(
        '[DM-Conversations][Success] Join Conversation',
        props<{ conversation: Conversation }>(),
    ),
    joinConversationError: createAction('[DM-Conversations][Error] Join Conversation', props<{ error: Error }>()),

    joinGroupConversationOther: createAction(
        '[DM-Conversations] Join Conversation Other',
        props<{ conversation: Conversation }>(),
    ),
    joinGroupConversationOtherSuccess: createAction(
        '[DM-Conversations][Success] Join Conversation Other',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
};
