import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectsActions } from './projects.actions';
import { ProjectsEntity, ProjectsProfile, PROFILE_STORAGE_KEY, IS_PROFILE_DIRTY } from './projects.types';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    isProjectsProfileInDirtyState,
    selectApplicationSkills,
    selectCurrentProjectsProfile,
    selectMatching,
    selectSavedProjectsProfile,
    selectSearchResultsEntities,
    selectStaffingFilter,
} from './projects.selectors';
import { ProjectsService } from './projects.service';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
    AccountActions,
    AccountState,
    Contact,
    ConversationType,
    DirectMessagesCreateConversationActions,
    NotificationsService,
    DEFAULT_PAGE_SIZE,
    SelectedTopicsActions,
    StorageService,
    PROJECTS_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { ProjectsUnexpectedException } from './projects.exceptions';
import { ProjectsState } from './projects.reducer';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProjectsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{ projects: ProjectsState; account: AccountState }>,
        private readonly projectsService: ProjectsService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
        private readonly router: Router,
        private readonly notificationService: NotificationsService,
    ) {}

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.loadProfile),
            switchMap(() =>
                this.projectsService.loadProfile().pipe(
                    map((profile: ProjectsProfile) =>
                        ProjectsActions.loadProfileSuccess({ profile, session: this.readProfileFromSession() }),
                    ),
                    catchError((error) => {
                        if (error instanceof ProjectsUnexpectedException) {
                            // Other possible error is "Not Found" but this is a valid scenario
                            // When something else happens, we want to log it.
                            this.errorHandler.handleError(error);
                        }
                        return of(ProjectsActions.loadProfileError({ error, session: this.readProfileFromSession() }));
                    }),
                ),
            ),
        ),
    );

    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentProjectsProfile)),
                tap(([{ currentProfile }, state]) => this.storeProfileInSession({ ...state, ...currentProfile })),
            ),
        { dispatch: false },
    );

    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => ProjectsActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.saveProfile),
            // ignore the projects profile's non dirty state
            // it is a responsibility of the project to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isProjectsProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentProjectsProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.projectsService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) => ProjectsActions.saveProfileSuccess({ currentProfile: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(ProjectsActions.saveProfileError({ error, currentProfile }));
                    }),
                ),
            ),
        ),
    );

    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ProjectsActions.cancelCurrentProfileEdit,
                AccountActions.createSuccess,
                SelectedTopicsActions.cancelEdit,
            ),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedProjectsProfile)),
            map(([action, profile]) => ProjectsActions.loadProfileSuccess({ profile, session: null })),
        ),
    );

    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    syncProfileSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.syncProfileSkills),
            withLatestFrom(this.store.select(selectCurrentProjectsProfile)),
            switchMap(([action, currentProfile]) => {
                return of(AccountActions.sync({ skills: currentProfile.skills.map((s) => s.name) }));
            }),
        ),
    );

    saveEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.saveEntity),
            exhaustMap((action) =>
                this.projectsService.saveEntity(action.entity, action.sendNotifications).pipe(
                    map((savedEntity) => ProjectsActions.saveEntitySuccess({ entity: savedEntity })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.saveEntityError({ error }));
                    }),
                ),
            ),
        ),
    );

    createEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.createEntity),
            switchMap((action) => {
                if (action.entity.id) {
                    return this.projectsService.saveEntity(action.entity);
                }

                return this.projectsService.createEntity(action.entity);
            }),
            map((savedEntity) => ProjectsActions.createEntitySuccess({ entity: savedEntity })),
            catchError((error) => {
                this.errorHandler.handleError(error);
                return of(ProjectsActions.createEntityError({ error }));
            }),
        ),
    );

    deleteEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.deleteEntity),
            switchMap((action) =>
                this.projectsService.deleteEntity(action.entityId).pipe(
                    map((entityId) => ProjectsActions.deleteEntitySuccess({ entityId })),
                    catchError((error: Error) => of(ProjectsActions.deleteEntityError({ error }))),
                ),
            ),
        ),
    );

    publishEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.publishEntity),
            switchMap((projectsEntity) =>
                this.projectsService.publishEntity(projectsEntity.entityId).pipe(
                    withLatestFrom(this.store.select('account')),
                    switchMap(([entity, account]) => {
                        const participants: Contact[] = [
                            {
                                userId: account.userId,
                                generalData: {
                                    name: account.generalData?.current?.data?.name || '',
                                    imageUrl: account.generalData?.current?.data?.imageUrl || '',
                                },
                            },
                        ];
                        return [
                            ProjectsActions.publishEntitySuccess({ entity }),
                            DirectMessagesCreateConversationActions.createConversation({
                                payload: {
                                    origin: 'ORIGIN_TOPIC_PROJECTS',
                                    type: ConversationType.GROUP,
                                    participants,
                                    originId: entity.id,
                                    imageUrl: entity.imageUrl,
                                    name: entity.title,
                                },
                            }),
                        ];
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.publishEntityError({ error }));
                    }),
                ),
            ),
        ),
    );

    finishProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.finishProject),
            map((action) => action.entity),
            switchMap((entity) => of(ProjectsActions.finishProjectSuccess({ entity }))),
        ),
    );

    finishOngoingProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.finishOngoingProject),
            map((action) => action.entity),
            switchMap((entity) => of(ProjectsActions.finishOngoingProjectSuccess({ entity }))),
        ),
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.projectsService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return ProjectsActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    searchEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.searchEntities),
            switchMap((action) =>
                this.projectsService.searchEntities(action.searchQuery, action.pageParams, action.campaigns).pipe(
                    map((result) =>
                        ProjectsActions.searchEntitiesSuccess({
                            entities: result.results,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.searchEntitiesError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadStaffingEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.loadStaffingEntities),
            withLatestFrom(this.store.select(selectStaffingFilter)),
            switchMap(([action, filter]) =>
                this.projectsService
                    .loadStaffingEntities(action.searchQuery, action.pageParams, action.filter ?? filter)
                    .pipe(
                        map((search) =>
                            ProjectsActions.loadStaffingEntitiesSuccess({
                                entities: search.results,
                                pageParams: search.pageParams,
                            }),
                        ),
                        catchError((error) => {
                            this.errorHandler.handleError(error);
                            return of(ProjectsActions.loadStaffingEntitiesError({ error }));
                        }),
                    ),
            ),
        ),
    );

    loadEntitiesOfferedByMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.loadEntitiesOfferedByMe),
            withLatestFrom(this.store.select((state) => state.account)),
            switchMap(([action, account]) =>
                this.projectsService.loadEntitiesOfferedByMe(account.userId).pipe(
                    map((entities: ProjectsEntity[]) => ProjectsActions.loadEntitiesOfferedByMeSuccess({ entities })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.loadEntitiesOfferedByMeError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.loadMatches),
            switchMap((action) =>
                this.projectsService.getProfileMatches(action.pageParams, action.campaigns).pipe(
                    map((result) =>
                        ProjectsActions.loadMatchesSuccess({
                            entities: result.matches,
                            pageParams: result.pageParams,
                            selectedCampaigns: action.campaigns,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );

    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.saveProfileSuccess),
            switchMap(() => {
                if (this.router.url.includes('/projects/matches')) {
                    return of(
                        ProjectsActions.loadMatches({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    onProfileSaveSelectTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.saveProfile),
            switchMap(() => {
                return of(
                    SelectedTopicsActions.selectTopic({
                        topic: PROJECTS_TOPIC_NAME,
                    }),
                );
            }),
        ),
    );

    applyTo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.applyTo),
            withLatestFrom(this.store.select(selectApplicationSkills)),
            switchMap(([action, applicationSkills]) =>
                this.projectsService.apply(action.entityId, action.name, applicationSkills).pipe(
                    withLatestFrom(this.store.select(selectMatching), this.store.select(selectSearchResultsEntities)),
                    map(([application, matches, searchResults]) => {
                        matches = [
                            ...matches.map((match) => {
                                if (match.projectsEntity.id === action.entityId) {
                                    match.projectsEntity.isApplicationForUserPendingOrApproved = true;
                                }
                                return match;
                            }),
                        ];
                        searchResults = [
                            ...searchResults.map((searchResult) => {
                                if (searchResult.projectsEntity.id === action.entityId) {
                                    searchResult.projectsEntity.isApplicationForUserPendingOrApproved = true;
                                }
                                return searchResult;
                            }),
                        ];
                        return ProjectsActions.applyToSuccess({ application, matches, searchResults });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.applyToError({ error }));
                    }),
                ),
            ),
        ),
    );

    searchProfiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.searchProfiles),
            switchMap((action) =>
                this.projectsService.searchProfiles(action.projectId, action.searchTerms).pipe(
                    map((profiles) => ProjectsActions.searchProfilesSuccess({ profiles })),
                    catchError((error: Error) => of(ProjectsActions.searchProfilesError({ error }))),
                ),
            ),
        ),
    );

    searchTopicUsersByName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.searchTopicUsers),
            switchMap((action) =>
                this.projectsService.searchTopicUserByName(action.searchQuery, action.entityId).pipe(
                    map((searchResult) => ProjectsActions.searchTopicUsersSuccess({ results: searchResult })),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(ProjectsActions.searchTopicUsersError({ error }));
                    }),
                ),
            ),
        ),
    );

    sendInvitationMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.sendInvitationMessage),
            concatMap((action) =>
                this.projectsService.sendInvitation(action.message).pipe(
                    tap(() =>
                        this.notificationService.openDefaultSnack(
                            'PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK',
                        ),
                    ),
                    map(() =>
                        ProjectsActions.sendInvitationMessageSuccess({ profileId: action.message.profile.user.id }),
                    ),
                    catchError((error: Error) => of(ProjectsActions.sendInvitationMessageError({ error }))),
                ),
            ),
        ),
    );

    markApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.entityMarkApplicationsAsSeen),
            switchMap((action) =>
                this.projectsService.markApplicationsAsSeen(action.entityId).pipe(
                    map(() =>
                        ProjectsActions.entityMarkApplicationsAsSeenSuccess({
                            entityId: action.entityId,
                        }),
                    ),
                    catchError((error: Error) => of(ProjectsActions.entityMarkApplicationsAsSeenError({ error }))),
                ),
            ),
        ),
    );

    topicLinkMarkApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.topicLinkIndicatorMarkAsSeen),
            switchMap(() =>
                this.projectsService.markTopicLinkApplicationsAsSeen().pipe(
                    map(() => ProjectsActions.topicLinkIndicatorMarkAsSeenSuccess()),
                    catchError((error: Error) => of(ProjectsActions.topicLinkIndicatorMarkAsSeenError({ error }))),
                ),
            ),
        ),
    );

    offeringTabMarkApplicationsAsSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.offeringTabIndicatorMarkAsSeen),
            switchMap(() =>
                this.projectsService.markOfferingTabApplicationsAsSeen().pipe(
                    map(() => ProjectsActions.offeringTabIndicatorMarkAsSeenSuccess()),
                    catchError((error: Error) => of(ProjectsActions.offeringTabIndicatorMarkAsSeenError({ error }))),
                ),
            ),
        ),
    );

    getApplicationsSelections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.getApplications),
            switchMap(({ entityId }) =>
                this.projectsService.getApplications(entityId).pipe(
                    map((data) => ProjectsActions.getApplicationsSuccess({ data })),
                    catchError((error: Error) => of(ProjectsActions.getApplicationsSelectionsError({ error }))),
                ),
            ),
        ),
    );

    getStaffingApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.getStaffingApplications),
            switchMap(({ entity }) =>
                this.projectsService.getApplications(entity.id).pipe(
                    map((data) => ProjectsActions.getStaffingApplicationsSuccess({ data, entity })),
                    catchError((error: Error) => of(ProjectsActions.getStaffingApplicationsError({ error }))),
                ),
            ),
        ),
    );

    resetApplicationsSelections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.resetApplicationsSelections),
            map(() => ProjectsActions.resetApplicationsSelectionsSuccess()),
        ),
    );

    getOwnerUnseenApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.getOwnerUnseenApplications),
            switchMap(() =>
                this.projectsService.getUnseenApplications().pipe(
                    map((applicationIndicators) =>
                        ProjectsActions.getOwnerUnseenApplicationsSuccess({ applicationIndicators }),
                    ),
                    catchError((error: Error) => of(ProjectsActions.getOwnerUnseenApplicationsError({ error }))),
                ),
            ),
        ),
    );

    private storeProfileInSession(profile: ProjectsProfile) {
        this.storage.local.set(PROFILE_STORAGE_KEY, profile);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): ProjectsProfile {
        return this.storage.local.get<ProjectsProfile, null>(PROFILE_STORAGE_KEY, null);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(PROFILE_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
