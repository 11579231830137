<div class="slider-tiles__wrapper" [class.slider-tiles__wrapper--hide-paginator]="hidePaginator">
    <mat-paginator
        [length]="matchesTotal"
        [pageSize]="pageSize"
        [pageIndex]="pageParams.pageIndex"
        (page)="onPageEvent($event)"
        #paginator
    >
    </mat-paginator>
    <div class="slider-tiles__container">
        <ng-content></ng-content>
        <tp-matches-slider-side-buttons
            [isLoading]="isLoading"
            [paginator]="paginator"
        ></tp-matches-slider-side-buttons>
    </div>
    <tp-matches-slider-side-bottom-buttons
        [isLoading]="isLoading"
        [paginator]="paginator"
    ></tp-matches-slider-side-bottom-buttons>
</div>
