import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutingSequenceException } from './routing-sequence.exception';

@Injectable({
    providedIn: 'root',
})
export abstract class RoutingSequenceGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isStepAllowed(route, state).pipe(
            map((stepAllowed) => {
                if (stepAllowed) {
                    return true;
                }

                throw new RoutingSequenceException();
            }),
        );
    }

    abstract isStepAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;
}
