import { Inject, Injectable } from '@angular/core';
import { ENTITY_PLACEHOLDERS, ENTITY_PLACEHOLDERS_WIDE } from './image-picker.di';

@Injectable({
    providedIn: 'root',
})
export class ImagePickerService {
    constructor(
        @Inject(ENTITY_PLACEHOLDERS_WIDE) private readonly widePlaceholders: string[],
        @Inject(ENTITY_PLACEHOLDERS) private readonly placeholders: string[],
    ) {}

    getEntityWidePlaceholder(entityImage: string): string {
        if (this.placeholders.includes(entityImage)) {
            return this.widePlaceholders[this.placeholders.indexOf(entityImage)];
        }
        return entityImage;
    }
}
