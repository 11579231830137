import { TaggerComponent } from './tagger.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ChipsModule } from '@tandemploy/ngx-components';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [TaggerComponent],
    exports: [TaggerComponent],
    imports: [
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        ChipsModule,
        MatTooltipModule,
    ],
})
export class TaggerModule {}
