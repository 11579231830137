import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models';

export const DirectMessagesUnreadMessagesActions = {
    // Unread Messages Actions
    loadUnreadMessagesCount: createAction('[DM-Conversations] Load Unread Messages Count'),
    loadUnreadMessagesCountSuccess: createAction(
        '[DM-Conversations][Success] Load Unread Messages Count',
        props<{ count: number }>(),
    ),
    loadUnreadMessagesCountError: createAction(
        '[DM-Conversations][Error] Load Unread Messages Count',
        props<{ error: Error }>(),
    ),

    // Read Message Actions
    markMessageAsRead: createAction('[DM-Conversations] Read Message', props<{ messageId: string }>()),
    markMessageAsReadSuccess: createAction(
        '[DM-Conversations][Success] Read Message',
        props<{ unreadMessages: number; conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    markMessageAsReadError: createAction('[DM-Conversations][Error] Read Message', props<{ error: Error }>()),
};
