import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RoutingSequenceEffects } from './routing-sequence.effects';
import { StoreModule } from '@ngrx/store';
import { routingSequenceReducer } from './routing-sequence.reducer';

@NgModule({
    imports: [
        EffectsModule.forFeature([RoutingSequenceEffects]),
        StoreModule.forFeature('routingSequence', routingSequenceReducer),
    ],
})
export class RoutingSequenceModule {}
