export default {
    WORKSHOP: 'Events',
    WORKSHOP_SINGULAR: 'Event',
    WORKSHOP_INFO:
        'Wer eine interne Veranstaltung bewerben und genau die passenden Teilnehmer*innen gewinnen möchte, ist mit dem Event-Matching richtig beraten. Hier kannst Du interne Infoveranstaltungen, Netzwerkevents, Events und Weiterbildungen – interne Events aller Art – erstellen und genau angeben, wie viele Teilnehmer*innen gesucht sind und ob diese bestimmte Voraussetzungen (neben dem Interesse für ein bestimmtes Thema) mitbringen sollten. Potentiell interessierte Mitglieder bekommen automatisch eine Benachrichtigung und können sich unkompliziert anmelden! Oder umgekehrt: Wer über interne Veranstaltungen auf dem Laufenden bleiben möchte, sollte unbedingt „Events“ als sein Interesse auswählen und angeben, was sie oder ihn besonders interessiert!',
    WORKSHOP_ENTITY_CREATE_BTN: 'Neues Event',
    WORKSHOP_TAB_MATCHING: 'Matches',
    WORKSHOP_TAB_MY: 'Meine Events',
    WORKSHOP_TAB_SEARCH: 'Suche',
    WORKSHOP_TAB_OFFERED_BY_ME: 'Von mir angeboten',
    CREATE_WORKSHOP_DIALOG_TITLE: 'Neues Event erstellen',
    EDIT_WORKSHOP_DIALOG_TITLE: 'Event bearbeiten',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_REQUIRED_ERROR_MSG: 'Bitte mindestens einen Skill angeben',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_ADD_SKILL_BTN_TITLE: 'Skill hinzufügen',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_LABEL: 'Nötige/geeignete Skills',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_MUST_HAVE_SKILLS_LABEL: 'Nötige Skills',
    SAVE_WORKSHOP_DIALOG_REQUIRED_SKILLS_LEVEL_LABEL: 'Geforderter Skill-Level',
    PUBLISH_WORKSHOP_DIALOG_TITLE: 'Event jetzt veröffentlichen?',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_1: 'Dein Angebot wird sichtbar und Matches werden benachrichtigt',
    PUBLISH_WORKSHOP_DIALOG_CONTENT_2:
        'Sobald ein Event veröffentlicht wird, werden automatisch alle Matches ermittelt und via Mail darüber informiert. Bitte prüfe daher, ob deine Angaben korrekt sind. Die Benachrichtigungen werden innerhalb von 24h nach Veröffentlichung verschickt.',
    PUBLISH_WORKSHOP_DIALOG_PUBLISH_BTN_TITLE: 'Event veröffentlichen',
    WORKSHOP_DELETE_DIALOG_HEADER: 'Angebot löschen',
    WORKSHOP_DELETE_DIALOG_CONTENT: 'Möchtest du das Angebot wirklich endgültig löschen?',
    WORKSHOP_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Bist du sicher, dass du dieses Angebot einschließlich der potenziellen Bewerber/innen und/oder Teilnehmer/innen unwiderruflich löschen möchtest? Wenn du es löschst, werden alle beteiligten Benutzer entsprechend benachrichtigt. Das Angebot kann anschließend nicht mehr aufgerufen werden.',

    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_1: 'Erstelle dein erstes Event',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CONTENT_2:
        'Du hast bisher noch kein Event angeboten. Hier kannst du erstmalig eines erstellen.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_CREATE_WORKSHOP_BTN: 'Neues Event erstellen',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_ASIDE_CONTENT_1: 'Kolleg*innen einladen',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_ASIDE_CONTENT_2: 'Lade Kolleg*innen ein, diese Plattform zu nutzen.',
    DEFAULT_WORKSHOP_OFFERED_BY_ME_TILE_INVITE_BTN: 'Einladen',
    WORKSHOPS_OFFERED_BY_ME_TILE_CONTINUE_EDIT: 'Bearbeiten fortsetzen',
    WORKSHOPS_OFFERED_BY_ME_TILE_VIEW_DETAILS_BTN_TITLE: 'Ansehen',
    WORKSHOPS_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Nachricht an Teilnehmer*innen',
    WORKSHOPS_OFFERED_BY_ME_TILE_LAST_EDITED_INDICATOR_LABEL: 'Zuletzt geändert',
    WORKSHOPS_OFFERED_BY_ME_TILE_CONTACT_LABEL: 'Kontakt',
    WORKSHOPS_OFFERED_BY_ME_TILE_START_DATE_INDICATOR_LABEL: 'Findet statt',
    WORKSHOPS_OFFERED_BY_ME_TILE_APPLICANTS_INDICATOR_LABEL: 'Teilnehmer*innen',
    WORKSHOPS_OFFERED_BY_ME_TILE_PLANNED: 'Geplante',
    WORKSHOPS_OFFERED_BY_ME_TILE_RUNNING: 'Laufende',
    WORKSHOPS_OFFERED_BY_ME_TILE_COMPLETED: 'Abgeschlossene',
    WORKSHOP_STATUS_UNPUBLISHED: 'Unveröffentlicht',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_TITLE: 'Events',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_SUB_TITLE:
        'Spannende Events entdecken und/oder eigene Events veröffentlichen und passende Teilnehmer*innen adressieren.',
    SIGN_UP_WORKSHOP_MOTIVATION_STEP_INFO:
        'Hier kannst du die für dich passenden Optionen auswählen. Entweder kannst du auf die Suche nach interessanten Events gehen und dir automatisch passende vorschlagen lassen. Oder du erstellst selbst Events und findest mithilfe des Matchings automatisch Teilnehmer*innen.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_LABEL: 'Bitte wähle aus, was du machen möchtest.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_INTEREST_LABEL: 'Über passende Events informiert werden.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_OFFERING_LABEL: 'Events bequem ausschreiben und Teilnehmer*innen finden.',
    SIGN_UP_WORKSHOP_MOTIVATION_SELECT_ERROR_MSG: 'Mindestens eine Option muss ausgewählt werden.',
    SIGN_UP_WORKSHOP_INTEREST_STEP_TITLE: 'Events',
    SIGN_UP_WORKSHOP_INTEREST_STEP_SUB_TITLE:
        'Hier kannst du Vorkenntnisse, Skills und Fähigkeiten angeben, die für die Teilnahme an Events relevant sein könnten. Das verbessert deine Chancen, wenn solche Kenntnisse vorausgesetzt werden.',
    SIGN_UP_WORKSHOP_INTEREST_STEP_INFO:
        'Was muss ich hier eintragen – und warum wird nach Skills gefragt? Ganz einfach: Passende Events werden vor allem auf Grundlage deiner Skills und fachlichen Orientierung gematcht. Außerdem gibt es auch Events, in denen bestimmte Skills und Skill-Levels zwingend erforderlich sind. Wenn du Chancen auf eine Teilnahme an solchen Events haben willst, empfielt es sich, möglichst viele deiner Skills und den entsprechenden Level anzugeben.',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LABEL: 'Deine Fähigkeiten und Vorkenntnisse',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LIST_SKILLS_LABEL: 'Deine Skills',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_LIST_LEVEL_LABEL: 'Dein Skill-Level',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_AND_KNOWLEDGE_ADD_SKILL_BTN_TITLE: 'Skill hinzufügen',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_REQUIRED_ERROR_MSG: 'Es muss mindestens ein Skill angegeben werden.',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_ITEM_NAME_REQUIRED_ERROR_MSG: 'Füge noch den Skill hinzu',
    SIGN_UP_WORKSHOP_INTEREST_CATEGORIES_LABEL: 'Bereiche, die dich besonders interessieren',
    SIGN_UP_WORKSHOP_INTEREST_CATEGORIES_ERROR_MSG: 'Bereiche auswählen',
    SIGN_UP_WORKSHOP_INTEREST_SKILLS_INTERESTED_IN_LABEL: 'Workshop-Themen, die dich interessieren',
    WORKSHOP_INFO_PANEL_LIST_PARTICIPANTS_LABEL: 'Teilnehmer*innen',
    WORKSHOP_MATCHING_TILE_REQUIRED_SKILLS_LABEL: 'Hilfreiche Fähigkeiten',
    WORKSHOP_MATCHING_TILE_OFFERED_TOPICS_LABEL: 'Workshop-Themen',
    WORKSHOP_MATCHING_TILE_VIEW_BTN_TITLE: 'Ansehen',
    WORKSHOP_MATCHING_TILE_CONTACT_LABEL: 'Kontakt',
    WORKSHOP_MATCHING_DETAILS_PARTICIPATE_BTN_TITLE: 'Teilnehmen',
    WORKSHOP_MATCHING_DETAILS_TO_WAITING_LIST_BTN_TITLE: 'Auf Warteliste setzen',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TITLE: 'Nur noch Plätze auf der Warteliste',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_1:
        'Vielen Dank für dein Interesse an diesem Event. Leider sind aktuell alle Plätze vergeben. Wenn du sofort über freiwerdende Plätze informiert werden willst, kannst du dich mit einem Klick auf der Warteliste eintragen.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_TEXT_2:
        'Du kannst den Bewerbungsprozess nun vorläufig abbrechen und dich selbst zur Warteliste hinzufügen. Sobald Plätze frei werden, erhalten alle Wartenden eine Benachrichtigung. Wer die Bewerbung dann zuerst abschließt, sichert sich die Teilnahme.',
    WORKSHOP_MATCHING_CONFIRM_JOIN_WAITING_LIST_CONTINUE_BTN_LABEL: 'Auf Warteliste setzen',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_HEADLINE: 'Bewerbung war nicht erfolgreich',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_HEADING: 'Leider keine freien Plätze mehr',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_COMPLETELY_FULL_TEXT:
        'Ups, leider konnte deine Bewerbung nicht abgschlossen werden. Jemand ist dir zuvor gekommen – und nun sind alle Plätze belegt.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_HEADLINE: 'Danke für dein Interesse!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_HEADING: 'Du wirst über frei werdende Plätze informiert.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_ON_WAITING_LIST_TEXT:
        'Du erhältst – zeitgleich mit allen anderen Wartenden – Benachrichtigungen per E-Mail. Dann gilt "first come, first served". Vielen Dank für deine Geduld!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_HEADLINE: 'Danke für deine Bewerbung',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_HEADING: 'Du bist dabei!',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_PARTICIPATING_TEXT: 'Deine Bewerbung war erfolgreich.',
    WORKSHOP_MATCHING_APPLICATION_FEEDBACK_DONE_BTN_LABEL: 'Fertig',

    WORKSHOP_ENTITY_REQUIRED_SKILLS_TITLE: 'Fähigkeiten',
    WORKSHOP_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        'Hier siehst du hilfreiche Vorkenntnisse für dieses Event. Wenn du noch Fähigkeiten entdeckst, die du eigentlich besitzt, aber bisher noch nicht in deinem Profil hast, kannst du diese hier auswählen und deinem Profil hinzufügen.',

    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Teilnahmebedingungen',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE:
        'Bitte bestätige, dass du die folgenden Hinweise zur Kenntnis genommen hast:',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Individuelle Regelungen Ihres Unternehmens, welche die Teilnahme an Workshops/Events allgemein regeln. Hier können auch spezifische Bedingungen stehen, denen Nutzer zustimmen müssen. Alternativ kann dieser Schritt auch für hilfreiche Informationen oder generische Hinweise genutzt werden.',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL: 'Ich habe die Hinweise gelesen und bin einverstanden.',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'Nein',
    WORKSHOP_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Ja',
    WORKSHOP_ENTITY_PARTICIPATE_BTN: 'Teilnehmen!',
    WORKSHOP_ENTITY_VIEW_REGISTERED_STATUS_NOTE: 'Du hast einen Platz für das Event',
    WORKSHOP_ENTITY_VIEW_IN_WAITING_LIST_STATUS_NOTE: 'Du bist auf der Warteliste und wirst per Mail informiert',
    WORKSHOP_ENTITY_VIEW_BOOKED_OUT_STATUS_NOTE:
        'Der Workshop ist leider ausgebucht. Nach unten scrollen, um auf die Warteliste zu kommen',
    WORKSHOP_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'Du kannst dich für {{topicEntity}} bewerben, indem du die <i>>>erste Option in deinem Profil aktivierst</i> und somit angibst, dass du auch an der Teilnahme interessiert bist',

    WORKSHOP_DETAILS_VIEW_DESCRIPTION_TITLE: 'Eventbeschreibung',
    WORKSHOP_DETAILS_VIEW_SKILLS_TITLE: 'Hilfreiche Fähigkeiten',
    WORKSHOP_DETAILS_VIEW_ORGANIZER_LABEL: 'Kontakt',
    WORKSHOP_DETAILS_VIEW_START_DATE_FORMAT: 'dd.MM.yyyy | HH:mm',

    WORKSHOP_FORM_HEADLINE_DESCRIPTION: 'Workshop-Beschreibung',
    WORKSHOP_FORM_TITLE_LABEL: 'Titel des Events',
    WORKSHOP_FORM_START_TIME_LABEL: 'Startzeit',
    WORKSHOP_FORM_END_TIME_LABEL: 'Endet',
    WORKSHOP_FORM_DATE_LABEL: 'Startet',
    WORKSHOP_FORM_APPLICATION_DEADLINE_LABEL: 'Bewerbungsschluss',
    WORKSHOP_FORM_VENUE_LABEL: 'Veranstaltungsort',
    WORKSHOP_FORM_LANGUAGES_LABEL: 'Sprache (für die Teilnahme nötig)',
    WORKSHOP_FORM_SUBSIDIARY_LABEL: 'Ausschreibende (Tochter-)Gesellschaft',
    WORKSHOP_FORM_DEPARTMENT_LABEL: 'Ausschreibendes Department',
    WORKSHOP_FORM_TARGET_GROUPS_LABEL: 'Zielgruppen eingrenzen',
    WORKSHOP_FORM_CATEGORY_LABEL: 'Betrifft folgende Interessenbereiche',
    WORKSHOP_FORM_PARTICIPANTS_LABEL: 'Maximale Teilnehmerzahl',
    WORKSHOP_FORM_TOPICS_LABEL: 'Workshop-Themen in Schlagworten (so werden die richtigen Leute benachrichtigt)',
    WORKSHOP_FORM_DESCRIPTION_LABEL: 'Beschreibung',
    WORKSHOP_FORM_DESCRIPTION_ERROR: 'Die Beschreibung muss mindestens 50 Zeichen lang sein.',
    WORKSHOP_FORM_BUTTON_DRAFT: 'Entwurf speichern',
    WORKSHOP_FORM_BUTTON_PUBLISH: 'Veröffentlichen',
    WORKSHOP_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unbegrenzt (alle werden erreicht)',
    WORKSHOP_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Wähle eine Funktion',

    WORKSHOP_FORM_REQUIRED_SKILLS_LABEL: 'Hilfreiche Vorkenntnisse für die Teilnahme',
    WORKSHOP_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Füge die gewünschte Sprache hinzu',
    WORKSHOP_FORM_HEADLINE_TAGS: 'Workshop Tags',
    WORKSHOP_FORM_HEADLINE_LANGUAGE: 'Benötigte Sprache',
    WORKSHOP_FORM_HEADLINE_TARGET_GROUPS: 'Offen für folgende Gruppen',
    WORKSHOP_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Füge eine Gruppe hinzu, wenn du ihr angehörst',
    WORKSHOP_FORM_HEADLINE_WORKSHOP_TOPICS: 'Workshop-Themen',
    WORKSHOP_FORM_REQUIRED_TO_ADD_WORKSHOP_TOPIC: 'Wähle mindestens ein Thema',
    WORKSHOP_FORM_TOPIC_TABLE_INTERESTED: 'Interesse?',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_NAME: 'Workshop-Thema',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_INFORMATION: 'Information',
    WORKSHOP_FORM_TOPIC_TABLE_TOPIC_ADD_IF_YOU_INTERESTED: 'Thema hinzufügen, wenn du interessiert bist',
    WORKSHOP_FORM_READY_STATUS: 'Yes! Du kannst teilnehmen!',
    WORKSHOP_FORM_ALMOST_READY_STATUS: 'Fast bereit zur Teilnahme!',
    WORKSHOP_FORM_NOT_READY_STATUS: 'Noch nicht bereit zur Teilnahme!',
    WORKSHOP_FORM_HEADLINE_NICE_TO_HAVE_SKILLS: 'Hilfreiche Vorkenntnisse meinem Profil hinzufügen',
    WORKSHOP_FORM_SKILL_TABLE_INTERESTED: 'Vorkenntnis vorhanden?',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_NAME: 'Hilfreiche Vorkenntnisse',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_INFORMATION: 'Information',
    WORKSHOP_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Fähigkeit hinzufügen, wenn du diese mitbringst',
    WORKSHOP_FORM_RELATED_TERM: 'Du hast einen verwandten Begriff in deinem Profil',

    MY_WORKSHOPS_BUTTON_VIEW_DETAILS: 'Details anzeigen',
    MY_WORKSHOPS_BUTTON_VIEW_APPLICATION: 'Anmeldung ansehen',
    MY_WORKSHOPS_BUTTON_CANCEL_APPLICATION: 'Anmeldung abbrechen',
    MY_WORKSHOPS_STATUS_NOTIFICATION_REQUESTED:
        'Du bist nun auf der Warteliste. \nFalls wieder Plätze frei werden oder neue hinzukommen, erhältst du eine E-Mail.',
    MY_WORKSHOPS_PLANNED_TITLE: 'Geplante',
    MY_WORKSHOPS_RUNNING_TITLE: 'Laufende',
    MY_WORKSHOPS_COMPLETED_TITLE: 'Abgeschlossene',
    MY_WORKSHOPS_VIEW_REQUEST_TITLE: 'Deine Bewerbung',
    MY_WORKSHOPS_VIEW_REQUEST_STATUS: 'Status der Anmeldung',
    MY_WORKSHOPS_REG_REQUEST_SENT: 'Bewerbung erfolgreich abgeschickt',
    MY_WORKSHOPS_PLACEHOLDER_TEXT: 'Du nimmst noch an keinem Event teil.',
    MY_WORKSHOPS_PLACEHOLDER_BTN: 'Finde passende Events',
    MY_WORKSHOPS_CANCEL_APPLICATION_TITLE: 'Anmeldung zurückziehen',
    MY_WORKSHOPS_CANCEL_APPLICATION_BODY_TEXT: 'Möchtest du deine geplante Teilnahme  stornieren? ',
    MY_WORKSHOPS_CANCEL_APPLICATION_BODY_SUB_TEXT: 'Dein Platz wird dann für Kolleg*innen verfügbar.',
    MY_WORKSHOPS_CANCEL_APPLICATION_CANCEL_BUTTON: 'Ja, Anmeldung stornieren!',

    WORKSHOP_SEARCH_HEADER: 'Eventsuche mit Schlagwörtern',
    WORKSHOP_SEARCH_BTN_TEXT: 'Suchen!',
    WORKSHOP_SEARCH_RESULT_REQUESTED_SKILLS: 'Hilfreiche Fähigkeiten',
    WORKSHOP_SEARCH_RESULT_OFFERED_TOPICS_LABEL: 'Workshop-Themen',
    WORKSHOP_SEARCH_RESULT_VIEW_ENTITY: 'Details ansehen',
    WORKSHOP_SEARCH_RESULT_AVATAR_LABEL: 'Kontakt',
    WORKSHOP_SEARCH_NO_RESULT_IMG_ALT:
        'Das Bild zeigt einen Platzhalter, der anstelle von Ergebnissen erscheint, da die Suche keine Ergebnisse ergab.',
    WORKSHOP_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Suchen ohne Suchbegriff zeigt alle aktuell verfügbaren Angebote.',

    WORKSHOP_PROFILE_VIEW_SKILLS: 'Kenntnisse, die ich einbringen kann',
    WORKSHOP_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Interessenfelder',
    WORKSHOP_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Workshop-Themen, die mich interessieren',

    WORKSHOP_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalisierung',
    WORKSHOP_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Kundenorientierung',
    WORKSHOP_PROFILE_CATEGORY_SUSTAINABILITY: 'Nachhaltigkeit',
    WORKSHOP_PROFILE_CATEGORY_MOBILITY: 'Mobilität / Internationale Themen',
    WORKSHOP_PROFILE_CATEGORY_COMMERCIAL: 'Wirtschaftliche/ Kommerzielle',
    WORKSHOP_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Produktthemen',
    WORKSHOP_PROFILE_CATEGORY_IT_TOPICS: 'IT Themen & Tools',
    WORKSHOP_PROFILE_CATEGORY_COMMUNICATION: 'Kommunikationsthemen',
    WORKSHOP_PROFILE_CATEGORY_SOCIAL: 'Soziale Themen',
    WORKSHOP_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methoden',
    WORKSHOP_PROFILE_CATEGORY_LEAN_METHODS: 'Lean Methoden',
    WORKSHOP_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Individuelle Skills teilen',
    WORKSHOP_PARTICIPANTS_DIALOG_TITLE: 'Teilnehmer*innen-Liste',
    WORKSHOP_PARTICIPANTS_DIALOG_SUB_TITLE: 'Interessierte auf der Warteliste werden über freie Plätze informiert',
    WORKSHOP_PARTICIPANTS_REQUIRED_TOPICS_LABEL: 'Workshop-Themen',
    WORKSHOP_PARTICIPANTS_NUMBER_LABEL: 'Teilnehmer*innen',
    WORKSHOP_PARTICIPANTS_REGISTERED_TITLE: 'Teilnehmer*innen-Liste',
    WORKSHOP_PARTICIPANTS_WAITING_LIST_TITLE: 'Warteliste',
    WORKSHOP_PARTICIPANTS_COLUMN_PERSON: 'Teilnehmer*innen',
    WORKSHOP_PARTICIPANTS_COLUMN_PERSON_WAITING: 'auf Warteliste',
    WORKSHOP_PARTICIPANTS_COLUMN_TOPICS: 'Workshop-Themen',
    WORKSHOP_PARTICIPANTS_COLUMN_SKILLS: 'Vorkenntnisse',
    WORKSHOP_PARTICIPANTS_COLUMN_REGISTERED_AT: 'Registriert am',
    WORKSHOP_PARTICIPANTS_COLUMN_REGISTERED_AT_DATE_FORMAT: 'yyyy/MM/dd',
    WORKSHOP_PARTICIPANTS_MESSAGE_TO_PARTICIPANTS_BTN: 'Nachricht an alle Teilnehmer',

    WORKSHOP_NO_MATCH_IMG_ALT:
        'Bild zeigt eine Lupe mit traurigem Smiley und symbolisiert, dass noch keine Matches gefunden wurden.',
    WORKSHOP_NO_MATCH_CONTENT:
        'Bitte gib mehr Fähigkeiten und (versteckte?) Talente in deinem Profil an, um Matches zu finden.',
    WORKSHOP_NO_MATCH_CTA: 'Bearbeiten',
    WORKSHOP_NO_MATCH_FIND_CONTENT: 'Weitere Workshops über die Suche finden.',

    WORKSHOP_VALIDATION_ERROR_MIN_PARTICIPANTS:
        'Diese Zahl ist niedriger als die Anzahl der bereits angemeldeten Teilnehmer*innen',

    WORKSHOP_SKILLS_LABEL: 'Hilfreiche Fähigkeiten',
    WORKSHOP_SKILLS_LEVEL_LABEL: 'Bevorzugtes Level',

    WORKSHOP_LABEL_ENTITY_VIEW_STAFFING: 'Teilnehmer*innen',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE:
        'Wir haben das Matching verbessert. Mach noch die letzten Angaben für deine Matching-Ergebnisse.',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE_BTN: 'Los geht’s',
    WORKSHOP_NOTIFICATION_PROFILE_NOT_COMPLETE_HEADER:
        'Das Matching wurde verbessert und wir benötigen noch einige zusätzliche Informationen, um dir passende Angebote vorschlagen zu können.',

    PAGE_TITLE_WORKSHOPS_MATCHES: 'Workshop - Matches',
    PAGE_TITLE_WORKSHOPS_MY: 'Meine Workshops',
    PAGE_TITLE_WORKSHOPS_OFFERED: 'Workshops- Von mir angeboten',
    PAGE_TITLE_WORKSHOPS_SEARCH: 'Workshops - Suche',

    ORIGIN_TOPIC_WORKSHOPS_PRIVATE: 'Workshops',
    ORIGIN_TOPIC_WORKSHOPS_PARTICIPANT: 'Workshops',

    WORKSHOP_ORGANIZERS_INFO:
        'Ein Angebot kann mehrere Organisator*innen haben, sodass sie parallel oder in Abwesenheit anderer Organisator*innen dieses Angebot vorantreiben können. Hier kannst du festlegen, wer außer dir dieses {{name_workshop_singular}}-Angebot bearbeiten und löschen sowie Nachrichten an Teilnehmende senden können soll. \n' +
        'Die hier aufgeführten Nutzer*Innen können ihrerseits Organisator*Innen hinzufügen und löschen.',
};
