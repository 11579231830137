import { createAction, props } from '@ngrx/store';
import { Workshop } from '../models/workshop.types';
import { GeneralData } from '@tploy-enterprise/tenant-core';

export const TopicWorkshopActions = {
    loadWorkshopsOfferedByMe: createAction('[Workshop] Load Offered By Me'),
    loadWorkshopsOfferedByMeSuccess: createAction(
        '[Workshop][Success] Load Offered By Me',
        props<{ workshops: Workshop[] }>(),
    ),
    loadWorkshopsOfferedByMeError: createAction('[Workshop][Error] Load Offered By Me', props<{ error: Error }>()),

    selectWorkshop: createAction('[Workshop] Select', props<{ workshop: Workshop }>()),

    createWorkshop: createAction('[Workshop] Create', props<{ workshop: Workshop }>()),
    createWorkshopSuccess: createAction('[Workshop][Success] Create', props<{ workshop: Workshop }>()),
    createWorkshopError: createAction('[Workshop][Error] Create', props<{ error: Error }>()),

    saveWorkshop: createAction('[Workshop] Save', props<{ workshop: Workshop; sendNotifications?: boolean }>()),
    saveWorkshopSuccess: createAction('[Workshop][Success] Save', props<{ workshop: Workshop }>()),
    saveWorkshopError: createAction('[Workshop][Error] Save', props<{ error: Error }>()),

    publishWorkshop: createAction('[Workshop] Publish', props<{ workshopId: string }>()),
    publishWorkshopSuccess: createAction('[Workshop][Success] Publish', props<{ workshop: Workshop }>()),
    publishWorkshopError: createAction('[Workshop][Error] Publish', props<{ error: Error }>()),

    deleteWorkshop: createAction('[Workshop] Delete', props<{ workshopId: string }>()),
    deleteWorkshopSuccess: createAction('[Workshop][Success] Delete', props<{ workshopId: string }>()),
    deleteWorkshopError: createAction('[Workshop][Error] Delete', props<{ error: Error }>()),

    clearSearchTopicUsers: createAction('[Workshop][Search] Clear Search Topic Users Results'),
    searchTopicUsers: createAction(
        '[Workshop][Search] Search Topic Users',
        props<{ searchQuery: string; entityId: string }>(),
    ),
    searchTopicUsersSuccess: createAction(
        '[Workshop][Search][Success] Search Topic Users',
        props<{ results: Partial<GeneralData>[] }>(),
    ),
    searchTopicUsersError: createAction('[Workshop][Search][Error] Search Topic Users', props<{ error: Error }>()),
};
