import { createAction, props } from '@ngrx/store';
import { QuestionnaireData } from './questionnaire.types';

export const QuestionnaireActions = {
    edit: createAction('[Account Questionnaire] Edit', props<{ questionnaireData: QuestionnaireData }>()),
    editCurrentQuestion: createAction(
        '[Account Questionnaire] Edit current question',
        props<{ currentQuestion: string }>(),
    ),
    abortEdit: createAction('[Account Questionnaire] Abort edition'),
    save: createAction('[Account Questionnaire] Save'),
    saveSuccess: createAction(
        '[Account Questionnaire][Success] Save',
        props<{ questionnaireData: QuestionnaireData }>(),
    ),
    saveError: createAction(
        '[Account Questionnaire][Error] Save',
        props<{ error: Error; questionnaireData: QuestionnaireData }>(),
    ),
};
