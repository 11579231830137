import { NgModule } from '@angular/core';
import { NLAProfileTitleComponent } from './nla-profile-title.component';
import { NlaProfileTileLocationsPopoverComponent } from './nla-profile-tile-locations-popover.component';
import { NlaProfileTileLocationsComponent } from './nla-profile-tile-locations.component';
import { NlaProfileTileLocationIndependentComponent } from './nla-profile-tile-location-independent.component';
import { NlaProfileTileLocationIndependentPopoverComponent } from './nla-profile-tile-location-independent-popover.component';
import { NlaProfileTileLocationsHeaderComponent } from './nla-profile-tile-locations-header.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule, PopoverModule } from '@tploy-enterprise/tenant-common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    declarations: [
        NLAProfileTitleComponent,
        NlaProfileTileLocationsPopoverComponent,
        NlaProfileTileLocationsComponent,
        NlaProfileTileLocationIndependentComponent,
        NlaProfileTileLocationIndependentPopoverComponent,
        NlaProfileTileLocationsHeaderComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatBadgeModule,
        MatListModule,
        PopoverModule,
        AccountListItemModule,
        ButtonModule,
        MatButtonModule,
        LanguageModule,
    ],
    exports: [NLAProfileTitleComponent],
})
export class NLAProfileTitleModule {}
