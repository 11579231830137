import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NLAActions } from './nla.actions';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
    AccountActions,
    AccountState,
    SelectedTopicsActions,
    StorageService,
    NEVER_LUNCH_ALONE_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { NLAState } from './nla.reducer';
import { NLAService } from './nla-service/nla.service';
import { NLABookmark, NLAMatches, NLAProfile, PROFILE_STORAGE_KEY, IS_PROFILE_DIRTY } from './nla-service/nla.types';
import { isNLAProfileInDirtyState, selectCurrentNLAProfile, selectSavedNLAProfile } from './nla.selectors';
import { NlaUnexpectedException } from './nla-service/nla.exceptions';

@Injectable({
    providedIn: 'root',
})
export class NLAEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{
            neverLunchAlone: NLAState;
            account: AccountState;
            router: RouterReducerState;
        }>,
        private readonly nlaService: NLAService,
        private readonly errorHandler: ErrorHandler,
        private readonly storage: StorageService,
    ) {}

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadProfile),
            switchMap(() =>
                this.nlaService.loadProfile().pipe(
                    map((profile: NLAProfile) =>
                        NLAActions.loadProfileSuccess({ profile, session: this.readProfileFromSession() }),
                    ),
                    catchError((error) => {
                        if (error instanceof NlaUnexpectedException) {
                            // Other possible error is "Not Found" but this is a valid scenario
                            // When something else happens, we want to log it.
                            this.errorHandler.handleError(error);
                        }
                        return of(NLAActions.loadProfileError({ error, session: this.readProfileFromSession() }));
                    }),
                ),
            ),
        ),
    );

    storeCurrentProfileInSessionStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NLAActions.editCurrentProfile),
                withLatestFrom(this.store.select(selectCurrentNLAProfile)),
                tap(([{ currentProfile }, state]) => {
                    this.storeProfileInSession({ ...state, ...currentProfile });
                }),
            ),
        { dispatch: false },
    );

    reliveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.featureInit),
            map(() => this.readProfileFromSession()),
            filter((profile) => !!profile),
            map((profile) => NLAActions.editCurrentProfile({ currentProfile: profile })),
        ),
    );

    saveCurrentProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.saveProfile),
            // ignore the sta profile's non dirty state
            // it is a responsibility of the sta to set its state to dirty according to what happens
            // in its pages
            switchMap(() => this.store.select(isNLAProfileInDirtyState).pipe(take(1))),
            filter((dirty) => dirty || this.readIsDirtyFromSession()),
            switchMap(() =>
                forkJoin(
                    this.store.select(selectCurrentNLAProfile).pipe(
                        take(1),
                        map((profile) => this.readProfileFromSession() || profile),
                    ),
                    this.store
                        .select((state) => {
                            return state.account.generalData.edit.data;
                        })
                        .pipe(take(1)),
                ),
            ),
            switchMap(([currentProfile, general]) =>
                this.nlaService.saveProfile(currentProfile, general).pipe(
                    map((savedProfile) => NLAActions.saveProfileSuccess({ currentProfile: savedProfile })),
                    catchError((error) => {
                        // Validation should prevent sending invalid data. If it does happen, it means a fix is needed
                        this.errorHandler.handleError(error);

                        return of(NLAActions.saveProfileError({ error, currentProfile }));
                    }),
                ),
            ),
        ),
    );

    rollbackProfileEdition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.cancelCurrentProfileEdit, AccountActions.createSuccess, SelectedTopicsActions.cancelEdit),
            tap(() => this.removeProfileFromSession()),
            withLatestFrom(this.store.select(selectSavedNLAProfile)),
            map(([action, profile]) => NLAActions.loadProfileSuccess({ profile, session: null })),
        ),
    );

    clearStorageOnSave$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NLAActions.saveProfileSuccess),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.nlaService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return NLAActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(NLAActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadMatches),
            switchMap((action) =>
                this.nlaService.getProfileMatches(action.pageParams).pipe(
                    map((result: NLAMatches) =>
                        NLAActions.loadMatchesSuccess({
                            results: result.matches,
                            allResults: result.allMatches,
                            length: result.total,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(NLAActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadBookmarks),
            switchMap(() =>
                this.nlaService.getBookmarks(NEVER_LUNCH_ALONE_TOPIC_NAME).pipe(
                    map((bookmarks: NLABookmark[]) => NLAActions.loadBookmarksSuccess({ bookmarks })),
                    catchError((error: Error) => {
                        this.errorHandler.handleError(error);
                        return of(NLAActions.loadBookmarksError({ error }));
                    }),
                ),
            ),
        ),
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.search),
            switchMap((action) =>
                this.nlaService.search(action.searchQuery, action.pageParams).pipe(
                    map((result) =>
                        NLAActions.searchSuccess({
                            results: result.results,
                            allResults: result.allResults,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(NLAActions.searchError({ error }));
                    }),
                ),
            ),
        ),
    );

    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.saveProfileSuccess),
            withLatestFrom(this.getCurrentUrl()),
            switchMap(([action, url]) => {
                if (url && url.includes('/never-lunch-alone/matching')) {
                    return of(
                        NLAActions.loadMatches({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: 3,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    loadSearchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadSearchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.nlaService.loadSearchResult(action.searchQuery, action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return NLAActions.loadSearchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(NLAActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    loadMatchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NLAActions.loadMatchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.nlaService.loadMatchResult(action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return NLAActions.loadMatchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(NLAActions.loadResultError({ error }));
                    }),
                );
            }),
        ),
    );

    private getCurrentUrl(): Observable<string> {
        const routerSelector = (state: { router: RouterReducerState }) => state.router;
        const { selectUrl } = getSelectors(routerSelector);
        return this.store.select(selectUrl);
    }

    private storeProfileInSession(profile: NLAProfile) {
        this.storage.local.set(PROFILE_STORAGE_KEY, profile);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): NLAProfile {
        return this.storage.local.get<NLAProfile, null>(PROFILE_STORAGE_KEY);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(PROFILE_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
