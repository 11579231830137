import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { LogData, LogEntry, LoggerEvents, LogType } from './logger.types';
import { LocalTenantConfiguration } from '../../tenant-core.types';
import { DeviceInfo } from 'ngx-device-detector';

declare const ENV: {
    TPLOY_NAMESPACE: string;
};

export class Logger {
    private static readonly DEBOUNCE_DELAY = 30_000;

    private readonly buffer: LogEntry[] = [];
    private readonly flush = new Subject<LoggerEvents>();

    constructor(private readonly config: LocalTenantConfiguration) {
        this.flush.pipe(
            debounceTime(Logger.DEBOUNCE_DELAY),
            filter((event) => event === LoggerEvents.Flush),
        );
    }

    public log(type: LogType, message: string, data: LogData | (LogData & DeviceInfo & Location)) {
        this.buffer.push({
            type,
            message,
            data,
        });
        this.flush.next(LoggerEvents.Flush);
        if (this.config.environment.environment === 'development') {
            Logger.logLocal(type, message, data);
        }
    }

    private static logLocal(type: LogType, message: string, data: LogData | (LogData & DeviceInfo & Location)): void {
        let fontColor;
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            fontColor = 'white';
        } else {
            fontColor = 'black';
        }

        console.groupCollapsed(
            `%c[${type}]: %c${message}`,
            `font-weight: bold; color: ${Logger.localLogColorMap(type)}`,
            `font-weight:normal; color: ${fontColor};`,
        );
        console.table(data);
        console.groupCollapsed('%cTrace:', `font-weight:normal; color: ${fontColor};`);
        console.trace();
        console.groupEnd();
        console.groupEnd();
    }

    private static localLogColorMap(type: LogType): string {
        switch (type) {
            case LogType.DEBUG:
                return '#CDC7C6';
            case LogType.INFO:
                return '#2F4DF0';
            case LogType.FATAL:
                return '#FF0000';
            case LogType.WARNING:
                return '#FF6C00';
            case LogType.ERROR:
                return '#FF0000';
        }
    }
}
