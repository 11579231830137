import { createAction, props } from '@ngrx/store';
import { DirectMessage } from '../models/direct-messages.types';
import { Conversation } from '../models/direct-messages-conversation.types';

export const DirectMessagesReceiveMessageActions = {
    // Receive Message Actions
    receiveMessage: createAction('[DM-Conversations] Receive Message', props<{ message: DirectMessage }>()),
    receiveMessageSuccess: createAction(
        '[DM-Conversations][Success] Receive Message',
        props<{
            message: DirectMessage;
            unreadMessages: number;
            conversations: Conversation[];
            currentConversation: Conversation;
        }>(),
    ),
    receiveMessageError: createAction('[DM-Conversations][Error] Receive Message', props<{ error: Error }>()),
};
