import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { SimpleTaggerComponent } from './simple-tagger.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TagsSuggestionModule } from './tags-suggestion/tags-suggestion.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { LanguageModule } from '../language';
import { SuggestModule } from '../suggest/suggest.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SortablejsModule } from 'nxt-sortablejs';
import { ChipsModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [
        CommonModule,
        LanguageModule,
        ReactiveFormsModule,
        FormFieldModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        TagsSuggestionModule,
        SuggestModule,
        MatProgressBarModule,
        SortablejsModule,
        ChipsModule,
    ],
    declarations: [SimpleTaggerComponent],
    exports: [SimpleTaggerComponent, TagsSuggestionModule, SuggestModule],
})
export class SimpleTaggerModule {}
