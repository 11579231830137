<ng-container [ngTemplateOutlet]="multiple ? checkbox : radio"></ng-container>

<ng-template #checkbox>
    <tp-checkbox-list-item
        #item
        *ngFor="let option of options"
        [option]="option"
        [selected]="contains(option)"
        (toggleOption)="toggleOption($event)"
        [class.disabled]="option.disabled"
    ></tp-checkbox-list-item>
</ng-template>

<ng-template #radio>
    <mat-radio-group>
        <tp-radio-list-item
            #item
            *ngFor="let option of options"
            [option]="option"
            [selected]="contains(option)"
            (toggleOption)="setOption($event)"
        ></tp-radio-list-item>
        <tp-radio-list-item
            #item
            [option]="emptyRadioOption"
            [selected]="selectionEmpty()"
            (toggleOption)="clearSelection()"
        ></tp-radio-list-item>
    </mat-radio-group>
</ng-template>
