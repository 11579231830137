import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserRoles } from '../account/account.selectors';
import { take } from 'rxjs/operators';
import { AccountState } from '../account';
import { UserRole } from '@tandemploy/common';

@Injectable({
    providedIn: 'root',
})
export class RolesResolver implements Resolve<Array<UserRole>> {
    constructor(private readonly store: Store<{ account: AccountState }>) {}

    resolve(): Observable<Array<UserRole>> {
        return this.store.select(selectUserRoles).pipe(take(1));
    }
}
