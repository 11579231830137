import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import { ICONS } from '@tandemploy/ngx-components';

@Injectable({
    providedIn: 'root',
})
export class IconsService {
    private readonly icons = ICONS;

    constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

    public registerIcons(): void {
        this.loadIcons(this.icons, '/assets/icons');
    }

    private loadIcons(iconKeys: string[], iconUrl: string): void {
        iconKeys.forEach((key) => {
            this.matIconRegistry.addSvgIcon(
                key,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`),
            );
        });
    }
}
