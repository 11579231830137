import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

@Directive({
    selector: '[tpTopicApplicantsIndicator]',
    exportAs: 'applicantsIndicatorViewContainer',
})
export class ApplicantsIndicatorDirective implements OnChanges {
    @Input() topicApplicantsIndicatorView: ComponentType<unknown>;

    private componentRef: ComponentRef<any>;

    constructor(
        private viewContainer: ViewContainerRef,
        private factoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const checkProperties = ['topicApplicantsIndicatorView'];
        const hasChanges = Object.keys(changes).some((change) => checkProperties.includes(change));

        if (hasChanges) {
            this.viewContainer.clear();
            const factory = this.factoryResolver.resolveComponentFactory(this.topicApplicantsIndicatorView);
            this.componentRef = this.viewContainer.createComponent(factory);
            this.cd.detectChanges();
        }
    }
}
