import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountState } from '../../core-modules/account';
import { TenantTemplatesService } from '../../core-modules/tenant-templates';

@Component({
    selector: 'tp-terms',
    templateUrl: './terms.component.html',
    host: {
        class: 'modal-layout',
    },
})
export class TermsComponent {
    htmlTemplate$: Observable<string>;

    constructor(
        private tenantTemplatesService: TenantTemplatesService,
        private readonly store: Store<{ account: AccountState }>,
    ) {
        this.htmlTemplate$ = this.store
            .select((state) => state.account.locale.used)
            .pipe(
                switchMap((locale): Observable<string> => {
                    return (this.htmlTemplate$ = this.tenantTemplatesService.loadHTML('terms', locale));
                }),
            );
    }
}
