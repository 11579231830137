export const EXPERIENCE_STORAGE_EDIT_KEY = 'tploy.experience.edit';

export interface PositionDescription {
    name: string;
    numberOfYears: number;
    numberOfMonths: number;
}

export interface ExperienceData {
    positions: PositionDescription[];
    education: string[];
    yearsOfExperience: number;
    skillsOrQualifications: string[];
    pushToSuccessFactor?: boolean;
}

export interface ExperienceDataDTO {
    positions: PositionDescription[];
    education: string[];
    yearsOfExperience: number;
    skillsOrQualifications: string[];
    pushToSuccessFactor?: boolean;
}
