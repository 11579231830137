<ng-container *ngIf="!(areBookmarksLoading$ | async) && (bookmarks$ | async); else loadingBookmarks">
    <ng-template #noBookmarks>
        <h3>{{ 'JOBX_BOOKMARKS_TITLE' | translatable }}</h3>
        <p>{{ 'JOBX_BOOKMARKS_SUBTITLE' | translatable }}</p>
    </ng-template>

    <ng-container *ngIf="bookmarks$ | async as bookmarks">
        <ng-container *ngIf="bookmarks?.length > 0; else noBookmarks">
            <h3>{{ 'JOBX_BOOKMARKS_PROFILES' | translatable }}</h3>
            <div class="account-tiles-container">
                <tp-job-x-bookmark
                    *ngFor="let bookmark of bookmarks$ | async"
                    [jobXBookmark]="bookmark"
                    [ownProfile]="ownProfile$ | async"
                    [context]="context"
                >
                </tp-job-x-bookmark>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loadingBookmarks>
    <tp-search-loader [showAlways]="true" class="loader"></tp-search-loader>
</ng-template>
