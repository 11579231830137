import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkshopProfileActions } from './workshop-profile.actions';
import { catchError, map, switchMap, withLatestFrom, tap, filter, take } from 'rxjs/operators';
import { of, EMPTY, Observable } from 'rxjs';
import { WorkshopProfileService } from '../services/workshop-profile.service';
import { Store } from '@ngrx/store';
import { TopicWorkshopState } from './topic-workshop.reducer';
import {
    AccountState,
    GeneralData,
    AccountActions,
    SelectedTopicsActions,
    StorageService,
    SuccessFactorsActions,
    SuccessFactorsSkillsState,
} from '@tploy-enterprise/tenant-core';
import { WorkshopProfile, WORKSHOP_PROFILE_DATA_STORAGE_KEY, IS_PROFILE_DIRTY } from '../models/workshop-profile.types';
import { selectEditingWsProfile } from './workshop.selectors';

@Injectable({
    providedIn: 'root',
})
export class WorkshopProfileEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopProfileService: WorkshopProfileService,
        private readonly store: Store<{
            topicWorkshop: TopicWorkshopState;
            account: AccountState;
            successFactors: SuccessFactorsSkillsState;
        }>,
        private readonly storage: StorageService,
    ) {}

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopProfileActions.load),
            switchMap(() => this.store.select((state) => state.topicWorkshop.workshopProfile)),
            filter((state) => !state.loaded),
            switchMap(() =>
                this.workshopProfileService.load().pipe(
                    map((workshopProfile) => WorkshopProfileActions.loadSuccess({ workshopProfile })),
                    catchError((error) => of(WorkshopProfileActions.loadError({ error }))),
                ),
            ),
        ),
    );

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopProfileActions.save),
            // ignore the workshop profile's non dirty state
            // it is a responsibility of the workshop to set its state to dirty according to what happens
            // in its pages
            withLatestFrom(this.store.select((state) => state.topicWorkshop.workshopProfile.dirty)),
            filter(([action, workshopDirty]) => workshopDirty || this.readIsDirtyFromSession()),
            switchMap(() => this.readSaveDataFromState().pipe(take(1))),
            switchMap(({ workshopProfile, general }) =>
                this.workshopProfileService.save(workshopProfile, general).pipe(
                    map((workshopProfile) => WorkshopProfileActions.saveSuccess({ workshopProfile })),
                    catchError((error) => of(WorkshopProfileActions.saveError({ error }))),
                ),
            ),
        ),
    );

    syncSuccessFactorsSkills$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SuccessFactorsActions.syncSuccessFactorsSkills),
                withLatestFrom(this.store.select((state) => state.successFactors.topicsForSync)),
                switchMap(([{ skills }, topics]) => {
                    if (topics.includes('workshops')) {
                        this.store.dispatch(WorkshopProfileActions.syncSuccessFactorsSkills({ skills }));
                    }
                    return [];
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    loadSuggestedSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopProfileActions.loadSuggestedSkills, AccountActions.loadAllSuggestedSkills),
            switchMap((action) =>
                this.workshopProfileService.getSuggestedSkills(action.id).pipe(
                    map((suggestedSkills) => {
                        this.store.dispatch(AccountActions.loadAllSuggestedSkillsSuccess({ suggestedSkills }));
                        return WorkshopProfileActions.loadSuggestedSkillsSuccess({ suggestedSkills });
                    }),
                    catchError((error) => {
                        return of(WorkshopProfileActions.loadSuggestedSkillsError({ error }));
                    }),
                ),
            ),
        ),
    );

    saveToStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WorkshopProfileActions.edit),
                withLatestFrom(this.store.select((state) => state.topicWorkshop.workshopProfile.editing)),
                tap(([action, editionState]) => this.saveEditionStateToSession(editionState)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    propagateReset = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.createSuccess, SelectedTopicsActions.cancelEdit),
            map(() => WorkshopProfileActions.editAbort()),
        ),
    );

    deleteFromStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WorkshopProfileActions.editAbort),
                tap(() => this.removeProfileFromSession()),
            ),
        { dispatch: false },
    );

    syncProfileSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopProfileActions.syncProfileSkills),
            withLatestFrom(this.store.select(selectEditingWsProfile)),
            switchMap(([action, currentProfile]) => {
                return of(AccountActions.sync({ skills: currentProfile.skills.map((s) => s.name) }));
            }),
        ),
    );

    private readSaveDataFromState(): Observable<{ workshopProfile: WorkshopProfile; general: GeneralData }> {
        return this.store.select((state) => {
            const general = state.account.generalData.edit.data;
            const workshopProfile = this.readProfileFromSession() || state.topicWorkshop.workshopProfile.editing;

            return {
                general,
                workshopProfile,
            };
        });
    }

    private saveEditionStateToSession(editionState: any) {
        this.storage.local.set(WORKSHOP_PROFILE_DATA_STORAGE_KEY, editionState);
        this.storage.local.set(IS_PROFILE_DIRTY, true);
    }

    private readProfileFromSession(): WorkshopProfile {
        return this.storage.local.get<WorkshopProfile, null>(WORKSHOP_PROFILE_DATA_STORAGE_KEY, null);
    }

    private readIsDirtyFromSession(): boolean {
        return this.storage.local.get<boolean, null>(IS_PROFILE_DIRTY, null);
    }

    private removeProfileFromSession() {
        this.storage.local.remove(WORKSHOP_PROFILE_DATA_STORAGE_KEY);
        this.storage.local.remove(IS_PROFILE_DIRTY);
    }
}
