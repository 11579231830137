import { TranslateModuleConfig } from '@ngx-translate/core';

export type LanguageOptions = 'de' | 'en';

export const TPLOY_LANGUAGE_STORAGE_KEY = 'tploy-current-locale';

export interface LanguageConfiguration {
    appName: string;
    culture: string;
    translations: any;
    defaultLocale: LanguageOptions;
    availableLocales: LanguageOptions[];
    translateModuleOptions?: TranslateModuleConfig;
}

export interface AppWindow extends Window {
    languageConfig: LanguageConfiguration;
}
