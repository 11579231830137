import { Injectable } from '@angular/core';
import { Permission, RolePermissions } from './authorization.types';
import { RolesResolver } from './roles-resolver.service';
import { StorageService } from '../storage/service';
import { Store } from '@ngrx/store';
import { AccountState } from '../account';
import { Observable } from 'rxjs';
import { UserRole } from '@tandemploy/common';

@Injectable()
export class AuthorizationService {
    private readonly permissions = {
        [UserRole.RESTRICTED_USER]: new Set<Permission>(),
        [UserRole.USER]: new Set<Permission>(),
        [UserRole.CONTRIBUTOR]: new Set<Permission>(),
        [UserRole.ADMIN]: new Set<Permission>(),
        [UserRole.ADMIN_MAIL]: new Set<Permission>(),
        [UserRole.STATISTICS]: new Set<Permission>(),
        [UserRole.STAFFING_MANAGER]: new Set<Permission>(),
    };

    constructor(
        private readonly rolesResolver: RolesResolver,
        private readonly storageService: StorageService,
        private store: Store<{ account: AccountState }>,
    ) {}

    registerPermissions(permissions: RolePermissions) {
        this.mergePermissions(permissions);
    }

    isAuthorized(): Observable<boolean> {
        return this.store.select((state) => !!state.account.userId);
    }

    getPermissionsForRole(): Permission[] {
        let roles: Array<UserRole>;
        this.rolesResolver.resolve().subscribe((userRoles) => (roles = userRoles));

        if (!roles) {
            return [];
        }

        let permissions = [];
        roles.forEach((role) => (permissions = permissions.concat(Array.from(this.permissions[role].values()))));

        return permissions;
    }

    hasPermissions(requestedPermissions: Permission[]) {
        const actualPermissions = this.getPermissionsForRole();
        return requestedPermissions.reduce(
            (hasAllPermissions, requiredPermission) =>
                hasAllPermissions && actualPermissions.indexOf(requiredPermission) !== -1,
            true,
        );
    }

    private mergePermissions(permissions: RolePermissions) {
        const roles: Array<UserRole> = Object.keys(UserRole).map((key) => UserRole[key]);
        roles.forEach((role) => {
            permissions[role].forEach((permission) => {
                if (!this.hasFeatureFlagEnabled(permission)) {
                    this.permissions[role].add(permission);
                }
            });
        });
    }

    private hasFeatureFlagEnabled(permission: Permission): boolean {
        if (!this.storageService.local.has('tploy-ff')) {
            return false;
        }

        const featureFlags = this.storageService.local.get<string>('tploy-ff').split(',');

        return featureFlags.map((featureFlag) => featureFlag.trim()).includes(permission);
    }
}
