import { ValidatorFn, Validators } from '@angular/forms';

export function requiredIfValidator(predicate): ValidatorFn {
    return (formControl) => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    };
}

export function conditionalValidator(
    predicate: () => boolean,
    validator: ValidatorFn,
    errorNamespace?: string,
): ValidatorFn {
    return (formControl) => {
        if (!formControl.parent) {
            return null;
        }
        let error = null;
        if (predicate()) {
            error = validator(formControl);
        }
        if (errorNamespace && error) {
            const customError = {};
            customError[errorNamespace] = error;
            error = customError;
        }
        return error;
    };
}
