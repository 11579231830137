import { ErrorHandler, Injectable } from '@angular/core';
import { TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';
import { MentoringService } from './mentoring-service/mentoring.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { MentoringActions } from './mentoring.actions';
import { MentoringProfile } from './mentoring-service/mentoring.types';
import {
    isMentoringProfileInDirtyState,
    isMentoringProfileLoaded,
    isMentoringProfileLoadedOrLoading,
    selectCurrentMentoringProfile,
} from './mentoring.selectors';
import {
    MentoringOthersProfileNotFoundException,
    MentoringUnexpectedException,
} from './mentoring-service/mentoring.exceptions';
import { MentoringState } from './mentoring.reducer';

@Injectable({
    providedIn: 'root',
})
export class MentoringTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ mentoring: MentoringState }>,
        private readonly mentoringService: MentoringService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(MentoringActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<MentoringProfile> {
        this.store
            .select(isMentoringProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(MentoringActions.loadProfile());
                }
            });

        return this.store.select(isMentoringProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentMentoringProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        return this.mentoringService.loadProfileById(id).pipe(
            catchError((error) => {
                if (
                    error instanceof MentoringOthersProfileNotFoundException ||
                    error instanceof MentoringUnexpectedException
                ) {
                    this.errorHandler.handleError(error);
                }
                return of(null);
            }),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isMentoringProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }
}
