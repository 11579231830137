import { Injectable } from '@angular/core';
import { WorkshopParticipantsActions } from './workshop-participants.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TopicWorkshopState } from './topic-workshop.reducer';
import { AccountService, AccountState } from '@tploy-enterprise/tenant-core';
import { WorkshopService } from '../services/workshop.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WorkshopParticipants } from './workshop-participants.reducer';
import { WorkshopProfileService } from '../services/workshop-profile.service';
@Injectable({
    providedIn: 'root',
})
export class WorkshopParticipantsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<{ topicWorkshop: TopicWorkshopState; account: AccountState }>,
        private service: WorkshopService,
        private accountService: AccountService,
        private profileService: WorkshopProfileService,
    ) {}

    loadMyRequestedWorkshops$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopParticipantsActions.load),
            switchMap((action) => this.service.loadEntity(action.workshopId)),
            switchMap((workshop) =>
                this.service.loadParticipantsWithSkills(workshop.id).pipe(
                    map((participantsWithSkills) => {
                        return {
                            ...workshop,
                            ...participantsWithSkills,
                        };
                    }),
                ),
            ),
            map((data: WorkshopParticipants) => WorkshopParticipantsActions.loadSuccess({ data })),
            catchError((error: Error) => of(WorkshopParticipantsActions.loadError({ error }))),
        ),
    );
}
