import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models/direct-messages-conversation.types';

export const DirectMessagesLoadConversationsActions = {
    // Load Conversations (Preview) Actions
    loadConversations: createAction('[DM-Conversations] Load Conversations'),
    loadConversationsSuccess: createAction(
        '[DM-Conversations][Success] Load Conversations',
        props<{ conversations: Conversation[] }>(),
    ),
    loadConversationsError: createAction('[DM-Conversations][Error] Load Conversations', props<{ error: Error }>()),
};
