import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { NLAMatch } from '../../nla-service/nla.types';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'tp-nla-matches-view',
    templateUrl: './nla-matches-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLAMatchesViewComponent implements OnChanges {
    @Input() matches: NLAMatch[];
    @Input() matchesTotal: number;
    @Input() pageSize: number;
    @Input() isLoadingFirstly: boolean;
    @Input() isLoading: boolean;

    showLoader = true;

    @Output() pageChange = new EventEmitter<PageEvent>();

    hasMatches: boolean;

    get hidePaginator() {
        return this.matchesTotal <= this.pageSize;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.matches) {
            this.hasMatches = !!this.matches && this.matches.length > 0;
            this.showLoader = changes.matches.previousValue === changes.matches.currentValue;
        }
        if (changes.isLoading?.currentValue) {
            this.showLoader = true;
        }
    }

    onPageEvent(pageEvent: PageEvent): void {
        this.pageChange.emit(pageEvent);
    }
}
