import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileContentModule, LanguageModule } from '@tploy-enterprise/tenant-common';
import { GenerationExchangeProfileViewComponent } from './generation-exchange-profile-view.component';

@NgModule({
    declarations: [GenerationExchangeProfileViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class GenerationExchangeProfileViewModule {}
