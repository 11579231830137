import { Skill } from '@tploy-enterprise/tenant-common';

export const SUCCESS_FACTORS_USER_DATA_STORAGE_KEY = 'success-factors-user-data';

export type SuccessFactorsSkills = {
    changedLevelSkills: Skill[];
    newSkills: Skill[];
};

export interface SuccessFactorsUserDataDTO {
    displayName: string;
    imageUrl: string;
    skills: Skill[];
}

export interface SuccessFactorsUserData {
    displayName: string;
    imageUrl: string;
    skills: Skill[];
}
