import { NgModule, Provider, Injector } from '@angular/core';
import { default as enTranslations } from '../i18n/translate.en';
import { default as deTranslations } from '../i18n/translate.de';
import { default as deInformalTranslations } from '../i18n/translate.de_informal';
import {
    StaticTopicDefinition,
    AuthenticatedGuard,
    TopicEventHandlers,
    TopicDefinition,
    TopicRegistryService,
    AuthorizationModule,
    WORKSHOPS_TOPIC_NAME,
    CommonActions,
} from '@tploy-enterprise/tenant-core';
import { WorkshopProfileDetailsComponent } from './workshop-profile/profile-view/workshop-profile-details/workshop-profile-details.component';
import { provideRoutes } from '@angular/router';
import { WorkshopTopicEventHandlers } from './workshop-topic-event-handler.service';
import { topicWorkshopsReducer } from './workshop/store/topic-workshop.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TopicWorkshopEffects } from './workshop/store/topic-workshop.effects';
import { WorkshopProfileEffects } from './workshop/store/workshop-profile.effects';
import { WorkshopMatchingEffects } from './workshop/store/workshop-matching.effects';
import { MyWorkshopsEffects } from './workshop/store/my-workshops.effects';
import { WorkshopParticipantsEffects } from './workshop/store/workshop-participants.effects';
import { WorkshopSearchEffects } from './workshop-domain/search/workshop-search.effects';
import { UserRole } from '@tandemploy/common';

const WorkshopTopicDefinition: StaticTopicDefinition = {
    name: WORKSHOPS_TOPIC_NAME,
    info: 'WORKSHOP_INFO',
    translations: {
        formal: {
            en: enTranslations,
            de: deTranslations,
        },
        informal: {
            en: enTranslations,
            de: deInformalTranslations,
        },
    },
    actions: CommonActions,
    links: {
        signUp: ['sign-up', 'workshop'],
        signUpSequence: [
            ['/', 'sign-up', 'workshop', 'motivation'],
            ['/', 'sign-up', 'workshop', 'interest'],
        ],
        edition: [{ outlets: { overlay: ['workshop', 'profile', 'edit'] } }],
        add: [{ outlets: { overlay: ['add-topic', 'workshop'] } }],
        addSequence: [
            ['/', { outlets: { overlay: ['add-topic', 'workshop', 'motivation'] } }],
            ['/', { outlets: { overlay: ['add-topic', 'workshop', 'interest'] } }],
        ],
        domain: ['workshop'],
    },
    embeddableContent: {
        sidenavItem: {
            label: 'WORKSHOP',
            link: '/workshop',
            svgIcon: 'workshop',
            editLink: ['/', { outlets: { overlay: ['workshop', 'profile', 'edit'] } }],
            editPermission: 'workshop.entity.register_to',
            referenceContext: 'workshop',
        },
        profileView: {
            component: WorkshopProfileDetailsComponent,
            permission: 'workshop.entity.register_to',
        },
        modelInformation: {
            svgIcon: 'workshop',
            image: '/assets/images/topics/workshops.jpg',
            label: 'WORKSHOP',
            content: 'WORKSHOP_INFO',
            imageAlt: '',
        },
    },
    rolePermissions: {
        [UserRole.RESTRICTED_USER]: ['workshop.entity.register_to'],
        [UserRole.USER]: ['workshop.entity.register_to', 'workshop.entity.organize', 'workshop.entity.administrate'],
        [UserRole.CONTRIBUTOR]: ['workshop.entity.organize', 'workshop.entity.administrate'],
        [UserRole.ADMIN]: ['workshop.entity.register_to', 'workshop.entity.organize', 'workshop.entity.administrate'],
        [UserRole.ADMIN_MAIL]: [
            'workshop.entity.register_to',
            'workshop.entity.organize',
            'workshop.entity.administrate',
        ],
        [UserRole.STATISTICS]: [
            'workshop.entity.register_to',
            'workshop.entity.organize',
            'workshop.entity.administrate',
        ],
        [UserRole.STAFFING_MANAGER]: [
            'workshop.entity.register_to',
            'workshop.entity.organize',
            'workshop.entity.administrate',
        ],
    },
    notification: true,
};

const LazyRoutesProvider: Provider = provideRoutes([
    {
        path: 'workshop',
        loadChildren: () => import('./workshop-domain/workshop-domain.module').then((m) => m.WorkshopDomainModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'workshop',
        outlet: 'overlay',
        loadChildren: () => import('./workshop-overlay.module').then((m) => m.WorkshopOverlayModule),
    },
    {
        path: 'add-topic/workshop',
        loadChildren: () => import('./workshop-profile/ws-profile-add.module').then((m) => m.WsProfileAddModule),
        outlet: 'overlay',
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'sign-up/workshop',
        loadChildren: () => import('./workshop-profile/ws-profile-sign-up.module').then((m) => m.WsProfileSignUpModule),
        canActivate: [AuthenticatedGuard],
    },
]);

@NgModule({
    providers: [LazyRoutesProvider],
    imports: [
        StoreModule.forFeature('topicWorkshop', topicWorkshopsReducer),
        EffectsModule.forFeature([
            TopicWorkshopEffects,
            WorkshopProfileEffects,
            WorkshopMatchingEffects,
            WorkshopSearchEffects,
            MyWorkshopsEffects,
            WorkshopParticipantsEffects,
        ]),
        AuthorizationModule.forFeature({ permissions: WorkshopTopicDefinition.rolePermissions }),
    ],
})
export class WorkshopFeatureModule {
    constructor(private injector: Injector) {
        const eventHandlers = injector.get(WorkshopTopicEventHandlers);
        this.registerWorkshopTopic(eventHandlers);
    }

    private registerWorkshopTopic(eventHandler: TopicEventHandlers) {
        const definition: TopicDefinition = {
            ...WorkshopTopicDefinition,
            eventHandlers: eventHandler,
        };

        const topicRegistry = this.injector.get(TopicRegistryService);
        topicRegistry.registerTopic(definition);
    }
}
