import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';
import { PageParams, LabelKeys, DEFAULT_PAGE_SIZE } from './paginator.types';

@Component({
    selector: 'tp-paginator',
    templateUrl: './paginator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges {
    @Input() length: number;
    @Input() pageParams: PageParams;
    @Output() page = new EventEmitter<PageEvent>();

    pageSizeOptions: number[];
    showPaginator: boolean;

    get translations(): LabelKeys {
        return {
            ofPageLabel: this.translateService.instant('PAGINATOR_OF'),
            itemsPerPageLabel: this.translateService.instant('PAGINATOR_ITEMS_PER_PAGE'),
        };
    }

    get paginatorLength(): number {
        return this.length ? this.length : this.pageParams.length;
    }

    constructor(private readonly translateService: TranslateService) {
        this.pageSizeOptions = [DEFAULT_PAGE_SIZE, 25, 50, 100];
        this.pageParams = { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE };
    }

    onPageEvent(event: PageEvent) {
        this.page.emit(event);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.length) {
            this.showPaginator = this.length > DEFAULT_PAGE_SIZE;
        }
        if (changes.pageParams) {
            this.showPaginator =
                this.pageParams.length > this.pageParams.pageSize || DEFAULT_PAGE_SIZE < this.pageParams.pageSize;
        }
    }
}
