import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Conversation } from '../models/direct-messages-conversation.types';
import {
    directMessagesConversationSelector,
    directMessagesCurrentConversationSelector,
} from './direct-messages.selector';

@Injectable()
export class DirectMessagesStore {
    constructor(private readonly store: Store<any>) {}

    selectConversations(): Observable<Conversation[]> {
        return this.store.select(directMessagesConversationSelector);
    }

    selectConversationIsSelected(): Observable<boolean> {
        return new Observable<boolean>((observer$) => {
            this.selectCurrentConversation().subscribe((conversation) => {
                observer$.next(!!conversation);
            });
        });
    }

    selectCurrentConversation(): Observable<Conversation> {
        return this.store.select(directMessagesCurrentConversationSelector);
    }
}
