import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkshopService } from '../../workshop/services/workshop.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkshopSearchActions } from './workshop-search.actions';

@Injectable({
    providedIn: 'root',
})
export class WorkshopSearchEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopService: WorkshopService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    searchEntities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopSearchActions.searchEntities),
            switchMap((action) =>
                this.workshopService.searchEntities(action.searchQuery, action.pageParams).pipe(
                    map((result) =>
                        WorkshopSearchActions.searchEntitiesSuccess({
                            entities: result.results,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        this.errorHandler.handleError(error);
                        return of(WorkshopSearchActions.searchEntitiesError({ error }));
                    }),
                ),
            ),
        ),
    );
}
