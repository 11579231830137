import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ExpertService } from '../../expert-api/expert.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ExpertMatchingActions } from './expert-matching.actions';
import { ExpertActions } from '../../expert-store/topic-expert.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DEFAULT_PAGE_SIZE, CommonActions, CommonState } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class ExpertMatchesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly expertService: ExpertService,
        private readonly router: Router,
        private readonly store: Store<{ common: CommonState }>,
    ) {}

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertMatchingActions.load),
            switchMap((action) =>
                this.expertService.getMatches(action.pageParams).pipe(
                    map((result) =>
                        ExpertMatchingActions.loadSuccess({
                            results: result.matches,
                            allResults: result.allMatches,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        return of(ExpertMatchingActions.loadError({ error }));
                    }),
                ),
            ),
        ),
    );

    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertActions.saveSuccess),
            switchMap(() => {
                if (this.router.url.includes('/expert/matching')) {
                    return of(
                        ExpertMatchingActions.load({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    loadMatchItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExpertMatchingActions.loadMatchResult),
            switchMap((action) => {
                this.store.dispatch(CommonActions.loadList());
                return this.expertService.loadMatchResult(action.index).pipe(
                    map((result) => {
                        this.store.dispatch(CommonActions.loadListSuccess());
                        return ExpertMatchingActions.loadMatchResultSuccess({
                            data: result,
                            index: action.index,
                        });
                    }),
                    catchError((error) => {
                        return of(ExpertMatchingActions.loadError({ error }));
                    }),
                );
            }),
        ),
    );
}
