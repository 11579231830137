import { TechWolfState } from './tech-wolf.reducer';
import { TechWolfSkills } from '@tploy-enterprise/tenant-common';

export function techWolfSkills(state: { techWolf: TechWolfState }): TechWolfSkills {
    return state.techWolf.techWolfSkills.skills;
}

export function techWolfSTypeSkills(state: { techWolf: TechWolfState }): string[] {
    return state.techWolf.techWolfSkills.skills.sType || [];
}

export function techWolfLTypeSkills(state: { techWolf: TechWolfState }): string[] {
    return state.techWolf.techWolfSkills.skills.lType || [];
}

export function techWolfATypeSkills(state: { techWolf: TechWolfState }): string[] {
    return state.techWolf.techWolfSkills.skills.aType || [];
}
