import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { Permission } from './authorization.types';
import { NoRequiredPermissionsException } from './no-required-permissions.exception';
import { Store } from '@ngrx/store';
import { AccountResolver } from '../account/account.resolver';
import { AccountState } from '../account/account.reducer';
import { selectCurrentUserId } from '../account/account.selectors';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HasPermissionsGuard implements CanActivate {
    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly store: Store<{ account: AccountState }>,
        private readonly accountResolver: AccountResolver,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.store.select(selectCurrentUserId).pipe(
            take(1),
            switchMap((userId) => {
                if (!userId) {
                    return this.accountResolver.resolve();
                } else {
                    return of(userId);
                }
            }),
            map(() => this.checkPermissions(route)),
        );
    }

    private checkPermissions(route: ActivatedRouteSnapshot): boolean {
        const requiredPermissions: Permission[] = route.data.requiredPermissions;

        if (!requiredPermissions || requiredPermissions.length === 0) {
            throw new Error(
                'HasPermissionGuard was used and no permissions have been requested. This is most likely a mistake. Please provide required permissions to use this guard',
            );
        }

        if (this.authorizationService.hasPermissions(requiredPermissions)) {
            return true;
        }

        throw new NoRequiredPermissionsException();
    }
}
