import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    HostBinding,
    Input,
    Optional,
    Self,
    ViewChild,
    forwardRef,
    ElementRef,
    AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'tp-birth-year-select',
    templateUrl: './birth-year-select.component.html',
    styleUrls: ['./birth-year-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: forwardRef(() => BirthYearSelectComponent),
        },
    ],
})
export class BirthYearSelectComponent
    implements ControlValueAccessor, MatFormFieldControl<number>, OnInit, AfterViewInit, OnDestroy
{
    private readonly destroy$ = new Subject<void>();

    static nextId = 0;

    options: Array<number>;

    @ViewChild(MatSelect, { static: true }) select: MatSelect;

    @Input()
    set value(value: number) {
        this._value = value;
        this._stateChanges.next();
        if (this.onValueChange) {
            this.onValueChange(value);
        }
    }
    get value(): number {
        return this._value;
    }
    private _value: number;

    @Input()
    set required(value) {
        this._required = coerceBooleanProperty(value);
        this._stateChanges.next();
    }
    get required() {
        return this._required;
    }
    private _required = false;

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(disabled) {
        this._disabled = coerceBooleanProperty(disabled);
    }
    private _disabled = false;

    get stateChanges(): Observable<void> {
        return this._stateChanges.asObservable();
    }

    private readonly _stateChanges = new Subject<void>();

    @HostBinding() id = `tp-birth-year-select-${BirthYearSelectComponent.nextId++}`;

    @Input()
    placeholder: string; // { message: 'BIRTH_YEAR_PLACEHOLDER' };

    @Input()
    set ariaLabelledBy(value: string) {
        this._ariaLabelledBy = value;
    }

    get ariaLabelledBy(): string {
        if (this._ariaLabelledBy) {
            return this._ariaLabelledBy;
        }

        return this.formField && this.formField._labelId;
    }
    private _ariaLabelledBy: string;

    focused = false;
    get empty(): boolean {
        return this.ngControl.value === null;
    }

    shouldLabelFloat = true;

    get errorState(): boolean {
        return this.ngControl.touched && this.ngControl.invalid;
    }

    controlType = 'tp-birth-date-select';
    describedBy = '';

    private set touched(value) {
        this.onTouched();
    }

    private onTouched: () => void;
    private onValueChange: (value: number) => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private readonly focusMonitor: FocusMonitor,
        private readonly elementRef: ElementRef<HTMLElement>,
        @Optional() private readonly formField: MatFormField,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
            this.focused = !!origin;
            this._stateChanges.next();
        });
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        this.touched = true;
    }

    registerOnChange(fn) {
        this.onValueChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: number) {
        this.value = value;
    }

    ngOnInit() {
        this.initializeOptions();
    }

    ngAfterViewInit() {
        this.select.valueChange.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            this.value = value;
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private initializeOptions() {
        this.options = [];
        const year = moment().year();
        for (let i = 0; i < 100; i++) {
            this.options.push(year - 18 - i);
        }
    }
}
