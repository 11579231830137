import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { BookmarksPageEntries, BookmarksPageEntry } from './bookmarks-page.types';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BookmarksPageLoadException } from './bookmarks-page.exceptions';
import { AccountService } from '../../core-modules/account';
import { Bookmark, BookmarksApi, BookmarksService } from '../../core-modules/bookmarks';
import { BookmarksPageTopicViewData, TopicRegistryService } from '../../core-modules/topic';
import { SelectedTopicsDefinitionsService } from '../../core-modules/account/selected-topics/selected-topics-definitions.service';
import { BOOKMARKS_CONTEXT } from '../../tenant-core.constants';

@Injectable({
    providedIn: 'root',
})
export class BookmarksPageService {
    constructor(
        private readonly bookmarksService: BookmarksService,
        private readonly bookmarksApi: BookmarksApi,
        private readonly accountService: AccountService,
        private readonly topicRegistryService: TopicRegistryService,
        private readonly selectedTopicsDefinitions: SelectedTopicsDefinitionsService,
    ) {}

    loadBookmarksPageEntries(
        selectedTopicDefinitions: Array<BookmarksPageTopicViewData>,
    ): Observable<BookmarksPageEntries> {
        let bookmarksTotal = 0;
        return this.bookmarksService.loadBookmarks().pipe(
            tap((bookmarks) => (bookmarksTotal = bookmarks.length)),
            catchError(() => {
                throw new BookmarksPageLoadException();
            }),
            switchMap((bookmarks) => {
                if (bookmarks.length > 0) {
                    return forkJoin(bookmarks.map((bookmark) => this.hydrateBookmarksPageEntry(bookmark))).pipe(
                        map((bookmarks) => {
                            return { bookmarks, total: bookmarksTotal };
                        }),
                    );
                } else {
                    return of({ bookmarks: [], total: 0 });
                }
            }),
            map(({ bookmarks, total }) => {
                const filteredBookmarks = bookmarks.filter((bookmark) => !!bookmark.account);
                return {
                    bookmarks: this.sortBookmarksByTopics(filteredBookmarks, selectedTopicDefinitions),
                    total: filteredBookmarks.length,
                };
            }),
        );
    }
    hydrateBookmarksPageEntry(bookmark: Bookmark): Observable<BookmarksPageEntry> {
        const account$ = this.accountService.getAccountById(bookmark.entityId).pipe(catchError(() => of(null)));
        const bookmark$ = of(bookmark);
        const profile$ = this.loadProfileByContext(bookmark.context, bookmark.entityId).pipe(
            catchError(() => of(null)),
        );

        return forkJoin({ account: account$, bookmark: bookmark$, profile: profile$ });
    }

    loadProfileByContext(context: string, accountId: string): Observable<any> {
        const enabledTopics = this.topicRegistryService.availableTopics().map((topic) => topic.name);
        if ('mentoring_mentor' === context || 'mentoring_mentee' === context) {
            context = 'mentoring';
        }
        if (enabledTopics.includes(context)) {
            const topicDefinition = this.topicRegistryService.selectTopicDefinition(context);
            if (topicDefinition) {
                return topicDefinition.eventHandlers
                    .onOthersProfileLoad(accountId)
                    .pipe(catchError((error) => of(null)));
            }
            return of(null);
        }
        return of(null);
    }

    isOtherContextBookmark(context: string) {
        return !context || context === BOOKMARKS_CONTEXT.DIRECT_MESSAGES || context === BOOKMARKS_CONTEXT.PROFILE;
    }

    private sortBookmarksByTopics(
        bookmarkEntries: Array<BookmarksPageEntry>,
        selectedTopicDefinitions: Array<BookmarksPageTopicViewData>,
    ): Array<BookmarksPageEntry> {
        const enabledTopics = selectedTopicDefinitions.map((topic) => topic.name);
        const topicBookmarks = enabledTopics.reduce(
            (topicBookmarks, topicContext) => [
                ...topicBookmarks,
                ...bookmarkEntries.filter((entry) => entry.bookmark.context == topicContext),
            ],
            [],
        );
        const otherBookmarks = bookmarkEntries.filter((entry) => this.isOtherContextBookmark(entry.bookmark.context));
        return [...topicBookmarks, ...otherBookmarks];
    }
}
