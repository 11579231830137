import { Component, Input, OnInit } from '@angular/core';
import { JobXTandemCheckOthersProfile, JobXTandemCheckProfile } from '../../../../jobs.types';
import { Skill } from '@tploy-enterprise/tenant-common';
import { TranslateService } from '@ngx-translate/core';
import { PositionDescription } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-job-x-tandem-check-grid',
    templateUrl: './job-x-tandem-check-grid.component.html',
    styleUrls: ['./job-x-tandem-check-grid.component.scss'],
    host: {
        class: 'job-x__tandem-check-grid',
    },
})
export class JobXTandemCheckGridComponent implements OnInit {
    @Input() ownProfile: JobXTandemCheckProfile;
    @Input() targetProfile: JobXTandemCheckOthersProfile;
    @Input() ownLocations: Array<string>;
    @Input() targetLocations: Array<string>;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {}

    getLocations(locations: Array<string>): string {
        return locations.join(' · ');
    }

    getSkills(skills: Array<Skill>): string {
        return skills.map((skill) => skill.name).join(' · ');
    }

    getLanguages(languages: Array<string>): string {
        if (languages && Array.isArray(languages) && languages.length > 0) {
            return languages
                .map((language) => {
                    return this.translateService.instant(language.toUpperCase());
                })
                .join(' · ');
        }
        return '-';
    }

    getYearOfBirth(data: any): string {
        const year = data?.yearOfBirth || '-';
        return year.toString();
    }

    getEducation(education: Array<string>): string {
        return education?.join(' · ') || '-';
    }

    getExperience(experience: Array<PositionDescription>): string {
        return experience?.map((position) => position.name).join(' · ') || '-';
    }

    getSplitHoursString(splitHours: { myHours: number; tandemHours: number }): string {
        return splitHours?.myHours ? splitHours.myHours.toString() + ' h' : '-';
    }
}
