import { STAApplication, STAEntity, STAEntityStatus } from '../sta.types';
import { Injectable } from '@angular/core';
import { Skill } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class STAUtilsService {
    getEntityRequiredSkills(staEntity: STAEntity): Skill[] {
        return this.filteredSkills(staEntity.requestedSkills, true);
    }

    getEntityNiceToHaveSkills(staEntity: STAEntity): Skill[] {
        return this.filteredSkills(staEntity.requestedSkills, false);
    }

    getMatchedSkills(
        application: STAApplication,
        showOnlyMatched: boolean,
        required: boolean,
        staEntity: STAEntity,
    ): Skill[] {
        const result = this.getSkillsList(application, showOnlyMatched, required, staEntity);

        if (!required && !result.length && showOnlyMatched) {
            const notMatchedList = this.getSkillsList(application, false, required, staEntity);
            if (notMatchedList.length > 0) {
                return [
                    {
                        name: '',
                        exact: true,
                        related: [],
                    },
                ];
            } else {
                return result;
            }
        } else {
            return result;
        }
    }

    getFilteredMatchedSkills(
        application: STAApplication,
        showOnlyMatched: boolean,
        required: boolean,
        staEntity: STAEntity,
    ): Skill[] {
        const matchedSkills = this.getMatchedSkills(application, showOnlyMatched, required, staEntity);
        return matchedSkills.filter((skill) => {
            return showOnlyMatched ? !skill?.related?.length || skill.exact : skill?.related?.length > 0;
        });
    }

    getRelatedSkills(
        application: STAApplication,
        showOnlyMatched: boolean,
        required: boolean,
        staEntity: STAEntity,
    ): Skill[][] {
        const skills = this.getFilteredMatchedSkills(application, showOnlyMatched, required, staEntity);
        return skills.length > 0 ? skills.map((skill) => (skill?.related || []).map((name) => ({ name }))) : [[]];
    }

    private filteredSkills(skills: Skill[], required: boolean): Skill[] {
        return skills.filter((skill) => (required ? skill.required : !skill.required));
    }

    private getSkillsList(
        application: STAApplication,
        showOnlyMatched: boolean,
        required: boolean,
        staEntity: STAEntity,
    ): Skill[] {
        const highlightedExactSkills = application.profile?.highlightedExactSkills || [];
        const skills = showOnlyMatched ? highlightedExactSkills : application.profile?.highlightedByRelatedSkills || [];

        const result = skills.filter((skill) => {
            const entitySkills = required
                ? this.getEntityRequiredSkills(staEntity)
                : this.getEntityNiceToHaveSkills(staEntity);

            return (
                entitySkills.map((sk) => sk.name).includes(skill.name) ||
                (!skill.exact &&
                    skill.related.some((related) => entitySkills.map((sk) => sk.name).includes(related)) &&
                    !highlightedExactSkills.some((sk) => skill.related.includes(sk.name)))
            );
        });

        return result;
    }

    getEntityStatus(entity: STAEntity): STAEntityStatus {
        const dateToday = new Date();
        let status: STAEntityStatus;
        entity.published = entity.published === undefined || entity.published;
        if (!entity.published || entity.startDate > dateToday) {
            status = STAEntityStatus.PLANNED;
        }
        if (entity.published && entity.startDate < dateToday && entity.endDate >= dateToday) {
            status = STAEntityStatus.RUNNING;
        }
        if (entity.published && entity.endDate < dateToday) {
            status = STAEntityStatus.COMPLETED;
        }
        return status;
    }
}
