import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentoringUserCardViewComponent } from './mentoring-user-card-view.component';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';

@NgModule({
    declarations: [MentoringUserCardViewComponent],
    imports: [CommonModule, AccountListItemModule],
})
export class MentoringUserCardViewModule {}
