import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../config';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MatomoService {
    private matomoAttached = false;
    private renderer2: Renderer2;

    constructor(
        private readonly renderer2Factory: RendererFactory2,
        @Inject(DOCUMENT) private readonly document,
        private readonly configService: ConfigService,
        private sanitizer: DomSanitizer,
    ) {
        this.renderer2 = renderer2Factory.createRenderer(null, null);
    }

    public trackPageView(url: string, refUrl?: string): void {
        if (this.matomoAttached) {
            window['_paq'].push(['setCustomUrl', url]);
            if (refUrl) {
                refUrl = `${location.protocol}//${location.hostname}${refUrl}`;
                window['_paq'].push(['setReferrerUrl', refUrl]);
            }
            window['_paq'].push(['trackPageView']);
        }
    }

    attachInstance(): void {
        if (this.configService.remoteSettings.trackingEnabled && !this.matomoAttached) {
            const siteId = this.getMatomoSiteId();
            const trackingUrl = this.getMatomoTrackingUrl();
            const scriptUrl = this.getMatomoScriptUrl();

            if (siteId && trackingUrl && scriptUrl) {
                window['_paq'] = [['enableLinkTracking'], ['setSiteId', siteId], ['setTrackerUrl', trackingUrl]];

                const scriptTag = this.renderer2.createElement('script');
                scriptTag.type = 'text/javascript';
                scriptTag.src = scriptUrl;
                scriptTag.async = true;
                scriptTag.defer = true;
                scriptTag.setAttribute('nonce', '__CSP_NONCE__');

                const firstScriptTag = this.document.head.getElementsByTagName('script')[0];
                this.renderer2.insertBefore(this.document.head, scriptTag, firstScriptTag);
                this.matomoAttached = true;
            }
        }
    }

    private isProductionEnvironment(): boolean {
        return this.configService.environment.environment === 'production';
    }

    private isStagingEnvironment(): boolean {
        return this.configService.environment.environment === 'staging';
    }

    private getMatomoSiteId(): number | undefined {
        if (this.isProductionEnvironment()) {
            return this.configService.remoteSettings.trackingProductionSiteId;
        }

        if (this.isStagingEnvironment()) {
            return this.configService.remoteSettings.trackingStagingSiteId;
        }

        return undefined;
    }

    private getMatomoBaseUrl(): string | undefined {
        if (this.isProductionEnvironment()) {
            return 'https://pi.tandemploy.com';
        }

        if (this.isStagingEnvironment()) {
            return 'https://matomo-experiments.tandemploy.com';
        }

        return undefined;
    }

    private getMatomoTrackingUrl(): string | undefined {
        const baseUrl = this.getMatomoBaseUrl();

        if (baseUrl) {
            return `${baseUrl}/matomo.php`;
        }

        return undefined;
    }

    private getMatomoScriptUrl(): string | undefined {
        const baseUrl = this.getMatomoBaseUrl();

        if (baseUrl) {
            return `${baseUrl}/matomo.js`;
        }

        return undefined;
    }
}
