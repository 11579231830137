import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from, of, forkJoin } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { NoRequiredPermissionsException } from './no-required-permissions.exception';

/**
 * This guard has a limitation: if one of the provided guards was returning a UrlTree,
 * this will be intepreted as truthy and allow navigating (depending on mode and other guards values)
 */
@Injectable()
export class CombineGuardsGuard implements CanActivate {
    constructor(private injector: Injector) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Promise<boolean> | Observable<boolean> {
        // Allows for different use cases. The default angaulr behavior is that all
        // guards must succeed to allow routing.
        // `oneOf` means that only one guard must succeed to grant navigation
        const mode: 'atLeastOne' = route.data.guardsCombinationMode || 'atLeastOne';
        const values: Observable<boolean>[] = route.data.guards
            .map((service) => this.injector.get<CanActivate>(service))
            .map((guard: CanActivate) => {
                try {
                    return guard.canActivate(route, state);
                } catch (error) {
                    if (error instanceof NoRequiredPermissionsException) {
                        return false;
                    } else {
                        throw error;
                    }
                }
            })
            .map((value) => this.toObservable(value).pipe(take(1)));

        return this.getResult(values, mode);
    }

    private getResult(values: Observable<boolean>[], mode: 'atLeastOne' = 'atLeastOne') {
        if (mode === 'atLeastOne') {
            // If at least one guard returns true, allow navigating.
            return this.oneOfStrategy(values);
        }
    }

    private oneOfStrategy(values: Observable<boolean>[]) {
        return forkJoin(values).pipe(
            map((values) =>
                values.reduce((hasAlreadySucceeded, current) => hasAlreadySucceeded || current === true, false),
            ),
        );
    }

    private toObservable(value: boolean | Promise<boolean> | Observable<boolean>): Observable<boolean> {
        if (this.isObservable(value)) {
            return value;
        } else if (this.isPromise(value)) {
            return from(value);
        } else {
            return of(value);
        }
    }

    private isObservable(value: boolean | Promise<boolean> | Observable<boolean>): value is Observable<boolean> {
        return (value as Observable<boolean>).pipe !== undefined;
    }

    private isPromise(value: boolean | Promise<boolean>): value is Promise<boolean> {
        return (value as Promise<boolean>).then !== undefined;
    }
}
