import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WorkshopProfile } from '../../../workshop/models/workshop-profile.types';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-workshop-profile-details',
    templateUrl: './workshop-profile-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__subsection-details',
    },
})
export class WorkshopProfileDetailsComponent implements TopicProfileView {
    @Input()
    topicData: WorkshopProfile;

    get skills(): string[] {
        return this.topicData.skills.map((s) => s.name);
    }

    get interestInCategories(): string[] {
        return this.topicData.categoriesInterestedIn.map((category) => `WORKSHOP_PROFILE_CATEGORY_${category}`);
    }

    get interestedInSkills(): string[] {
        return this.topicData.skillsInterestedIn;
    }

    hasSkills(): boolean {
        return this.topicData && this.topicData.skills && this.topicData.skills.length > 0;
    }

    hasInterestCategories(): boolean {
        return (
            this.topicData && this.topicData.categoriesInterestedIn && this.topicData.categoriesInterestedIn.length > 0
        );
    }

    hasInterestSkills(): boolean {
        return this.topicData && this.topicData.skillsInterestedIn && this.topicData.skillsInterestedIn.length > 0;
    }

    hasContent() {
        return this.hasSkills() || this.hasInterestCategories() || this.hasInterestSkills();
    }
}
