import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TopicSidenavItem } from '../../../topic';
import { Router } from '@angular/router';

@Component({
    selector: 'tp-sidenav-topics',
    templateUrl: './sidenav-topics.component.html',
    styleUrls: ['./sidenav-topics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'sidenav_topics',
    },
})
export class SidenavTopicsComponent {
    @Input() topics: TopicSidenavItem[];
    @Input() canAddMoreTopics: boolean;
    @Input() referenceContext: string;

    constructor(private router: Router) {}

    navigate(routerLink: any[]): Promise<boolean> {
        return this.router.navigate(routerLink);
    }
}
