import { combineReducers, createReducer, on } from '@ngrx/store';
import { NLAActions } from './nla.actions';
import { NLABookmark, NLAMatch, NLAProfile, NLASearchResult, NLASuggestedSkills } from './nla-service/nla.types';
import { AccountActions, BookmarkActions, PageParams } from '@tploy-enterprise/tenant-core';

export type NLAState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
    profile: NLAProfileState;
    matches: NLAMatchesState;
    bookmarks: NLABookmarksState;
    searchResults: NLASearchState;
    suggestedSkills: SuggestedSkillsState;
};

export type SuggestedSkillsState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    suggestedSkills: NLASuggestedSkills;
};

export type StateStatus = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    loadingOther: boolean;
    loadedOther: boolean;
    saving: boolean;
    savingError: Error;
    dirty: boolean;
};

export type NLAProfileState = {
    status: StateStatus;
    saved: NLAProfile;
    current: NLAProfile;
};

export type NLAMatchesState = {
    loaded: boolean;
    loading: boolean;
    error: Error;
    results: NLAMatch[];
    allResults: NLAMatch[];
    pageParams: PageParams;
    length: number;
};

export type NLABookmarksState = {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    data: NLABookmark[];
};

export type NLASearchState = {
    loaded: boolean;
    loading: boolean;
    error: Error;
    results: NLASearchResult[];
    allResults: NLASearchResult[];
    pageParams: PageParams;
    searchQuery: string;
};

export const NLAProfileStatusReducer = createReducer(
    {
        loaded: false,
        loading: false,
        loadingError: null,
        loadingOther: false,
        loadedOther: false,
        saving: false,
        savingError: null,
        dirty: false,
    },
    on(NLAActions.loadProfile, (state) => ({ ...state, loading: true })),
    on(NLAActions.loadProfileSuccess, (state) => ({ ...state, loading: false, loaded: true, loadingError: null })),
    on(NLAActions.loadProfileError, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: true,
        loadingError: error,
    })),
    on(NLAActions.loadOtherProfile, (state) => ({ ...state, loadingOther: true, loadedOther: false })),
    on(NLAActions.loadOtherProfileSuccess, (state) => ({ ...state, loadingOther: false, loadedOther: true })),
    on(NLAActions.loadOtherProfileError, (state) => ({ ...state, loadingOther: false, loadedOther: false })),
    on(NLAActions.saveProfile, (state) => ({ ...state, saving: true })),
    on(NLAActions.saveProfileSuccess, (state) => ({
        ...state,
        saving: false,
        savingError: null,
        loaded: true,
        loadingError: null,
        dirty: false,
    })),
    on(NLAActions.saveProfileError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(NLAActions.editCurrentProfile, (state) => ({ ...state, dirty: true })),
);

export const NLAProfileSavedReducer = createReducer<NLAProfile>(
    {
        companyLocations: [],
        locationIndependent: false,
        interestedInTopics: [],
        favoriteFood: [],
    },
    on(NLAActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(NLAActions.loadProfileSuccess, (state, { profile }) => profile),
);
export const NLAProfileCurrentReducer = createReducer<NLAProfile>(
    {
        companyLocations: [],
        locationIndependent: false,
        interestedInTopics: [],
        favoriteFood: [],
    },
    on(NLAActions.editCurrentProfile, (state, { currentProfile }) => ({ ...state, ...currentProfile })),
    on(NLAActions.loadProfileSuccess, (state, { profile, session }) => session || profile),
    on(NLAActions.loadProfileError, (state, { session }) => session || state),
    on(NLAActions.saveProfileSuccess, (state, { currentProfile }) => currentProfile),
    on(AccountActions.loadSsoDataSuccess, (state, { account }) => ({
        ...state,
        interestedInTopics:
            state.interestedInTopics.length > 0 ? state.interestedInTopics : account.generalData.externalSkills ?? [],
    })),
);

export const NLAProfileReducer = combineReducers<NLAProfileState>({
    status: NLAProfileStatusReducer,
    saved: NLAProfileSavedReducer,
    current: NLAProfileCurrentReducer,
});

export const nlaMatchesInitialState: NLAMatchesState = {
    loaded: false,
    loading: false,
    results: [],
    allResults: [],
    pageParams: { pageIndex: 0, pageSize: 0 },
    length: 0,
    error: null,
};

const NLAMatchesReducer = createReducer(
    nlaMatchesInitialState,
    on(NLAActions.loadMatches, (state) => {
        return { ...state, loading: true };
    }),
    on(NLAActions.loadMatchesSuccess, (state, { results, allResults, length, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...results],
            allResults: [...allResults],
            pageParams,
            length,
        };
    }),
    on(NLAActions.loadMatchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(NLAActions.loadMatchesError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const NLABookmarksReducer = createReducer(
    { loaded: false, loading: false, data: [], error: null },
    on(NLAActions.loadBookmarks, (state) => {
        return { ...state, loading: true };
    }),
    on(NLAActions.loadBookmarksSuccess, (state, { bookmarks }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            data: [...bookmarks],
        };
    }),
    on(NLAActions.loadBookmarksError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
    on(BookmarkActions.addSuccess, (state) => ({ ...state, loaded: false })),
    on(BookmarkActions.deleteSuccess, (state, { bookmark }) => ({
        ...state,
        loaded: false,
        data: state.data.filter((existingBookmark) => {
            return existingBookmark.id !== bookmark.id;
        }),
    })),
);

export const nlaSearchInitialState: NLASearchState = {
    loaded: false,
    loading: false,
    error: null,
    results: [],
    allResults: [],
    pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
    searchQuery: '',
};

export const NLASearchReducer = createReducer(
    nlaSearchInitialState,
    on(NLAActions.search, (state, { searchQuery }) => {
        return { ...state, loading: true, searchQuery };
    }),
    on(NLAActions.searchSuccess, (state, { results, allResults, pageParams }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results: [...results],
            allResults: [...allResults],
            pageParams,
        };
    }),
    on(NLAActions.loadSearchResultSuccess, (state, { data, index }) => {
        state.allResults[index] = data;
        return {
            ...state,
            allResults: [...state.allResults],
        };
    }),
    on(NLAActions.searchError, (state, { error }) => {
        return { ...state, loading: false, error };
    }),
);

const NLASuggestedSkillsReducer = createReducer(
    { suggestedSkills: {}, loadingError: null, loading: false },
    on(NLAActions.loadSuggestedSkills, (state) => ({ ...state, loading: true })),
    on(NLAActions.loadSuggestedSkillsSuccess, (state, { suggestedSkills }) => ({
        ...state,
        suggestedSkills,
        loading: false,
    })),
    on(NLAActions.loadSuggestedSkillsError, (state, { error }) => ({
        ...state,
        loadingError: error,
        loading: false,
    })),
);

export const NLAReducer = combineReducers({
    profile: NLAProfileReducer,
    searchResults: NLASearchReducer,
    bookmarks: NLABookmarksReducer,
    matches: NLAMatchesReducer,
    suggestedSkills: NLASuggestedSkillsReducer,
});
