export const ROUTING_SEQUENCE_STORAGE_KEY = 'tploy.routingSequence';

export interface NavigateCommand {
    step: RoutingStep;
    index: number;
    event: 'navigate' | 'complete' | 'abort' | 'skip';
    direction?: 'forward' | 'backward';
}

export type RoutingStep = any[];

export interface RoutingSequence {
    routes: RoutingStep[];
    onComplete: RoutingStep;
    onAbort: RoutingStep;
}
