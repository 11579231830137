import { DirectMessage } from './direct-messages.types';
import { Contact } from './direct-messages-contact.types';

export enum ConversationType {
    PRIVATE = 'private',
    GROUP = 'group',
}

export type Conversation = PrivateConversation | GroupConversation;

export type BaseConversation = {
    messages: DirectMessage[];
    participants: Contact[];
    imageUrl: string;
    id: string;
    lastMessage?: DirectMessage;
    name: string;
    origin: string;
    createdAt: string;
    owner: string;
    mutedBy: string[];
    originId?: string;
};

export interface PrivateConversation extends BaseConversation {
    type: ConversationType.PRIVATE;
}

export interface GroupConversation extends BaseConversation {
    type: ConversationType.GROUP;
}
