import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { TranslatableModule } from '../language/translatable/translatable.module';
import { PopoverDirective } from './popover.directive';
import { PopoverComponent } from './popover.component';
import { PopoverHoverDirective } from './popover-hover.directive';

@NgModule({
    imports: [A11yModule, CommonModule, TranslatableModule, OverlayModule, MatCommonModule, RouterModule, PortalModule],
    exports: [PopoverDirective, PopoverHoverDirective, PopoverComponent],
    declarations: [PopoverDirective, PopoverHoverDirective, PopoverComponent],
})
export class PopoverModule {}
