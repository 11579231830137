import {
    STAEntity,
    STAProfile,
    STAProfileMatch,
    STASearchResult,
    StaStaffingFilter,
    STASuggestedSkills,
} from './sta.types';
import { STAProfileState, STAState } from './sta.reducer';
import {
    AccountState,
    GeneralData,
    INTEREST_KEY,
    OFFERING_KEY,
    PageParams,
    SHORT_TIME_ASSIGNMENTS_TOPIC_NAME,
} from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';

export function selectCurrentSTAProfile(state: { shortTimeAssignments: STAState }): STAProfile {
    return state.shortTimeAssignments.profile.current;
}

export function selectSavedSTAProfile(state: { shortTimeAssignments: STAState }): STAProfile {
    return state.shortTimeAssignments.profile.saved;
}

export function isSTAProfileLoadedOrLoading(state: { shortTimeAssignments: STAState }): boolean {
    const status = state.shortTimeAssignments.profile.status;
    return status.loaded || status.loading;
}

export function isSTAProfileLoaded(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.profile.status.loaded;
}

export function isSTAProfileInDirtyState(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.profile.status.dirty;
}

export function selectMatchesPageSelectedCampaigns(state: { shortTimeAssignments: STAState }): string[] {
    return state.shortTimeAssignments.matches.selectedCampaigns;
}

export function hasSTAProfile(state: { shortTimeAssignments: STAState; account: AccountState }): boolean {
    const profileState: STAProfileState = state.shortTimeAssignments.profile;
    const accountState: AccountState = state.account;
    return (
        profileState.status.loaded &&
        !profileState.status.loadingError &&
        profileState.current?.motivations?.length > 0 &&
        accountState.selectedTopics.current.data.includes(SHORT_TIME_ASSIGNMENTS_TOPIC_NAME)
    );
}

export function isUserInterestedInSTA(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.profile.current.motivations.indexOf(INTEREST_KEY) !== -1;
}

export function isUserOfferingSTA(state: { shortTimeAssignments: STAState }): boolean {
    const currentMotivations = state.shortTimeAssignments.profile.current.motivations;
    return currentMotivations.indexOf(OFFERING_KEY) >= 0;
}

export function selectSearchResults(state: { shortTimeAssignments: STAState }): STASearchResult[] {
    return state.shortTimeAssignments.searchResults.results;
}

export function selectSearchPageParams(state: { shortTimeAssignments: STAState }): PageParams {
    return state.shortTimeAssignments.searchResults.pageParams;
}

export function selectStaffingResults(state: { shortTimeAssignments: STAState }): STASearchResult[] {
    return state.shortTimeAssignments.staffing.results;
}

export function selectStaffingPageParams(state: { shortTimeAssignments: STAState }): PageParams {
    return state.shortTimeAssignments.staffing.pageParams;
}

export function selectMatching(state: { shortTimeAssignments: STAState }): STAProfileMatch[] {
    return state.shortTimeAssignments.matches.entities;
}

export function selectMatchingPageParams(state: { shortTimeAssignments: STAState }): PageParams {
    return state.shortTimeAssignments.matches.pageParams;
}

export function isSearchResultsEmpty(state: { shortTimeAssignments: STAState }): boolean {
    const searchState = state.shortTimeAssignments.searchResults;
    return searchState.loaded && searchState.results.length === 0;
}

export function isSearchResultsLoading(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.searchResults.loading;
}

export function isStaffingResultsEmpty(state: { shortTimeAssignments: STAState }): boolean {
    const staffingState = state.shortTimeAssignments.staffing;
    return staffingState.loaded && staffingState.results.length === 0;
}

export function isStaffingResultsLoading(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.staffing.loading;
}

export function isOfferedByMeLoading(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.offeredByMe.loading;
}

export function suggestedSkills(state: { shortTimeAssignments: STAState }): STASuggestedSkills {
    return state.shortTimeAssignments.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { shortTimeAssignments: STAState }): string[] {
    return state.shortTimeAssignments.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { shortTimeAssignments: STAState }): string[] {
    return state.shortTimeAssignments.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { shortTimeAssignments: STAState }): string[] {
    return state.shortTimeAssignments.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}

export function showTopicLinkIndicator(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.unseenApplications.hasUnseenApplicationsTopicLink;
}

export function showOfferingTabIndicator(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.unseenApplications.hasUnseenApplicationsOfferingTab;
}

export function selectOfferedByMe(state: { shortTimeAssignments: STAState }): Array<STAEntity> {
    return state.shortTimeAssignments.offeredByMe.entities;
}

export function selectApplicationSkills(state: { shortTimeAssignments: STAState }): Array<Skill> {
    return state.shortTimeAssignments.applicationSkills.selectedSkills;
}

export function selectStaffingFilter(state: { shortTimeAssignments: STAState }): StaStaffingFilter {
    return state.shortTimeAssignments.staffing.filter;
}

export function selectSearchTopicUsers(state: { shortTimeAssignments: STAState }): Partial<GeneralData>[] {
    return state.shortTimeAssignments.searchTopicUsers.results;
}

export function selectSearchTopicUsersLoading(state: { shortTimeAssignments: STAState }): boolean {
    return state.shortTimeAssignments.searchTopicUsers.loading;
}
