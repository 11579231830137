import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'tp-main-layout',
    templateUrl: './main-layout.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        class: 'main-layout',
    },
})
export class MainLayoutComponent {}
