<mat-icon
    svgIcon="wifi"
    tpPopover
    tpPopoverHover
    [tpPopoverHasBackdrop]="false"
    [tpVerticalTarget]="'above'"
    [tpPopoverContent]="popoverContent"
    [tpPopoverData]="popoverData"
    [tpPopoverClass]="popoverClass"
></mat-icon>
