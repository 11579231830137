import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '../../popover';

export interface CampaignsPopoverData {
    campaigns: Array<{ name: string; matched: boolean }>;
}

@Component({
    selector: 'tp-campaigns-popover',
    templateUrl: './campaigns-popover.component.html',
    host: { class: 'tp-campaigns-popover' },
})
export class CampaignsPopoverComponent {
    campaigns: Array<{ name: string; matched: boolean }>;

    constructor(@Inject(POPOVER_DATA) public popoverData: CampaignsPopoverData) {
        this.campaigns = this.popoverData.campaigns;
    }
}
