import { NgModule } from '@angular/core';
import { GenerationExchangeProfileNavigatorViewComponent } from './generation-exchange-profile-navigator-view.component';
import { CommonModule } from '@angular/common';
import { LanguageModule, ProfileContentModule } from '@tploy-enterprise/tenant-common';

@NgModule({
    declarations: [GenerationExchangeProfileNavigatorViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class GenerationExchangeProfileNavigatorViewModule {}
