import {
    AccountSettings,
    Bookmark,
    GeneralData,
    OthersAccount,
    ExperienceData,
    PageParams,
    INTEREST_TYPE,
} from '@tploy-enterprise/tenant-core';

import { Skill, SuggestedSkills } from '@tploy-enterprise/tenant-common';

export const JOBX_STORAGE_KEY = 'tploy.jobx';
export const IS_PROFILE_DIRTY = 'tploy.jobx.profile.isDirty';

export interface JobXSkill {
    name: string;
}

export interface Location {
    name: string;
}

export interface JobXRequiredSkill extends JobXSkill {
    required: boolean;
}

export type JobXMotivation = INTEREST_TYPE;

export enum JobXLeadershipPositionWish {
    LEADERSHIP_POSITION_YES = 'LEADERSHIP_POSITION_YES',
    LEADERSHIP_POSITION_FLEXIBLE = 'LEADERSHIP_POSITION_FLEXIBLE',
    LEADERSHIP_POSITION_NO = 'LEADERSHIP_POSITION_NO',
}

export enum JobXMainMotivation {
    JOBX_MAIN_MOTIVATION_DESIRED_CAREER = 'JOBX_MAIN_MOTIVATION_DESIRED_CAREER',
    JOBX_MAIN_MOTIVATION_TEAMWORK = 'JOBX_MAIN_MOTIVATION_TEAMWORK',
    JOBX_MAIN_MOTIVATION_CO_LEADERSHIP = 'JOBX_MAIN_MOTIVATION_CO_LEADERSHIP',
}

export interface JobXProfile {
    motivations: JobXMotivation[];
    skillsInterestedIn: Array<Skill>;
    skills: Array<Skill>;
    locations: Array<string>;
    mainMotivation: string;
    currentlyInLeadershipPosition: boolean;
    wantsToBeInLeadershipPosition: JobXLeadershipPositionWish;
    splitHours: {
        myHours: number;
        tandemHours: number;
    };
    currentJobLikes: string;
    currentJobDescription: string;
    pushToSuccessFactor?: boolean;
    campaigns: Array<string>;
}

export interface JobXOthersProfile extends JobXProfile {
    user: {
        id: string;
        name: string;
        imageUrl: string;
        locations: Array<{ name: string }>;
        languages: Array<string>;
        message?: string;
        yearOfBirth?: number;
    };
    bookmark?: Bookmark;
}

export interface JobXEntity {
    id?: string;
    title: string;
    languages: Array<string>;
    description: string;
    endDate: Date;
    requestedSkills: Array<Skill>;
    location: string;
    organizer?: string; // this is set in the backend and cannot be edited
    lastUpdatedAt?: string;
    published?: boolean;
    organizerData?: Partial<OthersAccount>; // this is set in the backend and cannot be edited
    draft: boolean;
}

export interface JobXProfileSearchResults {
    results: Array<JobXProfileSearchResult>;
    allResults: Array<JobXProfileSearchResult>;
    pageParams: PageParams;
}

export interface JobXProfileSearchResult {
    profile: JobXOthersProfile;
    userId: string;
    highlight: JobXSearchResultHighlight;
    bookmark?: Bookmark;
}

export type JobXSearchResultHighlight = {
    skillsRequired: Array<string>;
    location: Array<string>;
    title: Array<string>;
    description: Array<string>;
    campaigns: Array<string>;
};

export interface JobXSearchResults {
    results: Array<JobXSearchResult>;
    pageParams: PageParams;
}

export interface JobXSearchResult {
    jobId: string;
    jobEntity: JobXEntity;
    highlight: JobXSearchResultHighlight;
}

export type JobXSuggestedSkills = SuggestedSkills;

export type JobXProfileMatchHighlight = {
    mustHaveSkills: Array<string>;
    campaigns: Array<string>;
};

export type JobXProfileMatches = {
    matches: Array<JobXProfileMatch>;
    allMatches: Array<JobXProfileMatch>;
    pageParams: PageParams;
};

export type JobXProfileMatch = {
    userId: string;
    profile: JobXOthersProfile;
    highlight: JobXProfileMatchHighlight;
    bookmark?: Bookmark;
};

export type JobXBookmark = Bookmark & {
    bookmark?: Bookmark;
    profile?: JobXTandemCheckOthersProfile;
};

export type JobXTandemCheckProfile = Partial<JobXProfile> & {
    user?: {
        generalData: Partial<GeneralData>;
        experience: ExperienceData;
        settings: AccountSettings;
    };
};

export type JobXTandemCheckOthersProfile = Partial<JobXOthersProfile> & {
    user?: {
        generalData?: Partial<GeneralData>;
        experience?: ExperienceData;
        settings?: AccountSettings;
    };
};
