<section class="modal-participants-notification-wrap">
    <header class="form-header">
        <h1 mat-dialog-title>
            {{ 'HEADLINE_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION' | translatable }}
        </h1>
        <tp-icon-button
            [mat-dialog-close]="null"
            [icon]="'close'"
            ariaLabel="{{ 'MODAL_CLOSE_LABEL' | translatable }}"
            class="form-header_close modal-participants-notification-close no-border"
        ></tp-icon-button>
    </header>
    <mat-dialog-content class="dialog-content">
        <div class="dialog-content__column--left">
            <mat-icon svgIcon="info_filled"></mat-icon>
        </div>
        <div class="modal-participants-notification-text dialog-content__column--right">
            <p>{{ 'TEXT_DIALOG_CONFIRM_PARTICIPANTS_NOTIFICATION' | translatable }}</p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="form-footer form-footer--buttons-center form-footer--ignore-sibling-padding">
        <nav class="modal-participants-notification-nav">
            <tp-secondary-button [mat-dialog-close]="false">
                {{ 'NOT_INFORM_PARTICIPANTS' | translatable }}
            </tp-secondary-button>
            <tp-primary-button [mat-dialog-close]="true">
                {{ 'INFORM_PARTICIPANTS' | translatable }}
            </tp-primary-button>
        </nav>
    </mat-dialog-actions>
</section>
