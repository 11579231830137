import { Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { CommunicationType, MentoringProfile } from '../../mentoring-service/mentoring.types';

@Component({
    selector: 'tp-mentoring-profile-view',
    templateUrl: './mentoring-profile-view.component.html',
})
export class MentoringProfileViewComponent implements TopicProfileView {
    @Input()
    topicData: MentoringProfile;

    hasInterestSkills(): boolean {
        return (
            this.topicData &&
            this.topicData.skillsInterestedIn &&
            this.topicData.skillsInterestedIn.length > 0 &&
            this.topicData.isMentee
        );
    }

    hasOfferingSkills(): boolean {
        return (
            this.topicData &&
            this.topicData.skillsOffering &&
            this.topicData.skillsOffering.length > 0 &&
            this.topicData.isMentor
        );
    }

    get communicationTypeOfferingKey(): string {
        switch (this.topicData.communicationTypeOffering) {
            case CommunicationType.BOTH:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_BOTH';
            case CommunicationType.IN_PERSON:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_IN_PERSON';
            case CommunicationType.VIRTUAL:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_VIRTUAL';
        }
    }

    get communicationTypeInterestKey(): string {
        switch (this.topicData.communicationTypeInterest) {
            case CommunicationType.BOTH:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_BOTH';
            case CommunicationType.IN_PERSON:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_IN_PERSON';
            case CommunicationType.VIRTUAL:
                return 'MENTORING_PROFILE_VIEW_COMMUNICATION_VIRTUAL';
        }
    }

    hasCommunicationTypeOffering(): boolean {
        return this.topicData && !!this.topicData.communicationTypeOffering && this.topicData.isMentor;
    }

    hasCommunicationTypeInterest(): boolean {
        return this.topicData && !!this.topicData.communicationTypeInterest && this.topicData.isMentee;
    }

    get skillsInterestedIn() {
        return this.topicData.skillsInterestedIn;
    }

    get skillsOffering() {
        return this.topicData.skillsOffering;
    }

    get campaignsOffering(): string[] {
        return this.topicData.campaignsOffering;
    }

    get campaignsInterest(): string[] {
        return this.topicData.campaignsInterest;
    }

    hasCampaignsOffering(): boolean {
        return this.topicData?.campaignsOffering?.length > 0 && this.topicData.isMentor;
    }

    hasCampaignsInterest(): boolean {
        return this.topicData?.campaignsInterest?.length > 0 && this.topicData.isMentee;
    }

    hasContent(): boolean {
        return (
            this.hasInterestSkills() ||
            this.hasOfferingSkills() ||
            this.hasCommunicationTypeOffering() ||
            this.hasCommunicationTypeInterest() ||
            this.hasCampaignsOffering() ||
            this.hasCampaignsInterest()
        );
    }
}
