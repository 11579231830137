<ng-container *ngIf="showPaginator">
    <tp-custom-paginator
        [length]="paginatorLength"
        [showPaginator]="showPaginator"
        [translations]="translations"
        [pageSizeOptions]="pageSizeOptions"
        [pageParams]="pageParams"
        (page)="onPageEvent($event)"
    ></tp-custom-paginator>
</ng-container>
