import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { BookmarkActions } from './bookmark.actions';
import { BookmarksState } from './bookmarks.reducer';
import { Bookmark } from './bookmark.types';

@Injectable({
    providedIn: 'root',
})
export class BookmarksResolver implements Resolve<Array<Bookmark>> {
    constructor(private store: Store<{ bookmarks: BookmarksState }>) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Array<Bookmark>> {
        const context = route.data['context'];
        return this.store
            .select((state) => state.bookmarks)
            .pipe(
                tap((bookmarksData) => this.checkState(bookmarksData, context)),
                map((bookmarksState) => {
                    return bookmarksState.bookmarks;
                }),
                catchError((error) => {
                    console.error(error);
                    return of([] as Array<Bookmark>);
                }),
                take(1),
            );
    }

    private checkState(state: BookmarksState, context: string): void {
        if (state.status.loadingError) {
            throw state.status.loadingError;
        }

        if (!state.status.loaded && !state.status.loading) {
            this.store.dispatch(BookmarkActions.load({ context: context }));
        }
    }
}
