import { Bookmark, GeneralData } from '@tploy-enterprise/tenant-core';
import {
    JobXBookmark,
    JobXEntity,
    JobXOthersProfile,
    JobXProfile,
    JobXProfileMatch,
    JobXProfileMatchHighlight,
    JobXProfileSearchResult,
    JobXSearchResult,
    JobXSearchResultHighlight,
} from './jobs.types';
import {
    JobXEntityDTO,
    JobXProfileDTO,
    JobXProfileMatchDTO,
    JobXProfileMatchHighlightDTO,
    JobXProfileSearchResultDTO,
    JobXSearchResultDTO,
    JobXSearchResultHighlightDTO,
} from './job-x-api/jobx.dtos';
import { Injectable } from '@angular/core';
import { SkillsSerializer } from '@tploy-enterprise/tenant-common';
import { LocationSerializer } from '@tploy-enterprise/tenant-core';

@Injectable({
    providedIn: 'root',
})
export class JobXSerializer {
    constructor(
        private readonly skillsSerializer: SkillsSerializer,
        private readonly locationSerializer: LocationSerializer,
    ) {}

    serializeJobXProfile(profile: JobXProfile, user: GeneralData): JobXProfileDTO {
        return {
            user: {
                id: user.id,
                name: user.name,
                imageUrl: user.imageUrl,
                languages: user.languages,
                locations: this.locationSerializer.serialise(user.locations),
                yearOfBirth: user.yearOfBirth,
                groups: user.groups,
            },
            skills: this.skillsSerializer.serialize(profile.skills),
            skillsInterestedIn: this.skillsSerializer.serialize(profile.skillsInterestedIn || []),
            motivations: profile.motivations,
            locations: this.locationSerializer.serialise(profile.locations),
            mainMotivation: profile.mainMotivation?.length > 0 ? profile.mainMotivation : null,
            currentJobLikes: profile.currentJobLikes,
            currentJobDescription: profile.currentJobDescription,
            currentlyInLeadershipPosition: profile.currentlyInLeadershipPosition,
            wantsToBeInLeadershipPosition: profile.wantsToBeInLeadershipPosition,
            splitHours: profile.splitHours,
            pushToSuccessFactor: profile.pushToSuccessFactor,
            campaigns: profile.campaigns,
        };
    }

    deserializeJobXProfile(dto: JobXProfileDTO): JobXProfile {
        return {
            skills: this.skillsSerializer.deserialize(dto.skills),
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn || []),
            motivations: dto.motivations,
            locations: dto.locations.map((location) => location.name),
            mainMotivation: dto.mainMotivation,
            currentJobLikes: dto.currentJobLikes,
            currentJobDescription: dto.currentJobDescription,
            currentlyInLeadershipPosition: dto.currentlyInLeadershipPosition,
            wantsToBeInLeadershipPosition: dto.wantsToBeInLeadershipPosition,
            splitHours: dto.splitHours,
            campaigns: dto.campaigns,
        };
    }

    deserializeJobXOthersProfile(dto: JobXProfileDTO): JobXOthersProfile {
        return {
            user: dto.user,
            skills: this.skillsSerializer.deserialize(dto.skills),
            skillsInterestedIn: this.skillsSerializer.deserialize(dto.skillsInterestedIn || []),
            motivations: dto.motivations,
            locations: dto.locations.map((location) => location.name),
            mainMotivation: dto.mainMotivation,
            currentJobLikes: dto.currentJobLikes,
            currentJobDescription: dto.currentJobDescription,
            currentlyInLeadershipPosition: dto.currentlyInLeadershipPosition,
            wantsToBeInLeadershipPosition: dto.wantsToBeInLeadershipPosition,
            splitHours: dto.splitHours,
            campaigns: dto.campaigns,
        };
    }

    serializeJobXEntity(jobXEntity: JobXEntity): JobXEntityDTO {
        return {
            id: jobXEntity.id,
            title: jobXEntity.title,
            languages: this.serializeLanguagesArray(jobXEntity.languages),
            description: jobXEntity.description,
            endDate: jobXEntity.endDate.toISOString(),
            requestedSkills: this.skillsSerializer.serialize(jobXEntity.requestedSkills), // save current array position
            location: { name: jobXEntity.location[0] },
            published: jobXEntity.published,
            draft: jobXEntity.draft,
        };
    }

    private serializeLanguagesArray(languages: Array<string>): Array<string> | null {
        return languages.length === 0 ? null : languages;
    }

    deserializeJobXEntity(dto: JobXEntityDTO): JobXEntity {
        return {
            id: dto.id,
            title: dto.title,
            languages: this.deserializeLanguagesArray(dto.languages),
            description: dto.description,
            endDate: new Date(dto.endDate),
            requestedSkills: this.skillsSerializer.deserialize(dto.requestedSkills), // strip the position property
            location: dto.location.name,
            published: dto.published,
            organizer: dto.organizer,
            lastUpdatedAt: dto.lastUpdatedAt,
            draft: dto.draft,
        };
    }

    deserializeLanguagesArray(languages: Array<string> | null): Array<string> {
        return !languages ? [] : languages;
    }

    deserializeJobXProfileSearchResult(dto: JobXProfileSearchResultDTO): JobXProfileSearchResult {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeJobXSearchResultHighlight(dto.highlight),
            profile: this.deserializeJobXOthersProfile(dto.profile),
        };
    }

    deserializeJobXSearchResult(dto: JobXSearchResultDTO): JobXSearchResult {
        return {
            jobId: dto.entity.id,
            highlight: this.deserializeJobXSearchResultHighlight(dto.highlight),
            jobEntity: {
                ...this.deserializeJobXEntity(dto.entity),
                organizerData: {
                    generalData: {
                        ...dto.organizer,
                        locations: dto.organizer.locations?.map((location) => location.name),
                        yearOfBirth: +dto.organizer.yearOfBirth,
                    },
                },
            },
        };
    }

    deserializeJobXProfileMatch(dto: JobXProfileMatchDTO): JobXProfileMatch {
        return {
            userId: dto.profile.user.id,
            highlight: this.deserializeJobXProfileMatchHighlight(dto.highlight),
            profile: this.deserializeJobXOthersProfile(dto.profile),
        };
    }

    private deserializeJobXSearchResultHighlight(dto: JobXSearchResultHighlightDTO): JobXSearchResultHighlight {
        if (!dto) {
            return { description: [], location: [], skillsRequired: [], title: [], campaigns: [] };
        }

        return {
            skillsRequired: dto.skillsRequired,
            location: dto.location,
            title: dto.title,
            description: dto.description,
            campaigns: dto.campaigns,
        };
    }

    private deserializeJobXProfileMatchHighlight(dto: JobXProfileMatchHighlightDTO): JobXProfileMatchHighlight {
        if (!dto) {
            return { mustHaveSkills: [], campaigns: [] };
        }

        return {
            mustHaveSkills: dto.mustHaveSkills,
            campaigns: dto.campaigns,
        };
    }

    public deserializeJobXBookmark(bookmark: Bookmark): JobXBookmark {
        return {
            ...bookmark,
        };
    }
}
