import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'tp-common-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class CommonLoaderComponent {
    @Input() size: any;

    @HostBinding('class.small-size')
    get isSmall() {
        return this.size === 'small';
    }
}
