import { Injectable } from '@angular/core';
import { STAApi } from './sta-api/sta.api';
import { STASerializer } from './sta.serializer';
import {
    ApplicationRequest,
    STAApplication,
    STAApplicationIndicators,
    STAApplicationsSelections,
    STAEntity,
    STAOthersProfile,
    STAProfile,
    STAProfileInvitationMessage,
    STAProfileMatches,
    STAProfileSearchResult,
    STASearchResults,
    StaStaffingFilter,
    STASuggestedSkills,
} from './sta.types';
import {
    AccountService,
    EntityNotFoundService,
    GeneralData,
    OthersAccount,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    StaDomainIndexException,
    StaDomainMatchingException,
    StaDomainSearchException,
    StaEntityApplicationBadRequestException,
    StaEntityApplicationForbiddenException,
    StaEntityApplicationNotFoundException,
    StaEntityBadRequestException,
    StaEntityForbiddenException,
    StaEntityNotFoundException,
    StaMyProfileNotFoundException,
    StaOthersProfileNotFoundException,
    StaProfileBadRequestException,
    StaUnexpectedException,
} from './sta.exceptions';
import { Skill } from '@tploy-enterprise/tenant-common';

@Injectable({
    providedIn: 'root',
})
export class STAService {
    constructor(
        private readonly staApi: STAApi,
        private readonly staSerializer: STASerializer,
        private readonly accountService: AccountService,
        private readonly entityNotFoundService: EntityNotFoundService,
        private readonly paginatorService: PaginatorService,
    ) {}

    saveProfile(profile: STAProfile, general: GeneralData): Observable<STAProfile> {
        const dto = this.staSerializer.serializeSTAProfile(profile, general);
        return this.staApi.putProfile(dto).pipe(
            map((staProfileDTO) => this.staSerializer.deserializeSTAProfile(staProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfile(): Observable<STAProfile> {
        return this.staApi.getProfile().pipe(
            map((staProfileDTO) => this.staSerializer.deserializeSTAProfile(staProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<STAOthersProfile> {
        return this.staApi.getProfileById(id).pipe(
            map((staProfileDTO) => this.staSerializer.deserializeSTAOthersProfile(staProfileDTO)),
            catchError((error) => this.handleProfileError(error)),
        );
    }

    saveEntity(staEntity: STAEntity, sendNotifications?: boolean): Observable<STAEntity> {
        const dto = this.staSerializer.serializeSTAEntity(staEntity);
        return this.staApi.putEntity(dto, sendNotifications).pipe(
            map((staEntityDTO) => this.staSerializer.deserializeSTAEntity(staEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    createEntity(staEntity: STAEntity): Observable<STAEntity> {
        const dto = this.staSerializer.serializeSTAEntity(staEntity);
        return this.staApi.postEntity(dto).pipe(
            map((staEntityDTO) => this.staSerializer.deserializeSTAEntity(staEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    deleteEntity(entityId: string): Observable<string> {
        return this.staApi.deleteEntity(entityId).pipe(catchError((error) => this.handleEntityError(error)));
    }

    publishEntity(entityId: string): Observable<STAEntity> {
        return this.staApi.publishEntity(entityId).pipe(
            map((staEntityDTO) => this.staSerializer.deserializeSTAEntity(staEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    searchEntities(searchQuery: string, pageParams: PageParams, campaigns?: string[]): Observable<STASearchResults> {
        let matchesTotal = 0;
        return this.staApi.search(searchQuery, this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.staSerializer.deserializeSTASearchResult(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        results: results.map((result) => {
                            if (campaigns?.length > 0) {
                                result.highlight.campaigns = campaigns;
                            }
                            return result;
                        }),
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new StaDomainSearchException();
            }),
        );
    }

    loadStaffingEntities(
        searchQuery: string,
        pageParams: PageParams,
        filter: StaStaffingFilter,
    ): Observable<STASearchResults> {
        let matchesTotal = 0;
        return this.staApi
            .loadStaffingEntities(searchQuery, this.paginatorService.offsetLimit(pageParams), filter)
            .pipe(
                tap((response) => (matchesTotal = response.totalCount)),
                map((response) => response.results),
                map((results) => results.map((dto) => this.staSerializer.deserializeSTASearchResult(dto))),
                map((results) => {
                    if (results.length > 0) {
                        return {
                            results,
                            pageParams: { ...pageParams, length: matchesTotal },
                        };
                    } else {
                        return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                    }
                }),
                catchError(() => {
                    throw new StaDomainSearchException();
                }),
            );
    }

    loadEntity(entityId: string, showHighlighted = false, isStaffingManagerView = false): Observable<STAEntity> {
        return this.staApi.getEntity(entityId, showHighlighted, isStaffingManagerView).pipe(
            map((staEntityDTO) => this.staSerializer.deserializeSTAEntity(staEntityDTO)),
            catchError((error: Error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    finishStaffing(entity: STAEntity): Observable<STAEntity> {
        return this.staApi.completeStuffing(entity.id).pipe(
            map((staEntityDTO) => this.staSerializer.deserializeSTAEntity(staEntityDTO)),
            catchError((error) => this.handleEntityError(error)),
            switchMap((entity) => this.hydrateEntity(entity)),
        );
    }

    apply(entityId: string, name: string, selectedSkills: Array<Skill>): Observable<STAApplication> {
        const newApplication: ApplicationRequest = {
            user: { name },
            selectedSkills,
        };

        return this.staApi.postEntityApplication(entityId, newApplication).pipe(
            map((staApplicationDTO) => {
                return this.staSerializer.deserializeApplication(staApplicationDTO);
            }),
            catchError((error) => this.handleEntityApplicationsError(error)),
        );
    }

    acceptApplication(staEntity: STAEntity, application: STAApplication): Observable<STAApplication> {
        return this.staApi.patchEntityApplicationStatus(staEntity.id, application.id, { status: 'approved' }).pipe(
            map((staApplicationDTO) => {
                return this.staSerializer.deserializeApplication(staApplicationDTO);
            }),
            catchError((error) => this.handleEntityApplicationsError(error)),
            switchMap((application) => this.hydrateApplication(application)),
        );
    }

    rejectApplication(staEntity: STAEntity, application: STAApplication): Observable<STAApplication> {
        return this.staApi.patchEntityApplicationStatus(staEntity.id, application.id, { status: 'rejected' }).pipe(
            map((dto) => this.staSerializer.deserializeApplication(dto)),
            catchError((error) => this.handleEntityApplicationsError(error)),
            switchMap((application) => this.hydrateApplication(application)),
        );
    }

    cancelApplication(staEntity: STAEntity, application: STAApplication): Observable<STAApplication> {
        return this.staApi.patchEntityApplicationStatus(staEntity.id, application.id, { status: 'cancelled' }).pipe(
            map((dto) => this.staSerializer.deserializeApplication(dto)),
            catchError((error) => this.handleEntityApplicationsError(error)),
            switchMap((application) => this.hydrateApplication(application)),
        );
    }

    loadEntitiesOfferedByMe(userId: string): Observable<STAEntity[]> {
        return this.staApi.getEntitiesOfferedByMe(userId).pipe(
            map((staEntityDTOs) => {
                return staEntityDTOs.map((dto) => this.staSerializer.deserializeSTAEntity(dto));
            }),
            catchError(() => {
                throw new StaDomainIndexException();
            }),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
            catchError(() => {
                return of([]);
            }),
        );
    }

    getProfileMatches(pageParams: PageParams, campaigns?: string[]): Observable<STAProfileMatches> {
        let matchesTotal = 0;
        return this.staApi.getProfileMatches(this.paginatorService.offsetLimit(pageParams), campaigns).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.staSerializer.deserializeSTAProfileMatch(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        matches: results.map((result) => {
                            if (campaigns?.length > 0) {
                                result.highlight.campaigns = campaigns;
                            }
                            return result;
                        }),
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { matches: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new StaDomainMatchingException();
            }),
        );
    }

    getSuggestedSkills(id: string): Observable<STASuggestedSkills> {
        return this.staApi.getSuggestedSkills(id).pipe(
            catchError((error) => of({ aType: [], lType: [], sType: [] })),
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
        );
    }

    getAppliedToEntities(): Observable<STAEntity[]> {
        return this.staApi.getAppliedToEntities().pipe(
            map((staEntityDTOs) =>
                staEntityDTOs.map((dto) => {
                    return this.staSerializer.deserializeSTAEntity(dto);
                }),
            ),
            catchError(() => {
                throw new StaDomainIndexException();
            }),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    getApplicationsSelections(entityId: string): Observable<STAApplicationsSelections> {
        return this.staApi.getApplicationsSelections(entityId).pipe(
            map((dto) => dto),
            catchError(() => {
                throw new StaDomainIndexException();
            }),
        );
    }

    getUnseenApplications(): Observable<STAApplicationIndicators> {
        return this.staApi.getUnseenApplications().pipe(
            map((dto) => this.staSerializer.deserializeIndicators(dto)),
            catchError(() => {
                throw new StaDomainIndexException();
            }),
        );
    }

    markTopicLinkApplicationsAsSeen(): Observable<unknown> {
        return this.staApi.markTopicLinkApplicationsAsSeen().pipe(
            catchError(() => {
                throw new StaDomainIndexException();
            }),
        );
    }

    markOfferingTabApplicationsAsSeen(): Observable<unknown> {
        return this.staApi.markOfferingTabApplicationsAsSeen().pipe(
            catchError(() => {
                throw new StaDomainIndexException();
            }),
        );
    }

    markApplicationsAsSeen(entityId: string): Observable<unknown> {
        return this.staApi.markApplicationsAsSeen(entityId).pipe(
            catchError(() => {
                throw new StaDomainIndexException();
            }),
        );
    }

    searchProfiles(entityId: string, searchTerms: string[]): Observable<STAProfileSearchResult[]> {
        return this.staApi
            .searchProfiles(entityId, searchTerms)
            .pipe(
                map((searchResultsDTOs) =>
                    searchResultsDTOs.map((dto) => this.staSerializer.deserializeSTAProfileSearchResult(dto)),
                ),
            );
    }

    searchTopicUserByName(searchQuery: string, entityId: string): Observable<Partial<GeneralData>[]> {
        return this.staApi
            .searchTopicUserByName(searchQuery, entityId)
            .pipe(
                map((searchResultsDTOs) =>
                    searchResultsDTOs.map((dto) => this.staSerializer.deserializeSTAUserSearchResult(dto)),
                ),
            );
    }

    sendInvitation(invitationMessage: STAProfileInvitationMessage): Observable<void> {
        const dto = this.staSerializer.serializeSTAProfileInvitationMessage(invitationMessage);
        return this.staApi.sendInvitation(dto);
    }

    private hydrateEntity(entity: STAEntity): Observable<STAEntity> {
        const organizer$ = this.getOrganizerData(entity).pipe(catchError(() => of(null)));
        const applications$ = this.getApplicationProfiles(entity);

        return forkJoin({ organizerData: organizer$, applications: applications$ }).pipe(
            map((hydrationData) => ({ ...entity, ...hydrationData })),
        );
    }

    private getOrganizerData(entity: STAEntity): Observable<OthersAccount> {
        if (entity.organizerData) {
            return of(entity.organizerData);
        }

        return this.accountService.getAccountById(entity.organizers[0].id);
    }

    private getApplicationProfiles(entity: STAEntity): Observable<STAApplication[]> {
        if (entity.applications && entity.applications.length > 0) {
            return forkJoin(entity.applications.map((application) => this.hydrateApplication(application)));
        } else {
            return of([]);
        }
    }

    private hydrateApplication(application: STAApplication): Observable<STAApplication> {
        return this.loadProfileById(application.userId).pipe(
            map((profile) => {
                return { ...application, profile };
            }),
            catchError(() => of(null)),
        );
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new StaProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new StaMyProfileNotFoundException();
                    } else {
                        throw new StaOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new StaUnexpectedException(error);
        }
    }

    private handleEntityError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new StaEntityBadRequestException(error.error);
                case 403:
                    throw new StaEntityForbiddenException();
                case 404:
                    this.entityNotFoundService.displayDialog('/short-time-assignments/search');
                    throw new StaEntityNotFoundException();
                default:
                    throw error;
            }
        } else {
            throw new StaUnexpectedException(error);
        }
    }

    private handleEntityApplicationsError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new StaEntityApplicationBadRequestException();
                case 403:
                    throw new StaEntityApplicationForbiddenException();
                case 404:
                    throw new StaEntityApplicationNotFoundException();
                default:
                    throw new StaUnexpectedException(error.error);
            }
        } else {
            throw new StaUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
