import { Component, Input } from '@angular/core';

@Component({
    selector: 'tp-card-facts',
    templateUrl: './card-facts.component.html',
    host: {
        class: 'card__facts',
    },
})
export class CardFactsComponent {
    @Input() location: string;
    @Input() start: Date;
    @Input() end: Date;
    @Input() languages: string[];
    @Input() deadline: Date;
    @Input() tagsToHighlight: string[];
    @Input() company: string;
    @Input() showCampaigns: boolean;
    @Input() campaigns: string[];
    @Input() campaignsToHighlight: string[];

    @Input() startDateFormat = 'mediumDate';
    @Input() endDateFormat = 'mediumDate';
}
