import { createAction, props } from '@ngrx/store';
import { NLABookmark, NLAMatch, NLAProfile, NLASearchResult, NLASuggestedSkills } from './nla-service/nla.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const NLAActions = {
    featureInit: createAction('[NLA] Init'),

    loadProfile: createAction('[NLA] Load'),
    loadProfileSuccess: createAction('[NLA][Success] Load', props<{ profile: NLAProfile; session: NLAProfile }>()),
    loadProfileError: createAction('[NLA][Error] Load', props<{ error: Error; session: NLAProfile }>()),

    loadOtherProfile: createAction('[NLA Other] Load'),
    loadOtherProfileSuccess: createAction('[NLA Other][Success] Load'),
    loadOtherProfileError: createAction('[NLA Other][Error] Load'),

    editCurrentProfile: createAction('[NLA] Change edit profile', props<{ currentProfile: Partial<NLAProfile> }>()),
    cancelCurrentProfileEdit: createAction('[NLA] Cancel edition'),

    saveProfile: createAction('[NLA] Save profile'),
    saveProfileSuccess: createAction('[NLA][Success] Save profile', props<{ currentProfile: NLAProfile }>()),
    saveProfileError: createAction('[NLA][Error] Save profile', props<{ currentProfile: NLAProfile; error: Error }>()),

    loadMatches: createAction('[NLA] Load Matches', props<{ pageParams: PageParams }>()),
    loadMatchesSuccess: createAction(
        '[NLA][Success] Load Matches',
        props<{ results: NLAMatch[]; allResults: NLAMatch[]; length: number; pageParams: PageParams }>(),
    ),
    loadMatchesError: createAction('[NLA][Error] Load Matches', props<{ error: Error }>()),

    loadBookmarks: createAction('[NLA] Load Bookmarks'),
    loadBookmarksSuccess: createAction('[NLA][Success] Load Bookmarks', props<{ bookmarks: NLABookmark[] }>()),
    loadBookmarksError: createAction('[NLA][Error] Load Bookmarks', props<{ error: Error }>()),

    search: createAction('[NLA] Search entities', props<{ searchQuery: string; pageParams: PageParams }>()),
    searchSuccess: createAction(
        '[NLA][Success] Search entities',
        props<{ results: NLASearchResult[]; allResults: NLASearchResult[]; pageParams: PageParams }>(),
    ),
    searchError: createAction('[NLA][Error] Search entities', props<{ error: Error }>()),

    loadSuggestedSkills: createAction('[NLA] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[NLA][Success] Load Suggested Skills',
        props<{ suggestedSkills: NLASuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[NLA][Error] Load Suggested Skills', props<{ error: Error }>()),

    loadSearchResult: createAction('[NLA] Load search result', props<{ searchQuery: string; index: number }>()),
    loadSearchResultSuccess: createAction(
        '[NLA] [Success] Load search result',
        props<{ data: NLASearchResult; index: number }>(),
    ),
    loadMatchResult: createAction('[NLA] Load match result', props<{ index: number }>()),
    loadMatchResultSuccess: createAction(
        '[NLA] [Success] Load match result',
        props<{ data: NLAMatch; index: number }>(),
    ),
    loadResultError: createAction('[NLA] [Error] Load result', props<{ error: Error }>()),
};
