import { ErrorHandler, Injectable } from '@angular/core';
import { AccountState, isAccountSyncCompleted, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    isJobXProfileInDirtyState,
    isJobXProfileLoaded,
    isJobXProfileLoadedOrLoading,
    selectCurrentJobXProfile,
} from './jobx.selectors';
import { JobsActions } from './jobs.actions';
import { JobXProfile } from './jobs.types';
import { JobXService } from './jobx.service';
import { JobXOthersProfileNotFoundException, JobXUnexpectedException } from './jobx.exceptions';
import { JobXState } from './jobXReducer';

@Injectable({
    providedIn: 'root',
})
export class JobXTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ jobx: JobXState; account: AccountState }>,
        private readonly jobXService: JobXService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(JobsActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<JobXProfile> {
        this.store
            .select(isJobXProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(JobsActions.loadProfile());
                }
            });

        return this.store.select(isJobXProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentJobXProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        this.store.dispatch(JobsActions.loadOthersProfile());
        return this.jobXService.loadProfileById(id).pipe(
            catchError((error) => {
                if (error instanceof JobXOthersProfileNotFoundException || error instanceof JobXUnexpectedException) {
                    this.errorHandler.handleError(error);
                }
                this.store.dispatch(JobsActions.loadOthersProfileError());
                return of(null);
            }),
            tap(() => this.store.dispatch(JobsActions.loadOthersProfileSuccess())),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isJobXProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }

    onProfileSync() {
        this.store.dispatch(JobsActions.syncProfileSkills());
        return this.waitOnSync();
    }

    private waitOnSync(): Observable<void> {
        return this.store.select(isAccountSyncCompleted).pipe(
            filter((loaded) => !loaded),
            map(() => undefined),
            take(1),
        );
    }
}
