import { ErrorHandler, Injectable } from '@angular/core';
import { AccountState, TopicEventHandlers } from '@tploy-enterprise/tenant-core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { NLAActions } from './nla.actions';
import { NLAState } from './nla.reducer';
import { NLAService } from './nla-service/nla.service';
import { NLAProfile } from './nla-service/nla.types';
import {
    isNLAProfileInDirtyState,
    isNLAProfileLoaded,
    isNLAProfileLoadedOrLoading,
    selectCurrentNLAProfile,
} from './nla.selectors';
import { NlaOthersProfileNotFoundException, NlaUnexpectedException } from './nla-service/nla.exceptions';

@Injectable({
    providedIn: 'root',
})
export class NlaTopicEventHandlerService implements TopicEventHandlers {
    constructor(
        private readonly store: Store<{ neverLunchAlone: NLAState; account: AccountState }>,
        private readonly nlaService: NLAService,
        private readonly errorHandler: ErrorHandler,
    ) {}

    onProfileSave() {
        this.store.dispatch(NLAActions.saveProfile());
        return this.waitOnPristine();
    }

    onOwnProfileLoad(): Observable<NLAProfile> {
        this.store
            .select(isNLAProfileLoadedOrLoading)
            .pipe(take(1))
            .subscribe((loadedOrLoading) => {
                if (!loadedOrLoading) {
                    this.store.dispatch(NLAActions.loadProfile());
                }
            });

        return this.store.select(isNLAProfileLoaded).pipe(
            filter((loaded) => !!loaded),
            switchMap(() => this.store.select(selectCurrentNLAProfile)),
            take(1),
        );
    }

    onOthersProfileLoad(id: string) {
        return this.nlaService.loadProfileById(id).pipe(
            catchError((error) => {
                if (error instanceof NlaOthersProfileNotFoundException || error instanceof NlaUnexpectedException) {
                    this.errorHandler.handleError(error);
                }
                return of(null);
            }),
            take(1),
        );
    }

    private waitOnPristine(): Observable<void> {
        return this.store.select(isNLAProfileInDirtyState).pipe(
            filter((dirty) => !dirty),
            map(() => undefined),
            take(1),
        );
    }
}
