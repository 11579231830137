import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
    MentoringAccountListItem,
    MentoringBookmark,
    MentoringContext,
    MentoringMatch,
    MentoringMatches,
    MentoringProfile,
    MentoringSearchResult,
    MentoringSearchResults,
    MentoringSuggestedSkills,
    OthersMentoringProfile,
} from './mentoring.types';
import { MentoringSerializer } from './mentoring.serializer';
import { MentoringApi } from '../mentoring-api/mentoring-api';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
    MentoringDomainBookmarksException,
    MentoringDomainMatchingException,
    MentoringDomainSearchException,
    MentoringMyProfileNotFoundException,
    MentoringOthersProfileNotFoundException,
    MentoringProfileBadRequestException,
    MentoringUnexpectedException,
} from './mentoring.exceptions';
import {
    BookmarksApi,
    BookmarksService,
    GeneralData,
    PageParams,
    PaginatorService,
} from '@tploy-enterprise/tenant-core';
import { MentoringType } from '@tandemploy/common';

@Injectable({
    providedIn: 'root',
})
export class MentoringService {
    constructor(
        private readonly mentoringApi: MentoringApi,
        private readonly bookmarksApi: BookmarksApi,
        private readonly bookmarksService: BookmarksService,
        private readonly mentoringSerializer: MentoringSerializer,
        private readonly paginatorService: PaginatorService,
    ) {}

    loadProfile(): Observable<MentoringProfile> {
        return this.mentoringApi.getProfile().pipe(
            map((dto) => this.mentoringSerializer.deserializeMentoringProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    loadProfileById(id: string): Observable<OthersMentoringProfile> {
        return this.mentoringApi.getProfileById(id).pipe(
            map((dto) => this.mentoringSerializer.deserializeOthersMentoringProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    getSuggestedSkills(id: string): Observable<MentoringSuggestedSkills> {
        return this.mentoringApi.getSuggestedSkills(id).pipe(
            switchMap((suggestedSkills) => {
                if (suggestedSkills && suggestedSkills.aType) {
                    return of(suggestedSkills);
                } else {
                    return of({ aType: [], lType: [], sType: [] });
                }
            }),
            catchError((error) => of({ aType: [], lType: [], sType: [] })),
        );
    }

    saveProfile(profile: MentoringProfile, general: GeneralData): Observable<MentoringProfile> {
        const dto = this.mentoringSerializer.serializeMentoringProfile(profile, general);
        return this.mentoringApi.putProfile(dto).pipe(
            map((dto) => this.mentoringSerializer.deserializeMentoringProfile(dto)),
            catchError((error: Error) => this.handleProfileError(error)),
        );
    }

    getProfileMatches(
        context: MentoringContext,
        pageParams: PageParams,
        campaigns?: string[],
    ): Observable<MentoringMatches> {
        return this.mentoringApi
            .getMatches(this.contextToMotivation(context), this.paginatorService.offsetLimit(pageParams), campaigns)
            .pipe(
                tap((response) => (pageParams.length = response.totalCount)),
                map((response) => response.results),
                map((matches) => matches.map((dto) => this.mentoringSerializer.deserializeMatch(dto))),
                catchError(() => {
                    throw new MentoringDomainMatchingException();
                }),
                switchMap((matches) => {
                    if (matches.length > 0) {
                        const hydratedResults = matches.map((result) =>
                            this.hydrateMatchAccountListItem(result, result.userId),
                        );
                        return forkJoin(hydratedResults).pipe(
                            map((hydratedResults) => ({
                                hydratedResults,
                                mergedResults: matches.map(
                                    (i) => hydratedResults.find((j) => j.userId === i.userId) || i,
                                ),
                            })),
                            map(({ hydratedResults, mergedResults }) => {
                                return {
                                    matches: hydratedResults.filter((hydratedResult) => {
                                        if (campaigns?.length > 0) {
                                            hydratedResult.highlight.campaigns = campaigns;
                                        }
                                        return !!hydratedResult.profile;
                                    }),
                                    allMatches: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                    pageParams,
                                };
                            }),
                        );
                    } else {
                        return of({
                            matches: [],
                            allMatches: [],
                            pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
                        });
                    }
                }),
            );
    }

    getBookmarks(context: MentoringContext): Observable<MentoringBookmark[]> {
        return this.bookmarksService.loadBookmarksByContext(context).pipe(
            // Safeguard: makes sure we don't fire more than 20 loading requests.
            map((bookmarks) => bookmarks.slice(0, 20)),
            map((bookmarks) => bookmarks.map((dto) => this.mentoringSerializer.deserializeBookmark(dto))),
            catchError(() => {
                throw new MentoringDomainBookmarksException();
            }),
            switchMap((bookmarks) => {
                if (bookmarks.length > 0) {
                    return forkJoin(bookmarks.map((dto) => this.hydrateAccountListItem(dto, dto.entityId)));
                } else {
                    return of([]);
                }
            }),
            map((bookmarks) => bookmarks.filter((bookmark) => !!bookmark.profile)),
        );
    }

    search(
        searchQuery: string,
        context: MentoringContext,
        pageParams: PageParams,
        campaigns?: string[],
    ): Observable<MentoringSearchResults> {
        return this.mentoringApi
            .search(
                searchQuery,
                this.contextToMotivation(context),
                this.paginatorService.offsetLimit(pageParams),
                campaigns,
            )
            .pipe(
                tap((response) => (pageParams.length = response.totalCount)),
                map((response) => response.results),
                map((results) => results.map((dto) => this.mentoringSerializer.deserializeSearchResult(dto))),
                switchMap((results) => {
                    if (results.length > 0) {
                        const hydratedResults = results.map((result) =>
                            this.hydrateSearchAccountListItem(result, result.userId),
                        );
                        return forkJoin(hydratedResults).pipe(
                            map((hydratedResults) => ({
                                hydratedResults,
                                mergedResults: results.map(
                                    (i) => hydratedResults.find((j) => j.userId === i.userId) || i,
                                ),
                            })),
                            map(({ hydratedResults, mergedResults }) => {
                                return {
                                    results: hydratedResults.filter((hydratedResult) => {
                                        if (campaigns?.length > 0) {
                                            hydratedResult.highlight.campaigns = campaigns;
                                        }
                                        return !!hydratedResult.profile;
                                    }),
                                    allResults: this.paginatorService.fillAllPagesArray(mergedResults, pageParams),
                                    pageParams,
                                };
                            }),
                        );
                    } else {
                        return of({
                            results: [],
                            allResults: [],
                            pageParams: { pageIndex: 0, pageSize: 0, length: 0 },
                        });
                    }
                }),
                catchError(() => {
                    throw new MentoringDomainSearchException();
                }),
            );
    }

    loadSearchResult(searchQuery: string, context: MentoringContext, index: number): Observable<MentoringSearchResult> {
        return this.mentoringApi
            .search(searchQuery, this.contextToMotivation(context), { offset: String(index), limit: String(1) })
            .pipe(
                map((response) => response.results),
                map((results) =>
                    results.length > 0 ? this.mentoringSerializer.deserializeSearchResult(results[0]) : null,
                ),
                catchError(() => {
                    throw new MentoringDomainSearchException();
                }),
            );
    }

    loadMatchResult(context: MentoringContext, index: number): Observable<MentoringMatch> {
        return this.mentoringApi
            .getMatches(this.contextToMotivation(context), { offset: String(index), limit: String(1) })
            .pipe(
                map((response) => response.results),
                map((results) => (results.length > 0 ? this.mentoringSerializer.deserializeMatch(results[0]) : null)),
                catchError(() => {
                    throw new MentoringDomainSearchException();
                }),
            );
    }

    hydrateMatchAccountListItem(result: MentoringMatch, accountId: string): Observable<MentoringMatch> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));

        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateSearchAccountListItem(result: MentoringSearchResult, accountId: string): Observable<MentoringSearchResult> {
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));

        return forkJoin({ bookmark: bookmark$ }).pipe(map((hydrationData) => ({ ...result, ...hydrationData })));
    }

    hydrateAccountListItem(result: MentoringAccountListItem, accountId: string): Observable<MentoringAccountListItem> {
        const profile$ = this.loadProfileById(accountId).pipe(catchError(() => of(null)));
        const bookmark$ = this.bookmarksApi.getBookmark(accountId).pipe(take(1));

        return forkJoin({ profile: profile$, bookmark: bookmark$ }).pipe(
            map((hydrationData) => ({ ...result, ...hydrationData })),
        );
    }

    private contextToMotivation(context: MentoringContext): MentoringType {
        switch (context) {
            case MentoringContext.MENTORING:
                return null;
            case MentoringContext.MENTORING_MENTEE:
                return MentoringType.MENTEE;
            case MentoringContext.MENTORING_MENTOR:
                return MentoringType.MENTOR;
            default:
                return null;
        }
    }

    private handleProfileError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new MentoringProfileBadRequestException(error.error);
                case 404:
                    if (error.url.search(RegExp(/\/me$/))) {
                        throw new MentoringMyProfileNotFoundException();
                    } else {
                        throw new MentoringOthersProfileNotFoundException();
                    }

                default:
                    throw error;
            }
        } else {
            throw new MentoringUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
