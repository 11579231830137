import { Component, Input, ViewChild } from '@angular/core';
import { PopoverDirective } from '@tploy-enterprise/tenant-common';
import { GenerationExchangeProfileTileLocationIndependentPopoverComponent } from './generation-exchange-profile-tile-location-independent-popover.component';

export interface NLAProfileTileLocationIndependentPopoverData {
    matched: boolean;
}

@Component({
    selector: 'tp-generation-exchange-profile-tile-location-independent',
    templateUrl: './generation-exchange-profile-tile-location-independent.component.html',
})
export class GenerationExchangeProfileTileLocationIndependentComponent {
    @Input()
    matched = false;

    popoverContent = GenerationExchangeProfileTileLocationIndependentPopoverComponent;
    @ViewChild(PopoverDirective) popover: PopoverDirective;

    get popoverData(): NLAProfileTileLocationIndependentPopoverData {
        return {
            matched: this.matched,
        };
    }

    get popoverClass(): string {
        const popoverClass = 'nla-location-independent-popover';
        return this.matched ? `${popoverClass} matched` : popoverClass;
    }
}
