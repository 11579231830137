import {
    Component,
    ChangeDetectionStrategy,
    forwardRef,
    ChangeDetectorRef,
    Input,
    Inject,
    Output,
    EventEmitter,
    HostBinding,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AVATAR_PLACEHOLDERS } from './image-picker.di';
import { AvatarImagePickerDialogComponent } from './dialog/avatar-image-picker-dialog/avatar-image-picker-dialog.component';
import { ImagePickerDialogComponent } from './dialog/image-picker-dialog/image-picker-dialog.component';

@Component({
    selector: 'tp-image-picker',
    templateUrl: './image-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImagePickerComponent),
            multi: true,
        },
    ],
    host: {
        class: 'image-picker',
    },
})
export class ImagePickerComponent implements ControlValueAccessor {
    formControlChange: (value: string) => void;
    onTouched: () => void;

    @Input()
    value: string;

    @Input()
    pickRandomPlaceholderIfNullValue = true;

    @Input()
    withAvatars = true;

    @Input()
    square = false;

    @HostBinding('class.disabled')
    _disabled = false;

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        this.tabindex = value ? -1 : 0;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    tabindex = 0;

    @Output()
    openedDialog = new EventEmitter<boolean>();

    @Output()
    change = new EventEmitter<string>();

    constructor(
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        @Inject(AVATAR_PLACEHOLDERS) private placeholders,
    ) {}

    onChange(value: string) {
        if (this.formControlChange) {
            this.formControlChange(value);
        }

        this.change.emit(value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(onChangeFn): void {
        this.formControlChange = onChangeFn;
        if (!this.value && this.pickRandomPlaceholderIfNullValue && this.withAvatars) {
            this.pickRandomPlaceholder();
        }
    }

    registerOnTouched(onTouchedFn): void {
        this.onTouched = onTouchedFn;
    }

    openImagePickerDialog(event: MouseEvent): void {
        if (this.disabled) {
            return;
        }
        let dialogRef: MatDialogRef<AvatarImagePickerDialogComponent | ImagePickerDialogComponent, any>;
        if (this.withAvatars) {
            dialogRef = this.dialog.open(AvatarImagePickerDialogComponent, {
                data: this.value || null,
                autoFocus: true,
            });
        } else {
            dialogRef = this.dialog.open(ImagePickerDialogComponent, {
                data: this.value || null,
                autoFocus: true,
            });
        }
        this.openedDialog.emit(true);

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((value: string) => {
                this.openedDialog.emit(false);
                if (value) {
                    this.setNewPicture(value);
                }
            });
    }

    private setNewPicture(picture: string): void {
        this.value = picture;
        this.onChange(picture);
        this.cd.markForCheck();
    }

    private pickRandomPlaceholder(): void {
        const index = Math.floor(Math.random() * this.placeholders.length);
        return this.setNewPicture(this.placeholders[index]);
    }
}
