import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { WorkzModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.environment !== 'development') {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(WorkzModule)
    .catch((err) => console.error(err));
