import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthenticationActions } from './authentication.actions';
import { AuthenticationEndpoints } from './constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlatformUserRolesDTO } from '../../core-pages/administration/platform-administration/platform-administration.types';

const SERVICE_PATH = '/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private readonly store: Store<unknown>, private readonly http: HttpClient) {}

    checkAuthentication(): boolean {
        this.store.dispatch(AuthenticationActions.verified({ authenticated: true }));
        return true;
    }

    authenticate(): void {
        window.location.assign(AuthenticationEndpoints.SignIn);
    }

    loadPlatformUserRoles(): Observable<Array<PlatformUserRolesDTO>> {
        return this.http.get<Array<PlatformUserRolesDTO>>(`${SERVICE_PATH}/user-roles`);
    }

    updatePlatformUserRoles(userRoles: PlatformUserRolesDTO): Observable<PlatformUserRolesDTO> {
        return this.http.post<PlatformUserRolesDTO>(`${SERVICE_PATH}/user-roles`, userRoles);
    }
}
