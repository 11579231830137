import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeImage',
})
export class SafeImagePipe implements PipeTransform {
    constructor(private readonly domSanitizer: DomSanitizer) {}

    transform(value: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
