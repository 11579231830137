import { createAction, props } from '@ngrx/store';
import {
    MentoringBookmark,
    MentoringContext,
    MentoringMatch,
    MentoringProfile,
    MentoringSearchResult,
    MentoringSuggestedSkills,
} from './mentoring-service/mentoring.types';
import { PageParams } from '@tploy-enterprise/tenant-core';

export const MentoringActions = {
    featureInit: createAction('[Mentoring] Init'),

    loadProfile: createAction('[Mentoring] Load'),
    loadProfileSuccess: createAction(
        '[Mentoring][Success] Load',
        props<{ profile: MentoringProfile; session: MentoringProfile }>(),
    ),
    loadProfileError: createAction('[Mentoring][Error] Load', props<{ error: Error; session: MentoringProfile }>()),

    editCurrentProfile: createAction(
        '[Mentoring] Change edit profile',
        props<{ currentProfile: Partial<MentoringProfile> }>(),
    ),
    cancelCurrentProfileEdit: createAction('[Mentoring] Cancel edition'),

    saveProfile: createAction('[Mentoring] Save profile'),
    saveProfileSuccess: createAction(
        '[Mentoring][Success] Save profile',
        props<{ currentProfile: MentoringProfile }>(),
    ),
    saveProfileError: createAction(
        '[Mentoring][Error] Save profile',
        props<{ currentProfile: MentoringProfile; error: Error }>(),
    ),

    loadSuggestedSkills: createAction('[Mentoring] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[Mentoring][Success] Load Suggested Skills',
        props<{ suggestedSkills: MentoringSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[Mentoring][Error] Load Suggested Skills', props<{ error: Error }>()),

    loadMatches: createAction(
        '[Mentoring] Load Matches',
        props<{ context: MentoringContext; pageParams: PageParams; campaigns?: string[] }>(),
    ),
    loadMatchesSuccess: createAction(
        '[Mentoring][Success] Load Matches',
        props<{
            matches: MentoringMatch[];
            allMatches: MentoringMatch[];
            pageParams: PageParams;
            selectedCampaigns?: string[];
        }>(),
    ),
    loadMatchesError: createAction('[Mentoring][Error] Load Matches', props<{ error: Error }>()),

    loadBookmarks: createAction('[Mentoring] Load Bookmarks', props<{ context: MentoringContext }>()),
    loadBookmarksSuccess: createAction(
        '[Mentoring][Success] Load Bookmarks',
        props<{ bookmarks: MentoringBookmark[] }>(),
    ),
    loadBookmarksError: createAction('[Mentoring][Error] Load Bookmarks', props<{ error: Error }>()),

    search: createAction(
        '[Mentoring] Search',
        props<{ searchQuery: string; context: MentoringContext; pageParams: PageParams; campaigns?: string[] }>(),
    ),
    searchSuccess: createAction(
        '[Mentoring][Success] Search',
        props<{ results: MentoringSearchResult[]; allResults: MentoringSearchResult[]; pageParams: PageParams }>(),
    ),
    searchError: createAction('[Mentoring][Error] Search', props<{ error: Error }>()),

    changeContext: createAction('[Mentoring][Change] Context', props<{ context: MentoringContext }>()),

    loadSearchResult: createAction(
        '[Mentoring] Load search result',
        props<{ searchQuery: string; context: MentoringContext; index: number }>(),
    ),
    loadSearchResultSuccess: createAction(
        '[Mentoring] [Success] Load search result',
        props<{ data: MentoringSearchResult; index: number }>(),
    ),
    loadMatchResult: createAction(
        '[Mentoring] Load match result',
        props<{ context: MentoringContext; index: number }>(),
    ),
    loadMatchResultSuccess: createAction(
        '[Mentoring] [Success] Load match result',
        props<{ data: MentoringMatch; index: number }>(),
    ),
    loadResultError: createAction('[Mentoring] [Error] Load result', props<{ error: Error }>()),
};
