import { AccountState } from './account.reducer';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';
import { UserRole } from '@tandemploy/common';

export function selectUserRoles(state: { account: AccountState }): Array<UserRole> {
    return state.account.roles;
}

export function selectCurrentUserId(state: { account: AccountState }): string {
    return state.account.userId;
}

export function isAccountSyncCompleted(state: { account: AccountState }): boolean {
    return state.account.syncStatus.completed;
}
export function isAccountSyncEnabled(state: { account: AccountState }): boolean {
    return state.account.generalData.current.data.enableSync;
}
export function isContributor(state: { account: AccountState }): boolean {
    return state.account.roles.includes(UserRole.CONTRIBUTOR);
}
export function isStaffingManager(state: { account: AccountState }): boolean {
    return state.account.roles.includes(UserRole.STAFFING_MANAGER);
}

export function isCreating(state: { account: AccountState }): boolean {
    return state.account.status.creating;
}

export function suggestedSkills(state: { account: AccountState }): SuggestedSkills {
    return state.account.suggestedSkills.suggestedSkills;
}

export function suggestedSTypeSkills(state: { account: AccountState }): string[] {
    return state.account.suggestedSkills.suggestedSkills.sType?.map((sType) => sType.name) || [];
}

export function suggestedLTypeSkills(state: { account: AccountState }): string[] {
    return state.account.suggestedSkills.suggestedSkills.lType?.map((lType) => lType.name) || [];
}

export function suggestedATypeSkills(state: { account: AccountState }): string[] {
    return state.account.suggestedSkills.suggestedSkills.aType?.map((aType) => aType.name) || [];
}

export function hiddenSkillsFromSuggestion(state: { account: AccountState }): string[] {
    return state.account.hiddenSkillsFromSuggestion.skills || [];
}

export function isDeleting(state: { account: AccountState }): boolean {
    return state.account.status.deleting;
}
