import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageOptions } from '@tploy-enterprise/tenant-common';
import { Observable } from 'rxjs';

const SERVICE_PATH = '/api/v2/tenant-settings/documents';

@Injectable({
    providedIn: 'root',
})
export class TenantTemplatesService {
    constructor(private readonly httpClient: HttpClient) {}

    public loadHTML(templateName: string, lang: string): Observable<string> {
        return this.httpClient.get(`${SERVICE_PATH}/${templateName}?language=${lang}`, {
            responseType: 'text',
        });
    }
}
