import {
    Component,
    ChangeDetectionStrategy,
    Input,
    QueryList,
    ContentChildren,
    AfterContentInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { PreviewItemComponent } from './preview-item.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'tp-intro-view',
    templateUrl: './intro-view.component.html',
    styleUrls: ['./intro-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroViewComponent implements AfterContentInit, OnDestroy {
    @Input()
    maxVisiblePreviews = 3;
    public current: PreviewItemComponent;
    public introArray: Array<PreviewItemComponent>;

    previousTitle: string;
    nextTitle: string;

    private destroy$ = new Subject<void>();

    @ContentChildren(PreviewItemComponent) intros: QueryList<PreviewItemComponent>;

    public get isVideo(): boolean {
        return this.current && this.current.src.split('.').pop() === 'mp4';
    }

    constructor(private cd: ChangeDetectorRef) {}

    private setVisibility(index): void {
        if (this.introArray.indexOf(this.current) === 0) {
            this.introArray[index].isVisible =
                index >= 0 && index < Math.min(this.maxVisiblePreviews, this.intros.length);
        } else if (this.introArray.indexOf(this.current) === this.intros.length - 1) {
            this.introArray[index].isVisible = index >= this.intros.length - this.maxVisiblePreviews;
        } else {
            this.introArray[index].isVisible =
                index > this.introArray.indexOf(this.current) - Math.ceil(this.maxVisiblePreviews / 2) &&
                index < this.introArray.indexOf(this.current) + Math.ceil(this.maxVisiblePreviews / 2);
        }
    }

    public next() {
        const indexOfCurrent = this.introArray.indexOf(this.current);
        if (indexOfCurrent < this.introArray.length - 1) {
            this.selectIntro(this.introArray[indexOfCurrent + 1]);
        } else {
            this.selectIntro(this.introArray[0]);
        }
    }

    public previous() {
        const indexOfCurrent = this.introArray.indexOf(this.current);
        if (indexOfCurrent > 0) {
            this.selectIntro(this.introArray[indexOfCurrent - 1]);
        } else {
            this.selectIntro(this.introArray[this.introArray.length - 1]);
        }
    }

    private selectIntro(newCurrent: PreviewItemComponent) {
        if (this.current) {
            this.current.isSelected = false;
        }
        this.current = newCurrent;
        this.current.isSelected = true;
        this.introArray.forEach((item, index) => {
            this.setVisibility(index);
        });

        const currentIndex = this.introArray.indexOf(this.current);
        const modulo = this.introArray.length;
        if (modulo > 0) {
            const previous = this.introArray[this.mod(currentIndex - 1, modulo)];
            const next = this.introArray[this.mod(currentIndex + 1, modulo)];

            this.previousTitle = previous ? previous.title : '';
            this.nextTitle = next ? next.title : '';
        }

        this.cd.detectChanges();
    }

    // Fix modulo with negative numbers
    private mod(n: number, modulo: number): number {
        return ((n % modulo) + modulo) % modulo;
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    ngAfterContentInit() {
        this.introArray = this.intros.toArray();
        this.selectIntro(this.intros.first);

        this.intros.forEach((item) =>
            item.select.pipe(takeUntil(this.destroy$)).subscribe(() => this.selectIntro(item)),
        );
    }

    select(newCurrent: PreviewItemComponent) {
        this.selectIntro(newCurrent);
    }
}
