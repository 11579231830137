import { Injectable } from '@angular/core';
import { MentoringContext, MentoringProfile } from './mentoring.types';

@Injectable({
    providedIn: 'root',
})
export class MentoringSkillsService {
    public getProfileSkills(profile: MentoringProfile, filterContext: MentoringContext): string[] {
        switch (filterContext) {
            case MentoringContext.MENTORING:
                return [...profile.skillsOffering, ...profile.skillsInterestedIn];
            case MentoringContext.MENTORING_MENTOR:
                return profile.skillsOffering;
            case MentoringContext.MENTORING_MENTEE:
                return profile.skillsInterestedIn;
        }
        return [];
    }

    public getProfileCampaigns(profile: MentoringProfile, filterContext: MentoringContext): string[] {
        switch (filterContext) {
            case MentoringContext.MENTORING_MENTOR:
                return profile.campaignsOffering;
            case MentoringContext.MENTORING_MENTEE:
                return profile.campaignsInterest;
        }
        return [];
    }
}
