import { Component, Input } from '@angular/core';

@Component({
    selector: 'tp-details-facts',
    templateUrl: './details-facts.component.html',
    host: {
        class: 'details__facts',
    },
})
export class DetailsFactsComponent {
    @Input() location: string;
    @Input() start: Date;
    @Input() end: Date;
    @Input() languages: string[];
    @Input() deadline: Date;
    @Input() company: string;
    @Input() campaigns: string[];
    @Input() startDateFormat = 'mediumDate';
    @Input() endDateFormat = 'mediumDate';
}
