<div class="add-profile__container">
    <h2 class="add-profile__text">{{ 'ADD_PROFILE_TEXT' | translatable }}</h2>
    <footer class="add-profile__footer">
        <tp-primary-icon-button
            [icon]="'settings_filled'"
            [routerLink]="['', { outlets: { overlay: editTopicRoute } }]"
        >
            {{ 'ADD_PROFILE' | translatable }}
        </tp-primary-icon-button>
    </footer>
</div>
