import { Directive, Injector, Input, OnInit } from '@angular/core';
import { GeneralData } from '../../account';
import { Bookmark } from '../../bookmarks/bookmark.types';
import { BookmarkActions } from '../../bookmarks/bookmark.actions';
import { BookmarksState } from '../../bookmarks/bookmarks.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { WindowRef } from '../../window-ref';

@Directive()
export class AccountListItemBase implements OnInit {
    @Input() tags: string[] = [];
    @Input() selectedTags: string[] = [];
    @Input() bookmark: Bookmark;
    @Input() context: string;
    @Input() user: Partial<GeneralData>;
    @Input() profileMessage = '';

    bookmarks$: Observable<Array<Bookmark>>;
    bookmark$: Observable<Bookmark>;

    private readonly destroy$ = new Subject<void>();
    private store: Store<{ router: RouterReducerState; bookmarks: BookmarksState }>;
    private router: Router;
    private activatedRoute: ActivatedRoute;

    constructor(protected readonly injector: Injector) {
        this.store = injector.get(Store);
        this.router = injector.get(Router);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.bookmarks$ = this.store.select((state) => state.bookmarks.bookmarks);
        this.bookmarks$.pipe(takeUntil(this.destroy$)).subscribe((bookmarks) => {
            this.bookmark = bookmarks.find((b) => {
                return b.entityId === this.user.id && b.context === this.context;
            });
        });

        this.bookmark$ = this.store.select('bookmarks').pipe(
            filter((bookmarksState) => bookmarksState.status.loaded && !bookmarksState.status.loading),
            map((bookmarksState) => bookmarksState.bookmarks),
            map((bookmarks) =>
                bookmarks.find((bookmark) => bookmark.entityId === this.user.id && bookmark.context === this.context),
            ),
        );
    }

    openAccountPage(event?: MouseEvent): void {
        this.store // FIXME this shouldn't be necessary anymore if modal windows don't trigger a location change, until then: ugly hack.
            .select('router')
            .pipe(take(1))
            .subscribe((routerState) => {
                if (event && (event.ctrlKey || event.metaKey)) {
                    const urlTree = this.router.parseUrl(`/profile/${this.user.id}`);
                    urlTree.queryParams = {
                        ref: this.context,
                        src: routerState.state.url,
                        context: this.context,
                    };
                    WindowRef.open(urlTree.toString(), '_blank');
                } else {
                    this.router.navigate([
                        `/profile/${this.user.id}`,
                        {
                            ref: this.context,
                            src: routerState.state.url,
                            context: this.context,
                        },
                    ]);
                }
            });
    }

    getBookmarked() {
        return !!this.bookmark;
    }

    getTooltipMessageKey(): string {
        return this.getBookmarked() ? 'PROFILE_UNSTAR_TEXT' : 'PROFILE_STAR_TEXT';
    }

    onToggleStar($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();

        if (this.getBookmarked()) {
            this.store.dispatch(BookmarkActions.delete({ bookmark: this.bookmark, accountName: this.user.name }));
        } else {
            const bookmark = {
                context: this.context,
                entityName: 'account',
                entityId: this.user.id,
            };
            this.store.dispatch(
                BookmarkActions.add({
                    bookmark,
                    accountName: this.user.name,
                }),
            );
        }
    }
}
