import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, forkJoin, of, Subscription } from 'rxjs';
import { Account } from './account.types';
import { AccountService } from './account.service';
import { AccountActions } from './account.actions';
import { AccountState } from './account.reducer';
import { GeneralDataActions } from './general-data/general-data.actions';
import { AccountSettingsActions } from './account-settings/account-settings.actions';
import { LocaleActions } from './locale/locale.actions';
import { SelectedTopicsActions } from './selected-topics/selected-topics.actions';
import { NotificationsService } from '../notifications';
import { ExperienceActions } from './experience';
import { AuthenticationActions } from '../authentication/authentication.actions';
import { RedirectService } from '../../redirect.service';
import { TopicsDataService } from '../topic/topic-data.service';
import { SsoDataService } from './sso-data/sso-data.service';
import {
    SuccessFactorsActions,
    SuccessFactorsUserDataService,
    SuccessFactorsUtilsService,
    SuccessFactorsUserData,
    SUCCESS_FACTORS_USER_DATA_STORAGE_KEY,
} from '../success-factors';
import { AuthenticationState } from '../authentication/authentication.reducer';
import { StorageService } from '../storage/service/storage.service';
import { ConfigService } from '../config';
import { REMOVE_USER_SESSION_STORAGE_KEY } from '../authentication/constants';

@Injectable({
    providedIn: 'root',
})
export class AccountEffects {
    hiddenSkillsSubscriber: Subscription;
    hiddenSkills = [];

    constructor(
        private readonly actions$: Actions,
        private readonly accountDataService: AccountService,
        private readonly topicDataService: TopicsDataService,
        private readonly store: Store<{ account: AccountState; authentication: AuthenticationState }>,
        private readonly notifications: NotificationsService,
        private readonly storage: StorageService,
        private readonly redirect: RedirectService,
        private readonly ssoDataService: SsoDataService,
        private readonly configService: ConfigService,
        private readonly successFactorsUserDataService: SuccessFactorsUserDataService,
        private readonly successFactorsUtilsService: SuccessFactorsUtilsService,
    ) {
        this.hiddenSkillsSubscriber = this.store.select('account').subscribe((account) => {
            this.hiddenSkills = account.hiddenSkillsFromSuggestion.skills || [];
        });
    }

    get isSuccessFactorIntegrated(): boolean {
        return this.configService.remoteSettings.integrationSuccessFactor;
    }

    loadAccountData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.load),
            switchMap(() => this.accountDataService.loadCurrentUserAccount()),
            map((account) => AccountActions.loadSuccess({ account })),
            catchError((error) => of(AccountActions.loadError({ error }))),
        ),
    );

    loadSsoAccountData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.loadSsoData),
            switchMap(() => {
                if (this.isSuccessFactorIntegrated) {
                    this.store.dispatch(SuccessFactorsActions.loadSuccessFactorsUserData());
                }
                return this.ssoDataService.getAccountSSOData();
            }),
            map((account) => {
                if (this.isSuccessFactorIntegrated) {
                    return AccountActions.loadSsoDataSuccess({
                        account: {
                            ...account,
                            generalData: {
                                ...account.generalData,
                                externalSkills: [],
                                imageUrl: '',
                            },
                        },
                    });
                } else {
                    return AccountActions.loadSsoDataSuccess({ account });
                }
            }),
            catchError(() => EMPTY),
        ),
    );

    loadSuccessFactorsUserData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuccessFactorsActions.loadSuccessFactorsUserData),
            switchMap(() => this.successFactorsUserDataService.getSuccessFactorsUserData()),
            map((data: SuccessFactorsUserData) => {
                window.sessionStorage.setItem(SUCCESS_FACTORS_USER_DATA_STORAGE_KEY, JSON.stringify(data));
                return SuccessFactorsActions.loadSuccessFactorsUserDataSuccess({
                    ...data,
                    skills: this.successFactorsUtilsService.getSuccessFactorsSkills(data.skills),
                });
            }),
            catchError((error) => of(SuccessFactorsActions.loadSuccessFactorsUserDataError())),
        ),
    );

    saveAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.create),
            debounceTime(1000),
            withLatestFrom(this.store.select((state) => state.account)),
            map(([action, account]) => this.readAccountFromEditState(account)),
            switchMap((account) => {
                window.sessionStorage.removeItem(SUCCESS_FACTORS_USER_DATA_STORAGE_KEY);
                return this.topicDataService.saveTopicsData(account.selectedTopics).pipe(map(() => account));
            }),
            switchMap((account: Account) =>
                this.accountDataService.save(account).pipe(
                    switchMap(() => this.accountDataService.loadCurrentUserAccount()),
                    map((account) => AccountActions.createSuccess({ account })),
                    catchError((error) => of(AccountActions.createError({ error }))),
                ),
            ),
        ),
    );

    saveLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocaleActions.change),
            debounceTime(1000),
            withLatestFrom(this.store.select((state) => state.authentication.authenticated)),
            filter(([, authenticated]) => authenticated),
            map(([action]) => action),
            withLatestFrom(this.store.select((state) => state.account.locale.saved)),
            filter(([, savedLocale]) => !!savedLocale),
            switchMap(([action, savedLocale]) =>
                forkJoin([
                    this.store.select((state) => this.readAccountFromCurrentState(state.account)).pipe(take(1)),
                    of({ oldLocale: savedLocale, newLocale: action.locale }),
                ]),
            ),
            switchMap(([account, { oldLocale, newLocale }]) =>
                this.accountDataService.save({ ...account, locale: newLocale }).pipe(
                    map(() => LocaleActions.saveSuccess({ locale: newLocale })),
                    catchError((error) => of(LocaleActions.saveError({ error, locale: oldLocale }))),
                ),
            ),
        ),
    );

    dispatchSaveSuccesses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.createSuccess),
            map((action) => action.account),
            switchMap((account) =>
                of(
                    GeneralDataActions.saveSucces({ generalData: account.generalData }),
                    AccountSettingsActions.saveSuccess({ accountSettings: account.settings }),
                    LocaleActions.saveSuccess({ locale: account.locale }),
                    SelectedTopicsActions.saveSuccess({ selectedTopics: account.selectedTopics }),
                    ExperienceActions.saveSuccess({ experienceData: account.experience }),
                ),
            ),
        ),
    );

    navigateAfterCreateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AccountActions.createSuccess),
                tap(() => this.redirect.redirect(this.redirect.getRedirect('authenticated', false, {}))),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    deleteAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.delete),
            switchMap(() => this.accountDataService.delete()),
            map(() => AccountActions.deleteSuccess()),
            catchError((error) => of(AccountActions.deleteError({ error }))),
        ),
    );

    deleteAccountSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.deleteSuccess),
            tap(() => this.notifications.openImportantSnack('DELETE_SUCCESS_MESSAGE')),
            map(() => {
                this.storage.session.set(REMOVE_USER_SESSION_STORAGE_KEY, true);
                return AuthenticationActions.signOut();
            }),
        ),
    );

    syncAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.sync),
            switchMap((action) => this.accountDataService.sync(action.skills)),
            map(() => AccountActions.syncSuccess()),
            catchError((error) => of(AccountActions.syncError({ error }))),
        ),
    );

    hideSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.hideSkills),
            switchMap((action) => {
                const skills = [
                    ...this.hiddenSkills,
                    ...action.skills.filter((skill) => !this.hiddenSkills.includes(skill)),
                ];
                return this.accountDataService.hideSkills(skills).pipe(
                    map(() => AccountActions.hideSkillsSuccess({ skills })),
                    catchError((error) => of(AccountActions.hideSkillsError({ error }))),
                );
            }),
        ),
    );

    recoverSkills$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.recoverSkills),
            switchMap((action) => {
                const skills = this.hiddenSkills.filter((skill) => !action.skills.includes(skill));
                return this.accountDataService.recoverSkills(skills).pipe(
                    map(() => AccountActions.recoverSkillsSuccess({ skills })),
                    catchError((error) => of(AccountActions.recoverSkillsError({ error }))),
                );
            }),
        ),
    );

    private readAccountFromEditState(state: AccountState): Account {
        return {
            userId: null,
            roles: state.roles,
            generalData: state.generalData.edit.data,
            locale: state.locale.used,
            settings: state.settings.edit.data,
            selectedTopics: state.selectedTopics.edit.data,
            experience: state.experience.edit.data,
            questionnaire: state.questionnaire.edit.data,
            completionScore: state.completionScore,
        };
    }

    private readAccountFromCurrentState(state: AccountState): Account {
        return {
            userId: null,
            roles: state.roles,
            locale: state.locale.used,
            generalData: state.generalData.current.data,
            settings: state.settings.current.data,
            selectedTopics: state.selectedTopics.current.data,
            experience: state.experience.current.data,
            questionnaire: state.questionnaire.current.data,
            completionScore: state.completionScore,
        };
    }
}
