import { Component, Input } from '@angular/core';
import { TopicProfileView } from '@tploy-enterprise/tenant-core';
import { NLAProfile } from '../../nla-service/nla.types';
import { Observable } from 'rxjs';
import { LocationService } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-profile-view',
    templateUrl: './nla-profile-view.component.html',
})
export class NLAProfileViewComponent implements TopicProfileView {
    _topicData: NLAProfile;

    @Input('topicData')
    set topicData(nlaProfile) {
        this._topicData = nlaProfile;
    }

    get topicData() {
        return this._topicData;
    }

    get locations(): Array<string> {
        return this.locationService.getLocationsOrCountry(this.topicData.companyLocations);
    }

    get locationIndependentDetail(): string {
        return this.topicData.locationIndependent
            ? 'PROFILE_NEVER_LUNCH_ALONE_OPTION_ALL'
            : 'PROFILE_NEVER_LUNCH_ALONE_OPTION_SAME_LOCATION';
    }

    get topics(): string[] {
        return this.topicData.interestedInTopics;
    }

    get dishes(): string[] {
        return this.topicData.favoriteFood;
    }

    constructor(private readonly locationService: LocationService) {}

    hasLocations(): boolean {
        return this.topicData?.companyLocations?.length > 0;
    }

    hasTopics(): boolean {
        return this.topicData?.interestedInTopics?.length > 0;
    }

    hasDishes(): boolean {
        return this.topicData?.favoriteFood?.length > 0;
    }

    hasLocationIndependent(): boolean {
        return this.topicData?.locationIndependent;
    }

    hasContent() {
        return this.hasLocations() || this.hasTopics() || this.hasDishes() || this.hasLocationIndependent();
    }
}
