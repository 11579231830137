export default {
    JOBX: 'Job Sharing',
    JOBX_INFO:
        'Job sharing is a flexible working model in which two or more employees share at least one full-time job. They work very closely together as a team and determine their working hours and tasks individually. Job sharing can also be very interesting in management positions (so-called top-sharing / co-leadership). The matching algorithm of {{ appName }} finds suitable tandem partners* among your colleagues and informs you by e-mail when new matches are available.',

    JOBX_ENTITY_CREATE: 'Create Job',
    JOBX_ENTITY_CREATE_TITLE: 'Create Job',

    JOBX_ENTITY_FORM_TITLE_LABEL: 'Job title',
    JOBX_ENTITY_FORM_END_DATE_LABEL: 'Valid until (8 weeks max.)',
    JOBX_ENTITY_FORM_LOCATION_LABEL: 'Location',
    JOBX_ENTITY_FORM_LANGUAGES_LABEL: 'Suited only for people speaking…',
    JOBX_ENTITY_FORM_DESCRIPTION_LABEL: 'Job description',
    JOBX_ENTITY_FORM_REQUESTED_SKILLS_LABEL: 'Relevant knowledge and skills (keywords)',
    JOBX_ENTITY_FORM_DESCRIPTION_ERROR: 'The description needs to be at least 50 characters long.',

    JOBX_ENTITY_CREATE_BUTTON_DRAFT: 'Save draft',
    JOBX_ENTITY_CREATE_BUTTON_PUBLISH: 'Publish job',

    JOBX_ENTITY_EDIT_BUTTON_DRAFT: 'Save draft',
    JOBX_ENTITY_EDIT_BUTTON_PUBLISH: 'Publish job',
    JOBX_ENTITY_EDIT_BUTTON_SAVE: 'Save',
    JOBX_ENTITY_EDIT_TITLE: 'Edit job',

    JOBX_OFFERED_BY_ME_COMPLETE_HEADER: 'Expired',
    JOBX_OFFERED_BY_ME_EXTEND_VALIDITY_BTN_TITLE: 'Update expiration date',
    JOBX_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Posting has expired.',

    JOBX_DOMAIN_OFFERED_BY_ME_TAB: 'Offered by me',
    JOBX_DOMAIN_SEARCH_TAB: 'Search',
    JOBX_DOMAIN_JOB_BOARD_TAB: 'Job Board',
    JOBX_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    JOBX_DOMAIN_BOOKMARKS_TAB: 'Bookmarks',

    JOBX_PUBLISH_DIALOG_TITLE: 'Publish Job',
    JOBX_PUBLISH_DIALOG_CONTENT_1: 'Suitable colleagues will be informed now!',
    JOBX_PUBLISH_DIALOG_CONTENT_2:
        'As soon as a job is published it is visible for all colleagues interested in Job Sharing. From then on you can be contacted for further information.',
    JOBX_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Publish now!',

    JOBX_OFFERED_BY_ME_CONTACT_LABEL: 'Contact',
    JOBX_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'until',
    JOBX_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'View details',
    JOBX_OFFERED_BY_ME_RUNNING_HEADER: 'Current',
    JOBX_OFFERED_BY_ME_PLANNED_HEADER: 'Planned',
    JOBX_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Un-published',
    JOBX_OFFERED_BY_ME_PUBLISH_BTN_TITLE: 'Publish',
    JOBX_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Last updated ',
    JOBX_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Delete job',
    JOBX_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Are you sure you want to delete this job?',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Create your first job',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'Du hast bisher noch keinen Job angeboten. Um einen neuen zu erstellen, lege hier einfach los.',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_JOB_BTN: 'Create Job',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Invite colleagues',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Invite colleagues to join this platform.',
    JOBX_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Invite',

    JOBX_SEARCH_HEADER: 'Search for jobs by keywords',
    JOBX_SEARCH_EMPTY_MESSAGE: 'Unfortunately nothing was found. ',
    JOBX_SEARCH_RESULT_VIEW_ENTITY: 'View details',
    JOBX_SEARCH_RESULT_AVATAR_LABEL: 'Contact',
    JOBX_SEARCH_RESULT_REQUESTED_SKILLS: 'Required skills',
    JOBX_SEARCH_BTN_TEXT: 'Search!',

    JOBX_SEARCH_CAMPAIGNS_LABEL: 'HR Program',
    JOBX_SEARCH_CAMPAIGNS_PLACEHOLDER: 'any',
    JOBX_SEARCH_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    JOBX_MATCHING_CAMPAIGNS_LABEL: 'HR Program',
    JOBX_MATCHING_CAMPAIGNS_PLACEHOLDER: 'any',
    JOBX_MATCHING_NO_CAMPAIGNS_RESULT: 'There is no result for your selected filter',

    JOBX_ADS_LABEL:
        'The jobs advertised here might not be the complete set of current job listings for available Co-leadership positions. Thus, visit also SAP Success Maps Carrer Opportunities to find suitable positions for you. <u><a href="https://performancemanager5.successfactors.eu/sf/careers/jobsearch?bplte_company=SAP&_s.crb=T3PnaFkNEtvdAT9W9XMBUv6mYSumbW3waU9NDOqt97U%253d" target="_blank">SuccessFactors: Career Opportunities</a></u>',
    JOBX_ADS_INFO:
        'Tip: As part of your search criteria select Career Status “Executive” & “Management”. If you found an interesting job listing, view the details to see if this position has been advertised as a co-leadership position.',
    JOBX_ADS_INFO_LABEL: 'Tip',

    JOBX_DETAILS_VIEW_DESCRIPTION_TITLE: 'Job description',
    JOBX_DETAILS_VIEW_SKILLS_TITLE: 'Relevant knowledge and competencies (keywords)',
    JOBX_DETAILS_VIEW_ORGANIZER_LABEL: 'Contact',

    JOBX_PROFILE_TITLE: 'Job Sharing',
    JOBX_PROFILE_SUBTITLE: 'I am looking for a Job Sharing partner and / or want to find a new Job Sharing job.',
    JOBX_PROFILE_LOCATION_LABEL: 'In the future I would like to work in',
    JOBX_PROFILE_MAIN_MOTIVATION_LABEL: 'What is your main motivation for job sharing?',
    JOBX_PROFILE_IS_CURRENTLY_IN_LEADER_POSITION_LABEL: 'I am currently working in a leadership position',
    JOBX_PROFILE_WANTS_TO_BE_IN_LEADER_POSITION_LABEL: 'Working in a leadership position in the future:',
    JOBX_PROFILE_MY_SKILLS_LABEL: 'My skills',
    JOBX_PROFILE_TANDEM_SKILLS_LABEL: 'Tandem partner has better or complementary skills in',
    JOBX_PROFILE_HOUR_SPLIT_HEADER_LABEL: 'Desired division of weekly hours',
    JOBX_PROFILE_HOUR_SPLIT_MY_HOURS_LABEL: 'My weekly hours:',
    JOBX_PROFILE_HOUR_SPLIT_TANDEM_HOURS_LABEL: 'Tandem partner:',
    JOBX_PROFILE_FORM_CURRENT_JOB_LIKES_LABEL: 'What I like most about my current job:',
    JOBX_PROFILE_FORM_CURRENT_JOB_DESCRIPTION_LABEL: 'Short description of my current job:',

    JOBX_PROFILE_NO_MATCH_IMG_ALT: 'No matches found',
    JOBX_PROFILE_NO_MATCH_CONTENT: 'Please specify more skills and (hidden?) talents in your profile to find matches',
    JOBX_PROFILE_NO_MATCH_CTA: 'Edit skills',
    JOBX_PROFILE_NO_MATCH_FIND_CONTENT: 'Find more colleagues by using the search',
    JOBX_NO_MATCH_IMG_ALT: 'No matches found',
    JOBX_PROFILE_SEARCH_HEADER: 'Search for Job Sharing profiles by keywords',

    LEADERSHIP_POSITION_YES: 'yes',
    LEADERSHIP_POSITION_FLEXIBLE: "I don't mind",
    LEADERSHIP_POSITION_NO: 'no',

    JOBX_MAIN_MOTIVATION_DESIRED_CAREER: 'Desired career without full time',
    JOBX_MAIN_MOTIVATION_TEAMWORK: 'Teamwork in a tandem',
    JOBX_MAIN_MOTIVATION_CO_LEADERSHIP: 'Co-Leadership is the better way to lead',

    JOBX_PROFILE_VIEW_LOCATION_LABEL: 'Possible working locations',
    JOBX_PROFILE_VIEW_MY_SKILLS_LABEL: 'Skills',
    JOBX_PROFILE_VIEW_TANDEM_SKILLS_LABEL: 'Desired knowledge of the partner',
    JOBX_PROFILE_VIEW_CURRENT_JOB_DESCRIPTION_LABEL: 'Short description of my job',
    JOBX_PROFILE_VIEW_CURRENT_JOB_LIKES_LABEL: 'What I like about my job',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_LABEL: 'Leadership position',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_CURRENT: 'currently',
    JOBX_PROFILE_VIEW_LEADERSHIP_POSITION_FUTURE: 'in the future',
    JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_YES: 'yes',
    JOBX_PROFILE_VIEW_LEADERSHIP_CURRENT_NO: 'no',

    JOBX_BOOKMARKS_TITLE: 'No profiles bookmarked yet',
    JOBX_BOOKMARKS_PROFILES: 'Bookmarks',
    JOBX_BOOKMARKS_SUBTITLE: 'Here you can see your bookmarked colleagues in the field of Job Sharing.',

    JOBX_TANDEM_CHECK_BIRTH_YEAR: 'Year of birth',
    JOBX_TANDEM_CHECK_LANGUAGES: 'Languages',
    JOBX_TANDEM_CHECK_SPLIT_HOURS: 'Desired weekly hours',
    JOBX_TANDEM_CHECK_SKILLS: 'Skills',
    JOBX_TANDEM_CHECK_LOCATIONS: 'Locations',
    JOBX_TANDEM_CHECK_EDUCATION: 'Qualification',
    JOBX_TANDEM_CHECK_EXPERIENCE: 'Professional experience',
    JOBX_TANDEM_CHECK_TITLE: 'Tandem Check',
    JOBX_TANDEM_CHECK_GET_TO_KNOW: 'Send a message',

    PAGE_TITLE_JOB_SHARING_MATCHES: 'Job sharing matches',
    PAGE_TITLE_JOB_SHARING_BOOKMARKS: 'Job sharing bookmarks',
    PAGE_TITLE_JOB_SHARING_SEARCH: 'Search in job sharing',
    PAGE_TITLE_JOB_SHARING_JOBBOARD: 'Job sharing job board',
    PAGE_TITLE_JOB_SHARING_OFFERED: 'Job sharings offered by me',

    JOBX_PROFILE_CAMPAIGNS_LABEL: 'HR programs you participate in',
    JOBX_PROFILE_CAMPAIGNS_PLACEHOLDER: 'choose..',
    PROFILE_VIEW_JOBX_CAMPAIGNS_LABEL: 'HR programs',
};
