import { Component, ChangeDetectionStrategy, HostBinding, OnInit } from '@angular/core';
import { CheckboxListComponent } from '../checkbox-list.component';
import { ListItemBase } from './list-item-base.component';

@Component({
    selector: 'tp-checkbox-list-item',
    templateUrl: './checkbox-list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListItemComponent extends ListItemBase implements OnInit {
    @HostBinding('class.single-answer') singleAnswer = false;

    constructor(private readonly checkboxListComponent: CheckboxListComponent) {
        super();
    }

    ngOnInit() {
        if (this.checkboxListComponent.options.length === 1) {
            this.singleAnswer = true;
        }
    }
}
