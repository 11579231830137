import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

export type LayoutType = 'combined' | 'single' | 'canvas';

@Component({
    selector: 'tp-details',
    templateUrl: './details.component.html',
    host: {
        class: 'details',
    },
})
export class DetailsComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject<void>();

    @Input()
    layout: LayoutType = 'combined';

    @HostBinding('class.details__layout-combined')
    get isCombinedLayout() {
        return this.layout === 'combined';
    }

    @HostBinding('class.details__layout-single')
    get isSingleLayout() {
        return this.layout === 'single';
    }

    @HostBinding('class.details__layout-canvas')
    get isCanvasLayout() {
        return this.layout === 'canvas';
    }
    desktop = false;

    constructor(private readonly elementRef: ElementRef, private readonly breakpointObserver: BreakpointObserver) {}

    ngOnInit(): void {
        this.breakpointObserver
            .observe('(min-width: 960px)')
            .pipe(
                map((breakpoint) => breakpoint.matches),
                takeUntil(this.destroy$),
            )
            .subscribe((matches) => (this.desktop = matches));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
