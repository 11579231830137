import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { BookmarksService } from './bookmarks.service';
import { BookmarksState } from './bookmarks.reducer';
import { BookmarkActions } from './bookmark.actions';
import { NotificationsService } from '../notifications';

@Injectable({
    providedIn: 'root',
})
export class BookmarkEffects {
    constructor(
        private actions$: Actions,
        private bookmarksService: BookmarksService,
        private store: Store<{ bookmarks: BookmarksState }>,
        private notifications: NotificationsService,
    ) {}

    loadBookmarksData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookmarkActions.load),
            switchMap(({ context }) => this.bookmarksService.load(context)),
            map((bookmarks) => BookmarkActions.loadSuccess({ bookmarks })),
            catchError((error) => of(BookmarkActions.loadError({ error }))),
        ),
    );

    addBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookmarkActions.add),
            switchMap((action) =>
                this.bookmarksService.createBookmark(action.bookmark).pipe(
                    map((response) => {
                        return BookmarkActions.addSuccess({
                            bookmark: { ...action.bookmark, id: response },
                            accountName: action.accountName,
                        });
                    }),
                    catchError((error) => of(BookmarkActions.addError({ error }))),
                ),
            ),
        ),
    );

    addBookmarkSuccessNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BookmarkActions.addSuccess),
                tap(({ accountName }) => this.showAddSuccessNotification(accountName)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    deleteBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookmarkActions.delete),
            switchMap(({ bookmark, accountName }) =>
                this.bookmarksService.removeBookmark(bookmark.id).pipe(
                    map(() => BookmarkActions.deleteSuccess({ bookmark: bookmark, accountName: accountName })),
                    catchError((error) => of(BookmarkActions.deleteError({ error, bookmark }))),
                ),
            ),
        ),
    );

    deleteBookmarkSuccessNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BookmarkActions.deleteSuccess),
                tap(({ accountName }) => this.showDeleteSuccessNotification(accountName)),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );

    private showAddSuccessNotification(accountName: string): void {
        this.notifications.openDefaultSnack('PROFILE_STARED', { name: accountName });
    }

    private showDeleteSuccessNotification(accountName: string): void {
        this.notifications.openDefaultSnack('PROFILE_UNSTARED', { name: accountName });
    }
}
