import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PendingRequestsInterceptor implements HttpInterceptor {
    private pendingRequests = 0;
    private filteredUrls: RegExp[] = [new RegExp('ontology-proxy'), new RegExp('assets/icons')];

    private _pendingRequestsStatus$ = new ReplaySubject<boolean>(1);
    private _forceByPass: boolean;

    get pendingRequestsStatus$(): Observable<boolean> {
        return this._pendingRequestsStatus$.asObservable();
    }

    set forceByPass(value: boolean) {
        this._forceByPass = value;
    }

    private isFilteredUrl(url: string): boolean {
        return this.filteredUrls.some((regExp) => {
            return regExp.test(url);
        });
    }

    private doBypass(req: HttpRequest<unknown>): boolean {
        return this._forceByPass || this.isFilteredUrl(req.urlWithParams);
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.doBypass(req)) {
            if (++this.pendingRequests === 1) {
                this._pendingRequestsStatus$.next(true);
            }
        }

        return next.handle(req).pipe(
            finalize(() => {
                if (!this.doBypass(req)) {
                    if (--this.pendingRequests === 0) {
                        this._pendingRequestsStatus$.next(false);
                    }
                }
            }),
        );
    }
}
