import { Workshop } from '../models/workshop.types';
import { combineReducers, createReducer, createSelector, on } from '@ngrx/store';
import { TopicWorkshopActions } from './topic-workshop.actions';
import { workshopProfileReducer, WorkshopProfileState } from './workshop-profile.reducer';
import { workshopMatchingReducer, WorkshopMatchingState } from './workshop-matching.reducer';
import {
    MyCurrentWorkshopState,
    MyWorkshop,
    MyWorkshopsItemState,
    myWorkshopsReducer,
    MyWorkshopsState,
} from './my-workshops.reducer';
import { WorkshopParticipantsState, workshopParticipantsStateReducer } from './workshop-participants.reducer';
import { workshopSearchReducer, WorkshopSearchState } from '../../workshop-domain/search/workshop-search.reducer';
import { GeneralData } from '@tploy-enterprise/tenant-core';

export interface WorkshopCreationState {
    workshop: Workshop;
    savingError: Error;
    saving: boolean;
}

const workshopCreationStateInitialState: WorkshopCreationState = {
    workshop: null,
    savingError: null,
    saving: false,
};

const workshopCreationReducer = createReducer(
    workshopCreationStateInitialState,
    on(TopicWorkshopActions.createWorkshop, (state) => ({
        ...state,
        saving: true,
    })),
    on(TopicWorkshopActions.createWorkshopSuccess, (state, { workshop }) => ({
        ...state,
        workshop: workshop,
        savingError: null,
        saving: false,
    })),
    on(TopicWorkshopActions.createWorkshopError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(TopicWorkshopActions.publishWorkshopSuccess, () => ({
        ...workshopCreationStateInitialState,
    })),
);

export interface WorkshopEditionState {
    workshop: Workshop;
    savingError: Error;
    saving: boolean;
}

const workshopEditionStateInitialState: WorkshopEditionState = {
    workshop: null,
    savingError: null,
    saving: false,
};

const workshopEditionReducer = createReducer(
    workshopEditionStateInitialState,
    on(TopicWorkshopActions.selectWorkshop, (state, { workshop }) => ({
        ...state,
        workshop: workshop,
    })),
    on(TopicWorkshopActions.saveWorkshop, (state) => ({
        ...state,
        saving: true,
    })),
    on(TopicWorkshopActions.saveWorkshopSuccess, (state, { workshop }) => ({
        ...state,
        workshop: workshop,
        savingError: null,
        saving: false,
    })),
    on(TopicWorkshopActions.saveWorkshopError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(TopicWorkshopActions.publishWorkshopSuccess, () => ({
        ...workshopEditionStateInitialState,
    })),
);

export interface WorkshopDeletionState {
    workshopId: string;
    deletion: boolean;
    deletionError: Error;
}

const workshopDeletionReducer = createReducer<WorkshopDeletionState>(
    { workshopId: null, deletion: false, deletionError: null },
    on(TopicWorkshopActions.deleteWorkshop, (state, { workshopId }) => ({ ...state, workshopId, deletion: true })),
    on(TopicWorkshopActions.deleteWorkshopSuccess, (state, { workshopId }) => ({
        ...state,
        workshopId: workshopId,
        deletion: false,
    })),
    on(TopicWorkshopActions.deleteWorkshopError, (state, { error }) => ({
        ...state,
        deletion: false,
        deletionError: error,
    })),
);

export interface WorkshopsOfferedByMeState {
    loaded: boolean;
    loading: boolean;
    workshops: Workshop[];
    error: Error;
}

const workshopsOfferedByMeReducerInitialState: WorkshopsOfferedByMeState = {
    loaded: false,
    loading: false,
    workshops: [],
    error: null,
};

const workshopsOfferedByMeReducer = createReducer(
    workshopsOfferedByMeReducerInitialState,
    on(TopicWorkshopActions.loadWorkshopsOfferedByMe, (state) => {
        return { ...state, loading: true };
    }),
    on(TopicWorkshopActions.loadWorkshopsOfferedByMeSuccess, (state, { workshops }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            workshops: [...workshops],
        };
    }),
    on(TopicWorkshopActions.loadWorkshopsOfferedByMeError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
    on(
        TopicWorkshopActions.createWorkshopSuccess,
        TopicWorkshopActions.saveWorkshopSuccess,
        TopicWorkshopActions.publishWorkshopSuccess,
        (state, { workshop }) => {
            const existingWorkshopIndex = state.workshops.findIndex((w) => w.id === workshop.id);
            // create new workshop
            if (existingWorkshopIndex === -1) {
                return {
                    ...state,
                    workshops: [...state.workshops, workshop],
                };
            }

            // update existing workshop
            const existingWorkshop = state.workshops[existingWorkshopIndex];
            const newWorkshops = [...state.workshops];
            newWorkshops.splice(existingWorkshopIndex, 1, { ...existingWorkshop, ...workshop });
            return { ...state, workshops: newWorkshops };
        },
    ),
    on(TopicWorkshopActions.deleteWorkshopSuccess, (state, { workshopId }) => {
        return {
            ...state,
            workshops: state.workshops.filter((w) => w.id !== workshopId),
        };
    }),
);

export interface WorkshopPublishState {
    workshop: Workshop;
    publishError: Error;
    publishing: boolean;
}

const workshopPublishStateInitialState: WorkshopPublishState = {
    workshop: null,
    publishError: null,
    publishing: false,
};

const workshopPublishReducer = createReducer(
    workshopPublishStateInitialState,
    on(TopicWorkshopActions.publishWorkshop, (state) => ({ ...state, publishing: true })),
    on(TopicWorkshopActions.publishWorkshopSuccess, (state, { workshop }) => ({
        ...state,
        workshop: workshop,
        publishing: false,
    })),
    on(TopicWorkshopActions.publishWorkshopError, (state, { error }) => ({
        ...state,
        publishError: error,
        publishing: false,
    })),
);

export interface WorkshopSearchTopicUsersState {
    loaded: boolean;
    loading: boolean;
    loadingError: Error;
    results: Partial<GeneralData>[];
}

export const searchTopicUsersInitialState: WorkshopSearchTopicUsersState = {
    loaded: false,
    loading: false,
    loadingError: null,
    results: [],
};

export const WorkshopSearchTopicUsersReducer = createReducer(
    searchTopicUsersInitialState,
    on(TopicWorkshopActions.clearSearchTopicUsers, (state) => {
        return { ...state, results: [], loaded: false };
    }),
    on(TopicWorkshopActions.searchTopicUsers, (state) => {
        return { ...state, loading: true };
    }),
    on(TopicWorkshopActions.searchTopicUsersSuccess, (state, { results }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            results,
        };
    }),
    on(TopicWorkshopActions.searchTopicUsersError, (state, { error }) => {
        return { ...state, loading: false, error: error };
    }),
);

export interface TopicWorkshopState {
    workshopCreation: WorkshopCreationState;
    workshopEdition: WorkshopEditionState;
    workshopsPublish: WorkshopPublishState;
    workshopsOfferedByMe: WorkshopsOfferedByMeState;
    workshopProfile: WorkshopProfileState;
    workshopMatching: WorkshopMatchingState;
    workshopSearch: WorkshopSearchState;
    myWorkshops: MyWorkshopsState;
    workshopParticipants: WorkshopParticipantsState;
    searchTopicUsers: WorkshopSearchTopicUsersState;
}

export const topicWorkshopsReducer = combineReducers({
    workshopCreation: workshopCreationReducer,
    workshopEdition: workshopEditionReducer,
    workshopDeletion: workshopDeletionReducer,
    workshopsPublish: workshopPublishReducer,
    workshopsOfferedByMe: workshopsOfferedByMeReducer,
    workshopProfile: workshopProfileReducer,
    workshopMatching: workshopMatchingReducer,
    workshopSearch: workshopSearchReducer,
    myWorkshops: myWorkshopsReducer,
    workshopParticipants: workshopParticipantsStateReducer,
    searchTopicUsers: WorkshopSearchTopicUsersReducer,
});

// selectors
// todo move in separate file or index.ts

export const getWorkshopsOfferedByMeLoaded = (state: WorkshopsOfferedByMeState): Workshop[] | null =>
    state.loaded ? state.workshops : null;

export const getWorkshopById = (state: WorkshopsOfferedByMeState, id: string): Workshop => {
    if (!state.loaded) {
        return null;
    }

    const workshop = state.workshops.find((w) => w.id === id);
    if (workshop === null) {
        throw new Error(`Workshop ${id} doesnt exist`);
    }

    return workshop;
};

export const selectWorkshopsOfferedByMeState = (state: TopicWorkshopState): WorkshopsOfferedByMeState => {
    return state.workshopsOfferedByMe;
};

export const selectWorkshopsOfferedByMeLoaded = createSelector(
    selectWorkshopsOfferedByMeState,
    getWorkshopsOfferedByMeLoaded,
);

export const selectWorkshopOfferedByMeById = createSelector(selectWorkshopsOfferedByMeState, getWorkshopById);

export const selectMyRegisteredWorkshopsState = (state: TopicWorkshopState): MyWorkshopsItemState => {
    return state.myWorkshops.registered;
};

export const selectMyRequestedWorkshopsState = (state: TopicWorkshopState): MyWorkshopsItemState => {
    return state.myWorkshops.requested;
};
export const selectMyWorkshopDetailsState = (state: TopicWorkshopState): MyCurrentWorkshopState => {
    return state.myWorkshops.details;
};

export const getMyWorkshopsLoaded = (state: MyWorkshopsItemState): Workshop[] | null =>
    state.status.loaded ? state.workshops : null;
export const getMyWorkshopDetailsLoaded = (state: MyCurrentWorkshopState): MyWorkshop | null => {
    return state.status.loaded ? state.workshop : null;
};

export const selectMyRegisteredWorkshops = createSelector(selectMyRegisteredWorkshopsState, getMyWorkshopsLoaded);
export const selectMyRequestedWorkshops = createSelector(selectMyRequestedWorkshopsState, getMyWorkshopsLoaded);
export const selectMyWorkshopsDetails = createSelector(selectMyWorkshopDetailsState, getMyWorkshopDetailsLoaded);
