<mat-menu #footerLinkMenu="matMenu">
    <a *ngFor="let link of links" mat-menu-item [routerLink]="link.routerLink">{{ link.label | translatable }}</a>
</mat-menu>

<ng-container [ngTemplateOutlet]="viewMode === 'button' ? buttonViewMode : linkViewMode"></ng-container>

<ng-template #buttonViewMode>
    <button
        mat-button
        [matMenuTriggerFor]="footerLinkMenu"
        [attr.aria-label]="'HELP_AND_LEGAL_INFORMATION_BTN_ARIA_LABEL' | translatable"
    >
        <mat-icon class="sidenav_link-icon" svgIcon="help_filled"></mat-icon>
        {{ 'HELP_AND_LEGAL_INFORMATION' | translatable }}
    </button>
</ng-template>

<ng-template #linkViewMode>
    <a
        routerLink="."
        [matMenuTriggerFor]="footerLinkMenu"
        [attr.aria-label]="'HELP_AND_LEGAL_INFORMATION_BTN_ARIA_LABEL' | translatable"
    >
        <mat-icon class="sidenav_link-icon" svgIcon="help_filled"></mat-icon>
        {{ 'HELP_AND_LEGAL_INFORMATION' | translatable }}
    </a>
</ng-template>
