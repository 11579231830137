import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { Translatable } from '../../language';

@Component({
    selector: 'tp-profile-view-string-list-field',
    templateUrl: './profile-view-string-list-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__list-field',
    },
})
export class ProfileViewStringListFieldComponent {
    @Input()
    key: Translatable;

    @Input()
    value: string[];

    @Input()
    extraValue: Translatable;

    @Input()
    separateByComma: boolean;
}
