import { DirectMessagesState } from './direct-messages.state';

export const directMessagesInitialState: DirectMessagesState = {
    conversations: [],
    currentConversation: null,
    conversationsLoaded: false,
    conversationsLoading: false,
    conversationsLoadingError: null,
    currentConversationLoaded: false,
    currentConversationLoading: false,
    currentConversationLoadingError: null,
    loading: false,
    loadingError: null,
    unreadMessages: 0,
};
