import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountListItemTag } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-profile-title',
    templateUrl: './nla-profile-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLAProfileTitleComponent implements OnChanges {
    @Input()
    locations: string[];
    @Input()
    highlightLocations: string[] = [];
    @Input()
    isLocationIndependent: boolean;
    @Input()
    highlightLocationIndependent: boolean;

    private locationTags: AccountListItemTag[] = [];
    public visibleLocations: AccountListItemTag[] = [];
    private readonly visibleLocationsNumber: number = 2;
    public extraLocations: AccountListItemTag[] = [];

    get showBadge(): boolean {
        return this.extraLocations.length > 0;
    }

    get badgeText(): string {
        return `+${this.extraLocations.length}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.locations) {
            this.locations = Array.from(new Set([...this.highlightLocations, ...this.locations]));

            this.locationTags = this.locations.map((location) => ({
                name: location,
                matched: this.highlightLocations.includes(location),
            }));

            this.visibleLocations = this.locationTags.slice(0, this.visibleLocationsNumber);
            this.extraLocations = this.locationTags.slice(this.visibleLocationsNumber, this.locations.length);
        }
    }
}
