import { Component, Input } from '@angular/core';
import { Translatable } from '../../language';

@Component({
    selector: 'tp-card-organizer',
    templateUrl: './card-organizer.component.html',
    host: {
        class: 'card__organizer',
    },
})
export class CardOrganizerComponent {
    @Input() imageSrc: string;
    @Input() imageAlt: string | Translatable;
    @Input() label: string | Translatable;
    @Input() name: string | Translatable;
}
