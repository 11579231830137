import { IGenericStorageAdapter } from './generic-storage-adapter.interface';

export abstract class BaseStorageAdapter implements IGenericStorageAdapter {
    protected constructor(private storage: Storage) {}

    get<T = string | unknown, D = string | undefined | null>(key: string, defaultValue: D = undefined): T | D {
        if (this.has(key)) {
            const value = this.storage.getItem(key);
            try {
                return JSON.parse(value);
            } catch {
                return value as unknown as T;
            }
        }
        return defaultValue || undefined;
    }

    has(key: string): boolean {
        const value = this.storage.getItem(key);
        return typeof value !== 'undefined' && value !== null && value.length > 0;
    }

    set<T>(key: string, value: T, safe = false): IGenericStorageAdapter {
        if (!this.has(key) || !safe) {
            let stringValue;
            if (typeof value === 'object') {
                try {
                    stringValue = JSON.stringify(value);
                } catch {
                    stringValue = value;
                }
            } else {
                stringValue = value;
            }
            this.storage.setItem(key, stringValue);
        }
        return this;
    }

    remove(key: string): boolean {
        if (this.has(key)) {
            this.storage.removeItem(key);
            return true;
        }
        return false;
    }

    flush(): void {
        this.storage.clear();
    }
}
