import { Component, Input } from '@angular/core';
import {
    Direction,
    PageParams,
    ProfileNavigatorUser,
    ProfileNavigatorView,
    CommonState,
    TOPIC_NAMES_LIST,
} from '@tploy-enterprise/tenant-core';
import { Store } from '@ngrx/store';
import { ExpertMatchingActions } from '../../expert-domain/matching/expert-matching.actions';
import { ExpertSearchActions } from '../../expert-domain/search/expert-search.actions';
import {
    selectAllSearchResults,
    selectAllMatchResults,
    selectAllBookmarkResults,
    selectSearchingPageParams,
    selectMatchesPageParams,
} from '../../expert-store/expert.selectors';
import { filter, map, withLatestFrom, takeUntil } from 'rxjs/operators';
import { ExpertBookmark, ExpertMatch, ExpertSearchResult } from '../../expert-api/expert.types';
import { TopicExpertState } from '../../expert-store/topic-expert.reducer';
import { BookmarksPageState, BookmarksPageEntry } from '@tploy-enterprise/tenant-core';
import { ActivatedRoute, UrlSerializer } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'tp-expert-profile-paginator-view',
    templateUrl: './expert-profile-paginator-view.component.html',
})
export class ExpertProfileNavigatorViewComponent implements ProfileNavigatorView {
    @Input() isSearchScope: boolean;
    @Input() isMatchScope: boolean;
    @Input() isGlobalBookmarkScope: boolean;
    @Input() isTopicBookmarkScope: boolean;
    @Input() context: string;
    @Input() itemId: any;
    prev$: any;
    next$: any;

    common$: Observable<any>;
    private readonly destroy$ = new Subject<void>();

    isProfilesLoading = false;

    constructor(
        private readonly store: Store<{
            common: CommonState;
            expert: TopicExpertState;
            bookmarksPage: BookmarksPageState;
        }>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly urlSerializer: UrlSerializer,
    ) {}

    ngOnInit() {
        this.common$ = this.store.select((state) => state.common);
        this.common$.pipe(takeUntil(this.destroy$)).subscribe(({ otherProfiles }) => {
            const { loaded, loading, listLoading } = otherProfiles;
            this.isProfilesLoading =
                TOPIC_NAMES_LIST.some((topicName) => !loaded[topicName] && !!loading[topicName]) || listLoading;
        });

        if (this.isSearchScope) {
            this.initSearchState();
        } else if (this.isMatchScope) {
            this.initMatchState();
        } else if (this.isTopicBookmarkScope) {
            this.initTopicBookmarkState();
        } else {
            this.initGlobalBookmarkState();
        }
    }

    initSearchState(): void {
        const store = this.store.select(selectAllSearchResults);
        const pageParams = this.store.select(selectSearchingPageParams);
        this.next$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.NEXT, this.isSearchScope, pageParams),
            ),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.PREVIOUS, this.isSearchScope, pageParams),
            ),
        );
    }

    initMatchState(): void {
        const store = this.store.select(selectAllMatchResults);
        const pageParams = this.store.select(selectMatchesPageParams);
        this.next$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.NEXT, this.isSearchScope, pageParams),
            ),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            withLatestFrom(pageParams),
            map(([results, pageParams]) =>
                this.getUserByDirection(results, Direction.PREVIOUS, this.isSearchScope, pageParams),
            ),
        );
    }

    private getUserByDirection(
        results: Array<ExpertSearchResult | ExpertMatch>,
        direction: Direction,
        isSearchScope: boolean,
        pageParams: PageParams,
    ): ProfileNavigatorUser {
        const currentIndex = Number(results.findIndex((result) => result?.userId === this.itemId));
        const targetIndex = currentIndex + direction;
        const item = results[targetIndex];
        if (!item && targetIndex > -1 && targetIndex < pageParams.length) {
            this.loadItemByIndex(targetIndex, isSearchScope);
        }
        return item
            ? {
                  name: item?.expertProfile?.user?.name,
                  avatar: item?.expertProfile?.user?.imageUrl,
                  userId: item?.userId,
              }
            : null;
    }

    private loadItemByIndex(index: number, isSearchScope: boolean): void {
        isSearchScope
            ? this.store.dispatch(ExpertSearchActions.loadSearchResult({ searchQuery: this.getSearchQuery(), index }))
            : this.store.dispatch(ExpertMatchingActions.loadMatchResult({ index }));
    }

    initGlobalBookmarkState(): void {
        const store = this.store.select('bookmarksPage');
        this.next$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getGlobalBookmarkUserByDirection(results.data, Direction.NEXT)),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getGlobalBookmarkUserByDirection(results.data, Direction.PREVIOUS)),
        );
    }

    private getGlobalBookmarkUserByDirection(
        results: Array<BookmarksPageEntry>,
        direction: Direction,
    ): ProfileNavigatorUser {
        results = results.filter((result) => result.bookmark.context === this.context);
        const resultIndex = Number(results.findIndex((result) => result.account.userId === this.itemId));
        const item = results[resultIndex + direction];
        return item
            ? {
                  name: item?.account?.generalData.name,
                  avatar: item?.account?.generalData.imageUrl,
                  userId: item?.account?.userId,
              }
            : null;
    }

    initTopicBookmarkState() {
        const store = this.store.select(selectAllBookmarkResults);
        this.next$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getTopicBookmarkUserByDirection(results, Direction.NEXT)),
        );
        this.prev$ = store.pipe(
            filter((results) => !!results),
            map((results) => this.getTopicBookmarkUserByDirection(results, Direction.PREVIOUS)),
        );
    }

    private getTopicBookmarkUserByDirection(
        results: Array<ExpertBookmark>,
        direction: Direction,
    ): ProfileNavigatorUser {
        const resultIndex = Number(results.findIndex((result) => result.entityId === this.itemId));
        const item = results[resultIndex + direction];
        return item
            ? {
                  name: item?.expertProfile?.user?.name,
                  avatar: item?.expertProfile?.user?.imageUrl,
                  userId: item?.entityId,
              }
            : null;
    }

    private getSearchQuery(): string {
        const src = this.activatedRoute.snapshot.params.src;
        return this.urlSerializer.parse(src).queryParamMap.get('q') ?? '';
    }
}
