import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenerationExchangeListItemComponent } from './generation-exchange-list-item.component';
import { MatCardModule } from '@angular/material/card';
import { CampaignsModule, FocusModule, LanguageModule, TaggerModule } from '@tploy-enterprise/tenant-common';
import { GenerationExchangeProfileTitleModule } from '../generation-exchange-profile-title/generation-exchange-profile-title.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';
import { ButtonModule, ChipsModule } from '@tandemploy/ngx-components';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [GenerationExchangeListItemComponent],
    imports: [
        CommonModule,
        ButtonModule,
        FocusModule,
        LanguageModule,
        MatCardModule,
        GenerationExchangeProfileTitleModule,
        TaggerModule,
        MatButtonModule,
        MatIconModule,
        AccountListItemModule,
        MatChipsModule,
        ChipsModule,
        MatTooltipModule,
        CampaignsModule,
    ],
    exports: [GenerationExchangeListItemComponent],
})
export class GenerationExchangeListItemModule {}
