import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoToggleDialogComponent } from './info-toggle-dialog/info-toggle-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Translatable } from '../language';

@Component({
    selector: 'tp-info-toggle',
    templateUrl: './info-toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'info-toggle',
    },
})
export class InfoToggleComponent implements OnInit {
    @Input()
    content: Translatable = null;

    @Input()
    disabled = false;

    @Input()
    icon: string;

    @Input()
    label: string;

    @Input()
    set ariaLabel(value: string) {
        this._ariaLabel = value;
    }

    get ariaLabel(): string {
        return !this._ariaLabel ? this.translateService.instant('DIALOG_HELP') : this._ariaLabel;
    }

    private _ariaLabel: string;

    public svgIcon = 'info_filled';

    constructor(public readonly dialog: MatDialog, private readonly translateService: TranslateService) {}

    ngOnInit() {
        if (this.icon) {
            this.svgIcon = this.icon;
        }
    }

    openDialog($event: MouseEvent): void {
        $event.stopPropagation();

        this.dialog.open(InfoToggleDialogComponent, {
            width: '50rem',
            maxWidth: '100%',
            data: {
                translatable: this.content,
                svgIcon: this.svgIcon,
                label: this.label,
            },
        });
    }
}
