import { Component, OnInit } from '@angular/core';
import { JobXBookmark, JobXProfile, JobXTandemCheckProfile } from '../../../jobs.types';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { JobXState } from '../../../jobXReducer';
import { JobsActions } from '../../../jobs.actions';
import { selectBookmarks, selectCurrentJobXProfile } from '../../../jobx.selectors';
import { AccountState, JOB_SHARING_TOPIC_NAME } from '@tploy-enterprise/tenant-core';
import { map, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'tp-job-x-bookmarks',
    templateUrl: './job-x-bookmarks.component.html',
    styleUrls: ['./job-x-bookmarks.component.scss'],
})
export class JobXBookmarksComponent implements OnInit {
    areBookmarksLoading$: Observable<boolean>;
    bookmarks$: Observable<Array<JobXBookmark>>;
    context = JOB_SHARING_TOPIC_NAME;
    ownProfile$: Observable<JobXTandemCheckProfile>;

    constructor(private readonly store: Store<{ jobx: JobXState; account: AccountState }>) {}

    ngOnInit(): void {
        this.bookmarks$ = this.store.select(selectBookmarks);
        this.store.dispatch(JobsActions.loadBookmarks());
        this.ownProfile$ = this.store.select(selectCurrentJobXProfile).pipe(
            withLatestFrom(this.store.select('account')),
            map(([profile, account]) => {
                return {
                    ...profile,
                    user: {
                        generalData: account.generalData.current.data,
                        experience: account.experience.current.data,
                        settings: account.settings.current.data,
                    },
                };
            }),
        );
        this.areBookmarksLoading$ = this.store.select('jobx').pipe(map((jobx) => jobx.bookmarks.loading));
    }
}
