import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfigService } from '../../core-modules/config';
import { Settings } from '../../tenant-core.types';

@Component({
    selector: 'tp-footer-help',
    templateUrl: './help.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'modal-form-layout',
    },
})
export class HelpComponent {
    supportLink: string;
    feedBackUrl: string;

    get showClearTextLink(): boolean {
        return this.isMailLink(this.supportLink);
    }

    get target(): string {
        if (this.isMailLink(this.supportLink)) {
            return '_self';
        } else {
            return '_blank';
        }
    }

    get supportBody(): string {
        if (this.isMailLink(this.supportLink)) {
            return 'HELP_CONTACT_EMAIL_DIALOG_TEXT';
        } else {
            return 'HELP_CONTACT_HELP_DESK_DIALOG_TEXT';
        }
    }

    get cleanMailsupportLink(): string {
        return this.configService.remoteSettings.mailingSupportLink.replace('mailto:', '');
    }

    get hasFeedBackOption(): boolean {
        return !!this.feedBackUrl;
    }

    constructor(private readonly configService: ConfigService) {
        this.supportLink = this.configService.remoteSettings.mailingSupportLink;
        this.feedBackUrl = this.configService.remoteSettings.feedbackUrl;
    }

    public isMailLink(link: string): boolean {
        return link.includes('mailto:') ? true : false;
    }
}
