import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'tp-profile-view-subsection-placeholder',
    templateUrl: 'profile-view-subsection-placeholder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__subsection-placeholder',
        tabindex: '0',
    },
})
export class ProfileViewSubsectionPlaceholderComponent {}
