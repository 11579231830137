export default {
    EXPERT: 'Expert Finder',
    EXPERT_INFO:
        'With the expert finder, you can quickly locate relevant expertise in the organization. Are you looking for a pro for a specific topic or tool and would like to find out which colleagues have the right skills? Then select "Expert Finder" as your interest and specify what kind of skills you are currently looking for in the company. You can adapt and expand your selection at any time – and of course you can also specify what you yourself are frequently asked for advice on. Maybe you would like to share your special topics with others – and even add skills and talents that nobody knows about.',

    SIGN_UP_EXPERT_TITLE: 'Expert Finder',
    SIGN_UP_EXPERT_SUBTITLE: "I'm looking for expertise and / or would like mark myself as an expert",

    PROFILE_EXPERT_TITLE: 'Expert Finder',
    PROFILE_EXPERT_SUBTITLE: "I'm looking for expertise and / or would like mark myself as an expert",

    PROFILE_EXPERT_LOCATION_LABEL: 'My location',
    PROFILE_EXPERT_COMMUNICATE_OPTIONS_LABEL: 'How do you want to communicate',
    PROFILE_EXPERT_COMMUNICATE_OPTION_1: 'In person on my location',
    PROFILE_EXPERT_COMMUNICATE_OPTION_2: 'Virtual',
    PROFILE_EXPERT_COMMUNICATE_OPTION_3: 'Both is possible',
    PROFILE_EXPERT_SKILLS_INTEREST_LABEL: 'Which experts would you like to be informed about?',
    PROFILE_EXPERT_SKILLS_INTEREST_PLACEHOLDER: 'eg. wordpress, slack, executive development, powerpoint',
    PROFILE_EXPERT_OFFER_SKILLS_LABEL: 'What skills and tools do colleagues ask you for advice on?',
    PROFILE_EXPERT_OFFER_SKILLS_PLACEHOLDER: 'eg. wordpress, slack, executive development, powerpoint',

    EXPERT_AVAILABILITY_EDIT_TITLE: 'Not available for a while?',
    EXPERT_AVAILABILITY_EDIT_SUBTITLE:
        'Select the time period here. Your status will be updated automatically after this period has expired.',
    EXPERT_AVAILABILITY_SAVED: 'Availability updated.',
    EXPERT_UNAVAILABLE_FROM_DATE_LABEL: 'Unavailable from',
    EXPERT_UNAVAILABLE_TO_DATE_LABEL: 'until',

    EXPERT_TAB_MATCHES: 'Matches',
    EXPERT_TAB_MY_EXPERTS: 'Bookmarks',
    EXPERT_TAB_AVAILABILITY: 'My availability',
    EXPERT_TAB_SEARCH: 'Search',

    EXPERT_MATCHING_NOT_FOUND_CONTENT:
        'Please specify more skills and (hidden?) talents in your profile to find matches',
    EXPERT_MATCHING_NOT_FOUND_IMG_ALT: 'No matches found',
    EXPERT_MATCHING_NOT_FOUND_BTN: 'Edit Skills',
    EXPERT_NO_MATCH_FIND_CONTENT: 'Find more colleagues by using the search',

    EXPERT_BOOKMARKS_TITLE: "You didn't bookmark any experts.",
    EXPERT_BOOKMARKS_SUBTITLE: 'Here you can save your favourite experts.',
    EXPERT_BOOKMARKS_PROFILES: 'Bookmarked experts',

    EXPERT_PROFILE_VIEW_OFFERED_SKILLS: 'Expertise I can share',
    EXPERT_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Expertise I am looking for',
    EXPERT_PROFILE_VIEW_COMMUNICATION_TYPE: 'Preferred communication channel',
    EXPERT_PROFILE_VIEW_COMMUNICATION_IN_PERSON: 'Personal',
    EXPERT_PROFILE_VIEW_COMMUNICATION_VIRTUAL: 'Virtual',
    EXPERT_PROFILE_VIEW_COMMUNICATION_BOTH: 'Both personal and remote',
    EXPERT_PROFILE_VIEW_UNAVAILABILITY_DATES: 'Temporarily unavailable',

    EXPERT_SEARCH_HEADER: 'Search for Experts by keywords',
    EXPERT_SEARCH_BTN_TEXT: 'Search!',
    EXPERT_SEARCH_EMPTY_MESSAGE:
        'Unfortunately nothing was found. Tip: Searching without a search term shows all currently available experts.',

    PAGE_TITLE_EXPERT_MATCHES: 'Expert matches',
    PAGE_TITLE_EXPERT_BOOKMARKS: 'Expert bookmarks',
    PAGE_TITLE_EXPERT_SEARCH: 'Search expert',
    PAGE_TITLE_EXPERT_AVAILABILITY: 'Expert availability',
};
