import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EmbeddedViewRef,
    HostBinding,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { matTooltipAnimations } from '@angular/material/tooltip';
import { Observable, Subject } from 'rxjs';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { AnimationEvent } from '@angular/animations';

export type PopoverVisibility = 'initial' | 'visible' | 'hidden';

/**
 * Internal component that wraps the popover's content.
 */
@Component({
    selector: 'tp-popover',
    templateUrl: 'popover.component.html',
    styleUrls: ['popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [matTooltipAnimations.tooltipState],
})
export class PopoverComponent {
    /** Property watched by the animation framework to show or hide the popover */
    _visibility: PopoverVisibility = 'initial';

    /** Classes to be added to the popover. */
    @HostBinding('class')
    popoverClass: string;

    /** The timeout ID of any current timer set to show the popover */
    _showTimeoutId: number;

    /** The timeout ID of any current timer set to hide the popover */
    _hideTimeoutId: number;

    /** Whether interactions on the page should close the popover */
    private _closeOnInteraction = false;

    /** Subject for notifying that the popover has been hidden from the view */
    private readonly _onHide: Subject<void> = new Subject<void>();

    @HostBinding('attr.aria-hidden')
    isAriaHidden = true;

    template: TemplateRef<any>;

    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        return this.portalOutlet.attachComponentPortal(portal);
    }

    attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        return this.portalOutlet.attachTemplatePortal(portal);
    }

    /**
     * Shows the popover with an animation originating from the provided origin
     * @param delay Amount of milliseconds to the delay showing the popover.
     */
    show(delay: number): void {
        // Cancel the delayed hide if it is scheduled
        if (this._hideTimeoutId) {
            clearTimeout(this._hideTimeoutId);
            this._hideTimeoutId = null;
        }

        // Body interactions should cancel the popover if there is a delay in showing.
        this._closeOnInteraction = true;
        this._showTimeoutId = window.setTimeout(() => {
            this._visibility = 'visible';
            this._hideTimeoutId = null;

            // Mark for check so if any parent component has set the
            // ChangeDetectionStrategy to OnPush it will be checked anyways
            this._markForCheck();
        }, delay);
    }

    /**
     * Begins the animation to hide the popover after the provided delay in ms.
     * @param delay Amount of milliseconds to delay showing the popover.
     */
    hide(delay: number): void {
        // Cancel the delayed show if it is scheduled
        if (this._showTimeoutId) {
            clearTimeout(this._showTimeoutId);
            this._hideTimeoutId = null;
        }

        this._hideTimeoutId = window.setTimeout(() => {
            this._visibility = 'hidden';
            this._hideTimeoutId = null;

            // Mark for check so if any parent component has set the
            // ChangeDetectionStrategy to OnPush it will be checked anyways
            this._markForCheck();
        }, delay);
    }

    /** Returns an observable that notifies when the popover has been hidden from view. */
    afterHidden(): Observable<void> {
        return this._onHide.asObservable();
    }

    /** Whether the popover is being displayed. */
    isVisible(): boolean {
        return this._visibility === 'visible';
    }

    _animationStart() {
        this._closeOnInteraction = false;
    }

    _animationDone(event: AnimationEvent): void {
        const toState = event.toState as PopoverVisibility;

        if (toState === 'hidden' && !this.isVisible()) {
            this._onHide.next();
        }

        if (toState === 'visible' || toState === 'hidden') {
            this._closeOnInteraction = true;
        }
    }

    /**
     * Marks that the popover needs to be checked in the next change detection run.
     * Mainly used for rendering the initial text before positioning a popover, which
     * can be problematic in components with OnPush change detection.
     */
    _markForCheck(): void {
        this.changeDetectorRef.markForCheck();
    }
}
