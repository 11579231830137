import { Component, Input, OnInit } from '@angular/core';
import { Translatable } from '@tploy-enterprise/tenant-common';

@Component({
    selector: 'tp-job-x-tandem-check-row',
    templateUrl: './job-x-tandem-check-row.component.html',
    styleUrls: ['./job-x-tandem-check-row.component.scss'],
    host: {
        class: 'job-x__tandem-check-grid--row',
    },
})
export class JobXTandemCheckRowComponent implements OnInit {
    @Input() title: Translatable | string = '';
    @Input() ownProfileValue: string;
    @Input() targetProfileValue: string;

    constructor() {}

    ngOnInit(): void {}
}
