import { Injectable } from '@angular/core';
import {
    Notification,
    ParticipantsWithSkillsResponse,
    ParticipantsWithSkillsResponseDTO,
    Registration,
    RegistrationStatus,
    Workshop,
    WorkshopDTO,
} from '../models/workshop.types';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { WorkshopSerializer } from './workshop-serializer';
import { WorkshopMatchingResultDTO, WorkshopMatchResult } from '../models/workshop-matching.types';
import { WorkshopSearchDTO, WorkshopSearchResults } from '../models/workshop-search.types';
import { WorkshopDomainMatchException, WorkshopDomainSearchException } from '../../workshop.exceptions';
import {
    AccountService,
    EntityNotFoundService,
    GeneralData,
    OffsetLimit,
    PageParams,
    PaginatorService,
    OthersAccount,
    WORKSHOPS_TOPIC_NAME,
    GeneralDataDTO,
} from '@tploy-enterprise/tenant-core';
import {
    WorkshopEntityBadRequestException,
    WorkshopEntityForbiddenException,
    WorkshopEntityNotFoundException,
    WorkshopEntityRegistrationFullException,
    WorkshopUnexpectedException,
} from './workshop.exceptions';
import { MyWorkshop } from '../store/my-workshops.reducer';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class WorkshopService {
    private readonly API_PATH = `/api/v2/${WORKSHOPS_TOPIC_NAME}`;

    constructor(
        private accountService: AccountService,
        private http: HttpClient,
        private serializer: WorkshopSerializer,
        private readonly entityNotFoundService: EntityNotFoundService,
        private readonly paginatorService: PaginatorService,
    ) {}

    loadOfferedByMe(userId: string): Observable<Workshop[]> {
        const params = new HttpParams({
            fromObject: {
                organizer: userId,
            },
        });

        return this.http
            .get<WorkshopDTO[]>(this.API_PATH, { params: params })
            .pipe(map((workshopDTOs) => workshopDTOs.map((dto) => this.serializer.deserialize(dto))));
    }

    loadMyRegisteredWorkshops(): Observable<Workshop[]> {
        const params = new HttpParams({
            fromObject: {
                registered: 'true',
            },
        });

        return this.http.get<WorkshopDTO[]>(this.API_PATH, { params: params }).pipe(
            map((workshopDTOs) => workshopDTOs.map((dto) => this.serializer.deserialize(dto))),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    loadMyRequestedWorkshops(): Observable<Workshop[]> {
        const params = new HttpParams({
            fromObject: {
                notificationRequested: 'true',
            },
        });

        return this.http.get<WorkshopDTO[]>(this.API_PATH, { params: params }).pipe(
            map((workshopDTOs) => workshopDTOs.map((dto) => this.serializer.deserialize(dto))),
            switchMap((entities) => {
                if (entities.length > 0) {
                    return forkJoin(entities.map((entity) => this.hydrateEntity(entity)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    private hydrateEntity(entity: Workshop): Observable<MyWorkshop> {
        const organizer$ = this.getOrganizerData(entity).pipe(catchError(() => of(null)));

        return forkJoin({ account: organizer$ }).pipe(map((hydrationData) => ({ ...entity, ...hydrationData })));
    }

    private getOrganizerData(entity: Workshop): Observable<OthersAccount> {
        if (entity.organizerData) {
            return of(entity.organizerData);
        }

        return this.accountService.getAccountById(entity.organizers[0].id);
    }

    save(workshop: Workshop, sendNotifications?: boolean): Observable<Workshop> {
        const params: Params = sendNotifications !== undefined ? { sendNotifications: sendNotifications } : {};
        return this.http
            .put<WorkshopDTO>(`${this.API_PATH}/${workshop.id}`, this.serializer.serialize(workshop), { params })
            .pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    create(workshop: Workshop): Observable<Workshop> {
        return this.http
            .post<WorkshopDTO>(this.API_PATH, this.serializer.serialize(workshop))
            .pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    publish(workshopId: string): Observable<Workshop> {
        return this.http
            .get<WorkshopDTO>(`${this.API_PATH}/${workshopId}/publish`)
            .pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    delete(workshopId: string): Observable<string> {
        return this.http
            .delete(`${this.API_PATH}/${workshopId}`, {
                responseType: 'text',
            })
            .pipe(map(() => workshopId));
    }

    loadMatchesApi(offsetLimit: OffsetLimit): Observable<WorkshopMatchingResultDTO> {
        const params: Params = { ...offsetLimit };
        return this.http.get<WorkshopMatchingResultDTO>(`${this.API_PATH}/matches`, { params });
    }

    loadMatches(pageParams: PageParams): Observable<WorkshopMatchResult> {
        let matchesTotal = 0;
        return this.loadMatchesApi(this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.serializer.deserializeWorkshopMatchingEntry(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        matches: results,
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { matches: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new WorkshopDomainMatchException();
            }),
        );
    }

    public loadEntityWithAccountGeneralData(
        workshopId: string,
    ): Observable<{ workshop: Workshop; generalData: GeneralData }> {
        return this.loadEntity(workshopId).pipe(
            switchMap((workshop) =>
                this.accountService.getAccountById(workshop.organizers[0].id).pipe(
                    map((account) => {
                        return { workshop, generalData: account.generalData };
                    }),
                    catchError(() => of(null)),
                ),
            ),
        );
    }

    loadEntity(workshopId: string, showHighlighted = false): Observable<Workshop> {
        let params: Params = {};
        if (showHighlighted) {
            params = { showHighlighted: 1 };
        }
        return this.http.get<WorkshopDTO>(`${this.API_PATH}/${workshopId}`, { params }).pipe(
            map((dto) => this.serializer.deserialize(dto)),
            catchError((error: Error) => this.handleEntityError(error)),
        );
    }

    register(workshopId: string, userName: string): Observable<Workshop> {
        const body = { user: { name: userName } };

        return this.http
            .post<WorkshopDTO>(`${this.API_PATH}/${workshopId}/registration`, body)
            .pipe(map((dto) => this.serializer.deserialize(dto)));
    }

    startRegistration(workshopId: string, userName: string): Observable<Registration> {
        const body = { user: { name: userName } };

        return this.http
            .post<Registration>(`${this.API_PATH}/${workshopId}/registrations`, body)
            .pipe(catchError((error: Error) => this.handleEntityRegistrationError(error)));
    }

    finishRegistration(workshopId: string, registrationId: string): Observable<Registration> {
        const body = { status: RegistrationStatus.CREATED };

        return this.http.patch<Registration>(`${this.API_PATH}/${workshopId}/registrations/${registrationId}`, body);
    }

    cancelRegistration(workshopId: string, registrationId: string): Observable<void> {
        return this.http.delete<void>(`${this.API_PATH}/${workshopId}/registrations/${registrationId}`);
    }

    createNotification(workshopId: string, userName: string): Observable<Notification> {
        const body = { user: { name: userName } };

        return this.http.post<Notification>(`${this.API_PATH}/${workshopId}/notifications`, body);
    }

    searchEntitiesApi(searchQuery: string, offsetLimit: OffsetLimit): Observable<WorkshopSearchDTO> {
        const params: Params = {
            ...offsetLimit,
        };
        return this.http.get<WorkshopSearchDTO>(`${this.API_PATH}/search?q=${encodeURIComponent(searchQuery)}`, {
            params,
        });
    }

    searchEntities(searchQuery: string, pageParams: PageParams): Observable<WorkshopSearchResults> {
        let matchesTotal = 0;
        return this.searchEntitiesApi(searchQuery, this.paginatorService.offsetLimit(pageParams)).pipe(
            tap((response) => (matchesTotal = response.totalCount)),
            map((response) => response.results),
            map((results) => results.map((dto) => this.serializer.deserializeWorkshopSearchResult(dto))),
            map((results) => {
                if (results.length > 0) {
                    return {
                        results,
                        pageParams: { ...pageParams, length: matchesTotal },
                    };
                } else {
                    return { results: [], pageParams: { pageIndex: 0, pageSize: 0, length: 0 } };
                }
            }),
            catchError(() => {
                throw new WorkshopDomainSearchException();
            }),
        );
    }

    searchUsersApi(queryString: string, entityId: string): Observable<Partial<GeneralDataDTO>[]> {
        const entityIdSegment = entityId ? `&workshopId=${entityId}` : '';
        return this.http.get<Partial<GeneralDataDTO>[]>(
            `${this.API_PATH}/profiles/search/potential-organizers?q=${queryString}${entityIdSegment}`,
            {
                observe: 'body',
            },
        );
    }

    searchTopicUserByName(searchQuery: string, entityId: string): Observable<Partial<GeneralData>[]> {
        return this.searchUsersApi(searchQuery, entityId).pipe(
            map((searchResultsDTOs) =>
                searchResultsDTOs.map((dto) => this.serializer.deserializeWorkshopUserSearchResult(dto)),
            ),
        );
    }

    loadParticipantsWithSkills(workshopId: string): Observable<ParticipantsWithSkillsResponse> {
        return this.http.get<ParticipantsWithSkillsResponseDTO>(`${this.API_PATH}/${workshopId}/participants`).pipe(
            map((dto) => this.serializer.deserializeParticipantsWithSkillsResponse(dto)),
            catchError((error: Error) => this.handleEntityError(error)),
        );
    }

    private handleEntityRegistrationError(error: Error): never {
        if (!this.isHttpErrorResponse(error)) {
            throw new WorkshopUnexpectedException(error);
        }

        switch (error.status) {
            case 409:
                throw new WorkshopEntityRegistrationFullException();
            default:
                throw new WorkshopUnexpectedException(error.error);
        }
    }

    private handleEntityError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new WorkshopEntityBadRequestException(error.error);
                case 403:
                    throw new WorkshopEntityForbiddenException();
                case 404:
                    this.entityNotFoundService.displayDialog('/workshop/search');
                    throw new WorkshopEntityNotFoundException();
                default:
                    throw error;
            }
        } else {
            throw new WorkshopUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
