import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'tp-entity-not-found-dialog',
    templateUrl: './entity-not-found-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'entity-not-found',
    },
})
export class EntityNotFoundDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public searchUrl: unknown[] | string, private readonly router: Router) {}

    routerNavigate(): void {
        this.router.navigateByUrl(this.searchUrl.toString());
    }
}
