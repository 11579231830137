import { Injectable } from '@angular/core';
import { SkillLevel } from './skill.types';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SkillService {
    public showSkillsContainer$ = new Subject<string>();

    getLevelLabel(level: SkillLevel) {
        return `SKILL_LEVEL_${level}`;
    }

    getAllSkillsLevels(): SkillLevel[] {
        return [SkillLevel.JUNIOR, SkillLevel.SENIOR, SkillLevel.EXPERT];
    }
}
