import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule, PopoverModule } from '@tploy-enterprise/tenant-common';
import { GenerationExchangeProfileTitleComponent } from './generation-exchange-profile-title.component';
import { GenerationExchangeProfileTileLocationsPopoverComponent } from './generation-exchange-profile-tile-locations-popover.component';
import { GenerationExchangeProfileTileLocationsComponent } from './generation-exchange-profile-tile-locations.component';
import { GenerationExchangeProfileTileLocationIndependentComponent } from './generation-exchange-profile-tile-location-independent.component';
import { GenerationExchangeProfileTileLocationIndependentPopoverComponent } from './generation-exchange-profile-tile-location-independent-popover.component';
import { MatIconModule } from '@angular/material/icon';
import { AccountListItemModule } from '@tploy-enterprise/tenant-core';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        GenerationExchangeProfileTitleComponent,
        GenerationExchangeProfileTileLocationsPopoverComponent,
        GenerationExchangeProfileTileLocationsComponent,
        GenerationExchangeProfileTileLocationIndependentComponent,
        GenerationExchangeProfileTileLocationIndependentPopoverComponent,
    ],
    imports: [
        CommonModule,
        LanguageModule,
        MatIconModule,
        AccountListItemModule,
        MatListModule,
        PopoverModule,
        MatButtonModule,
    ],
    exports: [GenerationExchangeProfileTitleComponent],
})
export class GenerationExchangeProfileTitleModule {}
