import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgControl } from '@angular/forms';

@Component({
    selector: 'tp-tags-suggestion',
    templateUrl: './tags-suggestion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'tags-suggestion',
    },
})
export class TagsSuggestionComponent implements OnInit, OnDestroy, AfterContentInit {
    @Input()
    set tags(value: Array<string>) {
        this._tags = value;
    }

    get tags(): Array<string> {
        if (this.showAllSkills) {
            return this.getFilteredTags(this.allTags);
        } else {
            return this.getFilteredTags(this._tags);
        }
    }

    private _tags: Array<string> = [];

    isShowModalForHide = {};

    @Input()
    label = '';

    @Input()
    showCloseIcon = false;

    @Input()
    set allTags(value: Array<string>) {
        this._allTags = value;
    }

    get allTags(): Array<string> {
        return this._allTags;
    }

    ngAfterContentInit() {
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
    }

    private _allTags: Array<string> = [];

    get allTagsWithoutSelected(): Array<string> {
        return this.getFilteredTags(this.allTags);
    }

    private getFilteredTags(tags): Array<string> {
        const blacklistedNames = (this.control.value || []).map((tag) => tag.toLowerCase());
        return tags.filter((tag) => blacklistedNames.indexOf(tag.toLowerCase()) === -1);
    }

    @Input()
    set showSelectAll(value: boolean) {
        this._showSelectAll = value;
    }

    get showSelectAll(): boolean {
        return this._showSelectAll;
    }

    private _showSelectAll = true;

    @Input()
    set showConfigIcon(value: boolean) {
        this._showConfigIcon = value;
    }

    get showConfigIcon(): boolean {
        return this._showConfigIcon;
    }

    private _showConfigIcon = true;

    set showAllSkills(value: boolean) {
        this._showAllSkills = value;
    }

    get showAllSkills(): boolean {
        return this._showAllSkills;
    }

    private _showAllSkills = false;

    @Input()
    set skillsMode(value: string) {
        this._skillsMode = value;
    }

    get skillsMode(): string {
        return this._skillsMode;
    }

    private _skillsMode = '';

    @Output()
    select: EventEmitter<string> = new EventEmitter();

    @Output()
    selectAllTags: EventEmitter<string[]> = new EventEmitter();

    @Output()
    removeAllTags: EventEmitter<boolean> = new EventEmitter();

    @Output()
    toogleShowAllMode: EventEmitter<any> = new EventEmitter();

    @Output()
    hideSkills: EventEmitter<boolean> = new EventEmitter();

    @Output()
    hideSkill: EventEmitter<any> = new EventEmitter();

    @ViewChildren(MatChip, { read: ElementRef })
    chips: QueryList<ElementRef>;

    private readonly destroy$ = new Subject<void>();

    constructor(private readonly changeDetectorRef: ChangeDetectorRef, public control: NgControl) {}

    ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeDetectorRef.markForCheck());
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    selectTag(tag: string) {
        this.select.emit(tag);
    }

    openModalForHideSkill({ event, value }) {
        event.stopPropagation();
        event.preventDefault();
        this.isShowModalForHide = {};
        this.isShowModalForHide[value] = true;
    }

    closeModalForHideSkill(event, skill) {
        event.stopPropagation();
        event.preventDefault();
        this.isShowModalForHide = {};
    }

    hideTag(event, tag) {
        event.stopPropagation();
        event.preventDefault();
        this.hideSkill.emit(tag);
    }

    trackByName(index: number, tag: string) {
        return tag.toLowerCase();
    }

    hideSkillsContainer(event): void {
        this.hideSkills.emit(true);
        event.preventDefault();
        event.stopPropagation();
    }

    public selectAll() {
        this.selectAllTags.emit(this.allTags);
    }

    public removeTags(): void {
        this.removeAllTags.emit(true);
    }

    public toggleShowAll(): void {
        this.showAllSkills = !this.showAllSkills;
        this.toogleShowAllMode.emit({
            showAllSkills: this.showAllSkills,
            skillsMode: this.skillsMode,
        });
    }
}
