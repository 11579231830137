import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { NLAState } from '../../nla.reducer';
import { selectMatches, selectMatchesTotal } from '../../nla.selectors';
import { NLAMatch } from '../../nla-service/nla.types';
import { NLAActions } from '../../nla.actions';
import { filter } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { PageParams, QueryParamKey, UrlUtilService } from '@tploy-enterprise/tenant-core';

@Component({
    selector: 'tp-nla-matches',
    templateUrl: './nla-matches.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NLAMatchesComponent implements OnInit, OnDestroy {
    matches$: Observable<NLAMatch[]>;
    matchesTotal$: Observable<number>;
    isLoading$: Observable<boolean>;
    isLoadingFirstly$: Observable<boolean>;
    private readonly destroy$ = new Subject<void>();

    pageSize = 3;
    pageParams: PageParams = { pageIndex: 0, pageSize: this.pageSize };

    constructor(
        private readonly store: Store<{ neverLunchAlone: NLAState }>,
        private readonly urlUtilService: UrlUtilService,
    ) {}

    ngOnInit(): void {
        this.matches$ = this.store.select(selectMatches).pipe(filter((matches) => !!matches));
        this.matchesTotal$ = this.store.select(selectMatchesTotal);
        this.isLoadingFirstly$ = this.store.select((state) => {
            return (
                state.neverLunchAlone.matches.loading &&
                !state.neverLunchAlone.matches.loaded &&
                !state.neverLunchAlone.matches.error
            );
        });
        this.isLoading$ = this.store.select((state) => state.neverLunchAlone.matches.loading);
        this.dispatchLoadMatches();
    }

    onPageChange(pageEvent: PageEvent): void {
        this.urlUtilService
            .updateQueryParams({
                [QueryParamKey.PAGE_INDEX]: pageEvent.pageIndex,
                [QueryParamKey.PAGE_SIZE]: pageEvent.pageSize,
            })
            .then(() => this.dispatchLoadMatches());
    }

    dispatchLoadMatches(): void {
        this.store.dispatch(
            NLAActions.loadMatches({
                pageParams: this.urlUtilService.getPageParams(this.pageSize),
            }),
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
