import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NlaDomainComponent } from './nla-domain.component';
import { AccountListItemModule, MainLayoutModule, MatchesSliderModule } from '@tploy-enterprise/tenant-core';
import { NLADomainRoutingModule } from './nla-domain-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NLAMatchesComponent } from './nla-matches/nla-matches.component';
import { NLASearchResultsComponent } from './nla-search-results/nla-search-results.component';
import { NLABookmarksComponent } from './nla-bookmarks/nla-bookmarks.component';
import { NLAMatchesViewComponent } from './nla-matches-view/nla-matches-view.component';
import { NLAMatchesPlaceholderComponent } from './nla-matches-placeholder/nla-matches-placeholder.component';
import { NLAMatchComponent } from './nla-match/nla-match.component';
import {
    AddProfileModule,
    CardModule,
    MatchingLoaderModule,
    PopoverModule,
    SimpleTaggerModule,
} from '@tploy-enterprise/tenant-common';
import { ButtonModule } from '@tandemploy/ngx-components';
import { NLASearchResultsViewComponent } from './nla-search-results-view/nla-search-results-view.component';
import { NLASearchResultsPlaceholderComponent } from './nla-search-results-placeholder/nla-search-results-placeholder.component';
import { NLASearchResultComponent } from './nla-search-result/nla-search-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NLABookmarksViewComponent } from './nla-bookmarks-view/nla-bookmarks-view.component';
import { NLABookmarksPlaceholderComponent } from './nla-bookmarks-placeholder/nla-bookmarks-placeholder.component';
import { NLABookmarkComponent } from './nla-bookmark/nla-bookmark.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { PaginatorTpModule, SearchLoaderModule } from '@tploy-enterprise/tenant-core';
import { NLAProfileTitleModule } from './common/nla-profile-title/nla-profile-title.module';
import { TabModule } from '@tandemploy/ngx-components';

@NgModule({
    declarations: [
        NlaDomainComponent,
        NLAMatchesComponent,
        NLAMatchesViewComponent,
        NLAMatchesPlaceholderComponent,
        NLAMatchComponent,
        NLABookmarksComponent,
        NLABookmarksViewComponent,
        NLABookmarksPlaceholderComponent,
        NLABookmarkComponent,
        NLASearchResultsComponent,
        NLASearchResultsViewComponent,
        NLASearchResultsPlaceholderComponent,
        NLASearchResultComponent,
    ],
    imports: [
        CommonModule,
        NLADomainRoutingModule,
        ButtonModule,
        MainLayoutModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        TabModule,
        CardModule,
        AccountListItemModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        SimpleTaggerModule,
        MatTooltipModule,
        MatBadgeModule,
        MatListModule,
        PopoverModule,
        AddProfileModule,
        MatchingLoaderModule,
        SearchLoaderModule,
        PaginatorTpModule,
        NLAProfileTitleModule,
        MatchesSliderModule,
    ],
})
export class NLADomainModule {}
