import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '../language';
import { CommonLoaderModule } from '../loader';
import { MatIconModule } from '@angular/material/icon';
import { ProfileViewListFieldComponent } from './list-field/profile-view-list-field.component';
import { ProfileViewTextFieldComponent } from './text-field/profile-view-text-field.component';
import { ProfileViewFieldsetComponent } from './fieldset/profile-view-fieldset.component';
import { ProfileViewSectionTitle } from './section-title/profile-view-section-title.directive';
import { ProfileViewIconButtonDirective } from './icon-button/profile-view-icon-button.directive';
import { ProfileViewTextButtonDirective } from './text-button/profile-view-text-button.directive';
import { ProfileViewSubsectionPlaceholderComponent } from './subsection-placeholder/profile-view-subsection-placeholder.component';
import { ProfileViewSubsectionTitleDirective } from './subsection-title/profile-view-subsection-title.directive';
import { ProfileViewDatesFieldComponent } from './dates-field/profile-view-dates-field.component';
import { ProfileNavigationButtonComponent } from './profile-navigator/navigator-button/navigator-button.component';
import { ProfileNavigationComponent } from './profile-navigator/navigator.component';
import { ProfileNavigatorDirective } from './profile-navigator/navigator.directive';
import { LocalizedDateModule } from '../localized-date';
import { ProfileViewStringListFieldComponent } from './list-field/profile-view-string-list-field.component';

const profileViewFields = [
    ProfileViewListFieldComponent,
    ProfileViewStringListFieldComponent,
    ProfileViewTextFieldComponent,
    ProfileViewFieldsetComponent,
    ProfileViewDatesFieldComponent,
    ProfileViewSectionTitle,
    ProfileViewIconButtonDirective,
    ProfileViewTextButtonDirective,
    ProfileViewSubsectionPlaceholderComponent,
    ProfileViewSubsectionTitleDirective,
    ProfileNavigationComponent,
    ProfileNavigationButtonComponent,
    ProfileNavigatorDirective,
];

@NgModule({
    declarations: [...profileViewFields, ProfileViewFieldsetComponent],
    imports: [CommonModule, LanguageModule, CommonLoaderModule, LocalizedDateModule, MatIconModule],
    exports: [...profileViewFields],
})
export class ProfileContentModule {}
