import { NgModule } from '@angular/core';
import { CloudSelectorComponent } from './cloud-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { SelectModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [CommonModule, MatSelectModule, MatOptionModule, SelectModule],
    declarations: [CloudSelectorComponent],
    exports: [CloudSelectorComponent],
})
export class CloudSelectorModule {}
