export interface CloudNodeMetadata {
    isLeaf: boolean;
    level: number;
    parent: CloudNode;
}

export interface CloudNode {
    name: string;
    metadata: CloudNodeMetadata;
    showAsParent?: boolean;
    translationKey?: string;
    originalKey?: string;
}

export interface CloudData {
    name: string;
    children?: Array<CloudData>;
    showAsParent?: boolean;
    translationKey?: string;
    originalKey?: string;
}

type NodeIdentifier = string | CloudNode;

export class Cloud {
    get root(): CloudNode {
        return this._root ? this._root : null;
    }
    private _root: CloudNode = null;
    private readonly nodesMap: Map<string, CloudNode> = new Map();
    private readonly nodesList: Array<CloudNode> = [];

    static from(data: CloudData): Cloud {
        const instance = new Cloud();
        instance.loadData(data);
        return instance;
    }

    getChildCloud(nodeName: string): Cloud {
        const rootNode = this.nodesMap.get(nodeName.toLowerCase());

        const instance = new Cloud();
        instance._root = rootNode;
        instance.addNode(rootNode);

        const childrenNodes = this.findChildrenNodes(rootNode);

        const queue = [];
        queue.push(...childrenNodes);

        while (queue.length > 0) {
            const nodeToProcess = queue.shift();
            instance.addNode(nodeToProcess);

            const nodeToProcessChildren = this.findChildrenNodes(nodeToProcess);
            queue.unshift(...nodeToProcessChildren);
        }

        return instance;
    }

    getList(): Array<CloudNode> {
        return this.nodesList;
    }

    findParent(id: NodeIdentifier): CloudNode {
        const node = this.locateNode(id);

        if (node) {
            return node.metadata.parent ? node.metadata.parent : null;
        } else {
            return null;
        }
    }

    findAncestors(id: NodeIdentifier): Array<CloudNode> {
        const node = this.locateNode(id);

        if (!node) {
            return [];
        }

        const ancestors: Array<CloudNode> = [];
        let parent = node.metadata.parent;

        while (parent !== null) {
            ancestors.push(parent);
            parent = parent.metadata.parent;
        }

        return ancestors;
    }

    findChildren(id: NodeIdentifier): Array<CloudNode> {
        return this.findChildrenNodes(id);
    }

    private findChildrenNodes(id: NodeIdentifier): Array<CloudNode> {
        const node = this.locateNode(id);

        if (!node) {
            return [];
        }
        return this.nodesList.filter(
            (candidate) =>
                candidate.metadata.parent && candidate.metadata.parent.name.toLowerCase() === node.name.toLowerCase(),
        );
    }

    findDescendants(id: NodeIdentifier): Array<CloudNode> {
        const node = this.locateNode(id);

        if (!node) {
            return [];
        }

        const descendants = [];
        let children = this.findChildren(node);

        while (children.length > 0) {
            descendants.push(...children);
            children = children.reduce((childrenChildren, child) => {
                childrenChildren.push(...this.findChildren(child));
                return childrenChildren;
            }, []);
        }

        return descendants;
    }

    isLeaf(id: NodeIdentifier) {
        const node = this.locateNode(id);
        if (!node) {
            return true;
        }

        return node.metadata.isLeaf === true && !node.showAsParent;
    }

    locateNode(id: NodeIdentifier): CloudNode {
        if (typeof id === 'string') {
            return this.nodesMap.get(id.toLowerCase());
        } else {
            return id;
        }
    }

    private loadData(data: CloudData, level = 1, parent = null) {
        const node = this.createNode(data, level, parent);
        if (parent === null) {
            this._root = node;
        }

        if (data.children) {
            data.children.forEach((child) => {
                this.loadData(child, level + 1, node);
            });
        }
    }

    private createNode(nodeData: CloudData, level: number, parent: CloudNode): CloudNode {
        const existingNode = this.nodesMap.get(nodeData.name);
        if (!existingNode) {
            const node = {
                name: nodeData.name,
                metadata: {
                    isLeaf: !nodeData.children || nodeData.children.length === 0,
                    level,
                    parent,
                },
                showAsParent: nodeData.showAsParent,
                translationKey: nodeData.translationKey,
                originalKey: nodeData.originalKey,
            };

            this.addNode(node);
            return node;
        } else {
            throw new Error(
                `Trying to create a node that already exists. Please check the provided data (name: ${existingNode.name})`,
            );
        }
    }

    private addNode(node: CloudNode) {
        this.nodesMap.set(node.name.toLowerCase(), node);
        this.nodesList.push(node);
    }
}
