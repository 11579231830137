import { createAction, props } from '@ngrx/store';
import { Conversation } from '../models';

export const DirectMessagesOwnershipPermissionsActions = {
    promoteUserToOwner: createAction(
        '[DM-Permissions] Promote User to Owner',
        props<{ conversationId: string; participantId: string }>(),
    ),
    promoteUserToOwnerSuccess: createAction(
        '[DM-Permissions][Success] Promote User to Owner',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    promoteUserToOwnerError: createAction('[DM-Permissions][Error] Promote User to Owner', props<{ error: Error }>()),

    demoteUserFromOwner: createAction(
        '[DM-Permissions] Demote User from Owner',
        props<{ conversationId: string; participantId: string }>(),
    ),
    demoteUserFromOwnerSuccess: createAction(
        '[DM-Permissions][Success] Demote User from Owner',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    demoteUserFromOwnerError: createAction('[DM-Permissions][Error] Demote User from Owner', props<{ error: Error }>()),

    promotedToOwner: createAction(
        '[DM-Permissions] Promoted to Owner',
        props<{ conversationId: string; participantId: string }>(),
    ),
    promotedToOwnerSuccess: createAction(
        '[DM-Permissions][Success] Promoted to Owner',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    promotedToOwnerError: createAction('[DM-Permissions][Error] Promoted to Owner', props<{ error: Error }>()),

    demotedFromOwner: createAction(
        '[DM-Permissions] Demoted from Owner',
        props<{ conversationId: string; participantId: string }>(),
    ),
    demotedFromOwnerSuccess: createAction(
        '[DM-Permissions][Success] Demoted from Owner',
        props<{ conversations: Conversation[]; currentConversation: Conversation }>(),
    ),
    demotedFromOwnerError: createAction('[DM-Permissions][Error] Demoted from Owner', props<{ error: Error }>()),
};
