import { ExperienceData, EXPERIENCE_STORAGE_EDIT_KEY } from './experience.types';
import { createReducer, on, combineReducers } from '@ngrx/store';
import { ExperienceActions } from './experience.actions';
import { AccountActions } from '../account.actions';
import { SuccessFactorsActions } from '../../success-factors';

interface ExperienceEditState {
    dirty: boolean;
    data: ExperienceData;
}

interface ExperienceCurrentState {
    loading: boolean;
    loadingError: Error;
    saving: boolean;
    savingError: Error;
    data: ExperienceData;
}

export interface ExperienceState {
    edit: ExperienceEditState;
    current: ExperienceCurrentState;
}

const defaultExperience: ExperienceData = {
    education: [],
    positions: [],
    skillsOrQualifications: [],
    yearsOfExperience: 0,
    pushToSuccessFactor: false,
};

const initialEditExperienceState: ExperienceEditState = {
    dirty: false,
    data: defaultExperience,
};

function getInitialEditState(): ExperienceEditState {
    const savedState = window.sessionStorage.getItem(EXPERIENCE_STORAGE_EDIT_KEY);
    if (savedState) {
        return JSON.parse(savedState);
    } else return { ...initialEditExperienceState };
}

const initialCurrentExperienceState: ExperienceCurrentState = {
    loading: false,
    loadingError: null,
    saving: false,
    savingError: null,
    data: defaultExperience,
};

const editExperienceReducer = createReducer(
    getInitialEditState(),
    on(AccountActions.loadSuccess, (state, { account }) => ({ ...state, data: account.experience, dirty: false })),
    on(AccountActions.deleteSuccess, () => ({ ...initialEditExperienceState })),
    on(ExperienceActions.edit, (state, { experienceData }) => ({ ...state, data: experienceData, dirty: true })),
    on(ExperienceActions.saveSuccess, (state, { experienceData }) => ({
        ...state,
        data: experienceData,
        dirty: false,
    })),
);

const currentExperienceReducer = createReducer(
    initialCurrentExperienceState,
    on(AccountActions.load, (state) => ({ ...state, loading: true })),
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        data: account.experience || initialCurrentExperienceState.data,
        loading: false,
        loadingError: null,
    })),
    on(SuccessFactorsActions.loadSuccessFactorsUserDataSuccess, (state, { skills }) => ({
        ...state,
        data: {
            ...state.data,
            skillsOrQualifications: skills.map((skill) => skill.name),
        },
    })),
    on(AccountActions.deleteSuccess, () => ({ ...initialCurrentExperienceState })),
    on(ExperienceActions.save, (state) => ({ ...state, saving: true })),
    on(ExperienceActions.saveSuccess, (state, { experienceData }) => ({
        ...state,
        data: experienceData,
        saving: false,
        savingError: null,
    })),
    on(ExperienceActions.saveError, (state, { error }) => ({ ...state, savingError: error, saving: false })),
);

export const experienceReducer = combineReducers({
    edit: editExperienceReducer,
    current: currentExperienceReducer,
});
