import { DeviceInfo } from 'ngx-device-detector';

export type LogData = {
    requestPath?: string;
    url?: string;
    trace?: string;
    elapsedTime?: number;
    routerStack?: string;
    status?: number;
    statusText?: string;
    isMobile?: boolean;
    isDesktop?: boolean;
    isTablet?: boolean;
};

export type LogEntry = {
    type: LogType;
    message: string;
    data: LogData | (LogData & DeviceInfo & Location);
};

export enum LoggerEvents {
    Flush = 1,
}

export enum HTTPLogStatus {
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export enum LogType {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    FATAL = 'fatal',
    DEBUG = 'debug',
}
