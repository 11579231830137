<h3 id="search_label">{{ 'NLA_SEARCH_HEADER' | translatable }}</h3>
<div class="search-layout">
    <div class="search-layout_input">
        <form autocomplete="off" [formGroup]="form">
            <mat-form-field appearance="outline">
                <mat-icon matPrefix svgIcon="search"></mat-icon>
                <tp-simple-tagger
                    formControlName="searchTagger"
                    (keydown.enter)="triggerSearch($event)"
                    suggestSkills
                    #suggestions="suggestSkills"
                    [suggestions]="suggestions.$ | async"
                    attr.aria-labelledby="search_label"
                ></tp-simple-tagger>
            </mat-form-field>
            <mat-error *ngIf="isResultsEmpty">{{ 'NLA_SEARCH_EMPTY_MESSAGE' | translatable }}</mat-error>
        </form>
    </div>
    <div class="search-layout_button">
        <tp-primary-icon-button
            tpLoader
            #container="loaderContainer"
            [showUntil]="buttonClicked && ((isSearchLoading$ | async) || !hasResults)"
            (click)="buttonTriggerSearch($event)"
            (keydown.enter)="buttonTriggerSearch($event)"
            [icon]="'search'"
            class="call-to-action small"
        >
            <span class="call-to-action__text">
                {{ 'NLA_SEARCH_BTN_TEXT' | translatable }}
            </span>
        </tp-primary-icon-button>
    </div>
</div>

<ng-container *ngIf="!(isSearchLoading$ | async) && !isAutocompleteLoading && results; else loadingSearch">
    <ng-template #noResults>
        <tp-nla-search-results-placeholder class="grid-layout"></tp-nla-search-results-placeholder>
    </ng-template>

    <ng-container *ngIf="hasResults; else noResults">
        <div class="account-tiles-container">
            <tp-nla-search-result *ngFor="let result of results" [result]="result"></tp-nla-search-result>
        </div>
        <tp-paginator [pageParams]="resultPageParams" (page)="onPageChange($event)"> </tp-paginator>
    </ng-container>
</ng-container>

<ng-template #loadingSearch>
    <tp-search-loader [showAlways]="true" class="loader"></tp-search-loader>
</ng-template>
