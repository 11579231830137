import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, NavigationError, Router, ActivatedRoute } from '@angular/router';
import { MatomoService } from './core-modules/matomo';
import { RedirectService } from './redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';

@Component({
    selector: 'tp-tenant-core',
    templateUrl: './tenant-core.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TenantCoreComponent {
    constructor(
        private readonly matomoService: MatomoService,
        private readonly router: Router,
        private readonly redirectService: RedirectService,
        private readonly translateService: TranslateService,
        private titleService: Title,
    ) {}

    private scrollTimer = null;

    ngOnInit(): void {
        this.matomoService.attachInstance();
        this.registerRouteEvents();

        document.addEventListener(
            'scroll',
            (event) => {
                const element = (event ? event.target : null) as HTMLElement;
                if (element?.classList) {
                    element.classList.add('scrollable');
                    if (!this.scrollTimer) {
                        this.scrollTimer = setTimeout(() => {
                            element.classList.remove('scrollable');
                            this.scrollTimer = null;
                        }, 750);
                    }
                }
            },
            true,
        );

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let route: ActivatedRoute = this.router.routerState.root;
                    let routeTitle = this.translateService.instant('PAGE_TITLE_DEFAULT');
                    while (route!.firstChild) {
                        route = route.firstChild;
                    }

                    if (route.snapshot.data['title']) {
                        routeTitle = route!.snapshot.data['title'];
                    }
                    return routeTitle;
                }),
            )
            .subscribe((title: string) => {
                if (title) {
                    this.titleService.setTitle(`${this.translateService.instant(title)}`);
                }
            });
    }

    private registerRouteEvents(): void {
        let previousUrl: string;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationError && !this.redirectService.isNavigationStepError(event.error)) {
                this.router.navigateByUrl('/');
            }
            if (event instanceof NavigationEnd) {
                if (!previousUrl || previousUrl !== event.urlAfterRedirects) {
                    this.matomoService.trackPageView(event.urlAfterRedirects, previousUrl);
                }
                previousUrl = event.urlAfterRedirects;
            }
        });
    }
}
