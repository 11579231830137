import { createAction, props } from '@ngrx/store';
import {
    JobXBookmark,
    JobXEntity,
    JobXProfile,
    JobXProfileMatch,
    JobXProfileSearchResult,
    JobXSearchResult,
    JobXSuggestedSkills,
} from './jobs.types';
import { PageParams } from '@tploy-enterprise/tenant-core';
import { Skill } from '@tploy-enterprise/tenant-common';

export const JobsActions = {
    featureInit: createAction('[JobX] Init'),

    loadProfile: createAction('[JobX] Load'),
    loadProfileSuccess: createAction('[JobX][Success] Load', props<{ profile: JobXProfile; session: JobXProfile }>()),
    loadProfileError: createAction('[JobX][Error] Load', props<{ error: Error; session: JobXProfile }>()),

    loadOthersProfile: createAction('[JobX] Load other profile'),
    loadOthersProfileSuccess: createAction('[JobX][Success] Load other profile'),
    loadOthersProfileError: createAction('[JobX][Error] Load other profile'),

    editCurrentProfile: createAction('[JobX] Change edit profile', props<{ currentProfile: Partial<JobXProfile> }>()),
    cancelCurrentProfileEdit: createAction('[JobX] Cancel edition'),

    saveProfile: createAction('[JobX] Save profile'),
    saveProfileSuccess: createAction('[JobX][Success] Save profile', props<{ currentProfile: JobXProfile }>()),
    saveProfileError: createAction(
        '[JobX][Error] Save profile',
        props<{ currentProfile: JobXProfile; error: Error }>(),
    ),

    loadSuggestedSkills: createAction('[JobX] Load Suggested Skills', props<{ id: string }>()),
    loadSuggestedSkillsSuccess: createAction(
        '[JobX][Success] Load Suggested Skills',
        props<{ suggestedSkills: JobXSuggestedSkills }>(),
    ),
    loadSuggestedSkillsError: createAction('[JobX][Error] Load Suggested Skills', props<{ error: Error }>()),

    createEntity: createAction('[JobX] Create entity', props<{ entity: JobXEntity }>()),
    createEntitySuccess: createAction('[JobX][Success] Create entity', props<{ entity: JobXEntity }>()),
    createEntityError: createAction('[JobX][Error] Create entity', props<{ error: Error }>()),

    saveEntity: createAction('[JobX] Save entity', props<{ entity: JobXEntity }>()),
    saveEntitySuccess: createAction('[JobX][Success] Save entity', props<{ entity: JobXEntity }>()),
    saveEntityError: createAction('[JobX][Error] Save entity', props<{ error: Error }>()),

    deleteEntity: createAction('[JobX] Delete entity', props<{ entityId: string }>()),
    deleteEntitySuccess: createAction('[JobX][Success] Delete entity', props<{ entityId: string }>()),
    deleteEntityError: createAction('[JobX][Error] Delete entity', props<{ error: Error }>()),

    publishEntity: createAction('[JobX] Publish entity', props<{ entityId: string }>()),
    publishEntitySuccess: createAction('[JobX][Success] Publish entity', props<{ entity: JobXEntity }>()),
    publishEntityError: createAction('[JobX][Error] Publish entity', props<{ error: Error }>()),

    searchEntities: createAction('[JobX] Search entities', props<{ searchQuery: string; pageParams: PageParams }>()),
    searchEntitiesSuccess: createAction(
        '[JobX][Success] Search entities',
        props<{ entities: JobXSearchResult[]; pageParams: PageParams }>(),
    ),
    searchEntitiesError: createAction('[JobX][Error] Search entities', props<{ error: Error }>()),

    loadEntitiesOfferedByMe: createAction('[JobX] Load entities offered by me'),
    loadEntitiesOfferedByMeSuccess: createAction(
        '[JobX][Success] Load entities offered by me',
        props<{ entities: JobXEntity[] }>(),
    ),
    loadEntitiesOfferedByMeError: createAction('[JobX][Error] Load entities offered by me', props<{ error: Error }>()),

    loadMatches: createAction('[JobX] Load Matches', props<{ pageParams: PageParams; campaigns: string[] }>()),
    loadMatchesSuccess: createAction(
        '[JobX][Success] Load Matches',
        props<{
            entities: Array<JobXProfileMatch>;
            allEntities: Array<JobXProfileMatch>;
            pageParams: PageParams;
            selectedCampaigns?: string[];
        }>(),
    ),
    loadMatchesError: createAction('[JobX][Error] Load Matches', props<{ error: Error }>()),

    syncSuccessFactorsSkills: createAction(
        '[JobX SuccessFactors SyncSkills][Success] sync',
        props<{ skills: Skill[] }>(),
    ),
    syncProfileSkills: createAction('[JobX] Sync profile skills'),

    searchProfiles: createAction(
        '[JobX] Search profiles',
        props<{ searchQuery: string; pageParams: PageParams; campaigns: string[] }>(),
    ),
    searchProfilesSuccess: createAction(
        '[JobX][Success] Search profiles',
        props<{
            profiles: JobXProfileSearchResult[];
            allProfiles: JobXProfileSearchResult[];
            pageParams: PageParams;
        }>(),
    ),
    searchProfilesError: createAction('[JobX][Error] Search profiles', props<{ error: Error }>()),
    resetSearchProfilesResults: createAction('[JobX] Reset search profiles results'),

    loadBookmarks: createAction('[JobX] Load Bookmarks'),
    loadBookmarksSuccess: createAction('[JobX][Success] Load Bookmarks', props<{ data: Array<JobXBookmark> }>()),
    loadBookmarksError: createAction('[JobX][Error] Load Bookmarks', props<{ error: Error }>()),

    loadSearchResult: createAction('[JobX] Load search result', props<{ searchQuery: string; index: number }>()),
    loadSearchResultSuccess: createAction(
        '[JobX] [Success] Load search result',
        props<{ data: JobXProfileSearchResult; index: number }>(),
    ),
    loadMatchResult: createAction('[JobX] Load match result', props<{ index: number }>()),
    loadMatchResultSuccess: createAction(
        '[JobX] [Success] Load match result',
        props<{ data: JobXProfileMatch; index: number }>(),
    ),
    loadResultError: createAction('[JobX] [Error] Load result', props<{ error: Error }>()),
};
