import { RoutingSequence, RoutingStep, ROUTING_SEQUENCE_STORAGE_KEY } from './routing-sequence.types';
import { createReducer, on } from '@ngrx/store';
import { RoutingSequenceActions } from './routing-sequence.actions';

export interface RoutingSequenceState {
    current: number;
    sequence: RoutingStep[];
    onComplete: RoutingStep;
    onAbort: RoutingStep;
}

function getInitialRoutingSequenceState(): RoutingSequenceState {
    const serialized = window.localStorage.getItem(ROUTING_SEQUENCE_STORAGE_KEY);
    if (serialized) {
        return JSON.parse(serialized);
    } else {
        return initialRoutingSequenceState;
    }
}

export const initialRoutingSequenceState: RoutingSequenceState = {
    current: null,
    sequence: null,
    onComplete: null,
    onAbort: null,
};

export const routingSequenceReducer = createReducer(
    getInitialRoutingSequenceState(),
    on(RoutingSequenceActions.start, (state, { sequence }) => startRoutingSequence(state, sequence)),
    on(RoutingSequenceActions.aborted, (state) => abortRoutingSequence(state)),
    on(RoutingSequenceActions.navigated, (state, { step }) => updateIndex(state, step)),
    on(RoutingSequenceActions.completed, (state) => completedRoutingSequence(state)),
);

function startRoutingSequence(state: RoutingSequenceState, sequence: RoutingSequence): RoutingSequenceState {
    return {
        current: 0,
        sequence: sequence.routes,
        onAbort: sequence.onAbort,
        onComplete: sequence.onComplete,
    };
}

function abortRoutingSequence(state: RoutingSequenceState): RoutingSequenceState {
    return { ...initialRoutingSequenceState };
}

function updateIndex(state: RoutingSequenceState, index: number): RoutingSequenceState {
    return { ...state, current: index };
}

function completedRoutingSequence(state: RoutingSequenceState): RoutingSequenceState {
    return { ...initialRoutingSequenceState };
}
