export class MediaConversionException extends Error {
    constructor() {
        super('Impossible to convert the image data string to binary.');
    }
}

export class MediaResponsePayloadException extends Error {
    constructor() {
        super('MediaService POST response did not contain the expected "url" field.');
    }
}

export class MediaTooBigException extends Error {
    constructor() {
        super("The image's size exceeds the size limit.");
    }
}

export class MediaNotAnImageException extends Error {
    constructor() {
        super('The uploaded file was not recognized as a valid image.');
    }
}
export class MediaForbiddenException extends Error {
    constructor() {
        super('Request to media forbidden.');
    }
}
export class MediaNotFoundException extends Error {
    constructor() {
        super('Media not found.');
    }
}
