import { createReducer, on, combineReducers } from '@ngrx/store';
import { AccountSettingsActions } from './account-settings.actions';
import { AccountSettings } from './account-settings.types';
import { AccountActions } from '../account.actions';

const defaultSettings: AccountSettings = {
    notifications: {
        allowU2UMatchingNotifications: true,
        allowO2UMatchingNotifications: true,
        allowJobsharingNotifications: true,
        allowStaffingManagerDailyUpdateNotifications: false,
        directMessages: {
            enableNotifications: true,
            notificationInterval: '1h',
            notificationThreshold: 10,
        },
    },
    privacy: {
        showExperience: true,
        showQuestionnaire: true,
        showYearOfBirth: true,
    },
};

export interface AccountSettingsState {
    current: {
        saving: boolean;
        savingError: Error;
        loading: boolean;
        loadingError: Error;
        data: AccountSettings;
    };
    edit: {
        data: AccountSettings;
    };
}

const accountSettingsInitialState: AccountSettingsState = {
    current: {
        saving: false,
        savingError: null,
        loading: false,
        loadingError: null,
        data: defaultSettings,
    },
    edit: {
        data: defaultSettings,
    },
};

const currentReducer = createReducer(
    accountSettingsInitialState.current,
    on(AccountActions.load, (state) => ({ ...state, loading: true, loadingError: null })),
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        loading: false,
        loadingError: null,
        data: account.settings,
    })),
    on(AccountActions.loadError, (state, { error }) => ({ ...state, loading: false, loadingError: error })),
    on(AccountSettingsActions.save, (state) => ({ ...state, saving: true, savingError: null })),
    on(AccountSettingsActions.saveError, (state, { error }) => ({ ...state, saving: false, savingError: error })),
    on(AccountSettingsActions.saveSuccess, (state, { accountSettings }) => ({
        ...state,
        saving: false,
        savingError: null,
        data: accountSettings,
    })),
    on(AccountActions.deleteSuccess, () => ({ ...accountSettingsInitialState.current })),
);

const editReducer = createReducer(
    accountSettingsInitialState.edit,
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        data: account.settings,
    })),
    on(AccountSettingsActions.edit, (state, { accountSettings }) => ({
        ...state,
        data: { ...state.data, ...accountSettings },
    })),
    on(AccountSettingsActions.saveSuccess, (state, { accountSettings }) => ({ ...state, data: accountSettings })),
    on(AccountActions.deleteSuccess, () => ({ ...accountSettingsInitialState.edit })),
);

export const accountSettingsReducer = combineReducers({
    current: currentReducer,
    edit: editReducer,
});
