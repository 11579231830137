import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AddProfileComponent } from './add-profile.component';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '../language';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [CommonModule, ButtonModule, RouterModule, MatButtonModule, LanguageModule, MatIconModule],
    declarations: [AddProfileComponent],
    exports: [AddProfileComponent],
})
export class AddProfileModule {}
