import { NgModule } from '@angular/core';
import { CheckboxListComponent } from './checkbox-list.component';
import { CommonModule } from '@angular/common';
import { CheckboxListItemComponent } from './item/checkbox-list-item.component';
import { RadioListItemComponent } from './item/radio-list-item.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LanguageModule } from '../../language';

@NgModule({
    imports: [CommonModule, MatRadioModule, MatCheckboxModule, LanguageModule],
    declarations: [CheckboxListComponent, CheckboxListItemComponent, RadioListItemComponent],
    exports: [CheckboxListComponent],
})
export class CheckboxListModule {}
