import { NgModule } from '@angular/core';
import { FocusDirective } from './focus-directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [FocusDirective],
    exports: [FocusDirective],
})
export class FocusModule {}
