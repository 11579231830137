import { combineReducers, createReducer, on } from '@ngrx/store';
import { SelectedTopicsActions } from './selected-topics.actions';
import { ValidationErrors } from '../account.types';
import { SelectedTopics, SELECTED_TOPICS_STORAGE_EDIT_KEY } from './selected-topics.types';
import { AccountActions } from '../account.actions';

export interface SelectedTopicsState {
    current: {
        loading: boolean;
        loadingError: Error;
        saving: boolean;
        savingError: Error;
        data: SelectedTopics;
    };
    edit: {
        data: SelectedTopics;
        dirty: boolean;
        validationErrors: ValidationErrors;
    };
}

const selectedTopicsInitialState: SelectedTopicsState = {
    current: {
        data: [],
        loadingError: null,
        loading: false,
        saving: false,
        savingError: null,
    },
    edit: {
        data: [],
        dirty: false,
        validationErrors: null,
    },
};

function getInitialEditState() {
    const stored = window.localStorage.getItem(SELECTED_TOPICS_STORAGE_EDIT_KEY);
    if (stored) {
        return JSON.parse(stored);
    } else {
        return { ...selectedTopicsInitialState.edit };
    }
}

const editReducer = createReducer(
    getInitialEditState(),
    on(AccountActions.loadSuccess, (state, { account }) => {
        if (!state.dirty) {
            return { ...state, data: account.selectedTopics, dirty: false };
        } else {
            return state;
        }
    }),
    on(AccountActions.createSuccess, (state, { account }) => ({ ...state, data: account.selectedTopics })),
    on(SelectedTopicsActions.edit, (state, { selectedTopics, errors }) => ({
        ...state,
        data: selectedTopics,
        dirty: true,
        validationErrors: { ...state.validationErrors, ...errors },
    })),
    on(SelectedTopicsActions.resetErrors, (state) => ({
        ...state,
        validationErrors: null,
    })),
    on(SelectedTopicsActions.saveSuccess, (state, { selectedTopics }) => ({
        ...state,
        data: selectedTopics,
        dirty: false,
    })),
    on(AccountActions.deleteSuccess, () => ({ ...selectedTopicsInitialState.edit })),

    // remove the topic optimistically, the save success is still not confirmed
    on(SelectedTopicsActions.unselectTopic, (state, { topic }) => removeTopic(state, topic)),
);

const currentReducer = createReducer(
    selectedTopicsInitialState.current,
    on(AccountActions.load, (state) => ({ ...state, loading: true })),
    on(AccountActions.loadSuccess, (state, { account }) => ({
        ...state,
        loading: false,
        loadingError: null,
        data: account.selectedTopics,
    })),
    on(AccountActions.loadError, (state, { error }) => ({ ...state, loading: false, loadingError: error })),
    on(AccountActions.createSuccess, (state) => ({ ...state, loadingError: null })),
    on(SelectedTopicsActions.save, (state) => ({ ...state, saving: true })),
    on(SelectedTopicsActions.saveError, (state, { error }) => ({
        ...state,
        savingError: error,
        saving: false,
    })),
    on(SelectedTopicsActions.saveSuccess, (state, { selectedTopics }) => ({
        ...state,
        saving: false,
        savingError: null,
        data: selectedTopics,
    })),
    on(AccountActions.deleteSuccess, () => ({ ...selectedTopicsInitialState.current })),

    // remove the topic optimistically, the save success is still not confirmed
    on(SelectedTopicsActions.unselectTopic, (state, { topic }) => removeTopic(state, topic)),
);

export const selectedTopicsReducer = combineReducers({
    current: currentReducer,
    edit: editReducer,
});

function removeTopic(state: any, topicToRemove: string): any {
    const index = state.data.indexOf(topicToRemove);
    if (index === -1) {
        return state;
    } else {
        const newTopics = [...state.data];
        newTopics.splice(index, 1);

        return {
            ...state,
            data: newTopics,
        };
    }
}
