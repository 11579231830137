import { Store } from '@ngrx/store';
import { Notification, Registration } from '../models/workshop.types';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TopicWorkshopState } from './topic-workshop.reducer';
import { WorkshopService } from '../services/workshop.service';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { WorkshopMatchingActions } from './workshop-matching.actions';
import { AccountState, DirectMessagesJoinConversationActions, DEFAULT_PAGE_SIZE } from '@tploy-enterprise/tenant-core';
import { WorkshopProfileActions } from './workshop-profile.actions';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class WorkshopMatchingEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopMatchingActions.loadMatches),
            switchMap((action) =>
                this.service.loadMatches(action.pageParams).pipe(
                    map((result) =>
                        WorkshopMatchingActions.loadMatchesSuccess({
                            workshopMatchingEntries: result.matches,
                            pageParams: result.pageParams,
                        }),
                    ),
                    catchError((error) => {
                        return of(WorkshopMatchingActions.loadMatchesError({ error }));
                    }),
                ),
            ),
        ),
    );
    onProfileSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopProfileActions.saveSuccess),
            switchMap(() => {
                if (this.router.url.includes('/workshop/matching')) {
                    return of(
                        WorkshopMatchingActions.loadMatches({
                            pageParams: {
                                pageIndex: 0,
                                pageSize: DEFAULT_PAGE_SIZE,
                            },
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{
            topicWorkshop: TopicWorkshopState;
            account: AccountState;
        }>,
        private readonly service: WorkshopService,
        private readonly router: Router,
    ) {}

    startRegistration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopMatchingActions.startRegistration),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(this.store.select((state) => state.account.generalData.current.data.name)),
                ),
            ),
            switchMap(([action, userName]) =>
                this.service.startRegistration(action.workshopId, userName).pipe(
                    map((registration: Registration) =>
                        WorkshopMatchingActions.startRegistrationSuccess({
                            workshopId: action.workshopId,
                            registration,
                        }),
                    ),
                    catchError((error: Error) => of(WorkshopMatchingActions.startRegistrationError({ error }))),
                ),
            ),
        ),
    );

    finishRegistration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopMatchingActions.finishRegistration),
            switchMap((action) =>
                this.service.finishRegistration(action.workshopId, action.registrationId).pipe(
                    withLatestFrom(this.store.select('account')),
                    switchMap(([registration, account]) => {
                        return [
                            WorkshopMatchingActions.finishRegistrationSuccess({
                                workshopId: action.workshopId,
                                registration,
                            }),
                            DirectMessagesJoinConversationActions.joinGroupConversation({
                                originId: action.workshopId,
                                userId: account.userId,
                                generalData: {
                                    name: account.generalData.current?.data?.name || '',
                                    imageUrl: account.generalData?.current?.data?.imageUrl || '',
                                },
                                informTargetUser: true,
                            }),
                        ];
                    }),
                    catchError((error: Error) => of(WorkshopMatchingActions.finishRegistrationError({ error }))),
                ),
            ),
        ),
    );

    deleteRegistration$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WorkshopMatchingActions.deleteRegistration),
                switchMap((action) => this.service.cancelRegistration(action.workshopId, action.registrationId)),
                switchMap(() => EMPTY),
                catchError(() => EMPTY),
            ),
        { dispatch: false },
    );

    createNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopMatchingActions.createNotification),
            concatMap((action) => {
                return of(action).pipe(
                    withLatestFrom(this.store.select((state) => state.account.generalData.current.data.name)),
                );
            }),
            switchMap(([action, userName]) =>
                this.service.createNotification(action.workshopId, userName).pipe(
                    map((notification: Notification) =>
                        WorkshopMatchingActions.createNotificationSuccess({
                            workshopId: action.workshopId,
                            notification,
                        }),
                    ),
                    catchError((error: Error) => of(WorkshopMatchingActions.createNotificationError({ error }))),
                ),
            ),
        ),
    );
}
