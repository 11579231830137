import { Injectable } from '@angular/core';
import { TopicDefinition, TopicRegistryService } from '../../topic';
import { AccountState } from '../account.reducer';
import { createSelector } from '@ngrx/store';
import { isStaffingManager, SelectedTopics } from '../';

type State = { account: AccountState };

@Injectable({
    providedIn: 'root',
})
export class SelectedTopicsDefinitionsService {
    constructor(private topicsRegistryService: TopicRegistryService) {}

    available(): TopicDefinition[] {
        return this.topicsRegistryService.availableTopics();
    }

    selected = createSelector(
        (state: State) => state.account.selectedTopics.current.data,
        (selectedTopics: SelectedTopics): TopicDefinition[] => {
            try {
                return this.topicsRegistryService.selectTopicDefinitions(selectedTopics);
            } catch (e) {
                return [];
            }
        },
    );

    editing = createSelector(
        (state: State) => state.account.selectedTopics.edit.data,
        (selectedTopics: SelectedTopics): TopicDefinition[] => {
            try {
                return this.topicsRegistryService.selectTopicDefinitions(selectedTopics);
            } catch (e) {
                return [];
            }
        },
    );

    fromTopicList(topics: string[]): TopicDefinition[] {
        return this.topicsRegistryService.selectTopicDefinitions(topics);
    }

    newFromEditing = createSelector(
        (state: State) => state.account.selectedTopics.current.data,
        (state: State) => state.account.selectedTopics.edit.data,
        (selected: SelectedTopics, edition: SelectedTopics): TopicDefinition[] => {
            try {
                const diff = edition.filter((t) => selected.indexOf(t) === -1);
                return this.topicsRegistryService.selectTopicDefinitions(diff);
            } catch (e) {
                return [];
            }
        },
    );

    canAddMoreTopics = createSelector(
        (state: State) => this.selected(state),
        (defsFromSaved: TopicDefinition[]): boolean => {
            const available = this.available();
            return defsFromSaved.length < available.length;
        },
    );

    staffingManagerTopics = createSelector(
        (state: State): boolean => isStaffingManager(state),
        (isStaffingManager): TopicDefinition[] =>
            isStaffingManager ? this.available().filter((topicDef) => topicDef.staffingManager) : [],
    );
}
