import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileContentModule, LanguageModule } from '@tploy-enterprise/tenant-common';
import { NLAProfileViewComponent } from './nla-profile-view.component';

@NgModule({
    declarations: [NLAProfileViewComponent],
    imports: [CommonModule, ProfileContentModule, LanguageModule],
})
export class NlaProfileViewModule {}
