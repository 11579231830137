import { Injectable } from '@angular/core';
import { AccountService } from '../../account/account.service';
import { ContactSearchResult } from '../types/search.types';
import { Observable } from 'rxjs';

@Injectable()
export class SearchService {
    constructor(private accountService: AccountService) {}

    contactsSearch(query: string): Observable<ContactSearchResult[]> {
        return this.accountService.contactSearch(query);
    }
}
