<div [class]="styleType + '__facts-segment'">
    <mat-icon
        [matTooltip]="'LABEL_ENTITY_CAMPAIGNS' | translatable"
        [class]="styleType + '__facts-segment-icon'"
        matTooltipPosition="above"
        svgIcon="campaign"
    ></mat-icon>

    <div
        [matTooltip]="campaignToShow"
        [matTooltipDisabled]="!hasHiddenText(campaignToShow)"
        matTooltipPosition="above"
        tpTextHighlighter
        [text]="campaignToShow"
        [textToShow]="hasHiddenText(campaignToShow) ? (campaignToShow | slice : 0 : textLimit) + '...' : campaignToShow"
        [tagsToHighlight]="campaignsToHighlight"
        [highlightClass]="'card__facts-segment--highlight'"
    ></div>

    <button
        *ngIf="extraCampaigns && extraCampaigns.length > 0"
        [class]="styleType + '__facts-segment-button'"
        mat-mini-fab
        color="accent"
        aria-label="Has more campaigns"
        tpPopover
        tpPopoverHover
        [tpPopoverHasBackdrop]="false"
        [tpVerticalTarget]="'above'"
        [tpPopoverContent]="popoverContent"
        [tpPopoverData]="popoverData"
        [tpHorizontalConnectionPosition]="'start'"
        [fallbackHorizontalOffset]="25"
    >
        {{ badgeText }}
    </button>
</div>
