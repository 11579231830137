import { Bookmark, PageParams } from '@tploy-enterprise/tenant-core';
import { SuggestedSkills } from '@tploy-enterprise/tenant-common';

export const PROFILE_STORAGE_KEY = 'tploy.generationExchange.profile';
export const IS_PROFILE_DIRTY = 'tploy.generationExchange.profile.isDirty';

export type GenerationExchangeMotivation = 'exchangeKnowledge' | 'generationsCommunication' | 'networking';

export type GenerationExchangeSuggestedSkills = SuggestedSkills;

export interface GenerationExchangeProfile {
    campaigns: string[];
    companyLocations: string[];
    locationIndependent: boolean;
    skills: string[];
    generation: number;
    motivations: GenerationExchangeMotivation[];
}

export interface OthersGenerationExchangeProfile extends GenerationExchangeProfile {
    user: {
        id: string;
        name?: string;
        languages?: string[];
        locations?: string[];
        imageUrl?: string;
    };
}

export interface GenerationExchangeMatch extends GenerationExchangeAccountListItem {
    userId: string;
    highlight: GenerationExchangeHighlight;
}

export interface GenerationExchangeMatchResults {
    allMatches: GenerationExchangeMatch[];
    matches: GenerationExchangeMatch[];
    total: number;
    pageParams: PageParams;
}

export interface GenerationExchangeBookmark extends Bookmark, GenerationExchangeAccountListItem {}

export interface GenerationExchangeSearchResult extends GenerationExchangeAccountListItem {
    userId: string;
    highlight: GenerationExchangeHighlight;
}

export interface GenerationExchangeSearchResults {
    allResults: GenerationExchangeSearchResult[];
    results: GenerationExchangeSearchResult[];
    pageParams: PageParams;
}

export interface GenerationExchangeBookmarks {
    bookmarks: GenerationExchangeBookmark[];
    total: number;
}

export interface GenerationExchangeAccountListItem {
    bookmark?: Bookmark;
    profile?: OthersGenerationExchangeProfile;
}

export interface GenerationExchangeHighlight {
    campaigns?: string[];
    skills: string[];
    isOnlineMatch: boolean;
    locations: string[];
}
