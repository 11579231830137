import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    DirectMessagesCreateConversationActions,
    DirectMessagesJoinConversationActions,
    DirectMessagesLeaveConversationActions,
    DirectMessagesLoadConversationActions,
    DirectMessagesLoadConversationsActions,
    DirectMessagesMuteConversationActions,
    DirectMessagesOwnershipPermissionsActions,
    DirectMessagesReceiveMessageActions,
    DirectMessagesSendMessageActions,
    DirectMessagesUnreadMessagesActions,
    DirectMessagesWritePermissionsActions,
} from '../actions';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DirectMessagesService } from '../services/direct-messages.service';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { DirectMessagesState } from '../state/direct-messages.state';
import { NotificationsService } from '../../../core-modules/notifications/notifications.service';
import { RequestHandlerService } from '../../../core-modules/request-handler/request-handler.service';
import { LAST_SELECTED_CONVERSATION } from '../constants/storage-keys.constant';
import { AccountState } from '../../../core-modules/account';
import { MessageMetadata } from '../../direct-messages/models';
import { StorageService } from '../../../core-modules/storage/service/storage.service';
import { Router } from '@angular/router';
import { AuthenticationActions } from '../../../core-modules/authentication/authentication.actions';

@Injectable({
    providedIn: 'root',
})
export class DirectMessagesConversationEffects {
    connectOnLogin = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.verified),
                filter((data) => !!data.authenticated),
                tap(() => this.directMessagesService.connect()),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    disconnectOnLogout = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.signOutSuccess, AuthenticationActions.signOutError),
                tap(() => this.directMessagesService.disconnect()),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    createConversationWithForceLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesCreateConversationActions.createConversationWithForceLoad),
            switchMap((data) => this.directMessagesService.createConversation(data.payload)),
            map((response) => response.data),
            tap((conversation) => this.storage.local.set(LAST_SELECTED_CONVERSATION, conversation.id)),
            withLatestFrom(this.store.select('directMessages')),
            switchMap(([conversation, directMessages]) => {
                const conversationExists =
                    typeof directMessages.conversations.find((existingConversation) => {
                        return existingConversation.id === conversation.id;
                    }) !== 'undefined';

                let conversations;

                if (conversationExists) {
                    conversations = [...new Set(directMessages.conversations)];
                } else {
                    conversations = [...new Set([...directMessages.conversations, conversation])];
                }

                return [
                    DirectMessagesCreateConversationActions.createConversationSuccess({ conversations }),
                    DirectMessagesLoadConversationActions.loadConversation({ conversationId: conversation.id }),
                ];
            }),
            catchError((error) => of(DirectMessagesCreateConversationActions.createConversationError(error))),
        ),
    );
    createConversationWithMessageAndForceLoad$ = createEffect(() => {
        let message: { message: string; metadata?: MessageMetadata };
        return this.actions$.pipe(
            ofType(DirectMessagesCreateConversationActions.createConversationWithMessageAndForceLoad),
            switchMap((action) => {
                message = action.payload.message;
                return this.directMessagesService.createConversation(action.payload);
            }),
            map((response) => response.data),
            tap((conversation) => this.storage.local.set(LAST_SELECTED_CONVERSATION, conversation.id)),
            withLatestFrom(this.store.select('directMessages')),
            switchMap(([conversation, directMessages]) => {
                const conversationExists =
                    typeof directMessages.conversations.find((existingConversation) => {
                        return existingConversation.id === conversation.id;
                    }) !== 'undefined';

                let conversations;

                if (conversationExists) {
                    conversations = [...new Set(directMessages.conversations)];
                } else {
                    conversations = [...new Set([...directMessages.conversations, conversation])];
                }

                const payload = {
                    ...message,
                    conversationId: conversation.id,
                };

                return [
                    DirectMessagesCreateConversationActions.createConversationSuccess({ conversations }),
                    DirectMessagesSendMessageActions.sendMessage({ payload }),
                    DirectMessagesLoadConversationActions.loadConversation({ conversationId: conversation.id }),
                ];
            }),
            catchError((error) => of(DirectMessagesCreateConversationActions.createConversationError(error))),
        );
    });
    createConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesCreateConversationActions.createConversation),
            switchMap((data) => this.directMessagesService.createConversation(data.payload)),
            map((response) => response.data),
            tap((conversation) => this.storage.local.set(LAST_SELECTED_CONVERSATION, conversation.id)),
            withLatestFrom(this.store.select('directMessages')),
            switchMap(([conversation, directMessages]) => {
                const conversationExists =
                    typeof directMessages.conversations.find((existingConversation) => {
                        return existingConversation.id === conversation.id;
                    }) !== 'undefined';

                let conversations;

                if (conversationExists) {
                    conversations = [...new Set(directMessages.conversations)];
                } else {
                    conversations = [...new Set([...directMessages.conversations, conversation])];
                }

                return [DirectMessagesCreateConversationActions.createConversationSuccess({ conversations })];
            }),
            catchError((error) => of(DirectMessagesCreateConversationActions.createConversationError(error))),
        ),
    );
    loadConversations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLoadConversationsActions.loadConversations),
            switchMap(() => this.directMessagesService.loadConversations()),
            map((conversations) => conversations.data),
            map((conversations) => [...new Set(conversations)]),
            map((conversations) => DirectMessagesLoadConversationsActions.loadConversationsSuccess({ conversations })),
            catchError((error) => of(DirectMessagesLoadConversationsActions.loadConversationsError(error))),
        ),
    );
    loadConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLoadConversationActions.loadConversation),
            switchMap((payload) => this.directMessagesService.loadConversation(payload.conversationId)),
            tap(() => this.store.dispatch(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCount())),
            map((response) => response.data),
            tap((currentConversation) => {
                this.storage.local.set(LAST_SELECTED_CONVERSATION, currentConversation.id);
            }),
            withLatestFrom(
                this.store.select((state) => state.directMessages.conversations),
                this.store.select('account'),
            ),
            map(([currentConversation, conversations, account]) => {
                conversations = [
                    ...conversations.map((conversation) => {
                        if (conversation.id === currentConversation.id) {
                            conversation.messages = [...currentConversation.messages];
                        }
                        return conversation;
                    }),
                ];

                currentConversation.messages.forEach((message) => {
                    if (!message.readStatus.includes(account.userId)) {
                        this.store.dispatch(
                            DirectMessagesUnreadMessagesActions.markMessageAsRead({ messageId: message.id }),
                        );
                    }
                });

                return DirectMessagesLoadConversationActions.loadConversationSuccess({
                    currentConversation,
                    conversations,
                });
            }),
            tap(() => this.router.navigateByUrl('/direct-messages')),
            catchError((error) => of(DirectMessagesLoadConversationActions.loadConversationError(error))),
        ),
    );
    unloadConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLoadConversationActions.unloadConversation),
            map((action) => action.clearStorage),
            tap((clearStorage) => {
                if (clearStorage) {
                    this.storage.local.remove(LAST_SELECTED_CONVERSATION);
                }
            }),
            map(() => DirectMessagesLoadConversationActions.unloadConversationSuccess()),
        ),
    );
    markMessagesAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesUnreadMessagesActions.markMessageAsRead),
            mergeMap((payload) => this.directMessagesService.markMessageAsRead(payload.messageId)),
            map((response) => response.data),
            withLatestFrom(this.store.select('directMessages')),
            map(([eventData, directMessages]) => {
                const conversations = [
                    ...directMessages.conversations.map((conversation) => {
                        conversation.messages = [
                            ...conversation.messages.map((message) => {
                                if (
                                    message.id === eventData.messageId &&
                                    !message.readStatus.includes(eventData.userId)
                                ) {
                                    message.readStatus = [...message.readStatus, eventData.userId];
                                }
                                return message;
                            }),
                        ];

                        if (
                            conversation.lastMessage &&
                            conversation.lastMessage.id === eventData.messageId &&
                            !conversation.lastMessage.readStatus.includes(eventData.userId)
                        ) {
                            conversation.lastMessage = {
                                ...conversation.lastMessage,
                                readStatus: [...conversation.lastMessage.readStatus, eventData.userId],
                            };
                        }

                        return conversation;
                    }),
                ];

                const currentConversation = directMessages.currentConversation;

                if (currentConversation) {
                    currentConversation.messages = [
                        ...currentConversation.messages.map((message) => {
                            if (message.id === eventData.messageId && !message.readStatus.includes(eventData.userId)) {
                                message.readStatus = [...message.readStatus, eventData.userId];
                            }
                            return message;
                        }),
                    ];

                    if (
                        currentConversation.lastMessage &&
                        currentConversation.lastMessage.id === eventData.messageId &&
                        !currentConversation.lastMessage.readStatus.includes(eventData.userId)
                    ) {
                        currentConversation.lastMessage = {
                            ...currentConversation.lastMessage,
                            readStatus: [...currentConversation.lastMessage.readStatus, eventData.userId],
                        };
                    }
                }

                const returnData = {
                    unreadMessages: directMessages.unreadMessages === 0 ? 0 : --directMessages.unreadMessages,
                    conversations,
                    currentConversation,
                };

                return DirectMessagesUnreadMessagesActions.markMessageAsReadSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesUnreadMessagesActions.markMessageAsReadError(error))),
        ),
    );
    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesSendMessageActions.sendMessage),
            switchMap((data) => {
                this.requestHandlerService.showSpinner$.next(true);
                return this.directMessagesService.sendMessage(data.payload);
            }),
            map((response) => {
                this.requestHandlerService.showSpinner$.next(false);
                return response.data;
            }),
            map((message) => DirectMessagesSendMessageActions.sendMessageSuccess({ message })),
            catchError((error) => of(DirectMessagesSendMessageActions.sendMessageError(error))),
        ),
    );
    receiveMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DirectMessagesReceiveMessageActions.receiveMessage),
            withLatestFrom(this.store),
            switchMap(([message, store]) => {
                const conversation = store.directMessages.conversations.find(
                    (conversation) => conversation.id === message.message.conversationId,
                );

                if (conversation.owner === store.account.userId) {
                    return of(message.message);
                }

                if (message.message.userId === store.account.userId) {
                    return of(message.message);
                }

                return conversation.mutedBy.includes(store.account.userId)
                    ? of(message.message)
                    : this.directMessagesService.showMessageNotification(message.message);
            }),
            withLatestFrom(this.store),
            map(([message, store]) => {
                if (message.userId !== store.account.userId) {
                    this.directMessagesService.markMessageAsReadWhenInCurrentConversation(message);
                }
                const currentConversation = store.directMessages.currentConversation;
                let conversations = store.directMessages.conversations;
                let unreadMessages = store.directMessages.unreadMessages;

                const messageConversation = conversations.find((conversation) => {
                    return conversation.id === message.conversationId;
                });

                if (
                    message.userId !== store.account.userId &&
                    !messageConversation.mutedBy.includes(store.account.userId) &&
                    !(currentConversation && currentConversation.id === message.conversationId)
                ) {
                    unreadMessages++;
                }

                if (currentConversation && currentConversation.id === message.conversationId) {
                    const messageExists = currentConversation.messages.some((msg) => msg.id === message.id);
                    currentConversation.messages = messageExists
                        ? [...currentConversation.messages]
                        : [...currentConversation.messages, message];

                    currentConversation.lastMessage = message;
                }

                conversations = conversations.map((conversation) => {
                    if (conversation.id === message.conversationId) {
                        conversation.lastMessage = message;
                        const messageExists = conversation.messages.some((msg) => msg.id === message.id);
                        conversation.messages = messageExists
                            ? [...conversation.messages]
                            : [...conversation.messages, message];
                    }
                    return conversation;
                });

                const returnData = {
                    message,
                    currentConversation,
                    conversations,
                    unreadMessages,
                };

                return DirectMessagesReceiveMessageActions.receiveMessageSuccess(returnData);
            }),
            catchError((error) => {
                return of(DirectMessagesReceiveMessageActions.receiveMessageError(error));
            }),
        );
    });
    receiveMessageNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesReceiveMessageActions.receiveMessageSuccess),
                withLatestFrom(this.store),
                tap(([payload, store]) => {
                    if (
                        !store.directMessages.currentConversation ||
                        payload.message.conversationId !== store.directMessages.currentConversation.id
                    ) {
                        const conversation = store.directMessages.conversations.find(
                            (conversation) => conversation.id === payload.message.conversationId,
                        );

                        const isNotSender = payload.message.userId !== store.account.userId;
                        const receiverHasNotMuted = !conversation.mutedBy.includes(store.account.userId);

                        if (isNotSender && receiverHasNotMuted) {
                            const participant = conversation.participants.find(
                                (participant) => participant.userId === payload.message.userId,
                            );
                            if (participant) {
                                this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_SNACK_NEW_MESSAGE', {
                                    userName: participant?.generalData?.name,
                                });
                            }
                        }
                    }
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    loadUnreadMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCount),
            switchMap(() => this.directMessagesService.loadUnreadMessagesCount()),
            map((response) => response.data),
            map((data) => DirectMessagesUnreadMessagesActions.loadUnreadMessagesCountSuccess(data)),
            catchError((error) => of(DirectMessagesUnreadMessagesActions.loadUnreadMessagesCountError(error))),
        ),
    );
    muteConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesMuteConversationActions.muteConversation),
            withLatestFrom(this.store.select((state) => state.directMessages.currentConversation)),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(([action, currentConversation]) =>
                this.directMessagesService.muteConversation(currentConversation.id),
            ),
            withLatestFrom(this.store.select('directMessages'), this.store.select('account')),
            map(([conversationId, directMessages, account]) => {
                const currentConversation = directMessages.currentConversation;
                const conversations = directMessages.conversations.map((conversation) => {
                    if (conversation.id === conversationId) {
                        if (!conversation.mutedBy.includes(account.userId)) {
                            conversation.mutedBy.push(account.userId);
                        }
                        if (currentConversation.id === conversation.id) {
                            if (!currentConversation.mutedBy.includes(account.userId)) {
                                currentConversation.mutedBy.push(account.userId);
                            }
                        }
                    }
                    return conversation;
                });

                const returnData = {
                    conversations,
                    currentConversation,
                };

                return DirectMessagesMuteConversationActions.muteConversationSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesMuteConversationActions.muteConversationError(error))),
        ),
    );
    muteConversationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesMuteConversationActions.muteConversationSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_CONVERSATION_MUTED_NOTIFICATION');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    unmuteConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesMuteConversationActions.unmuteConversation),
            withLatestFrom(this.store.select((state) => state.directMessages.currentConversation)),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(([action, currentConversation]) =>
                this.directMessagesService.unmuteConversation(currentConversation.id),
            ),
            withLatestFrom(this.store.select('directMessages'), this.store.select('account')),
            map(([conversationId, directMessages, account]) => {
                const currentConversation = directMessages.currentConversation;
                const conversations = directMessages.conversations.map((conversation) => {
                    if (conversation.id === conversationId) {
                        if (conversation.mutedBy.includes(account.userId)) {
                            conversation.mutedBy.splice(conversation.mutedBy.indexOf(account.userId));
                        }
                        if (currentConversation.id === conversation.id) {
                            if (!currentConversation.mutedBy.includes(account.userId)) {
                                currentConversation.mutedBy.splice(
                                    currentConversation.mutedBy.indexOf(account.userId),
                                    1,
                                );
                            }
                        }
                    }
                    return conversation;
                });

                const returnData = {
                    conversations,
                    currentConversation,
                };

                return DirectMessagesMuteConversationActions.unmuteConversationSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesMuteConversationActions.unmuteConversationError(error))),
        ),
    );
    unmuteConversationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesMuteConversationActions.unmuteConversationSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_CONVERSATION_UNMUTED_NOTIFICATION');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    removeFromConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLeaveConversationActions.removeFromConversation),
            switchMap((data) => this.directMessagesService.removeFromConversation(data.conversationId, data.userId)),
            map(({ data }) => ({ conversationId: data.conversationId, userId: data.userId })),
            withLatestFrom(this.store.select('directMessages')),
            map(([{ conversationId, userId }, directMessages]) => {
                const conversations = [
                    ...directMessages.conversations.map((conversation) => {
                        if (conversation.id === conversationId) {
                            const participantIndex = conversation.participants?.findIndex((participant) => {
                                return participant.userId === userId;
                            });
                            if (participantIndex) {
                                conversation.participants = [
                                    ...conversation.participants.slice(participantIndex, participantIndex + 1),
                                    ...conversation.participants.slice(participantIndex + 2),
                                ];
                            }
                        }
                        return conversation;
                    }),
                ];

                const currentConversation = directMessages.currentConversation;

                if (currentConversation) {
                    currentConversation.participants = [
                        ...currentConversation.participants.filter((participant) => participant.userId !== userId),
                    ];
                }

                const returnData = {
                    conversations,
                    currentConversation,
                };
                return DirectMessagesLeaveConversationActions.removeFromConversationSuccess(returnData);
            }),
            catchError((error) => {
                return of(DirectMessagesLeaveConversationActions.removeFromConversationError(error));
            }),
        ),
    );
    leaveConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLeaveConversationActions.leaveConversation),
            map((payload) => payload.conversationId),
            withLatestFrom(this.store),
            map(([conversationId, state]) => {
                const conversations = [
                    ...state.directMessages.conversations.filter((conversation) => {
                        return conversation.id !== conversationId;
                    }),
                ];

                const currentConversation = state.directMessages.currentConversation;

                if (currentConversation?.id === conversationId) {
                    this.store.dispatch(
                        DirectMessagesLoadConversationActions.unloadConversation({ clearStorage: true }),
                    );
                }

                return DirectMessagesLeaveConversationActions.leaveConversationSuccess({
                    conversations,
                    currentConversation,
                });
            }),
            catchError((error) => of(DirectMessagesLeaveConversationActions.leaveConversationError(error))),
        ),
    );
    leaveConversationOthers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesLeaveConversationActions.leaveConversationOther),
            withLatestFrom(this.store.select('directMessages')),
            map(([data, directMessages]) => {
                const conversations = [
                    ...directMessages.conversations.map((conversation) => {
                        if (conversation.id === data.conversationId) {
                            const participantIndex = conversation.participants.findIndex((participant) => {
                                return participant.userId === data.userId;
                            });
                            conversation.participants = [
                                ...conversation.participants.slice(participantIndex, participantIndex + 1),
                                ...conversation.participants.slice(participantIndex + 2),
                            ];
                        }
                        return conversation;
                    }),
                ];

                const currentConversation = directMessages.currentConversation;

                if (currentConversation) {
                    const participantIndex = currentConversation.participants.findIndex((participant) => {
                        return participant.userId === data.userId;
                    });
                    currentConversation.participants = [
                        ...currentConversation.participants.slice(participantIndex, participantIndex + 1),
                        ...currentConversation.participants.slice(participantIndex + 2),
                    ];
                }

                return DirectMessagesLeaveConversationActions.leaveConversationOtherSuccess({
                    conversations,
                    currentConversation,
                });
            }),
            catchError((error) => of(DirectMessagesLeaveConversationActions.leaveConversationOtherError(error))),
        ),
    );
    joinGroupConversation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesJoinConversationActions.joinGroupConversation),
            switchMap((payload) => this.directMessagesService.joinGroupConversation(payload)),
            map((response) => response.data),
            map((conversation) => DirectMessagesJoinConversationActions.joinConversationSuccess({ conversation })),
            catchError((error) => of(DirectMessagesJoinConversationActions.joinConversationError(error))),
        ),
    );
    joinConversationOther$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesJoinConversationActions.joinGroupConversationOther),
            map((payload) => payload.conversation),
            withLatestFrom(this.store.select('directMessages')),
            map(([conversation, directMessages]) => {
                const currentConversation = directMessages.currentConversation;

                const conversations = directMessages.conversations.map((existingConversation) => {
                    if (existingConversation.id === conversation.id) {
                        existingConversation.participants = conversation.participants;
                    }
                    return existingConversation;
                });

                if (currentConversation) {
                    if (currentConversation.id === conversation.id) {
                        currentConversation.participants = conversation.participants;
                    }
                }

                const returnData = {
                    conversations,
                    currentConversation,
                };

                return DirectMessagesJoinConversationActions.joinGroupConversationOtherSuccess(returnData);
            }),
        ),
    );
    enableWritePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesWritePermissionsActions.enableWritePermissions),
            map((payload) => payload.conversationId),
            switchMap((conversationId) =>
                this.directMessagesService.enableWritePermissionsForConversation(conversationId),
            ),
            map((response) => response.data.conversationId),
            withLatestFrom(this.store.select('directMessages')),
            map(([conversationId, directMessages]) => {
                const returnData = this.setWritePermissionsForAllParticipants(conversationId, directMessages, true);
                return DirectMessagesWritePermissionsActions.enableWritePermissionsSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesWritePermissionsActions.enableWritePermissionsError(error))),
        ),
    );
    enableWritePermissionsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesWritePermissionsActions.enableWritePermissionsSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_WRITE_PERMISSIONS_ENABLED');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    enableWritePermissionsOthers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesWritePermissionsActions.enableWritePermissionsOthers),
            map((payload) => payload.conversationId),
            withLatestFrom(this.store.select('directMessages')),
            map(([conversationId, directMessages]) => {
                const returnData = this.setWritePermissionsForAllParticipants(conversationId, directMessages, true);

                return DirectMessagesWritePermissionsActions.enableWritePermissionsOthersSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesWritePermissionsActions.enableWritePermissionsOthersError(error))),
        ),
    );
    disableWritePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesWritePermissionsActions.disableWritePermissions),
            map((payload) => payload.conversationId),
            switchMap((conversationId) =>
                this.directMessagesService.disableWritePermissionsForConversation(conversationId),
            ),
            map((response) => response.data.conversationId),
            withLatestFrom(this.store.select('directMessages')),
            map(([conversationId, directMessages]) => {
                const returnData = this.setWritePermissionsForAllParticipants(conversationId, directMessages, false);

                return DirectMessagesWritePermissionsActions.disableWritePermissionsSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesWritePermissionsActions.disableWritePermissionsError(error))),
        ),
    );
    disableWritePermissionsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesWritePermissionsActions.disableWritePermissionsSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_WRITE_PERMISSIONS_DISABLED');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    disableWritePermissionsOthers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesWritePermissionsActions.disableWritePermissionsOthers),
            map((payload) => payload.conversationId),
            withLatestFrom(this.store.select('directMessages')),
            map(([conversationId, directMessages]) => {
                const returnData = this.setWritePermissionsForAllParticipants(conversationId, directMessages, false);

                return DirectMessagesWritePermissionsActions.disableWritePermissionsOthersSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesWritePermissionsActions.disableWritePermissionsOthersError(error))),
        ),
    );
    promoteUserToOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesOwnershipPermissionsActions.promoteUserToOwner),
            switchMap((payload) => {
                return this.directMessagesService.promoteUserToOwner(payload.conversationId, payload.participantId);
            }),
            map((response) => response.data),
            withLatestFrom(this.store.select('directMessages')),
            map(([responseData, directMessages]) => {
                const returnData = this.setOwnershipForUser(
                    responseData.conversationId,
                    responseData.participantId,
                    directMessages,
                    true,
                );

                return DirectMessagesOwnershipPermissionsActions.promoteUserToOwnerSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesOwnershipPermissionsActions.promoteUserToOwnerError(error))),
        ),
    );
    promoteUserToOwnerSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesOwnershipPermissionsActions.promoteUserToOwnerSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_PROMOTED_USER_TO_OWNER');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    demoteUserFromOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesOwnershipPermissionsActions.demoteUserFromOwner),
            switchMap((payload) => {
                return this.directMessagesService.demoteUserFromOwner(payload.conversationId, payload.participantId);
            }),
            map((response) => response.data),
            withLatestFrom(this.store.select('directMessages')),
            map(([responseData, directMessages]) => {
                const returnData = this.setOwnershipForUser(
                    responseData.conversationId,
                    responseData.participantId,
                    directMessages,
                    false,
                );

                return DirectMessagesOwnershipPermissionsActions.demoteUserFromOwnerSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesOwnershipPermissionsActions.demoteUserFromOwnerError(error))),
        ),
    );
    demoteUserFromOwnerSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DirectMessagesOwnershipPermissionsActions.demoteUserFromOwnerSuccess),
                tap(() => {
                    this.notificationsService.openDefaultSnack('DIRECT_MESSAGES_DEMOTED_USER_FROM_OWNER');
                }),
                switchMap(() => EMPTY),
            ),
        { dispatch: false },
    );
    promotedToOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesOwnershipPermissionsActions.promotedToOwner),
            withLatestFrom(this.store.select('directMessages')),
            map(([payload, directMessages]) => {
                const returnData = this.setOwnershipForUser(
                    payload.conversationId,
                    payload.participantId,
                    directMessages,
                    true,
                );

                return DirectMessagesOwnershipPermissionsActions.promotedToOwnerSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesOwnershipPermissionsActions.promotedToOwnerError(error))),
        ),
    );
    demotedFromOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DirectMessagesOwnershipPermissionsActions.demotedFromOwner),
            withLatestFrom(this.store.select('directMessages')),
            map(([payload, directMessages]) => {
                const returnData = this.setOwnershipForUser(
                    payload.conversationId,
                    payload.participantId,
                    directMessages,
                    false,
                );

                return DirectMessagesOwnershipPermissionsActions.demotedFromOwnerSuccess(returnData);
            }),
            catchError((error) => of(DirectMessagesOwnershipPermissionsActions.demotedFromOwnerError(error))),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly directMessagesService: DirectMessagesService,
        private readonly store: Store<{ directMessages: DirectMessagesState; account: AccountState }>,
        private readonly notificationsService: NotificationsService,
        private readonly storage: StorageService,
        private readonly router: Router,
        private requestHandlerService: RequestHandlerService,
    ) {}

    private setOwnershipForUser(
        conversationId: string,
        participantId: string,
        directMessages: DirectMessagesState,
        ownership: boolean,
    ) {
        const currentConversation = directMessages.currentConversation;

        if (currentConversation?.id === conversationId) {
            currentConversation.participants = [
                ...currentConversation.participants.map((participant) => {
                    if (participant.userId === participantId) {
                        participant.isConversationOwner = ownership;
                    }
                    return participant;
                }),
            ];
        }

        const conversations = [
            ...directMessages.conversations.map((conversation) => {
                if (conversation.id === conversationId) {
                    conversation.participants = [
                        ...conversation.participants.map((participant) => {
                            if (participant.userId === participantId) {
                                participant.isConversationOwner = ownership;
                            }
                            return participant;
                        }),
                    ];
                }
                return conversation;
            }),
        ];

        return { conversations, currentConversation };
    }

    private setWritePermissionsForAllParticipants(
        conversationId: string,
        directMessages: DirectMessagesState,
        permissionState: boolean,
    ) {
        const currentConversation = directMessages.currentConversation;

        if (currentConversation?.id === conversationId) {
            currentConversation.participants = [
                ...currentConversation.participants.map((participant) => {
                    if (!participant.isConversationOwner) {
                        participant.allowWrite = permissionState;
                    }
                    return participant;
                }),
            ];
        }

        const conversations = [
            ...directMessages.conversations.map((conversation) => {
                if (conversation.id === conversationId) {
                    conversation.participants = [
                        ...conversation.participants.map((participant) => {
                            if (!participant.isConversationOwner) {
                                participant.allowWrite = permissionState;
                            }
                            return participant;
                        }),
                    ];
                }
                return conversation;
            }),
        ];

        return { conversations, currentConversation };
    }
}
