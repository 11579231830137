import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FooterLinksComponent } from './footer-links.component';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '../language';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@tandemploy/ngx-components';

@NgModule({
    imports: [CommonModule, ButtonModule, RouterModule, MatMenuModule, MatButtonModule, LanguageModule, MatIconModule],
    declarations: [FooterLinksComponent],
    exports: [FooterLinksComponent],
})
export class FooterLinksModule {}
