import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BlogArticle } from './blog.types';
import { BlogApi } from './blog.api';
import {
    BlogArticleNotFoundException,
    BlogArticlesLoadBadRequestException,
    BlogArticlesLoadForbiddenException,
    BlogArticlesLoadNotFoundException,
    BlogArticlesLoadUnexpectedException,
} from './blog.exceptions';
import { BlogSerializer } from './blog.serializer';
import { LanguageOptions } from '@tploy-enterprise/tenant-common';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class BlogService {
    constructor(private api: BlogApi, private serializer: BlogSerializer) {}

    loadArticleById(id: string): Observable<BlogArticle> {
        return this.api.getArticleById(id).pipe(
            map((article) => this.serializer.deserialize(article)),
            catchError(() => {
                throw new BlogArticleNotFoundException();
            }),
        );
    }

    loadArticles(locale: LanguageOptions): Observable<BlogArticle[]> {
        return this.api.getArticles(locale).pipe(
            map((articles) => articles.map((article) => this.serializer.deserialize(article))),
            catchError((error) => this.handleLoadBlogArticlesError(error)),
        );
    }

    private handleLoadBlogArticlesError(error: Error): never {
        if (this.isHttpErrorResponse(error)) {
            switch (error.status) {
                case 400:
                    throw new BlogArticlesLoadBadRequestException();
                case 403:
                    throw new BlogArticlesLoadForbiddenException();
                case 404:
                    throw new BlogArticlesLoadNotFoundException();
                default:
                    throw new BlogArticlesLoadUnexpectedException(error.error);
            }
        } else {
            throw new BlogArticlesLoadUnexpectedException(error);
        }
    }

    private isHttpErrorResponse(error: Error): error is HttpErrorResponse {
        const asserted = error as HttpErrorResponse;
        return asserted && !!asserted.status && asserted.name === 'HttpErrorResponse';
    }
}
