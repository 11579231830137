import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AuthenticationActions } from './authentication.actions';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { RedirectService } from '../../redirect.service';
import { StorageService } from '../storage/service/storage.service';
import { Store } from '@ngrx/store';
import { AuthenticationEndpoints, REMOVE_USER_SESSION_STORAGE_KEY } from './constants';
import { TPLOY_LANGUAGE_STORAGE_KEY } from '../account/locale/locale.effects';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly authenticationService: AuthenticationService,
        private readonly redirectService: RedirectService,
        private readonly storage: StorageService,
        private readonly store: Store<unknown>,
    ) {}

    authenticate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.authenticate),
            tap(() => this.authenticationService.authenticate()),
            catchError(() => EMPTY),
        ),
    );

    signOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.signOut),
            tap(() => {
                this.store.dispatch(AuthenticationActions.signOutSuccess());
                window.location.href = AuthenticationEndpoints.SignOut;
            }),
            switchMap(() => EMPTY),
            catchError(() => of(AuthenticationActions.signOutError())),
        ),
    );

    forceLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.forceLogin),
            tap((payload) => {
                this.store.dispatch(AuthenticationActions.signOutSuccess());
                this.redirectService.redirect(`/login?returnUrl=${payload.returnUrl}`);
            }),
            switchMap(() => EMPTY),
            catchError(() => of(AuthenticationActions.signOutError())),
        ),
    );

    clearSessionOnSignOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.signOutSuccess, AuthenticationActions.signOutError),
                tap(() => {
                    const selectedLocale = this.storage.local.get(TPLOY_LANGUAGE_STORAGE_KEY);
                    const userWasRemoved = this.storage.session.get(REMOVE_USER_SESSION_STORAGE_KEY);
                    this.storage.session.flush();
                    this.storage.local.flush();
                    this.storage.local.set(TPLOY_LANGUAGE_STORAGE_KEY, selectedLocale);
                    if (userWasRemoved) {
                        this.storage.session.set(REMOVE_USER_SESSION_STORAGE_KEY, userWasRemoved);
                    }
                }),
            ),
        { dispatch: false },
    );
}
