export default {
    PROJECTS: 'Projekte',
    PROJECTS_SINGULAR: 'Projekt',
    PROJECTS_INFO:
        'Du suchst – unter Umständen auch recht kurzfristig – die passenden Personen oder Teams für einen Projekt? Beides ist mit dem Matching für Projekte unkompliziert möglich. Ideal geeignet ist das Matching zum Beispiel für das Staffing kurzfristiger und zeitlich begrenzter Bedarfe, für Auslandseinsätze, aber auch für Hospitationsmöglichkeiten. Wer sich für die Teilnahme an Projekten interessiert oder gar gezielt nach einer Abwechslung sucht und bestimmte Fähigkeiten stärker einbringen möchte, sollte unbedingt „Projekte“ als sein Interesse auswählen und hierzu auf dem Laufenden bleiben!',
    PROJECTS_ENTITY_CREATE: 'Neues Projekt',
    PROJECTS_PROFILE_TITLE: 'Projekte',
    PROJECTS_PROFILE_SUBTITLE: 'Bitte tätige ein paar weitere Angaben, um ein passgenaues Matching zu ermöglichen.',
    PROJECTS_PROFILE_MOTIVATION_SUBTITLE:
        'Spannende Projekte entdecken und/oder eigene Projekte veröffentlichen und das passende Team finden.',
    PROJECTS_PROFILE_MOTIVATION_SELECT_OFFERING_OPTION: 'Selbst Projekte einstellen und Teams finden.',
    PROJECTS_PROFILE_MOTIVATION_SELECT_INTEREST_OPTION: 'Passende Projekte finden.',
    PROJECTS_MOTIVATION_VALIDATION_REQUIRED: 'Es muss mindestens eine Option ausgewählt werden.',
    PROJECTS_PROFILE_SKILLS_LABEL: 'Kenntnisse und Fähigkeiten, die du bei Projekten einbringen kannst',
    PROJECTS_PROFILE_CATEGORIES_INTERESTED_IN_LABEL: 'Bereiche, die dich besonders interessieren',
    PROJECTS_PROFILE_SKILLS_INTERESTED_IN_LABEL:
        'Kenntnisse, die du im Rahmen von Projekten gern erwerben/vertiefen möchtest',

    // ALIAS OF PROJECTS_PROFILE_SKILLS_LABEL
    PROJECTS_PROFILE_VIEW_SKILLS: 'Kenntnisse, die ich einbringen kann',
    PROJECTS_PROFILE_VIEW_INTERESTED_IN_SKILLS: 'Was ich lernen möchte',
    PROJECTS_PROFILE_VIEW_INTERESTED_IN_CATEGORIES: 'Interessenfelder',
    PROJECTS_PROFILE_VIEW_CAMPAIGNS_LABEL: 'HR Programme',

    PROJECTS_ENTITY_EDIT_TITLE: 'Projekt bearbeiten',
    PROJECTS_ENTITY_EDIT_BUTTON_DRAFT: 'Entwurf speichern',
    PROJECTS_ENTITY_EDIT_BUTTON_PUBLISH: 'Veröffentlichen',
    PROJECTS_ENTITY_EDIT_BUTTON_SAVE: 'Speichern',
    PROJECTS_ENTITY_CREATE_TITLE: 'Projekt erstellen',
    PROJECTS_ENTITY_CREATE_BUTTON_DRAFT: 'Entwurf speichern',
    PROJECTS_ENTITY_CREATE_BUTTON_PUBLISH: 'Veröffentlichen',
    PROJECTS_ENTITY_FORM_TITLE_LABEL: 'Titel des Projektes',
    PROJECTS_ENTITY_FORM_ONGOING_LABEL: 'Dies ist ein laufendes Projekt ohne Enddatum',
    PROJECTS_ENTITY_FORM_START_DATE_LABEL: 'Startdatum',
    PROJECTS_ENTITY_FORM_START_DATE_OPTIONAL_LABEL: 'Startdatum (optional)',
    PROJECTS_ENTITY_FORM_END_DATE_LABEL: 'Enddatum',
    PROJECTS_ENTITY_FORM_APPLICATION_DEADLINE_LABEL: 'Bewerbungsschluss',
    PROJECTS_ENTITY_FORM_APPLICATION_DEADLINE_VALIDATION_MIN_MAX_ERROR:
        'Bitte wählen Sie einen Bewerbungsschluss vor dem Start aus.',
    PROJECTS_ENTITY_FORM_VENUE_LABEL: 'Ort',
    PROJECTS_ENTITY_FORM_LANGUAGES_LABEL: 'Projektsprache (von allen Bewerber/innen erwartet)',
    PROJECTS_ENTITY_FORM_SUBSIDIARY_LABEL: 'Ausschreibende (Tochter-)Gesellschaft',
    PROJECTS_ENTITY_FORM_DEPARTMENT_LABEL: 'Abteilung',
    PROJECTS_ENTITY_FORM_TARGET_GROUPS_LABEL: 'Zielgruppe eingrenzen',
    PROJECTS_ENTITY_FORM_CATEGORY_LABEL: 'Thematische Einordnung (für Statistik):',
    PROJECTS_ENTITY_FORM_CAMPAIGNS_LABEL: 'Gehört zu folgendem Programm:',
    PROJECTS_ENTITY_FORM_CAMPAIGNS_PLACEHOLDER: 'wählen..',
    PROJECTS_ENTITY_FORM_DESCRIPTION_LABEL: 'Projektbeschreibung',
    PROJECTS_ENTITY_FORM_DESCRPTION_ERROR: 'Die Beschreibung muss mindestens 50 Zeichen umfassen.',
    PROJECTS_ENTITY_FORM_TARGET_GROUPS_PLACEHOLDER: 'Unbegrenzt (alle werden erreicht)',
    PROJECTS_ENTITY_FORM_SKILLS_FORM_NAME_FIELD_INFO:
        'Trage hier bitte alle Fähigkeiten/Skills/Expertisen ein, die du dir für ein erfolgreiches Projekt-Team wünschst. Pro Zeile kann nur ein Skill eingetragen werden. Bei Bedarf bestimmst du im nächsten Schritt das bevorzugte Kenntnis-Level.\nInteressierte Mitarbeiter*innen können sich mit einem oder mehreren passenden Skills für dein Projekt bewerben.',
    PROJECTS_FORM_READY_STATUS: 'Ja! Du kannst dich bewerben!',
    PROJECTS_FORM_ALMOST_READY_STATUS: 'Fast bereit zur Bewerbung!',
    PROJECTS_FORM_NOT_READY_STATUS: 'Noch nicht bereit zur Bewerbung!',
    PROJECTS_FORM_HEADLINE_LANGUAGE: 'Benötigte Sprache',
    PROJECTS_FORM_REQUIRED_TO_ADD_LANGUAGE: 'Füge die gewünschte Sprache hinzu',
    PROJECTS_FORM_HEADLINE_TARGET_GROUPS: 'Offen für folgende Gruppen',
    PROJECTS_FORM_REQUIRED_TO_ADD_TARGET_GROUP: 'Füge eine Gruppe hinzu, wenn du ihr angehörst',
    PROJECTS_FORM_TARGET_GROUPS_SELECT_PLACEHOLDER: 'Wähle eine Funktion ̰',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS_TITLE: 'Gesuchte Fähigkeiten fürs Project',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS_DESCRIPTION:
        'Um an diesem Projekt teilnehmen zu können, musst du mindestens eine der unten genannten Fähigkeiten mitbringen. Solltest du dir unsicher sein, ob dein Wissen ausreichend ist, schreib dem Projekt-Anbieter eine Nachricht.',
    PROJECTS_FORM_HEADLINE_PROJECT_SKILLS: 'Wähle mindestens eine der folgenden Fähigkeiten, um teilzunehmen',
    PROJECTS_FORM_REQUIRED_TO_ADD_PROJECT_SKILLS: 'Wähle mindestens eine Fähigkeit',
    PROJECTS_FORM_SKILL_TABLE_COL_CHECKED: 'Fähigkeit vorhanden?',
    PROJECTS_FORM_SKILL_TABLE_COL_SKILL_NAME: 'Fähigkeit',
    PROJECTS_FORM_SKILL_TABLE_COL_SKILL_INFORMATION: 'Information',

    PROJECTS_FORM_SKILL_TABLE_SKILL_ADD_IF_YOU_INTERESTED: 'Fähigkeit auswählen, wenn du diese mitbringst ',

    PROJECTS_PUBLISH_DIALOG_TITLE: 'Projekt jetzt veröffentlichen?',
    PROJECTS_PUBLISH_DIALOG_CONTENT_1: 'Passende Kollegen werden benachrichtigt',
    PROJECTS_PUBLISH_DIALOG_CONTENT_2:
        'Nachdem das Projekt veröffentlicht wurde, werden automatisch alle Matches ermittelt und via Mail darüber informiert. Bitte prüfe daher vorab, ob deine Angaben stimmen.',
    PROJECTS_PUBLISH_DIALOG_PUBLISH_BTN_TITLE: 'Jetzt veröffentlichen',

    PROJECTS_PUBLISH_APPROVAL_TITLE: 'Projekteinsatz erstellen',
    PROJECTS_PUBLISH_APPROVAL_SUB_TITLE:
        'Dein zuständiges Personalmanagement muss über den Projekteinsatz informiert werden.',
    PROJECTS_PUBLISH_APPROVAL_TEXT:
        'Tandemploy bringt deine Skills und spannende Angebote zusammen. Dies erfolgt natürlich unter Einhaltung von arbeitsrechtlich vorgeschriebenen Prozessen, die im Falle einer Versetzung oder eines Auslandseinsatzes zu beachten sind. Bei Einsätzen im Ausland, die die Dauer einer Dienstreise übersteigen, sind entsprechende Vorlaufzeiten zur Administration zu berücksichtigen. Wenn du dazu Fragen hast, wende dich an deine Führungskraft oder deine Personalabteilung.',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_LABEL:
        'Ich habe mit meinem zuständigen Personalmanagement besprochen, welche rechtlichen Rahmenbedingungen gelten und inwiefern die Mitbestimmung bei Veröffentlichung und Besetzung dieses Projekteinsatzes einzubeziehen ist.',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_YES_LABEL: 'Ja',
    PROJECTS_PUBLISH_APPROVAL_OPTIONS_NO_LABEL: 'Noch nicht',
    PROJECTS_PUBLISH_APPROVAL_PUBLISH_BTN_TITLE: 'Jetzt veröffentlichen!',

    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_1: 'Erstelle dein erstes Projekt',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CONTENT_2:
        'Du hast bisher noch kein Projekt angeboten. Hier kannst du erstmalig eines erstellen.',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_CREATE_ENTITY_BTN: 'Neues Projekt erstellen',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_1: 'Kolleg*innen einladen',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_ASIDE_CONTENT_2: 'Laden Sie Kolleg*innen ein, diese Plattform zu nutzen.',
    PROJECTS_OFFERED_BY_ME_PLACEHOLDER_TILE_INVITE_BTN: 'Einladen',
    PROJECTS_OFFERED_BY_ME_PLANNED_HEADER: 'Geplante',
    PROJECTS_OFFERED_BY_ME_RUNNING_HEADER: 'Laufende',
    PROJECTS_OFFERED_BY_ME_COMPLETE_HEADER: 'Abgeschlossene',
    PROJECTS_OFFERED_BY_ME_CONTINUE_EDIT: 'Bearbeiten fortsetzen',
    PROJECTS_OFFERED_BY_ME_MESSAGE_PARTICIPANTS_BTN_TITLE: 'Nachricht an Teilnehmer*innen',
    PROJECTS_OFFERED_BY_ME_VIEW_DETAILS_BTN_TITLE: 'Details ansehen',
    PROJECTS_OFFERED_BY_ME_DRAFT_STATUS_LABEL: 'Unveröffentlicht',
    PROJECTS_OFFERED_BY_ME_START_DATE_INDICATOR_LABEL: 'Startdatum',
    PROJECTS_OFFERED_BY_ME_END_DATE_INDICATOR_LABEL: 'Endet am',
    PROJECTS_OFFERED_BY_ME_DATE_RANGE_INDICATOR_LABEL: 'Zeitraum',
    PROJECTS_OFFERED_BY_ME_APPLICANTS_INDICATOR_LABEL: 'Bewerber',
    PROJECTS_OFFERED_BY_ME_LAST_UPDATED_INDICATOR_LABEL: 'Zuletzt aktualisiert',
    PROJECTS_OFFERED_BY_ME_CONTACT_LABEL: 'Kontakt',
    PROJECTS_OFFERED_BY_ME_DELETE_DIALOG_HEADER: 'Projekt löschen',
    PROJECTS_OFFERED_BY_ME_DELETE_DIALOG_CONTENT: 'Bist du sicher, dass du das Projekt löschen willst?',
    PROJECTS_OFFERED_BY_ME_DELETE_WITH_APPLICANTS_DIALOG_CONTENT:
        'Bist du sicher, dass du dieses Angebot einschließlich der potenziellen Bewerber/innen und/oder Teilnehmer/innen unwiderruflich löschen möchtest? Wenn du es löschst, werden alle beteiligten Benutzer entsprechend benachrichtigt. Das Angebot kann anschließend nicht mehr aufgerufen werden.',

    PROJECTS_SEARCH_HEADER: 'Projektsuche mit Schlagwörtern',
    PROJECTS_SEARCH_BTN_TEXT: 'Suchen!',
    PROJECTS_SEARCH_RESULT_REQUESTED_SKILLS: 'Gesuchte Fähigkeiten',
    PROJECTS_SEARCH_RESULT_VIEW_ENTITY: 'Details ansehen',
    PROJECTS_SEARCH_RESULT_AVATAR_LABEL: 'Kontakt',
    PROJECTS_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Suchen ohne Suchbegriff zeigt alle aktuell verfügbaren Angebote.',

    PROJECTS_APPLIED_TO_PLACHOLDER_TEXT: 'Du hast dich bisher noch nicht auf Projekte beworben.',
    PROJECTS_APPLIED_TO_PLACHOLDER_BTN: 'Passende Projekte finden',
    PROJECTS_APPLIED_TO_CONTACT_LABEL: 'Kontakt',
    PROJECTS_APPLIED_TO_VIEW_DETAILS_BTN_TITLE: 'Details ansehen',
    PROJECTS_APPLIED_TO_VIEW_APPLICATION_BTN_TITLE: 'Bewerbung ansehen',
    PROJECTS_APPLIED_TO_PENDING_LABEL:
        'Deine Bewerbung ist vollständig. \nDu wirst über die Entscheidung per Mail informiert.',
    PROJECTS_APPLIED_TO_CANCELLED_LABEL: 'Teilnahme abgelehnt. \nNachträgliche Absage durch Anbieter.',
    PROJECTS_APPLIED_TO_REJECTED_LABEL:
        'Du hast leider keinen Platz bekommen. \nDeine Bewerbung war leider nicht erfolgreich.',
    PROJECTS_APPLIED_TO_ACCEPTED_LABEL: 'Gratulation! \nDeine Bewerbung war erfolgreich.',
    PROJECTS_APPLIED_TO_PLANNED_HEADER: 'Geplante',
    PROJECTS_APPLIED_TO_RUNNING_HEADER: 'Laufende Projekte',
    PROJECTS_APPLIED_TO_COMPLETE_HEADER: 'Abgeschlossene',
    PROJECTS_APPLIED_TO_END_DATE_INDICATOR_LABEL: 'Endet am',
    PROJECTS_APPLIED_TO_APPLICANTS_INDICATOR_LABEL: 'Bewerber',
    PROJECTS_APPLIED_TO_DATE_RANGE_INDICATOR_LABEL: 'Zeitraum',

    PROJECTS_PROFILE_CATEGORY_DIGITALIZATION: 'Digitalisierung',
    PROJECTS_PROFILE_CATEGORY_CUSTOMER_CENTRICITY: 'Kundenorientierung',
    PROJECTS_PROFILE_CATEGORY_SUSTAINABILITY: 'Nachhaltigkeit',
    PROJECTS_PROFILE_CATEGORY_MOBILITY: 'Mobilität / Internationale Themen',
    PROJECTS_PROFILE_CATEGORY_COMMERCIAL: 'Wirtschaftliche/ Kommerzielle',
    PROJECTS_PROFILE_CATEGORY_AIRLINE_PRODUCT: 'Produktthemen',
    PROJECTS_PROFILE_CATEGORY_IT_TOPICS: 'IT Themen & Tools',
    PROJECTS_PROFILE_CATEGORY_COMMUNICATION: 'Kommunikationsthemen',
    PROJECTS_PROFILE_CATEGORY_SOCIAL: 'Soziale Themen',
    PROJECTS_PROFILE_CATEGORY_AGILE_METHODS: 'Agile Methoden',
    PROJECTS_PROFILE_CATEGORY_LEAN_METHODS: 'Lean Methoden',
    PROJECTS_PROFILE_CATEGORY_SHARING_PERSONAL_SKILLS: 'Individuelle Skills teilen',
    PROJECTS_PROFILE_CATEGORY_SERVICES: 'Services',
    PROJECTS_PROFILE_CATEGORY_SUPPORT: 'Support',
    PROJECTS_PROFILE_CATEGORY_SALES: 'Sales',
    PROJECTS_PROFILE_CATEGORY_FACILITY: 'Facility',
    PROJECTS_PROFILE_CATEGORY_FINANCE: 'Finance',
    PROJECTS_PROFILE_CATEGORY_GENERAL_ADMINISTRATION: 'General Administration',
    PROJECTS_PROFILE_CATEGORY_HUMAN_RESOURCES: 'Human Resources',
    PROJECTS_PROFILE_CATEGORY_HEALTH: 'Health',
    PROJECTS_PROFILE_CATEGORY_LEGAL: 'Legal',
    PROJECTS_PROFILE_CATEGORY_MARKETING: 'Marketing',
    PROJECTS_PROFILE_CATEGORY_EVENTS: 'Events',
    PROJECTS_PROFILE_CATEGORY_DEVELOPMENT: 'Development',
    PROJECTS_PROFILE_CATEGORY_PRODUCT_MANAGEMENT: 'Product Management',
    PROJECTS_PROFILE_CATEGORY_TRAINING_AND_EDUCATION: 'Training&Education',

    PROJECTS_DOMAIN_PROFILE_MATCHES_TAB: 'Matches',
    PROJECTS_DOMAIN_APPLIED_TO_TAB: 'Meine Projekte',
    PROJECTS_DOMAIN_SEARCH_TAB: 'Suche',
    PROJECTS_DOMAIN_OFFERED_BY_ME_TAB: 'Von mir angeboten',
    PROJECTS_DOMAIN_STAFFING: 'Staffing',

    PROJECTS_PROFILE_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',
    PROJECTS_PROFILE_NO_MATCH_CONTENT:
        'Bitte gib mehr Fähigkeiten und (versteckte?) Talente in deinem Profil an, um Matches zu finden',
    PROJECTS_PROFILE_NO_MATCH_CTA: 'Bearbeiten',
    PROJECTS_PROFILE_NO_MATCH_FIND_CONTENT: 'Weitere Projekte über die Suche finden',
    PROJECTS_PROFILE_SEARCH_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von dir gewählten Filter',

    PROJECTS_DOMAIN_PROFILE_MATCH_SKILLS: 'Gesuchte Fähigkeiten',
    PROJECTS_PROFILE_MATCH_VIEW_ENTITY: 'Details ansehen',
    PROJECTS_DOMAIN_PROFILE_MATCH_AVATAR_LABEL: 'Kontakt',
    CUSTOM_SKILL_FORM_PERSONAL_NAME_LABEL: 'Gesuchte Fähigkeiten',
    CUSTOM_SKILL_FORM_PERSONAL_LEVEL_LABEL: 'Bevorzugtes Level',

    PROJECTS_DETAILS_VIEW_DESCRIPTION_TITLE: 'Projekt-Beschreibung',
    PROJECTS_DETAILS_VIEW_SKILLS_TITLE: 'Gesuchte Fähigkeiten',
    PROJECTS_DETAILS_VIEW_ORGANIZER_LABEL: 'Kontakt',

    PROJECTS_ENTITY_PARTICIPANTS_SKILL_SUMMARY_LABEL: 'Zu besetzende Fähigkeiten:',
    PROJECTS_ENTITY_PARTICIPANTS_TITLE: 'Bewerber*innen Liste',
    PROJECTS_ENTITY_VIEW_PARTICIPATE_BUTTON: 'Bewerben',
    PROJECTS_ENTITY_VIEW_APPLIED_STATUS_NOTE: 'Du hast dich bereits auf dieses Projekt beworben',
    PROJECTS_ENTITY_VIEW_HOW_TO_APPLY_NOTE:
        'Du kannst dich für {{topicEntity}} bewerben, indem du die <i>>>erste Option in deinem Profil aktivierst</i> und somit angibst, dass du auch an der Teilnahme interessiert bist',

    PROJECTS_ENTITY_APPLICATIONS_IDENTITY_HEADER: 'Bewerber*in',
    PROJECTS_ENTITY_APPLICATIONS_SKILLS_AND_LEVELS_HEADER: 'Fähigkeiten der Bewerber*in',
    PROJECTS_ENTITY_APPLICATIONS_RELATED_SKILLS_HEADER: 'Verwandter Begriff',
    PROJECTS_ENTITY_APPLICATIONS_STATUS_HEADER: 'Status',
    PROJECTS_ENTITY_APPLICATIONS_ACCEPT: 'Annehmen',
    PROJECTS_ENTITY_APPLICATIONS_ACCEPTED: 'Angenommen',
    PROJECTS_ENTITY_APPLICATIONS_REFUSED: 'abgelehnt',
    PROJECTS_ENTITY_APPLICATIONS_CANCELLED: 'abgesagt',
    PROJECTS_ENTITY_APPLICATIONS_COMPLETE_STAFFING: 'Besetzung abschließen',
    PROJECTS_ENTITY_APPLICATIONS_STAFFING_COMPLETED: 'Besetzung abgeschlossen',
    PROJECTS_ENTITY_APPLICATIONS_FINISH_ONGOING: 'Projekt beenden',
    PROJECTS_ENTITY_APPLICATIONS_ONGOING_FINISHED: 'Projekt beendet',

    PROJECTS_ENTITY_APPLICATIONS_SEARCH_TITLE: 'Selbst nach Matches suchen',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_INPUT_VALIDATION_REQUIRED: 'Bitte mindestens einen gesuchten Skill eingeben.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_INPUT_SEARCH_BUTTON: 'Suchen',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFY_BUTTON: 'hinweisen',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NOTIFIED: 'hingewiesen',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_RESULTS_NO_MATCHES_FOUND: 'Keine Treffer gefunden.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_TITLE: 'Auf dieses {{topicName}} hinweisen',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SUB_TITLE:
        'Informieren Sie die ausgewählte Person bequem mit einem Klick.',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE_LABEL: 'Die folgende Nachricht wird per Mail versendet:',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_MESSAGE:
        'Ein neues {{name_project_singular}}-Angebot passt hervorragend zu deinen Skills und Wünschen! Der {{name_project_singular}}-Owner hat dich auf {{platform_name}} gefunden und würde sich freuen, dich an Bord zu haben. Log dich in deinen Account ein, um Details zu erfahren und gleich Kontakt aufzunehmen!',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFY_DIALOG_SEND_NOTE_BUTTON: 'Hinweis senden',
    PROJECTS_ENTITY_APPLICATIONS_SEARCH_NOTIFICATION_SENT_FEEDBACK: 'Hinweis wurde gesendet.',

    PROJECTS_ENTITY_APPLICATION_FEEDBACK_DIALOG_BTN: 'Fertig',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADLINE: 'Du hast keinen Platz bekommen!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_HEADING: 'Deine Bewerbung war leider nicht erfolgreich!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_REJECTED_TEXT:
        'Dieses Mal hat es leider nicht geklappt. Du wirst natürlich weiterhin über passende neue Angebote informiert.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADLINE: 'Danke für dein Interesse',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_HEADING: 'Deine Bewerbung ist eingegangen.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_PENDING_TEXT:
        'Sobald die Besetzung feststeht, wirst du informiert. Danke für deine Geduld!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADLINE: 'Danke für Ihre Bewerbung',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_HEADING: 'Du bist dabei!',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_APPROVED_TEXT: 'Deine Bewerbung war erfolgreich.',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADLINE: 'Teilnahme abgelehnt',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_HEADING: 'Nachträgliche Absage durch Anbieter',
    PROJECTS_ENTITY_APPLICATION_FEEDBACK_CANCELLED_TEXT:
        'Ihre erfolgreiche Bewerbung für das Projekt wurde nachträglich von Seiten des Anbieters aufgehoben. Kontaktieren Sie bei Rückfragen bitte die verantwortlichen Ansprechpartner*innen.\nVielen Dank für Ihr Interesse! Über neue Projekte werden Sie  weiter informiert.',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_TITLE: 'Bitte bestätigen',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_COMPLETE_STAFFING_DIALOG_CONTENT:
        'Klar zum Durchstarten? Es scheint, du hast Personen für dein Projekt-Team gefunden und möchtest die Projektbesetzung jetzt abschließen. Wichtig: Wenn du nun bestätigst, werden alle nicht akzeptierten Bewerber*innen eine Absage erhalten und es können anschließend keine weiteren Personen zum Team hinzugefügt werden. Mit deinem Projekt-Team kannst du im Gruppen-Chat weiteres klären.\nJetzt die Besetzung für dieses Projekt vollständig abschließen?',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_FINISH_ONGOING_DIALOG_TITLE: 'Bitte bestätigen',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_FINISH_ONGOING_DIALOG_CONTENT:
        'Ist das Projekt abgeschlossen? Es scheint, dass du dein Projekt abschließen konntest und keine weitere Besetzung erforderlich ist. Wichtig: Wenn du jetzt bestätigst, können sich deine Kolleg*innen nicht mehr auf dein Angebot bewerben.\nMöchtest du das Staffing für das Projekt jetzt abschließen?',

    PROJECTS_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_TITLE: '{{userName}} jetzt annehmen',
    PROJECTS_ENTITY_APPLICATION_CONFIRM_ACCEPT_DIALOG_CONTENT:
        'Wähle  “Annehmen”, um den/die Bewerber/in zuzulassen. Die Person wird im Anschluss automatisch per Mail über die erfolgreiche Bewerbung informiert.',

    PROJECTS_ENTITY_REQUIRED_SKILLS_TITLE: 'Fähigkeiten',
    PROJECTS_ENTITY_REQUIRED_SKILLS_SUB_TITLE:
        'Hier siehst du alle Fähigkeiten, die für dieses Projekt gesucht werden. Wenn du noch Fähigkeiten entdeckst, die du eigentlich besitzt und einbringen möchtest, aber bisher noch nicht in deinem Profil hast, kannst du diese hier auswählen und deinem Profil hinzufügen.',

    PROJECTS_ENTITY_ACCEPT_CONDITIONS_TITLE: 'Vielen Dank für dein Interesse!',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_SUB_TITLE: 'Dein/e Vorgesetze/r muss deiner Teilnahme zustimmen.',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_TEXT:
        'Tandemploy bringt deine Skills und spannende Angebote zusammen. Dies erfolgt natürlich unter Einhaltung von arbeitsrechtlich vorgeschriebenen Prozessen, die im Falle einer Versetzung oder eines Auslandseinsatzes zu beachten sind. Bei Einsätzen im Ausland, die die Dauer einer Dienstreise übersteigen, sind entsprechende Vorlaufzeiten zur Administration zu berücksichtigen. Wenn du dazu Fragen hast, wende dich an deine Führungskraft oder deine Personalabteilung.',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_LABEL:
        'Ich bestätige hiermit, dass ich meine Führungskraft informiert habe und er/sie meiner Teilnahme zustimmt. ',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_YES_LABEL: 'Ja',
    PROJECTS_ENTITY_ACCEPT_CONDITIONS_OPTIONS_NO_LABEL: 'Noch nicht',

    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_QUESTION: 'Diese Person bereits jetzt ablehnen?',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_YES: 'Ja',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_NO: 'Nein',
    PROJECTS_ENTITY_APPLICATION_REJECT_TOOLTIP_MORE: 'Was bedeutet das?',
    PROJECTS_ENTITY_APPLICATION_REJECT_INFO_TITLE: 'Bewerbungen vorab ablehnen',
    PROJECTS_ENTITY_APPLICATION_REJECT_INFO_CONTENT:
        'Bewerbungen können frühzeitig abgelehnt werden, wenn bereits klar ist, dass der/die Kandidat/in eher schlechte Chancen hat. Das bringt den Vorteil, dass die Betroffenen früher Bescheid wissen und entsprechend planen können. Außerdem bleibt so die Liste der Bewerbungen übersichtlicher.',

    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_QUESTION: 'Diese Person im Nachhinein ablehnen?',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_YES: 'Ja',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_NO: 'Nein',
    PROJECTS_ENTITY_APPLICATION_CANCEL_TOOLTIP_MORE: 'Was bedeutet das?',
    PROJECTS_ENTITY_APPLICATION_CANCEL_INFO_TITLE: 'Bewerbung im Nachhinein ablehnen',
    PROJECTS_ENTITY_APPLICATION_CANCEL_INFO_CONTENT:
        'Bewerbungen können abgelehnt werden, nachdem Bewerber*innen zuvor schon angenommen wurden. Die Bewerber*innen werden via E-Mail benachrichtigt. Für eine Erklärung empfiehlt es sich, eine persönliche Nachricht zu schreiben.',

    PROJECTS_VALIDATION_ERROR_MAX_DURATION: 'Maximale Dauer 4 Wochen',

    PROJECTS_STAFFING_FILTER_STATUS_LABEL: 'Status',
    PROJECTS_STAFFING_FILTER_STAFFING_LABEL: 'Staffing',
    PROJECTS_STAFFING_FILTER_STAFFING_OPTION_ON: 'offen für Bewerbungen',
    PROJECTS_STAFFING_FILTER_STAFFING_OPTION_OFF: 'Berwerbungsfrist erreicht',
    PROJECTS_STAFFING_FILTER_CATEGORY_LABEL: 'Thematische Einordnung',
    PROJECTS_STAFFING_FILTER_LANGUAGE_LABEL: 'Sprache',
    PROJECTS_STAFFING_NO_RESULTS_PLACEHOLDER: 'Es gibt keine Ergebnisse für Deine ausgewählten Filter.',

    PROJECTS_PROFILE_CAMPAIGNS_LABEL: 'HR Programme, an denen du teilnimmst',
    PROJECTS_PROFILE_CAMPAIGNS_PLACEHOLDER: 'wählen..',

    PAGE_TITLE_PROJECTS_MATCHES: 'Projekte matches',
    PAGE_TITLE_PROJECTS_MY: 'Meine Projekte',
    PAGE_TITLE_PROJECTS_SEARCH: 'Projekte - Suche',
    PAGE_TITLE_PROJECTS_OFFERED: 'Projekte- Von mir angeboten',

    ORIGIN_TOPIC_PROJECTS_PRIVATE: 'Projekte',
    ORIGIN_TOPIC_PROJECTS_PARTICIPANT: 'Projekte',

    PROJECTS_ORGANIZERS_INFO:
        'Ein Angebot kann mehrere Organisator*innen haben, sodass sie parallel oder in Abwesenheit anderer Organisator*innen dieses Angebot vorantreiben können. Hier kannst du festlegen, wer außer dir dieses {{name_project_singular}}-Angebot bearbeiten und löschen sowie Nachrichten an Teilnehmende senden können soll. \n' +
        'Die hier aufgeführten Nutzer*Innen können ihrerseits Organisator*Innen hinzufügen und löschen.',
};
