<tp-intro-view>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/en/saas_animation.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/en/saas_animation_preview.jpg"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/en/Moritz_Mihm_BPM_Gelbe_Couch_Cover.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/en/Moritz_Mihm_BPM_Gelbe_Couch_Cover.png"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/en/gelbe_couch_lufthansa.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/en/gelbe_couch_lufthansa_preview.png"
    ></tp-preview-item>
    <tp-preview-item
        src="https://tployassets.blob.core.windows.net/videos/en/easter_bunny.mp4"
        preview="https://tployassets.blob.core.windows.net/videos/en/easter_bunny_preview.png"
    ></tp-preview-item>
</tp-intro-view>
