import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { Translatable } from '../../language';
import { Skill } from '../../skill';

@Component({
    selector: 'tp-profile-view-list-field',
    templateUrl: './profile-view-list-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'profile-view__list-field',
    },
})
export class ProfileViewListFieldComponent {
    @Input()
    key: Translatable | string;

    @Input()
    value: Translatable[] | string[] | Skill[];

    @Input()
    orientation = 'inline' as const;

    @Input() template: TemplateRef<{ item: unknown }>;
}
