import { NgModule } from '@angular/core';
import { LanguageModule } from '@tploy-enterprise/tenant-common';
import { EntityNotFoundDialogComponent } from './entity-not-found-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ButtonModule, LinkModule } from '@tandemploy/ngx-components';
@NgModule({
    declarations: [EntityNotFoundDialogComponent],
    imports: [LanguageModule, ButtonModule, LinkModule, MatButtonModule, MatDialogModule, RouterModule],
})
export class EntityNotFoundModule {}
