export default {
    GENERATION_EXCHANGE: 'Generation Exchange',
    GENERATION_EXCHANGE_INFO:
        'Sie möchten sich mit Kolleg*Innen unterschiedlicher Generationen vernetzten, Erfahrungen austauschen und Ihr  persönliches Netzwerk erweitern? Dann ist Generation Exchange das Richtige für Sie!',

    GENERATION_EXCHANGE_PROFILE_SUBTITLE:
        'Sie möchten sich mit Kolleg*Innen unterschiedlicher Generationen vernetzten, Erfahrungen austauschen und Ihr  persönliches Netzwerk erweitern? Dann ist Generation Exchange das Richtige für Sie!',
    GENERATION_EXCHANGE_PROFILE_LOCATION_LABEL: 'An welchen Standorten sind Sie für Treffen verfügbar?',
    GENERATION_EXCHANGE_PROFILE_LOCATION_INDEPENDENT_LABEL: 'Gerne virtuelles Treffen',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_LABEL: 'Was motiviert Sie zur Teilnahme an Generation Exchange?',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_1:
        'Ich möchte mich über spezifische/arbeitsbezogene Themen unterhalten und dabei Wissen mit anderen Generationen austauschen.',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_2:
        'Mich interessiert das Thema Generationen im Unternehmen und ich möchte die Sichtweisen der Generationen auf verschiedene Themen besser verstehen.',
    GENERATION_EXCHANGE_PROFILE_MOTIVATION_OPTION_3:
        'Ich möchte mich mit Kolleg*innen aus anderen Generationen vernetzen, da ich den Austausch, egal ob fachlich oder privat, wichtig finde.',
    GENERATION_MOTIVATION_VALIDATION_REQUIRED: 'Es muss mindestens eine Option ausgewählt werden.',
    GENERATION_EXCHANGE_PROFILE_SKILLS_LABEL: 'Sie können auch individuelle Themen für den Austausch vorschlagen.',
    GENERATION_EXCHANGE_PROFILE_SKILLS_PLACEHOLDER: 'z.B. Sales, Sport, Theater',
    GENERATION_EXCHANGE_PROFILE_GENERATION_LABEL: 'Welcher Generation gehören Sie an?',
    GENERATION_EXCHANGE_PROFILE_GENERATION_0: 'Traditionalist (geb. vor 1946)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_1: 'Babyboomer (geb. 1946-1963)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_2: 'Generation X (geb. 1964-1978)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_3: 'Generation Y (geb. 1979-1994)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_4: 'Generation Z (geb. ab 1995)',
    GENERATION_EXCHANGE_PROFILE_GENERATION_PLACEHOLDER: 'Bitte auswählen',

    PROFILE_VIEW_GENERATION_EXCHANGE_LOCATIONS_LABEL: 'Mögliche Treffpunkte',
    PROFILE_VIEW_GENERATION_EXCHANGE_LOCATION_ONLINE: 'online',
    PROFILE_VIEW_GENERATION_EXCHANGE_TOPICS_LABEL: 'Individuelle Themen',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_LABEL: 'Meine Motivation',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_1: 'Spezifische/ arbeitsbezogene Themen',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_2: 'Unterschiedliche Sichtweisen kennenlernen',
    PROFILE_VIEW_GENERATION_EXCHANGE_MOTIVATION_OPTION_3: 'Austausch zwischen Generationen, egal worüber',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_LABEL: 'Meine Generation',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_0: 'Traditionalist',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_1: 'Babyboomer',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_2: 'Generation X',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_3: 'Generation Y',
    PROFILE_VIEW_GENERATION_EXCHANGE_GENERATION_4: 'Generation Z',
    PROFILE_VIEW_GENERATION_EXCHANGE_CAMPAIGNS_LABEL: 'HR Programme',

    GENERATION_EXCHANGE_PROFILE_CAMPAIGNS_LABEL: 'HR Programme, an denen Sie teilnehmen',
    GENERATION_EXCHANGE_PROFILE_CAMPAIGNS_PLACEHOLDER: 'wählen..',

    GENERATION_EXCHANGE_MATCHES_TAB: 'Matches',
    GENERATION_EXCHANGE_BOOKMARKS_TAB: 'Gemerkte Kolleg*innen',
    GENERATION_EXCHANGE_SEARCH_TAB: 'Suche',

    GENERATION_EXCHANGE_NO_MATCH_IMG_ALT: 'Keine Matches gefunden',
    GENERATION_EXCHANGE_NO_MATCH_CONTENT:
        'Bitte geben Sie mehr Fähigkeiten und (versteckte?) Talente in Ihrem Profil an, um Matches zu finden',
    GENERATION_EXCHANGE_NO_MATCH_CTA: 'Bearbeiten',
    GENERATION_EXCHANGE_NO_MATCH_FIND_CONTENT: 'Weitere Kolleg*innen über die Suche finden',

    GENERATION_EXCHANGE_NO_BOOKMARKS_TITLE: 'Noch kein Profil gemerkt',
    GENERATION_EXCHANGE_NO_BOOKMARKS_SUBTITLE:
        'Hier sehen Sie Ihre gemerkten Kolleg*innen im Bereich Generation Exchange.',

    GENERATION_EXCHANGE_SEARCH_HEADER: 'Generation Exchange - Profile durchsuchen',
    GENERATION_EXCHANGE_SEARCH_BTN_TEXT: 'Suchen!',
    GENERATION_EXCHANGE_SEARCH_EMPTY_MESSAGE:
        'Leider wurde nichts gefunden. Tipp: Eine Suche ohne Suchbegriff zeigt alle Generation Exchange profile.',
    GENERATION_EXCHANGE_SEARCH_NO_RESULT_IMG_ALT:
        'Das Bild zeigt einen Platzhalter, der anstelle von Ergebnissen erscheint, da die Suche keine Ergebnisse ergab.',
    GENERATION_EXCHANGE_SEARCH_NO_CAMPAIGNS_RESULT: 'Es gibt kein Ergebnis für den von Ihnen gewählten Filter',

    GENERATION_EXCHANGE_ITEM_LOCATION_INDEPENDENT_LABEL: 'auch zum Online-Lunch bereit',
    GENERATION_EXCHANGE_ITEM_MATCHED_LOCATION_INDEPENDENT_LABEL: 'Match nur für Online-Lunch',

    PAGE_TITLE_GENERATION_EXCHANGE_MATCHING: 'Generation Exchange - Matches',
    PAGE_TITLE_GENERATION_EXCHANGE_BOOKMARKS: 'Generation Exchange - Gemerkte Kollegen',
    PAGE_TITLE_GENERATION_EXCHANGE_SEARCH: 'Generation Exchange - Suche',
};
