<div class="no-match-placeholder__container">
    <span class="no-match-placeholder__content-left">
        <span class="no-match-placeholder__text">
            {{ 'NLA_NO_MATCH_CONTENT' | translatable }}
            <tp-secondary-button [routerLink]="['/', { outlets: { overlay: ['edit', 'never-lunch-alone'] } }]">{{
                'NLA_NO_MATCH_CTA' | translatable
            }}</tp-secondary-button>
        </span>
        <a
            [routerLink]="['/', { outlets: { overlay: ['edit', 'never-lunch-alone'] } }]"
            class="no-match-placeholder__link-left"
        >
            <img
                src="assets/images/placeholder/no_matches_placeholder.png"
                [alt]="'NLA_NO_MATCH_IMG_ALT' | translatable"
                class="no-match-placeholder__image-left"
            />
        </a>
    </span>
    <span class="no-match-placeholder__content-right">
        <span class="no-match-placeholder__text">{{ 'NLA_NO_MATCH_FIND_CONTENT' | translatable }}</span>
        <a [routerLink]="['../search']" class="no-match-placeholder__link-right">
            <img
                src="assets/images/placeholder/search_people_placeholder.png"
                [alt]="'NLA_NO_MATCH_IMG_ALT' | translatable"
                class="no-match-placeholder__image-right"
            />
        </a>
    </span>
</div>
